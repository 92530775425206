import { createActions } from 'redux-actions';

export const NAMESPACE = 'playerPositions';

export const actions = createActions(
  'GET_PLAYER_POSITIONS_BY_SPORT_REQUEST',
  'GET_PLAYER_POSITIONS_BY_SPORT_SUCCESS',
  'GET_PLAYER_POSITIONS_BY_SPORT_FAILURE',
  { prefix: NAMESPACE },
) as unknown as {
  getPlayerPositionsBySportRequest: RequestLifecycleAction;
  getPlayerPositionsBySportSuccess: RequestLifecycleAction;
  getPlayerPositionsBySportFailure: RequestLifecycleAction;
};
