import {
  all,
  call,
  cancelled,
  Effect,
  put,
  race,
  SagaReturnType,
  select,
  take,
} from 'redux-saga/effects';

import { getURLSearchParams, handleApiErrors } from '@core/helpers';

import * as matchAllocationAPI from '@core/api/match-allocation';
import { isActionOfType, takeLeadingByPredicate } from '@core/store/helpers';

import { getMatchesStatus } from './helpers';
import * as matchesAllocationSelectors from './selectors';
import { actions } from '.';

function* getPaginationUrlSearchParams({
  stageId,
  page,
}: {
  stageId: number;
  page: number;
}) {
  const paginationSelector =
    matchesAllocationSelectors.getMatchesAllocation(stageId);

  return getURLSearchParams(
    {
      paginated: (yield select(paginationSelector)) as InferSelector<
        typeof paginationSelector
      >,
    },
    { page, limit: undefined },
  );
}

function* getMatchesAllocation(
  groupId: number,
  query: URLSearchParams,
): Generator<Effect, any, any> {
  try {
    yield put(actions.loadMatchesAllocationRequest());
    const response = (yield call(
      matchAllocationAPI.getMatchAllocationList,
      groupId,
      query,
    )) as InferApi<typeof matchAllocationAPI.getMatchAllocationList>;

    if (!response.error) {
      yield put(actions.loadMatchesAllocationSuccess());
    } else {
      yield put(actions.loadMatchesAllocationFailure());
    }

    return response;
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.loadMatchesAllocationSuccess());
    }
  }
}

export function* getMatchesAllocationFlow() {
  yield takeLeadingByPredicate(
    actions.getMatchesAllocation,
    function* (action): Generator<Effect, any, any> {
      const { stageId, page } = action.payload;

      const urlSearchParams = yield* getPaginationUrlSearchParams({
        stageId,
        page,
      });

      yield put(actions.getMatchesAllocationRequest({ stageId }));
      const { error, response } = (yield call(
        getMatchesAllocation,
        stageId,
        urlSearchParams,
      )) as SagaReturnType<typeof getMatchesAllocation>;

      yield put(actions.cancelMatchesAllocationRefresh({ stageId }));

      if (!error) {
        yield put(
          actions.setMatchesAllocation({
            groupId: stageId,
            payload: response,
          }),
        );
        yield put(
          actions.setAllocations({
            groupId: stageId,
            payload: getMatchesStatus(response),
          }),
        );
        yield put(actions.getMatchesAllocationSuccess({ stageId }));
      } else {
        yield call(handleApiErrors, error);
        yield put(actions.getMatchesAllocationFailure({ stageId }));
      }
    },
  );
}

export function* refreshMatchesAllocationFlow() {
  yield takeLeadingByPredicate(
    actions.refreshMatchesAllocation,
    function* (action) {
      const { stageId } = action.payload;

      yield race([
        call(function* () {
          try {
            const urlSearchParams = yield* getPaginationUrlSearchParams({
              stageId,
              page: undefined,
            });

            yield put(actions.refreshMatchesAllocationRequest({ stageId }));

            const { error, response } = (yield call(
              getMatchesAllocation,
              stageId,
              urlSearchParams,
            )) as SagaReturnType<typeof getMatchesAllocation>;

            if (!error) {
              yield put(
                actions.setAllocations({
                  groupId: stageId,
                  payload: getMatchesStatus(response),
                }),
              );
              yield put(actions.refreshMatchesAllocationSuccess({ stageId }));
            } else {
              yield call(handleApiErrors, error);
              yield put(actions.refreshMatchesAllocationFailure({ stageId }));
            }
          } finally {
            if ((yield cancelled()) as boolean) {
              yield put(actions.refreshMatchesAllocationSuccess({ stageId }));
            }
          }
        }),
        take(
          (anyAction: any) =>
            isActionOfType(actions.cancelMatchesAllocationRefresh)(anyAction) &&
            anyAction.payload.stageId === stageId,
        ),
      ]);
    },
  );
}

export default function* saga() {
  yield all([getMatchesAllocationFlow(), refreshMatchesAllocationFlow()]);
}
