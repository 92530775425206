import { ClubTypes } from '@core/types';

import { getClubType } from './get-club-type';

export const getClubTypeOptions = (t: (key: string) => string) => {
  return [
    {
      label: getClubType(t, ClubTypes.CLUB),
      value: ClubTypes.CLUB,
    },
    {
      label: getClubType(t, ClubTypes.NATIONAL_CLUB),
      value: ClubTypes.NATIONAL_CLUB,
    },
  ];
};
