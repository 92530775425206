import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { MatchPlayerOrigin } from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';

import AddedTeamOfficialsTable from './added-team-officials-table';
import AvailableTeamOfficialsTable from './available-team-officials-table';

interface OwnProps {
  matchId: number;
  competitionId: number;
  teamId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  tabId: string;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

type Props = OwnProps & DispatchProps;

const TeamOfficialsTab = (props: Props) => {
  const { matchId, competitionId, teamId, matchPlayerOrigin, tabId, actions } =
    props;

  useEffect(() => {
    actions.matchSheetManagement.searchCompetitorTeamOfficials({
      matchId,
      teamId,
      competitionId,
      matchPlayerOrigin,
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <AvailableTeamOfficialsTable
        tabId={tabId}
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        teamId={teamId}
        competitionId={competitionId}
      />
      <AddedTeamOfficialsTable
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        tabId={tabId}
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(null, mapDispatchToProps)(TeamOfficialsTab);
