export const COMPETITION_PROFILE_PAGE = 'COMPETITION_PROFILE_PAGE';
export const COMPETITION_PROFILE_PANEL = 'COMPETITION_PROFILE_PANEL';
export const COMPETITION_PROFILE_SUMMARY = 'COMPETITION_PROFILE_SUMMARY';
export const COMPETITION_PROFILE_SUMMARY_TOGGLE =
  'COMPETITION_PROFILE_SUMMARY_TOGGLE';
export const COMPETITION_PROFILE_TABS = 'COMPETITION_PROFILE_TABS';
export const COMPETITION_PROFILE_EDIT_DETAILS_BUTTON =
  'COMPETITION_PROFILE_EDIT_DETAILS_BUTTON';
export const COMPETITION_PROFILE_EDIT_SETTINGS_BUTTON =
  'COMPETITION_PROFILE_EDIT_SETTINGS_BUTTON';
export const COMPETITION_PROFILE_COMPETITORS_TAB =
  'COMPETITION_PROFILE_COMPETITORS_TAB';
export const COMPETITION_PROFILE_ADD_TEAM_BUTTON =
  'COMPETITION_PROFILE_ADD_TEAM_BUTTON';
export const COMPETITION_PROFILE_COMPETITORS_TABLE =
  ' COMPETITION_PROFILE_COMPETITORS_TABLE';
export const COMPETITION_PROFILE_CREATE_STAGE =
  'COMPETITION_PROFILE_CREATE_STAGE';
export const COMPETITION_PROFILE_EDIT_DISCIPLINE =
  'COMPETITION_PROFILE_EDIT_DISCIPLINE';
export const COMPETITION_PROFILE_ACTIONS = 'COMPETITION_PROFILE_ACTIONS';

export const STAGE_PROFILE_PANEL = 'STAGE_PROFILE_PANEL';
export const STAGE_PROFILE_DETAILS = 'STAGE_PROFILE_DETAILS';
export const STAGE_PROFILE_ACTIONS = 'STAGE_PROFILE_ACTIONS';
export const STAGE_PROFILE_DELETE = 'STAGE_PROFILE_DELETE';
export const STAGE_PROFILE_TABS = 'STAGE_PROFILE_TABS';
export const STAGE_PROFILE_FIXTURE_BUTTON = 'STAGE_PROFILE_FIXTURE_BUTTON';
export const STAGE_PROFILE_EDIT_DETAILS_BUTTON =
  'STAGE_PROFILE_EDIT_DETAILS_BUTTON';
export const STAGE_PROFILE_INFO_BLOCK = 'STAGE_PROFILE_INFO_BLOCK';
export const STAGE_PROFILE_TEAMS_BLOCK = 'STAGE_PROFILE_TEAMS_BLOCK';
export const STAGE_PROFILE_TEAM_GRID_BUTTON = 'STAGE_PROFILE_TEAM_GRID_BUTTON';
export const STAGE_PROFILE_EXPORT_MATCHES_BUTTON =
  'STAGE_PROFILE_EXPORT_MATCHES_BUTTON';
export const STAGE_PROFILE_DOWNLOAD_CSV_TEMPLATE_BUTTON =
  'STAGE_PROFILE_DOWNLOAD_CSV_TEMPLATE_BUTTON';
export const STAGE_PROFILE_IMPORT_MATCHES_BUTTON =
  'STAGE_PROFILE_IMPORT_MATCHES_BUTTON';
export const STAGE_PROFILE_CREATE_CUSTOM_MATCH_BUTTON =
  'STAGE_PROFILE_CREATE_CUSTOM_MATCH_BUTTON';
export const STAGE_PROFILE_MATCH_TAB = 'STAGE_PROFILE_MATCH_TAB';
export const STAGE_PROFILE_BRACKETS_TAB = 'STAGE_PROFILE_BRACKETS_TAB';
export const STAGE_PROFILE_SUMMARY_TOGGLE = 'STAGE_PROFILE_SUMMARY_TOGGLE';
export const STAGE_PROFILE_EDIT_POOL_BUTTON = 'STAGE_PROFILE_EDIT_POOL_BUTTON';
export const STAGE_PROFILE_DELETE_POOL_BUTTON =
  'STAGE_PROFILE_DELETE_POOL_BUTTON';
export const STAGE_PROFILE_CREATE_GROUP_BUTTON =
  'STAGE_PROFILE_CREATE_GROUP_BUTTON';

export const CONTACT_TITLE_INPUT = 'CONTACT_TITLE_INPUT';
export const CONTACT_FIRSTNAME_INPUT = 'CONTACT_FIRSTNAME_INPUT';
export const CONTACT_FAMILY_INPUT = 'CONTACT_FAMILY_INPUT';
export const CONTACT_PHONE_INPUT = 'CONTACT_PHONE_INPUT';
export const CONTACT_EMAIL_INPUT = 'CONTACT_EMAIL_INPUT';
export const CONTACT_CANCEL_BUTTON = 'CONTACT_CANCEL_BUTTON';
export const CONTACT_SUBMIT_BUTTON = 'CONTACT_SUBMIT_BUTTON';
