import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCoreDispatch } from '@core/hooks';
import { createURL } from '@core/helpers';
import { getTabsList } from '@core/store/modules/ui/tabs/selectors';
import paths from '@core/routes/paths';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';

export function useResetCompetitorProfile(competitorId: number) {
  const dispatch = useCoreDispatch();
  const tabs = useSelector(getTabsList);
  const filteredTabs = tabs?.filter(
    (tab) =>
      [
        createURL(paths.competitorProfile),
        createURL(paths.playerListEdit),
      ].includes(tab.name) &&
      (tab.props.competitorId === competitorId ||
        tab.props.competitor?.id === competitorId),
  );

  const callback = () => {
    if (filteredTabs.length === 1) {
      dispatch(competitorProfileActions.resetCompetitorProfile(competitorId));
    }
  };

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return callbackRef;
}
