import React from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { AddCircleTwoTone, EditTwoTone } from '@mui/icons-material';

interface Props {
  title: string;
  buttonOptions: {
    component: 'Button' | 'IconButton';
    dataQa: string;
    disabled?: boolean;
    onClick: () => void;
    text: string;
  };
}

export const MatchDataViewTableTitleAndAction: React.FC<Props> = (props) => {
  const {
    title,
    buttonOptions: { component, dataQa, disabled, onClick, text },
  } = props;

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography fontWeight="bold">{title}</Typography>
      </Grid>
      <Grid item>
        {component === 'Button' && (
          <Button
            color="primary"
            data-qa={dataQa}
            disabled={disabled}
            onClick={onClick}
            startIcon={<AddCircleTwoTone />}
            variant="outlined"
          >
            {text}
          </Button>
        )}
        {component === 'IconButton' && (
          <IconButton
            color="primary"
            data-qa={dataQa}
            disabled={disabled}
            onClick={onClick}
            title={text}
          >
            <EditTwoTone />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
