import type { GroupCompetitor, ChildGroupCompetitors } from '@core/types';

const hasChildGroups = (
  groupCompetitors: GroupCompetitor[] | ChildGroupCompetitors,
): groupCompetitors is ChildGroupCompetitors =>
  !!(groupCompetitors as ChildGroupCompetitors)?.childCompetitors;

export const getUniqueCompetitors = (
  groupCompetitors: GroupCompetitor[] | ChildGroupCompetitors,
) => {
  if (hasChildGroups(groupCompetitors)) {
    const childGroupCompetitors = Object.values(
      groupCompetitors.childCompetitors,
    )
      .flatMap((groupCompetitor) => groupCompetitor)
      .filter((groupCompetitor) => groupCompetitor.competitor)
      .map(({ competitor }) => ({
        id: competitor.id,
        title: competitor.title,
      }));

    const uniqueChildGroupCompetitors = [
      ...new Map(
        childGroupCompetitors.map((competitor) => [competitor.id, competitor]),
      ).values(),
    ];

    return uniqueChildGroupCompetitors;
  }

  const uniqueGroupCompetitors = (groupCompetitors ?? [])
    .filter(({ competitor }) => competitor)
    .map(({ competitor }) => ({
      id: competitor.id,
      title: competitor.title,
    }));

  return uniqueGroupCompetitors;
};
