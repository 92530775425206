import { useSelector } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomTable } from '@core/components';
import { formatTime, getTimeDiff } from '@core/helpers';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { Column, MaterialTableProps } from '@core/components/material-table';
import { MatchModel, MatchStatus } from '@core/types';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';
import { isMatchLoading } from '@core/store/modules/tabs/match-profile/selectors';
import { MatchSet, ModalTypes } from '@volleyball/types';
import { MatchDataViewTableTitleAndAction } from './match-data-view-table-title-and-action';
import { OwnProps as MatchDataEditModalProps } from './match-data-edit-modal';
import { convertDurationToString } from './helpers';
import { useAppDispatch } from '@volleyball/store/hooks';

type Data = {
  id: number;
  label: string;
  value: string;
};

type Props = {
  competitionId: number;
  match: MatchModel;
  stageId: number | undefined;
};

const MatchDataViewMatchTimeTable = ({
  competitionId,
  match,
  stageId,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const currentCustomer = useSelector(getCurrentCustomer);
  const isLoading = useSelector(isMatchLoading(match.id));
  const isMatchComplete = [MatchStatus.COMPLETE, MatchStatus.BYE].includes(
    match.status,
  );

  const handleEdit = (editOptions: MatchDataEditModalProps) => {
    dispatch(
      globalModalActions.openModal<MatchDataEditModalProps>({
        type: ModalTypes.MATCH_DATA_EDIT_MODAL,
        params: editOptions,
      }),
    );
  };

  const data = [
    {
      id: 1,
      label: t('Start time'),
      value: match.actualStartTime,
    },
    {
      id: 2,
      label: t('End time'),
      value: match.actualEndTime,
    },
  ];

  const columns: Array<Column<Data>> = [
    {
      field: 'label',
      headerStyle: { padding: 0 },
    },
    {
      field: 'value',
      cellStyle: {
        textAlign: 'right',
      },
      headerStyle: { padding: 0 },
      render: (rowData) => {
        return formatTime(currentCustomer.timeFormat, rowData.value);
      },
    },
  ];

  const renderSummaryRow: MaterialTableProps<Data>['renderSummaryRow'] = ({
    column,
    index,
    data: _data,
  }) => {
    const value = (() => {
      switch (column.field) {
        case 'label':
          return t('Match duration');
        case 'value': {
          let timeDiff =
            match.actualStartTime && match.actualEndTime
              ? getTimeDiff(match.actualStartTime, match.actualEndTime)
              : undefined;

          // If difference is negative, we need to add 24 hours in minutes
          if (timeDiff < 0) {
            timeDiff += 1440;
          }

          return convertDurationToString(timeDiff, t);
        }
        default:
          return ' ';
      }
    })().toString();

    const cellStyle =
      typeof column.cellStyle === 'function'
        ? column.cellStyle(_data, _data[index], column)
        : column.cellStyle;
    const textAlign = cellStyle?.textAlign;

    // NB: falsy value variable will cause the returned object to be rendered as a node
    return {
      value: value || ' ',
      style: {
        ...(textAlign ? { textAlign } : {}),
        fontSize: 'inherit',
        fontWeight: theme.typography.fontWeightBold,
      },
    };
  };

  const options: MaterialTableProps<MatchSet>['options'] = {
    rowStyle: (_data, index) =>
      data.length - 1 === index ? { fontWeight: undefined } : {},
  };

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <MatchDataViewTableTitleAndAction
        title={t('Match time')}
        buttonOptions={{
          component: 'IconButton',
          text: t('Edit'),
          disabled: isMatchComplete || !match?._links?.patch,
          onClick: () =>
            handleEdit({ competitionId, stageId, match, kind: 'matchTime' }),
          dataQa: 'data-qa',
        }}
      />
      <Grid item xs={12}>
        <CustomTable
          noPaper
          columns={columns}
          data={data}
          options={options}
          isLoading={isLoading}
          renderSummaryRow={renderSummaryRow}
        />
      </Grid>
    </Grid>
  );
};

export default MatchDataViewMatchTimeTable;
