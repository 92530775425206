import { StageType } from '@core/types';

export const getStageSubHeader = (
  t: (key: string) => string,
  type: StageType,
) => {
  switch (type) {
    case StageType.ROUND_ROBIN:
      return t('Round Robin');
    case StageType.KNOCK_OUT:
      return t('Knock Out');
    default:
      return type;
  }
};
