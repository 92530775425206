import DurationListForm from './components/duration-list-form';

interface OwnProps {
  matchId: number;
  tabId: string;
}

type Props = OwnProps;

const MatchDurationAttendanceEdit = (props: Props) => (
  <DurationListForm {...props} />
);

export default MatchDurationAttendanceEdit;
