import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Theme } from '@mui/material';

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderTop: ({ connectedTable }) =>
      !connectedTable && `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: `12px ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `12px ${theme.spacing(2)}`,
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface OwnProps {
  title: string | React.ReactElement;
  connectedTable?: boolean;
}

type Props = OwnProps;

const TableTitle = ({ title, connectedTable }: Props) => {
  const classes = useStyles({ connectedTable, title });

  return (
    <Grid className={classes.root}>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.title}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default TableTitle;
