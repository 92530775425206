import { createFilterId } from '@core/helpers';
import { FilteringOperations } from '@core/types';

export const COMPETITION_STATUS_FILTER = {
  fieldName: 'status',
  filteringOperation: FilteringOperations.equal,
};

export const COMPETITION_TYPE_FILTER = {
  fieldName: 'clubType',
  filteringOperation: FilteringOperations.equal,
};

export const COMPETITION_STATUS_FILTER_ID = createFilterId(
  COMPETITION_STATUS_FILTER,
);

export const COMPETITION_TYPE_FILTER_ID = createFilterId(
  COMPETITION_TYPE_FILTER,
);
