import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { ChildGroupCompetitors, GroupCompetitor } from '@core/types';
import actions from '.';

export interface DefaultState {
  byGroupId: {
    [groupId: string]: {
      competitors: Array<GroupCompetitor> | ChildGroupCompetitors;
    };
  };
  byCompetitionId: {
    [competitionId: string]: { competitors: Array<GroupCompetitor['id']> };
  };
}

export const defaultState: DefaultState = {
  byGroupId: {},
  byCompetitionId: {},
};

type Payload = any;

interface Meta {
  groupId: number;
  competitionId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setCompetitorsByGroupId.toString()]: (
      state,
      { payload, meta },
    ) => {
      return assocPath(
        ['byGroupId', meta.groupId, 'competitors'],
        payload,
        state,
      );
    },
    [actions.setCompetitorsByCompetitionId.toString()]: (
      state,
      { payload, meta },
    ) => {
      return assocPath(
        ['byCompetitionId', meta.competitionId, 'competitors'],
        payload,
        state,
      );
    },
  },
  defaultState,
);

export default reducer;
