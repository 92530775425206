import { call, cancelled, Effect, put } from 'redux-saga/effects';

import { VenueModel } from '@core/types';
import * as apiVenues from '@core/api/venues';

import { actions as venuesActions } from '.';

const formatVenueData = (data: any) => {
  if (!data['capacity']) {
    data['capacity'] = null;
  }

  return data;
};

export function* getVenue(venueId: number): Generator<Effect, any, any> {
  yield put(venuesActions.getVenueRequest());
  const response = yield call(apiVenues.getVenue, venueId);

  if (!response.error) {
    yield put(venuesActions.getVenueSuccess());
    yield put(venuesActions.setVenue(response.response));
  } else {
    yield put(venuesActions.getVenueFailure());
  }

  return response;
}

export function* getVenues(queryParams: {
  query?: string;
  page?: number;
}): Generator<Effect, any, any> {
  try {
    yield put(venuesActions.getVenuesRequest());
    const response = (yield call(apiVenues.getVenues, {
      queryParams,
    })) as InferApi<typeof apiVenues.getVenues>;

    if (!response.error) {
      yield put(venuesActions.getVenuesSuccess());
      // yield put(venuesActions.resetVenueList());
      yield put(venuesActions.setVenueList(response.response._embedded.venues));
    } else {
      yield put(venuesActions.getVenuesFailure());
    }

    return response;
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(venuesActions.getVenuesSuccess());
    }
  }
}

export function* createVenue(
  venueData: VenueModel,
): Generator<Effect, any, any> {
  yield put(venuesActions.createVenueRequest());
  const response = yield call(
    apiVenues.createVenue,
    formatVenueData(venueData),
  );

  if (!response.error) {
    yield put(venuesActions.createVenueSuccess());
  } else {
    yield put(venuesActions.createVenueFailure());
  }

  return response;
}

export function* patchVenue(
  venueId: number,
  venueData: VenueModel,
): Generator<Effect, any, any> {
  yield put(venuesActions.patchVenueRequest());
  const response = yield call(
    apiVenues.patchVenue,
    venueId,
    formatVenueData(venueData),
  );

  if (!response.error) {
    yield put(venuesActions.patchVenueSuccess());
  } else {
    yield put(venuesActions.patchVenueFailure());
  }

  return response;
}

export function* deleteVenue(venueId: number): Generator<Effect, any, any> {
  yield put(venuesActions.deleteVenueRequest());
  const response = yield call(apiVenues.deleteVenue, venueId);

  if (!response.error) {
    yield put(venuesActions.deleteVenueSuccess());
  } else {
    yield put(venuesActions.deleteVenueFailure());
  }

  return response;
}
