import { useTranslation } from 'react-i18next';
import { Grid, Box, Button, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetAppOutlined } from '@mui/icons-material';

type Props = {
  language?: string;
  languagesAvailable?: Array<string>;
  onChange?: (language: string) => void;
  onDownload?: () => void;
  isLoading?: boolean;
  buttonVariant?: 'text' | 'outlined' | 'contained';
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  languageSwitcher: {
    marginLeft: theme.spacing(1),
  },

  link: {},
}));

const TermsAndConditionsControls = ({ onDownload, buttonVariant }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // const options =
  //   !!language &&
  //   languagesAvailable?.map((lang) => ({
  //     text: mapLocaleToTermsLang(lang)?.toUpperCase(),
  //     value: lang,
  //   }));

  return (
    <Box className={classes.root}>
      {!!onDownload && (
        <Tooltip
          title={t('Unavailable, but soon will be available. Stay patient.')}
          placement="bottom"
        >
          <Grid>
            <Button
              disabled
              onClick={onDownload}
              startIcon={<GetAppOutlined />}
              color="primary"
              variant={buttonVariant ?? 'text'}
            >
              {t('PDF')}
            </Button>
          </Grid>
        </Tooltip>
      )}
      {/* {!!language && (
        <Box className={classes.languageSwitcher}>
          <ButtonWithDropdown
            isLoading={isLoading}
            startIcon={<IconLanguage />}
            variant={buttonVariant ?? 'text'}
            options={options}
            onSelect={onChange}
          >
            {mapLocaleToTermsLang(language)}
          </ButtonWithDropdown>
        </Box>
      )} */}
    </Box>
  );
};

export default TermsAndConditionsControls;
