import { WeekDaysShort } from '@core/types';

export const getWeekdayShort = (
  t: (key: string) => string,
  weekDay: string,
) => {
  switch (weekDay) {
    case WeekDaysShort.MON:
      return t('Mon');
    case WeekDaysShort.TUE:
      return t('Tue');
    case WeekDaysShort.WED:
      return t('Wed');
    case WeekDaysShort.THU:
      return t('Thu');
    case WeekDaysShort.FRI:
      return t('Fri');
    case WeekDaysShort.SAT:
      return t('Sat');
    case WeekDaysShort.SUN:
      return t('Sun');
    default:
      return t(weekDay);
  }
};
