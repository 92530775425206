import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 100%',
  },
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    marginTop: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
      maxWidth: 'none',
    },
  },
  titleIcon: {
    fontSize: theme.typography.h4.fontSize,
    fill: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.75),
  },
  title: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: `0 0 calc(${theme.spacing(5)} + 2px)`,
    lineHeight: `calc(${theme.spacing(3)} + 2px)`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: 'none',

    [theme.breakpoints.down('md')]: {
      borderTopWidth: 0,
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  titleText: {
    paddingLeft: theme.spacing(1),
    lineHeight: `${theme.spacing(3)}`,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  listWrapper: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 100%',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('md')]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  listWrapperEmpty: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -1,
    overflowY: 'auto',

    [theme.breakpoints.down('md')]: {
      position: 'relative',
      width: '100%',
      bottom: 0,
    },
  },
}));
