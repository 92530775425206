import React from 'react';

import { Competition } from '@core/icons';
import { LogoChanger } from '@core/components';
import { ObjectType, CompetitionResponse } from '@core/types';

type CompetitionAvatarProps = {
  competition: CompetitionResponse;
};

const CompetitionAvatar = (props: CompetitionAvatarProps) => {
  const {
    competition: { id, logoFileLink: src, logoFileId, _links },
  } = props;

  const canEditCompetition = !!_links?.patch;

  const fileParams = {
    fileId: logoFileId,
    objectId: id,
    objectType: ObjectType.Competitions,
  };

  return (
    <LogoChanger
      src={src}
      fileParams={fileParams}
      disabled={!canEditCompetition}
    >
      <Competition />
    </LogoChanger>
  );
};

export default CompetitionAvatar;
