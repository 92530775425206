import { createSelector } from '@reduxjs/toolkit';

import { State } from '@core/store';

import { NAMESPACE } from './constants';

const getRoot = createSelector(
  (state: State) => state?.pages,
  (root: any) => root?.[NAMESPACE],
);

export const getTerms = createSelector(getRoot, (root) => root?.terms);
export const getTermsPreferredLanguage = createSelector(
  getRoot,
  (root) => root?.preferredLanguage,
);
