export const NAMESPACE = 'ageGroupsPage';

export const SET_AGE_GROUPS = `${NAMESPACE}/SET_AGE_GROUPS`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;
export const SET_GROUP_BEING_MODIFIED = `${NAMESPACE}/SET_GROUP_BEING_MODIFIED`;
export const SET_NEW_GROUP_BEING_CREATED = `${NAMESPACE}/SET_NEW_GROUP_BEING_CREATED`;

export const GET_AGE_GROUPS = `${NAMESPACE}/GET_AGE_GROUPS`;
export const GET_AGE_GROUPS_REQUEST = `${NAMESPACE}/GET_AGE_GROUPS_REQUEST`;
export const GET_AGE_GROUPS_SUCCESS = `${NAMESPACE}/GET_AGE_GROUPS_SUCCESS`;
export const GET_AGE_GROUPS_FAILURE = `${NAMESPACE}/GET_AGE_GROUPS_FAILURE`;

export const CREATE_AGE_GROUP = `${NAMESPACE}/CREATE_AGE_GROUP`;
export const CREATE_AGE_GROUP_REQUEST = `${NAMESPACE}/CREATE_AGE_GROUP_REQUEST`;
export const CREATE_AGE_GROUP_SUCCESS = `${NAMESPACE}/CREATE_AGE_GROUP_SUCCESS`;
export const CREATE_AGE_GROUP_FAILURE = `${NAMESPACE}/CREATE_AGE_GROUP_FAILURE`;

export const UPDATE_AGE_GROUP = `${NAMESPACE}/UPDATE_AGE_GROUP`;
export const UPDATE_AGE_GROUP_REQUEST = `${NAMESPACE}/UPDATE_AGE_GROUP_REQUEST`;
export const UPDATE_AGE_GROUP_SUCCESS = `${NAMESPACE}/UPDATE_AGE_GROUP_SUCCESS`;
export const UPDATE_AGE_GROUP_FAILURE = `${NAMESPACE}/UPDATE_AGE_GROUP_FAILURE`;

export const DELETE_AGE_GROUP = `${NAMESPACE}/DELETE_AGE_GROUP`;
export const DELETE_AGE_GROUP_REQUEST = `${NAMESPACE}/DELETE_AGE_GROUP_REQUEST`;
export const DELETE_AGE_GROUP_SUCCESS = `${NAMESPACE}/DELETE_AGE_GROUP_SUCCESS`;
export const DELETE_AGE_GROUP_FAILURE = `${NAMESPACE}/DELETE_AGE_GROUP_FAILURE`;
