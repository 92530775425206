import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { MatchOfficialAllocationModel } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  byMatchId: {
    [matchId: number]: Array<MatchOfficialAllocationModel>;
  };
}

export const defaultState: DefaultState = {
  byMatchId: {},
};

type Payload = any;
interface Meta {
  matchId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setMatchOfficialAllocations.toString()]: (
      state,
      { payload, meta },
    ) => assocPath(['byMatchId', meta.matchId], payload, state),
  },
  defaultState,
);

export default reducer;
