import React, { useEffect } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, FormikConfig } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';

import {
  FullScreenSpinner,
  SelectField,
  NamedValueColumn,
  PlayerKitPreviewNew,
  makePlayerKitPreviewProps,
  ModalFieldsBlock,
} from '@core/components';
import {
  GlobalModalProps,
  KitSetList,
  KitType,
  KitTypes,
  Sport,
  Sports,
} from '@core/types';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getTeamColorSetsHaveSpecialPlayer } from '@core/factories/kit-set/get-team-color-sets-have-special-player';
import useModalStyles from '@core/components/global-modal/styles';

import { State } from '@core/store';
import { actions as competitorKitSetActions } from '@core/store/modules/tabs/competitor-kit-set';
import { getKitSets } from '@core/store/modules/tabs/competitor-kit-set/selectors';
import { ButtonWithProgress } from '@ui-components';

export const testIds = {
  TEAM_KITS_CANCEL_BUTTON: 'TEAM_KITS_CANCEL_BUTTON',
  TEAM_KITS_SUBMIT_BUTTON: 'TEAM_KITS_SUBMIT_BUTTON',
};

export enum FIELD_NAMES {
  kitSetId = 'kitSetId',
}

export type Values = { [FIELD_NAMES.kitSetId]: number };

export const getInitialValues = ({
  kitSetId,
}: {
  kitSetId: number;
}): Values => {
  return { [FIELD_NAMES.kitSetId]: kitSetId ?? ('' as any as number) };
};

export const getValidationSchema = (t: TFunction): any => {
  return yup.object({
    [FIELD_NAMES.kitSetId]: yup.number().required(t('Required')),
  });
};

export const getKitTitle = (t: TFunction, kitType: KitType): string =>
  kitType === 'home'
    ? t('Primary set')
    : kitType === 'away'
      ? t('Secondary set')
      : kitType === 'reserved'
        ? t('Tertiary set')
        : null;

export const getSpecialPlayerKitLabel = (
  t: TFunction,
  sport: Sport,
): string => {
  switch (sport.type) {
    case Sports.BEACH_VOLLEYBALL:
    case Sports.SNOW_VOLLEYBALL:
    case Sports.VOLLEYBALL:
      return t('Libero colors');
    default:
      return t('Special player colors');
  }
};

interface DispatchProps {
  actions: {
    competitorKitSet: typeof competitorKitSetActions;
  };
}

interface StateProps {
  isLoading: boolean;
  isSubmitting: boolean;
  kitSets: KitSetList;
}

export interface OwnProps {
  clubId: number;
  competitionId: number;
  competitorId: number;
  kitSetId: number;
  sport: Sport;
  teamId: number;
}

type Props = GlobalModalProps<OwnProps> & DispatchProps & StateProps;

const ManageTeamKitsModal = (props: Props) => {
  const {
    actions,
    isLoading,
    isModalOpen,
    isSubmitting,
    kitSets,
    modalActions,
    modalParams,
  } = props;

  const { clubId, competitionId, competitorId, sport, teamId } = modalParams;

  const classes = useModalStyles();

  const { t } = useTranslation();

  const isLoadingOrSubmitting = isLoading || isSubmitting;

  useEffect(() => {
    actions.competitorKitSet.getKitSets({ clubId, competitorId });

    return () => {
      actions.competitorKitSet.resetCompetitor({ competitorId });
    };
  }, [actions.competitorKitSet, clubId, competitorId]);

  const teamColorSetsHaveSpecialPlayer =
    getTeamColorSetsHaveSpecialPlayer(sport);

  const handleSubmit: FormikConfig<Values>['onSubmit'] = (
    values,
    formikHelpers,
  ) => {
    actions.competitorKitSet.updateCompetitorKitSet({
      competitionId,
      teamId,
      competitorId,
      data: {
        teamColorSetId: values[FIELD_NAMES.kitSetId],
      },
    });
  };

  const cancel = () => modalActions.closeModal();

  // TODO: JB: refactor after competition competitor creation is refactored
  return (
    <Dialog
      open={isModalOpen}
      onClose={isSubmitting ? null : cancel}
      maxWidth="sm"
      fullWidth
    >
      <Formik
        initialValues={getInitialValues({ kitSetId: modalParams.kitSetId })}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={getValidationSchema(t)}
      >
        {(formikProps) => {
          const data = kitSets ?? [];
          const value = formikProps.getFieldMeta(FIELD_NAMES.kitSetId).value;
          const selectedKitSet = data.find((kitSet) => kitSet.id === value);

          return (
            <Form noValidate>
              <DialogTitle className={classes.title}>
                {t('Team color set')}
              </DialogTitle>
              <DialogContent className={classes.content}>
                <Box pb={2}>
                  {isLoading ? (
                    <FullScreenSpinner />
                  ) : (
                    <>
                      <ModalFieldsBlock title={t('Team colors')}>
                        <Grid item xs={12}>
                          <SelectField
                            required
                            name={FIELD_NAMES.kitSetId}
                            data-qa={FIELD_NAMES.kitSetId}
                            label={t('Select team color set')}
                            options={data.map((item) => ({
                              label: item.title,
                              value: item.id,
                            }))}
                          />
                        </Grid>
                      </ModalFieldsBlock>
                      <Grid item xs={12}>
                        <Box mb={3}>
                          <Divider orientation="horizontal" />
                        </Box>
                      </Grid>
                      {KitTypes.map((kitType, index, array) => {
                        const label = getKitTitle(t, kitType);
                        const sportHasSpecialPlayerKit =
                          teamColorSetsHaveSpecialPlayer;

                        return (
                          <React.Fragment key={index}>
                            <ModalFieldsBlock title={label}>
                              <Grid container item /* alignItems="flex-end" */>
                                <Grid
                                  container
                                  item
                                  xs={
                                    sportHasSpecialPlayerKit ? 6 : 12
                                  } /* justifyContent="center" */
                                >
                                  <NamedValueColumn
                                    label={t('Team colors')}
                                    regular
                                    value={
                                      <PlayerKitPreviewNew
                                        disabled={!selectedKitSet}
                                        {...(selectedKitSet
                                          ? makePlayerKitPreviewProps(
                                              selectedKitSet,
                                              kitType,
                                              'regular',
                                            )
                                          : { colors: [null, null] })}
                                      />
                                    }
                                  />
                                </Grid>
                                {sportHasSpecialPlayerKit && (
                                  <Grid
                                    container
                                    item
                                    xs={6} /* justifyContent="center" */
                                  >
                                    <NamedValueColumn
                                      label={getSpecialPlayerKitLabel(t, sport)}
                                      regular
                                      value={
                                        <PlayerKitPreviewNew
                                          disabled={!selectedKitSet}
                                          {...(selectedKitSet
                                            ? makePlayerKitPreviewProps(
                                                selectedKitSet,
                                                kitType,
                                                'special',
                                              )
                                            : { colors: [null, null] })}
                                        />
                                      }
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </ModalFieldsBlock>
                            {index !== array.length - 1 && (
                              <Grid item xs={12}>
                                <Box mb={3}>
                                  <Divider orientation="horizontal" />
                                </Box>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                </Box>
              </DialogContent>
              <Divider />
              <DialogActions className={classes.actions}>
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Box display="flex">
                    <Box mr={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={cancel}
                        data-qa={testIds.TEAM_KITS_CANCEL_BUTTON}
                        disabled={isSubmitting}
                      >
                        {t('Cancel')}
                      </Button>
                    </Box>
                    <ButtonWithProgress
                      type="submit"
                      data-qa={testIds.TEAM_KITS_SUBMIT_BUTTON}
                      color="primary"
                      variant="contained"
                      isLoading={isSubmitting}
                      disabled={isLoadingOrSubmitting}
                    >
                      {t('Submit')}
                    </ButtonWithProgress>
                  </Box>
                </Box>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const loadingSelector = (ownProps: GlobalModalProps<OwnProps>) =>
  createLoadingSelector([
    competitorKitSetActions.getKitSetsRequest({
      clubId: ownProps.modalParams.clubId,
      competitorId: ownProps.modalParams.competitorId,
    }),
  ]);

const submittingSelector = (ownProps: GlobalModalProps<OwnProps>) =>
  createLoadingSelector([
    // TODO: JB: add argument
    competitorKitSetActions.updateCompetitorKitSetRequest({
      competitionId: ownProps.modalParams.competitionId,
      teamId: ownProps.modalParams.teamId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorKitSet: bindActionCreators(competitorKitSetActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  ownProps: GlobalModalProps<OwnProps>,
): StateProps => ({
  isLoading: loadingSelector(ownProps)(state),
  isSubmitting: submittingSelector(ownProps)(state),
  kitSets: getKitSets(state, {
    competitorId: ownProps.modalParams.competitorId,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageTeamKitsModal);
