import { put, call, Effect, cancelled } from 'redux-saga/effects';

import * as competitionsAPI from '@core/api/competitions';
import { Competition, CreateRolloverCompetitionPayload } from '@core/types';

import { actions } from '.';

export function* getCompetitions(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  try {
    yield put(actions.getCompetitionsRequest());
    const response = (yield call(
      competitionsAPI.getCompetitions,
      queryParams,
    )) as InferApi<typeof competitionsAPI.getCompetitions>;

    if (!response.error) {
      yield put(actions.getCompetitionsSuccess());
      yield put(
        actions.setCompetitions(response.response._embedded.competitions),
      );
    } else {
      yield put(actions.getCompetitionsFailure());
    }

    return response;
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.getCompetitionsSuccess());
    }
  }
}

export function* getRolloverCompetitions(
  queryParams?: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getRolloverCompetitionsRequest());
  const response = (yield call(
    competitionsAPI.getCompetitions,
    queryParams,
  )) as InferApi<typeof competitionsAPI.getCompetitions>;

  if (!response.error) {
    yield put(actions.getRolloverCompetitionsSuccess());
  } else {
    yield put(actions.getRolloverCompetitionsFailure());
  }

  return response;
}

export function* getCompetition(
  competitionId: number,
): Generator<Effect, any, any> {
  yield put(actions.getCompetitionRequest());
  const response = (yield call(
    competitionsAPI.getCompetition,
    competitionId,
  )) as InferApi<typeof competitionsAPI.getCompetition>;

  if (!response.error) {
    yield put(actions.getCompetitionSuccess());
    yield put(actions.setCompetition(response.response));
  } else {
    yield put(actions.getCompetitionFailure());
  }

  return response;
}

export function* createCompetition(
  data: Partial<Competition>,
): Generator<Effect, any, any> {
  yield put(actions.createCompetitionRequest());
  const response = yield call(competitionsAPI.createCompetition, data);

  if (!response.error) {
    yield put(actions.createCompetitionSuccess());
  } else {
    yield put(actions.createCompetitionFailure());
  }

  return response;
}

export function* createRolloverCompetition(
  competitionId: number,
  data: CreateRolloverCompetitionPayload,
): Generator<Effect, any, any> {
  yield put(actions.createRolloverCompetitionRequest());
  const response = yield call(
    competitionsAPI.createRolloverCompetition,
    competitionId,
    data,
  );

  if (!response.error) {
    yield put(actions.createRolloverCompetitionSuccess());
  } else {
    yield put(actions.createRolloverCompetitionFailure());
  }

  return response;
}

export function* updateCompetition(
  competitionId: number,
  data: Partial<Competition>,
): Generator<Effect, any, any> {
  yield put(actions.updateCompetitionRequest());
  const response = yield call(
    competitionsAPI.updateCompetition,
    competitionId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateCompetitionSuccess());
    yield call(getCompetition, competitionId);
  } else {
    yield put(actions.updateCompetitionFailure());
  }

  return response;
}
