import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getFilters,
  getGroupMatches,
  getGroupMatchesList,
  getMatchesSearchQuery,
  getSorting,
} from '@core/store/modules/tabs/competition-profile/selectors';
import {
  Competition,
  Group,
  MatchModel,
  Paginated,
  Filters,
  Sorting,
} from '@core/types';
import { State } from '@core/store';
import MatchesTable from '@core/components/match-table/matches-table';

import MatchesTabActions from './components/matches-tab-actions';
import { getCompetitionMatchFilterTabsList } from './helpers/get-filter-tabs-list';

interface OwnProps {
  competition: Competition;
  competitionId: number;
  stage: Group;
  stageId: number;
}

interface DispatchProps {
  actions: {
    // matches: typeof matchesActions;
    competitionProfile: typeof competitionProfileActions;
  };
}

interface StateProps {
  filters: Filters;
  isLoading: boolean;
  matches: Paginated<MatchModel['id']>;
  matchesList: Array<MatchModel>;
  query: string;
  sorting: Sorting;
}

type Props = OwnProps & DispatchProps & StateProps;

const MatchesTab = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    competitionId,
    filters,
    isLoading,
    matches,
    matchesList,
    query,
    sorting,
    stage,
  } = props;

  useEffect(() => {
    actions.competitionProfile.getMatchesByGroup({
      competitionId,
      groupId: stage?.id,
    });

    return () => {
      actions.competitionProfile.clearMatchesByGroup({
        competitionId,
        groupId: stage?.id,
      });
    };
  }, [stage]);

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Matches'),
      value: matches?.total,
    },
  ];

  const handleDataReload = ({ page }: { page: number }) =>
    actions.competitionProfile.getMatchesByGroup({
      competitionId,
      groupId: stage?.id,
      page,
    });

  const handleSearch = ({
    query: newQuery,
    page,
  }: {
    query: string;
    page: number;
  }) => {
    actions.competitionProfile.setMatchSearchQuery({
      competitionId,
      groupId: stage?.id,
      payload: newQuery,
    });

    actions.competitionProfile.searchMatchesByQuery({
      competitionId,
      groupId: stage?.id,
      page: 1,
    });
  };

  const handleFilterChange = (updatedFilters: Filters) => {
    actions.competitionProfile.setFilterParams({
      competitionId,
      groupId: stage?.id,
      payload: updatedFilters,
    });

    actions.competitionProfile.searchMatchesByQuery({
      competitionId,
      groupId: stage?.id,
      page: 1,
    });
  };

  const handleSorting = (newSorting: Sorting) => {
    actions.competitionProfile.setSorting({
      competitionId,
      groupId: stage?.id,
      payload: newSorting,
    });
    actions.competitionProfile.getMatchesByGroup({
      competitionId,
      groupId: stage?.id,
      page: 1,
    });
  };

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Box
        display="flex"
        flexDirection={isSmall ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <SummaryBlock isLoading={isLoading} values={getInfoBlocks()} />
        <MatchesTabActions
          stage={stage}
          competitionId={competitionId}
          isLoading={isLoading}
        />
      </Box>
      <Box mt={3}>
        <MatchesTable
          competitionId={competitionId}
          handleSearch={handleSearch}
          handleSorting={handleSorting}
          isLoading={isLoading}
          matches={matches}
          matchesList={matchesList}
          onReload={handleDataReload}
          query={query}
          sorting={sorting}
          stage={stage}
          tableFilter={{
            tabsList: getCompetitionMatchFilterTabsList({
              competitionId,
              stage,
            }),
            onSave: handleFilterChange,
            initialFilters: filters,
          }}
        />
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
    // matches: bindActionCreators(matchesActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const stageId = ownProps?.stage?.id;
  const competitionId = ownProps?.competitionId;
  const loadingSelector = createLoadingSelector([
    {
      actionName: competitionProfileActions.getMatchesByGroupRequest.toString(),
      id: stageId,
    },
  ]);

  return {
    matches: getGroupMatches(state, { competitionId, groupId: stageId }),
    matchesList: getGroupMatchesList(state, {
      competitionId,
      groupId: stageId,
    }),
    isLoading: loadingSelector(state),
    query: getMatchesSearchQuery(state, { competitionId, groupId: stageId }),
    filters: getFilters(state, { competitionId, groupId: stageId }),
    sorting: getSorting(state, { competitionId, groupId: stageId }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchesTab);
