import { createAction } from 'redux-actions';

const NAMESPACE = 'users';

const refreshUserSession = createAction<void>(
  `${NAMESPACE}/REFRESH_USER_SESSION`,
);
const refreshUserSessionSuccess = createAction<void>(
  `${NAMESPACE}/REFRESH_USER_SESSION_SUCCESS`,
);
const refreshUserSessionFailure = createAction<void>(
  `${NAMESPACE}/REFRESH_USER_SESSION_FAILURE`,
);
const refreshUserSessionRequest = createAction<void>(
  `${NAMESPACE}/REFRESH_USER_SESSION_REQUEST`,
);

export default {
  refreshUserSession,
  refreshUserSessionRequest,
  refreshUserSessionSuccess,
  refreshUserSessionFailure,
};
