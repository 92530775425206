import { darken, alpha, lighten } from '@mui/material';
import { Components } from '@mui/material/styles/components';

import palette from '../palette';
import {
  latoLatinLight,
  latoLatinRegular,
  latoLatinMedium,
  latoLatinBold,
} from '../typography/font-faces';

const overrides: Components = {
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: `
      ${latoLatinLight}
      ${latoLatinRegular}
      ${latoLatinMedium}
      ${latoLatinBold}
    `,
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        '&$contained': {
          background: palette.common.white,
        },
        '&$containedPrimary': {
          background: palette.primary.main,
        },
        '&$containedSecondary': {
          background: palette.secondary.main,
          color: palette.common.white,
          '&:hover': {
            background: palette.secondary.light,
          },
          '&$disabled': {
            background: palette.primary.light,
            color: palette.primary.dark,
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        width: 'auto',
        margin: '0 .5rem',
        borderRadius: '.25rem',
        '&.Mui-selected': {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
        },
        '&.Mui-selected:hover': {
          backgroundColor: darken(palette.primary.main, 0.15),
        },
      },
      button: {
        '&:hover': {
          backgroundColor: alpha(palette.primary.light, 0.15),
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 24,
        '&.without-cursor:hover:not(.Mui-disabled)': {
          cursor: 'auto',
        },
      },
      content: {
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      expandIconWrapper: {
        marginTop: -24,
        marginBottom: -24,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '8px 24px 24px',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'rgb(0, 0, 0, 0.54)',
        background: '#F7F7F7 !important',
      },
      body: {
        fontSize: 13,
        '&:empty::after': {
          content: '"-"',
        },
      },
      root: {
        padding: 12,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow:
          '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: palette.primary.main,
          backgroundColor: lighten(palette.primary.main, 0.93),
        },
        '@media screen and (min-width: 600px)': {
          minWidth: 160,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        overflowY: 'visible',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '&+.MuiDialogContent-root': {
          paddingTop: '20px !important',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h6: {
        fontWeight: 700,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.secondary.main,
      },
    },
  },
};

export default overrides;
