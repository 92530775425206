import { createAction } from 'redux-actions';

import { NavigationTabProps } from '@core/types';

import {
  OPEN_TAB,
  OPEN_ROOT_TAB,
  REMOVE_TAB,
  UPDATE_ACTIVE_TAB_INDEX,
  CLEAR_ALL_TABS,
  SET_TOUCHED,
  SET_TABS,
  SET_TOUCHED_BY_TAB_ID,
  UPDATE_TAB_TITLE,
  SET_ALL_TABS,
} from './constants';

export const openTab = createAction(OPEN_TAB, (tab: NavigationTabProps) => tab);

export const openRootTab = createAction(
  OPEN_ROOT_TAB,
  (tab: NavigationTabProps) => tab,
);

export const removeTab = createAction(REMOVE_TAB, (tabId: any) => ({ tabId }));

export const updateActiveTabIndex = createAction(
  UPDATE_ACTIVE_TAB_INDEX,
  (activeTabIndex: number) => activeTabIndex,
);

export const clearAllTabs = createAction(CLEAR_ALL_TABS);

export const setTouched = createAction(
  SET_TOUCHED,
  (isTouched: boolean, index: number) => ({ isTouched, index }),
);

export const setTouchedByTabId = createAction(
  SET_TOUCHED_BY_TAB_ID,
  (isTouched: boolean, tabId: string) => ({
    isTouched,
    tabId,
  }),
);

export const setTabs = createAction(SET_TABS);

export const updateTabTitle = createAction<{ tabId: string; title: string }>(
  UPDATE_TAB_TITLE,
);

export const setAllTabs = createAction(SET_ALL_TABS);
