import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import { KitSet } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [kitsetId: string]: {
    kitSet: KitSet;
  };
}

type Payload = any;

interface Meta {
  kitSetId: number;
}

export const defaultState: DefaultState = {};

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setKitSet.toString()]: (state, { payload, meta }) =>
      assocPath([meta.kitSetId, 'kitSet'], payload, state),
    [actions.resetKitSetState.toString()]: (state, { payload }) =>
      dissoc(payload?.kitSetId, state),
  },
  defaultState,
);

export default reducer;
