import { createActions } from 'redux-actions';

import { KitSetList, UpdateCompetitorProps } from '@core/types';

import { NAMESPACE } from './constants';

export const actions = createActions(
  'GET_KIT_SETS',
  'GET_KIT_SETS_REQUEST',
  'GET_KIT_SETS_SUCCESS',
  'GET_KIT_SETS_FAILURE',
  'SET_KIT_SETS',
  'UPDATE_COMPETITOR_KIT_SET',
  'UPDATE_COMPETITOR_KIT_SET_REQUEST',
  'UPDATE_COMPETITOR_KIT_SET_SUCCESS',
  'UPDATE_COMPETITOR_KIT_SET_FAILURE',
  'RESET_COMPETITOR',
  { prefix: NAMESPACE },
) as unknown as {
  getKitSets: IdentityActionFunction<{ clubId: number; competitorId: number }>;
  getKitSetsRequest: RequestLifecycleAction<{
    clubId: number;
    competitorId: number;
  }>;
  getKitSetsSuccess: RequestLifecycleAction<{
    clubId: number;
    competitorId: number;
  }>;
  getKitSetsFailure: RequestLifecycleAction<{
    clubId: number;
    competitorId: number;
  }>;
  setKitSets: IdentityActionFunction<{
    competitorId: number;
    data: KitSetList;
  }>;

  updateCompetitorKitSet: IdentityActionFunction<
    { competitorId: number } & UpdateCompetitorProps
  >;
  updateCompetitorKitSetRequest: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;
  updateCompetitorKitSetSuccess: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;
  updateCompetitorKitSetFailure: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;

  resetCompetitor: IdentityActionFunction<{ competitorId: number }>;
};
