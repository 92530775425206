import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TeamListPopover from './team-list-popover';
import Draggable from './draggable';
import { UseSlots } from './use-slots';
import TeamCard from './team-card';
import TeamPlaceholder from './team-placeholder';
import { Slot } from './types';

type Props = {
  competitionId: number;
  state: ReturnType<UseSlots>;
  isEditable: boolean;
};

const SlotsTable = (props: Props) => {
  const { state, competitionId, isEditable } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentSlot, setCurrentSlot] = useState<Slot | null>(null);

  const handleClose = () => {
    setCurrentSlot(null);
    setAnchorEl(null);
  };

  const { slots, moveSlot, findSlot, updateSlot } = state;
  const { t } = useTranslation();

  const createRemoveHandler = (slot: Slot) => () => {
    updateSlot(slot.id, undefined);
  };

  const createPopulateHandler =
    (slot: Slot) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setCurrentSlot(slot);
    };

  const selectedCompetitorsIds = slots
    .filter((slot) => slot.competitor)
    .map((slot) => slot.competitor?.id);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: 100 }}>
              {t('Slot #')}
            </TableCell>
            <TableCell>{t('Team')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slots.map((slot, index) => (
            <TableRow key={slot.id}>
              <TableCell align="center" style={{ width: 100 }}>
                {`${index + 1}.`}
              </TableCell>
              <TableCell>
                <Draggable
                  id={slot.id}
                  move={moveSlot}
                  find={findSlot}
                  canDrag={
                    isEditable ? !!slot.competitor && slot.editable : false
                  }
                  canDrop={isEditable ? slot.editable : false}
                >
                  {slot.competitor ? (
                    <TeamCard
                      onRemove={
                        isEditable && slot.editable
                          ? createRemoveHandler(slot)
                          : undefined
                      }
                      competitor={slot.competitor}
                    />
                  ) : (
                    <TeamPlaceholder
                      title={`${t('Team')} ${index + 1}`}
                      onClick={
                        isEditable ? createPopulateHandler(slot) : undefined
                      }
                    />
                  )}
                </Draggable>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TeamListPopover
        competitionId={competitionId}
        popoverProps={{ open: Boolean(anchorEl), anchorEl }}
        updateSlot={updateSlot}
        slotId={currentSlot?.id}
        onClose={handleClose}
        selectedCompetitorsIds={selectedCompetitorsIds}
      />
    </>
  );
};

export default SlotsTable;
