import React from 'react';
import { isNil } from 'ramda';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Theme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDate } from '@core/helpers';
import { CustomerResponse } from '@core/types';
import { State } from '@core/store';

interface OwnProps {
  minPlayers?: number;
  maxPlayers?: number;
  earliestPlayerDateOfBirth?: string;
  latestPlayerRegistrationDate?: string;
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

type Props = OwnProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    boxShadow: 'none',
    color: `rgb(13, 60, 97)`,
    backgroundColor: `rgb(232, 244, 253)`,
  },
  summary: {
    padding: '6px 16px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    '&.Mui-expanded': {
      minHeight: '48px',
    },
  },
  icon: {
    color: theme.palette.info.main,
    fontSize: 22,
    opacity: 0.9,
  },
  list: {
    margin: 0,
  },
  details: {
    padding: theme.spacing(0, 2, 2, 2.5),
  },
}));

const PlayerListValidations = (props: Props) => {
  const {
    minPlayers,
    maxPlayers,
    earliestPlayerDateOfBirth,
    currentCustomer,
    latestPlayerRegistrationDate,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  if (
    isNil(minPlayers) &&
    isNil(maxPlayers) &&
    isNil(earliestPlayerDateOfBirth) &&
    isNil(latestPlayerRegistrationDate)
  )
    return <></>;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMore className={classes.icon} />}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" mr={1.5}>
            <InfoOutlinedIcon className={classes.icon} />
          </Box>
          <Grid>{t('Player list requirements')}</Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box pl={3.5} className={classes.list}>
          <Grid>
            {!isNil(minPlayers) &&
              !isNil(maxPlayers) &&
              `• ${t(
                'Allowed number of Players in Player list',
              )}: ${minPlayers} min - ${maxPlayers} max`}
          </Grid>
          <Grid>
            {!isNil(earliestPlayerDateOfBirth) &&
              `• ${t('Age restrictions')}: ${t('born after')} ${formatDate(
                currentCustomer?.dateFormat,
                earliestPlayerDateOfBirth,
              )}`}
          </Grid>
          <Grid>
            {!isNil(latestPlayerRegistrationDate) &&
              `• ${t('Player list submission deadline')}: ${formatDate(
                currentCustomer?.dateFormat,
                latestPlayerRegistrationDate,
              )}`}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(PlayerListValidations);
