import React, { useEffect } from 'react';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';

import { useGetRoles } from '@core/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { withLayout } from '@core/components/layout';
import MatchesTable from '@core/components/match-table';
import {
  Paginated,
  Filters,
  Sorting,
  UserRoleType,
  MatchModel,
  LayoutProps,
} from '@core/types';
import { actions as matchesActions } from '@core/store/modules/pages/matches';
import {
  getFilters,
  getMatches,
  getMatchesList,
  getSearchQuery,
  getSorting,
} from '@core/store/modules/pages/matches/selectors';
import { State } from '@core/store';

import { getMatchFilterTabsList } from './helpers/get-filter-tabs-list';

interface DispatchProps {
  actions: {
    matches: typeof matchesActions;
  };
}

interface StateProps {
  filters: Filters;
  isLoading: boolean;
  matches: Paginated<MatchModel['id']>;
  matchesList: Array<MatchModel>;
  query: string;
  sorting: Sorting;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const MatchesPage = (props: Props) => {
  const { t } = useTranslation();
  const { actions, filters, isLoading, matches, matchesList, query, sorting } =
    props;
  const userRoles = useGetRoles();

  useEffect(() => {
    actions.matches.getMatches({});

    return () => {
      actions.matches.resetMatches();
    };
  }, []);

  const handleDataReload = ({ page }: { page: number }) => {
    actions.matches.getMatches({ page });
  };

  const handleSearch = ({
    query: newQuery,
    page,
  }: {
    query: string;
    page: number;
  }) => {
    actions.matches.setSearchQuery(newQuery);
    actions.matches.searchByQuery({ page });
  };

  const handleFilterChange = (updatedFilters: Filters) => {
    actions.matches.setFilterParams(updatedFilters);
    actions.matches.getMatches({ page: 1 });
  };

  const handleSorting = (newSorting: Sorting) => {
    actions.matches.setSorting(newSorting);
    actions.matches.getMatches({ page: 1 });
  };

  let tableFilter = {
    tabsList: getMatchFilterTabsList(t),
    onSave: handleFilterChange,
    initialFilters: filters,
  };

  if (
    !(
      userRoles?.includes(UserRoleType.ROLE_COMPETITION_MANAGER) ||
      userRoles?.includes(UserRoleType.ROLE_SUPER_ADMIN)
    )
  ) {
    tableFilter = null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t('Matches')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MatchesTable
          handleSearch={handleSearch}
          handleSorting={handleSorting}
          isGlobal
          isLoading={isLoading}
          matches={matches}
          matchesList={matchesList}
          onReload={handleDataReload}
          query={query}
          sorting={sorting}
          tableFilter={tableFilter}
        />
      </Grid>
    </Grid>
  );
};

const getMatchesLoadingSelector = createLoadingSelector([
  matchesActions.getMatchesRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matches: bindActionCreators(matchesActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  filters: getFilters(state),
  isLoading: getMatchesLoadingSelector(state),
  matches: getMatches(state),
  matchesList: getMatchesList(state),
  query: getSearchQuery(state),
  sorting: getSorting(state),
});

export default compose<React.FunctionComponent<LayoutProps>>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(MatchesPage);
