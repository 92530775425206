import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Paper,
  Typography,
  Grid,
  Button,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { getSanctionType, getSanctionStatus } from '@core/helpers';
import { MatchModel, SanctionStatus } from '@core/types';
import { getCompetition } from '@volleyball/store/modules/tabs/impose-sanction/selectors';
import {
  SanctionType,
  OffenderType,
  DisciplinarySanction,
} from '@volleyball/types';
import { actions as sanctionActions } from '@volleyball/store/modules/tabs/impose-sanction';
import MatchSuspensionForm from './components/match-suspension-form';
import FinancialPenaltyForm from './components/financial-penalty-form';
import { getValidationSchema } from './helpers/validation-schema';
import {
  getDefaultInitialValues,
  MarchSuspensionFormValues,
  FinancialPenaltyFormValues,
} from './helpers/defaultValues';
import type { FormMode } from './constants';
import { ButtonWithProgress } from '@ui-components';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    position: 'relative',
    top: '-1px',
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
}));

function getPenalty(sanctionType: SanctionType, values: any) {
  return sanctionType === SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES
    ? Number(values?.numberOfMatches)
    : values.amount;
}

type OwnProps = {
  competitionId: number;
  sanctionType: SanctionType;
  tabId: string;
  sanction?: DisciplinarySanction;
};

type Props = OwnProps;

const ImposeSanction = (props: Props) => {
  const { competitionId, tabId, sanctionType, sanction } = props;
  const competition = useSelector(getCompetition(competitionId));
  const { t } = useTranslation();
  const classes = useStyles();
  const [match, setMatch] = useState<MatchModel>();
  const dispatch = useDispatch();
  const selectedSanctionType = sanctionType || sanction.sanctionType;

  useEffect(() => {
    setMatch(sanction?.match);
  }, []);

  function handleCancel() {
    dispatch({ type: tabsActions.removeTab.toString(), payload: { tabId } });
  }

  function handleSubmit(
    values: MarchSuspensionFormValues | FinancialPenaltyFormValues,
  ) {
    if (sanctionModeDate.mode === 'new') {
      submitNewSanction(values);
    } else {
      submitEditedSanction(values);
    }
  }

  function submitNewSanction(
    values: MarchSuspensionFormValues | FinancialPenaltyFormValues,
  ) {
    const data = {
      status: SanctionStatus.ACTIVE,
      sanctionType: selectedSanctionType,
      offenderType: values?.offenderType,
      penalty: sanctionTypeData.penalty(values),
      comment: values?.comment,
      offenderId:
        (values?.offenderType === OffenderType.PLAYER ? values?.player : '') ||
        (values?.offenderType === OffenderType.TEAM_OFFICIAL
          ? values?.teamOfficial
          : '') ||
        (values?.offenderType === OffenderType.TEAM
          ? values?.competitor.props.teamId
          : '') ||
        (values?.offenderType === OffenderType.MATCH_OFFICIAL
          ? values?.competitor
          : ''),
      matchId: match?.id,
      currency: values?.currency && values?.currency.toUpperCase(),
    };

    dispatch({
      type: sanctionActions.createSanction.toString(),
      payload: { competitionId, data, tabId },
    });
  }

  function submitEditedSanction(
    values: MarchSuspensionFormValues | FinancialPenaltyFormValues,
  ) {
    const data = {
      penalty: sanctionTypeData.penalty(values),
      comment: values?.comment,
      matchId: match?.id,
    };

    dispatch({
      type: sanctionActions.updateSanction.toString(),
      payload: { competitionId, sanctionId: sanction?.id, data, tabId },
    });
  }

  const getEditModeSummary = (
    currentSanction: DisciplinarySanction,
  ): SummaryBlockValues => [
    {
      label: t('Sanction'),
      value: getSanctionType(t, currentSanction.sanctionType),
    },
    {
      label: t('Id'),
      value: currentSanction.id,
    },
    {
      label: t('Last modified'),
    },
    {
      label: t('Modified by'),
    },
    {
      label: t('Status'),
      value: getSanctionStatus(t, currentSanction.status),
    },
  ];

  const sanctionModeDate = {
    mode: sanction ? 'edit' : 'new',
    title: sanction ? t('Edit sanction') : t('Impose sanction'),
    submitButton: sanction ? t('Save') : t('Impose'),
    summary: sanction
      ? getEditModeSummary(sanction)
      : ([
          {
            label: 'Sanction',
            value: getSanctionType(t, selectedSanctionType),
          },
        ] as SummaryBlockValues),
  };

  const sanctionTypeData = {
    initialValues: sanction
      ? getDefaultInitialValues(selectedSanctionType, competition, sanction)
      : getDefaultInitialValues(selectedSanctionType, competition),
    validationSchema: getValidationSchema(
      sanctionModeDate.mode as FormMode,
      selectedSanctionType,
      t,
    ),
    penalty: (values: any) => getPenalty(selectedSanctionType, values),
  };

  function handleMatchSelect(selectedMatch: MatchModel) {
    setMatch(selectedMatch);
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={sanctionTypeData.initialValues}
      validationSchema={sanctionTypeData.validationSchema}
    >
      <Form>
        <Paper style={{ padding: '24px 24px 68px 24px' }}>
          <Typography variant="h6">{sanctionModeDate.title}</Typography>
          <Box width="100%" mt={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
            >
              <SummaryBlock values={sanctionModeDate.summary} />
            </Box>
            <Grid className={classes.divider}>
              <Divider variant="fullWidth" />
            </Grid>
            <Box mt={3}>
              {selectedSanctionType ===
                SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES && (
                <MatchSuspensionForm
                  competition={competition}
                  match={match}
                  offenderType={sanction?.offenderType || OffenderType.PLAYER}
                  setMatch={handleMatchSelect}
                  mode={sanctionModeDate.mode as FormMode}
                />
              )}
              {selectedSanctionType === SanctionType.FINANCIAL_PENALTY && (
                <FinancialPenaltyForm
                  competition={competition}
                  match={match}
                  offenderType={sanction?.offenderType || OffenderType.PLAYER}
                  setMatch={handleMatchSelect}
                  mode={sanctionModeDate.mode as FormMode}
                />
              )}
            </Box>
          </Box>
        </Paper>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          style={{ paddingTop: 24 }}
        >
          <Grid md={1} item>
            <Button onClick={handleCancel} variant="outlined" fullWidth>
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid md={1} item>
            <ButtonWithProgress
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={
                !match &&
                selectedSanctionType !== SanctionType.FINANCIAL_PENALTY
              }
            >
              {sanctionModeDate.submitButton}
            </ButtonWithProgress>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default ImposeSanction;
