import { useSelector } from 'react-redux';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import ContactsAndManagers from '@core/business-components/contacts-and-managers';

type Props = {
  id: number;
};

const ContactsTab = ({ id }: Props) => {
  const competition = useSelector(getCompetitionDetailsById(id));

  return <ContactsAndManagers competition={competition} />;
};

export default ContactsTab;
