import { Competitor, StandingTableHeader } from '@core/types';

export enum ItemTypes {
  Slot = 'slot',
}

export type Slot = {
  competitor?: Competitor;
  displayOption?: StandingTableHeader;
  editable: boolean;
  id: string;
};

export type Slots = Array<Slot>;
