import { AutocompleteOption } from '@core/types';

export enum COMPETITOR_FIELDS {
  teamColorSetId = 'teamColorSetId',
  title = 'title',
  internationalTitle = 'internationalTitle',
  defaultVenueOption = 'defaultVenueOption',
  alternativeVenueOption = 'alternativeVenueOption',
}

export interface CompetitorFormValues {
  [COMPETITOR_FIELDS.teamColorSetId]: number;
  [COMPETITOR_FIELDS.title]: string;
  [COMPETITOR_FIELDS.internationalTitle]: string;
  [COMPETITOR_FIELDS.defaultVenueOption]: AutocompleteOption;
  [COMPETITOR_FIELDS.alternativeVenueOption]: AutocompleteOption;
}
