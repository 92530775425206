import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';
import {
  GetCompetitorPayload,
  SetCompetitorPayload,
  UpdateCompetitorPayload,
  SetSubmitErrorsPayload,
  GetVenues,
  SetVenueList,
} from './types';

const getCompetitor = createAction<GetCompetitorPayload>(
  `${NAMESPACE}/GET_COMPETITOR`,
);
const getCompetitorRequest = createAction<GetCompetitorPayload>(
  `${NAMESPACE}/GET_COMPETITOR_REQUEST`,
);
const getCompetitorSuccess = createAction<GetCompetitorPayload>(
  `${NAMESPACE}/GET_COMPETITOR_SUCCESS`,
);
const getCompetitorFailure = createAction<GetCompetitorPayload>(
  `${NAMESPACE}/GET_COMPETITOR_FAILURE`,
);
const setCompetitor = createAction<SetCompetitorPayload>(
  `${NAMESPACE}/SET_COMPETITOR`,
);

const updateCompetitor = createAction<UpdateCompetitorPayload>(
  `${NAMESPACE}/UPDATE_COMPETITOR`,
);
const updateCompetitorRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITOR_REQUEST`,
);
const updateCompetitorSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITOR_SUCCESS`,
);
const updateCompetitorFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITOR_FAILURE`,
);

const resetCompetitor = createAction<{ competitorId: number }>(
  `${NAMESPACE}/RESET_COMPETITOR`,
);

const setSubmitErrors = createAction<SetSubmitErrorsPayload>(
  `${NAMESPACE}/SET_SUBMIT_ERRORS`,
);

const searchDefaultVenues = createAction<GetVenues>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE`,
);
const searchDefaultVenuesRequest = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_REQUEST`,
);
const searchDefaultVenuesSuccess = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_SUCCESS`,
);
const searchDefaultVenuesFailure = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_FAILURE`,
);
const setDefaultVenuesList = createAction<SetVenueList>(
  `${NAMESPACE}/SET_DEFAULT_VENUES_LIST`,
);
const resetSearchDefaultVenues = createAction(
  `${NAMESPACE}/RESET_SEARCH_DEFAULT_VENUE`,
);
const getDefaultVenues = createAction<GetVenues>(
  `${NAMESPACE}/GET_DEFAULT_VENUES`,
);

const searchAlternativeVenues = createAction<GetVenues>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE`,
);
const searchAlternativeVenuesRequest = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_REQUEST`,
);
const searchAlternativeVenuesSuccess = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_SUCCESS`,
);
const searchAlternativeVenuesFailure = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_FAILURE`,
);
const setAlternativeVenuesList = createAction<SetVenueList>(
  `${NAMESPACE}/SET_ALTERNATIVE_VENUES_LIST`,
);
const resetSearchAlternativeVenues = createAction(
  `${NAMESPACE}/RESET_SEARCH_ALTERNATIVE_VENUE`,
);
const getAlternativeVenues = createAction<GetVenues>(
  `${NAMESPACE}/GET_ALTERNATIVE_VENUE`,
);

export const actions = {
  getCompetitor,
  getCompetitorRequest,
  getCompetitorSuccess,
  getCompetitorFailure,
  setCompetitor,

  updateCompetitor,
  updateCompetitorRequest,
  updateCompetitorSuccess,
  updateCompetitorFailure,

  searchDefaultVenues,
  searchDefaultVenuesRequest,
  searchDefaultVenuesSuccess,
  searchDefaultVenuesFailure,
  setDefaultVenuesList,
  resetSearchDefaultVenues,
  getDefaultVenues,

  searchAlternativeVenues,
  searchAlternativeVenuesRequest,
  searchAlternativeVenuesSuccess,
  searchAlternativeVenuesFailure,
  setAlternativeVenuesList,
  resetSearchAlternativeVenues,
  getAlternativeVenues,

  resetCompetitor,

  setSubmitErrors,
};
