import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { State } from '@core/store';
import { NAMESPACE as matchSetsListNamespace } from '@core/store/modules/api/match-set';
import { MatchSet } from '@core/types';

const getMatchSetsList = (state: State) =>
  pathOr(null, ['api', matchSetsListNamespace], state);
const getId = (state: State, id: number) => id;

export const getMatchSetsByMatchId = createSelector(
  getMatchSetsList,
  getId,
  (matchSets, id): Array<MatchSet> => {
    const values = pathOr(null, [id], matchSets);

    return values
      ? Object.values<MatchSet>(values).sort((a, b) => a.number - b.number)
      : null;
  },
);
