import { useState, type ReactNode } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  Divider,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FullScreenSpinner } from '@core/components';
import ExpansionPanelTitleComponent from './expansion-panel-title-component';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    position: 'relative',
  },
  summary: {
    padding: theme.spacing(0, 2),
    minHeight: `${theme.spacing(6)}px !important`,
  },
  details: {
    padding: theme.spacing(2),
  },
  expandIcon: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

type Props = AccordionProps & {
  title: ReactNode;
  tooltip: string;
  value: JSX.Element | string | number;
  error?: string;
  children: any;
  testId?: string;
  onChange?: (event: object, expanded: boolean) => void;
  showIcon?: boolean;
  isLoading?: boolean;
  unrestrictedLabel?: boolean;
  inputRef?: {
    current?: HTMLInputElement | null;
  };
};

const ExpansionPanelComponent = ({
  title,
  value,
  tooltip,
  error,
  children,
  testId,
  showIcon = true,
  isLoading,
  inputRef,
  unrestrictedLabel,
}: Props) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  const handleChange: AccordionProps['onChange'] = (_event, expanded) => {
    setExpanded(expanded);

    if (expanded && inputRef?.current) {
      setTimeout(() => {
        inputRef?.current.focus();
        inputRef?.current?.select();
      }, 0);
    }
  };

  const expansionIcon = showIcon && <ExpandMoreIcon />;

  return (
    <Grid data-qa={testId} className={classes.root}>
      <Accordion variant="outlined" onChange={handleChange}>
        <AccordionSummary
          expandIcon={expansionIcon}
          className={classes.summary}
          classes={{ expandIconWrapper: classes.expandIcon }}
        >
          <ExpansionPanelTitleComponent
            error={error}
            value={value}
            title={title}
            tooltipText={tooltip}
            hideValue={isExpanded}
            unrestrictedLabel={unrestrictedLabel}
          />
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          {isLoading ? <FullScreenSpinner /> : children}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ExpansionPanelComponent;
