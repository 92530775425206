import microMemoize from 'micro-memoize';
import { shallowEqual } from 'react-redux';
import { createSelectorCreator, lruMemoize } from '@reduxjs/toolkit';
import type { State } from '@core/store';

export const createCoreSelector = createSelectorCreator({
  memoize: lruMemoize,
  argsMemoize: microMemoize,
  memoizeOptions: {
    maxSize: 10,
    equalityCheck: shallowEqual,
    resultEqualityCheck: shallowEqual,
  },
  argsMemoizeOptions: {
    isEqual: shallowEqual,
    maxSize: 10,
  },
  devModeChecks: {
    identityFunctionCheck: 'never',
    inputStabilityCheck: 'always',
  },
}).withTypes<State>();
