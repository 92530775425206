import { put, call, Effect, SagaReturnType } from 'redux-saga/effects';
import * as groupsAPI from '@core/api/groups';
import { Group, GroupSettingsUpdate } from '@core/types';
import { actions } from '.';

export function* getGroups(
  competitionId: number,
): Generator<Effect, SagaReturnType<typeof groupsAPI.getGroups>, any> {
  yield put(actions.getGroupsRequest());
  const response: InferApi<typeof groupsAPI.getGroups> = yield call(
    groupsAPI.getGroups,
    competitionId,
  );

  if (!response.error) {
    yield put(actions.setGroups(response.response._embedded.groups));
    yield put(actions.getGroupsSuccess());
  } else {
    yield put(actions.getGroupsFailure());
  }

  return response;
}

export function* getGroup({
  groupId,
}: {
  groupId: number;
}): Generator<Effect, any, any> {
  yield put(actions.getGroupRequest());
  const response = (yield call(groupsAPI.getGroup, { groupId })) as InferApi<
    typeof groupsAPI.getGroup
  >;

  if (!response.error) {
    yield put(actions.setGroup(response.response));
    yield put(actions.getGroupSuccess());
  } else {
    yield put(actions.getGroupFailure());
  }

  return response;
}

export function* createGroup(
  competitionId: number,
  groupData: Partial<Group>,
): Generator<Effect, any, any> {
  yield put(actions.createGroupRequest());
  const response = (yield call(
    groupsAPI.createGroup,
    competitionId,
    groupData,
  )) as InferApi<typeof groupsAPI.createGroup>;

  if (!response.error) {
    yield put(actions.createGroupSuccess());
  } else {
    yield put(actions.createGroupFailure());
  }

  return response;
}

export function* updateGroup(
  groupId: number,
  groupData: Partial<Group>,
): Generator<Effect, any, any> {
  yield put(actions.updateGroupRequest());
  const response = (yield call(
    groupsAPI.updateGroup,
    groupId,
    groupData,
  )) as InferApi<typeof groupsAPI.updateGroup>;

  if (!response.error) {
    yield put(actions.updateGroupSuccess());
  } else {
    yield put(actions.updateGroupFailure());
  }

  return response;
}

export function* deleteGroup(groupId: number): Generator<Effect, any, any> {
  yield put(actions.deleteGroupRequest());
  const response = (yield call(groupsAPI.deleteGroup, groupId)) as InferApi<
    typeof groupsAPI.deleteGroup
  >;

  if (!response.error) {
    yield put(actions.deleteGroupSuccess());
  } else {
    yield put(actions.deleteGroupFailure());
  }

  return response;
}

export function* updateGroupSettings(
  groupId: number,
  groupSettings: GroupSettingsUpdate,
): Generator<Effect, any, any> {
  yield put(actions.updateGroupSettingsRequest());
  const response = (yield call(
    groupsAPI.updateGroupSettings,
    groupId,
    groupSettings,
  )) as InferApi<typeof groupsAPI.updateGroupSettings>;

  if (!response.error) {
    yield put(actions.updateGroupSettingsSuccess());
  } else {
    yield put(actions.updateGroupSettingsFailure());
  }

  return response;
}
