import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { object, string, number } from 'yup';
import { times, mergeAll, isNil, flatten, last } from 'ramda';
import { validateStringAgainstDayjsFormat } from '@core/helpers';
import { CompetitionSettings } from '@volleyball/types';

export const DEFAULT_PERIOD_TIME = 45;
export const DEFAULT_EXTRA_TIME = 15;

export enum DURATION_PERIOD_TYPES {
  REGULAR = 'REGULAR',
  EXTRATIME = 'EXTRATIME',
  PENALTY_SHOOT_OUT = 'PENALTY_SHOOT_OUT',
}

export enum DURATION_MAIN_FIELDS {
  periodNumber = 'periodNumber',
  startTime = 'startTime',
  endTime = 'endTime',
}

export enum NOT_MUTABLE_FIELDS {
  duration = 'duration',
  stoppageTime = 'stoppageTimeDuration',
}

export enum ATTENDANCE_MAIN_FIELDS {
  attendance = 'attendance',
}

export type AttendanceFormValues = {
  [ATTENDANCE_MAIN_FIELDS.attendance]: null | string | number;
};

export type FormValues = {
  REGULAR_1_startTime: null | string;
  REGULAR_1_endTime: null | string;
  REGULAR_1_duration: number;
  REGULAR_1_stoppageTimeDuration: number;
  REGULAR_2_startTime: null | string;
  REGULAR_2_endTime: null | string;
  REGULAR_2_duration: number;
  REGULAR_2_stoppageTimeDuration: number;
  EXTRATIME_1_startTime: null | string;
  EXTRATIME_1_endTime: null | string;
  EXTRATIME_1_duration: number;
  EXTRATIME_1_stoppageTimeDuration: number;
  EXTRATIME_2_startTime: null | string;
  EXTRATIME_2_endTime: null | string;
  EXTRATIME_2_duration: number;
  EXTRATIME_2_stoppageTimeDuration: number;
  PENALTY_SHOOT_OUT_1_startTime: null | string;
  PENALTY_SHOOT_OUT_1_endTime: null | string;
};

export const getDefaultValues = (
  competitionSettings: CompetitionSettings,
): FormValues & AttendanceFormValues => ({
  REGULAR_1_startTime: null,
  REGULAR_1_endTime: null,
  REGULAR_1_duration: competitionSettings.periodDuration || DEFAULT_PERIOD_TIME,
  REGULAR_1_stoppageTimeDuration: 0,
  REGULAR_2_startTime: null,
  REGULAR_2_endTime: null,
  REGULAR_2_duration: competitionSettings.periodDuration || DEFAULT_PERIOD_TIME,
  REGULAR_2_stoppageTimeDuration: 0,
  EXTRATIME_1_startTime: null,
  EXTRATIME_1_endTime: null,
  EXTRATIME_1_duration:
    competitionSettings.periodDurationInExtraTime || DEFAULT_EXTRA_TIME,
  EXTRATIME_1_stoppageTimeDuration: 0,
  EXTRATIME_2_startTime: null,
  EXTRATIME_2_endTime: null,
  EXTRATIME_2_duration:
    competitionSettings.periodDurationInExtraTime || DEFAULT_EXTRA_TIME,
  EXTRATIME_2_stoppageTimeDuration: 0,
  PENALTY_SHOOT_OUT_1_startTime: null,
  PENALTY_SHOOT_OUT_1_endTime: null,
  [ATTENDANCE_MAIN_FIELDS.attendance]: 0,
});

export const getPeriodName = (
  type: DURATION_PERIOD_TYPES,
  index = 0,
  total = 0,
) => {
  switch (type) {
    case DURATION_PERIOD_TYPES.REGULAR:
      if (index === 0 && total === 1) {
        return `Full time`;
      } else if (index === 0 && total === 2) {
        return `First Half`;
      } else if (index === 1 && total === 2) {
        return `Second Half`;
      } else {
        return `Period ${index + 1}`;
      }
    case DURATION_PERIOD_TYPES.EXTRATIME:
      if (index === 0 && total === 1) {
        return `Extra time`;
      } else if (index === 0 && total === 2) {
        return `Extra Time First Half`;
      } else if (index === 1 && total === 2) {
        return `Extra Time Second Half`;
      } else {
        return `Extra time ${index + 1}`;
      }

    case DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT:
      return 'Penalty Shoot Out';
  }

  return `${type} ${index}`;
};

export const getDefaultKeysDynamic = ({
  numberOfPeriods,
  numberOfPeriodsInExtraTime,
  penaltyShootOut,
}: CompetitionSettings): Array<string> => {
  return [
    ...times((index) => {
      return `${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}`;
    }, numberOfPeriods),
    ...times((index) => {
      return `${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}`;
    }, numberOfPeriodsInExtraTime),
    ...(penaltyShootOut
      ? [`${DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT}_1`]
      : []),
  ];
};

export const getDefaultValuesDynamic = ({
  periodDuration,
  numberOfPeriods,
  periodDurationInExtraTime,
  numberOfPeriodsInExtraTime,
  penaltyShootOut,
}: CompetitionSettings): FormValues & AttendanceFormValues => {
  const regularTimes = times((index) => {
    return {
      [`${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}_startTime`]: null,
      [`${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}_startTime`]: null,
      [`${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}_endTime`]: null,
      [`${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}_duration`]:
        periodDuration || DEFAULT_PERIOD_TIME,
      [`${DURATION_PERIOD_TYPES.REGULAR}_${index + 1}_stoppageTimeDuration`]: 0,
    };
  }, numberOfPeriods);

  const extraTimes = times((index) => {
    return {
      [`${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}_startTime`]: null,
      [`${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}_startTime`]: null,
      [`${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}_endTime`]: null,
      [`${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}_duration`]:
        periodDurationInExtraTime || DEFAULT_EXTRA_TIME,
      [`${DURATION_PERIOD_TYPES.EXTRATIME}_${index + 1}_stoppageTimeDuration`]: 0,
    };
  }, numberOfPeriodsInExtraTime);

  const penaltyTimes = penaltyShootOut
    ? {
        PENALTY_SHOOT_OUT_1_startTime: null,
        PENALTY_SHOOT_OUT_1_endTime: null,
      }
    : {};

  const merged = mergeAll([
    mergeAll(regularTimes),
    mergeAll(extraTimes),
    penaltyTimes,
    { [ATTENDANCE_MAIN_FIELDS.attendance]: 0 },
  ]);

  return merged as FormValues & AttendanceFormValues;
};

export const getValidationSchema = (t: TFunction) => {
  const validateStartTime = (endTimeLabel: string, prevEndTimeLabel?: string) =>
    string()
      .nullable()
      .test('is-valid-time-format', t('Invalid time'), (value) =>
        validateStringAgainstDayjsFormat(value, 'HH:mm', true),
      )
      .test(
        'is-less',
        `${t('Start Time')} should be earlier than ${t('End Time')}`,
        function (value) {
          const { [endTimeLabel]: end } = this.parent;
          const current = dayjs(value, 'HH:mm');

          return (
            !end ||
            (end && current.isBefore(dayjs(end, 'HH:mm'))) ||
            current.isAfter(dayjs('23:15', 'HH:mm'))
          );
        },
      )
      .test(
        'is-greater',
        `${t('Start Time')} should be later than previous ${t('End Time')}`,
        function (value) {
          if (prevEndTimeLabel) {
            const { [prevEndTimeLabel]: end } = this.parent;
            const current = dayjs(value, 'HH:mm');

            const validate =
              !end ||
              dayjs(end, 'HH:mm').isAfter(dayjs('23:45', 'HH:mm')) ||
              !(value && end) ||
              (end && current.isAfter(dayjs(end, 'HH:mm')));

            return validate;
          }

          return true;
        },
      );
  const validateEndTime = (label: string) =>
    string()
      .nullable()
      .test('is-valid-time-format', t('Invalid time'), (value) =>
        validateStringAgainstDayjsFormat(value, 'HH:mm', true),
      )
      .test(
        'is-greater',
        `${t('End Time')} should be later than ${t('Start Time')}`,
        function (value) {
          const { [label]: start } = this.parent;

          return (
            (!value && !start) ||
            dayjs(start, 'HH:mm').isAfter(dayjs('23:15', 'HH:mm')) ||
            (start && dayjs(value, 'HH:mm').isAfter(dayjs(start, 'HH:mm')))
          );
        },
      );

  return object().shape({
    REGULAR_1_startTime: validateStartTime('REGULAR_1_endTime'),
    REGULAR_2_startTime: validateStartTime(
      'REGULAR_2_endTime',
      'REGULAR_1_endTime',
    ),
    EXTRATIME_1_startTime: validateStartTime(
      'EXTRATIME_1_endTime',
      'REGULAR_2_endTime',
    ),
    EXTRATIME_2_startTime: validateStartTime(
      'EXTRATIME_2_endTime',
      'EXTRATIME_1_endTime',
    ),
    PENALTY_SHOOT_OUT_1_startTime: validateStartTime(
      'PENALTY_SHOOT_OUT_1_endTime',
      'EXTRATIME_2_endTime',
    ),

    REGULAR_1_endTime: validateEndTime('REGULAR_1_startTime'),
    REGULAR_2_endTime: validateEndTime('REGULAR_2_startTime'),
    EXTRATIME_1_endTime: validateEndTime('EXTRATIME_1_startTime'),
    EXTRATIME_2_endTime: validateEndTime('EXTRATIME_2_startTime'),
    PENALTY_SHOOT_OUT_1_endTime: validateEndTime(
      'PENALTY_SHOOT_OUT_1_startTime',
    ),
    [ATTENDANCE_MAIN_FIELDS.attendance]: number()
      .moreThan(-1, t('Has to be 0 or higher.'))
      .integer(),
  });
};

export const getValidationSchemaDynamic = (
  t: TFunction,
  competitionSettings: CompetitionSettings,
) => {
  const { periodDuration, periodDurationInExtraTime } = competitionSettings;
  const validateTime = (
    label: string,
    activeKey: string,
    prevKeys: Array<string>,
  ) =>
    string()
      .nullable()
      .test('is-valid-time-format', t('Invalid time'), (value) =>
        validateStringAgainstDayjsFormat(value, 'HH:mm', true),
      )
      .test(
        'is-not-earlier-than-previous',
        'Should not be earlier than previous time',
        function (time) {
          if (!time || !activeKey) {
            return true;
          }

          const prevTimes = flatten(
            prevKeys.map((key) => [
              this.parent[`${key}_${DURATION_MAIN_FIELDS.startTime}`],
              this.parent[`${key}_${DURATION_MAIN_FIELDS.endTime}`],
            ]),
          ).filter((item) => !isNil(item));

          if (
            label.includes(DURATION_MAIN_FIELDS.endTime) &&
            this.parent[`${activeKey}_${DURATION_MAIN_FIELDS.startTime}`]
          ) {
            prevTimes.push(
              this.parent[`${activeKey}_${DURATION_MAIN_FIELDS.startTime}`],
            );
          }

          if (!prevTimes?.length) {
            return true;
          }

          const lastTime = last(prevTimes);
          const minutesSinceLast = dayjs(time, 'HH:mm').diff(
            dayjs(lastTime, 'HH:mm'),
            'minute',
          );

          return minutesSinceLast >= 0;
        },
      );

  const validateDurationRegular = (
    labelStart: string,
    labelEnd: string,
    labelDuration: string,
  ) => {
    return string()
      .nullable()
      .test(
        'is-period-length-ok',
        `Period length is shorter than required`,
        function (duration) {
          const { [labelStart]: timeStart, [labelEnd]: timeEnd } = this.parent;
          const durationCalculated = parseInt(duration, 10);
          let durationRequired;

          if (labelDuration?.includes(DURATION_PERIOD_TYPES.REGULAR)) {
            durationRequired = periodDuration;
          } else if (labelDuration?.includes(DURATION_PERIOD_TYPES.EXTRATIME)) {
            durationRequired = periodDurationInExtraTime;
          }

          if (
            !timeStart ||
            !timeEnd ||
            !durationRequired ||
            isNil(durationCalculated)
          ) {
            return true;
          }

          const dateStart = dayjs(timeStart, 'HH:mm');
          const dateEnd = dayjs(timeEnd, 'HH:mm');
          const diff = dateEnd.diff(dateStart, 'minute');

          return diff >= durationRequired;
        },
      );
  };

  const keys = getDefaultKeysDynamic(competitionSettings);

  const initialShape = {
    [ATTENDANCE_MAIN_FIELDS.attendance]: number()
      .moreThan(-1, t('Has to be 0 or higher.'))
      .integer(),
  };

  const shape = keys.reduce((resultingShape, key, index, originalKeys) => {
    const prevKeys = originalKeys.slice(0, index);

    return {
      ...resultingShape,
      [`${key}_${DURATION_MAIN_FIELDS.startTime}`]: validateTime(
        `${key}_${DURATION_MAIN_FIELDS.startTime}`,
        key,
        prevKeys,
      ),
      [`${key}_${DURATION_MAIN_FIELDS.endTime}`]: validateTime(
        `${key}_${DURATION_MAIN_FIELDS.endTime}`,
        key,
        prevKeys,
      ),
      [`${key}_${NOT_MUTABLE_FIELDS.duration}`]: validateDurationRegular(
        `${key}_${DURATION_MAIN_FIELDS.startTime}`,
        `${key}_${DURATION_MAIN_FIELDS.endTime}`,
        `${key}_${NOT_MUTABLE_FIELDS.duration}`,
      ),
    };
  }, initialShape);

  return object().shape(shape);
};

export const addPrefix = (
  prefix: string,
  id: string | number,
  name: string,
): string => `${prefix}_${id}_${name}`;

export const removePrefix = (keyValuePair: {
  [key: string]: string | number;
}): Array<Array<string> | string | number> => {
  const name = Object.keys(keyValuePair)[0];
  const splitted = name.split('_');
  const field = splitted.splice(-1);
  const index = splitted.splice(-1);
  const period = splitted.join('_');
  let value = keyValuePair[name];
  if (DURATION_MAIN_FIELDS.periodNumber === field[0]) {
    value = value || 1;
  }

  return [[period, ...index, ...field].filter((item) => !!item), value];
};
