import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';

import { Tabs, TabPanel } from '@core/components';
import { MATCH_PROFILE_TEAMS_TABS } from '@core/pages/matches/tests/test-ids';
import { TabsProps, MatchCompetitorsResponse, MatchModel } from '@core/types';

import HomeCompetitorTab from './home-competitor-tab';
import AwayCompetitorTab from './away-competitor-tab';
import MatchLineupTabComponent from './match-lineup-tab-component';

interface OwnProps {
  matchId: number;
  match: MatchModel;
  competitors: MatchCompetitorsResponse;
}

type Props = OwnProps;

enum MATCH_SHEET_TABS {
  HOME_TEAM_TAB = 'HOME_TEAM_TAB',
  AWAY_TEAM_TAB = 'AWAY_TEAM_TAB',
}

const useStyles = makeStyles((theme: Theme) => ({
  selectedTab: {
    color: 'inherit !important',
  },
  buttonContainer: {
    paddingBottom: theme.spacing(4),
  },
}));

const MatchLineupTab = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { matchId, match, competitors } = props;

  const homeCompetitorTitle = match?.homeCompetitorTitle || '';
  const homeCompetitorInternationalTitle =
    match?.homeCompetitorInternationalTitle || '';
  const homeCompetitorLogoFileLink =
    match?._embedded?.homeCompetitor?.logoFileLink ||
    match?._embedded?.homeCompetitor?.clubLogoFileLink;
  const awayCompetitorTitle = match?.awayCompetitorTitle || '';
  const awayCompetitorInternationalTitle =
    match?.awayCompetitorInternationalTitle || '';
  const awayCompetitorLogoFileLink =
    match?._embedded?.awayCompetitor?.logoFileLink ||
    match?._embedded?.awayCompetitor?.clubLogoFileLink;
  const canViewHomeTeam = match?._links?.homeCompetitor;
  const canViewAwayTeam = match?._links?.awayCompetitor;
  const initialTab = MATCH_SHEET_TABS.HOME_TEAM_TAB;

  const tabs: TabsProps = [
    {
      value: MATCH_SHEET_TABS.HOME_TEAM_TAB,
      component: HomeCompetitorTab,
      label: (
        <MatchLineupTabComponent
          competitorTitle={homeCompetitorTitle}
          competitorInternationalTitle={homeCompetitorInternationalTitle}
          competitorLogoFileLink={homeCompetitorLogoFileLink}
        />
      ),
      locked: !canViewHomeTeam,
      tooltipText:
        !canViewHomeTeam &&
        t(
          'Opponent’s Match Sheet will be available after the Match Sheet submission deadline',
        ),
    },
    {
      value: MATCH_SHEET_TABS.AWAY_TEAM_TAB,
      component: AwayCompetitorTab,
      label: (
        <MatchLineupTabComponent
          competitorTitle={awayCompetitorTitle}
          competitorInternationalTitle={awayCompetitorInternationalTitle}
          competitorLogoFileLink={awayCompetitorLogoFileLink}
        />
      ),
      locked: !canViewAwayTeam,
      tooltipText:
        !canViewAwayTeam &&
        t(
          'Opponent’s Match Sheet will be available after the Match Sheet submission deadline',
        ),
    },
  ];

  return (
    <Grid>
      <Tabs
        tabs={tabs}
        fullWidth
        variant="fullWidth"
        styles={{
          selectedTab: classes.selectedTab,
        }}
        testId={MATCH_PROFILE_TEAMS_TABS}
        initialTab={initialTab}
      >
        {(currentTab) => {
          const side =
            currentTab === MATCH_SHEET_TABS.HOME_TEAM_TAB
              ? 'homeMatchCompetitor'
              : 'awayMatchCompetitor';
          const canManage =
            !!competitors?._embedded[side]?._links?.changePlayerList ||
            !!competitors?._embedded[side]?._links?.changeTeamOfficialList;

          return (
            <TabPanel
              currentTab={currentTab}
              tabs={tabs}
              props={{ matchId, canManage }}
            />
          );
        }}
      </Tabs>
    </Grid>
  );
};

export default MatchLineupTab;
