import { combineReducers } from 'redux';
import { NAMESPACE as groups } from '@core/store/modules/pages/create-stage/constants';
import groupsReducer from '@core/store/modules/pages/create-stage/reducer';
import { NAMESPACE as matches } from '@core/store/modules/pages/matches';
import matchesReducer from '@core/store/modules/pages/matches/reducer';
import { NAMESPACE as appointments } from '@core/store/modules/pages/appointments';
import appointmentsReducer from '@core/store/modules/pages/appointments/reducer';
import { NAMESPACE as assignments } from '@core/store/modules/pages/assignments/constants';
import assignmentsReducer from '@core/store/modules/pages/assignments/reducer';
import refereeCategoriesReducer from './referee-categories/reducer';
import { NAMESPACE as refereeCategories } from './referee-categories/constants';

export const staticPages = {
  [refereeCategories]: refereeCategoriesReducer,
  [appointments]: appointmentsReducer,
  [matches]: matchesReducer,
  [groups]: groupsReducer,
  [assignments]: assignmentsReducer,
};

export default combineReducers(staticPages);
