// (value, 6) => 'value';
// (value, 2) => 'va...';

export const truncateString = (val: string, length: number) => {
  if (typeof val !== 'string' || val.length <= length) {
    return val;
  }
  const truncatedString = val.slice(0, length);

  return `${truncatedString}...`;
};
