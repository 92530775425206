import restService from '@core/api/rest-service';
import {
  MatchesListResponse,
  MatchOfficialsByPersonResponse,
  MatchOfficialsResponse,
} from '@core/types';

export const getMatchOfficialsList = (query = '') =>
  restService.get<MatchOfficialsResponse>(`/v1/matchOfficials${query}`);

export const getMatchOfficialsBySport = (sportId: number, query = '') =>
  restService.get<MatchOfficialsResponse>(
    `/v1/sports/${sportId}/matchOfficials${query}`,
  );

export const getMatchOfficialGroup = (officialId: number, query = '') =>
  restService.get<MatchOfficialsResponse>(
    `/v1/matchOfficials/${officialId}/matchOfficialGroups${query}`,
  );

export const getMatchOfficials = (queryParams?: URLSearchParams) =>
  restService.get<MatchOfficialsResponse>(`/v1/matchOfficials`, {
    params: queryParams,
  });

export const getMatchOfficialConflicts = (
  id: number,
  dateFilter: URLSearchParams,
) =>
  restService.get<MatchesListResponse>(`/v1/matches/matchOfficials/${id}`, {
    params: dateFilter,
  });

export const createMatchOfficial = (
  sportId: number,
  personId: number,
  roleId: Array<number>,
) =>
  restService.post<unknown>(`/v1/matchOfficials`, {
    sportId,
    personId,
    roleId,
  });

export const deleteMatchOfficial = (matchOfficialId: number) =>
  restService.delete(`/v1/matchOfficials/${matchOfficialId}`);

export const getMatchOfficialListByPerson = (personId: number) =>
  restService.get<MatchOfficialsByPersonResponse>(
    `/v1/persons/${personId}/matchOfficials`,
  );
