import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { FeatureToggles } from '@core/types/api';

import actions from './actions';

export interface DefaultState {
  featureToggles: FeatureToggles;
}

export const defaultState: DefaultState = {
  featureToggles: null,
};

type Payload = any;

export const initializedState = {};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setFeatureToggles.toString()]: (state, { payload }) =>
      assocPath(['featureToggles'], payload.payload, state),
  },
  defaultState,
);

export default reducer;
