import React from 'react';

import { PlayerKitColors } from '@core/types';

import { useStyles } from './player-kit-styles';

interface OwnProps {
  playerKitColors: PlayerKitColors;
  width?: string | number;
  height?: string | number;
  disabled?: boolean;
}

type Prop = OwnProps;

const PlayerKitPreview = (props: Prop) => {
  const { width, height, playerKitColors } = props;
  const classes = useStyles({ playerKitColors });

  return (
    <svg
      className={classes.root}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 185.1 398.7"
      width={width || '100'}
      height={height || '300'}
    >
      <ellipse
        className={classes.st0}
        cx="90.8"
        cy="368.1"
        rx="77.4"
        ry="14.6"
      />
      <g id="static-character">
        <path
          className={classes.st1}
          d="M72.9,90.8l-17.1,9.3c-1,0.5-1.9,1.2-2.7,2c10,8.4,22.8,13.5,36.9,13.5c14,0,26.7-5,36.7-13.3
            c-0.9-0.8-1.9-1.5-3-2l-18.3-9.2c-2.4-1.2-3.9-3.6-3.9-6.2v-7.2c0.5-0.6,1.1-1.3,1.7-2.2c2.5-3.5,4.4-7.4,5.7-11.5
            c2.3-0.7,4.1-2.9,4.1-5.5v-7.7c0-1.7-0.8-3.2-1.9-4.2V35.5c0,0,2.3-17.2-21.1-17.2S68.9,35.5,68.9,35.5v11.1
            c-1.2,1.1-1.9,2.6-1.9,4.2v7.7c0,2,1.1,3.8,2.7,4.8c1.9,8.3,6.9,14.3,6.9,14.3v7C76.5,87.2,75.1,89.5,72.9,90.8z"
        />
        <path
          className={classes.st1}
          d="M47.7,141.7c0.8,2.8,0,5.6,0,8.4C48,167,48,193.6,48.1,190c0-1.8-0.7-3-2-4c-8.4-6.5-12.3-15-11.9-25.7
            c0.3-6.5,0-13.1,0-19.7c0-1.4,0.3-6.3,0.3-6.3s7.5,3.6,11.3,5.3C46.9,140,47.3,140.8,47.7,141.7z"
        />
        <path
          className={classes.st1}
          d="M131.4,187c-0.8-5.5-0.1-11.1-0.2-16.7c-0.1-9.6,0-19.1,0.1-28.7c2.3-2.7,5.7-3.5,8.6-5.1
            c1.7-0.9,3.6-1.4,5.3-2.5c0.6-0.4-0.4,5.5-0.4,7.3c0,7.6,0.1,15.1,0,22.7C144.7,174.2,139.5,181.5,131.4,187z"
        />
        <path
          className={classes.st1}
          d="M94.4,265.8c9.9-0.3,19.8-0.4,29.7,0c-0.2,5.8-0.5,11.5-0.7,17.3c-0.1,3.1-0.4,6.2-0.5,9.4
            c0,1.3-0.5,1.9-1.9,1.9c-7.8,0-15.6,0-23.5,0.1c-2,0-2.1-1.1-2.2-2.6c-0.2-7.8-0.4-15.6-0.7-23.4C94.6,267.5,94.5,266.6,94.4,265.8
            z"
        />
        <path
          className={classes.st1}
          d="M55.6,265.7c10-0.2,19.9-0.5,29.9,0c-0.3,8.4-0.7,16.8-0.9,25.2c0,2.5-0.6,3.5-3.3,3.4
            c-7.2-0.1-14.5,0-21.7,0.1c-1.8,0-2.5-0.5-2.6-2.4c-0.4-7.7-0.9-15.4-1-23.1C55.9,267.8,56.1,266.7,55.6,265.7z"
        />
      </g>
      <g className={classes.primarySocks}>
        <path
          id="leg2"
          d="M84.8,294.3c1,0.6,0.7,1.5,0.7,2.4c-0.5,19.7-1.7,39.4-1.6,59.1c0,0.2-0.1,0.4-0.2,0.9
            c-3.8-5.8-8.9-8.3-15.6-7.1c-5.4,0.3-8.8,3.2-10.5,8.1c-0.4-9.1-0.6-18.2-0.8-27.3c-0.2-11.1-0.7-22.3-1.1-33.4
            c0-0.9-0.5-2,0.6-2.7C65.8,294.3,75.3,294.3,84.8,294.3z"
        />
        <path
          id="leg1"
          d="M95.1,294.3c-1,0.6-0.7,1.5-0.7,2.4c0.5,19.7,1.7,39.4,1.6,59.1c0,0.2,0.1,0.4,0.2,0.9
            c3.8-5.8,8.9-8.3,15.6-7.1c5.4,0.3,8.8,3.2,10.5,8.1c0.4-9.1,0.6-18.2,0.8-27.3c0.2-11.1,0.7-22.3,1.1-33.4c0-0.9,0.5-2-0.6-2.7
            C114.1,294.3,104.6,294.3,95.1,294.3z"
        />
      </g>
      <path
        className={classes.primaryShorts}
        d="M55.5,265.7c-2.1,0.2-2.9-0.7-2.9-2.9c-0.1-18.1-0.2-36.2-0.4-54.2c0-2.1,0.7-2.6,2.7-2.6
          c23.4,0.1,46.7,0.1,70.1,0.1c2.2,0,2.6,0.7,2.5,2.7c-0.2,17.9-0.3,35.8-0.4,53.7c0,2.3-0.6,3.5-3.1,3.3c-9.9-0.5-19.8-0.3-29.7,0
          c-2.6,0.2-3.3-1.1-3.3-3.5c0.1-6.1-0.1-12.1-0.2-18.2c0-0.9,0.6-2.7-0.8-2.6c-1.4,0-0.7,1.8-0.7,2.7c-0.2,6.1-0.5,12.1-0.5,18.2
          c0,2.5-0.8,3.7-3.4,3.4C75.4,265.2,65.4,265.5,55.5,265.7z"
      />
      <path
        className={classes.primaryJersey}
        d="M111.6,93.6c3.6,0,6.1,2.5,8.9,4.1c6.1,3.3,12.4,6,18.3,9.6c6.3,3.9,8.6,9.7,9,16.6
          c0.1,1.6-0.1,3.1,0,4.7c0.1,4.8-0.2,5.4-4.7,7.1c-3.2,1.1-6,3.2-9.3,4.3c-2,0.7-2.1,4.7-2.1,4.7l-0.1,28.7l0.2,16.7
          c0,0-0.2,9-0.3,13.4c0,1.8-0.3,2.9-2.6,2.9c-26.4,0-52.7,0-79.1,0c-2,0-1.6-1.5-1.9-2.5c-0.1-16.9-0.2-33.8-0.5-50.7
          c0-2.8,0.8-5.6,0-8.4c0-0.3,0-0.6,0.1-0.9l0.2-0.2l-0.2-0.1c-0.3-2.1-1.3-3.3-3.5-4.1c-3.5-1.3-6.8-3.2-10.2-4.8
          c-1.4-0.7-2-1.7-2-3.3c0-4-0.2-8,0.4-12c0.8-5.2,3.7-9,8-11.7c3.6-2.3,7.4-4.3,11.2-6.2c4.6-2.2,9.2-4.3,13.4-7
          c0.8-0.5,1.6-0.6,2.5-0.6c1.9,2.3,7.5,5.9,7.8,6.2l0,0c4.6,3.3,9.2,6.6,13.8,9.9c0.8,0.6,1.2,0.4,1.8-0.1c4.8-3.5,9.7-7,14.5-10.5
          l0,0C105.1,99.3,110.4,95.9,111.6,93.6z"
      />
      <g id="static-shoes">
        <g>
          <g>
            <path
              className={classes.st3}
              d="M57,364.7c0-1.2,0-2.4,0-3.6c0.1-6.3,3.3-10,9.4-11.6c3.7-0.9,7.2-0.3,10.7,0.8c5.8,1.9,8.2,6.9,6.9,14.3
                C74.9,364,65.9,363.8,57,364.7z M76.9,356.5c-0.2-0.2-0.5-0.5-0.8-0.7c-1.7-1.1-9.4-1.2-11,0c-0.4,0.3-0.9,0.7-0.6,1.2
                c0.4,0.8,1.1,0.3,1.6,0.1c3.2-1.1,6.3-1.3,9.4,0.1C76.1,357.4,76.7,357.7,76.9,356.5z"
            />
            <path
              d="M57,364.7c9-0.8,18-0.7,26.9-0.2c0.2,1.2-0.6,1.7-1.5,1.9c-1.2,0.3-2,0.8-1.9,2.2c0.1,1.1-0.6,1.3-1.6,1.3
                c-0.9,0-1.7-0.2-1.7-1.3c0.1-1.9-1.1-2-2.5-2c-1.5,0-2.8,0-2.8,2.1c0,0.9-0.6,1.1-1.5,1.1s-1.6-0.3-1.6-1.3c0-1.9-1.1-2-2.6-1.9
                c-1.3,0-2.4,0.1-2.4,1.9c0,1.3-0.9,1.3-1.8,1.4c-1.1,0-1.5-0.5-1.4-1.4c0.1-1.3-0.6-1.7-1.7-1.9C57.9,366.4,57,366,57,364.7z"
            />
          </g>
          <g>
            <path
              className={classes.st4}
              d="M76.9,356.5c-0.2,1.1-0.8,0.9-1.4,0.6c-3.1-1.4-6.3-1.1-9.4-0.1c-0.5,0.2-1.2,0.7-1.6-0.1
                c-0.3-0.6,0.2-0.9,0.6-1.2c1.6-1.1,9.3-1.1,11,0C76.4,356,76.7,356.3,76.9,356.5z"
            />
            <path
              className={classes.st4}
              d="M76.9,353.6c-0.2,1.1-0.8,0.9-1.4,0.6c-3.1-1.4-6.3-1.1-9.4-0.1c-0.5,0.2-1.2,0.7-1.6-0.1
                c-0.3-0.6,0.2-0.9,0.6-1.2c1.6-1.1,9.3-1.1,11,0C76.4,353.1,76.7,353.4,76.9,353.6z"
            />
          </g>
        </g>
        <g id="shoe">
          <g>
            <path
              className={classes.st3}
              d="M123,364.7c0-1.2,0-2.4,0-3.6c-0.1-6.3-3.3-10-9.4-11.6c-3.7-0.9-7.2-0.3-10.7,0.8
                c-5.8,1.9-8.2,6.9-6.9,14.3C105,364,114,363.8,123,364.7z M103,356.5c0.2-0.2,0.5-0.5,0.8-0.7c1.7-1.1,9.4-1.2,11,0
                c0.4,0.3,0.9,0.7,0.6,1.2c-0.4,0.8-1.1,0.3-1.6,0.1c-3.2-1.1-6.3-1.3-9.4,0.1C103.9,357.4,103.2,357.7,103,356.5z"
            />
            <path
              d="M123,364.7c-9-0.8-18-0.7-26.9-0.2c-0.2,1.2,0.6,1.7,1.5,1.9c1.2,0.3,2,0.8,1.9,2.2c-0.1,1.1,0.6,1.3,1.6,1.3
                c0.9,0,1.7-0.2,1.7-1.3c-0.1-1.9,1.1-2,2.5-2c1.5,0,2.8,0,2.8,2.1c0,0.9,0.6,1.1,1.5,1.1s1.6-0.3,1.6-1.3c0-1.9,1.1-2,2.6-1.9
                c1.3,0,2.4,0.1,2.4,1.9c0,1.3,0.9,1.3,1.8,1.4c1.1,0,1.5-0.5,1.4-1.4c-0.1-1.3,0.6-1.7,1.7-1.9C122,366.4,122.9,366,123,364.7z"
            />
          </g>
          <g>
            <path
              className={classes.st4}
              d="M103,356.5c0.2,1.1,0.8,0.9,1.4,0.6c3.1-1.4,6.3-1.1,9.4-0.1c0.5,0.2,1.2,0.7,1.6-0.1
                c0.3-0.6-0.2-0.9-0.6-1.2c-1.6-1.1-9.3-1.1-11,0C103.5,356,103.3,356.3,103,356.5z"
            />
            <path
              className={classes.st4}
              d="M103,353.6c0.2,1.1,0.8,0.9,1.4,0.6c3.1-1.4,6.3-1.1,9.4-0.1c0.5,0.2,1.2,0.7,1.6-0.1
                c0.3-0.6-0.2-0.9-0.6-1.2c-1.6-1.1-9.3-1.1-11,0C103.5,353.1,103.3,353.4,103,353.6z"
            />
          </g>
        </g>
      </g>
      <g className={classes.singleHorizontalStripeSocks}>
        <path d="M56.4,315.3c0.2,5,0.3,10.1,0.4,15.1c0,0.1,0,0.2,0,0.3h27.5c0.2-5.1,0.4-10.2,0.6-15.3H56.4z" />
        <path d="M123.5,315.3H95c0.2,5.1,0.4,10.2,0.6,15.3h27.5c0-0.1,0-0.2,0-0.3C123.2,325.4,123.3,320.4,123.5,315.3z" />
      </g>
      <g className={classes.horizontallyDividedSocks}>
        <path
          d="M84.8,294.3c-9.5,0-19,0-28.5,0c-1.1,0.7-0.6,1.8-0.6,2.7c0.3,9.6,0.8,19.3,1,29h27.8c0.3-9.8,0.8-19.5,1-29.3
            C85.5,295.8,85.8,294.9,84.8,294.3z"
        />
        <path
          d="M123.6,294.3c-9.5,0-19,0-28.5,0c-1,0.6-0.7,1.5-0.7,2.4c0.2,9.8,0.7,19.5,1,29.3h27.8c0.2-9.7,0.6-19.4,1-29
            C124.2,296.1,124.7,295,123.6,294.3z"
        />
      </g>
      <g className={classes.singleVerticalStripeShorts}>
        <path
          d="M73.8,206v59.4c3.9,0,7.7,0.1,11.6,0.3c2.6,0.3,3.4-0.9,3.4-3.4c0-6.1,0.3-12.1,0.5-18.2
            c0-0.9-0.7-2.7,0.7-2.7c1.4-0.1,0.8,1.7,0.8,2.6c0.1,6.1,0.3,12.1,0.2,18.2c0,2.4,0.7,3.7,3.3,3.5c3.8-0.1,8.7-0.2,12.5-0.3
            l-1-59.4C95.1,206.1,84.5,206.1,73.8,206z"
        />
      </g>
      <g className={classes.verticallyDividedShorts}>
        <path
          d="M90,241.5c1.4-0.1,0.8,1.7,0.8,2.6c0.1,6.1,0.3,12.1,0.2,18.2c0,2.4,0.7,3.7,3.3,3.5c9.9-0.3,19.8-0.5,29.7,0
            c2.5,0.2,3.1-1,3.1-3.3c0.1-17.9,0.2-35.8,0.4-53.7c0.1-2-0.3-2.7-2.5-2.7c-11.8,0-23.5,0-35.2,0v35.4
            C89.9,241.5,89.9,241.5,90,241.5z"
        />
      </g>
      <g className={classes.verticalStripesJersey}>
        <path
          d="M75.1,100.1c-0.3-0.3-5.9-3.9-7.8-6.2c-0.9,0-1.7,0.1-2.5,0.6c-1.2,0.8-2.5,1.5-3.8,2.2v109.7
            c6.7,0,13.5,0,20.2,0V104.5C79.2,103,77.1,101.6,75.1,100.1z"
        />
        <path
          d="M111.6,93.6c-1.2,2.3-6.5,5.7-6.4,5.8c-2.2,1.6-4.5,3.3-6.8,4.9v102.1c6.7,0,13.5,0,20.2,0V96.5
            C116.5,95.1,114.4,93.6,111.6,93.6z"
        />
      </g>
      <g className={classes.horizontalStripesJersey}>
        <path
          d="M133.8,140c3.3-1.1,6.1-3.2,9.3-4.3c4.5-1.7,4.8-2.3,4.7-7.1c-0.1-1.6,0.1-3.1,0-4.7c-0.1-1.6-0.3-3.1-0.6-4.5
            h-115c0,0,0,0,0,0c-0.6,4-0.4,8-0.4,12c0,1.6,0.6,2.6,2,3.3c3.4,1.6,6.7,3.5,10.2,4.8c1.4,0.5,2.3,1.2,2.9,2.2h85.4
            C132.6,140.9,133.1,140.2,133.8,140z"
        />
        <path d="M131.6,173.4l0-13.6H47.5c0.1,6.7,0.2,13.4,0.2,20.1h83.9L131.6,173.4z" />
      </g>
      <path
        className={classes.singleVerticalStripeJersey}
        d="M105.2,99.4c-4.8,3.5-9.7,7-14.5,10.5c-0.6,0.5-1,0.7-1.8,0.1
          c-4.6-3.3-9.2-6.6-13.8-9.9c-0.1-0.1-0.6-0.4-1.3-0.9v107.2c10.7,0,21.3,0,32,0V98.9C105.4,99.2,105.2,99.4,105.2,99.4z"
      />
      <path
        className={classes.singleHorizontalStripeJersey}
        d="M31.8,131.4c0,1.6,0.6,2.6,2,3.3c3.4,1.6,6.7,3.5,10.2,4.8c2.2,0.8,3.2,2,3.5,4.1
          l0.2,0.1l-0.2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9c0.7,2.6,0.1,5.2,0,7.9h84.3l0-8c0,0,0.1-4,2.1-4.7c3.3-1.1,6.1-3.2,9.3-4.3
          c4.5-1.7,4.8-2.3,4.7-7.1c-0.1-1.6,0.1-3.1,0-4.7c-0.1-1.1-0.2-2.2-0.4-3.2H32C31.6,124.2,31.8,127.8,31.8,131.4z"
      />
      <g className={classes.chequeredJersey}>
        <rect
          x="57.5"
          y="120.3"
          className={classes.st5}
          width="21.5"
          height="21.5"
        />
        <rect
          x="57.5"
          y="163.4"
          className={classes.st5}
          width="21.5"
          height="21.5"
        />
        <path d="M57.5,184.9v21.5c7.2,0,14.3,0,21.5,0v-21.5H57.5z" />
        <path d="M121.8,184.9h-21.2v21.5c7.2,0,14.3,0,21.5,0v-21.5H121.8z" />
        <rect x="79" y="163.4" width="21.5" height="21.5" />
        <path d="M57.5,163.4h-10c0.1,7.2,0.2,14.3,0.2,21.5h9.8V163.4z" />
        <rect x="47" y="120.3" width="10.5" height="21.5" />
        <polygon points="121.8,163.4 121.8,184.9 122.1,184.9 131.7,184.9 131.6,173.4 131.6,163.4 122.1,163.4 	" />
        <rect x="57.5" y="141.9" width="21.5" height="21.5" />
        <polygon points="100.6,163.4 121.8,163.4 122.1,163.4 122.1,141.9 121.8,141.9 100.6,141.9 	" />
        <path d="M122.1,120.3L122.1,120.3l-0.3,0v21.5h0.3h10.2c0,0,0-0.1,0.1-0.1v-21.4H122.1z" />
        <path d="M57.5,120.3H79v-17.4c-1.3-0.9-2.6-1.9-3.9-2.8c-0.1-0.1-0.8-0.6-1.8-1.3H57.5V120.3z" />
        <path d="M121.8,120.3h0.3v0V98.8h-16.1c-0.5,0.3-0.8,0.6-0.7,0.6c-1.5,1.1-3.1,2.2-4.6,3.4v17.6H121.8z" />
        <rect x="79" y="120.3" width="21.5" height="21.5" />
      </g>
      <g className={classes.obliqueStripeLeftToRightJersey}>
        <path
          d="M67.3,93.9c-0.9,0-1.7,0.1-2.5,0.6c-4.2,2.7-8.8,4.8-13.4,7c-1.6,0.8-3.1,1.6-4.6,2.4l59.2,102.5
            c7.7,0,15.3,0,23,0c1.7,0,2.3-0.6,2.5-1.6l-64-110.8C67.4,94,67.3,93.9,67.3,93.9z"
        />
      </g>
      <g className={classes.obliqueStripeRightToLeftJersey}>
        <path
          d="M120.5,97.7c-2.6-1.5-4.9-3.7-8.1-4.1L48.1,205c0.1,0.7,0.4,1.4,1.7,1.4c8,0,15.9,0,23.8,0L132.8,104
            C128.7,101.8,124.6,99.9,120.5,97.7z"
        />
      </g>
      <g className={classes.verticallyDividedJersey}>
        <path
          d="M128.9,206.4c2.3,0,2.6-1.1,2.6-2.9c0.1-4.4,0.3-13.4,0.3-13.4l-0.2-16.7l0.1-28.7c0,0,0.1-4,2.1-4.7
            c3.3-1.1,6.1-3.2,9.3-4.3c4.5-1.7,4.8-2.3,4.7-7.1c-0.1-1.6,0.1-3.1,0-4.7c-0.4-6.9-2.7-12.7-9-16.6c-5.9-3.6-12.2-6.3-18.3-9.6
            c-2.8-1.6-5.3-4.1-8.9-4.1c-1.2,2.3-6.5,5.7-6.4,5.8c-4.8,3.5-9.7,7-14.5,10.5c-0.3,0.3-0.6,0.4-0.9,0.5v96
            C102.8,206.4,115.8,206.4,128.9,206.4z"
        />
      </g>
      <g className={classes.horizontallyDividedJersey}>
        <path
          d="M47.9,203.9c0.3,1-0.1,2.5,1.9,2.5c26.4,0,52.7,0,79.1,0c2.3,0,2.6-1.1,2.6-2.9c0.1-4.4,0.3-13.4,0.3-13.4
            l-0.2-16.7l0.1-19.3H47.4C47.7,170.7,47.8,187.3,47.9,203.9z"
        />
      </g>
      <g className={classes.verticalNarrowStripesJersey}>
        <path
          d="M51.4,101.5c-1.5,0.7-2.9,1.5-4.4,2.3V142c0.2,0.5,0.4,1,0.5,1.6l0.2,0.1l-0.2,0.2c-0.1,0.3-0.1,0.6-0.1,0.9
            c0.8,2.8,0,5.6,0,8.4c0.3,16.9,0.4,33.8,0.5,50.7c0.3,1-0.1,2.5,1.9,2.5c3.1,0,6.1,0,9.2,0V97.8C56.5,99.1,54,100.3,51.4,101.5z"
        />
        <path d="M75.1,100.1c-0.2-0.2-1.9-1.3-3.8-2.7v109c4,0,8,0,12,0V106C80.5,104,77.8,102.1,75.1,100.1z" />
        <path d="M105.2,99.4c-3.2,2.3-6.4,4.6-9.6,7v100c4,0,8,0,12,0V97.6C106.2,98.6,105.2,99.4,105.2,99.4z" />
        <path
          d="M120.5,97.7c-0.2-0.1-0.4-0.3-0.7-0.4v109.1c3,0,6,0,9.1,0c2.3,0,2.6-1.1,2.6-2.9c0.1-4.4,0.3-13.4,0.3-13.4
            l-0.2-16.7l0.1-28.7c0,0,0-0.5,0.1-1.1v-40.2C128.1,101.5,124.2,99.7,120.5,97.7z"
        />
      </g>
      <g className={classes.horizontalNarrowStripesJersey}>
        <path
          d="M51.7,101.5c4.6-2.2,9.2-4.3,13.4-7c0.7-0.5,1.4-0.6,2.2-0.6c0,0,0,0,0,0c-0.9,0-1.7,0.1-2.5,0.6
            c-4.2,2.7-8.8,4.8-13.4,7c-1.4,0.7-2.9,1.5-4.3,2.2l0.5-0.1C49,102.9,50.3,102.2,51.7,101.5z"
        />
        <path d="M48.1,187.7h83.7l-0.2-12.7H47.9C48,179.3,48,183.5,48.1,187.7z" />
        <path d="M47.7,153.2c0,2.5,0.1,4.9,0.1,7.4h83.9l0-12.7H48C48,149.7,47.7,151.4,47.7,153.2z" />
        <path
          d="M147.8,128.6c-0.1-1.6,0.1-3.1,0-4.7c-0.1-1.1-0.2-2.1-0.3-3.2H32c-0.4,3.6-0.2,7.1-0.2,10.7
            c0,0.8,0.2,1.5,0.5,2.1h114.7C147.8,132.5,147.9,131.1,147.8,128.6z"
        />
        <path
          d="M105.5,99.4c-3.2,2.3-6.4,4.6-9.5,6.9h41.2c-5.4-3.1-11.1-5.6-16.6-8.6c-2.7-1.6-5.2-4-8.6-4.1
            C110.6,95.9,105.4,99.3,105.5,99.4z"
        />
        <path
          d="M75.1,100.1c-0.3-0.3-5.9-3.9-7.8-6.2c-0.8,0-1.5,0.1-2.2,0.6c-4.2,2.7-8.8,4.8-13.4,7c-1.3,0.7-2.7,1.4-4,2.1
            l-0.5,0.1c-1.6,0.8-3.1,1.7-4.6,2.6h41.3C80.9,104.2,78,102.2,75.1,100.1z"
        />
      </g>
      <g id="static-shades">
        <path
          className={classes.st6}
          d="M89.8,79.6V18.2c-23.2,0.1-21,17.2-21,17.2v11.1c-1.2,1.1-1.9,2.6-1.9,4.2v7.7c0,2,1.1,3.8,2.7,4.8
            c1.9,8.3,6.9,14.3,6.9,14.3v7c0,2.6-1.4,4.9-3.6,6.1l-5.4,3l0,0c-0.9,0.1-1.7,0.1-2.5,0.6c-4.3,2.7-8.9,4.8-13.4,7
            c-3.8,1.9-7.6,3.9-11.2,6.2c-4.3,2.7-7.2,6.5-8,11.7c-0.6,4-0.4,8-0.4,12c0,1.5,0.5,2.6,2,3.3c0.2,0.1,0.5,0.2,0.7,0.4
            c-0.1,1.4-0.2,4.6-0.2,5.7c0,6.6,0.2,13.1,0,19.7c-0.4,10.7,3.5,19.2,11.9,25.7c0.8,0.6,1.3,1.3,1.6,2.1c0,5.3,0.1,10.5,0.1,15.8
            c0.3,1.1-0.1,2.6,1.9,2.5c1,0,2.1,0,3.1,0c-0.6,0.4-0.8,1-0.8,2.2c0.2,18.1,0.3,36.2,0.4,54.2c0,2.2,0.8,3.1,2.9,2.9h0.1
            c0.6,1,0.4,2.1,0.4,3.2c0.1,7.7,0.5,15.4,1,23.1c0.1,1.4,0.5,2,1.3,2.3c-0.7,0-1.3,0-2,0c-1,0.7-0.6,1.8-0.6,2.7
            c0.4,11.1,1,22.3,1.1,33.4c0.1,8.8,0.3,17.6,0.7,26.3c-0.4,1.3-0.6,2.7-0.6,4.3c0,1.2,0,2.4,0,3.6c0.1,1.3,1,1.7,2,1.8
            c1.2,0.2,1.8,0.6,1.7,1.9c-0.1,1,0.3,1.5,1.4,1.4c1,0,1.9-0.1,1.8-1.4c-0.1-1.8,1-1.8,2.4-1.9c1.5,0,2.6,0,2.6,1.9
            c0,0.8,0.5,1.2,1.2,1.3V214.3l58.8-7.9L70.1,206v-92.5L89.8,79.6z"
        />
        <path
          className={classes.st7}
          d="M90.8,242.1c0.1,5,0.2,15.1,0.2,20.1c0,2.4,0.6,3.8,3.3,3.5h0.1c0.1,0.9,0.2,1.7,0.3,2.6
            c0.2,7.8,0.5,15.6,0.7,23.4c0,1.2,0.2,2.1,1.1,2.5c-0.5,0-0.9,0-1.4,0c-1,0.6-0.7,1.5-0.7,2.4c0.5,19.7,1.7,39.4,1.6,59.1
            c0,0.2,0.1,0.4,0.2,0.9c0-0.1,0.1-0.1,0.1-0.2c-0.8,2.2-0.9,4.9-0.3,8.1c-0.2,1.2,0.6,1.7,1.5,1.9c1.2,0.3,2,0.8,1.9,2.2
            c-0.1,1.1,0.6,1.3,1.6,1.3c0.9,0,1.7-0.2,1.7-1.3c-0.1-1.9,1.1-2,2.5-2c1.5,0,2.8,0,2.8,2.1c0,0.9,0.6,1.1,1.5,1.1c0,0,0,0,0.1,0
            l-1.1-115.3L90.8,242.1z"
        />
        <g>
          <path
            className={classes.st7}
            d="M133.8,140c3.3-1.1,6.1-3.2,9.3-4.3c4.5-1.7,4.8-2.3,4.7-7.1c-0.1-1.6,0.1-3.1,0-4.7
              c-0.2-2.6-0.6-5.1-1.4-7.4c-3,6.5-5.4,14.6-8.2,20.8C138.3,137.3,133.7,140,133.8,140z"
          />
        </g>
      </g>
    </svg>
  );
};

export default PlayerKitPreview;
