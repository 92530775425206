import { pathOr } from 'ramda';

import { NAMESPACE } from './constants';

export const getRefereeCategoriesList = pathOr(null, [
  'pages',
  NAMESPACE,
  'allRefereeCategories',
]);
export const getUpdatingRefereeCategory = pathOr(null, [
  'pages',
  NAMESPACE,
  'refereeCategoryBeingModified',
]);
export const isNewRefereeCategoryBeingCreated = pathOr(null, [
  'pages',
  NAMESPACE,
  'isNewRefereeCategoryBeingCreated',
]);
export const getRefereeCategoriesErrors = pathOr(null, [
  'pages',
  NAMESPACE,
  'errors',
]);
