import { TFunction } from 'i18next';

import { VenueFilterTab } from '@core/components';
import { VENUES_FILTER_ID } from '@core/components/venue-filter-tab/constants';
import { FilterTabsList } from '@core/types';
import CompetitionsFilterTab from '@core/components/match-competitions-filter-tab';
import { MATCH_COMPETITIONS_FILTER_ID } from '@core/components/match-competitions-filter-tab/constants';
import {
  MATCH_DATE_FROM_FILTER_ID,
  MATCH_DATE_TO_FILTER_ID,
  MATCH_DAY_TO_FILTER_ID,
  MATCH_DAY_FROM_FILTER_ID,
  MATCH_STATUS_FILTER_ID,
} from '@core/components/match-filter-tab/constants';
import MatchFilterTab from '@core/components/match-filter-tab';
import { TEAMS_FILTER_ID } from '@core/components/teams-filter-tab/constants';
import { GlobalTeamsFilterTab } from '@core/components/teams-filter-tab';

export const getMatchFilterTabsList = (t: TFunction): FilterTabsList => [
  {
    value: 'competitionsFilter',
    component: CompetitionsFilterTab,
    label: t('Competitions'),
    filterIds: [MATCH_COMPETITIONS_FILTER_ID],
  },
  {
    value: 'matchFilter',
    filterIds: [
      MATCH_DATE_FROM_FILTER_ID,
      MATCH_DATE_TO_FILTER_ID,
      MATCH_DAY_TO_FILTER_ID,
      MATCH_DAY_FROM_FILTER_ID,
      MATCH_STATUS_FILTER_ID,
    ],
    component: MatchFilterTab,
    label: t('Match'),
  },
  {
    value: 'teamsFilter',
    component: GlobalTeamsFilterTab,
    filterIds: [TEAMS_FILTER_ID],
    label: t('Teams'),
  },
  {
    value: 'venuesFilter',
    component: VenueFilterTab,
    filterIds: [VENUES_FILTER_ID],
    label: t('Venues'),
  },
];
