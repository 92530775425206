import axios from 'axios';
import {
  addCustomerCode,
  handleApiReject,
  mapAPIResponse,
  handleAuthorization,
} from './interceptors';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(handleAuthorization);
axiosInstance.interceptors.response.use(mapAPIResponse, handleApiReject);
axiosInstance.interceptors.request.use(addCustomerCode);

export default axiosInstance;
