import { State } from '@core/store';
import { NAMESPACE } from '.';

export const getGroupsById = (state: State, ids: Array<number>) => {
  const groups = state.api[NAMESPACE];

  return ids.map((id: number) => groups[id]);
};

export const getGroupsList = (state: State) => state.api[NAMESPACE];
