import { Action, createActions } from 'redux-actions';
import { prop, pick } from 'ramda';
import {
  CompetitionResponse,
  Group,
  Groups,
  MatchesListResponse,
  Filters,
  Sorting,
} from '@core/types';

export const NAMESPACE = 'competitionProfile';

export const actions = createActions(
  {
    SET_COMPETITION_DETAILS: [prop('payload'), pick(['competitionId'])],
    SET_STAGE_DETAILS: [prop('payload'), pick(['competitionId'])],
    SET_COMPETITORS: [prop('payload'), pick(['competitionId'])],
    SET_COMPETITOR_BY_TEAM: [
      prop('payload'),
      pick(['competitionId', 'teamId']),
    ],
    INITIALIZE_COMPETITION_STORE: [undefined, pick(['competitionId'])],
    SET_COMPETITOR_BY_TEAM_DEFAULT_VENUE: [
      prop('payload'),
      pick(['competitionId', 'teamId']),
    ],
    SET_COMPETITOR_BY_TEAM_ALTERNATE_VENUE: [
      prop('payload'),
      pick(['competitionId', 'teamId']),
    ],
    SET_GROUP: [prop('payload'), pick(['competitionId', 'groupId'])],
    SET_DISCIPLINARY_SANCTIONS: [prop('payload'), pick(['competitionId'])],
    SET_GROUP_MATCHES: [prop('payload'), pick(['competitionId', 'groupId'])],
    SET_MATCH_SEARCH_QUERY: [
      prop('payload'),
      pick(['competitionId', 'groupId']),
    ],
    SET_FILTER_PARAMS: [prop('payload'), pick(['competitionId', 'groupId'])],
    SET_SORTING: [prop('payload'), pick(['competitionId', 'groupId'])],
    SET_GROUP_FIXTURES: [prop('payload'), pick(['competitionId', 'groupId'])],
    SET_FIXTURES_SEARCH_QUERY: [
      prop('payload'),
      pick(['competitionId', 'groupId']),
    ],
    SET_DISCIPLINARY_INFRINGEMENTS: [prop('payload'), pick(['competitionId'])],
    SET_SANCTIONS_SEARCH_QUERY: [prop('payload'), pick(['competitionId'])],
    SET_INFRINGEMENTS_SEARCH_QUERY: [prop('payload'), pick(['competitionId'])],
  },
  'GET_COMPETITION_DETAILS',
  'GET_COMPETITION_DETAILS_NO_INIT',
  'GET_COMPETITION_DETAILS_REQUEST',
  'GET_COMPETITION_DETAILS_SUCCESS',
  'GET_COMPETITION_DETAILS_FAILURE',

  'GET_STAGE_DETAILS',
  'GET_STAGE_DETAILS_REQUEST',
  'GET_STAGE_DETAILS_SUCCESS',
  'GET_STAGE_DETAILS_FAILURE',

  'GET_COMPETITORS',
  'GET_COMPETITORS_SUCCESS',
  'GET_COMPETITORS_FAILURE',
  'GET_COMPETITORS_REQUEST',

  'GET_COMPETITOR_BY_TEAM',
  'GET_COMPETITOR_BY_TEAM_REQUEST',
  'GET_COMPETITOR_BY_TEAM_SUCCESS',
  'GET_COMPETITOR_BY_TEAM_FAILURE',

  'DELETE_COMPETITOR',
  'DELETE_COMPETITOR_REQUEST',
  'DELETE_COMPETITOR_SUCCESS',
  'DELETE_COMPETITOR_FAILURE',

  'CHANGE_STAGE_STATUS',
  'CHANGE_STAGE_STATUS_REQUEST',
  'CHANGE_STAGE_STATUS_SUCCESS',
  'CHANGE_STAGE_STATUS_FAILURE',

  'GET_GROUP_REQUEST',
  'GET_GROUP_SUCCESS',
  'GET_GROUP_FAILURE',

  'UPDATE_COMPETITION_REQUEST',
  'UPDATE_COMPETITION_SUCCESS',
  'UPDATE_COMPETITION_FAILURE',

  'GET_DISCIPLINARY_INFRINGEMENTS',
  'GET_DISCIPLINARY_INFRINGEMENTS_REQUEST',
  'GET_DISCIPLINARY_INFRINGEMENTS_SUCCESS',
  'GET_DISCIPLINARY_INFRINGEMENTS_FAILURE',

  'GET_DISCIPLINARY_SANCTIONS',
  'GET_DISCIPLINARY_SANCTIONS_REQUEST',
  'GET_DISCIPLINARY_SANCTIONS_SUCCESS',
  'GET_DISCIPLINARY_SANCTIONS_FAILURE',

  'UPDATE_SANCTION_STATUS',
  'UPDATE_SANCTION_STATUS_REQUEST',
  'UPDATE_SANCTION_STATUS_SUCCESS',
  'UPDATE_SANCTION_STATUS_FAILURE',

  'GET_MATCHES_BY_GROUP',
  'GET_MATCHES_BY_GROUP_REQUEST',
  'GET_MATCHES_BY_GROUP_SUCCESS',
  'GET_MATCHES_BY_GROUP_FAILURE',
  'CLEAR_MATCHES_BY_GROUP',
  'SEARCH_MATCHES_BY_QUERY',

  'GET_FIXTURES_BY_GROUP',
  'GET_FIXTURES_BY_GROUP_REQUEST',
  'GET_FIXTURES_BY_GROUP_SUCCESS',
  'GET_FIXTURES_BY_GROUP_FAILURE',
  'CLEAR_FIXTURES_BY_GROUP',
  'SEARCH_FIXTURES_BY_QUERY',

  'DELETE_GROUP_FIXTURE',
  'DELETE_GROUP_FIXTURE_REQUEST',
  'DELETE_GROUP_FIXTURE_SUCCESS',
  'DELETE_GROUP_FIXTURE_FAILURE',

  'UPDATE_GROUP',
  'UPDATE_GROUP_REQUEST',
  'UPDATE_GROUP_SUCCESS',
  'UPDATE_GROUP_FAILURE',

  'CREATE_GROUP',
  'CREATE_GROUP_REQUEST',
  'CREATE_GROUP_SUCCESS',
  'CREATE_GROUP_FAILURE',

  'DELETE_GROUP',
  'DELETE_GROUP_REQUEST',
  'DELETE_GROUP_SUCCESS',
  'DELETE_GROUP_FAILURE',

  { prefix: NAMESPACE },
) as unknown as {
  setCompetitionDetails: IdentityActionMetaFunction<
    {
      competitionId: number;
      payload: CompetitionResponse;
    },
    {
      payload: CompetitionResponse;
    },
    { competitionId: number }
  >;

  setCompetitors: UnknownAction;
  setCompetitorByTeam: UnknownAction;
  initializeCompetitionStore: UnknownAction;
  setCompetitorByTeamDefaultVenue: UnknownAction;
  setCompetitorByTeamAlternateVenue: UnknownAction;
  setGroup: UnknownAction;
  setDisciplinarySanctions: UnknownAction;
  setGroupMatches: IdentityActionMetaFunction<
    { groupId: number; competitionId: number; payload: MatchesListResponse },
    { payload: MatchesListResponse },
    { groupId: number; competitionId: number }
  >;
  setMatchSearchQuery: UnknownAction;
  setFilterParams: IdentityActionFunction<{
    payload: Filters;
    competitionId: number;
    groupId: number;
  }>;
  setSorting: IdentityActionFunction<{
    payload: Sorting;
    competitionId: number;
    groupId: number;
  }>;
  setGroupFixtures: UnknownAction;
  setFixturesSearchQuery: UnknownAction;
  setDisciplinaryInfringements: UnknownAction;
  setSanctionsSearchQuery: UnknownAction;
  setInfringementsSearchQuery: UnknownAction;
  getCompetitionDetails: IdentityActionFunction<number>;
  getCompetitionDetailsNoInit: UnknownAction;
  getCompetitionDetailsRequest: RequestLifecycleAction;
  getCompetitionDetailsSuccess: RequestLifecycleAction;
  getCompetitionDetailsFailure: RequestLifecycleAction;
  getStageDetails: IdentityActionFunction<{ competitionId: number }>;
  setStageDetails: IdentityActionFunction<{
    competitionId: number;
    payload: Groups;
  }>;
  /** @deprecated TODO: JB: when used with createLoadingSelector it should use id */
  getStageDetailsRequest: RequestLifecycleAction<{ id: number }>;
  getStageDetailsSuccess: RequestLifecycleAction<{ id: number }>;
  getStageDetailsFailure: RequestLifecycleAction<{ id: number }>;
  getCompetitors: UnknownAction;
  getCompetitorsSuccess: RequestLifecycleAction;
  getCompetitorsFailure: RequestLifecycleAction;
  getCompetitorsRequest: RequestLifecycleAction;
  getCompetitorByTeam: UnknownAction;
  getCompetitorByTeamRequest: RequestLifecycleAction;
  getCompetitorByTeamSuccess: RequestLifecycleAction;
  getCompetitorByTeamFailure: RequestLifecycleAction;
  deleteCompetitor: UnknownAction;
  deleteCompetitorRequest: RequestLifecycleAction;
  deleteCompetitorSuccess: RequestLifecycleAction;
  deleteCompetitorFailure: RequestLifecycleAction;
  changeStageStatus: UnknownAction;
  changeStageStatusRequest: RequestLifecycleAction;
  changeStageStatusSuccess: RequestLifecycleAction;
  changeStageStatusFailure: RequestLifecycleAction;

  /** @deprecated action and related actions are not dispatched anywhere */
  getGroupRequest: RequestLifecycleAction;
  /** @deprecated action and related actions are not dispatched anywhere */
  getGroupSuccess: RequestLifecycleAction;
  /** @deprecated action and related actions are not dispatched anywhere */
  getGroupFailure: RequestLifecycleAction;

  updateCompetitionRequest: RequestLifecycleAction;
  updateCompetitionSuccess: RequestLifecycleAction;
  updateCompetitionFailure: RequestLifecycleAction;
  getDisciplinaryInfringements: UnknownAction;
  getDisciplinaryInfringementsRequest: RequestLifecycleAction;
  getDisciplinaryInfringementsSuccess: RequestLifecycleAction;
  getDisciplinaryInfringementsFailure: RequestLifecycleAction;
  getDisciplinarySanctions: UnknownAction;
  getDisciplinarySanctionsRequest: RequestLifecycleAction;
  getDisciplinarySanctionsSuccess: RequestLifecycleAction;
  getDisciplinarySanctionsFailure: RequestLifecycleAction;
  updateSanctionStatus: UnknownAction;
  updateSanctionStatusRequest: RequestLifecycleAction;
  updateSanctionStatusSuccess: RequestLifecycleAction;
  updateSanctionStatusFailure: RequestLifecycleAction;
  getMatchesByGroup: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    page?: number;
  }>;
  getMatchesByGroupRequest: RequestLifecycleAction<{ id: number }>;
  getMatchesByGroupSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchesByGroupFailure: RequestLifecycleAction<{ id: number }>;
  clearMatchesByGroup: IdentityActionFunction<{
    groupId: number;
    competitionId: number;
  }>;
  searchMatchesByQuery: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    page?: number;
  }>;
  /** @deprecated **action is not handled in any reduce** */
  getFixturesByGroup: (payload: {
    competitionId: number;
    groupId: number;
    page?: unknown;
  }) => Action<void>;
  getFixturesByGroupRequest: RequestLifecycleAction<{ id: number }>;
  getFixturesByGroupSuccess: RequestLifecycleAction<{ id: number }>;
  getFixturesByGroupFailure: RequestLifecycleAction<{ id: number }>;
  /** @deprecated **not typed** */
  clearFixturesByGroup: any;
  /** @deprecated **not typed** */
  searchFixturesByQuery: any;
  deleteGroupFixture: UnknownAction;
  deleteGroupFixtureRequest: RequestLifecycleAction;
  deleteGroupFixtureSuccess: RequestLifecycleAction;
  deleteGroupFixtureFailure: RequestLifecycleAction;
  updateGroup: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    data: Partial<Group>;
  }>;
  updateGroupRequest: RequestLifecycleAction;
  updateGroupSuccess: RequestLifecycleAction;
  updateGroupFailure: RequestLifecycleAction;
  createGroup: IdentityActionFunction<{
    competitionId: number;
    data: Partial<Group> & { parentGroupId?: number };
  }>;
  createGroupRequest: RequestLifecycleAction;
  createGroupSuccess: RequestLifecycleAction;
  createGroupFailure: RequestLifecycleAction;
  deleteGroup: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    stageId?: number;
  }>;
  deleteGroupRequest: RequestLifecycleAction;
  deleteGroupSuccess: RequestLifecycleAction;
  deleteGroupFailure: RequestLifecycleAction;
};
