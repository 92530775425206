import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Checkbox, Grid, Box, Typography, Theme } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';

import { TableWithAssign, PersonNameColumn } from '@core/components';
import {
  CAPTAIN_RADIO_BUTTON,
  LIBERO_CHECKBOX,
} from '@core/pages/matches/tests/test-ids';
import { AddedMatchPlayer, MatchPlayerOrigin, Sports } from '@core/types';
import { getAddedMatchLineupPlayers } from '@core/store/modules/tabs/match-sheet-management/selectors';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import { State } from '@core/store';

interface StateProps {
  addedLineup: Array<AddedMatchPlayer>;
}

interface OwnProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  sport: keyof typeof Sports;
  tabId: string;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  control: {
    padding: 0,
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = StateProps & OwnProps & DispatchProps;

const AddedLineupTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addedLineup, actions, matchId, matchPlayerOrigin, sport, tabId } =
    props;

  const liberoPlayersCount =
    addedLineup?.filter((player: AddedMatchPlayer) => player.isLibero)
      ?.length || 0;

  const handleRoleSelect = (role: 'isCaptain' | 'isLibero') => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const playerId = event.target.value;

      actions.matchSheetManagement.changeLineupAddedPlayersRole({
        playerId,
        matchPlayerOrigin,
        matchId,
        role,
        tabId,
      });
    };
  };

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: AddedMatchPlayer) => (
        <PersonNameColumn
          startText={!!rowData.jerseyNumber && `#${rowData.jerseyNumber}`}
          person={rowData?.player}
        />
      ),
    },
    {
      name: 'isCaptain',
      label: t('Captain'),
      render: (rowData: AddedMatchPlayer) => (
        <Radio
          value={rowData.id}
          onChange={handleRoleSelect('isCaptain')}
          name="isCaptain"
          checked={rowData.isCaptain || false}
          className={classes.control}
          data-qa={CAPTAIN_RADIO_BUTTON}
        />
      ),
      align: 'center' as const,
    },
  ];

  if (sport === Sports.VOLLEYBALL) {
    columns.push({
      name: 'libero',
      label: t('Libero'),
      render: (rowData: AddedMatchPlayer) => (
        <Checkbox
          value={rowData.id}
          onChange={handleRoleSelect('isLibero')}
          name="isLibero"
          checked={rowData.isLibero || false}
          disabled={liberoPlayersCount >= 2 && !rowData.isLibero}
          className={classes.control}
          data-qa={LIBERO_CHECKBOX}
        />
      ),
      align: 'center' as const,
    });
  }

  const handleRemoveFromList = (id: number) => {
    const updatedLineupPlayers = addedLineup?.filter(
      (player: AddedMatchPlayer) => player.id !== id,
    );

    actions.matchSheetManagement.setLineupAddedPlayers({
      matchId,
      matchPlayerOrigin,
      tabId,
      payload: updatedLineupPlayers,
    });
  };

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Added players')} (${addedLineup?.length || 0})`}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            columns={columns}
            data={addedLineup}
            handleRemove={handleRemoveFromList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;
  const matchPlayerOrigin = ownProps?.matchPlayerOrigin;

  return {
    addedLineup: getAddedMatchLineupPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddedLineupTable);
