import { createAction } from 'redux-actions';

import * as FILES_ACTION_TYPES from './constants';

export const initializeFileStore = createAction(
  FILES_ACTION_TYPES.INITIALIZE_FILE_STORE,
);
export const setFileList = createAction(FILES_ACTION_TYPES.SET_FILE_LIST);

export const getFiles = createAction(FILES_ACTION_TYPES.GET_FILES);
export const getFilesRequest = createAction(
  FILES_ACTION_TYPES.GET_FILES_REQUEST,
);
export const getFilesSuccess = createAction(
  FILES_ACTION_TYPES.GET_FILES_SUCCESS,
);
export const getFilesFailure = createAction(
  FILES_ACTION_TYPES.GET_FILES_FAILURE,
);

export const getFile = createAction(FILES_ACTION_TYPES.GET_FILE);
export const getFileRequest = createAction(FILES_ACTION_TYPES.GET_FILE_REQUEST);
export const getFileSuccess = createAction(FILES_ACTION_TYPES.GET_FILE_SUCCESS);
export const getFileFailure = createAction(FILES_ACTION_TYPES.GET_FILE_FAILURE);

export const deleteFile = createAction(FILES_ACTION_TYPES.DELETE_FILE);
export const deleteFileRequest = createAction(
  FILES_ACTION_TYPES.DELETE_FILE_REQUEST,
);
export const deleteFileSuccess = createAction(
  FILES_ACTION_TYPES.DELETE_FILE_SUCCESS,
);
export const deleteFileFailure = createAction(
  FILES_ACTION_TYPES.DELETE_FILE_FAILURE,
);

export const uploadFile = createAction(FILES_ACTION_TYPES.UPLOAD_FILE);
export const uploadFileRequest = createAction(
  FILES_ACTION_TYPES.UPLOAD_FILE_REQUEST,
);
export const uploadFileSuccess = createAction(
  FILES_ACTION_TYPES.UPLOAD_FILE_SUCCESS,
);
export const uploadFileFailure = createAction(
  FILES_ACTION_TYPES.UPLOAD_FILE_FAILURE,
);

export const overwriteFile = createAction(FILES_ACTION_TYPES.OVERWRITE_FILE);
export const overwriteFileRequest = createAction(
  FILES_ACTION_TYPES.OVERWRITE_FILE_REQUEST,
);
export const overwriteFileSuccess = createAction(
  FILES_ACTION_TYPES.OVERWRITE_FILE_SUCCESS,
);
export const overwriteFileFailure = createAction(
  FILES_ACTION_TYPES.OVERWRITE_FILE_FAILURE,
);
