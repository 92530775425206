import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import { RoleTypes } from '@core/types';
import { State } from '@core/store';

import { ALL_GROUPS, NAMESPACE } from './constants';
import { MatchOfficialGroupWithMatchOfficials } from './reducers';

export const getExpandedGroupId = pathOr(null, [
  'pages',
  NAMESPACE,
  'expandedGroup',
]);
export const getMatchOfficialGroupsList = pathOr(null, [
  'pages',
  NAMESPACE,
  ALL_GROUPS,
]);
export const getUpdatingMatchOfficialGroup = pathOr(null, [
  'pages',
  NAMESPACE,
  'matchOfficialGroupBeingModified',
]);
export const isNewMatchOfficialGroupBeingCreated = pathOr(null, [
  'pages',
  NAMESPACE,
  'isNewMatchOfficialGroupBeingCreated',
]);
export const getMatchOfficialGroupsErrors = pathOr(null, [
  'pages',
  NAMESPACE,
  'errors',
]);

const getId = (state: State, id: number) => id;

export const getRefereeGroupList = pathOr(null, [
  'pages',
  NAMESPACE,
  RoleTypes.REFEREE,
]);
export const getRefereeGroupById = createSelector(
  getId,
  getRefereeGroupList,
  (id, groups) =>
    groups.find(
      (group: MatchOfficialGroupWithMatchOfficials) => group?.id === id,
    ),
);

export const getMatchOfficialsByRefereeGroupId = createSelector(
  getId,
  getRefereeGroupList,
  (id, groups) =>
    groups.find(
      (group: MatchOfficialGroupWithMatchOfficials) => group.id === id,
    )?.matchOfficials,
);

export const getMaOfficialGroupList = pathOr(null, [
  'pages',
  NAMESPACE,
  RoleTypes.REFEREE,
]);
export const getMaOfficialGroupById = createSelector(
  getId,
  getMaOfficialGroupList,
  (id, groups) =>
    groups.find(
      (group: MatchOfficialGroupWithMatchOfficials) => group.id === id,
    ),
);
