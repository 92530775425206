import LatoRegularWoff2 from '../fonts/Lato-Regular.woff2';
import LatoRegularWoff from '../fonts/Lato-Regular.woff';

const latoLatinRegular = `
        @font-face {
          font-family: 'LatoWeb';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          text-rendering: optimizeLegibility;
          src: url(${LatoRegularWoff2}) format('woff2'), url(${LatoRegularWoff}) format('woff');
        }
      `;

export default latoLatinRegular;
