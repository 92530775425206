import { Box, Grid, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  DottedText,
  ConnectedText,
  Status,
  ButtonWithDropdown,
  OptionProps,
} from '@core/components';
import { CalendarEdit } from '@core/icons';
import { getStatusColor, getMatchStatus, getVenue } from '@core/helpers';
import { MatchStatus, MatchModel, ModalTypes, Group } from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { MatchCreateModalProps } from '@core/pages/competition-profile';
import { OwnProps as ChangeMatchStatusModalProps } from '@core/pages/match-profile/components/change-match-status-modal';
import { CHANGE_MATCH_STATUS_BUTTON } from './tests/test-ids';
import GoldenMatchLabel from '@core/components/golden-match-label';
import { useCoreDispatch } from '@core/hooks';

type Props = {
  competitionId: number;
  stageId: number;
  group: Group;
  match: MatchModel;
  tabId: string;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(0.75, 1),
  },
  subtitle: {
    fontSize: 12,
  },
  expansionPanel: {
    margin: '0 !important',
  },
  accordionContent: {
    position: 'relative',
  },
  actionsButtonsGrid: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
  },
  matchInformationGrid: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

const MatchProfileActions = ({
  competitionId,
  stageId,
  match,
  group,
  tabId,
}: Props) => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const isMatchComplete = [MatchStatus.COMPLETE, MatchStatus.BYE].includes(
    match?.status,
  );
  const venue = getVenue(match?.venue, { subVenue: true });
  const parentVenueTitle =
    venue?.parentVenue?.title || venue?.parentVenue?.internationalTitle;
  const venueTitle = venue?.title || venue?.internationalTitle;

  const subtitle: Array<string> = [];

  if (match?.date && match?.time) {
    subtitle.push(`${match.date}, ${match.time}`);
  }

  if (parentVenueTitle) {
    subtitle.push(`${parentVenueTitle}${venueTitle ? `, ${venueTitle}` : ''}`);
  }

  if (!parentVenueTitle && venueTitle) {
    subtitle.push(venueTitle);
  }

  const handleEditClick = () =>
    dispatch(
      globalModalActions.openModal<MatchCreateModalProps>({
        type: ModalTypes.MATCH_CREATE_MODAL,
        params: {
          tabId,
          competitionId,
          stage: group,
          matchId: match?.id,
          isEditMode: true,
        },
      }),
    );

  const handleMatchStatusChange = (status: MatchStatus) =>
    dispatch(
      globalModalActions.openModal<ChangeMatchStatusModalProps>({
        type: ModalTypes.CHANGE_MATCH_STATUS_MODAL,
        params: {
          stageId,
          stage: group,
          matchId: match.id,
          match,
          status,
        },
      }),
    );

  const matchStatusOptions: Array<OptionProps<MatchStatus>> = [
    {
      text: t('Complete match'),
      value: MatchStatus.COMPLETE,
      disabled:
        (!match?._links?.changeStatus?.[MatchStatus.COMPLETE] &&
          match?.status !== MatchStatus.COMPLETE) ||
        match?.status === MatchStatus.COMPLETE,
    },
    {
      text: t('Schedule match'),
      value: MatchStatus.SCHEDULED,
      disabled:
        (!match?._links?.changeStatus?.[MatchStatus.SCHEDULED] &&
          match?.status !== MatchStatus.SCHEDULED) ||
        match?.status === MatchStatus.SCHEDULED,
    },
    {
      text: t('Cancel match'),
      value: MatchStatus.CANCELLED,
      disabled:
        (!match?._links?.changeStatus?.[MatchStatus.CANCELLED] &&
          match?.status !== MatchStatus.CANCELLED) ||
        match?.status === MatchStatus.CANCELLED,
    },
    {
      text: t('Postpone match'),
      value: MatchStatus.POSTPONED,
      disabled:
        (!match?._links?.changeStatus?.[MatchStatus.POSTPONED] &&
          match?.status !== MatchStatus.POSTPONED) ||
        match?.status === MatchStatus.POSTPONED,
    },
    {
      text: t('Abandon match'),
      value: MatchStatus.ABANDONED,
      disabled:
        (!match?._links?.changeStatus?.[MatchStatus.ABANDONED] &&
          match?.status !== MatchStatus.ABANDONED) ||
        match?.status === MatchStatus.ABANDONED,
    },
  ];

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        sm={12}
        md={8}
        alignItems="center"
        className={classes.matchInformationGrid}
      >
        {match.goldenMatch && (
          <Box mr={1}>
            <GoldenMatchLabel />
          </Box>
        )}
        <Grid item>
          <Typography variant="subtitle1">
            <strong>
              {match?.number ? (
                <ConnectedText
                  textEntries={[t('Match No'), '. ', match?.number]}
                />
              ) : (
                '-'
              )}
            </strong>
          </Typography>
        </Grid>
        <Grid item ml={1.5}>
          <Status
            title={getMatchStatus(t, match.status)}
            statusColor={getStatusColor(match.status)}
          />
        </Grid>
        <Grid item ml={1.6} className={classes.subtitle}>
          <DottedText color="text.primary" textEntries={subtitle} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={4}
        alignItems="center"
        className={classes.actionsButtonsGrid}
      >
        <Box>
          <ButtonWithDropdown
            variant="outlined"
            options={matchStatusOptions}
            onSelect={handleMatchStatusChange}
            data-qa={CHANGE_MATCH_STATUS_BUTTON}
          >
            {t('Change status')}
          </ButtonWithDropdown>
        </Box>
        {match?._links?.patch && (
          <Box ml={2}>
            <IconButton
              color="primary"
              onClick={handleEditClick}
              disabled={isMatchComplete}
            >
              <CalendarEdit />
            </IconButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default MatchProfileActions;
