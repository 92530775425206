import restService from '@core/api/rest-service';
import { CityResponse, CityModel } from '@core/types';

export const getCityList = (queryParams?: { query?: string }) =>
  restService.get<CityResponse>('/v1/cities', { params: queryParams });

export const getCitiesByRegionId = (
  regionId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<CityResponse>(`/v1/regions/${regionId}/cities`, {
    params: queryParams,
  });

export const getCitiesByCountryId = (
  countryId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<CityResponse>(`/v1/countries/${countryId}/cities`, {
    params: queryParams,
  });

export const getCity = (id: number) =>
  restService.get<CityModel>(`/v1/cities/${id}`);
