import { put, call, take, all, Effect } from 'redux-saga/effects';
import download from 'downloadjs';
import { handleApiErrors } from '@core/helpers';
import * as matchesAPI from '@core/api/matches';
import {
  HttpResponse,
  MatchesBulkActions,
  MatchesListResponse,
  MatchStatus,
  PatchMatchPayload,
} from '@core/types';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';

import { actions } from '.';

export function* getMatches(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getMatchesRequest());
  const response = (yield call(matchesAPI.getMatches, queryParams)) as InferApi<
    typeof matchesAPI.getMatches
  >;

  if (!response.error) {
    yield put(actions.getMatchesSuccess());
    yield put(actions.setMatches(response.response?._embedded?.matches));
  } else {
    yield put(actions.getMatchesFailure());
  }

  return response;
}

export function* getGroupsMatches(
  groupId: number,
  queryParams: URLSearchParams,
): Generator<Effect, HttpResponse<MatchesListResponse>, any> {
  yield put(actions.getMatchesByGroupRequest({ id: groupId }));
  const response = (yield call(matchesAPI.getMatches, queryParams)) as InferApi<
    typeof matchesAPI.getMatches
  >;

  if (!response.error) {
    yield put(actions.getMatchesByGroupSuccess({ id: groupId }));
    yield put(actions.setMatches(response.response?._embedded?.matches));
  } else {
    yield put(actions.getMatchesByGroupFailure({ id: groupId }));
  }

  return response;
}

export function* getMatch(matchId: number): Generator<Effect, any, any> {
  yield put(actions.getMatchRequest());
  const response = (yield call(matchesAPI.getMatch, matchId)) as InferApi<
    typeof matchesAPI.getMatch
  >;

  if (!response.error) {
    yield put(actions.getMatchSuccess());
    // TODO: JB: maybe not persist /v1/matches GET response with /v1/matches/{id} GET response in the same list
    // since the hal links on match by the 2 requests are different and depending on the match hal links from this list
    // will cause race like bugs when match is overwritten with less hal links than expected
    yield put(actions.setMatch(response.response));
  } else {
    yield put(actions.getMatchFailure());
  }

  return response;
}

export function* createMatch(
  groupId: number,
  data: any,
): Generator<Effect, any, any> {
  yield put(actions.createMatchRequest());
  const response = (yield call(
    matchesAPI.createMatch,
    groupId,
    data,
  )) as InferApi<typeof matchesAPI.createMatch>;

  if (!response.error) {
    yield put(actions.createMatchSuccess());
  } else {
    yield put(actions.createMatchFailure());
  }

  return response;
}

/**
 * @deprecated
 * seems like PATCH match method doesn't support partial/delta change
 * full payload has to be given not to unitinialize some match properties
 * */
export function* patchMatch(
  matchId: number,
  data: PatchMatchPayload,
): Generator<Effect, any, any> {
  yield put(actions.updateMatchRequest());
  const response = (yield call(
    matchesAPI.patchMatch,
    matchId,
    data,
  )) as InferApi<typeof matchesAPI.patchMatch>;

  if (!response.error) {
    yield put(actions.updateMatchSuccess());
  } else {
    yield put(actions.updateMatchFailure());
  }

  return response;
}

export function* deleteMatch(matchId: number): Generator<Effect, any, any> {
  yield put(actions.deleteMatchRequest());
  const response = (yield call(matchesAPI.deleteMatch, matchId)) as InferApi<
    typeof matchesAPI.deleteMatch
  >;

  if (!response.error) {
    yield put(actions.deleteMatchSuccess());
  } else {
    yield put(actions.deleteMatchFailure());
  }

  return response;
}

export function* exportMatchesFlow() {
  while (true) {
    const { payload } = yield take(actions.exportMatches.toString());

    yield put(actions.exportMatchesRequest());
    const { response, error } = yield call(
      matchesAPI.exportGroupMatches,
      payload.groupId,
    );

    if (!error) {
      const blob = new Blob([response], {
        type: 'text/csv;charset=utf-8',
      });

      yield call(download, blob, 'matches.csv', 'text/plain');
      yield put(actions.exportMatchesSuccess());
    } else {
      yield put(actions.exportMatchesFailure());
    }
  }
}

export function* exportMatchesTemplateFlow() {
  while (true) {
    const { payload } = yield take(actions.exportMatchesTemplate.toString());

    yield put(actions.exportMatchesTemplateRequest());
    const { response, error } = yield call(
      matchesAPI.exportGroupMatchesTemplate,
      payload.groupId,
    );

    if (!error) {
      const blob = new Blob([response], {
        type: 'text/csv;charset=utf-8',
      });

      yield call(download, blob, 'matches-template.csv', 'text/plain');
      yield put(actions.exportMatchesTemplateSuccess());
    } else {
      yield put(actions.exportMatchesTemplateFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* uploadMatchesFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.uploadMatches)) as InferAction<
      typeof actions.uploadMatches
    >;

    yield put(actions.uploadMatchesRequest());
    const { error } = (yield call(
      matchesAPI.uploadGroupMatches,
      payload,
    )) as InferApi<typeof matchesAPI.uploadGroupMatches>;

    if (!error) {
      yield put(actions.uploadMatchesSuccess());
      yield put(
        competitionProfileActions.getMatchesByGroup({
          competitionId: payload?.competitionId,
          groupId: payload?.groupId,
          page: payload?.page,
        }),
      );
      if (payload.successCallback) yield put(payload.successCallback());
    } else {
      yield put(actions.uploadMatchesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* changeMatchStatus({
  matchId,
  status,
  reasonDescription,
}: {
  matchId: number;
  status: MatchStatus;
  reasonDescription: string;
}): Generator<Effect, any, any> {
  yield put(actions.changeMatchStatusRequest());
  const response = (yield call(
    matchesAPI.changeMatchStatus,
    matchId,
    status,
    reasonDescription,
  )) as InferApi<typeof matchesAPI.changeMatchStatus>;

  if (!response.error) {
    yield put(actions.changeMatchStatusSuccess());
  } else {
    yield put(actions.changeMatchStatusFailure());
  }

  return response;
}

export function* changeMatchStatusInBulk(payload: {
  matchIds: Array<number>;
  status: MatchesBulkActions;
}): Generator<Effect, any, any> {
  yield put(actions.changeMatchStatusInBulkRequest());
  const response = (yield call(
    matchesAPI.changeMatchStatusInBulk,
    payload,
  )) as InferApi<typeof matchesAPI.changeMatchStatusInBulk>;

  if (!response.error) {
    yield put(actions.changeMatchStatusInBulkSuccess());
  } else {
    yield put(actions.changeMatchStatusInBulkFailure());
  }

  return response;
}

export function* bulkDelete(payload: {
  matchIds: Array<number>;
}): Generator<Effect, any, any> {
  yield put(actions.bulkDeleteRequest());
  const response = (yield call(matchesAPI.bulkDelete, payload)) as InferApi<
    typeof matchesAPI.bulkDelete
  >;

  if (!response.error) {
    yield put(actions.bulkDeleteSuccess());
  } else {
    yield put(actions.bulkDeleteFailure());
  }

  return response;
}

export function* getMatchUrlFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.getMatchUrl.toString());

    yield put(actions.getMatchUrlRequest());
    const response = (yield call(
      matchesAPI.getMatch,
      payload?.href,
    )) as InferApi<typeof matchesAPI.getMatch>;

    if (!response.error) {
      yield put(actions.getMatchUrlSuccess());
      yield put(actions.setMatch(response.response));
    } else {
      yield put(actions.getMatchUrlFailure());
    }
  }
}

export default function* saga() {
  yield all([
    getMatchUrlFlow(),
    exportMatchesFlow(),
    exportMatchesTemplateFlow(),
    uploadMatchesFlow(),
  ]);
}
