import { lazy } from 'react';
import { logDev } from '@core/store/helpers';
import { lazyLoadStore } from './lazy-load-store';
import { injectModules } from './inject-modules';

export const lazyLoadPage = (modulePath: string, moduleImport: Promise<any>) =>
  lazy(
    () =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          const module = await moduleImport;
          const dependencies = module?.dependencies;

          if (dependencies) {
            for await (const key of Object.keys(dependencies)) {
              const dependencyImport = dependencies[key];
              await lazyLoadStore(key, dependencyImport, modulePath);
            }
          }

          const isSuccess = await injectModules(module, modulePath);
          isSuccess
            ? logDev(`${modulePath}`, `loaded`, '#006c5f')
            : logDev(`${modulePath}`, `skipped (already loaded)`, '#8cadab');

          resolve(module);
        } catch (error) {
          reject(error);
        }
      }),
  );
