import { useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Radio,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
} from '@mui/material';
import {
  CompetitionResponse,
  PaginationLimits,
  Competition as CompetitionType,
} from '@core/types';
import CustomTable from '@core/components/custom-table';
import ConnectedText from '@core/components/connected-text';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments';
import {
  areCompetitionsLoading,
  getAllCompetitions,
  getAllCompetitionsPagination,
} from '@core/store/modules/pages/assignments/selectors';
import {
  getGenders,
  getSports,
  getClubType,
  getCompetitionStatus,
  getStatusColor,
} from '@core/helpers';
import ColumnWithSubValue from '@core/components/column-with-sub-value';
import { Competition } from '@core/icons';
import Status from '@core/components/status';
import FullScreenSpinner from '@core/components/full-screen-spinner';
import { Column } from '@core/components/material-table';
import { useAppDispatch } from '@volleyball/store/hooks';

type Props = {
  personId: number;
  personName: string;
  assignedCompetitions: Array<CompetitionType>;
  isModalOpen: boolean;
  closeModal: () => void;
  reloadCallback: () => void;
};

const limit: PaginationLimits = 999;
const perPage: PaginationLimits = 5;

const AssignCompetitionModal = ({
  personId,
  personName,
  assignedCompetitions,
  isModalOpen,
  closeModal,
  reloadCallback,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<number>();
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const isLoading = useSelector(areCompetitionsLoading);
  const allCompetitions = useSelector(getAllCompetitions);
  const allCompetitionsPagination = useSelector(getAllCompetitionsPagination);
  const pages = Math.ceil((allCompetitions?.length ?? 0) / perPage);
  const data = (allCompetitions ?? []).slice(
    page * perPage - perPage,
    page * perPage,
  );

  const isDisabled = (competitionId: number) =>
    assignedCompetitions.some(
      (competition) => competition.id === competitionId,
    );

  const toggleSelected = (
    _event: MouseEvent,
    competition: CompetitionResponse,
  ) => {
    if (!isDisabled(competition.id)) {
      setSelected(competition.id);
    }
  };

  const handleSearch = (params: { query: string }) => {
    setQuery(params.query);
    setPage(1);
  };

  const handleClose = () => {
    setQuery('');
    closeModal();
  };

  const columns: Column<CompetitionResponse>[] = [
    {
      field: 'actions',
      cellStyle: {
        width: 10,
        padding: '8px 0 8px 8px',
      },
      render: ({ id: rowId }) => (
        <Box display="flex" alignItems="center">
          <Radio
            checked={selected === rowId || isDisabled(rowId)}
            disabled={isDisabled(rowId)}
            value={rowId}
            inputProps={{ 'aria-label': 'Select competition' }}
          />
        </Box>
      ),
    },
    {
      title: t('Title'),
      render: ({ title, internationalTitle, logoFileLink }) => (
        <ColumnWithSubValue
          value={title}
          valueTypographyProps={{ variant: 'subtitle2' }}
          subValue={internationalTitle}
          logo={{ url: logoFileLink, defaultIcon: <Competition /> }}
        />
      ),
    },
    {
      title: t('Comp. ID'),
      field: 'id',
    },
    {
      title: t('Season'),
      field: 'competitionSeasonTitle',
    },
    {
      title: t('Sport'),
      field: 'sportTitle',
      render: ({ sportTitle }) => getSports(t, sportTitle),
    },
    {
      title: t('Gender'),
      field: 'gender',
      render: ({ gender }) => getGenders(t, gender),
    },
    {
      title: t('Level'),
      field: 'competitionLevelTitle',
    },
    {
      title: t('Type'),
      field: 'clubType',
      render: ({ clubType }) => getClubType(t, clubType),
    },
    {
      title: t('Teams'),
      field: 'participatingTeams',
    },
    {
      title: t('Status'),
      field: 'status',
      render: ({ status }) => (
        <Status
          title={getCompetitionStatus(t, status)}
          statusColor={getStatusColor(status)}
        />
      ),
    },
  ];

  const handleAssign = () => {
    closeModal();

    dispatch(
      assignmentsActions.assignRegionalCompetitionManager({
        reloadCallback,
        personId,
        competitionId: selected,
      }),
    );
  };

  const onPageChange = ({ page }: { page: number }) => setPage(page);

  const pagination = {
    page,
    pages,
    limit: perPage,
    total: allCompetitionsPagination?.total,
    reloadData: onPageChange,
  };

  useEffect(() => {
    dispatch(
      assignmentsActions.getAllCompetitions({
        queryParams: new URLSearchParams([
          ['query', ''],
          ['page', '1'],
          ['limit', limit.toString()],
          ['filter[isSubmitted]', '1'],
        ]),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      assignmentsActions.getAllCompetitions({
        queryParams: new URLSearchParams([
          ['query', query],
          ['page', '1'],
          ['limit', limit.toString()],
          ['filter[isSubmitted]', '1'],
        ]),
      }),
    );
  }, [query, dispatch]);

  return (
    <Dialog onClose={handleClose} open={isModalOpen} maxWidth="lg" fullWidth>
      <DialogTitle>{t('Assign competition')}</DialogTitle>
      <DialogContent>
        <Box mb={3} display="flex">
          <ConnectedText
            textEntries={[
              t('Select a competition you want to assign to'),
              ' ',
              { value: personName, boldText: true },
            ]}
          />
        </Box>
        <Box mb={3}>
          <Card>
            <Box overflow="hidden auto">
              {isLoading && allCompetitions === null ? (
                <FullScreenSpinner />
              ) : (
                allCompetitions &&
                allCompetitionsPagination && (
                  <CustomTable
                    columns={columns}
                    isLoading={isLoading}
                    data={data}
                    options={{
                      toolbar: false,
                      actionsColumnIndex: 0,
                    }}
                    tableSearch={{ query, onSearch: handleSearch }}
                    rowActions={{
                      handleRowClick: toggleSelected,
                    }}
                    disableBodyElevation
                    pagination={pagination}
                  />
                )
              )}
            </Box>
          </Card>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={handleAssign}
            disabled={!selected}
            data-qa="assign-button"
          >
            {t('Assign')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssignCompetitionModal;
