import { NAMESPACE } from './constants';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import actions from './actions';
import type { DefaultState } from './reducer';
import type { State } from '@core/store';

// TODO: update core state typing with modals;
// @ts-ignore
export const getTeams = (state: State): DefaultState['teams'] =>
  state.modals[NAMESPACE].teams;

export const areTeamsLoading = createLoadingSelector([
  actions.getTeamsRequest.toString(),
]);
