import React from 'react';
import { Grid, Alert, AlertTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { path, length } from 'ramda';

import {
  AddedMatchPlayerList,
  AddedMatchTeamOfficialList,
  MatchModel,
  MatchSheetErrorType,
} from '@core/types';
import { getMatchSheetValidationErrors } from '@core/store/modules/tabs/match-sheet-management/helpers';

export interface MatchSheetAlert {
  matchDetails?: MatchModel;
  addedLineup?: AddedMatchPlayerList;
  addedTeamOfficials?: AddedMatchTeamOfficialList;
}

const MatchSheetAlert = ({
  matchDetails,
  addedLineup,
  addedTeamOfficials,
}: MatchSheetAlert) => {
  const matchSheetValidationErrors = getMatchSheetValidationErrors(
    matchDetails,
    addedLineup,
    addedTeamOfficials,
  );
  const competitionSettingsProperties = [
    '_embedded',
    'competition',
    'competitionSettings',
  ];
  const minimumPlayersInLineup = path(
    [...competitionSettingsProperties, 'minimumPlayersInLineup'],
    matchDetails,
  );
  const maximumPlayersInLineup = path(
    [...competitionSettingsProperties, 'maximumPlayersInLineup'],
    matchDetails,
  );
  const maximumTeamOfficialsInLineup = path(
    [...competitionSettingsProperties, 'maximumTeamOfficialsInLineup'],
    matchDetails,
  );
  const { t } = useTranslation();
  if (length(matchSheetValidationErrors) <= 0) return <></>;

  return (
    <Box mb={2}>
      <Alert severity="warning">
        <AlertTitle>
          <strong>{t('Match sheet is not complying with requirements')}</strong>
        </AlertTitle>
        {matchSheetValidationErrors.includes(
          MatchSheetErrorType.TOTAL_PLAYERS,
        ) && (
          <Grid>
            {`• ${t(
              'Total number of players (starting + subs)',
            )}: ${minimumPlayersInLineup} min - ${maximumPlayersInLineup} max`}
          </Grid>
        )}
        {matchSheetValidationErrors.includes(
          MatchSheetErrorType.TEAM_OFFICIALS,
        ) && (
          <Grid>{`• ${t(
            'Number of team officials',
          )}: ${maximumTeamOfficialsInLineup} max`}</Grid>
        )}
      </Alert>
    </Box>
  );
};

export default MatchSheetAlert;
