import Formatter from 'php-date-formatter';
import { pipe, replace, isEmpty } from 'ramda';
import dayjs from 'dayjs';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_TIME_FORMAT_AM_PM,
} from '@core/constants/common';

export const phpDateTimeFormatter = new Formatter();

const dateFormatWithFallback = (dateFormat: string) =>
  isEmpty(dateFormat) ? DEFAULT_DATE_FORMAT : dateFormat;
const timeFormatWithFallback = (dateFormat: string) =>
  isEmpty(dateFormat) ? DEFAULT_DATE_TIME_FORMAT : dateFormat;

export const formatDate = (dateFormat: string | undefined, date: any) => {
  if (!dateFormat) {
    return date;
  }
  if (!(typeof date === 'string')) return date;

  dateFormat = dateFormatWithFallback(dateFormat);

  return phpDateTimeFormatter.formatDate(
    phpDateTimeFormatter.parseDate(date, DEFAULT_DATE_FORMAT),
    dateFormat,
  );
};

export const formatTime = (
  timeFormat: string | undefined,
  time: any,
): string => {
  if (!timeFormat) {
    return time;
  }

  if (!(typeof time === 'string')) return time;

  timeFormat = timeFormatWithFallback(timeFormat);

  const isAmPm = timeFormat.includes(' a') || timeFormat.includes(' A');
  const isTimeOnly = !dayjs(time).isValid();

  const dateParsed = phpDateTimeFormatter.parseDate(
    time,
    isTimeOnly ? DEFAULT_TIME_FORMAT : DEFAULT_DATE_TIME_FORMAT,
  );

  return phpDateTimeFormatter.formatDate(
    dateParsed,
    isAmPm ? DEFAULT_TIME_FORMAT_AM_PM : DEFAULT_TIME_FORMAT,
  );
};

export const formatDateTime = (
  dateTimeFormat: string | undefined,
  dateTime: any,
): string => {
  if (!dateTimeFormat) {
    return dateTime;
  }

  if (!(typeof dateTime === 'string')) return dateTime;

  dateTimeFormat = timeFormatWithFallback(dateTimeFormat);

  return phpDateTimeFormatter.formatDate(
    phpDateTimeFormatter.parseDate(dateTime, DEFAULT_DATE_TIME_FORMAT),
    dateTimeFormat,
  );
};

export const convertCustomerDateFormatToDayjsFormat = (
  dateFormat: string | undefined,
) => {
  if (!dateFormat) {
    return { format: null };
  }

  return {
    format: pipe(
      replace(/Y/g, 'YYYY'),
      replace(/y/g, 'YY'),
      replace(/m/g, 'MM'),
      replace(/n/g, 'M'),
      replace(/d/g, 'DD'),
      replace(/j/g, 'D'),
    )(dateFormat),
  };
};

export const convertCustomerTimeFormatToDayjsFormat = (
  timeFormat: string | undefined,
) => {
  if (!timeFormat) {
    return {
      format: null,
      ampm: null,
    };
  }
  const isAmPm = timeFormat?.includes(' A') || timeFormat?.includes(' a');

  timeFormat = timeFormatWithFallback(timeFormat);

  return {
    format: pipe(
      replace(/[hH]/g, isAmPm ? 'hh' : 'HH'),
      replace(/i/g, 'mm'),
      replace(/I/g, 'mm'),
    )(timeFormat),
    ampm: isAmPm,
  };
};

export const validateStringAgainstDayjsFormat = (
  input: string,
  format: string,
  allowNull = false,
) => {
  if (allowNull && input === null) {
    return true;
  }

  return dayjs(input, format, true)?.isValid();
};
