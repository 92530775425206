import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Theme,
} from '@mui/material';
import { find, propEq, map } from 'ramda';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, bindActionCreators } from 'redux';

import {
  AlertStatus,
  ColumnWithSubValue,
  PaginationProps,
  TableWithAssign,
} from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDate } from '@core/helpers';
import { CustomerResponse, Genders, Player, PlayerList } from '@core/types';
import { State } from '@core/store';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
  };
}

interface PlayerWithStatus extends Player {
  isAdded?: boolean;
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  clubPlayers: PlayerList;
  clubPlayersPagination: PaginationProps;
  addedCompetitorPlayers: PlayerList;
  updatePlayersList: (players: PlayerList) => void;
  onPageChange: (page: number) => void;
  earliestDateOfBirth: string;
  isLoading?: boolean;
  clubId: number;
  sportId: number;
  gender: keyof typeof Genders;
}

type Props = StateProps & OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
  },
  gridContainer: {
    paddingRight: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
}));

const ClubPlayersTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    clubPlayers,
    clubPlayersPagination,
    addedCompetitorPlayers = [],
    updatePlayersList,
    onPageChange,
    earliestDateOfBirth,
    currentCustomer,
    isLoading,
    actions,
    clubId,
    sportId,
    gender,
  } = props;

  const isClubPlayerAdded = (playerId: number) =>
    find(propEq('id', playerId))(addedCompetitorPlayers);

  const markAddedCompetitorPlayers = (
    players: PlayerList,
  ): Array<PlayerWithStatus> => {
    if (!players) {
      return [];
    }

    return map((player: Player) => {
      if (!addedCompetitorPlayers) {
        return player;
      }

      if (isClubPlayerAdded(player.id)) {
        return { ...player, isAdded: true };
      }

      return player;
    }, players);
  };

  const handleAddToList = (id: number) => {
    const playerToAdd = clubPlayers.find((player) => player.id === id);
    const addedPlayers = addedCompetitorPlayers ? addedCompetitorPlayers : [];

    updatePlayersList([...addedPlayers, playerToAdd]);
  };

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: PlayerWithStatus) => {
        return (
          <ColumnWithSubValue
            subValue={
              rowData.firstName && `${rowData.firstName} ${rowData.familyName}`
            }
            value={
              rowData.localFirstName &&
              `${rowData.localFirstName} ${rowData.localFamilyName}`
            }
            isDisabled={rowData.isAdded}
          />
        );
      },
    },
    {
      name: 'dateOfBirth',
      label: 'Date of birth',
      render: (rowData: PlayerWithStatus) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {formatDate(currentCustomer?.dateFormat, rowData.dateOfBirth)}
          {earliestDateOfBirth &&
            earliestDateOfBirth >= rowData.dateOfBirth && (
              <AlertStatus title={t('Overage')} />
            )}
        </Typography>
      ),
    },
    {
      name: 'added',
      label: 'Status',
      render: (rowData: PlayerWithStatus) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {rowData.isAdded ? t('added') : ' '}
        </Typography>
      ),
    },
  ];

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    actions.playerListManagement.resetClubPlayers({ clubId });
    actions.playerListManagement.searchClubPlayers({
      clubId,
      queryParams: getSearchClubPlayersQueryParams({
        clubId,
        sportId,
        gender,
        val,
      }),
    });
  };

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Available players')} (${clubPlayersPagination?.total || 0})`}
        </Typography>
      </Grid>
      <Grid className={classes.gridContainer} item xs={12} sm={6}>
        <TextField
          classes={{
            root: classes.root,
          }}
          placeholder={t('Search')}
          id="searchForPlayer"
          onChange={onInputChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon classes={{ root: classes.searchIcon }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            infiniteScroll={{
              loadNext: onPageChange,
              pagination: clubPlayersPagination,
            }}
            title={renderTableTitle()}
            maxHeight={496}
            columns={columns}
            data={markAddedCompetitorPlayers(clubPlayers)}
            isLoading={isLoading}
            handleAdd={handleAddToList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubPlayersTable);

// Only male or female genders
const genderToFilter = (competitionGender: keyof typeof Genders) =>
  competitionGender === Genders.MALE || competitionGender === Genders.FEMALE
    ? competitionGender
    : null;

export function getSearchClubPlayersQueryParams(options: {
  clubId: number;
  sportId: number;
  gender: keyof typeof Genders;
  page?: number;
  val?: string;
}): URLSearchParams {
  const { clubId, sportId, gender, page, val } = options;

  return new URLSearchParams(
    [
      ['filter[club][]', String(clubId)],
      ['filter[sport][]', String(sportId)],
      ['filter[gender][]', genderToFilter(gender)],
      (!!page || page === 0) && ['page', String(page)],
      (!!val || val === '') && ['query', val],
    ].filter(Boolean),
  );
}
