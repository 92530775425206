import restService from '@core/api/rest-service';
import { VenueModel, VenuesTableResponse } from '@core/types';

interface GetVenuesProps {
  queryParams?: {
    query?: string;
    page?: number;
  };
}

export const getVenues = ({ queryParams }: GetVenuesProps = {}) =>
  restService.get<VenuesTableResponse>('/v1/venues', { params: queryParams });

export const getVenue = (venueId: number) =>
  restService.get(`/v1/venues/${venueId}`);

export const deleteVenue = (venueId: number) =>
  restService.delete(`/v1/venues/${venueId}`);

export const createVenue = (data: VenueModel) =>
  restService.post(`/v1/venues`, data);

export const patchVenue = (venueId: number, data: VenueModel) =>
  restService.patch(`/v1/venues/${venueId}`, data);
