import React from 'react';
import { Grid, Box, Typography, TypographyProps } from '@mui/material';

interface OwnProps {
  textEntries: Array<string | Array<string>>;
  color?: string;
  variant?: TypographyProps['variant'];
}

type Props = OwnProps;

const DottedText = (props: Props) => {
  const { textEntries, color = 'inherit', variant = 'caption' } = props;
  const dotColor = color === 'text.secondary' ? 'gray' : '';

  return (
    <Box display="flex" flexWrap="wrap">
      {textEntries.map((textEntry: string | Array<string>, index: number) => (
        <Box key={index} display="flex" alignItems="center">
          {Array.isArray(textEntry) ? (
            <Box display="flex" flexWrap="wrap">
              {textEntry.map((childEntry: string, childIndex: number) => (
                <Box key={childIndex} display="flex">
                  <Typography variant={variant} sx={{ color }} noWrap>
                    {childEntry}
                  </Typography>
                  {childIndex !== textEntry.length - 1 && (
                    <Grid style={{ whiteSpace: 'pre' }}>{` `}</Grid>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant={variant} sx={{ color }} noWrap>
              {textEntry}
            </Typography>
          )}
          {index !== textEntries.length - 1 && (
            <Grid
              sx={{ whiteSpace: 'pre', fontSize: '0.875rem', color: dotColor }}
            >{` • `}</Grid>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default DottedText;
