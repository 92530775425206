import { all } from 'redux-saga/effects';

import competitionProfileSaga from '@core/store/modules/tabs/competition-profile/sagas';
import competitorAdditionSaga from '@core/store/modules/tabs/competitor-addition/sagas';
import editKitSetSaga from '@core/store/modules/tabs/edit-kit-set/sagas';
import competitionContactsEditSaga from '@core/store/modules/tabs/competition-contacts-edit/sagas';
import matchProfileSaga from '@core/store/modules/tabs/match-profile/sagas';
import matchSheetManagementSaga from '@core/store/modules/tabs/match-sheet-management/sagas';
import competitorProfileSaga from '@core/store/modules/tabs/competitor-profile/sagas';
import editStageSaga from '@core/store/modules/tabs/edit-stage/sagas';
import competitorKitSetSaga from '@core/store/modules/tabs/competitor-kit-set/sagas';
import competitorGeneralInformationEditSaga from '@core/store/modules/tabs/competitor-general-information-edit/sagas';
import playerListManagementSaga from '@core/store/modules/tabs/player-list-management/sagas';
import createKitSetSaga from '@core/store/modules/tabs/create-kit-set/sagas';

import matchDataManagementSaga from './match-data-management/sagas';
import stageFixtureSettingsSaga from './stage-fixture-settings/sagas';
import imposeSanctionSaga from './impose-sanction/sagas';

export default function* saga() {
  yield all([
    competitorProfileSaga(),
    playerListManagementSaga(),
    matchProfileSaga(),
    matchSheetManagementSaga(),
    matchDataManagementSaga(),
    createKitSetSaga(),
    editKitSetSaga(),
    competitorAdditionSaga(),
    competitionProfileSaga(),
    stageFixtureSettingsSaga(),
    competitorKitSetSaga(),
    competitorGeneralInformationEditSaga(),
    competitionContactsEditSaga(),
    imposeSanctionSaga(),
    editStageSaga(),
  ]);
}
