import { createAction } from 'redux-actions';

import { QueryParams, CompetitionsList } from '@core/types';

import { NAMESPACE } from './constants';

const setCompetitions = createAction<CompetitionsList>(
  `${NAMESPACE}/SET_COMPETITIONS`,
);
const getCompetitions = createAction<QueryParams>(
  `${NAMESPACE}/GET_COMPETITIONS`,
);
const getCompetitionsRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITIONS_REQUEST`,
);
const getCompetitionsSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITIONS_SUCCESS`,
);
const getCompetitionsFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITIONS_FAILURE`,
);
const searchCompetitionsByQuery = createAction<QueryParams>(
  `${NAMESPACE}/SEARCH_COMPETITIONS_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setCompetitions,
  getCompetitions,
  getCompetitionsRequest,
  getCompetitionsSuccess,
  getCompetitionsFailure,
  searchCompetitionsByQuery,
  resetState,
};
