import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompetitionResponse, Group } from '@core/types';
import { KNOCKOUT_STANDINGS_BRACKETS } from './__tests__/test-ids';
import KnockoutBrackets from './knockout-brackets/knockout-brackets';
import { FullScreenSpinner, NoDataAlert } from '@core/components';
import { useGetBracketsQuery } from '@core/services/groups';
import { useRef } from 'react';
import KnockoutBracketsDownload from './knockout-brackets/knockout-brackets-download';
import KnockoutBracketsRounds from './knockout-brackets/knockout-brackets-rounds';

type Props = {
  stage: Group;
  competition: CompetitionResponse;
};

const BracketsTab = ({ stage, competition }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBracketsQuery(stage.id);
  const bracketsRef = useRef();
  const hasItems = data?.items.length > 0;

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  if (!hasItems) {
    return <NoDataAlert text={t('No bracket data available')} />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <KnockoutBracketsDownload
          stage={stage}
          competition={competition}
          bracketsRef={bracketsRef}
        />
      </Box>
      <Box mt={5}>
        <Box sx={{ overflow: 'auto' }} data-qa={KNOCKOUT_STANDINGS_BRACKETS}>
          <div ref={bracketsRef}>
            <KnockoutBracketsRounds rounds={stage.childGroups} data={data} />
            <KnockoutBrackets data={data} />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default BracketsTab;
