import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { GlobalModalProps } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as personsActions } from '@core/store/modules/api/persons';
import { FileUploadModal } from '@core/components';
import { usePersonMutatedHelpers } from '@core/hooks';

interface StateProps {
  isUploading: boolean;
}

interface DispatchProps {
  actions: {
    persons: typeof personsActions;
  };
}

export interface OwnProps {
  clubId: number | undefined;
}

type Props = StateProps & DispatchProps & GlobalModalProps<OwnProps>;

const PersonsUploadModal = (props: Props) => {
  const { actions, isUploading, modalParams, modalActions, ...rest } = props;
  const { clubId } = modalParams;
  const { t } = useTranslation();

  const { refreshPersonsListInAnyTab } = usePersonMutatedHelpers({ clubId });

  const onSuccess = () => {
    modalActions.closeModal();
    refreshPersonsListInAnyTab();
  };

  const handleFileSelect = (value: any) => {
    actions.persons.uploadPersons({
      clubId,
      file: value,
      onSuccess,
    });
  };

  const handleDownloadTemplate = () => {
    actions.persons.exportPersonsTemplate({ clubId });
  };

  return (
    <FileUploadModal
      title={t('Import persons')}
      handleFileSelect={handleFileSelect}
      label={t('To import persons, select a CSV file.')}
      modalParams={modalParams}
      modalActions={modalActions}
      accept=".csv"
      additionalCheck={
        <Button variant="text" color="primary" onClick={handleDownloadTemplate}>
          Download a template
        </Button>
      }
      {...rest}
    />
  );
};

const uploadingLoadingSelector = createLoadingSelector([
  personsActions.uploadPersonsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isUploading: uploadingLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    persons: bindActionCreators(personsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonsUploadModal);
