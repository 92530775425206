import React from 'react';
import { uuid } from '@core/helpers';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
} from '@mui/material';

import { ChipsSelect, NumberField } from '@core/components';
import { getPoolTypes } from '@core/helpers';
import { StageType } from '@core/types';

import PoolsTable from './components/pools-table';
import { FIELD_NAMES } from '../../constants';
import useStyles from '../../styles';
import { FormValues } from '../../helpers/initialValues';
import { getNumberOfTeamsMinMax } from '../../helpers/validationSchema';

const RoundRobinAccordion = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const getPoolLetter = (index: number) =>
    String.fromCharCode('A'.charCodeAt(0) + index);

  const handleNumberOfPoolsChange = (prevValue: any, value: any) => {
    // Update dynamic form with round robin groups
    const groups = [...values.roundRobinGroups];
    const numberOfGroups = value || 0;
    const previousNumber = prevValue || 0;

    if (previousNumber < numberOfGroups) {
      for (let i = previousNumber; i < numberOfGroups; i++) {
        groups.push({
          id: uuid(),
          index: i,
          title: `${t('Pool')} ${getPoolLetter(i)}`,
          numberOfTeams: 2,
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfGroups; i--) {
        groups.splice(i, 1);
      }
    }

    setFieldValue(FIELD_NAMES.roundRobinGroups, groups);
  };

  return (
    <Box mb={1}>
      <Paper>
        <Accordion expanded={values.stageType === StageType.ROUND_ROBIN}>
          <AccordionSummary classes={{ root: classes.accordionSummary }}>
            <FormControlLabel
              control={<Radio color="primary" />}
              label={t('Round robin')}
              style={{ width: '100%' }}
              value={StageType.ROUND_ROBIN}
            />
          </AccordionSummary>
          {values.stageType === StageType.ROUND_ROBIN && (
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <ChipsSelect
                    fieldName={FIELD_NAMES.numberOfPools}
                    data-qa={FIELD_NAMES.numberOfPools}
                    onChange={handleNumberOfPoolsChange}
                    options={getPoolTypes(t)}
                    required
                    title={t('Number of pools')}
                  />
                </Grid>
                {values?.[FIELD_NAMES.numberOfPools] > 0 && (
                  <Grid item xs={12}>
                    <Box mx={1}>
                      <PoolsTable groups={values.roundRobinGroups} />
                    </Box>
                  </Grid>
                )}
                {!values?.[FIELD_NAMES.numberOfPools] && (
                  <Grid item xs={12} md={6}>
                    <NumberField
                      name={FIELD_NAMES.numberOfTeams}
                      data-qa={FIELD_NAMES.numberOfTeams}
                      label={t('Number of teams')}
                      required
                      {...getNumberOfTeamsMinMax({
                        knockoutDoubleElimination: undefined,
                        knockoutElimination: undefined,
                        numberOfPoolsValue: 0,
                        stageType: StageType.ROUND_ROBIN,
                        variant: 'stage',
                      })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <NumberField
                    name={FIELD_NAMES.numberOfRounds}
                    data-qa={FIELD_NAMES.numberOfRounds}
                    label={t('Times teams play each other')}
                    min={1}
                    required
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      </Paper>
    </Box>
  );
};

export default RoundRobinAccordion;
