import { createActions } from 'redux-actions';

export const NAMESPACE = 'matchPlayers';

export const actions = createActions(
  'GET_PLAYERS_REQUEST',
  'GET_PLAYERS_SUCCESS',
  'GET_PLAYERS_FAILURE',

  'CHANGE_PLAYER_LIST_REQUEST',
  'CHANGE_PLAYER_LIST_SUCCESS',
  'CHANGE_PLAYER_LIST_FAILURE',

  // 'UPDATE_AWAY_PLAYER_REQUEST',
  // 'UPDATE_AWAY_PLAYER_SUCCESS',
  // 'UPDATE_AWAY_PLAYER_FAILURE',

  'GET_PREVIOUS_PLAYERS_REQUEST',
  'GET_PREVIOUS_PLAYERS_SUCCESS',
  'GET_PREVIOUS_PLAYERS_FAILURE',

  { prefix: NAMESPACE },
) as unknown as {
  getPlayersRequest: RequestLifecycleAction;
  getPlayersSuccess: RequestLifecycleAction;
  getPlayersFailure: RequestLifecycleAction;
  changePlayerListRequest: RequestLifecycleAction;
  changePlayerListSuccess: RequestLifecycleAction;
  changePlayerListFailure: RequestLifecycleAction;
  // updateAwayPlayerRequest: RequestLifecycleAction;
  // updateAwayPlayerSuccess: RequestLifecycleAction;
  // updateAwayPlayerFailure: RequestLifecycleAction;
  getPreviousPlayersRequest: RequestLifecycleAction;
  getPreviousPlayersSuccess: RequestLifecycleAction;
  getPreviousPlayersFailure: RequestLifecycleAction;
};
