import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Tabs, TabPanel, TabsComponentProps } from '@core/components';
import {
  MATCH_TABS,
  MATCH_LINEUP_TAB,
  MATCH_DATA_TAB,
  MATCH_OFFICIALS_TAB,
} from '@core/pages/matches/tests/test-ids';
import MatchOfficialAllocations from '@core/components/match-official-allocations';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import { getMatchCompetitorsByMatchId } from '@core/store/modules/tabs/match-profile/selectors';
import MatchLineupTab from '@core/pages/match-profile/components/match-lineup-tab';
import {
  Group,
  MatchCompetitorsResponse,
  MatchModel,
  MatchSet,
} from '@core/types';
import { State } from '@core/store';
import MatchDataTab from './match-data-tab';
// import MatchDocuments from './documents-tab';

interface OwnProps {
  competitionId: number;
  group: Group;
  matchId: number;
  match: MatchModel;
  matchSets: Array<MatchSet>;
  stageId: number | undefined;
}

interface StateProps {
  competitors: MatchCompetitorsResponse;
}

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

const MatchTabs = (props: Props) => {
  const {
    competitionId,
    competitors,
    group,
    match,
    matchId,
    stageId,
    actions,
    matchSets,
  } = props;
  const { t } = useTranslation();

  const haveConflicts =
    match?.conflicts &&
    Object.keys(match?.conflicts).findIndex(
      (key) => match?.conflicts[key] === true,
    ) !== -1;

  const {
    //matchEvents,
    //matchCommissionerMatchNotes,
    //refereeMatchNotes,
    //matchScores,
    matchOfficialAllocations,
    //matchDurationList,
    //matchDocuments,
  } = match?._links ?? {};
  // const matchDataLocked = !(
  //   matchEvents ||
  //   matchCommissionerMatchNotes ||
  //   refereeMatchNotes ||
  //   matchScores ||
  //   matchOfficialAllocations ||
  //   matchDurationList
  // );

  useEffect(() => {
    actions.matchProfile.getMatchCompetitors(match.id);
  }, []);

  const tabs: TabsComponentProps['tabs'] = [
    {
      value: 'match-lineup',
      component: MatchLineupTab,
      label: t('Match Lineup'),
      testId: MATCH_LINEUP_TAB,
      withWarning: haveConflicts,
    },
    {
      value: 'match-result',
      component: MatchDataTab,
      label: t('Match Data'),
      testId: MATCH_DATA_TAB,
      // locked: matchDataLocked,
      // tooltipText: matchDataLocked && t('Match data will be available when the Match is marked as Complete'),
    },
    // ...(isDocumentsUpload && matchDocuments
    //   ? [
    //       {
    //         value: 'documents',
    //         component: MatchDocuments,
    //         label: t('Documents'),
    //         testId: MATCH_DOCUMENTS_TAB,
    //       },
    //     ]
    //   : []),
    match._links.matchOfficialAllocations && {
      value: 'match-officials',
      component: MatchOfficialAllocations,
      label: t('Match Officials'),
      testId: MATCH_OFFICIALS_TAB,
      locked: !matchOfficialAllocations,
      tooltipText:
        !matchOfficialAllocations && t('No permission to view Match Officials'),
    },
  ].filter(Boolean);

  return (
    <Tabs tabs={tabs} fullWidth initialTab={tabs[0].value} testId={MATCH_TABS}>
      {(currentTab) => (
        <TabPanel
          currentTab={currentTab}
          tabs={tabs}
          props={{
            competitionId,
            competitors,
            group,
            match,
            matchId,
            stageId,
            matchSets,
            topBorder: true,
          }}
        />
      )}
    </Tabs>
  );
};

const mapStateToProps = (state: State, { matchId }: OwnProps): StateProps => ({
  competitors: getMatchCompetitorsByMatchId(state, { matchId }),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchTabs);
