import { call, Effect, put, SagaReturnType } from 'redux-saga/effects';

import { Season } from '@core/types';
import * as apiSeasons from '@core/api/seasons';

import { actions as seasonsActions } from '.';

export function* getSeason(seasonId: number): Generator<Effect, any, any> {
  yield put(seasonsActions.getCompetitionSeasonRequest());
  const response = yield call(apiSeasons.getSeason, seasonId);

  if (!response.error) {
    yield put(seasonsActions.getCompetitionSeasonSuccess());
    yield put(seasonsActions.setCompetitionSeason(response.response));
  } else {
    yield put(seasonsActions.getCompetitionSeasonFailure());
  }

  return response;
}

export function* getSeasons(queryParams: {
  query?: string;
  page?: number;
}): Generator<Effect, SagaReturnType<typeof apiSeasons.getSeasons>, any> {
  yield put(seasonsActions.getCompetitionSeasonsRequest());
  const response = yield call(apiSeasons.getSeasons, { queryParams });

  if (!response.error) {
    yield put(seasonsActions.getCompetitionSeasonsSuccess());
    yield put(
      seasonsActions.setCompetitionSeasonList(
        response.response._embedded.seasons,
      ),
    );
  } else {
    yield put(seasonsActions.getCompetitionSeasonsFailure());
  }

  return response;
}

export function* createSeason(seasonData: Season): Generator<Effect, any, any> {
  yield put(seasonsActions.createCompetitionSeasonRequest());
  const response = yield call(apiSeasons.createSeason, seasonData);

  if (!response.error) {
    yield put(seasonsActions.createCompetitionSeasonSuccess());
  } else {
    yield put(seasonsActions.createCompetitionSeasonFailure());
  }

  return response;
}

export function* patchSeason(
  seasonId: number,
  seasonData: Season,
): Generator<Effect, SagaReturnType<typeof apiSeasons.patchSeason>, any> {
  yield put(seasonsActions.patchCompetitionSeasonRequest());
  const response = yield call(apiSeasons.patchSeason, seasonId, seasonData);

  if (!response.error) {
    yield put(seasonsActions.patchCompetitionSeasonSuccess());
  } else {
    yield put(seasonsActions.patchCompetitionSeasonFailure());
  }

  return response;
}

export function* deleteSeason(
  seasonId: number,
): Generator<Effect, SagaReturnType<typeof apiSeasons.deleteSeason>, any> {
  yield put(seasonsActions.deleteCompetitionSeasonRequest());
  const response = yield call(apiSeasons.deleteSeason, seasonId);

  if (!response.error) {
    yield put(seasonsActions.deleteCompetitionSeasonSuccess());
  } else {
    yield put(seasonsActions.deleteCompetitionSeasonFailure());
  }

  return response;
}
