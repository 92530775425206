import restService from '@core/api/rest-service';
import {
  MatchNotesDescriptionsModel,
  MatchNotesTitlesModel,
  MatchNotesDescriptionSubmitData,
} from '@core/types';

export const getMatchCommissionerNoteDescriptions = (matchId: number) =>
  restService.get<MatchNotesDescriptionsModel>(
    `/v1/matches/${matchId}/matchCommissionerNoteDescriptions`,
  );

export const getMatchCommissionerNoteTitles = (matchId: number) =>
  restService.get<MatchNotesTitlesModel>(
    `/v1/matches/${matchId}/matchCommissionerNoteTitles`,
  );

export const postMatchCommissionerNotesDescription = (
  matchId: number,
  matchCommissionerNoteDescriptions: MatchNotesDescriptionSubmitData,
) =>
  restService.post<MatchNotesDescriptionsModel>(
    `/v1/matches/${matchId}/rewriteMatchCommissionerNoteDescriptions`,
    {
      matchCommissionerNoteDescriptions,
    },
  );
