import React from 'react';
import { Grid, Typography, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Target } from '@core/types';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'capitalize',
    padding: theme.spacing(2),
  },
}));

interface FooterLink {
  href: string;
  label: string;
  target?: Target;
}

export const FooterMenu = () => {
  const DEFAULT_TARGET: Target = '_blank';
  const { t } = useTranslation();
  const classes = useStyles();
  const footerLinks: Array<FooterLink> = [
    {
      href: createURL(paths.supportCenter),
      label: t('Support Center'),
    },
    {
      href: createURL(paths.termsAndConditions),
      label: t('Terms and Conditions'),
    },
    {
      href: createURL(paths.cookiePolicy),
      label: t('Cookie Policy'),
    },
    {
      href: createURL(paths.privacyStatement),
      label: t('Privacy Statement'),
    },
  ];

  return (
    <Grid container>
      {footerLinks.map((link) => (
        <Grid item key={link.label}>
          <Typography align="center">
            <Link
              className={classes.link}
              to={link.href}
              target={link.target || DEFAULT_TARGET}
              component={RouterLink}
            >
              {link.label}
            </Link>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
