import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import {
  useAuth0,
  Auth0ContextInterface,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import {
  createURL,
  getAuthProvider,
  getCustomerCode,
  setAccessToken,
  setAuthProvider,
  setSessionDuration,
  getSessionExpirationTime,
} from '@core/helpers';
import paths from '@core/routes/paths';
import { CustomerResponse } from '@core/types';

type GetAccessTokenSilently = Auth0ContextInterface['getAccessTokenSilently'];
type Logout = Auth0ContextInterface['logout'];
type RedirectLoginOptionsOrResult<T extends { appState?: any }> = Omit<
  T,
  'appState'
> & {
  appState: { pathnameToRedirect: string };
};
type TypedRedirectLoginOptions =
  RedirectLoginOptionsOrResult<RedirectLoginOptions>;

const getAccessTokenSilentlyRef: { current: GetAccessTokenSilently | null } = {
  current: null,
};
const setAuth0GetAccessTokenSilently = (value: GetAccessTokenSilently) =>
  (getAccessTokenSilentlyRef.current = value);
export const getAuth0GetAccessTokenSilently =
  (): GetAccessTokenSilently | null => getAccessTokenSilentlyRef.current;

const auth0LogoutRef: { current: Logout | null } = { current: null };
const setAuth0Logout = (value: Logout) => (auth0LogoutRef.current = value);
export const getAuth0Logout = (): Logout | null => auth0LogoutRef.current;

export const getDefaultRouteOnLogin = () => createURL(paths.home);

const RETURN_TO_URL_SEARCH_PARAM_KEY = 'customer';

const getBase = () => window.location.origin;

export const getAuth0ReturnTo = () => {
  const pathname = createURL(paths.loggedOut);
  const search = `?${new URLSearchParams({
    [RETURN_TO_URL_SEARCH_PARAM_KEY]: getCustomerCode() as string,
  })}`;

  return new URL(pathname + search, getBase()).toString();
};

export const getAuth0RedirectUri = () => {
  return new URL(createURL(paths.authenticated), getBase()).toString();
};

export const useAuth0ReturnTo = () => {
  const { search } = useLocation();
  const customerCode = new URLSearchParams(search).get(
    RETURN_TO_URL_SEARCH_PARAM_KEY,
  );

  return customerCode ? createURL(paths.login, customerCode) : null;
};

/** use only once at app root to wait for auth0 */
export const useAuth0IsLoading = () => {
  const { isLoading } = useAuth0();

  return { isLoading };
};

/**
 * use only once at app root to initiate singletons to auth0 functions
 * so that auth0 instance functions could be called outside react context
 * i. e. inside axios interceptors and inside redux sagas
 * */
export const useAuth0Singleton = () => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      setAuth0GetAccessTokenSilently(getAccessTokenSilently);
      setAuth0Logout(logout);
    }
  }, [isAuthenticated, getAccessTokenSilently, logout]);
};

export const useAuth0LoginWithRedirect = (
  currentCustomer: CustomerResponse,
) => {
  const { loginWithRedirect } = useAuth0();

  const pathnameToRedirect = getDefaultRouteOnLogin();

  const options: TypedRedirectLoginOptions = {
    appState: { pathnameToRedirect },
    authorizationParams: {
      organization: currentCustomer?.organizationId,
    },
  };

  return () => loginWithRedirect(options);
};

export const useAuth0HandleRedirectCallback = () => {
  const { error, isLoading, handleRedirectCallback } = useAuth0();

  const [pathnameToRedirect, setPathnameToRedirect] = useState<string>('');

  useEffect(() => {
    type TypedRedirectLoginResult = RedirectLoginOptionsOrResult<
      InferPromise<ReturnType<typeof handleRedirectCallback>>
    >;

    const handleRedirect = async () => {
      const { pathnameToRedirect: pathname } = (
        (await handleRedirectCallback()) as TypedRedirectLoginResult
      ).appState;

      setPathnameToRedirect(pathname);
    };

    handleRedirect();
  }, []);

  useEffect(() => {
    if (!isLoading && pathnameToRedirect) {
      setAuthProvider('auth0');
      setAccessToken();
      setSessionDuration(30 * 60 * 1000);
    }
  });

  return { error, pathnameToRedirect };
};

export const useIsLoggedIn = () => {
  const { isAuthenticated: isAuthenticatedToAuth0, isLoading } = useAuth0();
  const authProvider = getAuthProvider() as any as 'cognito' | 'auth0';
  const sessionExpirationTime = getSessionExpirationTime();

  let isAuthenticated = false;
  switch (authProvider) {
    case 'auth0': {
      isAuthenticated = isAuthenticatedToAuth0;
      break;
    }
    case 'cognito': {
      isAuthenticated = dayjs().isBefore(sessionExpirationTime);
      break;
    }
  }

  return { isLoading, isAuthenticated };
};
