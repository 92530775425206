import { User, UserRoleType } from '@core/types';

export const getRegionalCompetitionManagerId = (user: User) => {
  const foundRegionalCompetitionManager = user.userRoles.find(
    (userRole) =>
      userRole.role === UserRoleType.ROLE_REGIONAL_COMPETITION_MANAGER,
  );

  return foundRegionalCompetitionManager?.id;
};
