import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, TabPanel } from '@core/components';

import { getCompetitorTabs } from './tabs-mapping';

interface OwnProps {
  teamId: number;
  competitionId: number;
  competitorId: number;
  playersCount: number;
}

type Props = OwnProps;

const CompetitorProfileTabs = (props: Props) => {
  const { competitionId, teamId, competitorId, playersCount } = props;
  const { t } = useTranslation();
  const tabsMapping = getCompetitorTabs(t, playersCount === 0);

  return (
    <Tabs fullWidth tabs={tabsMapping}>
      {(currentTab) => (
        <TabPanel
          currentTab={currentTab}
          tabs={tabsMapping}
          props={{ competitionId, teamId, competitorId }}
        />
      )}
    </Tabs>
  );
};

export default CompetitorProfileTabs;
