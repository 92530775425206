import { AutocompleteOption } from '@core/types';
import {
  OffenderType,
  CompetitionResponse,
  SanctionType,
  DisciplinarySanction,
} from '@volleyball/types';

import {
  MATCH_SANCTION_FIELDS,
  FINANCIAL_PENALTY_FIELDS,
  OFFENDER_TYPE_FIELDS,
} from '../constants';

export interface MarchSuspensionFormValues {
  [MATCH_SANCTION_FIELDS.commment]: string;
  [MATCH_SANCTION_FIELDS.competitionSeason]: string;
  [MATCH_SANCTION_FIELDS.competitionTitle]: string;
  [OFFENDER_TYPE_FIELDS.competitor]: AutocompleteOption;
  [MATCH_SANCTION_FIELDS.matchId]: number | null;
  [MATCH_SANCTION_FIELDS.numberOfMatches]: number | null;
  [OFFENDER_TYPE_FIELDS.offenderType]: OffenderType;
  [OFFENDER_TYPE_FIELDS.player]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.teamOfficial]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.offender]: string;
  [FINANCIAL_PENALTY_FIELDS.currency]?: string; // TODO remove in future
}

const getOffenderName = (sanction: DisciplinarySanction) => {
  const offenderName = `${
    sanction?.player?.firstName ||
    sanction?.player?.localFirstName ||
    sanction?.matchOfficial?.firstName ||
    sanction?.matchOfficial?.localFirstName ||
    sanction?.teamOfficial?.firstName ||
    sanction?.teamOfficial?.localFirstName ||
    ''
  } ${
    sanction?.player?.familyName ||
    sanction?.player?.localFamilyName ||
    sanction?.matchOfficial?.familyName ||
    sanction?.matchOfficial?.localFamilyName ||
    sanction?.teamOfficial?.familyName ||
    sanction?.teamOfficial?.localFamilyName ||
    ''
  }`;

  return offenderName;
};

export const getMatchSuspensionDefaultValues = (
  competition: CompetitionResponse,
  sanction: DisciplinarySanction,
): MarchSuspensionFormValues => ({
  [MATCH_SANCTION_FIELDS.commment]: sanction?.comment || '',
  [MATCH_SANCTION_FIELDS.competitionSeason]:
    competition?.competitionSeasonTitle,
  [MATCH_SANCTION_FIELDS.competitionTitle]: competition?.title,
  [OFFENDER_TYPE_FIELDS.competitor]: null,
  [MATCH_SANCTION_FIELDS.matchId]: sanction?.matchId || null,
  [MATCH_SANCTION_FIELDS.numberOfMatches]: sanction?.penalty || null,
  [OFFENDER_TYPE_FIELDS.offenderType]:
    sanction?.offenderType || OffenderType.PLAYER,
  [OFFENDER_TYPE_FIELDS.player]: null,
  [OFFENDER_TYPE_FIELDS.teamOfficial]: null,
  [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: null,
  [OFFENDER_TYPE_FIELDS.offender]: getOffenderName(sanction),
});

export interface FinancialPenaltyFormValues {
  [FINANCIAL_PENALTY_FIELDS.commment]: string;
  [FINANCIAL_PENALTY_FIELDS.competitionSeason]: string;
  [FINANCIAL_PENALTY_FIELDS.competitionTitle]: string;
  [OFFENDER_TYPE_FIELDS.competitor]: AutocompleteOption;
  [FINANCIAL_PENALTY_FIELDS.matchId]: number | null;
  [FINANCIAL_PENALTY_FIELDS.amount]: number | null;
  [OFFENDER_TYPE_FIELDS.offenderType]: OffenderType;
  [OFFENDER_TYPE_FIELDS.player]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.teamOfficial]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.matchOfficial]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.competitionTeam]: AutocompleteOption;
  [OFFENDER_TYPE_FIELDS.offender]: string;
  [FINANCIAL_PENALTY_FIELDS.currency]?: string;
}

export const getFinancialPenaltyDefaultValues = (
  competition: CompetitionResponse,
  sanction: DisciplinarySanction,
): FinancialPenaltyFormValues => ({
  [FINANCIAL_PENALTY_FIELDS.commment]: sanction?.comment || '',
  [FINANCIAL_PENALTY_FIELDS.competitionSeason]:
    competition.competitionSeasonTitle,
  [FINANCIAL_PENALTY_FIELDS.competitionTitle]: competition.title,
  [OFFENDER_TYPE_FIELDS.competitor]: null,
  [FINANCIAL_PENALTY_FIELDS.matchId]: sanction?.matchId || null,
  [FINANCIAL_PENALTY_FIELDS.amount]: sanction?.penalty || null,
  [FINANCIAL_PENALTY_FIELDS.currency]: 'EUR',
  [OFFENDER_TYPE_FIELDS.offenderType]:
    sanction?.offenderType || OffenderType.PLAYER,
  [OFFENDER_TYPE_FIELDS.player]: null,
  [OFFENDER_TYPE_FIELDS.teamOfficial]: null,
  [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: null,
  [OFFENDER_TYPE_FIELDS.matchOfficial]: null,
  [OFFENDER_TYPE_FIELDS.competitionTeam]: null,
  [OFFENDER_TYPE_FIELDS.offender]: getOffenderName(sanction),
});

export const getDefaultInitialValues = (
  sanctionType: SanctionType,
  competition: CompetitionResponse,
  sanction?: DisciplinarySanction,
) => {
  if (sanctionType === SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES) {
    return getMatchSuspensionDefaultValues(competition, sanction);
  }
  if (sanctionType === SanctionType.FINANCIAL_PENALTY) {
    return getFinancialPenaltyDefaultValues(competition, sanction);
  }

  return undefined;
};
