import { all, call, Effect, put, SagaReturnType } from 'redux-saga/effects';
import { path } from 'ramda';

import { handleApiErrors } from '@core/helpers';
import * as matchOfficialAllocationAPI from '@core/api/match-official-allocation';
import { actions as matchesAllocationActions } from '@core/store/modules/matches-allocation';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';

import { actions } from '.';

function* getMatchOfficialAllocations(
  matchId: number,
): Generator<Effect, any, any> {
  yield put(actions.getMatchOfficialAllocationsRequest({ matchId }));
  const response = (yield call(
    matchOfficialAllocationAPI.getMatchOfficialAllocations,
    matchId,
  )) as InferApi<typeof matchOfficialAllocationAPI.getMatchOfficialAllocations>;

  if (!response.error) {
    yield put(actions.getMatchOfficialAllocationsSuccess({ matchId }));
  } else {
    yield put(actions.getMatchOfficialAllocationsFailure({ matchId }));
  }

  return response;
}

export function* getMatchOfficialAllocationsFlow() {
  yield takeLeadingByPredicate(
    actions.getMatchOfficialAllocations,
    function* (action): Generator<Effect, any, any> {
      const { payload } = action;

      const { error, response } = (yield call(
        getMatchOfficialAllocations,
        payload,
      )) as SagaReturnType<typeof getMatchOfficialAllocations>;

      if (!error) {
        const matchOfficialsData = path(
          ['_embedded', 'matchOfficialAllocations'],
          response,
        );

        yield put(
          actions.setMatchOfficialAllocations({
            matchId: payload,
            payload: matchOfficialsData,
          }),
        );
      } else {
        yield call(handleApiErrors, error);
      }
    },
  );
}

function* appointMatchOfficial(
  matchId: number,
  allocationData: any,
): Generator<Effect, any, any> {
  yield put(actions.appointMatchOfficialRequest({ matchId }));
  const response = (yield call(
    matchOfficialAllocationAPI.appointMatchOfficial,
    matchId,
    allocationData,
  )) as InferApi<typeof matchOfficialAllocationAPI.appointMatchOfficial>;

  if (!response.error) {
    yield put(actions.appointMatchOfficialSuccess({ matchId }));
  } else {
    yield put(actions.appointMatchOfficialFailure({ matchId }));
  }

  return response;
}

export function* appointMatchOfficialFlow() {
  yield takeLeadingByPredicate(
    actions.appointMatchOfficial,
    function* (action): Generator<Effect, any, any> {
      const { payload } = action;
      const { error } = (yield call(
        appointMatchOfficial,
        payload.matchId,
        payload.data,
      )) as SagaReturnType<typeof appointMatchOfficial>;

      if (!error) {
        yield put(globalModalActions.closeModal());
        yield put(actions.getMatchOfficialAllocations(payload.matchId));

        // Update allocations only in stage view
        if (payload.stageId) {
          yield put(
            matchesAllocationActions.refreshMatchesAllocation({
              stageId: payload.stageId,
            }),
          );
        }
      } else {
        yield call(handleApiErrors, error);
      }
    },
  );
}

function* removeMatchOfficial(
  matchOfficialAppointmentId: number,
  matchId: number,
): Generator<Effect, any, any> {
  yield put(actions.removeMatchOfficialRequest({ matchId }));
  const response = (yield call(
    matchOfficialAllocationAPI.removeMatchOfficial,
    matchOfficialAppointmentId,
  )) as InferApi<typeof matchOfficialAllocationAPI.removeMatchOfficial>;

  if (!response.error) {
    yield put(actions.removeMatchOfficialSuccess({ matchId }));
  } else {
    yield put(actions.removeMatchOfficialFailure({ matchId }));
  }

  return response;
}

export function* removeMatchOfficialFlow() {
  yield takeLeadingByPredicate(
    actions.removeMatchOfficial,
    function* (action): Generator<Effect, any, any> {
      const { payload } = action;

      const { error } = (yield call(
        removeMatchOfficial,
        payload.matchOfficialAppointmentId,
        payload.matchId,
      )) as SagaReturnType<typeof removeMatchOfficial>;

      if (!error) {
        yield put(actions.getMatchOfficialAllocations(payload.matchId));

        // Update allocations only in stage view
        if (payload.stageId) {
          yield put(
            matchesAllocationActions.refreshMatchesAllocation({
              stageId: payload.stageId,
            }),
          );
        }
      } else {
        yield call(handleApiErrors, error);
      }
    },
  );
}

export default function* saga() {
  yield all([
    getMatchOfficialAllocationsFlow(),
    appointMatchOfficialFlow(),
    removeMatchOfficialFlow(),
  ]);
}
