import { call, Effect, put } from 'redux-saga/effects';

import { replaceEmptyToNull } from '@core/helpers';
import * as groupFixturesAPI from '@core/api/group-fixtures';
import { GenerateGroupFixtureSettings } from '@volleyball/types';

import actions from './actions';

export function* regenerateFixtures(
  link: string,
  data: GenerateGroupFixtureSettings,
): Generator<Effect, any, any> {
  yield put(actions.regenerateGroupFixturesRequest());

  const response = yield call(
    groupFixturesAPI.regenerateGroupFixtures,
    link,
    replaceEmptyToNull(data),
  );

  if (!response.error) {
    yield put(actions.regenerateGroupFixturesSuccess());
  } else {
    yield put(actions.regenerateGroupFixturesFailure());
  }

  return response;
}
