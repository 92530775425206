import React from 'react';

import { MTableHeader } from '@core/components/material-table';
import LoadingIndicator from './loading-indicator';

interface OwnProps {
  columns: Array<any>;
  isLoading: boolean;
  headerProps: any;
}

type Props = OwnProps;

const TableHeader = ({ columns, isLoading, headerProps }: Props) => {
  let colSpan = columns.length;

  if (headerProps?.hasDetailPanel) {
    colSpan += 1;
  }

  if (headerProps?.showActionsColumn) {
    colSpan += 1;
  }

  if (headerProps?.options?.selection) {
    colSpan += 1;
  }

  return (
    <>
      <MTableHeader {...headerProps} />
      <LoadingIndicator colSpan={colSpan} isLoading={isLoading} />
    </>
  );
};

export default TableHeader;
