import React from 'react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

import { getMenuRootCssDeclaration } from './helpers';
import * as testIds from '../tests/test-ids';

type Props = MenuProps<AutocompleteOption, boolean>;

const useStyles = makeStyles((theme: Theme) => ({
  root: getMenuRootCssDeclaration(theme),
}));

const Menu = (props: Props) => {
  const {
    selectProps: { menuPosition, tooltipText, width, withTooltipMargin },
  } = props;
  const classes = useStyles({
    menuPosition,
    tooltipText,
    width,
    withTooltipMargin,
  });

  return (
    <Paper
      data-qa={testIds.AUTOCOMPLETE_MENU}
      className={classes.root}
      square
      {...(props.innerProps as any)}
    >
      {props.children}
    </Paper>
  );
};

export default Menu;
