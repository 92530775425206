import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { prop } from 'ramda';

import { Tabs, TabPanel } from '@core/components';
import { MATCH_SHEET_TABS } from '@core/pages/matches/tests/test-ids';
import {
  AddedMatchPlayerList,
  MatchPlayerOrigin,
  MatchModel,
} from '@core/types';
import {
  getAddedMatchLineupPlayers,
  getAddedMatchTeamOfficials,
  getMatchDetails,
} from '@core/store/modules/tabs/match-sheet-management/selectors';
import { State } from '@core/store';

import StartingLineupTab from './starting-lineup-tab';
import TeamOfficialsTab from './team-officials-tab';

interface OwnProps {
  tabId: string;
  matchPlayerOrigin: MatchPlayerOrigin;
  matchId: number;
}

interface StateProps {
  addedLineup: AddedMatchPlayerList;
  addedTeamOfficials: any;
  matchDetails: MatchModel;
}

type Props = OwnProps & StateProps;

enum matchSheetTabs {
  STARTING_LINEUP_TAB = 'STARTING_LINEUP_TAB',
  TEAM_OFFICIALS_TAB = 'TEAM_OFFICIALS_TAB',
}

const MatchSheetManagement = (props: Props) => {
  const { t } = useTranslation();
  const {
    addedLineup,
    matchPlayerOrigin,
    matchId,
    addedTeamOfficials,
    matchDetails,
    tabId,
  } = props;

  const competitionId = matchDetails?.competitionId;
  const sport = matchDetails?.sport;

  const teamId = prop(
    matchPlayerOrigin === MatchPlayerOrigin.AWAY
      ? 'awayCompetitorTeamId'
      : 'homeCompetitorTeamId',
    matchDetails,
  );

  const addedLineupCount = addedLineup ? addedLineup.length : 0;
  const addedTeamOfficialsCount = addedTeamOfficials
    ? addedTeamOfficials.length
    : 0;

  const getMatchSheetTabs = () => [
    {
      value: matchSheetTabs.STARTING_LINEUP_TAB,
      component: StartingLineupTab,
      label: `${t('Starting lineup')} (${addedLineupCount})`,
    },
    {
      value: matchSheetTabs.TEAM_OFFICIALS_TAB,
      component: TeamOfficialsTab,
      label: `${t('Team officials')} (${addedTeamOfficialsCount})`,
    },
  ];

  return (
    <Tabs
      tabs={getMatchSheetTabs()}
      fullWidth
      variant="scrollable"
      testId={MATCH_SHEET_TABS}
    >
      {(currentTab) => (
        <TabPanel
          currentTab={currentTab}
          tabs={getMatchSheetTabs()}
          props={{
            matchId,
            competitionId,
            teamId,
            matchPlayerOrigin,
            sport,
            tabId,
          }}
        />
      )}
    </Tabs>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;
  const matchPlayerOrigin = ownProps?.matchPlayerOrigin;

  return {
    addedLineup: getAddedMatchLineupPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    addedTeamOfficials: getAddedMatchTeamOfficials(state, {
      matchId,
      matchPlayerOrigin,
    }),
    matchDetails: getMatchDetails(state, { matchId, matchPlayerOrigin }),
  };
};

export default connect(mapStateToProps, null)(MatchSheetManagement);
