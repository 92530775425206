import React from 'react';
import { Card, CardHeader, IconButton, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import TeamCardSummary from '@core/pages/team-grids-edit/team-card-summary';
import TeamCardAvatar from '@core/pages/team-grids-edit/team-card-avatar';
import { Competitor } from '@core/types';

type Props = {
  onRemove: () => void;
  competitor: Competitor;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  cardHeaderAction: {
    margin: 'unset',
    alignSelf: 'center',
  },
  cardHeaderRoot: {
    padding: theme.spacing(1),
  },
  disabledCard: {
    opacity: 0.5,
  },
}));

const TeamCard = (props: Props) => {
  const { onRemove, competitor } = props;
  const classes = useStyles();
  const disabled = !onRemove;

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          root: clsx(classes.cardHeaderRoot, disabled && classes.disabledCard),
          action: classes.cardHeaderAction,
        }}
        avatar={<TeamCardAvatar competitor={competitor} disabled={disabled} />}
        action={
          <IconButton onClick={onRemove} disabled={disabled}>
            <Close />
          </IconButton>
        }
        title={<TeamCardSummary competitor={competitor} />}
        disableTypography
      />
    </Card>
  );
};

export default TeamCard;
