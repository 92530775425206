import { all, put, take, call } from 'redux-saga/effects';

import userActions from '@core/pages/user-profile/store/actions';
import clubProfileActions from '@core/pages/club-profile/store/actions';
import personProfileActions from '@core/pages/person-profile/store/actions';
import { FileParams } from '@core/components';
import { uploadFile, deleteFile, overwriteFile } from '@core/api/files';
import { FileType, ObjectType } from '@core/types';
import * as competitionsActions from '@core/pages/competitions/store/actions';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';

import { actions } from '.';

type UploadPicture = ReturnType<typeof actions.uploadPicture>;
type DeletePicture = ReturnType<typeof actions.deletePicture>;

export function* uploadPictureFlow() {
  while (true) {
    const {
      payload: { fileParams, refreshParams, file, documentType, documentName },
    }: UploadPicture = yield take(actions.uploadPicture);

    yield put(actions.uploadPictureRequest());
    const { objectType, objectId, fileId } = fileParams;
    const { error } = yield call(
      fileId ? overwriteFile : uploadFile,
      objectType,
      objectId,
      FileType.Logo,
      file,
      fileId,
      documentName,
      documentType,
    );

    if (!error) {
      yield put(actions.uploadPictureSuccess());
      yield put(globalModalActions.closeModal());
      yield call(refresh, fileParams, refreshParams);
    } else {
      yield put(actions.uploadPictureFailure());
      yield put(globalModalActions.setModalErrors(error));
    }
  }
}

export function* deletePictureFlow() {
  while (true) {
    const {
      payload: { fileParams, refreshParams },
    }: DeletePicture = yield take(actions.deletePicture);

    yield put(actions.deletePictureRequest());
    const { error } = yield call(
      deleteFile,
      fileParams.objectType,
      fileParams.objectId,
      FileType.Logo,
      fileParams.fileId,
    );

    if (!error) {
      yield put(actions.deletePictureSuccess());
      yield put(globalModalActions.closeModal());
      yield call(refresh, fileParams, refreshParams);
    } else {
      yield put(actions.deletePictureFailure());
    }
  }
}

export function* refresh(
  { objectId, objectType }: FileParams,
  refreshParams: any,
) {
  switch (objectType) {
    case ObjectType.Competitions:
      yield put(competitionProfileActions.getCompetitionDetails(objectId));
      yield put(
        competitionProfileActions.getStageDetails({ competitionId: objectId }),
      );
      yield put(competitionsActions.getCompetitions());
      break;
    case ObjectType.Clubs:
      yield put(clubProfileActions.getClub(objectId));
      break;
    case ObjectType.Venues:
    case ObjectType.Teams:
      break;
    case ObjectType.Competitors:
      yield put(competitionProfileActions.getCompetitors(refreshParams));
      yield put(competitorProfileActions.getCompetitorByTeam(refreshParams));
      break;
    case ObjectType.Users:
      yield put(userActions.getCurrentUser());
      break;
    case ObjectType.Persons:
      yield put(personProfileActions.getPerson(objectId));
      break;
    default:
      throw new Error('Unknown type!');
  }
}

export default function* saga() {
  yield all([uploadPictureFlow(), deletePictureFlow()]);
}
