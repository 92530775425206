import React from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AutocompleteField } from '@core/components';
import { formatOptions } from '@core/helpers';
import FormWithSubmit from '@volleyball/components/form-with-submit';
import { KitSetList } from '@volleyball/types';

import KitSetPreview from './components/kit-set-preview';

interface OwnProps {
  onInputChange: (val: string) => void;
  kitSets: KitSetList;
  isSubmitting?: boolean;
  tabId?: string;
}

type Props = OwnProps;

const KitSetSelectionForm = (props: Props) => {
  const { t } = useTranslation();
  const { kitSets, onInputChange, isSubmitting, tabId } = props;

  return (
    <FormWithSubmit isSubmitting={isSubmitting ?? false} tabId={tabId}>
      <Grid item xs={12} lg={7}>
        <Box maxWidth={309}>
          {/* TODO: JB: must check if AutocompleteField works with initial non empty value */}
          <AutocompleteField
            name="kitSet"
            textFieldProps={{ label: t('Main team kit') }}
            options={formatOptions(t, kitSets)}
            onInputChange={onInputChange}
            noOptionsMessage={t('No available options')}
          />
        </Box>
      </Grid>
      <Grid xs={12} lg={5} item>
        <Grid container spacing={2}>
          <KitSetPreview kitSets={kitSets} />
        </Grid>
      </Grid>
    </FormWithSubmit>
  );
};

export default KitSetSelectionForm;
