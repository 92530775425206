import { ElementType } from 'react';
import { compose } from 'redux';
import { CSSProperties, makeStyles } from '@mui/styles';
import { Grid, MenuList, ListSubheader, Divider, Theme } from '@mui/material';
import { MenuItemProps } from '@core/types';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';
import { MenuItem } from './components/menu-item';
import { withMenuDrawer } from './hocs/with-menu-drawer';
import { withMenuDrawerState } from './hocs/with-menu-drawer-state';
import { getMenu } from './helpers';
import { MenuProps, RenderFunction } from './types';
import { MENU, MENU_GROUP } from './tests/test-ids';

const useStyles = makeStyles((theme: Theme) => ({
  root: (menuTree: Array<MenuItemProps>): CSSProperties => {
    const hasDashboard = menuTree?.[0]?.path === createURL(paths.home);

    return {
      height: '100%',
      overflowY: 'auto',
      paddingTop: hasDashboard ? 0 : 12,
    };
  },
  menuList: {
    outline: 'none',
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      '@global': {
        '> hr': {
          display: 'none',
        },
      },
    },
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  header: {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    position: 'initial',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 8,
    marginBottom: 8,
    marginTop: 8,
    lineHeight: 1,
  },
}));

export const Menu = (props: MenuProps) => {
  const classes = useStyles(props?.menuTree);
  const renderMenuItem = (menuItem: MenuItemProps) => (
    <MenuItem {...menuItem} key={menuItem.title} />
  );
  const renderMenuGroup: RenderFunction = (groupTitle, menuItems) => {
    const subHeader = groupTitle ? (
      <ListSubheader className={classes.header}>{groupTitle}</ListSubheader>
    ) : null;

    return (
      <MenuList
        className={classes.menuList}
        data-qa={MENU_GROUP}
        subheader={subHeader}
        key={groupTitle}
      >
        {menuItems.map(renderMenuItem)}
        <Divider className={classes.divider} />
      </MenuList>
    );
  };

  return (
    <Grid data-qa={MENU} className={classes.root}>
      {getMenu(props.menuTree, renderMenuGroup)}
    </Grid>
  );
};

export const MenuWithDrawer = compose<ElementType>(
  withMenuDrawerState,
  withMenuDrawer,
)(Menu);
