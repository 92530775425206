import React, { useEffect } from 'react';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FeatureToggle } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { CustomTable } from '@core/components';
import { Column } from '@core/components/material-table';

import { getFeatureToggles } from './store/selectors';
import featureToggleActions from './store/actions';

interface StateProps {
  featureToggles: Array<FeatureToggle>;
  isLoading: boolean;
}

interface DispatchProps {
  actions: {
    featureToggle: typeof featureToggleActions;
  };
}

type Props = StateProps & DispatchProps;

const ReleaseToggleTab = ({ isLoading, actions, featureToggles }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featureToggles?.length) {
      actions.featureToggle.getFeatureToggles();
    }
  }, []);

  const handleChange = (e: any) =>
    actions.featureToggle.changeFeatureToggleStatus({
      name: e.target.name,
      status: e.target.value === 'true',
    });

  const columns: Array<Column<FeatureToggle>> = [
    {
      title: '',
      field: 'featureState',
      render: (rowData) => (
        <Switch
          checked={!!rowData.featureState}
          value={rowData.featureState}
          onChange={handleChange}
          name={rowData.name}
          data-qa={rowData.name}
        />
      ),
    },
    {
      title: t('Title'),
      field: 'name',
      render: (rowData) => t(rowData.name),
    },
    {
      title: t('Description'),
      field: 'description',
      cellStyle: {
        width: '50%',
      },
    },
    { title: t('Created'), field: 'createdAt' },
    { title: t('Last enabled'), field: 'lastEnabled' },
  ];

  return (
    <CustomTable
      noPaper
      topBorder
      columns={columns}
      data={featureToggles || []}
      isLoading={isLoading}
      options={{
        idSynonym: 'name',
      }}
    />
  );
};

const getFeatureToggleLoadingSelector = createLoadingSelector([
  featureToggleActions.getFeatureTogglesRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    featureToggle: bindActionCreators(featureToggleActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  featureToggles: getFeatureToggles(state),
  isLoading: getFeatureToggleLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseToggleTab);
