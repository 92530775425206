import React from 'react';
import { SingleValueProps } from 'react-select';
import { pathOr } from 'ramda';

import { PersonNameColumn } from '@core/components';

type Props = SingleValueProps<any>;

const PersonSingleValue = (props: Props) => {
  const person =
    pathOr('', ['data', 'props', 'player'], props) ||
    pathOr('', ['data', 'props', 'teamOfficial'], props) ||
    pathOr('', ['data', 'props'], props);

  return <PersonNameColumn person={person} />;
};

export default PersonSingleValue;
