import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Theme } from '@mui/material';
import { ArrowUpwardOutlined } from '@mui/icons-material';

interface OwnProps {
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: (event?: any) => void;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(8),
  },
  button: {
    position: 'absolute',
    top: theme.spacing(3),
    right: 0,
  },
}));

const ScrollUp = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { onClick } = props;

  const handleClick = () => {
    onClick ? onClick() : window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid className={classes.root}>
      <Button
        className={classes.button}
        onClick={handleClick}
        startIcon={<ArrowUpwardOutlined />}
        variant={'outlined'}
      >
        {t('Scroll up')}
      </Button>
    </Grid>
  );
};

export default ScrollUp;
