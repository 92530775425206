import { take, put, call, all, debounce, Effect } from 'redux-saga/effects';

import { getVenues } from '@core/pages/venues/store/api/sagas';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { formatPaginatedStoreWithIds, handleApiErrors } from '@core/helpers';
import {
  getCompetitorByTeamId,
  updateCompetitor,
} from '@core/store/modules/api/competitors/sagas';
import { updateCompetitorDataFactory } from '@core/factories/competitor/update-competitor-data-factory';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';

import { actions } from '.';

export function* getCompetitorFlow() {
  yield takeLeadingByPredicate(actions.getCompetitor, function* (action) {
    const { payload } = action;
    const { competitionId, competitorId, teamId } = payload;

    yield put(
      actions.getCompetitorRequest({ competitionId, competitorId, teamId }),
    );

    const { error, response } = yield call(getCompetitorByTeamId, {
      competitionId,
      teamId,
    });

    if (!error) {
      yield put(
        actions.getCompetitorSuccess({ competitionId, competitorId, teamId }),
      );
      yield put(actions.setCompetitor({ data: response.id, competitorId }));
    } else {
      yield put(
        actions.getCompetitorFailure({ competitionId, competitorId, teamId }),
      );
      yield call(handleApiErrors, error);
    }
  });
}

export function* updateCompetitorFlow() {
  while (true) {
    const { payload } = yield take(actions.updateCompetitor);
    const { data, competitionId, teamId, tabId, competitorId } = payload;

    yield put(actions.updateCompetitorRequest());

    yield put(actions.setSubmitErrors({ data: null, competitorId }));

    const { error } = yield call(updateCompetitor, {
      data: updateCompetitorDataFactory(data),
      competitionId,
      teamId,
    });

    if (!error) {
      yield put(actions.updateCompetitorSuccess());
      yield put(tabActions.removeTab({ tabId }));
      yield put(
        competitorProfileActions.getCompetitorByTeam({
          teamId,
          competitionId,
          competitorId,
        }),
      );
    } else {
      yield put(actions.updateCompetitorFailure());

      if (error.errors) {
        yield put(
          actions.setSubmitErrors({ data: error.errors, competitorId }),
        );
      } else {
        yield call(handleApiErrors, error);
      }
    }
  }
}

export function* getDefaultVenues(action: any) {
  const { payload } = action;
  const { query, page, competitorId } = payload;

  yield put(actions.searchDefaultVenuesRequest());

  if (page === 1) {
    yield put(actions.resetSearchDefaultVenues({ competitorId }));
  }

  const { error, response } = yield call(getVenues, { query, page });

  if (!error) {
    yield put(actions.searchDefaultVenuesSuccess());

    yield put(
      actions.setDefaultVenuesList({
        competitorId,
        data: formatPaginatedStoreWithIds('venues')(response) as any,
      }),
    );
  } else {
    yield put(actions.searchDefaultVenuesFailure());
  }
}

export function* watchSearchDefaultVenues() {
  yield debounce(500, actions.searchDefaultVenues, getDefaultVenues);
}

export function* getDefaultVenuesFlow(): Generator<Effect, any, any> {
  while (true) {
    const action = yield take(actions.getDefaultVenues);

    yield call(getDefaultVenues, action);
  }
}

export function* getAlternativeVenues(action: any) {
  const { payload } = action;
  const { query, page, competitorId } = payload;

  yield put(actions.searchAlternativeVenuesRequest());

  if (page === 1) {
    yield put(actions.resetSearchAlternativeVenues({ competitorId }));
  }

  const { error, response } = yield call(getVenues, { query, page });

  if (!error) {
    yield put(actions.searchAlternativeVenuesSuccess());

    yield put(
      actions.setAlternativeVenuesList({
        competitorId,
        data: formatPaginatedStoreWithIds('venues')(response) as any,
      }),
    );
  } else {
    yield put(actions.searchAlternativeVenuesFailure());
  }
}

export function* getAlternativeVenuesFlow(): Generator<Effect, any, any> {
  while (true) {
    const action = yield take(actions.getAlternativeVenues);

    yield call(getAlternativeVenues, action);
  }
}

export function* watchSearchAlternativeVenues() {
  yield debounce(500, actions.searchAlternativeVenues, getAlternativeVenues);
}

export default function* saga() {
  yield all([
    getCompetitorFlow(),
    updateCompetitorFlow(),
    watchSearchDefaultVenues(),
    watchSearchAlternativeVenues(),
    getAlternativeVenuesFlow(),
    getDefaultVenuesFlow(),
  ]);
}
