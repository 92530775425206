import { handleActions } from 'redux-actions';

import { actions } from '.';

type DefaultState = any;

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setUserRoles.toString()]: (state, { payload }) => ({
      ...state,
      roles: payload,
    }),
  },
  defaultState,
);

export default reducer;
