import { createAction } from 'redux-actions';

import { MatchOfficialRoleType } from '@core/types';

import * as ACTION_TYPES from './constants';

export const setExpandedGroup = createAction(ACTION_TYPES.SET_EXPANDED_GROUP);
export const setBeingModified = createAction(
  ACTION_TYPES.SET_GROUP_BEING_MODIFIED,
);
export const setNewBeingCreated = createAction(
  ACTION_TYPES.SET_NEW_GROUP_BEING_CREATED,
);
export const setErrors = createAction(ACTION_TYPES.SET_ERRORS);

export const addMatchOfficialToGroup = createAction(
  ACTION_TYPES.ADD_MATCH_OFFICIAL_TO_GROUP,
);
export const deleteMatchOfficialFromGroup = createAction(
  ACTION_TYPES.DELETE_MATCH_OFFICIAL_FROM_GROUP,
);

export const setMatchOfficialGroups = createAction(
  ACTION_TYPES.SET_MATCH_OFFICIAL_GROUPS,
);
export const setRefereeGroups = createAction(ACTION_TYPES.SET_REFEREE_GROUPS);
export const setRefereeGroup = createAction(ACTION_TYPES.SET_REFEREE_GROUP);
export const setRefereeGroupTotal = createAction(
  ACTION_TYPES.SET_REFEREE_GROUP_TOTAL,
);
export const setMaOfficialGroups = createAction(
  ACTION_TYPES.SET_MA_OFFICIAL_GROUPS,
);
export const setMaOfficialGroup = createAction(
  ACTION_TYPES.SET_MA_OFFICIAL_GROUP,
);
export const setMaOfficialGroupTotal = createAction(
  ACTION_TYPES.SET_MA_OFFICIAL_GROUP_TOTAL,
);

export const getMatchOfficialsInGroup = createAction<{
  groupId: number;
  roleType?: MatchOfficialRoleType;
}>(ACTION_TYPES.GET_MATCH_OFFICIALS_IN_GROUP);
export const getMatchOfficialGroupUrl = createAction<{
  href: number;
}>(ACTION_TYPES.GET_MATCH_OFFICIALS_IN_GROUP);
export const getMatchOfficialsInGroupRequest = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIALS_IN_GROUP_REQUEST,
);
export const getMatchOfficialsInGroupSuccess = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIALS_IN_GROUP_SUCCESS,
);
export const getMatchOfficialsInGroupFailure = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIALS_IN_GROUP_FAILURE,
);

export const getMatchOfficialGroups = createAction<{
  query?: string;
}>(ACTION_TYPES.GET_MATCH_OFFICIAL_GROUPS);
export const getMatchOfficialGroupsRequest = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIAL_GROUPS_REQUEST,
);
export const getMatchOfficialGroupsSuccess = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIAL_GROUPS_SUCCESS,
);
export const getMatchOfficialGroupsFailure = createAction<void>(
  ACTION_TYPES.GET_MATCH_OFFICIAL_GROUPS_FAILURE,
);

export const setMatchOfficialGroupsRank = createAction<{
  groupsOrder: Array<number>;
  matchOfficialGroupType: MatchOfficialRoleType;
  sportId: number;
}>(ACTION_TYPES.SET_MATCH_OFFICIAL_GROUPS_RANK);
export const setMatchOfficialGroupsRankRequest = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_GROUPS_RANK_REQUEST,
);
export const setMatchOfficialGroupsRankSuccess = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_GROUPS_RANK_SUCCESS,
);
export const setMatchOfficialGroupsRankFailure = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_GROUPS_RANK_FAILURE,
);

export const setMatchOfficialRankInGroup = createAction<{
  groupId: number;
  roleType: MatchOfficialRoleType;
  officialsOrder: Array<number>;
}>(ACTION_TYPES.SET_MATCH_OFFICIAL_RANK_IN_GROUP);
export const setMatchOfficialRankInGroupRequest = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_RANK_IN_GROUP_REQUEST,
);
export const setMatchOfficialRankInGroupSuccess = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_RANK_IN_GROUP_SUCCESS,
);
export const setMatchOfficialRankInGroupFailure = createAction<void>(
  ACTION_TYPES.SET_MATCH_OFFICIAL_RANK_IN_GROUP_FAILURE,
);

export const createMatchOfficialGroup = createAction(
  ACTION_TYPES.CREATE_MATCH_OFFICIAL_GROUP,
);
export const createMatchOfficialGroupRequest = createAction<void>(
  ACTION_TYPES.CREATE_MATCH_OFFICIAL_GROUP_REQUEST,
);
export const createMatchOfficialGroupSuccess = createAction<void>(
  ACTION_TYPES.CREATE_MATCH_OFFICIAL_GROUP_SUCCESS,
);
export const createMatchOfficialGroupFailure = createAction<void>(
  ACTION_TYPES.CREATE_MATCH_OFFICIAL_GROUP_FAILURE,
);

export const updateMatchOfficialGroup = createAction(
  ACTION_TYPES.UPDATE_MATCH_OFFICIAL_GROUP,
);
export const updateMatchOfficialGroupRequest = createAction<void>(
  ACTION_TYPES.UPDATE_MATCH_OFFICIAL_GROUP_REQUEST,
);
export const updateMatchOfficialGroupSuccess = createAction<void>(
  ACTION_TYPES.UPDATE_MATCH_OFFICIAL_GROUP_SUCCESS,
);
export const updateMatchOfficialGroupFailure = createAction<void>(
  ACTION_TYPES.UPDATE_MATCH_OFFICIAL_GROUP_FAILURE,
);

export const deleteMatchOfficialGroup = createAction<number>(
  ACTION_TYPES.DELETE_MATCH_OFFICIAL_GROUP,
);
export const deleteMatchOfficialGroupRequest = createAction<void>(
  ACTION_TYPES.DELETE_MATCH_OFFICIAL_GROUP_REQUEST,
);
export const deleteMatchOfficialGroupSuccess = createAction<void>(
  ACTION_TYPES.DELETE_MATCH_OFFICIAL_GROUP_SUCCESS,
);
export const deleteMatchOfficialGroupFailure = createAction<void>(
  ACTION_TYPES.DELETE_MATCH_OFFICIAL_GROUP_FAILURE,
);

export const deleteMatchOfficialGroupUrl = createAction<{
  groupId: number;
  officialId: number;
  roleType: MatchOfficialRoleType;
}>(ACTION_TYPES.DELETE_MATCH_OFFICIAL_GROUP_URL);

export const actions = {
  setExpandedGroup,
  setBeingModified,
  setNewBeingCreated,
  setErrors,

  setMatchOfficialGroups,
  setRefereeGroups,

  getMatchOfficialsInGroup,
  getMatchOfficialsInGroupRequest,
  getMatchOfficialsInGroupSuccess,
  getMatchOfficialsInGroupFailure,

  getMatchOfficialGroups,
  getMatchOfficialGroupsRequest,
  getMatchOfficialGroupsSuccess,
  getMatchOfficialGroupsFailure,

  setMatchOfficialGroupsRank,
  setMatchOfficialGroupsRankRequest,
  setMatchOfficialGroupsRankSuccess,
  setMatchOfficialGroupsRankFailure,

  createMatchOfficialGroup,
  createMatchOfficialGroupRequest,
  createMatchOfficialGroupSuccess,
  createMatchOfficialGroupFailure,

  updateMatchOfficialGroup,
  updateMatchOfficialGroupRequest,
  updateMatchOfficialGroupSuccess,
  updateMatchOfficialGroupFailure,

  setMatchOfficialRankInGroup,
  setMatchOfficialRankInGroupRequest,
  setMatchOfficialRankInGroupSuccess,
  setMatchOfficialRankInGroupFailure,

  deleteMatchOfficialGroup,
  deleteMatchOfficialGroupRequest,
  deleteMatchOfficialGroupSuccess,
  deleteMatchOfficialGroupFailure,

  deleteMatchOfficialGroupUrl,

  addMatchOfficialToGroup,
  deleteMatchOfficialFromGroup,
};
