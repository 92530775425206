import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { LANGUAGE_KEY } from '@core/helpers/storage';
import localeToLanguageNameMap from '@core/components/language-select/constants/locale-to-language-name-map';
import { FALLBACK_LANGUAGE } from '@core/i18n/constants';
import CustomLanguageDetector from '@core/i18n/detector';

const languageDetector = new LanguageDetector();

const options: InitOptions = {
  keySeparator: false,
  nsSeparator: ':::',
  fallbackLng: FALLBACK_LANGUAGE,
  preload: Object.keys(localeToLanguageNameMap),
  debug: false,
  returnEmptyString: false,
  load: 'currentOnly',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
  detection: {
    lookupLocalStorage: LANGUAGE_KEY,
    order: ['cookie', 'localStorage', 'language-detector'],
    caches: ['cookie', 'localStorage'],
    lookupCookie: 'language',
  },
  backend: {
    crossDomain: false,
    loadPath: '/locales/{{lng}}.json',
  },
};

languageDetector.addDetector(CustomLanguageDetector);

i18n
  .use(languageDetector) // detect user language
  .use(initReactI18next); // pass the i18n instance to react-i18next.

// prevent translations fetching during unit tests
if (import.meta.env.MODE !== 'test') {
  i18n.use(HttpApi);
}

i18n.init(options);

export default i18n;
