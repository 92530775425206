import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { PageFormActionBlock } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  disabled: boolean;
  isSubmitting: boolean;
  tabId: string;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
  };
}

type Props = OwnProps & DispatchProps;

const StageStandingsSettingsActions = (props: Props) => {
  const { actions, tabId, disabled, isSubmitting } = props;
  const { t } = useTranslation();

  const handleCancel = () => actions.tabs.removeTab({ tabId });

  return (
    <PageFormActionBlock>
      <Box display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button
            onClick={handleCancel}
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </Button>
        </Box>
        <ButtonWithProgress
          variant="contained"
          type="submit"
          disabled={disabled || isSubmitting}
          isLoading={isSubmitting}
        >
          {t('Save')}
        </ButtonWithProgress>
      </Box>
    </PageFormActionBlock>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(StageStandingsSettingsActions);
