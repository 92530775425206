import React, { forwardRef, Ref } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { MenuItem, MenuList, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as authActions from '@core/pages/login/store/actions';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';
import * as testIds from '../tests/test-ids';

const useStyles = makeStyles(() => ({
  root: {
    width: 200,
    outline: 'none',
  },
}));

interface ForwarderProps {
  fwdRef: Ref<any>;
}

interface OwnProps {
  handleMenuToggle: (event: React.MouseEvent<EventTarget>) => void;
}

interface DispatchProps {
  actions: {
    auth: typeof authActions;
  };
}

type Props = DispatchProps & OwnProps & ForwarderProps;

export const UserMenuList = ({ handleMenuToggle, fwdRef, actions }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menuItems = [
    {
      label: t('My Profile'),
      onClick: () => navigate(createURL(paths.userProfile)),
      dataQa: testIds.MY_PROFILE_LINK,
    },
    {
      label: t('Terms and conditions'),
      onClick: () => navigate(createURL(paths.termsAndConditions)),
      dataQa: testIds.TERMS_LINK,
    },
    {
      label: t('Logout'),
      onClick: actions.auth.logout,
      dataQa: testIds.LOGOUT_LINK,
    },
  ];

  return (
    <MenuList
      ref={fwdRef}
      data-qa={testIds.USER_MENU_LIST}
      className={classes.root}
    >
      {menuItems.map(({ label, onClick, dataQa }) => {
        function handleMenuClick(event: React.MouseEvent<EventTarget>) {
          onClick && onClick();
          handleMenuToggle(event);
        }

        return (
          <MenuItem data-qa={dataQa} key={label} onClick={handleMenuClick}>
            <Typography color="textPrimary" variant="body2">
              {label}
            </Typography>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch),
  },
});

const UserMenuListConnected = connect(null, mapDispatchToProps)(UserMenuList);

export const UserMenuListContainer = forwardRef(
  (props: OwnProps, ref: Ref<any>) => (
    <UserMenuListConnected {...props} fwdRef={ref} />
  ),
);
