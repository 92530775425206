import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { CompetitionManager, CompetitionManagersList } from '@core/types';

import {
  SET_COMPETITION_MANAGERS,
  SET_CURRENT_COMPETITION_MANAGER,
} from './constants';

export interface DefaultState {
  allManagers: CompetitionManagersList;
  currentManager: CompetitionManager;
}

export const defaultState: DefaultState = {
  allManagers: null,
  currentManager: null,
};

const reducer = handleActions<DefaultState, any>(
  {
    [SET_COMPETITION_MANAGERS]: (state, { payload }) =>
      assoc('allManagers', payload, state),
    [SET_CURRENT_COMPETITION_MANAGER]: (state, { payload }) =>
      assoc('currentManager', payload, state),
  },
  defaultState,
);

export default reducer;
