import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import {
  AddedMatchPlayerList,
  AddedLineupPlayerErrors,
  AvailablePlayerList,
  AddedMatchTeamOfficialList,
  AvailableTeamOfficialList,
  MatchPlayerOrigin,
  MatchPlayersList,
  MatchModel,
} from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [matchId: string]: {
    [matchPlayerOrigin: string]: {
      matchPlayers: MatchPlayersList;
      availableLineupCompetitorPlayers: AvailablePlayerList;
      addedLineupPlayers: AddedMatchPlayerList;
      availableSubstituteCompetitorPlayers: AvailablePlayerList;
      addedSubstitutePlayers: AddedMatchPlayerList;
      addedLineupPlayersErrors: AddedLineupPlayerErrors;
      addedTeamOfficials: AddedMatchTeamOfficialList;
      availableCompetitorTeamOfficials: AvailableTeamOfficialList;
      matchTeamOfficials: AddedMatchTeamOfficialList;
      matchDetails: MatchModel;
      previousMatchPlayersTotal: number;
    };
  };
}

export const defaultState: DefaultState = {};

type Payload = any;

interface Meta {
  matchId: number;
  competitorId?: number;
  matchPlayerOrigin?: MatchPlayerOrigin;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setMatchPlayers.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'matchPlayers'],
        payload,
        state,
      ),

    [actions.setLineupCompetitorPlayers.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        [
          meta.matchId,
          meta.matchPlayerOrigin,
          'availableLineupCompetitorPlayers',
        ],
        payload,
        state,
      ),

    [actions.setLineupAddedPlayers.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'addedLineupPlayers'],
        payload,
        state,
      ),

    [actions.setSubstituteCompetitorPlayers.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        [
          meta.matchId,
          meta.matchPlayerOrigin,
          'availableSubstituteCompetitorPlayers',
        ],
        payload,
        state,
      ),

    [actions.setSubstituteAddedPlayers.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'addedSubstitutePlayers'],
        payload,
        state,
      ),

    [actions.resetLineupCompetitorPlayers.toString()]: (state, { meta }) =>
      dissocPath(
        [
          meta.matchId,
          meta.matchPlayerOrigin,
          'availableLineupCompetitorPlayers',
        ],
        state,
      ),

    [actions.resetSubstituteCompetitorPlayers.toString()]: (state, { meta }) =>
      dissocPath(
        [
          meta.matchId,
          meta.matchPlayerOrigin,
          'availableSubstituteCompetitorPlayers',
        ],
        state,
      ),

    [actions.resetMatchSheetManagement.toString()]: (state, { meta }) =>
      dissocPath([meta.matchId, meta.matchPlayerOrigin], state),

    [actions.setAddedLineupPlayerDataErrors.toString()]: (
      state,
      { meta, payload },
    ) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'addedLineupPlayersErrors'],
        payload,
        state,
      ),

    [actions.setAddedTeamOfficials.toString()]: (state, { meta, payload }) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'addedTeamOfficials'],
        payload,
        state,
      ),

    [actions.setTeamOfficials.toString()]: (state, { meta, payload }) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'matchTeamOfficials'],
        payload,
        state,
      ),

    [actions.setCompetitorTeamOfficials.toString()]: (
      state,
      { meta, payload },
    ) =>
      assocPath(
        [
          meta.matchId,
          meta.matchPlayerOrigin,
          'availableCompetitorTeamOfficials',
        ],
        payload,
        state,
      ),

    [actions.setMatch.toString()]: (state, { meta, payload }) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'matchDetails'],
        payload,
        state,
      ),

    [actions.setPreviousMatchPlayersTotal.toString()]: (
      state,
      { meta, payload },
    ) =>
      assocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'previousMatchPlayersTotal'],
        payload,
        state,
      ),

    [actions.resetPreviousMatchPlayersTotal.toString()]: (state, { meta }) =>
      dissocPath(
        [meta.matchId, meta.matchPlayerOrigin, 'previousMatchPlayersTotal'],
        state,
      ),

    [actions.setMatchSheetHistory.toString()]: (state, { meta, payload }) => ({
      ...state,
      [meta.matchId]: {
        ...state[meta.matchId],
        [meta.competitorId]: {
          ...state[meta.matchId]?.[meta.competitorId],
          matchSheetHistory: payload,
        },
      },
    }),

    [actions.resetMatchSheetHistory.toString()]: (state, { meta }) =>
      dissocPath([meta.matchId], state),
  },
  defaultState,
);

export default reducer;
