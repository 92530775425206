import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';

const setSports = createAction<Array<number>>(`${NAMESPACE}/SET_SPORTS`);
const getSports = createAction<void>(`${NAMESPACE}/GET_SPORTS`);
const getSportsRequest = createAction<void>(`${NAMESPACE}/GET_SPORTS_REQUEST`);
const getSportsSuccess = createAction<void>(`${NAMESPACE}/GET_SPORTS_SUCCESS`);
const getSportsFailure = createAction<void>(`${NAMESPACE}/GET_SPORTS_FAILURE`);

export default {
  setSports,
  getSportsRequest,
  getSportsSuccess,
  getSportsFailure,
  getSports,
};
