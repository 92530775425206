import { match } from 'ramda';

import { CUSTOMER_CODE_REGEX } from '@core/constants/regex';

export const getCustomerCode = (): string | undefined => {
  const [, customerCode] = match(
    new RegExp(`^/(\\${CUSTOMER_CODE_REGEX})(/|$)`),
    window.location.pathname,
  );

  return customerCode;
};
