import React from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { getSports, getGenders } from '@core/helpers';
import { CompetitionResponse } from '@core/types';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { State } from '@core/store';

interface OwnProps {
  competition: CompetitionResponse;
}

interface StateProps {
  isLoading: boolean;
}

type Props = OwnProps & StateProps;

const CompetitorAssignmentSummaryBlock = (props: Props) => {
  const { competition, isLoading } = props;
  const { t } = useTranslation();

  const getSummaryBlockValues = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.competitionSeasonTitle,
    },
    {
      label: t('Sport'),
      value: getSports(t, competition?.sportTitle),
    },
    {
      label: t('Gender'),
      value: getGenders(t, competition?.gender),
    },
  ];

  return (
    <Box p={3}>
      <Box mb={3}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          {t('Add team to competition')}
        </Typography>
      </Box>
      <Grid container>
        <SummaryBlock isLoading={isLoading} values={getSummaryBlockValues()} />
      </Grid>
    </Box>
  );
};

const isLoadingSelector = createLoadingSelector([
  competitionProfileActions.getCompetitionDetailsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, null)(CompetitorAssignmentSummaryBlock);
