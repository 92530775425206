import { useSelector } from 'react-redux';
import { FeatureToggle } from '@core/types/api';
import { getFeatureToggles } from './selectors';

export const useCheckToggles = (required: Array<string> | string) => {
  const featureToggles = useSelector(getFeatureToggles);

  const isEnabled = (toggle: string) =>
    featureToggles.some(
      ({ name, featureState }: FeatureToggle) =>
        name === toggle && featureState,
    );

  return Array.isArray(required)
    ? required.every((requiredFeatureToggle) =>
        isEnabled(requiredFeatureToggle),
      )
    : isEnabled(required);
};
