import { isEmpty, path, pathOr } from 'ramda';
import { Paginated, Season } from '@core/types';
import { State } from '@core/store';
import { NAMESPACE } from './constants';
import { getCompetitionSeasonsById } from './api/selectors';

export const getSeasonsList = (state: State): Paginated<Season> => {
  const seasonsPage = { ...state.pages.seasons.seasons };

  return seasonsPage && !isEmpty(seasonsPage)
    ? {
        ...seasonsPage,
        list: getCompetitionSeasonsById(state, seasonsPage?.list),
      }
    : null;
};

export const getSeasons = (state: State): Array<Season> => {
  const seasonsPage = { ...state.pages.seasons.seasons };

  return seasonsPage && !isEmpty(seasonsPage)
    ? getCompetitionSeasonsById(state, seasonsPage?.list)
    : null;
};

export const isSeasonBeingModified = pathOr(null, [
  'pages',
  NAMESPACE,
  'seasonBeingModified',
]);

export const isNewSeasonBeingCreated = pathOr(null, [
  'pages',
  NAMESPACE,
  'newSeasonBeingCreated',
]);

export const getSeasonErrors = pathOr(null, ['pages', NAMESPACE, 'errors']);

export const getQueryValue = (state: State): string =>
  path(['pages', NAMESPACE, 'query'], state);
