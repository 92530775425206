import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SettingsTwoTone, FileDownloadSharp } from '@mui/icons-material';
import { uuid } from '@core/helpers';
import { FullScreenSpinner } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { createURL } from '@core/helpers';
import { Group } from '@core/types';
import {
  CompetitionResponse,
  GroupStandings,
  StageType,
} from '@volleyball/types';
import { State } from '@volleyball/store';
import * as ACTION_TYPES from '@volleyball/store/modules/group-standings/constants';
import { getGroupStandings } from '@volleyball/store/modules/group-standings/selectors';
import * as groupStandingsActions from '@volleyball/store/modules/group-standings/actions';
import paths from '@volleyball/routes/paths';
import StandingsTable from './components/standings-table';
import { COMPETITION_STAGE_STANDINGS_SETTINGS_BUTTON } from './tests/test-ids';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

interface DispatchProps {
  actions: {
    groupStandings: typeof groupStandingsActions;
    tabs: typeof tabActions;
  };
}

interface OwnProps {
  stage: Group;
  competition: CompetitionResponse;
}

interface StateProps {
  groupStandings: GroupStandings;
  isLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 'auto',
    padding: 8.5,
    minHeight: '19px',
  },
}));

const StageStandingsTab = (props: Props) => {
  const { stage, groupStandings, isLoading, actions, competition } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [standingsToShow, setStandingsToShow] = useState('all');

  const getGroupName = (groupId: number) => {
    const group = stage?.childGroups?.find(
      (childGroup) => childGroup.id === groupId,
    );

    return group?.title || group?.internationalName;
  };

  const canViewStandingsOrStandingSettings =
    stage?._links?.groupStandingSettings;
  const canAdjustStandingsOrEditStandingSettings =
    stage?._links?.groupStandingEdit;

  useEffect(() => {
    actions.groupStandings.getGroupStandings({ groupId: stage.id });
  }, []);

  const handleGroupChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setStandingsToShow(value);
  };

  const handleSettings = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.stageStandingsSettings),
      id: uuid(),
      props: {
        stage,
        competition,
        disabled: !canAdjustStandingsOrEditStandingSettings,
      },
    });

  const handleExportPDF = async () => {
    const element = document.querySelector(
      `[data-qa="group-standings-table-${stage.id}"]`,
    ) as HTMLElement;

    const canvas = await html2canvas(element, {
      scale: 3,
      useCORS: true,
      onclone: (doc) => {
        const standingsContainer = doc.querySelector<HTMLDivElement>(
          `[data-qa="group-standings-table-${stage.id}"]`,
        );
        const tables = standingsContainer.querySelectorAll('table');

        standingsContainer.style.border = '1px solid lightgrey';

        tables.forEach((table) => {
          const editButtons = table.querySelectorAll('[data-qa="edit-button"]');

          if (!editButtons.length) {
            return;
          }

          const thead = editButtons[0].parentElement.parentElement.parentElement
            .previousSibling as HTMLElement;
          const rows = Array.from(thead.querySelectorAll('tr'));
          const hasPools = rows.length === 3;
          const poolRow = hasPools ? rows[0] : null;
          const categoryRow = rows[hasPools ? 1 : 0];
          const dataRow = rows[hasPools ? 2 : 1];

          if (hasPools) {
            const poolRowColspan = poolRow.children[0].getAttribute('colspan');

            poolRow.children[0].setAttribute(
              'colspan',
              String(Number(poolRowColspan) - 1),
            );
          }

          categoryRow.children[categoryRow.childElementCount - 1].remove();
          dataRow.children[dataRow.childElementCount - 1].remove();

          editButtons.forEach((button) => {
            button.parentElement.remove();
          });
        });
      },
    });
    const margin = 10;
    const img = canvas.toDataURL('image/jpeg', 2);
    const doc = new jsPDF('p', 'mm', 'a4');
    const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin;
    const maxHeight = doc.internal.pageSize.getHeight() - 2 * margin;
    const imgProps = doc.getImageProperties(img);

    let imgWidth = maxWidth;
    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    if (imgHeight > maxHeight) {
      imgHeight = maxHeight;
      imgWidth = (imgProps.width * imgHeight) / imgProps.height;
    }

    const selectedStandings = stage.childGroups.find(
      (group) => group.id === Number(standingsToShow),
    );
    const suffix =
      standingsToShow === 'all'
        ? ' - All pools'
        : ` - ${selectedStandings.title}`;
    const filenameSuffix = stage.childGroups.length ? suffix : '';

    doc.addImage(img, 'JPEG', margin, margin, imgWidth, imgHeight);
    doc.save(`${competition.title} - ${stage.title}${filenameSuffix}.pdf`);
  };

  return isLoading ? (
    <FullScreenSpinner />
  ) : (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <FormControl sx={{ width: 250 }}>
          {groupStandings?.standings?.length > 1 && (
            <Select
              value={standingsToShow}
              classes={{
                select: classes.select,
              }}
              onChange={handleGroupChange}
            >
              <MenuItem value="all">
                {stage?.stageType === StageType.ROUND_ROBIN
                  ? t('All pools')
                  : t('All rounds')}
              </MenuItem>
              {groupStandings?.standings?.map((standing) => (
                <MenuItem key={standing.groupId} value={standing.groupId}>
                  {getGroupName(standing.groupId)}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <Box>
          {canViewStandingsOrStandingSettings && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSettings}
              data-qa={COMPETITION_STAGE_STANDINGS_SETTINGS_BUTTON}
              startIcon={<SettingsTwoTone />}
            >
              {t('Settings')}
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FileDownloadSharp />}
            onClick={handleExportPDF}
            sx={{ ml: 1 }}
          >
            {t('Download as PDF')}
          </Button>
        </Box>
      </Box>
      <Box data-qa={`group-standings-table-${stage.id}`}>
        {groupStandings?.standings
          ?.filter(
            (standing) =>
              standing.groupId === Number(standingsToShow) ||
              standingsToShow === 'all',
          )
          .map((standing, index) => (
            <StandingsTable
              key={index}
              tableIndex={index}
              stage={stage}
              headers={groupStandings?.header}
              standings={standing}
              groupName={getGroupName(standing.groupId)}
            />
          ))}
      </Box>
    </Box>
  );
};

const isLoadingSelector = createLoadingSelector([
  ACTION_TYPES.GET_GROUP_STANDINGS_REQUEST,
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    groupStandings: bindActionCreators(groupStandingsActions, dispatch),
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    isLoading: isLoadingSelector(state),
    groupStandings: getGroupStandings(state, { groupId: ownProps?.stage?.id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StageStandingsTab);
