import { map, ifElse, always } from 'ramda';
import { TFunction } from 'i18next';

import { AutocompleteOption } from '@core/types';
import { isValue } from '@core/helpers';

// TODO: JB: use this with AutocompleteField.options prop instead of custom functions like in clubs-search.tsx

export const formatOptions = <TValue = any>(
  t: TFunction | null,
  values: Array<TValue>,
  labelKey: keyof TValue = 'title' as any,
): Array<AutocompleteOption> =>
  ifElse(
    isValue,
    map((value: any) => ({
      label: t ? t(value[labelKey]) : value[labelKey],
      value: value.id,
      props: value,
    })),
    always([]),
  )(values);

export const formatOption = <TValue = any>(
  t: TFunction | null,
  value: TValue,
  labelKey: keyof TValue = 'title' as any,
): AutocompleteOption =>
  formatOptions(t, value ? [value] : undefined, labelKey)[0];

export const formatObjectOptions = (
  t: TFunction | null,
  values: any,
  labelKey = 'title',
) => {
  const arr = [];
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const value = values[key];
      arr.push({
        label: t ? t(value[labelKey]) : value[labelKey],
        value: value.id,
        props: value,
      });
    }
  }

  return arr;
};
