import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { map } from 'ramda';
import { Typography, Grid, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TableWithAssign, PersonNameColumn } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  AddedMatchPlayerList,
  AvailablePlayer,
  AvailablePlayerList,
  MatchPlayerOrigin,
} from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import {
  getAddedMatchLineupPlayers,
  getAvailableLineupCompetitorPlayers,
} from '@core/store/modules/tabs/match-sheet-management/selectors';
import { State } from '@core/store';

import AvailablePlayersSearchField from './available-players-search-field';

interface OwnProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  tabId: string;
  teamId: number;
  competitionId: number;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

interface StateProps {
  availablePlayers: AvailablePlayerList;
  addedLineupPlayers: AddedMatchPlayerList;
  isLoading: boolean;
}

interface PlayerWithStatus extends AvailablePlayer {
  isAdded?: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const AvailablePlayersTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    availablePlayers,
    actions,
    matchId,
    matchPlayerOrigin,
    addedLineupPlayers,
    tabId,
    isLoading,
    teamId,
    competitionId,
  } = props;

  const sortedAvailablePlayers = availablePlayers
    ? [
        ...availablePlayers
          .filter((element) => element.jerseyNumber)
          .sort(
            (a, b) =>
              parseInt(b.jerseyNumber, 10) - parseInt(a.jerseyNumber, 10),
          ),
        ...availablePlayers
          .filter((element) => !element.jerseyNumber)
          .sort((a, b) =>
            b.player.localFamilyName > a.player.localFamilyName ? 1 : -1,
          ),
      ]
    : [];

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: PlayerWithStatus) => (
        <PersonNameColumn
          startText={!!rowData.jerseyNumber && `#${rowData.jerseyNumber}`}
          isDisabled={rowData.isAdded}
          person={rowData.player}
        />
      ),
    },
    {
      name: 'added',
      label: '',
      render: (rowData: PlayerWithStatus) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {rowData.isAdded ? t('added') : ' '}
        </Typography>
      ),
    },
  ];

  const handleAddToList = (id: number) => {
    const playerToAdd = availablePlayers.find((player) => player.id === id);

    actions.matchSheetManagement.setLineupAddedPlayers({
      matchId,
      matchPlayerOrigin,
      payload: [...addedLineupPlayers, playerToAdd],
      tabId,
    });
  };

  const isPlayerAdded = (playerId: number) =>
    addedLineupPlayers &&
    addedLineupPlayers.some((player) => player.id === playerId);

  const markAddedCompetitorPlayers = (players: AvailablePlayerList) => {
    if (!players) {
      return [];
    }

    return map((player: PlayerWithStatus) => {
      if (!addedLineupPlayers) {
        return player;
      }

      if (isPlayerAdded(player.id)) {
        return { ...player, isAdded: true };
      }

      return player;
    }, players);
  };

  const getPlayersCount = () =>
    availablePlayers?.reduce(
      (total: number, player) => (isPlayerAdded(player.id) ? total : total + 1),
      0,
    ) || 0;

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Available players')} (${getPlayersCount()})`}
        </Typography>
      </Grid>
      <AvailablePlayersSearchField
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        teamId={teamId}
        competitionId={competitionId}
      />
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            columns={columns}
            data={markAddedCompetitorPlayers(sortedAvailablePlayers)}
            isLoading={isLoading}
            handleAdd={handleAddToList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const isLoadingSelector = createLoadingSelector([
  matchSheetManagementActions.searchCompetitorPlayersRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;
  const matchPlayerOrigin = ownProps?.matchPlayerOrigin;

  return {
    addedLineupPlayers: getAddedMatchLineupPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    availablePlayers: getAvailableLineupCompetitorPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    isLoading: isLoadingSelector(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailablePlayersTable);
