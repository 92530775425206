import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
  onClick: (event: React.SyntheticEvent) => void;
}

type Props = OwnProps;

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    marginLeft: 2,
  },
}));

export const CloseButton = ({ onClick }: Props) => {
  const classes = useStyles();

  return (
    <IconButton size="small" onClick={onClick} className={classes.root}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};
