import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SummaryBlock } from '@core/components';

interface OwnProps {
  date: string;
  version: string;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const TermsAndConditionsInfo = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { date, version } = props;

  const dateToDisplay = date?.includes(' ') ? date.split(' ')[0] : date;

  return (
    <Box className={classes.root}>
      <SummaryBlock
        values={[
          {
            label: t('Version'),
            value: version ?? '-',
          },
          {
            label: t('Last updated'),
            value: dateToDisplay ?? '-',
          },
        ]}
      />
    </Box>
  );
};

export default TermsAndConditionsInfo;
