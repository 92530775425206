import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid, Box, Typography, Divider, Theme, Tooltip } from '@mui/material';

import { ButtonWithDropdown, Status } from '@core/components';
import { getStatusColor, getCompetitionStatus } from '@core/helpers';
import {
  CompetitionResponse,
  CompetitionStatus,
  ModalTypes,
} from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';

import { OwnProps as ChangeCompetitionStatusModalProps } from '../../modals/change-competition-status-modal';

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  competition: CompetitionResponse;
}

type Props = DispatchProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.975, 3),
  },
  title: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
  },
}));

const CompetitionProfileHeader = ({ actions, competition }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const canChangeStatus = !!competition?._links?.changeStatus?.href;

  // Prevent panel toggle
  const handleClick = (event: React.MouseEvent<EventTarget>) =>
    event.stopPropagation();

  const handleStatusChange = (status: CompetitionStatus) =>
    actions.globalModal.openModal<ChangeCompetitionStatusModalProps>({
      type: ModalTypes.CHANGE_COMPETITION_STATUS_MODAL,
      params: {
        competition,
        status,
      },
    });

  const competitionStatusOptions = [
    {
      text: t('Complete'),
      value: CompetitionStatus.COMPLETE,
      disabled: !competition?._links?.changeStatus?.COMPLETE,
    },
    {
      text: t('Schedule'),
      value: CompetitionStatus.SCHEDULED,
      disabled: !competition?._links?.changeStatus?.SCHEDULED,
    },
  ];

  return (
    <Grid container>
      <Grid container className={classes.root}>
        <Grid container item xs={8} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" className={classes.title}>
              {competition.competitionSeasonTitle}
            </Typography>
          </Grid>
          <Grid item ml={1.5}>
            <Status
              title={getCompetitionStatus(t, competition.status)}
              statusColor={getStatusColor(competition.status)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box onClick={handleClick}>
            <Tooltip
              disableFocusListener={canChangeStatus}
              disableHoverListener={canChangeStatus}
              disableTouchListener={canChangeStatus}
              title={t(
                'Competition Status will be changed automatically, once there are scheduled matches.',
              )}
              placement="bottom"
            >
              <span>
                <ButtonWithDropdown
                  variant="outlined"
                  options={competitionStatusOptions}
                  onSelect={handleStatusChange}
                  disabled={!canChangeStatus}
                >
                  {t('Change status')}
                </ButtonWithDropdown>
              </span>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(CompetitionProfileHeader);
