import React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FilterTabsList, Filters } from '@core/types';
import { SearchField, Filter } from '@core/components';

import FilterChips from './filter-chips';

interface OwnProps {
  tableSearch?: {
    query: string;
    onSearch: (values: { query: string; page: number }) => void;
  };
  tableFilter?: {
    tabsList: FilterTabsList;
    onSave: (filters: Filters) => void;
    initialFilters: Filters;
  };
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    },
  },
  actionsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  filter: {},
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
}));

const TableToolbar = (props: Props) => {
  const { tableFilter, tableSearch } = props;
  const classes = useStyles();

  const showLimitationBar = !!(tableFilter || tableSearch);

  return showLimitationBar ? (
    <Grid className={classes.root}>
      <Grid className={classes.actionsRow}>
        {tableFilter && (
          <Grid className={classes.filter}>
            <Filter {...tableFilter} />
          </Grid>
        )}
        {tableSearch && (
          <Grid className={classes.searchInput}>
            <SearchField {...tableSearch} />
          </Grid>
        )}
      </Grid>
      {tableFilter && <FilterChips {...tableFilter} />}
    </Grid>
  ) : (
    <Grid />
  );
};

export default TableToolbar;
