import { createAction } from 'redux-actions';

export const NAMESPACE = 'disciplinaryInfringementsList';

const getDisciplinaryInfringementsRequest = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_INFRINGEMENTS_REQUEST`,
);
const getDisciplinaryInfringementsSuccess = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_INFRINGEMENTS_SUCCESS`,
);
const getDisciplinaryInfringementsFailure = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_INFRINGEMENTS_FAILURE`,
);

const setDisciplinaryInfringementsList = createAction<any>(
  `${NAMESPACE}/SET_DISCIPLINARY_INFRINGEMENTS_LIST`,
);

export default {
  getDisciplinaryInfringementsRequest,
  getDisciplinaryInfringementsSuccess,
  getDisciplinaryInfringementsFailure,
  setDisciplinaryInfringementsList,
};
