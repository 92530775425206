import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { NumberField, TextField, ModalFieldsBlock } from '@core/components';
import modalStyles from '@core/components/global-modal/styles';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { GlobalModalProps } from '@core/types';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { State } from '@core/store';
import * as testIds from './tests/test-ids';
import { FIELD_NAMES } from './constants';
import { getInitialValues, type FormValues } from './helpers/initialValues';
import { getValidationSchema } from './helpers/validationSchema';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
  };
}

interface StateProps {
  isSubmitting: boolean;
}

type Props = GlobalModalProps & DispatchProps & StateProps;

const GroupCreateModal = (props: Props) => {
  const { isModalOpen, modalActions, modalParams, actions, isSubmitting } =
    props;
  const classes = modalStyles();
  const { t } = useTranslation();
  const { groupBeingModified, competitionId, stage, isEditMode } = modalParams;

  const handleModalClose = () => modalActions.closeModal();

  const handleSubmit = (values: FormValues) => {
    const noChanges = equals(getInitialValues(groupBeingModified), values);

    if (noChanges) {
      return handleModalClose();
    }

    if (isEditMode) {
      actions.competitionProfile.updateGroup({
        competitionId,
        groupId: groupBeingModified?.id,
        data: values,
      });
    } else {
      actions.competitionProfile.createGroup({
        competitionId,
        data: {
          ...values,
          parentGroupId: stage?.id,
          stageType: stage?.stageType,
        },
      });
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="xs"
      fullWidth
    >
      <Formik
        initialValues={getInitialValues(groupBeingModified)}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={getValidationSchema(t)}
      >
        <Form noValidate>
          <DialogTitle className={classes.title}>
            {isEditMode ? t('Edit pool') : t('Create pool')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <ModalFieldsBlock>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name={FIELD_NAMES.title}
                  label={t('Title')}
                  data-qa={testIds.GROUP_TITLE_INPUT}
                  fullWidth
                  required
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumberField
                  name={FIELD_NAMES.numberOfTeams}
                  data-qa={testIds.GROUP_NUMBER_OF_TEAMS_INPUT}
                  label={t('Number of teams')}
                  disabled={isSubmitting}
                  min={2}
                  required
                />
              </Grid>
            </ModalFieldsBlock>
          </DialogContent>
          <Divider />
          <DialogActions className={classes.actions}>
            <Box display="flex" padding={1}>
              <Box mr={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleModalClose}
                  data-qa={testIds.GROUP_CANCEL_BUTTON}
                  disabled={isSubmitting}
                >
                  {t('Cancel')}
                </Button>
              </Box>
              <ButtonWithProgress
                type="submit"
                data-qa={testIds.GROUP_SUBMIT_BUTTON}
                color="primary"
                variant="contained"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {isEditMode ? t('Save') : t('Create')}
              </ButtonWithProgress>
            </Box>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

const submittingSelector = createLoadingSelector([
  competitionProfileActions.updateGroupRequest.toString(),
  competitionProfileActions.createGroupRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => {
  return {
    isSubmitting: submittingSelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreateModal);
