import { CancelToken } from 'axios';
import restService from '@core/api/rest-service';
import { Status, User, UsersResponse } from '@core/types';

export const refreshSession = () =>
  restService.get('/v1/users/current/refreshSession');

export const getUsers = (
  queryParams?: URLSearchParams,
  cancelToken?: CancelToken,
) =>
  restService.get<UsersResponse>(`/v1/users`, {
    params: queryParams,
    cancelToken,
  });

export const updateUsers = (userId: User['id'], data: { status: Status }) =>
  restService.patch<void>(`/v1/users/${userId}`, data);
