import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  Divider,
  DialogContent,
  DialogActions,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form } from 'formik';
import { object, number, string } from 'yup';

import { Club } from '@core/icons';
import { Logo, TextField } from '@core/components';
import { LogoSize } from '@core/types';
import { StandingItem } from '@volleyball/types';

enum FIELD_NAMES {
  POINTS = 'points',
  CHANGE_REASON = 'changeReason',
}

interface FormValues {
  [FIELD_NAMES.POINTS]: number;
  [FIELD_NAMES.CHANGE_REASON]: string;
}

interface Props {
  handleClose: () => void;
  handleSubmit: (values: any, rowData: StandingItem) => void;
  isOpen?: boolean;
  rowData?: StandingItem;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
  },
}));

const EditStandingsPointsModal = ({
  handleClose,
  handleSubmit,
  isOpen,
  rowData,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getInitialValues = (): FormValues => {
    return {
      [FIELD_NAMES.POINTS]: 0,
      [FIELD_NAMES.CHANGE_REASON]: '',
    };
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="xs" fullWidth>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={object().shape({
          [FIELD_NAMES.POINTS]: number().required(t('Required')),
          [FIELD_NAMES.CHANGE_REASON]: string().required(t('Required')),
        })}
        onSubmit={(values) => {
          setIsSubmitting(true);
          handleSubmit(values, rowData);
          handleClose();
        }}
        enableReinitialize
      >
        <Form noValidate>
          <DialogContent>
            <Box>
              <Typography variant="body2" className={classes.title}>
                {t('Standings adjustment')}
              </Typography>
            </Box>
            <Box display="flex" flex="1" mb={4}>
              <Logo
                size={LogoSize.LARGE}
                src={
                  rowData?.competitor?.logoFileLink ||
                  rowData?.competitor?.clubLogoFileLink
                }
              >
                <Club />
              </Logo>
              <Box
                ml={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  variant="body1"
                  noWrap
                  style={{ fontWeight: 'bold' }}
                >
                  {rowData?.competitorName}
                </Typography>
                <Typography variant="body2" noWrap color="textSecondary">
                  {rowData?.competitor?.internationalTitle}
                </Typography>
              </Box>
            </Box>
            <Box mb={2}>
              <TextField
                required
                name={FIELD_NAMES.POINTS}
                data-qa={FIELD_NAMES.POINTS}
                type="number"
                label={t('Points')}
              />
              <Box mt={2} mb={2}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  label={t('Reason')}
                  name={FIELD_NAMES.CHANGE_REASON}
                  data-qa={FIELD_NAMES.CHANGE_REASON}
                />
              </Box>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Box display="flex" padding={1}>
              <Box mr={1}>
                <Button variant="outlined" onClick={handleClose}>
                  {t('Cancel')}
                </Button>
              </Box>
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                {t('Save')}
              </Button>
            </Box>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default EditStandingsPointsModal;
