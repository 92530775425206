import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { pathOr } from 'ramda';

import { Logo, SummaryBlockValues } from '@core/components';
import { Club } from '@core/icons';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { formatDate, getVenue } from '@core/helpers';
import { CLOSE_BUTTON } from '@core/constants/test-ids';
import { actions as matchActions } from '@core/store/modules/api/match';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { actions as matchSheetActions } from '@core/store/modules/tabs/match-sheet-management';
import {
  CustomerResponse,
  GlobalModalProps,
  LogoSize,
  MatchPlayerOrigin,
  MatchModel,
} from '@core/types';
import { State } from '@core/store';
import SummaryBlockExpandable from '@core/components/summary-block-expandable';

import MatchSheetHistory from './match-sheet-history';

interface DispatchProps {
  actions: {
    match: typeof matchActions;
  };
}

interface StateProps {
  isLoading: boolean;
  match: MatchModel;
  currentCustomer: CustomerResponse;
}

interface ModalParams {
  matchId: number;
  competitorId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
}

type Props = GlobalModalProps<ModalParams> & DispatchProps & StateProps;

const MatchSheetValidationsModal = (props: Props) => {
  const { isModalOpen, modalActions, modalParams, match, currentCustomer } =
    props;
  const [competitor, setCompetitor] = useState<any>();

  const competitionTitle = pathOr(
    '',
    ['_embedded', 'competition', 'title'],
    match,
  );
  const competitionSeasonTitle = pathOr(
    '',
    ['_embedded', 'competition', 'competitionSeasonTitle'],
    match,
  );

  useEffect(() => {
    const origin = modalParams.matchPlayerOrigin === MatchPlayerOrigin.HOME;
    setCompetitor({
      logo: origin
        ? match.homeCompetitorLogoFileLink
        : match.awayCompetitorLogoFileLink,
      title: origin ? match.homeCompetitorTitle : match.awayCompetitorTitle,
      internationalTitle: origin
        ? match.homeCompetitorInternationalTitle
        : match.awayCompetitorInternationalTitle,
    });
  }, [match]);

  const { t } = useTranslation();

  const getInfoBlocks = (): SummaryBlockValues => {
    return [
      {
        label: t('Competition'),
        value: competitionTitle,
      },
      {
        label: t('Season'),
        value: competitionSeasonTitle,
      },
      {
        label: t('Stage'),
        value: match?.groupTitle,
      },
      {
        label: t('Match No.'),
        value: match?.number,
      },
      {
        label: t('RND'),
        value: match?.round,
      },
      {
        label: t('MD'),
        value: match?.matchDayNumber,
      },
      {
        label: t('Home'),
        value: match?.homeCompetitorTitle,
      },
      {
        label: t('Away'),
        value: match?.awayCompetitorTitle,
      },
      {
        label: t('Date, time'),
        value: `${match?.date} ${match?.time}`,
        dateField: true,
      },
      {
        label: t('Venue'),
        value: getVenue(match.venue)?.title,
      },
    ];
  };

  const handleClose = () => {
    modalActions.closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t('Match sheet history')}</DialogTitle>
      <DialogContent>
        <SummaryBlockExpandable
          firstRowElements={[competitionTitle, competitionSeasonTitle]}
          secondRowElements={[
            `${match?.homeCompetitorTitle} - ${match?.awayCompetitorTitle}`,
            formatDate(
              currentCustomer?.dateTimeFormat,
              `${match?.date} ${match?.time}`,
            ),
          ]}
          values={getInfoBlocks()}
        />
        <Box mt={3}>
          <Grid container>
            <Logo size={LogoSize.LARGE} src={competitor?.logo}>
              <Club />
            </Logo>
            <Box
              ml={1.5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography style={{ fontWeight: 'bold' }} variant="body1">
                {competitor?.title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {competitor?.internationalTitle}
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box mt={3}>
          <MatchSheetHistory
            matchId={modalParams.matchId}
            competitorId={modalParams.competitorId}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleClose}
              data-qa={CLOSE_BUTTON}
            >
              {t('Close')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  matchSheetActions.getMatchSheetHistoryRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    match: bindActionCreators(matchActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  { modalParams }: GlobalModalProps,
): StateProps => ({
  isLoading: loadingSelector(state),
  match: getMatchById(state, modalParams.matchId),
  currentCustomer: getCurrentCustomer(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchSheetValidationsModal);
