import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { getSports, getGenders } from '@core/helpers';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { getCompetitorByTeamId } from '@core/store/modules/tabs/competitor-profile/selectors';
import {
  Competitor,
  CompetitionResponse,
  TeamModel,
  Sports,
} from '@core/types';
import { State } from '@core/store';

interface StateProps {
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
  competitionDetails: CompetitionResponse;
}

interface OwnProps {
  competitorId: number;
  competitionId: number;
}

type Props = OwnProps & StateProps;

const CompetitorProfileSummary = (props: Props) => {
  const { competitorDetails, competitionDetails } = props;
  const { t } = useTranslation();

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Team ID'),
      value: competitorDetails?.team?.id,
    },
    {
      label: t('Competition'),
      value: competitionDetails?.title,
    },

    {
      label: t('Season'),
      value: competitionDetails?.competitionSeasonTitle,
    },
    {
      label: t('Sport'),
      value: getSports(t, competitionDetails?.sportTitle as Sports),
    },
    {
      label: t('Gender'),
      value: getGenders(t, competitionDetails?.gender),
    },
    {
      label: t('Club'),
      value: competitorDetails?.team?.club?.title,
    },
  ];

  return <SummaryBlock values={getInfoBlocks()} />;
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitorDetails: getCompetitorByTeamId(state, ownProps?.competitorId),
  competitionDetails: getCompetitionDetailsById(ownProps.competitionId)(state),
});

export default connect(mapStateToProps)(CompetitorProfileSummary);
