import { useEffect, Suspense, useState } from 'react';
import {
  Routes as RouterRoutes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { FullScreenSpinner } from '@core/components';
import { store as storeFeatureToggle } from '@core/pages/feature-toggle';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { decodeTabs } from '@core/store/modules/ui/tabs/helpers';
import { createURL, getCustomerCode } from '@core/helpers';
import { useAuth0Singleton, useAuth0IsLoading } from '@core/helpers/auth';
import * as customerApi from '@core/api/customers';
import {
  defaultUserRoleSet,
  managerUserRoleSet,
  moGroupRoleSet,
} from '@core/types';
import NotFoundPage from '@core/pages/not-found';
import Authorized from '@core/pages/authenticated';
import LoggedOut from '@core/pages/logged-out';
import NoCustomerFound from '@core/pages/no-customer-found';
import MatchesPage from '@core/pages/matches';
import Dashboard from '@core/pages/dashboard';
import LandingPage from '@volleyball/pages/landing';
import CompetitionProfilePage from '@volleyball/pages/competition-profile';
import RefereeCategoriesPage from '@volleyball/pages/referee-categories';
import MatchProfilePage from '@volleyball/pages/match-profile';
import AppointmentsPage from '@volleyball/pages/appointments';
import { DisciplineSettingsWithLayout as DisciplineSettings } from '@volleyball/pages/discipline-settings';
import { lazyLoadPage } from '@volleyball/store/lazy-load-page';
import paths, { accessLinks } from './paths';
import ProtectedRoute from './protected-route';
import { useCoreSelector } from '@core/hooks';
import { useAppDispatch } from '@volleyball/store/hooks';
import { getTabMapping } from '@volleyball/constants/pages-map';
import { getMainMenuMapping } from '@volleyball/constants/main-menu-map';

const AgeGroupsPage = lazyLoadPage(
  'pages/ageGroupsPage',
  import('@core/pages/age-groups'),
);
const CompetitionLevelListPage = lazyLoadPage(
  'pages/competitionLevels',
  import('@core/pages/competition-levels'),
);
const FeatureTogglePage = lazyLoadPage(
  'featureToggle',
  import('@core/pages/feature-toggle'),
);
const LoginPage = lazyLoadPage('pages/login', import('@core/pages/login'));
const PersonsPage = lazyLoadPage(
  'pages/persons',
  import('@core/pages/persons'),
);
const RegisterVerifyPage = lazyLoadPage(
  'pages/register-verify',
  import('@core/pages/register-verify'),
);
const RegisterSuccessPage = lazyLoadPage(
  'pages/register-success',
  import('@core/pages/register-success'),
);
const SeasonListPage = lazyLoadPage(
  'pages/seasons',
  import('@core/pages/seasons'),
);
const TermsAndConditionsPage = lazyLoadPage(
  'pages/terms',
  import('@core/pages/terms-and-conditions'),
);
const UsersPage = lazyLoadPage('pages/users', import('@core/pages/users'));
const UserProfile = lazyLoadPage(
  'pages/userProfile',
  import('@core/pages/user-profile'),
);
const VenuesPage = lazyLoadPage('pages/venues', import('@core/pages/venues'));
const VenueProfilePage = lazyLoadPage(
  'pages/venueProfile',
  import('@core/pages/venue-profile'),
);
const NationalTeamsPage = lazyLoadPage(
  'pages/nationalTeams',
  import('@core/pages/representatives'),
);
const ClubsPage = lazyLoadPage('pages/clubs', import('@core/pages/clubs'));
const ClubPage = lazyLoadPage('pages/club', import('@core/pages/club'));
const ClubProfilePage = lazyLoadPage(
  'pages/clubProfile',
  import('@core/pages/club-profile'),
);
const CompetitionsPage = lazyLoadPage(
  'pages/competitions',
  import('@core/pages/competitions'),
);
const CompetitionCreationPage = lazyLoadPage(
  'pages/createCompetition',
  import('@core/pages/competition-creation'),
);
const RefereeGroups = lazyLoadPage(
  'pages/refereeGroups',
  import('@core/pages/referee-groups'),
);

const Routes = () => {
  const { pathname, hash } = useLocation();
  const [customerExist, setCustomerExist] = useState<boolean | null>(null);
  const customerCode = getCustomerCode();
  const dispatch = useAppDispatch();
  const featureToggles = useCoreSelector(
    storeFeatureToggle.selectors.getFeatureToggles,
  );

  const routeParams = {
    tabMapping: getTabMapping(),
    mainMenuMapping: getMainMenuMapping(),
  } as const;

  useEffect(() => {
    if (!featureToggles?.length) {
      dispatch(storeFeatureToggle.actions.getFeatureToggles());
    }

    const fetchCustomer = async () => {
      try {
        const { error } = await customerApi.getCurrentCustomer();
        if (error?.code === 404) {
          throw new Error();
        }

        setCustomerExist(true);
      } catch {
        setCustomerExist(false);
      }
    };
    if (customerCode) {
      fetchCustomer();
    }
  }, [customerCode, featureToggles, dispatch]);

  useEffect(() => {
    const tabsDecoded = decodeTabs(hash);

    dispatch(
      tabsActions.setAllTabs({
        allTabs: [
          {
            isClosable: false,
            name: pathname,
            id: `tab-id-${pathname}`,
          },
          ...(tabsDecoded?.allTabs?.length ? tabsDecoded.allTabs : []),
        ],
        activeIndex: tabsDecoded?.activeIndex || 0,
      }),
    );
  }, [pathname, hash, dispatch]);

  useAuth0Singleton();
  const { isLoading } = useAuth0IsLoading();
  if (isLoading) {
    return <FullScreenSpinner absolute />;
  }

  if (customerCode && customerExist) {
    return (
      <Suspense fallback={<FullScreenSpinner absolute />}>
        <RouterRoutes>
          <Route
            path={createURL(paths.registerVerify)}
            element={<RegisterVerifyPage />}
          />
          <Route
            path={createURL(paths.registerSuccess)}
            element={<RegisterSuccessPage />}
          />
          <Route path={createURL(paths.landing)} element={<LandingPage />} />
          {/* TODO: JB: not used anymore? */}
          <Route path={createURL(paths.login)} element={<LoginPage />} />
          <Route path={createURL(paths.register)} element={<LoginPage />} />
          <Route
            path={createURL(paths.home)}
            element={
              <ProtectedRoute
                requiredAccess={[accessLinks.home, accessLinks.clubHome]}
              >
                <Dashboard {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.clubs)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.clubs}>
                <ClubsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.club)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.club}>
                <ClubPage {...routeParams} />
              </ProtectedRoute>
            }
          />

          <Route
            path={createURL(paths.clubProfile)}
            element={
              <ProtectedRoute roleAccessArray={managerUserRoleSet}>
                <ClubProfilePage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.users)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.users}>
                <UsersPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.nationalTeams)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.nationalClubs}>
                <NationalTeamsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.competitionCreation)}
            element={
              <ProtectedRoute roleAccessArray={managerUserRoleSet}>
                <CompetitionCreationPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.competitions)}
            element={
              <ProtectedRoute
                requiredAccess={[
                  accessLinks.competitions,
                  accessLinks.clubAdminCompetitions,
                ]}
              >
                <CompetitionsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.competitionProfile)}
            element={
              <ProtectedRoute roleAccessArray={managerUserRoleSet}>
                <CompetitionProfilePage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.disciplineSettings)}
            element={
              <ProtectedRoute roleAccessArray={managerUserRoleSet}>
                <DisciplineSettings {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.ageGroups)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.ageGroups}>
                <AgeGroupsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.seasons)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.seasons}>
                <SeasonListPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.competitionLevels)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.competitionLevels}>
                <CompetitionLevelListPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.refereeGroups)}
            element={
              <ProtectedRoute roleAccessArray={moGroupRoleSet}>
                <RefereeGroups {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.refereeCategories)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.refereeCategories}>
                <RefereeCategoriesPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.venues)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.venues}>
                <VenuesPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.venueProfile)}
            element={
              <ProtectedRoute roleAccessArray={managerUserRoleSet}>
                <VenueProfilePage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.appointments)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.appointments}>
                <AppointmentsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.matches)}
            element={
              <ProtectedRoute
                requiredAccess={[
                  accessLinks.matches,
                  accessLinks.clubAdminMatches,
                ]}
              >
                <MatchesPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.matchProfile)}
            element={
              <ProtectedRoute roleAccessArray={defaultUserRoleSet}>
                <MatchProfilePage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.persons)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.persons}>
                <PersonsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.userProfile)}
            element={
              <ProtectedRoute>
                <UserProfile {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.featureToggle)}
            element={
              <ProtectedRoute requiredAccess={accessLinks.featureToggle}>
                <FeatureTogglePage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route
            path={createURL(paths.termsAndConditions)}
            element={
              <ProtectedRoute>
                <TermsAndConditionsPage {...routeParams} />
              </ProtectedRoute>
            }
          />
          <Route path={createURL(paths.notFound)} element={<NotFoundPage />} />
          <Route
            path={createURL(paths.customerRoot)}
            element={<Navigate to={createURL(paths.login)} />}
          />
          <Route element={<NotFoundPage />} />
        </RouterRoutes>
      </Suspense>
    );
  } else if (customerCode && customerExist === false) {
    return <NoCustomerFound />;
  } else if (customerCode && customerExist === null) {
    return <FullScreenSpinner absolute />;
  } else {
    return (
      <RouterRoutes>
        <Route path={createURL(paths.authenticated)} element={<Authorized />} />
        <Route path={createURL(paths.loggedOut)} element={<LoggedOut />} />
        <Route path={createURL(paths.root)} element={<NoCustomerFound />} />
        <Route element={<NoCustomerFound />} />
      </RouterRoutes>
    );
  }
};

export default Routes;
