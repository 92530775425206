import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createFilterId,
  validateStringAgainstDayjsFormat,
} from '@core/helpers';
import { FilterDatePicker } from '@core/components';
import { FilterModel, FilterTabProps } from '@core/types';

type OwnProps = {
  label: string;
  filterProps: Partial<FilterModel>;
};

type Props = OwnProps & FilterTabProps;

const DatePickerField = (props: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { handleFilterReplace, filters, label, filterProps } = props;
  const filterId = createFilterId(filterProps);
  const dateValue = filters?.[filterId]?.filterValues?.[0]?.value;

  function handleChange(date: string) {
    const isValid = validateStringAgainstDayjsFormat(date, 'YYYY-MM-DD');
    const filter = {
      ...filterProps,
      filterValues: { value: isValid ? date : null },
    } as FilterModel;

    handleFilterReplace(filter);

    if (isValid) {
      setError(null);
    } else {
      setError(t('Invalid date'));
    }
  }

  return (
    <FilterDatePicker
      label={label}
      onChange={handleChange}
      value={dateValue}
      error={error}
    />
  );
};

export default DatePickerField;
