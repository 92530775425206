import React from 'react';
import { Field, FieldProps, FieldInputProps } from 'formik';
import clsx from 'clsx';
import { none, isNil } from 'ramda';
import type { SelectProps } from '@mui/material/Select';
import {
  Grid,
  Select,
  Chip,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { LanguageDirections, SelectOptions } from '@core/types';
import { isValue, languageDirection } from '@core/helpers';
import { Tooltip, ErrorMessage } from '@core/components';

import * as testIds from './tests/test-ids';
import { CloseButton } from './components/close-button';

interface OwnProps {
  name: string;
  label: string;
  options: SelectOptions;
  onChange?: (value: any) => any;
  clearable?: boolean;
  inputProps?: {
    'data-qa': string;
  };
  tooltipText?: string;
  width?: string;
  withTooltipMargin?: boolean;
  renderCustomOptions?: (options: SelectOptions) => any;
  menuDirectionRow?: boolean;
  renderCustomInputValue?: (val: any) => any;
  removableChips?: boolean;
  smallField?: boolean;
  chipTranslationFn?: (t: TFunction, value: any) => any;
}

interface StyleProps {
  width?: string;
  tooltipText?: string;
  withTooltipMargin?: boolean;
  smallField?: boolean;
}

type Props = OwnProps & SelectProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: ({ width }: StyleProps) => width || '100%',
    marginRight: ({ tooltipText, withTooltipMargin }: StyleProps) => {
      const tooltipIconWith = 34;
      const marginWithoutTooltipText = withTooltipMargin ? tooltipIconWith : 0;

      if (languageDirection === LanguageDirections.RTL) return 'unset';

      return tooltipText ? theme.spacing(1) : marginWithoutTooltipText;
    },

    marginLeft: ({ tooltipText, withTooltipMargin }: StyleProps) => {
      const tooltipIconWith = 34;
      const marginWithoutTooltipText = withTooltipMargin ? tooltipIconWith : 0;

      if (languageDirection === LanguageDirections.LTR) return 'unset';

      return tooltipText ? theme.spacing(1) : marginWithoutTooltipText;
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  select: {
    position: 'static',
    minWidth: '201px',
  },
  clearableSelect: {
    marginLeft: 26,
  },
  chip: {
    margin: 2,
  },
  inputWrapper: {
    position: 'relative',
    width: '100%',
    height: ({ smallField }: StyleProps) => (smallField ? '36px' : 'auto'),
  },
  menuDirectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 380,
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  smallField: {
    minWidth: 'auto',
    padding: 8.5,
    minHeight: '19px',
  },
}));

export const SelectFieldWrapper = (props: Props) => {
  const {
    name,
    label,
    options,
    onChange,
    disabled,
    multiple,
    clearable,
    className,
    inputProps,
    width,
    tooltipText,
    withTooltipMargin,
    renderCustomOptions,
    renderCustomInputValue,
    required,
    menuDirectionRow,
    'data-qa': dataQa,
    removableChips,
    smallField,
    chipTranslationFn,
  } = props;
  const classes = useStyles({
    width,
    tooltipText,
    withTooltipMargin,
    smallField,
  });
  const { t } = useTranslation();
  const menuClasses = {
    list: clsx({
      [classes.menuDirectionRow]: menuDirectionRow,
    }),
  };

  function renderInputValue(field: FieldInputProps<any>) {
    if (renderCustomInputValue) {
      return renderCustomInputValue(field.value);
    }

    return multiple ? () => t('Please Select') : null;
  }

  function getInputValue(inputValue: any) {
    if (!isNil(inputValue)) {
      return inputValue;
    }

    return multiple ? [] : '';
  }

  function renderOptions() {
    if (renderCustomOptions) {
      return renderCustomOptions(options);
    }

    return isValue(options) ? (
      options.map((option) => (
        <MenuItem
          data-qa={testIds.SELECT_FIELD_OPTION}
          key={option.value}
          value={option.value}
          disabled={option.selected}
        >
          {option.label}
        </MenuItem>
      ))
    ) : (
      <Box mx={2}>
        <Typography variant="body1">{t('No options')}</Typography>
      </Box>
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const shouldShowFieldError = none(isNil, [
          meta.error,
          meta.touched || null,
        ]);
        const shouldShowCloseIcon = isValue(field.value) && clearable;
        const shrinkLabel = isValue(field.value);
        const selectClasses = {
          select: clsx(classes.select, {
            [classes.clearableSelect]: shouldShowCloseIcon,
            [classes.smallField]: smallField,
          }),
        };

        function renderChips() {
          function handleOnDelete(value: any) {
            const index = field.value.indexOf(value, 0);
            field.value.splice(index, 1);

            if (index > -1) {
              setFieldValue(name, field.value, true);
            }
          }

          return (
            <Box
              position="absolute"
              ml={2}
              left="100%"
              width="100%"
              display="flex"
              flexWrap="wrap"
            >
              {field.value &&
                (field.value as Array<string>).map((value) => (
                  <Chip
                    key={value}
                    label={
                      chipTranslationFn ? chipTranslationFn(t, value) : value
                    }
                    className={classes.chip}
                    onDelete={
                      removableChips ? () => handleOnDelete(value) : null
                    }
                  />
                ))}
            </Box>
          );
        }

        function handleOnChange(value: any) {
          field.onChange(value);
          onChange && onChange(value);
        }

        function handleCloseClick(event: React.SyntheticEvent) {
          event.stopPropagation();
          setFieldValue(name, '', true);
        }

        function renderInput() {
          return (
            <OutlinedInput
              notched={shrinkLabel}
              disabled={disabled}
              name={name}
              label={label}
              onBlur={field.onBlur}
              value={getInputValue(field.value)}
              onChange={handleOnChange}
              className={classes.input}
              data-qa={inputProps && inputProps['data-qa']}
            />
          );
        }

        return (
          <Box position="relative" display="flex" alignItems="center">
            <FormControl
              required={required}
              className={clsx(className, classes.root)}
              variant="outlined"
              data-qa={dataQa || testIds.SELECT_FIELD}
            >
              <InputLabel
                required={required}
                shrink={shrinkLabel}
                disabled={disabled}
                color="primary"
              >
                {label}
              </InputLabel>
              <Grid className={classes.inputWrapper}>
                <Select
                  fullWidth
                  multiple={multiple}
                  classes={selectClasses}
                  error={shouldShowFieldError}
                  disabled={disabled}
                  label={label}
                  input={renderInput()}
                  renderValue={renderInputValue(field)}
                  MenuProps={{ classes: menuClasses }}
                >
                  {renderOptions()}
                </Select>
              </Grid>
              {shouldShowCloseIcon && (
                <CloseButton onClick={handleCloseClick} />
              )}
              <ErrorMessage name={name} />
            </FormControl>
            {tooltipText && <Tooltip title={tooltipText} />}
            {multiple && renderChips()}
          </Box>
        );
      }}
    </Field>
  );
};
