import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { CompetitionMatchOfficialRolesList } from '@volleyball/types';

import { actions } from '.';

export interface DefaultState {
  competitionMatchOfficialRoles: CompetitionMatchOfficialRolesList;
}

export const defaultState: DefaultState = {
  competitionMatchOfficialRoles: null,
};

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCompetitionMatchOfficialRoles.toString()]: (
      state,
      { payload },
    ) => assoc('competitionMatchOfficialRoles', payload, state),
  },
  defaultState,
);

export default reducer;
