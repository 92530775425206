import { useTranslation } from 'react-i18next';
import { BubbleIcon } from '@ui-components';

const GoldenMatchLabel = () => {
  const { t } = useTranslation();

  return <BubbleIcon text="G" tooltipText={t('Golden match')} />;
};

export default GoldenMatchLabel;
