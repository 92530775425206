import { call, put, select } from 'redux-saga/effects';
import { XOR } from 'ts-xor';
import { createURL } from '@core/helpers';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import * as tabsSelectors from '@core/store/modules/ui/tabs/selectors';
import { NavigationTabProps } from '@core/types';
import * as competitionProfileSelectors from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import * as matchProfileSelectors from '@core/store/modules/tabs/match-profile/selectors';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import paths from '@core/routes/paths';
import * as appointmentsSelectors from '@core/store/modules/pages/appointments/selectors';
import { actions as appointmentsActions } from '@core/store/modules/pages/appointments';
import * as matchesSelectors from '@core/store/modules/pages/matches/selectors';
import { actions as matchesPageActions } from '@core/store/modules/pages/matches';

export type OnSuccessEffectParameters = XOR<
  {
    competitionId: number;
    stageId: number;
    matchId: number;
    modal: boolean;
    tabId: string;
  },
  {
    competitionId: number;
    stageId: number;
    matchIds: Array<number>;
    modal: boolean;
    tabId: string;
  }
>;

export interface OnSuccessEffectParametersPayload {
  onSuccessEffectParameters: OnSuccessEffectParameters;
}

type IsTabOpenedByUrl = (url: string) => boolean;

function* isTabOpenedByUrlFactory() {
  const tabs = (yield select(tabsSelectors.getTabsList)) as InferSelector<
    typeof tabsSelectors.getTabsList
  >;

  const isTabOpenedByUrl: IsTabOpenedByUrl = (url: string) =>
    tabs.some((tab) => tab.name === url);

  return isTabOpenedByUrl;
}

type GetOpenedTabsByTabIdOrMatchingMatchId = (parameters: {
  matchIds: Array<number>;
  tabId: string;
}) => Array<NavigationTabProps>;

function* getOpenedTabsByTabIdOrMatchingMatchIdFactory() {
  const tabs = (yield select(tabsSelectors.getTabsList)) as InferSelector<
    typeof tabsSelectors.getTabsList
  >;

  const getOpenedTabsByTabIdOrMatchingMatchId: GetOpenedTabsByTabIdOrMatchingMatchId =
    ({ tabId, matchIds }) => {
      const matchRelatedTabNames = [
        createURL(paths.matchProfile),
        createURL(paths.matchDataCreate),
      ];
      const filteredTabs = tabs.filter(
        (tab) =>
          tab.id === tabId ||
          (matchRelatedTabNames.includes(tab.name) &&
            matchIds.includes(tab.props.matchId)),
      );

      return filteredTabs;
    };

  return getOpenedTabsByTabIdOrMatchingMatchId;
}

function* refreshMatchesListInAnyTab(
  parameters: { competitionId: number; stageId: number },
  isTabOpenedByUrl: IsTabOpenedByUrl,
) {
  const { competitionId, stageId } = parameters;

  const competitionProfileStageMatchesExist = !!((yield select(
    competitionProfileSelectors.getGroupMatches,
    {
      competitionId,
      groupId: stageId,
    },
  )) as InferSelector<typeof competitionProfileSelectors.getGroupMatches>);

  const appointmentsExist = !!((yield select(
    appointmentsSelectors.getAppointments,
  )) as InferSelector<typeof appointmentsSelectors.getAppointments>);

  const matchesExist = !!((yield select(
    matchesSelectors.getMatches,
  )) as InferSelector<typeof matchesSelectors.getMatches>);

  if (
    isTabOpenedByUrl(createURL(paths.competitionProfile)) &&
    competitionProfileStageMatchesExist
  ) {
    yield put(
      competitionProfileActions.getMatchesByGroup({
        competitionId,
        groupId: stageId,
      }),
    );
  }

  if (isTabOpenedByUrl(createURL(paths.matches)) && matchesExist) {
    yield put(matchesPageActions.getMatches({}));
  }

  if (isTabOpenedByUrl(createURL(paths.appointments)) && appointmentsExist) {
    yield put(appointmentsActions.getAppointments({}));
  }
}

function* refreshTabsRelatedToMatch(
  parameters: { matchIds: Array<number> },
  isTabOpenedByUrl: IsTabOpenedByUrl,
) {
  const { matchIds } = parameters;

  for (const matchId of matchIds) {
    const matchProfileExist = !!((yield select(matchProfileSelectors.getMatch, {
      matchId,
    })) as Record<string, unknown> | null | undefined);

    if (isTabOpenedByUrl(createURL(paths.matchProfile)) && matchProfileExist) {
      yield put(matchProfileActions.getMatch({ matchId }));
    }
  }
}

function* closeTabByTabIdOrTabsRelatedToMatches(
  parameters: XOR<{ matchIds: Array<number> }, { tabId: string }>,
) {
  const { matchIds = [], tabId } = parameters;

  if (!matchIds.length && !tabId) {
    return;
  }

  // redux state better be queried each time function is called since redux state can be changed
  // by any previous call to function in which action to remove tab was dispatched
  const getOpenedTabsByTabIdOrMatchingMatchId =
    yield* getOpenedTabsByTabIdOrMatchingMatchIdFactory();

  const tabsToClose = getOpenedTabsByTabIdOrMatchingMatchId({
    matchIds,
    tabId,
  });

  for (const tab of tabsToClose) {
    yield put(tabsActions.removeTab(tab.id));
  }
}

export function* getMatchMutatedSuccessfullyCallbacks(
  parameters: OnSuccessEffectParameters,
  operation: 'create' | 'update' | 'delete',
) {
  if (!parameters) {
    return;
  }
  const { competitionId, stageId, modal, tabId, ...matchIdOrMatchIds } =
    parameters;
  const matchIds = matchIdOrMatchIds.matchId
    ? [matchIdOrMatchIds.matchId]
    : matchIdOrMatchIds.matchIds ?? [];
  const isTabOpenedByUrl = yield* isTabOpenedByUrlFactory();

  switch (operation) {
    case 'create': {
      yield call(
        refreshMatchesListInAnyTab,
        { competitionId, stageId },
        isTabOpenedByUrl,
      );
      // when create is done in a separate tab
      tabId &&
        ((yield call(closeTabByTabIdOrTabsRelatedToMatches, {
          tabId,
        })) as void);
      break;
    }
    case 'update': {
      yield call(
        refreshMatchesListInAnyTab,
        { competitionId, stageId },
        isTabOpenedByUrl,
      );
      yield call(refreshTabsRelatedToMatch, { matchIds }, isTabOpenedByUrl);
      // when update is done in a separate tab, e.g. match data create
      tabId &&
        ((yield call(closeTabByTabIdOrTabsRelatedToMatches, {
          tabId,
        })) as void);
      break;
    }
    case 'delete': {
      yield call(
        refreshMatchesListInAnyTab,
        { competitionId, stageId },
        isTabOpenedByUrl,
      );
      yield call(closeTabByTabIdOrTabsRelatedToMatches, { tabId });
      yield call(closeTabByTabIdOrTabsRelatedToMatches, { matchIds });
      break;
    }
  }

  if (modal) {
    yield put(globalModalActions.closeModal());
  }
}
