import { TFunction } from 'i18next';
import { object, string, number } from 'yup';
import { OffenderType } from '@core/types';

export enum FIELD_NAMES {
  PERSON = 'person',
  PERSON_TYPE = 'personType',
  PLAYER_SPORT = 'playerSport',
  TEAM_OFFICIAL_ROLE = 'teamOfficialRole',
}

export interface FormValues {
  [FIELD_NAMES.PERSON]: any;
  [FIELD_NAMES.PERSON_TYPE]: OffenderType;
  [FIELD_NAMES.PLAYER_SPORT]: any;
  [FIELD_NAMES.TEAM_OFFICIAL_ROLE]: any;
}

export const getValidationSchema = (t: TFunction) =>
  object().shape({
    [FIELD_NAMES.PERSON]: number().required(t('Required')),
    [FIELD_NAMES.PERSON_TYPE]: string().required(t('Required')),
    [FIELD_NAMES.PLAYER_SPORT]: number().when(
      FIELD_NAMES.PERSON_TYPE,
      ([formValue], schema) =>
        formValue === OffenderType.PLAYER
          ? schema.required(t('Required'))
          : schema,
    ),
    [FIELD_NAMES.TEAM_OFFICIAL_ROLE]: number().when(
      FIELD_NAMES.PERSON_TYPE,
      ([formValue], schema) =>
        formValue === OffenderType.TEAM_OFFICIAL
          ? schema.required(t('Required'))
          : schema,
    ),
  });

export const getInitialValues = (): FormValues => ({
  [FIELD_NAMES.PERSON]: null,
  [FIELD_NAMES.PERSON_TYPE]: null,
  [FIELD_NAMES.PLAYER_SPORT]: null,
  [FIELD_NAMES.TEAM_OFFICIAL_ROLE]: null,
});
