import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  ColumnWithSubValue,
  CustomTable,
  getPaginationValuesFactory,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import { Club } from '@core/icons';
import {
  getMatchStatus,
  getCompetitorLogoUrl,
  formatDate,
  formatTime,
  getVenue,
} from '@core/helpers';
import { Column } from '@core/components/material-table';
import {
  CustomerResponse,
  Paginated,
  MatchAllocation,
  Group,
  StageType,
} from '@core/types';
import { getStageById } from '@core/store/modules/tabs/competition-profile/selectors';
import { appendRowId } from '@core/helpers/append-row-id';
import { actions as matchesAllocationActions } from '@core/store/modules/matches-allocation';
import { getMatchesAllocation } from '@core/store/modules/matches-allocation/selectors';
import { State } from '@core/store';
import MatchOfficialAllocations from '@core/components/match-official-allocations';

import AllocationsMatchOfficialsStatus from './allocations-match-officials-status';
import { getCompetitorTitleFactory } from '@core/components/match-table';

interface DispatchProps {
  actions: {
    matchesAllocation: typeof matchesAllocationActions;
  };
}

interface StateProps {
  currentCustomer: CustomerResponse;
  matchesAllocation: Paginated<MatchAllocation>;
  stage: Group;
  isLoading: boolean;
}

interface OwnProps {
  competitionId: number;
  stageId: number;
}

type Props = OwnProps & DispatchProps & StateProps;

const StageAllocationsTab = (props: Props) => {
  const {
    actions,
    stageId,
    stage,
    matchesAllocation,
    isLoading,
    currentCustomer,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    stageId && actions.matchesAllocation.getMatchesAllocation({ stageId });
  }, []);

  const data: Array<MatchAllocation> = (matchesAllocation?.list || []).map(
    appendRowId((value) => value.match.id),
  );

  const getCompetitorTitle = getCompetitorTitleFactory(t);

  const columns: Array<Column<InferArray<typeof data>>> = [
    {
      title: t('No.'),
      field: 'match.number',
    },
    {
      title:
        stage?.stageType === StageType.ROUND_ROBIN ? t('Pool') : t('Round'),
      field: 'match.round',
    },
    {
      title: t('MD'),
      field: 'match.matchDayNumber',
    },
    {
      title: t('Date, time'),
      field: 'dateTime',
      render: (rowData) => {
        const dateTime = `${rowData?.match?.date} ${rowData?.match?.time}`;

        return rowData?.match?.date && rowData?.match?.time ? (
          <ColumnWithSubValue
            value={formatDate(currentCustomer?.dateFormat, dateTime)}
            subValue={formatTime(currentCustomer?.timeFormat, dateTime)}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Home'),
      field: 'homeCompetitor',
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
      render: (rowData) => (
        <ColumnWithSubValue
          value={getCompetitorTitle(rowData.match, 'home')}
          subValue={rowData.match.homeCompetitorInternationalTitle}
          alignment="right"
          logo={{
            url: getCompetitorLogoUrl(rowData.match, 'homeCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
    },
    {
      title: t('Away'),
      field: 'awayCompetitor',
      render: (rowData) => (
        <ColumnWithSubValue
          value={getCompetitorTitle(rowData.match, 'away')}
          subValue={rowData.match.awayCompetitorInternationalTitle}
          logo={{
            url: getCompetitorLogoUrl(rowData.match, 'awayCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
    },
    {
      title: t('Venue'),
      field: 'venue',
      render: (rowData) => (
        <ColumnWithSubValue
          value={getVenue(rowData.match.venue)?.title}
          subValue={getVenue(rowData.match.venue)?.internationalTitle}
        />
      ),
    },
    {
      title: t('Status'),
      field: 'status',
      render: (rowData) => (
        <ColumnWithSubValue value={getMatchStatus(t, rowData.match.status)} />
      ),
    },
    {
      title: t('Match officials'),
      field: 'officials',
      render: (rowData: any) => (
        <AllocationsMatchOfficialsStatus
          matchId={rowData.match.id}
          groupId={stageId}
        />
      ),
    },
  ];

  const handleDataReload = ({ page }: { page: number }) =>
    actions.matchesAllocation.getMatchesAllocation({ stageId, page });

  const getPaginationValues = getPaginationValuesFactory(
    handleDataReload,
    matchesAllocation,
  );

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Matches'),
      value: matchesAllocation?.total,
    },
  ];

  return (
    <>
      <SummaryBlock isLoading={isLoading} values={getInfoBlocks()} />
      <Box mt={3}>
        <CustomTable
          noPaper
          columns={columns}
          isLoading={isLoading}
          data={data}
          topBorder
          pagination={getPaginationValues()}
          options={{
            detailPanelType: 'single',
          }}
          detailPanel={[
            {
              tooltip: t('Allocate'),
              icon: () => <ExpandMore />,
              openIcon: () => <ExpandLess />,
              render: (rowData: { rowData: MatchAllocation }) => (
                <Box m={2}>
                  <MatchOfficialAllocations
                    borderedTable
                    match={rowData?.rowData?.match}
                    stageId={stageId}
                    group={stage}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    matchesAllocationActions.getMatchesAllocationRequest({
      stageId: ownProps.stageId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchesAllocation: bindActionCreators(matchesAllocationActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
  isLoading: isLoadingSelector(ownProps)(state),
  matchesAllocation: getMatchesAllocation(ownProps.stageId)(state),
  stage: getStageById(ownProps.competitionId, ownProps.stageId)(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageAllocationsTab);
