import React from 'react';
import { Box, IconButton } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { Logo } from '@core/components';
import { Club } from '@core/icons';
import { LogoSize, Competitor } from '@core/types';

type Props = {
  competitor: Competitor;
  disabled: boolean;
};

const useStyles = makeStyles(() => ({
  button: {
    cursor: 'move',
  },
}));

const TeamCardAvatar = (props: Props) => {
  const classes = useStyles();
  const { competitor, disabled } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2} display="flex">
        <IconButton className={classes.button} disabled={disabled}>
          <DragIndicator />
        </IconButton>
      </Box>
      <Box display="flex">
        <Logo
          size={LogoSize.SMALL}
          src={competitor.logoFileLink || competitor.clubLogoFileLink}
        >
          <Club />
        </Logo>
      </Box>
    </Box>
  );
};

export default TeamCardAvatar;
