import { useMemo } from 'react';
import clsx from 'clsx';
import { KnockoutBracket } from '@core/types/api/knockout-standing-brackets';
import { useBracketStyle } from './knockout-brackets-style';

export const topBottomHeight = {
  1: {
    top: 'calc(38px)',
    bottom: 'calc(60px)',
  },
  2: {
    top: 'calc(80px)',
    bottom: 'calc(80px)',
  },
  3: {
    top: 'calc(180px)',
    bottom: 'calc(180px)',
  },
  4: {
    top: 'calc(400px)',
    bottom: 'calc(400px)',
  },
  5: {
    top: 'calc(800px)',
    bottom: 'calc(800px)',
  },
  6: {
    top: 'calc(1500px)',
    bottom: 'calc(1500px)',
  },
  7: {
    top: 'calc(2000px)',
    bottom: 'calc(2000px)',
  },
};

type Props = {
  data: KnockoutBracket;
  currentIndex: number;
  isMaxRound: boolean;
  numberOfItems: number;
};

const KnockoutBracketLine = ({
  data,
  currentIndex,
  isMaxRound,
  numberOfItems,
}: Props) => {
  const classes = useBracketStyle();

  const borderHeight = useMemo(() => {
    if (isMaxRound) {
      return 0;
    }
    return currentIndex % 2 === 0
      ? topBottomHeight[data.round as keyof typeof topBottomHeight].top
      : topBottomHeight[data.round as keyof typeof topBottomHeight].bottom;
  }, [currentIndex, data.round, isMaxRound]);

  return (
    <div
      className={clsx({
        [classes.bracketLine]: !isMaxRound,
        [classes.bracketLineTop]: !isMaxRound && currentIndex % 2 === 0,
        [classes.bracketLineBottom]: !isMaxRound && currentIndex % 2 !== 0,
        [classes.bracketLineLong]:
          !isMaxRound && currentIndex < Math.ceil(numberOfItems / 2),
        [classes.bracketLineShort]:
          !isMaxRound && !(currentIndex < Math.ceil(numberOfItems / 2)),
      })}
      style={{ height: `${borderHeight}` }}
    />
  );
};

export default KnockoutBracketLine;
