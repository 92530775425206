import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uuid } from '@core/helpers';
import { prop, pathOr } from 'ramda';
import { EditTwoTone, GroupAddOutlined } from '@mui/icons-material';

import {
  FullScreenSpinner,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { createURL } from '@core/helpers';
import { EDIT_BUTTON, MANAGE_BUTTON } from '@core/constants/test-ids';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';
import {
  getCompetitorTeamOfficials,
  getCompetitorPlayersList,
  getCompetitorByTeamId,
  getPlayerPositions,
} from '@core/store/modules/tabs/competitor-profile/selectors';
import paths from '@core/routes/paths';
import {
  Competitor,
  CompetitionResponse,
  CompetitorPlayerList,
  CompetitorTeamOfficialList,
  TeamModel,
  PlayerPositions,
} from '@core/types';
import { State } from '@core/store';

import PlayersTable from './players-table';
import OfficialsTable from './officials-table';
import { ActionsButtons, Alert } from '@ui-components';

interface OwnProps {
  competitionId: number;
  teamId: number;
  competitorId: number;
}

interface StateProps {
  competitorPlayers: CompetitorPlayerList;
  playerPositions: PlayerPositions;
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
  competition: CompetitionResponse;
  isOfficialLoading: boolean;
  isPlayersLoading: boolean;
  competitorTeamOfficials: CompetitorTeamOfficialList;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
    competitionProfile: typeof competitionProfileActions;
    competitorProfile: typeof competitorProfileActions;
  };
}

type Props = OwnProps & DispatchProps & StateProps;

const PlayerListTab = (props: Props) => {
  const {
    actions,
    competitionId,
    teamId,
    competitorPlayers,
    competition,
    competitorDetails,
    competitorId,
    isOfficialLoading,
    isPlayersLoading,
    competitorTeamOfficials,
    playerPositions,
  } = props;
  const sportPlayerHasPosition = !!playerPositions?.length;
  const { t } = useTranslation();
  const sportId = prop('sportId', competition);

  const canEditTeamRoster = !!competitorDetails?.competitor?._links?.patch;

  useEffect(() => {
    actions.competitorProfile.getPlayerPositions({ sportId, competitorId });
    actions.competitorProfile.getCompetitorPlayerList({
      competitionId,
      teamId,
      competitorId,
    });
    actions.competitorProfile.getTeamOfficialsRoles({ competitorId });
    actions.competitorProfile.getTeamOfficials({
      competitionId,
      teamId,
      competitorId,
    });
  }, []);

  const competitorPlayerCount = competitorPlayers && competitorPlayers.length;
  const sortedCompetitorPlayers =
    competitorPlayers &&
    competitorPlayers.sort((a, b) =>
      b.player.localFamilyName > a.player.localFamilyName ? 1 : -1,
    );

  const officialsCount =
    competitorTeamOfficials && competitorTeamOfficials.length;
  const sortedCompetitorTeamOfficials =
    competitorTeamOfficials &&
    competitorTeamOfficials.sort((a, b) =>
      b.teamOfficial.localFamilyName > a.teamOfficial.localFamilyName ? 1 : -1,
    );

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Players'),
      value: competitorPlayerCount,
    },
    {
      label: t('Team officials'),
      value: officialsCount,
    },
  ];

  const handleEditClick = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.playerListEdit),
      props: {
        competition: {
          id: competitionId,
          title: competition.title,
          season: competition.competitionSeasonTitle,
          sport: competition.sportTitle,
          gender: competition.gender,
        },
        teamId,
        competitor: {
          id: competitorDetails?.competitor?.id || '-',
          title: competitorDetails?.competitor?.title || '-',
          internationalTitle: competitorDetails?.competitor?.internationalTitle,
          logoFileLink: competitorDetails?.competitor?.logoFileLink,
          club: competitorDetails?.team?.club?.title || '-',
        },
        sportId: competitorDetails?.team?.sport?.id || '-',
      },
      id: uuid(),
    });
  };

  const handleManageClick = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.playerListManagement),
      props: {
        competition: {
          id: competitionId,
          title: competition.title,
          season: competition.competitionSeasonTitle,
          minPlayers: pathOr(
            null,
            ['competitionSettings', 'minimumPlayersInSquad'],
            competition,
          ),
          maxPlayers: pathOr(
            null,
            ['competitionSettings', 'maximumPlayersInSquad'],
            competition,
          ),
          earliestPlayerDateOfBirth: pathOr(
            null,
            ['competitionSettings', 'latestPlayerDateOfBirth'],
            competition,
          ),
          latestPlayerRegistrationDate: pathOr(
            null,
            ['competitionSettings', 'latestPlayerRegistrationDate'],
            competition,
          ),
          sport: competition.sportTitle,
          gender: competition.gender,
        },
        teamId,
        competitor: {
          id: competitorDetails?.competitor?.id || '-',
          title: competitorDetails?.competitor?.title || '-',
          internationalTitle: competitorDetails?.competitor?.internationalTitle,
          logoFileLink: competitorDetails?.competitor?.logoFileLink,
          club: competitorDetails?.team?.club?.title || '-',
        },
        clubId: competitorDetails?.team?.club?.id || '-',
        sportId: competitorDetails?.team?.sport?.id || '-',
      },
      id: uuid(),
    });
  };

  /*

    const actionButtons: React.ComponentProps<typeof ActionsButtons>['buttons'] = [
    () => (
      <Button variant="outlined" color="primary" onClick={handleClose}>
        {t('Cancel')}
      </Button>
    ),
    () => (
      <Button variant="contained" color="primary" onClick={handleClose}>
        {t('Save')}
      </Button>
    ),
  ];

   */

  const actionButtons: React.ComponentProps<typeof ActionsButtons>['buttons'] =
    [
      () => (
        <Button
          color="primary"
          variant="contained"
          onClick={handleManageClick}
          startIcon={<GroupAddOutlined />}
          data-qa={MANAGE_BUTTON}
        >
          {t('Manage')}
        </Button>
      ),
      () => (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleEditClick}
          startIcon={<EditTwoTone />}
          data-qa={EDIT_BUTTON}
        >
          {t('Edit')}
        </Button>
      ),
    ];

  return (
    <Grid>
      {isPlayersLoading && isOfficialLoading ? (
        <FullScreenSpinner />
      ) : (
        <>
          {competitorPlayerCount === 0 && (
            <Box mb={4}>
              <Alert
                title={t(
                  'Team members are missing. Please manage players and team officials on the team roster.',
                )}
                warning
              />
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SummaryBlock values={getInfoBlocks()} />
            {canEditTeamRoster && <ActionsButtons buttons={actionButtons} />}
          </Box>
          <Box mt={3}>
            <Grid>
              <PlayersTable
                players={sortedCompetitorPlayers}
                sportPlayerHasPosition={sportPlayerHasPosition}
                competitorId={competitorId}
                competitionId={competitionId}
                teamId={teamId}
              />
              <OfficialsTable
                officials={sortedCompetitorTeamOfficials}
                competitionId={competitionId}
                teamId={teamId}
                competitorId={competitorId}
              />
            </Grid>
          </Box>
        </>
      )}
    </Grid>
  );
};

const officialLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    competitorProfileActions.getTeamOfficialsRequest({
      competitionId: ownProps.competitionId,
      competitorId: ownProps.competitorId,
      teamId: ownProps.teamId,
    }),
    competitorProfileActions.getTeamOfficialsRolesRequest({
      competitorId: ownProps.competitorId,
    }),
  ]);

const playersLoadingSelector = (ownProps: OwnProps & { sportId: number }) =>
  createLoadingSelector([
    competitorProfileActions.getPlayerPositionsRequest({
      competitorId: ownProps.competitorId,
      sportId: ownProps.sportId,
    }),
    competitorProfileActions.getCompetitorPlayerListRequest({
      competitionId: ownProps.competitionId,
      competitorId: ownProps.competitorId,
      teamId: ownProps.teamId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
    competitorProfile: bindActionCreators(competitorProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { competitionId, competitorId } = ownProps ?? {};
  const competition = getCompetitionDetailsById(competitionId)(state);

  return {
    competition,
    competitorDetails: getCompetitorByTeamId(state, competitorId),
    competitorPlayers: getCompetitorPlayersList(state, competitorId),
    competitorTeamOfficials: getCompetitorTeamOfficials(state, competitorId),
    playerPositions: getPlayerPositions(state, competitorId),
    isOfficialLoading: officialLoadingSelector(ownProps)(state),
    isPlayersLoading: playersLoadingSelector({
      ...ownProps,
      sportId: competition?.sportId,
    })(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerListTab);
