import { createActions } from 'redux-actions';
import { OnSuccessEffectParametersPayload } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import { MatchSet, PostMatchSetPayload } from '@core/types';

export const NAMESPACE = 'matchSets';

export const actions = createActions(
  'GET_MATCH_SETS',
  'GET_MATCH_SETS_REQUEST',
  'GET_MATCH_SETS_SUCCESS',
  'GET_MATCH_SETS_FAILURE',
  'SET_MATCH_SETS',

  'CREATE_MATCH_SET',
  'CREATE_MATCH_SET_REQUEST',
  'CREATE_MATCH_SET_SUCCESS',
  'CREATE_MATCH_SET_FAILURE',

  'CREATE_MATCH_SETS',
  'CREATE_MATCH_SETS_REQUEST',
  'CREATE_MATCH_SETS_SUCCESS',
  'CREATE_MATCH_SETS_FAILURE',

  'UPDATE_MATCH_SETS',
  'UPDATE_MATCH_SETS_REQUEST',
  'UPDATE_MATCH_SETS_SUCCESS',
  'UPDATE_MATCH_SETS_FAILURE',

  'UPDATE_SET',
  'UPDATE_SET_REQUEST',
  'UPDATE_SET_SUCCESS',
  'UPDATE_SET_FAILURE',

  'DELETE_SET',
  'DELETE_SET_REQUEST',
  'DELETE_SET_SUCCESS',
  'DELETE_SET_FAILURE',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getMatchSets: IdentityActionFunction<{ matchId: number }>;
  getMatchSetsRequest: RequestLifecycleAction<{ id: number }>;
  getMatchSetsSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchSetsFailure: RequestLifecycleAction<{ id: number }>;
  setMatchSets: IdentityActionFunction<{
    matchId: number;
    matchSets: Array<MatchSet>;
  }>;

  createMatchSet: IdentityActionFunction<
    {
      matchId: number;
      matchSet: PostMatchSetPayload;
      onSuccess?: () => void;
    } & OnSuccessEffectParametersPayload
  >;
  createMatchSetRequest: RequestLifecycleAction<{ id: number }>;
  createMatchSetSuccess: RequestLifecycleAction<{ id: number }>;
  createMatchSetFailure: RequestLifecycleAction<{ id: number }>;

  createMatchSets: IdentityActionFunction<
    {
      matchId: number;
      matchSets: Array<PostMatchSetPayload>;
      onSuccess?: () => void;
    } & OnSuccessEffectParametersPayload
  >;
  createMatchSetsRequest: RequestLifecycleAction<{ id: number }>;
  createMatchSetsSuccess: RequestLifecycleAction<{ id: number }>;
  createMatchSetsFailure: RequestLifecycleAction<{ id: number }>;

  updateMatchSets: IdentityActionFunction<{
    matchId: number;
    matchSets: Array<MatchSet>;
    onSuccess?: () => void;
  }>;
  updateMatchSetsRequest: RequestLifecycleAction<{ id: number }>;
  updateMatchSetsSuccess: RequestLifecycleAction<{ id: number }>;
  updateMatchSetsFailure: RequestLifecycleAction<{ id: number }>;
  updateSet: IdentityActionFunction<
    {
      setId: number;
      set: MatchSet;
      onSuccess?: () => void;
    } & OnSuccessEffectParametersPayload
  >;
  updateSetRequest: RequestLifecycleAction<{ id: number }>;
  updateSetSuccess: RequestLifecycleAction<{ id: number }>;
  updateSetFailure: RequestLifecycleAction<{ id: number }>;

  deleteSet: IdentityActionFunction<
    { setId: number; onSuccess?: () => void } & OnSuccessEffectParametersPayload
  >;
  deleteSetRequest: RequestLifecycleAction<{ id: number }>;
  deleteSetSuccess: RequestLifecycleAction<{ id: number }>;
  deleteSetFailure: RequestLifecycleAction<{ id: number }>;
};
