import React from 'react';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Steps } from '@core/types';

export interface HeaderStepperProps {
  steps: Steps;
  activeStep?: number;
}

const useStyles = makeStyles(() => ({
  stepper: {
    padding: 0,
    background: 'transparent',
    width: '100%',
  },
  labelContainer: {
    lineHeight: 1,
  },
}));

const HeaderStepper = (props: HeaderStepperProps) => {
  const { steps, activeStep = 0 } = props;
  const classes = useStyles();

  return (
    <Stepper className={classes.stepper} activeStep={activeStep}>
      {steps.map((step) => (
        <Step key={step.title}>
          <StepLabel
            classes={{ labelContainer: classes.labelContainer }}
            optional={
              step.subtitle && (
                <Typography variant="caption">{step.subtitle}</Typography>
              )
            }
          >
            {step.title}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default HeaderStepper;
