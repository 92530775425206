import { Grid, Box, Theme, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Check } from '@mui/icons-material';
import { Field, FieldProps } from 'formik';
import { Tooltip, ErrorMessage } from '@core/components';

type Value = string | number;

interface Option {
  value: Value;
  label: string;
}

interface OwnProps {
  isLoading?: boolean;
  options: Array<Option>;
  fieldName: string;
  title?: string;
  tooltipText?: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: (prevValue: any, value: any) => void;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ChipsSelect = (props: Props) => {
  const {
    options,
    fieldName,
    title,
    required,
    multiple,
    tooltipText,
    disabled,
    onChange,
  } = props;
  const classes = useStyles();

  return (
    <>
      {title && (
        <Box display="flex" mb={1}>
          <Typography variant="body1" color="textSecondary">
            {`${title}${required ? ' *' : ''}`}
          </Typography>
          {tooltipText && (
            <Box>
              <Tooltip title={tooltipText} />
            </Box>
          )}
          <Box ml={1}>
            <ErrorMessage name={fieldName} />
          </Box>
        </Box>
      )}
      <Grid data-qa={fieldName}>
        <Field name={fieldName}>
          {({ field, form }: FieldProps) => {
            const handleChange = (value: Value) => {
              if (disabled) {
                return;
              }

              if (multiple) {
                const currentValues = form?.values[fieldName].includes(value)
                  ? form?.values[fieldName].filter(
                      (currentValue: string) => currentValue !== value,
                    )
                  : [...(form?.values[fieldName] ?? {}), value];

                form.setFieldValue(field.name, currentValues, true);
              } else {
                form.setFieldValue(
                  field.name,
                  required
                    ? value
                    : form?.values[fieldName] === value
                      ? null
                      : value,
                  true,
                );
              }

              if (onChange) {
                onChange(field.value, value);
              }
            };

            const isSelected = (value: Value) => {
              if (multiple) {
                return form?.values[fieldName].includes(value);
              }

              return form?.values[fieldName] === value;
            };

            return (
              <>
                {options.map((option: Option, index: number) => {
                  return (
                    <Chip
                      key={option.value}
                      label={option.label}
                      color={isSelected(option.value) ? 'primary' : 'default'}
                      onClick={() => handleChange(option.value)}
                      className={classes.chip}
                      data-qa={`${fieldName}-${index}`}
                      disabled={disabled && !isSelected(option.value)}
                      icon={
                        isSelected(option.value) && multiple ? (
                          <Box display="flex">
                            <Check fontSize="small" />
                          </Box>
                        ) : (
                          <></>
                        )
                      }
                    />
                  );
                })}
              </>
            );
          }}
        </Field>
      </Grid>
    </>
  );
};

export default ChipsSelect;
