import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  loading: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: `calc(100vh - ${theme.spacing(23)})`,
  },
  bodyContainer: {
    minHeight: '50vh',
  },
  agreeBlock: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  agreeLabel: {
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2.5, 8, 2.5, 8),
  },
  agreeCheckbox: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
  },
  actions: {
    display: 'flex',
    flex: '1 1 100%',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
  },
  actionControls: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        display: 'flex',
        flex: '1 1 100%',
        flexFow: 'row nowrap',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(1),
      },
    },
  },
  actionButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      '& > div': {
        flex: '1 1 50%',
      },
      '& > div:first-child': {
        marginRight: theme.spacing(0.5),
      },
      '& > div:last-child': {
        marginLeft: theme.spacing(0.5),
      },
    },
  },
  buttonAccept: {},
  buttonCancel: {
    position: 'relative',
    left: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
}));
