import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import {
  CompetitionTypesList,
  CompetitionType,
  APIErrorsModel,
} from '@volleyball/types';

import {
  SET_REFEREE_CATEGORIES,
  SET_NEW_CATEGORY_BEING_CREATED,
  SET_CATEGORY_BEING_MODIFIED,
  SET_ERRORS,
} from './constants';

export interface DefaultState {
  allRefereeCategories: CompetitionTypesList;
  refereeCategoryBeingModified: CompetitionType;
  isNewRefereeCategoryBeingCreated: boolean;
  errors: APIErrorsModel;
}

export const defaultState: DefaultState = {
  allRefereeCategories: null,
  refereeCategoryBeingModified: null,
  isNewRefereeCategoryBeingCreated: false,
  errors: null,
};

const reducer = handleActions<DefaultState, any>(
  {
    [SET_REFEREE_CATEGORIES]: (state, { payload }) =>
      assoc('allRefereeCategories', payload, state),
    [SET_CATEGORY_BEING_MODIFIED]: (state, { payload }) =>
      assoc('refereeCategoryBeingModified', payload, state),
    [SET_NEW_CATEGORY_BEING_CREATED]: (state, { payload }) =>
      assoc('isNewRefereeCategoryBeingCreated', payload, state),
    [SET_ERRORS]: (state, { payload }) => assoc('errors', payload, state),
  },
  defaultState,
);

export default reducer;
