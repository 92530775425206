import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { isNil } from 'ramda';

import { FilterTabProps, Paginated, FilterModel } from '@core/types';
import { SearchField, FullScreenSpinner } from '@core/components';

import Checkboxes from './components/checkboxes';

type QueryParams = {
  query?: string;
  page?: number;
  'filter[candidatesForCompetition]'?: number;
};

type OwnProps = {
  isLoading: boolean;
  filterId: string;
  getList: (queryParams: QueryParams) => void;
  resetList: (val: void) => void;
  paginatedList: Paginated<any>;
  searchByQuery: (queryParams: QueryParams) => void;
  filterProps: Partial<FilterModel>;
  formatFilterLabel: (id: string) => string;
  formatLabel?: (item: any) => string;
  initialQueryParams?: QueryParams;
};

type Props = OwnProps & FilterTabProps;

const SearchableFilterList = (props: Props) => {
  const {
    handleFilterAdd,
    handleFilterRemove,
    filters,
    isLoading,
    paginatedList,
    resetList,
    filterId,
    getList,
    searchByQuery,
    filterProps,
    formatFilterLabel,
    formatLabel,
    initialQueryParams,
  } = props;

  useEffect(() => {
    getList(initialQueryParams);

    return () => resetList();
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const filterValues = filters?.[filterId]?.filterValues || [];
  const showSpinner = isLoading && isNil(paginatedList);

  function handleSearch(queryParams: QueryParams) {
    setSearchQuery(queryParams.query);
    searchByQuery({
      ...initialQueryParams,
      ...queryParams,
    });
  }

  function loadNext(queryParams: QueryParams) {
    searchByQuery({
      ...initialQueryParams,
      ...queryParams,
    });
  }

  return (
    <Grid>
      <SearchField query={searchQuery} onSearch={handleSearch} />
      {showSpinner ? (
        <FullScreenSpinner />
      ) : (
        <Box mt={1}>
          <Checkboxes
            handleFilterAdd={handleFilterAdd}
            handleFilterRemove={handleFilterRemove}
            filterValues={filterValues}
            searchQuery={searchQuery}
            loadNext={loadNext}
            filterProps={filterProps}
            paginatedList={paginatedList}
            formatFilterLabel={formatFilterLabel}
            formatLabel={formatLabel}
          />
        </Box>
      )}
    </Grid>
  );
};

export default SearchableFilterList;
