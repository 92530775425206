import { all } from 'redux-saga/effects';

import competitionsFilterTabSaga from '@core/store/modules/modals/competitions-filter-tab/sagas';
import venuesFilterTabSaga from '@core/store/modules/modals/venues-filter-tab/sagas';
import teamsFilterTabSaga from '@core/store/modules/modals/teams-filter-tab/sagas';
import nationalitiesFilterSaga from '@core/store/modules/modals/nationalities-filter-tab/sagas';
import fixtureEditSaga from '@core/store/modules/modals/fixture-match-edit/sagas';
import seasonFilterTabSaga from '@core/store/modules/modals/season-filter-tab/sagas';

export default function* saga() {
  yield all([
    fixtureEditSaga(),
    competitionsFilterTabSaga(),
    venuesFilterTabSaga(),
    teamsFilterTabSaga(),
    nationalitiesFilterSaga(),
    seasonFilterTabSaga(),
  ]);
}
