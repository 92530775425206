import { all, call, Effect, put, take } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as apiCompetitionMatchOfficialRole from '@core/api/competition-match-official-role';

import { actions as competitionMatchOfficialRoleActions } from '.';

export function* getCompetitionMatchOfficialRoles(
  competitionId: number,
): Generator<Effect, any, any> {
  yield put(
    competitionMatchOfficialRoleActions.getCompetitionMatchOfficialRolesRequest(),
  );
  const response = yield call(
    apiCompetitionMatchOfficialRole.getCompetitionMatchOfficialRoles,
    competitionId,
  );

  if (!response.error) {
    yield put(
      competitionMatchOfficialRoleActions.getCompetitionMatchOfficialRolesSuccess(),
    );
  } else {
    yield put(
      competitionMatchOfficialRoleActions.getCompetitionMatchOfficialRolesFailure(),
    );
  }

  return response;
}

function* getCompetitionMatchOfficialRolesFlow() {
  while (true) {
    const { payload } = yield take(
      competitionMatchOfficialRoleActions.getCompetitionMatchOfficialRoles,
    );
    const { response, error } = yield call(
      getCompetitionMatchOfficialRoles,
      payload,
    );

    if (!error) {
      yield put(
        competitionMatchOfficialRoleActions.setCompetitionMatchOfficialRoles(
          response._embedded.competitionMatchOfficialRoles,
        ),
      );
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([getCompetitionMatchOfficialRolesFlow()]);
}
