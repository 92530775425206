import React from 'react';
import { useDrag, useDrop, useDragLayer, DropTargetMonitor } from 'react-dnd';
import { Grid, Theme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { FindSlot, MoveSlot } from '@core/pages/team-grids-edit/use-slots';

import { ItemTypes } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 64,
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  invisible: {
    opacity: 0,
  },
  dragging: {
    //...theme.components.MuiPaper.styleOverrides.elevation1,
    ...theme.shape,
  },
  dropArea: {
    border: '1px dashed rgb(189, 189, 189)',
    backgroundColor: 'rgb(238, 238, 238)',
    ...theme.shape,
  },
}));

export interface DraggableProps {
  id: string;
  move: MoveSlot;
  find: FindSlot;
  children: React.ReactNode;
  canDrag: boolean;
  canDrop: boolean;
}

interface DragObject {
  type: string;
  id: string;
  originalIndex: number;
}

const Draggable: React.FC<DraggableProps> = ({
  id,
  children,
  move,
  find,
  canDrag,
  canDrop,
}) => {
  const originalIndex = find(id).index;
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag<
    DragObject,
    unknown,
    { isDragging: boolean }
  >({
    type: ItemTypes.Slot,
    item: { type: ItemTypes.Slot, id, originalIndex },
    canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex: index } = monitor.getItem();
      const didDrop = monitor.didDrop();

      // TODO: JB: wtf
      if (!didDrop) {
        move(droppedId, index);
      }
    },
  });

  const [, drop] = useDrop<DragObject, unknown, unknown>({
    accept: ItemTypes.Slot,
    canDrop: () => canDrop,
    hover({ id: draggedId }, monitor: DropTargetMonitor<DragObject, unknown>) {
      if (draggedId !== id && monitor.canDrop()) {
        const { index: overIndex } = find(id);
        move(draggedId, overIndex);
      }
    },
  });

  const { isAnyDragging } = useDragLayer((monitor) => ({
    isAnyDragging: monitor.isDragging(),
  }));

  const ref = (node: HTMLElement) => drag(drop(node));

  const isEmptyDropArea = isAnyDragging && !canDrag && canDrop;

  return (
    <Grid
      ref={ref}
      className={clsx(classes.root, {
        [classes.dragging]: isDragging,
        [classes.dropArea]: isEmptyDropArea,
      })}
    >
      <Grid
        className={clsx(classes.wrapper, {
          [classes.invisible]: isDragging || isEmptyDropArea,
        })}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Draggable;
