import { path } from 'ramda';
import { NAMESPACE, actions } from '.';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import type { State } from '@core/store';

export const getOrganisers = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => path<T>(['tabs', NAMESPACE, competitionId, 'organisers'], state);

export const getManagers = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => path<T>(['tabs', NAMESPACE, competitionId, 'managers'], state);

export const getCompetition = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => path<T>(['tabs', NAMESPACE, competitionId, 'competition'], state);

export const getContacts = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => path<T>(['tabs', NAMESPACE, competitionId, 'contacts'], state);

export const areContactsLoading = createLoadingSelector([
  actions.createCompetitionContactRequest.toString(),
  actions.updateCompetitionContactRequest.toString(),
  actions.deleteCompetitionContactRequest.toString(),
]);
