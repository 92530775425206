import { StatusColors, Status, CompetitionStatus } from '@core/types';

export const getStatusColor = (status: string) => {
  switch (status) {
    case CompetitionStatus.SCHEDULED:
      return StatusColors.BLUE;
    case Status.ACTIVE:
      return StatusColors.GREEN;
    case CompetitionStatus.COMPLETE:
      return StatusColors.DARK_GREY;
    default:
      return StatusColors.LIGHT_GREY;
  }
};
