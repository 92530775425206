import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, AutocompleteField } from '@core/components';
import { formatOptions } from '@core/helpers';
import { actions as sanctionActions } from '@volleyball/store/modules/tabs/impose-sanction';
import { State } from '@volleyball/store';
import {
  getCompetitors,
  getCompetitorPlayers,
} from '@volleyball/store/modules/tabs/impose-sanction/selectors';
import { SanctionType } from '@volleyball/types';
import { OFFENDER_TYPE_FIELDS } from '../constants';
import { formatPersons } from '../helpers/format-person-options';
import PersonOption from './person-option';
import PersonSingleValue from './person-single-value';
import type { FormMode } from '../constants';

type Props = {
  competitionId: number;
  mode: FormMode;
  sanctionType: SanctionType;
};

const OffenderTypePlayer = (props: Props) => {
  const { competitionId, mode, sanctionType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCompetitorTeam, setSelectedCompetitorTeam] = useState(null);
  const [selectedCompetitorId, setSelectedCompetitorId] = useState(null);
  const competitors = useSelector((state: State) =>
    getCompetitors(state, competitionId, sanctionType),
  );
  const players = useSelector((state: State) =>
    getCompetitorPlayers(state, competitionId, sanctionType),
  );

  useEffect(() => {
    dispatch({
      type: sanctionActions.getCompetitors.toString(),
      payload: { competitionId, sanctionType },
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: sanctionActions.setCurrentCompetitor.toString(),
      payload: {
        competitionId,
        sanctionType,
        data: selectedCompetitorId,
      },
    });
  }, [selectedCompetitorId]);

  useEffect(() => {
    if (!selectedCompetitorTeam) return;

    dispatch({
      type: sanctionActions.getPlayers.toString(),
      payload: {
        competitionId,
        sanctionType,
        teamId: selectedCompetitorTeam,
      },
    });
  }, [selectedCompetitorTeam]);

  function handleCompetitorInputChange(query: string) {
    dispatch({
      type: sanctionActions.getCompetitors.toString(),
      payload: { competitionId, sanctionType, query },
    });
  }

  function handleCompetitorChange(option: any) {
    setSelectedCompetitorTeam(option?.props?.teamId);
    setSelectedCompetitorId(option?.value);
  }

  return (
    <Grid container spacing={2}>
      {mode === 'edit' ? (
        <Grid item md={12}>
          <TextField
            name={OFFENDER_TYPE_FIELDS.offender}
            label={t('Player')}
            disabled
          />
        </Grid>
      ) : (
        <>
          <Grid item md={12}>
            {/* TODO: JB: probably ok, but check if AutocompleteField works with initial non empty value */}
            <AutocompleteField
              name={OFFENDER_TYPE_FIELDS.competitor}
              options={formatOptions(t, competitors?.list, 'title')}
              textFieldProps={{
                label: t('Select team in competition'),
                required: true,
              }}
              onInputChange={handleCompetitorInputChange}
              onChange={handleCompetitorChange}
              useOptionAsValue
            />
          </Grid>
          <Grid item md={12}>
            {/* TODO: JB: probably ok, but check if AutocompleteField works with initial non empty value */}
            <AutocompleteField
              name={OFFENDER_TYPE_FIELDS.player}
              options={formatPersons(t, players?.list, 'player')}
              textFieldProps={{ label: t('Select player'), required: true }}
              customComponents={{
                Option: PersonOption,
                SingleValue: PersonSingleValue,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OffenderTypePlayer;
