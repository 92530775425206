import {
  all,
  put,
  take,
  call,
  debounce,
  takeEvery,
  Effect,
  SagaReturnType,
  select,
} from 'redux-saga/effects';
import { getURLSearchParams, handleApiErrors } from '@core/helpers';
import * as groupFixturesApi from '@core/api/group-fixtures';
import * as groupsApi from '@core/api/groups';
import { getCompetition } from '@core/pages/competitions/store/api/sagas';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import {
  getCompetitorsByCompetition,
  deleteCompetitor,
} from '@core/store/modules/api/competitors/sagas';
import { getGroups, deleteGroup } from '@core/store/modules/api/groups/sagas';
import {
  getDisciplinarySanctions,
  updateDisciplinarySanctionStatus,
} from '@core/store/modules/api/disciplinary-sanctions/sagas';
import { getDisciplinaryInfringements } from '@core/store/modules/api/disciplinary-infringements/sagas';
import { getGroupsMatches } from '@core/store/modules/api/match/sagas';
import {
  getFilters,
  getGroupMatches,
  getMatchesSearchQuery,
  getSorting,
  getStageCurrentPage,
  getStagesByCompetitionId,
} from './selectors';
import { actions } from '.';
import { Group } from '@core/types';

export function* getCompetitionsProfileFlow({
  payload,
}: InferAction<typeof actions.getCompetitionDetails>): Generator<
  Effect,
  any,
  any
> {
  yield put(actions.getCompetitionDetailsRequest());
  yield put(actions.initializeCompetitionStore({ competitionId: payload }));

  const { error, response } = (yield call(
    getCompetition,
    payload,
  )) as SagaReturnType<typeof getCompetition>;

  if (!error) {
    yield put(
      actions.setCompetitionDetails({
        competitionId: payload,
        payload: response,
      }),
    );

    yield put(actions.getCompetitionDetailsSuccess());
  } else {
    yield put(actions.getCompetitionDetailsFailure());
    yield call(handleApiErrors, error);
  }
}

export function* getCompetitionsProfileWithoutInitFlow() {
  while (true) {
    const { payload } = yield take(actions.getCompetitionDetailsNoInit);

    yield put(actions.getCompetitionDetailsRequest());

    const { error, response } = yield call(getCompetition, payload);

    if (!error) {
      yield put(
        actions.setCompetitionDetails({
          competitionId: payload,
          payload: response,
        }),
      );

      yield put(actions.getCompetitionDetailsSuccess());
    } else {
      yield put(actions.getCompetitionDetailsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

// TODO: JB: doesn't support multiple different competitions requests at the same time, but ui supports opening 2 competitions at once
export function* getStageDetailsWorker({
  payload,
}: InferAction<typeof actions.getStageDetails>): Generator<Effect, any, any> {
  const { competitionId } = payload;

  yield put(actions.getStageDetailsRequest({ id: competitionId }));

  const { error, response }: SagaReturnType<typeof getGroups> = yield call(
    getGroups,
    competitionId,
  );

  if (!error) {
    const groupIds = response._embedded.groups.map(({ id }) => id);

    yield put(
      actions.setStageDetails({
        competitionId,
        payload: response._embedded.groups,
      }),
    );

    yield all(
      groupIds.map((groupId) =>
        put(
          actions.setSorting({
            competitionId,
            groupId,
            payload: {
              sortingOrderBy: 'date',
              sortingDirection: 'desc',
            },
          }),
        ),
      ),
    );

    yield put(actions.getStageDetailsSuccess({ id: competitionId }));
  } else {
    yield put(actions.getStageDetailsFailure({ id: competitionId }));
    yield call(handleApiErrors, error);
  }
}
export function* getStageDetailsWatcher() {
  yield takeEvery(actions.getStageDetails, getStageDetailsWorker);
}

export function* getCompetitorsFlow() {
  while (true) {
    const { payload } = yield take(actions.getCompetitors);

    yield put(actions.getCompetitorsRequest());

    const { error, response } = yield call(
      getCompetitorsByCompetition,
      payload,
    );

    if (!error) {
      yield put(
        actions.setCompetitors({
          competitionId: payload.competitionId,
          payload: response,
        }),
      );
      yield put(actions.getCompetitorsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getCompetitorsFailure());
    }
  }
}

export function* deleteCompetitorFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteCompetitor);

    yield put(actions.deleteCompetitorRequest());

    const { error } = yield call(deleteCompetitor, payload);
    const stages: Group[] = yield select((state) =>
      getStagesByCompetitionId(state, payload.competitionId),
    );

    if (!error) {
      yield put(
        actions.getCompetitors({ competitionId: payload.competitionId }),
      );

      yield put(actions.deleteCompetitorSuccess());
      yield all(
        stages.map((stage) =>
          call(refreshGroupMatches, payload.competitionId, stage.id),
        ),
      );
    } else {
      yield put(actions.deleteCompetitorFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* refreshGroupMatches(
  competitionId: number,
  groupId: number,
  page?: number,
) {
  const currentPage = yield select(getStageCurrentPage(competitionId, groupId));
  const paginated: ReturnType<typeof getGroupMatches> = yield select(
    getGroupMatches,
    { competitionId, groupId },
  );
  const filters: ReturnType<typeof getFilters> = yield select(getFilters, {
    competitionId,
    groupId,
  });
  const searchQuery: ReturnType<typeof getMatchesSearchQuery> = yield select(
    getMatchesSearchQuery,
    { competitionId, groupId },
  );
  const sorting: ReturnType<typeof getSorting> = yield select(getSorting, {
    competitionId,
    groupId,
  });

  const urlSearchParams: URLSearchParams = yield call(
    getURLSearchParams,
    {
      paginated,
      filters,
      searchQuery,
      sorting,
    },
    { page: page ?? currentPage, limit: null },
    { shouldAddTimeToSorting: true },
  );

  const filterKeys = Object.keys(filters);
  const hasMatchGroupFilter = filterKeys.some((key) =>
    key.includes('matchGroup.'),
  );

  if (!hasMatchGroupFilter) {
    urlSearchParams.append('filter[matchGroup][]', groupId.toString());
  }

  yield put(actions.getMatchesByGroupRequest({ id: groupId }));

  const { response, error }: SagaReturnType<typeof getGroupsMatches> =
    yield call(getGroupsMatches, groupId, urlSearchParams);

  if (!error) {
    yield put(actions.getMatchesByGroupSuccess({ id: groupId }));
    yield put(
      actions.setGroupMatches({ groupId, competitionId, payload: response }),
    );
  } else {
    yield put(actions.getMatchesByGroupFailure({ id: groupId }));
    yield call(handleApiErrors, error);
  }
}

export function* deleteGroupFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.deleteGroup)) as InferAction<
      typeof actions.deleteGroup
    >;
    const { competitionId, groupId } = payload;

    yield put(actions.deleteGroupRequest());
    const { error } = (yield call(deleteGroup, groupId)) as SagaReturnType<
      typeof deleteGroup
    >;

    if (!error) {
      yield call(getCompetition, competitionId);
      yield put(actions.getStageDetails({ competitionId }));
      yield put(actions.deleteGroupSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.deleteGroupFailure());
    }
  }
}

export function* handleCompetitionDetailsUpdate({
  competitionId,
}: {
  competitionId: number;
}) {
  yield put(actions.updateCompetitionRequest());
  const { error, response } = yield call(getCompetition, competitionId);

  if (!error) {
    yield put(
      actions.setCompetitionDetails({ competitionId, payload: response }),
    );

    yield put(actions.updateCompetitionSuccess());
  } else {
    yield put(actions.updateCompetitionFailure());
    yield call(handleApiErrors, error);
  }
}

export function* getDisciplinaryInfringementsFlow() {
  while (true) {
    const { payload } = yield take(actions.getDisciplinaryInfringements);

    yield put(actions.getDisciplinaryInfringementsRequest());

    const { error, response } = yield call(
      getDisciplinaryInfringements,
      payload.competitionId,
      payload.queryParams,
    );

    if (!error) {
      yield put(
        actions.setDisciplinaryInfringements({
          competitionId: payload.competitionId,
          payload: response,
        }),
      );
      yield put(actions.getDisciplinaryInfringementsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getDisciplinaryInfringementsFailure());
    }
  }
}

export function* getDisciplinarySanctionsFlow() {
  while (true) {
    const { payload } = yield take(actions.getDisciplinarySanctions);

    yield put(actions.getDisciplinarySanctionsRequest());

    const { error, response } = yield call(
      getDisciplinarySanctions,
      payload.competitionId,
      payload.queryParams,
    );

    if (!error) {
      yield put(
        actions.setDisciplinarySanctions({
          competitionId: payload.competitionId,
          payload: response,
        }),
      );
      yield put(actions.getDisciplinarySanctionsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getDisciplinarySanctionsFailure());
    }
  }
}

export function* updateDisciplinarySanctionStatusFlow() {
  while (true) {
    const { payload } = yield take(actions.updateSanctionStatus);
    const { competitionId, sanctionId, data } = payload;

    yield put(actions.updateSanctionStatusRequest());

    const { error } = yield call(updateDisciplinarySanctionStatus, {
      competitionId,
      sanctionId,
      data,
    });

    if (!error) {
      yield put(actions.updateSanctionStatusSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.updateSanctionStatusFailure());
    }
  }
}

export function* searchMatchesFlow(
  action: InferAction<
    typeof actions.getMatchesByGroup | typeof actions.searchMatchesByQuery
  >,
): Generator<Effect, any, any> {
  const { payload } = action;
  const { groupId, page, competitionId } = payload;

  yield call(refreshGroupMatches, competitionId, groupId, page);
}

// TODO: JB: will drop subsequent actions until previous action handler is in progress even if subsequent action is for different group/stage
export function* getGroupsMatchesFlow() {
  while (true) {
    const action = (yield take(actions.getMatchesByGroup)) as InferAction<
      typeof actions.getMatchesByGroup
    >;

    yield call(searchMatchesFlow, action);
  }
}

export function* watchSearchMatchesByQuery() {
  yield debounce(
    500,
    actions.searchMatchesByQuery.toString(),
    searchMatchesFlow,
  );
}

export function* searchFixturesFlow(action: any) {
  const {
    payload: { groupId, queryParams, competitionId },
  } = action;

  yield put(actions.getFixturesByGroupRequest({ id: groupId }));

  const { error, response } = yield call(groupFixturesApi.getGroupFixtures, {
    groupId,
    queryParams,
  });

  if (!error) {
    yield put(actions.getFixturesByGroupSuccess({ id: groupId }));
    yield put(
      actions.setGroupFixtures({ payload: response, groupId, competitionId }),
    );
  } else {
    yield put(actions.getFixturesByGroupFailure({ id: groupId }));
    yield call(handleApiErrors, error);
  }
}

export function* getGroupFixturesFlow(): Generator<Effect, any, any> {
  while (true) {
    const action = yield take(actions.getFixturesByGroup);

    yield call(searchFixturesFlow, action);
  }
}

export function* watchSearchFixtureByQuery() {
  yield debounce(
    500,
    actions.searchFixturesByQuery.toString(),
    searchFixturesFlow,
  );
}

export function* deleteGroupFixtureFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteGroupFixture);
    const { link, groupId, page, competitionId } = payload;

    yield put(actions.deleteGroupFixtureRequest());
    const { error } = yield call(groupFixturesApi.deleteGroupFixture, link);

    if (!error) {
      yield put(actions.deleteGroupFixtureSuccess());
      yield put(actions.getFixturesByGroup({ groupId, page, competitionId }));
    } else {
      yield put(actions.deleteGroupFixtureFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* updateGroupFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.updateGroup)) as InferAction<
      typeof actions.updateGroup
    >;
    const { groupId, data, competitionId } = payload;

    yield put(actions.updateGroupRequest());
    const { error } = (yield call(
      groupsApi.updateGroup,
      groupId,
      data,
    )) as InferApi<typeof groupsApi.updateGroup>;

    if (!error) {
      yield put(actions.getStageDetails({ competitionId }));
      yield put(globalModalActions.closeModal());
      yield put(actions.updateGroupSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.updateGroupFailure());
    }
  }
}

function* createGroupFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.createGroup)) as InferAction<
      typeof actions.createGroup
    >;
    const { data, competitionId } = payload;

    yield put(actions.createGroupRequest());
    const { error } = (yield call(
      groupsApi.createGroup,
      competitionId,
      data,
    )) as InferApi<typeof groupsApi.createGroup>;

    if (!error) {
      yield put(actions.getStageDetails({ competitionId }));
      yield put(globalModalActions.closeModal());
      yield put(actions.createGroupSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.createGroupFailure());
    }
  }
}

export function* getCompetitionsProfileFlowWatcher() {
  yield takeEvery(actions.getCompetitionDetails, getCompetitionsProfileFlow);
}

export default function* saga() {
  yield all([
    getCompetitionsProfileFlowWatcher(),
    getCompetitionsProfileWithoutInitFlow(),
    getStageDetailsWatcher(),
    getCompetitorsFlow(),
    deleteCompetitorFlow(),
    getDisciplinarySanctionsFlow(),
    getDisciplinaryInfringementsFlow(),
    getGroupsMatchesFlow(),
    watchSearchMatchesByQuery(),
    getGroupFixturesFlow(),
    deleteGroupFixtureFlow(),
    watchSearchFixtureByQuery(),
    updateGroupFlow(),
    createGroupFlow(),
    deleteGroupFlow(),
    updateDisciplinarySanctionStatusFlow(),
  ]);
}
