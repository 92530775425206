import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { VenueSearchField } from '@core/components';
import { VenueModel, Paginated } from '@core/types';
import { actions as competitorAssignmentActions } from '@core/store/modules/tabs/competitor-addition';
import { getDefaultVenueOptions } from '@core/store/modules/tabs/competitor-addition/selectors';
import { State } from '@core/store';

import { COMPETITOR_FIELDS } from '../../constants';

interface DispatchProps {
  actions: {
    competitorAssignment: typeof competitorAssignmentActions;
  };
}

interface OwnProps {
  competitionId: number;
}

interface StateProps {
  options: Paginated<VenueModel>;
}

type Props = DispatchProps & OwnProps & StateProps;

const DefaultVenueSearch = (props: Props) => {
  const { t } = useTranslation();
  const { actions, competitionId, options } = props;

  useEffect(() => {
    if (competitionId) {
      actions.competitorAssignment.getDefaultVenues({
        page: 1,
        competitionId,
      });
    }
  }, [actions.competitorAssignment, competitionId]);

  const handleInputChange = (query: string) => {
    actions.competitorAssignment.getDefaultVenues({
      page: 1,
      query,
      competitionId,
    });
  };

  const handleLoadNext = ({ query, page }: { query: string; page: number }) => {
    actions.competitorAssignment.getDefaultVenues({
      page,
      query,
      competitionId,
    });
  };

  return (
    <VenueSearchField
      name={COMPETITOR_FIELDS.defaultVenueOption}
      label={t('Default venue')}
      onInputChange={handleInputChange}
      options={options}
      onLoadNext={handleLoadNext}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorAssignment: bindActionCreators(
      competitorAssignmentActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  options: getDefaultVenueOptions(state, {
    competitionId: ownProps?.competitionId,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultVenueSearch);
