import { Action, createActions } from 'redux-actions';
import { MatchModel } from '@core/types';

export const NAMESPACE = 'matchesList';

export const actions = createActions(
  'GET_MATCH_URL',
  'GET_MATCH_URL_REQUEST',
  'GET_MATCH_URL_SUCCESS',
  'GET_MATCH_URL_FAILURE',

  'GET_MATCHES_REQUEST',
  'GET_MATCHES_SUCCESS',
  'GET_MATCHES_FAILURE',
  'SET_MATCHES',

  'GET_MATCHES_BY_GROUP_REQUEST',
  'GET_MATCHES_BY_GROUP_SUCCESS',
  'GET_MATCHES_BY_GROUP_FAILURE',

  'GET_MATCH_REQUEST',
  'GET_MATCH_SUCCESS',
  'GET_MATCH_FAILURE',
  'SET_MATCH',

  'REFRESH_MATCH_REQUEST',
  'REFRESH_MATCH_SUCCESS',
  'REFRESH_MATCH_FAILURE',

  'CREATE_MATCH_REQUEST',
  'CREATE_MATCH_SUCCESS',
  'CREATE_MATCH_FAILURE',

  'UPDATE_MATCH_REQUEST',
  'UPDATE_MATCH_SUCCESS',
  'UPDATE_MATCH_FAILURE',

  'DELETE_MATCH_REQUEST',
  'DELETE_MATCH_SUCCESS',
  'DELETE_MATCH_FAILURE',

  'CHANGE_MATCH_STATUS_IN_BULK_REQUEST',
  'CHANGE_MATCH_STATUS_IN_BULK_SUCCESS',
  'CHANGE_MATCH_STATUS_IN_BULK_FAILURE',

  'BULK_DELETE_REQUEST',
  'BULK_DELETE_SUCCESS',
  'BULK_DELETE_FAILURE',

  'CHANGE_MATCH_STATUS_REQUEST',
  'CHANGE_MATCH_STATUS_SUCCESS',
  'CHANGE_MATCH_STATUS_FAILURE',

  'GET_MATCHES_BY_COMPETITION_REQUEST',
  'GET_MATCHES_BY_COMPETITION_SUCCESS',
  'GET_MATCHES_BY_COMPETITION_FAILURE',

  'UPLOAD_MATCHES',
  'UPLOAD_MATCHES_REQUEST',
  'UPLOAD_MATCHES_FAILURE',
  'UPLOAD_MATCHES_SUCCESS',

  'EXPORT_MATCHES',
  'EXPORT_MATCHES_REQUEST',
  'EXPORT_MATCHES_FAILURE',
  'EXPORT_MATCHES_SUCCESS',

  'EXPORT_MATCHES_TEMPLATE',
  'EXPORT_MATCHES_TEMPLATE_REQUEST',
  'EXPORT_MATCHES_TEMPLATE_FAILURE',
  'EXPORT_MATCHES_TEMPLATE_SUCCESS',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getMatchUrl: UnknownAction;
  getMatchUrlRequest: RequestLifecycleAction;
  getMatchUrlSuccess: RequestLifecycleAction;
  getMatchUrlFailure: RequestLifecycleAction;

  getMatchesRequest: RequestLifecycleAction;
  getMatchesSuccess: RequestLifecycleAction;
  getMatchesFailure: RequestLifecycleAction;
  setMatches: IdentityActionFunction<Array<MatchModel>>;

  getMatchesByGroupRequest: RequestLifecycleAction<{ id: number }>;
  getMatchesByGroupSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchesByGroupFailure: RequestLifecycleAction<{ id: number }>;

  getMatchRequest: RequestLifecycleAction;
  getMatchSuccess: RequestLifecycleAction;
  getMatchFailure: RequestLifecycleAction;
  setMatch: IdentityActionFunction<MatchModel>;

  refreshMatchRequest: RequestLifecycleAction;
  refreshMatchSuccess: RequestLifecycleAction;
  refreshMatchFailure: RequestLifecycleAction;

  createMatchRequest: RequestLifecycleAction;
  createMatchSuccess: RequestLifecycleAction;
  createMatchFailure: RequestLifecycleAction;

  updateMatchRequest: RequestLifecycleAction;
  updateMatchSuccess: RequestLifecycleAction;
  updateMatchFailure: RequestLifecycleAction;

  deleteMatchRequest: RequestLifecycleAction;
  deleteMatchSuccess: RequestLifecycleAction;
  deleteMatchFailure: RequestLifecycleAction;

  changeMatchStatusInBulkRequest: RequestLifecycleAction;
  changeMatchStatusInBulkSuccess: RequestLifecycleAction;
  changeMatchStatusInBulkFailure: RequestLifecycleAction;

  bulkDeleteRequest: RequestLifecycleAction;
  bulkDeleteSuccess: RequestLifecycleAction;
  bulkDeleteFailure: RequestLifecycleAction;

  changeMatchStatusRequest: RequestLifecycleAction;
  changeMatchStatusSuccess: RequestLifecycleAction;
  changeMatchStatusFailure: RequestLifecycleAction;

  getMatchesByCompetitionRequest: RequestLifecycleAction<{ id: number }>;
  getMatchesByCompetitionSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchesByCompetitionFailure: RequestLifecycleAction<{ id: number }>;

  uploadMatches: IdentityActionFunction<{
    groupId: number;
    competitionId: number;
    file: unknown;
    importByTeamNames: boolean;
    successCallback: () => Action<any>;
    page: number;
  }>;
  uploadMatchesRequest: RequestLifecycleAction;
  uploadMatchesSuccess: RequestLifecycleAction;
  uploadMatchesFailure: RequestLifecycleAction;

  exportMatches: IdentityActionFunction<{ groupId: number }>;
  exportMatchesRequest: RequestLifecycleAction;
  exportMatchesSuccess: RequestLifecycleAction;
  exportMatchesFailure: RequestLifecycleAction;

  exportMatchesTemplate: IdentityActionFunction<{ groupId: number }>;
  exportMatchesTemplateRequest: RequestLifecycleAction;
  exportMatchesTemplateSuccess: RequestLifecycleAction;
  exportMatchesTemplateFailure: RequestLifecycleAction;
};
