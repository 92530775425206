import React, { useCallback, useEffect } from 'react';
import { uuid } from '@core/helpers';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { TFunction } from 'i18next';

import { getKitSetList } from '@core/pages/club-profile/store/selectors';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createURL } from '@core/helpers';
import { actions as competitorAdditionActions } from '@core/store/modules/tabs/competitor-addition';
import {
  getSelectedTeam,
  getKitSets,
} from '@core/store/modules/tabs/competitor-addition/selectors';
import { getTeamColorSetsHaveSpecialPlayer } from '@core/factories/kit-set/get-team-color-sets-have-special-player';
import paths from '@core/routes/paths';
import {
  TeamModel,
  KitSetList,
  KitTypes,
  KitType,
  Sport,
  Sports,
} from '@core/types';
import { State } from '@core/store';
import { OwnProps as CreateKitSetProps } from '@core/pages/create-kit-set/create-kit-set';
import {
  makePlayerKitPreviewProps,
  ModalFieldsBlock,
  NamedValueColumn,
  PlayerKitPreviewNew as PlayerKitPreview,
  SelectField,
} from '@core/components';

import { COMPETITOR_FIELDS, CompetitorFormValues } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
}));

interface DispatchProps {
  actions: {
    competitorAddition: typeof competitorAdditionActions;
    tabs: typeof tabsActions;
  };
}

interface OwnProps {
  competitionId: number;
}

interface StateProps {
  kitSets: KitSetList;
  selectedTeam: TeamModel;
}

type Props = DispatchProps & OwnProps & StateProps;

export const getKitTitle = (t: TFunction, kitType: KitType): string =>
  kitType === 'home'
    ? t('Primary set')
    : kitType === 'away'
      ? t('Secondary set')
      : kitType === 'reserved'
        ? t('Tertiary set')
        : null;

export const getSpecialPlayerKitLabel = (
  t: TFunction,
  sport: Sport,
): string => {
  switch (sport.type) {
    case Sports.BEACH_VOLLEYBALL:
    case Sports.SNOW_VOLLEYBALL:
    case Sports.VOLLEYBALL:
      return t('Libero colors');
    default:
      return t('Special player colors');
  }
};

const KitSetPanel = (props: Props) => {
  const { actions, competitionId, selectedTeam, kitSets } = props;
  const { t } = useTranslation();
  const { values } = useFormikContext<CompetitorFormValues>();
  const classes = useStyles();

  const clubId = selectedTeam?.club?.id;

  useEffect(() => {
    if (clubId) {
      actions.competitorAddition.getClubKitSets({ competitionId, clubId });
    }
  }, [actions.competitorAddition, competitionId, clubId]);

  const handleCreateKit = useCallback(() => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.createKitSet),
      title: t('Create color set'),
      props: {
        competitionId,
        clubId,
        kitSetId: null,
        teamColorSetsHaveSpecialPlayer: getTeamColorSetsHaveSpecialPlayer(
          selectedTeam.club.sports,
        ),
      } as TabbedProps<CreateKitSetProps>,
      id: uuid(),
    });
  }, [actions.tabs, competitionId, clubId]);

  const teamColorSetsHaveSpecialPlayer = getTeamColorSetsHaveSpecialPlayer(
    selectedTeam?.sport,
  );
  const selectedKitSet = kitSets?.find(
    (kitSet) => kitSet.id === values[COMPETITOR_FIELDS.teamColorSetId],
  );

  return kitSets ? (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Grid item xs={12}>
        <Divider orientation="horizontal" />
      </Grid>
      <Grid container item spacing={3} sx={{ mt: 5, mx: 0 }}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Team color sets')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            required
            name={COMPETITOR_FIELDS.teamColorSetId}
            data-qa={COMPETITOR_FIELDS.teamColorSetId}
            label={t('Select team color set')}
            options={kitSets.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={3} sx={{ ml: 0, mt: 0, mb: 5 }}>
        <Grid item xs={2} md={2} />
        <Grid item xs={10} md={10}>
          <Box>
            <Box>
              <Grid item xs={12}>
                <Box mt={3} mb={3}>
                  <Divider orientation="horizontal" />
                </Box>
              </Grid>
              {KitTypes.map((kitType, index, array) => (
                <React.Fragment key={index}>
                  <ModalFieldsBlock title={getKitTitle(t, kitType)}>
                    <Grid container item>
                      <Grid
                        container
                        item
                        xs={teamColorSetsHaveSpecialPlayer ? 6 : 12}
                      >
                        <NamedValueColumn
                          label={t('Team colors')}
                          regular
                          value={
                            <PlayerKitPreview
                              disabled={!selectedKitSet}
                              {...(selectedKitSet
                                ? makePlayerKitPreviewProps(
                                    selectedKitSet,
                                    kitType,
                                    'regular',
                                  )
                                : { colors: [null, null] })}
                            />
                          }
                        />
                      </Grid>
                      {teamColorSetsHaveSpecialPlayer && (
                        <Grid container item xs={6}>
                          <NamedValueColumn
                            label={getSpecialPlayerKitLabel(
                              t,
                              selectedTeam?.sport,
                            )}
                            regular
                            value={
                              <PlayerKitPreview
                                disabled={!selectedKitSet}
                                {...(selectedKitSet
                                  ? makePlayerKitPreviewProps(
                                      selectedKitSet,
                                      kitType,
                                      'special',
                                    )
                                  : { colors: [null, null] })}
                              />
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </ModalFieldsBlock>
                  {index !== array.length - 1 && (
                    <Grid item xs={12}>
                      <Box mb={3}>
                        <Divider orientation="horizontal" />
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <Box mr={1}>
                <Typography variant="body2" color="textSecondary">
                  {t("Can't find color set? Probably it's not created yet.")}
                </Typography>
              </Box>
              <Typography variant="body2">
                <Link onClick={handleCreateKit}>{t('Create color set')}</Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorAddition: bindActionCreators(competitorAdditionActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { competitionId } = ownProps;
  const selectedTeam = getSelectedTeam<TeamModel>(state, { competitionId });

  let kitSets = getKitSetList(state, { clubId: selectedTeam?.clubId });
  kitSets = kitSets
    ? kitSets
    : getKitSets<KitSetList>(state, { competitionId });

  return {
    kitSets,
    selectedTeam,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KitSetPanel);
