import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Grid, Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ColumnWithSubValue,
  PlayerKitPreviewNew as PlayerKitPreview,
  makePlayerKitPreviewProps,
  ButtonWithDropdown,
  ButtonWithDropdownProps,
} from '@core/components';
import { getTeamColorSetsHaveSpecialPlayer } from '@core/factories/kit-set/get-team-color-sets-have-special-player';
import { getIsPlayerKitEnabled } from '@core/factories/kit-set/get-is-player-kit-enabled';
import {
  KitPlayerTypes,
  KitSet,
  KitType,
  PlayerKitsModel,
  Sports,
} from '@core/types';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
// import * as matchProfileSelectors from '@core/store/modules/tabs/match-profile/selectors';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile/index';
import { MATCH_PROFILE_TEAM_KIT_SET_BUTTON } from '../tests/test-ids';
import type { TFunction } from 'i18next';
import type { State } from '@core/store';
import { BubbleIcon } from '@ui-components';

export const getKitTitle = (t: TFunction, kitType: KitType): string =>
  kitType === 'home'
    ? t('Primary set')
    : kitType === 'away'
      ? t('Secondary set')
      : kitType === 'reserved'
        ? t('Tertiary set')
        : null;

const getSpecialPlayerLabel = (t: TFunction, sport: Sports): string => {
  switch (sport) {
    case Sports.VOLLEYBALL:
      return t('Libero');
    case Sports.BEACH_VOLLEYBALL:
    case Sports.SNOW_VOLLEYBALL:
    default:
      return null;
  }
};

const getSpecialPlayerLabelInitial = (t: TFunction, sport: Sports): string => {
  switch (sport) {
    case Sports.VOLLEYBALL:
      return t('L');
    case Sports.BEACH_VOLLEYBALL:
    case Sports.SNOW_VOLLEYBALL:
    default:
      return null;
  }
};

const SortByKitTypePredicate = (
  a: readonly [KitType, any],
  b: readonly [KitType, any],
): number => {
  const sortOrder: Array<KitType> = ['home', 'away', 'reserved'];

  return sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]);
};

const getKitType = (value: PlayerKitsModel, kitSet: KitSet): KitType => {
  if (!value || !kitSet) {
    return null;
  }

  switch (value.id) {
    case kitSet.awayPlayerKit?.id:
      return 'away';
    case kitSet.homePlayerKit?.id:
      return 'home';
    case kitSet.reservedPlayerKit?.id:
      return 'reserved';
    default:
      return null;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  loadingRoot: {
    paddingLeft: theme.spacing(1),
  },
  multiplePlayerKitPreviewRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  singlePlayerKitPreviewRoot: {
    position: 'relative',
  },
  topBadge: {
    position: 'absolute',
    right: '-12px',
    top: '-6px',
  },
  bottomBadge: {
    position: 'absolute',
    right: '-12px',
    bottom: '-6px',
  },
  progress: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: theme.spacing(2.5),
  },
}));

interface OwnProps {
  competitorId: number;
  kitSet: KitSet;
  kitSetId: number;
  matchId: number;
  sport: Sports;
  teamColor: PlayerKitsModel;
}

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
  };
}

interface StateProps {
  isLoading: boolean;
  isSubmitting: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const PlayerKitSelect = (props: Props) => {
  const {
    actions,
    competitorId,
    isLoading,
    isSubmitting,
    kitSet: nullableKitSet,
    matchId,
    sport,
    teamColor,
  } = props;
  const kitSet = nullableKitSet ?? ({} as any as typeof nullableKitSet);
  const { t } = useTranslation();
  const classes = useStyles();

  const teamColorSetsHaveSpecialPlayer = getTeamColorSetsHaveSpecialPlayer({
    id: null,
    type: sport,
  });

  type Keys = OmitByPropertyValueType<KitSet, PlayerKitsModel>;

  type ButtonWithDropdownPropsInstance = ButtonWithDropdownProps<
    PlayerKitsModel['id']
  >;

  const options = (
    Object.entries(kitSet) as Array<[keyof Keys, PlayerKitsModel]>
  )
    .map(([, value]) => [getKitType(value, kitSet), value] as const)
    .filter(([kitType]) => kitType)
    .sort(SortByKitTypePredicate)
    .map<ButtonWithDropdownPropsInstance['options'][number]>(
      ([kitType, value]) => {
        const isPlayerKitEnabled = getIsPlayerKitEnabled(value);

        // if (
        //   false &&
        //   import.meta.env.MODE === 'development' &&
        //   !isPlayerKitEnabled
        // ) {
        //   return {
        //     text: `${getKitTitle(t, kitType)} (disabled or n/a in prod)`,
        //     value: value.id,
        //   };
        // }

        return isPlayerKitEnabled
          ? {
              text: getKitTitle(t, kitType),
              value: value.id,
            }
          : null;
      },
    )
    .filter(Boolean);

  const onSelect: ButtonWithDropdownPropsInstance['onSelect'] = (value) => {
    const teamColorId = value;
    actions.matchProfile.updateMatchCompetitor({
      matchId,
      competitorId,
      teamColorId,
    });
  };

  const isTeamColorInKitSet = options.some(
    (option) => option.value === teamColor?.id,
  );

  if (isNil(kitSet?.id) && isLoading) {
    return (
      <Box className={classes.loadingRoot}>
        <ColumnWithSubValue
          value={t('Team colors')}
          valueTypographyProps={{ color: 'textSecondary', variant: 'caption' }}
          // @ts-expect-error
          subValue={
            <Box className={classes.progress}>
              <CircularProgress size="15px" />
            </Box>
          }
          valueSecondaryProps={undefined}
        />
      </Box>
    );
  }

  return (
    <ColumnWithSubValue
      // @ts-expect-error
      startText={
        <Grid
          container
          className={classes.multiplePlayerKitPreviewRoot}
          flexDirection="row"
          flexWrap="nowrap"
          spacing={2}
        >
          {KitPlayerTypes.map((value, index) => {
            const isSpecialPlayer = value === 'special';

            const show =
              !isSpecialPlayer ||
              (isSpecialPlayer && teamColorSetsHaveSpecialPlayer);

            return show ? (
              <Grid key={index} item>
                <div className={classes.singlePlayerKitPreviewRoot}>
                  <PlayerKitPreview
                    {...(isTeamColorInKitSet
                      ? makePlayerKitPreviewProps(teamColor, value)
                      : { colors: [null, null] })}
                  />
                  {isSpecialPlayer && (
                    <Grid className={classes.bottomBadge}>
                      <BubbleIcon
                        text={getSpecialPlayerLabelInitial(t, sport)}
                        tooltipText={getSpecialPlayerLabel(t, sport)}
                      />
                    </Grid>
                  )}
                </div>
              </Grid>
            ) : null;
          })}
        </Grid>
      }
      value={t('Team colors')}
      valueTypographyProps={{ color: 'textSecondary', variant: 'caption' }}
      // @ts-expect-error
      subValue={
        <ButtonWithDropdown
          isLoading={isLoading || isSubmitting}
          disabled={isLoading || isSubmitting || options.length === 0}
          options={options}
          onSelect={onSelect}
          variant="text"
          align="flex-end"
          data-qa={MATCH_PROFILE_TEAM_KIT_SET_BUTTON}
          sx={{ padding: 0 }}
        >
          {teamColor && isTeamColorInKitSet
            ? getKitTitle(t, getKitType(teamColor, kitSet))
            : t('Select team color')}
        </ButtonWithDropdown>
      }
      valueSecondaryProps={undefined}
    />
  );
};

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    matchProfileActions.getMatchCompetitorsRequest({
      matchId: ownProps.matchId,
    }),
    matchProfileActions.getKitSetRequest({ kitSetId: ownProps.kitSetId }),
  ]);

const isSubmittingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    matchProfileActions.updateMatchCompetitorRequest({
      matchId: ownProps.matchId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    isLoading: isLoadingSelector(ownProps)(state),
    isSubmitting: isSubmittingSelector(ownProps)(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerKitSelect);
