import restService from '@core/api/rest-service';
import { MatchesAllocationResponse } from '@core/types';

export const getMatchAllocationList = (
  groupId: number,
  query: URLSearchParams,
) =>
  restService.get<MatchesAllocationResponse>(
    `/v1/groups/${groupId}/matchAllocations`,
    { params: query },
  );
