import { createActions } from 'redux-actions';
import { NAMESPACE } from './constants';

export default createActions(
  'GET_TERMS',
  'GET_TERMS_REQUEST',
  'GET_TERMS_SUCCESS',
  'GET_TERMS_FAILURE',

  'GET_DOCUMENT',
  'GET_DOCUMENT_REQUEST',
  'GET_DOCUMENT_SUCCESS',
  'GET_DOCUMENT_FAILURE',

  'SET_PREFERRED_LANGUAGE',

  'SET_TERMS',

  { prefix: NAMESPACE },
) as unknown as {
  getTerms: UnknownAction;
  getTermsRequest: RequestLifecycleAction;
  getTermsSuccess: RequestLifecycleAction;
  getTermsFailure: RequestLifecycleAction;
  getDocument: UnknownAction;
  getDocumentRequest: RequestLifecycleAction;
  getDocumentSuccess: RequestLifecycleAction;
  getDocumentFailure: RequestLifecycleAction;
  setPreferredLanguage: IdentityActionFunction<string>;
  setTerms: UnknownAction;
};
