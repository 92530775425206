import React from 'react';
import {
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import { isEmpty, prop } from 'ramda';
import FileUploadButton from '@core/components/file-upload-button';
import { makeStyles } from '@mui/styles';

enum IMAGE_UPLOAD_ERRORS {
  TOO_HIGH_ERROR = 'TOO_HIGH_ERROR',
  TOO_WIDE_ERROR = 'TOO_WIDE_ERROR',
  INVALID_MIME_TYPE_ERROR = 'INVALID_MIME_TYPE_ERROR',
}
const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    background: `rgb(253,236,234)`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  infoTypography: {},
  errorList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorItem: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  errorText: {
    margin: 0,
    fontWeight: 'bold',
    color: `rgb(97,26,21)`,
  },
  bulletPoint: {
    fontSize: '0.5rem',
    marginRight: theme.spacing(1),
    color: `rgb(97,26,21)`,
  },
  italic: {
    fontStyle: 'italic',
  },
}));

interface OwnProps {
  handleClose: () => void;
  setSelectedFiles: (file: Array<File>) => void;
  selectedFiles: Array<File>;
  clearErrors: () => void;
  errors: any;
  selectedFileIsTooBig: boolean;
  incorrectFileFormat: boolean;
}

type Props = OwnProps;

const ErrorDialogContent = (props: Props) => {
  const {
    handleClose,
    selectedFiles,
    setSelectedFiles,
    clearErrors,
    errors,
    selectedFileIsTooBig,
    incorrectFileFormat,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const selectedFile = selectedFiles[0];
  const mimeTypeError = errors?.apiErrors?.filter(
    (err: any) => err.code === IMAGE_UPLOAD_ERRORS.INVALID_MIME_TYPE_ERROR,
  );
  const tooWideError = errors?.apiErrors?.filter(
    (err: any) => err.code === IMAGE_UPLOAD_ERRORS.TOO_WIDE_ERROR,
  );
  const tooHeighError = errors?.apiErrors?.filter(
    (err: any) => err.code === IMAGE_UPLOAD_ERRORS.TOO_HIGH_ERROR,
  );
  const generalError =
    isEmpty(mimeTypeError) &&
    isEmpty(tooWideError) &&
    isEmpty(tooHeighError) &&
    !incorrectFileFormat &&
    !selectedFileIsTooBig;

  const showSizeLimitError = selectedFileIsTooBig && !incorrectFileFormat;
  const showIncorrectFormatError =
    !isEmpty(mimeTypeError) && !incorrectFileFormat;
  const showResolutionError =
    (!isEmpty(tooWideError) || !isEmpty(tooHeighError)) && !incorrectFileFormat;

  const selectFileButton = (btnProps: any) => (
    <Button color="primary" variant="contained" {...btnProps}>
      {t('Select another image')}
    </Button>
  );

  const errorTextComponent = (errMessage: string) => (
    <ListItem className={classes.errorItem}>
      <FiberManualRecordIcon classes={{ root: classes.bulletPoint }} />
      <ListItemText
        primaryTypographyProps={{ className: classes.errorText }}
        className={classes.errorText}
        primary={errMessage}
      />
    </ListItem>
  );

  function handleFileReselect(files: Array<File>) {
    setSelectedFiles(files);
    clearErrors();
  }

  function handleModalClose() {
    handleClose();
  }

  return (
    <>
      <DialogTitle>{t('Upload picture')}</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignContent="flex-start"
          alignItems="flex-start"
          className={classes.errorContainer}
        >
          <Box>
            <ErrorOutlineIcon className={classes.errorIcon} />
          </Box>
          <Box className={classes.infoTypography}>
            <List className={classes.errorList}>
              <ListItem className={classes.errorItem}>
                <ListItemText
                  primaryTypographyProps={{ className: classes.errorText }}
                  className={classes.errorText}
                  primary={
                    <Box>
                      <span>{t('Selected file')} </span>
                      <span className={classes.italic}>
                        {prop('name', selectedFile)}{' '}
                      </span>
                      <span>{`${t('could not be uploaded')}:`}</span>
                    </Box>
                  }
                />
              </ListItem>
              {incorrectFileFormat &&
                errorTextComponent(t('Provided file format is not accepted'))}
              {showSizeLimitError &&
                errorTextComponent(
                  t('Provided file size exceeds limits. (Limit - 25 MB)'),
                )}
              {showIncorrectFormatError &&
                errorTextComponent(
                  t('Provided file extension does not match image format.'),
                )}
              {showResolutionError &&
                errorTextComponent(
                  t(
                    'Provided file resolution exceeds limits. (Limit - 5000x5000 px)',
                  ),
                )}
              {generalError && errorTextComponent(t('File import error'))}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleModalClose}>{t('Cancel')}</Button>
          </Box>
          <FileUploadButton
            buttonComponent={selectFileButton}
            files={selectedFiles}
            setSelectedFile={handleFileReselect}
            withSelectedFileNaming={false}
          />
        </Box>
      </DialogActions>
    </>
  );
};

export default ErrorDialogContent;
