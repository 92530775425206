import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';
import {
  PlayerList,
  PlayerListPlayer,
  TeamOfficial,
  TeamOfficialList,
  Paginated,
} from '@core/types';

export const NAMESPACE = 'playerListManagementTab';

type CompetitionCompetitorCompositeId = {
  competitionId: number;
  competitorId: number;
  teamId: number;
};

export const actions = createActions(
  {
    SET_CLUB_PLAYERS: [prop('payload'), pick(['clubId'])],
    SET_ADDED_COMPETITOR_PLAYERS: [prop('payload'), pick(['competitorId'])],
    SET_COMPETITOR_PLAYERS: [prop('payload'), pick(['competitorId'])],
    RESET_CLUB_PLAYERS: [undefined, pick(['clubId'])],
    SET_COMPETITOR_TEAM_OFFICIALS: [prop('payload'), pick(['competitorId'])],
    SET_ADDED_COMPETITOR_TEAM_OFFICIALS: [
      prop('payload'),
      pick(['competitorId']),
    ],
    SET_CLUB_TEAM_OFFICIALS: [prop('payload'), pick(['clubId'])],
    RESET_CLUB_TEAM_OFFICIALS: [undefined, pick(['clubId'])],

    SET_PREVIOUS_COMPETITOR_PLAYER_LIST_TOTAL: [
      prop('payload'),
      pick(['competitorId']),
    ],
    RESET_PREVIOUS_COMPETITOR_PLAYER_LIST_TOTAL: [
      undefined,
      pick(['competitorId']),
    ],
  },
  'SEARCH_CLUB_PLAYERS',
  'SEARCH_CLUB_PLAYERS_REQUEST',
  'SEARCH_CLUB_PLAYERS_SUCCESS',
  'SEARCH_CLUB_PLAYERS_FAILURE',

  'SEARCH_CLUB_TEAM_OFFICIALS',
  'SEARCH_CLUB_TEAM_OFFICIALS_REQUEST',
  'SEARCH_CLUB_TEAM_OFFICIALS_SUCCESS',
  'SEARCH_CLUB_TEAM_OFFICIALS_FAILURE',

  'GET_COMPETITOR_PLAYERS',
  'GET_COMPETITOR_PLAYERS_REQUEST',
  'GET_COMPETITOR_PLAYERS_SUCCESS',
  'GET_COMPETITOR_PLAYERS_FAILURE',

  'GET_COMPETITOR_TEAM_OFFICIALS',
  'GET_COMPETITOR_TEAM_OFFICIALS_REQUEST',
  'GET_COMPETITOR_TEAM_OFFICIALS_SUCCESS',
  'GET_COMPETITOR_TEAM_OFFICIALS_FAILURE',

  'GET_PREVIOUS_COMPETITOR_PLAYER_LIST',
  'GET_PREVIOUS_COMPETITOR_PLAYER_LIST_REQUEST',
  'GET_PREVIOUS_COMPETITOR_PLAYER_LIST_SUCCESS',
  'GET_PREVIOUS_COMPETITOR_PLAYER_LIST_FAILURE',

  'ADD_PLAYERS_AND_TEAM_OFFICIALS_TO_COMPETITOR',
  'ADD_PLAYERS_AND_TEAM_OFFICIALS_TO_COMPETITOR_REQUEST',
  'ADD_PLAYERS_AND_TEAM_OFFICIALS_TO_COMPETITOR_SUCCESS',
  'ADD_PLAYERS_AND_TEAM_OFFICIALS_TO_COMPETITOR_FAILURE',

  'UPDATE_PLAYERS_FOR_COMPETITOR',
  'UPDATE_PLAYERS_FOR_COMPETITOR_REQUEST',
  'UPDATE_PLAYERS_FOR_COMPETITOR_SUCCESS',
  'UPDATE_PLAYERS_FOR_COMPETITOR_FAILURE',

  'ADD_ADDED_COMPETITOR_PLAYERS',

  { prefix: NAMESPACE },
) as unknown as {
  searchClubPlayers: IdentityActionFunction<{
    clubId: number;
    queryParams: URLSearchParams;
  }>;
  searchClubPlayersRequest: RequestLifecycleAction;
  searchClubPlayersSuccess: RequestLifecycleAction;
  searchClubPlayersFailure: RequestLifecycleAction;
  setClubPlayers: IdentityActionMetaFunction<
    {
      payload: { players: PlayerList; list: Omit<Paginated<any>, 'list'> };
      clubId: number;
    },
    { payload: { players: PlayerList; list: Omit<Paginated<any>, 'list'> } },
    { clubId: number }
  >;
  resetClubPlayers: IdentityActionMetaFunction<
    { clubId: number },
    never,
    { clubId: number }
  >;

  searchClubTeamOfficials: IdentityActionFunction<{
    clubId: number;
    queryParams: URLSearchParams;
  }>;
  searchClubTeamOfficialsRequest: RequestLifecycleAction;
  searchClubTeamOfficialsSuccess: RequestLifecycleAction;
  searchClubTeamOfficialsFailure: RequestLifecycleAction;
  setClubTeamOfficials: IdentityActionMetaFunction<
    {
      payload: {
        officials: TeamOfficialList;
        list: Omit<Paginated<any>, 'list'>;
      };
      clubId: number;
    },
    {
      payload: {
        officials: TeamOfficialList;
        list: Omit<Paginated<any>, 'list'>;
      };
    },
    { clubId: number }
  >;
  resetClubTeamOfficials: IdentityActionMetaFunction<
    { clubId: number },
    never,
    { clubId: number }
  >;

  getCompetitorPlayers: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayersRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayersSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayersFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setCompetitorPlayers: IdentityActionMetaFunction<
    { payload: PlayerList; competitorId: number },
    { payload: PlayerList },
    { competitorId: number }
  >;

  getCompetitorTeamOfficials: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getCompetitorTeamOfficialsRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorTeamOfficialsSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorTeamOfficialsFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setCompetitorTeamOfficials: IdentityActionMetaFunction<
    { payload: TeamOfficialList; competitorId: number },
    { payload: TeamOfficialList },
    { competitorId: number }
  >;

  getPreviousCompetitorPlayerList: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getPreviousCompetitorPlayerListRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getPreviousCompetitorPlayerListSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getPreviousCompetitorPlayerListFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setPreviousCompetitorPlayerListTotal: UnknownAction;
  resetPreviousCompetitorPlayerListTotal: IdentityActionMetaFunction<
    { payload?: number; competitorId: number },
    { payload?: number },
    { competitorId: number }
  >;

  addPlayersAndTeamOfficialsToCompetitor: IdentityActionFunction<{
    competitionId: number;
    teamId: number;
    competitorId: number;
    playersData: Array<PlayerListPlayer>;
    teamOfficialsData: Array<{
      teamOfficialId: number;
      teamOfficialRoleId: number;
    }>;
    tabId: string;
  }>;
  addPlayersAndTeamOfficialsToCompetitorRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  addPlayersAndTeamOfficialsToCompetitorSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  addPlayersAndTeamOfficialsToCompetitorFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;

  updatePlayersForCompetitor: IdentityActionFunction<{
    competitionId: number;
    teamId: number;
    competitorId: number;
    players: Array<PlayerListPlayer>;
    teamOfficials: Array<{
      teamOfficialId: number;
      teamOfficialRoleId: number;
    }>;
    tabId: string;
  }>;
  updatePlayersForCompetitorRequest: RequestLifecycleAction;
  updatePlayersForCompetitorSuccess: RequestLifecycleAction;
  updatePlayersForCompetitorFailure: RequestLifecycleAction;

  addAddedCompetitorPlayers: UnknownAction;

  setAddedCompetitorPlayers: IdentityActionMetaFunction<
    { payload: PlayerList; competitorId: number },
    { payload: PlayerList },
    { competitorId: number }
  >;
  setAddedCompetitorTeamOfficials: IdentityActionMetaFunction<
    { payload: Array<TeamOfficial>; competitorId: number },
    { payload: Array<TeamOfficial> },
    { competitorId: number }
  >;
};
