import { pathOr, omit, pipe } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { NAMESPACE as matchesListNamespace } from '@core/store/modules/api/match';
import { State } from '@core/store';

import { NAMESPACE } from '.';

const getMatchesList = (state: State) =>
  pathOr(null, ['api', matchesListNamespace], state);
const getId = (state: State, id: number) => id;

export const getGroupMatches = (id: number) =>
  pathOr(null, [NAMESPACE, 'byGroupId', id]);

export const getMatches = pathOr(null, [NAMESPACE, 'matches']);

/** @depracated match from a match list doesn't have all hal links */
export const getMatchById = createSelector(
  getMatchesList,
  getId,
  (matches, id) => pathOr(null, [id], matches),
);

export const getMatchAttendanceById = (id: number) =>
  pathOr(null, [NAMESPACE, 'byId', id, 'attendance']);

export const getPaginationInfo = (id: number) =>
  pipe(pathOr(null, [NAMESPACE, 'byGroupId', id]), omit(['list']));
