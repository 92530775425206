import restService from '@core/api/rest-service';
import {
  GroupFixtureSettings,
  GenerateGroupFixtureSettings,
} from '@core/types';

export const getGroupFixtureSettings = (groupId: number) =>
  restService.get<GroupFixtureSettings>(
    `/v1/groups/${groupId}/groupFixtureSettings`,
  );

export const postGroupFixtureSettings = (
  groupId: number,
  data: GenerateGroupFixtureSettings,
) =>
  restService.post<GroupFixtureSettings>(
    `/v1/groups/${groupId}/groupFixtureSettings`,
    data,
  );

export const putGroupFixtureSettings = (
  groupId: number,
  data: GenerateGroupFixtureSettings,
) => restService.put(`/v1/groups/${groupId}/groupFixtureSettings`, data);
