import { TFunction } from 'i18next';

import { ConflictType } from '@core/types';

export const getConflictStatus = (t: TFunction, type?: string) => {
  switch (type) {
    case ConflictType.SCHEDULE:
      return t('Sched. conflict');
    case ConflictType.REFEREE_CATEGORY:
      return t('Cat. missmatch');
    default:
      return t(type);
  }
};
