import React from 'react';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { map, find, propEq, prop } from 'ramda';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';

import { TableWithAssign, PersonNameColumn } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  AddedMatchTeamOfficial,
  AddedMatchTeamOfficialList,
  AvailableTeamOfficialList,
  MatchPlayerOrigin,
} from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import {
  getAvailableCompetitorTeamOfficials,
  getAddedMatchTeamOfficials,
} from '@core/store/modules/tabs/match-sheet-management/selectors';
import { State } from '@core/store';

import AvailableTeamOfficialsSearchField from './available-team-officials-search-field';

interface OwnProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  tabId: string;
  teamId: number;
  competitionId: number;
}

interface StateProps {
  availableTeamOfficials: AvailableTeamOfficialList;
  isLoading: boolean;
  addedTeamOfficials: AddedMatchTeamOfficialList;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const AvailableTeamOfficialsTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    matchId,
    matchPlayerOrigin,
    isLoading,
    addedTeamOfficials,
    availableTeamOfficials,
    tabId,
    actions,
    teamId,
    competitionId,
  } = props;

  const sortedAvailableTeamOfficials = availableTeamOfficials
    ? [
        ...availableTeamOfficials.sort((a: any, b: any) =>
          b.teamOfficial.localFamilyName > a.teamOfficial.localFamilyName
            ? 1
            : -1,
        ),
      ]
    : [];

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: any) => (
        <PersonNameColumn
          isDisabled={rowData.isAdded}
          person={rowData.teamOfficial}
        />
      ),
    },
    {
      name: 'role',
      label: t('Role'),
      render: (rowData: any) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {t(rowData?.teamOfficialRole?.title) || '-'}
        </Typography>
      ),
    },
    {
      name: 'added',
      label: '',
      render: (rowData: any) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {rowData.isAdded ? t('added') : ' '}
        </Typography>
      ),
    },
  ];

  const handleAddToList = (id: number) => {
    const teamOfficialToAdd = availableTeamOfficials.find(
      (teamOfficial) => teamOfficial.id === id,
    );

    actions.matchSheetManagement.setAddedTeamOfficials({
      matchId,
      matchPlayerOrigin,
      payload: [...addedTeamOfficials, teamOfficialToAdd],
      tabId,
    });
  };

  const findById = (id: number, list: Array<any>) =>
    find(propEq('id', id))(list);

  const isTeamOfficialAdded = (teamOfficialId: number) =>
    findById(teamOfficialId, addedTeamOfficials);

  const markAddedCompetitorTeamOfficials = (teamOfficials: any) => {
    if (!teamOfficials) {
      return [];
    }

    return map((teamOfficial: AddedMatchTeamOfficial) => {
      if (!addedTeamOfficials) {
        return teamOfficial;
      }

      if (isTeamOfficialAdded(teamOfficial.id)) {
        return { ...teamOfficial, isAdded: true };
      }

      return teamOfficial;
    }, teamOfficials);
  };

  const getTeamOfficialsCount = () =>
    availableTeamOfficials?.reduce(
      (total: number, teamOfficial: { id: number }) =>
        isTeamOfficialAdded(teamOfficial.id) ? total : total + 1,
      0,
    ) || 0;

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Available team officials')} (${getTeamOfficialsCount()})`}
        </Typography>
      </Grid>
      <AvailableTeamOfficialsSearchField
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        teamId={teamId}
        competitionId={competitionId}
      />
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            columns={columns}
            data={markAddedCompetitorTeamOfficials(
              sortedAvailableTeamOfficials,
            )}
            isLoading={isLoading}
            handleAdd={handleAddToList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const isLoadingSelector = createLoadingSelector([
  matchSheetManagementActions.searchCompetitorTeamOfficialsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = prop('matchId', ownProps);
  const matchPlayerOrigin = prop('matchPlayerOrigin', ownProps);

  return {
    availableTeamOfficials: getAvailableCompetitorTeamOfficials(state, {
      matchId,
      matchPlayerOrigin,
    }),
    addedTeamOfficials: getAddedMatchTeamOfficials(state, {
      matchId,
      matchPlayerOrigin,
    }),
    isLoading: isLoadingSelector(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailableTeamOfficialsTable);
