import { ComponentType } from 'react';
import { Drawer, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuDispatchProps, UIState } from '../types';
import { MENU_CLICK_AWAY } from '../tests/test-ids';
import type { MenuProps } from '../types';

type MenuWithState = MenuProps & MenuDispatchProps & UIState;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: 280,
    paddingTop: theme.mixins.toolbar.minHeight,
  },
}));

export const withMenuDrawer =
  <P extends MenuWithState>(WrappedComponent: ComponentType<MenuProps>) =>
  ({ actions, isMenuOpen, ...menuProps }: P) => {
    const classes = useStyles();
    const handleClose = (_: Event) => {
      actions.ui.toggleMenu();
    };

    return (
      <Drawer
        classes={{ paper: classes.paper }}
        data-qa={MENU_CLICK_AWAY}
        open={isMenuOpen}
        onClose={handleClose}
      >
        <WrappedComponent {...menuProps} />
      </Drawer>
    );
  };
