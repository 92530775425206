import { createFilterId } from '@core/helpers';
import { FilteringOperations } from '@core/types';

export const MATCH_COMPETITIONS_FILTER = {
  fieldName: 'matchGroup.competition',
  filteringOperation: FilteringOperations.equal,
};

export const MATCH_COMPETITIONS_FILTER_ID = createFilterId(
  MATCH_COMPETITIONS_FILTER,
);
