import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Box, Card, Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FullScreenSpinner } from '@core/components';
import { getMatchSetType, getInitialErrors } from '@core/helpers';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import { Group } from '@core/types';
import {
  APIErrorsModel,
  CompetitionResponse,
  GroupStandingSettings,
  MatchSets,
  MatchSetSetting,
  StageType,
} from '@volleyball/types';
import * as groupStandingsActions from '@volleyball/store/modules/group-standings/actions';
import * as standingSettingsActions from '@volleyball/store/modules/group-standing-settings/actions';
import { State } from '@volleyball/store';
import { getApiErrors } from '@volleyball/store/modules/tabs/stage-fixture-settings/selectors';
import { getGroupStandingSettingsByGroupId } from '@volleyball/store/modules/group-standing-settings/selectors';
import {
  getDefaultValues,
  FormValues,
  PointsFieldLabels,
} from '@volleyball/business-components/stage-standings-settings-template/helpers/default-values';
import getValidationSchema from '@volleyball/business-components/stage-standings-settings-template/helpers/validation-schema';
import {
  dependantSetsOfWin1,
  dependantSetsOfWin2,
  dependantSetsOfLoss1,
  dependantSetsOfLoss2,
  STANDING_SETTINGS_FIELD_NAMES,
} from '@volleyball/business-components/stage-standings-settings-template/constants';
import { headersArray, rankingsArray } from './constants';
import StageStandingsSummary from './components/stage-standings-summary';
import StageStandingsForm from './components/stage-standings-form';

interface OwnProps {
  stageType: StageType;
  groupId: number;
  tabId: string;
  generateFixtureUrl: string;
  competitionId: number;
  stageId?: number;
  stage: Group;
  competition: CompetitionResponse;
  disabled: boolean;
  callbacks: {
    onSave: () => void;
  };
}

interface StateProps {
  standingSettings: GroupStandingSettings;
  isLoading: boolean;
  initialErrors: APIErrorsModel;
}

interface DispatchProps {
  actions: {
    groupStandings: typeof groupStandingsActions;
    groupStandingSettings: typeof standingSettingsActions;
  };
}

type Props = OwnProps & DispatchProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  removeBottomBorderRadius: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  removeTopBorderRadius: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

const StageStandingsSettings = ({
  tabId,
  actions,
  disabled,
  isLoading,
  initialErrors,
  stage,
  competition,
  standingSettings,
  callbacks,
}: Props) => {
  const { t } = useTranslation();
  const [pointFields, setPointFields] = useState({});
  const [fieldLabels, setFieldLabels] = useState<PointsFieldLabels | undefined>(
    null,
  );
  const classes = useStyles();

  useEffect(() => {
    let fields;
    let labels;
    const matchSetEnums = standingSettings?.matchSetSettings.map(
      (matchSetSetting) => matchSetSetting.matchSetEnum,
    );

    if (matchSetEnums) {
      if (matchSetEnums.length === 2) {
        fields = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: getMatchSetPoints(
            MatchSets.SET_1_0,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetPoints(
            MatchSets.SET_0_1,
          ),
        };

        labels = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: getMatchSetType(
            MatchSets.SET_1_0,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetType(
            MatchSets.SET_0_1,
          ),
        };
      } else if (matchSetEnums.length === 4) {
        fields = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: getMatchSetPoints(
            MatchSets.SET_2_0,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: getMatchSetPoints(
            MatchSets.SET_2_1,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetPoints(
            MatchSets.SET_1_2,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: getMatchSetPoints(
            MatchSets.SET_0_2,
          ),
        };

        labels = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: getMatchSetType(
            MatchSets.SET_2_0,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: getMatchSetType(
            MatchSets.SET_2_1,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetType(
            MatchSets.SET_1_2,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: getMatchSetType(
            MatchSets.SET_0_2,
          ),
        };
      } else if (matchSetEnums.length === 6) {
        fields = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: getMatchSetPoints(
            MatchSets.SET_3_0,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: getMatchSetPoints(
            MatchSets.SET_3_2,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetPoints(
            MatchSets.SET_2_3,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: getMatchSetPoints(
            MatchSets.SET_0_3,
          ),
        };

        labels = {
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: `${getMatchSetType(
            MatchSets.SET_3_0,
          )} ${t('or')} ${getMatchSetType(MatchSets.SET_3_1)}`,
          [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: getMatchSetType(
            MatchSets.SET_3_2,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: getMatchSetType(
            MatchSets.SET_2_3,
          ),
          [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: `${getMatchSetType(
            MatchSets.SET_1_3,
          )} ${t('or')} ${getMatchSetType(MatchSets.SET_0_3)}`,
        };
      }

      setPointFields(fields);
      setFieldLabels(labels);
    }
  }, [standingSettings]);

  useEffect(() => {
    if (stage.id) {
      actions.groupStandingSettings.getGroupStandingSettings(stage.id);
    }

    return () => {
      if (stage.id) {
        actions.groupStandingSettings.clearGroupStandingSettings(stage.id);
      }
    };
  }, [actions, stage]);

  const getPointsValue = (
    data: FormValues,
    matchSetSetting: MatchSetSetting,
  ) => {
    if (dependantSetsOfWin1.includes(matchSetSetting.matchSetEnum)) {
      return data[STANDING_SETTINGS_FIELD_NAMES.matchSetWin1];
    }

    if (dependantSetsOfLoss1.includes(matchSetSetting.matchSetEnum)) {
      return data[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1];
    }

    if (dependantSetsOfWin2.includes(matchSetSetting.matchSetEnum)) {
      return data[STANDING_SETTINGS_FIELD_NAMES.matchSetWin2];
    }

    if (dependantSetsOfLoss2.includes(matchSetSetting.matchSetEnum)) {
      return data[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2];
    }

    return matchSetSetting.points;
  };

  const handleSubmit = (data: FormValues) => {
    const matchSetSettings = standingSettings?.matchSetSettings.map(
      (matchSetSetting) => ({
        ...matchSetSetting,
        points: getPointsValue(data, matchSetSetting),
      }),
    );

    const standingTableHeaders = data.standingTableHeaders
      .filter((val) => val.isSelected === true)
      .map((val) => ({ column: val.id }));

    const standingTableRanks = data.standingTableRanks
      .filter((ranks) => ranks.isSelected === true)
      .map((ranks) => ({
        standingsRank: ranks.id,
      }));

    actions.groupStandingSettings.setGroupStandingSettings({
      tabId,
      groupId: stage.id,
      settings: {
        matchSetSettings,
        standingTableHeaders,
        standingTableRanks,
      },
    });

    const header = data.standingTableHeaders
      .filter((val) => val.isSelected === true)
      .map((val) => ({ name: val.title, label: val.id }));

    actions.groupStandings.setGroupStandingsHeader({
      groupId: stage.id,
      payload: header,
    });

    setTimeout(() => {
      callbacks?.onSave?.();
    }, 0);
  };

  const renderForm = (formikProps: FormikProps<FormValues>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTabStateSetter(formikProps.dirty);

    return (
      <StageStandingsForm
        tabId={tabId}
        fieldLabels={fieldLabels}
        disabled={disabled}
      />
    );
  };

  const getOrderedValues = (sorting: any, items: any, sortBy: string) => {
    const sortedResult: any = [];
    sorting?.forEach((key: any) => {
      let found = false;
      items = items.filter((value: any) => value.isSelected);
      items = items.filter((item: any) => {
        if (!found && item.id === key[sortBy]) {
          sortedResult.push(item);
          found = true;

          return false;
        } else return true;
      });
    });

    return sortedResult;
  };

  const getMatchSetPoints = (matchSet: MatchSets) => {
    const matchSetSettings = standingSettings?.matchSetSettings.find(
      (matchSetSetting) => matchSetSetting.matchSetEnum === matchSet,
    );

    return matchSetSettings.points;
  };

  const createInitialValues = (): FormValues => {
    const headers = headersArray(t).map((header) => ({
      ...header,
      title: header.title,
      isSelected: !!standingSettings?.standingTableHeaders.find(
        (element) => element.column === header.id,
      ),
    }));

    const ranks = rankingsArray(t).map((ranking) => {
      const index = standingSettings?.standingTableRanks.findIndex(
        (element) => element.standingsRank === ranking.id,
      );

      return {
        ...ranking,
        isSelected: index !== -1,
      };
    });

    return {
      ...getDefaultValues(),
      standingTableHeaders: [
        ...getOrderedValues(
          standingSettings?.standingTableHeaders,
          headers,
          'column',
        ),
        ...headers.filter((value) => !value.isSelected),
      ],
      standingTableRanks: [
        ...getOrderedValues(
          standingSettings?.standingTableRanks,
          ranks,
          'standingsRank',
        ),
        ...ranks.filter((value) => !value.isSelected),
      ],
      ...pointFields,
    };
  };

  return (
    <Grid>
      {isLoading || !standingSettings || !fieldLabels ? (
        <FullScreenSpinner />
      ) : (
        <>
          <Card className={classes.removeBottomBorderRadius}>
            <Box m={3}>
              <Typography variant="h6">{t('Standings settings')}</Typography>
              <Box mt={3}>
                <StageStandingsSummary
                  stage={stage}
                  competition={competition}
                />
              </Box>
            </Box>
          </Card>
          <Formik
            onSubmit={handleSubmit}
            initialValues={createInitialValues()}
            validationSchema={getValidationSchema(
              t,
              Object.keys(pointFields).length > 2,
            )}
            enableReinitialize
            initialErrors={getInitialErrors(initialErrors)}
            validateOnChange={false}
          >
            {renderForm}
          </Formik>
        </>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    groupStandings: bindActionCreators(groupStandingsActions, dispatch),
    groupStandingSettings: bindActionCreators(
      standingSettingsActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const groupId = ownProps?.stage?.id;

  return {
    isLoading: false,
    standingSettings: getGroupStandingSettingsByGroupId(state, { groupId }),
    initialErrors: getApiErrors(state, { groupId }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageStandingsSettings);
