import React, { useState, useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import type { OnChangeValue, InputActionMeta, ActionMeta } from 'react-select';
import { none, isNil } from 'ramda';
import { AutocompleteOption, SelectOption } from '@core/types';
import {
  Autocomplete,
  AutocompleteProps,
  ErrorMessage,
} from '@core/components';
import { getSelectedValue, getValueFromOption } from './helpers';

const defaultFilterOption = () => true; // bypass filtering on a JavaScript side

interface OwnProps {
  initialInputValue?: SelectOption;
  onChange?: (
    option: OnChangeValue<AutocompleteOption, boolean> | string | number,
  ) => void;
  onInputChange?: (val: string) => void;
  useOptionAsValue?: boolean;
}

type Props = OwnProps &
  Omit<AutocompleteProps, keyof OwnProps | 'inputValue' | 'value' | ''>;

const AutocompleteField = (props: Props) => {
  const {
    defaultInputValue,
    filterOption,
    initialInputValue,
    name,
    onBlur: onBlurFromParent,
    onChange,
    onFocus: onFocusFromParent,
    onInputChange,
    options,
    pagination,
    textFieldProps,
    useOptionAsValue,
    withTooltipMargin,
    defaultValue,
    ...autoCompleteProps
  } = props;

  const [inputValue, setInputValue] = useState<string>();

  function renderField(fieldProps: FieldProps) {
    const {
      field: { value, onBlur: onBlurFromFormik },
      form: { setFieldValue },
      meta,
    } = fieldProps;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (inputValue) {
        setFieldValue(name, inputValue);
      }
      if (initialInputValue) {
        onInputChange && onInputChange(initialInputValue?.label);
        setInputValue(initialInputValue?.label as string);

        const selectedValue = useOptionAsValue
          ? initialInputValue
          : getValueFromOption(initialInputValue as SelectOption);
        setFieldValue(name, selectedValue);
      }
    }, [initialInputValue]);

    function handleChange(
      option: OnChangeValue<AutocompleteOption, boolean>,
      action: ActionMeta<AutocompleteOption>,
    ) {
      const { action: actionType } = action;
      const selectedValue = useOptionAsValue
        ? option
        : getValueFromOption(option as SelectOption);
      onChange && onChange(selectedValue);
      actionType === 'select-option' && setFieldValue(name, selectedValue);
      actionType === 'clear' && setFieldValue(name, null);
    }

    function handleBlur(event: React.FocusEvent<HTMLElement>) {
      onBlurFromParent && onBlurFromParent(event);
      onBlurFromFormik(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLElement>) {
      !inputValue && onFocusFromParent && onFocusFromParent(event);
    }

    /**
     * "input-change" - value is what is typed to input field,
     *
     * "set-value" - value is zero length string; fired on option selection,
     *
     * "input-blur" - value is zero length string; fired on menu close by option selection or unfocusing,
     *
     * "menu-close" - value is zero length string; fired on unfocusing
     */
    function handleInputChange(newValue: string, actionMeta: InputActionMeta) {
      const { action: actionType } = actionMeta;

      if (newValue === inputValue) return;

      setInputValue(newValue);

      if (actionType === 'input-change') {
        onInputChange && onInputChange(newValue);
        setFieldValue(name, null);
      }

      actionType === 'menu-close' && onInputChange && onInputChange(newValue);
    }

    const shouldShowFieldError = none(isNil, [
      meta.error,
      meta.touched || null,
    ]);

    return (
      <>
        <Autocomplete
          name={name}
          options={options}
          withTooltipMargin={withTooltipMargin}
          textFieldProps={{ ...textFieldProps, error: shouldShowFieldError }}
          inputValue={inputValue}
          defaultInputValue={defaultInputValue}
          defaultValue={defaultValue}
          onInputChange={handleInputChange}
          pagination={pagination}
          onChange={handleChange}
          value={getSelectedValue({ useOptionAsValue, value, options })}
          onBlur={handleBlur}
          onFocus={handleFocus}
          filterOption={filterOption ?? defaultFilterOption}
          {...autoCompleteProps}
        />
        <ErrorMessage name={name} />
      </>
    );
  }

  return <Field name={name}>{renderField}</Field>;
};

export default AutocompleteField;
