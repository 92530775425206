import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';

import { actions } from '.';

type DefaultState = any;

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setDisciplinaryInfringementsList.toString()]: (
      state,
      { payload },
    ) => {
      return {
        ...state,
        ...convertArrayToObject(payload),
      };
    },
  },
  defaultState,
);

export default reducer;
