import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TimePickerField } from '@core/components';

import { getMatchFormFieldNames } from './helpers';

interface Props {
  responsive?: boolean;
}

export const MatchDataFormFieldsMatchTime: React.FC<Props> = (props) => {
  const { responsive } = props;
  const { t } = useTranslation();
  const formFieldNames = getMatchFormFieldNames();

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={12} md={responsive && 6}>
        <TimePickerField
          name={formFieldNames.actualStartTime}
          data-qa={formFieldNames.actualStartTime}
          label={t('Start time')}
          required
        />
      </Grid>
      <Grid item xs={12} md={responsive && 6}>
        <TimePickerField
          name={formFieldNames.actualEndTime}
          data-qa={formFieldNames.actualEndTime}
          label={t('End time')}
          required
        />
      </Grid>
    </Grid>
  );
};
