import { take, call, put, all } from 'redux-saga/effects';
import * as usersApi from '@core/api/users';

import actions from './actions';

function* refreshUserSessionFlow() {
  while (true) {
    yield take(actions.refreshUserSession);
    yield put(actions.refreshUserSessionRequest());
    const { error } = yield call(usersApi.refreshSession);

    if (!error) {
      yield put(actions.refreshUserSessionSuccess());
    } else {
      yield put(actions.refreshUserSessionFailure());
    }
  }
}

export default function* sagas() {
  yield all([refreshUserSessionFlow()]);
}
