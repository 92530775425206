import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  ExpansionPanelComponent,
  FullScreenSpinner,
  NumberField,
} from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { State } from '@volleyball/store';
import TableWithActions from '@volleyball/components/table-with-actions';
import { ModalTypes } from '@volleyball/types';
import { STANDING_SETTINGS_FIELD_NAMES } from '@volleyball/business-components/stage-standings-settings-template/constants';
import {
  FormValues,
  PointsFieldLabels,
} from '@volleyball/business-components/stage-standings-settings-template/helpers/default-values';
import {
  GET_GROUP_STANDING_SETTINGS_REQUEST,
  SET_GROUP_STANDING_SETTINGS_REQUEST,
} from '@volleyball/store/modules/group-standing-settings/constants';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';

import StageStandingsSettingsActions from './stage-standings-settings-actions';

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  tabId: string;
  fieldLabels: PointsFieldLabels;
  disabled: boolean;
}

interface StateProps {
  isLoading: boolean;
  isSubmitting: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  removeTopBorderRadius: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
  inputSpacing: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      '& > *:first-child': {
        visibility: 'collapse',
      },
    },
  },
  horizontalInputSpacing: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
    },
  },
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const StageStandingsForm = (props: Props) => {
  const { tabId, fieldLabels, actions, disabled, isLoading, isSubmitting } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    values,
    errors,
  }: {
    values: FormValues;
    errors: Partial<Record<STANDING_SETTINGS_FIELD_NAMES, any>>;
  } = useFormikContext();

  const refWins = useRef<HTMLInputElement>(null);
  const refLosses = useRef<HTMLInputElement>(null);

  const handlePreview = () =>
    actions.globalModal.openModal({
      type: ModalTypes.STANDINGS_SETTINGS_TABLE_PREVIEW_MODAL,
      params: {
        headers: values.standingTableHeaders,
      },
    });

  if (isLoading) {
    return (
      <Card className={classes.removeTopBorderRadius}>
        <Box>
          <FullScreenSpinner />
        </Box>
      </Card>
    );
  }

  const summaryHeaders = (
    <Box display="flex" flex="1" width="100%" flexDirection="column" my={1}>
      {values?.[STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders]
        ?.filter((item) => item?.isSelected)
        ?.map(({ title, id }) => <Grid key={id}>{title}</Grid>)}
    </Box>
  );

  const summaryRanks = (
    <Box display="flex" flex="1" width="100%" flexDirection="column" my={1}>
      {values?.[STANDING_SETTINGS_FIELD_NAMES.standingTableRanks]
        ?.filter((item) => item?.isSelected)
        ?.map(({ title, id }) => <Grid key={id}>{title}</Grid>)}
    </Box>
  );

  const getSummaryWinPoints = () =>
    `${values?.[STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]} ${t('pts')} (${
      fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]
    })${
      fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]
        ? ' • ' +
          values?.[STANDING_SETTINGS_FIELD_NAMES.matchSetWin2] +
          ' ' +
          t('pts') +
          ' (' +
          fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetWin2] +
          ')'
        : ''
    }`;

  const getSummaryLossPoints = () =>
    `${values?.[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]} ${t('pts')} (${
      fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]
    })${
      fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]
        ? ' • ' +
          values?.[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2] +
          ' ' +
          t('pts') +
          ' (' +
          fieldLabels[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2] +
          ')'
        : ''
    }`;

  return (
    <Form noValidate className={classes.root}>
      <Card className={classes.removeTopBorderRadius}>
        <Box m={3} mb={5}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Box mb={5}>
              <Typography variant="caption" color="textSecondary">
                {t('* Indicates a required field')}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={2}>
              <Typography variant="caption" className={classes.uppercase}>
                {t('Points')}
              </Typography>
            </Grid>
            <Grid container item md={8}>
              <Grid item xs={12} md={12}>
                <Box mb={1}>
                  <Paper>
                    <ExpansionPanelComponent
                      title={t('Points per win')}
                      tooltip={t('Enter points per win')}
                      value={getSummaryWinPoints()}
                      error={
                        errors?.[STANDING_SETTINGS_FIELD_NAMES.matchSetWin1] ||
                        errors?.[STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]
                      }
                      inputRef={refWins}
                    >
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <NumberField
                            name={STANDING_SETTINGS_FIELD_NAMES.matchSetWin1}
                            data-qa={STANDING_SETTINGS_FIELD_NAMES.matchSetWin1}
                            label={`${t('Points')} (Win ${
                              fieldLabels[
                                STANDING_SETTINGS_FIELD_NAMES.matchSetWin1
                              ]
                            })`}
                            min={1}
                            required
                            disabled={disabled}
                          />
                        </Grid>
                        {fieldLabels[
                          STANDING_SETTINGS_FIELD_NAMES.matchSetWin2
                        ] && (
                          <Grid item xs={12} md={6}>
                            <NumberField
                              name={STANDING_SETTINGS_FIELD_NAMES.matchSetWin2}
                              data-qa={
                                STANDING_SETTINGS_FIELD_NAMES.matchSetWin2
                              }
                              label={`${t('Points')} (Win ${
                                fieldLabels[
                                  STANDING_SETTINGS_FIELD_NAMES.matchSetWin2
                                ]
                              })`}
                              min={1}
                              required
                              disabled={disabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </ExpansionPanelComponent>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mb={1}>
                  <Paper>
                    <ExpansionPanelComponent
                      title={t('Points per loss')}
                      tooltip={t('Enter points per loss')}
                      value={getSummaryLossPoints()}
                      error={
                        errors?.[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1] ||
                        errors?.[STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]
                      }
                      inputRef={refLosses}
                    >
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <NumberField
                            name={STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1}
                            data-qa={
                              STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1
                            }
                            label={`${t('Points')} (Loss ${
                              fieldLabels[
                                STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1
                              ]
                            })`}
                            min={-3}
                            required
                            disabled={disabled}
                          />
                        </Grid>
                        {fieldLabels[
                          STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2
                        ] && (
                          <Grid item xs={12} md={6}>
                            <NumberField
                              name={STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2}
                              data-qa={
                                STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2
                              }
                              label={`${t('Points')} (Loss ${
                                fieldLabels[
                                  STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2
                                ]
                              })`}
                              min={-3}
                              required
                              disabled={disabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </ExpansionPanelComponent>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box my={5}>
            <Divider variant="fullWidth" />
          </Box>
          <Grid container item xs={12}>
            <Grid container item xs={12} md={12}>
              <Grid item xs={12} md={2}>
                <Typography variant="caption" className={classes.uppercase}>
                  {t('Table')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid item xs={12} md={12}>
                  <Paper>
                    <ExpansionPanelComponent
                      title={t('Columns to display')}
                      tooltip={'Configure and preview standing table columns'}
                      value={summaryHeaders}
                    >
                      <Box display="flex" flex="1" flexDirection="column">
                        <Box justifyContent="flex-end" display="flex">
                          <Box mb={2}>
                            <Button disabled={!values} onClick={handlePreview}>
                              {t('TABLE PREVIEW')}
                            </Button>
                          </Box>
                        </Box>
                        <TableWithActions
                          titleComponent
                          fieldName={
                            STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders
                          }
                          isLoading={isLoading}
                          disabled={disabled}
                        />
                      </Box>
                    </ExpansionPanelComponent>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box my={5}>
            <Divider variant="fullWidth" />
          </Box>
          <Grid container item xs={12}>
            <Grid container item xs={12} md={12}>
              <Grid item xs={12} md={2}>
                <Typography variant="caption" className={classes.uppercase}>
                  {t('Ranking')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid item xs={12} md={12}>
                  <Paper>
                    <ExpansionPanelComponent
                      title={t('Position ranking options')}
                      value={summaryRanks}
                      tooltip={'Configure ranking options and sorting rules'}
                    >
                      <TableWithActions
                        titleComponent
                        fieldName={
                          STANDING_SETTINGS_FIELD_NAMES.standingTableRanks
                        }
                        isLoading={isLoading}
                        disabled={disabled}
                      />
                    </ExpansionPanelComponent>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <StageStandingsSettingsActions
        tabId={tabId}
        disabled={disabled}
        isSubmitting={isSubmitting}
      />
    </Form>
  );
};

const isLoadingSelector = createLoadingSelector([
  GET_GROUP_STANDING_SETTINGS_REQUEST,
]);

const isSubmittingSelector = createLoadingSelector([
  SET_GROUP_STANDING_SETTINGS_REQUEST,
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => {
  return {
    isLoading: isLoadingSelector(state),
    isSubmitting: isSubmittingSelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StageStandingsForm);
