import i18n from 'i18next';
import { VenueFilterTab } from '@core/components';
import { VENUES_FILTER_ID } from '@core/components/venue-filter-tab/constants';
import { FilterTabsList, Group, StageType } from '@core/types';
import {
  MATCH_DATE_FROM_FILTER_ID,
  MATCH_DATE_TO_FILTER_ID,
  MATCH_DAY_TO_FILTER_ID,
  MATCH_DAY_FROM_FILTER_ID,
  MATCH_STATUS_FILTER_ID,
} from '@core/components/match-filter-tab/constants';
import MatchFilterTab from '@core/components/match-filter-tab';
import { TEAMS_FILTER_ID } from '@core/components/teams-filter-tab/constants';
import { GroupTeamsFilterTab } from '@core/components/teams-filter-tab';
import RoundFilterTab from '../components/round-filter-tab';
import { STAGE_ROUND_FILTER_ID } from '../constants';

type Props = {
  competitionId: number;
  stage: Group;
};

export const getCompetitionMatchFilterTabsList = ({
  competitionId,
  stage,
}: Props): FilterTabsList => [
  {
    value: 'matchFilter',
    filterIds: [
      MATCH_DATE_FROM_FILTER_ID,
      MATCH_DATE_TO_FILTER_ID,
      MATCH_DAY_TO_FILTER_ID,
      MATCH_DAY_FROM_FILTER_ID,
      MATCH_STATUS_FILTER_ID,
    ],
    component: MatchFilterTab,
    componentProps: { stageType: stage.stageType },
    label: i18n.t('Match'),
  },
  {
    value: 'teamsFilter',
    component: GroupTeamsFilterTab,
    componentProps: { groupId: stage.id },
    filterIds: [TEAMS_FILTER_ID],
    label: i18n.t('Teams'),
    initialQueryParams: {
      'filter[candidatesForCompetition]': competitionId,
    },
  },
  {
    value: 'venuesFilter',
    component: VenueFilterTab,
    filterIds: [VENUES_FILTER_ID],
    label: i18n.t('Venues'),
  },
  {
    value: 'roundFilter',
    component: RoundFilterTab,
    componentProps: { competitionId, stageId: stage.id },
    filterIds: [STAGE_ROUND_FILTER_ID],
    label: i18n.t(
      stage.stageType === StageType.ROUND_ROBIN ? 'Pools' : 'Rounds',
    ),
    hidden: !stage.childGroups.length,
  },
];
