// @ts-nocheck
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Box,
  useTheme,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Grid,
} from '@mui/material';
import { EditTwoTone } from '@mui/icons-material';

import { ColumnWithSubValue } from '@core/components';
import { Club } from '@core/icons';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import {
  Group,
  Standing,
  StandingItem,
  StandingsHeader,
  StandingsTableColumn,
} from '@volleyball/types';
import * as groupStandingsActions from '@volleyball/store/modules/group-standings/actions';
import { getMainHeaderColumn, getAlwaysVisibleHeaderColumns } from '../helpers';
import EditStandingPointsModal from '../components/edit-standing-points-modal';

interface DispatchProps {
  actions: {
    groupStandings: typeof groupStandingsActions;
    tabs: typeof tabActions;
  };
}

interface OwnProps {
  tableIndex: number;
  stage?: Group;
  groupName?: string;
  headers: Array<StandingsHeader>;
  standings: Standing;
  preview?: boolean;
}

type Props = OwnProps & DispatchProps;

const StandingsTable = ({
  actions,
  tableIndex,
  stage,
  headers,
  standings,
  groupName,
  preview,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [standingPointsModalOpen, setStandingPointsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(undefined);

  const getCanAdjustSingleStanding = (standingItem: StandingItem): boolean =>
    !!standingItem._links?.adjustPoints;
  const canAdjustSomeStandings =
    stage?._links?.groupStandingEdit &&
    standings?.standingItems.some(getCanAdjustSingleStanding);
  const includeActionColumn =
    !preview && canAdjustSomeStandings && standings?.standingItems?.length > 0;

  const handleEditClick = (standing: StandingItem) => () => {
    setRowData(standing);
    setStandingPointsModalOpen(true);
  };

  const handleStandingPointsChange = (
    values: { points: number; changeReason: string },
    data: StandingItem,
  ) =>
    actions.groupStandings.updateGroupStandingsCompetitorPointAdjustment({
      stageId: stage.id,
      groupId: standings.groupId,
      competitorId: data?.competitor?.id,
      data: {
        pointAdjustment: values?.points,
        comment: values?.changeReason,
      },
    });

  const mainHeaderColumns = (): Array<StandingsTableColumn> =>
    [
      getAlwaysVisibleHeaderColumns(t),
      headers.map((header) => getMainHeaderColumn(t, header)),
      includeActionColumn && getMainHeaderColumn(t, { label: 'actions' }),
    ]
      .filter(Boolean)
      .flat(2)
      .filter(Boolean);

  const getChildHeaderColumns = (): Array<StandingsTableColumn> =>
    [
      getAlwaysVisibleHeaderColumns(t).childColumns,
      headers.map((header) => getMainHeaderColumn(t, header)?.childColumns),
      includeActionColumn &&
        getMainHeaderColumn(t, { label: 'actions' }).childColumns,
    ]
      .filter(Boolean)
      .flat(2)
      .filter(Boolean);

  const childHeaderColumns = getChildHeaderColumns();

  const getPropertyValue = (standing: StandingItem, key: string) => {
    const value =
      standing[key as keyof StandingItem] ||
      standing?.setsBreakdown[key as unknown as number] ||
      0;

    if (key === 'setsRatio' && typeof value == 'number' && value !== 0) {
      return value.toFixed(3);
    }

    return value;
  };

  const getTableRow = (standing: StandingItem, rowIndex: number) => {
    const cells = childHeaderColumns?.map((column, index) => (
      <TableCell key={index} align={column.align || 'center'}>
        {column.key === 'actions' && getCanAdjustSingleStanding(standing) && (
          <IconButton
            color="primary"
            onClick={handleEditClick(standing)}
            title={t('Edit')}
            data-qa="edit-button"
          >
            <EditTwoTone />
          </IconButton>
        )}
        {column.key === 'competitor' && (
          <ColumnWithSubValue
            value={standing?.competitor?.title}
            subValue={standing?.competitor?.internationalTitle}
            logo={{
              url:
                standing?.competitor?.logoFileLink ||
                standing?.competitor?.clubLogoFileLink,
              defaultIcon: <Club />,
            }}
          />
        )}
        {column.key === 'points' ? (
          standing?.pointAdjustments?.length ? (
            <Tooltip title={t('Adjusted')} arrow>
              <Box
                bgcolor="#fff4e5"
                borderRadius={32}
                height={32}
                width={32}
                lineHeight="32px"
                margin="auto"
              >
                {getPropertyValue(standing, column.key)}
              </Box>
            </Tooltip>
          ) : (
            getPropertyValue(standing, column.key)
          )
        ) : (
          ''
        )}
        {!['actions', 'competitor', 'points'].includes(String(column.key)) &&
          getPropertyValue(standing, column.key)}
      </TableCell>
    ));

    return (
      <TableRow
        key={rowIndex}
        sx={{ '&:last-child > td': { borderBottom: '0' } }}
      >
        {cells}
      </TableRow>
    );
  };

  return (
    <Grid>
      <TableContainer>
        <Table>
          <TableHead>
            {groupName && (
              <TableRow>
                <TableCell
                  colSpan={childHeaderColumns?.length}
                  sx={{
                    borderTop:
                      tableIndex === 0
                        ? `1px solid ${theme.palette.grey[300]}`
                        : 'none',
                    padding: theme.spacing(1),
                  }}
                >
                  {groupName}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              {mainHeaderColumns()?.map((column, index) => (
                <TableCell
                  key={index}
                  colSpan={column.colSpan}
                  align="center"
                  sx={{
                    padding: theme.spacing(1),
                    textTransform: 'uppercase',
                    borderLeft: `1px solid ${theme.palette.grey[300]}`,
                    '&:first-of-type': {
                      borderLeft: 'none',
                    },
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {childHeaderColumns?.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    textAlign: column.align || 'center',
                    whiteSpace: 'nowrap',
                    width: column.width || 'auto',
                    borderLeft: column.noBorder
                      ? 'none'
                      : `1px solid ${theme.palette.grey[300]}`,
                    '&:first-of-type': {
                      borderLeft: 'none',
                    },
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {standings?.standingItems?.length > 0 ? (
              standings.standingItems.map(getTableRow)
            ) : (
              <TableRow>
                <TableCell colSpan={childHeaderColumns.length} align="center">
                  {t('No records to display')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {standingPointsModalOpen && (
        <EditStandingPointsModal
          isOpen={standingPointsModalOpen}
          handleClose={() => setStandingPointsModalOpen(false)}
          rowData={rowData}
          handleSubmit={handleStandingPointsChange}
        />
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    groupStandings: bindActionCreators(groupStandingsActions, dispatch),
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(StandingsTable);
