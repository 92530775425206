import { all, call, put, take, throttle } from 'redux-saga/effects';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import {
  getCompetition,
  updateCompetition,
} from '@core/pages/competitions/store/api/sagas';
import {
  createCompetitionContact,
  getCompetitionContacts,
  updateCompetitionContact,
  deleteCompetitionContact,
} from '@core/store/modules/api/competition-contacts/sagas';
import { getCompetitionOrganisers } from '@core/store/modules/competition-organisers/sagas';
import { getCompetitionManagers } from '@core/store/modules/competition-managers/sagas';

import { actions } from '.';

export function* getCompetitionOrganisersFlow(action: any) {
  const { payload } = action;
  const { query, competitionId } = payload;

  yield put(actions.getCompetitionOrganisersRequest());

  const { error, response } = yield call(getCompetitionOrganisers, query);

  if (!error) {
    yield put(actions.getCompetitionOrganisersSuccess());
    const { competitionOrganisers } = response._embedded;

    yield put(
      actions.setCompetitionOrganisers({
        payload: competitionOrganisers,
        competitionId,
      }),
    );
  } else {
    yield put(actions.getCompetitionOrganisersFailure());
  }
}

export function* getCompetitionManagersFlow(action: any) {
  const { payload } = action;
  const { query, competitionId } = payload;

  yield put(actions.getCompetitionManagersRequest());

  const { error, response } = yield call(getCompetitionManagers, query);

  if (!error) {
    yield put(actions.getCompetitionManagersSuccess());
    const { users: competitionManagers } = response._embedded;

    yield put(
      actions.setCompetitionManagers({
        payload: competitionManagers,
        competitionId,
      }),
    );
  } else {
    yield put(actions.getCompetitionManagersFailure());
  }
}

export function* getCompetitionFlow() {
  while (true) {
    const { payload: competitionId } = yield take(actions.getCompetition);

    yield put(actions.getCompetitionRequest());

    const { error, response } = yield call(getCompetition, competitionId);

    if (!error) {
      yield put(actions.getCompetitionSuccess());
      yield put(actions.setCompetition({ payload: response, competitionId }));
    } else {
      yield put(actions.getCompetitionFailure());
    }
  }
}

export function* updateCompetitionFlow() {
  while (true) {
    const { payload } = yield take(actions.updateCompetition);
    const { competitionId, data, tabId } = payload;

    yield put(actions.updateCompetitionRequest());
    const { error } = yield call(updateCompetition, competitionId, data);

    if (!error) {
      yield put(actions.updateCompetitionSuccess());
      yield put(tabActions.removeTab({ tabId }));
    } else {
      yield put(actions.updateCompetitionFailure());
    }
  }
}

function* createCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.createCompetitionContact);

    yield put(actions.createCompetitionContactRequest());
    const { error } = yield call(
      createCompetitionContact,
      payload.competitionId,
      payload.data,
    );

    if (!error) {
      yield put(actions.createCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.createCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* updateCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.updateCompetitionContact);

    yield put(actions.updateCompetitionContactRequest());
    const { error } = yield call(
      updateCompetitionContact,
      payload.competitionId,
      payload.contactId,
      payload.data,
    );

    if (!error) {
      yield put(actions.updateCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.updateCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* deleteCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteCompetitionContact);

    yield put(actions.deleteCompetitionContactRequest());
    const { error } = yield call(
      deleteCompetitionContact,
      payload.competitionId,
      payload.contactId,
    );

    if (!error) {
      yield put(actions.deleteCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.deleteCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* getCompetitionContactsFlow() {
  while (true) {
    const { payload } = yield take(actions.getCompetitionContacts);

    yield put(actions.getCompetitionContactsRequest());
    const { error, response } = yield call(getCompetitionContacts, payload);

    if (!error) {
      yield put(actions.getCompetitionContactsSuccess());
      yield put(
        actions.setCompetitionContacts({
          payload: response._embedded.competitionContacts,
          competitionId: payload,
        }),
      );
    } else {
      yield put(actions.getCompetitionContactsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* watchGetCompetitionOrganisers() {
  yield throttle(
    500,
    actions.getCompetitionOrganisers,
    getCompetitionOrganisersFlow,
  );
}

export function* watchGetCompetitionManagers() {
  yield throttle(
    500,
    actions.getCompetitionManagers,
    getCompetitionManagersFlow,
  );
}

export default function* saga() {
  yield all([
    watchGetCompetitionOrganisers(),
    watchGetCompetitionManagers(),
    getCompetitionFlow(),
    updateCompetitionFlow(),
    createCompetitionContactFlow(),
    getCompetitionContactsFlow(),
    updateCompetitionContactFlow(),
    deleteCompetitionContactFlow(),
  ]);
}
