import { handleActions } from 'redux-actions';
import { assoc, assocPath, dissocPath } from 'ramda';

import { ExpansionPanelGroupsStatesById } from '@core/types';

import {
  SET_EXPANSION_PANEL_GROUPS,
  ADD_EXPANSION_PANEL_GROUP,
  REMOVE_EXPANSION_PANEL_GROUP,
} from './constants';

export interface DefaultState {
  allGroupsStatesById: ExpansionPanelGroupsStatesById;
}

export const defaultState: DefaultState = {
  allGroupsStatesById: {},
};

const reducer = handleActions<any>(
  {
    [SET_EXPANSION_PANEL_GROUPS]: (state, { payload }) =>
      assoc('allGroupsStatesById', payload, state),
    [ADD_EXPANSION_PANEL_GROUP]: (state, { payload }) =>
      assocPath(
        ['allGroupsStatesById', payload.groupId],
        payload.panelsStates,
        state,
      ),
    [REMOVE_EXPANSION_PANEL_GROUP]: (state, { payload }) =>
      dissocPath(['allGroupsStatesById', payload], state),
  },
  defaultState,
);

export default reducer;
