import {
  GroupOutlined,
  SettingsOutlined,
  ToggleOnOutlined,
  Sports,
  SportsVolleyball,
} from '@mui/icons-material';
import {
  Venue,
  Club,
  Member,
  Competition,
  National,
  Dashboard,
} from '@core/icons';
import { createURL } from '@core/helpers';
import { MenuItemProps } from '@core/types';
import paths, { accessLinks } from '@volleyball/routes/paths';

export const getMainMenuMapping = (): Array<MenuItemProps> => [
  {
    title: 'Dashboard',
    path: createURL(paths.home),
    icon: Dashboard,
    group: null,
    requiredAccess: [accessLinks.home, accessLinks.clubHome],
  },
  {
    title: 'Clubs',
    path: createURL(paths.clubs),
    icon: Club,
    group: 'Clubs & People',
    requiredAccess: [accessLinks.clubs],
  },
  {
    title: 'National teams',
    path: createURL(paths.nationalTeams),
    icon: National,
    group: 'Clubs & People',
    requiredAccess: [accessLinks.nationalClubs],
  },
  {
    title: 'Club',
    path: createURL(paths.club),
    icon: Club,
    group: 'Clubs & People',
    requiredAccess: [accessLinks.club],
  },
  {
    title: 'Persons',
    path: createURL(paths.persons),
    icon: Member,
    group: 'Clubs & People',
    requiredAccess: [accessLinks.persons],
  },
  {
    title: 'Competitions',
    icon: Competition,
    path: createURL(paths.competitions),
    group: 'Competitions',
    requiredAccess: [
      accessLinks.competitions,
      accessLinks.clubAdminCompetitions,
    ],
  },
  {
    title: 'Venues',
    icon: Venue,
    path: createURL(paths.venues),
    group: 'Competitions',
    requiredAccess: [accessLinks.venues],
  },
  {
    title: 'Matches',
    icon: SportsVolleyball,
    path: createURL(paths.matches),
    group: 'Competitions',
    requiredAccess: [accessLinks.matches, accessLinks.clubAdminMatches],
  },
  {
    title: 'Appointments',
    icon: Sports,
    path: createURL(paths.appointments),
    group: 'Competitions',
    requiredAccess: [accessLinks.appointments],
  },
  {
    title: 'Age groups',
    icon: SettingsOutlined,
    path: createURL(paths.ageGroups),
    group: 'Setup',
    requiredAccess: [accessLinks.ageGroups],
  },
  {
    title: 'Seasons',
    icon: SettingsOutlined,
    path: createURL(paths.seasons),
    group: 'Setup',
    requiredAccess: [accessLinks.seasons],
  },
  {
    title: 'Competition levels',
    icon: SettingsOutlined,
    path: createURL(paths.competitionLevels),
    group: 'Setup',
    requiredAccess: [accessLinks.competitionLevels],
  },
  {
    title: 'Referee Groups',
    icon: SettingsOutlined,
    path: createURL(paths.refereeGroups),
    group: 'Setup',
    requiredAccess: [accessLinks.refereeGroups],
  },
  {
    title: 'Users',
    path: createURL(paths.users),
    icon: GroupOutlined,
    group: 'Admin',
    requiredAccess: [accessLinks.users],
  },
  {
    title: 'Feature Toggle',
    icon: ToggleOnOutlined,
    path: createURL(paths.featureToggle),
    group: 'Admin',
    requiredAccess: [accessLinks.users],
  },
];
