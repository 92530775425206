export const NAMESPACE = 'competitionLevels';

export const SET_LEVEL_BEING_MODIFIED = `${NAMESPACE}/SET_LEVEL_BEING_MODIFIED`;
export const SET_NEW_LEVEL_BEING_CREATED = `${NAMESPACE}/SET_NEW_LEVEL_BEING_CREATED`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;

export const GET_COMPETITION_LEVELS = `${NAMESPACE}/GET_COMPETITION_LEVELS`;
export const SET_COMPETITION_LEVELS = `${NAMESPACE}/SET_COMPETITION_LEVELS`;
export const GET_COMPETITION_LEVELS_REQUEST = `${NAMESPACE}/GET_COMPETITION_LEVELS_REQUEST`;
export const GET_COMPETITION_LEVELS_SUCCESS = `${NAMESPACE}/GET_COMPETITION_LEVELS_SUCCESS`;
export const GET_COMPETITION_LEVELS_FAILURE = `${NAMESPACE}/GET_COMPETITION_LEVELS_FAILURE`;

export const CREATE_COMPETITION_LEVEL = `${NAMESPACE}/CREATE_COMPETITION_LEVEL`;
export const CREATE_COMPETITION_LEVEL_REQUEST = `${NAMESPACE}/CREATE_COMPETITION_LEVEL_REQUEST`;
export const CREATE_COMPETITION_LEVEL_SUCCESS = `${NAMESPACE}/CREATE_COMPETITION_LEVEL_SUCCESS`;
export const CREATE_COMPETITION_LEVEL_FAILURE = `${NAMESPACE}/CREATE_COMPETITION_LEVEL_FAILURE`;

export const UPDATE_COMPETITION_LEVEL = `${NAMESPACE}/UPDATE_COMPETITION_LEVEL`;
export const UPDATE_COMPETITION_LEVEL_REQUEST = `${NAMESPACE}/UPDATE_COMPETITION_LEVEL_REQUEST`;
export const UPDATE_COMPETITION_LEVEL_SUCCESS = `${NAMESPACE}/UPDATE_COMPETITION_LEVEL_SUCCESS`;
export const UPDATE_COMPETITION_LEVEL_FAILURE = `${NAMESPACE}/UPDATE_COMPETITION_LEVEL_FAILURE`;

export const DELETE_COMPETITION_LEVEL = `${NAMESPACE}/DELETE_COMPETITION_LEVEL`;
export const DELETE_COMPETITION_LEVEL_REQUEST = `${NAMESPACE}/DELETE_COMPETITION_LEVEL_REQUEST`;
export const DELETE_COMPETITION_LEVEL_SUCCESS = `${NAMESPACE}/DELETE_COMPETITION_LEVEL_SUCCESS`;
export const DELETE_COMPETITION_LEVEL_FAILURE = `${NAMESPACE}/DELETE_COMPETITION_LEVEL_FAILURE`;
