import * as i18next from 'i18next';
import {
  STAGE_PROFILE_MATCH_TAB,
  STAGE_PROFILE_BRACKETS_TAB,
} from '@core/pages/competition-profile/tests/test-ids';
import StageGeneralInformationTab from '@core/pages/competition-profile/components/stage-profile-panel/general-tab';
import MatchesTab from '@core/pages/competition-profile/components/stage-profile-panel/matches-tab';
import StageAllocationsTab from '@core/pages/competition-profile/components/stage-profile-panel/allocations-tab';
import KnockOutStageStandingsTab from '@core/pages/competition-profile/components/stage-profile-panel/brackets-tab';
import { GROUP_STATUSES, GroupLinks } from '@core/types';
import { StageType } from '@volleyball/types';
import SettingsTab from './settings-tab';
import StageStandingsTab from './standings-tab';

export enum StageProfileTabs {
  GENERAL_INFO_TAB = 'GENERAL_INFO_TAB',
  SETTINGS_TAB = 'SETTINGS_TAB',
  MATCHES_TAB = 'MATCHES_TAB',
  STANDINGS_TAB = 'STANDINGS_TAB',
  ALLOCATIONS_TAB = 'ALLOCATIONS_TAB',
  BRACKETS_TAB = 'BRACKETS_TAB',
}

type StageTabs = Array<{
  value: StageProfileTabs;
  component: any;
  label: string;
  disabled?: boolean;
  testId?: string;
  hidden?: boolean;
}>;

export const getStageProfileTabs = (
  t: i18next.TFunction,
  stageType: keyof typeof StageType,
  stageLinks: GroupLinks,
  status: GROUP_STATUSES,
): StageTabs => [
  {
    value: StageProfileTabs.GENERAL_INFO_TAB,
    component: StageGeneralInformationTab,
    label: t('General'),
  },
  {
    value: StageProfileTabs.SETTINGS_TAB,
    component: SettingsTab,
    label: t('Settings'),
  },
  {
    value: StageProfileTabs.MATCHES_TAB,
    component: MatchesTab,
    label: t('Matches'),
    testId: STAGE_PROFILE_MATCH_TAB,
  },
  {
    value: StageProfileTabs.STANDINGS_TAB,
    component: StageStandingsTab,
    label: t('Standings'),
    hidden:
      !stageLinks?.groupStandingSettings || stageType === StageType.KNOCK_OUT,
  },
  {
    value: StageProfileTabs.BRACKETS_TAB,
    component: KnockOutStageStandingsTab,
    label: t('Brackets'),
    testId: STAGE_PROFILE_BRACKETS_TAB,
    disabled: status === GROUP_STATUSES.DRAFT,
    hidden: stageType !== StageType.KNOCK_OUT || !stageLinks['brackets'],
  },
  {
    value: StageProfileTabs.ALLOCATIONS_TAB,
    component: StageAllocationsTab,
    label: t('Allocations'),
    hidden: !stageLinks?.['matchAllocations.list'],
  },
];
