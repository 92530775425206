import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { formatPaginatedStore } from '@core/helpers';
import { Paginated, MatchAllocation } from '@core/types';

import { getMatchesStatus } from './helpers';
import { actions } from '.';

export interface DefaultState {
  byGroupId: {
    [id: number]: Paginated<MatchAllocation> & {
      allocations: ReturnType<typeof getMatchesStatus>;
    };
  };
}

export const defaultState: DefaultState = {
  byGroupId: {},
};

type Payload = any;

interface Meta {
  groupId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setMatchesAllocation.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byGroupId', meta.groupId, 'paginated'],
        formatPaginatedStore('matchAllocations')(payload),
        state,
      ),
    [actions.setAllocations.toString()]: (state, { payload, meta }) =>
      assocPath(['byGroupId', meta.groupId, 'allocations'], payload, state),
  },
  defaultState,
);

export default reducer;
