import { StandingTableHeaders, StandingTableRanks } from '@volleyball/types';

import { STANDING_SETTINGS_FIELD_NAMES } from '../constants';

export interface FormValues {
  [STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders]?: StandingTableHeaders;
  [STANDING_SETTINGS_FIELD_NAMES.standingTableRanks]?: StandingTableRanks;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: number;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]?: number;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: number;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]?: number;
}

export interface PointsFieldLabels {
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: string;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]?: string;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: string;
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]?: string;
}

export const getDefaultValues = (): FormValues => ({
  [STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders]: [],
  [STANDING_SETTINGS_FIELD_NAMES.standingTableRanks]: [],
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: 1,
  [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: 1,
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: 1,
  [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: 1,
});
