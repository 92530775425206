import { Paginated } from '@core/types';

export const appendToPaginatedListFactory =
  <T extends { [entity: string]: Paginated<any> }>(entity: keyof T) =>
  (state: T, payload: T[typeof entity]): T => {
    const currentList = state?.[entity]?.list;

    return currentList
      ? {
          ...state,
          [entity]: {
            ...payload,
            list:
              payload.page === 1
                ? payload.list
                : [...currentList, ...payload.list].filter(
                    (item, index, items) => items.indexOf(item) === index,
                  ),
          },
        }
      : {
          ...state,
          [entity]: {
            ...payload,
          },
        };
  };
