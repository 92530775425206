import React from 'react';
import { Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FULL_SCREEN_SPINNER } from '@core/constants/test-ids';

const useStyles = makeStyles((theme: Theme) => ({
  root: (absolute) =>
    absolute
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          backgroundColor: theme.palette.background.default,
        }
      : {},
  spinner: (absolute) =>
    absolute
      ? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: theme.spacing(-2.5, 0, 0, -2.5),
        }
      : {},
}));

interface StyleProps {
  absolute?: boolean;
}

type Props = StyleProps;

const FullScreenSpinner = ({ absolute = false }: Props) => {
  const classes = useStyles(absolute);

  return (
    <Box
      className={classes.root}
      display="flex"
      flex="1"
      justifyContent="center"
      p={2}
    >
      <CircularProgress
        color={'primary'}
        className={classes.spinner}
        data-qa={FULL_SCREEN_SPINNER}
      />
    </Box>
  );
};

export default FullScreenSpinner;
