import { pathOr, isEmpty } from 'ramda';

import { getVenuesById } from '@core/pages/venues/store/api/selectors';
import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getFixtureMatchEditErrors = pathOr(null, [
  'modals',
  NAMESPACE,
  'errors',
]);
export const getVenuesOptions = (state: State) => {
  const venues = { ...pathOr(null, ['modals', NAMESPACE, 'venues'], state) };

  return venues && !isEmpty(venues)
    ? { ...venues, list: getVenuesById(state, venues.list) }
    : null;
};
export const getGroupCompetitors = pathOr(null, [
  'modals',
  NAMESPACE,
  'groupCompetitors',
]);
