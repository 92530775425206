import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormControlLabel, Checkbox } from '@mui/material';

import { FileUploadModal } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { GlobalModalProps } from '@core/types';
import { actions as matchActions } from '@core/store/modules/api/match';
import { State } from '@core/store';

interface StateProps {
  isUploading: boolean;
}

interface DispatchProps {
  actions: {
    match: typeof matchActions;
  };
}

export interface OwnProps {
  competitionId: number;
  groupId: number;
}

type Props = StateProps & DispatchProps & GlobalModalProps<OwnProps>;

const StageMatchesUploadModal = (props: Props) => {
  const { actions, isUploading, modalParams, modalActions, ...rest } = props;
  const [useTeams, setUseTeams] = useState(false);
  const { t } = useTranslation();

  const handleFileSelect = (value: any) => {
    const groupId = modalParams?.groupId;
    const competitionId = modalParams?.competitionId;

    actions.match.uploadMatches({
      groupId,
      competitionId,
      file: value,
      importByTeamNames: useTeams,
      // TODO: JB: remove any
      successCallback: modalActions.closeModal as any,
      page: 1,
    });
  };

  const handleUseTeamsSelectChange = () => setUseTeams(!useTeams);

  const additionalCheck = () => (
    <FormControlLabel
      control={
        <Checkbox
          checked={useTeams}
          onChange={handleUseTeamsSelectChange}
          name="useTeams"
          color="primary"
        />
      }
      label={<>{t('I want to import matches with team names')}</>}
    />
  );

  return (
    <FileUploadModal
      title={t('Import matches')}
      handleFileSelect={handleFileSelect}
      alertText={t('Existing matches will be replaced after import')}
      label={t('To import matches, select a CSV file.')}
      modalParams={modalParams}
      modalActions={modalActions}
      accept=".csv"
      additionalCheck={additionalCheck()}
      {...rest}
    />
  );
};

const uploadingLoadingSelector = createLoadingSelector([
  matchActions.uploadMatchesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isUploading: uploadingLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    match: bindActionCreators(matchActions, dispatch),
  },
});

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageMatchesUploadModal);
