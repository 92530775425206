export const MATCH_GROUP_SELECT = 'MATCH_GROUP_SELECT';
export const MATCH_DAY_INPUT = 'MATCH_DAY_INPUT';
export const MATCH_NUMBER_INPUT = 'MATCH_NUMBER_INPUT';
export const MATCH_DATE_INPUT = 'MATCH_DATE_INPUT';
export const MATCH_TIME_INPUT = 'MATCH_TIME_INPUT';
export const MATCH_HOME_COMPETITOR_SELECT = 'MATCH_HOME_COMPETITOR_SELECT';
export const MATCH_AWAY_COMPETITOR_SELECT = 'MATCH_AWAY_COMPETITOR_SELECT';
export const MATCH_DELETE_BUTTON = 'MATCH_DELETE_BUTTON';
export const MATCH_CANCEL_BUTTON = 'MATCH_CANCEL_BUTTON';
export const MATCH_SUBMIT_BUTTON = 'MATCH_SUBMIT_BUTTON';
export const MATCH_SUB_VENUE_SELECT = 'MATCH_SUB_VENUE_SELECT';
