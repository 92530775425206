import { makeStyles } from '@mui/styles';
import { Box, BoxProps, Theme } from '@mui/material';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 64,
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    fontSize: 12,
    '@global': {
      '*': {
        fontSize: 12,
      },
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type HighlightedSectionProps = BoxProps & {
  alignCenter?: boolean;
};

const HighlightedBox = (props: HighlightedSectionProps) => {
  const { alignCenter, ...rest } = props;
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, { [classes.alignCenter]: alignCenter })}
      {...rest}
    />
  );
};

export default HighlightedBox;
