import React from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { uuid } from '@core/helpers';
import { bindActionCreators, Dispatch } from 'redux';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { TabTitle, ContentBlock, NamedValueColumn } from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import {
  getClubType,
  getGeography,
  getSports,
  getGenders,
  createURL,
  formatDate,
} from '@core/helpers';
import { COMPETITION_PROFILE_EDIT_DETAILS_BUTTON } from '@core/pages/competition-profile/tests/test-ids';
import { CustomerResponse, CompetitionResponse } from '@core/types';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import paths from '@core/routes/paths';
import { State } from '@core/store';

interface OwnProps {
  id: number;
}

interface StateProps {
  competition: CompetitionResponse;
  currentCustomer?: CustomerResponse;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

type Props = StateProps & DispatchProps & OwnProps;

export const CompetitionGeneralInformationTab = ({
  competition,
  actions,
  currentCustomer,
}: Props) => {
  const { t } = useTranslation();
  const canEditCompetition = !!competition?._links?.patch;

  const handleEdit = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.competitionGeneralInformationEdit),
      props: {
        competitionId: competition.id,
      },
      id: uuid(),
    });

  const tabActions = canEditCompetition && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleEdit}
      data-qa={COMPETITION_PROFILE_EDIT_DETAILS_BUTTON}
      startIcon={<EditOutlinedIcon />}
    >
      {t('Edit')}
    </Button>
  );

  return (
    competition && (
      <>
        <TabTitle actions={tabActions} />
        <ContentBlock title={t('Titles')}>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn label={t('Title')} value={competition.title} />
            <NamedValueColumn
              label={t('Abbreviation')}
              value={competition.abbreviation}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('International title')}
              value={competition.internationalTitle}
            />
          </Grid>
          <Grid xs={12} sm={4} item />
        </ContentBlock>
        <ContentBlock title={t('Details')}>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Season')}
              value={t(competition.competitionSeasonTitle) as string}
            />
            <NamedValueColumn
              label={t('Age group')}
              value={t(competition.ageGroupTitle) as string}
            />
            <NamedValueColumn
              label={t('Number of teams')}
              value={competition.participatingTeams}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Sport')}
              value={getSports(t, competition.sportTitle)}
            />
            <NamedValueColumn
              label={t('Competition level')}
              value={t(competition.competitionLevelTitle) as string}
            />
            <NamedValueColumn
              label={t('Geography')}
              value={getGeography(t, competition.geography) as string}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Gender')}
              value={getGenders(t, competition.gender)}
            />
            <NamedValueColumn
              label={t('Team type')}
              value={getClubType(t, competition.clubType) as string}
            />
          </Grid>
        </ContentBlock>
        <ContentBlock title={t('Dates')}>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Start date')}
              value={formatDate(
                currentCustomer?.dateFormat,
                competition.startDate,
              )}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('End date')}
              value={formatDate(
                currentCustomer?.dateFormat,
                competition.endDate,
              )}
            />
          </Grid>
          <Grid xs={12} sm={4} item />
        </ContentBlock>
      </>
    )
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competition: getCompetitionDetailsById(ownProps.id)(state),
  currentCustomer: getCurrentCustomer(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitionGeneralInformationTab);
