import React, { useEffect } from 'react';
import { uuid } from '@core/helpers';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Badge, Button, Grow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AddCircleOutlineOutlined,
  FormatListBulleted,
  PriorityHigh,
} from '@mui/icons-material';

import { ButtonWithDropdown } from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { createURL } from '@core/helpers';
import {
  STAGE_PROFILE_CREATE_CUSTOM_MATCH_BUTTON,
  STAGE_PROFILE_IMPORT_MATCHES_BUTTON,
  STAGE_PROFILE_EXPORT_MATCHES_BUTTON,
  STAGE_PROFILE_DOWNLOAD_CSV_TEMPLATE_BUTTON,
  STAGE_PROFILE_TEAM_GRID_BUTTON,
  STAGE_PROFILE_FIXTURE_BUTTON,
} from '@core/pages/competition-profile/tests/test-ids';
import { actions as matchActions } from '@core/store/modules/api/match';
import {
  ChildGroupCompetitors,
  Group,
  GroupCompetitor,
  ModalTypes,
} from '@core/types';
import paths from '@core/routes/paths';
import { State } from '@core/store';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { OwnProps as StageMatchesUploadModalProps } from '@core/pages/competition-profile/components/stage-profile-panel/stage-matches-upload-modal';
import { MatchCreateModalProps } from '@core/pages/competition-profile';
import { getGroupCompetitors } from '@core/store/modules/tabs/team-grid/selectors';
import teamGridActions from '@core/store/modules/tabs/team-grid/index';
import { isStageGroupCompetitorsSet } from '@core/pages/team-grids-edit';
import { ActionsButtons } from '@ui-components';

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
    tabs: typeof tabsActions;
    teamGrid: typeof teamGridActions;
    match: typeof matchActions;
  };
}

interface StateProps {
  isGroupCompetitorsLoading: boolean;
  groupCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors;
}

interface OwnProps {
  stage: Group;
  competitionId: number;
  isLoading: boolean;
}

enum MatchesActions {
  CREATE_CUSTOM = 'CREATE_CUSTOM',
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  DOWNLOAD_CSV_TEMPLATE = 'DOWNLOAD_CSV_TEMPLATE',
}

const useStyles = makeStyles((theme: Theme) => ({
  badgeRoot: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  badgeColorError: {
    backgroundColor: theme.palette.warning.main,
    '& > svg': {
      fontSize: theme.spacing(2),
    },
  },
}));

type TeamGridsEditProps = {
  competitionId: number;
  groupId: number;
  tabId: string;
};

type Props = DispatchProps & OwnProps & StateProps;

const MatchesTabActions = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isGroupCompetitorsLoading,
    isLoading,
    actions,
    stage,
    competitionId,
    groupCompetitors,
  } = props;

  const isLoadingAny = isGroupCompetitorsLoading || isLoading;
  const groupId = stage?.id;
  const canCreateMatch = !!stage?._links?.['matches.create'];
  const canImportMatches = !!stage?._links?.['matches.import'];
  const canExportMatches = !!stage?._links?.['matches.export'];

  useEffect(() => {
    actions.teamGrid.getCompetitorsByGroupId({ groupId });
  }, []);

  const onSelect = (value: string) => {
    switch (value) {
      case MatchesActions.CREATE_CUSTOM:
        actions.globalModal.openModal<MatchCreateModalProps>({
          type: ModalTypes.MATCH_CREATE_MODAL,
          params: { competitionId, stage },
        });
        break;
      case MatchesActions.IMPORT:
        actions.globalModal.openModal<StageMatchesUploadModalProps>({
          type: ModalTypes.STAGE_MATCHES_UPLOAD_MODAL,
          params: { groupId, competitionId },
        });
        break;
      case MatchesActions.EXPORT:
        actions.match.exportMatches({ groupId });
        break;
      case MatchesActions.DOWNLOAD_CSV_TEMPLATE:
        actions.match.exportMatchesTemplate({ groupId });
        break;
    }
  };

  const options = [
    {
      text: t('Create custom'),
      value: MatchesActions.CREATE_CUSTOM,
      testId: STAGE_PROFILE_CREATE_CUSTOM_MATCH_BUTTON,
      disabled: !canCreateMatch,
    },
    {
      text: t('Import'),
      value: MatchesActions.IMPORT,
      testId: STAGE_PROFILE_IMPORT_MATCHES_BUTTON,
      disabled: !canImportMatches,
    },
    {
      text: t('Export'),
      value: MatchesActions.EXPORT,
      testId: STAGE_PROFILE_EXPORT_MATCHES_BUTTON,
      disabled: !canExportMatches,
    },
    {
      text: t('Download CSV template'),
      value: MatchesActions.DOWNLOAD_CSV_TEMPLATE,
      testId: STAGE_PROFILE_DOWNLOAD_CSV_TEMPLATE_BUTTON,
      disabled: !canImportMatches,
    },
  ];

  const openTeamGridTab = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.stageTeamGridEdit),
      props: {
        groupId,
        competitionId,
      } as TabbedProps<TeamGridsEditProps>,
      id: uuid(),
    });

  const actionButtons: React.ComponentProps<typeof ActionsButtons>['buttons'] =
    [
      () => (
        <Badge
          classes={{
            badge: classes.badgeRoot,
            colorError: classes.badgeColorError,
          }}
          badgeContent={<Grow in={true}>{<PriorityHigh />}</Grow>}
          color="error"
          invisible={
            isStageGroupCompetitorsSet(groupCompetitors, stage.childGroups) ||
            isLoadingAny
          }
        >
          <Button
            data-qa={STAGE_PROFILE_TEAM_GRID_BUTTON}
            disabled={isLoadingAny}
            onClick={openTeamGridTab}
            startIcon={<FormatListBulleted />}
            title={t('Team grid')}
            variant={'contained'}
          >
            {t('Team grid')}
          </Button>
        </Badge>
      ),
      () => (
        <ButtonWithDropdown
          disabled={isLoadingAny}
          options={options}
          onSelect={onSelect}
          startIcon={<AddCircleOutlineOutlined />}
          variant="outlined"
          align="flex-end"
          data-qa={STAGE_PROFILE_FIXTURE_BUTTON}
        >
          {t('Fixture')}
        </ButtonWithDropdown>
      ),
    ];

  return <ActionsButtons buttons={actionButtons} />;
};

const isGroupCompetitorsLoadingSelector = (groupId: number) =>
  createLoadingSelector([
    {
      id: groupId,
      actionName: teamGridActions.getCompetitorsByGroupIdRequest.toString(),
    },
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
    teamGrid: bindActionCreators(teamGridActions, dispatch),
    match: bindActionCreators(matchActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isGroupCompetitorsLoading: isGroupCompetitorsLoadingSelector(
    ownProps.stage?.id,
  )(state),
  groupCompetitors: getGroupCompetitors(state, ownProps.stage?.id),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchesTabActions);
