import { handleActions } from 'redux-actions';
import { dissocPath } from 'ramda';

import { actions } from '.';

export interface DefaultState {
  [matchId: string]: Record<string, never>;
}

export const defaultState: DefaultState = {};

type Payload = any;

interface Meta {
  matchId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setMatchDataHistory.toString()]: (state, { meta, payload }) => ({
      ...state,
      [meta.matchId]: {
        ...state[meta.matchId],
        matchDataHistory: payload,
      },
    }),

    [actions.resetMatchDataHistory.toString()]: (state, { meta }) =>
      dissocPath([meta.matchId], state),
  },
  defaultState,
);

export default reducer;
