import restService from '@core/api/rest-service';
import {
  TeamsResponse,
  TeamModel,
  QueryParamsByCompetition,
} from '@core/types';
import { TeamFormValues } from '@core/pages/create-team/constants/fields';

export const getTeamsBySport = (
  sportId: number,
  searchParams: URLSearchParams,
) =>
  restService.get<TeamsResponse>(`/v1/sports/${sportId}/teams`, {
    params: searchParams,
  });

export const getTeamsForCompetition = (queryParams: QueryParamsByCompetition) =>
  restService.get<TeamsResponse>('/v1/teams', { params: queryParams });

export const getTeam = (teamId: number) =>
  restService.get<TeamModel>(`/v1/teams/${teamId}`);

export const getTeamsByClub = (clubId: number, params: any) =>
  restService.get<TeamsResponse>(`/v1/clubs/${clubId}/teams`, { params });

export const createTeam = (clubId: number, data: TeamFormValues) =>
  restService.post<unknown>(`/v1/clubs/${clubId}/teams`, data);

export const getTeams = (queryParams?: QueryParamsByCompetition) =>
  restService.get<TeamsResponse>('/v1/teams', {
    params: { ...queryParams, limit: 20 },
  });

export const deleteTeam = (link: string) => restService.delete<void>(link);

export const patchTeam = (teamId: number, data: TeamFormValues) =>
  restService.patch<unknown>(`/v1/teams/${teamId}`, data);
