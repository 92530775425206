import { all } from 'redux-saga/effects';

import groupsSaga from '@core/store/modules/pages/create-stage/sagas';
import matchesSaga from '@core/store/modules/pages/matches/sagas';
import appointmentsSaga from '@core/store/modules/pages/appointments/sagas';
import assignmentsSaga from '@core/store/modules/pages/assignments/sagas';
import refereeCategoriesSaga from './referee-categories/sagas';

export default function* saga() {
  yield all([
    refereeCategoriesSaga(),
    matchesSaga(),
    appointmentsSaga(),
    groupsSaga(),
    assignmentsSaga(),
  ]);
}
