import restService from '@core/api/rest-service';
import {
  MatchPlayersResponse,
  ChangeListRequestData,
  UpdateMatchPlayersRequestData,
} from '@core/types';

export const getMatchPlayers = (matchId: number, competitorId: number) =>
  restService.get<MatchPlayersResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchPlayers`,
  );

export const changeMatchPlayersList = (
  matchId: number,
  competitorId: number,
  data: ChangeListRequestData,
) =>
  restService.put<MatchPlayersResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchPlayers`,
    data,
  );

export const updateMatchPlayers = (
  matchId: number,
  playerId: number,
  competitorId: number,
  data: UpdateMatchPlayersRequestData,
) =>
  restService.patch<MatchPlayersResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchTeamOfficials/${playerId}`,
    data,
  );

export const getPreviousMatchPlayers = (
  matchId: number,
  competitorId: number,
) =>
  restService.get<any>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchPlayers/activeOfPreviousMatch`,
  );
