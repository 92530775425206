import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const popperArrowClassName = 'popper-arrow';

export default makeStyles((theme: Theme) => {
  const borderColor = theme.palette.grey[300];
  const backgroundColor = theme.palette.common.white;
  const arrowBorder = `1px solid ${borderColor}`;

  const side = 16; // in addition font-size so em units could be used
  const hypotenuse = Math.sqrt(side ** 2 * 2);
  const arrowHeight = hypotenuse / 2; // triangle altitude from the right angle of the arrow triangle
  const arrowWidth = hypotenuse; // line length opposite to the right angle of the arrow triangle
  const tooltipPaperMargin = arrowHeight; // space between tooltip and the refence element
  const arrowContainerMargin = '-1em'; // space between tooltip and arrow container

  const popperArrowClassCssSelector = `.${popperArrowClassName}`;
  const paperSubselector = '& > div';
  const arrowContainerSubselector = `& ${popperArrowClassCssSelector}`;
  const arrowSubselector = '&::before';

  return {
    '@global': {
      [`[data-popper-reference-hidden]`]: {
        visibility: 'hidden',
        pointerEvents: 'none',
      },
      [`${popperArrowClassCssSelector}[data-hide]`]: {
        visibility: 'hidden',
      },
    },

    popper: {
      zIndex: theme.zIndex.tooltip,
      maxWidth: '280px',

      [paperSubselector]: {
        position: 'relative',
      },

      '&[data-popper-placement]': {
        [arrowContainerSubselector]: {
          display: 'block',
          position: 'absolute',
          fontSize: side,
          width: side,
          height: side,

          [arrowSubselector]: {
            content: '""',
            display: 'block',
            backgroundColor,
            width: side,
            height: side,
          },
        },
      },

      '&[data-popper-placement*="top"]': {
        [paperSubselector]: {
          marginBottom: tooltipPaperMargin,
        },
        [arrowContainerSubselector]: {
          bottom: 0,
          left: 0,
          width: `${arrowWidth}px !important`,
          marginBottom: arrowContainerMargin,
          [arrowSubselector]: {
            borderLeft: arrowBorder,
            borderBottom: arrowBorder,
            transformOrigin: 'top left',
            transform: `rotate(calc(45deg * -1))`,
          },
        },
      },
      '&[data-popper-placement*="bottom"]': {
        [paperSubselector]: {
          marginTop: tooltipPaperMargin,
        },
        [arrowContainerSubselector]: {
          top: 0,
          left: 0,
          width: `${arrowWidth}px !important`,
          marginTop: arrowContainerMargin,
          [arrowSubselector]: {
            borderLeft: arrowBorder,
            borderTop: arrowBorder,
            transformOrigin: 'bottom left',
            transform: `rotate(calc(45deg * 1))`,
          },
        },
      },
      '&[data-popper-placement*="left"]': {
        [paperSubselector]: {
          marginRight: tooltipPaperMargin,
        },
        [arrowContainerSubselector]: {
          right: 0,
          height: `${arrowWidth}px !important`,
          marginRight: arrowContainerMargin,
          [arrowSubselector]: {
            borderTop: arrowBorder,
            borderRight: arrowBorder,
            transformOrigin: 'top left',
            transform: `rotate(calc(45deg * 1))`,
          },
        },
        '&[data-popper-placement*="right"]': {
          [paperSubselector]: {
            marginLeft: tooltipPaperMargin,
          },
          [arrowContainerSubselector]: {
            left: 0,
            height: `${arrowWidth}px !important`,
            marginLeft: arrowContainerMargin,
            [arrowSubselector]: {
              borderTop: arrowBorder,
              borderLeft: arrowBorder,
              transformOrigin: 'top right',
              transform: `rotate(calc(45deg * -1))`,
            },
          },
        },
      },
    },

    subtitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    infoEntry: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(2),
        fontSize: '20px',
      },
    },
    infoLink: {
      cursor: 'pointer',
    },
    infoIcon: {
      fill: theme.palette.text.secondary,
    },
  };
});
