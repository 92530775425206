import React from 'react';
import { Grid, Box, Typography, TypographyProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { LogoSize } from '@core/types';
import { Logo } from '@core/components';
import { BubbleIcon } from '@ui-components';

const useStyles = makeStyles<Theme, any>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: ({ alignment }: Props) =>
      alignment === 'right' ? 'row-reverse' : 'row',
  },
  logo: {
    marginRight: ({ alignment }: Props) => (alignment === 'right' ? 0 : 12),
    marginLeft: ({ alignment }: Props) => (alignment === 'right' ? 12 : 0),
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ alignment }: Props) =>
      alignment === 'right' ? 'flex-end' : 'flex-start',
  },
  titleLink: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  topIcon: {
    position: 'absolute',
    right: '-12px',
    top: '-6px',
  },
  bottomIcon: {
    position: 'absolute',
    right: '-12px',
    bottom: '-6px',
  },
}));

type Alignment = 'right' | 'left';

interface Props {
  value?: React.ReactNode;
  valueTypographyProps?: TypographyProps;
  valueSecondaryProps?: TypographyProps;
  subValue?: string;
  onTitleClick?: (arg: any) => void;
  isDisabled?: boolean;
  alignment?: Alignment;
  isCaptain?: boolean;
  isLibero?: boolean;
  startText?: string;
  logo?: {
    url?: string;
    nameInitials?: string;
    defaultIcon?: any;
  };
}

const ColumnWithSubValue = (props: Props) => {
  const {
    value,
    valueTypographyProps,
    valueSecondaryProps,
    subValue,
    isDisabled,
    onTitleClick,
    logo,
    alignment = 'left',
    isCaptain,
    isLibero,
    startText,
  } = props;
  const classes = useStyles({ alignment });
  const { t } = useTranslation();

  return (
    <Grid className={classes.root}>
      {logo && (
        <Grid className={classes.logo}>
          <Logo
            size={LogoSize.SMALL}
            src={logo?.url}
            nameInitials={logo?.nameInitials}
          >
            {logo?.defaultIcon}
          </Logo>
          {isCaptain && (
            <Grid className={classes.bottomIcon}>
              <BubbleIcon text={t('C')} tooltipText={t('Captain')} />
            </Grid>
          )}
          {isLibero && (
            <Grid className={isCaptain ? classes.topIcon : classes.bottomIcon}>
              <BubbleIcon text={t('L')} tooltipText={t('Libero')} />
            </Grid>
          )}
        </Grid>
      )}
      {startText && (
        <Box mr={1}>
          <Typography variant="caption" color="textSecondary">
            {startText}
          </Typography>
        </Box>
      )}
      {value && (
        <Box className={classes.title}>
          {onTitleClick ? (
            <Typography
              align={alignment}
              onClick={onTitleClick}
              className={classes.titleLink}
              variant="body2"
              color={isDisabled ? 'textSecondary' : 'primary'}
              {...valueTypographyProps}
            >
              {value}
            </Typography>
          ) : (
            <Typography
              align={alignment}
              variant="body2"
              color={isDisabled ? 'textSecondary' : 'textPrimary'}
              {...valueTypographyProps}
            >
              {value}
            </Typography>
          )}
          {subValue && (
            <Typography
              {...valueSecondaryProps}
              variant="caption"
              color="textSecondary"
            >
              {subValue}
            </Typography>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default ColumnWithSubValue;
