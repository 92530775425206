import React from 'react';
import {
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prop, has, pipe, pathOr, propOr } from 'ramda';

import { FileErrorModel, ApiError } from '@core/types';
import { FileUploadButton, CustomTable } from '@core/components';

interface OwnProps {
  handleClose: () => void;
  setSelectedFiles: (file: Array<File>) => void;
  selectedFiles: Array<File>;
  clearErrors: () => void;
  errors: any;
}

type Props = OwnProps;

const ErrorDialogContent = (props: Props) => {
  const { errors, handleClose, selectedFiles, setSelectedFiles, clearErrors } =
    props;
  const { t } = useTranslation();
  const selectedFile = selectedFiles[0];
  const apiErrors = propOr([], 'apiErrors', errors) as any;

  const isFieldTypeErrors: (errors: FileErrorModel) => any = pipe(
    prop('apiErrors'),
    (val: Array<any>) => val[0],
    has('lineNumber'),
  );

  function renderFileTypeError() {
    return (
      <DialogContentText>
        {t(pathOr('', [0, 'message'], apiErrors))}
      </DialogContentText>
    );
  }

  function renderFieldTypeErrors() {
    const columns = [
      { title: t('Row'), field: 'lineNumber' },
      { title: t('Column'), field: 'path' },
      { title: t('Error'), field: 'code' },
      { title: t('Message'), field: 'message' },
    ];

    const data = apiErrors.map(({ lineNumber, ...rest }: ApiError) => ({
      ...rest,
      lineNumber: ++lineNumber, // first row is a header and its line number is 0
    }));

    return (
      <>
        <DialogContentText>
          {t(
            'Sorry, this file has some structural errors. Please fix errors and re-upload this or select another file.',
          )}
        </DialogContentText>
        <CustomTable noPaper columns={columns} data={data} />
      </>
    );
  }

  const selectFileButton = (btnProps: any) => (
    <Button color="primary" variant="contained" {...btnProps}>
      {t('Select another file')}
    </Button>
  );

  function handleFileReselect(files: Array<File>) {
    setSelectedFiles(files);
    clearErrors();
  }

  function handleModalClose() {
    handleClose();
  }

  return (
    <>
      <DialogTitle>{t('Import error')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{prop('name', selectedFile)}</DialogContentText>
        <Box mt={3}>
          {isFieldTypeErrors(errors)
            ? renderFieldTypeErrors()
            : renderFileTypeError()}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleModalClose}>{t('Cancel')}</Button>
          </Box>
          <FileUploadButton
            buttonComponent={selectFileButton}
            files={selectedFiles}
            setSelectedFile={handleFileReselect}
            withSelectedFileNaming={false}
          />
        </Box>
      </DialogActions>
    </>
  );
};

export default ErrorDialogContent;
