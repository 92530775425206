import { TFunction } from 'i18next';

import GeneralInformationTab from './general-information-tab';
import PlayerListTab from './player-list-tab';
import TeamKitsTab from './team-kits-tab';

export enum CompetitionProfileTabs {
  GENERAL_INFO_TAB = 'GENERAL_INFO_TAB',
  TEAM_ROSTER_TAB = 'TEAM_ROSTER_TAB',
  TEAM_KIT_TAB = 'TEAM_KIT_TAB',
}

type Tabs = Array<{
  value: CompetitionProfileTabs;
  component:
    | typeof GeneralInformationTab
    | typeof PlayerListTab
    | typeof TeamKitsTab;
  label: string;
  withWarning?: boolean;
}>;

export const getCompetitorTabs = (t: TFunction, withWarning: boolean): Tabs => [
  {
    value: CompetitionProfileTabs.GENERAL_INFO_TAB,
    component: GeneralInformationTab,
    label: t('General'),
  },
  {
    value: CompetitionProfileTabs.TEAM_ROSTER_TAB,
    component: PlayerListTab,
    label: t('Team Roster'),
    withWarning,
  },
  {
    value: CompetitionProfileTabs.TEAM_KIT_TAB,
    component: TeamKitsTab,
    label: t('Team kits'),
  },
];
