import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { MenuItem, Typography, Theme, Menu, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Language } from '@mui/icons-material';
import * as i18nActions from '@core/store/modules/i18n/actions';

import * as testIds from './tests/test-ids';
import localeToLanguageNameMap from './constants/locale-to-language-name-map';

interface DispatchProps {
  actions: {
    i18n: typeof i18nActions;
  };
}

interface OwnProps {
  disableGutters?: boolean;
  shouldUseNativeLanguageName?: boolean;
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: 14,
    padding: ({ disableGutters }: Props) =>
      theme.spacing(0, disableGutters ? 0 : 2),
    lineHeight: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const LanguageSelect = (props: Props) => {
  const { i18n } = useTranslation();
  const { actions, shouldUseNativeLanguageName } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    actions.i18n.changeLanguage(event.currentTarget.getAttribute('data-value'));
    handleClose();
  };

  const getAvailableLocales = () => Object.keys(localeToLanguageNameMap);

  const getLanguageName = (language: string, useNative: boolean) => {
    const locale = localeToLanguageNameMap[language];

    return useNative ? locale.nativeName : locale?.code.toUpperCase();
  };

  const shouldDisplayLanguage = (language: string) =>
    !localeToLanguageNameMap[language].hidden;

  return i18n.language ? (
    <>
      <ButtonBase
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-qa={testIds.LANGUAGE_SELECT}
        className={classes.button}
      >
        <Language className={classes.icon} />
        {getLanguageName(i18n.language, shouldUseNativeLanguageName)}
      </ButtonBase>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getAvailableLocales().map(
          (language) =>
            shouldDisplayLanguage(language) && (
              <MenuItem
                key={language}
                data-value={language}
                data-qa={testIds.LANGUAGE_SELECT_OPTION}
                onClick={handleChange}
              >
                <Typography color="inherit">
                  {getLanguageName(language, shouldUseNativeLanguageName)}
                </Typography>
              </MenuItem>
            ),
        )}
      </Menu>
    </>
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    i18n: bindActionCreators(i18nActions, dispatch),
  },
});

LanguageSelect.defaultProps = {
  shouldUseNativeLanguageName: false,
};

export default connect(null, mapDispatchToProps)(LanguageSelect);
