import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';

export const authorize = createAction<void>(`${NAMESPACE}/AUTHORIZE`);

export const logout = createAction<void>(`${NAMESPACE}/LOGOUT`);

export const logoutRequest = createAction<void>(`${NAMESPACE}/LOGOUT_REQUEST`);

export const logoutSuccess = createAction<void>(`${NAMESPACE}/LOGOUT_SUCCESS`);

export const logoutFailure = createAction<void>(`${NAMESPACE}/LOGOUT_FAILURE`);

/** is excluded from sentry logging */
export const register = createAction<{
  client_id: string;
  email: string;
  password: string;
  connection: string;
  given_name: string;
  family_name: string;
}>(`${NAMESPACE}/REGISTER`);
export const registerRequest = createAction<void>(
  `${NAMESPACE}/REGISTER_REQUEST`,
);

export const registerSuccess = createAction<void>(
  `${NAMESPACE}/REGISTER_SUCCESS`,
);

export const registerFailure = createAction<void>(
  `${NAMESPACE}/REGISTER_FAILURE`,
);

export const logoutSessionExpired = createAction<void>(
  `${NAMESPACE}/LOGOUT_SESSION_EXPIRED`,
);

export const logoutSessionExpiredRequest = createAction<void>(
  `${NAMESPACE}/LOGOUT_SESSION_EXPIRED_REQUEST`,
);

export const logoutSessionExpiredSuccess = createAction<void>(
  `${NAMESPACE}/LOGOUT_SESSION_EXPIRED_SUCCESS`,
);

export const logoutSessionExpiredFailure = createAction<void>(
  `${NAMESPACE}/LOGOUT_SESSION_EXPIRED_FAILURE`,
);
