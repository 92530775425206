import React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { MatchModel } from '@core/types';

interface OwnProps {
  matchDetails: MatchModel;
}

type Props = OwnProps;

const MatchSheetSummaryBlock = (props: Props) => {
  const { t } = useTranslation();
  const { matchDetails } = props;

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Match No.'),
      value: matchDetails?.number,
    },
    {
      label: t('Home team'),
      value: matchDetails?.homeCompetitorTitle,
    },
    {
      label: t('Away team'),
      value: matchDetails?.awayCompetitorTitle,
    },
    {
      label: t('Competition'),
      value: matchDetails?.competitionTitle,
    },
    {
      label: t('Season'),
      value: matchDetails?.seasonTitle,
    },
  ];

  return <SummaryBlock values={getInfoBlocks()} />;
};

export default MatchSheetSummaryBlock;
