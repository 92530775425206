import { pathOr, values, isEmpty } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import {
  Paginated,
  Filters,
  Sorting,
  Group,
  MatchModel,
  CompetitionResponse,
} from '@core/types';
import { NAMESPACE as competitionsNamespace } from '@core/pages/competitions/store/api';
import { State } from '@core/store';
import { getCompetitorsById } from '@core/store/modules/api/competitors/selectors';
import { getDisciplinarySanctionsById } from '@core/store/modules/api/disciplinary-sanctions/selectors';
import { getDisciplinaryInfringementsById } from '@core/store/modules/api/disciplinary-infringements/selectors';
import { DefaultState } from './reducer';
import { NAMESPACE } from '.';

export const getCompetitionDetails = (competitionId: number) => {
  return pathOr(null, ['api', competitionsNamespace, competitionId]);
};

export const getStageCurrentPage =
  (competitionId: number, stageId: number) =>
  (state: State): number =>
    state.tabs.competitionProfile[competitionId].matchesByGroup[stageId]
      ?.matches?.page ?? 1;

export const getCompetitionDetailsById =
  (competitionId: CompetitionResponse['id']) =>
  (state: State): CompetitionResponse =>
    state.api[competitionsNamespace]?.[competitionId];

export const getCompetitionProfileById = createSelector(
  (state: State): DefaultState => pathOr(null, ['tabs', NAMESPACE], state),
  (_: State, competitionId: number) => competitionId,
  (competitionProfile: DefaultState, id) => competitionProfile?.[id] ?? null,
);

export const getStagesByCompetitionId = createSelector(
  getCompetitionProfileById,
  (competitionProfile) => values(competitionProfile?.stages) ?? [],
);

export const getChildGroupsByCompAndStageIds =
  ({ competitionId, stageId }: { competitionId: number; stageId: number }) =>
  (state: State) => {
    const stage = state.tabs[NAMESPACE]?.[competitionId]?.stages?.[stageId];

    if (!stage.childGroups.length) {
      return [];
    }

    return stage.childGroups.map(({ id, title }) => ({ id, title }));
  };

export const getCompetitors = (state: State, competitionId: number) => {
  const competitionCompetitors = {
    ...pathOr(null, ['tabs', NAMESPACE, competitionId, 'competitors'], state),
  };

  return competitionCompetitors && !isEmpty(competitionCompetitors)
    ? {
        ...competitionCompetitors,
        list: getCompetitorsById(state, competitionCompetitors.list),
      }
    : null;
};

export const getStageById =
  (competitionId: number, stageId: number) =>
  (state: State): Group =>
    pathOr(null, ['tabs', NAMESPACE, competitionId, 'stages', stageId], state);

export const getDisciplinarySanctionsList = (competitionId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    competitionId,
    'disciplinarySanctions',
    'list',
  ]);

export const getDisciplinarySanctions = (
  state: State,
  competitionId: number,
) => {
  const sanctions = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, 'disciplinarySanctions'],
      state,
    ),
  };

  return sanctions && !isEmpty(sanctions)
    ? {
        ...sanctions,
        list: getDisciplinarySanctionsById(state, sanctions.list),
      }
    : null;
};
export const getDisciplinaryInfringements = (
  state: State,
  competitionId: number,
) => {
  const infringements = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, 'disciplinaryInfringements'],
      state,
    ),
  };

  return infringements && !isEmpty(infringements)
    ? {
        ...infringements,
        list: getDisciplinaryInfringementsById(state, infringements.list),
      }
    : null;
};

export const getGroupMatches = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
): Paginated<MatchModel['id']> =>
  pathOr(
    null,
    ['tabs', NAMESPACE, competitionId, 'matchesByGroup', groupId, 'matches'],
    state,
  );

export const getGroupMatchesList = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
): Array<MatchModel> => {
  const matches = pathOr(null, ['api', 'matchesList'], state);
  const matchesIds = pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      competitionId,
      'matchesByGroup',
      groupId,
      'matches',
      'list',
    ],
    state,
  );

  return matchesIds ? matchesIds.map((id: number) => matches[id]) : [];
};

export const getMatchesSearchQuery = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
) =>
  pathOr(
    '',
    [
      'tabs',
      NAMESPACE,
      competitionId,
      'matchesByGroup',
      groupId,
      'searchQuery',
    ],
    state,
  );

export const getFilters = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
): Filters =>
  pathOr(
    {},
    ['tabs', NAMESPACE, competitionId, 'matchesByGroup', groupId, 'filters'],
    state,
  );

export const getSorting = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
): Sorting =>
  pathOr(
    null,
    ['tabs', NAMESPACE, competitionId, 'matchesByGroup', groupId, 'sorting'],
    state,
  );

export const getGroupFixtures = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, competitionId, 'fixturesByGroup', groupId, 'fixtures'],
    state,
  );

export const getFixturesSearchQuery = (
  state: State,
  { competitionId, groupId }: { competitionId: number; groupId: number },
) =>
  pathOr(
    '',
    [
      'tabs',
      NAMESPACE,
      competitionId,
      'fixturesByGroup',
      groupId,
      'searchQuery',
    ],
    state,
  );

export const getSanctionsSearchQuery = (
  state: State,
  { competitionId }: { competitionId: number },
) =>
  pathOr(
    '',
    ['tabs', NAMESPACE, competitionId, 'sanctions', 'searchQuery'],
    state,
  );

export const getInfringementsSearchQuery = (
  state: State,
  { competitionId }: { competitionId: number },
) =>
  pathOr(
    '',
    ['tabs', NAMESPACE, competitionId, 'infringements', 'searchQuery'],
    state,
  );
