import { createActions } from 'redux-actions';

export const NAMESPACE = 'countriesList';

export const actions = createActions(
  'GET_COUNTRIES_REQUEST',
  'GET_COUNTRIES_SUCCESS',
  'GET_COUNTRIES_FAILURE',

  'SET_COUNTRIES',

  { prefix: NAMESPACE },
);
