import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

export const NAMESPACE = 'matchDataManagementTab';

export const actions = createActions(
  {
    SET_MATCH_DATA_HISTORY: [prop('payload'), pick(['matchId'])],

    RESET_MATCH_DATA_HISTORY: [undefined, pick(['matchId'])],
  },
  'GET_MATCH_DATA_HISTORY',
  'GET_MATCH_DATA_HISTORY_REQUEST',
  'GET_MATCH_DATA_HISTORY_SUCCESS',
  'GET_MATCH_DATA_HISTORY_FAILURE',

  { prefix: NAMESPACE },
);
