import { map } from 'ramda';
import { TFunction } from 'i18next';

import { SummaryBlockValues, TabsComponentProps } from '@core/components';
import { getStageSubHeader } from '@core/helpers';
import { useLazyReinitializableState } from '@core/hooks';
import {
  ChildGroupCompetitors,
  Groups,
  Group,
  GroupCompetitor,
  GroupCompetitorPayload,
  GroupCompetitorsPayload,
  Competition,
} from '@core/types';
import { createEmptySlots } from './use-slots';
import { Slot, Slots } from './types';
import TeamGridEdit from './team-grid-edit';

type Props = {
  competition: Competition;
  stage: Group;
};

export const getSummaryBlockValues = (
  { stage, competition }: Props,
  t: TFunction,
): SummaryBlockValues => [
  {
    label: t('Competition'),
    value: competition?.title,
  },
  {
    label: t('Season'),
    value: competition?.competitionSeasonTitle,
  },
  {
    label: t('Stage'),
    value: stage?.title,
  },
  {
    label: t('Stage type'),
    value: getStageSubHeader(t, stage?.stageType),
  },
  {
    label: t('Teams in stage'),
    value: stage?.numberOfTeams,
  },
];

export const isStageGroupCompetitorsSet = (
  stageCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors,
  childGroups: Groups,
): boolean => {
  const isExtraRound = (groupId: number) =>
    childGroups.find((childGroup) => childGroup.id === groupId)?.extraRound;

  const isGroupGroupCompetitorsSet = (
    groupCompetitors: Array<GroupCompetitor>,
  ): boolean =>
    // groupCompetitors is an empty array for round robin with no pools initially
    // and not empty array with group competitor elements in all other cases
    // extra rounds must be excluded
    groupCompetitors.length &&
    groupCompetitors
      .filter((groupCompetitor) => !isExtraRound(groupCompetitor.groupId))
      .every((groupCompetitor) => !!groupCompetitor.competitor);

  return (
    stageCompetitors &&
    (Array.isArray(stageCompetitors)
      ? isGroupGroupCompetitorsSet(stageCompetitors)
      : Object.values(stageCompetitors.childCompetitors).every(
          (childGroupCompetitors) =>
            isGroupGroupCompetitorsSet(childGroupCompetitors),
        ))
  );
};

const idTabValue = {
  groupIdToTabValue: (id: number): string => id.toString(),
  tabValueToGroupId: (tabValue: string): number => +tabValue,
};

export function useTabs(stage: Group) {
  const [tabs] = useLazyReinitializableState<TabsComponentProps['tabs']>(
    () =>
      stage?.childGroups.length >= 2
        ? stage.childGroups.map<TabsComponentProps['tabs'][number]>(
            (childGroup) => ({
              value: idTabValue.groupIdToTabValue(childGroup.id),
              component: TeamGridEdit,
              label: childGroup.title,
            }),
          )
        : null,
    [stage],
  );

  const [groupIdFromActiveTab, setGroupIdFromActiveTab] =
    useLazyReinitializableState(
      () => (tabs ? idTabValue.tabValueToGroupId(tabs[0].value) : null),
      [tabs],
    );

  const onTabChange: TabsComponentProps['onChange'] = (tab: string) => {
    setGroupIdFromActiveTab(idTabValue.tabValueToGroupId(tab));
  };

  const getGroupFromActiveTab = () =>
    stage.childGroups.find(({ id }) => id === groupIdFromActiveTab);

  return { getGroupFromActiveTab, groupIdFromActiveTab, onTabChange, tabs };
}

const mapGroupCompetitorsToSlots = (groupCompetitors: Array<GroupCompetitor>) =>
  groupCompetitors.map<Slot>((value) => ({ ...value, id: value.id as any }));

function fromGroupCompetitorResponseToSlotsByGroupIdDictionary(
  groupCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors,
  stageId: number,
  stageNumberOfTeams: number,
): Record<number, Slots> {
  const arrayOrDictionary = Array.isArray(groupCompetitors)
    ? groupCompetitors
    : groupCompetitors.childCompetitors;

  if (Array.isArray(arrayOrDictionary)) {
    return {
      [stageId]:
        arrayOrDictionary.length === 0
          ? createEmptySlots(stageNumberOfTeams)
          : mapGroupCompetitorsToSlots(arrayOrDictionary),
    };
  }

  return map<Record<number, Array<GroupCompetitor>>, Record<number, Slots>>(
    (value: Array<GroupCompetitor>): Slots => mapGroupCompetitorsToSlots(value),
    arrayOrDictionary,
  );
}

function fromSlotsByGroupIdDictionaryToPayload(
  slotsByGroupIdDictionary: Record<number, Slots>,
  groupCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors,
): GroupCompetitorsPayload {
  const groupCompetitorPayloadsByGroupIdDictionary = map<
    Record<number, Slots>,
    Record<number, Array<GroupCompetitorPayload>>
  >(
    (slots: Slots): Array<GroupCompetitorPayload> =>
      slots.map((slot, index) => ({
        competitorId: slot.competitor?.id ?? null,
        slotNumber: index + 1,
      })),
    slotsByGroupIdDictionary,
  );

  if (Array.isArray(groupCompetitors)) {
    const onlyGroupId = Object.keys(
      groupCompetitorPayloadsByGroupIdDictionary,
    )[0] as unknown as number;

    return {
      groupCompetitors: groupCompetitorPayloadsByGroupIdDictionary[onlyGroupId],
    };
  }

  return { childGroups: groupCompetitorPayloadsByGroupIdDictionary };
}

export function useSlotsByGroupIdDictionary(options: {
  groupCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors;
  activeGroupId: number;
  stageId: number;
  stageNumberOfTeams: number;
}) {
  const {
    groupCompetitors,
    activeGroupId: initialGroupId,
    stageId,
    stageNumberOfTeams,
  } = options;
  const [slotsByGroupIdDictionary, setSlotsDictionary] =
    useLazyReinitializableState<Record<number, Slots>>(() => {
      if (stageId && groupCompetitors) {
        return fromGroupCompetitorResponseToSlotsByGroupIdDictionary(
          groupCompetitors,
          stageId,
          stageNumberOfTeams,
        );
      }

      return null;
    }, [groupCompetitors, stageId, stageNumberOfTeams]);

  const updateSlotsByGroupIdDictionary = (slots: Slots) => {
    setSlotsDictionary({
      ...slotsByGroupIdDictionary,
      [initialGroupId]: slots,
    });
  };

  const getUpdateGroupCompetitorsPayload = () => {
    return fromSlotsByGroupIdDictionaryToPayload(
      slotsByGroupIdDictionary,
      groupCompetitors,
    );
  };

  return {
    getUpdateGroupCompetitorsPayload,
    slotsByGroupIdDictionary,
    updateSlotsByGroupIdDictionary,
  };
}
