import { useEffect, useState, useRef } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { find, sortBy, prop } from 'ramda';
import type { CalendarOptions } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
} from '@mui/material';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import ruLocale from '@fullcalendar/core/locales/ru';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import userProfileActions from '@core/pages/user-profile/store/actions';
import { getActiveTabIndex } from '@core/store/modules/ui/tabs/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  getWeekdayLong,
  getMonthShort,
  formatDate,
  formatTime,
  formatDateTime,
} from '@core/helpers';
import { useTabReactivated } from '@core/components';
import { FULL_SCREEN_SPINNER } from '@core/constants/test-ids';
import {
  FIXTURE_CALENDAR_DAY,
  FIXTURE_CALENDAR_ROOT,
} from '@core/pages/dashboard/tests/test-ids';
import { CustomerResponse, MatchFixture } from '@core/types';
import { actions as fixturesActions } from '@core/store/modules/api/fixtures';
import { getMatchesWithConflicts } from '@core/store/modules/api/fixtures/selectors';
import { State } from '@core/store';

import { rootStyles } from '../styles';
import { createCalendarEvent } from '../helpers';
import FixtureCalendarEvent from './fixture-calendar-event';
import FixtureCalendarList from './fixture-calendar-list';

interface DispatchProps {
  actions: {
    fixtures: typeof fixturesActions;
  };
}

interface StateProps {
  matches: Array<MatchFixture>;
  isLoading: boolean;
  activeTabIndex: number;
  currentCustomer: CustomerResponse;
}

type Props = DispatchProps & StateProps;

const getLocale = (language: string) => {
  switch (language) {
    case 'en-US':
      return enLocale;
    case 'es-ES':
      return esLocale;
    case 'ru-RU':
      return ruLocale;
    case 'fr-FR':
      return frLocale;
    default:
      return enLocale;
  }
};

const FixtureCalendar = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [activeRange, setActiveRange] = useState<{ start: Date; end: Date }>(
    null,
  );
  const [selectedDate, setSelectedDate] = useState<string>(null);
  const { actions, matches, isLoading, currentCustomer } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const classes = rootStyles({ isLoading });
  const calendarRef = useRef<FullCalendar>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const prevActiveRange = useRef(null);
  useEffect(() => {
    if (activeRange) {
      actions.fixtures.getFixtures({
        startDate: dayjs(activeRange.start).format('YYYY-MM-DD'),
        endDate: dayjs(activeRange.end).format('YYYY-MM-DD'),
      });

      if (prevActiveRange.current) {
        setSelectedDate(null);
      } else {
        setSelectedDate(dayjs().format('YYYY-MM-DD'));
      }

      prevActiveRange.current = activeRange;
    }
  }, [actions.fixtures, activeRange]);

  useTabReactivated(() => {
    actions.fixtures.getFixtures({
      startDate: dayjs(activeRange.start).format('YYYY-MM-DD'),
      endDate: dayjs(activeRange.end).format('YYYY-MM-DD'),
    });
  });

  const handleClickOnDate: CalendarOptions['dateClick'] = (event) => {
    setSelectedDate(event.dateStr);

    const eventsExist = find(
      ({ date }) => date === event.dateStr,
      matchesLocalized,
    );

    eventsExist && isMobile && handleScrollToBottom();
  };

  const handleDateSet: CalendarOptions['datesSet'] = (event) => {
    actions.fixtures.clearFixtures();
    actions.fixtures.clearConflicts();
    setActiveRange({
      start: event.start,
      end: event.end,
    });
  };

  const handleScrollToBottom = () => {
    anchorRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const renderDay: CalendarOptions['dayCellContent'] = (event) => {
    const eventDate = dayjs(event.date).format('YYYY-MM-DD');
    const isSelected = eventDate === selectedDate;
    const isFirstDayOfNextMonth =
      parseInt(event.dayNumberText, 10) === 1 &&
      event.isFuture &&
      !event.isToday &&
      !event.isPast;
    const labelText = isFirstDayOfNextMonth
      ? `${event.dayNumberText} ${getMonthShort(
          t,
          dayjs(event.date).format('MMM'),
        )}`
      : event.dayNumberText;

    return (
      <Grid data-qa={FIXTURE_CALENDAR_DAY} className={classes.dayLabel}>
        {isSelected && <Grid className={'fc-day-active'} />}
        <Grid className={'fc-day-number'}>{labelText}</Grid>
      </Grid>
    );
  };

  const renderEvent: CalendarOptions['eventContent'] = (event) => {
    const eventId = parseInt(event.event.id, 10);
    const eventData = find(({ id }) => id === eventId, matchesLocalized);

    return <FixtureCalendarEvent match={eventData} className={classes.event} />;
  };

  const matchesLocalized: Array<MatchFixture> = isLoading
    ? []
    : matches.map((match) => {
        const dateTime = `${match.date} ${match.time}`;

        return {
          ...match,
          dateTimeLocalized: formatDateTime(
            currentCustomer?.dateTimeFormat,
            dateTime,
          ),
          dateLocalized: formatDate(currentCustomer?.dateFormat, match.date),
          timeLocalized: formatTime(currentCustomer?.timeFormat, dateTime),
        };
      });

  const dayEvents: Array<MatchFixture> = sortBy(
    prop('time'),
    sortBy(
      prop('date'),
      matchesLocalized.filter(({ date }) => date === selectedDate),
    ),
  );

  const calendarEvents: CalendarOptions['events'] =
    matchesLocalized.map(createCalendarEvent);

  const listTitle =
    !!selectedDate &&
    `${formatDate(currentCustomer?.dateFormat, selectedDate)}, ${getWeekdayLong(
      t,
      dayjs(selectedDate).format('dddd'),
    )}`;

  return (
    <Grid className={classes.root} data-qa={FIXTURE_CALENDAR_ROOT}>
      {isLoading && <Box className={classes.calendarLoading} />}
      <Grid container className={classes.wrapper}>
        <Grid item className={classes.calendar}>
          {isLoading && (
            <Box className={classes.tableLoading}>
              <CircularProgress size={100} data-qa={FULL_SCREEN_SPINNER} />
            </Box>
          )}
          <FullCalendar
            contentHeight={'auto'}
            customButtons={{
              calendarTitle: {
                text: t('Upcoming matches'),
                click: null,
              },
            }}
            dateClick={handleClickOnDate}
            datesSet={handleDateSet}
            dayCellClassNames={classes.day}
            dayCellContent={renderDay}
            eventContent={renderEvent}
            events={calendarEvents}
            firstDay={1}
            fixedWeekCount={false}
            headerToolbar={{
              left: 'calendarTitle',
              center: 'prev,next',
              right: 'title',
            }}
            initialView="dayGridMonth"
            locale={getLocale(i18n?.language)}
            nextDayThreshold={'09:00:00'}
            plugins={[dayGridPlugin, interactionPlugin]}
            ref={calendarRef}
            viewClassNames={classes.view}
            windowResizeDelay={500}
          />
        </Grid>
        <Grid item className={classes.sidebar} ref={anchorRef}>
          <FixtureCalendarList
            isOnBottom={isMobile}
            matches={dayEvents}
            title={listTitle}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    fixtures: bindActionCreators(fixturesActions, dispatch),
  },
});

const getFixturesLoadingSelector = createLoadingSelector([
  userProfileActions.getCurrentCustomerRequest.toString(),
  fixturesActions.getFixturesRequest.toString(),
  fixturesActions.getAllConflictsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  matches: getMatchesWithConflicts(state),
  isLoading: getFixturesLoadingSelector(state),
  activeTabIndex: getActiveTabIndex(state),
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixtureCalendar);
