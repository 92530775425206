import { __, findIndex, keys, map, pipe, replace, test, values } from 'ramda';
import type { NavigationTabProps, NavigationsTabsMap } from '@core/types';

export const getTabData = (
  tab: NavigationTabProps,
  tabMapping: NavigationsTabsMap,
) => {
  const idToRegex = replace(':id', '[1-9]+');
  // @ts-ignore
  const matchTabName = test(__, tab.name);
  const createRegexp = (value: string): RegExp => new RegExp(`^${value}$`);
  // @ts-ignore
  const tabIndex = pipe(
    keys,
    map(idToRegex),
    // @ts-ignore
    map(createRegexp),
    findIndex(matchTabName),
    // @ts-ignore
  )(tabMapping);
  let data = values(tabMapping)[tabIndex as number];
  if (!data) {
    data = tabMapping[tab.name];
  }

  return tab.title ? { ...data, title: tab.title } : data;
};
