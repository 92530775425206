import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Theme,
} from '@mui/material';
import { find, propEq, map } from 'ramda';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';

import {
  ColumnWithSubValue,
  PaginationProps,
  TableWithAssign,
} from '@core/components';
import { TeamOfficial, TeamOfficialList } from '@core/types';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';

interface TeamOfficialWithStatus extends TeamOfficial {
  isAdded?: boolean;
}

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
  };
}

interface OwnProps {
  onPageChange: (page: number) => void;
  clubTeamOfficials: TeamOfficialList;
  clubTeamOfficialsPagination: PaginationProps;
  addedCompetitorTeamOfficials: TeamOfficialList;
  updateTeamOfficialsList: (teamOfficials: TeamOfficialList) => void;
  isLoading?: boolean;
  clubId: number;
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
  },
  gridContainer: {
    paddingRight: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
}));

const ClubTeamOfficialsTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    clubTeamOfficials,
    clubTeamOfficialsPagination,
    addedCompetitorTeamOfficials,
    updateTeamOfficialsList,
    onPageChange,
    isLoading,
    actions,
    clubId,
  } = props;

  const isClubTeamOfficialAdded = (TeamOfficialId: number) =>
    find(propEq('id', TeamOfficialId))(addedCompetitorTeamOfficials);

  const markAddedCompetitorTeamOfficials = (
    teamOfficials: TeamOfficialList,
  ): Array<TeamOfficialWithStatus> => {
    if (!teamOfficials) {
      return [];
    }

    return map((teamOfficial: TeamOfficial) => {
      if (!addedCompetitorTeamOfficials) {
        return teamOfficial;
      }

      if (isClubTeamOfficialAdded(teamOfficial.id)) {
        return { ...teamOfficial, isAdded: true };
      }

      return teamOfficial;
    }, teamOfficials);
  };

  const handleAddToList = (id: number) => {
    const officialToAdd = clubTeamOfficials.find(
      (official) => official.id === id,
    );

    updateTeamOfficialsList([...addedCompetitorTeamOfficials, officialToAdd]);
  };

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: TeamOfficialWithStatus) => (
        <ColumnWithSubValue
          subValue={
            rowData.firstName && `${rowData.firstName} ${rowData.familyName}`
          }
          value={
            rowData.localFirstName &&
            `${rowData.localFirstName} ${rowData.localFamilyName}`
          }
          isDisabled={rowData.isAdded}
        />
      ),
    },
    {
      name: 'role',
      label: t('Role'),
      render: (rowData: any) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {t(rowData?.teamOfficialRole?.title)}
        </Typography>
      ),
    },
    {
      name: 'added',
      label: '',
      render: (rowData: TeamOfficialWithStatus) => (
        <Typography
          variant="body2"
          color={rowData.isAdded ? 'textSecondary' : 'textPrimary'}
        >
          {rowData.isAdded ? t('added') : ' '}
        </Typography>
      ),
    },
  ];

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    actions.playerListManagement.resetClubTeamOfficials({ clubId });
    actions.playerListManagement.searchClubTeamOfficials({
      clubId,
      queryParams: getSearchClubTeamOfficialsQueryParams({ clubId, val }),
    });
  };

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Available team officials')} (${
            clubTeamOfficialsPagination?.total || 0
          })`}
        </Typography>
      </Grid>
      <Grid className={classes.gridContainer} item xs={12} sm={6}>
        <TextField
          classes={{
            root: classes.root,
          }}
          placeholder={t('Search')}
          id="searchForTeamOfficials"
          onChange={onInputChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon classes={{ root: classes.searchIcon }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            infiniteScroll={{
              loadNext: onPageChange,
              pagination: clubTeamOfficialsPagination,
            }}
            columns={columns}
            maxHeight={496}
            data={markAddedCompetitorTeamOfficials(clubTeamOfficials)}
            isLoading={isLoading}
            handleAdd={handleAddToList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
  },
});

export default connect(null, mapDispatchToProps)(ClubTeamOfficialsTable);

export function getSearchClubTeamOfficialsQueryParams(options: {
  clubId: number;
  page?: number;
  val?: string;
}): URLSearchParams {
  const { clubId, page, val } = options;

  return new URLSearchParams(
    [
      ['filter[club][]', String(clubId)],
      (!!page || page === 0) && ['page', String(page)],
      (!!val || val === '') && ['query', val],
    ].filter(Boolean),
  );
}
