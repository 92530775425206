import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ReportProblem, ReportProblemOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Club } from '@core/icons';
import { Logo } from '@core/components';
import { usePopperToggle } from '@core/hooks';
import {
  FIXTURE_CALENDAR_LIST_ITEM,
  FIXTURE_CALENDAR_LIST_ITEM_WRAPPER,
} from '@core/pages/dashboard/tests/test-ids';
import {
  LogoSize,
  MatchStatus,
  ConflictSeverityType,
  MatchFixture,
} from '@core/types';
import { getCompetitorTitleFactory } from '@core/components/match-table';
import { getConflictLevel } from '@core/components/fixture-calendar/helpers';

import { listItemStyles } from '../styles';
import FixtureCalendarPopup from './fixture-calendar-popup';

interface OwnProps {
  isOnBottom: boolean;
  match: MatchFixture;
}

type Props = OwnProps;

const FixtureCalendarListItem = (props: Props) => {
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();
  const { isOnBottom, match } = props;
  const {
    id,
    timeLocalized,
    conflicts,
    homeCompetitorLogoLink,
    awayCompetitorLogoLink,
    status,
  } = match;
  const conflictLevel = getConflictLevel(conflicts);
  const classes = listItemStyles({
    conflictLevel,
    selected: isOpen,
    isCompleted: status === MatchStatus.COMPLETE,
  });

  const showPopup = handleToggle;
  const closePopup = handleClose;

  const { t } = useTranslation();
  const getCompetitorTitle = getCompetitorTitleFactory(t);
  const teams = [
    {
      title: getCompetitorTitle(match, 'home'),
      clubLogoFileLink: homeCompetitorLogoLink,
    },
    {
      title: getCompetitorTitle(match, 'away'),
      clubLogoFileLink: awayCompetitorLogoLink,
    },
  ];

  return (
    <Grid
      data-qa={FIXTURE_CALENDAR_LIST_ITEM_WRAPPER}
      className={classes.wrapper}
    >
      <Grid
        data-list-id={id}
        data-qa={FIXTURE_CALENDAR_LIST_ITEM}
        className={classes.root}
        ref={anchorRef}
        onClick={showPopup}
      >
        <Grid className={classes.item} container>
          <Box className={classes.indicator} />
          <Box className={classes.warning}>
            {conflictLevel > ConflictSeverityType.MODERATE ? (
              <ReportProblem className={classes.warningIcon} />
            ) : (
              <ReportProblemOutlined className={classes.warningIcon} />
            )}
          </Box>
          <Grid item>
            <Typography className={classes.time} variant={'body1'}>
              {timeLocalized}
            </Typography>
          </Grid>
          {teams.map((team, index) => (
            <Grid className={classes.team} key={index} item>
              <Logo
                className={classes.teamLogo}
                size={LogoSize.EXTRASMALL}
                src={team.clubLogoFileLink}
              >
                <Club className={classes.teamLogoPlaceholder} />
              </Logo>
              <Typography className={classes.teamName} variant={'body2'}>
                {team.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {anchorRef?.current && (
        <FixtureCalendarPopup
          data={match}
          open={isOpen}
          anchorEl={anchorRef.current}
          placement={isOnBottom ? 'bottom' : 'left-start'}
          handleClose={closePopup}
        />
      )}
    </Grid>
  );
};

export default FixtureCalendarListItem;
