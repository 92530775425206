import { path, pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { Paginated, Filters, Sorting, MatchModel } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

const getAppointmentsUnsorted = (state: State): { [id: string]: MatchModel } =>
  pathOr(null, ['api', 'appointmentsList'], state);
const getAppointmentsIds = (
  state: State,
): Paginated<MatchModel['id']>['list'] =>
  pathOr(null, ['pages', NAMESPACE, 'appointments', 'list'], state);

export const getAppointmentsList = createSelector(
  getAppointmentsUnsorted,
  getAppointmentsIds,
  (appointmentsUnsorted, appointmentsIds) =>
    appointmentsIds
      ? appointmentsIds.map((id: number) => appointmentsUnsorted[id])
      : [],
);

export const getAppointments = (state: State): Paginated<MatchModel['id']> =>
  path(['pages', NAMESPACE, 'appointments'], state);

export const getSearchQuery = (state: State): string =>
  path(['pages', NAMESPACE, 'searchQuery'], state);

export const getFilters = (state: State): Filters =>
  path(['pages', NAMESPACE, 'filters'], state);

export const getSorting = (state: State): Sorting =>
  path(['pages', NAMESPACE, 'sorting'], state);
