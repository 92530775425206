import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FilterModel, Filters, FilterTabsList } from '@core/types';
import { State } from '@core/store';
import { actions as filterActions } from '@core/store/modules/ui/filter';
import { getFiltersList } from '@core/store/modules/ui/filter/selectors';
import { Tabs, TabPanel } from '@core/components';

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    backgroundColor: 'whitesmoke',
  },
  tab: {
    minWidth: 79,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  selectedTab: {
    backgroundColor: 'white !important',
  },
  tabPanel: {
    overflow: 'auto',
    height: '440px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 120px)',
      overflow: 'auto',
    },
    marginTop: 0,
  },
}));

type OwnProps = {
  tabsList: FilterTabsList;
};

type DispatchProps = {
  actions: {
    filter: typeof filterActions;
  };
};

type StateProps = {
  filters: Filters;
};

type Props = OwnProps & DispatchProps & StateProps;

const FilterTabs = (props: Props) => {
  const { tabsList, actions, filters } = props;
  const classes = useStyles();

  const handleFilterAdd = (filter: FilterModel) =>
    actions.filter.addFilter(filter);

  const handleFilterRemove = (filter: FilterModel) =>
    actions.filter.removeFilter(filter);

  const handleFilterReplace = (filter: FilterModel) =>
    actions.filter.replaceFilter(filter);

  const getFilterCount = (filterNames: ReadonlyArray<string> = []) => {
    return filterNames.reduce((totalCount, filterName) => {
      const currentCount = filters?.[filterName]?.filterValues?.length;

      return currentCount ? totalCount + currentCount : totalCount;
    }, 0);
  };

  const tabsListWithCount = tabsList.map((tab) => ({
    ...tab,
    badge: getFilterCount(tab.filterIds),
  }));

  /*
    initialQueryParams?: {
    'filter[candidatesForCompetition]'?: number;
  };
   */

  return (
    <Tabs
      tabs={tabsListWithCount}
      styles={{
        root: classes.rootTabs,
        selectedTab: classes.selectedTab,
        tab: classes.tab,
      }}
    >
      {(currentTab) => (
        <TabPanel
          classes={{ root: classes.tabPanel }}
          currentTab={currentTab}
          tabs={tabsList}
          props={{
            handleFilterAdd,
            handleFilterRemove,
            handleFilterReplace,
            filters,
          }}
        />
      )}
    </Tabs>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  filters: getFiltersList(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    filter: bindActionCreators(filterActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTabs);
