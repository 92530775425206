import { take, all, call, put, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as competitionTypesAPI from '@core/api/competition-types';

import * as ACTION_TYPES from './constants';
import * as competitionTypesActions from './actions';

export function* getCompetitionTypes(): Generator<Effect, any, any> {
  yield put(competitionTypesActions.getCompetitionTypesRequest());
  const response = yield call(competitionTypesAPI.getCompetitionTypes);

  if (!response.error) {
    yield put(competitionTypesActions.getCompetitionTypesSuccess());
  } else {
    yield put(competitionTypesActions.getCompetitionTypesFailure());
  }

  return response;
}

export function* getCompetitionTypesFlow() {
  while (true) {
    yield take(ACTION_TYPES.GET_COMPETITION_TYPES);
    const { error, response } = yield call(getCompetitionTypes);

    if (!error) {
      const competitionTypes = response._embedded.competitionTypes;

      yield put(competitionTypesActions.setCompetitionTypes(competitionTypes));
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([getCompetitionTypesFlow()]);
}
