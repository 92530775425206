import { handleActions } from 'redux-actions';

import { appendToPaginatedListFactory } from '@core/helpers';
import { Paginated, TeamModel } from '@core/types';

import actions from './actions';

export type DefaultState = {
  teams: Paginated<TeamModel>;
};

type Payload = any;

export const defaultState: DefaultState = {
  teams: null,
};

const appendToTeamsList = appendToPaginatedListFactory<DefaultState>('teams');

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setTeams.toString()]: (state, { payload }) =>
      appendToTeamsList(state, payload),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
