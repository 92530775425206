import React, { useState } from 'react';
import { Grid, Button, Typography, Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { uuid } from '@core/helpers';
import clsx from 'clsx';

import * as testIds from './tests/test-ids';

interface OwnProps {
  label?: string;
  accept?: string;
  name?: string;
  buttonComponent?: any;
  onInputChange?: (value: any) => void;
  withSelectedFileNaming?: boolean;
  fullWidth?: boolean;
  files: Array<File>;
  setSelectedFile: (files: Array<File>) => void;
}

type Props = OwnProps;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  fileLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidthLabel: {
    width: '100%',
  },
}));

export const FileUploadButton = (props: Props) => {
  const {
    label,
    buttonComponent: ButtonComponent,
    withSelectedFileNaming = true,
    fullWidth,
    files,
    setSelectedFile,
    ...inputProps
  } = props;
  const classes = useStyles();
  const [inputKey, setInputKey] = useState(uuid());
  const { t } = useTranslation();

  function handleFileClear() {
    setInputKey(uuid()); // setting different key value will force input to rerender
    setSelectedFile([]);
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();
    const selectedFiles = event.target.files;
    setSelectedFile(Array.from(selectedFiles));
  }

  function renderImportButton() {
    const labelClasses = clsx({ [classes.fullWidthLabel]: fullWidth });

    return (
      <label className={labelClasses} htmlFor="file">
        {ButtonComponent ? (
          <ButtonComponent component="span" />
        ) : (
          <Button fullWidth={fullWidth} variant="outlined" component="span">
            {t('Browse')}
          </Button>
        )}
      </label>
    );
  }

  function renderSelectedFileNames() {
    return (
      withSelectedFileNaming && (
        <>
          <Box ml={2}>
            {files.map((file) => (
              <Grid
                key={file.name}
                className={classes.fileLabel}
                data-qa={testIds.SELECTED_FILE}
              >
                <Typography variant="caption">{file.name}</Typography>
              </Grid>
            ))}
          </Box>
          {files.length > 0 && (
            <IconButton
              size="small"
              onClick={handleFileClear}
              data-qa={testIds.REMOVE_SELECTED_FILES}
            >
              <Close />
            </IconButton>
          )}
        </>
      )
    );
  }

  return (
    <Grid className={classes.root}>
      {label && (
        <Box mr={2}>
          <Typography>{label}</Typography>
        </Box>
      )}
      <input
        onChange={handleFileUpload}
        type="file"
        name="file"
        id="file"
        className={classes.fileInput}
        key={inputKey}
        data-qa={testIds.FILE_UPLOAD_INPUT}
        {...inputProps}
      />
      {renderImportButton()}
      {renderSelectedFileNames()}
    </Grid>
  );
};

export default FileUploadButton;
