import { createActions } from 'redux-actions';

const NAMESPACE = 'matchReports';

export const GET_MATCH_REPORT = 'GET_MATCH_REPORT';
export const GET_MATCH_REPORT_REQUEST = 'GET_MATCH_REPORT_REQUEST';
export const GET_MATCH_REPORT_SUCCESS = 'GET_MATCH_REPORT_SUCCESS';
export const GET_MATCH_REPORT_FAILURE = 'GET_MATCH_REPORT_FAILURE';

export const actions = createActions(
  GET_MATCH_REPORT,
  GET_MATCH_REPORT_REQUEST,
  GET_MATCH_REPORT_SUCCESS,
  GET_MATCH_REPORT_FAILURE,
  { prefix: NAMESPACE },
) as unknown as {
  getMatchReport: UnknownAction;
  getMatchReportRequest: RequestLifecycleAction;
  getMatchReportSuccess: RequestLifecycleAction;
  getMatchReportFailure: RequestLifecycleAction;
};
