import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, CircularProgress } from '@mui/material';
import { withFullScreenLayout } from '@core/components/layout';
import * as authActions from '@core/pages/login/store/actions';
import { useAppDispatch } from '@volleyball/store/hooks';

export const LandingPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.authorize());
  }, [dispatch]);

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h4">{t('Authenticating')}...</Typography>
      </Grid>
      <Grid item>
        <Box margin={2}>
          <CircularProgress />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withFullScreenLayout(LandingPage);
