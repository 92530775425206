import { createActions } from 'redux-actions';

export const NAMESPACE = 'competitionMatchOfficialRoles';

export const actions = createActions(
  'GET_COMPETITION_MATCH_OFFICIAL_ROLES',
  'GET_COMPETITION_MATCH_OFFICIAL_ROLES_REQUEST',
  'GET_COMPETITION_MATCH_OFFICIAL_ROLES_SUCCESS',
  'GET_COMPETITION_MATCH_OFFICIAL_ROLES_FAILURE',
  'SET_COMPETITION_MATCH_OFFICIAL_ROLES',
  { prefix: NAMESPACE },
) as unknown as {
  getCompetitionMatchOfficialRoles: UnknownAction;
  getCompetitionMatchOfficialRolesRequest: RequestLifecycleAction;
  getCompetitionMatchOfficialRolesSuccess: RequestLifecycleAction;
  getCompetitionMatchOfficialRolesFailure: RequestLifecycleAction;
  setCompetitionMatchOfficialRoles: UnknownAction;
};
