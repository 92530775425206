import { createAction } from 'redux-actions';

import {
  GenerateGroupFixtureSettings,
  GroupFixtureSettings,
  APIErrorsModel,
} from '@volleyball/types';

import { NAMESPACE } from './constants';

interface RegenerateFixturesPayload {
  link: string;
  data: GenerateGroupFixtureSettings;
  tabId: string;
  competitionId: number;
  groupId: number;
}

interface SetGroupFixturesSettingsPayload {
  groupId: number;
  data: GroupFixtureSettings;
}

interface SetErrorsPayload {
  groupId: number;
  data: APIErrorsModel;
}

const regenerateFixtures = createAction<RegenerateFixturesPayload>(
  `${NAMESPACE}/REGENERATE_FIXTURES`,
);
const regenerateFixturesRequest = createAction<void>(
  `${NAMESPACE}/REGENERATE_FIXTURES_REQUEST`,
);
const regenerateFixturesSuccess = createAction<void>(
  `${NAMESPACE}/REGENERATE_FIXTURES_SUCCESS`,
);
const regenerateFixturesFailure = createAction<void>(
  `${NAMESPACE}/REGENERATE_FIXTURES_FAILURE`,
);

const getGroupFixturesSettings = createAction<number>(
  `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS`,
);
const getGroupFixturesSettingsRequest = createAction<void>(
  `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_REQUEST`,
);
const getGroupFixturesSettingsSuccess = createAction<void>(
  `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_SUCCESS`,
);
const getGroupFixturesSettingsFailure = createAction<void>(
  `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_FAILURE`,
);
const setGroupFixturesSettings = createAction<SetGroupFixturesSettingsPayload>(
  `${NAMESPACE}/SET_GROUP_FIXTURE_SETTINGS`,
);

const resetGroupFixtureSettings = createAction<number>(
  `${NAMESPACE}/RESET_GROUP_FIXTURE_SETTINGS`,
);
const setApiErrors = createAction<SetErrorsPayload>(`${NAMESPACE}/SET_ERRORS`);

export default {
  regenerateFixtures,
  regenerateFixturesRequest,
  regenerateFixturesSuccess,
  regenerateFixturesFailure,

  getGroupFixturesSettings,
  getGroupFixturesSettingsRequest,
  getGroupFixturesSettingsSuccess,
  getGroupFixturesSettingsFailure,
  setGroupFixturesSettings,

  resetGroupFixtureSettings,
  setApiErrors,
};
