import { createFilterId } from '@core/helpers';
import { FilteringOperations } from '@core/types';

export const MATCH_DATE_FROM_FILTER = {
  fieldName: 'date',
  filteringOperation: FilteringOperations.greaterThanOrEqual,
};

export const MATCH_DATE_FROM_FILTER_ID = createFilterId(MATCH_DATE_FROM_FILTER);

export const MATCH_DATE_TO_FILTER = {
  fieldName: 'date',
  filteringOperation: FilteringOperations.lessThanOrEqual,
};

export const MATCH_DATE_TO_FILTER_ID = createFilterId(MATCH_DATE_TO_FILTER);

export const MATCH_DAY_FROM_FILTER = {
  fieldName: 'matchDayNumber',
  filteringOperation: FilteringOperations.greaterThanOrEqual,
};

export const MATCH_DAY_FROM_FILTER_ID = createFilterId(MATCH_DAY_FROM_FILTER);

export const MATCH_DAY_TO_FILTER = {
  fieldName: 'matchDayNumber',
  filteringOperation: FilteringOperations.lessThanOrEqual,
};

export const MATCH_DAY_TO_FILTER_ID = createFilterId(MATCH_DAY_TO_FILTER);

export const MATCH_STATUS_FILTER = {
  fieldName: 'status',
  filteringOperation: FilteringOperations.equal,
};

export const MATCH_STATUS_FILTER_ID = createFilterId(MATCH_STATUS_FILTER);
