import { TFunction } from 'i18next';

import { SportsList } from '@core/types';
import { getSports } from '@core/helpers';

export const formatSportOptions = (t: TFunction, sports: SportsList) => {
  if (!sports) {
    return [];
  }

  return sports?.map((sport) => ({
    label: getSports(t, sport.type),
    value: sport.id,
  }));
};
