import React from 'react';
import { connect } from 'react-redux';

import {
  AddedMatchTeamOfficialList,
  MatchCompetitorsResponse,
  MatchPlayerOrigin,
  MatchModel,
  MatchPlayersList,
} from '@core/types';
import * as matchProfileSelectors from '@core/store/modules/tabs/match-profile/selectors';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { State } from '@core/store';

import MatchLineupTemplate, { MatchProps } from './match-lineup-template';

interface StateProps {
  homePlayers: MatchPlayersList;
  homeTeamOfficials: AddedMatchTeamOfficialList;
  match: MatchModel;
  matchCompetitors: MatchCompetitorsResponse;
}

interface OwnProps {
  matchId: number;
  canManage: boolean;
}

type Props = OwnProps & StateProps;

const matchPlayerOrigin = MatchPlayerOrigin.HOME;

const HomeCompetitorTab = (props: Props) => {
  const {
    canManage: canManageMatch,
    match,
    matchCompetitors,
    matchId,
    homePlayers,
    homeTeamOfficials,
  } = props;

  const competitorId = match?.homeCompetitorId;
  const clubId = match?._embedded?.homeCompetitor?.clubId;
  const competitorTitle = match?.homeCompetitorTitle || '-';
  const competitorInternationalTitle =
    match?.homeCompetitorInternationalTitle || '';
  const competitorLogoFileLink =
    match?._embedded?.homeCompetitor?.logoFileLink ||
    match?._embedded?.homeCompetitor?.clubLogoFileLink;
  const canEditMatch = !!match?._links?.patch;

  const matchProps: MatchProps = {
    competitorTitle,
    competitorInternationalTitle,
    competitorLogoFileLink,
    competitorId,
    matchId,
    clubId,
    matchPlayerOrigin,
    canEditMatch,
    canManageMatch,
  };

  const awayMatchCompetitor = matchCompetitors?._embedded.homeMatchCompetitor;

  return (
    <MatchLineupTemplate
      matchCompetitor={awayMatchCompetitor}
      matchProps={matchProps}
      playersList={homePlayers}
      teamOfficialsList={homeTeamOfficials}
    />
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;

  return {
    homePlayers: matchProfileSelectors.getMatchPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    homeTeamOfficials:
      matchProfileSelectors.getHomeTeamOfficials(matchId)(state),
    match: getMatchById(state, matchId),
    matchCompetitors: matchProfileSelectors.getMatchCompetitorsByMatchId(
      state,
      { matchId },
    ),
  };
};

export default connect(mapStateToProps, null)(HomeCompetitorTab);
