import { createActions } from 'redux-actions';
import { NAMESPACE } from './constants';

export const actions = createActions(
  {
    SET_USERS: [
      ({ usersResponse }) => usersResponse,
      ({ origin }) => ({ origin }),
    ],
  },
  'GET_USERS',
  'GET_USERS_REQUEST',
  'GET_USERS_SUCCESS',
  'GET_USERS_FAILURE',
  'GET_ASSIGNED_COMPETITIONS',
  'GET_ASSIGNED_COMPETITIONS_REQUEST',
  'GET_ASSIGNED_COMPETITIONS_SUCCESS',
  'GET_ASSIGNED_COMPETITIONS_FAILURE',
  'GET_COMPETITION_ASSIGNED_PERSONS',
  'GET_COMPETITION_ASSIGNED_PERSONS_REQUEST',
  'GET_COMPETITION_ASSIGNED_PERSONS_SUCCESS',
  'GET_COMPETITION_ASSIGNED_PERSONS_FAILURE',
  'SET_COMPETITIONS_BY_ID',
  'SET_ASSIGNED_COMPETITIONS_PAGINATION',
  'SET_COMPETITION_ASSIGNED_PERSONS',
  'GET_ALL_COMPETITIONS',
  'GET_ALL_COMPETITIONS_REQUEST',
  'GET_ALL_COMPETITIONS_SUCCESS',
  'GET_ALL_COMPETITIONS_FAILURE',
  'SET_ALL_COMPETITIONS',
  'SET_ALL_COMPETITIONS_PAGINATION',
  'ASSIGN_REGIONAL_COMPETITION_MANAGER',
  'ASSIGN_REGIONAL_COMPETITION_MANAGER_REQUEST',
  'ASSIGN_REGIONAL_COMPETITION_MANAGER_SUCCESS',
  'ASSIGN_REGIONAL_COMPETITION_MANAGER_FAILURE',
  'UNASSIGN_REGIONAL_COMPETITION_MANAGER',
  'UNASSIGN_REGIONAL_COMPETITION_MANAGER_REQUEST',
  'UNASSIGN_REGIONAL_COMPETITION_MANAGER_SUCCESS',
  'UNASSIGN_REGIONAL_COMPETITION_MANAGER_FAILURE',
  'RESET_STATE',
  {
    prefix: NAMESPACE,
  },
);
