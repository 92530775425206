import React from 'react';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TableWithCRUD } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { withLayout } from '@core/components/layout';
import { AgeGroup, AgeGroupsList, LayoutProps } from '@core/types';
import { APIErrorsModel } from '@volleyball/types';
import * as refereeCategoriesActions from '@volleyball/store/modules/pages/referee-categories/actions';
import {
  CREATE_REFEREE_CATEGORY_REQUEST,
  UPDATE_REFEREE_CATEGORY_REQUEST,
  DELETE_REFEREE_CATEGORY_REQUEST,
  GET_REFEREE_CATEGORIES_REQUEST,
} from '@volleyball/store/modules/pages/referee-categories/constants';
import {
  getRefereeCategoriesList,
  getUpdatingRefereeCategory,
  isNewRefereeCategoryBeingCreated,
  getRefereeCategoriesErrors,
} from '@volleyball/store/modules/pages/referee-categories/selectors';
import { State } from '@volleyball/store';

import RefereeCategoriesTable from './components/referee-categories-table';
import RefereeCategoryForm from './components/referee-category-form';
import { REFEREE_CATEGORIES_FIELDS } from './constants';

interface StateProps {
  isFormSubmitting: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  rows: AgeGroupsList;
  beingModified: AgeGroup;
  isNewBeingCreated: boolean;
  errors: APIErrorsModel;
}

interface DispatchProps {
  actions: {
    refereeCategories: typeof refereeCategoriesActions;
  };
}

type Props = StateProps & DispatchProps;

const RefereeCategoriesPage = ({ actions, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <TableWithCRUD
      {...rest}
      title={t('Referee categories')}
      buttonLabel={t('Create referee category')}
      tableComponent={RefereeCategoriesTable}
      formComponent={RefereeCategoryForm}
      columns={REFEREE_CATEGORIES_FIELDS}
      actions={{
        setBeingModified: actions.refereeCategories.setBeingModified,
        getList: actions.refereeCategories.getRefereeCategories,
        setNewBeingCreated: actions.refereeCategories.setNewBeingCreated,
        deleteItem: actions.refereeCategories.deleteRefereeCategory,
        updateItem: actions.refereeCategories.updateRefereeCategory,
        createItem: actions.refereeCategories.createRefereeCategory,
        setErrors: actions.refereeCategories.setErrors,
      }}
    />
  );
};

const isFormSubmittingSelector = createLoadingSelector([
  CREATE_REFEREE_CATEGORY_REQUEST,
  UPDATE_REFEREE_CATEGORY_REQUEST,
]);

const isDeletingSelector = createLoadingSelector([
  DELETE_REFEREE_CATEGORY_REQUEST,
]);

const isLoadingSelector = createLoadingSelector([
  GET_REFEREE_CATEGORIES_REQUEST,
]);

const mapStateToProps = (state: State): StateProps => ({
  isFormSubmitting: isFormSubmittingSelector(state),
  isDeleting: isDeletingSelector(state),
  isLoading: isLoadingSelector(state),
  rows: getRefereeCategoriesList(state),
  beingModified: getUpdatingRefereeCategory(state),
  isNewBeingCreated: isNewRefereeCategoryBeingCreated(state),
  errors: getRefereeCategoriesErrors(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    refereeCategories: bindActionCreators(refereeCategoriesActions, dispatch),
  },
});

export default compose<React.FunctionComponent<LayoutProps>>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(RefereeCategoriesPage);
