import React from 'react';
import { Box, Button, Grid, Paper, useMediaQuery, Theme } from '@mui/material';
import { AddCircleTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FullScreenSpinner, GridItems } from '@core/components';
import { Competitor, Group, ModalTypes } from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import SlotsTable from './slots-table';
import { UseSlots } from './use-slots';
import { OwnProps as MultiteamSelectModalProps } from './components/team-grid-multiteam-select-modal';
import { TEAM_GRID_POPULATE_SLOTS_BUTTON } from './tests/test-ids';

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
  };
}

export interface OwnProps {
  competitionId: number;
  group: Group;
  isGroupCompetitorsLoading: boolean;
  isTeamGridEditable: boolean;
  slotsStateWithUpdaters: ReturnType<UseSlots>;
}

type Props = DispatchProps & OwnProps;

const TeamGridEdit = (props: Props) => {
  const {
    actions,
    competitionId,
    group,
    isGroupCompetitorsLoading,
    isTeamGridEditable,
    slotsStateWithUpdaters,
  } = props;
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const { slots, setSlots } = slotsStateWithUpdaters;

  const applySelectedCompetitors = (competitors: Array<Competitor>) => {
    const readOnlyCompetitorIds = slots
      .filter((slot) => !slot.editable && slot.competitor)
      .map((slot) => slot.competitor.id);
    const _competitors = competitors.filter(
      (competitor) => !readOnlyCompetitorIds.includes(competitor.id),
    );

    setSlots(
      slots.map((slot, index) =>
        slot.editable
          ? {
              ...slot,
              competitor: _competitors.shift(),
            }
          : slot,
      ),
    );
  };

  const openMultiteamSelectModal = () => {
    actions.globalModal.openModal({
      type: ModalTypes.TEAM_GRID_MULTITEAM_SELECT_MODAL,
      params: {
        applyAction: applySelectedCompetitors,
        competitionId,
        competitorsLimit: group?.numberOfTeams,
        groupTitle: group.title,
        slots,
      } as MultiteamSelectModalProps,
    });
  };

  return (
    <Grid container spacing={0} direction="column">
      <GridItems xs>
        <Paper elevation={0}>
          <Box pt={3} pb={1.5}>
            <Grid container spacing={3} direction="column">
              <GridItems xs>
                <Grid
                  container
                  justifyContent="right"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12} sm="auto">
                    <Button
                      onClick={openMultiteamSelectModal}
                      variant="outlined"
                      startIcon={<AddCircleTwoTone color={'primary'} />}
                      fullWidth={isSmallScreen}
                      disabled={
                        isGroupCompetitorsLoading || !isTeamGridEditable
                      }
                      data-qa={TEAM_GRID_POPULATE_SLOTS_BUTTON}
                    >
                      {t('Populate Slots')}
                    </Button>
                  </Grid>
                </Grid>
              </GridItems>
            </Grid>
          </Box>
          <Box pt={1.5} pb={3}>
            {isGroupCompetitorsLoading ? (
              <FullScreenSpinner />
            ) : (
              <SlotsTable
                isEditable={isTeamGridEditable}
                state={slotsStateWithUpdaters}
                competitionId={competitionId}
              />
            )}
          </Box>
        </Paper>
      </GridItems>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(TeamGridEdit);
