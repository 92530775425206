import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

export const NAMESPACE = 'matchSheetManagementTab';

export const actions = createActions(
  {
    SET_MATCH_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    RESET_MATCH_SHEET_MANAGEMENT: [
      undefined,
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_LINEUP_COMPETITOR_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_LINEUP_ADDED_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin', 'tabId']),
    ],
    RESET_LINEUP_COMPETITOR_PLAYERS: [
      undefined,
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_SUBSTITUTE_COMPETITOR_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_SUBSTITUTE_ADDED_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin', 'tabId']),
    ],
    RESET_SUBSTITUTE_COMPETITOR_PLAYERS: [
      undefined,
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_ADDED_LINEUP_PLAYER_DATA_ERRORS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_TEAM_OFFICIALS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_ADDED_TEAM_OFFICIALS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_COMPETITOR_TEAM_OFFICIALS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_MATCH: [prop('payload'), pick(['matchId', 'matchPlayerOrigin'])],
    SET_PREVIOUS_MATCH_PLAYERS_TOTAL: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    RESET_PREVIOUS_MATCH_PLAYERS_TOTAL: [
      undefined,
      pick(['matchId', 'matchPlayerOrigin']),
    ],

    SET_MATCH_SHEET_HISTORY: [
      prop('payload'),
      pick(['matchId', 'competitorId']),
    ],

    RESET_MATCH_SHEET_HISTORY: [undefined, pick(['matchId'])],
  },
  'GET_MATCH_PLAYERS',
  'GET_MATCH_PLAYERS_REQUEST',
  'GET_MATCH_PLAYERS_SUCCESS',
  'GET_MATCH_PLAYERS_FAILURE',

  'GET_PREVIOUS_MATCH_PLAYERS',
  'GET_PREVIOUS_MATCH_PLAYERS_REQUEST',
  'GET_PREVIOUS_MATCH_PLAYERS_SUCCESS',
  'GET_PREVIOUS_MATCH_PLAYERS_FAILURE',

  'SEARCH_COMPETITOR_PLAYERS',
  'SEARCH_COMPETITOR_PLAYERS_REQUEST',
  'SEARCH_COMPETITOR_PLAYERS_SUCCESS',
  'SEARCH_COMPETITOR_PLAYERS_FAILURE',

  'CHANGE_LINEUP_ADDED_PLAYERS_ROLE',

  'SUBMIT_MATCH_SHEET',
  'SUBMIT_MATCH_SHEET_REQUEST',
  'SUBMIT_MATCH_SHEET_SUCCESS',
  'SUBMIT_MATCH_SHEET_FAILURE',

  'GET_MATCH_TEAM_OFFICIALS',
  'GET_MATCH_TEAM_OFFICIALS_REQUEST',
  'GET_MATCH_TEAM_OFFICIALS_SUCCESS',
  'GET_MATCH_TEAM_OFFICIALS_FAILURE',

  'SEARCH_COMPETITOR_TEAM_OFFICIALS',
  'SEARCH_COMPETITOR_TEAM_OFFICIALS_REQUEST',
  'SEARCH_COMPETITOR_TEAM_OFFICIALS_SUCCESS',
  'SEARCH_COMPETITOR_TEAM_OFFICIALS_FAILURE',

  'GET_MATCH',
  'GET_MATCH_REQUEST',
  'GET_MATCH_SUCCESS',
  'GET_MATCH_FAILURE',

  'GET_MATCH_SHEET_HISTORY',
  'GET_MATCH_SHEET_HISTORY_REQUEST',
  'GET_MATCH_SHEET_HISTORY_SUCCESS',
  'GET_MATCH_SHEET_HISTORY_FAILURE',

  { prefix: NAMESPACE },
);
