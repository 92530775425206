export const NAMESPACE = 'files';

export const INITIALIZE_FILE_STORE = `${NAMESPACE}/INITIALIZE_FILE_STORE`;
export const SET_FILE_LIST = `${NAMESPACE}/SET_FILE_LIST`;

export const GET_FILES = `${NAMESPACE}/GET_FILES`;
export const GET_FILES_REQUEST = `${NAMESPACE}/GET_FILES_REQUEST`;
export const GET_FILES_SUCCESS = `${NAMESPACE}/GET_FILES_SUCCESS`;
export const GET_FILES_FAILURE = `${NAMESPACE}/GET_FILES_FAILURE`;

export const GET_FILE = `${NAMESPACE}/GET_FILE`;
export const GET_FILE_REQUEST = `${NAMESPACE}/GET_FILE_REQUEST`;
export const GET_FILE_SUCCESS = `${NAMESPACE}/GET_FILE_SUCCESS`;
export const GET_FILE_FAILURE = `${NAMESPACE}/GET_FILE_FAILURE`;

export const DELETE_FILE = `${NAMESPACE}/DELETE_FILE`;
export const DELETE_FILE_REQUEST = `${NAMESPACE}/DELETE_FILE_REQUEST`;
export const DELETE_FILE_SUCCESS = `${NAMESPACE}/DELETE_FILE_SUCCESS`;
export const DELETE_FILE_FAILURE = `${NAMESPACE}/DELETE_FILE_FAILURE`;

export const UPLOAD_FILE = `${NAMESPACE}/UPLOAD_FILE`;
export const UPLOAD_FILE_REQUEST = `${NAMESPACE}/UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_SUCCESS = `${NAMESPACE}/UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_FAILURE = `${NAMESPACE}/UPLOAD_FILE_FAILURE`;

export const OVERWRITE_FILE = `${NAMESPACE}/OVERWRITE_FILE`;
export const OVERWRITE_FILE_REQUEST = `${NAMESPACE}/OVERWRITE_FILE_REQUEST`;
export const OVERWRITE_FILE_SUCCESS = `${NAMESPACE}/OVERWRITE_FILE_SUCCESS`;
export const OVERWRITE_FILE_FAILURE = `${NAMESPACE}/OVERWRITE_FILE_FAILURE`;
