import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAuth0RedirectUri } from '@core';
import store from '@volleyball/store';
import { lazyLoadStore } from '@volleyball/store/lazy-load-store';
import Content from './app-content';
import '@core/styles/global.css';

const App = () => {
  const [shouldRenderApp, setShouldRenderApp] = useState<boolean>(false);

  const lazyLoadStoreHandler = async () => {
    await Promise.all([
      lazyLoadStore('featureToggle', import('@core/pages/feature-toggle')),
      lazyLoadStore('pages/userProfile', import('@core/pages/user-profile')),
      lazyLoadStore('pages/login', import('@core/pages/login')),
      lazyLoadStore('pages/terms', import('@core/pages/terms-and-conditions')),
      lazyLoadStore('loading', import('@core/store/modules/ui/loading')),
      lazyLoadStore('ui/tabs', import('@core/store/modules/ui/tabs')),
      lazyLoadStore('ui/snackbar', import('@core/store/modules/ui/snackbar')),

      // Put temporary pre-loaded modules here alongside with comments regarding which module they belong to. Remove after moving that module to core.
      lazyLoadStore('pages/ageGroupsPage', import('@core/pages/age-groups')), // dependency of pages/competition-creation
      lazyLoadStore(
        'pages/competitionLevels',
        import('@core/pages/competition-levels'),
      ), // dependency of pages/competition-creation
      lazyLoadStore('pages/seasons', import('@core/pages/seasons')), // dependency of pages/competition-creation
      lazyLoadStore('pages/venues', import('@core/pages/venues')), // dependency of pages/competitor-assignment
      lazyLoadStore('pages/persons', import('@core/pages/persons')), // dependency of pages/create-stage
      lazyLoadStore(
        'pages/refereeGroups',
        import('@core/pages/referee-groups'),
      ), // dependency of pages/create-stage
      lazyLoadStore('pages/competitions', import('@core/pages/competitions')),
    ]);

    setShouldRenderApp(true);
  };

  useEffect(() => {
    lazyLoadStoreHandler();
  }, []);

  if (!shouldRenderApp) {
    return null;
  }

  return (
    <Provider store={store}>
      <Auth0Provider
        useRefreshTokens
        useRefreshTokensFallback
        skipRedirectCallback
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        cacheLocation="localstorage"
        authorizationParams={{
          audience: import.meta.env.VITE_AUTH0_APP_AUDIENCE,
          redirect_uri: getAuth0RedirectUri(),
        }}
      >
        <Content />
      </Auth0Provider>
    </Provider>
  );
};

export default App;
