import { all, call, put, SagaReturnType } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { createKitSet } from '@core/store/modules/api/kit-set/sagas';
import { getKitSetMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-kit-set-mutated-successfully-callbacks';

import { actions } from '.';

export function* createKitSetFlow() {
  yield takeLeadingByPredicate(actions.createKitSet, function* (action) {
    const { payload } = action;
    const { clubId, data, competitionId, tabId } = payload;

    yield put(actions.createKitSetRequest({ clubId }));

    const { error } = (yield call(createKitSet, {
      clubId,
      data,
    })) as SagaReturnType<typeof createKitSet>;

    if (!error) {
      yield put(actions.createKitSetSuccess({ clubId }));
      yield call(
        getKitSetMutatedSuccessfullyCallbacks,
        { clubId, competitionId, kitSetId: null, modal: null, tabId },
        'create',
      );
    } else {
      yield put(actions.createKitSetFailure({ clubId }));
      yield call(handleApiErrors, error);
    }
  });
}
export default function* saga() {
  yield all([createKitSetFlow()]);
}
