import { handleActions } from 'redux-actions';

import { Country, Paginated } from '@core/types';
import { appendToPaginatedListFactory } from '@core/helpers';

import actions from './actions';

export type DefaultState = {
  countries: Paginated<Country['id']>;
};

type Payload = any;

export const defaultState: DefaultState = {
  countries: null,
};

const appendToCountriesList =
  appendToPaginatedListFactory<DefaultState>('countries');

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCountries.toString()]: (state, { payload }) =>
      appendToCountriesList(state, payload),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
