import { pathOr, path } from 'ramda';

import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getDisciplinaryRules = (competitionId: number, state: State) =>
  pathOr(
    [],
    ['shared', NAMESPACE, 'byCompetitionId', competitionId, 'rules'],
    state,
  );

export const getCompetitionContacts = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) =>
  path<T>(
    ['shared', NAMESPACE, 'byCompetitionId', competitionId, 'contacts'],
    state,
  );
