import LatoBoldWoff2 from '../fonts/Lato-Bold.woff2';
import LatoBoldWoff from '../fonts/Lato-Bold.woff';

const latoLatinBold = `
        @font-face {
          font-family: 'LatoWeb';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          text-rendering: optimizeLegibility;
          src: url(${LatoBoldWoff2}) format('woff2'), url(${LatoBoldWoff}) format('woff');
        }
      `;

export default latoLatinBold;
