import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { removeTab } from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { getGroupStandings } from '@volleyball/store/modules/group-standings/actions';
import * as groupSettingsApi from '@core/api/group-standing-settings';
import * as groupStandingSettingsActions from './actions';
import * as ACTION_TYPES from './constants';

export function* getGroupStandingsSettingsFlowWorker(
  action: ReturnType<
    typeof groupStandingSettingsActions.getGroupStandingSettings
  >,
) {
  yield put(groupStandingSettingsActions.getGroupStandingSettingsRequest());
  const { payload: groupId } = action;

  const { error, response } = yield call(
    groupSettingsApi.getGroupStandingSettings,
    groupId,
  );

  if (!error) {
    yield put(
      groupStandingSettingsActions.addGroupStandingSettings({
        groupId,
        settings: response,
      }),
    );
    yield put(groupStandingSettingsActions.getGroupStandingSettingsSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(groupStandingSettingsActions.getGroupStandingSettingsFailure());
  }
}

export function* clearGroupStandingsSettingsFlowWorker(
  action: ReturnType<
    typeof groupStandingSettingsActions.clearGroupStandingSettings
  >,
) {
  const { payload } = action;

  yield put(groupStandingSettingsActions.clearGroupStandingSettings(payload));
}

export function* setGroupStandingsSettingsFlowWorker(
  action: ReturnType<
    typeof groupStandingSettingsActions.setGroupStandingSettings
  >,
) {
  yield put(groupStandingSettingsActions.setGroupStandingSettingsRequest());
  const {
    payload: { tabId, groupId, settings },
  } = action;

  const { error, response } = yield call(
    groupSettingsApi.setGroupStandingSettings,
    groupId,
    settings,
  );

  if (!error) {
    yield put(groupStandingSettingsActions.setGroupStandingSettingsSuccess());
    yield put(removeTab(tabId));
    yield put(getGroupStandings({ groupId }));
  } else {
    yield put(groupStandingSettingsActions.setGroupStandingSettingsFailure());
  }

  return response;
}

export function* getGroupStandingsSettingsFlowWatcher() {
  yield takeLatest(
    ACTION_TYPES.GET_GROUP_STANDING_SETTINGS,
    getGroupStandingsSettingsFlowWorker,
  );
}

export function* setGroupStandingsSettingsFlowWatcher() {
  yield takeLatest(
    ACTION_TYPES.SET_GROUP_STANDING_SETTINGS,
    setGroupStandingsSettingsFlowWorker,
  );
}

export function* clearGroupStandingsSettingsFlowWatcher() {
  yield takeEvery(
    ACTION_TYPES.CLEAR_GROUP_STANDING_SETTINGS,
    clearGroupStandingsSettingsFlowWorker,
  );
}

export default function* saga() {
  yield all([
    getGroupStandingsSettingsFlowWatcher(),
    setGroupStandingsSettingsFlowWatcher(),
  ]);
}
