import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { dissoc, equals, isEmpty } from 'ramda';

import { isValue } from '@core/helpers';
import {
  MTableEditRow,
  Localization,
  Column,
  Action,
  Icons,
  Options,
} from '@core/components/material-table';

export interface RowProps {
  icons: Icons;
  index: number;
  options: Options<any>;
  mode: string;
  actions?: [Action<any>];
  data?: any;
  onRowSelected?: () => any | void;
  path?: [number];
  columns?: [Column<any>];
  onRowClick?: () => void;
  onEditingApproved?: (mode: string, newData: any, oldData: any) => any | void;
  onEditingCanceled?: (mode: string, data: any) => any | void;
  localization?: Localization;
  getFieldValue?: (stateData: any, columnDef: any) => any;
}

export interface RowActions {
  handleRowOpen?: (data: any) => void;
  handleRowClose?: () => void;
}

type Props = RowProps & RowActions;

export const FormikMTRow = (props: Props) => {
  const {
    onEditingApproved,
    onEditingCanceled,
    handleRowOpen,
    handleRowClose,
    ...restProps
  } = props;
  const {
    values,
    resetForm,
    setValues,
    setTouched,
    validateForm,
    initialValues,
  } = useFormikContext();
  useEffect(() => {
    handleRowOpen && handleRowOpen(props.data);

    return () => {
      handleRowClose && handleRowClose();
    };
  }, []);

  useEffect(() => {
    const data = dissoc('tableData', props.data);
    const isPristine = equals(values, data);
    if (!isPristine && isValue(data)) {
      setValues(data, false);
    }
  }, []);

  const handleSubmit = async (mode: string) => {
    const result = await validateForm();
    const fields = Object.keys(initialValues).reduce(
      (accumulator: any, key: string) => {
        accumulator[key] = true;

        return accumulator;
      },
      {},
    );
    setTouched(fields); // have to set all fields touched,
    // otherwise after submit validation errors are not shown

    if (isEmpty(result)) {
      onEditingApproved(mode, values, props.data);
    }
  };

  const handleCancel = (mode: string, data: any) => {
    onEditingCanceled(mode, data);
    resetForm();
  };

  return (
    <MTableEditRow
      {...restProps}
      onEditingApproved={handleSubmit}
      onEditingCanceled={handleCancel}
    />
  );
};

export default FormikMTRow;
