import React from 'react';
import { path, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Theme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { MatchModel } from '@core/types';

interface OwnProps {
  matchDetails: MatchModel;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    boxShadow: 'none',
    color: `rgb(13, 60, 97)`,
    backgroundColor: `rgb(232, 244, 253)`,
  },
  summary: {
    padding: '6px 16px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    '&.Mui-expanded': {
      minHeight: '48px',
    },
  },
  icon: {
    color: theme.palette.info.main,
    fontSize: 22,
    opacity: 0.9,
  },
  list: {
    margin: 0,
  },
  details: {
    padding: theme.spacing(0, 2, 2, 2.5),
  },
}));

const MatchSheetValidations = (props: Props) => {
  const { matchDetails } = props;
  const competitionSettingsProperties = [
    '_embedded',
    'competition',
    'competitionSettings',
  ];

  const minimumPlayersInLineup = path(
    [...competitionSettingsProperties, 'minimumPlayersInLineup'],
    matchDetails,
  );
  const maximumPlayersInLineup = path(
    [...competitionSettingsProperties, 'maximumPlayersInLineup'],
    matchDetails,
  );
  const maximumTeamOfficialsInLineup = path(
    [...competitionSettingsProperties, 'maximumTeamOfficialsInLineup'],
    matchDetails,
  );
  const { t } = useTranslation();
  const classes = useStyles();

  if (
    isNil(minimumPlayersInLineup) &&
    isNil(maximumPlayersInLineup) &&
    isNil(maximumTeamOfficialsInLineup)
  )
    return <></>;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMore className={classes.icon} />}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" mr={1.5}>
            <InfoOutlinedIcon className={classes.icon} />
          </Box>
          <Grid>{t('Match lineup requirements')}</Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Box pl={3.5} className={classes.list}>
          <Grid>
            {!isNil(minimumPlayersInLineup) &&
              !isNil(maximumPlayersInLineup) &&
              `• ${t(
                'Total number of players',
              )}: ${minimumPlayersInLineup} min - ${maximumPlayersInLineup} max`}
          </Grid>
          <Grid>
            {!isNil(maximumTeamOfficialsInLineup) &&
              `• ${t(
                'Number of team officials',
              )}: ${maximumTeamOfficialsInLineup} max`}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MatchSheetValidations;
