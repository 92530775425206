import React from 'react';
import { Typography, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  value: string;
  subvalue: string;
  keepPlaceholder?: boolean;
  alignRight?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    display: 'inline',
    textAlign: ({ alignRight }) => (alignRight ? 'right' : 'left'),
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
  },
  placeholder: {
    color: 'transparent',
  },
}));

const ValueWithSubvalue = (props: Props) => {
  const { value, subvalue, keepPlaceholder } = props;
  const classes = useStyles(props);

  return (
    <Grid className={classes.root}>
      <Typography className={classes.value} variant="body2">
        {value}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={keepPlaceholder && !subvalue ? classes.placeholder : ''}
      >
        {subvalue ? subvalue : keepPlaceholder ? 'p' : ''}
      </Typography>
    </Grid>
  );
};

export default ValueWithSubvalue;
