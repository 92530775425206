import { all, call, put, throttle, take, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as competitionManagersAPI from '@core/api/competition-managers';

import * as ACTION_TYPES from './constants';
import * as competitionManagersActions from './actions';

export function* getCompetitionManagers(
  query: string,
): Generator<Effect, any, any> {
  yield put(competitionManagersActions.getCompetitionManagersRequest());
  const response = yield call(
    competitionManagersAPI.getCompetitionManagers,
    query,
  );

  if (!response.error) {
    yield put(competitionManagersActions.getCompetitionManagersSuccess());
  } else {
    yield put(competitionManagersActions.getCompetitionManagersFailure());
  }

  return response;
}

function* getCompetitionManagersFlow(
  action: ReturnType<typeof competitionManagersActions.getCompetitionManagers>,
) {
  const { payload = '' } = action;

  const { error, response } = yield call(getCompetitionManagers, payload);

  if (!error) {
    const { users: competitionManagers } = response._embedded;

    yield put(
      competitionManagersActions.setCompetitionManagers(competitionManagers),
    );
  } else {
    yield call(handleApiErrors, error);
  }
}

function* getCurrentCompetitionManager(): Generator<Effect, any, any> {
  yield put(competitionManagersActions.getCurrentCompetitionManagerRequest());
  const response = yield call(
    competitionManagersAPI.getCurrentCompetitionManager,
  );

  if (!response.error) {
    yield put(competitionManagersActions.getCurrentCompetitionManagerSuccess());
  } else {
    yield put(competitionManagersActions.getCurrentCompetitionManagerFailure());
  }

  return response;
}

export function* getCurrentCompetitionManagerFlow() {
  while (true) {
    yield take(ACTION_TYPES.GET_CURRENT_COMPETITION_MANAGER);
    const { error, response } = yield call(getCurrentCompetitionManager);

    if (!error) {
      yield put(
        competitionManagersActions.setCurrentCompetitionManager(response),
      );
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export function* watchGetCompetitionManagers() {
  yield throttle(
    500,
    ACTION_TYPES.GET_COMPETITION_MANAGERS,
    getCompetitionManagersFlow,
  );
}

export default function* saga() {
  yield all([
    watchGetCompetitionManagers(),
    getCurrentCompetitionManagerFlow(),
  ]);
}
