import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Button, Paper, Theme } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, FormikConfig } from 'formik';
import { makeStyles } from '@mui/styles';
import { array, number, object, string } from 'yup';

import { GridItems, TextField } from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import {
  getMatchNotesDescriptions,
  getMatchNotesTitles,
  getMatchCommissionerNoteTitles,
  getMatchCommissionerNoteDescriptions,
} from '@core/store/modules/tabs/match-profile/selectors';
import { MatchNotesDescriptions, MatchNotesTitles } from '@volleyball/types';
import { State } from '@volleyball/store';
import MatchProfileSummaryBlock from '@volleyball/components/match-notes-summary-block';
import { ButtonWithProgress } from '@ui-components';
interface StateProps {
  isLoading: boolean;
  matchNotesDescriptions: MatchNotesDescriptions;
  matchNotesTitles: MatchNotesTitles;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
    matchProfile: typeof matchProfileActions;
  };
}

interface OwnProps {
  matchId: number;
  tabId: string;
  isCommissioner?: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
}));

const MatchNotesEdit = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    matchNotesTitles,
    matchNotesDescriptions,
    actions,
    tabId,
    matchId,
    isLoading,
    isCommissioner,
  } = props;

  const initialValues = {
    notes: matchNotesTitles.map(({ id }) => ({
      titleId: id,
      description: matchNotesDescriptions.find((desc) => desc.titleId === id)
        ?.description,
    })),
  };

  const validationSchema = object({
    notes: array().of(
      object({
        titleId: number(),
        description: string().nullable(),
      }),
    ),
  });

  type Values = {
    notes: {
      titleId: number;
      description: string | null;
    }[];
  };

  const handleCancel: MouseEventHandler<HTMLButtonElement> = () => {
    actions.tabs.removeTab({ tabId });
  };

  const handleRefereeSubmit: FormikConfig<Values>['onSubmit'] = ({ notes }) => {
    notes.forEach((item, index) =>
      item.description ? '' : (notes[index].description = ''),
    );
    actions.matchProfile.updateMatchNotesDescriptions({
      matchId,
      matchNotesDescriptions: notes,
      tabId,
    });
  };

  const handleCommissionerSubmit: FormikConfig<Values>['onSubmit'] = ({
    notes,
  }) => {
    notes.forEach((item, index) =>
      item.description ? '' : (notes[index].description = ''),
    );
    actions.matchProfile.updateMatchCommissionerNoteDescriptions({
      matchId,
      matchCommissionerNoteDescriptions: notes,
      tabId,
    });
  };

  return (
    <Formik
      onSubmit={isCommissioner ? handleCommissionerSubmit : handleRefereeSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={2} direction="column">
          <GridItems xs>
            <Paper className={classes.paper}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Typography variant="h6">
                    {isCommissioner
                      ? t('Edit match commissioner notes')
                      : t('Referee notes')}
                  </Typography>
                </Grid>
                <Grid container item>
                  <MatchProfileSummaryBlock matchId={matchId} />
                </Grid>
                <Divider />
                <Grid item>
                  {matchNotesTitles.map(({ id, title }, index) => {
                    return (
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        key={id}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <GridItems md={8} xs={12} className={classes.fullWidth}>
                          <Typography variant="subtitle1">
                            {t(title)}
                          </Typography>
                          <TextField
                            name={`notes.${index}.description`}
                            label={t('Comment')}
                            multiline
                            fullWidth
                            type="text"
                            minRows={4}
                          />
                        </GridItems>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Paper>
            <Divider />
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button onClick={handleCancel}>{t('Cancel')}</Button>
              </Grid>
              <Grid item>
                <ButtonWithProgress
                  type="submit"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                >
                  {t('Save')}
                </ButtonWithProgress>
              </Grid>
            </Grid>
          </GridItems>
        </Grid>
      </Form>
    </Formik>
  );
};

const isLoadingSelector = createLoadingSelector([
  matchProfileActions.updateMatchNotesDescriptionsRequest.toString(),
  matchProfileActions.updateMatchCommissionerNoteDescriptionsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    isLoading: isLoadingSelector(state),
    matchNotesDescriptions: ownProps.isCommissioner
      ? getMatchCommissionerNoteDescriptions(ownProps.matchId)(state)
      : getMatchNotesDescriptions(ownProps.matchId)(state),

    matchNotesTitles: ownProps.isCommissioner
      ? getMatchCommissionerNoteTitles(ownProps.matchId)(state)
      : getMatchNotesTitles(ownProps.matchId)(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchNotesEdit);
