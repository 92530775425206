import React from 'react';
import { Avatar, AvatarProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LogoSize } from '@core/types';

interface OwnProps extends AvatarProps {
  size: LogoSize;
  nameInitials?: string;
}

export type LogoProps = OwnProps;

const sizes = {
  [LogoSize.EXTRASMALL]: 20,
  [LogoSize.SMALL]: 36,
  [LogoSize.LARGE]: 60,
} as const;

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ size, src, nameInitials }: LogoProps) => ({
    color: nameInitials
      ? theme.palette.background.default
      : theme.palette.grey[500],
    height: sizes[size],
    width: sizes[size],
    ...(!src && !nameInitials
      ? {
          // fallback style
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[200],
        }
      : {}),
  }),
}));

const Logo = (props: LogoProps) => {
  const { size, nameInitials, ...avatarProps } = props;
  const classes = useStyles(props);

  return nameInitials ? (
    <Avatar className={classes.root} {...avatarProps}>
      {nameInitials}
    </Avatar>
  ) : (
    <Avatar className={classes.root} {...avatarProps} />
  );
};

export default Logo;
