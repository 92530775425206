import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { prop, find } from 'ramda';
import { Grid } from '@mui/material';

import { KitSetList } from '@volleyball/types';

import PlayerKit from './player-kit';

interface OwnProps {
  kitSets: KitSetList;
}

type Props = OwnProps;

const KitSetPreview = (props: Props) => {
  const { kitSets } = props;
  const { values } = useFormikContext();
  const { t } = useTranslation();
  const selectedKitSetId = prop('kitSet', values as any);
  const selectedKitSet =
    kitSets && find(({ id }) => id === selectedKitSetId, kitSets);
  const playerKits = [
    {
      label: t('Home Kit'),
      playerKitSet: prop('homePlayerKit', selectedKitSet),
    },
    {
      label: t('Away Kit'),
      playerKitSet: prop('awayPlayerKit', selectedKitSet),
    },
    {
      label: t('Reserved Kit'),
      playerKitSet: prop('reservedPlayerKit', selectedKitSet),
    },
  ];

  return (
    <>
      {playerKits.map(({ label, playerKitSet }) => (
        <Grid item xs={12} sm={6} lg={4} key={label}>
          <PlayerKit playerKitSet={playerKitSet} label={label} />
        </Grid>
      ))}
    </>
  );
};

export default KitSetPreview;
