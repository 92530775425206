import { useState, useRef, useCallback, RefObject, MouseEvent } from 'react';

interface PopperToggleReturnType {
  isOpen: boolean;
  anchorRef: RefObject<any>;
  handleToggle: (event: MouseEvent<EventTarget>) => void;
  handleClose: (event: MouseEvent<EventTarget>) => void;
  forceClose: () => void;
}

export const usePopperToggle = (): PopperToggleReturnType => {
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const forceClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = useCallback((event) => {
    event.stopPropagation();

    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  return { isOpen, handleToggle, handleClose, anchorRef, forceClose };
};
