import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { Logo } from '@core/components';
import { Club } from '@core/icons';
import { getCompetitorLogoUrl } from '@core/helpers';
import { LogoSize, MatchModel, MatchStatus } from '@core/types';
import { MatchSet } from '@volleyball/types';

interface OwnProps {
  match: MatchModel;
  matchSets: Array<MatchSet>;
  noPaper?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    textAlign: 'center',
    boxShadow: ({ noPaper }) => noPaper && 'none',
    paddingBottom: theme.spacing(3),
  },
  lineHeight: {
    lineHeight: 1,
  },
  score: {
    padding: 0,
    width: 24,
    height: 36,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
  },
  truncTeam: {
    [theme.breakpoints.only('xs')]: {
      maxWidth: theme.spacing(7),
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: theme.spacing(20),
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sets: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginTop: theme.spacing(1),
    fontSize: '0.75rem',
    color: theme.palette.grey[600],
  },
  noResults: {
    textTransform: 'uppercase',
  },
  noWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  scoresSet: {
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
}));

const MatchDataHeader = (props: Props) => {
  const { match, matchSets } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const Team = ({
    type,
    title,
    subtitle,
    direction = 'left',
    logo,
  }: {
    type: string;
    title: string;
    subtitle?: string;
    direction?: string;
    logo: { url: string; defaultIcon: JSX.Element };
  }) => {
    return (
      <Grid
        container
        direction="column"
        alignItems={direction === 'right' ? 'flex-end' : 'flex-start'}
        sx={{
          pl: direction === 'left' ? 0.25 : 0,
          pr: direction === 'right' ? 0.25 : 0,
        }}
        wrap="nowrap"
      >
        <Grid item>
          <Typography
            className={classes.truncTeam}
            variant="caption"
            color="textSecondary"
            style={{ textTransform: 'uppercase' }}
          >
            {type}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            direction={direction === 'right' ? 'row-reverse' : 'row'}
          >
            <Grid item>
              <Logo size={LogoSize.SMALL} src={logo.url}>
                {logo.defaultIcon}
              </Logo>
            </Grid>
            <Grid
              item
              sx={{
                pl: direction === 'left' ? 1.5 : 0,
                pr: direction === 'right' ? 1.5 : 0,
              }}
            >
              <Grid
                container
                direction="column"
                alignItems={direction === 'right' ? 'flex-end' : 'flex-start'}
              >
                <Grid item sx={{ mb: subtitle ? 0.25 : 0 }}>
                  <Typography className={classes.truncTeam} variant="body2">
                    <strong>{title}</strong>
                  </Typography>
                </Grid>
                {subtitle && (
                  <Grid item className={classes.lineHeight}>
                    <Typography
                      className={classes.truncTeam}
                      variant="caption"
                      color="textSecondary"
                    >
                      <strong>{subtitle}</strong>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const isWinner = (competitorId: number) => {
    if (match.status === MatchStatus.COMPLETE) {
      if (
        match.awayCompetitorScore < match.homeCompetitorScore &&
        match.homeCompetitorId === competitorId
      )
        return true;
      else if (
        match.awayCompetitorScore > match.homeCompetitorScore &&
        match.awayCompetitorId === competitorId
      )
        return true;
    }

    return false;
  };

  const matchSetsResult = () => {
    if (!matchSets || matchSets?.length === 0) {
      return (
        <Typography variant="inherit" className={classes.noResults}>
          {t('No Results')}
        </Typography>
      );
    }

    const goldenSet = matchSets.find((matchSet) => matchSet.goldenSet);
    const matchSetsWithoutGoldenSet = matchSets.filter(
      (matchSet) => !matchSet.goldenSet,
    );

    return (
      <Grid>
        (
        {matchSetsWithoutGoldenSet.map((matchSet, index) => (
          <Typography
            key={index}
            variant="inherit"
            sx={{ display: 'inline' }}
          >{`${matchSet.homeCompetitorScore}-${matchSet.awayCompetitorScore}${
            index !== matchSetsWithoutGoldenSet.length - 1 ? ' • ' : ''
          }`}</Typography>
        ))}
        )
        {goldenSet &&
          ` • ${t('G')} ${goldenSet.homeCompetitorScore}-${
            goldenSet.awayCompetitorScore
          }`}
      </Grid>
    );
  };

  const checkCondition =
    !match.homeCompetitorScore &&
    !match.awayCompetitorScore &&
    match.status !== MatchStatus.COMPLETE;

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Team
              type={t('Home')}
              title={match.homeCompetitorTitle}
              subtitle={match.homeCompetitorInternationalTitle}
              logo={{
                url: getCompetitorLogoUrl(match, 'homeCompetitor'),
                defaultIcon: <Club />,
              }}
              direction="right"
            />
          </Grid>
          <Grid
            item
            alignSelf="flex-end"
            sx={{ position: 'relative', padding: theme.spacing(0, 2) }}
            className={classes.scoresSet}
          >
            <Grid container alignItems="center">
              <Grid item>
                <Grid container alignItems="center" justifyContent="center">
                  {isWinner(match.homeCompetitorId) && <ArrowLeft />}
                  <Typography
                    variant={isSmall ? 'h5' : 'h3'}
                    className={classes.score}
                  >
                    {match?.homeCompetitorScore || (checkCondition ? '—' : 0)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ px: 1 }}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h5">
                      <strong>:</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography
                    variant={isSmall ? 'h5' : 'h3'}
                    className={classes.score}
                  >
                    {match?.awayCompetitorScore || (checkCondition ? '—' : 0)}
                  </Typography>
                  {isWinner(match.awayCompetitorId) && <ArrowRight />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.sets}>
              {matchSetsResult()}
            </Grid>
          </Grid>
          <Grid item>
            <Team
              type={t('Away')}
              title={match.awayCompetitorTitle}
              subtitle={match.awayCompetitorInternationalTitle}
              logo={{
                url: getCompetitorLogoUrl(match, 'awayCompetitor'),
                defaultIcon: <Club />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MatchDataHeader;
