import { Action, createActions } from 'redux-actions';

export const NAMESPACE = 'fixtureMatchEdit';

export const actions = createActions(
  'GET_COMPETITORS',
  'GET_COMPETITORS_REQUEST',
  'GET_COMPETITORS_SUCCESS',
  'GET_COMPETITORS_FAILURE',
  'SET_COMPETITORS',

  'GET_GROUP_COMPETITORS',
  'GET_GROUP_COMPETITORS_REQUEST',
  'GET_GROUP_COMPETITORS_SUCCESS',
  'GET_GROUP_COMPETITORS_FAILURE',
  'SET_GROUP_COMPETITORS',

  'RESET_STATE',

  'GET_VENUES',
  'GET_VENUES_REQUEST',
  'GET_VENUES_SUCCESS',
  'GET_VENUES_FAILURE',
  'SET_VENUE_OPTIONS',
  'SEARCH_VENUES',
  'VENUES_RESET',

  'CREATE_FIXTURE_MATCH',
  'CREATE_FIXTURE_MATCH_REQUEST',
  'CREATE_FIXTURE_MATCH_SUCCESS',
  'CREATE_FIXTURE_MATCH_FAILURE',

  'UPDATE_FIXTURE_MATCH',
  'UPDATE_FIXTURE_MATCH_REQUEST',
  'UPDATE_FIXTURE_MATCH_SUCCESS',
  'UPDATE_FIXTURE_MATCH_FAILURE',

  'DELETE_FIXTURE_MATCH',
  'DELETE_FIXTURE_MATCH_REQUEST',
  'DELETE_FIXTURE_MATCH_SUCCESS',
  'DELETE_FIXTURE_MATCH_FAILURE',

  'SET_FIXTURE_MATCH_BEING_MODIFIED',

  { prefix: NAMESPACE },
) as unknown as {
  getCompetitors: UnknownAction;
  getCompetitorsRequest: RequestLifecycleAction;
  getCompetitorsSuccess: RequestLifecycleAction;
  getCompetitorsFailure: RequestLifecycleAction;
  setCompetitors: UnknownAction;

  getGroupCompetitors: UnknownAction;
  getGroupCompetitorsRequest: RequestLifecycleAction;
  getGroupCompetitorsSuccess: RequestLifecycleAction;
  getGroupCompetitorsFailure: RequestLifecycleAction;
  setGroupCompetitors: UnknownAction;

  resetState: () => Action<void>;

  getVenues: IdentityActionFunction<{ query: string; page: number }>;
  getVenuesRequest: RequestLifecycleAction;
  getVenuesSuccess: RequestLifecycleAction;
  getVenuesFailure: RequestLifecycleAction;
  setVenueOptions: UnknownAction;

  searchVenues: IdentityActionFunction<{ query: string; page: number }>;

  venuesReset: () => Action<void>;

  createFixtureMatch: IdentityActionFunction<{
    competitionId: number;
    stageId: number;
    groupId: number;
    data: unknown;
  }>;
  createFixtureMatchRequest: RequestLifecycleAction;
  createFixtureMatchSuccess: RequestLifecycleAction;
  createFixtureMatchFailure: RequestLifecycleAction;

  updateFixtureMatch: IdentityActionFunction<{
    competitionId: number;
    stageId: number;
    matchId: number;
    data: unknown;
  }>;
  updateFixtureMatchRequest: RequestLifecycleAction;
  updateFixtureMatchSuccess: RequestLifecycleAction;
  updateFixtureMatchFailure: RequestLifecycleAction;

  deleteFixtureMatch: IdentityActionFunction<{
    competitionId: number;
    stageId: number;
    matchId: number;
    tabId: string;
  }>;
  deleteFixtureMatchRequest: RequestLifecycleAction;
  deleteFixtureMatchSuccess: RequestLifecycleAction;
  deleteFixtureMatchFailure: RequestLifecycleAction;
};
