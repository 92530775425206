import { MatchesAllocationResponse, MatchAllocation } from '@core/types';

export function getMatchesStatus(response: MatchesAllocationResponse) {
  return response._embedded.matchAllocations.map(
    (matchAllocation: MatchAllocation) => ({
      matchId: matchAllocation.match.id,
      isFullyAllocated: matchAllocation.isAllMatchOfficialsAppointed,
      hasConflicts: matchAllocation.hasConflicts,
    }),
  );
}
