import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

import { MatchesAllocationResponse } from '@core/types';

import { getMatchesStatus } from './helpers';

export const NAMESPACE = 'matchesAllocation';

export const actions = createActions(
  {
    SET_MATCHES_ALLOCATION: [prop('payload'), pick(['groupId'])],
    SET_ALLOCATIONS: [prop('payload'), pick(['groupId'])],
  },

  'GET_MATCHES_ALLOCATION',
  'GET_MATCHES_ALLOCATION_REQUEST',
  'GET_MATCHES_ALLOCATION_SUCCESS',
  'GET_MATCHES_ALLOCATION_FAILURE',

  'REFRESH_MATCHES_ALLOCATION',
  'REFRESH_MATCHES_ALLOCATION_REQUEST',
  'REFRESH_MATCHES_ALLOCATION_SUCCESS',
  'REFRESH_MATCHES_ALLOCATION_FAILURE',

  'LOAD_MATCHES_ALLOCATION_REQUEST',
  'LOAD_MATCHES_ALLOCATION_SUCCESS',
  'LOAD_MATCHES_ALLOCATION_FAILURE',

  'CANCEL_MATCHES_ALLOCATION_REFRESH',

  { prefix: NAMESPACE },
) as unknown as {
  setMatchesAllocation: IdentityActionMetaFunction<
    { groupId: number; payload: MatchesAllocationResponse },
    { payload: MatchesAllocationResponse },
    { groupId: number }
  >;
  setAllocations: IdentityActionMetaFunction<
    { groupId: number; payload: ReturnType<typeof getMatchesStatus> },
    { payload: ReturnType<typeof getMatchesStatus> },
    { groupId: number }
  >;

  getMatchesAllocation: IdentityActionFunction<{
    stageId: number;
    page?: number;
  }>;
  getMatchesAllocationRequest: RequestLifecycleAction<{ stageId: number }>;
  getMatchesAllocationSuccess: RequestLifecycleAction<{ stageId: number }>;
  getMatchesAllocationFailure: RequestLifecycleAction<{ stageId: number }>;

  refreshMatchesAllocation: IdentityActionFunction<{ stageId: number }>;
  refreshMatchesAllocationRequest: RequestLifecycleAction<{ stageId: number }>;
  refreshMatchesAllocationSuccess: RequestLifecycleAction<{ stageId: number }>;
  refreshMatchesAllocationFailure: RequestLifecycleAction<{ stageId: number }>;

  loadMatchesAllocationRequest: RequestLifecycleAction;
  loadMatchesAllocationSuccess: RequestLifecycleAction;
  loadMatchesAllocationFailure: RequestLifecycleAction;

  cancelMatchesAllocationRefresh: RequestLifecycleAction<{ stageId: number }>;
};
