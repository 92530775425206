import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';

// const getCompetition = createAction<any>(`${NAMESPACE}/GET_COMPETITION`);
// const getCompetitionRequest = createAction<any>(`${NAMESPACE}/GET_COMPETITION_REQUEST`);
// const getCompetitionFailure = createAction<any>(`${NAMESPACE}/GET_COMPETITION_FAILURE`);
// const getCompetitionSuccess = createAction<any>(`${NAMESPACE}/GET_COMPETITION_SUCCESS`);

const getCompetitors = createAction<any>(`${NAMESPACE}/GET_COMPETITORS`);
const getCompetitorsRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITORS_REQUEST`,
);
const getCompetitorsFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITORS_FAILURE`,
);
const getCompetitorsSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITORS_SUCCESS`,
);

const getPlayers = createAction<any>(`${NAMESPACE}/GET_PLAYERS`);
const getPlayersRequest = createAction<void>(
  `${NAMESPACE}/GET_PLAYERS_REQUEST`,
);
const getPlayersFailure = createAction<void>(
  `${NAMESPACE}/GET_PLAYERS_FAILURE`,
);
const getPlayersSuccess = createAction<void>(
  `${NAMESPACE}/GET_PLAYERS_SUCCESS`,
);

const getMatches = createAction<any>(`${NAMESPACE}/GET_MATCHES`);
const getMatchesRequest = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_REQUEST`,
);
const getMatchesSuccess = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_SUCCESS`,
);
const getMatchesFailure = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_FAILURE`,
);

const createSanction = createAction<any>(`${NAMESPACE}/CREATE_SANCTION`);
const createSanctionRequest = createAction<void>(
  `${NAMESPACE}/CREATE_SANCTION_REQUEST`,
);
const createSanctionFailure = createAction<void>(
  `${NAMESPACE}/CREATE_SANCTION_FAILURE`,
);
const createSanctionSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_SANCTION_SUCCESS`,
);

const updateSanction = createAction<any>(`${NAMESPACE}/UPDATE_SANCTION`);
const updateSanctionRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_SANCTION_REQUEST`,
);
const updateSanctionFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_SANCTION_FAILURE`,
);
const updateSanctionSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_SANCTION_SUCCESS`,
);

const setCompetitors = createAction<any>(`${NAMESPACE}/SET_COMPETITORS`);
const setPlayers = createAction<any>(`${NAMESPACE}/SET_PLAYERS`);
const setMatches = createAction<any>(`${NAMESPACE}/SET_MATCHES`);

const getTeamOfficials = createAction<any>(`${NAMESPACE}/GET_TEAM_OFFICIALS`);
const getTeamOfficialsRequest = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_REQUEST`,
);
const getTeamOfficialsFailure = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_FAILURE`,
);
const getTeamOfficialsSuccess = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_SUCCESS`,
);

const setTeamOfficials = createAction<any>(`${NAMESPACE}/SET_TEAM_OFFICIALS`);

const getMatchOfficials = createAction<{
  competitionId: number;
  sanctionType: any;
  queryParams: URLSearchParams;
}>(`${NAMESPACE}/GET_MATCH_OFFICIALS`);
const getMatchOfficialsRequest = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_REQUEST`,
);
const getMatchOfficialsSuccess = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_SUCCESS`,
);
const getMatchOfficialsFailure = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_FAILURE`,
);
const setMatchOfficials = createAction<any>(`${NAMESPACE}/SET_MATCH_OFFICIALS`);

const getTeams = createAction<any>(`${NAMESPACE}/GET_TEAMS`);
const getTeamsRequest = createAction<void>(`${NAMESPACE}/GET_TEAMS_REQUEST`);
const getTeamsSuccess = createAction<void>(`${NAMESPACE}/GET_TEAMS_SUCCESS`);
const getTeamsFailure = createAction<void>(`${NAMESPACE}/GET_TEAMS_FAILURE`);
const setTeams = createAction<any>(`${NAMESPACE}/SET_TEAMS`);

const setCurrentCompetitor = createAction<any>(
  `${NAMESPACE}/SET_CURRENT_COMPETITOR`,
);

const resetState = createAction<any>(`${NAMESPACE}/RESET_STATE`);

export const actions = {
  getCompetitors,
  getCompetitorsRequest,
  getCompetitorsFailure,
  getCompetitorsSuccess,
  setCompetitors,
  setCurrentCompetitor,
  getPlayers,
  getPlayersRequest,
  getPlayersFailure,
  getPlayersSuccess,
  setPlayers,
  createSanction,
  createSanctionRequest,
  createSanctionFailure,
  createSanctionSuccess,
  updateSanction,
  updateSanctionRequest,
  updateSanctionFailure,
  updateSanctionSuccess,
  getMatches,
  getMatchesRequest,
  getMatchesSuccess,
  getMatchesFailure,
  setMatches,
  getTeamOfficials,
  getTeamOfficialsRequest,
  getTeamOfficialsFailure,
  getTeamOfficialsSuccess,
  setTeamOfficials,
  resetState,
  getMatchOfficials,
  getMatchOfficialsRequest,
  getMatchOfficialsSuccess,
  getMatchOfficialsFailure,
  setMatchOfficials,
  getTeams,
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFailure,
  setTeams,
};
