import React, { useState, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextField, RadioGroup, ContentBlock } from '@core/components';
import {
  CompetitionResponse,
  OffenderType,
  SanctionType,
} from '@volleyball/types';

import { FINANCIAL_PENALTY_FIELDS, OFFENDER_TYPE_FIELDS } from '../constants';
import OffenderTypePlayer from './offender-type-player';
import OffenderTypeTeamOfficial from './offender-type-team-official';
import OffenderTypeMatchOfficial from './offender-type-match-official';
import OffenderTypeTeam from './offender-type-team';
import RelatedMatchBlock from './related-match-block';
import RequiredFieldsMessage from './required-fields-message';
import type { MatchModel } from '@core/types';
import type { FormMode } from '../constants';

type OwnProps = {
  competition: CompetitionResponse;
  match: MatchModel;
  setMatch: (match: MatchModel) => void;
  offenderType: OffenderType;
  mode: FormMode;
};

type Props = OwnProps;

const FinancialPenaltyForm = (props: Props) => {
  const { competition, match, setMatch, offenderType, mode } = props;
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(offenderType);

  function handleTypeChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedValue(event.target.value as OffenderType);
  }

  return (
    <Grid>
      <RequiredFieldsMessage />
      <ContentBlock spacing={3} title={t('Competition')} mb={5} pb={5}>
        <Grid item xs={12} md={5}>
          <TextField
            name={FINANCIAL_PENALTY_FIELDS.competitionTitle}
            label={t('Competition')}
            disabled
            required
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            name={FINANCIAL_PENALTY_FIELDS.competitionSeason}
            label={t('Season')}
            required
            disabled
          />
        </Grid>
      </ContentBlock>
      <ContentBlock spacing={3} title={t('Offender')} mb={5} pb={5}>
        <Grid item xs={12} md={10}>
          <RadioGroup
            selectedValue={selectedValue}
            labelBackground="inherit"
            name={OFFENDER_TYPE_FIELDS.offenderType}
            onChange={handleTypeChange}
            disabled={mode === 'edit'}
            blocks={[
              {
                label: t('Player'),
                value: OffenderType.PLAYER,
                content: (
                  <OffenderTypePlayer
                    competitionId={competition.id}
                    mode={mode}
                    sanctionType={SanctionType.FINANCIAL_PENALTY}
                  />
                ),
              },
              {
                label: t('Team official'),
                value: OffenderType.TEAM_OFFICIAL,
                content: (
                  <OffenderTypeTeamOfficial
                    competitionId={competition.id}
                    mode={mode}
                    sanctionType={SanctionType.FINANCIAL_PENALTY}
                  />
                ),
              },
              {
                label: t('Team'),
                value: OffenderType.TEAM,
                content: (
                  <OffenderTypeTeam
                    competitionId={competition.id}
                    sanctionType={SanctionType.FINANCIAL_PENALTY}
                  />
                ),
              },
              {
                label: t('Match official'),
                value: OffenderType.MATCH_OFFICIAL,
                content: (
                  <OffenderTypeMatchOfficial
                    competitionId={competition.id}
                    sportId={competition.sportId}
                    mode={mode}
                    sanctionType={SanctionType.FINANCIAL_PENALTY}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </ContentBlock>
      <ContentBlock spacing={3} title={t('Penalty')} mb={5} pb={5}>
        <Grid item xs={12} md={3}>
          <TextField
            name={FINANCIAL_PENALTY_FIELDS.amount}
            label={t('Amount')}
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            name={FINANCIAL_PENALTY_FIELDS.currency}
            label={t('Currency')}
            required
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            name={FINANCIAL_PENALTY_FIELDS.commment}
            label={t('Comment')}
            multiline
            minRows={6}
          />
        </Grid>
      </ContentBlock>
      <RelatedMatchBlock
        competitionId={competition.id}
        match={match}
        setMatch={setMatch}
        sanctionType={SanctionType.FINANCIAL_PENALTY}
      />
    </Grid>
  );
};

export default FinancialPenaltyForm;
