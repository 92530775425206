import { TFunction } from 'i18next';
import { Genders } from '@core/types';

export const getGenders = (t: TFunction, gender: keyof typeof Genders) => {
  switch (gender) {
    case Genders.MALE:
      return t('Male');
    case Genders.FEMALE:
      return t('Female');
    case Genders.MIXED:
      return t('Mixed');
    default:
      return gender;
  }
};
