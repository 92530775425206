import restService from '@core/api/rest-service';

export const getDisciplinaryInfringements = (
  competitionId: number,
  page: {
    page?: number;
  },
) =>
  restService.get<any>(`/v1/competitions/${competitionId}/penaltyCardLog`, {
    params: page,
  });
