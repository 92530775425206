import restService from '@core/api/rest-service';
import { RefereeCategoriesResponse, RefereeCategory } from '@core/types';

export const getRefereeCategories = () =>
  restService.get<RefereeCategoriesResponse>('/v1/refereeCategories');

export const createRefereeCategory = (data: RefereeCategory) =>
  restService.post('/v1/refereeCategories', data);

export const deleteRefereeCategory = (id: number) =>
  restService.delete(`/v1/refereeCategories/${id}`);

export const updateRefereeCategory = (id: number, data: RefereeCategory) =>
  restService.patch(`/v1/refereeCategories/${id}`, data);
