import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import {
  Paginated,
  User,
  UserRoleRequest,
  UserRoleRequestStatus,
} from '@core/types';
import { State } from '@core/store';
import { getUserRoleRequestsApiRoot } from '@core/store/modules/api/user-role-request/selectors';

import { NAMESPACE } from './constants';
import { DefaultState } from './reducers';

const getNamespaceRoot = (state: State) =>
  pathOr<DefaultState>(null, ['pages', NAMESPACE], state);

export const getUsers = createSelector(
  getNamespaceRoot,
  (root): Array<User> => root?.users?.list ?? [],
);

export const getUsersPaginated = createSelector(
  getNamespaceRoot,
  (root): Paginated<User> => root?.users,
);
export const getUsersSearchQuery = createSelector(
  getNamespaceRoot,
  (root): string => root?.usersQuery ?? '',
);

export const getUsersWithoutId = createSelector(
  getNamespaceRoot,
  (state: any, id: number): number => id,
  (root, id): Array<User> => root.users?.list?.filter((u) => u.id !== id) ?? [],
);

export const getUserRoleRequests = createSelector(
  getNamespaceRoot,
  getUserRoleRequestsApiRoot,
  (root, userRoleRequests): Array<UserRoleRequest> => {
    const userRoleRequestIds = root?.userRoleRequests?.list;

    return userRoleRequests && userRoleRequestIds
      ? userRoleRequestIds.map((id: number) => userRoleRequests[id])
      : [];
  },
);

export const getPaginatedUserRoleRequests = createSelector(
  getNamespaceRoot,
  getUserRoleRequests,
  (root, userRoleRequests): Paginated<UserRoleRequest> => {
    const pagination = root.userRoleRequests;

    return {
      ...pagination,
      list: userRoleRequests,
    };
  },
);

export const getUserRoleRequestsStatusCount = createSelector(
  getNamespaceRoot,
  (
    state: any,
    status: UserRoleRequestStatus.PENDING,
  ): UserRoleRequestStatus.PENDING => status,
  (root, status): number => root[status],
);
