import { GeographyTypes } from '@core/types';

import { getGeography } from './get-geography';

export const getGeographyOptions = (t: (key: string) => string) => {
  return [
    {
      label: getGeography(t, GeographyTypes.DOMESTIC),
      value: GeographyTypes.DOMESTIC,
    },
    {
      label: getGeography(t, GeographyTypes.INTERNATIONAL),
      value: GeographyTypes.INTERNATIONAL,
    },
  ];
};
