import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  modalRoot: {
    position: 'relative',
  },
  title: {
    height: theme.spacing(8),
    lineHeight: theme.spacing(4),
    padding: theme.spacing(2),
  },
  content: {
    overflowY: 'auto',
    maxHeight: `calc(var(--vh, 1vh) * 100  - ${theme.spacing(8)} - ${theme.spacing(8)} - ${theme.spacing(10)})`,
    '& > form': {
      outline: '2px dashed red',
    },
  },
  form: {
    display: 'flex',
    flexFlow: 'column nowrap',
    maxHeight: `calc(var(--vh, 1vh) * 100  - ${theme.spacing(8)} - ${theme.spacing(8)} - ${theme.spacing(10)})`,
  },
  actions: {
    height: theme.spacing(10),
    padding: theme.spacing(2),
  },
}));
