import { ComponentType, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, useMediaQuery, Theme } from '@mui/material';
import { AppBar, ScrollUp, TabsBar, TabsPanel } from '@core/components';
import MainMenu from './components/main-menu';
import type { LayoutProps, MenuList, NavigationsTabsMap } from '@core/types';

type Props = LayoutProps & {
  body: ComponentType<any> | JSX.Element;
  paperless?: boolean;
  noPadding?: boolean;
  showScrollUp?: boolean;
  tabMapping: NavigationsTabsMap;
  mainMenuMapping: MenuList;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    paddingTop: theme.mixins.toolbar.minHeight,
  },
  content: {
    display: 'flex',
    maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
  nav: {
    paddingLeft: theme.spacing(1),
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    minWidth: theme.mixins.sideMenu.minWidth,
    '-webkit-overflow-scrolling': 'touch',
    position: 'relative',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    '-webkit-overflow-scrolling': 'touch',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: `100%`,
    '-webkit-overflow-scrolling': 'touch',
    overflowY: 'auto',
    marginTop: 'auto',
  },
  footer: {
    marginTop: 'auto',
  },
  body: {
    padding: ({ noPadding }: Partial<Props>) =>
      noPadding ? 0 : theme.spacing(3, 3, 3, 3),
  },
}));

export const MainLayout = ({
  body,
  paperless,
  noPadding,
  showScrollUp,
  tabMapping,
  mainMenuMapping,
}: Props) => {
  const classes = useStyles({ noPadding });
  const containerRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );

  const handleScrollUp = () =>
    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <Grid className={classes.root}>
      <AppBar />
      <Grid className={classes.content}>
        {!isSmallScreen && (
          <nav className={classes.nav}>
            <MainMenu mainMenuMapping={mainMenuMapping} />
          </nav>
        )}
        <section className={classes.section}>
          <TabsBar tabMapping={tabMapping} />
          <Grid ref={containerRef} className={classes.container}>
            <main>
              {/* don't render any panels while used as placeholder to prevent tabs mounting-dismounting-mounting */}
              {body ? (
                <TabsPanel tabMapping={tabMapping}>
                  {paperless ? (
                    <>{body}</>
                  ) : (
                    // @ts-ignore
                    <Paper className={classes.body}>{body}</Paper>
                  )}
                  {!!showScrollUp && <ScrollUp onClick={handleScrollUp} />}
                </TabsPanel>
              ) : null}
            </main>
          </Grid>
          {isSmallScreen && (
            <MainMenu mainMenuMapping={mainMenuMapping} isMobile />
          )}
        </section>
      </Grid>
    </Grid>
  );
};
