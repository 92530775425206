import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import { actions } from '.';

export interface DefaultState {
  [competitionId: string]: {
    organisers: any;
    managers: any;
    competition: any;
    contacts: any;
  };
}

export const defaultState: DefaultState = {};

type Payload = any;

interface Meta {
  competitionId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setCompetitionManagers.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'managers'], payload, state),
    [actions.setCompetitionOrganisers.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'organisers'], payload, state),
    [actions.setCompetition.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'competition'], payload, state),
    [actions.setCompetitionContacts.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'contacts'], payload, state),
    [actions.resetCompetitionContactsState.toString()]: (state, { payload }) =>
      dissoc(String(payload.competitionId), state),
  },
  defaultState,
);

export default reducer;
