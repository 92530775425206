import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FilterTabProps, Paginated, Country } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import countriesActions from '@core/store/modules/modals/nationalities-filter-tab/actions';
import { getCountries } from '@core/store/modules/modals/nationalities-filter-tab/selectors';
import { SearchableFilterList } from '@core/components';

import { NATIONALITY_FILTER_ID, NATIONALITY_FILTER } from './constants';

type DispatchProps = {
  actions: {
    countries: typeof countriesActions;
  };
};

type StateProps = {
  isLoading: boolean;
  countries: Paginated<Country>;
};

type OwnProps = FilterTabProps & StateProps;

type Props = DispatchProps & OwnProps & StateProps;

const NationalitiesFilterTab = (props: Props) => {
  const { actions, isLoading, countries, ...rest } = props;

  function findCountryTitle(id: string) {
    return countries?.list.find((country) => country.id === Number(id)).name;
  }

  function formatCountryLabel(item: Country) {
    return item.name;
  }

  return (
    <SearchableFilterList
      isLoading={isLoading}
      filterId={NATIONALITY_FILTER_ID}
      paginatedList={countries}
      filterProps={NATIONALITY_FILTER}
      getList={actions.countries.getCountries}
      resetList={actions.countries.resetState}
      searchByQuery={actions.countries.searchCountriesByQuery}
      formatFilterLabel={findCountryTitle}
      formatLabel={formatCountryLabel}
      {...rest}
    />
  );
};

const loadingSelector = createLoadingSelector([
  countriesActions.getCountriesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
  countries: getCountries(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    countries: bindActionCreators(countriesActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NationalitiesFilterTab);
