import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments';
import { NAMESPACE } from '.';
import type {
  CompetitionResponse,
  Paginated,
  UsersResponse,
} from '@core/types';

export const getAllAssignedCompetitions = (state: State) =>
  state.pages[NAMESPACE].competitionsById;

export const getAssignedCompetitionsById = (id: number) => (state: State) =>
  state.pages[NAMESPACE].competitionsById[id];

export const getAssignedPersonsById = (id: number) => (state: State) =>
  state.pages[NAMESPACE].personsById[id];

export const getAssignmentPageUsers = (
  state: State,
): Omit<UsersResponse, 'header'> => state.pages[NAMESPACE].assignmentsPageUsers;

export const getCompetitionContactsUsers = (
  state: State,
): Omit<UsersResponse, 'header'> =>
  state.pages[NAMESPACE].competitionContactsUsers;

export const getAllCompetitions = (state: State): CompetitionResponse[] =>
  state.pages[NAMESPACE].allCompetitions;

export const getAllCompetitionsPagination = (
  state: State,
): Omit<Paginated<never>, 'list'> =>
  state.pages[NAMESPACE].allCompetitionsPagination;

export const getAssignedCompetitionsPagination = (
  state: State,
): Omit<Paginated<never>, 'list'> =>
  state.pages[NAMESPACE].assignedCompetitionsPagination;

export const areUsersLoading = createLoadingSelector([
  assignmentsActions.getUsersRequest.toString(),
]);

export const areCompetitionsLoading = createLoadingSelector([
  assignmentsActions.getAllCompetitionsRequest.toString(),
]);

export const areAssignedCompetitionsLoading = createLoadingSelector([
  assignmentsActions.getAssignedCompetitionsRequest.toString(),
  assignmentsActions.assignRegionalCompetitionManagerRequest.toString(),
  assignmentsActions.unassignRegionalCompetitionManagerRequest.toString(),
]);

export const areCompetitionAssignedPersonsLoading = createLoadingSelector([
  assignmentsActions.getCompetitionAssignedPersonsRequest.toString(),
]);
