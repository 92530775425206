import { makeStyles } from '@mui/styles';

export const useBracketStyle = makeStyles((theme) => ({
  wrapper: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
  },
  wrapperField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
  },
  bracketWrapper: {
    width: '100%',
    height: '80px',
    backgroundColor: 'white',
    border: '1px solid #C7C7CC',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
  },
  gridContainer: {
    margin: '7px',
    display: 'grid',
    alignItems: 'center',
    gap: '17px 30px',
    gridTemplateRows: 'repeat(4, 1fr)',
  },
  gridItem: {
    position: 'relative',
    minWidth: '180px',
  },
  row1: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingRight: '4px',
    fontSize: '14px',
    '&:first-child': {
      borderBottom: '1px solid #C7C7CC',
    },
  },
  row1Winner: {
    borderRight: '4px solid',
    paddingRight: 0,
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  nameAndLogoHome: {
    paddingLeft: '1px',
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
    width: '42%',
  },
  teamNameLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  teamNameLabelContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  teamNameLabelContainerIsWinner: {
    fontWeight: 700,
  },
  teamNameLabelContainerIsNotWinner: {
    opacity: 0.4,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    minWidth: '30px',
    color: 'rgb(158, 158, 158)',
  },
  svgIconField: {
    backgroundColor: 'rgb(238, 238, 238)',
    borderRadius: '50%',
    border: '1px solid rgb(189, 189, 189)',
  },
  svgIconFieldHidden: {
    display: 'none',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    textAlign: 'center',
  },
  imgHidden: {
    display: 'none',
  },
  scoreWrapper: {
    justifyContent: 'end',
    fontSize: '14px',
    display: 'flex',
    paddingRight: '1%',
    width: '50%',
  },
  scoreWrapperIsNotWinner: {
    color: 'rgba(142, 142, 147, 1)',
  },
  aggregateScore: {
    backgroundColor: 'rgb(238, 238, 238)',
    height: '98%',
    width: '8%',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
  },
  aggregateScoreHidden: {
    display: 'none',
  },
  aggregateScoreIsNotWinner: {
    color: 'rgba(142, 142, 147, 1)',
  },
  bold: {
    width: '20%',
    fontWeight: 700,
    textAlign: 'center',
  },
  bracketLine: {
    left: '100%',
    position: 'absolute',
    borderRight: '1px solid #e5e5ea',
  },
  bracketLineTop: {
    top: '25px',
    borderTop: '1px solid #e5e5ea',
  },
  bracketLineBottom: {
    bottom: '25px',
    borderBottom: '1px solid #e5e5ea',
  },
  bracketLineLong: {
    width: 'calc(50% + 32px)',
  },
  bracketLineShort: {
    width: 'calc(0% + 20px)',
  },
  roundsGridContainer: {
    margin: '0 7px 16px',
    display: 'grid',
    alignItems: 'center',
    gap: '17px 30px',
  },
  roundsGridItem: {
    textAlign: 'center',
  },
}));
