import {
  all,
  put,
  take,
  call,
  throttle,
  race,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';
import { pick } from 'ramda';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import {
  Player,
  PlayerList,
  CompetitorPlayerResponse,
  CompetitorTeamOfficialsResponse,
} from '@core/types';
import { handleApiErrors } from '@core/helpers';
import { getClubTeamOfficials } from '@core/store/modules/api/team-officials/sagas';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getCompetitorPlayers,
  addPlayersToCompetitor,
  getPreviousCompetitorPlayers,
  updatePlayersForCompetitor,
} from '@core/store/modules/api/competitor-players/sagas';
import {
  getCompetitorTeamOfficials,
  addTeamOfficialsToCompetitor,
  getPreviousCompetitorTeamOfficials,
  updateTeamOfficialsForCompetitor,
} from '@core/store/modules/api/competitor-team-officials/sagas';
import { takeLeadingByPredicate } from '@core/store/helpers/takeLeadingByPredicate';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';
import { getClubPlayers } from '@core/store/modules/api/club-players/sagas';

import { actions } from '.';

function* searchClubPlayersFlow(
  action: InferAction<typeof actions.searchClubPlayers>,
) {
  yield put(actions.searchClubPlayersRequest());
  const {
    payload: { clubId, queryParams },
  } = action;

  const { playersRequest } = (yield race({
    playersRequest: call(getClubPlayers, queryParams),
    resetInput: take(actions.resetClubPlayers),
  })) as {
    playersRequest: SagaReturnType<typeof getClubPlayers>;
    resetInput: InferAction<typeof actions.resetClubPlayers>;
  };

  if (playersRequest) {
    const { error, response } = playersRequest;

    if (!error) {
      yield put(
        actions.setClubPlayers({
          clubId,
          payload: {
            players: response._embedded.players,
            // @ts-ignore
            list: pick(['page', 'pages', 'limit', 'total'], response),
          },
        }),
      );
      yield put(actions.searchClubPlayersSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.searchClubPlayersFailure());
    }
  } else {
    yield put(actions.searchClubPlayersFailure());
  }
}

export function* watchSearchClubPlayersFlow() {
  yield throttle(500, actions.searchClubPlayers, searchClubPlayersFlow);
}

export function getPlayerInformation(
  response: CompetitorPlayerResponse,
): PlayerList {
  const players = response._embedded.competitorPlayers;

  // TODO: JB: Player interface here is used in Competitor Player context
  // (by adding properties to a player property of type Player on CompetitorPlayer interface)
  // maybe use separate interface since jerseyNumber, positionId doesn't exist on Player interface in other contexts
  return players.map<Player>((player) => ({
    ...player.player,
    jerseyNumber: player?.jerseyNumber,
    positionId: player.playerPosition?.id,
  }));
}

export function* getCompetitorPlayersFlow() {
  yield takeLeadingByPredicate(
    actions.getCompetitorPlayers,
    function* (action): Generator<Effect, any, any> {
      const {
        payload: { competitionId, competitorId, teamId },
      } = action;

      yield put(
        actions.getCompetitorPlayersRequest({
          competitionId,
          competitorId,
          teamId,
        }),
      );
      const { error, response } = (yield call(
        getCompetitorPlayers,
        competitionId,
        teamId,
      )) as SagaReturnType<typeof getCompetitorPlayers>;

      if (!error) {
        yield put(
          actions.setCompetitorPlayers({
            competitorId,
            payload: getPlayerInformation(response),
          }),
        );
        yield put(
          actions.setAddedCompetitorPlayers({
            payload: getPlayerInformation(response),
            competitorId,
          }),
        );
        yield put(
          actions.getCompetitorPlayersSuccess({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      } else {
        yield put(
          actions.getCompetitorPlayersFailure({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      }
    },
  );
}

function* addAddedCompetitorPlayersFlow() {
  while (true) {
    const {
      payload: { data, competitorId },
    } = yield take(actions.addAddedCompetitorPlayers);

    yield put(
      actions.setAddedCompetitorPlayers({
        payload: data,
        competitorId,
      }),
    );
  }
}

export function* addPlayersToCompetitorFlow() {
  yield takeLeadingByPredicate(
    actions.addPlayersAndTeamOfficialsToCompetitor,
    function* (action): Generator<Effect, any, any> {
      const {
        payload: {
          teamId,
          competitionId,
          competitorId,
          playersData,
          teamOfficialsData,
          tabId,
        },
      } = action;

      yield put(
        actions.addPlayersAndTeamOfficialsToCompetitorRequest({
          competitionId,
          competitorId,
          teamId,
        }),
      );

      const { error: addPlayersError } = playersData
        ? ((yield call(
            addPlayersToCompetitor,
            competitionId,
            teamId,
            playersData.map((player) => player.playerId),
          )) as SagaReturnType<typeof addPlayersToCompetitor>)
        : { error: undefined };

      const { error: addTeamOfficialsError } = teamOfficialsData
        ? ((yield call(
            addTeamOfficialsToCompetitor,
            competitionId,
            teamId,
            teamOfficialsData.map(
              (teamOfficial) => teamOfficial.teamOfficialId,
            ),
          )) as SagaReturnType<typeof addTeamOfficialsToCompetitor>)
        : { error: undefined };

      const { error: updateJerseyNumbersError } =
        playersData?.some((player) => player.jerseyNumber) &&
        !addPlayersError?.errors
          ? ((yield call(
              updatePlayersForCompetitor,
              competitionId,
              teamId,
              playersData,
            )) as SagaReturnType<typeof updatePlayersForCompetitor>)
          : { error: undefined };

      const error =
        addPlayersError !== undefined ||
        addTeamOfficialsError !== undefined ||
        updateJerseyNumbersError !== undefined;

      if (!error) {
        yield put(
          competitorProfileActions.getCompetitorPlayerList({
            competitionId,
            teamId,
            competitorId,
          }),
        );
        yield put(
          competitorProfileActions.getTeamOfficials({
            competitionId,
            teamId,
            competitorId,
          }),
        );
        yield put(competitionProfileActions.getCompetitors({ competitionId }));
        yield put(tabActions.removeTab({ tabId }));
        yield put(
          actions.addPlayersAndTeamOfficialsToCompetitorSuccess({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      } else {
        yield call(handleApiErrors, addPlayersError);
        yield call(handleApiErrors, addTeamOfficialsError);
        yield call(handleApiErrors, updateJerseyNumbersError);
        yield put(
          actions.addPlayersAndTeamOfficialsToCompetitorFailure({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      }
    },
  );
}

export function* updatePlayersForCompetitorFlow() {
  while (true) {
    const {
      payload: {
        teamId,
        competitionId,
        competitorId,
        players,
        teamOfficials,
        tabId,
      },
    } = yield take(actions.updatePlayersForCompetitor);

    yield put(actions.updatePlayersForCompetitorRequest());

    const { error: addPlayersError } = players
      ? yield call(updatePlayersForCompetitor, competitionId, teamId, players)
      : { error: undefined };

    const { error: addTeamOfficialsError } = teamOfficials
      ? yield call(
          updateTeamOfficialsForCompetitor,
          competitionId,
          teamId,
          teamOfficials,
        )
      : { error: undefined };

    const shouldNotCloseModal =
      (addPlayersError !== undefined && addTeamOfficialsError !== undefined) ||
      (players === null && addTeamOfficialsError !== undefined) ||
      (addPlayersError !== undefined && teamOfficials === null);

    if (!shouldNotCloseModal) {
      yield put(
        competitorProfileActions.getCompetitorPlayerList({
          competitionId,
          teamId,
          competitorId,
        }),
      );
      yield put(
        competitorProfileActions.getTeamOfficials({
          competitionId,
          teamId,
          competitorId,
        }),
      );
      yield put(competitionProfileActions.getCompetitors({ competitionId }));
      yield put(tabActions.removeTab({ tabId }));
      yield put(actions.addPlayersAndTeamOfficialsToCompetitorSuccess());
      yield call(handleApiErrors, addPlayersError);
      yield call(handleApiErrors, addTeamOfficialsError);
    } else {
      yield call(handleApiErrors, addPlayersError);
      yield call(handleApiErrors, addTeamOfficialsError);
      yield put(actions.addPlayersAndTeamOfficialsToCompetitorFailure());
    }
  }
}

export function getTeamOfficialInformation(
  response: CompetitorTeamOfficialsResponse,
) {
  const teamOfficials = response._embedded.competitorTeamOfficials;

  return teamOfficials.map((teamOfficial) => teamOfficial.teamOfficial);
}

export function* getCompetitorTeamOffcialsFlow() {
  yield takeLeadingByPredicate(
    actions.getCompetitorTeamOfficials,
    function* (action) {
      const {
        payload: { competitionId, competitorId, teamId },
      } = action;

      yield put(
        actions.getCompetitorTeamOfficialsRequest({
          competitionId,
          competitorId,
          teamId,
        }),
      );
      const { error, response } = yield call(
        getCompetitorTeamOfficials,
        competitionId,
        teamId,
      );

      if (!error) {
        yield put(
          actions.setCompetitorTeamOfficials({
            competitorId,
            payload: getTeamOfficialInformation(response),
          }),
        );
        yield put(
          actions.setAddedCompetitorTeamOfficials({
            payload: getTeamOfficialInformation(response),
            competitorId,
          }),
        );
        yield put(
          actions.getCompetitorTeamOfficialsSuccess({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      } else {
        yield put(
          actions.getCompetitorTeamOfficialsFailure({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      }
    },
  );
}

export function* searchClubTeamOfficialsFlow(
  action: InferAction<typeof actions.searchClubTeamOfficials>,
) {
  yield put(actions.searchClubTeamOfficialsRequest());
  const {
    payload: { clubId, queryParams },
  } = action;

  const { teamOfficialsRequest } = (yield race({
    teamOfficialsRequest: call(getClubTeamOfficials, queryParams),
    resetInput: take(actions.resetClubTeamOfficials),
  })) as {
    teamOfficialsRequest: SagaReturnType<typeof getClubTeamOfficials>;
    resetInput: InferAction<typeof actions.resetClubTeamOfficials>;
  };

  if (teamOfficialsRequest) {
    const { error, response } = teamOfficialsRequest;

    if (!error) {
      yield put(
        actions.setClubTeamOfficials({
          clubId,
          payload: {
            officials: response._embedded.teamOfficials,
            // @ts-ignore
            list: pick(['page', 'pages', 'limit', 'total'], response),
          },
        }),
      );
      yield put(actions.searchClubTeamOfficialsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.searchClubTeamOfficialsFailure());
    }
  } else {
    yield put(actions.searchClubTeamOfficialsFailure());
  }
}

export function* watchSearchClubTeamOfficialsFlow() {
  yield throttle(
    500,
    actions.searchClubTeamOfficials,
    searchClubTeamOfficialsFlow,
  );
}

export function* getPreviousCompetitorPlayerListFlow() {
  yield takeLeadingByPredicate(
    actions.getPreviousCompetitorPlayerList,
    function* (action): Generator<Effect, any, any> {
      const {
        payload: { competitionId, competitorId, teamId },
      } = action;

      yield put(
        actions.getPreviousCompetitorPlayerListRequest({
          competitionId,
          competitorId,
          teamId,
        }),
      );
      const { error: playersError, response: playersResponse } = (yield call(
        getPreviousCompetitorPlayers,
        competitionId,
        teamId,
      )) as SagaReturnType<typeof getPreviousCompetitorPlayers>;

      if (!playersError) {
        let competitorPlayerListTotal =
          getPlayerInformation(playersResponse).length;

        const { error: teamOfficialsError, response: teamOfficialsResponse } =
          yield call(getPreviousCompetitorTeamOfficials, competitionId, teamId);

        if (!teamOfficialsError) {
          competitorPlayerListTotal += getTeamOfficialInformation(
            teamOfficialsResponse,
          ).length;

          if (competitorPlayerListTotal > 0) {
            yield put(
              actions.setAddedCompetitorTeamOfficials({
                payload: getTeamOfficialInformation(teamOfficialsResponse),
                competitorId,
              }),
            );
          }
        }
        if (competitorPlayerListTotal > 0) {
          yield put(
            actions.setAddedCompetitorPlayers({
              payload: getPlayerInformation(playersResponse),
              competitorId,
            }),
          );
        }

        yield put(
          actions.setPreviousCompetitorPlayerListTotal({
            payload: competitorPlayerListTotal,
            competitorId,
          }),
        );
        yield put(
          actions.getPreviousCompetitorPlayerListSuccess({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      } else {
        yield put(
          actions.getPreviousCompetitorPlayerListFailure({
            competitionId,
            competitorId,
            teamId,
          }),
        );
      }
    },
  );
}

export default function* saga() {
  yield all([
    addAddedCompetitorPlayersFlow(),
    getCompetitorPlayersFlow(),
    watchSearchClubPlayersFlow(),
    addPlayersToCompetitorFlow(),
    updatePlayersForCompetitorFlow(),
    getCompetitorTeamOffcialsFlow(),
    watchSearchClubTeamOfficialsFlow(),
    getPreviousCompetitorPlayerListFlow(),
  ]);
}
