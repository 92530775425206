import React from 'react';
import { useTranslation } from 'react-i18next';
import FileCopyTwoTone from '@mui/icons-material/FileCopyTwoTone';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  actions: {
    getPrevious: any;
  };
  competitionId: number;
  teamId: number;
  competitorId: number;
  isLoading: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const PlayerListActionsBlock = (props: Props) => {
  const { actions, competitionId, teamId, competitorId, isLoading } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  function handleCopyLineupClick() {
    actions.getPrevious({
      competitionId,
      teamId,
      competitorId,
    });
  }

  return (
    <Grid className={classes.fullWidth}>
      <ButtonWithProgress
        isLoading={isLoading}
        color="primary"
        variant="outlined"
        onClick={handleCopyLineupClick}
        startIcon={<FileCopyTwoTone />}
        className={classes.button}
        fullWidth
      >
        {t('Copy previous')}
      </ButtonWithProgress>
    </Grid>
  );
};

export default PlayerListActionsBlock;
