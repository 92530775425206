import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SingleValueProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

import * as testIds from '../tests/test-ids';

type Props = SingleValueProps<AutocompleteOption>;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SingleValue = (props: Props) => {
  const classes = useStyles();

  return (
    <Typography
      data-qa={testIds.AUTOCOMPLETE_SINGLE_VALUE}
      className={classes.root}
      {...(props.innerProps as any)}
    >
      {props.children}
    </Typography>
  );
};

export default SingleValue;
