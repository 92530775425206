import { createActions } from 'redux-actions';

export const NAMESPACE = 'groupFixturesUpload';

export const actions = createActions(
  'UPLOAD_FIXTURES',
  'UPLOAD_FIXTURES_REQUEST',
  'UPLOAD_FIXTURES_FAILURE',
  'UPLOAD_FIXTURES_SUCCESS',
  'EXPORT_FIXTURES',
  'EXPORT_FIXTURES_REQUEST',
  'EXPORT_FIXTURES_FAILURE',
  'EXPORT_FIXTURES_SUCCESS',
  { prefix: NAMESPACE },
) as unknown as {
  uploadFixtures: UnknownAction;
  uploadFixturesRequest: RequestLifecycleAction;
  uploadFixturesFailure: RequestLifecycleAction;
  uploadFixturesSuccess: RequestLifecycleAction;
  exportFixtures: UnknownAction;
  exportFixturesRequest: RequestLifecycleAction;
  exportFixturesFailure: RequestLifecycleAction;
  exportFixturesSuccess: RequestLifecycleAction;
};
