import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterCheckboxList } from '@core/components';
import { getCompetitionStatus } from '@core/helpers';
import { CompetitionStatus, FilterTabProps } from '@core/types';
import {
  COMPETITION_STATUS_FILTER,
  COMPETITION_STATUS_FILTER_ID,
} from '../constants';

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

const CompetitionStatusFilter = ({ filters, ...rest }: FilterTabProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const filterValues =
    filters[COMPETITION_STATUS_FILTER_ID]?.filterValues || [];

  const formatFilterLabel = (val: string) =>
    getCompetitionStatus(t, val as CompetitionStatus);

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Status')}
      </Typography>
      <FilterCheckboxList
        shifted
        list={Object.values(CompetitionStatus)}
        filterProps={COMPETITION_STATUS_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default CompetitionStatusFilter;
