import { useEffect, useRef, useState } from 'react';
import { uuid } from '@core/helpers';
import update from 'immutability-helper';

import { Slot, Slots } from '@core/pages/team-grids-edit/types';
import { Competitor } from '@core/types';

const initialZeroSlots: Slots = [];

export const isSlotEditable = (slot: Slot) => slot.editable === true;

export const isSlotsEditable = (slots: Slots) =>
  slots.some((slot) => slot?.editable);

export const createEmptySlots = (numberOfTeams: number): Slots =>
  Array(numberOfTeams)
    .fill(undefined)
    .map<Slot>(() => ({ competitor: null, editable: true, id: uuid() }));

export type MoveSlot = (id: Slot['id'], atIndex: number) => void;

export type FindSlot = (id: Slot['id']) => { slot: Slot; index: number };

export type UpdateSlot = (id: Slot['id'], competitor: Competitor) => void;

const useSlots = (initialState: Slots, onChange: (slots: Slots) => void) => {
  const [slots, setSlots] = useState<Slots>(initialState ?? initialZeroSlots);

  const setSlotsAndNotifyRef = useRef<(newSlots: Slots) => void>();
  setSlotsAndNotifyRef.current = (newSlots) => {
    // state change in onChange callback must be batched with setSlots
    setSlots(newSlots);
    onChange(newSlots);
  };

  useEffect(() => {
    setSlotsAndNotifyRef.current(initialState ?? initialZeroSlots);
  }, [initialState]);

  const moveSlot: MoveSlot = (id, atIndex) => {
    const { slot: slotToMove, index: oldIndex } = findSlot(id);
    const newIndex = atIndex;
    const lowerIndex = Math.min(oldIndex, newIndex);
    const upperIndex = Math.max(oldIndex, newIndex);
    const isSlotMovedDown = oldIndex < newIndex;
    const offset = isSlotMovedDown ? 1 : 0;
    const shiftableSlots = slots
      .slice(lowerIndex + offset, upperIndex + offset)
      .filter(isSlotEditable);

    setSlotsAndNotifyRef.current(
      slots.map((slot, index) => {
        const isIndexInRange = lowerIndex <= index && index <= upperIndex;

        if (isIndexInRange) {
          if (!isSlotEditable(slot)) {
            return slot;
          }

          if (index === newIndex) {
            return slotToMove;
          }

          return shiftableSlots.shift();
        }

        return slot;
      }),
    );
  };

  const findSlot: FindSlot = (id) => {
    const slotIndex = slots.findIndex((s) => s.id === id);

    return {
      slot: slots[slotIndex],
      index: slotIndex,
    };
  };

  const updateSlot: UpdateSlot = (id, competitor) => {
    setSlotsAndNotifyRef.current(
      update(slots, {
        $apply: (items: Slots) =>
          items.map((item: Slot) =>
            item.id === id ? { ...item, competitor } : item,
          ),
      }),
    );
  };

  return {
    slots,
    setSlots: setSlotsAndNotifyRef.current,
    moveSlot,
    findSlot,
    updateSlot,
  };
};

export type UseSlots = typeof useSlots;

export default useSlots;
