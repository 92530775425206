import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  TableRow,
  TableCell,
  TableContainer,
  Box,
  IconButton,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FullScreenSpinner } from '@core/components';
import { STANDING_SETTINGS_FIELD_NAMES } from '@volleyball/business-components/stage-standings-settings-template/constants';
import { FormValues } from '@volleyball/business-components/stage-standings-settings-template/helpers/default-values';
import TableWithActionsRow from './table-with-actions-row';
import TableWithActionsContainer from './table-with-actions-container';
import type { Column } from './types';

interface Action {
  helpers?: any;
  label: string;
  onClick: (selectedIds: Array<number>, arrayHelpers: any) => void;
}

interface OwnProps {
  bulkAction?: Action;
  title?: string | React.ReactElement;
  isLoading?: boolean;
  disabled: boolean;
  titleComponent?: boolean;
  noStickyHeader?: boolean;
  infiniteScroll?: any;
  maxHeight?: number;
  connectedTable?: boolean;
  noPaper?: boolean;
  titleRow?: boolean;
  emptyMessage?: string;
  arrayHelpers?: any;
  fieldName: keyof FormValues;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  // scrollable: {
  //   maxHeight: ({ maxHeight }) => maxHeight,
  //   overflowY: 'auto',
  // },
  tableContainer: {
    // maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : '753px'),
  },
  // noPaper: {
  //   margin: theme.spacing(0, -3),
  // },
  // selectWrapper: {
  //   display: 'flex',
  //   flexFlow: 'row nowrap',
  //   flex: '1 1 auto',
  //   '& > *': {
  //     display: 'flex',
  //     flexFlow: 'row nowrap',
  //     flex: '1 1 auto',
  //   },
  // },
  // select: {
  //   paddingTop: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  // },
  root: {
    '@global': {
      'tr > *:first-child': {
        paddingLeft: ({ titleRow }) =>
          titleRow ? theme.spacing(1.5) : theme.spacing(0.5),
      },
    },
  },
  // dragging: {
  //   opacity: 0.5,
  // },
  iconButton: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
  iconDisabled: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
}));

const TableWithActions = (props: Props) => {
  const { isLoading, disabled, emptyMessage, fieldName } = props;
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles(props);
  const [items, setItems] = React.useState<Array<any>>([]);

  // @ts-ignore
  const data = values[fieldName];

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleCellClick = (_: Event, id: number) => {
    const updatedItems = [...items];
    const itemToUpdate = updatedItems.find((field) => {
      return field?.id === id;
    });
    itemToUpdate.isSelected = !itemToUpdate.isSelected;

    setItems(updatedItems);
    setFieldValue(fieldName, updatedItems);
  };

  const handleReorder = (index: number, isUp: boolean) => {
    const updatedItems = [...items];
    const indexToSwapWith = isUp ? index - 1 : index + 1;
    [updatedItems[index], updatedItems[indexToSwapWith]] = [
      updatedItems[indexToSwapWith],
      updatedItems[index],
    ];

    setItems(updatedItems);
    setFieldValue(fieldName, updatedItems);
  };

  const actionsColRender = (index: number, colData: any) => (
    <Box display="flex" justifyContent="flex-end" width={100}>
      <IconButton
        className={clsx(classes.iconButton, { [classes.iconDisabled]: !index })}
        onClick={() => handleReorder(index, true)}
      >
        <ArrowUpward />
      </IconButton>
      <IconButton
        className={clsx(classes.iconButton, {
          [classes.iconDisabled]: index === colData.length - 1,
        })}
        onClick={() => handleReorder(index, false)}
      >
        <ArrowDownward />
      </IconButton>
    </Box>
  );

  // TODO: use component props to generate needed columns
  let columns: Array<Column>;
  if (fieldName === STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders) {
    columns = [
      {
        name: 'columns',
        label: 'Column',
        render: (rowData: any) => (
          <Box sx={{ lineHeight: 1 }}> {rowData.title || rowData.column} </Box>
        ),
      },
      {
        name: 'actions',
        width: 100,
        render: (rowData: any) => {
          if (disabled || rowData.isDisabled) {
            return null;
          }
          const index = items?.findIndex(
            ({ id }: { id: any }) => id === rowData?.id,
          );

          return actionsColRender(index, items);
        },
      },
    ];
  } else if (fieldName === STANDING_SETTINGS_FIELD_NAMES.standingTableRanks) {
    columns = [
      {
        name: 'columns',
        label: 'Tie breaker',
        align: 'left',
        render: (rowData: any) => <Box> {rowData.title} </Box>,
      },
      {
        name: 'actions',
        width: 100,
        render: (rowData: any) => {
          if (disabled || rowData.isDisabled) {
            return null;
          }
          const index = items?.findIndex(
            ({ id }: { id: any }) => id === rowData?.id,
          );

          return actionsColRender(index, items);
        },
      },
    ];
  } else {
    columns = [];
  }

  const renderTable = () => {
    if (items.length === 0 && !emptyMessage) {
      return null;
    }

    return (
      <TableContainer className={classes.tableContainer}>
        <TableWithActionsContainer>
          <>
            {items?.length <= 0 && emptyMessage ? (
              <TableRow>
                <TableCell align="center" colSpan={20} padding="none">
                  {emptyMessage}
                </TableCell>
              </TableRow>
            ) : (
              items?.map((rowData: any) => (
                <TableWithActionsRow
                  columns={columns}
                  key={rowData.id}
                  rowData={rowData}
                  isItemSelected={rowData.isSelected}
                  callback={handleCellClick}
                  disabled={disabled}
                />
              ))
            )}
          </>
        </TableWithActionsContainer>
      </TableContainer>
    );
  };

  return isLoading ? <FullScreenSpinner /> : renderTable();
};

export default TableWithActions;
