import { all, put, call, takeEvery } from 'redux-saga/effects';
import { handleApiErrors } from '@core/helpers';
import {
  getGroupStandings,
  modifyGroupStandingsCompetitorPoint,
} from '@core/api/group-standings';

import * as actions from './actions';
import * as ACTION_TYPES from './constants';

export function* updateGroupStandingsCompetitorPointsFlowWorker(
  action: ReturnType<
    typeof actions.updateGroupStandingsCompetitorPointAdjustment
  >,
) {
  const {
    payload: { stageId, groupId, competitorId, data },
  } = action;

  yield put(actions.updateGroupStandingsCompetitorPointAdjustmentRequest());

  const { error } = yield call(
    modifyGroupStandingsCompetitorPoint,
    groupId,
    competitorId,
    data,
  );

  if (!error) {
    yield put(actions.updateGroupStandingsCompetitorPointAdjustmentSuccess());
    yield put(actions.getGroupStandings({ groupId: stageId }));
  } else {
    yield put(actions.updateGroupStandingsCompetitorPointAdjustmentFailure());
    yield call(handleApiErrors, error);
  }
}

export function* updateGroupStandingsCompetitorPointsFlowWatcher() {
  yield takeEvery(
    ACTION_TYPES.UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT,
    updateGroupStandingsCompetitorPointsFlowWorker,
  );
}

export function* getGroupStandingsWorker(
  action: ReturnType<typeof actions.getGroupStandings>,
) {
  const { payload } = action;

  yield put(actions.getGroupStandingsRequest());
  const { error, response } = yield call(getGroupStandings, payload.groupId);

  if (!error) {
    yield put(
      actions.setGroupStandings({
        groupId: payload.groupId,
        payload: {
          ...response._embedded,
          header: response.header,
        },
      }),
    );
    yield put(actions.getGroupStandingsSuccess());
  } else {
    yield put(
      actions.setGroupStandings({ groupId: payload.groupId, payload: error }),
    );
    yield put(actions.getGroupStandingsFailure());
  }
}

export function* getGroupStandingsWatcher() {
  yield takeEvery(ACTION_TYPES.GET_GROUP_STANDINGS, getGroupStandingsWorker);
}

export default function* saga() {
  yield all([
    getGroupStandingsWatcher(),
    updateGroupStandingsCompetitorPointsFlowWatcher(),
  ]);
}
