import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { ModalTypes } from '@volleyball/types';

import { actions } from '.';

export interface DefaultState {
  isOpen: boolean;
  errors: any;
  params: any;
  type: ModalTypes;
}

export const defaultState: DefaultState = {
  isOpen: false,
  errors: null,
  params: null,
  type: null,
};

const reducer = handleActions(
  {
    [actions.setModalErrors.toString()]: (state, { payload }) =>
      assoc('errors', payload, state),
    [actions.closeModal.toString()]: () => defaultState,
    [actions.setModalState.toString()]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [actions.clearModalErrors.toString()]: assoc('errors', null),
  },
  defaultState,
);

export default reducer;
