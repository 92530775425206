import React, { useEffect } from 'react';
import {
  Grid,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  Paper,
  TablePagination,
  TableCellProps,
  TableContainer,
  Box,
  CircularProgress,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';

import { FullScreenSpinner } from '@core/components';
import { TABLE_WITH_SELECT, TABLE_CHECKBOX } from './tests/test-ids';

import TableToolbar from './table-toolbar';

interface Action {
  label: string;
  onClick: (selectedIds: Array<number>) => void;
}

interface Column {
  name: string;
  label?: React.ReactNode;
  render: (rowData: any) => any;
  align?: TableCellProps['align'];
}

interface OwnProps {
  data: any;
  bulkAction: Action;
  title: string | React.ReactElement;
  columns: Array<Column>;
  isLoading?: boolean;
  titleComponent?: boolean;
  noStickyHeader?: boolean;
  infiniteScroll?: any;
  maxHeight?: number;
  connectedTable?: boolean;
  noPaper?: boolean;
  titleRow?: boolean;
  emptyMessage?: string;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  scrollable: {
    maxHeight: ({ maxHeight }) => maxHeight,
    overflowY: 'auto',
  },
  tableContainer: {
    maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : '753px'),
  },
  noPaper: {
    margin: theme.spacing(0, -3),
  },
  root: {
    '@global': {
      'tr > *:first-child': {
        paddingLeft: ({ titleRow }) =>
          titleRow ? theme.spacing(1.5) : theme.spacing(0.5),
      },
    },
  },
}));

const TableWithSelect = (props: Props) => {
  const ROWS_PER_PAGE = 100;
  const {
    data,
    bulkAction,
    title,
    columns,
    isLoading,
    titleComponent,
    infiniteScroll,
    noStickyHeader,
    connectedTable,
    noPaper,
    titleRow,
    emptyMessage,
  } = props;
  const [selected, setSelected] = React.useState<Array<number>>([]);
  const [page, setPage] = React.useState(0);
  const [paginatedData, setData] = React.useState(
    data.length ? data.slice(0, ROWS_PER_PAGE) : [],
  );
  const numSelected = selected.length;
  const availableData = data.filter((row: any) => !row.isDisabled);
  const availableRowCount = availableData ? availableData.length : data.length;
  const classes = useStyles(props);

  useEffect(() => {
    if (!infiniteScroll) {
      setSelected([]);
    }

    setPaginatedData(page);
  }, [data]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = availableData
        ? availableData.map((n: any) => n.id)
        : data.map((n: any) => n.id);
      setSelected(newSelected);

      return;
    }

    setSelected([]);
  };

  const handleCellClick = (_: Event, id: number) => () => {
    const selectedIndex = selected.indexOf(id);
    const newSelected =
      selectedIndex === -1
        ? [...selected, id]
        : selected.filter((item) => item !== id);

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleActionClick = () => {
    bulkAction.onClick(selected);
    setSelected([]);
  };

  const setPaginatedData = (newPage: number) => {
    const startIndex = newPage * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    const newData = data.slice(startIndex, endIndex);

    setData(() => newData);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPaginatedData(newPage);
    setPage(() => newPage);
  };

  const handleLoadNext = () => {
    infiniteScroll.loadNext(infiniteScroll?.pagination?.page + 1);
  };

  const renderTable = () => {
    if (data.length === 0 && !emptyMessage) {
      return null;
    }

    const renderTableContainer = () => (
      <Table stickyHeader={!noStickyHeader} className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  numSelected > 0 && numSelected < availableRowCount
                }
                checked={
                  availableRowCount > 0 && numSelected === availableRowCount
                }
                onChange={handleSelectAllClick}
                data-qa={TABLE_CHECKBOX}
              />
            </TableCell>
            {columns &&
              columns.map((column: Column) => (
                <TableCell key={column.name} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData?.length <= 0 && emptyMessage ? (
            <TableRow>
              <TableCell align="center" colSpan={20}>
                {emptyMessage}
              </TableCell>
            </TableRow>
          ) : (
            paginatedData?.map((rowData: any) => {
              const isItemSelected = isSelected(rowData.id);

              return (
                <TableRow key={rowData.id}>
                  <TableCell
                    padding="checkbox"
                    onClick={
                      !rowData.isDisabled
                        ? // eslint-disable-next-line no-restricted-globals
                          handleCellClick(event, rowData.id)
                        : undefined
                    }
                  >
                    <Checkbox
                      checked={isItemSelected}
                      disabled={rowData.isDisabled}
                      data-qa={TABLE_CHECKBOX}
                    />
                  </TableCell>
                  {columns &&
                    columns.map((column: Column) => (
                      <TableCell key={column.name} align={column.align}>
                        {column.render(rowData)}
                      </TableCell>
                    ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    );

    if (infiniteScroll) {
      return (
        <TableContainer>
          <Grid id="scrollableDiv" className={classes.scrollable}>
            <InfiniteScroll
              hasMore={
                infiniteScroll?.pagination?.page <
                infiniteScroll?.pagination?.pages
              }
              next={handleLoadNext}
              loader={
                <Box display="flex" flex="1" justifyContent="center" p={2}>
                  <CircularProgress size={20} />
                </Box>
              }
              dataLength={paginatedData?.length}
              scrollableTarget="scrollableDiv"
            >
              {renderTableContainer()}
            </InfiniteScroll>
          </Grid>
        </TableContainer>
      );
    }

    return (
      <TableContainer className={classes.tableContainer}>
        {renderTableContainer()}
      </TableContainer>
    );
  };

  return (
    <Grid
      className={clsx({
        [classes.noPaper]: noPaper,
      })}
      data-qa={TABLE_WITH_SELECT}
    >
      {noPaper ? (
        <>
          <TableToolbar
            numSelected={numSelected}
            title={title}
            titleComponent={titleComponent}
            connectedTable={connectedTable}
            titleRow={titleRow}
            action={{
              label: bulkAction.label,
              onClick: handleActionClick,
            }}
          />

          {isLoading && !infiniteScroll?.pagination ? (
            <FullScreenSpinner />
          ) : (
            renderTable()
          )}
          {data.length > ROWS_PER_PAGE && (
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={handleChangePage}
            />
          )}
        </>
      ) : (
        <Paper>
          <>
            <TableToolbar
              numSelected={numSelected}
              title={title}
              titleComponent={titleComponent}
              connectedTable={connectedTable}
              titleRow={titleRow}
              action={{
                label: bulkAction.label,
                onClick: handleActionClick,
              }}
            />
            {isLoading && !infiniteScroll?.pagination ? (
              <FullScreenSpinner />
            ) : (
              renderTable()
            )}
            {data.length > ROWS_PER_PAGE && (
              <TablePagination
                component="div"
                count={data.length}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
              />
            )}
          </>
        </Paper>
      )}
    </Grid>
  );
};

export default TableWithSelect;
