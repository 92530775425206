import restService from '@core/api/rest-service';
import { MatchDataHistoryResponse } from '@core/types';

export const getMatchDataHistory = (
  matchId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<MatchDataHistoryResponse>(
    `/v1/matches/${matchId}/matchDataHistory`,
    {
      params: queryParams,
    },
  );
