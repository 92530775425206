import React from 'react';
import { ArrowDropDown, Lock } from '@mui/icons-material';
import {
  Grow,
  Box,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Button,
  Grid,
  CircularProgress,
  Divider,
  Typography,
  Tooltip,
  Theme,
  alpha,
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { makeStyles } from '@mui/styles';

interface OptionProps<T> {
  text: string;
  disabled?: boolean;
  value: T;
}

interface OwnProps<T = any> {
  options: Array<OptionProps<T>>;
  selected?: T;
  isLoading?: boolean;
  maxWidthMobile?: boolean;
  disabled?: boolean;
  locked?: boolean;
  lockedTooltip?: string;
  label?: string;
  translateFunction?: (value: any) => string;
  onSelect: (value: T) => void;
}

type Button = Omit<ButtonProps, 'onSelect' | 'children'>;
type Props<T> = Button & OwnProps<T>;

const useStyles = makeStyles<Theme, Props<any>>((theme: Theme) => ({
  paper: {
    zIndex: theme.zIndex.modal,
  },
  wrapper: {
    position: 'relative',
    cursor: ({ disabled, locked }) => !disabled && !locked && 'pointer',
    '&:hover': {
      backgroundColor: ({ disabled, locked }) =>
        !disabled && !locked && alpha(theme.palette.primary.main, 0.04),
    },
  },
  maxWidthMobile: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  content: {
    marginLeft: theme.spacing(1),
    color: ({ locked }) =>
      locked ? theme.palette.text.secondary : theme.palette.primary.main,
  },
  contentDisabled: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
    color: ({ locked }) => locked && theme.palette.text.secondary,
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
  },
  divider: {
    backgroundColor: ({ locked }) =>
      !locked && alpha(theme.palette.primary.main, 0.5),
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  contentBox: {
    borderWidth: '1px',
    borderColor: ({ locked }) =>
      locked ? 'rgba(0, 0, 0, 0.12)' : alpha(theme.palette.primary.main, 0.5),
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    padding: '8px',
    margin: '-8px 0px',
    '&:hover': {
      borderColor: ({ locked }) => !locked && theme.palette.primary.main,
    },
  },
  lockIcon: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
  },
}));

type Value = string | number | any;

export default function SummaryBlockDropdown<T extends Value>(props: Props<T>) {
  const {
    options,
    onSelect,
    selected,
    isLoading,
    disabled,
    maxWidthMobile,
    label,
    translateFunction,
    locked,
    lockedTooltip,
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles(props);

  const handleMenuItemClick =
    (val: T) => (_: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setOpen(false);
      onSelect && onSelect(val);
    };

  const handleToggle = () => {
    if (disabled || locked) return;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const renderContent = () => {
    if (disabled) {
      return (
        <Grid className={classes.contentDisabled}>
          <Typography color="textSecondary" variant="caption">
            {label}
          </Typography>
          <Typography className={classes.value} variant="body2">
            {translateFunction
              ? translateFunction(selected)
              : (selected as string)}
          </Typography>
        </Grid>
      );
    }

    const renderContentText = () => (
      <Grid className={classes.contentWrapper}>
        <Divider orientation="vertical" absolute className={classes.divider} />
        <Grid className={classes.content}>
          <Typography variant="caption">{label}</Typography>
          <Typography className={classes.value} variant="body2">
            {translateFunction
              ? translateFunction(selected)
              : (selected as string)}
          </Typography>
        </Grid>
        {locked ? (
          <Lock className={classes.lockIcon} />
        ) : (
          <ArrowDropDown className={classes.arrow} />
        )}
      </Grid>
    );

    return (
      <Grid
        onClick={handleToggle}
        ref={anchorRef}
        className={classes.contentBox}
      >
        {lockedTooltip ? (
          <Tooltip title={lockedTooltip} arrow>
            {renderContentText()}
          </Tooltip>
        ) : (
          renderContentText()
        )}
      </Grid>
    );
  };

  const renderMenuItem = (option: any) => (
    <MenuItem
      key={option.value.toString()}
      disabled={option.disabled}
      selected={option.value === selected}
      onClick={
        option.value !== selected ? handleMenuItemClick(option.value) : null
      }
    >
      {option.text}
    </MenuItem>
  );

  const renderOption = (option: any) => {
    if (option.tooltipText && option.disabled)
      return (
        <Tooltip
          key={option.value.toString()}
          title={option.tooltipText}
          placement="bottom"
          arrow
        >
          <Grid>{renderMenuItem(option)}</Grid>
        </Tooltip>
      );

    return renderMenuItem(option);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        xs={12}
        className={maxWidthMobile ? classes.maxWidthMobile : ''}
      >
        <Grid className={classes.wrapper}>
          {isLoading ? (
            <Box ml={2} mb={-1}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            renderContent()
          )}
          <Popper
            className={classes.paper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options &&
                        options.map(
                          (option: OptionProps<T>) =>
                            option && renderOption(option),
                        )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
}
