import { ClubModel } from '@core/types';
import { State } from '@core/store';

export const getClubsById = (
  state: State,
  ids: Array<number>,
): Array<ClubModel> => {
  // TODO: must solve static combineReducers issue in module
  // @ts-ignore
  const clubs = state?.api?.clubsList;

  return ids.map((id: number) => clubs[id]);
};
