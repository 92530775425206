import { AutocompleteOption, SelectOption } from '@core/types';

function getOption(value: SelectOption) {
  return value?.value ? value : null;
}

function getOptionByValue(
  value: string | number,
  options: Array<AutocompleteOption>,
) {
  return !options ? null : options.find((option) => option.value === value);
}

export function getSelectedValue({
  useOptionAsValue,
  value,
  options,
}: {
  useOptionAsValue: boolean;
  value: any;
  options: Array<AutocompleteOption>;
}) {
  return useOptionAsValue ? getOption(value) : getOptionByValue(value, options);
}

export function getValueFromOption(option: SelectOption) {
  return option ? (option as AutocompleteOption)?.value : null;
}
