import { pathOr, path } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { Filters, Person } from '@core/types';
import { State } from '@core/store';
import { NAMESPACE as personsNamespace } from '@core/store/modules/api/persons/actions';

import { NAMESPACE } from './constants';
import { DefaultState } from './reducer';

const getRoot = (state: State): DefaultState =>
  pathOr(null, ['pages', NAMESPACE], state);
const getId = (state: State, id: number) => id;

export const getKitSetList = (
  state: State,
  { clubId }: { clubId: number },
): DefaultState[number]['kitSetList'] =>
  path(['pages', NAMESPACE, clubId, 'kitSetList'], state);

export const getClubById = createSelector(
  getRoot,
  getId,
  (root, id): DefaultState[number]['club'] => {
    return pathOr(null, [id, 'club'], root);
  },
);

export const getClub = (
  state: State,
  { clubId }: { clubId: number },
): DefaultState[number]['club'] => {
  return path(['pages', NAMESPACE, clubId, 'club'], state);
};

export const getTeams = (
  state: State,
  { clubId }: { clubId: number },
): DefaultState[number]['teams'] =>
  path(['pages', NAMESPACE, clubId, 'teams'], state);

export const getMembersPaginated = (
  state: State,
  { clubId }: { clubId: number },
): DefaultState[number]['members'] =>
  path(['pages', NAMESPACE, clubId, 'members'], state);

const getMembersIds = (
  state: State,
  { clubId }: { clubId: number },
): DefaultState[number]['members']['list'] => {
  return pathOr(null, ['pages', NAMESPACE, clubId, 'members', 'list'], state);
};
const getPersonsList = (state: State): { [id: number]: Person } =>
  pathOr(null, ['api', personsNamespace], state);

export const getMembersList = createSelector(
  getMembersIds,
  getPersonsList,
  (membersIds, membersPersons) => {
    return membersIds ? membersIds.map((id: number) => membersPersons[id]) : [];
  },
);

export const getMembersSearchQuery = (
  state: State,
  { clubId }: { clubId: number },
): string => pathOr('', ['pages', NAMESPACE, clubId, 'membersQuery'], state);

export const getMembersFilters = (
  state: State,
  { clubId }: { clubId: number },
): Filters => pathOr({}, ['pages', NAMESPACE, clubId, 'filters'], state);
