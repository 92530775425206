import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

interface OwnProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  label: string | number | React.ReactElement;
  'data-qa'?: string;
}

const Toggle = (props: OwnProps) => {
  const { checked, disabled, label, onChange, 'data-qa': dataQa } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch checked={checked} disabled={disabled} data-qa={dataQa} />
        }
        label={label}
        onChange={onChange}
      />
    </FormGroup>
  );
};

export default Toggle;
