import { put, call, Effect } from 'redux-saga/effects';

import * as teamOfficialRoleAPI from '@core/api/team-official-role';

import { actions } from '.';

export function* getTeamOfficialRole(): Generator<Effect, any, any> {
  yield put(actions.getTeamOfficialRoleRequest());
  const response = (yield call(
    teamOfficialRoleAPI.getTeamOfficialRoles,
  )) as InferApi<typeof teamOfficialRoleAPI.getTeamOfficialRoles>;

  if (!response.error) {
    yield put(actions.getTeamOfficialRoleSuccess());
  } else {
    yield put(actions.getTeamOfficialRoleFailure());
  }

  return response;
}
