import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Paper } from '@mui/material';
import { Form, Formik } from 'formik';

import teamCreationActions from '@core/pages/create-team/store/actions';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { getSelectedTeam } from '@core/store/modules/tabs/competitor-addition/selectors';
import { actions as competitorActions } from '@core/store/modules/tabs/competitor-addition';
import { CompetitionResponse, TeamModel, CompetitorStatus } from '@core/types';
import { State } from '@core/store';
import { getValueFromOption } from '@core/components/autocomplete-field/helpers';

import CompetitorAssignmentActions from './components/competitor-assignment-actions';
import CompetitorAssignmentSummaryBlock from './components/competitor-assignment-summary-block';
import CompetitorAssignmentExpansionPanelGroup from './components/competitor-assignment-expansion-panel-group';
import { getInitialValues, getValidationSchema } from './helpers';
import { COMPETITOR_FIELDS, CompetitorFormValues } from './constants';

interface OwnProps {
  competitionId: number;
  tabId: string;
}

interface DispatchProps {
  actions: {
    competitor: typeof competitorActions;
    teamCreation: typeof teamCreationActions;
  };
}

interface StateProps {
  competition: CompetitionResponse;
  selectedTeam: TeamModel;
}

type Props = OwnProps & StateProps & DispatchProps;

const CompetitorAssignment = (props: Props) => {
  const { competitionId, tabId, actions, competition, selectedTeam } = props;
  const { t } = useTranslation();

  useEffect(() => {
    actions.competitor.clearCompetitorState({ competitionId });
    actions.teamCreation.clearState();
  }, [actions]);

  const handleSubmit = (values: CompetitorFormValues) => {
    actions.competitor.createCompetitor({
      competitionId,
      teamId: selectedTeam.id,
      tabId,
      data: {
        defaultVenueId: getValueFromOption(
          values[COMPETITOR_FIELDS.defaultVenueOption],
        ),
        alternativeVenueId: getValueFromOption(
          values[COMPETITOR_FIELDS.alternativeVenueOption],
        ),
        teamColorSetId: values[COMPETITOR_FIELDS.teamColorSetId],
        title: values[COMPETITOR_FIELDS.title],
        internationalTitle: values[COMPETITOR_FIELDS.internationalTitle],
        status: CompetitorStatus.CONFIRMED,
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(selectedTeam)}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(t)}
    >
      <Form noValidate>
        <Paper>
          <CompetitorAssignmentSummaryBlock competition={competition} />
          <CompetitorAssignmentExpansionPanelGroup
            competitionId={competitionId}
            selectedTeam={selectedTeam}
          />
        </Paper>
        <CompetitorAssignmentActions
          tabId={tabId}
          competitionId={competitionId}
          selectedTeam={selectedTeam}
        />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { competitionId } = ownProps;

  return {
    selectedTeam: getSelectedTeam<TeamModel>(state, { competitionId }),
    competition: getCompetitionDetailsById(competitionId)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitor: bindActionCreators(competitorActions, dispatch),
    teamCreation: bindActionCreators(teamCreationActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitorAssignment);
