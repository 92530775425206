import type { State } from '@core/store';
import { createCoreSelector } from '@core/store/helpers';

const getSports = (state: State, ids: number[]) => {
  const sports = state.api.sportsList;

  return ids?.map((id) => sports?.[id]).filter(Boolean) ?? [];
};

export const getSportsById = createCoreSelector(
  [getSports],
  (sports) => sports,
);
