import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { STAGE_PROFILE_DETAILS } from '@core/pages/competition-profile/tests/test-ids';
import { Group } from '@core/types';

interface OwnProps {
  id: number;
  stage: Group;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  detailsBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
}));

const StageProfileSummary = ({ stage, id }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Status'),
      value: stage.status,
    },
    {
      label: t('Type'),
      value: t(stage.stageType) as string,
    },
    {
      label: t('Teams'),
      value: stage.numberOfTeams,
    },
    {
      label: t('Start date'),
      value: stage.startDate,
      dateField: true,
    },
    {
      label: t('End date'),
      value: stage.endDate,
      dateField: true,
    },
  ];

  return (
    <Grid className={classes.detailsBlock} data-qa={STAGE_PROFILE_DETAILS}>
      <SummaryBlock values={getInfoBlocks()} />
    </Grid>
  );
};

export default StageProfileSummary;
