import { NAMESPACE } from './constants';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getCompetitionLevelsRequest } from './actions';
import type {
  APIErrorsModel,
  CompetitionLevel,
  CompetitionLevelsList,
} from '@core/types';
import type { State } from '@core/store';

export const getCompetitionsLevelsList = (
  state: State,
): CompetitionLevelsList => state.pages[NAMESPACE].allLevels;

export const getUpdatingCompetitionLevel = (state: State): CompetitionLevel =>
  state.pages[NAMESPACE].levelBeingModified;

export const isNewLevelBeingCreated = (state: State): boolean =>
  state.pages[NAMESPACE].isNewLevelBeingCreated;

export const getLevelErrors = (state: State): APIErrorsModel =>
  state.pages[NAMESPACE].errors;

export const areCompetitionLevelsLoading = createLoadingSelector([
  getCompetitionLevelsRequest.toString(),
]);
