import React from 'react';
import {
  Grid,
  Toolbar,
  Typography,
  Tooltip,
  Button,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { TOOLBAR_BUTTON } from './tests/test-ids';

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flex: '1 1 100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleRow: {
    textTransform: 'uppercase',
  },
  titleRowWrapper: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '12px 24px',
  },
  connectedTableRow: {
    borderTop: 'none',
  },
  button: {
    color: theme.palette.common.white,
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const TableToolbar = (props: any) => {
  const classes = useToolbarStyles();
  const { t } = useTranslation();
  const {
    numSelected,
    action,
    title,
    titleComponent,
    connectedTable,
    titleRow,
  } = props;

  const renderTitle = () =>
    titleComponent ? (
      title
    ) : (
      <Typography
        className={clsx(classes.title, {
          [classes.titleRow]: titleRow,
        })}
        variant={titleRow ? 'caption' : 'subtitle1'}
        color={titleRow ? 'textSecondary' : 'inherit'}
        id="tableTitle"
      >
        {title}
      </Typography>
    );

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.connectedTableRow]: connectedTable,
        [classes.titleRowWrapper]: titleRow,
      })}
    >
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} {t('selected')}
        </Typography>
      ) : (
        renderTitle()
      )}
      {numSelected > 0 ? (
        <Tooltip title={action.label}>
          <Button
            onClick={action.onClick}
            className={classes.button}
            data-qa={TOOLBAR_BUTTON}
          >
            {action.label}
          </Button>
        </Tooltip>
      ) : (
        <Grid />
      )}
    </Toolbar>
  );
};

export default TableToolbar;
