import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { uuid } from '@core/helpers';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { TabTitle } from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createURL } from '@core/helpers';
import { getCompetitorByTeamId } from '@core/store/modules/tabs/competitor-profile/selectors';
import { Competitor, TeamModel } from '@core/types';
import { State } from '@core/store';
import paths from '@core/routes/paths';

import TeamDetailsBlock from './team-details-block';
import TeamNameBlock from './team-name-block';
import TeamVenuesBlock from './team-venues-block';

interface OwnProps {
  teamId: number;
  competitionId: number;
  competitorId: number;
}

interface StateProps {
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

const GeneralInformationTab = (props: Props) => {
  const { competitorDetails, actions, competitorId, competitionId, teamId } =
    props;
  const { t } = useTranslation();

  const canEditCompetitor = !!competitorDetails?.competitor?._links?.patch;

  const handleEdit = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.competitorGeneralInformationEdit),
      title: `${t('Team general information')} ${competitorId}`,
      props: { competitorId, competitionId, teamId },
      id: uuid(),
    });

  const tabActions = canEditCompetitor && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleEdit}
      startIcon={<EditOutlinedIcon />}
    >
      {t('Edit')}
    </Button>
  );

  return (
    competitorDetails && (
      <>
        <TabTitle actions={tabActions} />
        <TeamDetailsBlock competitorDetails={competitorDetails} />
        <TeamNameBlock competitorDetails={competitorDetails} />
        <TeamVenuesBlock
          alternativeVenue={competitorDetails?.competitor?.alternativeVenue}
          defaultVenue={competitorDetails?.competitor?.defaultVenue}
        />
      </>
    )
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitorDetails: getCompetitorByTeamId(state, ownProps?.competitorId),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInformationTab);
