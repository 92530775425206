import { put, call, Effect } from 'redux-saga/effects';

import * as getMatchSetsAPI from '@core/api/match-sets';

import {
  MatchSet,
  PatchMatchSetPayload,
  PostMatchSetPayload,
} from '@core/types';
import { actions } from '.';

export function* getMatchSets(matchId: number): Generator<Effect, any, any> {
  yield put(actions.getMatchSetsRequest());
  const response = (yield call(
    getMatchSetsAPI.getMatchSets,
    matchId,
  )) as InferApi<typeof getMatchSetsAPI.getMatchSets>;

  if (!response.error) {
    yield put(
      actions.setMatchSets({
        matchId,
        matchSets: response.response._embedded.items,
      }),
    );
    yield put(actions.getMatchSetsSuccess());
  } else {
    yield put(actions.getMatchSetsFailure());
  }

  return response;
}

export function* createMatchSet(
  matchId: number,
  matchSet: PostMatchSetPayload,
): Generator<Effect, any, any> {
  yield put(actions.createMatchSetRequest({ id: matchId }));
  const response = (yield call(
    getMatchSetsAPI.createMatchSet,
    matchId,
    matchSet,
  )) as InferApi<typeof getMatchSetsAPI.createMatchSet>;

  if (!response.error) {
    yield put(actions.createMatchSetSuccess({ id: matchId }));
  } else {
    yield put(actions.createMatchSetFailure({ id: matchId }));
  }

  return response;
}

export function* updateMatchSets(
  matchId: number,
  matchSets: Array<MatchSet>,
): Generator<Effect, any, any> {
  yield put(actions.updateMatchSetsRequest({ id: matchId }));
  const response = (yield call(
    getMatchSetsAPI.updateMatchSets,
    matchId,
    matchSets,
  )) as InferApi<typeof getMatchSetsAPI.updateMatchSets>;

  if (!response.error) {
    yield put(actions.updateMatchSetsSuccess({ id: matchId }));
  } else {
    yield put(actions.updateMatchSetsFailure({ id: matchId }));
  }

  return response;
}
export function* deleteSet(matchSetId: number): Generator<Effect, any, any> {
  yield put(actions.deleteSetRequest({ id: matchSetId }));
  const response = (yield call(
    getMatchSetsAPI.deleteSet,
    matchSetId,
  )) as InferApi<typeof getMatchSetsAPI.deleteSet>;

  if (!response.error) {
    yield put(actions.deleteSetSuccess({ id: matchSetId }));
  } else {
    yield put(actions.deleteSetFailure({ id: matchSetId }));
  }

  return response;
}

export function* updateSet(
  matchSetId: number,
  matchSet: MatchSet,
): Generator<Effect, any, any> {
  yield put(actions.updateSetRequest({ id: matchSetId }));

  const payload: PatchMatchSetPayload = {
    awayCompetitorScore: matchSet.awayCompetitorScore,
    duration: matchSet.duration,
    homeCompetitorScore: matchSet.homeCompetitorScore,
  };
  const response = (yield call(
    getMatchSetsAPI.updateSet,
    matchSetId,
    payload,
  )) as InferApi<typeof getMatchSetsAPI.updateSet>;

  if (!response.error) {
    yield put(actions.updateSetSuccess({ id: matchSetId }));
  } else {
    yield put(actions.updateSetFailure({ id: matchSetId }));
  }

  return response;
}
