import React from 'react';
import { Grid } from '@mui/material';

import { PlayerKitColors } from '@core/types';
import { isValue } from '@core/helpers';

import DisabledPlayerKit from './components/disabled-player-kit';
import PlayerKit from './components/player-kit';
import * as testIds from './tests/test-ids';

interface OwnProps {
  playerKitColors?: PlayerKitColors;
  width?: string | number;
  height?: string | number;
  disabled?: boolean;
}

type Prop = OwnProps;

/**
 *  @deprecated not used anymore?
 * TODO: JB: remove me?
 * */
const PlayerKitPreview = (props: Prop) => {
  const { width, height, playerKitColors, disabled } = props;
  const showDisabled = disabled || !isValue(playerKitColors);

  return (
    <Grid data-qa={testIds.PLAYER_KIT_PREVIEW}>
      {showDisabled ? (
        <Grid data-qa={testIds.PLAYER_KIT_PREVIEW_DISABLED}>
          <DisabledPlayerKit width={width} height={height} />
        </Grid>
      ) : (
        <Grid data-qa={testIds.PLAYER_KIT_PREVIEW_COLORED}>
          <PlayerKit
            playerKitColors={playerKitColors}
            width={width}
            height={height}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlayerKitPreview;
