import restService from '@core/api/rest-service';
import {
  TeamOfficialsByPersonResponse,
  TeamOfficialsResponse,
} from '@core/types';

export const getClubTeamOfficialsBySport = (
  clubId: number,
  sportId: number,
  params: any,
) =>
  restService.get<TeamOfficialsResponse>(
    `/v1/sports/${sportId}/clubs/${clubId}/teamOfficials`,
    {
      params,
    },
  );

export const getClubTeamOfficials = (queryParams: any = { page: 1 }) =>
  restService.get<TeamOfficialsResponse>(`/v1/teamOfficials`, {
    params: queryParams,
  });

export const createTeamOfficial = (
  clubId: number,
  personId: number,
  teamOfficialRoleId: Array<number>,
  sportId: number,
) =>
  restService.post(`/v1/teamOfficials`, {
    clubId,
    personId,
    sportId,
    teamOfficialRoleId,
  });

export const getTeamOfficialListByPerson = (personId: number) =>
  restService.get<TeamOfficialsByPersonResponse>(
    `/v1/persons/${personId}/teamOfficials`,
  );

export const deleteTeamOfficial = (teamOfficialId: number) =>
  restService.delete(`/v1/teamOfficials/${teamOfficialId}`);
