import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FilterTabProps } from '@core/types';
import DatePickerField from './components/date-picker-field';
import {
  MEMBER_BIRTH_DATE_FROM_FILTER,
  MEMBER_BIRTH_DATE_TO_FILTER,
} from './constants';
import MemberTypeFilter from './components/member-type-filter';
import GenderFilter from '../gender-filter';

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type OwnProps = FilterTabProps;
type Props = OwnProps;

const MemberFilterTab = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.label}
        >
          {t('Date of birth')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerField
          label={t('Born after')}
          filterProps={MEMBER_BIRTH_DATE_FROM_FILTER}
          {...props}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerField
          label={t('Born before')}
          filterProps={MEMBER_BIRTH_DATE_TO_FILTER}
          {...props}
        />
      </Grid>
      <Grid item xs={12}>
        <MemberTypeFilter {...props} />
      </Grid>
      <Grid item xs={12}>
        <GenderFilter {...props} />
      </Grid>
    </Grid>
  );
};

export default MemberFilterTab;
