import { take, all, call, put, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as refereeCategoriesAPI from '@core/api/referee-categories';
import { RefereeCategory } from '@volleyball/types';

import * as ACTION_TYPES from './constants';
import * as refereeCategoriesActions from './actions';

export function* getRefereeCategories(): Generator<Effect, any, any> {
  yield put(refereeCategoriesActions.getRefereeCategoriesRequest());
  const response = yield call(refereeCategoriesAPI.getRefereeCategories);

  if (!response.error) {
    yield put(refereeCategoriesActions.getRefereeCategoriesSuccess());
  } else {
    yield put(refereeCategoriesActions.getRefereeCategoriesFailure());
  }

  return response;
}

export function* getRefereeCategoriesFlow() {
  while (true) {
    yield take(ACTION_TYPES.GET_REFEREE_CATEGORIES);
    const { error, response } = yield call(getRefereeCategories);

    if (!error) {
      const refereeCategories = response._embedded.refereeCategories;

      yield put(
        refereeCategoriesActions.setRefereeCategories(refereeCategories),
      );
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export function* createRefereeCategory(
  data: RefereeCategory,
): Generator<Effect, any, any> {
  yield put(refereeCategoriesActions.createRefereeCategoryRequest());
  const response = yield call(refereeCategoriesAPI.createRefereeCategory, data);

  if (!response.error) {
    yield put(refereeCategoriesActions.createRefereeCategorySuccess());
  } else {
    yield put(refereeCategoriesActions.createRefereeCategoryFailure());
  }

  return response;
}

export function* createRefereeCategoryFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.CREATE_REFEREE_CATEGORY);

    yield put(refereeCategoriesActions.setErrors(null));
    const { error } = yield call(createRefereeCategory, payload);

    if (!error) {
      yield put(refereeCategoriesActions.setNewBeingCreated(false));
      yield put(refereeCategoriesActions.getRefereeCategories());
    } else {
      if (error.errors) {
        yield put(refereeCategoriesActions.setErrors(error.errors));
      } else {
        yield call(handleApiErrors, error);
      }
    }
  }
}

export function* updateRefereeCategory(
  id: number,
  data: RefereeCategory,
): Generator<Effect, any, any> {
  yield put(refereeCategoriesActions.updateRefereeCategoryRequest());
  const response = yield call(
    refereeCategoriesAPI.updateRefereeCategory,
    id,
    data,
  );

  if (!response.error) {
    yield put(refereeCategoriesActions.updateRefereeCategorySuccess());
  } else {
    yield put(refereeCategoriesActions.updateRefereeCategoryFailure());
  }

  return response;
}

export function* updateRefereeCategoryFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.UPDATE_REFEREE_CATEGORY);

    yield put(refereeCategoriesActions.setErrors(null));
    const { error } = yield call(
      updateRefereeCategory,
      payload.id,
      payload.data,
    );

    if (!error) {
      yield put(refereeCategoriesActions.setBeingModified(null));
      yield put(refereeCategoriesActions.getRefereeCategories());
    } else {
      if (error.errors) {
        yield put(refereeCategoriesActions.setErrors(error.errors));
      } else {
        yield call(handleApiErrors, error);
      }
    }
  }
}

export function* deleteRefereeCategory(
  id: number,
): Generator<Effect, any, any> {
  yield put(refereeCategoriesActions.deleteRefereeCategoryRequest());
  const response = yield call(refereeCategoriesAPI.deleteRefereeCategory, id);

  if (!response.error) {
    yield put(refereeCategoriesActions.deleteRefereeCategorySuccess());
  } else {
    yield put(refereeCategoriesActions.deleteRefereeCategoryFailure());
  }

  return response;
}

export function* deleteRefereeCategoryFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.DELETE_REFEREE_CATEGORY);
    const { error } = yield call(deleteRefereeCategory, payload);

    if (!error) {
      yield put(refereeCategoriesActions.getRefereeCategories());
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    getRefereeCategoriesFlow(),
    createRefereeCategoryFlow(),
    updateRefereeCategoryFlow(),
    deleteRefereeCategoryFlow(),
  ]);
}
