import { omit } from 'ramda';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Theme } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Field, FieldProps } from 'formik';
import { makeStyles } from '@mui/styles';
import dayjs, { Dayjs } from 'dayjs';
import { CustomerResponse } from '@core/types/api';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { State } from '@core/store';
import { convertCustomerDateFormatToDayjsFormat } from '@core/helpers';
import { Tooltip } from '@core/components';
import { DEFAULT_DATE_FORMAT } from '@core/constants/common';
import { DATE_PICKER_FIELD_INPUT } from './tests/test-ids';

interface StyleProps {
  width?: string;
  tooltipText?: string;
  withTooltipMargin?: boolean;
}

interface OwnProps extends Partial<DatePickerProps<Dayjs>> {
  label: string;
  name: string;
  disablePast?: boolean;
  clearable?: boolean;
  className?: string;
  tooltipText?: string;
  width?: string;
  withTooltipMargin?: boolean;
  afterChange?: (value: string | null) => void;
  forceFormat?: string;
  inputVariant?: any;
  placeholder?: string | null;
  required?: boolean;
  'data-qa'?: string;
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

type Props = StateProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: ({ width }: StyleProps) => width || '100%',
    marginRight: ({ tooltipText, withTooltipMargin }: StyleProps) => {
      const tooltipIconWith = 34;
      const marginWithoutTooltipText = withTooltipMargin ? tooltipIconWith : 0;

      return tooltipText ? theme.spacing(1) : marginWithoutTooltipText;
    },
  },
}));

const DatePickerField = ({
  name,
  width,
  tooltipText,
  withTooltipMargin,
  forceFormat,
  currentCustomer,
  afterChange,
  placeholder,
  label,
  required,
  'data-qa': dataQa,
  ...restDatePickerProps
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ width, tooltipText, withTooltipMargin });
  const dateFormat =
    currentCustomer?.dateFormat || forceFormat || DEFAULT_DATE_FORMAT;
  const { format: DATE_FORMAT } =
    convertCustomerDateFormatToDayjsFormat(dateFormat);
  const labelToDisplay = required ? `${label} *` : label;

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;

        const handleChange = (dayjsDate: Dayjs) => {
          if (!dayjsDate) {
            setFieldValue(
              name,
              dayjsDate?.format?.('YYYY-MM-DD') ?? null,
              true,
            );

            return;
          }

          const parsed = dayjs(dayjsDate, DATE_FORMAT, true);
          const parsedValue = parsed.isValid()
            ? parsed.format('YYYY-MM-DD')
            : dayjs(null).format('YYYY-MM-DD');

          setFieldValue(name, parsedValue, true);
        };

        const isFieldTouched = meta.touched;
        const currentError = isFieldTouched && meta.error;
        const helperText = currentError ?? '';

        let value = null;
        if (field.value && typeof field.value === 'string') {
          value = dayjs(field.value, 'YYYY-MM-DD', true);
        } else if (field.value) {
          value = dayjs(field.value);
          setFieldValue(name, dayjs(field.value).format('YYYY-MM-DD'), false);
        }

        const restProps = omit(['dispatch'], restDatePickerProps);

        return (
          <Box display="flex" alignItems="center" width="100%">
            <DatePicker
              {...restProps}
              slotProps={{
                textField(textFieldProps) {
                  return {
                    ...textFieldProps,
                    name,
                    label: labelToDisplay,
                    className: classes.field,
                    variant: 'outlined',
                    placeholder:
                      placeholder || DATE_FORMAT?.toLowerCase() || '',
                    helperText: helperText,
                    error: Boolean(currentError),
                    onBlur: field.onBlur,
                    inputProps: {
                      ...textFieldProps.inputProps,
                      'data-qa': dataQa ?? DATE_PICKER_FIELD_INPUT,
                    },
                  };
                },
                actionBar: {
                  actions: ['cancel', 'accept', 'today'],
                },
              }}
              localeText={{
                cancelButtonLabel: t('Cancel'),
                okButtonLabel: t('OK'),
                todayButtonLabel: t('Today'),
              }}
              format={DATE_FORMAT}
              className={classes.field}
              onChange={handleChange}
              value={dayjs(value)}
            />
            {tooltipText && <Tooltip title={tooltipText} />}
          </Box>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps, null)(DatePickerField);
