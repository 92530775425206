export const NAMESPACE = 'groupFixtureSettings';

export const GET_GROUP_FIXTURE_SETTINGS_REQUEST = `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_REQUEST`;
export const GET_GROUP_FIXTURE_SETTINGS_SUCCESS = `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_SUCCESS`;
export const GET_GROUP_FIXTURE_SETTINGS_FAILURE = `${NAMESPACE}/GET_GROUP_FIXTURE_SETTINGS_FAILURE`;

export const UPDATE_GROUP_FIXTURE_SETTINGS_REQUEST = `${NAMESPACE}/UPDATE_GROUP_FIXTURE_SETTINGS_REQUEST`;
export const UPDATE_GROUP_FIXTURE_SETTINGS_SUCCESS = `${NAMESPACE}/UPDATE_GROUP_FIXTURE_SETTINGS_SUCCESS`;
export const UPDATE_GROUP_FIXTURE_SETTINGS_FAILURE = `${NAMESPACE}/UPDATE_GROUP_FIXTURE_SETTINGS_FAILURE`;

export const CREATE_GROUP_FIXTURE_SETTINGS_REQUEST = `${NAMESPACE}/CREATE_GROUP_FIXTURE_SETTINGS_REQUEST`;
export const CREATE_GROUP_FIXTURE_SETTINGS_SUCCESS = `${NAMESPACE}/CREATE_GROUP_FIXTURE_SETTINGS_SUCCESS`;
export const CREATE_GROUP_FIXTURE_SETTINGS_FAILURE = `${NAMESPACE}/CREATE_GROUP_FIXTURE_SETTINGS_FAILURE`;
