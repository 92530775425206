import restService from '@core/api/rest-service';
import {
  GroupStandingsPointAjustmentRequest,
  GroupStandingsResponse,
} from '@core/types';

export const getGroupStandings = (groupId: number) =>
  restService.get<GroupStandingsResponse>(`/v1/groups/${groupId}/standings`);

export const modifyGroupStandingsCompetitorPoint = (
  groupId: number,
  competitorId: number,
  data: GroupStandingsPointAjustmentRequest,
) =>
  restService.post<GroupStandingsResponse>(
    `/v1/groups/${groupId}/standings/${competitorId}/pointAdjustment`,
    data,
  );
