import type { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { TreeItem2Label } from '@mui/x-tree-view/TreeItem2';

const Label = ({ children, ...rest }: PropsWithChildren) => (
  <TreeItem2Label
    {...rest}
    sx={{
      wordBreak: 'break-word',
    }}
  >
    <Typography variant="body2">{children}</Typography>
  </TreeItem2Label>
);

export default Label;
