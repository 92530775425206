import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  title?: string;
  actions?: any;
  rootStyles?: object;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ title, rootStyles }: Partial<OwnProps>) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: title ? 'space-between' : 'flex-end',
    alignItems: 'center',
    marginBottom: title ? theme.spacing(4) : theme.spacing(3),
    ...rootStyles,
  }),
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type Props = OwnProps;

const Tab = ({ title, actions, rootStyles }: Props) => {
  const classes = useStyles({ title, rootStyles });

  return (
    <Grid className={classes.root}>
      {!!title && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {title}
        </Typography>
      )}
      {actions}
    </Grid>
  );
};

export default Tab;
