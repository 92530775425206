import restService from '@core/api/rest-service';
import {
  DisciplinarySanctionsResponse,
  NewDisciplinarySanction,
  SanctionStatus,
} from '@core/types';

export const getDisciplinarySanctions = (
  competitionId: number,
  page: {
    page?: number;
  },
) =>
  restService.get<DisciplinarySanctionsResponse>(
    `/v1/competitions/${competitionId}/disciplinarySanctions`,
    {
      params: page,
    },
  );

export const createDisciplinarySanction = (
  competitionId: number,
  data: NewDisciplinarySanction,
) =>
  restService.post(
    `/v1/competitions/${competitionId}/disciplinarySanctions`,
    data,
  );

export const updateDisciplinarySanctionStatus = (
  competitionId: number,
  sanctionId: number,
  data: { status: SanctionStatus },
) =>
  restService.post(
    `/v1/competitions/${competitionId}/disciplinarySanctions/${sanctionId}/changeStatus`,
    data,
  );

export const updateDisciplinarySanction = (
  competitionId: number,
  sanctionId: number,
  data: any,
) =>
  restService.patch(
    `/v1/competitions/${competitionId}/disciplinarySanctions/${sanctionId}`,
    data,
  );
