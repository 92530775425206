import { createActions } from 'redux-actions';

import { Group } from '@core/types';

export const NAMESPACE = 'groupsList';

export const actions = createActions(
  'GET_GROUPS',
  'GET_GROUPS_REQUEST',
  'GET_GROUPS_SUCCESS',
  'GET_GROUPS_FAILURE',
  'SET_GROUPS',

  'GET_GROUP',
  'GET_GROUP_REQUEST',
  'GET_GROUP_SUCCESS',
  'GET_GROUP_FAILURE',
  'SET_GROUP',

  'CREATE_GROUP',
  'CREATE_GROUP_REQUEST',
  'CREATE_GROUP_SUCCESS',
  'CREATE_GROUP_FAILURE',

  'UPDATE_GROUP',
  'UPDATE_GROUP_REQUEST',
  'UPDATE_GROUP_SUCCESS',
  'UPDATE_GROUP_FAILURE',

  'DELETE_GROUP',
  'DELETE_GROUP_REQUEST',
  'DELETE_GROUP_SUCCESS',
  'DELETE_GROUP_FAILURE',

  'UPDATE_GROUP_SETTINGS',
  'UPDATE_GROUP_SETTINGS_REQUEST',
  'UPDATE_GROUP_SETTINGS_SUCCESS',
  'UPDATE_GROUP_SETTINGS_FAILURE',

  'SET_GROUP_BEING_MODIFIED',
  'SET_ACTIVE_STEP',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getGroupsRequest: RequestLifecycleAction;
  getGroupsSuccess: RequestLifecycleAction;
  getGroupsFailure: RequestLifecycleAction;
  setGroups: IdentityActionFunction<Array<Group>>;

  getGroupRequest: RequestLifecycleAction;
  getGroupSuccess: RequestLifecycleAction;
  getGroupFailure: RequestLifecycleAction;
  setGroup: IdentityActionFunction<Group>;

  createGroupRequest: RequestLifecycleAction;
  createGroupSuccess: RequestLifecycleAction;
  createGroupFailure: RequestLifecycleAction;

  updateGroupRequest: RequestLifecycleAction;
  updateGroupSuccess: RequestLifecycleAction;
  updateGroupFailure: RequestLifecycleAction;

  deleteGroupRequest: RequestLifecycleAction;
  deleteGroupSuccess: RequestLifecycleAction;
  deleteGroupFailure: RequestLifecycleAction;

  updateGroupSettingsRequest: RequestLifecycleAction;
  updateGroupSettingsSuccess: RequestLifecycleAction;
  updateGroupSettingsFailure: RequestLifecycleAction;

  /** @deprecated TODO: JB: not used yet */
  setGroupBeingModified: UnknownAction;
  /** @deprecated TODO: JB: not used yet */
  setActiveStep: IdentityActionFunction<number>;
};
