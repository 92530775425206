import React, { useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { withLayout } from '@core/components/layout';
import {
  Paginated,
  Filters,
  Sorting,
  MatchModel,
  LayoutProps,
} from '@core/types';
import MatchesTable from '@core/components/match-table';
import {
  getAppointments,
  getAppointmentsList,
  getFilters,
  getSearchQuery,
  getSorting,
} from '@core/store/modules/pages/appointments/selectors';
import { actions as appointmentsActions } from '@core/store/modules/pages/appointments';
import { State } from '@volleyball/store';

interface StateProps {
  appointments: Paginated<MatchModel['id']>;
  appointmentsList: Array<MatchModel>;
  filters: Filters;
  isLoading: boolean;
  query: string;
  sorting: Sorting;
}

interface DispatchProps {
  actions: {
    appointments: typeof appointmentsActions;
  };
}

type Props = StateProps & DispatchProps;

const AppointmentsPage = (props: Props) => {
  const { t } = useTranslation();
  const { actions, appointments, appointmentsList, isLoading, query, sorting } =
    props;

  useEffect(() => {
    actions.appointments.getAppointments({});

    return () => {
      actions.appointments.resetAppointments();
    };
  }, [actions.appointments]);

  const handleDataReload = ({ page }: { page: number }) => {
    actions.appointments.getAppointments({ page });
  };

  const handleSearch = ({
    query: newQuery,
    page,
  }: {
    query: string;
    page: number;
  }) => {
    actions.appointments.setSearchQuery(newQuery);
    actions.appointments.searchByQuery({ page: 1 });
  };

  const handleSorting = (newSorting: Sorting) => {
    actions.appointments.setSorting(newSorting);
    actions.appointments.getAppointments({ page: 1 });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t('My appointments')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MatchesTable
          handleSearch={handleSearch}
          handleSorting={handleSorting}
          isGlobal
          isLoading={isLoading}
          matches={appointments}
          matchesList={appointmentsList}
          onReload={handleDataReload}
          query={query}
          sorting={sorting}
          tableFilter={null}
        />
      </Grid>
    </Grid>
  );
};

const getAppointmentsLoadingSelector = createLoadingSelector([
  appointmentsActions.getAppointmentsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    appointments: bindActionCreators(appointmentsActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  appointments: getAppointments(state),
  appointmentsList: getAppointmentsList(state),
  isLoading: getAppointmentsLoadingSelector(state),
  query: getSearchQuery(state),
  filters: getFilters(state),
  sorting: getSorting(state),
});

export default compose<React.FunctionComponent<LayoutProps>>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(AppointmentsPage);
