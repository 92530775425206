import React from 'react';
import { Grid } from '@mui/material';

import { HeaderWithCallToAction } from '@core/components';

import * as testIds from '../tests/test-ids';

interface Props {
  title: string;
  table: React.ReactElement;
  dialog: React.ReactElement;
  button: React.ReactElement;
}

const PageLayout = (props: Props) => {
  const { table, dialog, button, title } = props;

  return (
    <Grid data-qa={testIds.TABLE_WITH_CRUD} container spacing={3}>
      <Grid item xs={12}>
        <HeaderWithCallToAction title={title} button={button} />
      </Grid>
      <Grid item xs={12}>
        {table}
      </Grid>
      {dialog}
    </Grid>
  );
};

export default PageLayout;
