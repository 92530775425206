import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { CompetitionResponse } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

const getIds = (state: State, ids: Array<number>) => ids;

export const getCompetitionsList = (
  state: State,
): { [competitionId: number]: CompetitionResponse } =>
  pathOr(null, ['api', NAMESPACE], state);

export const getCompetitionsById = createSelector(
  getIds,
  getCompetitionsList,
  (ids, competitions) => ids.map((id: number) => competitions[id]),
);
