import { FIELD_NAMES } from '../constants';

export interface FormValues {
  [FIELD_NAMES.cards]: string;
  [FIELD_NAMES.cardType]: string;
  [FIELD_NAMES.matchesSuspended]: string;
  [FIELD_NAMES.offenderType]: string;
  [FIELD_NAMES.resetCounter]: boolean;
}

export const getDefaultValues = (): FormValues => ({
  [FIELD_NAMES.cards]: '',
  [FIELD_NAMES.cardType]: '',
  [FIELD_NAMES.matchesSuspended]: '',
  [FIELD_NAMES.offenderType]: '',
  [FIELD_NAMES.resetCounter]: false,
});

export default getDefaultValues;
