import React from 'react';
import { uuid } from '@core/helpers';
import deepEqual from 'fast-deep-equal/react';
import { FastField, FieldProps, FieldInputProps } from 'formik';
import { isNil, none } from 'ramda';
import { Box, Button, Typography, Theme } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { Remove, Add } from '@mui/icons-material';
import clsx from 'clsx';

import { ErrorMessage } from '@core/components';

interface StyleProps {
  width?: string;
}

interface OwnProps {
  name: string;
  label?: string;
  min?: number;
  max?: number;
  smallInput?: boolean;
  required?: boolean;
  width?: string;
  forceError?: boolean;
}

type Props = Omit<
  TextFieldProps,
  keyof Omit<FieldInputProps<any>, 'onChange' | 'name'> | 'variant'
> &
  OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(0.75),
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
  input: {
    width: ({ width }: StyleProps) => width || 100,
    backgroundColor: theme.palette.common.white,
    '& input': {
      textAlign: 'center',
      '-moz-appearance': 'textfield',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  smallInput: {
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
  },
  button: {
    minWidth: 20,
    fontSize: 24,
    padding: theme.spacing(0.625),
  },
}));

const NumberField = ({
  onChange,
  name,
  label,
  max,
  min,
  smallInput,
  'data-qa': dataQa,
  disabled,
  required,
  width,
  forceError,
}: Props) => {
  const classes = useStyles({ width });
  const minValue = min || 0;
  const maxValue = max || Infinity;

  // force FastField to re-render on NumberField props change otherwise it will not re-render (even if it's render prop reference is different)
  const key = uuid();

  return (
    <FastField key={key} name={name}>
      {({ field, form, meta }: FieldProps) => {
        const value = Number(field.value);
        const { setFieldValue, setFieldTouched } = form;
        const shouldShowFieldError = forceError
          ? none(isNil, [meta.error])
          : none(isNil, [meta.error, meta.touched || null]);

        const isDecrementDisabled = () => value <= minValue;
        const isIncrementDisabled = () => value >= maxValue;

        const handleOnChange = (event: React.ChangeEvent<any>) => {
          onChange && onChange(event);
          field.onChange(event);
        };

        const handleIncrement = () => {
          const newValue = `${value + 1}`;
          onChange &&
            onChange({
              target: { name, type: 'number', value: newValue },
            } as any);
          setFieldValue(name, +newValue, true);
          setFieldTouched(name, true, true);
        };

        const handleDecrement = () => {
          const newValue = `${value - 1}`;
          onChange &&
            onChange({
              target: { name, type: 'number', value: newValue },
            } as any);
          setFieldValue(name, +newValue, true);
          setFieldTouched(name, true, true);
        };

        return (
          <>
            {label && (
              <Typography className={classes.label}>{`${label}${
                required && ' *'
              }`}</Typography>
            )}
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Button
                  classes={{ root: classes.button }}
                  disabled={disabled || isDecrementDisabled()}
                  onClick={handleDecrement}
                  variant="outlined"
                >
                  <Remove />
                </Button>
              </Box>
              <Box>
                <TextField
                  name={name}
                  value={field.value ?? ''}
                  type="number"
                  error={shouldShowFieldError}
                  onChange={handleOnChange}
                  disabled={disabled}
                  onBlur={field.onBlur}
                  variant="outlined"
                  className={clsx(classes.input, {
                    [classes.smallInput]: smallInput,
                  })}
                  data-qa={dataQa}
                  inputProps={{
                    min: minValue,
                    max: maxValue,
                    step: 1,
                  }}
                />
              </Box>
              <Box ml={1}>
                <Button
                  classes={{ root: classes.button }}
                  disabled={disabled || isIncrementDisabled()}
                  onClick={handleIncrement}
                  variant="outlined"
                >
                  <Add />
                </Button>
              </Box>
            </Box>
            <ErrorMessage name={name} />
          </>
        );
      }}
    </FastField>
  );
};

export default React.memo(NumberField, deepEqual);
