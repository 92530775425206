import { combineReducers } from 'redux';

import { sortByKey } from '@core/helpers';

import competitionProfileReducer from '@core/store/modules/tabs/competition-profile/reducer';
import { NAMESPACE as competitionProfile } from '@core/store/modules/tabs/competition-profile';
import { NAMESPACE as editKitSet } from '@core/store/modules/tabs/edit-kit-set';
import editKitSetReducer from '@core/store/modules/tabs/edit-kit-set/reducer';
import competitorAdditionReducer from '@core/store/modules/tabs/competitor-addition/reducer';
import { NAMESPACE as competitorAddition } from '@core/store/modules/tabs/competitor-addition';
import competitionContactsEditReducer from '@core/store/modules/tabs/competition-contacts-edit/reducer';
import { NAMESPACE as competitionContactsEdit } from '@core/store/modules/tabs/competition-contacts-edit';
import { NAMESPACE as matchProfile } from '@core/store/modules/tabs/match-profile';
import matchProfileReducer from '@core/store/modules/tabs/match-profile/reducer';
import teamGridReducer from '@core/store/modules/tabs/team-grid/reducer';
import { NAMESPACE as teamGrid } from '@core/store/modules/tabs/team-grid';
import { NAMESPACE as matchSheetManagement } from '@core/store/modules/tabs/match-sheet-management';
import matchSheetManagementReducer from '@core/store/modules/tabs/match-sheet-management/reducer';
import { NAMESPACE as competitorProfile } from '@core/store/modules/tabs/competitor-profile';
import competitorProfileTabReducer from '@core/store/modules/tabs/competitor-profile/reducer';
import competitorKitSetReducer from '@core/store/modules/tabs/competitor-kit-set/reducer';
import { NAMESPACE as competitorKitSet } from '@core/store/modules/tabs/competitor-kit-set';
import competitorGeneralInformationEditReducer from '@core/store/modules/tabs/competitor-general-information-edit/reducer';
import { NAMESPACE as competitorGeneralInformationEdit } from '@core/store/modules/tabs/competitor-general-information-edit';
import { NAMESPACE as playerListManagement } from '@core/store/modules/tabs/player-list-management';
import playerListManagementTabReducer from '@core/store/modules/tabs/player-list-management/reducer';

import { NAMESPACE as matchDataManagement } from './match-data-management';
import matchDataManagementReducer from './match-data-management/reducer';
import stateFixtureSettingsReducer from './stage-fixture-settings/reducer';
import { NAMESPACE as stateFixtureSettings } from './stage-fixture-settings';
import imposeSanctionReducer from './impose-sanction/reducer';
import { NAMESPACE as imposeSanction } from './impose-sanction';

export default combineReducers(
  sortByKey({
    [competitionContactsEdit]: competitionContactsEditReducer,
    [competitionProfile]: competitionProfileReducer,
    [competitorAddition]: competitorAdditionReducer,
    [competitorGeneralInformationEdit]: competitorGeneralInformationEditReducer,
    [competitorKitSet]: competitorKitSetReducer,
    [competitorProfile]: competitorProfileTabReducer,
    [editKitSet]: editKitSetReducer,
    [imposeSanction]: imposeSanctionReducer,
    [matchDataManagement]: matchDataManagementReducer,
    [matchProfile]: matchProfileReducer,
    [matchSheetManagement]: matchSheetManagementReducer,
    [playerListManagement]: playerListManagementTabReducer,
    [stateFixtureSettings]: stateFixtureSettingsReducer,
    [teamGrid]: teamGridReducer,
  }),
);
