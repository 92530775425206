import React from 'react';
import { Table, TableBody, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  children: JSX.Element;
  titleComponent?: boolean;
  noStickyHeader?: boolean;
  maxHeight?: number;
  titleRow?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Partial<Props>>((theme: Theme) => ({
  root: {
    '@global': {
      'tr > *:first-child': {
        paddingLeft: ({ titleRow }) =>
          titleRow ? theme.spacing(1.5) : theme.spacing(0.5),
      },
    },
  },
  showActions: {
    '& tr:hover div[id="actions"]': {
      visibility: 'inherit',
    },
  },
}));

const TableWithActionsContainer = ({
  noStickyHeader,
  children,
  maxHeight,
  titleRow,
}: Props) => {
  const classes = useStyles({ maxHeight, titleRow });

  return (
    <Table stickyHeader={!noStickyHeader} className={classes.root}>
      <TableBody className={classes.showActions}>{children}</TableBody>
    </Table>
  );
};

export default TableWithActionsContainer;
