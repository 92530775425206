import { pathOr, path, isEmpty } from 'ramda';

import { getVenuesById } from '@core/pages/venues/store/api/selectors';
import { VenueModel, Paginated } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getSelectedTeam = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => {
  return pathOr<T>(null, ['tabs', NAMESPACE, competitionId, 'team'], state);
};

export const getKitSets = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) => pathOr<T>(null, ['tabs', NAMESPACE, competitionId, 'kitSets'], state);

export const getTeamsSearchList = <T>(
  state: State,
  { competitionId }: { competitionId: number },
) =>
  pathOr<T>(null, ['tabs', NAMESPACE, competitionId, 'teamSearchList'], state);

export const getDefaultVenueOptions = (
  state: State,
  { competitionId }: { competitionId: number },
): Paginated<VenueModel> => {
  const venues: Paginated<number> = {
    ...path(['tabs', NAMESPACE, competitionId, 'defaultVenuesList'], state),
  };

  return venues && !isEmpty(venues)
    ? { ...venues, list: getVenuesById(state, venues?.list) }
    : null;
};

export const getAlternativeVenueOptions = (
  state: State,
  { competitionId }: { competitionId: number },
): Paginated<VenueModel> => {
  const venues: Paginated<number> = {
    ...path(['tabs', NAMESPACE, competitionId, 'alternativeVenuesList'], state),
  };

  return venues && !isEmpty(venues)
    ? { ...venues, list: getVenuesById(state, venues?.list) }
    : null;
};

export const getPreviousCompetitorPlayers = (
  state: State,
  { competitionId }: { competitionId: number },
): Array<number> =>
  path(['tabs', NAMESPACE, competitionId, 'previousCompetitorPlayers'], state);

export const getPreviousCompetitorTeamOfficials = (
  state: State,
  { competitionId }: { competitionId: number },
): Array<number> =>
  path(
    ['tabs', NAMESPACE, competitionId, 'previousCompetitorTeamOfficials'],
    state,
  );
