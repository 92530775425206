import React, { ReactNode } from 'react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0.5, 1),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginRight: ({ noMargin }: Props) => (noMargin ? 0 : theme.spacing(1)),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
    width: ({ width }: Props) => (width ? width : 'auto'),
  },
}));

interface Props {
  children: string | ReactNode;
  width?: number;
  noMargin?: boolean;
}

const StatusCard = (props: Props) => {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <Paper elevation={0} className={classes.root}>
      {children}
    </Paper>
  );
};

export default StatusCard;
