import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReportProblem } from '@mui/icons-material';

import { FullScreenSpinner } from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { formatDateTime } from '@core/helpers';
import {
  AddedMatchPlayerList,
  AddedMatchTeamOfficialList,
  CompetitorResponse,
  CustomerResponse,
  KitSet,
  MatchCompetitorModel,
  MatchPlayerOrigin,
  MatchPlayersList,
  MatchModel,
} from '@core/types';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import * as matchProfileSelectors from '@core/store/modules/tabs/match-profile/selectors';
import { getMatchById } from '@core/store/modules/matches/selectors';

import MatchSheetAlert from '@core/components/match-sheet-alert/match-sheet-alert';
import { filterStartingLineup } from '@core/factories/match';
import { State } from '@core/store';

import PlayersTable from './players-table';
import TeamOfficialsTable from './team-officials-table';
import MatchSummaryBlock from './match-summary-block';
import MatchLineupActionsBlock from './match-lineup-actions-block';
import PlayerKitSelect from './player-kit-select';
import { useMatchForJerseyNumbers } from '@core/hooks/use-match-for-jersey-numbers';

export interface MatchProps {
  competitorTitle: string;
  competitorInternationalTitle: string;
  competitorLogoFileLink: string;
  competitorId: number;
  matchId: number;
  clubId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  canEditMatch: boolean;
  canManageMatch: boolean;
}

interface OwnProps {
  matchProps: MatchProps;
  playersList: MatchPlayersList;
  teamOfficialsList: AddedMatchTeamOfficialList;
  matchCompetitor: MatchCompetitorModel;
}

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
  };
}

interface StateProps {
  competitionCompetitor: CompetitorResponse;
  currentCustomer: CustomerResponse;
  isPlayersLoading: boolean;
  isTeamDataLoading: boolean;
  isTeamOfficialsLoading: boolean;
  kitSet: KitSet;
  matchDetails: MatchModel;
}

type Props = OwnProps & DispatchProps & StateProps;

function renderPersonsTable({
  isLoading,
  persons,
  tableElement,
}: {
  isLoading: boolean;
  persons: Array<any>;
  tableElement: JSX.Element;
}) {
  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return persons && persons.length > 0 && tableElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
      paddingTop: theme.spacing(2),
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const MatchLineupTemplate = ({
  actions,
  competitionCompetitor,
  currentCustomer,
  isPlayersLoading,
  isTeamDataLoading,
  isTeamOfficialsLoading,
  kitSet,
  matchCompetitor,
  matchDetails,
  matchProps: { competitorId, matchId, matchPlayerOrigin, canManageMatch },
  playersList,
  teamOfficialsList,
}: Props) => {
  const getJerseyNumber = useMatchForJerseyNumbers();
  const competitionId = matchDetails?.competitionId;
  const teamId =
    matchDetails?.[
      matchPlayerOrigin === MatchPlayerOrigin.HOME
        ? 'homeCompetitorTeamId'
        : 'awayCompetitorTeamId'
    ];
  const kitSetId = competitionCompetitor?.teamColorSetId;

  useEffect(() => {
    actions.matchProfile.getMatchPlayers({
      matchId,
      competitorId,
      matchPlayerOrigin,
    });

    actions.matchProfile.getMatchTeamOfficials({
      matchId,
      competitorId,
      matchPlayerOrigin,
    });
  }, [actions.matchProfile, competitorId, matchId, matchPlayerOrigin]);

  useEffect(() => {
    if (competitionId && matchId && teamId)
      actions.matchProfile.getCompetitionCompetitor({
        competitionId,
        matchId,
        matchPlayerOrigin,
        teamId,
      });
  }, [actions.matchProfile, competitionId, matchId, matchPlayerOrigin, teamId]);

  useEffect(() => {
    if (competitionId && matchId && teamId && kitSetId) {
      actions.matchProfile.getKitSet({
        kitSetId,
        matchId,
        matchPlayerOrigin,
        competitionId,
        teamId,
      });
    }
  }, [
    actions.matchProfile,
    competitionId,
    matchId,
    matchPlayerOrigin,
    kitSetId,
    teamId,
  ]);

  const { t } = useTranslation();
  const classes = useStyles();

  const startingLineup: AddedMatchPlayerList =
    filterStartingLineup(playersList);
  const sortedStartingLineup = startingLineup
    ? [
        ...startingLineup.filter((el) => el.isCaptain || el.isLibero),
        ...startingLineup
          .filter(
            (element) =>
              !element.isCaptain &&
              !element.isLibero &&
              getJerseyNumber(element),
          )
          .sort(
            (a, b) =>
              parseInt(getJerseyNumber(b), 10) -
              parseInt(getJerseyNumber(a), 10),
          ),
        ...(startingLineup ?? [])
          .filter((el) => !el.isCaptain && !el.isLibero)
          .filter(
            (element) =>
              !element.isCaptain &&
              !element.isLibero &&
              !getJerseyNumber(element),
          )
          .sort((a, b) =>
            b.competitorPlayer.player.localFamilyName >
            a.competitorPlayer.player.localFamilyName
              ? 1
              : -1,
          ),
      ]
    : [];

  const areNoPersons = [
    startingLineup && startingLineup.length,
    teamOfficialsList && teamOfficialsList.length,
  ].every((persons) => persons === 0);

  const {
    matchSheetSubmissionDeadlineDatetime: deadlineTime,
    matchSheetSubmissionDeadlineRemainingTime: remainingTime,
    pastMatchSheetSubmissionDeadline: deadlineMissed,
  } = matchDetails;
  const isDeadlineMissed = areNoPersons && deadlineTime && deadlineMissed;
  const isMatchSheetMissing = areNoPersons && deadlineTime && remainingTime;

  const renderPlayersTable = () => {
    return renderPersonsTable({
      isLoading: isPlayersLoading,
      persons: startingLineup,
      tableElement: <PlayersTable players={sortedStartingLineup} />,
    });
  };

  const secondsToRemainingTimeText = (duration: number) => {
    let durationText = '';
    const totalMinutes = duration / 60;
    const days = Math.floor(totalMinutes / 24 / 60);
    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = Math.floor(totalMinutes % 60);

    if (duration < 0) return null;
    if (duration < 60) return t('Less than a minute');

    if (days) durationText += `${days} ${t('days')} `;
    if (hours) durationText += `${hours} ${t('hours')} `;
    if (minutes) durationText += `${minutes} ${t('minutes')} `;

    return durationText;
  };

  const getTooltipText = () => {
    if (isDeadlineMissed && !canManageMatch) {
      return t('Match Lineup has been locked due to missed deadline');
    }

    if (!canManageMatch) {
      return t('Match Sheet is locked for non Scheduled matches');
    }

    return null;
  };

  const renderMatchSheetValidation = () => {
    if (isMatchSheetMissing) {
      const remainingText = secondsToRemainingTimeText(remainingTime);

      return (
        <Box mb={2}>
          <Alert severity="warning">
            <Grid>
              <span>{`${t(
                'Match sheet is missing. Please manage and submit before',
              )} ${formatDateTime(
                currentCustomer?.dateTimeFormat,
                deadlineTime,
              )} `}</span>
              {!!remainingText && (
                <span className={classes.boldText}>{`(${remainingText} ${t(
                  'left',
                )})`}</span>
              )}
            </Grid>
          </Alert>
        </Box>
      );
    }

    if (isDeadlineMissed) {
      return (
        <Box mb={2}>
          <Alert severity="error" icon={<ReportProblem />}>
            <Grid>
              <span>{`${t('Match sheet is missing.')} `}</span>
              <span className={classes.boldText}>{`Deadline (${formatDateTime(
                currentCustomer?.dateTimeFormat,
                deadlineTime,
              )}) missed.`}</span>
            </Grid>
          </Alert>
        </Box>
      );
    }

    return (
      <MatchSheetAlert
        matchDetails={matchDetails}
        addedLineup={startingLineup}
        addedTeamOfficials={teamOfficialsList}
      />
    );
  };

  const sortedTeamOfficialsList = teamOfficialsList
    ? [
        ...teamOfficialsList.sort((a: any, b: any) =>
          b.competitorTeamOfficial?.teamOfficial?.localFamilyName >
          a.competitorTeamOfficial?.teamOfficial?.localFamilyName
            ? 1
            : -1,
        ),
      ]
    : [];

  const renderTeamOfficialsTable = () =>
    renderPersonsTable({
      isLoading: isTeamOfficialsLoading,
      persons: teamOfficialsList,
      tableElement: <TeamOfficialsTable officials={sortedTeamOfficialsList} />,
    });

  return (
    <Grid>
      {renderMatchSheetValidation()}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        className={classes.root}
      >
        <MatchSummaryBlock
          playersList={playersList}
          isPlayersLoading={isPlayersLoading}
          isTeamOfficialsLoading={isTeamOfficialsLoading}
          teamOfficialsList={teamOfficialsList}
          playerKitSelect={
            <PlayerKitSelect
              matchId={matchId}
              competitorId={competitorId}
              sport={matchDetails.sport}
              kitSetId={kitSetId}
              kitSet={kitSet}
              teamColor={matchCompetitor?.teamColor}
            />
          }
        />
        <Box pl={2} className={classes.button}>
          <MatchLineupActionsBlock
            matchId={matchId}
            competitorId={competitorId}
            matchPlayerOrigin={matchPlayerOrigin}
            canManage={canManageMatch}
            tooltipText={getTooltipText()}
            areNoPersons={areNoPersons}
          />
        </Box>
      </Box>
      <Box mt={2}>
        {isTeamDataLoading ? (
          <FullScreenSpinner />
        ) : (
          <>
            {renderPlayersTable()}
            {renderTeamOfficialsTable()}
          </>
        )}
      </Box>
    </Grid>
  );
};

// TODO: JB: make loading selector to watch current tab requests instead of any tab request

const isTeamDataLoadingSelector = createLoadingSelector([
  matchProfileActions.getMatchPlayersRequest.toString(),
  matchProfileActions.getMatchTeamOfficialsRequest.toString(),
]);

const isPlayersLoadingSelector = createLoadingSelector([
  matchProfileActions.getMatchPlayersRequest.toString(),
]);

const isTeamOfficialsLoadingSelector = createLoadingSelector([
  matchProfileActions.getMatchTeamOfficialsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { matchId, matchPlayerOrigin } = ownProps.matchProps;

  return {
    competitionCompetitor: matchProfileSelectors.getCompetitionCompetitor(
      state,
      { matchId, matchPlayerOrigin },
    ),
    currentCustomer: getCurrentCustomer(state),
    isPlayersLoading: isPlayersLoadingSelector(state),
    isTeamDataLoading: isTeamDataLoadingSelector(state),
    isTeamOfficialsLoading: isTeamOfficialsLoadingSelector(state),
    kitSet: matchProfileSelectors.getKitSet(state, {
      matchId,
      matchPlayerOrigin,
    }),
    matchDetails: getMatchById(state, matchId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchLineupTemplate);
