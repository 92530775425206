import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AutocompleteField } from '@core/components';
import { formatOptions } from '@core/helpers';
import { actions as sanctionActions } from '@volleyball/store/modules/tabs/impose-sanction';
import { State } from '@volleyball/store';
import { getCompetitors } from '@volleyball/store/modules/tabs/impose-sanction/selectors';
import { SanctionType } from '@volleyball/types';

import { OFFENDER_TYPE_FIELDS } from '../constants';

type Props = {
  competitionId: number;
  sanctionType: SanctionType;
};

const OffenderTypeTeam = (props: Props) => {
  const { competitionId, sanctionType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const competitors = useSelector((state: State) =>
    getCompetitors(state, competitionId, sanctionType),
  );

  useEffect(() => {
    dispatch({
      type: sanctionActions.getCompetitors.toString(),
      payload: { competitionId, sanctionType },
    });
  }, []);

  function handleCompetitorInputChange(option: any) {
    const selectedCompetitorId = option?.value;
    dispatch({
      type: sanctionActions.setCurrentCompetitor.toString(),
      payload: {
        competitionId,
        sanctionType,
        data: selectedCompetitorId,
      },
    });
    dispatch({
      type: sanctionActions.getCompetitors.toString(),
      payload: { competitionId, sanctionType, option },
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        {/* TODO: JB: probably ok, but check if AutocompleteField works with initial non empty value */}
        <AutocompleteField
          name={OFFENDER_TYPE_FIELDS.competitor}
          options={formatOptions(t, competitors?.list, 'title')}
          textFieldProps={{
            label: t('Select team in competition'),
            required: true,
          }}
          onChange={handleCompetitorInputChange}
          useOptionAsValue
        />
      </Grid>
    </Grid>
  );
};

export default OffenderTypeTeam;
