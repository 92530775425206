import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import {
  CompetitionLevelsList,
  CompetitionLevel,
  APIErrorsModel,
} from '@core/types';

import {
  SET_COMPETITION_LEVELS,
  SET_NEW_LEVEL_BEING_CREATED,
  SET_LEVEL_BEING_MODIFIED,
  SET_ERRORS,
} from './constants';

export interface DefaultState {
  allLevels: CompetitionLevelsList;
  levelBeingModified: CompetitionLevel;
  isNewLevelBeingCreated: boolean;
  errors: APIErrorsModel;
}

export const defaultState: DefaultState = {
  allLevels: null,
  levelBeingModified: null,
  isNewLevelBeingCreated: false,
  errors: null,
};

const reducers = handleActions<DefaultState, any>(
  {
    [SET_COMPETITION_LEVELS]: (state, { payload }) =>
      assoc('allLevels', payload, state),
    [SET_LEVEL_BEING_MODIFIED]: (state, { payload }) =>
      assoc('levelBeingModified', payload, state),
    [SET_NEW_LEVEL_BEING_CREATED]: (state, { payload }) =>
      assoc('isNewLevelBeingCreated', payload, state),
    [SET_ERRORS]: (state, { payload }) => assoc('errors', payload, state),
  },
  defaultState,
);

export default reducers;
