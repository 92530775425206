import { Grid, Tooltip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  text: string;
  tooltipText?: string;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => {
  const backgroundColor = 'rgb(248, 231, 28)';

  return {
    root: {
      width: 22,
      height: 22,
      borderRadius: '50%',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      border: `2.5px solid ${theme.palette.common.white}`,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor,
    },
    text: {
      lineHeight: 1,
      overflow: 'hidden',
      fontSize: 12,
      cursor: 'default',
    },
  };
});

const BubbleIcon = (props: Props) => {
  const { text, tooltipText } = props;
  const classes = useStyles();

  const renderBubble = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={classes.root}
    >
      <Grid item className={classes.text}>
        {text}
      </Grid>
    </Grid>
  );

  return tooltipText ? (
    <Tooltip title={tooltipText} placement="bottom">
      {renderBubble()}
    </Tooltip>
  ) : (
    renderBubble()
  );
};

export default BubbleIcon;
