import { all, call, put, take } from 'redux-saga/effects';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  updateGroup,
  updateGroupSettings,
} from '@core/store/modules/api/groups/sagas';

import { actions as groupsActions } from '.';

export function* updateGroupFlow() {
  while (true) {
    const { payload } = yield take(groupsActions.updateGroup);

    yield put(groupsActions.updateGroupRequest());
    const { error } = yield call(updateGroup, payload.groupId, payload.data);

    if (!error) {
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId: payload.competitionId,
        }),
      );
      yield put(tabActions.removeTab({ tabId: payload.tabId }));
      yield put(groupsActions.updateGroupSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.updateGroupFailure());
    }
  }
}

export function* updateGroupSettingsFlow() {
  while (true) {
    const { payload } = yield take(groupsActions.updateGroupSettings);

    yield put(groupsActions.updateGroupSettingsRequest());
    const { error } = yield call(
      updateGroupSettings,
      payload.groupId,
      payload.groupSettings,
    );

    if (!error) {
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId: payload.competitionId,
        }),
      );
      yield put(tabActions.removeTab({ tabId: payload.tabId }));
      yield put(groupsActions.updateGroupSettingsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.updateGroupSettingsFailure());
    }
  }
}

export default function* saga() {
  yield all([updateGroupFlow(), updateGroupSettingsFlow()]);
}
