import type { SelectComponents } from 'react-select/dist/declarations/src/components';
import type { AutocompleteOption } from '@core/types';
import type { GroupBase } from 'react-select';
import Control from './control';
import DropdownIndicator from './drop-down-indicator';
import LoadingIndicator from './loading-indicator';
import LoadingMessage from './loading-message';
import Menu from './menu';
import MenuList from './menu-list';
import MultiValue from './multi-value';
import NoOptionsMessage from './no-options-message';
import Option from './option';
import SelectContainer from './select-container';
import SingleValue from './single-value';
import ValueContainer from './value-container';

export { default as MenuWithInfiniteScroll } from './menu-with-infinite-scroll';

export const COMPONENTS: Partial<
  SelectComponents<AutocompleteOption, boolean, GroupBase<any>>
> = {
  Control,
  DropdownIndicator,
  LoadingIndicator,
  LoadingMessage,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Option,
  SelectContainer,
  SingleValue,
  ValueContainer,
};
