import { createAction } from 'redux-actions';

import { PersonsList, Person } from '@core/types';

export const NAMESPACE = 'personsList';

const getPersonsByClubRequest = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_BY_CLUB_REQUEST`,
);
const getPersonsByClubSuccess = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_BY_CLUB_SUCCESS`,
);
const getPersonsByClubFailure = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_BY_CLUB_FAILURE`,
);
const getPersonsRequest = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_REQUEST`,
);
const getPersonsSuccess = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_SUCCESS`,
);
const getPersonsFailure = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_FAILURE`,
);
const getPersonRequest = createAction<void>(`${NAMESPACE}/GET_PERSON_REQUEST`);
const getPersonSuccess = createAction<void>(`${NAMESPACE}/GET_PERSON_SUCCESS`);
const getPersonFailure = createAction<void>(`${NAMESPACE}/GET_PERSON_FAILURE`);

const setPersons = createAction<PersonsList>(`${NAMESPACE}/SET_PERSONS`);
const setPerson = createAction<Person>(`${NAMESPACE}/SET_PERSON`);

const createPersonRequest = createAction<void>(
  `${NAMESPACE}/CREATE_PERSON_REQUEST`,
);
const createPersonSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_PERSON_SUCCESS`,
);
const createPersonFailure = createAction<void>(
  `${NAMESPACE}/CREATE_PERSON_FAILURE`,
);

const updatePersonRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_PERSON_REQUEST`,
);
const updatePersonSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_PERSON_SUCCESS`,
);
const updatePersonFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_PERSON_FAILURE`,
);

const deletePersonRequest = createAction<void>(
  `${NAMESPACE}/DELETE_PERSON_REQUEST`,
);
const deletePersonSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_PERSON_SUCCESS`,
);
const deletePersonFailure = createAction<void>(
  `${NAMESPACE}/DELETE_PERSON_FAILURE`,
);

const uploadPersons = createAction<{
  clubId?: number;
  file: unknown;
  onSuccess: () => void;
}>(`${NAMESPACE}/UPLOAD_PERSONS`);
const uploadPersonsRequest = createAction<void>(
  `${NAMESPACE}/UPLOAD_PERSONS_REQUEST`,
);
const uploadPersonsSuccess = createAction<void>(
  `${NAMESPACE}/UPLOAD_PERSONS_SUCCESS`,
);
const uploadPersonsFailure = createAction<void>(
  `${NAMESPACE}/UPLOAD_PERSONS_FAILURE`,
);

const exportPersonsTemplate = createAction(
  `${NAMESPACE}/EXPORT_PERSONS_TEMPLATE`,
);
const exportPersonsTemplateRequest = createAction<void>(
  `${NAMESPACE}/EXPORT_PERSONS_TEMPLATE_REQUEST`,
);
const exportPersonsTemplateSuccess = createAction<void>(
  `${NAMESPACE}/EXPORT_PERSONS_TEMPLATE_SUCCESS`,
);
const exportPersonsTemplateFailure = createAction<void>(
  `${NAMESPACE}/EXPORT_PERSONS_TEMPLATE_FAILURE`,
);

export default {
  getPersonsByClubRequest,
  getPersonsByClubSuccess,
  getPersonsByClubFailure,
  getPersonsRequest,
  getPersonsSuccess,
  getPersonsFailure,
  getPersonRequest,
  getPersonSuccess,
  getPersonFailure,
  setPersons,
  setPerson,

  createPersonRequest,
  createPersonSuccess,
  createPersonFailure,
  updatePersonRequest,
  updatePersonSuccess,
  updatePersonFailure,
  deletePersonRequest,
  deletePersonSuccess,
  deletePersonFailure,

  uploadPersons,
  uploadPersonsRequest,
  uploadPersonsSuccess,
  uploadPersonsFailure,

  exportPersonsTemplate,
  exportPersonsTemplateRequest,
  exportPersonsTemplateSuccess,
  exportPersonsTemplateFailure,
};
