import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';

import { actions } from '.';

type DefaultState = {
  data: Record<string, any>;
  meta: {
    links: Record<string, any>;
    limit: number;
    page: number;
    pages: number;
    total: number;
  };
};

export const defaultState: DefaultState = {
  data: {},
  meta: null,
};

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCityList.toString()]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...convertArrayToObject(payload?._embedded?.cities ?? []),
      },
    }),

    [actions.setCityListMeta.toString()]: (state, { payload }) => ({
      ...state,
      meta: payload,
    }),

    [actions.setCity.toString()]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload,
      },
    }),

    [actions.resetCityList.toString()]: (state) => ({
      ...state,
      data: defaultState.data,
    }),
  },
  defaultState,
);

export default reducer;
