import { pathOr, isEmpty } from 'ramda';

import { getMatchOfficialsById } from '@core/store/modules/api/match-officials/selectors';
import { getCompetitorsById } from '@core/store/modules/api/competitors/selectors';
import { getMatchesById } from '@core/store/modules/api/match/selectors';
import { getCompetitorPlayersById } from '@core/store/modules/api/competitor-players/selectors';
import { getCompetitorTeamOfficialsById } from '@core/store/modules/api/competitor-team-officials/selectors';
import { State } from '@volleyball/store';
import { SanctionType } from '@volleyball/types';

import { NAMESPACE } from '.';

export const getCompetition = (competitionId: number) =>
  pathOr(null, ['api', 'competitionsList', competitionId]);

export const getCompetitors = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) => {
  const competitors = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, sanctionType, 'competitors'],
      state,
    ),
  };

  return competitors && !isEmpty(competitors)
    ? { ...competitors, list: getCompetitorsById(state, competitors.list) }
    : null;
};

export const getCurrentCompetitor = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, competitionId, sanctionType, 'currentCompetitorId'],
    state,
  );

export const getCompetitorPlayers = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) => {
  const players = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, sanctionType, 'players'],
      state,
    ),
  };

  return players && !isEmpty(players)
    ? { ...players, list: getCompetitorPlayersById(state, players.list) }
    : null;
};

export const getCompetitorTeamOfficials = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) => {
  const teamOfficials = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, sanctionType, 'teamOfficials'],
      state,
    ),
  };

  return teamOfficials && !isEmpty(teamOfficials)
    ? {
        ...teamOfficials,
        list: getCompetitorTeamOfficialsById(state, teamOfficials.list),
      }
    : null;
};

export const getMatchOfficials = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) => {
  const matchOfficials = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, sanctionType, 'matchOfficials'],
      state,
    ),
  };

  return matchOfficials && !isEmpty(matchOfficials)
    ? {
        ...matchOfficials,
        list: getMatchOfficialsById(state, matchOfficials.list),
      }
    : null;
};

export const getMatches = (
  state: State,
  competitionId: number,
  sanctionType: SanctionType,
) => {
  const matches = {
    ...pathOr(
      null,
      ['tabs', NAMESPACE, competitionId, sanctionType, 'matches'],
      state,
    ),
  };

  return matches && !isEmpty(matches)
    ? { ...matches, list: getMatchesById(state, matches.list) }
    : null;
};
