import {
  CompetitorStatus,
  TeamListModel,
  Paginated,
  AutocompleteOption,
  VenueOption,
  KitSetList,
} from '@core/types';
import { PaginatedListResponse } from './list-response';
import { TeamModel } from './teams';
import { VenueModel } from './venues';

export interface Competitor {
  alternativeVenueId: number;
  contacts: unknown; // TODO: ADD TYPE
  defaultVenueId: number;
  id: number;
  internationalTitle: string;
  status: CompetitorStatus;
  teamId: number;
  title: string;
  teamColorSetId?: number;
  clubId: number;
  logoFileLink: string;
  logoFileId: number;
  clubLogoFileLink: string;
  defaultVenue: VenueModel;
  alternativeVenue: VenueModel;
  competitorPlayersCount: number;
  playerListApproved: boolean;
  teamCode: string;
  _links?: {
    self: {
      href: string;
    };
    delete: {
      href: string;
    };
    competition: {
      href: string;
    };
    team: {
      href: string;
    };
    patch: {
      href: string;
    };
  };
}

export interface CreateCompetitorProps {
  competitionId: number;
  teamId: number;
  data: Competitor;
}

export enum COMPETITOR_GENERAL_INFO_FIELDS {
  clubId = 'clubId',
  sportId = 'sportId',
  id = 'id',
  teamTitle = 'title',
  internationalTeamTitle = 'internationalTitle',
  defaultVenueOption = 'defaultVenueOption',
  alternativeVenueOption = 'alternativeVenueOption',
  logoFileLink = 'logoFileLink',
  clubLogoFileLink = 'clubLogoFileLink',
}

export interface CompetitorGeneralInfoFormValues {
  [COMPETITOR_GENERAL_INFO_FIELDS.teamTitle]: string;
  [COMPETITOR_GENERAL_INFO_FIELDS.id]: number | '';
  [COMPETITOR_GENERAL_INFO_FIELDS.clubId]: number | '';
  [COMPETITOR_GENERAL_INFO_FIELDS.sportId]: number | '';
  [COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle]: string;
  [COMPETITOR_GENERAL_INFO_FIELDS.defaultVenueOption]: AutocompleteOption;
  [COMPETITOR_GENERAL_INFO_FIELDS.alternativeVenueOption]: AutocompleteOption;
  [COMPETITOR_GENERAL_INFO_FIELDS.logoFileLink]: string;
  [COMPETITOR_GENERAL_INFO_FIELDS.clubLogoFileLink]: string;
}

export interface CompetitorAdditionState {
  generalInformation: CompetitorGeneralInfoFormValues;
  team: TeamModel;
  kitSets: KitSetList;
  teamSearchList: TeamListModel;
  defaultVenuesList: Paginated<VenueModel>;
  alternativeVenuesList: Paginated<VenueModel>;
}

export type CompetitorsList = Array<Competitor>;

export interface CompetitorResponse extends Competitor {}

export interface CompetitorsResponse
  extends PaginatedListResponse<{ competitors: CompetitorsList }> {
  _links: {
    first: {
      href: string;
    };
    last: {
      href: string;
    };
    self: {
      href: string;
    };
  };
}

export interface CompetitorPlaceholder {
  alternativeVenueId: string;
  contacts: Array<any>; // TODO: ADD TYPE
  defaultVenueId: string;
  id: string;
  internationalTitle: string;
  status: string;
  teamId: number;
  title: string;
  defaultVenue: VenueModel;
  alternativeVenue: VenueModel;
  logoFileLink: string;
  clubLogoFileLink: string;
}

export interface DeleteCompetitorProps {
  competitionId: number;
  teamId: number;
}

export interface UpdateCompetitorFormValues extends Partial<Competitor> {
  defaultVenueOption: VenueOption;
  alternativeVenueOption: VenueOption;
}

export interface UpdateCompetitorProps {
  competitionId: number;
  teamId: number;
  data: Partial<Competitor>;
}
