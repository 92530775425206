import React from 'react';
import { pathOr } from 'ramda';
import { Typography, MenuItem, Box, Theme, alpha } from '@mui/material';
import { OptionProps } from 'react-select';
import { makeStyles } from '@mui/styles';

import { AutocompleteOption } from '@core/types';

import * as testIds from '../../tests/tests-ids';

type Props = OptionProps<AutocompleteOption, boolean>;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    backgroundColor: (props) =>
      props.isFocused
        ? alpha(theme.palette.primary.light, 0.15)
        : theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.15),
    },
  },
}));

const TeamOption = (props: Props) => {
  const classes = useStyles(props);
  const title = pathOr('', ['data', 'props', 'title'], props);
  const internationalTitle = pathOr(
    '',
    ['data', 'props', 'internationalTitle'],
    props,
  );
  const localClubName = pathOr('', ['data', 'props', 'club', 'title'], props);
  const localClubNameLatin = pathOr(
    '',
    ['data', 'props', 'club', 'internationalTitle'],
    props,
  );

  return (
    <MenuItem
      ref={props.innerRef as any}
      className={classes.root}
      selected={props.isSelected}
      component="div"
      data-qa={testIds.COMPETITOR_ASSIGNMENT_SELECT_TEAM_OPTION}
      {...props.innerProps}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box width="50%">
          <Typography variant="body2">{title}</Typography>
          {internationalTitle && (
            <Typography variant="body2" color="textSecondary">
              {internationalTitle}
            </Typography>
          )}
        </Box>
        <Box width="50%" justifyContent="flex-start">
          <Typography variant="body2">{localClubName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {localClubNameLatin}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

export default TeamOption;
