import React, { MouseEventHandler, useEffect } from 'react';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isNil } from 'ramda';

import {
  FullScreenSpinner,
  GridItems,
  SummaryBlock,
  Tabs,
  TabPanel,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getCompetitionDetailsById,
  getStageById,
} from '@core/store/modules/tabs/competition-profile/selectors';
import teamGridActions from '@core/store/modules/tabs/team-grid';
import { getGroupCompetitors } from '@core/store/modules/tabs/team-grid/selectors';
import { State } from '@core/store';
import {
  ChildGroupCompetitors,
  Competition,
  Group,
  GroupCompetitor,
} from '@core/types';
import { getSummaryBlockValues } from '@core/pages/team-grids-edit/helpers';

import useSlots, { isSlotsEditable } from './use-slots';
import { useTabs, useSlotsByGroupIdDictionary } from './helpers';
import TeamGridEdit, { OwnProps as TeamGridEditProps } from './team-grid-edit';
import { ButtonWithProgress } from '@ui-components';

interface StateProps {
  isGroupCompetitorsLoading: boolean;
  isSubmitting: boolean;
  competition: Competition;
  group?: Group;
  stage: Group;
  groupCompetitors: Array<GroupCompetitor> | ChildGroupCompetitors;
}

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
    tabs: typeof tabsActions;
    teamGrid: typeof teamGridActions;
  };
}

interface OwnProps {
  competitionId: number;
  groupId: number;
  tabId: string;
}

type Props = OwnProps & StateProps & DispatchProps;

const TeamGridsEdit: React.FC<Props> = (props) => {
  const {
    actions,
    competitionId,
    groupCompetitors,
    groupId,
    isGroupCompetitorsLoading,
    isSubmitting,
    stage,
    tabId,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (isNil(stage)) {
      actions.competitionProfile.getCompetitionDetails(competitionId);
      actions.competitionProfile.getStageDetails({ competitionId });
    }
  }, [actions.competitionProfile, competitionId, stage]);

  useEffect(() => {
    if (
      stage?.id &&
      stage?.numberOfTeams &&
      stage?.childGroups.every(Boolean)
    ) {
      actions.teamGrid.getCompetitorsByGroupId({ groupId });
    }
  }, [
    actions.teamGrid,
    groupId,
    stage?.childGroups,
    stage?.id,
    stage?.numberOfTeams,
  ]);

  const stageId = stage?.id;
  const stageNumberOfTeams = stage?.numberOfTeams;
  const childGroups = stage?.childGroups;
  const onlyChildGroup = childGroups?.[0];
  const onlyChildGroupId = onlyChildGroup?.id;

  const { getGroupFromActiveTab, groupIdFromActiveTab, onTabChange, tabs } =
    useTabs(stage);

  const activeGroupId = groupIdFromActiveTab ?? onlyChildGroupId ?? stageId;

  const {
    getUpdateGroupCompetitorsPayload,
    slotsByGroupIdDictionary,
    updateSlotsByGroupIdDictionary,
  } = useSlotsByGroupIdDictionary({
    groupCompetitors,
    activeGroupId,
    stageId,
    stageNumberOfTeams,
  });

  const slotsStateWithUpdaters = useSlots(
    slotsByGroupIdDictionary?.[activeGroupId],
    updateSlotsByGroupIdDictionary,
  );

  const isAnyTeamGridEditable = Object.values(
    slotsByGroupIdDictionary ?? {},
  ).some(isSlotsEditable);

  const isGroupCompetitorsNotInitialized = !groupCompetitors;

  const getTeamGridEditProps = (group: Group): TeamGridEditProps => ({
    competitionId,
    group,
    isGroupCompetitorsLoading:
      isGroupCompetitorsLoading || isGroupCompetitorsNotInitialized,
    slotsStateWithUpdaters,
    isTeamGridEditable: isSlotsEditable(slotsStateWithUpdaters.slots),
  });

  const handleCancel: MouseEventHandler<HTMLButtonElement> = () => {
    actions.tabs.removeTab({ tabId });
  };

  const handleSubmit = () => {
    const payload = getUpdateGroupCompetitorsPayload();

    actions.teamGrid.updateGroupCompetitors({
      groupId,
      groupCompetitors: payload,
      tabId,
      competitionId,
    });
  };

  return (
    <Grid container spacing={2} direction="column">
      <GridItems xs>
        <Paper>
          <Box p={3}>
            <Grid container spacing={3} direction="column">
              <GridItems xs>
                <Typography variant="h6">{t('Team grid')}</Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12} sm="auto">
                    <SummaryBlock values={getSummaryBlockValues(props, t)} />
                  </Grid>
                </Grid>
              </GridItems>
            </Grid>
          </Box>
          {isGroupCompetitorsLoading || isGroupCompetitorsNotInitialized ? (
            <FullScreenSpinner />
          ) : (
            <>
              {tabs && (
                <Box pl={3} pr={3}>
                  <Tabs fullWidth tabs={tabs} onChange={onTabChange}>
                    {(currentTab) => (
                      <TabPanel
                        currentTab={currentTab}
                        tabs={tabs}
                        props={getTeamGridEditProps(getGroupFromActiveTab())}
                        mt={0}
                      />
                    )}
                  </Tabs>
                </Box>
              )}
              {!tabs && (onlyChildGroup || stage) && (
                <>
                  <Divider />
                  <Box pl={3} pr={3}>
                    <TeamGridEdit
                      {...getTeamGridEditProps(onlyChildGroup || stage)}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Paper>
        <Box pt={1}>
          <Grid container spacing={1} justifyContent="flex-end">
            <GridItems>
              <Button variant="outlined" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <ButtonWithProgress
                variant="contained"
                disabled={
                  !isAnyTeamGridEditable ||
                  isSubmitting ||
                  isGroupCompetitorsLoading ||
                  isGroupCompetitorsNotInitialized
                }
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                {t('Save')}
              </ButtonWithProgress>
            </GridItems>
          </Grid>
        </Box>
      </GridItems>
    </Grid>
  );
};

const isGroupCompetitorsLoadingSelector = (groupId: number) =>
  createLoadingSelector([
    {
      id: groupId,
      actionName: teamGridActions.getCompetitorsByGroupIdRequest.toString(),
    },
  ]);

const submittingSelector = createLoadingSelector([
  teamGridActions.updateGroupCompetitorsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
    teamGrid: bindActionCreators(teamGridActions, dispatch),
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isGroupCompetitorsLoading: isGroupCompetitorsLoadingSelector(
    ownProps.groupId,
  )(state),
  isSubmitting: submittingSelector(state),
  competition: getCompetitionDetailsById(ownProps.competitionId)(state),
  stage: getStageById(ownProps.competitionId, ownProps.groupId)(state),
  groupCompetitors: getGroupCompetitors(state, ownProps.groupId),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamGridsEdit);
