import { combineReducers } from 'redux';

import { NAMESPACE as competitionFilterTab } from '@core/store/modules/modals/competitions-filter-tab';
import competitionFilterTabReducer from '@core/store/modules/modals/competitions-filter-tab/reducer';
import { NAMESPACE as venuesFilterTab } from '@core/store/modules/modals/venues-filter-tab';
import venuesFilterTabReducer from '@core/store/modules/modals/venues-filter-tab/reducer';
import { NAMESPACE as teamsFilterTab } from '@core/store/modules/modals/teams-filter-tab';
import teamsFilterTabReducer from '@core/store/modules/modals/teams-filter-tab/reducer';
import { NAMESPACE as nationalitiesFilterTab } from '@core/store/modules/modals/nationalities-filter-tab';
import nationalitiesFilterTabReducer from '@core/store/modules/modals/nationalities-filter-tab/reducer';
import { NAMESPACE as fixtureMatchEdit } from '@core/store/modules/modals/fixture-match-edit';
import fixtureMatchEditReducer from '@core/store/modules/modals/fixture-match-edit/reducer';
import {
  NAMESPACE as seasonFilterTab,
  reducer as seasonFilterTabReducer,
} from '@core/store/modules/modals/season-filter-tab';

export const staticModals = {
  [fixtureMatchEdit]: fixtureMatchEditReducer,
  [competitionFilterTab]: competitionFilterTabReducer,
  [venuesFilterTab]: venuesFilterTabReducer,
  [teamsFilterTab]: teamsFilterTabReducer,
  [nationalitiesFilterTab]: nationalitiesFilterTabReducer,
  [seasonFilterTab]: seasonFilterTabReducer,
};

export default combineReducers(staticModals);
