import { pathOr, propOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import { MatchPlayerOrigin } from '@core/types';
import { getGroupsList } from '@core/store/modules/api/groups/selectors';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { DefaultState } from './reducer';
import { NAMESPACE, actions } from '.';

interface GetPlayersParams {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
}

const getMatchProfiles = (state: State) =>
  pathOr(null, ['tabs', NAMESPACE, 'byMatchId'], state);
const getId = (_state: State, { matchId }: Partial<GetPlayersParams>) =>
  matchId;
const getOrigin = (
  _state: State,
  { matchPlayerOrigin }: Partial<GetPlayersParams>,
) => matchPlayerOrigin;

// TODO: JB: use this for the rest of the selectors
const getMatchProfileByMatchId = createSelector(
  getMatchProfiles,
  getId,
  (matchProfiles, id) => {
    return matchProfiles?.[id] ?? null;
  },
);

export const getMatchGroupByGroupId = createSelector(
  getGroupsList,
  (_state: State, groupId: number) => groupId,
  (groups, groupId) => pathOr(null, [groupId], groups),
);

export const getMatch = createSelector(getMatchProfileByMatchId, (match) => {
  return pathOr(null, ['match'], match);
});

export const isMatchLoading = (matchId: number) => (state: State) =>
  createLoadingSelector([
    { actionName: actions.getMatchRequest.toString(), id: matchId },
  ])(state);

export const getMatchEventsById = createSelector(
  getMatchProfileByMatchId,
  (match) => {
    return pathOr(null, ['events'], match);
  },
);

export const getMatchEventsLengthById =
  (matchId: number) =>
  (state: State): number =>
    propOr(
      0,
      'length',
      pathOr([], ['tabs', NAMESPACE, 'byMatchId', matchId, 'events'], state),
    );

export const getCompetitorsMatchById = (
  state: State,
  { matchId }: { matchId: number },
) => pathOr(null, ['api', 'matchesList', matchId], state);

export const getMatchPlayers = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersParams,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, 'byMatchId', matchId, matchPlayerOrigin, 'players'],
    state,
  );

export const getHomeTeamOfficials = (matchId: number) =>
  pathOr(null, ['tabs', NAMESPACE, 'byMatchId', matchId, 'homeTeamOfficials']);

export const getAwayTeamOfficials = (matchId: number) =>
  pathOr(null, ['tabs', NAMESPACE, 'byMatchId', matchId, 'awayTeamOfficials']);

export const getKitSet = createSelector(
  getMatchProfileByMatchId,
  getOrigin,
  (match, origin) => {
    return pathOr<
      DefaultState['byMatchId'][string][MatchPlayerOrigin]['kitSet']
    >(null, [origin, 'kitSet'], match);
  },
);

export const getPenaltyShootoutsByMatchId = (matchId: number) =>
  pathOr(null, ['tabs', NAMESPACE, 'byMatchId', matchId, 'penaltyShootouts']);

export const getMatchCommissionerNoteTitles = (matchId: number) =>
  pathOr(
    [],
    ['tabs', NAMESPACE, 'byMatchId', matchId, 'matchCommissionerNoteTitles'],
  );

export const getMatchCommissionerNoteDescriptions = (matchId: number) =>
  pathOr(
    [],
    [
      'tabs',
      NAMESPACE,
      'byMatchId',
      matchId,
      'matchCommissionerNoteDescriptions',
    ],
  );

export const getMatchDurationListByMatchId = createSelector(
  getMatchProfileByMatchId,
  (match) => {
    return pathOr(null, ['matchDurationList'], match);
  },
);

export const getMatchScoresByMatchId = createSelector(
  getMatchProfileByMatchId,
  (match) => {
    return pathOr(null, ['matchScores'], match);
  },
);

export const getMatchCompetitorsByMatchId = createSelector(
  getMatchProfileByMatchId,
  (match) => {
    return pathOr<DefaultState['byMatchId'][string]['matchCompetitors']>(
      null,
      ['matchCompetitors'],
      match,
    );
  },
);

export const getMatchNotesDescriptions = (matchId: number) =>
  pathOr(
    [],
    ['tabs', NAMESPACE, 'byMatchId', matchId, 'matchNotesDescriptions'],
  );

export const getMatchNotesTitles = (matchId: number) =>
  pathOr([], ['tabs', NAMESPACE, 'byMatchId', matchId, 'matchNotesTitles']);

export const getCompetitionCompetitor = createSelector(
  getMatchProfileByMatchId,
  getOrigin,
  (match, origin) => {
    return pathOr<
      DefaultState['byMatchId'][string][MatchPlayerOrigin]['competitionCompetitor']
    >(null, [origin, 'competitionCompetitor'], match);
  },
);

export const isLoading = createLoadingSelector([
  actions.getMatchDurationListRequest.toString(),
]);
