import { createActions } from 'redux-actions';
import { Conflict, DashboardMatchModel } from '@core/types';

export const NAMESPACE = 'fixtures';

export const actions = createActions(
  'GET_FIXTURES',
  'GET_FIXTURES_REQUEST',
  'GET_FIXTURES_SUCCESS',
  'GET_FIXTURES_FAILURE',
  'SET_FIXTURES',
  'CLEAR_FIXTURES',

  'SET_CONFLICTS',
  'CLEAR_CONFLICTS',

  'GET_ALL_CONFLICTS_REQUEST',
  'GET_ALL_CONFLICTS_SUCCESS',
  'GET_ALL_CONFLICTS_FAILURE',

  'GET_VENUE_CONFLICTS_REQUEST',
  'GET_VENUE_CONFLICTS_SUCCESS',
  'GET_VENUE_CONFLICTS_FAILURE',

  'GET_TEAM_CONFLICTS_REQUEST',
  'GET_TEAM_CONFLICTS_SUCCESS',
  'GET_TEAM_CONFLICTS_FAILURE',

  'GET_OFFICIAL_CONFLICTS_REQUEST',
  'GET_OFFICIAL_CONFLICTS_SUCCESS',
  'GET_OFFICIAL_CONFLICTS_FAILURE',

  'GET_REFEREE_CONFLICTS_REQUEST',
  'GET_REFEREE_CONFLICTS_SUCCESS',
  'GET_REFEREE_CONFLICTS_FAILURE',

  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getFixtures: IdentityActionFunction<{ startDate: string; endDate: string }>;
  getFixturesRequest: RequestLifecycleAction;
  getFixturesSuccess: RequestLifecycleAction;
  getFixturesFailure: RequestLifecycleAction;
  setFixtures: IdentityActionFunction<Array<DashboardMatchModel>>;
  clearFixtures: NoopAction;

  setConflicts: IdentityActionFunction<Array<Conflict>>;
  clearConflicts: NoopAction;

  getAllConflictsRequest: RequestLifecycleAction;
  getAllConflictsSuccess: RequestLifecycleAction;
  getAllConflictsFailure: RequestLifecycleAction;

  getVenueConflictsRequest: RequestLifecycleAction;
  getVenueConflictsSuccess: RequestLifecycleAction;
  getVenueConflictsFailure: RequestLifecycleAction;

  getTeamConflictsRequest: RequestLifecycleAction;
  getTeamConflictsSuccess: RequestLifecycleAction;
  getTeamConflictsFailure: RequestLifecycleAction;

  getOfficialConflictsRequest: RequestLifecycleAction;
  getOfficialConflictsSuccess: RequestLifecycleAction;
  getOfficialConflictsFailure: RequestLifecycleAction;
};
