import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Box,
  Theme,
  TypographyProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomerResponse } from '@core/types';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { State } from '@core/store';
import { formatDate, formatDateTime } from '@core/helpers';
import { StatusCard } from '@core/components';

type TextColor = Pick<TypographyProps, 'color'>;

interface SummaryBlockProps {
  label: string;
  value?: string | number;
  card?: boolean;
  dataQa?: string;
  fullWidth?: boolean;
  halfWidth?: boolean;
  fullWidthMobile?: boolean;
  node?: React.ReactNode;
  dateField?: boolean;
  noDivider?: boolean;
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

type SummaryBlock = SummaryBlockProps & TextColor;

export type SummaryBlockValues = Array<SummaryBlock>;

interface OwnProps {
  values: SummaryBlockValues;
  backgroundColor?: string;
  isLoading?: boolean;
}

type Props = StateProps & OwnProps;

interface StyleProps {
  backgroundColor?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'fit-content',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor ? backgroundColor : theme.palette.grey[100],
    padding: theme.spacing(1),
  },
  infoBlock: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.only('xs')]: {
      '&:last-of-type': {
        marginRight: theme.spacing(0),
      },
      margin: theme.spacing(1),
    },
  },
  fullWidth: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  halfWidth: {
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  fullWidthMobile: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: `${theme.spacing(2.5)}`,
  },
  content: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: theme.spacing(2.5),
  },
  divider: {
    width: 1,
  },
}));

const SummaryBlock = (props: Props) => {
  const { values, currentCustomer, backgroundColor, isLoading } = props;
  const classes = useStyles({ backgroundColor });

  const getValue = (
    value: string | number,
    color: TextColor['color'],
    card?: boolean,
  ) =>
    card ? (
      <StatusCard noMargin>{value}</StatusCard>
    ) : (
      <Typography color={color} className={classes.value} variant="body2">
        {value}
      </Typography>
    );

  return (
    <Grid className={classes.root}>
      {values?.map(
        ({
          value,
          fullWidthMobile,
          label,
          card,
          color = 'initial',
          dataQa,
          fullWidth,
          halfWidth,
          node,
          dateField,
          noDivider,
        }) => {
          if (value && dateField) {
            const [date, time] = (value as string).split(' ');
            value = time
              ? formatDateTime(currentCustomer?.dateTimeFormat, value)
              : formatDate(currentCustomer?.dateFormat, date);
          }

          return (
            <Grid
              className={
                classes.infoBlock +
                (fullWidth ? ` ${classes.fullWidth}` : '') +
                (halfWidth ? ` ${classes.halfWidth}` : '') +
                (fullWidthMobile ? ` ${classes.fullWidthMobile}` : '')
              }
              data-qa={dataQa}
              key={label}
            >
              {!noDivider && (
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  absolute
                />
              )}
              {node ? (
                node
              ) : (
                <Grid className={classes.content}>
                  <Typography color="textSecondary" variant="caption">
                    {label}
                  </Typography>
                  {isLoading ? (
                    <Box className={classes.progress}>
                      <CircularProgress size="15px" />
                    </Box>
                  ) : (
                    getValue(value || '-', color, card)
                  )}
                </Grid>
              )}
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(SummaryBlock);
