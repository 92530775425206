import React from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMore } from '@mui/icons-material';
import { Grid, Box, IconButton, Theme } from '@mui/material';

import {
  ExpansionPanelTitle,
  FlowIndicator,
  MoreActionsButton,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import {
  getGenders,
  getSports,
  getClubType,
  getGeography,
} from '@core/helpers';
import { CompetitionResponse, Sports, ModalTypes } from '@core/types';
import CompetitionAvatar from '@core/pages/competition-profile/components/competition-profile-panel/competition-avatar';
import { useConfirmationDialog } from '@core/hooks';
import * as competitionsActions from '@core/pages/competitions/store/actions';
import { CREATE_BUTTON, DELETE_BUTTON } from '@core/constants/test-ids';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';

import {
  COMPETITION_PROFILE_SUMMARY,
  COMPETITION_PROFILE_SUMMARY_TOGGLE,
  COMPETITION_PROFILE_ACTIONS,
} from '../../tests/test-ids';
import { useIsCompetitionDeletable } from '../../hooks/useIsCompetitionDeletable';

interface OwnProps {
  tabId: string;
  expanded: boolean;
  competition: CompetitionResponse;
}

interface DispatchProps {
  actions: {
    competitions: typeof competitionsActions;
    globalModal: typeof globalModalActions;
  };
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  detailsBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  expansionIcon: {
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
    }),
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
}));

const CompetitionProfileSummary = ({
  actions,
  competition,
  expanded,
  tabId,
}: Props) => {
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const classes = useStyles();
  const isCompetitionDeletable = useIsCompetitionDeletable(competition);

  const canCreateNewCompetitionSeason = !!competition?._links?.rollover;
  const canDeleteCompetition = !!competition?._links?.patch;

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Comp. ID'),
      value: competition.id,
    },
    {
      label: t('Season'),
      value: competition.competitionSeasonTitle,
    },
    {
      label: t('Sport'),
      value: getSports(t, competition?.sportTitle as Sports),
    },
    {
      label: t('Gender'),
      value: getGenders(t, competition?.gender),
    },
    {
      label: t('Teams'),
      value: competition.participatingTeams,
    },
    {
      label: t('Team type'),
      value: getClubType(t, competition.clubType),
    },
    {
      label: t('Geography'),
      value: getGeography(t, competition.geography),
    },
  ];

  const expandIconClasses = clsx(classes.expansionIcon, {
    [classes.iconExpanded]: expanded,
  });
  const shouldShowMissingTeamWarning =
    !expanded && competition.competitorsCount === 0;

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this competition?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      actions.competitions.deleteCompetition({
        competitionId: competition.id,
        tabId,
      });
    }
  };

  const handleCreate = () => {
    actions.globalModal.openModal({
      type: ModalTypes.CREATE_COMPETITION_SEASON_MODAL,
      params: {
        competition,
      },
    });
  };

  const moreActions = [];

  if (canCreateNewCompetitionSeason) {
    moreActions.push({
      title: t('Create new season'),
      action: handleCreate,
      testId: CREATE_BUTTON,
    });
  }

  if (canDeleteCompetition) {
    moreActions.push({
      title: t('Delete competition'),
      action: handleDelete,
      testId: DELETE_BUTTON,
      disabled: !isCompetitionDeletable,
      disabledTooltip: t(
        'The Competition contains matches with statuses, which do not allow deletion.',
      ),
    });
  }

  return (
    <Grid data-qa={COMPETITION_PROFILE_SUMMARY} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <ExpansionPanelTitle
          label={t('Competition')}
          title={competition.title}
          subtitle={competition.internationalTitle}
          avatar={<CompetitionAvatar competition={competition} />}
          strongTitle
        />
        {shouldShowMissingTeamWarning && <FlowIndicator />}
        {moreActions.length > 0 && (
          <MoreActionsButton
            actions={moreActions}
            testId={COMPETITION_PROFILE_ACTIONS}
          />
        )}
        <IconButton
          classes={{ root: expandIconClasses }}
          data-qa={COMPETITION_PROFILE_SUMMARY_TOGGLE}
        >
          <ExpandMore />
        </IconButton>
      </Box>
      <Grid className={classes.detailsBlock}>
        <SummaryBlock values={getInfoBlocks()} />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitions: bindActionCreators(competitionsActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(CompetitionProfileSummary);
