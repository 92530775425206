import { createAction } from 'redux-actions';

import { Paginated, Filters, Sorting, MatchModel } from '@core/types';

import { NAMESPACE } from './constants';

const getAppointments = createAction<{ page?: number }>(
  `${NAMESPACE}/GET_APPOINTMENTS`,
);
const getAppointmentsRequest = createAction<void>(
  `${NAMESPACE}/GET_APPOINTMENTS_REQUEST`,
);
const getAppointmentsSuccess = createAction<void>(
  `${NAMESPACE}/GET_APPOINTMENTS_SUCCESS`,
);
const getAppointmentsFailure = createAction<void>(
  `${NAMESPACE}/GET_APPOINTMENTS_FAILURE`,
);
const setAppointments = createAction<Paginated<MatchModel['id']>>(
  `${NAMESPACE}/SET_APPOINTMENTS`,
);

const setFilterParams = createAction<Filters>(`${NAMESPACE}/SET_FILTER_PARAMS`);
const setSearchQuery = createAction<string>(`${NAMESPACE}/SET_SEARCH_QUERY`);
const setSorting = createAction<Sorting>(`${NAMESPACE}/SET_SORTING`);

const searchByQuery = createAction<{ page?: number }>(
  `${NAMESPACE}/SEARCH_BY_QUERY`,
);

const resetAppointments = createAction<void>(`${NAMESPACE}/RESET_APPOINTMENTS`);

const changeAppointmentAttendance = createAction(
  `${NAMESPACE}/CHANGE_APPOINTMENT_ATTENDANCE`,
);

export const actions = {
  getAppointments,
  getAppointmentsRequest,
  getAppointmentsSuccess,
  getAppointmentsFailure,
  setAppointments,

  setFilterParams,
  setSearchQuery,
  setSorting,

  searchByQuery,

  resetAppointments,

  changeAppointmentAttendance,
};
