import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { AddCircleTwoTone } from '@mui/icons-material';

type Props = {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const TeamPlaceholder = (props: Props) => {
  const { title, onClick } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>{title}</Grid>
      {onClick && (
        <Grid item>
          <IconButton onClick={onClick}>
            <AddCircleTwoTone color={'primary'} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default TeamPlaceholder;
