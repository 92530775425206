import { TFunction } from 'i18next';
import * as yup from 'yup';

import { getDefaultPlayerKitColors } from '@core/factories/kit-set';
import { MAX_FIELD_LENGTH } from '@core/constants';
import { getIsPlayerKitEnabled } from '@core/factories/kit-set/get-is-player-kit-enabled';
import {
  KitSet,
  KitSetFormData,
  PlayerKitsModel,
  KitPlayerType,
  KitType,
} from '@core/types';

export enum KITSET_FIELDS {
  title = 'title',
}

export interface KitSetFormValues {
  [KITSET_FIELDS.title]: string;
  homePlayerKit: Record<
    'regularPlayerColors' | 'specialPlayerColors',
    Array<string>
  >;
  awayPlayerKit: Record<
    'regularPlayerColors' | 'specialPlayerColors',
    Array<string>
  >;
  reservedPlayerKit: Record<
    'regularPlayerColors' | 'specialPlayerColors',
    Array<string>
  >;
}

const arrayOfStringValidationSchema = yup.array(yup.string());

const atLeastOnColorValidationSchema = (t: TFunction) =>
  arrayOfStringValidationSchema.min(1, t('At least one color required'));

const getValidationSchema1 = (t: TFunction, shouldValidate: boolean) =>
  shouldValidate
    ? atLeastOnColorValidationSchema(t)
    : arrayOfStringValidationSchema;

export const getValidationSchema = (
  t: TFunction,
  {
    isAwayKitEnabled,
    isReservedKitEnabled,
    isSpecialPlayerEnabled,
  }: Record<
    'isAwayKitEnabled' | 'isReservedKitEnabled' | 'isSpecialPlayerEnabled',
    boolean
  >,
) =>
  yup.object().shape({
    [KITSET_FIELDS.title]: yup
      .string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    homePlayerKit: yup.object({
      regularPlayerColors: atLeastOnColorValidationSchema(t),
      specialPlayerColors: getValidationSchema1(t, isSpecialPlayerEnabled),
    }),
    awayPlayerKit: yup.object({
      regularPlayerColors: getValidationSchema1(t, isAwayKitEnabled),
      specialPlayerColors: getValidationSchema1(
        t,
        isAwayKitEnabled && isSpecialPlayerEnabled,
      ),
    }),
    reservedPlayerKit: yup.object({
      regularPlayerColors: getValidationSchema1(t, isReservedKitEnabled),
      specialPlayerColors: getValidationSchema1(
        t,
        isReservedKitEnabled && isSpecialPlayerEnabled,
      ),
    }),
  });

export const getKitTitle = (t: TFunction, kitType: KitType) => {
  switch (kitType) {
    case 'home':
      return t('Primary set');
    case 'away':
      return t('Secondary set');
    case 'reserved':
      return t('Tertiary set');
  }
};

export const getSpecialPlayerKitToggleLabel = (t: TFunction) => t('Libero');

export const getSpecialPlayerKitFieldLabel = (t: TFunction) =>
  t('Libero colors');

export const getColorsFieldName = (
  kitType: KitType,
  playerType: KitPlayerType,
) => {
  const playerTypeKey =
    playerType === 'regular' ? 'regularPlayerColors' : 'specialPlayerColors';
  const kitTypeKey =
    kitType === 'home'
      ? 'homePlayerKit'
      : kitType === 'away'
        ? 'awayPlayerKit'
        : 'reservedPlayerKit';

  return `${kitTypeKey}.${playerTypeKey}` as `${typeof kitTypeKey}.${typeof playerTypeKey}`;
};

export const defaultPlayerKitColors = getDefaultPlayerKitColors();

export const CREATE_TEAM_KIT_PAGE = 'CREATE_TEAM_KIT_PAGE';

export const getIsAwayKitEnabled = (kitSet: KitSet) =>
  getIsPlayerKitEnabled(kitSet.awayPlayerKit);

export const getIsReservedKitEnabled = (kitSet: KitSet) =>
  getIsPlayerKitEnabled(kitSet.reservedPlayerKit);

export const getIsSpecialPlayerEnabled = (kitSet: KitSet) =>
  !!(
    kitSet.awayPlayerKit.specialPlayerPrimaryColor ||
    kitSet.awayPlayerKit.specialPlayerSecondaryColor ||
    kitSet.homePlayerKit.specialPlayerPrimaryColor ||
    kitSet.homePlayerKit.specialPlayerSecondaryColor ||
    kitSet.reservedPlayerKit.specialPlayerPrimaryColor ||
    kitSet.reservedPlayerKit.specialPlayerSecondaryColor
  );

const getColorsTupleForKitTypeAndPlayerType = (
  kitSet: KitSet,
  kitType: KitType,
  playerType: KitPlayerType,
): Array<string> => {
  const kitTypeKey =
    kitType === 'home'
      ? 'homePlayerKit'
      : kitType === 'away'
        ? 'awayPlayerKit'
        : 'reservedPlayerKit';

  return (
    playerType === 'regular'
      ? [kitSet[kitTypeKey].primaryColor, kitSet[kitTypeKey].secondaryColor]
      : [
          kitSet[kitTypeKey].specialPlayerPrimaryColor,
          kitSet[kitTypeKey].specialPlayerSecondaryColor,
        ]
  ).filter(Boolean);
};

export const getInitialValues = (
  isEdit: boolean,
  kitSet: KitSet,
): null | KitSetFormValues =>
  isEdit
    ? kitSet
      ? {
          title: kitSet.title,
          homePlayerKit: {
            regularPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'home',
              'regular',
            ),
            specialPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'home',
              'special',
            ),
          },
          awayPlayerKit: {
            regularPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'away',
              'regular',
            ),
            specialPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'away',
              'special',
            ),
          },
          reservedPlayerKit: {
            regularPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'reserved',
              'regular',
            ),
            specialPlayerColors: getColorsTupleForKitTypeAndPlayerType(
              kitSet,
              'reserved',
              'special',
            ),
          },
        }
      : null
    : {
        title: '',
        homePlayerKit: {
          regularPlayerColors: [],
          specialPlayerColors: [],
        },
        awayPlayerKit: {
          regularPlayerColors: [],
          specialPlayerColors: [],
        },
        reservedPlayerKit: {
          regularPlayerColors: [],
          specialPlayerColors: [],
        },
      };

export const getColorsRecordForKitType = (
  values: KitSetFormValues,
  kitType: KitType,
  {
    isAwayKitEnabled,
    isReservedKitEnabled,
    isSpecialPlayerEnabled,
  }: Record<
    'isAwayKitEnabled' | 'isReservedKitEnabled' | 'isSpecialPlayerEnabled',
    boolean
  >,
): Omit<PlayerKitsModel, 'id'> => {
  const kitTypeKey =
    kitType === 'home'
      ? 'homePlayerKit'
      : kitType === 'away'
        ? 'awayPlayerKit'
        : 'reservedPlayerKit';

  return (kitType !== 'away' && kitType !== 'reserved') ||
    (kitType === 'away' && isAwayKitEnabled) ||
    (kitType === 'reserved' && isReservedKitEnabled)
    ? {
        primaryColor: values[kitTypeKey].regularPlayerColors[0],
        secondaryColor: values[kitTypeKey].regularPlayerColors[1] ?? null,
        specialPlayerPrimaryColor: isSpecialPlayerEnabled
          ? values[kitTypeKey].specialPlayerColors[0]
          : null,
        specialPlayerSecondaryColor: isSpecialPlayerEnabled
          ? values[kitTypeKey].specialPlayerColors[1]
          : null,
      }
    : {
        primaryColor: null,
        secondaryColor: null,
        specialPlayerPrimaryColor: null,
        specialPlayerSecondaryColor: null,
      };
};

export const getPayload = (
  values: KitSetFormValues,
  {
    isAwayKitEnabled,
    isReservedKitEnabled,
    isSpecialPlayerEnabled,
  }: Record<
    'isAwayKitEnabled' | 'isReservedKitEnabled' | 'isSpecialPlayerEnabled',
    boolean
  >,
): KitSetFormData => {
  const postOrPatchPayload = {
    title: values.title,
    homePlayerKit: getColorsRecordForKitType(values, 'home', {
      isAwayKitEnabled,
      isReservedKitEnabled,
      isSpecialPlayerEnabled,
    }),
    awayPlayerKit: getColorsRecordForKitType(values, 'away', {
      isAwayKitEnabled,
      isReservedKitEnabled,
      isSpecialPlayerEnabled,
    }),
    reservedPlayerKit: getColorsRecordForKitType(values, 'reserved', {
      isAwayKitEnabled,
      isReservedKitEnabled,
      isSpecialPlayerEnabled,
    }),
  };

  return postOrPatchPayload;
};
