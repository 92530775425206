import { UserRoleType } from '@core/types';

export const getUserRoleType = (
  t: (key: string) => string,
  roleType: UserRoleType,
) => {
  switch (roleType) {
    case UserRoleType.ROLE_MATCH_COMMISSIONER:
      return t('Match Commissioner');
    case UserRoleType.ROLE_REFEREE:
      return t('Referee');
    case UserRoleType.ROLE_SUPER_ADMIN:
      return t('Super Admin');
    case UserRoleType.ROLE_COMPETITION_MANAGER:
      return t('Competition Manager');
    case UserRoleType.ROLE_MA_SYSTEM_ADMIN:
      return t('MA System Admin');
    case UserRoleType.ROLE_USER:
      return t('User');
    case UserRoleType.ROLE_CLUB_ADMIN:
      return t('Club admin');
    case UserRoleType.ROLE_REGIONAL_COMPETITION_MANAGER:
      return t('Regional Competition Manager');
    default:
      return '-';
  }
};
