import React from 'react';
import { MenuList } from '@mui/material';
import type { MenuListProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

type Props = MenuListProps<AutocompleteOption, boolean>;

const MenuListComponent = (props: Props) => {
  return (
    <MenuList
      sx={{ maxHeight: 250, overflowY: 'auto' }}
      ref={props.innerRef as any}
    >
      {props.children}
    </MenuList>
  );
};

export default MenuListComponent;
