import { path } from 'ramda';

import { State } from '@core/store';
import { KitSetList } from '@core/types';

import { NAMESPACE } from './constants';

export const getKitSets = (
  state: State,
  { competitorId }: { competitorId: number },
): KitSetList => path(['tabs', NAMESPACE, competitorId, 'kitSets'], state);
