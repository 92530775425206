import React from 'react';
import { AccordionSummary } from '@mui/material';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ExpansionPanelTitle } from '@core/components';
import { getCompetitorByTeamId } from '@core/store/modules/tabs/competitor-profile/selectors';
import { Competitor, TeamModel } from '@core/types';
import { State } from '@core/store';
import CompetitorAvatar from '@core/pages/competitor-profile/competitor-avatar';

interface StateProps {
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
}

interface OwnProps {
  teamId: number;
  competitionId: number;
  competitorId: number;
}

type Props = OwnProps & StateProps;

const CompetitorProfilePanelSummary = (props: Props) => {
  const { competitionId, teamId, competitorDetails, competitorId } = props;
  const { t } = useTranslation();

  return (
    <AccordionSummary className="without-cursor">
      <ExpansionPanelTitle
        label={t('Team in competition')}
        title={competitorDetails?.competitor?.title || ''}
        strongTitle
        subtitle={competitorDetails?.competitor?.internationalTitle || ''}
        avatar={
          <CompetitorAvatar
            competitor={competitorDetails?.competitor}
            refreshParams={{ competitionId, teamId, competitorId }}
          />
        }
      />
    </AccordionSummary>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitorDetails: getCompetitorByTeamId(state, ownProps?.competitorId),
});

export default connect(mapStateToProps)(CompetitorProfilePanelSummary);
