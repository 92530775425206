import restService from '@core/api/rest-service';
import { GroupStandingSettings } from '@core/types';

export const getGroupStandingSettings = (groupId: number) =>
  restService.get<GroupStandingSettings>(
    `/v1/groups/${groupId}/standings/settings`,
  );

export const setGroupStandingSettings = (
  groupId: number,
  data: GroupStandingSettings,
) =>
  restService.patch<GroupStandingSettings>(
    `/v1/groups/${groupId}/standings/settings`,
    data,
  );
