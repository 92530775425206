import { select } from 'redux-saga/effects';
import { getCurrentUser } from '@core/pages/user-profile/store/selectors';

// requires "downlevelIteration": true
function* getAuthorizedRoles() {
  const currentUser = (yield select(getCurrentUser)) as ReturnType<
    typeof getCurrentUser
  >;

  return currentUser.userRoles
    .map((userRole) => userRole.role)
    .filter((value, index, array) => array.indexOf(value) === index)
    .join(',');
}

export function* authorizationParamsTuple() {
  const concatenatedUserRoles = yield* getAuthorizedRoles();

  return ['filter[forRole]', concatenatedUserRoles] as [string, string];
}

export function* authorizationParamsObject() {
  const concatenatedUserRoles = yield* getAuthorizedRoles();

  return { 'filter[forRole]': concatenatedUserRoles };
}
