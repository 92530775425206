import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

export const NAMESPACE = 'matchOfficialAllocations';

export const actions = createActions(
  {
    SET_MATCH_OFFICIAL_ALLOCATIONS: [prop('payload'), pick(['matchId'])],
  },
  'GET_MATCH_OFFICIAL_ALLOCATIONS',
  'GET_MATCH_OFFICIAL_ALLOCATIONS_REQUEST',
  'GET_MATCH_OFFICIAL_ALLOCATIONS_SUCCESS',
  'GET_MATCH_OFFICIAL_ALLOCATIONS_FAILURE',

  'APPOINT_MATCH_OFFICIAL',
  'APPOINT_MATCH_OFFICIAL_REQUEST',
  'APPOINT_MATCH_OFFICIAL_SUCCESS',
  'APPOINT_MATCH_OFFICIAL_FAILURE',

  'REMOVE_MATCH_OFFICIAL',
  'REMOVE_MATCH_OFFICIAL_REQUEST',
  'REMOVE_MATCH_OFFICIAL_SUCCESS',
  'REMOVE_MATCH_OFFICIAL_FAILURE',
  { prefix: NAMESPACE },
) as unknown as {
  setMatchOfficialAllocations: IdentityActionMetaFunction<
    { matchId: number; payload: unknown },
    { payload: unknown },
    { matchId: number }
  >;
  getMatchOfficialAllocations: IdentityActionFunction<number>;
  getMatchOfficialAllocationsRequest: RequestLifecycleAction<{
    matchId: number;
  }>;
  getMatchOfficialAllocationsSuccess: RequestLifecycleAction<{
    matchId: number;
  }>;
  getMatchOfficialAllocationsFailure: RequestLifecycleAction<{
    matchId: number;
  }>;

  appointMatchOfficial: IdentityActionFunction<{
    matchId: number;
    stageId: number;
    data: {
      matchOfficialRoleId: number;
      matchOfficialId: number;
    };
  }>;
  appointMatchOfficialRequest: RequestLifecycleAction<{ matchId: number }>;
  appointMatchOfficialSuccess: RequestLifecycleAction<{ matchId: number }>;
  appointMatchOfficialFailure: RequestLifecycleAction<{ matchId: number }>;

  removeMatchOfficial: IdentityActionFunction<{
    matchId: number;
    stageId: number;
    matchOfficialAppointmentId: number;
  }>;
  removeMatchOfficialRequest: RequestLifecycleAction<{ matchId: number }>;
  removeMatchOfficialSuccess: RequestLifecycleAction<{ matchId: number }>;
  removeMatchOfficialFailure: RequestLifecycleAction<{ matchId: number }>;
};
