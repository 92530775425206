import { createCoreSelector } from '@core/store/helpers';
import { NAMESPACE } from './constants';
import type { State } from '@core/store';

const getTabs = (state: State) => state.ui[NAMESPACE];

export const getTabsList = createCoreSelector(getTabs, (tabs) => tabs.allTabs);

export const getActiveTabIndex = createCoreSelector(
  getTabs,
  (tabs) => tabs.activeIndex,
);
