import { handleActions } from 'redux-actions';
import { assoc, assocPath, dissoc, __ } from 'ramda';

import { CompetitorAdditionState } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [id: string]: CompetitorAdditionState;
}

export const defaultState: DefaultState = {};

type Payload = {
  competitionId: number;
  data: any;
};

export const initialState: CompetitorAdditionState = {
  generalInformation: null,
  team: null,
  kitSets: null,
  teamSearchList: null,
  defaultVenuesList: null,
  alternativeVenuesList: null,
};

function appendToVenueList(
  state: DefaultState,
  payload: Payload,
  venueType: 'defaultVenuesList' | 'alternativeVenuesList',
) {
  const competitionId = payload.competitionId;
  const currentList = state?.[competitionId]?.[venueType]?.list;
  const addToList = assocPath([competitionId, venueType], __, state);

  if (!currentList) {
    return addToList(payload.data);
  }

  return addToList({
    ...payload.data,
    list: [...currentList, ...payload.data.list],
  });
}

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setSelectedTeam.toString()]: (state, { payload }) =>
      assoc(
        String(payload.competitionId),
        {
          ...state[payload.competitionId],
          generalInformation: payload.data,
          team: payload.data,
        },
        state,
      ), // On submit also resets competition state;

    [actions.resetCompetitorState.toString()]: (state, { payload }) =>
      dissoc(String(payload.competitionId), state),

    [actions.setKitSets.toString()]: (state, { payload }) =>
      assocPath([payload.competitionId, 'kitSets'], payload.data, state),

    [actions.setTeams.toString()]: (state, { payload }) =>
      assocPath([payload.competitionId, 'teamSearchList'], payload.data, state),

    [actions.setDefaultVenuesList.toString()]: (state, { payload }) =>
      appendToVenueList(state, payload, 'defaultVenuesList'),

    [actions.resetSearchDefaultVenues.toString()]: (state, { payload }) =>
      assocPath([payload.competitionId, 'defaultVenuesList'], {}, state),

    [actions.setAlternativeVenuesList.toString()]: (state, { payload }) =>
      appendToVenueList(state, payload, 'alternativeVenuesList'),

    [actions.resetSearchAlternativeVenues.toString()]: (state, { payload }) =>
      assocPath([payload.competitionId, 'alternativeVenuesList'], {}, state),
  },
  defaultState,
);

export default reducer;
