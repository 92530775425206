import { SanctionStatus } from '@core/types';

export const getSanctionStatus = (
  t: (key: string) => string,
  status: SanctionStatus,
) => {
  switch (status) {
    case SanctionStatus.ACTIVE:
      return t('Active');
    case SanctionStatus.CANCELLED:
      return t('Cancelled');
    case SanctionStatus.CLOSED:
      return t('Closed');
    case SanctionStatus.PENDING:
      return t('Pending');
    default:
      return '';
  }
};
