import { MatchOfficialRole, MatchOfficialRoleType } from '@core/types';

export const getMatchOfficialRoleType = (
  t: (key: string) => string,
  matchOfficial: MatchOfficialRole,
) => {
  const { roleType } = matchOfficial;
  switch (roleType) {
    case MatchOfficialRoleType.REFEREE:
      return t('1st Referee');
    case MatchOfficialRoleType.SECOND_REFEREE:
      return t('2nd Referee');
    case MatchOfficialRoleType.ASSISTANT_REFEREE_1ST:
      return t('1st Assistant referee');
    case MatchOfficialRoleType.ASSISTANT_REFEREE_2ND:
      return t('2nd Assistant referee');
    case MatchOfficialRoleType.FOURTH_OFFICIAL:
      return t('Fourth official');
    case MatchOfficialRoleType.ADDITIONAL_ASSISTANT_REFEREE_1ST:
      return t('1st Additional Assistant referee');
    case MatchOfficialRoleType.ADDITIONAL_ASSISTANT_REFEREE_2ND:
      return t('2nd Additional Assistant referee');
    case MatchOfficialRoleType.RESERVE_ASSISTANT_REFEREE:
      return t('Reserve Assistant referee');
    case MatchOfficialRoleType.DELEGATE:
      return t('Delegate');
    case MatchOfficialRoleType.REFEREE_OBSERVER:
      return t('Referee observer/assessor');
    case MatchOfficialRoleType.VIDEO_ASSISTANT_REFEREE:
      return t('Video assistant referee');
    case MatchOfficialRoleType.ASSISTANT_VIDEO_ASSISTANT_REFEREE_1ST:
      return t('1st Assistant video assistant referee');
    case MatchOfficialRoleType.ASSISTANT_VIDEO_ASSISTANT_REFEREE_2ND:
      return t('2nd Assistant video assistant referee');
    case MatchOfficialRoleType.MATCH_COMMISSIONER:
      return t('Match commissioner');
    case MatchOfficialRoleType.CLUB_ADMIN:
      return t('Club admin');
    case MatchOfficialRoleType.SCORER:
      return t('Scorer');
    case MatchOfficialRoleType.ASSISTANT_SCORER:
      return t('Assistant scorer');
    case MatchOfficialRoleType.LINE_JUDGE:
      return t('Line judge');
    default:
      return roleType;
  }
};
