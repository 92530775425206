import React from 'react';
import { Grid, Typography } from '@mui/material';

export interface HeaderWithCallToActionProps {
  title: string;
  button?: React.ReactNode;
}

const HeaderWithCallToAction = ({
  title,
  button,
}: HeaderWithCallToActionProps) => (
  <Grid container spacing={3} justifyContent="space-between">
    <Grid item xs>
      <Typography variant="h6">{title}</Typography>
    </Grid>
    {button && (
      <Grid item xs={12} sm="auto">
        {button}
      </Grid>
    )}
  </Grid>
);

export default HeaderWithCallToAction;
