import React from 'react';
import { Grid } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomTable } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { Column } from '@core/components/material-table';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import { State } from '@volleyball/store';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';
import { ModalTypes } from '@volleyball/types';

import { MatchModel, MatchStatus } from '@core/types';
import { MatchDataViewTableTitleAndAction } from './match-data-view-table-title-and-action';
import { OwnProps as MatchDataEditModalProps } from './match-data-edit-modal';

interface StateProps {
  isLoading: boolean;
}

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  competitionId: number;
  match: MatchModel;
  stageId: number | undefined;
}

type Props = StateProps & DispatchProps & OwnProps;

const MatchDataViewMatchAttendanceTable: React.FC<Props> = ({
  competitionId,
  actions,
  isLoading,
  match,
  stageId,
}: Props) => {
  const { t } = useTranslation();
  const isMatchComplete = [MatchStatus.COMPLETE, MatchStatus.BYE].includes(
    match.status,
  );

  const handleEdit = (editOptions: MatchDataEditModalProps) => {
    actions.globalModal.openModal<MatchDataEditModalProps>({
      type: ModalTypes.MATCH_DATA_EDIT_MODAL,
      params: editOptions,
    });
  };

  const data = [
    {
      id: 1,
      label: t('Attendance'),
      value: match.attendance,
    },
  ];

  type DataType = (typeof data)[number];

  const columns: Array<Column<DataType> & { field?: keyof DataType }> = [
    {
      field: 'label',
      headerStyle: { padding: 0 },
    },
    {
      field: 'value',
      cellStyle: {
        textAlign: 'right',
      },
      headerStyle: { padding: 0 },
    },
  ];

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <MatchDataViewTableTitleAndAction
        title={t('Other details')}
        buttonOptions={{
          component: 'IconButton',
          text: t('Edit'),
          disabled: isMatchComplete || !match._links.patch,
          onClick: () =>
            handleEdit({ competitionId, stageId, match, kind: 'other' }),
          dataQa: 'data-qa',
        }}
      />
      <Grid item xs={12}>
        <CustomTable
          noPaper
          columns={columns}
          data={data}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

const isLoadingSelector = (matchId: number) =>
  createLoadingSelector([
    { actionName: matchProfileActions.getMatchRequest.toString(), id: matchId },
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isLoading: isLoadingSelector(ownProps.match.id)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchDataViewMatchAttendanceTable);
