import store from './';

const injectedModules = new Map();

export const injectModules = (module: any, path: string) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<false | string>(async (resolve, reject) => {
    try {
      if (injectedModules.has(path)) {
        resolve(false);
      }

      const { reducers: rootReducers, sagas: rootSagas } =
        module?.default || {};

      !!rootReducers && (await store.injectReducer(path, rootReducers));
      !!rootSagas && (await store.injectSaga(path, rootSagas));

      const moduleStore = module?.store;

      const { reducers, sagas, api } = moduleStore || {};

      !!reducers && (await store.injectReducer(path, reducers));
      !!sagas && (await store.injectSaga(path, sagas));

      if (api?.NAMESPACE && api?.reducers) {
        !!api?.reducers &&
          (await store.injectReducer(`api/${api?.NAMESPACE}`, api?.reducers));
        !!api?.sagas &&
          typeof api?.sagas === 'function' &&
          (await store.injectSaga(`api/${api?.NAMESPACE}`, api?.sagas));
      }

      if (moduleStore?.NAMESPACE && moduleStore?.reducers) {
        await store.injectReducer(
          `api/${module.store.NAMESPACE}`,
          module.store.reducers,
        );
      }

      injectedModules.set(path, true);

      resolve(path);
    } catch (error) {
      reject(error);
    }
  });
