import { createActions } from 'redux-actions';

export const NAMESPACE = 'competitorPlayersList';

export const actions = createActions(
  'GET_COMPETITOR_PLAYERS_REQUEST',
  'GET_COMPETITOR_PLAYERS_SUCCESS',
  'GET_COMPETITOR_PLAYERS_FAILURE',

  'UPDATE_COMPETITOR_PLAYER_REQUEST',
  'UPDATE_COMPETITOR_PLAYER_SUCCESS',
  'UPDATE_COMPETITOR_PLAYER_FAILURE',

  'ADD_PLAYERS_TO_COMPETITOR_REQUEST',
  'ADD_PLAYERS_TO_COMPETITOR_SUCCESS',
  'ADD_PLAYERS_TO_COMPETITOR_FAILURE',

  'UPDATE_PLAYERS_FOR_COMPETITOR_REQUEST',
  'UPDATE_PLAYERS_FOR_COMPETITOR_SUCCESS',
  'UPDATE_PLAYERS_FOR_COMPETITOR_FAILURE',

  'GET_PREVIOUS_COMPETITOR_PLAYERS_REQUEST',
  'GET_PREVIOUS_COMPETITOR_PLAYERS_SUCCESS',
  'GET_PREVIOUS_COMPETITOR_PLAYERS_FAILURE',

  'SET_COMPETITOR_PLAYERS',

  { prefix: NAMESPACE },
) as unknown as {
  getCompetitorPlayersRequest: RequestLifecycleAction;
  getCompetitorPlayersSuccess: RequestLifecycleAction;
  getCompetitorPlayersFailure: RequestLifecycleAction;
  updateCompetitorPlayerRequest: RequestLifecycleAction;
  updateCompetitorPlayerSuccess: RequestLifecycleAction;
  updateCompetitorPlayerFailure: RequestLifecycleAction;
  addPlayersToCompetitorRequest: RequestLifecycleAction;
  addPlayersToCompetitorSuccess: RequestLifecycleAction;
  addPlayersToCompetitorFailure: RequestLifecycleAction;
  updatePlayersForCompetitorRequest: RequestLifecycleAction;
  updatePlayersForCompetitorSuccess: RequestLifecycleAction;
  updatePlayersForCompetitorFailure: RequestLifecycleAction;
  getPreviousCompetitorPlayersRequest: RequestLifecycleAction;
  getPreviousCompetitorPlayersSuccess: RequestLifecycleAction;
  getPreviousCompetitorPlayersFailure: RequestLifecycleAction;
  setCompetitorPlayers: UnknownAction;
};
