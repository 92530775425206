import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import { AddCircleOutlineOutlined, DeleteTwoTone } from '@mui/icons-material';
import { uuid } from '@core/helpers';
import { isNil } from 'ramda';
import CustomTable from '@core/components/custom-table';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments';
import {
  areAssignedCompetitionsLoading,
  getAssignedCompetitionsById,
  getAssignedCompetitionsPagination,
} from '@core/store/modules/pages/assignments/selectors';
import ColumnWithSubValue from '@core/components/column-with-sub-value';
import { Competition } from '@core/icons';
import {
  getGenders,
  getSports,
  getCompetitionStatus,
  getClubType,
  createURL,
  getStatusColor,
} from '@core/helpers';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import paths from '@volleyball/routes/paths';
import Status from '@core/components/status';
import FullScreenSpinner from '@core/components/full-screen-spinner';
import AssignCompetitionModal from './assign-competition-modal';
import { useConfirmationDialog } from '@core';
import { Column } from '@core/components/material-table';
import { CompetitionResponse, PaginationLimits } from '@core/types';
import { useAppDispatch } from '@volleyball/store/hooks';

type Props = {
  personId: number;
  personName: string;
};

const limit: PaginationLimits = 999;
const perPage: PaginationLimits = 10;

const AssignedCompetitionsTable = ({ personId, personName }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const isLoading = useSelector(areAssignedCompetitionsLoading);
  const assignedCompetitions = useSelector(
    getAssignedCompetitionsById(personId),
  );
  const assignedCompetitionsPagination = useSelector(
    getAssignedCompetitionsPagination,
  );
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const pages = Math.ceil((assignedCompetitions?.length ?? 0) / perPage);
  const data = (assignedCompetitions ?? []).slice(
    page * perPage - perPage,
    page * perPage,
  );

  const reloadCallback = () => {
    dispatch(
      assignmentsActions.getAssignedCompetitions({
        personId,
        queryParams: { limit },
      }),
    );
  };

  const handleUnassign = async (competitionId: number) => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this assignment?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      dispatch(
        assignmentsActions.unassignRegionalCompetitionManager({
          reloadCallback,
          personId,
          competitionId,
        }),
      );
    }
  };

  const handleCompetitionClick =
    ({ id, title }: Pick<CompetitionResponse, 'id' | 'title'>) =>
    () => {
      dispatch(
        tabActions.openTab({
          id: uuid(),
          title,
          props: { id },
          name: createURL(paths.competitionProfile),
          isClosable: true,
        }),
      );
    };

  const columns: Column<CompetitionResponse>[] = [
    {
      title: t('Title'),
      render: ({ id, title, internationalTitle, logoFileLink }) => (
        <ColumnWithSubValue
          value={
            <Link
              onClick={handleCompetitionClick({ id, title })}
              style={{ cursor: 'pointer' }}
            >
              {title}
            </Link>
          }
          valueTypographyProps={{ variant: 'subtitle2' }}
          subValue={internationalTitle}
          logo={{ url: logoFileLink, defaultIcon: <Competition /> }}
        />
      ),
    },
    {
      title: t('Comp. ID'),
      field: 'id',
    },
    {
      title: t('Season'),
      field: 'competitionSeasonTitle',
    },
    {
      title: t('Sport'),
      field: 'sportTitle',
      render: ({ sportTitle }) => getSports(t, sportTitle),
    },
    {
      title: t('Gender'),
      field: 'gender',
      render: ({ gender }) => getGenders(t, gender),
    },
    {
      title: t('Level'),
      field: 'competitionLevelTitle',
    },
    {
      title: t('Type'),
      field: 'clubType',
      render: ({ clubType }) => getClubType(t, clubType),
    },
    {
      title: t('Teams'),
      field: 'participatingTeams',
    },
    {
      title: t('Status'),
      field: 'status',
      render: ({ status }) => (
        <Status
          title={getCompetitionStatus(t, status)}
          statusColor={getStatusColor(status)}
        />
      ),
    },
    {
      field: 'actions',
      render: ({ id }) => (
        <IconButton color="primary" onClick={() => handleUnassign(id)}>
          <DeleteTwoTone />
        </IconButton>
      ),
    },
  ];

  const onPageChange = ({ page }: { page: number }) => setPage(page);

  const pagination = {
    page,
    pages,
    limit: perPage,
    total: assignedCompetitionsPagination?.total,
    reloadData: onPageChange,
  };

  useEffect(() => {
    dispatch(
      assignmentsActions.getAssignedCompetitions({
        personId,
        queryParams: { limit },
      }),
    );
  }, [personId, dispatch]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        pt={2}
        alignItems="center"
      >
        <Box mr={2}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {t('Assigned competitions')}
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineOutlined />}
          onClick={() => setAssignModalOpen(true)}
          data-qa="assign-modal-open"
        >
          {t('Assign')}
        </Button>
      </Box>

      <Box p={3}>
        {isLoading && isNil(assignedCompetitions) ? (
          <FullScreenSpinner />
        ) : (
          assignedCompetitions && (
            <CustomTable
              noPaper
              borderedTable
              borderBottomTable
              columns={columns}
              isLoading={isLoading}
              data={data}
              options={{
                toolbar: false,
                headerStyle: {
                  height: 40,
                  paddingBottom: 8,
                  paddingTop: 8,
                },
              }}
              pagination={pagination}
            />
          )
        )}
      </Box>
      {assignModalOpen && (
        <AssignCompetitionModal
          personId={personId}
          personName={personName}
          isModalOpen={assignModalOpen}
          reloadCallback={reloadCallback}
          assignedCompetitions={assignedCompetitions}
          closeModal={() => setAssignModalOpen(false)}
        />
      )}
    </>
  );
};

export default AssignedCompetitionsTable;
