import { object, array, number } from 'yup';
import { TFunction } from 'i18next';

import { STANDING_SETTINGS_FIELD_NAMES } from '../constants';

const getValidationSchema = (t: TFunction, secondFieldsRequired: boolean) => {
  let validationRules = {
    [STANDING_SETTINGS_FIELD_NAMES.matchSetWin1]: number()
      .min(1, t('Minimum {{value}} point', { value: 1 }))
      .required(t('Required')),
    [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: number()
      .notRequired()
      .nullable(),
    [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss1]: number()
      .min(-3, t('Minimum {{value}} points', { value: -3 }))
      .required(t('Required')),
    [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: number()
      .notRequired()
      .nullable(),
    [STANDING_SETTINGS_FIELD_NAMES.standingTableHeaders]: array(),
    [STANDING_SETTINGS_FIELD_NAMES.standingTableRanks]: array(),
  };

  if (secondFieldsRequired) {
    validationRules = {
      ...validationRules,
      [STANDING_SETTINGS_FIELD_NAMES.matchSetWin2]: number()
        .min(1, t('Minimum {{value}} point', { value: 1 }))
        .required(t('Required')),
      [STANDING_SETTINGS_FIELD_NAMES.matchSetLoss2]: number()
        .min(-3, t('Minimum {{value}} points', { value: -3 }))
        .required(t('Required')),
    };
  }

  return object().shape(validationRules);
};

export default getValidationSchema;
