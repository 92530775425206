import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { Grid, Box, Paper, IconButton, Button } from '@mui/material';
import { AddCircleTwoTone, DeleteTwoTone } from '@mui/icons-material';
import {
  CustomTable,
  FullScreenSpinner,
  PersonNameColumn,
  ContentBlock,
} from '@core/components';
import {
  getRegionalCompetitionManagerId,
  getUserRoleTypeValues,
} from '@core/helpers';
import { useCoreDispatch, useConfirmationDialog } from '@core/hooks';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments/actions';
import {
  areCompetitionAssignedPersonsLoading,
  getAssignedPersonsById,
} from '@core/store/modules/pages/assignments/selectors';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import AssignManagerModal from './assign-manager-modal';
import { Column, MTableBodyRow } from '@core/components/material-table';
import type { User, PaginationLimits, CompetitionResponse } from '@core/types';
import {
  ASSIGNED_MANAGERS_ROW,
  ASSIGN_MANAGERS_BUTTON,
  ASSIGN_MANAGERS_DELETE_BUTTON,
} from './__tests__/testIds';

type Props = {
  competition: CompetitionResponse;
};

const limit: PaginationLimits = 999;
const perPage: PaginationLimits = 5;

const AssignedManagers = ({ competition }: Props) => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const isLoading = useSelector(areCompetitionAssignedPersonsLoading);
  const existingCompetition = useSelector(
    getCompetitionDetailsById(competition?.id),
  );
  const currentCompetition = existingCompetition ?? competition;
  const assignedPersons = useSelector(
    getAssignedPersonsById(currentCompetition.id),
  );

  const userRoleValues = getUserRoleTypeValues();
  const canEdit =
    !!currentCompetition._links?.manageRegionalCompetitionManagerAssignments;

  const pages = Math.ceil((assignedPersons?.list.length ?? 0) / perPage);
  const data = (assignedPersons?.list ?? []).slice(
    page * perPage - perPage,
    page * perPage,
  );

  useEffect(() => {
    dispatch(
      assignmentsActions.getCompetitionAssignedPersons({
        competitionId: currentCompetition.id,
        queryParams: { limit },
      }),
    );
  }, [currentCompetition.id, dispatch]);

  const reloadCallback = () => {
    dispatch(
      assignmentsActions.getCompetitionAssignedPersons({
        competitionId: currentCompetition.id,
        queryParams: { limit },
      }),
    );
  };

  const handleUnassign = async (person: User) => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this assignment?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      dispatch(
        assignmentsActions.unassignRegionalCompetitionManager({
          reloadCallback,
          personId: getRegionalCompetitionManagerId(person),
          competitionId: currentCompetition.id,
        }),
      );
    }
  };

  const columns: Column<User>[] = [
    {
      title: t('Name'),
      render: ({ firstName, lastName, profilePictureFileLink }) => (
        <PersonNameColumn
          person={{
            localFirstName: firstName,
            localFamilyName: lastName,
            logoFileLink: profilePictureFileLink,
          }}
          showInitials
          withLogo
        />
      ),
    },
    { title: t('Email Address'), field: 'email' },
    {
      title: t('User Roles'),
      render: ({ userRoles }) =>
        userRoles.map(({ role }) => userRoleValues[role]).join(', '),
    },
    canEdit && {
      field: 'actions',
      render: (rowData) => (
        <IconButton
          color="primary"
          onClick={() => handleUnassign(rowData)}
          data-qa={ASSIGN_MANAGERS_DELETE_BUTTON}
        >
          <DeleteTwoTone />
        </IconButton>
      ),
    },
  ];

  const onPageChange = ({ page }: { page: number }) => setPage(page);

  const pagination = {
    page,
    pages,
    limit: perPage,
    total: assignedPersons?.total,
    reloadData: onPageChange,
  };

  return (
    <ContentBlock title={t('Assigned managers')}>
      <Grid item xs={12}>
        {isLoading && isNil(assignedPersons) ? (
          <FullScreenSpinner />
        ) : (
          assignedPersons && (
            <Paper variant="elevation">
              {canEdit && (
                <Box display="flex" justifyContent="flex-end" p={1.5}>
                  <Button
                    variant="outlined"
                    onClick={() => setAssignModalOpen(true)}
                    startIcon={<AddCircleTwoTone />}
                    data-qa={ASSIGN_MANAGERS_BUTTON}
                  >
                    {t('Assign')}
                  </Button>
                </Box>
              )}

              <CustomTable
                disableBodyElevation
                isLoading={isLoading}
                columns={columns}
                data={data}
                options={{
                  search: false,
                  toolbar: false,
                  showFirstLastPageButtons: false,
                }}
                pagination={pages > 1 ? pagination : null}
                components={{
                  Row: (props) => (
                    <MTableBodyRow data-qa={ASSIGNED_MANAGERS_ROW} {...props} />
                  ),
                }}
              />
            </Paper>
          )
        )}
        {assignModalOpen && (
          <AssignManagerModal
            competition={currentCompetition}
            isModalOpen={assignModalOpen}
            reloadCallback={reloadCallback}
            assignedPersons={assignedPersons?.list}
            closeModal={() => setAssignModalOpen(false)}
          />
        )}
      </Grid>
    </ContentBlock>
  );
};

export default AssignedManagers;
