import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@mui/material';
import {
  AddCircleTwoTone,
  DeleteTwoTone as DeleteIcon,
} from '@mui/icons-material';

import {
  ColumnWithSubValue,
  AlertStatus,
  CustomTable,
  Status,
  PersonNameColumn,
} from '@core/components';
import { useConfirmationDialog } from '@core/hooks';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { Column } from '@core/components/material-table';
import {
  StatusColors,
  MatchStatus,
  MatchModel,
  MatchOfficialAllocationModel,
  MatchOfficialAllocationsList,
  ModalTypes,
  MatchOfficialRoleType,
  Group,
} from '@core/types';
import { State } from '@core/store';
import { getConflictStatus } from '@core/helpers';
import { ADD_BUTTON } from '@core/constants/test-ids';
import { appendRowId } from '@core/helpers/append-row-id';
import { actions as matchOfficialAllocationsActions } from '@core/store/modules/match-official-allocations';
import { getMatchOfficialsAllocations } from '@core/store/modules/match-official-allocations/selectors';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { getSortedByMatchOfficialRoles } from '@core/helpers/match-official-role';

import { actions as modalActions } from '@core/store/modules/ui/match-official-allocations';
import { getModalMode } from '@core/store/modules/ui/match-official-allocations/selectors';
import * as testIds from './tests/test-ids';
import { MatchOfficialAllocationModalProps } from './components/match-official-allocation-modal';

interface DispatchProps {
  actions: {
    matchOfficialAllocations: typeof matchOfficialAllocationsActions;
    modal: typeof modalActions;
    globalModal: typeof globalModalActions;
  };
}

interface StateProps {
  isLoading: boolean;
  isModalOpen: boolean;
  matchOfficials: MatchOfficialAllocationsList;
}

interface OwnProps {
  match: MatchModel;
  topBorder?: boolean;
  borderedTable?: boolean;
  stageId: number;
  group: Group;
}

type Props = OwnProps & DispatchProps & StateProps;

const MatchOfficialAllocations = (props: Props) => {
  const {
    actions,
    match,
    matchOfficials,
    isLoading,
    topBorder,
    borderedTable,
    stageId,
    group,
  } = props;
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    actions.matchOfficialAllocations.getMatchOfficialAllocations(match.id);
  }, []);

  const handleAppointClickFactory = ({
    roleId,
    selectedOfficial,
  }: {
    roleId: number;
    selectedOfficial: MatchOfficialAllocationModel;
  }) => {
    return () => {
      actions.globalModal.openModal<MatchOfficialAllocationModalProps>({
        type: ModalTypes.MATCH_OFFICIAL_ALLOCATION_MODAL,
        params: {
          match,
          roleId,
          stageId,
          stageType: group.stageType,
          selectedOfficial,
        },
      });
    };
  };

  const handleRemoveClick =
    (matchOfficialAppointmentId: number) => async () => {
      const confirmed = await getConfirmation({
        title: t('Delete'),
        message: t('Are you sure you want to delete this match official?'),
        confirmText: t('Delete'),
        cancelText: t('Cancel'),
      });

      if (confirmed) {
        actions.matchOfficialAllocations.removeMatchOfficial({
          matchId: match.id,
          stageId,
          matchOfficialAppointmentId,
        });
      }
    };

  const data: Array<MatchOfficialAllocationModel> =
    getSortedByMatchOfficialRoles(
      t,
      matchOfficials || [],
      (matchOfficialAllocationsList: MatchOfficialAllocationsList[number]) =>
        matchOfficialAllocationsList.matchOfficialRole,
    ).map(
      appendRowId(
        (value) =>
          `${value.matchOfficialRoleAppointmentId ?? 'undefined'}-${
            value.matchOfficialRole.id
          }`,
      ),
    );

  const columns: Array<Column<InferArray<typeof data>>> = [
    {
      title: t('Match official'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={t(rowData.matchOfficialRole?.title)}
          subValue={t(rowData.matchOfficialGroup?.title)}
        />
      ),
    },
    {
      title: t('Name'),
      render: (rowData) =>
        rowData.matchOfficial ? (
          <PersonNameColumn person={rowData.matchOfficial} withLogo />
        ) : null,
    },
    {
      title: t('Nationality'),
      field: 'matchOfficial.nationalCitizenships.0.iso3',
    },
    {
      title: t('Status'),
      field: 'status',
      render: (rowData) => (
        <Box>
          {rowData.matchOfficialRoleAppointmentId ? (
            <Status title={t('Confirmed')} statusColor={StatusColors.GREEN} />
          ) : (
            <Status
              title={t('Not appointed')}
              statusColor={StatusColors.LIGHT_GREY}
            />
          )}
          {rowData.conflictTypes?.map((conflictType, index) => (
            <AlertStatus
              key={index}
              title={getConflictStatus(t, conflictType)}
            />
          ))}
        </Box>
      ),
    },
    {
      field: 'actions',
      render: (rowData) => {
        const handleAppointClick = handleAppointClickFactory({
          roleId: rowData.matchOfficialRole.id,
          selectedOfficial: rowData,
        });

        const disabled = match.status === MatchStatus.COMPLETE;

        const getAllowAdditionalAppointments = (): boolean => {
          if (
            rowData.matchOfficialRoleAppointmentId &&
            rowData.matchOfficialRole.roleType ===
              MatchOfficialRoleType.LINE_JUDGE
          ) {
            const lastLineJudge = data
              .filter(
                ({ matchOfficialRole }) =>
                  matchOfficialRole.roleType ===
                  MatchOfficialRoleType.LINE_JUDGE,
              )
              .slice(-1)[0];

            return (
              rowData.matchOfficialRoleAppointmentId ===
              lastLineJudge.matchOfficialRoleAppointmentId
            );
          }

          return false;
        };

        return rowData.matchOfficialRoleAppointmentId ? (
          <>
            {getAllowAdditionalAppointments() && (
              <IconButton
                disabled={disabled}
                color="primary"
                onClick={handleAppointClick}
                title={t('Add')}
                data-qa={ADD_BUTTON}
              >
                <AddCircleTwoTone />
              </IconButton>
            )}
            <IconButton
              disabled={disabled}
              color="primary"
              onClick={handleRemoveClick(
                rowData.matchOfficialRoleAppointmentId,
              )}
              title={t('Delete')}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ) : (
          <Button
            disabled={disabled}
            onClick={handleAppointClick}
            data-qa={testIds.APPOINT_BUTTON}
          >
            {t('Appoint')}
          </Button>
        );
      },
    },
  ];

  return (
    <CustomTable
      noPaper
      columns={columns}
      isLoading={isLoading}
      data={data}
      topBorder={topBorder}
      borderedTable={borderedTable}
      options={{
        headerStyle: {
          height: 40,
          paddingBottom: 8,
          paddingTop: 8,
        },
      }}
    />
  );
};

const isLoadingSelector = (ownProps: OwnProps) => {
  const matchId = ownProps.match.id;

  return createLoadingSelector([
    matchOfficialAllocationsActions.appointMatchOfficialRequest({ matchId }),
    matchOfficialAllocationsActions.removeMatchOfficialRequest({ matchId }),
    matchOfficialAllocationsActions.getMatchOfficialAllocationsRequest({
      matchId,
    }),
  ]);
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchOfficialAllocations: bindActionCreators(
      matchOfficialAllocationsActions,
      dispatch,
    ),
    modal: bindActionCreators(modalActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isLoading: isLoadingSelector(ownProps)(state),
  matchOfficials: getMatchOfficialsAllocations(ownProps?.match?.id)(state),
  isModalOpen: getModalMode(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchOfficialAllocations);
