import { GroupSettings, Sports } from '@core/types';

import { MatchOfficialSettings, FIELD_NAMES } from '../constants';

export interface FormValues {
  [FIELD_NAMES.numberOfSets]: number;
  [FIELD_NAMES.pointsToWinInRegularSet]: number;
  [FIELD_NAMES.pointsToWinInTiebreakerSet]: number;
  [FIELD_NAMES.goldenSetRule]: boolean;
  [FIELD_NAMES.goldenMatchRule]: boolean;
  [FIELD_NAMES.pointsToWinInGoldenSet]: number;
  [FIELD_NAMES.pointsToWinInGoldenMatchSet]: number;
  [FIELD_NAMES.matchOfficialsSettings]: Array<MatchOfficialSettings>;
}

const getNumberOfSets = (
  groupSettings: GroupSettings,
  sport?: keyof typeof Sports,
) => {
  if (typeof groupSettings?.numberOfSets === 'number') {
    return groupSettings?.numberOfSets;
  }

  // Indoor Volleyball - 5 sets; Beach & Snow - 3 sets
  return sport === Sports.VOLLEYBALL ? 5 : 3;
};

const getPointsToWinInRegularSet = (
  groupSettings: GroupSettings,
  sport?: keyof typeof Sports,
) => {
  if (typeof groupSettings?.pointsToWinInRegularSet === 'number') {
    return groupSettings?.pointsToWinInRegularSet;
  }

  // Indoor Volleyball regular set - 25; Beach & Snow - 21
  return sport === Sports.VOLLEYBALL ? 25 : 21;
};

export const getInitialValues = (
  groupSettings: GroupSettings,
  sport?: keyof typeof Sports,
): FormValues => {
  const matchOfficialsSettings = groupSettings?.matchOfficialsSettings?.map(
    (matchOfficialSettings) => ({
      id: matchOfficialSettings?.role?.id,
      roleId: matchOfficialSettings?.role?.id,
      groupId: matchOfficialSettings?.group?.id,
    }),
  );

  return {
    [FIELD_NAMES.numberOfSets]: getNumberOfSets(groupSettings, sport),
    [FIELD_NAMES.pointsToWinInRegularSet]: getPointsToWinInRegularSet(
      groupSettings,
      sport,
    ),
    [FIELD_NAMES.pointsToWinInTiebreakerSet]:
      groupSettings?.pointsToWinInTiebreakerSet || 15,
    [FIELD_NAMES.goldenSetRule]: groupSettings?.goldenSetRule || false,
    [FIELD_NAMES.goldenMatchRule]: groupSettings?.goldenMatchRule || false,
    [FIELD_NAMES.pointsToWinInGoldenSet]:
      groupSettings?.pointsToWinInGoldenSet || 15,
    [FIELD_NAMES.pointsToWinInGoldenMatchSet]:
      groupSettings?.pointsToWinInGoldenMatchSet || 15,
    [FIELD_NAMES.matchOfficialsSettings]: matchOfficialsSettings || [],
  };
};
