import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { PersonNameColumn, CustomTable } from '@core/components';
import { getGenders, formatNationalities } from '@core/helpers';
import { Column } from '@core/components/material-table';
import { getCompetitorTeamOfficialsRoles } from '@core/store/modules/tabs/competitor-profile/selectors';
import {
  CompetitorTeamOfficialList,
  CompetitorTeamOfficial,
  TeamOfficialRoles,
} from '@core/types';
import { State } from '@core/store';
import { getTitle, APP_TITLES } from '@core/helpers/app-sports/title-getters';

interface StateProps {
  teamOfficialRoles: TeamOfficialRoles;
}

interface OwnProps {
  officials: CompetitorTeamOfficialList;
  competitorId: number;
  competitionId: number;
  teamId: number;
}

type Props = OwnProps & StateProps;

const OfficialsTable = (props: Props) => {
  const { officials } = props;
  const { t } = useTranslation();

  const columns: Array<Column<CompetitorTeamOfficial>> = [
    {
      title: t('Name'),
      render: (rowData) => (
        <PersonNameColumn person={rowData.teamOfficial} withLogo />
      ),
    },
    {
      title: getTitle(APP_TITLES.POPULAR_NAME),
      render: (rowData) => rowData?.teamOfficial?.popularName,
    },
    {
      title: t('Gender'),
      render: (rowData) => getGenders(t, rowData?.teamOfficial?.gender),
    },
    {
      title: t('Nationality'),
      render: (rowData) =>
        formatNationalities(rowData?.teamOfficial?.nationalCitizenships || []),
    },
    {
      title: t('Role'),
      render: (rowData) => rowData?.teamOfficialRole?.shortName,
    },
  ];

  return (
    <CustomTable
      isForm
      noPaper
      columns={columns}
      titleRow={t('Team officials')}
      connectedTable
      data={officials || []}
      options={{
        showTitle: true,
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: t('No team officials. Please manage.'),
        },
      }}
    />
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  teamOfficialRoles: getCompetitorTeamOfficialsRoles(ownProps?.competitorId)(
    state,
  ),
});

export default connect(mapStateToProps, null)(OfficialsTable);
