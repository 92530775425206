import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { SportsList } from '@core/types';

import { SET_SPORTS } from './constants';

export interface DefaultState {
  allSports: SportsList;
}

export const defaultState: DefaultState = {
  allSports: null,
};

const reducer = handleActions<DefaultState, any>(
  {
    [SET_SPORTS]: (state, { payload }) => assoc('allSports', payload, state),
  },
  defaultState,
);

export default reducer;
