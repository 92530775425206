import { Filters, FilterParams, FiltersItem } from '@core/types';

const getFilterName = (filter: FiltersItem) => `filter[${filter.fieldName}][]`;

const formatFilterValues = (filter: FiltersItem) =>
  filter.filterValues
    .map((val) => `${filter.filteringOperation}:${val.value}`)
    .join(',');

export const formatFilterParams = (filterList: Filters): FilterParams => {
  const filters = Object.values(filterList);
  const filterParams = [] as FilterParams;

  filters.forEach((filter) => {
    const filterName = getFilterName(filter);

    if (filter.filteringOperation) {
      filterParams.push([filterName, formatFilterValues(filter)]);
    } else {
      filter.filterValues.forEach((val) =>
        filterParams.push([filterName, val.value]),
      );
    }
  });

  return filterParams;
};
