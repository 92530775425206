import { Settings } from '@mui/icons-material';
import { Tooltip, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  tooltipContainer: {
    position: 'relative',
  },
  tooltipText: {
    overflow: 'hidden',
    margin: 0,
    lineHeight: '19px',
    paddingLeft: '4px',
  },
  tooltipIcon: {
    float: 'left',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  rotate: {
    fontSize: 18,
    animationName: '$rotation',
    animationDuration: '3s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}));

interface OwnProps {
  children: any;
  display?: CSSStyleDeclaration['display'];
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

type Props = OwnProps;

const UnderConstructionTooltip = (props: Props) => {
  const classes = useStyles();
  const { children, placement = 'top' } = props;
  const { t } = useTranslation();

  return (
    <Grid style={{ display: props?.display ?? 'block' }}>
      <Tooltip
        placement={placement}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        title={
          <Grid className={classes.tooltipContainer}>
            <Grid className={classes.tooltipIcon}>
              <Settings className={classes.rotate} style={{ fontSize: 18 }} />
            </Grid>
            <Grid className={classes.tooltipText}>
              {t('Future Development')}
            </Grid>
          </Grid>
        }
        arrow
      >
        {children}
      </Tooltip>
    </Grid>
  );
};

export default UnderConstructionTooltip;
