import { handleActions } from 'redux-actions';

import { Filters } from '@core/types';

import actions from './actions';

type DefaultState = Filters;
const defaultState: DefaultState = {};

const reducer = handleActions<DefaultState>(
  {
    [actions.setFilters.toString()]: (_, { payload }) => payload,

    [actions.clearFilters.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
