import { createActions } from 'redux-actions';

export const NAMESPACE = 'clubsList';

export const actions = createActions(
  'CREATE_CLUB_REQUEST',
  'CREATE_CLUB_SUCCESS',
  'CREATE_CLUB_FAILURE',

  'PATCH_CLUB_REQUEST',
  'PATCH_CLUB_SUCCESS',
  'PATCH_CLUB_FAILURE',

  'GET_CLUB_REQUEST',
  'GET_CLUB_SUCCESS',
  'GET_CLUB_FAILURE',

  'GET_CLUB_LIST_REQUEST',
  'GET_CLUB_LIST_SUCCESS',
  'GET_CLUB_LIST_FAILURE',

  'SET_CLUB',
  'SET_CLUB_LIST',

  { prefix: NAMESPACE },
);
