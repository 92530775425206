import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  separation: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
  label: {
    marginBottom: theme.spacing(0.75),
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
  accordionSummary: {
    minHeight: 52,
    padding: theme.spacing(0.75, 2),
    '&.Mui-expanded': {
      minHeight: 52,
    },
  },
  accordionDetails: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
  },
}));
