import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';
import { Group } from '@core/types';

import { actions } from '.';

export type DefaultState = {
  [groupId: number]: Group;
};

export const defaultState: DefaultState = null;

type Payload = any;

function flattenGroups(state: DefaultState, payload: Array<Group>): any {
  return {
    ...state,
    ...convertArrayToObject(payload),
    ...convertArrayToObject(payload.flatMap((group) => group.childGroups)),
  };
}

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setGroups.toString()]: (
      state,
      { payload }: InferAction<typeof actions.setGroups>,
    ) => flattenGroups(state, payload),
    [actions.setGroup.toString()]: (
      state,
      { payload }: InferAction<typeof actions.setGroup>,
    ) => flattenGroups(state, [payload]),
  },
  defaultState,
);

export default reducer;
