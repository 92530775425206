import { combineReducers } from 'redux';

import { NAMESPACE as userRoleRequest } from '@core/store/modules/api/user-role-request';
import userRoleRequestReducer from '@core/store/modules/api/user-role-request/reducer';
import { NAMESPACE as competitors } from '@core/store/modules/api/competitors';
import competitorsReducer from '@core/store/modules/api/competitors/reducer';
import { NAMESPACE as disciplinaryInfringements } from '@core/store/modules/api/disciplinary-infringements';
import disciplinaryInfringementsReducer from '@core/store/modules/api/disciplinary-infringements/reducer';
import { NAMESPACE as disciplinarySanctions } from '@core/store/modules/api/disciplinary-sanctions';
import disciplinarySanctionsReducer from '@core/store/modules/api/disciplinary-sanctions/reducer';
import { NAMESPACE as groups } from '@core/store/modules/api/groups';
import groupsReducer from '@core/store/modules/api/groups/reducer';
import { NAMESPACE as matches } from '@core/store/modules/api/match';
import matchReducer from '@core/store/modules/api/match/reducer';
import { NAMESPACE as competitorPlayers } from '@core/store/modules/api/competitor-players';
import competitorPlayersReducer from '@core/store/modules/api/competitor-players/reducer';
import { NAMESPACE as competitorTeamOfficials } from '@core/store/modules/api/competitor-team-officials';
import competitorTeamOfficialsReducer from '@core/store/modules/api/competitor-team-officials/reducer';
import { NAMESPACE as matchSets } from '@core/store/modules/api/match-set';
import matchSetsReducer from '@core/store/modules/api/match-set/reducer';
import appointmentReducer from '@core/store/modules/api/appointment/reducer';
import { NAMESPACE as appointments } from '@core/store/modules/api/appointment';
import fixturesReducer from '@core/store/modules/api/fixtures/reducer';
import { NAMESPACE as fixtures } from '@core/store/modules/api/fixtures';

import { NAMESPACE as city } from './city';
import cityReducer from './city/reducer';
import { NAMESPACE as countries } from './countries';
import countriesReducer from './countries/reducer';

import { NAMESPACE as sports } from './sports';
import sportsReducer from './sports/reducer';
import { NAMESPACE as teams } from './team';
import teamsReducer from './team/reducer';
import { NAMESPACE as userRole } from './user-role';
import userRoleReducer from './user-role/reducer';

export const staticApiReducers = {
  [appointments]: appointmentReducer,
  [city]: cityReducer,
  [competitorPlayers]: competitorPlayersReducer,
  [competitors]: competitorsReducer,
  [competitorTeamOfficials]: competitorTeamOfficialsReducer,
  [countries]: countriesReducer,
  [disciplinaryInfringements]: disciplinaryInfringementsReducer,
  [disciplinarySanctions]: disciplinarySanctionsReducer,
  [fixtures]: fixturesReducer,
  [groups]: groupsReducer,
  [matches]: matchReducer,
  [matchSets]: matchSetsReducer,
  [sports]: sportsReducer,
  [teams]: teamsReducer,
  [userRole]: userRoleReducer,
  [userRoleRequest]: userRoleRequestReducer,
};

// TODO: JB: not used
export default combineReducers(staticApiReducers);
