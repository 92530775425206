import React from 'react';
import { Box } from '@mui/material';
interface OwnProps {
  children: any;
}

type Props = OwnProps;

const PageFormActionBlock = (props: Props) => (
  <Box mt={3} mb={3}>
    {props.children}
  </Box>
);

export default PageFormActionBlock;
