import React from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextField } from '@core/components';

import useStyles from './styles';
import { FIELD_NAMES } from '../constants';

const CompetitionTitleBlock = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} justifyContent="flex-end">
        <Box mb={3}>
          <Typography variant="caption" color="textSecondary">
            {`* ${t('Indicates a required field')}`}
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Titles')}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <TextField
                name={FIELD_NAMES.title}
                data-qa={FIELD_NAMES.title}
                required
                label={t('Title')}
                withTooltipMargin={false}
                tooltipText={t(
                  'The Competition Title in the local language. This title will be displayed across the application and reports',
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <TextField
                name={FIELD_NAMES.internationalTitle}
                data-qa={FIELD_NAMES.internationalTitle}
                label={t('International title')}
                withTooltipMargin={false}
                tooltipText={t(
                  'The international version of Competition Title (in Latin characters)',
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <TextField
                name={FIELD_NAMES.abbreviation}
                data-qa={FIELD_NAMES.abbreviation}
                label={t('Abbreviation')}
                withTooltipMargin={false}
                tooltipText={t('The short version of the Competition title')}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Box mb={2} mt={1} flex={1}>
          <Divider orientation="horizontal" />
        </Box>
      </Grid>
    </>
  );
};

export default CompetitionTitleBlock;
