import { createAction } from 'redux-actions';
import { NAMESPACE } from './constants';
import type { Paginated } from '@core/types';

type QueryParams = {
  page?: number;
  query?: string;
};

const setSeasons = createAction<Paginated<number>>(`${NAMESPACE}/SET_SEASONS`);
const getSeasons = createAction<QueryParams | void>(`${NAMESPACE}/GET_SEASONS`);
const getSeasonsRequest = createAction<void>(
  `${NAMESPACE}/GET_SEASONS_REQUEST`,
);
const getSeasonsSuccess = createAction<void>(
  `${NAMESPACE}/GET_SEASONS_SUCCESS`,
);
const getSeasonsFailure = createAction<void>(
  `${NAMESPACE}/GET_SEASONS_FAILURE`,
);
const searchSeasonsByQuery = createAction<QueryParams>(
  `${NAMESPACE}/SEARCH_SEASONS_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setSeasons,
  getSeasons,
  getSeasonsRequest,
  getSeasonsSuccess,
  getSeasonsFailure,
  searchSeasonsByQuery,
  resetState,
};
