import { createAction } from 'redux-actions';
import { prop, pick } from 'ramda';

import {
  CompetitionOrganisersList,
  CompetitionManagersList,
  CompetitionResponse,
} from '@core/types';

import { NAMESPACE } from './constants';

interface SetCompetitionOrganisers {
  competitionId: number;
  payload: CompetitionOrganisersList;
}

interface SetCompetitionManagers {
  competitionId: number;
  payload: CompetitionManagersList;
}

interface SetCompetition {
  competitionId: number;
  payload: CompetitionResponse;
}

interface GetCompetitionOrganisers {
  competitionId: number;
  query?: string;
}

interface GetCompetitionManagers {
  competitionId: number;
  query?: string;
}

const getCompetitionOrganisers = createAction<GetCompetitionOrganisers>(
  `${NAMESPACE}/GET_COMPETITION_ORGANISERS`,
);
const getCompetitionOrganisersRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_ORGANISERS_REQUEST`,
);
const getCompetitionOrganisersSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_ORGANISERS_SUCCESS`,
);
const getCompetitionOrganisersFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_ORGANISERS_FAILURE`,
);
const setCompetitionOrganisers = createAction(
  `${NAMESPACE}/SET_COMPETITION_ORGANISERS`,
  (data: SetCompetitionOrganisers): CompetitionOrganisersList =>
    prop('payload', data),
  (data: SetCompetitionOrganisers): { competitionId: number } =>
    pick(['competitionId'], data),
);

const getCompetitionManagers = createAction<GetCompetitionManagers>(
  `${NAMESPACE}/GET_COMPETITION_MANAGERS`,
);
const getCompetitionManagersRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_MANAGERS_REQUEST`,
);
const getCompetitionManagersSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_MANAGERS_SUCCESS`,
);
const getCompetitionManagersFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_MANAGERS_FAILURE`,
);
const setCompetitionManagers = createAction(
  `${NAMESPACE}/SET_COMPETITION_MANAGERS`,
  (data: SetCompetitionManagers): CompetitionManagersList =>
    prop('payload', data),
  (data: SetCompetitionManagers): { competitionId: number } =>
    pick(['competitionId'], data),
);

const getCompetition = createAction<number>(`${NAMESPACE}/GET_COMPETITION`);
const getCompetitionRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_REQUEST`,
);
const getCompetitionSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_SUCCESS`,
);
const getCompetitionFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_FAILURE`,
);
const setCompetition = createAction(
  `${NAMESPACE}/SET_COMPETITION`,
  (data: SetCompetition): CompetitionResponse => prop('payload', data),
  (data: SetCompetition): { competitionId: number } =>
    pick(['competitionId'], data),
);

const updateCompetition = createAction<any>(`${NAMESPACE}/UPDATE_COMPETITION`);
const updateCompetitionRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_REQUEST`,
);
const updateCompetitionSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_SUCCESS`,
);
const updateCompetitionFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_FAILURE`,
);

const getCompetitionContacts = createAction<any>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS`,
);
const getCompetitionContactsRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_REQUEST`,
);
const getCompetitionContactsSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_SUCCESS`,
);
const getCompetitionContactsFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_FAILURE`,
);

const createCompetitionContact = createAction<any>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT`,
);
const createCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_REQUEST`,
);
const createCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_SUCCESS`,
);
const createCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_FAILURE`,
);

const updateCompetitionContact = createAction<any>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT`,
);
const updateCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_REQUEST`,
);
const updateCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_SUCCESS`,
);
const updateCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_FAILURE`,
);

const deleteCompetitionContact = createAction<any>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT`,
);
const deleteCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_REQUEST`,
);
const deleteCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_SUCCESS`,
);
const deleteCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_FAILURE`,
);

type SetCompetitionContacts = {
  payload: any;
  competitionId: number;
};

const setCompetitionContacts = createAction(
  `${NAMESPACE}/SET_COMPETITION_CONTACTS`,
  (data: SetCompetitionContacts): any => prop('payload', data),
  (data: SetCompetitionContacts): { competitionId: number } =>
    pick(['competitionId'], data),
);
const resetCompetitionContactsState = createAction<{ competitionId: number }>(
  `${NAMESPACE}/RESET_COMPETITION_CONTACTS_STATE`,
);

export default {
  getCompetitionOrganisers,
  getCompetitionOrganisersRequest,
  getCompetitionOrganisersSuccess,
  getCompetitionOrganisersFailure,
  setCompetitionOrganisers,
  getCompetitionManagers,
  getCompetitionManagersRequest,
  getCompetitionManagersSuccess,
  getCompetitionManagersFailure,
  setCompetitionManagers,
  getCompetition,
  getCompetitionRequest,
  getCompetitionSuccess,
  getCompetitionFailure,
  setCompetition,
  updateCompetition,
  updateCompetitionRequest,
  updateCompetitionSuccess,
  updateCompetitionFailure,

  getCompetitionContacts,
  getCompetitionContactsRequest,
  getCompetitionContactsSuccess,
  getCompetitionContactsFailure,

  createCompetitionContact,
  createCompetitionContactRequest,
  createCompetitionContactSuccess,
  createCompetitionContactFailure,

  updateCompetitionContact,
  updateCompetitionContactRequest,
  updateCompetitionContactSuccess,
  updateCompetitionContactFailure,

  deleteCompetitionContact,
  deleteCompetitionContactRequest,
  deleteCompetitionContactSuccess,
  deleteCompetitionContactFailure,

  setCompetitionContacts,
  resetCompetitionContactsState,
};
