import dayjs from 'dayjs';

import { DATE_FORMAT } from '@core/constants/common';
import { Genders, ClubTypes, GeographyTypes } from '@core/types';

import { FIELD_NAMES } from '../constants';

export interface FormValues {
  [FIELD_NAMES.title]: string;
  [FIELD_NAMES.internationalTitle]: string;
  [FIELD_NAMES.abbreviation]: string;
  [FIELD_NAMES.startDate]: string;
  [FIELD_NAMES.endDate]: string;
  [FIELD_NAMES.competitionSeasonId]: number | null;
  [FIELD_NAMES.sportId]: number | null;
  [FIELD_NAMES.gender]: keyof typeof Genders;
  [FIELD_NAMES.ageGroupId]: number | null;
  [FIELD_NAMES.competitionLevelId]: number | null;
  [FIELD_NAMES.participatingTeams]: number | null;
  [FIELD_NAMES.clubType]: keyof typeof ClubTypes;
  [FIELD_NAMES.geography]: keyof typeof GeographyTypes;
}

const getDefaultValues = (): FormValues => ({
  [FIELD_NAMES.title]: '',
  [FIELD_NAMES.internationalTitle]: '',
  [FIELD_NAMES.abbreviation]: '',
  [FIELD_NAMES.startDate]: dayjs().format(DATE_FORMAT),
  [FIELD_NAMES.endDate]: null,
  [FIELD_NAMES.competitionSeasonId]: null,
  [FIELD_NAMES.sportId]: null,
  [FIELD_NAMES.gender]: null,
  [FIELD_NAMES.ageGroupId]: null,
  [FIELD_NAMES.competitionLevelId]: null,
  [FIELD_NAMES.participatingTeams]: 2,
  [FIELD_NAMES.clubType]: null,
  [FIELD_NAMES.geography]: null,
});

export default getDefaultValues;
