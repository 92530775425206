import { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import userActions from '@core/pages/user-profile/store/actions';
import { MainLayout as Placeholder } from '@core/components/layout/main-layout';
import { useCurrentUser } from '@core/hooks';
import { createURL, getAccessToken } from '@core/helpers';
import { useIsLoggedIn } from '@core/helpers/auth';
import { TermsAndConditionsModalProps } from '@core/components';
import { MenuList, NavigationsTabsMap, UserRoleType } from '@core/types';
import { shouldAllowAccess, getRedirectPath } from '@core/routes';
import * as authActions from '@core/pages/login/store/actions';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';
import { ModalTypes } from '@volleyball/types';
import paths from '@volleyball/routes/paths';
import { getTabMapping } from '@volleyball/constants/pages-map';
import { getMainMenuMapping } from '@volleyball/constants/main-menu-map';

interface OwnProps {
  roleAccessArray?: Array<UserRoleType>;
  requiredAccess?: string | Array<string>;
  customRedirectPath?: string;
}

interface DispatchProps {
  actions?: {
    user: typeof userActions;
    globalModal: typeof globalModalActions;
    auth: typeof authActions;
  };
}

type Props = OwnProps & RouteProps & DispatchProps;

export const ProtectedRoute = ({
  actions,
  roleAccessArray,
  requiredAccess,
  customRedirectPath,
  children,
}: Props) => {
  const { user: currentUser, hasUser, accessLinks } = useCurrentUser();
  const childComponentProps = {
    tabMapping: getTabMapping(),
    mainMenuMapping: getMainMenuMapping(),
  };

  useEffect(() => {
    if (!hasUser && getAccessToken()) {
      actions.user.getCurrentUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.mustAcceptTermsAndConditions) {
      actions.globalModal.openModal<TermsAndConditionsModalProps>({
        type: ModalTypes.TERMS_AND_CONDITIONS_MODAL,
        params: {
          isAuthenticated: true,
          onTermsAccepted: () => {
            actions.user.setTermsAccepted();
          },
          onTermsDeclined: () => {
            actions.auth.logout();
          },
        },
      });
    }
  }, [currentUser]);

  const { isAuthenticated } = useIsLoggedIn();

  if (!isAuthenticated) {
    // First check - access token presence. No token - redirect to login and done.
    return <Navigate to={createURL(paths.login)} />;
  }

  if (requiredAccess && (isNil(accessLinks) || isEmpty(accessLinks))) {
    // If this protected route requires HAL-based access, but no links have been fetched yet, show a placeholder.
    return <Placeholder {...childComponentProps} body={null} />;
  }

  if (
    !shouldAllowAccess({
      accessLinks,
      currentUser,
      requiredAccess,
      roleAccessArray,
    })
  ) {
    // Finally, check for access. If no access granted, calculate redirect path and use it.
    return (
      <Navigate
        to={createURL(
          getRedirectPath({ currentUser, customRedirectPath, accessLinks }),
        )}
      />
    );
  }

  return children;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    user: bindActionCreators(userActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
    auth: bindActionCreators(authActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ProtectedRoute);
