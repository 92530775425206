import {
  StageType,
  MatchOfficialGroup,
  MatchOfficialRoles,
  MatchOfficialRole,
  GROUP_STATUSES,
  KNOCKOUT_ROUNDS,
} from '@core/types';
import { MatchOfficialGroupList } from './officials';

export interface MatchOfficialSettings {
  id: number;
  setting?: {
    matchOfficialRoles: MatchOfficialRoles;
  };
  role: MatchOfficialRole;
  group: MatchOfficialGroup;
}

export interface GroupSettings {
  numberOfSets?: number;
  pointsToWinInRegularSet?: number;
  pointsToWinInTiebreakerSet: number;
  matchOfficialRoles: MatchOfficialGroupList;
  goldenSetRule: boolean;
  goldenMatchRule: boolean;
  pointsToWinInGoldenSet: number;
  pointsToWinInGoldenMatchSet?: number;
  matchOfficialsSettings: Array<MatchOfficialSettings>;
}

export interface GroupLinks {
  competition: { href: string };
  delete?: { href: string };
  /** edit both standings and standing settings */
  groupStandingEdit?: { href: string };
  /** view both standings and standing settings */
  groupStandingSettings?: { href: string };
  'matchAllocations.list'?: { href: string };
  'matches.create'?: { href: string };
  'matches.export'?: { href: string };
  'matches.import'?: { href: string };
  patch?: { href: string };
  self: { href: string };
  updateSettings?: { href: string };
  brackets?: { href: string };
}

export enum EliminationType {
  DOUBLE = 'DOUBLE',
  SINGLE = 'SINGLE',
}

export enum DoubleEliminationType {
  CLASSIC = 'CLASSIC',
  CROSSOVER = 'CROSSOVER',
}

export enum BracketsType {
  CROSSOVER = 'CROSSOVER',
  LOSERS = 'LOSERS',
  WINNERS = 'WINNERS',
}

export interface Group {
  childGroups: Array<Group>;
  description: string;
  endDate: string;
  id: number;
  internationalName?: string;
  numberOfRounds: number;
  numberOfTeams: number;
  numberOfPools?: number;
  stageType: StageType;
  startDate: string;
  status: GROUP_STATUSES;
  title: string;
  bestOf: number;
  knockoutRound: KNOCKOUT_ROUNDS;
  extraRound: boolean;
  settings?: GroupSettings;
  bracketsType: BracketsType;
  eliminationType: EliminationType;
  orderNumber?: number;
  _links: GroupLinks;
}

export type GroupResponse = Group;

export type Groups = Array<Group>;

export type CreateGroupResponse = Group;

export type NewGroupData = Partial<
  Omit<
    Group,
    'id' | 'competitionId' | 'groupFixtureSettingsId' | 'childGroups' | '_links'
  >
> &
  Pick<Group, 'title' | 'stageType' | 'numberOfTeams'>;

export interface GroupsResponse {
  header: Array<any>;
  _embedded: {
    groups: Array<Group>;
  };
  _links: {
    self: { href: string };
  };
}

export interface GroupSettingsUpdate {
  numberOfSets?: number;
  pointsToWinInRegularSet?: number;
  pointsToWinInTiebreakerSet?: number;
  goldenSetRule?: boolean;
  goldenMatchRule?: boolean;
  pointsToWinInGoldenSet?: number;
  pointsToWinInGoldenMatchSet?: number;
  matchOfficialsSettings: Array<{
    roleId: number;
    groupId: number;
  }>;
}

export type GroupCompetitorPayload = {
  competitorId: number;
  slotNumber: number;
};

export type GroupCompetitorsPayload =
  | {
      groupCompetitors: Array<GroupCompetitorPayload>;
      childGroups?: undefined;
    }
  | {
      groupCompetitors?: undefined;
      childGroups: {
        [childGroupId in number | string]: Array<GroupCompetitorPayload>;
      };
    };
