import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Grow,
  Box,
  Paper,
  Popper,
  Tooltip,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Button,
  Grid,
  CircularProgress,
  ButtonGroup,
  PopperProps,
  Theme,
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { usePopperToggle } from '@core/hooks';
import { ConditionalWrapper } from '@ui-components';

interface OptionProps<T> {
  text: string;
  disabled?: boolean;
  tooltipTextDisabled?: string;
  value: T;
  testId?: string;
  icon?: JSX.Element;
}

export interface OwnProps<T> {
  children: string;
  options: Array<OptionProps<T>>;
  selected?: T;
  isLoading?: boolean;
  maxWidthMobile?: boolean;
  placement?: PopperProps['placement'];
  minPopperWidth?: string;
  disabled?: boolean;
  onSelect: (value: T) => void;
  align?: React.CSSProperties['alignItems'];
}

type Button = Omit<ButtonProps, 'onSelect' | 'children'>;
type Props<T> = Button & OwnProps<T>;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    zIndex: theme.zIndex.modal,
  },
  wrapper: {
    position: 'relative',
  },
  maxWidthMobile: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  button: {
    whiteSpace: 'nowrap',
  },
}));

type Value = string | number | any;

export default function ButtonWithDropdown<T extends Value>({
  children,
  options,
  onSelect,
  selected,
  color = 'primary',
  variant = 'contained',
  isLoading,
  disabled,
  maxWidthMobile,
  placement = 'bottom-end',
  minPopperWidth,
  align = 'center',
  ...buttonProps
}: Props<T>) {
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();
  const classes = useStyles();

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    val: T,
  ) => {
    handleClose(event);
    onSelect?.(val);
  };

  return (
    <Grid container direction="column" alignItems={align}>
      <Grid
        item
        xs={12}
        className={maxWidthMobile ? classes.maxWidthMobile : ''}
        style={{ width: minPopperWidth }}
      >
        <Grid className={classes.wrapper}>
          <ButtonGroup
            className={maxWidthMobile ? classes.maxWidthMobile : ''}
            variant={variant}
            color={color}
            ref={anchorRef}
            sx={{ float: 'right' }}
          >
            <Button
              variant={variant}
              className={clsx(classes.button, {
                [classes.maxWidthMobile]: maxWidthMobile,
              })}
              {...buttonProps}
              disabled={!options || isLoading || disabled}
              color={color}
              onClick={handleToggle}
              endIcon={<ArrowDropDown />}
            >
              {children}
              {isLoading && (
                <Box ml={2} mb={-1}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Button>
          </ButtonGroup>
          <Popper
            className={classes.paper}
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={placement}
          >
            {({ TransitionProps }) => {
              return (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(event: any) => handleClose(event)}
                    >
                      <MenuList id="split-button-menu">
                        {options?.map((option) => (
                          <ConditionalWrapper
                            key={`${option.text}${
                              option.value?.toString() ?? ''
                            }`}
                            condition={
                              option.disabled && !!option.tooltipTextDisabled
                            }
                            wrapper={(wrapperChildren) => (
                              <Tooltip
                                arrow
                                title={option.tooltipTextDisabled}
                                placement="top"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -20],
                                      },
                                    },
                                  ],
                                }}
                              >
                                <Box display="flex">{wrapperChildren}</Box>
                              </Tooltip>
                            )}
                          >
                            <MenuItem
                              disabled={option.disabled}
                              data-qa={option.testId}
                              selected={option.value === selected}
                              onClick={(event) =>
                                option.value !== selected
                                  ? handleMenuItemClick(event, option.value)
                                  : null
                              }
                            >
                              {!!option.icon && (
                                <Box display="flex" mr={1}>
                                  {option.icon}
                                </Box>
                              )}
                              {option.text}
                            </MenuItem>
                          </ConditionalWrapper>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              );
            }}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
}
