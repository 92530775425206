import restService from '@core/api/rest-service';
import {
  DashboardMatchesListResponse,
  MatchesBulkActions,
  MatchesListResponse,
  MatchModel,
  MatchStatus,
  MatchStatusUpdateInBulkResponse,
  PatchMatchPayload,
} from '@core/types';

export const getMatches = (
  queryParams: URLSearchParams | Record<string, any> = { page: 1 },
) =>
  restService.get<MatchesListResponse>('/v1/matches', { params: queryParams });

export const getDashboardMatches = (queryParams: URLSearchParams) =>
  restService.get<DashboardMatchesListResponse>('/v1/matches/dashboard', {
    params: queryParams,
  });

export const getMatch = (matchId: number) =>
  restService.get<MatchModel>(`/v1/matches/${matchId}`);

export const patchMatch = (matchId: number, data: PatchMatchPayload) =>
  restService.patch<void>(`/v1/matches/${matchId}`, data);

export const deleteMatch = (matchId: number) =>
  restService.delete<void>(`/v1/matches/${matchId}`);

export const createMatch = (groupId: number, data: unknown) =>
  restService.post<unknown>(`/v1/groups/${groupId}/matches`, data);

export const changeMatchStatus = (
  matchId: number,
  status: MatchStatus,
  reasonDescription: string,
) =>
  restService.post<void>(`/v1/matches/${matchId}/changeStatus`, {
    status,
    reasonDescription,
  });

export const changeMatchStatusInBulk = (payload: {
  matchIds: Array<number>;
  status: MatchesBulkActions;
}) =>
  restService.post<MatchStatusUpdateInBulkResponse>(
    `/v1/matches/changeStatus`,
    payload,
  );

export const bulkDelete = (payload: { matchIds: Array<number> }) =>
  restService.post<MatchStatusUpdateInBulkResponse>(
    `/v1/matches/bulkDelete`,
    payload,
  );

export const uploadGroupMatches = ({
  groupId,
  file,
  importByTeamNames,
}: any) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('importByTeamNames', importByTeamNames);

  return restService.post<unknown>(
    `/v1/groups/${groupId}/matches/import`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const exportGroupMatches = (groupId: number) =>
  restService.get<unknown>(`/v1/groups/${groupId}/matches/export`, {
    responseType: 'blob',
  });

export const exportGroupMatchesTemplate = (groupId: number) =>
  restService.get<unknown>(`/v1/groups/${groupId}/matches/export/template`, {
    responseType: 'blob',
  });
