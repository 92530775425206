import { createActions } from 'redux-actions';

import { Paginated, Status, User, UserRoleRequestStatus } from '@core/types';

import { NAMESPACE } from './constants';

const actions = createActions(
  'GET_USERS',
  'GET_USERS_REQUEST',
  'GET_USERS_SUCCESS',
  'GET_USERS_FAILURE',
  'SET_USERS',
  'RESET_USERS',
  'SET_USERS_QUERY',
  'SEARCH_USERS_BY_QUERY',
  'CANCEL_GET_USERS',
  'CANCEL_SEARCH_USERS_BY_QUERY',

  'UPDATE_USERS',
  'UPDATE_USERS_REQUEST',
  'UPDATE_USERS_SUCCESS',
  'UPDATE_USERS_FAILURE',

  'REMOVE_USER_ROLE',
  'REMOVE_USER_ROLE_REQUEST',
  'REMOVE_USER_ROLE_SUCCESS',
  'REMOVE_USER_ROLE_FAILURE',

  'GET_USER_ROLE_REQUESTS',
  'GET_USER_ROLE_REQUESTS_REQUEST',
  'GET_USER_ROLE_REQUESTS_SUCCESS',
  'GET_USER_ROLE_REQUESTS_FAILURE',
  'SET_USER_ROLE_REQUESTS',

  'GET_USER_ROLE_REQUESTS_BY_STATUS',
  'SET_USER_ROLE_REQUESTS_STATUS_COUNT',
  'DECREMENT_USER_ROLE_REQUEST_COUNT',

  'LINK_WITH_PERSON',
  'LINK_WITH_PERSON_REQUEST',
  'LINK_WITH_PERSON_SUCCESS',
  'LINK_WITH_PERSON_FAILURE',

  'UNLINK_PERSON',
  'UNLINK_PERSON_REQUEST',
  'UNLINK_PERSON_SUCCESS',
  'UNLINK_PERSON_FAILURE',

  'APPROVE_USER_ROLE',
  'REJECT_USER_ROLE',
  { prefix: NAMESPACE },
) as unknown as {
  getUsers: IdentityActionFunction<{ page?: number; query?: string }>;
  getUsersRequest: RequestLifecycleAction;
  getUsersSuccess: RequestLifecycleAction;
  getUsersFailure: RequestLifecycleAction;
  setUsers: IdentityActionFunction<Paginated<User>>;
  setUsersQuery: IdentityActionFunction<string>;
  cancelGetUsers: NoopAction;
  searchUsersByQuery: IdentityActionFunction<{ page?: number; query?: string }>;
  cancelSearchUsersByQuery: NoopAction;

  removeUserRole: IdentityActionFunction<{ roleId: number; userId: number }>;
  removeUserRoleRequest: RequestLifecycleAction<{ userId: number }>;
  removeUserRoleSuccess: RequestLifecycleAction<{ userId: number }>;
  removeUserRoleFailure: RequestLifecycleAction<{ userId: number }>;

  updateUsers: IdentityActionFunction<{ id: number; data: { status: Status } }>;
  updateUsersRequest: RequestLifecycleAction;
  updateUsersSuccess: RequestLifecycleAction;
  updateUsersFailure: RequestLifecycleAction;

  getUserRoleRequests: IdentityActionFunction<{
    queryParams: { page?: number; query: string /* [key: string]: string */ };
  }>;
  getUserRoleRequestsRequest: RequestLifecycleAction;
  getUserRoleRequestsSuccess: RequestLifecycleAction;
  getUserRoleRequestsFailure: RequestLifecycleAction;
  setUserRoleRequests: IdentityActionFunction<Paginated<number>>;

  getUserRoleRequestsByStatus: IdentityActionFunction<{
    queryParams: { query: UserRoleRequestStatus.PENDING };
  }>;
  setUserRoleRequestsStatusCount: IdentityActionFunction<{
    total: number;
    status: UserRoleRequestStatus.PENDING;
  }>;
  decrementUserRoleRequestCount: NoopAction;

  linkWithPerson: IdentityActionFunction<{
    personId: number;
    userId: number;
    onSuccess: () => void;
  }>;
  linkWithPersonRequest: RequestLifecycleAction<{ userId: number }>;
  linkWithPersonSuccess: RequestLifecycleAction<{ userId: number }>;
  linkWithPersonFailure: RequestLifecycleAction<{ userId: number }>;

  unlinkPerson: IdentityActionFunction<{
    userId: number;
    onSuccess: () => void;
  }>;
  unlinkPersonRequest: RequestLifecycleAction<{ userId: number }>;
  unlinkPersonSuccess: RequestLifecycleAction<{ userId: number }>;
  unlinkPersonFailure: RequestLifecycleAction<{ userId: number }>;

  approveUserRole: IdentityActionFunction<{ userRoleRequestId: number }>;
  rejectUserRole: IdentityActionFunction<{
    userRoleRequestId: number;
    statusChangeNotes: string;
  }>;
};

export default actions;
