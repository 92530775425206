import { groupBy, forEachObjIndexed } from 'ramda';

import { MenuItemProps } from '@core/types';

import { RenderFunction } from '../types';

const groupMenuItems = groupBy<MenuItemProps>(
  (menuItem: MenuItemProps) => menuItem.group || '',
);

export const getMenu = (
  menuTree: Array<MenuItemProps>,
  renderFunction: RenderFunction,
) => {
  const menu: Array<JSX.Element> = [];

  const renderGroups = (
    menuItem: Array<MenuItemProps>,
    groupTitle: string | number,
  ) => {
    menu.push(renderFunction(groupTitle, menuItem));
  };

  forEachObjIndexed(renderGroups, groupMenuItems(menuTree));

  return menu;
};
