import { createActions } from 'redux-actions';
import {
  CurrentUserResponse,
  CustomerResponse,
  Paginated,
  UserRoleRequest,
  UserRoleType,
} from '../../../types';
import { NAMESPACE } from './constants';

export default createActions(
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_REQUEST',
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_SUCCESS',
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_FAILURE',
  'GET_USER_ROLE_REQUESTS_BY_USER_ID',
  'SET_USER_ROLE_REQUESTS',

  'GET_CURRENT_USER_REQUEST',
  'GET_CURRENT_USER_SUCCESS',
  'GET_CURRENT_USER_FAILURE',
  'GET_CURRENT_USER',
  'SET_CURRENT_USER',

  'REQUEST_NEW_USER_ROLE_REQUEST',
  'REQUEST_NEW_USER_ROLE_SUCCESS',
  'REQUEST_NEW_USER_ROLE_FAILURE',
  'REQUEST_NEW_USER_ROLE',

  'GET_CURRENT_CUSTOMER_REQUEST',
  'GET_CURRENT_CUSTOMER_SUCCESS',
  'GET_CURRENT_CUSTOMER_FAILURE',
  'GET_CURRENT_CUSTOMER',
  'SET_CURRENT_CUSTOMER',

  'GET_CUSTOMER_LOGO_REQUEST',
  'GET_CUSTOMER_LOGO_SUCCESS',
  'GET_CUSTOMER_LOGO_FAILURE',
  'GET_CUSTOMER_LOGO',
  'SET_CUSTOMER_LOGO',

  'SET_TERMS_ACCEPTED',
  { prefix: NAMESPACE },
) as unknown as {
  getUserRoleRequestsByUserIdRequest: RequestLifecycleAction;
  getUserRoleRequestsByUserIdSuccess: RequestLifecycleAction;
  getUserRoleRequestsByUserIdFailure: RequestLifecycleAction;
  getUserRoleRequestsByUserId: IdentityActionFunction<{ userId: number }>;
  setUserRoleRequests: IdentityActionFunction<
    Omit<Paginated<UserRoleRequest['id']>, 'limit' | 'page' | 'pages' | 'total'>
  >;

  getCurrentUserRequest: RequestLifecycleAction;
  getCurrentUserSuccess: RequestLifecycleAction;
  getCurrentUserFailure: RequestLifecycleAction;
  getCurrentUser: NoopAction;
  setCurrentUser: IdentityActionFunction<CurrentUserResponse>;

  requestNewUserRoleRequest: RequestLifecycleAction;
  requestNewUserRoleSuccess: RequestLifecycleAction;
  requestNewUserRoleFailure: RequestLifecycleAction;
  requestNewUserRole: IdentityActionFunction<{
    role: UserRoleType;
    userId: number;
    clubId: number;
    setSuccessScreen: (value: boolean) => void;
  }>;

  getCurrentCustomerRequest: RequestLifecycleAction;
  getCurrentCustomerSuccess: RequestLifecycleAction;
  getCurrentCustomerFailure: RequestLifecycleAction;
  getCurrentCustomer: NoopAction;
  setCurrentCustomer: IdentityActionFunction<CustomerResponse>;

  getCustomerLogoRequest: RequestLifecycleAction;
  getCustomerLogoSuccess: RequestLifecycleAction;
  getCustomerLogoFailure: RequestLifecycleAction;
  getCustomerLogo: UnknownAction;
  setCustomerLogo: UnknownAction;

  setTermsAccepted: NoopAction;
};
