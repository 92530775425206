import { useTranslation } from 'react-i18next';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DatePickerField from './components/date-picker-field';
import FilterTextField from './components/filter-text-field';
import StatusFilter from './components/status-filter';
import {
  MATCH_DATE_FROM_FILTER,
  MATCH_DATE_TO_FILTER,
  MATCH_DAY_FROM_FILTER,
  MATCH_DAY_TO_FILTER,
} from './constants';
import { StageType, type FilterTabProps } from '@core/types';

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type Props = FilterTabProps<{
  stageType: StageType;
}>;

const MatchFilterTab = ({ stageType, ...rest }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.label}
        >
          {t('Date')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerField
          label={t('From')}
          filterProps={MATCH_DATE_FROM_FILTER}
          {...rest}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerField
          label={t('To')}
          filterProps={MATCH_DATE_TO_FILTER}
          {...rest}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.label}
            >
              {t(stageType === StageType.KNOCK_OUT ? 'Leg' : 'Match day')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FilterTextField
              label={t('From')}
              filterProps={MATCH_DAY_FROM_FILTER}
              filterLabel={t('MD from')}
              {...rest}
            />
          </Grid>
          <Grid item xs={6}>
            <FilterTextField
              label={t('To')}
              filterProps={MATCH_DAY_TO_FILTER}
              filterLabel={t('MD to')}
              {...rest}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <StatusFilter {...rest} />
      </Grid>
    </Grid>
  );
};

export default MatchFilterTab;
