import React from 'react';
import {
  ButtonBase,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { usePopperToggle } from '@core/hooks';

import UserProfile from './user-profile';
import { UserMenuListContainer as UserMenuList } from './user-menu-list';
import * as testIds from '../tests/test-ids';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
  },
  menuList: {
    zIndex: theme.zIndex.tooltip,
  },
}));

export const UserMenu = () => {
  const classes = useStyles();
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();

  return (
    <React.Fragment>
      <ButtonBase
        aria-controls="user-menu"
        ref={anchorRef}
        className={classes.root}
        onClick={handleToggle}
        data-qa={testIds.USER_MENU}
      >
        <UserProfile isOpen={isOpen} />
      </ButtonBase>
      <Popper
        id="user-menu"
        className={classes.menuList}
        placement="bottom-end"
        open={isOpen}
        anchorEl={anchorRef.current}
        disablePortal
        transition
      >
        {({ TransitionProps }: any) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event as any)}
              >
                <UserMenuList handleMenuToggle={handleToggle} />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
