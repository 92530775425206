export const NAMESPACE = 'tabs';

export const OPEN_TAB = `${NAMESPACE}/OPEN_TAB`;
export const OPEN_ROOT_TAB = `${NAMESPACE}/OPEN_ROOT_TAB`;
export const REMOVE_TAB = `${NAMESPACE}/REMOVE_TAB`;
export const UPDATE_ACTIVE_TAB_INDEX = `${NAMESPACE}/UPDATE_ACTIVE_TAB_INDEX`;
export const CLEAR_ALL_TABS = `${NAMESPACE}/CLEAR_ALL_TABS`;
export const SET_TOUCHED = `${NAMESPACE}/SET_TOUCHED`;
export const SET_TABS = `${NAMESPACE}/SET_TABS`;
export const SET_ALL_TABS = `${NAMESPACE}/SET_ALL_TABS`;
export const SET_TOUCHED_BY_TAB_ID = `${NAMESPACE}/SET_TOUCHED_BY_TAB_ID`;
export const UPDATE_TAB_TITLE = `${NAMESPACE}/UPDATE_TAB_TITLE`;
