import React, { useRef } from 'react';
import { FormikConfig, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';

import {
  FullScreenSpinner,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { SUBMIT_BUTTON } from '@core/constants/test-ids';
import { getCompetitionById } from '@core/pages/competitions/store/selectors';
import { getStageById } from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { actions as groupsActions } from '@core/store/modules/tabs/edit-stage';
import { State } from '@volleyball/store';
import StageSettingsTemplate, {
  FIELD_NAMES,
  FormValues,
} from '@volleyball/business-components/stage-settings-template';
import { Group } from '@core/types';
import { Competition } from '@volleyball/types';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  stageId: number;
  competitionId: number;
  tabId?: string;
}

interface StateProps {
  competition: Competition;
  stage: Group;
  isLoading: boolean;
  isSubmitting: boolean;
}

interface DispatchProps {
  actions: {
    groups: typeof groupsActions;
    tabs: typeof tabActions;
  };
}

type Props = OwnProps & DispatchProps & StateProps;

const StageSettingsEdit = (props: Props) => {
  const { actions, competition, stage, isLoading, isSubmitting, tabId } = props;
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();

  const closeTab = () => actions.tabs.removeTab({ tabId });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const submitForm: FormikConfig<FormValues>['onSubmit'] = (values) => {
    actions.groups.updateGroupSettings({
      competitionId: competition.id,
      groupId: stage.id,
      groupSettings: {
        ...values,
        matchOfficialsSettings: {
          ...values[FIELD_NAMES.matchOfficialsSettings].map(
            (matchOfficialSettings) => ({
              roleId: matchOfficialSettings.roleId,
              groupId:
                matchOfficialSettings.groupId > 0
                  ? matchOfficialSettings.groupId
                  : null,
            }),
          ),
        },
      },
      tabId,
    });
  };

  const getSummaryBlockValues = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.competitionSeasonTitle,
    },
    {
      label: t('Stage'),
      value: stage?.title,
    },
  ];

  return (
    <>
      <Paper>
        <Box paddingX={3} paddingY={3}>
          <Box mb={2}>
            <Typography variant="h6">{t('Edit stage settings')}</Typography>
          </Box>
          <SummaryBlock
            isLoading={isLoading}
            values={getSummaryBlockValues()}
          />
        </Box>
        <Grid container item xs={12}>
          <Box mb={2} flex={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Grid>
        <Box pb={3}>
          {isLoading ? (
            <FullScreenSpinner />
          ) : (
            <Box paddingX={3} paddingY={1}>
              <StageSettingsTemplate
                editMode
                ref={formRef}
                initialValues={stage}
                onSubmit={submitForm}
                competition={competition}
              />
            </Box>
          )}
        </Box>
      </Paper>
      <Grid container justifyContent="flex-end">
        <Box mt={3} display="flex">
          {tabId && (
            <Grid item>
              <Box mr={1}>
                <Button
                  variant="outlined"
                  onClick={closeTab}
                  disabled={isLoading || isSubmitting}
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item>
            <ButtonWithProgress
              isLoading={isSubmitting}
              variant="contained"
              disabled={isLoading || isSubmitting}
              onClick={handleSubmit}
              data-qa={SUBMIT_BUTTON}
            >
              {t('Save')}
            </ButtonWithProgress>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const isLoadingSelector = (competitionId: number) =>
  createLoadingSelector([
    competitionProfileActions.getCompetitionDetailsRequest.toString(),
    {
      actionName: competitionProfileActions.getStageDetailsRequest.toString(),
      id: competitionId,
    },
  ]);

const isSubmittingSelector = createLoadingSelector([
  groupsActions.updateGroupSettingsRequest.toString(),
]);

const mapStateToProps = (
  state: State,
  { competitionId, stageId }: OwnProps,
): StateProps => ({
  isLoading: isLoadingSelector(competitionId)(state),
  isSubmitting: isSubmittingSelector(state),
  competition: getCompetitionById(state, { competitionId }),
  stage: getStageById(competitionId, stageId)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    groups: bindActionCreators(groupsActions, dispatch),
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StageSettingsEdit);
