import { ViewList, Tune, Dashboard, PeopleOutline } from '@mui/icons-material';
import { t } from 'i18next';
import { createURL } from '@core/helpers';
import UserProfile from '@core/components/app-bar/components/user-profile';
import CompetitionGeneralInformationEdit from '@core/pages/competition-general-information-edit';
import TeamGridEdit from '@core/pages/team-grids-edit';
import StageGeneralInformationEdit from '@core/pages/stage-general-information-edit';
import CompetitorGeneralInformationEditPage from '@core/pages/competitor-general-information-edit';
import MatchSheetManagementPage from '@core/pages/match-sheet-management';
import CompetitorProfile from '@core/pages/competitor-profile';
import PlayerListManagementPage from '@core/pages/player-list-management';
import PlayerListEditPage from '@core/pages/player-list-edit';
import CompetitorAssignmentPage from '@core/pages/competitor-assignment';
import CreateKitSetPage from '@core/pages/create-kit-set';
import DashboardPage from '@core/pages/dashboard';
import { CompetitionProfile } from '@volleyball/pages/competition-profile';
import CreateStagePage from '@volleyball/pages/create-stage';
import { MatchProfile } from '@volleyball/pages/match-profile';
import StageSettingsEdit from '@volleyball/pages/stage-settings-edit';
import CompetitorKitSet from '@volleyball/pages/competitor-kit-set';
import DisciplineSettings from '@volleyball/pages/discipline-settings';
import MatchDurationAttendanceEdit from '@volleyball/pages/match-duration-attendance-edit';
import MatchNotesEdit from '@volleyball/pages/match-notes-edit';
import ImposeSanction from '@volleyball/pages/impose-sanction';
import StageStandingsSettingsPage from '@volleyball/pages/stage-standings-settings';
import AssignmentsPage from '@volleyball/pages/assignments';
import { MatchDataCreate } from '@volleyball/pages/match-profile/components/match-data-tab';
import paths from '@volleyball/routes/paths';
import { lazyLoadPage } from '@volleyball/store/lazy-load-page';
import { NavigationsTabsMap } from '@core/types';

const CreateRepresentative = lazyLoadPage(
  'pages/createRepresentative',
  import('@core/pages/create-representative'),
);
const CreateVenuePage = lazyLoadPage(
  'pages/createVenue',
  import('@core/pages/create-venue'),
);
const ClubsPage = lazyLoadPage('pages/clubs', import('@core/pages/clubs'));
const ClubPage = lazyLoadPage('pages/club', import('@core/pages/club'));
const ClubProfilePage = lazyLoadPage(
  'pages/clubProfile',
  import('@core/pages/club-profile'),
);
const CreateClub = lazyLoadPage(
  'pages/createClub',
  import('@core/pages/create-club'),
);
const NationalTeamsPage = lazyLoadPage(
  'pages/nationalTeams',
  import('@core/pages/representatives'),
);
const PersonsPage = lazyLoadPage(
  'pages/persons',
  import('@core/pages/persons'),
);
const PersonProfile = lazyLoadPage(
  'pages/personProfile',
  import('@core/pages/person-profile'),
);
const RegisterPerson = lazyLoadPage(
  'pages/registerPerson',
  import('@core/pages/register-person'),
);
const RepresentativeProfilePage = lazyLoadPage(
  'pages/representativeProfile',
  import('@core/pages/representative-profile'),
);
const SeasonPage = lazyLoadPage('pages/seasons', import('@core/pages/seasons'));
const TermsAndConditions = lazyLoadPage(
  'pages/terms',
  import('@core/pages/terms-and-conditions'),
);
const VenuesPage = lazyLoadPage('pages/venues', import('@core/pages/venues'));
const VenueProfile = lazyLoadPage(
  'pages/venueProfile',
  import('@core/pages/venue-profile'),
);
const CompetitionsPage = lazyLoadPage(
  'pages/competitions',
  import('@core/pages/competitions'),
);
const CompetitionCreation = lazyLoadPage(
  'pages/createCompetition',
  import('@core/pages/competition-creation'),
);
const RefereeGroups = lazyLoadPage(
  'pages/refereeGroups',
  import('@core/pages/referee-groups'),
);
const CreateTeam = lazyLoadPage(
  'pages/teamCreation',
  import('@core/pages/create-team'),
);

export const getTabMapping = (): NavigationsTabsMap => ({
  [createURL(paths.home)]: {
    title: t('Dashboard'),
    path: createURL(paths.home),
    component: DashboardPage,
    icon: Dashboard,
  },
  [createURL(paths.nationalTeams)]: {
    title: t('National teams'),
    path: createURL(paths.nationalTeams),
    component: NationalTeamsPage,
    icon: ViewList,
    group: 'Clubs & People',
  },
  [createURL(paths.clubs)]: {
    title: t('Clubs'),
    path: createURL(paths.clubs),
    component: ClubsPage,
    icon: ViewList,
    group: 'Clubs & People',
  },
  [createURL(paths.club)]: {
    title: t('Club'),
    component: ClubPage,
    path: createURL(paths.club),
    icon: ViewList,
    group: 'Clubs & People',
  },
  [createURL(paths.createClub)]: {
    title: t('Create club'),
    component: CreateClub,
  },
  [createURL(paths.createRepresentative)]: {
    title: t('Create representative'),
    component: CreateRepresentative,
  },
  [createURL(paths.persons)]: {
    title: t('Persons'),
    path: createURL(paths.persons),
    component: PersonsPage,
    icon: PeopleOutline,
    group: 'Clubs & People',
  },
  [createURL(paths.competitions)]: {
    title: t('Competitions'),
    icon: Tune,
    component: CompetitionsPage,
    path: createURL(paths.competitions),
    group: 'Competitions',
  },
  [createURL(paths.competitionProfile)]: {
    title: t('Competition profile'),
    component: CompetitionProfile,
  },
  [createURL(paths.ageGroups)]: {
    title: t('Age groups'),
    icon: Tune,
    path: createURL(paths.ageGroups),
    group: 'Setup',
  },
  [createURL(paths.seasons)]: {
    title: t('Seasons'),
    icon: Tune,
    component: SeasonPage,
    path: createURL(paths.seasons),
    group: 'Setup',
  },
  [createURL(paths.competitionLevels)]: {
    title: t('Competition levels'),
    icon: Tune,
    path: createURL(paths.competitionLevels),
    group: 'Setup',
  },
  [createURL(paths.refereeGroups)]: {
    title: t('Referee groups'),
    icon: Tune,
    component: RefereeGroups,
    path: createURL(paths.refereeGroups),
    group: 'Setup',
  },
  [createURL(paths.competitionCreation)]: {
    title: t('Create competition'),
    icon: Tune,
    component: CompetitionCreation,
    path: createURL(paths.competitionCreation),
  },
  [createURL(paths.clubProfile)]: {
    title: t('Club Item'),
    component: ClubProfilePage,
  },
  [createURL(paths.representativeProfile)]: {
    title: t('Representative'),
    component: RepresentativeProfilePage,
  },
  [createURL(paths.venues)]: {
    title: t('Venues'),
    component: VenuesPage,
  },
  [createURL(paths.venueProfile)]: {
    title: t('Venue'),
    component: VenueProfile,
  },
  [createURL(paths.createVenue)]: {
    title: t('Create venue'),
    component: CreateVenuePage,
  },
  [createURL(paths.appointments)]: {
    title: t('Appointments'),
  },
  [createURL(paths.matches)]: {
    title: t('Matches'),
  },
  [createURL(paths.matchProfile)]: {
    title: t('Match Profile'),
    component: MatchProfile,
    paths: createURL(paths.matchProfile),
  },
  [createURL(paths.competitorAssignment)]: {
    title: t('Add team'),
    component: CompetitorAssignmentPage,
  },
  [createURL(paths.competitorProfile)]: {
    title: t('Competitor'),
    component: CompetitorProfile,
  },
  [createURL(paths.createTeam)]: {
    title: t('Create new team'),
    component: CreateTeam,
  },
  [createURL(paths.playerListManagement)]: {
    title: t('Manage player roster'),
    component: PlayerListManagementPage,
  },
  [createURL(paths.playerListEdit)]: {
    title: t('Edit player list'),
    component: PlayerListEditPage,
  },
  [createURL(paths.manageMatchSheet)]: {
    title: t('Manage Match lineup'),
    component: MatchSheetManagementPage,
  },
  [createURL(paths.createKitSet)]: {
    title: t('Create Kit Set'),
    component: CreateKitSetPage,
  },
  [createURL(paths.createStage)]: {
    title: t('Create stage'),
    component: CreateStagePage,
  },
  [createURL(paths.competitionGeneralInformationEdit)]: {
    title: t('Edit general info'),
    component: CompetitionGeneralInformationEdit,
  },
  [createURL(paths.stageGeneralInformationEdit)]: {
    title: t('Edit general info'),
    component: StageGeneralInformationEdit,
  },
  [createURL(paths.stageSettingsEdit)]: {
    title: t('Edit settings'),
    component: StageSettingsEdit,
  },
  [createURL(paths.stageStandingsSettings)]: {
    title: t('Standings settings'),
    component: StageStandingsSettingsPage,
  },
  [createURL(paths.competitorTeamKitSet)]: {
    title: t('Team kit set'),
    component: CompetitorKitSet,
  },
  [createURL(paths.disciplineSettings)]: {
    title: t('Discipline settings'),
    component: DisciplineSettings,
  },
  [createURL(paths.personProfile)]: {
    title: t('Person profile'),
    component: PersonProfile,
  },
  [createURL(paths.competitorGeneralInformationEdit)]: {
    title: t('Team general information'),
    component: CompetitorGeneralInformationEditPage,
  },
  [createURL(paths.matchDurationEdit)]: {
    title: t('Edit duration & attendance'),
    component: MatchDurationAttendanceEdit,
  },
  [createURL(paths.matchNotesEdit)]: {
    title: t('Edit notes'),
    component: MatchNotesEdit,
  },
  [createURL(paths.matchCommissionerNotesEdit)]: {
    title: t('Edit Match Commissioner notes'),
    component: MatchNotesEdit,
  },
  [createURL(paths.stageTeamGridEdit)]: {
    title: t('Team grid'),
    component: TeamGridEdit,
  },
  [createURL(paths.userProfile)]: {
    title: t('User profile'),
    component: UserProfile,
  },
  [createURL(paths.users)]: {
    title: t('Users'),
  },
  [createURL(paths.featureToggle)]: {
    title: t('Feature Toggle'),
  },
  [createURL(paths.imposeSanction)]: {
    title: t('Impose Sanction'),
    component: ImposeSanction,
  },
  [createURL(paths.termsAndConditions)]: {
    title: 'Terms and Conditions',
    component: TermsAndConditions,
  },
  [createURL(paths.registerPerson)]: {
    title: t('Register Person'),
    component: RegisterPerson,
  },
  [createURL(paths.matchDataCreate)]: {
    title: t('Edit match data'),
    component: MatchDataCreate,
  },
  [createURL(paths.assignments)]: {
    title: t('Assignments'),
    paths: createURL(paths.assignments),
    component: AssignmentsPage,
  },
});
