import { MatchSets } from '@core/types';

export const getMatchSetType = (matchSet: keyof typeof MatchSets) => {
  if (!matchSet) {
    return '';
  }

  const numbers = matchSet.match(/\d+/g);

  return `${numbers[0]}-${numbers[1]}`;
};
