import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  PersonNameColumn,
  CustomTable,
  ColumnWithSubValue,
} from '@core/components';
import { Column } from '@core/components/material-table';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { getGenders, formatDate, formatNationalitiesISO } from '@core/helpers';
import { AddedMatchPlayerList, AddedMatchPlayer } from '@core/types';
import { getTitle, APP_TITLES } from '@core/helpers/app-sports/title-getters';
import { State } from '@core/store';
import JerseyNumber from '@core/components/jersey-number';
import { useMatchForJerseyNumbers } from '@core/hooks/use-match-for-jersey-numbers';

interface StateProps {
  dateFormat: string;
}

interface OwnProps {
  players: AddedMatchPlayerList;
}

type Props = StateProps & OwnProps;

const PlayersTable = (props: Props) => {
  const { t } = useTranslation();
  const { players, dateFormat } = props;
  const getJerseyNumber = useMatchForJerseyNumbers();

  const columns = new Array<Column<AddedMatchPlayer>>(
    {
      title: t('Jersey'),
      render: (rowData) => {
        const { isCaptain, isLibero } = rowData;

        return (
          <ColumnWithSubValue
            logo={{
              defaultIcon: <JerseyNumber number={getJerseyNumber(rowData)} />,
            }}
            isCaptain={isCaptain}
            isLibero={isLibero}
          />
        );
      },
    },
    {
      title: t('Pos.'),
      render: (rowData) =>
        useMatchForJerseyNumbers
          ? rowData?.playerPosition?.shortName ?? '-'
          : rowData.competitorPlayer.playerPosition?.shortName ?? '-',
    },
    {
      title: t('Nation'),
      render: (rowData) =>
        formatNationalitiesISO(
          rowData.competitorPlayer.player.nationalCitizenships ?? [],
        ),
    },
    {
      title: t('Name'),
      render: (rowData) => (
        <PersonNameColumn person={rowData.competitorPlayer.player} />
      ),
    },
    {
      title: getTitle(APP_TITLES.POPULAR_NAME),
      render: (rowData) => rowData.competitorPlayer.player.popularName ?? '-',
    },
    {
      title: t('Date of Birth'),
      render: (rowData) =>
        formatDate(dateFormat, rowData.competitorPlayer.player.dateOfBirth) ??
        '-',
    },
    {
      title: t('Gender'),
      render: (rowData) =>
        getGenders(t, rowData.competitorPlayer.player.gender) ?? '-',
    },
  );

  return (
    <CustomTable
      columns={columns}
      titleRow={t('Starting lineup')}
      data={players || []}
      noPaper
      mobileTable
      mobileColumnsToShow={columns.map((_, index) => index)}
    />
  );
};

const mapStateToProps = (state: State): StateProps => ({
  dateFormat: getCurrentCustomer(state).dateFormat,
});

export default connect(mapStateToProps, null)(PlayersTable);
