import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { INITIALIZE_FILE_STORE, SET_FILE_LIST } from './constants';
export interface DefaultState {
  [objectId: string]: {
    files: Array<any>;
  };
}

export const defaultState: DefaultState = {};

export const initializedState = {};

const reducer = handleActions<any, any>(
  {
    [INITIALIZE_FILE_STORE]: (state, { payload }) =>
      assocPath([payload.objectId], initializedState, state),
    [SET_FILE_LIST]: (state, { payload }) =>
      assocPath([payload.objectId], payload.payload, state),
  },
  defaultState,
);

export default reducer;
