import LatoMediumWoff2 from '../fonts/Lato-Medium.woff2';
import LatoMediumWoff from '../fonts/Lato-Medium.woff';

const latoLatinMedium = `
        @font-face {
          font-family: 'LatoWeb';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          text-rendering: optimizeLegibility;
          src: url(${LatoMediumWoff2}) format('woff2'), url(${LatoMediumWoff}) format('woff');
        }
      `;

export default latoLatinMedium;
