import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ValueContainerProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

type Props = ValueContainerProps<AutocompleteOption, boolean>;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: 'inherit',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
  },
}));

const ValueContainer = ({ children }: Props) => {
  const classes = useStyles();

  return <Grid className={classes.root}>{children}</Grid>;
};

export default ValueContainer;
