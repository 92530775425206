import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  mergeAll,
  pick,
  keys,
  propEq,
  pathOr,
  values,
  find,
  reject,
  assocPath,
  pipe,
} from 'ramda';
import { Dispatch, bindActionCreators } from 'redux';
import { Paper, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FullScreenSpinner } from '@core/components';
import { useCheckToggles } from '@core/pages/feature-toggle/store/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as customerActions } from '@core/store/modules/api/customer';
import { actions as matchDurationListActions } from '@core/store/modules/tabs/match-profile';
import { getMatchDurationListByMatchId } from '@core/store/modules/tabs/match-profile/selectors';
import { actions as matchesActions } from '@core/store/modules/matches';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { State } from '@volleyball/store';
import { MatchDurationList, CompetitionSettings } from '@volleyball/types';

import DurationListFormTemplate from './duration-list-form-template';
import DurationListFormActions from './duration-list-form-actions';
import {
  DURATION_PERIOD_TYPES,
  DURATION_MAIN_FIELDS,
  getDefaultValues,
  getValidationSchema,
  getValidationSchemaDynamic,
  FormValues,
  AttendanceFormValues,
  addPrefix,
  removePrefix,
  ATTENDANCE_MAIN_FIELDS,
  NOT_MUTABLE_FIELDS,
  getDefaultValuesDynamic,
} from './constants';
import { MatchModel } from '@core/types';

interface DispatchProps {
  actions: {
    durationList: typeof matchDurationListActions;
    matches: typeof matchesActions;
  };
}

interface OwnProps {
  tabId: string;
  matchId: number;
}

interface StateProps {
  durationList: MatchDurationList;
  match: MatchModel;
  isLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(15),
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    paddingBottom: theme.spacing(3),
  },
  fullWidth: {
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      margin: theme.spacing(2),
    },
  },
}));

const useStylesNew = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(15),
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    paddingBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  fullWidth: {
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      margin: theme.spacing(2),
    },
  },
}));

const mapFormValuesForAPI = (formValues: FormValues) => {
  const paths = keys(formValues).map((key) =>
    removePrefix({ [key]: formValues[key] }),
  );
  let result = {};
  paths.forEach((res) => {
    const path = Array.isArray(res[0]) ? res[0] : [res[0]];
    const fieldName = Array.isArray(path[2]) ? path[2][0] : path[2];
    let value = Array.isArray(res[1]) ? res[1][0] : res[1];

    if (values(DURATION_MAIN_FIELDS).includes(fieldName) && value) {
      const periodNumber = +path[1];

      const periodHalfs = pathOr([], [path[0]], result);
      if (
        [DURATION_MAIN_FIELDS.endTime, DURATION_MAIN_FIELDS.startTime].includes(
          fieldName,
        )
      ) {
        value = dayjs(value, 'HH:mm').format('h:mm A');
      }
      let currentHalf =
        find(propEq(DURATION_MAIN_FIELDS.periodNumber, periodNumber))(
          periodHalfs,
        ) || {};
      currentHalf = pipe(
        assocPath([fieldName], value),
        assocPath([DURATION_MAIN_FIELDS.periodNumber], periodNumber),
      )(currentHalf);

      const restPeriodHalfs = reject(
        propEq(DURATION_MAIN_FIELDS.periodNumber, periodNumber),
      )(periodHalfs);
      result = assocPath([path[0]], [...restPeriodHalfs, currentHalf])(result);
    }
  });

  return result;
};

const mapFormValuesForForm = (
  competitionSettings: CompetitionSettings,
  durationList: MatchDurationList,
  attendance: MatchModel['attendance'],
): FormValues & AttendanceFormValues => {
  const mapFormGroup = (
    group: (typeof durationList)[DURATION_PERIOD_TYPES.REGULAR],
    type: DURATION_PERIOD_TYPES,
  ) =>
    mergeAll(
      group
        ? group.map((item) => {
            const index = item.periodNumber || '1';
            const iterableValues =
              pick(
                values({ ...DURATION_MAIN_FIELDS, ...NOT_MUTABLE_FIELDS }),
                item,
              ) || {};

            const fields = keys(iterableValues).map(
              (field: DURATION_MAIN_FIELDS & NOT_MUTABLE_FIELDS) => {
                let value = iterableValues[field];
                if (
                  [
                    DURATION_MAIN_FIELDS.endTime,
                    DURATION_MAIN_FIELDS.startTime,
                  ].includes(field)
                ) {
                  // @ts-ignore
                  value = value
                    ? dayjs(iterableValues[field], 'h:mm A').format('HH:mm')
                    : null;
                }

                return { [addPrefix(type, index, field)]: value };
              },
            );

            return mergeAll(fields);
          })
        : [],
    );

  // @ts-ignore
  return keys(durationList)?.length
    ? {
        ...mapFormGroup(
          durationList[DURATION_PERIOD_TYPES.REGULAR],
          DURATION_PERIOD_TYPES.REGULAR,
        ),
        ...mapFormGroup(
          durationList[DURATION_PERIOD_TYPES.EXTRATIME],
          DURATION_PERIOD_TYPES.EXTRATIME,
        ),
        ...mapFormGroup(
          durationList[DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT],
          DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT,
        ),
        [ATTENDANCE_MAIN_FIELDS.attendance]: attendance || 0,
      }
    : {
        ...getDefaultValues(competitionSettings),
        [ATTENDANCE_MAIN_FIELDS.attendance]: attendance || 0,
      };
};

const mapFormValuesForFormDynamic = (
  competitionSettings: CompetitionSettings,
  durationList: MatchDurationList,
  attendance: MatchModel['attendance'],
): FormValues & AttendanceFormValues => {
  const mapFormGroupDynamic = (
    group: (typeof durationList)[DURATION_PERIOD_TYPES.REGULAR],
    type: DURATION_PERIOD_TYPES,
  ) => {
    return mergeAll(
      group
        ? group.map((item) => {
            const index = item.periodNumber || '1';
            const iterableValues =
              pick(
                values({ ...DURATION_MAIN_FIELDS, ...NOT_MUTABLE_FIELDS }),
                item,
              ) || {};

            const fields = keys(iterableValues).map(
              (field: DURATION_MAIN_FIELDS & NOT_MUTABLE_FIELDS) => {
                let value = iterableValues[field];
                if (
                  [
                    DURATION_MAIN_FIELDS.endTime,
                    DURATION_MAIN_FIELDS.startTime,
                  ].includes(field)
                ) {
                  // @ts-ignore
                  value = value
                    ? dayjs(iterableValues[field], 'h:mm A').format('HH:mm')
                    : null;
                }

                return { [addPrefix(type, index, field)]: value };
              },
            );

            return mergeAll(fields);
          })
        : [],
    );
  };

  if (keys(durationList)?.length) {
    const fetchedValues = {
      ...mapFormGroupDynamic(
        durationList[DURATION_PERIOD_TYPES.REGULAR],
        DURATION_PERIOD_TYPES.REGULAR,
      ),
      ...mapFormGroupDynamic(
        durationList[DURATION_PERIOD_TYPES.EXTRATIME],
        DURATION_PERIOD_TYPES.EXTRATIME,
      ),
      ...mapFormGroupDynamic(
        durationList[DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT],
        DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT,
      ),
      [ATTENDANCE_MAIN_FIELDS.attendance]: attendance || 0,
    };
    const defaultValues = getDefaultValuesDynamic(competitionSettings);

    return {
      ...defaultValues,
      ...fetchedValues,
      [ATTENDANCE_MAIN_FIELDS.attendance]: attendance || 0,
    };
  } else {
    return {
      ...getDefaultValuesDynamic(competitionSettings),
      [ATTENDANCE_MAIN_FIELDS.attendance]: attendance || 0,
    };
  }
};

const DurationListFormWrapper = ({
  isLoading,
  matchId,
  actions,
  tabId,
  durationList,
  match,
}: Props) => {
  const classesOld = useStyles();
  const classesNew = useStylesNew();
  const { t } = useTranslation();
  const isDynamicFormToggled = useCheckToggles('FE_DYNAMIC_MATCH_DURATION');
  const classes = isDynamicFormToggled ? classesNew : classesOld;

  useEffect(() => {
    matchId && actions.durationList.getMatchDurationList({ matchId });
  }, []);

  const competitionSettings = pathOr(
    null,
    ['_embedded', 'competition', 'competitionSettings'],
    match,
  );

  if (!match || !competitionSettings || isLoading) {
    return <FullScreenSpinner />;
  }

  const getInitialValues = (): FormValues & AttendanceFormValues => {
    if ((durationList && keys(durationList)?.length) || match.attendance) {
      // TODO: WIP
      return isDynamicFormToggled
        ? mapFormValuesForFormDynamic(
            competitionSettings,
            durationList,
            match.attendance,
          )
        : mapFormValuesForForm(
            competitionSettings,
            durationList,
            match.attendance,
          );
    } else {
      return isDynamicFormToggled
        ? getDefaultValuesDynamic(competitionSettings)
        : getDefaultValues(competitionSettings);
    }
  };

  const handleSubmit = ({
    attendance,
    ...submitValues
  }: FormValues & AttendanceFormValues) => {
    if (+attendance !== +match.attendance) {
      const url = match?._links?.self?.href;
      actions.matches.changeMatchAttendance({
        matchId,
        url,
        data: { attendance },
      });
    }
    actions.durationList.updateMatchDurationList({
      matchId,
      data: mapFormValuesForAPI(submitValues),
      tabId,
    });
  };

  const handleSubmitDynamic = ({
    attendance,
    ...submitValues
  }: FormValues & AttendanceFormValues) => {
    if (+attendance !== +match.attendance) {
      const url = match?._links?.self?.href;

      actions.matches.changeMatchAttendance({
        matchId,
        url,
        data: { attendance },
      });
    }

    actions.durationList.updateMatchDurationList({
      matchId,
      data: mapFormValuesForAPI(submitValues),
      tabId,
    });
  };

  const renderForm = (props: {
    values: any;
    errors: any;
    dirty: boolean;
    isValid: boolean;
  }) => {
    const { errors, dirty, isValid } = props;

    return (
      <Form>
        <Paper className={classes.root}>
          <Typography variant="subtitle1" className={classes.heading}>
            {t('Edit duration & attendance')}
          </Typography>
          <DurationListFormTemplate competitionSettings={competitionSettings} />
        </Paper>
        <DurationListFormActions
          tabId={tabId}
          disabled={!dirty || !!keys(errors)?.length || !isValid}
        />
      </Form>
    );
  };

  const initialValues = getInitialValues();

  if (isDynamicFormToggled) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitDynamic}
        validationSchema={getValidationSchemaDynamic(t, competitionSettings)}
        enableReinitialize
        // TODO: touched dynamic maybe?
        touched={keys(getDefaultValues(competitionSettings))}
      >
        {renderForm}
      </Formik>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(t)}
      enableReinitialize
      touched={keys(getDefaultValues(competitionSettings))}
    >
      {renderForm}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    durationList: bindActionCreators(matchDurationListActions, dispatch),
    matches: bindActionCreators(matchesActions, dispatch),
  },
});

const isLoadingSelector = createLoadingSelector([
  customerActions.getCustomerRequest.toString(),
]);

const mapStateToProps = (state: State, { matchId }: OwnProps): StateProps => ({
  durationList: getMatchDurationListByMatchId(state, { matchId }),
  match: getMatchById(state, matchId),
  isLoading: isLoadingSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DurationListFormWrapper);
