import { all, call, Effect, put, take, takeEvery } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as expansionPanelGroupsActions from '@core/store/modules/ui/expansion-panel-groups/actions';
import {
  getGroupFixtureSettings,
  updateGroupFixtureSettings,
  createGroupFixtureSettings,
} from '@volleyball/store/modules/api/group-fixture-settings/sagas';

import * as ACTION_TYPES from './constants';
import * as groupFixtureSettingsActions from './actions';

export function* getGroupFixtureSettingsWorker(
  action: ReturnType<
    typeof groupFixtureSettingsActions.getGroupFixtureSettings
  >,
) {
  const { payload } = action;

  const { error, response } = yield call(getGroupFixtureSettings, payload);

  if (!error) {
    yield put(groupFixtureSettingsActions.addGroupFixtureSettings(response));
  } else {
    yield call(handleApiErrors, error);
  }
}

export function* getGroupFixtureSettingsWatcher() {
  yield takeEvery(
    ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS,
    getGroupFixtureSettingsWorker,
  );
}

export function* updateGroupFixtureSettingsWorker(
  action: ReturnType<
    typeof groupFixtureSettingsActions.updateGroupFixtureSettings
  >,
): Generator<Effect, any, any> {
  const { payload } = action;

  const response = yield call(
    updateGroupFixtureSettings,
    payload.groupId,
    payload.data,
  );

  if (!response.error) {
    yield put(
      groupFixtureSettingsActions.getGroupFixtureSettings(payload.groupId),
    );
  } else {
    yield put(groupFixtureSettingsActions.setErrors(response.error.errors));
  }

  return response;
}

export function* updateGroupFixtureSettingsWatcher() {
  yield takeEvery(
    ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS,
    updateGroupFixtureSettingsWorker,
  );
}

export function* createGroupFixtureSettingsWorker(
  action: ReturnType<
    typeof groupFixtureSettingsActions.createGroupFixtureSettings
  >,
): Generator<Effect, any, any> {
  const { payload } = action;

  const response = yield call(
    createGroupFixtureSettings,
    payload.groupId,
    payload.data,
  );

  if (!response.error) {
    yield put(
      groupFixtureSettingsActions.getGroupFixtureSettings(payload.groupId),
    );
  } else {
    yield put(groupFixtureSettingsActions.setErrors(response.error.errors));
  }

  return response;
}

export function* createGroupFixtureSettingsWatcher() {
  yield takeEvery(
    ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS,
    createGroupFixtureSettingsWorker,
  );
}

export function* createDraftCompetitionGroupFixtureSettingsFlow(): Generator<
  Effect,
  any,
  any
> {
  while (true) {
    const {
      payload: { panelData, groupFixtureSettingsData },
    } = yield take(
      ACTION_TYPES.CREATE_DRAFT_COMPETITION_GROUP_FIXTURE_SETTINGS,
    );
    const response = yield call(
      createGroupFixtureSettingsWorker,
      groupFixtureSettingsActions.createGroupFixtureSettings(
        groupFixtureSettingsData,
      ),
    );

    if (!response.error) {
      yield put(expansionPanelGroupsActions.setPanelCompleted(panelData));
      yield put(expansionPanelGroupsActions.activateNextPanel(panelData));
    } else {
      yield put(expansionPanelGroupsActions.setPanelError(panelData));
    }
  }
}

export function* updateDraftCompetitionGroupFixtureSettingsFlow(): Generator<
  Effect,
  any,
  any
> {
  while (true) {
    const {
      payload: { panelData, groupFixtureSettingsData },
    } = yield take(
      ACTION_TYPES.UPDATE_DRAFT_COMPETITION_GROUP_FIXTURE_SETTINGS,
    );
    const response = yield call(
      updateGroupFixtureSettingsWorker,
      groupFixtureSettingsActions.updateGroupFixtureSettings(
        groupFixtureSettingsData,
      ),
    );

    if (!response.error) {
      yield put(expansionPanelGroupsActions.setPanelCompleted(panelData));
      yield put(expansionPanelGroupsActions.activateNextPanel(panelData));
    } else {
      yield put(expansionPanelGroupsActions.setPanelError(panelData));
    }
  }
}

export default function* saga() {
  yield all([
    getGroupFixtureSettingsWatcher(),
    updateGroupFixtureSettingsWatcher(),
    createGroupFixtureSettingsWatcher(),
    updateDraftCompetitionGroupFixtureSettingsFlow(),
    createDraftCompetitionGroupFixtureSettingsFlow(),
  ]);
}
