export const NAMESPACE = 'availability';

export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;
export const CLEAR_ERRORS = `${NAMESPACE}/CLEAR_ERROR`;
export const SET_AVAILABILITIES = `${NAMESPACE}/SET_AVAILABILITIES`;

export const GET_AVAILABILITIES = `${NAMESPACE}/GET_AVAILABILITIES`;
export const GET_AVAILABILITIES_REQUEST = `${NAMESPACE}/GET_AVAILABILITIES_REQUEST`;
export const GET_AVAILABILITIES_SUCCESS = `${NAMESPACE}/GET_AVAILABILITIES_SUCCESS`;
export const GET_AVAILABILITIES_FAILURE = `${NAMESPACE}/GET_AVAILABILITIES_FAILURE`;

export const CREATE_AVAILABILITY = `${NAMESPACE}/CREATE_AVAILABILITY`;
export const CREATE_AVAILABILITY_REQUEST = `${NAMESPACE}/CREATE_AVAILABILITY_REQUEST`;
export const CREATE_AVAILABILITY_SUCCESS = `${NAMESPACE}/CREATE_AVAILABILITY_SUCCESS`;
export const CREATE_AVAILABILITY_FAILURE = `${NAMESPACE}/CREATE_AVAILABILITY_FAILURE`;

export const DELETE_AVAILABILITY = `${NAMESPACE}/DELETE_AVAILABILITY`;
export const DELETE_AVAILABILITY_REQUEST = `${NAMESPACE}/DELETE_AVAILABILITY_REQUEST`;
export const DELETE_AVAILABILITY_SUCCESS = `${NAMESPACE}/DELETE_AVAILABILITY_SUCCESS`;
export const DELETE_AVAILABILITY_FAILURE = `${NAMESPACE}/DELETE_AVAILABILITY_FAILURE`;
