import { lazy } from 'react';
import { ModalTypes } from '@core/types';
import UserRoleRequestModal from '@core/pages/user-profile/components/user-role-request-modal';
import AddPersonToClubModal from '@core/pages/representative-profile/components/add-person-modal';
import { PersonsUploadModal } from '@core/components/persons-upload-modal';
import { MatchOfficialAllocationModal } from '@core/components/match-official-allocations';
import GroupCreateModal from '@core/pages/competition-profile/components/modals/group-create-modal';
import StageMatchesUploadModal from '@core/pages/competition-profile/components/stage-profile-panel/stage-matches-upload-modal';
import TeamGridMultiteamSelectModal from '@core/pages/team-grids-edit/components/team-grid-multiteam-select-modal';
import { MatchCreateModal } from '@core/pages/competition-profile';
import ChangeMatchStatusModal from '@core/pages/match-profile/components/change-match-status-modal';
import MatchSheetValidationsModal from '@core/pages/match-sheet-management/components/match-sheet-validations-modal';
import MatchSheetHistoryModal from '@core/pages/match-sheet-management/components/match-sheet-history-modal';
import ManageTeamKitsModal from '@core/pages/competitor-profile/components/team-kits-tab/manage-team-kits-modal';
import PlayerListValidationModal from '@core/pages/player-list-management/components/player-list-validations-modal';
import CompetitorPreviousPlayerListModal from '@core/pages/competitor-assignment/components/competitor-previous-player-list-modal';

import DocumentUploadModal from '@volleyball/components/document-upload-modal';
import SessionExpiredModal from '@volleyball/business-components/session-expired-modal/session-expired-modal';
import SelectMatchModal from '@volleyball/pages/impose-sanction/components/select-match-modal';
import StandingsSettingsTablePreviewModal from '@volleyball/pages/stage-standings-settings/components/standings-settings-table-preview-modal';
import StandingsSettingsHistoryModal from '@volleyball/pages/competition-profile/components/stage-profile-panel/standings-tab/components/standing-history-modal';
import { MatchDataEditModal } from '@volleyball/pages/match-profile/components/match-data-tab';

const TermsAndConditionsModal = lazy(
  () => import('@core/components/terms-and-conditions-modal'),
);
const PictureUploadModal = lazy(
  () => import('@core/components/picture-upload-modal'),
);
const CreateUpdateGroupModal = lazy(
  () =>
    import('@core/pages/referee-groups/components/create-update-group-modal'),
);
const MoToLinkTableModal = lazy(
  () => import('@core/pages/referee-groups/components/mo-to-link-table-modal'),
);
const CreateCompetitionSeasonModal = lazy(
  () =>
    import(
      '@core/pages/competition-profile/modals/create-competition-season-modal'
    ),
);
const ChangeCompetitionStatusModal = lazy(
  () =>
    import(
      '@core/pages/competition-profile/modals/change-competition-status-modal'
    ),
);

const modalsMapping = {
  [ModalTypes.STAGE_MATCHES_UPLOAD_MODAL]: StageMatchesUploadModal,
  [ModalTypes.CHANGE_MATCH_STATUS_MODAL]: ChangeMatchStatusModal,
  [ModalTypes.DOCUMENT_UPLOAD_MODAL]: DocumentUploadModal,
  [ModalTypes.MATCH_CREATE_MODAL]: MatchCreateModal,
  [ModalTypes.MANAGE_TEAM_KITS_MODAL]: ManageTeamKitsModal,
  [ModalTypes.MATCH_OFFICIAL_ALLOCATION_MODAL]: MatchOfficialAllocationModal,
  [ModalTypes.PICTURE_UPLOAD_MODAL]: PictureUploadModal,
  [ModalTypes.TERMS_AND_CONDITIONS_MODAL]: TermsAndConditionsModal,
  [ModalTypes.COMPETITOR_PREVIOUS_PLAYER_LIST]:
    CompetitorPreviousPlayerListModal,
  [ModalTypes.SESSION_EXPIRED_MODAL]: SessionExpiredModal,
  [ModalTypes.USER_ROLE_REQUEST_MODAL]: UserRoleRequestModal,
  [ModalTypes.SELECT_MATCH_MODAL]: SelectMatchModal,
  [ModalTypes.MATCH_SHEET_VALIDATION_MODAL]: MatchSheetValidationsModal,
  [ModalTypes.PLAYER_LIST_VALIDATION_MODAL]: PlayerListValidationModal,
  [ModalTypes.MATCH_SHEET_HISTORY_MODAL]: MatchSheetHistoryModal,
  [ModalTypes.STANDINGS_SETTINGS_TABLE_PREVIEW_MODAL]:
    StandingsSettingsTablePreviewModal,
  [ModalTypes.STANDINGS_SETTINGS_HISTORY_MODAL]: StandingsSettingsHistoryModal,
  [ModalTypes.CREATE_UPDATE_GROUP_MODAL]: CreateUpdateGroupModal,
  [ModalTypes.ADD_MO_MODAL]: MoToLinkTableModal,
  [ModalTypes.ADD_MEMBER_TO_CLUB_MODAL]: AddPersonToClubModal,
  [ModalTypes.GROUP_CREATE_MODAL]: GroupCreateModal,
  [ModalTypes.TEAM_GRID_MULTITEAM_SELECT_MODAL]: TeamGridMultiteamSelectModal,
  [ModalTypes.MATCH_DATA_EDIT_MODAL]: MatchDataEditModal,
  [ModalTypes.PERSONS_UPLOAD_MODAL]: PersonsUploadModal,
  [ModalTypes.CREATE_COMPETITION_SEASON_MODAL]: CreateCompetitionSeasonModal,
  [ModalTypes.CHANGE_COMPETITION_STATUS_MODAL]: ChangeCompetitionStatusModal,
};

export default modalsMapping;
