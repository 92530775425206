import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { getVenue } from '@core/helpers';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { State } from '@volleyball/store';
import { MatchModel } from '@core/types';

interface OwnProps {
  matchId: number;
}

interface StateProps {
  match: MatchModel;
}

type Props = OwnProps & StateProps;

const MatchProfileSummaryBlock = (props: Props) => {
  const { match } = props;
  const { t } = useTranslation();

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Match No.'),
      value: match?.id,
    },
    {
      label: t('Home team'),
      value: match?.homeCompetitorTitle,
    },
    {
      label: t('Away team'),
      value: match?.awayCompetitorTitle,
    },
    {
      label: t('Date, time'),
      value: `${match?.date} ${match?.time}`,
      dateField: true,
    },
    {
      label: t('Venue'),
      value: getVenue(match?.venue)?.title,
    },
  ];

  return <SummaryBlock values={getInfoBlocks()} />;
};

const mapStateToProps = (state: State, { matchId }: OwnProps): StateProps => ({
  match: getMatchById(state, matchId),
});

export default connect(mapStateToProps)(MatchProfileSummaryBlock);
