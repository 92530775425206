import { put, call, Effect } from 'redux-saga/effects';

import * as disciplinaryRulesAPI from '@core/api/disciplinary-rule';
import { DisciplinaryRuleSubmitData } from '@core/types';

import actions from '.';

interface GetPayload {
  competitionId: number;
}

interface GetOnePayload extends GetPayload {
  ruleId: number;
}

interface CreatePayload {
  competitionId: number;
  data: DisciplinaryRuleSubmitData;
}

interface UpdatePayload extends CreatePayload {
  ruleId: number;
}

interface DeletePayload extends GetPayload {
  ruleId: number;
}

export function* getDisciplinaryRules({
  competitionId,
}: GetPayload): Generator<Effect, any, any> {
  yield put(actions.getDisciplinaryRulesRequest());
  const response = yield call(
    disciplinaryRulesAPI.getDisciplinaryRules,
    competitionId,
  );

  if (!response.error) {
    yield put(actions.getDisciplinaryRulesSuccess());
  } else {
    yield put(actions.getDisciplinaryRulesFailure());
  }

  return response;
}

export function* getDisciplinaryRule({
  competitionId,
  ruleId,
}: GetOnePayload): Generator<Effect, any, any> {
  yield put(actions.getDisciplinaryRuleRequest());
  const response = yield call(
    disciplinaryRulesAPI.getDisciplinaryRule,
    competitionId,
    ruleId,
  );

  if (!response.error) {
    yield put(actions.getDisciplinaryRuleSuccess());
  } else {
    yield put(actions.getDisciplinaryRuleFailure());
  }

  return response;
}

export function* createDisciplinaryRule({
  competitionId,
  data,
}: CreatePayload): Generator<Effect, any, any> {
  yield put(actions.createDisciplinaryRuleRequest());
  const response = yield call(
    disciplinaryRulesAPI.createDisciplinaryRule,
    competitionId,
    data,
  );

  if (!response.error) {
    yield put(actions.createDisciplinaryRuleSuccess());
  } else {
    yield put(actions.createDisciplinaryRuleFailure());
  }

  return response;
}

export function* updateDisciplinaryRule({
  competitionId,
  ruleId,
  data,
}: UpdatePayload): Generator<Effect, any, any> {
  yield put(actions.updateDisciplinaryRuleRequest());
  const response = yield call(
    disciplinaryRulesAPI.updateDisciplinaryRule,
    competitionId,
    ruleId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateDisciplinaryRuleSuccess());
  } else {
    yield put(actions.updateDisciplinaryRuleFailure());
  }

  return response;
}

export function* deleteDisciplinaryRule({
  competitionId,
  ruleId,
}: DeletePayload): Generator<Effect, any, any> {
  yield put(actions.deleteDisciplinaryRuleRequest());
  const response = yield call(
    disciplinaryRulesAPI.deleteDisciplinaryRule,
    competitionId,
    ruleId,
  );

  if (!response.error) {
    yield put(actions.deleteDisciplinaryRuleSuccess());
  } else {
    yield put(actions.deleteDisciplinaryRuleFailure());
  }

  return response;
}
