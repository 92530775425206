import React from 'react';
import { Grid, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    minHeight: 'max-content',
    padding: theme.spacing(3),
    display: 'flex',
  },
}));

const ErrorMessage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Grid className={classes.root}>
      <Grid
        spacing={3}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography paragraph variant="h1">
            {t('Oops! Something went wrong.')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="justify" variant="body1">
            {t(
              'There might be a connectivity issue. Please check your internet connection.',
            )}{' '}
            {t('Try to')}{' '}
            <Link component="button" variant="body1" onClick={handleReload}>
              {t('reload')}
            </Link>{' '}
            {t('the page and retry the operation.')}{' '}
            {t('If the problem persists, please contact support.')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
