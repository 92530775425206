import {
  put,
  call,
  all,
  take,
  debounce,
  select,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';

import {
  formatPaginatedStoreWithIds,
  getURLSearchParams,
  handleApiErrors,
} from '@core/helpers';
import { getMatches } from '@core/store/modules/api/match/sagas';
import { authorizationParamsTuple } from '@core/store/helpers';

import {
  getFilters,
  getMatches as getMatchesPaginated,
  getSearchQuery,
  getSorting,
} from './selectors';
import { actions } from '.';

export function* searchMatchesFlow(
  action: InferAction<typeof actions.getMatches | typeof actions.searchByQuery>,
): Generator<Effect, any, any> {
  const {
    payload: { page },
  } = action;

  const urlSearchParams = getURLSearchParams(
    {
      paginated: (yield select(getMatchesPaginated)) as InferSelector<
        typeof getMatchesPaginated
      >,
      filters: (yield select(getFilters)) as InferSelector<typeof getFilters>,
      searchQuery: (yield select(getSearchQuery)) as InferSelector<
        typeof getSearchQuery
      >,
      sorting: (yield select(getSorting)) as InferSelector<typeof getSorting>,
    },
    { page, limit: undefined },
    { shouldAddTimeToSorting: true },
  );
  urlSearchParams.append(
    ...((yield call(authorizationParamsTuple)) as SagaReturnType<
      typeof authorizationParamsTuple
    >),
  );

  yield put(actions.getMatchesRequest());
  const { error, response } = (yield call(
    getMatches,
    urlSearchParams,
  )) as SagaReturnType<typeof getMatches>;

  if (!error) {
    yield put(
      actions.setMatches(
        formatPaginatedStoreWithIds('matches')(response) as any,
      ),
    );
    yield put(actions.getMatchesSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(actions.getMatchesFailure());
  }
}

export function* getMatchesFlow() {
  while (true) {
    const action = (yield take(actions.getMatches.toString())) as InferAction<
      typeof actions.getMatches
    >;

    yield call(searchMatchesFlow, action);
  }
}

export function* watchSearchMatchesByQuery() {
  yield debounce(500, actions.searchByQuery.toString(), searchMatchesFlow);
}

export default function* saga() {
  yield all([getMatchesFlow(), watchSearchMatchesByQuery()]);
}
