import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';

import { MatchModel } from '@core/types';
import { actions } from '.';

type DefaultState = {
  [id: string]: MatchModel;
};

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setAppointments.toString()]: (state, { payload }) => ({
      ...state,
      ...convertArrayToObject(payload),
    }),
    [actions.setAppointment.toString()]: (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  },
  defaultState,
);

export default reducer;
