import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { FilterTabProps, Sport } from '@core/types';
import sportsActions from '@core/store/modules/modals/sports-filter-tab/actions';
import { getSports as getSportsSelector } from '@core/store/modules/modals/sports-filter-tab/selectors';
import { getSports } from '@core/helpers';
import { FilterCheckboxList } from '@core/components';
import { SPORTS_FILTER, SPORTS_FILTER_ID } from '../constants';
import { useCoreDispatch } from '@core/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

type Props = FilterTabProps<{
  shifted?: boolean;
}>;

const SportsFilter = ({ filters, ...rest }: Props) => {
  const { t } = useTranslation();
  const dispatch = useCoreDispatch();
  const classes = useStyles();

  const sports = useSelector(getSportsSelector);
  const filterValues = filters[SPORTS_FILTER_ID]?.filterValues || [];

  const formatFilterLabel = (val: Sport) => getSports(t, val.type);

  const findSportTitle = (id: string) =>
    formatFilterLabel(sports.find((sport) => sport.id === Number(id)));

  useEffect(() => {
    dispatch(sportsActions.getSports());
  }, [dispatch]);

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Sport')}
      </Typography>
      <FilterCheckboxList
        isLoading={!sports.length}
        list={sports}
        filterProps={SPORTS_FILTER}
        filterValues={filterValues}
        formatFilterLabel={findSportTitle}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default SportsFilter;
