import { createAction } from 'redux-actions';

import * as ACTION_TYPES from './constants';

export const getGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const getGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const getGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_FAILURE,
);
export const updateGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const updateGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const updateGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_FAILURE,
);
export const createGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const createGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const createGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_FAILURE,
);
