import React from 'react';
import type { OnChangeValue } from 'react-select';
import { AutocompleteOption, Paginated, VenueModel } from '@core/types';
import { formatOptions } from '@core/helpers';
import { AutocompleteField } from '@core/components';

interface QueryValues {
  page: number;
  query: string;
}

interface OwnProps {
  options?: Paginated<VenueModel>;
  onInputChange: (query: string) => void;
  onChange?: (
    option: OnChangeValue<AutocompleteOption, boolean> | string | number,
  ) => void;
  onLoadNext: (values: QueryValues) => void;
  name: string;
  label: string;
  placeholder?: string;
  menuPosition?: 'fixed' | 'absolute';
  withTooltipMargin?: boolean;
}

type Props = OwnProps;

const VenueSearchField = (props: Props) => {
  const {
    options,
    onInputChange,
    onChange,
    name,
    label,
    onLoadNext,
    placeholder,
    menuPosition,
    withTooltipMargin = false,
  } = props;

  const getOptions = () =>
    options ? formatOptions(null, options.list, 'title') : [];

  const loadNext = (query: string) =>
    onLoadNext({
      page: options.page + 1,
      query,
    });

  const hasMore = options?.pages > options?.page;

  return (
    <AutocompleteField
      name={name}
      textFieldProps={{ label, placeholder }}
      options={getOptions()}
      onInputChange={onInputChange}
      onChange={onChange}
      pagination={{ hasMore, loadNext }}
      menuPosition={menuPosition}
      useOptionAsValue
      withTooltipMargin={withTooltipMargin}
    />
  );
};

export default VenueSearchField;
