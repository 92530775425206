import React from 'react';
import { Typography, Grid, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Lock } from '@mui/icons-material';

interface Props {
  title?: string;
  options?: Array<{ label: string; value: any }>;
  editActionDisabled?: boolean;
  editAction?: {
    label: string;
    onClick: () => void;
    icon: any;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  mainTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  title: {
    marginRight: theme.spacing(1),
    width: '50%',
    textAlign: 'right',
  },
  value: {
    width: '50%',
    marginLeft: theme.spacing(1),
    textAlign: 'left',
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
}));

const CenteredContent = ({
  title,
  options,
  editAction,
  editActionDisabled,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        {title && (
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.mainTitle}
          >
            {title}
          </Typography>
        )}
      </Grid>
      {editAction && (
        <Grid container item xs={12} sm={6} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            disabled={editActionDisabled}
            onClick={editAction.onClick}
            startIcon={!editActionDisabled ? editAction.icon : <Lock />}
            className={classes.button}
          >
            {editAction.label}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        {options?.map((option, key) => (
          <Grid className={classes.row} key={key}>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.title}
            >
              {option.label}:
            </Typography>
            <Grid className={classes.value}>{option.value}</Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CenteredContent;
