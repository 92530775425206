import { all, take, call } from 'redux-saga/effects';
import { setLanguage } from '@core/helpers';
import i18n from '@core/i18n';

import { CHANGE_LANGUAGE } from './constants';

export function* changeLanguageFlow() {
  while (true) {
    const { payload } = yield take(CHANGE_LANGUAGE);

    yield call(setLanguage, payload);
    yield call([i18n, 'changeLanguage'], payload);
  }
}

export default function* saga() {
  yield all([changeLanguageFlow()]);
}
