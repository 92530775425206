import restService from '@core/api/rest-service';
import {
  GetCompetitionManagersResponse,
  CompetitionManager,
} from '@core/types';

export const getCompetitionManagers = (query = '') =>
  restService.get<GetCompetitionManagersResponse>(`/v1/competitionManagers`, {
    params: { query },
  });

export const getCurrentCompetitionManager = () =>
  restService.get<CompetitionManager>('/v1/competitionManagers/current');
