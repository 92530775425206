import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Logo, TextField } from '@core/components';
import { Club } from '@core/icons';
import { LogoSize, TeamModel } from '@core/types';

import DefaultVenueSearch from './default-venue-search';
import AlternativeVenueSearch from './alternate-venue-search';
import { COMPETITOR_FIELDS } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  formText: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  formItemSpacing: {
    margin: theme.spacing(5, 0),
  },
  teamDetailsContainer: {
    height: theme.spacing(4.5),
  },
}));

interface OwnProps {
  competitionId: number;
  selectedTeam: TeamModel;
}

type Props = OwnProps;

const GeneralInformationForm = (props: Props) => {
  const { t } = useTranslation();
  const { competitionId, selectedTeam } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Grid item xs={12}>
        <Divider orientation="horizontal" />
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Box mt={2}>
          <Typography variant="caption" color="textSecondary">
            {`* ${t('Indicates a required field')}`}
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        spacing={3}
        className={classes.formItemSpacing}
        style={{ marginTop: 24 }}
      >
        <Grid item xs={2} md={2}>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.formText}
          >
            {t('Team details')}
          </Typography>
        </Grid>
        <Grid item xs={10} md={10}>
          <Grid container className={classes.teamDetailsContainer}>
            <Grid item>
              <Logo
                size={LogoSize.SMALL}
                src={selectedTeam?.club?.logoFileLink}
              >
                <Club />
              </Logo>
            </Grid>
            <Grid item ml={1.5}>
              <Typography variant="caption" color="textSecondary">
                {t('Club')}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }} variant="body2">
                {selectedTeam?.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider orientation="horizontal" />
      </Grid>
      <Grid container item spacing={3} className={classes.formItemSpacing}>
        <Grid item xs={2} md={2}>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.formText}
          >
            {t('Team names')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name={COMPETITOR_FIELDS.title}
            data-qa={COMPETITOR_FIELDS.title}
            withTooltipMargin={false}
            required
            label={t('Team name')}
            tooltipText={t(
              'The name of a team. Used to find a team while assigning a team to a competition',
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name={COMPETITOR_FIELDS.internationalTitle}
            data-qa={COMPETITOR_FIELDS.internationalTitle}
            withTooltipMargin
            label={t('International team name')}
            tooltipText={t('Name of a team in Latin characters')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider orientation="horizontal" />
      </Grid>
      <Grid container item spacing={3} className={classes.formItemSpacing}>
        <Grid item xs={2} md={2}>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.formText}
          >
            {t('Team venues')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <DefaultVenueSearch competitionId={competitionId} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AlternativeVenueSearch competitionId={competitionId} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralInformationForm;
