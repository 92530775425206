import type { CreateContactData } from '@core/types';

export interface ContactFormValues {
  firstName: string;
  familyName: string;
  title: string;
  email: string;
  phone: string;
}

export const getInitialValues = (
  contact: CreateContactData,
): ContactFormValues => ({
  firstName: contact?.firstName || '',
  familyName: contact?.familyName || '',
  title: contact?.title || '',
  email: contact?.email || '',
  phone: contact?.phone || '',
});

export default getInitialValues;
