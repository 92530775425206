import { createActions } from 'redux-actions';
import { Group, Groups, GroupSettingsUpdate } from '@core/types';

import { NAMESPACE } from './constants';

export const actions = createActions(
  'GET_GROUPS',
  'GET_GROUPS_REQUEST',
  'GET_GROUPS_SUCCESS',
  'GET_GROUPS_FAILURE',
  'SET_GROUPS',

  'GET_GROUP',
  'GET_GROUP_REQUEST',
  'GET_GROUP_SUCCESS',
  'GET_GROUP_FAILURE',

  'CREATE_GROUP',
  'CREATE_GROUP_REQUEST',
  'CREATE_GROUP_SUCCESS',
  'CREATE_GROUP_FAILURE',

  'UPDATE_GROUP',
  'UPDATE_GROUP_REQUEST',
  'UPDATE_GROUP_SUCCESS',
  'UPDATE_GROUP_FAILURE',

  'UPDATE_GROUP_SETTINGS',
  'UPDATE_GROUP_SETTINGS_REQUEST',
  'UPDATE_GROUP_SETTINGS_SUCCESS',
  'UPDATE_GROUP_SETTINGS_FAILURE',

  'SET_GROUP_BEING_MODIFIED',
  'SET_ACTIVE_STEP',

  { prefix: NAMESPACE },
) as unknown as {
  // TODO: JB: getGroups is never dispatched anywhere, persisted on pages/createStage
  getGroups: UnknownAction;
  getGroupsRequest: RequestLifecycleAction;
  getGroupsSuccess: RequestLifecycleAction;
  getGroupsFailure: RequestLifecycleAction;
  setGroups: IdentityActionFunction<Groups>;

  // TODO: JB: getGroup is never dispatched anywhere, nor persisted anywhere
  getGroup: IdentityActionFunction<{ groupId: number }>;
  getGroupRequest: RequestLifecycleAction;
  getGroupSuccess: RequestLifecycleAction;
  getGroupFailure: RequestLifecycleAction;

  createGroup: IdentityActionFunction<{
    competitionId: number;
    data: Partial<Group>;
    tabId: string;
  }>;
  createGroupRequest: RequestLifecycleAction;
  createGroupSuccess: RequestLifecycleAction;
  createGroupFailure: RequestLifecycleAction;

  updateGroup: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    data: unknown;
  }>;
  updateGroupRequest: RequestLifecycleAction;
  updateGroupSuccess: RequestLifecycleAction;
  updateGroupFailure: RequestLifecycleAction;

  updateGroupSettings: IdentityActionFunction<{
    competitionId: number;
    groupId: number;
    groupSettings: GroupSettingsUpdate;
    tabId: string;
  }>;
  updateGroupSettingsRequest: RequestLifecycleAction;
  updateGroupSettingsSuccess: RequestLifecycleAction;
  updateGroupSettingsFailure: RequestLifecycleAction;

  setGroupBeingModified: IdentityActionFunction<Group>;
  setActiveStep: IdentityActionFunction<number>;
};
