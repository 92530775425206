import { useTranslation } from 'react-i18next';
import { Alert, Theme, Box } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { FullScreenSpinner } from '@core/components';
import { AgreementsModel, TermsResponse } from './terms-and-conditions';
import { mapLocaleToTermsLang } from './helpers';

type Props = {
  lang: keyof AgreementsModel;
  terms: TermsResponse;
  hasChanged?: boolean;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  info: {
    marginTop: theme.spacing(2),
  },
  text: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}`,
    maxWidth: theme.spacing(120),
    margin: '0 auto',
    '& table': {
      borderCollapse: 'collapse',
      [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    '& table thead': {
      display: 'none',
    },
    '& table td, & table th': {
      // backgroundColor: theme.palette.grey[100],
      verticalAlign: 'top',
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    '& table td:first-child, & table th:first-child': {
      minWidth: theme.spacing(25),
      backgroundColor: theme.palette.grey[100],
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    '& table td:last-child, & table th:last-child': {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(4),
        borderTop: 'none',
        borderBottom: 'none',
      },
    },
    '& h1': {
      fontSize: theme.typography.h4.fontSize,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    '& h2, & h3, & h4, & h5, & h6': {
      fontSize: theme.typography.h5.fontSize,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    '& > p, & > ul, & > ol': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    '& > ul > li, & > ol > li': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      '& table, & tbody, & tr, & th, & td': {
        display: 'block',
      },
    },
  },
}));

const TermsAndConditionsBody = ({ terms, lang, hasChanged }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!terms) {
    return <FullScreenSpinner />;
  }

  const termsText = terms?.agreements?.[mapLocaleToTermsLang(lang)];

  return (
    <Box className={classes.root}>
      {hasChanged && (
        <Alert className={classes.info} icon={<InfoOutlined />} severity="info">
          {t('Service Description and Terms of Use have changed')}.{' '}
          {t('Please read and accept new version')}.
        </Alert>
      )}
      <Box className={classes.text}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[gfm]}>
          {termsText}
        </ReactMarkdown>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsBody;
