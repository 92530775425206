import type { ComponentType } from 'react';
import { pathOr } from 'ramda';
import { MainLayout } from '../main-layout';
import type { LayoutProps } from '@core/types';

export const withLayout =
  <P extends LayoutProps>(Component: ComponentType<P>) =>
  (props: P) => {
    const paramsValues = pathOr(null, ['match', 'params'], props);

    return (
      <MainLayout
        tabMapping={props.tabMapping}
        mainMenuMapping={props.mainMenuMapping}
        body={<Component {...props} {...paramsValues} />}
      />
    );
  };
