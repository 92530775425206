import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GeniusSportsLogo } from '@core/icons';
import { FooterMenu } from './components/footer-menu';
import { Layout } from './components/layout';

const useStyles = makeStyles((theme: Theme) => ({
  logoText: {
    color: theme.palette.grey[600],
    fontSize: '.7rem',
    fontWeight: 600,
  },
  icon: {
    fill: theme.palette.grey[600],
    width: 115,
  },
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Layout
      copyright={`© ${currentYear} Genius Sports. ${t('All rights reserved')}.`}
      links={<FooterMenu />}
      icon={
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Typography className={classes.logoText} variant="body2">
              {t('BY')}
            </Typography>
          </Grid>
          <Grid item>
            <GeniusSportsLogo className={classes.icon} fontSize="large" />
          </Grid>
        </Grid>
      }
    />
  );
};

export default Footer;
