import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';

import * as uiActions from '@core/store/modules/ui/menu/actions';

import * as testIds from '../tests/test-ids';

interface DispatchProps {
  actions: {
    ui: typeof uiActions;
  };
}

type Props = DispatchProps;

export const MenuIconComponent = ({ actions }: Props) => {
  const handleClick = (_: MouseEvent) => {
    actions.ui.toggleMenu();
  };

  return (
    <IconButton
      onClick={handleClick}
      edge="start"
      color="inherit"
      aria-label="menu"
      data-qa={testIds.BURGER_MENU_ICON}
    >
      <Menu />
    </IconButton>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    ui: bindActionCreators(uiActions, dispatch),
  },
});

export const MenuIconContainer = connect(
  null,
  mapDispatchToProps,
)(MenuIconComponent);
