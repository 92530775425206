export type InjectSaga = (path: string, saga: Generator) => Promise<unknown>;

export function createSagaInjector(runSaga: any, rootSaga: any) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map<string, unknown>();

  const isInjected = (key: string) => injectedSagas.has(key);

  const injectSaga: InjectSaga = (path, saga) => {
    return new Promise<unknown>((resolve) => {
      if (isInjected(path)) {
        // by rejecting app would crash unnecessarily if the right saga is already injected
        // but wrong injected saga will not execute logic from the right saga
        if (import.meta.env.NODE_ENV !== 'production') {
          console.warn(`Saga at ${path} is already injected.`);
        }
        resolve(false);

        return;
      }

      // Sagas return task when they executed, which can be used to cancel them
      const task = runSaga(saga);

      // Save the task if we want to cancel it in the future
      injectedSagas.set(path, task);
      resolve(task);
    });
  };

  // Inject the root saga as it a statically loaded file
  injectSaga('root', rootSaga);

  return injectSaga;
}
