import restService from '@core/api/rest-service';
import {
  MatchCompetitorsResponse,
  PatchMatchCompetitorsPayload,
} from '@core/types';

export const getMatchCompetitors = (matchId: number) =>
  restService.get<MatchCompetitorsResponse>(
    `/v1/matches/${matchId}/competitors`,
  );

export const patchMatchCompetitor = (
  matchId: number,
  competitorId: number,
  paylaod: PatchMatchCompetitorsPayload,
) =>
  restService.patch<void>(
    `/v1/matches/${matchId}/competitors/${competitorId}`,
    paylaod,
  );
