import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { Dispatch, bindActionCreators } from 'redux';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { getInitialErrors, formatOption } from '@core/helpers';
import { MAX_FIELD_LENGTH } from '@core/constants';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  COMPETITOR_GENERAL_INFO_FIELDS,
  Competitor,
  APIErrorsModel,
} from '@core/types';
import { actions as competitorGeneralInformationEditActions } from '@core/store/modules/tabs/competitor-general-information-edit';
import {
  getCompetitor,
  getSubmitErrors,
} from '@core/store/modules/tabs/competitor-general-information-edit/selectors';
import { State } from '@core/store';
import GeneralInformationForm from './components/general-information-form';

interface OwnProps {
  competitionId: number;
  teamId: number;
  competitorId: number;
  tabId: string;
}

interface DispatchProps {
  actions: {
    competitor: typeof competitorGeneralInformationEditActions;
  };
}

interface StateProps {
  competitor: Competitor;
  isLoading: boolean;
  errors: APIErrorsModel;
}

type Props = OwnProps & DispatchProps & StateProps;

const CompetitorGeneralInformationEdit = (props: Props) => {
  const {
    actions,
    competitionId,
    teamId,
    competitorId,
    competitor,
    tabId,
    errors,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    actions.competitor.getCompetitor({ competitionId, competitorId, teamId });

    return () => {
      actions.competitor.resetCompetitor({ competitorId });
    };
  }, []);

  function handleSubmit(values: Partial<Competitor>) {
    actions.competitor.updateCompetitor({
      competitionId,
      teamId,
      data: values,
      tabId,
      competitorId,
    });
  }

  function getInitialValues() {
    return {
      [COMPETITOR_GENERAL_INFO_FIELDS.teamTitle]:
        competitor?.[COMPETITOR_GENERAL_INFO_FIELDS.teamTitle] ?? '',
      [COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle]:
        competitor?.[COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle] ??
        '',
      [COMPETITOR_GENERAL_INFO_FIELDS.defaultVenueOption]: formatOption(
        null,
        competitor?.defaultVenue,
      ),
      [COMPETITOR_GENERAL_INFO_FIELDS.alternativeVenueOption]: formatOption(
        null,
        competitor?.alternativeVenue,
      ),
    };
  }

  const validationSchema = object().shape({
    [COMPETITOR_GENERAL_INFO_FIELDS.teamTitle]: string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    [COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle]: string().max(
      MAX_FIELD_LENGTH,
      t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
    ),
  });

  function renderForm(formikProps: FormikProps<Partial<Competitor>>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTabStateSetter(formikProps.dirty);

    return <GeneralInformationForm tabId={tabId} competitorId={competitorId} />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialErrors={getInitialErrors(errors)}
    >
      {renderForm}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitor: bindActionCreators(
      competitorGeneralInformationEditActions,
      dispatch,
    ),
  },
});

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    competitorGeneralInformationEditActions.getCompetitorRequest({
      competitionId: ownProps.competitionId,
      competitorId: ownProps.competitorId,
      teamId: ownProps.teamId,
    }),
    competitorGeneralInformationEditActions.searchAlternativeVenuesRequest.toString(),
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const competitorId = ownProps?.competitorId;

  return {
    competitor: getCompetitor(state, { competitorId }),
    isLoading: isLoadingSelector(ownProps)(state),
    errors: getSubmitErrors(state, { competitorId }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitorGeneralInformationEdit);
