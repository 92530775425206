import React from 'react';
import { makeStyles } from '@mui/styles';

import { KitPlayerType, KitSet, KitType, PlayerKitsModel } from '@core/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 36,
    height: 36,
    border: 0,
    borderRadius: '50%',
    filter:
      'drop-shadow(0px 0.3px 0.5px rgba(28, 28, 30, 0.1)) drop-shadow(0px 2px 4px rgba(28, 28, 30, 0.2))',
  },
}));

interface OwnProps {
  disabled?: boolean;
  colors: [string, string];
}

const PlayerKitPreview = (props: OwnProps) => {
  const {
    colors: [primaryColor, secondaryColor],
    disabled,
  } = props;

  const classes = useStyles();

  const disabledColor = '#AEAEB2';

  const background: React.CSSProperties['background'] =
    primaryColor && secondaryColor
      ? `linear-gradient(180deg, ${primaryColor} 0%, ${primaryColor} 50%, ${secondaryColor} 50.01%, ${secondaryColor} 100%)`
      : primaryColor || secondaryColor || disabledColor;

  return (
    <div
      className={classes.root}
      style={{ background: disabled ? disabledColor : background }}
    />
  );
};

export default PlayerKitPreview;

export function makePlayerKitPreviewProps(
  teamColor: PlayerKitsModel,
  kitPlayerType: KitPlayerType,
): OwnProps;
export function makePlayerKitPreviewProps(
  kitSet: KitSet,
  kitType: KitType,
  kitPlayerType: KitPlayerType,
): OwnProps;
export function makePlayerKitPreviewProps(
  ...args: [
    teamColorOrKitSet: PlayerKitsModel | KitSet,
    kitPlayerTypeOrKitType: KitPlayerType | KitType,
    kitPlayerType?: KitPlayerType,
  ]
): OwnProps {
  if (args.length === 2) {
    const [teamColor, kitPlayerType] = args as [
      teamColor: PlayerKitsModel,
      kitPlayerType: KitPlayerType,
    ];

    return makePlayerKitPreviewProps(
      {
        id: null,
        title: null,
        homePlayerKit: teamColor,
        awayPlayerKit: null,
        reservedPlayerKit: null,
      },
      'home',
      kitPlayerType,
    );
  } else if (args.length === 3) {
    const [kitSet, kitType, kitPlayerType] = args as [
      kitSet: KitSet,
      kitType: KitType,
      kitPlayerType: KitPlayerType,
    ];

    const playerTypeKey =
      kitPlayerType === 'regular'
        ? (['primaryColor', 'secondaryColor'] as const)
        : ([
            'specialPlayerPrimaryColor',
            'specialPlayerSecondaryColor',
          ] as const);
    const kitTypeKey =
      kitType === 'home'
        ? 'homePlayerKit'
        : kitType === 'away'
          ? 'awayPlayerKit'
          : kitType === 'reserved'
            ? 'reservedPlayerKit'
            : undefined;

    return {
      colors: [
        kitSet[kitTypeKey]?.[playerTypeKey[0]],
        kitSet[kitTypeKey]?.[playerTypeKey[1]],
      ],
    };
  }

  return null;
}
