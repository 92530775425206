import React, { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Box,
  Theme,
} from '@mui/material';
import { RadioProps } from '@mui/material/Radio/Radio';
import { RadioButtonChecked } from '@mui/icons-material';
import { Field, FieldProps } from 'formik';
import clsx from 'clsx';

import { isValue } from '@core/helpers';

interface StyleProps {
  fullWidth?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  underlined: {
    borderBottom: `3px solid ${theme.palette.primary.light}`,
  },
  rowPadding: {
    paddingRight: theme.spacing(2),
  },
  box: {
    marginTop: theme.spacing(-1),
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
  },
}));

type RadioValue = boolean | string | number;

interface RadioOption {
  label: string;
  value: RadioValue;
  disabled?: boolean;
  additionalContentIfChecked?: any;
}

interface OwnProps {
  name: string;
  options: Array<RadioOption>;
  label?: string;
  row?: boolean;
  required?: boolean;
  underlined?: boolean;
  icon?: JSX.Element;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
}

type Props = OwnProps;

const RadioField = ({
  name,
  label,
  options,
  row,
  icon,
  underlined,
  required,
  onChange,
  fullWidth,
}: Props) => {
  const classes = useStyles({ fullWidth });
  const CustomRadio = (props: RadioProps) => (
    <Radio {...props} checkedIcon={icon ? icon : <RadioButtonChecked />} />
  );

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          onChange && onChange(event);

          if (options.length && typeof options[0].value === 'boolean') {
            form.setFieldValue(name, newValue === 'true', true);
          } else {
            form.setFieldValue(name, newValue, true);
          }
        };

        return (
          <FormControl
            component="fieldset"
            required={required}
            className={classes.box}
          >
            {label && (
              <FormLabel focused={false} component="legend">
                {label}
              </FormLabel>
            )}
            <RadioGroup
              row={row}
              name={field.name}
              value={isValue(field.value) ? field.value : ''}
              onChange={handleChange}
              onBlur={field.onBlur}
            >
              {options.map((option: RadioOption) => {
                return (
                  <React.Fragment key={option.label}>
                    <FormControlLabel
                      className={clsx({
                        [classes.underlined]:
                          underlined && field.value === option.value,
                        [classes.rowPadding]: row,
                      })}
                      key={option.value + option.label}
                      disabled={option.disabled}
                      value={option.value}
                      control={<CustomRadio />}
                      label={option.label}
                    />
                    {isValue(field.value) && field.value === option.value && (
                      <Box pl={3.6}>{option.additionalContentIfChecked}</Box>
                    )}
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default RadioField;
