export default <S, T extends { [x: string]: string }>(
  prefix: S,
  object: T,
): T =>
  Object.fromEntries(
    Object.entries(object).map<Array<string>>(([key, val]) => [
      key,
      `${prefix}${val}`,
    ]),
  );
