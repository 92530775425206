import React from 'react';
import { Grid, Box } from '@mui/material';

interface OwnProps {
  textEntries: Array<any>;
}

type Props = OwnProps;

const ConnectedText = (props: Props) => {
  const { textEntries } = props;

  return (
    <Box display="flex">
      {textEntries.map((textEntry: any, index: number) => {
        if (typeof textEntry === 'object' && textEntry?.boldText) {
          return (
            <Grid key={index} style={{ whiteSpace: 'pre', fontWeight: 'bold' }}>
              {textEntry.value}
            </Grid>
          );
        }

        return (
          <Grid key={index} style={{ whiteSpace: 'pre' }}>
            {textEntry}
          </Grid>
        );
      })}
    </Box>
  );
};

export default ConnectedText;
