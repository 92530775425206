import restService from '@core/api/rest-service';
import {
  Group,
  CreateGroupResponse,
  GroupResponse,
  GroupsResponse,
  GroupCompetitorsPayload,
  GroupCompetitorsResponse,
  GroupSettingsUpdate,
} from '@core/types';

export const getGroups = (competitionId: number) =>
  restService.get<GroupsResponse>(`/v1/competitions/${competitionId}/groups`);

export const getGroup = ({ groupId }: { groupId: number }) =>
  restService.get<GroupResponse>(`/v1/groups/${groupId}`);

export const createGroup = (competitionId: number, data: Partial<Group>) =>
  restService.post<CreateGroupResponse>(
    `/v1/competitions/${competitionId}/groups`,
    data,
  );

export const updateGroup = (groupId: number, data: Partial<Group>) =>
  restService.patch<void>(`/v1/groups/${groupId}`, data);

export const deleteGroup = (groupId: number) =>
  restService.delete<void>(`/v1/groups/${groupId}`);

export const updateGroupSettings = (
  groupId: number,
  groupSettings: GroupSettingsUpdate,
) => restService.put<void>(`/v1/groups/${groupId}/settings`, groupSettings);

export const getGroupCompetitors = (groupId: number) =>
  restService.get<GroupCompetitorsResponse>(
    `/v1/groups/${groupId}/groupCompetitors`,
  );

export const updateGroupCompetitors = (
  groupId: number,
  groupCompetitors: GroupCompetitorsPayload,
) =>
  restService.post<void>(
    `/v1/groups/${groupId}/groupCompetitors/changeList`,
    groupCompetitors,
  );
