import { useSelector } from 'react-redux';
import { CustomerResponse } from '@core/types/api';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@core/constants/common';

import { getCurrentCustomer } from '../store/selectors';

export function useCustomerFormat() {
  const customer: CustomerResponse =
    useSelector(getCurrentCustomer) ||
    ({} as ReturnType<typeof getCurrentCustomer>);

  return {
    dateFormat: customer?.dateFormat || DEFAULT_DATE_FORMAT,
    timeFormat: customer?.timeFormat || DEFAULT_TIME_FORMAT,
    dateTimeFormat: customer?.dateTimeFormat || DEFAULT_DATE_TIME_FORMAT,
  };
}
