import { createAction } from 'redux-actions';

import { ExpansionPanelGroupId } from '@core/types';
import { GroupFixtureSettings } from '@volleyball/types';

import * as ACTION_TYPES from './constants';

export const addGroupFixtureSettings = createAction<GroupFixtureSettings>(
  ACTION_TYPES.ADD_GROUP_FIXTURE_SETTINGS,
);

export const clearGroupFixtureSettings = createAction<
  GroupFixtureSettings['id']
>(ACTION_TYPES.CLEAR_GROUP_FIXTURE_SETTINGS);

export const setErrors = createAction(ACTION_TYPES.SET_ERRORS);

export const getGroupFixtureSettings = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS,
);
export const getGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const getGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const getGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.GET_GROUP_FIXTURE_SETTINGS_FAILURE,
);

export const createGroupFixtureSettings = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS,
);
export const createGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const createGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const createGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.CREATE_GROUP_FIXTURE_SETTINGS_FAILURE,
);

export const updateGroupFixtureSettings = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS,
);
export const updateGroupFixtureSettingsRequest = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_REQUEST,
);
export const updateGroupFixtureSettingsSuccess = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_SUCCESS,
);
export const updateGroupFixtureSettingsFailure = createAction(
  ACTION_TYPES.UPDATE_GROUP_FIXTURE_SETTINGS_FAILURE,
);

export const createDraftCompetitionGroupFixtureSettings = createAction<{
  groupFixtureSettingsData: {
    groupId: number;
    competitionId: number;
    data: Partial<Omit<GroupFixtureSettings, 'id'>>;
  };
  panelData: { groupId: ExpansionPanelGroupId; panelIndex: number };
}>(ACTION_TYPES.CREATE_DRAFT_COMPETITION_GROUP_FIXTURE_SETTINGS);

export const updateDraftCompetitionGroupFixtureSettings = createAction<{
  groupFixtureSettingsData: {
    groupId: number;
    competitionId: number;
    data: Partial<Omit<GroupFixtureSettings, 'id'>>;
  };
  panelData: { groupId: ExpansionPanelGroupId; panelIndex: number };
}>(ACTION_TYPES.UPDATE_DRAFT_COMPETITION_GROUP_FIXTURE_SETTINGS);
