import { Action, createActions } from 'redux-actions';
import { Competitor, CompetitorsList } from '@core/types';

export const NAMESPACE = 'competitorsList';

export const actions = createActions(
  'GET_COMPETITORS_REQUEST',
  'GET_COMPETITORS_SUCCESS',
  'GET_COMPETITORS_FAILURE',

  'GET_COMPETITORS_BY_COMPETITION_REQUEST',
  'GET_COMPETITORS_BY_COMPETITION_SUCCESS',
  'GET_COMPETITORS_BY_COMPETITION_FAILURE',

  'GET_COMPETITOR_BY_TEAM_REQUEST',
  'GET_COMPETITOR_BY_TEAM_SUCCESS',
  'GET_COMPETITOR_BY_TEAM_FAILURE',

  'GET_COMPETITOR_PLACEHOLDER_REQUEST',
  'GET_COMPETITOR_PLACEHOLDER_SUCCESS',
  'GET_COMPETITOR_PLACEHOLDER_FAILURE',

  'CREATE_COMPETITOR_REQUEST',
  'CREATE_COMPETITOR_SUCCESS',
  'CREATE_COMPETITOR_FAILURE',

  'DELETE_COMPETITOR_REQUEST',
  'DELETE_COMPETITOR_SUCCESS',
  'DELETE_COMPETITOR_FAILURE',

  'UPDATE_COMPETITOR_FAILURE',
  'UPDATE_COMPETITOR_REQUEST',
  'UPDATE_COMPETITOR_SUCCESS',

  'SET_COMPETITORS',
  'SET_COMPETITOR',

  { prefix: NAMESPACE },
) as unknown as {
  /** @deprecated no usages found */
  getCompetitorsRequest: () => Action<any>;
  /** @deprecated no usages found */
  getCompetitorsSuccess: () => Action<any>;
  /** @deprecated no usages found */
  getCompetitorsFailure: () => Action<any>;

  getCompetitorsByCompetitionRequest: RequestLifecycleAction;
  getCompetitorsByCompetitionSuccess: RequestLifecycleAction;
  getCompetitorsByCompetitionFailure: RequestLifecycleAction;

  getCompetitorByTeamRequest: RequestLifecycleAction;
  getCompetitorByTeamSuccess: RequestLifecycleAction;
  getCompetitorByTeamFailure: RequestLifecycleAction;

  getCompetitorPlaceholderRequest: RequestLifecycleAction;
  getCompetitorPlaceholderSuccess: RequestLifecycleAction;
  getCompetitorPlaceholderFailure: RequestLifecycleAction;

  createCompetitorRequest: RequestLifecycleAction;
  createCompetitorSuccess: RequestLifecycleAction;
  createCompetitorFailure: RequestLifecycleAction;

  deleteCompetitorRequest: RequestLifecycleAction;
  deleteCompetitorSuccess: RequestLifecycleAction;
  deleteCompetitorFailure: RequestLifecycleAction;

  updateCompetitorRequest: RequestLifecycleAction;
  updateCompetitorSuccess: RequestLifecycleAction;
  updateCompetitorFailure: RequestLifecycleAction;

  setCompetitors: (competitors: CompetitorsList) => Action<CompetitorsList>;
  setCompetitor: (competitor: Competitor) => Action<Competitor>;
};
