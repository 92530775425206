import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import type { LoadingIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import type { AutocompleteOption } from '@core/types';
import * as testIds from '../tests/test-ids';

type Props = LoadingIndicatorProps<AutocompleteOption, boolean>;

const LoadingIndicator = ({ innerProps }: Props) => (
  <CircularProgress
    data-qa={testIds.AUTOCOMPLETE_SPINNER}
    size={24}
    {...(innerProps as CircularProgressProps)}
  />
);

export default LoadingIndicator;
