import { createAction } from 'redux-actions';

import {
  ExpansionPanelGroupId,
  Competition,
  CreateRolloverCompetitionPayload,
  QueryParams,
  Sorting,
  CompetitionStatus,
} from '@core/types';

import * as COMPETITIONS_ACTION_TYPES from './constants';

export const setCompetitionBeingModified = createAction(
  COMPETITIONS_ACTION_TYPES.SET_COMPETITION_BEING_MODIFIED,
);
export const setErrors = createAction(COMPETITIONS_ACTION_TYPES.SET_ERRORS);

export const updateCompetition = createAction<{
  id: Competition['id'];
  data: Partial<Competition>;
}>(COMPETITIONS_ACTION_TYPES.UPDATE_COMPETITION);

export const updateDraftCompetition = createAction<{
  competitionData: {
    id: Competition['id'];
    data: Partial<Competition>;
  };
  panelData: { groupId: ExpansionPanelGroupId; panelIndex: number };
}>(COMPETITIONS_ACTION_TYPES.UPDATE_DRAFT_COMPETITION);

export const updateCompetitionRequest = createAction(
  COMPETITIONS_ACTION_TYPES.UPDATE_COMPETITION_REQUEST,
);
export const updateCompetitionSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.UPDATE_COMPETITION_SUCCESS,
);
export const updateCompetitionFailure = createAction(
  COMPETITIONS_ACTION_TYPES.UPDATE_COMPETITION_FAILURE,
);

export const createCompetition = createAction<{
  competitionData: Omit<
    Competition,
    | 'id'
    | '_links'
    | '_groups'
    | 'competitionSettings'
    | 'sportTitle'
    | 'competitorsCount'
    | 'hasOngoingMatches'
  >;
  panelData: { groupId?: ExpansionPanelGroupId; panelIndex?: number };
  tabId?: string;
}>(COMPETITIONS_ACTION_TYPES.CREATE_COMPETITION);
export const createCompetitionRequest = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_COMPETITION_REQUEST,
);
export const createCompetitionSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_COMPETITION_SUCCESS,
);
export const createCompetitionFailure = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_COMPETITION_FAILURE,
);

export const createRolloverCompetition = createAction<{
  competitionId: number;
  data: CreateRolloverCompetitionPayload;
}>(COMPETITIONS_ACTION_TYPES.CREATE_ROLLOVER_COMPETITION);
export const createRolloverCompetitionRequest = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_ROLLOVER_COMPETITION_REQUEST,
);
export const createRolloverCompetitionSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_ROLLOVER_COMPETITION_SUCCESS,
);
export const createRolloverCompetitionFailure = createAction(
  COMPETITIONS_ACTION_TYPES.CREATE_ROLLOVER_COMPETITION_FAILURE,
);

export const getCompetition = createAction<number>(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITION,
);
export const getCompetitionRequest = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITION_REQUEST,
);
export const getCompetitionSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITION_SUCCESS,
);
export const getCompetitionFailure = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITION_FAILURE,
);

export const getCompetitions = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITIONS,
);
export const getCompetitionsRequest = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITIONS_REQUEST,
);
export const getCompetitionsSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITIONS_SUCCESS,
);
export const getCompetitionsFailure = createAction(
  COMPETITIONS_ACTION_TYPES.GET_COMPETITIONS_FAILURE,
);
export const setCompetitions = createAction(
  COMPETITIONS_ACTION_TYPES.SET_COMPETITIONS,
);
export const setCompetitionsHalLinks = createAction(
  COMPETITIONS_ACTION_TYPES.SET_COMPETITIONS_HAL_LINKS,
);

export const getRolloverCompetitions = createAction<{
  queryParams?: QueryParams;
}>(COMPETITIONS_ACTION_TYPES.GET_ROLLOVER_COMPETITIONS);
export const getRolloverCompetitionsRequest = createAction(
  COMPETITIONS_ACTION_TYPES.GET_ROLLOVER_COMPETITIONS_REQUEST,
);
export const getRolloverCompetitionsSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.GET_ROLLOVER_COMPETITIONS_SUCCESS,
);
export const getRolloverCompetitionsFailure = createAction(
  COMPETITIONS_ACTION_TYPES.GET_ROLLOVER_COMPETITIONS_FAILURE,
);
export const setRolloverCompetitions = createAction(
  COMPETITIONS_ACTION_TYPES.SET_ROLLOVER_COMPETITIONS,
);

export const changeCompetitionStatus = createAction<{
  competitionId: number;
  status: CompetitionStatus;
}>(COMPETITIONS_ACTION_TYPES.CHANGE_COMPETITION_STATUS);
export const changeCompetitionStatusRequest = createAction(
  COMPETITIONS_ACTION_TYPES.CHANGE_COMPETITION_STATUS_REQUEST,
);
export const changeCompetitionStatusSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.CHANGE_COMPETITION_STATUS_SUCCESS,
);
export const changeCompetitionStatusFailure = createAction(
  COMPETITIONS_ACTION_TYPES.CHANGE_COMPETITION_STATUS_FAILURE,
);

export const deleteCompetition = createAction<{
  competitionId: Competition['id'];
  tabId: string;
}>(COMPETITIONS_ACTION_TYPES.DELETE_COMPETITION);
export const deleteCompetitionRequest = createAction(
  COMPETITIONS_ACTION_TYPES.DELETE_COMPETITION_REQUEST,
);
export const deleteCompetitionSuccess = createAction(
  COMPETITIONS_ACTION_TYPES.DELETE_COMPETITION_SUCCESS,
);
export const deleteCompetitionFailure = createAction(
  COMPETITIONS_ACTION_TYPES.DELETE_COMPETITION_FAILURE,
);

export const getDraftCompetition = createAction<number>(
  COMPETITIONS_ACTION_TYPES.GET_DRAFT_COMPETITION,
);

interface SubmitCompetition {
  competitionId: number;
  tabId: string;
  competitionProfileTab: any;
}

export const submitCompetition = createAction<SubmitCompetition>(
  COMPETITIONS_ACTION_TYPES.SUBMIT_COMPETITION,
);
export const setSorting = createAction<Sorting>(
  COMPETITIONS_ACTION_TYPES.SET_SORTING_ORDER,
);
