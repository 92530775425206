export const NAMESPACE = 'expansionPanelGroups';

export const SET_EXPANSION_PANEL_GROUPS = `${NAMESPACE}/SET_EXPANSION_PANELS_STATES`;
export const UPDATE_PANEL_STATE = `${NAMESPACE}/UPDATE_PANEL_STATE`;
export const ACTIVATE_NEXT_PANEL = `${NAMESPACE}/ACTIVATE_NEXT_PANEL`;
export const ACTIVATE_PREVIOUS_PANEL = `${NAMESPACE}/ACTIVATE_PREVIOUS_PANEL`;
export const SET_PANEL_ERROR = `${NAMESPACE}/SET_PANEL_ERROR`;
export const SET_PANEL_COMPLETED = `${NAMESPACE}/SET_PANEL_COMPLETED`;

export const ADD_EXPANSION_PANEL_GROUP = `${NAMESPACE}/ADD_EXPANSION_PANEL_GROUP`;
export const REMOVE_EXPANSION_PANEL_GROUP = `${NAMESPACE}/REMOVE_EXPANSION_PANEL_GROUP`;
