import React from 'react';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ColumnWithSubValue, CustomTable } from '@core/components';
import { Club } from '@core/icons';
import { getCompetitorLogoUrl } from '@core/helpers';
import { useConfirmationDialog } from '@core/hooks';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { Column, MaterialTableProps } from '@core/components/material-table';
import { actions as matchSetsActions } from '@core/store/modules/match-set';
import { Group, MatchModel } from '@core/types';
import { MatchSet, ModalTypes } from '@volleyball/types';
import { State } from '@volleyball/store';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';

import { OwnProps as MatchDataEditModalProps } from './match-data-edit-modal';
import { MatchDataViewTableTitleAndAction } from './match-data-view-table-title-and-action';
import {
  convertDurationToString,
  getGoldenMatchSets,
  getRegularMatchSets,
  getIsOptionalRegularSet,
} from './helpers';

const useStyles = makeStyles(() => ({
  scoreContainer: {
    width: 36,
    height: 36,
    borderRadius: '50%',
  },
  score: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'center',
  },
}));

interface StateProps {
  isLoading?: boolean;
}

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
    matchSets: typeof matchSetsActions;
  };
}

interface OwnProps {
  competitionId: number;
  group: Group;
  isEditable: boolean;
  isGoldenSetTable: boolean;
  match: MatchModel;
  matchSets: Array<MatchSet>;
  stageId: number | undefined;
}

type Props = StateProps & DispatchProps & OwnProps;

const MatchDataViewMatchSetsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    competitionId,
    actions,
    isEditable,
    isGoldenSetTable,
    isLoading,
    match,
    matchSets,
    group,
    stageId,
  } = props;
  const theme = useTheme();
  const classes = useStyles();

  const handleEdit = (editOptions: MatchDataEditModalProps) => {
    actions.globalModal.openModal<MatchDataEditModalProps>({
      type: ModalTypes.MATCH_DATA_EDIT_MODAL,
      params: editOptions,
    });
  };

  const { getConfirmation } = useConfirmationDialog();

  const data = isGoldenSetTable
    ? getGoldenMatchSets(matchSets)
    : getRegularMatchSets(matchSets);

  const shouldRenderSummaryRow = !isGoldenSetTable && data.length > 0;

  const renderScore = (
    isWinner: boolean,
    score: number,
    isHomeTeam?: boolean,
  ) => (
    <Grid container sx={{ justifyContent: isHomeTeam ? 'flex-end' : 'normal' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.scoreContainer}
        sx={{
          backgroundColor: isWinner ? theme.palette.success.light : 'inherit',
        }}
      >
        <Typography component="div" className={classes.score}>
          {score}
        </Typography>
      </Grid>
    </Grid>
  );

  const columns: Array<Column<MatchSet> & { field?: keyof MatchSet }> = [
    {
      title: t('Set #'),
      field: 'number',
      width: 30,
      render: (rowData: MatchSet) =>
        isGoldenSetTable ? t('Golden') : rowData.number,
    },
    {
      title: (
        <ColumnWithSubValue
          value={match.homeCompetitorTitle}
          subValue={match.homeCompetitorInternationalTitle}
          alignment="right"
          logo={{
            url: getCompetitorLogoUrl(match, 'homeCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
      field: 'homeCompetitorScore',
      headerStyle: { textAlign: 'right' },
      render: (rowData: MatchSet) =>
        renderScore(
          rowData.homeCompetitorScore > rowData.awayCompetitorScore,
          rowData.homeCompetitorScore,
          true,
        ),
    },
    {
      title: t('Score'),
      headerStyle: { textAlign: 'center', paddingLeft: 0, paddingRight: 0 },
      emptyValue: `\u{200B}`,
    },
    {
      title: (
        <ColumnWithSubValue
          value={match.awayCompetitorTitle}
          subValue={match.awayCompetitorInternationalTitle}
          logo={{
            url: getCompetitorLogoUrl(match, 'awayCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
      field: 'awayCompetitorScore',
      render: (rowData: MatchSet) =>
        renderScore(
          rowData.homeCompetitorScore < rowData.awayCompetitorScore,
          rowData.awayCompetitorScore,
        ),
    },
    {
      title: t('Duration'),
      field: 'duration',
      render: (rowData: MatchSet) =>
        convertDurationToString(rowData.duration, t),
    },
    {
      render: (rowData: MatchSet) => {
        const matchSet = rowData;
        const shouldShowDeleteAction =
          isGoldenSetTable ||
          (getIsOptionalRegularSet(matchSet.number, group) &&
            data.length === matchSet.number);

        const handleDelete = async () => {
          // TODO: JB: pattern that uses getConfirmation assumes that api call will be successful
          const confirmed = await getConfirmation({
            title: t('Delete'),
            message: t('Are you sure you want to delete this set?'),
            confirmText: t('Delete'),
            cancelText: t('Cancel'),
          });

          if (confirmed) {
            const onSuccess = () => {
              actions.matchSets.getMatchSets({ matchId: match.id });
            };

            actions.matchSets.deleteSet({
              setId: matchSet.id,
              onSuccess,
              onSuccessEffectParameters: {
                competitionId,
                stageId,
                matchId: match.id,
                modal: false,
                tabId: null,
              },
            });
          }
        };

        return (
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              title={t('Edit')}
              color="primary"
              onClick={() =>
                handleEdit({
                  competitionId,
                  stageId,
                  match,
                  kind: 'matchSetEdit',
                  matchSet,
                  group,
                })
              }
              disabled={!isEditable}
            >
              <EditTwoTone />
            </IconButton>
            {shouldShowDeleteAction && (
              <IconButton
                title={t('Delete')}
                color="primary"
                disabled={!isEditable}
                onClick={handleDelete}
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  const renderSummaryRow: MaterialTableProps<MatchSet>['renderSummaryRow'] =
    shouldRenderSummaryRow
      ? ({ column, data: _data }) => {
          const value = (() => {
            switch ((column as (typeof columns)[number]).field) {
              case 'number':
                return t('Total');
              case 'homeCompetitorScore':
                return renderScore(
                  false,
                  _data.reduce(
                    (acc, curr) => acc + curr.homeCompetitorScore,
                    0,
                  ),
                  true,
                );
              case 'awayCompetitorScore':
                return renderScore(
                  false,
                  _data.reduce(
                    (acc, curr) => acc + curr.awayCompetitorScore,
                    0,
                  ),
                );

              case 'duration': {
                const duration = _data.reduce(
                  (acc, curr) => acc + curr.duration,
                  0,
                );

                return convertDurationToString(duration, t);
              }
              default:
                return ' ';
            }
          })();

          // NB: falsy value variable will cause the returned object to be rendered as a node
          return {
            value: value || ' ',
            style: {
              fontSize: 'inherit',
              fontWeight: theme.typography.fontWeightBold,
            },
          };
        }
      : undefined;

  const options: MaterialTableProps<MatchSet>['options'] = {
    rowStyle: (_data, index) =>
      shouldRenderSummaryRow && data.length - 1 === index
        ? { fontWeight: undefined }
        : {},
  };

  return (
    <Grid container item xs={12} spacing={1.5}>
      {!isGoldenSetTable && (
        <MatchDataViewTableTitleAndAction
          title={t('Played sets')}
          buttonOptions={{
            component: 'Button',
            text: t('Add set'),
            onClick: () =>
              handleEdit({
                competitionId,
                stageId,
                match,
                kind: 'matchSetAdd',
                matchSets,
                goldenSet: false,
                group,
              }),
            dataQa: 'data-qa',
            disabled:
              data.length >= group.settings.numberOfSets ||
              !isEditable ||
              match.goldenMatch,
          }}
        />
      )}
      {isGoldenSetTable && (
        <MatchDataViewTableTitleAndAction
          title={t('Golden sets')}
          buttonOptions={{
            component: 'Button',
            text: t('Add set'),
            onClick: () =>
              handleEdit({
                competitionId,
                stageId,
                match,
                kind: 'matchSetAdd',
                matchSets,
                goldenSet: true,
                group,
              }),
            dataQa: 'data-qa',
            disabled: !!data.length || !isEditable,
          }}
        />
      )}
      <Grid item xs={12}>
        <CustomTable
          columns={columns}
          data={data}
          options={options}
          isLoading={isLoading}
          renderSummaryRow={renderSummaryRow}
        />
      </Grid>
    </Grid>
  );
};

const isLoadingSelector = (matchId: number) =>
  createLoadingSelector([
    {
      actionName: matchSetsActions.getMatchSetsRequest.toString(),
      id: matchId,
    },
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isLoading: isLoadingSelector(ownProps.match.id)(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
    matchSets: bindActionCreators(matchSetsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchDataViewMatchSetsTable);
