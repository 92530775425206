import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { GlobalModalProps, CompetitorStatus } from '@core/types';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  GroupStandingsColumn,
  GroupStandingsColumnLabels,
  MatchSets,
  Standing,
  StandingsHeader,
} from '@volleyball/types';
import StandingsTable from '@volleyball/pages/competition-profile/components/stage-profile-panel/standings-tab/components/standings-table';

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
  };
}

type OwnProps = GlobalModalProps<{
  headers: Array<GroupStandingsColumn>;
}>;

type Props = OwnProps & DispatchProps;

const StandingsSettingsTablePreviewModal = (props: Props) => {
  const { isModalOpen, modalActions, modalParams } = props;
  const { t } = useTranslation();

  const selectedHeaders: Array<StandingsHeader> = [
    ...(modalParams?.headers ?? [])
      .filter((val: any) => val.isSelected)
      .map((header) => {
        if (header.id === GroupStandingsColumnLabels.RESULT_BREAKDOWN) {
          return {
            name: '',
            label: header.id,
            setBreakdown: [
              {
                name: MatchSets.SET_3_0,
                value: MatchSets.SET_3_0,
              },
              {
                name: MatchSets.SET_3_1,
                value: MatchSets.SET_3_1,
              },
              {
                name: MatchSets.SET_3_2,
                value: MatchSets.SET_3_2,
              },
              {
                name: MatchSets.SET_2_3,
                value: MatchSets.SET_2_3,
              },
              {
                name: MatchSets.SET_1_3,
                value: MatchSets.SET_1_3,
              },
              {
                name: MatchSets.SET_0_3,
                value: MatchSets.SET_0_3,
              },
            ],
          };
        }

        return {
          name: '',
          label: header.id,
        };
      }),
  ];

  const standings: Standing = {
    groupId: 0,
    standingItems: [
      {
        position: 1,
        competitorName: null,
        competitor: {
          id: 0,
          title: t('Competitor'),
          internationalTitle: null,
          teamCode: 'ABC',
          status: CompetitorStatus.CONFIRMED,
          alternativeVenueId: null,
          contacts: null,
          defaultVenueId: null,
          teamId: null,
          clubId: null,
          logoFileLink: null,
          logoFileId: null,
          defaultVenue: null,
          alternativeVenue: null,
          clubLogoFileLink: null,
          competitorPlayersCount: null,
          playerListApproved: null,
        },
        setsBreakdown: [],
        points: null,
        gamesPlayed: null,
        gamesWon: null,
        gamesLost: null,
        percentage: null,
        setsWon: null,
        setsLost: null,
        setsRatio: null,
        scoredPoints: null,
        missedPoints: null,
        actions: null,
      },
    ],
  };

  const handleClose = () => modalActions.closeModal();

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t('Standings table preview')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <StandingsTable
            headers={selectedHeaders}
            standings={standings}
            tableIndex={0}
            preview
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Button variant="contained" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(StandingsSettingsTablePreviewModal);
