import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import { KitSetList } from '@core/types';

import { actions } from '.';

interface DefaultState {
  [competitorId: number]: {
    kitSets: KitSetList;
  };
}

interface Payload {
  data?: any;
  competitorId: number;
}

export const defaultState: DefaultState = {};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setKitSets.toString()]: (state, { payload }) =>
      assocPath([payload.competitorId, 'kitSets'], payload.data, state),
    [actions.resetCompetitor.toString()]: (state, { payload }) =>
      dissoc(payload.competitorId, state),
  },
  defaultState,
);

export default reducer;
