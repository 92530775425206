import { createSelector } from '@reduxjs/toolkit';

import { State } from '@volleyball/store';

import { NAMESPACE } from './constants';

export const getFiltersList = createSelector(
  (state: State) => state.ui ?? null,
  (ui) => ui[NAMESPACE] ?? null,
);
