import { call, put, Effect } from 'redux-saga/effects';

import * as competitorTeamOfficialsApi from '@core/api/competitor-team-officials';
import { UpdateCompetitorTeamOfficialRequest } from '@core/types';

import { actions } from '.';

export function* getCompetitorTeamOfficials(
  competitionId: number,
  teamId: number,
  params?: { query: string },
): Generator<Effect, any, any> {
  yield put(actions.getCompetitorTeamOfficialsRequest());
  const response = yield call(
    competitorTeamOfficialsApi.getCompetitorTeamOfficials,
    competitionId,
    teamId,
    params,
  );

  if (!response.error) {
    yield put(actions.getCompetitorTeamOfficialsSuccess());
    yield put(
      actions.setCompetitorTeamOfficials(
        response?.response?._embedded?.competitorTeamOfficials,
      ),
    );
  } else {
    yield put(actions.getCompetitorTeamOfficialsFailure());
  }

  return response;
}

export function* addTeamOfficialsToCompetitor(
  competitionId: number,
  teamId: number,
  teamOfficialIds: Array<number>,
): Generator<Effect, any, any> {
  yield put(actions.addTeamOfficialsToCompetitorRequest());
  const response = (yield call(
    competitorTeamOfficialsApi.setCompetitorTeamOfficials,
    competitionId,
    teamId,
    teamOfficialIds,
  )) as InferApi<typeof competitorTeamOfficialsApi.setCompetitorTeamOfficials>;

  if (!response.error) {
    yield put(actions.addTeamOfficialsToCompetitorSuccess());
  } else {
    yield put(actions.addTeamOfficialsToCompetitorFailure());
  }

  return response;
}

export function* updateTeamOfficialsForCompetitor(
  competitionId: number,
  teamId: number,
  teamOfficials: Array<{
    teamOfficialId: number;
    teamOfficialRoleId: number;
  }>,
): Generator<Effect, any, any> {
  yield put(actions.updateTeamOfficialsForCompetitorRequest());
  const response = yield call(
    competitorTeamOfficialsApi.patchCompetitorTeamOfficials,
    competitionId,
    teamId,
    teamOfficials,
  );

  if (!response.error) {
    yield put(actions.updateTeamOfficialsForCompetitorSuccess());
  } else {
    yield put(actions.updateTeamOfficialsForCompetitorFailure());
  }

  return response;
}

export function* updateCompetitorTeamOfficial(
  props: UpdateCompetitorTeamOfficialRequest,
): Generator<Effect, any, any> {
  yield put(actions.updateCompetitorTeamOfficialRequest());
  const response = yield call(
    competitorTeamOfficialsApi.updateCompetitorTeamOfficials,
    props,
  );

  if (!response.error) {
    yield put(actions.updateCompetitorTeamOfficialSuccess());
  } else {
    yield put(actions.updateCompetitorTeamOfficialFailure());
  }

  return response;
}

export function* getPreviousCompetitorTeamOfficials(
  competitionId: number,
  teamId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPreviousCompetitorTeamOfficialsRequest());
  const response = yield call(
    competitorTeamOfficialsApi.getPreviousCompetitorTeamOfficials,
    competitionId,
    teamId,
  );

  if (!response.error) {
    yield put(actions.getPreviousCompetitorTeamOfficialsSuccess());
  } else {
    yield put(actions.getPreviousCompetitorTeamOfficialsFailure());
  }

  return response;
}
