import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  AssignmentsUsersOriginType,
  User,
  UserRoleType as UserRoleTypeValues,
} from '@core/types';
import { CustomTable, PersonNameColumn, Badge } from '@core/components';
import {
  getAllAssignedCompetitions,
  getAssignmentPageUsers,
  areUsersLoading,
} from '@core/store/modules/pages/assignments/selectors';
import { actions as assignmentsActions } from '@core/store/modules/pages/assignments';
import {
  getRegionalCompetitionManagerId,
  getUserRoleTypeValues,
} from '@core/helpers';
import AssignedCompetitionsTable from './components/assigned-competitions-table';
import { AssignmentsUsersOrigin } from '@core';
import type { Column } from '@core/components/material-table';
import { useAppDispatch } from '@volleyball/store/hooks';

const usersOrigin: AssignmentsUsersOriginType =
  AssignmentsUsersOrigin.ASSIGNMENTS;

const AssignmentsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useSelector(areUsersLoading);
  const users = useSelector(getAssignmentPageUsers);
  const allAssignedCompetitions = useSelector(getAllAssignedCompetitions);
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(users.page);
  const userRoleValues = getUserRoleTypeValues();

  const handleSearch = (params: { query: string }) => {
    setQuery(params.query);
    setPage(1);
  };

  const onReload = ({ page: newPage }: { page: number }) => setPage(newPage);

  const getAssignedCompetitionCount = (user: User) => {
    const foundRegionalCompetitionManager = user.userRoles.find(
      ({ role }) =>
        role === UserRoleTypeValues.ROLE_REGIONAL_COMPETITION_MANAGER,
    );

    const assignedCompetitionsCountFromList = Array.isArray(
      allAssignedCompetitions[foundRegionalCompetitionManager?.id],
    )
      ? String(
          allAssignedCompetitions[foundRegionalCompetitionManager?.id]?.length,
        )
      : null;

    return (
      assignedCompetitionsCountFromList ||
      foundRegionalCompetitionManager?.assignedCompetitionCount ||
      0
    );
  };

  const onTableRowClick = (
    event: any,
    _user: User,
    togglePanel: (panelIndex?: number) => void,
  ) => {
    if (event.target.value) {
      return;
    }

    togglePanel();
  };

  const columns: Column<User>[] = [
    {
      title: t('User'),
      render: ({ firstName, lastName, profilePictureFileLink }) => (
        <PersonNameColumn
          person={{
            localFirstName: firstName,
            localFamilyName: lastName,
            logoFileLink: profilePictureFileLink,
          }}
          showInitials
          withLogo
        />
      ),
    },
    { title: t('Email Address'), field: 'email' },
    {
      title: t('User Roles'),
      render: ({ userRoles }) =>
        userRoles.map(({ role }) => userRoleValues[role]).join(', '),
    },
    {
      title: t('Competitions'),
      field: 'assignedCompetitionCount',
      render: (user) => (
        <Box display="flex">
          <Badge text={getAssignedCompetitionCount(user)} />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      assignmentsActions.getUsers({
        queryParams: new URLSearchParams([
          ['query', query],
          ['page', page.toString()],
          [
            'filter[role]',
            UserRoleTypeValues.ROLE_REGIONAL_COMPETITION_MANAGER,
          ],
        ]),
        origin: usersOrigin,
      }),
    );
  }, [query, page, dispatch]);

  return (
    <Paper>
      <Box p={3} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{t('People assignments')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CustomTable<User>
              noPaper
              columns={columns}
              data={users?._embedded?.users}
              isLoading={isLoading}
              tableSearch={{ query, onSearch: handleSearch }}
              options={{ toolbar: false, detailPanelType: 'single' }}
              onRowClick={onTableRowClick}
              pagination={{
                reloadData: onReload,
                page: users.page,
                pages: users.pages,
                limit: users.limit,
                total: users.total,
              }}
              detailPanel={[
                {
                  icon: () => <ExpandMore data-qa="expand-row" />,
                  openIcon: () => <ExpandLess />,
                  render: ({ rowData: user }) => {
                    const regionalCompetitionManagerId =
                      getRegionalCompetitionManagerId(user);

                    if (!regionalCompetitionManagerId) {
                      return null;
                    }

                    return (
                      <AssignedCompetitionsTable
                        personId={regionalCompetitionManagerId}
                        personName={`${user.firstName} ${user.lastName}`}
                      />
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AssignmentsPage;
