import { FormikErrors } from 'formik';

import { APIErrorsModel } from '@core/types/api/rest-service';

type MapApiErrorsToFormErrors = (
  apiErrors: APIErrorsModel,
) => FormikErrors<any>;

export const mapApiErrorsToFormErrors: MapApiErrorsToFormErrors = (
  apiErrors,
) => {
  const formikErrors: FormikErrors<any> = {};
  apiErrors.forEach((error) => {
    const field = error.path.replace(/[[\]]/g, '');
    formikErrors[field] = error.message;
  });

  return formikErrors;
};
