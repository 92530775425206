import { call, Effect, put, SagaReturnType } from 'redux-saga/effects';

import * as competitorPlayersApi from '@core/api/competitor-players';
import { PlayerListPlayer, UpdateCompetitorPlayerRequest } from '@core/types';

import { actions } from '.';

export function* getCompetitorPlayers(
  competitionId: number,
  teamId: number,
  params?: { query: string },
): Generator<Effect, any, any> {
  yield put(actions.getCompetitorPlayersRequest());
  const response = (yield call(
    competitorPlayersApi.getCompetitorPlayers,
    competitionId,
    teamId,
    params,
  )) as InferApi<typeof competitorPlayersApi.getCompetitorPlayers>;

  if (!response.error) {
    yield put(actions.getCompetitorPlayersSuccess());
    yield put(
      actions.setCompetitorPlayers(
        response?.response?._embedded?.competitorPlayers,
      ),
    );
  } else {
    yield put(actions.getCompetitorPlayersFailure());
  }

  return response;
}

export function* updateCompetitorPlayer(
  props: UpdateCompetitorPlayerRequest,
): Generator<Effect, any, any> {
  yield put(actions.updateCompetitorPlayerRequest());
  const response = (yield call(
    competitorPlayersApi.updateCompetitorPlayer,
    props,
  )) as SagaReturnType<typeof competitorPlayersApi.updateCompetitorPlayer>;

  if (!response.error) {
    yield put(actions.updateCompetitorPlayerSuccess());
  } else {
    yield put(actions.updateCompetitorPlayerFailure());
  }

  return response;
}

export function* addPlayersToCompetitor(
  competitionId: number,
  teamId: number,
  playerIds: Array<number>,
): Generator<Effect, any, any> {
  yield put(actions.addPlayersToCompetitorRequest());
  const response = (yield call(
    competitorPlayersApi.setCompetitorPlayers,
    competitionId,
    teamId,
    playerIds,
  )) as InferApi<typeof competitorPlayersApi.setCompetitorPlayers>;

  if (!response.error) {
    yield put(actions.addPlayersToCompetitorSuccess());
  } else {
    yield put(actions.addPlayersToCompetitorFailure());
  }

  return response;
}

export function* updatePlayersForCompetitor(
  competitionId: number,
  teamId: number,
  players: Array<PlayerListPlayer>,
): Generator<Effect, any, any> {
  yield put(actions.updatePlayersForCompetitorRequest());
  const response = (yield call(
    competitorPlayersApi.patchCompetitorPlayers,
    competitionId,
    teamId,
    players,
  )) as InferApi<typeof competitorPlayersApi.patchCompetitorPlayers>;

  if (!response.error) {
    yield put(actions.updatePlayersForCompetitorSuccess());
  } else {
    yield put(actions.updatePlayersForCompetitorFailure());
  }

  return response;
}

export function* getPreviousCompetitorPlayers(
  competitionId: number,
  teamId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPreviousCompetitorPlayersRequest());
  const response = (yield call(
    competitorPlayersApi.getPreviousCompetitorPlayers,
    competitionId,
    teamId,
  )) as InferApi<typeof competitorPlayersApi.getPreviousCompetitorPlayers>;

  if (!response.error) {
    yield put(actions.getPreviousCompetitorPlayersSuccess());
  } else {
    yield put(actions.getPreviousCompetitorPlayersFailure());
  }

  return response;
}
