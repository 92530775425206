import { pathOr } from 'ramda';

import { NAMESPACE } from './constants';

export const getAgeGroupList = pathOr(null, ['pages', NAMESPACE, 'allGroups']);
export const getAgeGroupBeingModified = pathOr(null, [
  'pages',
  NAMESPACE,
  'groupBeingModified',
]);
export const isNewGroupBeingCreated = pathOr(null, [
  'pages',
  NAMESPACE,
  'isNewGroupBeingCreated',
]);
export const getAgeGroupErrors = pathOr(null, ['pages', NAMESPACE, 'errors']);
