import React from 'react';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  colSpan: number;
  isLoading: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles({
  content: {
    padding: 0,
    position: 'relative',
  },
  loader: {
    margin: '0 -24px',
    position: 'absolute',
    width: '100%',
  },
});

const LoadingIndicator = (props: Props) => {
  const { colSpan, isLoading } = props;
  const classes = useStyles();

  return (
    <thead>
      <tr>
        <th className={classes.content} colSpan={colSpan}>
          {isLoading && <LinearProgress className={classes.loader} />}
        </th>
      </tr>
    </thead>
  );
};

export default LoadingIndicator;
