import React from 'react';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { PageFormActionBlock } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  isSubmitting?: boolean;
  tabId: string;
}

interface DispatchProps {
  actions: {
    tab: typeof tabActions;
  };
}

type Props = OwnProps & DispatchProps;

const FormWithAction = (props: Props) => {
  const { isSubmitting, actions, tabId } = props;
  const { t } = useTranslation();

  const handleCancel = () => actions.tab.removeTab({ tabId });

  return (
    <PageFormActionBlock>
      <Box display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </Box>
        <ButtonWithProgress
          isLoading={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t('Save')}
        </ButtonWithProgress>
      </Box>
    </PageFormActionBlock>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tab: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(FormWithAction);
