import { all, call, put, take, takeEvery } from 'redux-saga/effects';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { regenerateFixtures } from '@volleyball/store/modules/api/group-fixture/sagas';
import { getGroupFixtureSettings } from '@volleyball/store/modules/api/group-fixture-settings/sagas';

import { actions } from '.';

export function* regenerateFixturesFlow() {
  while (true) {
    const { payload } = yield take(actions.regenerateFixtures);
    const { data, link, tabId, competitionId, groupId } = payload;

    yield put(actions.regenerateFixturesRequest());
    yield put(actions.setApiErrors({ groupId, data: null }));

    const { error } = yield call(regenerateFixtures, link, data);

    if (!error) {
      yield put(actions.regenerateFixturesSuccess());
      yield put(tabActions.removeTab({ tabId }));
      yield put(competitionProfileActions.getStageDetails({ competitionId }));
    } else {
      yield put(actions.regenerateFixturesFailure());
      if (!error.errors) {
        yield call(handleApiErrors, error);
      } else {
        yield put(actions.setApiErrors({ data: error.errors, groupId }));
      }
    }
  }
}

export function* getGroupFixtureSettingsFlow(
  action: ReturnType<typeof actions.getGroupFixturesSettings>,
) {
  const { payload: groupId } = action;

  yield put(actions.getGroupFixturesSettingsRequest());

  const { error, response } = yield call(getGroupFixtureSettings, groupId);

  if (!error) {
    yield put(actions.getGroupFixturesSettingsSuccess());
    yield put(actions.setGroupFixturesSettings({ groupId, data: response }));
  } else {
    yield put(actions.getGroupFixturesSettingsFailure());
    yield call(handleApiErrors, error);
  }
}

export function* getGroupFixtureSettingsFlowWatcher() {
  yield takeEvery(
    actions.getGroupFixturesSettings,
    getGroupFixtureSettingsFlow,
  );
}

export default function* saga() {
  yield all([regenerateFixturesFlow(), getGroupFixtureSettingsFlowWatcher()]);
}
