import React from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Card,
  Divider,
  Theme,
  Typography,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { Club } from '@core/icons';
import { Logo } from '@core/components';
import { formatDateTime } from '@core/helpers';
import { CustomerResponse, LogoSize } from '@core/types';
import { State } from '@volleyball/store';
import {
  Competitor,
  GroupStandingsPointAdjustments,
  StandingItem,
  MatchSheetHistoryModel,
} from '@volleyball/types';

interface OwnProps {
  matchId: number;
  standings: Array<StandingItem>;
  competitorId?: number;
}

interface StateProps {
  matchSheetHistory?: Array<MatchSheetHistoryModel>;
  currentCustomer: CustomerResponse;
}

type Props = OwnProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  bolderText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  indicator: {
    '& > .MuiBox-root > .MuiBox-root::before': {
      content: '"\\2022"',
      fontSize: 32,
      position: 'absolute',
      background: theme.palette.background.paper,
      marginTop: 10,
      left: -44,
      color: theme.palette.grey[400],
      top: -8,
    },
    '& > .MuiBox-root:first-child > .MuiBox-root::before': {
      color: theme.palette.primary.main,
    },
    '& > .MuiBox-root[data-icon="true"]': {
      '&:last-child > .MuiBox-root > .MuiBox-root': {
        top: -8,
        // height: 230,
        marginTop: 8,
        // paddingBottom: 175,
      },
      '&:[data-active]': {
        color: theme.palette.primary.main,
      },
      '& > .MuiBox-root': {
        '&::before': {
          height: 0,
          width: 0,
          fontSize: 96,
          left: -63,
          top: navigator.platform === 'MacIntel' ? 8 : 16,
          lineHeight: 0,
          zIndex: 10,
        },
        '& > .MuiBox-root': {
          position: 'absolute',
          left: -45,
          width: 20,
          background: theme.palette.background.paper,
          height: 52,
        },
        '& > .MuiBox-root > svg': {
          position: 'relative',
          width: 20,
          color: theme.palette.background.paper,
          height: '100%',
          zIndex: 15,
        },
      },
    },
  },
}));

export interface StandingsHistory extends GroupStandingsPointAdjustments {
  competitor: Competitor;
}

const MatchDataHistory = (props: Props) => {
  const { standings, currentCustomer } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getRoles = (roles: Array<string>) => {
    return roles.length === 1 ? (
      `(${roles[0]})`
    ) : (
      <Tooltip
        title={
          <>
            {roles.map((role, index) => (
              <Grid key={index}>{role}</Grid>
            ))}
          </>
        }
        placement="bottom"
      >
        <span>({t('Multiple roles')})</span>
      </Tooltip>
    );
  };

  const pointAdjustments: Array<StandingsHistory> = [];
  standings.forEach((element) => {
    pointAdjustments.push(
      ...element.pointAdjustments.map((pointAdjustment) => ({
        ...pointAdjustment,
        competitor: element.competitor,
      })),
    );
  });
  const orderedAdjustments = pointAdjustments.sort(
    // @ts-ignore
    (a, b) => new Date(b.dateTime) - new Date(a.dateTime),
  );

  return pointAdjustments ? (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>
        <Box display="flex" flex="1" height="100%" width={36} mr={2}>
          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            style={{ width: 2, height: '100%' }}
          />
        </Box>
      </Grid>
      <Grid container item direction="column" className={classes.indicator}>
        {orderedAdjustments.map((event, index) => {
          return (
            <Box
              key={index}
              flex={1}
              mb={2}
              position="relative"
              data-icon={
                index === pointAdjustments.length - 1 &&
                pointAdjustments.length !== 1
              }
              data-active={pointAdjustments.length === index + 1}
            >
              <Box>
                {index === pointAdjustments.length - 1 &&
                  pointAdjustments.length !== 1 && (
                    <Box>
                      <ArrowUpwardIcon fontSize="small" />
                    </Box>
                  )}
              </Box>
              <Card style={{ flex: 1 }}>
                <Box
                  display="flex"
                  m={2}
                  flexWrap="wrap"
                  style={{ wordBreak: 'break-all' }}
                >
                  <Typography variant="body2" className={classes.bolderText}>
                    {event.user.firstName} {event.user.lastName}{' '}
                    {getRoles(event.user.roles)}
                  </Typography>
                  <Box mx={1}>
                    <Typography variant="body2">{t('adjusted')}</Typography>
                  </Box>
                  <Typography variant="body2" className={classes.bolderText}>
                    {t('Points')}
                  </Typography>
                  <Box ml={2}>
                    <Typography variant="body2" color="textSecondary">
                      {/* @ts-ignore */}
                      {formatDateTime(
                        currentCustomer?.dateTimeFormat,
                        event.time,
                      ) || event.time}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" p={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    mr={2}
                  >
                    <Logo
                      size={LogoSize.SMALL}
                      src={
                        event?.competitor?.logoFileLink ||
                        event?.competitor?.clubLogoFileLink
                      }
                    >
                      <Club />
                    </Logo>
                  </Box>
                  <Box flex={1}>
                    <Card style={{ flex: 1 }}>
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12}>
                            <Typography
                              variant="body2"
                              className={classes.bolderText}
                            >
                              {event?.competitor?.title}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {event?.competitor?.title}
                            </Typography>
                          </Grid>
                          <Grid item md={4} xs={3}>
                            <Box display="flex">
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >{`${t('Points')}:`}</Typography>
                            </Box>
                            <Box display="flex">
                              {event?.pointAdjustment > 0
                                ? `+${event?.pointAdjustment}`
                                : event?.pointAdjustment}
                            </Box>
                          </Grid>
                          <Grid item md={4} xs={9}>
                            <Box display="flex">
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >{`${t('Comment')}:`}</Typography>
                            </Box>
                            <Box
                              display="flex"
                              style={{ overflowWrap: 'anywhere' }}
                            >
                              {event?.comment}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>
                </Box>
              </Card>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(MatchDataHistory);
