import {
  put,
  call,
  all,
  takeLatest,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';

import { formatFilterParams, handleApiErrors } from '@core/helpers';
import {
  FilteringOperations,
  CalendarConflictType,
  Conflict,
} from '@core/types';
import * as matchesAPI from '@core/api/matches';
import * as conflictsAPI from '@core/api/conflicts';
import { authorizationParamsTuple } from '@core/store/helpers';

import { API_MATCH_LIMIT } from './constants';

import { actions } from '.';

export function* getFixtures({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Generator<Effect, any, any> {
  yield put(actions.getFixturesRequest());

  const matchFilters = {
    'date.gte': {
      fieldName: 'date',
      filteringOperation: FilteringOperations.greaterThanOrEqual,
      filterValues: [{ value: startDate }],
    },
    'date.lte': {
      fieldName: 'date',
      filteringOperation: FilteringOperations.lessThanOrEqual,
      filterValues: [{ value: endDate }],
    },
  };
  const matchQuery = new URLSearchParams([
    ['page', '1'],
    ['limit', String(API_MATCH_LIMIT)],
    ...formatFilterParams(matchFilters),
    (yield call(authorizationParamsTuple)) as SagaReturnType<
      typeof authorizationParamsTuple
    >,
  ]);

  const { response, error } = (yield call(
    matchesAPI.getDashboardMatches,
    matchQuery,
  )) as InferApi<typeof matchesAPI.getMatches>;

  if (!error) {
    yield put(actions.getFixturesSuccess());
  } else {
    yield put(actions.getFixturesFailure());
    yield call(handleApiErrors, error);
  }

  return response;
}

export function* getVenueConflicts({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Generator<Effect, any, any> {
  yield put(actions.getVenueConflictsRequest());

  const conflictsQuery = new URLSearchParams([
    ['startDate', startDate],
    ['endDate', endDate],
  ]);
  const { response, error } = (yield call(
    conflictsAPI.getVenueConflicts,
    conflictsQuery,
  )) as InferApi<typeof conflictsAPI.getVenueConflicts>;

  if (!error) {
    yield put(actions.getVenueConflictsSuccess());
  } else {
    yield put(actions.getVenueConflictsFailure());
    yield call(handleApiErrors, error);
  }

  return response;
}

export function* getTeamConflicts({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Generator<Effect, any, any> {
  yield put(actions.getTeamConflictsRequest());

  const conflictsQuery = new URLSearchParams([
    ['startDate', startDate],
    ['endDate', endDate],
  ]);
  const { response, error } = (yield call(
    conflictsAPI.getTeamConflicts,
    conflictsQuery,
  )) as InferApi<typeof conflictsAPI.getTeamConflicts>;

  if (!error) {
    yield put(actions.getTeamConflictsSuccess());
  } else {
    yield put(actions.getTeamConflictsFailure());
    yield call(handleApiErrors, error);
  }

  return response;
}

export function* getOfficialConflicts({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Generator<Effect, any, any> {
  yield put(actions.getOfficialConflictsRequest());

  const conflictsQuery = new URLSearchParams([
    ['startDate', startDate],
    ['endDate', endDate],
  ]);
  const { response, error } = (yield call(
    conflictsAPI.getOfficialConflicts,
    conflictsQuery,
  )) as InferApi<typeof conflictsAPI.getOfficialConflicts>;

  if (!error) {
    yield put(actions.getOfficialConflictsSuccess());
  } else {
    yield put(actions.getOfficialConflictsFailure());
    yield call(handleApiErrors, error);
  }

  return response;
}

export function* getFixturesFlow({
  payload,
}: InferAction<typeof actions.getFixtures>): Generator<Effect, any, any> {
  yield put(actions.getAllConflictsRequest());
  const { fixtures, matchConflicts, venueConflicts, officialConflicts } =
    (yield all({
      fixtures: call(getFixtures, payload),
      matchConflicts: call(getTeamConflicts, payload),
      venueConflicts: call(getVenueConflicts, payload),
      officialConflicts: call(getOfficialConflicts, payload),
    })) as {
      fixtures: SagaReturnType<typeof getFixtures>;
      matchConflicts: SagaReturnType<typeof getTeamConflicts>;
      venueConflicts: SagaReturnType<typeof getVenueConflicts>;
      officialConflicts: SagaReturnType<typeof getOfficialConflicts>;
    };

  const conflicts: Array<Conflict> = [
    // @ts-ignore
    ...(matchConflicts?.conflicts?.map<Conflict>((conflict: Conflict) => ({
      ...conflict,
      type: CalendarConflictType.MATCH,
    })) ?? []),
    // @ts-ignore
    ...(venueConflicts?.conflicts?.map<Conflict>((conflict: Conflict) => ({
      ...conflict,
      type: CalendarConflictType.VENUE,
    })) ?? []),
    // @ts-ignore
    ...(officialConflicts?.conflicts?.map<Conflict>((conflict: Conflict) => ({
      ...conflict,
      type: CalendarConflictType.MA_OFFICIAL,
    })) ?? []),
  ];

  yield put(actions.setConflicts(conflicts));
  yield put(actions.setFixtures(fixtures?._embedded?.matches ?? []));

  if ([matchConflicts, venueConflicts, officialConflicts].every(Boolean)) {
    yield put(actions.getAllConflictsSuccess());
  } else {
    yield put(actions.getAllConflictsFailure());
  }
}

export default function* saga() {
  yield takeLatest(actions.getFixtures, getFixturesFlow);
}
