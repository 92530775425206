import { handleActions } from 'redux-actions';

import { appendToPaginatedListFactory } from '@core/helpers';
import { Paginated, CompetitionResponse } from '@core/types';

import actions from './actions';

type DefaultState = {
  competitions: Paginated<CompetitionResponse['id']>;
};

type Payload = any;

const appendToCompetitionsList =
  appendToPaginatedListFactory<DefaultState>('competitions');
export const defaultState: DefaultState = {
  competitions: null,
};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCompetitions.toString()]: (state, { payload }) =>
      appendToCompetitionsList(state, payload),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
