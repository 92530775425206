import React, { createRef, forwardRef, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { pick, isEmpty, reduce, remove, pathOr, omit } from 'ramda';

import {
  CheckboxField,
  CustomTable,
  FormikMTContainer,
  FormikMTSelect,
  ContainerProps,
} from '@core/components';
import { getDisciplineOffenderType, getCardType, isValue } from '@core/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { EditComponentProps } from '@core/components/material-table';
import { ExpansionPanelGroupId } from '@core/types';
import * as expansionPanelGroupsActions from '@core/store/modules/ui/expansion-panel-groups/actions';
import * as competitionsActions from '@core/pages/competitions/store/actions';
import { UPDATE_COMPETITION_REQUEST } from '@core/pages/competitions/store/constants';
import { getCompetitionBeingModified } from '@core/pages/competitions/store/selectors';
import competitionCreationActions from '@core/store/modules/shared/competition-creation';
import { getDisciplinaryRules } from '@core/store/modules/shared/competition-creation/selectors';
import {
  GET_DISCIPLINARY_RULES_REQUEST,
  CREATE_DISCIPLINARY_RULE_REQUEST,
  UPDATE_DISCIPLINARY_RULE_REQUEST,
  DELETE_DISCIPLINARY_RULE_REQUEST,
} from '@core/store/modules/api/disciplinary-rule/constants';

import {
  Competition,
  DisciplinaryRules,
  DisciplinaryRule,
  DisciplinaryRuleSubmitData,
} from '@volleyball/types';
import {
  FormValues,
  getDefaultValues,
  getValidationSchema,
  FIELD_NAMES,
} from '@volleyball/business-components/discipline-settings-table';

import { State } from '@volleyball/store';

import { COMPETITION_STEP_DISCIPLINE } from './tests/test-ids';

interface StateProps {
  isSubmitting: boolean;
  isLoading: boolean;
  disciplineRules: DisciplinaryRules;
  competitionBeingModified: Competition;
}

interface DispatchProps {
  actions: {
    competitions: typeof competitionsActions;
    competitionCreation: typeof competitionCreationActions;
    expansionPanelGroups: typeof expansionPanelGroupsActions;
  };
}

interface OwnProps {
  expansionPanelGroupId?: ExpansionPanelGroupId;
  expansionPanelIndex?: number;
  competitionId?: number;
  actionsDisabled?: boolean;
  // handleRowIsEditted?: any;
}

interface OptionType {
  id: string;
  title: string;
}

type Props = OwnProps & DispatchProps & StateProps;

const DisciplinarySettingsTable = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    isSubmitting,
    competitionBeingModified,
    expansionPanelGroupId,
    expansionPanelIndex,
    isLoading,
    disciplineRules,
    actionsDisabled = false,
  } = props;

  const tableRef = createRef();
  const competitionId = pathOr(null, ['id'], competitionBeingModified);

  useEffect(() => {
    isValue(competitionId) &&
      actions.competitionCreation.getDisciplinaryRules({ competitionId });
  }, []);

  const pleaseSelectOption = { id: 'PLEASE_SELECT', title: t('Please Select') };

  const offenderTypes = [
    pleaseSelectOption,
    { id: 'PLAYER', title: t('Player') },
    { id: 'TEAM_OFFICIAL', title: t('Team Official') },
  ];

  const cardTypes = [
    pleaseSelectOption,
    { id: 'YELLOW_CARD', title: t('Yellow') },
    { id: 'RED_CARD', title: t('Red') },
  ];

  const optionSelect = (
    editProps: EditComponentProps<DisciplinaryRule>,
    options: Array<OptionType>,
  ) => {
    const initialValue = pathOr(pleaseSelectOption.id, ['value'], editProps);
    const optionsObject: { [id: string]: string } = reduce(
      (accValue, currentValue) => ({
        ...accValue,
        [currentValue.id]: currentValue.title,
      }),
      {},
      options,
    );
    const renderValue = (offenderTypeId: any) =>
      optionsObject[offenderTypeId] || '';

    return (
      <FormikMTSelect
        options={options}
        initialValue={initialValue}
        renderValue={renderValue}
        {...editProps}
      />
    );
  };

  const columns: Array<any> = [
    {
      title: t('Offender Type'),
      field: FIELD_NAMES.offenderType,
      editComponent: (editProps: EditComponentProps<DisciplinaryRule>) =>
        optionSelect(editProps, offenderTypes),
      render: (rowData: DisciplinaryRule) =>
        getDisciplineOffenderType(t, rowData[FIELD_NAMES.offenderType]),
    },
    {
      title: t('Card(s) collected'),
      field: FIELD_NAMES.cards,
      'data-qa': FIELD_NAMES.cards,
      type: 'numeric',
    },
    {
      title: t('Card(s) type'),
      field: FIELD_NAMES.cardType,
      editComponent: (editProps: EditComponentProps<DisciplinaryRule>) =>
        optionSelect(editProps, cardTypes),
      render: (rowData: DisciplinaryRule) =>
        getCardType(t, rowData[FIELD_NAMES.cardType]),
    },
    {
      title: t('Card counter reset'),
      field: FIELD_NAMES.resetCounter,
      editComponent: (editProps: EditComponentProps<DisciplinaryRule>) => (
        <CheckboxField
          name={FIELD_NAMES.resetCounter}
          label={t('Enable')}
          checked={editProps.value}
        />
      ),
      render: (rowData: DisciplinaryRule) =>
        rowData[FIELD_NAMES.resetCounter] ? t('Enabled') : t('Disabled'),
    },
    {
      title: t('Match(es) suspended'),
      field: FIELD_NAMES.matchesSuspended,
      'data-qa': FIELD_NAMES.matchesSuspended,
      type: 'numeric',
    },
  ];

  const getInitialValues = (): FormValues => {
    if (competitionId) {
      const fieldNames = Object.keys(FIELD_NAMES);
      const initialValues = pick(fieldNames, { id: competitionId });
      if (!isEmpty(initialValues)) {
        // @ts-ignore
        return initialValues;
      }
    }

    return getDefaultValues();
  };

  const getEditedRow = () =>
    pathOr(
      null,
      ['current', 'state', 'lastEditingRow', 'tableData', 'id'],
      tableRef,
    );

  const formatRule = (row: DisciplinaryRule): DisciplinaryRuleSubmitData => ({
    ...omit(['tableData', 'id', '_links'], row),
    [FIELD_NAMES.cards]: +row[FIELD_NAMES.cards],
    [FIELD_NAMES.matchesSuspended]: +row[FIELD_NAMES.matchesSuspended],
  });

  const handleSubmit = () => {
    const payload = {
      competitionData: { id: competitionId, data: {} },
      panelData: {
        groupId: expansionPanelGroupId,
        panelIndex: expansionPanelIndex,
      },
    };
    actions.competitions.updateDraftCompetition(payload);
  };

  return (
    <Box width="100%" data-qa={COMPETITION_STEP_DISCIPLINE}>
      <CustomTable
        noPaper
        title={t('Rules for accumulated cards')}
        columns={columns}
        components={{
          Container: (containerProps: ContainerProps) => {
            const editedRowId = getEditedRow();
            const rowData: Array<DisciplinaryRule> = remove(
              editedRowId,
              isValue(editedRowId) ? 1 : 0,
              disciplineRules,
            );

            const handlePreviousClick = () => {
              actions.expansionPanelGroups.activatePreviousPanel({
                groupId: expansionPanelGroupId,
                panelIndex: expansionPanelIndex,
              });
            };

            return (
              <>
                <FormikMTContainer
                  noPaper
                  validationSchema={getValidationSchema(t, rowData)}
                  initialValues={getInitialValues()}
                  {...containerProps}
                />
                {expansionPanelGroupId && expansionPanelIndex && (
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Box p={1}>
                      <Button
                        variant="text"
                        onClick={handlePreviousClick}
                        startIcon={<NavigateBefore />}
                      >
                        {t('Previous')}
                      </Button>
                    </Box>
                    <Box p={1}>
                      <Button
                        disabled={!disciplineRules.length}
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            );
          },
        }}
        data={disciplineRules}
        editable={
          actionsDisabled
            ? undefined
            : {
                onRowAdd:
                  disciplineRules.length > 9
                    ? undefined
                    : async (newRule: any) => {
                        const data = formatRule(newRule);
                        actions.competitionCreation.createDisciplinaryRule({
                          competitionId,
                          data,
                        });
                      },
                onRowUpdate: async (newRule: any) => {
                  const ruleId = newRule.id;
                  const data = formatRule(newRule);
                  actions.competitionCreation.updateDisciplinaryRule({
                    competitionId,
                    ruleId,
                    data,
                  });
                },
                onRowDelete: async (rule: any) => {
                  actions.competitionCreation.deleteDisciplinaryRule({
                    competitionId,
                    ruleId: rule.id,
                  });
                },
              }
        }
        icons={{
          Add: forwardRef((iconProps, ref: React.Ref<HTMLDivElement>) => (
            <Button
              {...iconProps}
              ref={ref}
              component="div"
              variant="outlined"
              color="primary"
              data-qa="add-rule"
            >
              {t('Add Rule')}
            </Button>
          )),
        }}
        isLoading={isLoading || isSubmitting}
        isForm
        localization={{
          body: {
            emptyDataSourceMessage: isLoading
              ? ''
              : t('No rules for accumulated cards. Please add.'),
          },
        }}
        options={{ actionsColumnIndex: columns.length }}
        tableRef={tableRef}
      />
    </Box>
  );
};

const isSubmittingSelector = createLoadingSelector([
  UPDATE_COMPETITION_REQUEST,
  CREATE_DISCIPLINARY_RULE_REQUEST,
  UPDATE_DISCIPLINARY_RULE_REQUEST,
  DELETE_DISCIPLINARY_RULE_REQUEST,
]);

const isLoadingSelector = createLoadingSelector([
  GET_DISCIPLINARY_RULES_REQUEST,
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const competitionBeingModified = ownProps.competitionId
    ? { id: ownProps.competitionId }
    : getCompetitionBeingModified(state);

  return {
    competitionBeingModified,
    isSubmitting: isSubmittingSelector(state),
    isLoading: isLoadingSelector(state),
    disciplineRules: isValue(competitionBeingModified)
      ? getDisciplinaryRules(competitionBeingModified.id, state)
      : [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitions: bindActionCreators(competitionsActions, dispatch),
    competitionCreation: bindActionCreators(
      competitionCreationActions,
      dispatch,
    ),
    expansionPanelGroups: bindActionCreators(
      expansionPanelGroupsActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisciplinarySettingsTable);
