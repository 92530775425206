import { useSelector } from 'react-redux';
import { SportsList } from '@core/types';
import { State } from '@core/store';

import { getSportList } from '@core/store/modules/sports/selectors';

export function useSports() {
  const sports: SportsList =
    useSelector((state: State) => getSportList(state)) || [];
  const loading = !sports.length;

  return {
    sports,
    loading,
  };
}
