import { combineReducers } from 'redux';

import { reducer as tabsReducer } from '@core/store/modules/ui/tabs/reducers';
import { NAMESPACE as expansionPanelGroups } from '@core/store/modules/ui/expansion-panel-groups/constants';
import expansionPanelGroupsReducer from '@core/store/modules/ui/expansion-panel-groups/reducer';
import teamCreationReducer from '@core/store/modules/ui/team-creation/reducer';
import { NAMESPACE as teamCreation } from '@core/store/modules/ui/team-creation';
import competitionCreationReducer from '@core/store/modules/ui/competition-creation/reducer';
import { NAMESPACE as competitionCreation } from '@core/store/modules/ui/competition-creation/constants';
import matchOfficialAllocationsReducer from '@core/store/modules/ui/match-official-allocations/reducer';
import { NAMESPACE as matchOfficialAllocations } from '@core/store/modules/ui/match-official-allocations';

import menuReducer from './menu/reducer';
import { NAMESPACE as globalModal } from './global-modal';
import globalModalReducer from './global-modal/reducer';
import { NAMESPACE as filter } from './filter';
import filterReducer from './filter/reducer';
import { NAMESPACE as matchDataEntry } from './match-data-entry';
import matchDataEntryReducer from './match-data-entry/reducer';

export const staticUiReducers = {
  menu: menuReducer,
  tabs: tabsReducer,
  [expansionPanelGroups]: expansionPanelGroupsReducer,
  [competitionCreation]: competitionCreationReducer,
  [matchOfficialAllocations]: matchOfficialAllocationsReducer,
  [teamCreation]: teamCreationReducer,
  [globalModal]: globalModalReducer,
  [filter]: filterReducer,
  [matchDataEntry]: matchDataEntryReducer,
};

export default combineReducers(staticUiReducers);
