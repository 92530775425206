import {
  all,
  call,
  Effect,
  put,
  SagaReturnType,
  take,
  takeEvery,
} from 'redux-saga/effects';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  updateGroupSettings,
} from '@core/store/modules/api/groups/sagas';
import { actions as groupsActions } from '.';

export function* getGroupsFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(groupsActions.getGroups)) as InferAction<
      typeof groupsActions.getGroups
    >;

    yield put(groupsActions.getGroupsRequest());
    const { error, response } = (yield call(
      getGroups,
      payload,
    )) as SagaReturnType<typeof getGroups>;

    if (!error) {
      const { groups } = response._embedded;
      yield put(groupsActions.setGroups(groups));
      yield put(groupsActions.getGroupsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.getGroupsFailure());
    }
  }
}

export function* getGroupWorker({
  payload,
}: InferAction<typeof groupsActions.getGroup>): Generator<Effect, any, any> {
  yield put(groupsActions.getGroupRequest());
  const { error, response } = (yield call(getGroup, payload)) as SagaReturnType<
    typeof getGroup
  >;

  if (!error) {
    yield put(groupsActions.setGroupBeingModified(response));
    yield put(groupsActions.getGroupSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(groupsActions.getGroupFailure());
  }
}
export function* getGroupWatcher() {
  yield takeEvery(groupsActions.getGroup, getGroupWorker);
}

export function* createGroupFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(groupsActions.createGroup)) as InferAction<
      typeof groupsActions.createGroup
    >;

    yield put(groupsActions.createGroupRequest());

    const { error } = (yield call(
      createGroup,
      payload.competitionId,
      payload.data,
    )) as SagaReturnType<typeof createGroup>;
    if (!error) {
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId: payload.competitionId,
        }),
      ); // add stage to total
      yield put(tabActions.removeTab({ tabId: payload.tabId })); // switch to old tab
      yield put(groupsActions.createGroupSuccess());
      yield put(groupsActions.updateGroupSettingsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.createGroupFailure());
    }
  }
}

export function* updateGroupFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(groupsActions.updateGroup)) as InferAction<
      typeof groupsActions.updateGroup
    >;

    yield put(groupsActions.updateGroupRequest());
    const { error } = (yield call(
      updateGroup,
      payload.groupId,
      payload.data,
    )) as SagaReturnType<typeof updateGroup>;

    if (!error) {
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId: payload.competitionId,
        }),
      );
      // @ts-ignore
      yield getGroupWorker(
        groupsActions.getGroup({ groupId: payload.groupId }),
      );
      yield put(groupsActions.setActiveStep(1));
      yield put(groupsActions.updateGroupSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.updateGroupFailure());
    }
  }
}

export function* updateGroupSettingsFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(
      groupsActions.updateGroupSettings,
    )) as InferAction<typeof groupsActions.updateGroupSettings>;

    yield put(groupsActions.updateGroupSettingsRequest());
    const { error } = (yield call(
      updateGroupSettings,
      payload.groupId,
      payload.groupSettings,
    )) as SagaReturnType<typeof updateGroupSettings>;

    if (!error) {
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId: payload.competitionId,
        }),
      );
      yield put(tabActions.removeTab({ tabId: payload.tabId }));
      yield put(groupsActions.updateGroupSettingsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(groupsActions.updateGroupSettingsFailure());
    }
  }
}

export default function* saga() {
  yield all([
    getGroupsFlow(),
    getGroupWatcher(),
    createGroupFlow(),
    updateGroupFlow(),
    updateGroupSettingsFlow(),
  ]);
}
