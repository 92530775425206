export const NAMESPACE = 'refereeGroups';

export const SET_EXPANDED_GROUP = `${NAMESPACE}/SET_EXPANDED_GROUP`;
export const SET_GROUP_BEING_MODIFIED = `${NAMESPACE}/SET_GROUP_BEING_MODIFIED`;
export const SET_NEW_GROUP_BEING_CREATED = `${NAMESPACE}/SET_NEW_GROUP_BEING_CREATED`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;

export const ADD_MATCH_OFFICIAL_TO_GROUP = `${NAMESPACE}/ADD_MATCH_OFFICIAL_TO_GROUP`;
export const DELETE_MATCH_OFFICIAL_FROM_GROUP = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_FROM_GROUP`;

export const SET_MATCH_OFFICIAL_GROUPS = `${NAMESPACE}/SET_MATCH_OFFICIAL_GROUPS`;
export const SET_REFEREE_GROUPS = `${NAMESPACE}/SET_REFEREE_GROUPS`;
export const SET_REFEREE_GROUP = `${NAMESPACE}/SET_REFEREE_GROUP`;
export const SET_REFEREE_GROUP_TOTAL = `${NAMESPACE}/SET_REFEREE_GROUP_TOTAL`;
export const SET_MA_OFFICIAL_GROUPS = `${NAMESPACE}/SET_MA_OFFICIAL_GROUPS`;
export const SET_MA_OFFICIAL_GROUP = `${NAMESPACE}/SET_MA_OFFICIAL_GROUP`;
export const SET_MA_OFFICIAL_GROUP_TOTAL = `${NAMESPACE}/SET_MA_OFFICIAL_GROUP_TOTAL`;

export const GET_MATCH_OFFICIALS_IN_GROUP = `${NAMESPACE}/GET_MATCH_OFFICIALS_IN_GROUP`;
export const GET_MATCH_OFFICIALS_IN_GROUP_REQUEST = `${NAMESPACE}/GET_MATCH_OFFICIALS_IN_GROUP_REQUEST`;
export const GET_MATCH_OFFICIALS_IN_GROUP_SUCCESS = `${NAMESPACE}/GET_MATCH_OFFICIALS_IN_GROUP_SUCCESS`;
export const GET_MATCH_OFFICIALS_IN_GROUP_FAILURE = `${NAMESPACE}/GET_MATCH_OFFICIALS_IN_GROUP_FAILURE`;

export const GET_MATCH_OFFICIAL_GROUPS = `${NAMESPACE}/GET_MATCH_OFFICIAL_GROUPS`;
export const GET_MATCH_OFFICIAL_GROUPS_REQUEST = `${NAMESPACE}/GET_MATCH_OFFICIAL_GROUPS_REQUEST`;
export const GET_MATCH_OFFICIAL_GROUPS_SUCCESS = `${NAMESPACE}/GET_MATCH_OFFICIAL_GROUPS_SUCCESS`;
export const GET_MATCH_OFFICIAL_GROUPS_FAILURE = `${NAMESPACE}/GET_MATCH_OFFICIAL_GROUPS_FAILURE`;

export const SET_MATCH_OFFICIAL_GROUPS_RANK = `${NAMESPACE}/SET_MATCH_OFFICIAL_GROUPS_RANK`;
export const SET_MATCH_OFFICIAL_GROUPS_RANK_REQUEST = `${NAMESPACE}/SET_MATCH_OFFICIAL_GROUPS_RANK_REQUEST`;
export const SET_MATCH_OFFICIAL_GROUPS_RANK_SUCCESS = `${NAMESPACE}/SET_MATCH_OFFICIAL_GROUPS_RANK_SUCCESS`;
export const SET_MATCH_OFFICIAL_GROUPS_RANK_FAILURE = `${NAMESPACE}/SET_MATCH_OFFICIAL_GROUPS_RANK_FAILURE`;

export const SET_MATCH_OFFICIAL_RANK_IN_GROUP = `${NAMESPACE}/SET_MATCH_OFFICIAL_RANK_IN_GROUP`;
export const SET_MATCH_OFFICIAL_RANK_IN_GROUP_REQUEST = `${NAMESPACE}/SET_MATCH_OFFICIAL_RANK_IN_GROUP_REQUEST`;
export const SET_MATCH_OFFICIAL_RANK_IN_GROUP_SUCCESS = `${NAMESPACE}/SET_MATCH_OFFICIAL_RANK_IN_GROUP_SUCCESS`;
export const SET_MATCH_OFFICIAL_RANK_IN_GROUP_FAILURE = `${NAMESPACE}/SET_MATCH_OFFICIAL_RANK_IN_GROUP_FAILURE`;

export const CREATE_MATCH_OFFICIAL_GROUP = `${NAMESPACE}/CREATE_MATCH_OFFICIAL_GROUP`;
export const CREATE_MATCH_OFFICIAL_GROUP_REQUEST = `${NAMESPACE}/CREATE_MATCH_OFFICIAL_GROUP_REQUEST`;
export const CREATE_MATCH_OFFICIAL_GROUP_SUCCESS = `${NAMESPACE}/CREATE_MATCH_OFFICIAL_GROUP_SUCCESS`;
export const CREATE_MATCH_OFFICIAL_GROUP_FAILURE = `${NAMESPACE}/CREATE_MATCH_OFFICIAL_GROUP_FAILURE`;

export const UPDATE_MATCH_OFFICIAL_GROUP = `${NAMESPACE}/UPDATE_MATCH_OFFICIAL_GROUP`;
export const UPDATE_MATCH_OFFICIAL_GROUP_REQUEST = `${NAMESPACE}/UPDATE_MATCH_OFFICIAL_GROUP_REQUEST`;
export const UPDATE_MATCH_OFFICIAL_GROUP_SUCCESS = `${NAMESPACE}/UPDATE_MATCH_OFFICIAL_GROUP_SUCCESS`;
export const UPDATE_MATCH_OFFICIAL_GROUP_FAILURE = `${NAMESPACE}/UPDATE_MATCH_OFFICIAL_GROUP_FAILURE`;

export const DELETE_MATCH_OFFICIAL_GROUP = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_GROUP`;
export const DELETE_MATCH_OFFICIAL_GROUP_REQUEST = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_GROUP_REQUEST`;
export const DELETE_MATCH_OFFICIAL_GROUP_SUCCESS = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_GROUP_SUCCESS`;
export const DELETE_MATCH_OFFICIAL_GROUP_FAILURE = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_GROUP_FAILURE`;

export const DELETE_MATCH_OFFICIAL_GROUP_URL = `${NAMESPACE}/DELETE_MATCH_OFFICIAL_GROUP_URL`;

export const ALL_GROUPS = 'ALL';
