import type { MouseEvent } from 'react';
import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
import { TABLE_CHECKBOX } from '@core/components/table-with-select/tests/test-ids';
import type { Column } from './types';

const TableWithActionsRow = ({
  callback,
  columns,
  disabled: globaDisabled,
  isItemSelected,
  rowData,
}: {
  callback: (event: any, id: number) => void;
  columns: Array<Column>;
  disabled: boolean;
  isItemSelected: boolean;
  rowData: any;
}) => {
  const disabled = globaDisabled || rowData.isDisabled;

  const handleClick = (event: MouseEvent) => {
    if (disabled) {
      return;
    }

    callback(event, rowData.id);
  };

  return (
    <TableRow key={rowData.id}>
      <TableCell
        padding="checkbox"
        onClick={(event) => handleClick(event)}
        style={{ padding: 0 }}
      >
        <Checkbox
          checked={isItemSelected}
          disabled={disabled}
          data-qa={TABLE_CHECKBOX}
        />
      </TableCell>
      {columns?.map((column: Column) => (
        <TableCell
          style={
            column.name === 'columns'
              ? {
                  cursor: !disabled ? 'pointer' : 'unset',
                  width: '100%',
                  padding: 0,
                }
              : { padding: 0 }
          }
          onClick={column.name === 'columns' ? handleClick : null}
          key={column.name}
          align={column.align}
          padding="none"
        >
          <Box
            id={column.name}
            visibility={column.name === 'actions' ? 'hidden' : 'inherit'}
          >
            {column.render(rowData)}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableWithActionsRow;
