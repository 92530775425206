import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as testIds from './tests/test-ids';

interface Props {
  name: string;
  value: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 350,
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(1),
    },
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const NamedValue = ({ name, value }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Typography data-qa={testIds.NAMED_VALUE_NAME} variant="caption">
        {`${name}:`}
      </Typography>
      <Typography
        data-qa={testIds.NAMED_VALUE_VALUE}
        className={classes.value}
        variant="caption"
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default NamedValue;
