import { api } from '@core/services';
import {
  KnockoutStandingsBrackets,
  KnockoutStandingsBracketsResponse,
} from '@core/types/api/knockout-standing-brackets';

const namespace = 'groups';

export const groupsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBrackets: build.query<KnockoutStandingsBrackets, number>({
      query: (groupId) => `${namespace}/${groupId}/brackets`,
      providesTags: ['Brackets'],
      transformResponse: (response: KnockoutStandingsBracketsResponse) =>
        response._embedded,
    }),
  }),
});

export const { useGetBracketsQuery } = groupsApi;
