import { createActions } from 'redux-actions';
import { MatchModel } from '@core/types';

export const NAMESPACE = 'appointmentsList';

export const actions = createActions(
  'GET_APPOINTMENTS_REQUEST',
  'GET_APPOINTMENTS_SUCCESS',
  'GET_APPOINTMENTS_FAILURE',

  'GET_APPOINTMENTS_BY_GROUP_REQUEST',
  'GET_APPOINTMENTS_BY_GROUP_SUCCESS',
  'GET_APPOINTMENTS_BY_GROUP_FAILURE',

  'GET_APPOINTMENT_REQUEST',
  'GET_APPOINTMENT_SUCCESS',
  'GET_APPOINTMENT_FAILURE',

  'REFRESH_APPOINTMENT_REQUEST',
  'REFRESH_APPOINTMENT_SUCCESS',
  'REFRESH_APPOINTMENT_FAILURE',

  'UPDATE_APPOINTMENT_REQUEST',
  'UPDATE_APPOINTMENT_SUCCESS',
  'UPDATE_APPOINTMENT_FAILURE',

  'CHANGE_APPOINTMENT_STATUS_REQUEST',
  'CHANGE_APPOINTMENT_STATUS_SUCCESS',
  'CHANGE_APPOINTMENT_STATUS_FAILURE',

  'GET_APPOINTMENTS_BY_COMPETITION_REQUEST',
  'GET_APPOINTMENTS_BY_COMPETITION_SUCCESS',
  'GET_APPOINTMENTS_BY_COMPETITION_FAILURE',

  'SET_APPOINTMENTS',
  'SET_APPOINTMENT',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getAppointmentsRequest: RequestLifecycleAction;
  getAppointmentsSuccess: RequestLifecycleAction;
  getAppointmentsFailure: RequestLifecycleAction;

  getAppointmentsByGroupRequest: RequestLifecycleAction<{ id: number }>;
  getAppointmentsByGroupSuccess: RequestLifecycleAction<{ id: number }>;
  getAppointmentsByGroupFailure: RequestLifecycleAction<{ id: number }>;

  getAppointmentRequest: RequestLifecycleAction;
  getAppointmentSuccess: RequestLifecycleAction;
  getAppointmentFailure: RequestLifecycleAction;

  refreshAppointmentRequest: RequestLifecycleAction;
  refreshAppointmentSuccess: RequestLifecycleAction;
  refreshAppointmentFailure: RequestLifecycleAction;

  updateAppointmentRequest: RequestLifecycleAction;
  updateAppointmentSuccess: RequestLifecycleAction;
  updateAppointmentFailure: RequestLifecycleAction;

  changeAppointmentStatusRequest: RequestLifecycleAction;
  changeAppointmentStatusSuccess: RequestLifecycleAction;
  changeAppointmentStatusFailure: RequestLifecycleAction;

  getAppointmentsByCompetitionRequest: RequestLifecycleAction<{ id: number }>;
  getAppointmentsByCompetitionSuccess: RequestLifecycleAction<{ id: number }>;
  getAppointmentsByCompetitionFailure: RequestLifecycleAction<{ id: number }>;

  setAppointments: IdentityActionFunction<Array<MatchModel>>;
  setAppointment: IdentityActionFunction<MatchModel>;
};
