import React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { FilterTabProps, RegistrationTypes } from '@core/types';
import { createFilterId, getRegistrationTypes } from '@core/helpers';
import { FilterCheckboxList } from '@core/components';

import { MEMBER_TYPE_FILTER } from '../constants';

type Props = FilterTabProps;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const MemberTypeFilter = (props: Props) => {
  const { t } = useTranslation();
  const { filters, ...rest } = props;
  const classes = useStyles();
  const filterId = createFilterId(MEMBER_TYPE_FILTER);
  const filterValues = filters[filterId]?.filterValues || [];

  function formatFilterLabel(val: string) {
    return getRegistrationTypes(t, val as RegistrationTypes);
  }

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Type')}
      </Typography>
      <FilterCheckboxList
        list={Object.values(RegistrationTypes)}
        filterProps={MEMBER_TYPE_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default MemberTypeFilter;
