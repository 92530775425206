import { Navigate } from 'react-router';
import { FullScreenSpinner } from '@core/components';
import { useAuth0HandleRedirectCallback } from '@core/helpers/auth';

const Authenticated = () => {
  const { pathnameToRedirect } = useAuth0HandleRedirectCallback();

  if (!pathnameToRedirect) {
    return <FullScreenSpinner absolute />;
  }

  return <Navigate to={pathnameToRedirect} />;
};

export default Authenticated;
