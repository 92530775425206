import restService from '@core/api/rest-service';
import {
  MatchSet,
  MatchSetsResponse,
  PatchMatchSetPayload,
  PostMatchSetPayload,
} from '@core/types';

export const getMatchSets = (matchId: number) =>
  restService.get<MatchSetsResponse>(`/v1/matches/${matchId}/sets`);

export const createMatchSet = (
  matchId: number,
  matchSet: PostMatchSetPayload,
) => restService.post<MatchSet>(`/v1/matches/${matchId}/sets`, matchSet);

export const updateMatchSets = (matchId: number, matchSets: Array<MatchSet>) =>
  restService.put<void>(`/v1/matches/${matchId}/sets`, matchSets);

export const updateSet = (setId: number, matchSet: PatchMatchSetPayload) =>
  restService.patch<void>(`/v1/sets/${setId}`, matchSet);

export const deleteSet = (setId: number) =>
  restService.delete<void>(`/v1/sets/${setId}`);
