import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { State } from '@volleyball/store';
import { GroupFixtureSettings, APIErrorsModel } from '@volleyball/types';

import { NAMESPACE } from './constants';

export const getSettings = (state: State, { groupId }: { groupId: number }) =>
  pathOr([], ['tabs', NAMESPACE, groupId], state);

export const getStageFixtureSettings = createSelector(
  getSettings,
  (settings): GroupFixtureSettings => {
    return pathOr(null, ['groupFixtureSettings'], settings);
  },
);

export const getApiErrors = createSelector(
  getSettings,
  (settings): APIErrorsModel => {
    return pathOr(null, ['errors'], settings);
  },
);
