import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  Theme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { CustomerResponse } from '@core/types';
import { State } from '@core/store';

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  firstRowElements: Array<string>;
  secondRowElements?: Array<string>;
  values: SummaryBlockValues;
}

type Props = StateProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  panel: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '4px',
  },
  panelSummary: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  textSeperators: {
    '& span:not(:last-child)::after': {
      content: '"\\2022"',
      fontSize: theme.typography.caption.fontSize,
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const printValues = (values: Array<string>) => (
  <Grid container item>
    {values?.map((val, index) => (
      <Typography variant="caption" key={index}>
        {val}
      </Typography>
    ))}
  </Grid>
);

const SummaryBlockExpandable = (props: Props) => {
  const { values, firstRowElements, secondRowElements } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) =>
    (event: React.ChangeEvent<Record<string, any>>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      square
      elevation={0}
      expanded={expanded === 'panel'}
      onChange={handleChange('panel')}
      className={classes.panel}
    >
      <AccordionSummary
        className={classes.panelSummary}
        expandIcon={<ExpandMore />}
      >
        {expanded === 'panel' ? (
          <SummaryBlock values={values} backgroundColor="transparent" />
        ) : (
          <Box my={1} ml={1}>
            <Grid
              container
              direction="column"
              className={classes.textSeperators}
            >
              {printValues(firstRowElements)}
              {printValues(secondRowElements)}
            </Grid>
          </Box>
        )}
      </AccordionSummary>
    </Accordion>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(SummaryBlockExpandable);
