import { styled } from '@mui/material/styles';
import {
  SnackbarProvider,
  SnackbarProviderProps,
  MaterialDesignContent,
} from 'notistack';
import { SNACKBAR } from '../tests/test-ids';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

export const SnackbarProviderWithStyle = ({
  maxSnack,
  children,
}: SnackbarProviderProps) => (
  <SnackbarProvider
    maxSnack={maxSnack}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
    }}
    SnackbarProps={{
      'data-qa': SNACKBAR,
    }}
  >
    {children}
  </SnackbarProvider>
);
