import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getMatchDataHistory } from '@core/api/match-data-history';
import { actions } from '.';

export function* getMatchDataHistoryFlowWatcher() {
  yield takeEvery(actions.getMatchDataHistory, getMatchDataHistoryFlow);
}

export function* getMatchDataHistoryFlow(action: any) {
  const { matchId } = action.payload;

  yield put(actions.getMatchDataHistoryRequest());

  const { error, response } = yield call(getMatchDataHistory, matchId);

  if (!error) {
    yield put(actions.getMatchDataHistorySuccess());
    yield put(
      actions.setMatchDataHistory({
        payload: response._embedded.matchDataHistory,
        matchId,
      }),
    );
  } else {
    yield put(actions.getMatchDataHistoryFailure());
  }
}

export default function* saga() {
  yield all([getMatchDataHistoryFlowWatcher()]);
}
