import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Theme } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { truncateString } from '@core/helpers';

import * as testIds from '../tests/test-ids';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    height: 15,
    width: 15,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface TabProps {
  isClosable: boolean;
  title: string;
  onRemove: (props: {
    index: number;
    tabId: string;
    isTouched: boolean;
    onRemoveCallback: any;
  }) => void;
  index: number;
  isSelected: boolean;
  isTouched: boolean;
  tabId: string;
  onRemoveCallback?: any;
}

export const TabLabel = React.forwardRef<HTMLDivElement, TabProps>(
  (props: TabProps, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
      isClosable,
      title,
      onRemove,
      index,
      isSelected,
      isTouched,
      tabId,
      onRemoveCallback,
    } = props;

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
      event.stopPropagation();
      onRemove({ index, tabId, isTouched, onRemoveCallback });
    };

    return (
      <Grid ref={ref} className={classes.root} data-qa={testIds.TAB}>
        <span className={classes.title} title={title}>
          {truncateString(title, 20)}
        </span>
        {isClosable && (
          <IconButton
            title={t('Close')}
            onClick={handleClose}
            size="small"
            className={classes.button}
            data-qa={testIds.TAB_CLOSE_BUTTON}
          >
            <Cancel
              color={isSelected ? 'primary' : 'action'}
              className={classes.icon}
            />
          </IconButton>
        )}
      </Grid>
    );
  },
);
