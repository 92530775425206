import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { PaginationProps } from '@core/components/custom-table';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { TeamOfficialList } from '@core/types';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';
import {
  getClubTeamOfficials,
  getCompetitorTeamOfficials,
  getAddedCompetitorTeamOfficials,
  getClubTeamOfficialsPagination,
} from '@core/store/modules/tabs/player-list-management/selectors';
import { State } from '@core/store';

import ClubTeamOfficialsTable, {
  getSearchClubTeamOfficialsQueryParams,
} from './club-team-officials-table';
import CompetitorTeamOfficialsTable from './competitor-team-officials-table';

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
  };
}

interface StateProps {
  clubTeamOfficials: TeamOfficialList;
  clubTeamOfficialsPagination: PaginationProps;
  competitorTeamOfficials: TeamOfficialList;
  addedCompetitorTeamOfficials: TeamOfficialList;
  areClubTeamOfficialsLoading: boolean;
}

interface OwnProps {
  clubId: number;
  competitorId: number;
  areCompetitorTeamOfficialsLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const TeamOfficialsTab = (props: Props) => {
  const {
    clubTeamOfficials,
    clubTeamOfficialsPagination,
    addedCompetitorTeamOfficials,
    actions,
    clubId,
    competitorId,
    areCompetitorTeamOfficialsLoading,
    areClubTeamOfficialsLoading,
  } = props;

  useEffect(() => {
    actions.playerListManagement.searchClubTeamOfficials({
      clubId,
      queryParams: getSearchClubTeamOfficialsQueryParams({ clubId }),
    });

    return () => {
      actions.playerListManagement.resetClubTeamOfficials({ clubId });
    };
  }, []);

  const handleAddedCompetitorTeamOfficialsChange = (
    teamOfficials: TeamOfficialList,
  ) => {
    actions.playerListManagement.setAddedCompetitorTeamOfficials({
      payload: teamOfficials,
      competitorId,
    });
  };

  const onPageChange = (page: number) => {
    actions.playerListManagement.searchClubTeamOfficials({
      clubId,
      queryParams: getSearchClubTeamOfficialsQueryParams({ clubId, page }),
    });
  };

  return (
    <Grid container spacing={3}>
      <ClubTeamOfficialsTable
        isLoading={
          areClubTeamOfficialsLoading || areCompetitorTeamOfficialsLoading
        }
        onPageChange={onPageChange}
        clubTeamOfficials={clubTeamOfficials}
        clubTeamOfficialsPagination={clubTeamOfficialsPagination}
        addedCompetitorTeamOfficials={addedCompetitorTeamOfficials}
        updateTeamOfficialsList={handleAddedCompetitorTeamOfficialsChange}
        clubId={clubId}
      />
      <CompetitorTeamOfficialsTable
        isLoading={areCompetitorTeamOfficialsLoading}
        addedCompetitorTeamOfficials={addedCompetitorTeamOfficials}
        updateTeamOfficialsList={handleAddedCompetitorTeamOfficialsChange}
      />
    </Grid>
  );
};

const clubTeamOfficialsLoadingSelector = createLoadingSelector([
  playerListManagementActions.searchClubTeamOfficialsRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  clubTeamOfficials: getClubTeamOfficials(ownProps.clubId)(state),
  clubTeamOfficialsPagination: getClubTeamOfficialsPagination(ownProps.clubId)(
    state,
  ),
  competitorTeamOfficials: getCompetitorTeamOfficials(ownProps.competitorId)(
    state,
  ),
  addedCompetitorTeamOfficials: getAddedCompetitorTeamOfficials(
    ownProps.competitorId,
  )(state),
  areClubTeamOfficialsLoading: clubTeamOfficialsLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamOfficialsTab);
