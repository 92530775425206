import { pathOr } from 'ramda';

import { MatchPlayerOrigin } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

interface GetPlayersProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
}
interface GetMatchSheetHistoryProps {
  matchId: number;
  competitorId: number;
}

export const getAddedMatchLineupPlayers = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'addedLineupPlayers'],
    state,
  );

export const getAvailableLineupCompetitorPlayers = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      matchId,
      matchPlayerOrigin,
      'availableLineupCompetitorPlayers',
    ],
    state,
  );

export const getAvailableSubstituteCompetitorPlayers = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      matchId,
      matchPlayerOrigin,
      'availableSubstituteCompetitorPlayers',
    ],
    state,
  );

export const getMatchPlayers = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'matchPlayers'],
    state,
  );

export const getLineupPlayersDataErrors = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'addedLineupPlayersErrors'],
    state,
  );

export const getAddedMatchTeamOfficials = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'addedTeamOfficials'],
    state,
  );

export const getMatchTeamOfficials = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'matchTeamOfficials'],
    state,
  );

export const getAvailableCompetitorTeamOfficials = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      matchId,
      matchPlayerOrigin,
      'availableCompetitorTeamOfficials',
    ],
    state,
  );

export const getMatchDetails = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, matchPlayerOrigin, 'matchDetails'],
    state,
  );

export const getPreviousMatchPlayersTotal = (
  state: State,
  { matchId, matchPlayerOrigin }: GetPlayersProps,
) =>
  pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      matchId,
      matchPlayerOrigin,
      'previousMatchPlayersTotal',
    ],
    state,
  );

export const getMatchSheetHistory = (
  state: State,
  { matchId, competitorId }: GetMatchSheetHistoryProps,
) =>
  pathOr(
    null,
    ['tabs', NAMESPACE, matchId, competitorId, 'matchSheetHistory'],
    state,
  );
