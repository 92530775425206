import { createActions } from 'redux-actions';

export const NAMESPACE = 'matchOfficialRole';

export const actions = createActions(
  'GET_MATCH_OFFICIAL_ROLE_REQUEST',
  'GET_MATCH_OFFICIAL_ROLE_SUCCESS',
  'GET_MATCH_OFFICIAL_ROLE_FAILURE',
  { prefix: NAMESPACE },
);
