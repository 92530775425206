import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon, Cancel } from '@mui/icons-material';
import clsx from 'clsx';

import { useDebouncedCallback } from '@core/hooks';

interface StateProps {
  onSearch: (props: { query: string; page: number }) => void;
  query: string;
  debounceMs?: number;
}

type Props = StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
  iconCancel: {
    color: theme.palette.grey[500],
    height: 17,
    width: 17,
  },
  iconCancelHidden: {
    visibility: 'hidden',
  },
  endAdornment: {
    paddingRight: theme.spacing(1),
  },
  startAdornment: {
    paddingLeft: theme.spacing(2),
  },
}));

const SearchField = ({ debounceMs, query, onSearch }: Props) => {
  const [value, setValue] = useState(query);
  const classes = useStyles();
  const { t } = useTranslation();
  const prevValue = useRef(null);

  const debouncedCallback = useDebouncedCallback((searchQuery) => {
    onSearch({ query: searchQuery, page: 1 });
  }, debounceMs ?? 200);

  useEffect(() => {
    prevValue.current !== null && debouncedCallback.callback(value);
    prevValue.current = value;
  }, [value]);

  const handleReset = () => {
    debouncedCallback.callback('');
    setValue('');
    prevValue.current = '';
  };

  return (
    <Grid>
      <TextField
        variant="outlined"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder={t('Search')}
        data-qa="search"
        classes={{
          root: classes.root,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon classes={{ root: classes.searchIcon }} />
            </InputAdornment>
          ),
          endAdornment: (
            <Grid className={clsx({ [classes.iconCancelHidden]: !query })}>
              <IconButton size="small" onClick={handleReset}>
                <Cancel classes={{ root: classes.iconCancel }} />
              </IconButton>
            </Grid>
          ),
          classes: {
            adornedEnd: classes.endAdornment,
            adornedStart: classes.startAdornment,
          },
        }}
        fullWidth
      />
    </Grid>
  );
};

export default SearchField;
