import { handleActions } from 'redux-actions';
import { assocPath, assoc, dissocPath } from 'ramda';

import { GroupFixtureSettings, APIErrorsModel } from '@volleyball/types';

import {
  ADD_GROUP_FIXTURE_SETTINGS,
  SET_ERRORS,
  CLEAR_GROUP_FIXTURE_SETTINGS,
} from './constants';

export interface DefaultState {
  allGroupsFixtureSettingsById: {
    [groupFixtureSettingsId: number]: GroupFixtureSettings;
  };
  errors: APIErrorsModel;
}

export const defaultState: DefaultState = {
  allGroupsFixtureSettingsById: {},
  errors: null,
};

const reducer = handleActions<any>(
  {
    [ADD_GROUP_FIXTURE_SETTINGS]: (state, { payload }) =>
      assocPath(['allGroupsFixtureSettingsById', payload.id], payload, state),
    [CLEAR_GROUP_FIXTURE_SETTINGS]: (state, { payload }) =>
      dissocPath(['allGroupsFixtureSettingsById', payload], state),
    [SET_ERRORS]: (state, { payload }) => assoc('errors', payload, state),
  },
  defaultState,
);

export default reducer;
