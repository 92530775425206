import React from 'react';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  label: string;
  regular?: boolean;
  value: string | number | React.ReactElement;
  actionButton?: React.ReactElement;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  value: {
    fontWeight: ({ regular }) => (regular ? 0 : 'bold'),
  },
  valueWrapper: {
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  mainWrapper: {
    display: ({ actionButton }) => (actionButton ? 'flex' : 'block'),
  },
}));

const NamedValueColumn = ({ label, value, regular, actionButton }: Props) => {
  const classes = useStyles({ label, value, regular, actionButton });

  return (
    <Grid className={classes.mainWrapper}>
      <Grid className={classes.root}>
        <Grid>
          <Typography color="textSecondary" variant="body2">
            {label}
          </Typography>
        </Grid>
        <Grid className={classes.valueWrapper}>
          {typeof value === 'object' ? (
            value || '-'
          ) : (
            <Typography className={classes.value} variant="body2">
              {value || '-'}
            </Typography>
          )}
        </Grid>
      </Grid>
      {!!actionButton && <Box ml={1}>{actionButton}</Box>}
    </Grid>
  );
};

export default NamedValueColumn;
