import { call, Effect, put } from 'redux-saga/effects';

import * as competitionContactsApi from '@core/api/competition-contacts';
import { getCompetition } from '@core/pages/competitions/store/api/sagas';

import { actions } from '.';

export function* getCompetitionContacts(
  competitionId: number,
): Generator<Effect, any, any> {
  yield put(actions.getCompetitionContactsRequest());
  const response = yield call(
    competitionContactsApi.getCompetitionContactList,
    competitionId,
  );

  if (!response.error) {
    yield put(actions.getCompetitionContactsSuccess());
  } else {
    yield put(actions.getCompetitionContactsFailure());
  }

  return response;
}

export function* createCompetitionContact(
  competitionId: number,
  data: any,
): Generator<Effect, any, any> {
  yield put(actions.createCompetitionContactRequest());
  const response = yield call(
    competitionContactsApi.createCompetitionContact,
    competitionId,
    data,
  );

  if (!response.error) {
    yield put(actions.createCompetitionContactSuccess());
    yield call(getCompetition, competitionId);
  } else {
    yield put(actions.createCompetitionContactFailure());
  }

  return response;
}

export function* updateCompetitionContact(
  competitionId: number,
  contactId: number,
  data: any,
): Generator<Effect, any, any> {
  yield put(actions.updateCompetitionContactRequest());
  const response = yield call(
    competitionContactsApi.updateCompetitionContact,
    contactId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateCompetitionContactSuccess());
    yield call(getCompetition, competitionId);
  } else {
    yield put(actions.updateCompetitionContactFailure());
  }

  return response;
}

export function* deleteCompetitionContact(
  competitionId: number,
  contactId: number,
): Generator<Effect, any, any> {
  yield put(actions.deleteCompetitionContactRequest());
  const response = yield call(
    competitionContactsApi.deleteCompetitionContact,
    contactId,
  );

  if (!response.error) {
    yield put(actions.deleteCompetitionContactSuccess());
    yield call(getCompetition, competitionId);
  } else {
    yield put(actions.deleteCompetitionContactFailure());
  }

  return response;
}
