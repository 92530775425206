import restService from '@core/api/rest-service';
import {
  GetAvailabilitiesResponse,
  CreateAvailabilityData,
  AvailabilityObject,
} from '@core/types';

export const getAvailabilities = (object: AvailabilityObject, id: number) =>
  restService.get<GetAvailabilitiesResponse>(
    `/v1/${object}/${id}/availabilities`,
  );

export const createAvailability = (
  object: AvailabilityObject,
  id: number,
  data: CreateAvailabilityData,
) => restService.post(`/v1/${object}/${id}/availabilities`, data);

export const getAvailability = (object: AvailabilityObject, id: number) =>
  restService.get<GetAvailabilitiesResponse>(
    `/v1/${object}/availabilities/${id}`,
  );

export const updateAvailability = (
  object = AvailabilityObject.GROUPS,
  id: number,
  data: Partial<CreateAvailabilityData>,
) => restService.put(`/v1/${object}/availabilities/${id}`, data);

export const deleteAvailability = (object: AvailabilityObject, id: number) =>
  restService.delete(`/v1/${object}/availabilities/${id}`);
