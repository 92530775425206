import React from 'react';
import { Grid, Typography, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  title?: string;
  children: any;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const ModalFieldsBlock = (props: Props) => {
  const { children, title } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid container>
        {title && (
          <Grid item xs={12} md={3}>
            <Box mb={2}>
              <Typography color="textSecondary">{title}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={title ? 9 : 12}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalFieldsBlock;
