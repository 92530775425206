import type { ComponentType } from 'react';
import { Box } from '@mui/material';
import { TabProps, FilterTab } from '@core/types';

type Props = {
  id?: number;
  currentTab: string;
  tabs: Array<TabProps & Omit<FilterTab, 'filterIds'>>;
  props?: any;
  mt?: number | string;
  classes?: {
    root?: string;
  };
};

export const TabPanel = ({
  currentTab,
  tabs,
  id,
  props,
  classes = {},
  mt = 3,
}: Props) => {
  const currentTabValues = tabs.find((tab) => tab.value === currentTab);
  const CurrentTabPanel = currentTabValues.component as ComponentType;

  return (
    <Box mt={mt} className={classes.root}>
      <CurrentTabPanel
        id={id}
        initialQueryParams={currentTabValues.initialQueryParams}
        {...currentTabValues.componentProps}
        {...props}
      />
    </Box>
  );
};

export default TabPanel;
