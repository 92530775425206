import React from 'react';
import {
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileUploadButton, FullScreenSpinner } from '@core/components';
import { Alert } from '@ui-components';

interface OwnProps {
  title: string;
  handleFileSelect: (val: any) => void;
  isLoading?: boolean;
  label?: string;
  alertText?: string;
  setSelectedFiles: (file: Array<File>) => void;
  selectedFiles: Array<File>;
  handleClose: () => void;
  accept?: string;
  submitLabel?: string;
  cancelLabel?: string;
  additionalCheck?: React.ReactNode;
}

type Props = OwnProps;

const ContentDialog = (props: Props) => {
  const { t } = useTranslation();
  const {
    title,
    handleFileSelect,
    isLoading,
    label,
    alertText,
    setSelectedFiles,
    selectedFiles,
    handleClose,
    accept,
    submitLabel = t('Import'),
    cancelLabel = t('Cancel'),
    additionalCheck,
  } = props;

  const uploadButtonComponent = (btnProps: any) => (
    <Button
      color="primary"
      variant="contained"
      disabled={isLoading}
      {...btnProps}
    >
      {t('Select file')}
    </Button>
  );

  const [selectedFile] = selectedFiles;

  function handleImport() {
    handleFileSelect(selectedFile);
  }

  function handleModalClose() {
    handleClose();
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <DialogContent>
          {alertText && <Alert title={alertText} warning />}
          {label && (
            <Box mt={2} mb={1}>
              <DialogContentText>{label}</DialogContentText>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <FileUploadButton
              buttonComponent={uploadButtonComponent}
              files={selectedFiles}
              setSelectedFile={setSelectedFiles}
              accept={accept}
            />
          </Box>
          {additionalCheck && (
            <Box mt={2} mb={2}>
              {additionalCheck}
            </Box>
          )}
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleModalClose} disabled={isLoading}>
              {cancelLabel}
            </Button>
          </Box>
          <Button
            onClick={handleImport}
            color="primary"
            variant="contained"
            disabled={!selectedFile || isLoading}
          >
            {submitLabel}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default ContentDialog;
