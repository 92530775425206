export interface File {
  userFirstName: string;
  userLastName: string;
  id: number;
  realName: string;
  mimeType: string;
  type: string;
  dateTime: string;
  size: number;
}

export interface FilesResponse {
  headers: [
    {
      name: string;
      label: string;
      entityFieldAttributes: [
        {
          attribute: number;
          value: number;
        },
      ];
    },
  ];
  _embedded: { files: Array<File> };
  _links: {
    self: {
      href: string;
    };
  };
}

export enum FileType {
  Logo = 'logo',
  Doc = 'doc',
}

export enum ObjectType {
  Competitions = 'competitions',
  Venues = 'venues',
  Clubs = 'clubs',
  Teams = 'teams',
  Competitors = 'competitors',
  Persons = 'persons',
  Users = 'users',
  Matches = 'matches',
}
