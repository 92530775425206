import { createAction } from 'redux-actions';

import * as REFEREE_CATEGORY_ACTION_TYPES from './constants';

export const setBeingModified = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.SET_CATEGORY_BEING_MODIFIED,
);
export const setNewBeingCreated = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.SET_NEW_CATEGORY_BEING_CREATED,
);
export const setErrors = createAction(REFEREE_CATEGORY_ACTION_TYPES.SET_ERRORS);
export const setRefereeCategories = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.SET_REFEREE_CATEGORIES,
);

export const getRefereeCategories = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.GET_REFEREE_CATEGORIES,
);
export const getRefereeCategoriesRequest = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.GET_REFEREE_CATEGORIES_REQUEST,
);
export const getRefereeCategoriesSuccess = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.GET_REFEREE_CATEGORIES_SUCCESS,
);
export const getRefereeCategoriesFailure = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.GET_REFEREE_CATEGORIES_FAILURE,
);

export const createRefereeCategory = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.CREATE_REFEREE_CATEGORY,
);
export const createRefereeCategoryRequest = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.CREATE_REFEREE_CATEGORY_REQUEST,
);
export const createRefereeCategorySuccess = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.CREATE_REFEREE_CATEGORY_SUCCESS,
);
export const createRefereeCategoryFailure = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.CREATE_REFEREE_CATEGORY_FAILURE,
);

export const updateRefereeCategory = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.UPDATE_REFEREE_CATEGORY,
);
export const updateRefereeCategoryRequest = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.UPDATE_REFEREE_CATEGORY_REQUEST,
);
export const updateRefereeCategorySuccess = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.UPDATE_REFEREE_CATEGORY_SUCCESS,
);
export const updateRefereeCategoryFailure = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.UPDATE_REFEREE_CATEGORY_FAILURE,
);

export const deleteRefereeCategory = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.DELETE_REFEREE_CATEGORY,
);
export const deleteRefereeCategoryRequest = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.DELETE_REFEREE_CATEGORY_REQUEST,
);
export const deleteRefereeCategorySuccess = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.DELETE_REFEREE_CATEGORY_SUCCESS,
);
export const deleteRefereeCategoryFailure = createAction(
  REFEREE_CATEGORY_ACTION_TYPES.DELETE_REFEREE_CATEGORY_FAILURE,
);
