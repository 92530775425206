import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, Chip, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldArray } from 'formik';
import { Check } from '@mui/icons-material';

import { Sports, Sport } from '@core/types';
import { getSports } from '@core/helpers';

export interface SportOption extends Sport {
  disabled?: boolean;
}

interface OwnProps {
  areSportsLoading: boolean;
  sports: Array<SportOption>;
  fieldName: string;
  allowMultiple?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SportsSelection = (props: Props) => {
  const { areSportsLoading, sports, fieldName, allowMultiple = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={1}>
      {areSportsLoading ? (
        <CircularProgress size={18} />
      ) : (
        <FieldArray
          name={fieldName}
          render={(arrayHelpers) => {
            return (
              <>
                {sports?.map((sport: SportOption) => {
                  const entryModel = { id: sport?.id, type: sport?.type };
                  const allSports = arrayHelpers?.form?.values[fieldName];

                  const existingIndex = allSports
                    ? allSports.findIndex(
                        (entry: { id: number; type: Sports }) =>
                          entry.id === sport?.id,
                      )
                    : -1;

                  const handleClick = () => {
                    if (!allowMultiple) {
                      arrayHelpers.form.setFieldValue(
                        fieldName,
                        existingIndex >= 0 ? [] : [entryModel],
                      );

                      return;
                    }

                    if (existingIndex >= 0) {
                      arrayHelpers.remove(existingIndex);

                      return;
                    }
                    arrayHelpers.push(entryModel);
                  };

                  return (
                    <Chip
                      icon={
                        allowMultiple && allSports && existingIndex >= 0 ? (
                          <Box display="flex">
                            <Check fontSize="small" />
                          </Box>
                        ) : (
                          <></>
                        )
                      }
                      disabled={sport?.disabled}
                      data-qa={`${fieldName}-${sport?.type}`}
                      key={sport?.id}
                      label={getSports(t, sport?.type)}
                      color={
                        allSports && existingIndex >= 0 ? 'primary' : 'default'
                      }
                      onClick={handleClick}
                      className={classes.chip}
                    />
                  );
                })}
              </>
            );
          }}
        />
      )}
    </Box>
  );
};

export default SportsSelection;
