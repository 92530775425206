import { DISCIPLINE_OFFENDER, DISCIPLINE_CARDS } from '@core/types';

export const getDisciplineOffenderType = (
  t: (key: string) => string,
  offenderType: DISCIPLINE_OFFENDER,
) => {
  switch (offenderType) {
    case DISCIPLINE_OFFENDER.PLAYER:
      return t('Player');
    case DISCIPLINE_OFFENDER.OFFICIAL:
      return t('Team Official');
    default:
      return offenderType;
  }
};

export const getCardType = (
  t: (key: string) => string,
  cardType: DISCIPLINE_CARDS,
) => {
  switch (cardType) {
    case DISCIPLINE_CARDS.YELLOW:
      return t('Yellow');
    case DISCIPLINE_CARDS.RED:
      return t('Red');
    default:
      return cardType;
  }
};
