import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { Conflict, DashboardMatchModel } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  matches: Array<DashboardMatchModel>;
  conflicts: Array<Conflict>;
}

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setFixtures.toString()]: (state, { payload }) =>
      assoc('matches', payload, state),
    [actions.clearFixtures.toString()]: (state) => assoc('matches', [], state),
    [actions.setConflicts.toString()]: (state, { payload }) =>
      assoc('conflicts', payload, state),
    [actions.clearConflicts.toString()]: (state) =>
      assoc('conflicts', [], state),
  },
  defaultState,
);

export default reducer;
