import { Sports } from '@core/types';

export const getSports = (
  t: (key: string) => string,
  sport: keyof typeof Sports,
) => {
  switch (sport) {
    case Sports.VOLLEYBALL:
      return t('Volleyball');
    case Sports.BEACH_VOLLEYBALL:
      return t('Beach volleyball');
    case Sports.SNOW_VOLLEYBALL:
      return t('Snow volleyball');
    case Sports.BASKETBALL:
      return t('Basketball');
    case Sports.WHEELCHAIR_BASKETBALL:
      return t('Wheelchair basketball');
    default:
      return sport;
  }
};
