import {
  all,
  call,
  cancel,
  cancelled,
  debounce,
  Effect,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';

import {
  formatPaginatedStoreWithIds,
  getURLSearchParams,
  handleApiErrors,
} from '@core/helpers';
import { getCompetitions } from '@core/pages/competitions/store/api/sagas';

import actions from './actions';

function* searchCompetitionsFlow(
  action: InferAction<
    typeof actions.getCompetitions | typeof actions.searchCompetitionsByQuery
  >,
): Generator<Effect, any, any> {
  try {
    const { payload } = action;

    const urlSearchParams = getURLSearchParams(
      {
        ...payload,
        searchQuery: payload?.query,
      },
      { page: payload?.page, limit: 20 },
    );

    yield put(actions.getCompetitionsRequest());
    const { response, error } = yield call(getCompetitions, urlSearchParams);

    if (!error) {
      yield put(actions.getCompetitionsSuccess());
      yield put(
        actions.setCompetitions(
          formatPaginatedStoreWithIds('competitions')(response) as any,
        ),
      );
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getCompetitionsFailure());
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.getCompetitionsSuccess());
    }
  }
}

export function* getCompetitionsFlow() {
  while (true) {
    const action = (yield take(actions.getCompetitions)) as InferAction<
      typeof actions.getCompetitions
    >;
    yield race([
      call(searchCompetitionsFlow, action),
      take(actions.resetState),
    ]);
  }
}

export function* watchSearchCompetitionsByQuery() {
  while (true) {
    const task = (yield debounce(
      500,
      actions.searchCompetitionsByQuery.toString(),
      searchCompetitionsFlow,
    )) as Task;
    yield take(actions.resetState);
    yield cancel(task);
  }
}

export default function* saga() {
  yield all([getCompetitionsFlow(), watchSearchCompetitionsByQuery()]);
}
