import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

import {
  CompetitorResponse,
  KitSet,
  MatchCompetitorsResponse,
  MatchPlayerOrigin,
  MatchModel,
  PatchMatchPayload,
  PostMatchSetPayload,
} from '@core/types';
import { OnSuccessEffectParametersPayload } from '@core/store/helpers/get-match-mutated-successfully-callbacks';

export const NAMESPACE = 'matchProfileTab';

export const actions = createActions(
  {
    SET_HOME_TEAM_OFFICIALS: [prop('payload'), pick(['matchId'])],
    SET_AWAY_TEAM_OFFICIALS: [prop('payload'), pick(['matchId'])],
    SET_MATCH_PLAYERS: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_KIT_SET: [prop('payload'), pick(['matchId', 'matchPlayerOrigin'])],
    SET_PENALTY_SHOOTOUTS: [prop('payload'), pick(['matchId'])],
    SET_MATCH_DURATION_LIST: [prop('payload'), pick(['matchId'])],
    SET_MATCH_SCORES: [prop('payload'), pick(['matchId'])],
    SET_MATCH_COMPETITORS: [prop('payload'), pick(['matchId'])],
    SET_COMPETITION_COMPETITOR: [
      prop('payload'),
      pick(['matchId', 'matchPlayerOrigin']),
    ],
    SET_MATCH_NOTES_DESCRIPTIONS: [prop('payload'), pick(['matchId'])],
    SET_MATCH_NOTES_TITLES: [prop('payload'), pick(['matchId'])],
    SET_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS: [
      prop('payload'),
      pick(['matchId']),
    ],
    SET_MATCH_COMMISSIONER_NOTE_TITLES: [prop('payload'), pick(['matchId'])],
    SET_MATCH: [prop('match'), pick(['matchId'])],
    SET_MATCH_GROUP: [prop('groupId'), pick(['matchId'])],
  },

  'GET_MATCH',
  'GET_MATCH_REQUEST',
  'GET_MATCH_SUCCESS',
  'GET_MATCH_FAILURE',

  'GET_MATCH_GROUP',
  'GET_MATCH_GROUP_REQUEST',
  'GET_MATCH_GROUP_SUCCESS',
  'GET_MATCH_GROUP_FAILURE',

  'CREATE_MATCH_DATA',
  'CREATE_MATCH_DATA_REQUEST',
  'CREATE_MATCH_DATA_SUCCESS',
  'CREATE_MATCH_DATA_FAILURE',

  'GET_MATCH_PLAYERS',
  'GET_MATCH_PLAYERS_REQUEST',
  'GET_MATCH_PLAYERS_SUCCESS',
  'GET_MATCH_PLAYERS_FAILURE',

  'GET_MATCH_EVENTS',
  'GET_MATCH_EVENTS_REQUEST',
  'GET_MATCH_EVENTS_SUCCESS',
  'GET_MATCH_EVENTS_FAILURE',

  'GET_MATCH_NOTES_TITLES',
  'GET_MATCH_NOTES_TITLES_REQUEST',
  'GET_MATCH_NOTES_TITLES_SUCCESS',
  'GET_MATCH_NOTES_TITLES_FAILURE',

  'GET_MATCH_COMMISSIONER_NOTE_TITLES',
  'GET_MATCH_COMMISSIONER_NOTE_TITLES_REQUEST',
  'GET_MATCH_COMMISSIONER_NOTE_TITLES_SUCCESS',
  'GET_MATCH_COMMISSIONER_NOTE_TITLES_FAILURE',

  'GET_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS',
  'GET_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_REQUEST',
  'GET_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_SUCCESS',
  'GET_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_FAILURE',

  'UPDATE_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS',
  'UPDATE_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_REQUEST',
  'UPDATE_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_SUCCESS',
  'UPDATE_MATCH_COMMISSIONER_NOTE_DESCRIPTIONS_FAILURE',

  'GET_MATCH_NOTES_DESCRIPTIONS',
  'GET_MATCH_NOTES_DESCRIPTIONS_REQUEST',
  'GET_MATCH_NOTES_DESCRIPTIONS_SUCCESS',
  'GET_MATCH_NOTES_DESCRIPTIONS_FAILURE',

  'UPDATE_MATCH_NOTES_DESCRIPTIONS',
  'UPDATE_MATCH_NOTES_DESCRIPTIONS_REQUEST',
  'UPDATE_MATCH_NOTES_DESCRIPTIONS_SUCCESS',
  'UPDATE_MATCH_NOTES_DESCRIPTIONS_FAILURE',

  'GET_MATCH_TEAM_OFFICIALS',
  'GET_MATCH_TEAM_OFFICIALS_REQUEST',
  'GET_MATCH_TEAM_OFFICIALS_SUCCESS',
  'GET_MATCH_TEAM_OFFICIALS_FAILURE',

  'RESET_MATCH_PROFILE',

  'GET_KIT_SET',
  'GET_KIT_SET_REQUEST',
  'GET_KIT_SET_SUCCESS',
  'GET_KIT_SET_FAILURE',

  'GET_PENALTY_SHOOTOUTS',

  'UPDATE_PENALTY_SHOOTOUTS',

  'GET_MATCH_DURATION_LIST',
  'GET_MATCH_DURATION_LIST_REQUEST',
  'GET_MATCH_DURATION_LIST_SUCCESS',
  'GET_MATCH_DURATION_LIST_FAILURE',

  'UPDATE_MATCH_DURATION_LIST',
  'UPDATE_MATCH_DURATION_LIST_REQUEST',
  'UPDATE_MATCH_DURATION_LIST_SUCCESS',
  'UPDATE_MATCH_DURATION_LIST_FAILURE',

  'GET_MATCH_SCORES',
  'GET_MATCH_SCORES_REQUEST',
  'GET_MATCH_SCORES_SUCCESS',
  'GET_MATCH_SCORES_FAILURE',

  'GET_MATCH_COMPETITORS',
  'GET_MATCH_COMPETITORS_REQUEST',
  'GET_MATCH_COMPETITORS_SUCCESS',
  'GET_MATCH_COMPETITORS_FAILURE',

  'UPDATE_MATCH_COMPETITOR',
  'UPDATE_MATCH_COMPETITOR_REQUEST',
  'UPDATE_MATCH_COMPETITOR_SUCCESS',
  'UPDATE_MATCH_COMPETITOR_FAILURE',

  'GET_COMPETITION_COMPETITOR',
  'GET_COMPETITION_COMPETITOR_REQUEST',
  'GET_COMPETITION_COMPETITOR_SUCCESS',
  'GET_COMPETITION_COMPETITOR_FAILURE',

  { prefix: NAMESPACE },
) as unknown as {
  setHomeTeamOfficials: UnknownAction;
  setAwayTeamOfficials: UnknownAction;
  setMatchEventsById: UnknownAction;
  setMatchPlayers: UnknownAction;
  setKitSet: IdentityActionMetaFunction<
    { payload: KitSet; matchId: number; matchPlayerOrigin: MatchPlayerOrigin },
    { payload: KitSet },
    { matchId: number; matchPlayerOrigin: MatchPlayerOrigin }
  >;
  setPenaltyShootouts: UnknownAction;
  setMatchDurationList: UnknownAction;
  setMatchScores: UnknownAction;
  setMatchCompetitors: IdentityActionMetaFunction<
    { payload: MatchCompetitorsResponse; matchId: number },
    { payload: MatchCompetitorsResponse },
    { matchId: number }
  >;
  setCompetitionCompetitor: IdentityActionMetaFunction<
    {
      payload: CompetitorResponse;
      matchId: number;
      matchPlayerOrigin: MatchPlayerOrigin;
    },
    { payload: CompetitorResponse },
    { matchId: number; matchPlayerOrigin: MatchPlayerOrigin }
  >;
  setMatchNotesDescriptions: UnknownAction;
  setMatchNotesTitles: UnknownAction;
  setMatchCommissionerNoteDescriptions: UnknownAction;
  setMatchCommissionerNoteTitles: UnknownAction;

  createMatchData: IdentityActionFunction<
    {
      matchId: number;
      match: MatchModel;
      matchPayload: PatchMatchPayload;
      matchSetsPayload: Array<PostMatchSetPayload>;
    } & OnSuccessEffectParametersPayload
  >;
  createMatchDataRequest: RequestLifecycleAction<{ id: number }>;
  createMatchDataSuccess: RequestLifecycleAction<{ id: number }>;
  createMatchDataFailure: RequestLifecycleAction<{ id: number }>;

  getMatch: IdentityActionFunction<{ matchId: number }>;
  getMatchRequest: RequestLifecycleAction<{ id: number }>;
  getMatchSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchFailure: RequestLifecycleAction<{ id: number }>;
  setMatch: IdentityActionMetaFunction<
    { match: MatchModel; matchId: number },
    { match: MatchModel },
    { matchId: number }
  >;

  getMatchGroup: IdentityActionFunction<{ groupId: number; matchId: number }>;
  getMatchGroupRequest: RequestLifecycleAction<{ id: number }>;
  getMatchGroupSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchGroupFailure: RequestLifecycleAction<{ id: number }>;
  setMatchGroup: IdentityActionMetaFunction<
    { groupId: number; matchId: number },
    { groupId: number },
    { matchId: number }
  >;

  getMatchPlayers: UnknownAction;
  getMatchPlayersRequest: RequestLifecycleAction;
  getMatchPlayersSuccess: RequestLifecycleAction;
  getMatchPlayersFailure: RequestLifecycleAction;

  getMatchEvents: UnknownAction;
  getMatchEventsRequest: RequestLifecycleAction;
  getMatchEventsSuccess: RequestLifecycleAction;
  getMatchEventsFailure: RequestLifecycleAction;

  getMatchNotesTitles: UnknownAction;
  getMatchNotesTitlesRequest: RequestLifecycleAction;
  getMatchNotesTitlesSuccess: RequestLifecycleAction;
  getMatchNotesTitlesFailure: RequestLifecycleAction;

  getMatchCommissionerNoteTitles: UnknownAction;
  getMatchCommissionerNoteTitlesRequest: RequestLifecycleAction;
  getMatchCommissionerNoteTitlesSuccess: RequestLifecycleAction;
  getMatchCommissionerNoteTitlesFailure: RequestLifecycleAction;

  getMatchCommissionerNoteDescriptions: UnknownAction;
  getMatchCommissionerNoteDescriptionsRequest: RequestLifecycleAction;
  getMatchCommissionerNoteDescriptionsSuccess: RequestLifecycleAction;
  getMatchCommissionerNoteDescriptionsFailure: RequestLifecycleAction;

  updateMatchCommissionerNoteDescriptions: UnknownAction;
  updateMatchCommissionerNoteDescriptionsRequest: RequestLifecycleAction;
  updateMatchCommissionerNoteDescriptionsSuccess: RequestLifecycleAction;
  updateMatchCommissionerNoteDescriptionsFailure: RequestLifecycleAction;

  getMatchNotesDescriptions: UnknownAction;
  getMatchNotesDescriptionsRequest: RequestLifecycleAction;
  getMatchNotesDescriptionsSuccess: RequestLifecycleAction;
  getMatchNotesDescriptionsFailure: RequestLifecycleAction;

  updateMatchNotesDescriptions: UnknownAction;
  updateMatchNotesDescriptionsRequest: RequestLifecycleAction;
  updateMatchNotesDescriptionsSuccess: RequestLifecycleAction;
  updateMatchNotesDescriptionsFailure: RequestLifecycleAction;

  getMatchTeamOfficials: UnknownAction;
  getMatchTeamOfficialsRequest: RequestLifecycleAction;
  getMatchTeamOfficialsSuccess: RequestLifecycleAction;
  getMatchTeamOfficialsFailure: RequestLifecycleAction;

  resetMatchProfile: UnknownAction;

  getKitSet: IdentityActionFunction<{
    kitSetId: number;
    matchId: number;
    matchPlayerOrigin: MatchPlayerOrigin;
    competitionId: number;
    teamId: number;
  }>;
  getKitSetRequest: RequestLifecycleAction<{ kitSetId: number }>;
  getKitSetSuccess: RequestLifecycleAction<{ kitSetId: number }>;
  getKitSetFailure: RequestLifecycleAction<{ kitSetId: number }>;

  getPenaltyShootouts: UnknownAction;

  updatePenaltyShootouts: UnknownAction;

  getMatchDurationList: UnknownAction;
  getMatchDurationListRequest: RequestLifecycleAction;
  getMatchDurationListSuccess: RequestLifecycleAction;
  getMatchDurationListFailure: RequestLifecycleAction;

  updateMatchDurationList: UnknownAction;
  updateMatchDurationListRequest: RequestLifecycleAction;
  updateMatchDurationListSuccess: RequestLifecycleAction;
  updateMatchDurationListFailure: RequestLifecycleAction;

  getMatchScores: UnknownAction;
  getMatchScoresRequest: RequestLifecycleAction;
  getMatchScoresSuccess: RequestLifecycleAction;
  getMatchScoresFailure: RequestLifecycleAction;

  getMatchCompetitors: IdentityActionFunction<number>;
  getMatchCompetitorsRequest: RequestLifecycleAction<{ matchId: number }>;
  getMatchCompetitorsSuccess: RequestLifecycleAction<{ matchId: number }>;
  getMatchCompetitorsFailure: RequestLifecycleAction<{ matchId: number }>;

  updateMatchCompetitor: IdentityActionFunction<{
    matchId: number;
    competitorId: number;
    teamColorId: number;
  }>;
  updateMatchCompetitorRequest: RequestLifecycleAction<{ matchId: number }>;
  updateMatchCompetitorSuccess: RequestLifecycleAction<{ matchId: number }>;
  updateMatchCompetitorFailure: RequestLifecycleAction<{ matchId: number }>;

  getCompetitionCompetitor: IdentityActionFunction<{
    competitionId: number;
    teamId: number;
    matchId: number;
    matchPlayerOrigin: MatchPlayerOrigin;
  }>;
  getCompetitionCompetitorRequest: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;
  getCompetitionCompetitorSuccess: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;
  getCompetitionCompetitorFailure: RequestLifecycleAction<{
    competitionId: number;
    teamId: number;
  }>;
};
