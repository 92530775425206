import React from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { map, values, pipe } from 'ramda';

import { TextField, SelectField, ChipsSelect } from '@core/components';
import {
  getGenders,
  formatSportOptions,
  getClubTypeOptions,
  getGeographyOptions,
  formatOptions,
} from '@core/helpers';
import {
  AgeGroupsList,
  SeasonsList,
  SportsList,
  Genders,
  CompetitionLevelsList,
} from '@core/types';

import { FIELD_NAMES } from '../constants';
import useStyles from './styles';

export interface OwnProps {
  ageGroups: AgeGroupsList;
  competitionLevels: CompetitionLevelsList;
  seasons: SeasonsList;
  sports: SportsList;
  sportSelectionDisabled?: boolean;
}

type Props = OwnProps;

const CompetitionDetailsBlock = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    ageGroups,
    seasons,
    competitionLevels,
    sports,
    sportSelectionDisabled,
  } = props;

  const mapGendersToOptions = pipe(
    // @ts-ignore
    values,
    map((val: Genders) => ({
      label: getGenders(t, val),
      value: val,
    })),
  );

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Details')}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <SelectField
                required
                name={FIELD_NAMES.competitionSeasonId}
                data-qa={FIELD_NAMES.competitionSeasonId}
                withTooltipMargin
                label={t('Season')}
                options={formatOptions(null, seasons)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <TextField
                required
                name={FIELD_NAMES.participatingTeams}
                data-qa={FIELD_NAMES.participatingTeams}
                withTooltipMargin
                label={t('Number of Teams')}
                type="number"
                inputProps={{
                  min: 0,
                  step: 1,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.sportId}
              options={formatSportOptions(t, sports)}
              title={t('Sport')}
              disabled={sportSelectionDisabled}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.gender}
              // @ts-ignore
              options={mapGendersToOptions(Genders)}
              title={t('Gender')}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.ageGroupId}
              options={formatOptions(t, ageGroups)}
              title={t('Age Group')}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.competitionLevelId}
              options={formatOptions(t, competitionLevels)}
              title={t('Competition Level')}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.clubType}
              options={getClubTypeOptions(t)}
              title={t('Team type')}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipsSelect
              fieldName={FIELD_NAMES.geography}
              options={getGeographyOptions(t)}
              title={t('Competition geography')}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Box mb={2} mt={1} flex={1}>
          <Divider orientation="horizontal" />
        </Box>
      </Grid>
    </>
  );
};

export default CompetitionDetailsBlock;
