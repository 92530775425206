import React, { useEffect } from 'react';
import { Paper, Box, Typography, Button, Grid, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { equals } from 'ramda';

import {
  Tabs,
  TabPanel,
  PageFormActionBlock,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getSports, getGenders } from '@core/helpers';
import {
  Genders,
  PlayerList,
  Player,
  TeamOfficialList,
  TeamOfficial,
  ModalTypes,
  Sports,
} from '@core/types';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competition-profile';
import CompetitorSummary from '@core/components/competitor-summary';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';
import {
  getAddedCompetitorPlayers,
  getCompetitorPlayers,
  getAddedCompetitorTeamOfficials,
  getCompetitorTeamOfficials,
  getPreviousCompetitorPlayerListTotal,
} from '@core/store/modules/tabs/player-list-management/selectors';
import { State } from '@core/store';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { getPlayerListValidationErrors } from '@core/store/modules/tabs/player-list-management/helpers';

import { getPlayerListTabs } from './components/tabs-mapping';
import PlayerListActionsBlock from './components/player-list-actions-block';
import PlayerListValidations from './components/player-list-validations';
import { ButtonWithProgress } from '@ui-components';

interface StateProps {
  addedCompetitorPlayers: PlayerList;
  competitorPlayers: PlayerList;
  addedCompetitorTeamOfficials: TeamOfficialList;
  competitorTeamOfficials: TeamOfficialList;
  isSubmitting: boolean;
  areCompetitorPlayersLoading: boolean;
  areCompetitorTeamOfficialsLoading: boolean;
  isPreviousPlayerListLoading: boolean;
  previousCompetitorPlayerListTotal: number;
}

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
    tab: typeof tabActions;
    competitorProfile: typeof competitorProfileActions;
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  competition: {
    id: number;
    title: string;
    season: string;
    minPlayers: number;
    maxPlayers: number;
    earliestPlayerDateOfBirth: string;
    sport: Sports;
    gender: Genders;
    latestPlayerRegistrationDate: string;
  };
  teamId: number;
  competitor: {
    id: number;
    title: string;
    internationalTitle?: string;
    logoFileLink?: string;
    club?: string;
  };
  clubId: number;
  sportId: number;
  tabId: string;
}

type Props = OwnProps & StateProps & DispatchProps;

const PlayerListManagementPage = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    addedCompetitorPlayers,
    addedCompetitorTeamOfficials,
    areCompetitorPlayersLoading,
    areCompetitorTeamOfficialsLoading,
    clubId,
    competition,
    competitor,
    competitorPlayers,
    competitorTeamOfficials,
    isPreviousPlayerListLoading,
    isSubmitting,
    previousCompetitorPlayerListTotal,
    sportId,
    tabId,
    teamId,
  } = props;
  const tabsMapping = getPlayerListTabs(
    t,
    addedCompetitorPlayers ? addedCompetitorPlayers.length : 0,
    addedCompetitorTeamOfficials ? addedCompetitorTeamOfficials.length : 0,
  );
  const arePlayersChanged = !equals(competitorPlayers, addedCompetitorPlayers);
  const areTeamOfficialsChanged = !equals(
    competitorTeamOfficials,
    addedCompetitorTeamOfficials,
  );

  useEffect(() => {
    actions.playerListManagement.getCompetitorPlayers({
      competitionId: competition.id,
      teamId,
      competitorId: competitor.id,
    });
    actions.playerListManagement.getCompetitorTeamOfficials({
      competitionId: competition.id,
      teamId,
      competitorId: competitor.id,
    });

    return () => {
      actions.playerListManagement.resetPreviousCompetitorPlayerListTotal({
        competitorId: competitor.id,
      });
    };
  }, []);

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Team in competition'),
      value: competitor?.title,
    },
    {
      label: t('Team ID'),
      value: competitor?.id,
    },
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.season,
    },
    {
      label: t('Sport'),
      value: getSports(t, competition?.sport),
    },
    {
      label: t('Gender'),
      value: getGenders(t, competition?.gender),
    },
    {
      label: t('Club'),
      value: competitor?.club,
    },
  ];

  const handleCancel = () => actions.tab.removeTab({ tabId });

  const submitPlayerAndTeamOfficialsList = () => {
    const shouldAutoAddJerseyNumbers = addedCompetitorPlayers?.every(
      (player) => !player.jerseyNumber,
    );

    actions.playerListManagement.addPlayersAndTeamOfficialsToCompetitor({
      competitionId: competition.id,
      teamId,
      competitorId: competitor.id,
      playersData: arePlayersChanged
        ? addedCompetitorPlayers?.map((player: Player, index) => ({
            playerId: player.id,
            jerseyNumber: shouldAutoAddJerseyNumbers
              ? (index + 1).toString()
              : player?.jerseyNumber,
            playerPositionId: player?.positionId,
          }))
        : null,
      teamOfficialsData: areTeamOfficialsChanged
        ? addedCompetitorTeamOfficials.map((teamOfficial: TeamOfficial) => {
            return {
              teamOfficialId: teamOfficial.id,
              teamOfficialRoleId: teamOfficial.teamOfficialRoleId,
            };
          })
        : null,
      tabId,
    });
  };

  const handleSubmit = () => {
    const maxPlayers = competition.maxPlayers;
    const minPlayers = competition.minPlayers;
    const earliestPlayerDateOfBirth = competition.earliestPlayerDateOfBirth;

    const playerListValidationErrors = getPlayerListValidationErrors(
      maxPlayers,
      minPlayers,
      earliestPlayerDateOfBirth,
      addedCompetitorPlayers,
    );

    if (playerListValidationErrors.length <= 0) {
      submitPlayerAndTeamOfficialsList();

      return;
    }

    actions.globalModal.openModal({
      type: ModalTypes.PLAYER_LIST_VALIDATION_MODAL,
      params: {
        maxPlayers: competition.maxPlayers,
        minPlayers: competition.minPlayers,
        earliestPlayerDateOfBirth: competition.earliestPlayerDateOfBirth,
        addedCompetitorPlayers,
        submitCallback: submitPlayerAndTeamOfficialsList,
      },
    });
  };

  return (
    <>
      <Paper>
        <Box py={3} px={3}>
          <Box mb={3}>
            <Typography variant="h6">{t('Manage player roster')}</Typography>
          </Box>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container>
              <Grid item xs={12} sm={7}>
                <SummaryBlock values={getInfoBlocks()} />
              </Grid>
              <Grid container item xs={12} sm={5} justifyContent="flex-end">
                <PlayerListActionsBlock
                  actions={{
                    getPrevious:
                      actions.playerListManagement
                        .getPreviousCompetitorPlayerList,
                  }}
                  competitionId={competition.id}
                  teamId={teamId}
                  competitorId={competitor.id}
                  isLoading={isPreviousPlayerListLoading}
                />
              </Grid>
            </Grid>
          </Box>
          {previousCompetitorPlayerListTotal === 0 ? (
            <Box mt={3}>
              <Alert severity="info">
                <strong>
                  {t(
                    'No available team roster for copy in previous competition.',
                  )}
                </strong>
              </Alert>
            </Box>
          ) : null}
          <Box mt={3}>
            <PlayerListValidations
              minPlayers={competition.minPlayers}
              maxPlayers={competition.maxPlayers}
              earliestPlayerDateOfBirth={competition.earliestPlayerDateOfBirth}
              latestPlayerRegistrationDate={
                competition.latestPlayerRegistrationDate
              }
            />
          </Box>
          <Box mt={3}>
            <CompetitorSummary
              competitorTitle={competitor?.title}
              competitorInternationalTitle={competitor?.internationalTitle}
              competitorLogoFileLink={competitor?.logoFileLink}
            />
          </Box>
          <Box mt={3}>
            <Tabs fullWidth tabs={tabsMapping}>
              {(currentTab) => (
                <TabPanel
                  currentTab={currentTab}
                  tabs={tabsMapping}
                  props={
                    {
                      clubId,
                      sportId,
                      competitorId: competitor.id,
                      minPlayers: competition.minPlayers,
                      maxPlayers: competition.maxPlayers,
                      earliestDateOfBirth:
                        competition.earliestPlayerDateOfBirth,
                      latestPlayerRegistrationDate:
                        competition.latestPlayerRegistrationDate,
                      areCompetitorPlayersLoading,
                      areCompetitorTeamOfficialsLoading,
                      gender: competition?.gender,
                    } as React.ComponentProps<
                      (typeof tabsMapping)[number]['component']
                    >
                  }
                />
              )}
            </Tabs>
          </Box>
        </Box>
      </Paper>
      <PageFormActionBlock>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button onClick={handleCancel} variant="outlined" color="primary">
              {t('Cancel')}
            </Button>
          </Box>
          <ButtonWithProgress
            isLoading={isSubmitting}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {t('Submit')}
          </ButtonWithProgress>
        </Box>
      </PageFormActionBlock>
    </>
  );
};

const submittingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    playerListManagementActions.addPlayersAndTeamOfficialsToCompetitorRequest({
      competitionId: ownProps.competition.id,
      competitorId: ownProps.competitor.id,
      teamId: ownProps.teamId,
    }),
  ]);
const competitorPlayersLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    playerListManagementActions.getCompetitorPlayersRequest({
      competitionId: ownProps.competition.id,
      competitorId: ownProps.competitor.id,
      teamId: ownProps.teamId,
    }),
  ]);
const competitorTeamOfficialsLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    playerListManagementActions.getCompetitorTeamOfficialsRequest({
      competitionId: ownProps.competition.id,
      competitorId: ownProps.competitor.id,
      teamId: ownProps.teamId,
    }),
  ]);

const previousPlayerListLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    playerListManagementActions.getPreviousCompetitorPlayerListRequest({
      competitionId: ownProps.competition.id,
      competitorId: ownProps.competitor.id,
      teamId: ownProps.teamId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
    tab: bindActionCreators(tabActions, dispatch),
    competitorProfile: bindActionCreators(competitorProfileActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  addedCompetitorPlayers: getAddedCompetitorPlayers(ownProps.competitor.id)(
    state,
  ),
  competitorPlayers: getCompetitorPlayers(ownProps.competitor.id)(state),
  addedCompetitorTeamOfficials: getAddedCompetitorTeamOfficials(
    ownProps.competitor.id,
  )(state),
  competitorTeamOfficials: getCompetitorTeamOfficials(ownProps.competitor.id)(
    state,
  ),
  isSubmitting: submittingSelector(ownProps)(state),
  areCompetitorPlayersLoading:
    competitorPlayersLoadingSelector(ownProps)(state),
  areCompetitorTeamOfficialsLoading:
    competitorTeamOfficialsLoadingSelector(ownProps)(state),
  isPreviousPlayerListLoading:
    previousPlayerListLoadingSelector(ownProps)(state),
  previousCompetitorPlayerListTotal: getPreviousCompetitorPlayerListTotal(
    state,
    {
      competitorId: ownProps.competitor.id,
    },
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerListManagementPage);
