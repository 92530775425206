import restService from './rest-service';

import { FilesResponse, FileType, ObjectType } from '../types/api';

export const getFileList = (
  objectType: ObjectType,
  objectId: number,
  fileType: FileType,
) =>
  restService.get<FilesResponse>(
    `/v1/files/${objectType}/${objectId}/${fileType}`,
  );

export const getFile = (
  objectType: ObjectType,
  objectId: number,
  fileType: FileType,
  fileId: number,
) =>
  restService.get<FilesResponse>(
    `/v1/files/${objectType}/${objectId}/${fileType}/${fileId}`,
    { responseType: 'blob' },
  );

export const deleteFile = (
  objectType: ObjectType,
  objectId: number,
  fileType: FileType,
  fileId: number,
) =>
  restService.delete<FilesResponse>(
    `/v1/files/${objectType}/${objectId}/${fileType}/${fileId}`,
  );

export const uploadFile = (
  objectType: ObjectType,
  objectId: number,
  fileType: FileType,
  file: File,
  name: string,
  type: string,
) => {
  const formData = new FormData();
  formData.append('file', file);
  name && formData.append('documentName', name);
  type && formData.append('documentType', type);

  return restService.post<FilesResponse>(
    `/v1/files/${objectType}/${objectId}/${fileType}`,
    formData,
  );
};

export const overwriteFile = (
  objectType: ObjectType,
  objectId: number,
  fileType: FileType,
  file: File,
  fileId: number,
  name: string,
  type: string,
  section?: string,
  position?: string,
) => {
  const formData = new FormData();
  formData.append('file', file);
  name && formData.append('documentName', name);
  type && formData.append('documentType', type);
  section && formData.append('section', section);
  position && formData.append('position', position);

  return restService.post<FilesResponse>(
    `/v1/files/${objectType}/${objectId}/${fileType}/${fileId}`,
    formData,
  );
};
