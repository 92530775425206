import { createActions } from 'redux-actions';
import { MatchSet } from '@core/types';

export const NAMESPACE = 'matchSetsList';

export const actions = createActions(
  'GET_MATCH_SETS',
  'GET_MATCH_SETS_REQUEST',
  'GET_MATCH_SETS_SUCCESS',
  'GET_MATCH_SETS_FAILURE',
  'SET_MATCH_SETS',

  'CREATE_MATCH_SET',
  'CREATE_MATCH_SET_REQUEST',
  'CREATE_MATCH_SET_SUCCESS',
  'CREATE_MATCH_SET_FAILURE',

  'UPDATE_MATCH_SETS',
  'UPDATE_MATCH_SETS_REQUEST',
  'UPDATE_MATCH_SETS_SUCCESS',
  'UPDATE_MATCH_SETS_FAILURE',

  'UPDATE_SET',
  'UPDATE_SET_REQUEST',
  'UPDATE_SET_SUCCESS',
  'UPDATE_SET_FAILURE',

  'DELETE_SET',
  'DELETE_SET_REQUEST',
  'DELETE_SET_SUCCESS',
  'DELETE_SET_FAILURE',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getMatchSets: IdentityActionFunction<{ matchId: number }>;
  getMatchSetsRequest: RequestLifecycleAction<{ id: number }>;
  getMatchSetsSuccess: RequestLifecycleAction<{ id: number }>;
  getMatchSetsFailure: RequestLifecycleAction<{ id: number }>;
  setMatchSets: IdentityActionFunction<{
    matchId: number;
    matchSets: Array<MatchSet>;
  }>;

  createMatchSetRequest: RequestLifecycleAction<{ id: number }>;
  createMatchSetSuccess: RequestLifecycleAction<{ id: number }>;
  createMatchSetFailure: RequestLifecycleAction<{ id: number }>;

  updateMatchSetsRequest: RequestLifecycleAction<{ id: number }>;
  updateMatchSetsSuccess: RequestLifecycleAction<{ id: number }>;
  updateMatchSetsFailure: RequestLifecycleAction<{ id: number }>;

  updateSetRequest: RequestLifecycleAction<{ id: number }>;
  updateSetSuccess: RequestLifecycleAction<{ id: number }>;
  updateSetFailure: RequestLifecycleAction<{ id: number }>;

  deleteSetRequest: RequestLifecycleAction<{ id: number }>;
  deleteSetSuccess: RequestLifecycleAction<{ id: number }>;
  deleteSetFailure: RequestLifecycleAction<{ id: number }>;
};
