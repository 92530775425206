import { KitPlayerType, KitType } from './api/kit-sets';

export enum Genders {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  MIXED = 'MIXED',
}

export enum GeographyTypes {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export enum CompetitionStructure {
  MULTI_STAGE = 'MULTI_STAGE',
  SINGLE_STAGE = 'SINGLE_STAGE',
  ONE_OFF_MATCH = 'ONE_OFF_MATCH',
}

export enum StatusColors {
  LIGHT_GREY = 'LIGHT_GREY',
  DARK_GREY = 'DARK_GREY',
  BLUE = 'BLUE',
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export enum ClubTypes {
  CLUB = 'CLUB',
  NATIONAL_CLUB = 'NATIONAL_CLUB',
  REPRESENTATIVE_CLUB = 'REPRESENTATIVE_CLUB',
}

export enum PersonGenders {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum CompetitionLevelTypes {
  OLYMPICS = 'OLYMPICS',
  WORLD_CHAMPIONSHIPS = 'WORLD_CHAMPIONSHIPS',
  ZONE_CHAMPIONSHIPS = 'ZONE_CHAMPIONSHIPS',
  NONCONTINENTAL_CHAMPIONSHIPS = 'NONCONTINENTAL_CHAMPIONSHIPS',
  FRIENDLIES = 'FRIENDLIES',
  INTERNATIONAL = 'INTERNATIONAL',
  REGION = 'REGION',
  ELITE = 'ELITE',
  TIER2 = 'TIER2',
  TIER3 = 'TIER3',
  GRASS_ROOTS = 'GRASS_ROOTS',
}

export enum Sports {
  VOLLEYBALL = 'VOLLEYBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  SNOW_VOLLEYBALL = 'SNOW_VOLLEYBALL',
  BASKETBALL = 'BASKETBALL',
  WHEELCHAIR_BASKETBALL = 'WHEELCHAIR_BASKETBALL',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OrganisationType {
  CLUB = 'CLUB',
  DISTRICT = 'DISTRICT',
  REGION = 'REGION',
  MEMBER = 'MEMBER',
}

export enum CompetitionStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
}

export enum RepresentationType {
  CLUB = 'CLUB',
  COUNTRY = 'COUNTRY',
}

export enum StageType {
  ROUND_ROBIN = 'ROUND_ROBIN',
  KNOCK_OUT = 'KNOCK_OUT',
}

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum WeekDayLong {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum WeekDaysShort {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

export enum MonthShort {
  JAN = 'Jan',
  FEB = 'Feb',
  MAR = 'Mar',
  APR = 'Apr',
  MAY = 'May',
  JUN = 'Jun',
  JUL = 'Jul',
  AUG = 'Aug',
  SEP = 'Sep',
  OCT = 'Oct',
  NOV = 'Nov',
  DEC = 'Dec',
}

export enum CompetitorStatus {
  INVITED = 'INVITED',
  SUBMITTED = 'SUBMITTED',
  RETURNED = 'RETURNED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

export enum FixtureGenerationType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export enum ModalTypes {
  STAGE_MATCHES_UPLOAD_MODAL = 'STAGE_MATCHES_UPLOAD_MODAL',
  CHANGE_MATCH_STATUS_MODAL = 'CHANGE_MATCH_STATUS_MODAL',
  DOCUMENT_UPLOAD_MODAL = 'DOCUMENT_UPLOAD_MODAL',
  MATCH_CREATE_MODAL = 'MATCH_CREATE_MODAL',
  MANAGE_TEAM_KITS_MODAL = 'MANAGE_TEAM_KITS_MODAL',
  MATCH_OFFICIAL_ALLOCATION_MODAL = 'MATCH_OFFICIAL_ALLOCATION_MODAL',
  PICTURE_UPLOAD_MODAL = 'PICTURE_UPLOAD_MODAL',
  COMPETITOR_PREVIOUS_PLAYER_LIST = 'COMPETITOR_PREVIOUS_PLAYER_LIST',
  SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL',
  USER_ROLE_REQUEST_MODAL = 'USER_ROLE_REQUEST_MODAL',
  SELECT_MATCH_MODAL = 'SELECT_MATCH_MODAL',
  MATCH_SHEET_VALIDATION_MODAL = 'MATCH_SHEET_VALIDATION_MODAL',
  PLAYER_LIST_VALIDATION_MODAL = 'PLAYER_LIST_VALIDATION_MODAL',
  MATCH_SHEET_HISTORY_MODAL = 'MATCH_SHEET_HISTORY_MODAL',
  MATCH_DATA_HISTORY_MODAL = 'MATCH_DATA_HISTORY_MODAL',
  STANDINGS_SETTINGS_HISTORY_MODAL = 'STANDINGS_SETTINGS_HISTORY_MODAL',
  STANDINGS_SETTINGS_TABLE_PREVIEW_MODAL = 'STANDINGS_SETTINGS_TABLE_PREVIEW_MODAL',
  TERMS_AND_CONDITIONS_MODAL = 'TERMS_AND_CONDITIONS_MODAL',
  ADD_MEMBER_TO_CLUB_MODAL = 'ADD_MEMBER_TO_CLUB_MODAL',
  GROUP_CREATE_MODAL = 'GROUP_CREATE_MODAL',
  TEAM_GRID_MULTITEAM_SELECT_MODAL = 'TEAM_GRID_MULTITEAM_SELECT_MODAL',
  MATCH_DATA_EDIT_MODAL = 'MATCH_DATA_EDIT_MODAL',
  PERSONS_UPLOAD_MODAL = 'PERSONS_UPLOAD_MODAL',
  CREATE_UPDATE_GROUP_MODAL = 'CREATE_UPDATE_GROUP_MODAL',
  ADD_MO_MODAL = 'ADD_MO_MODAL',
  CREATE_COMPETITION_SEASON_MODAL = 'CREATE_COMPETITION_SEASON_MODAL',
  CHANGE_COMPETITION_STATUS_MODAL = 'CHANGE_COMPETITION_STATUS_MODAL',
}

export enum GROUP_STATUSES {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
}

export enum KNOCKOUT_ROUNDS {
  ROUND_OF_2 = 'ROUND_OF_2',
  ROUND_OF_4 = 'ROUND_OF_4',
  ROUND_OF_8 = 'ROUND_OF_8',
  ROUND_OF_16 = 'ROUND_OF_16',
  ROUND_OF_32 = 'ROUND_OF_32',
  ROUND_OF_64 = 'ROUND_OF_64',
  ROUND_OF_128 = 'ROUND_OF_128',
}

export enum GroupStandingsColumnLabels {
  POSITION = 'POSITION',
  TEAM = 'TEAM',
  COMPETITOR = 'COMPETITOR',
  GAMES_PLAYED = 'GAMES_PLAYED',
  GAMES_WON = 'GAMES_WON',
  GAMES_WON_IN_REGULAR_TIME = 'GAMES_WON_IN_REGULAR_TIME',
  GAMES_WON_IN_OVERTIME = 'GAMES_WON_IN_OVERTIME',
  GAMES_WON_IN_PENALTY_SHOOTOUT = 'GAMES_WON_IN_PENALTY_SHOOTOUT',
  GAMES_DRAWN = 'GAMES_DRAWN',
  GAMES_LOST = 'GAMES_LOST',
  GOALS_FOR = 'GOALS_FOR',
  GOALS_AGAINST = 'GOALS_AGAINST',
  GOALS_DIFFERENCE = 'GOALS_DIFFERENCE',
  POINTS = 'POINTS',
  HOME_WINS = 'HOME_WINS',
  AWAY_WINS = 'AWAY_WINS',
  PERCENTAGE = 'PERCENTAGE',
  STREAK = 'STREAK',
  LAST_FIVE = 'LAST_FIVE',
  MATCHES = 'MATCHES',
  SETS = 'SETS',
  RESULT_BREAKDOWN = 'RESULT_BREAKDOWN',
  MATCH_POINTS = 'MATCH_POINTS',
}

export enum StandingsTableHeaderTypes {
  POSITION = 'POSITION',
  POINTS = 'POINTS',
  MATCHES = 'MATCHES',
  SETS = 'SETS',
  MATCH_POINTS = 'MATCH_POINTS',
  RESULT_BREAKDOWN = 'RESULT_BREAKDOWN',
  SET_RATIO = 'SET_RATIO',
  PERCENTAGE = 'PERCENTAGE',
}

export enum StandingsRanks {
  POINTS = 'POINTS',
  GAMES_WON = 'GAMES_WON',
  SET_RATIO = 'SET_RATIO',
  POINTS_RATIO = 'POINTS_RATIO',
}

export enum GroupStandingsColumnFields {
  competitorName = 'competitorName',
  team = 'team',
  competitor = 'competitor',
  position = 'position',
  gamesPlayed = 'gamesPlayed',
  gamesWon = 'gamesWon',
  gamesWonInRegularTime = 'gamesWonInRegularTime',
  gamesWonInOverTime = 'gamesWonInOverTime',
  gamesWonInPenaltyShootout = 'gamesWonInPenaltyShootout',
  gamesDrawn = 'gamesDrawn',
  gamesLost = 'gamesLost',
  goalsFor = 'goalsFor',
  goalsAgainst = 'goalsAgainst',
  goalsDifference = 'goalsDifference',
  points = 'points',
  homeWins = 'homeWins',
  awayWins = 'awayWins',
  percentage = 'percentage',
  streak = 'streak',
  lastFive = 'lastFive',
}

export enum MatchEventType {
  GOAL = 'GOAL',
  CARD = 'CARD',
  SUBSTITUTION = 'SUBSTITUTION',
  PENALTY = 'PENALTY',
  PENALTY_SHOOT_OUT = 'PENALTY_SHOOT_OUT',
}

export enum GoalType {
  OWN_GOAL = 'OWN',
  GOAL = 'SCORING',
}

export enum PenaltyType {
  SCORING = 'SCORING',
  MISSED = 'MISSED',
}

export enum CardType {
  YELLOW = 'YELLOW',
  Y2R = 'YELLOW_RED',
  RED = 'RED',
}

export enum MatchPeriod {
  DURING_MATCH = 'DURING_MATCH',
  BEFORE_MATCH = 'BEFORE_MATCH',
  AFTER_MATCH = 'AFTER_MATCH',
  BETWEEN_HALVES = 'BETWEEN_HALVES',
  DURING_PENALTY_SHOOT_OUT = 'DURING_PENALTY_SHOOT_OUT',
}

export enum RedCardReason {
  ENTER_VIDEO_OPERATION_ROOM = 'ENTER_VIDEO_OPERATION_ROOM',
  RECEIVE_SECOND_CAUTION = 'RECEIVE_SECOND_CAUTION',
  USING_OFFENSIVE_GESTURES = 'USING_OFFENSIVE_GESTURES',
  VIOLENT_CONDUCT = 'VIOLENT_CONDUCT',
  BITING_SPITTING = 'BITING_SPITTING',
  SERIOUS_FOUL_PLAY = 'SERIOUS_FOUL_PLAY',
  DENYING_GOAL_BY_HANDBALL = 'DENYING_GOAL_BY_HANDBALL',
  DENYING_GOAL_POSSIBILITY_MOVEMENT = 'DENYING_GOAL_POSSIBILITY_MOVEMENT',
}

export enum YellowCardReason {
  EXCESSIVE_USAGE_SIGNAL = 'EXCESSIVE_USAGE_SIGNAL',
  ENTER_REFEREE_REVIEW_AREA = 'ENTER_REFEREE_REVIEW_AREA',
  UNSPORTING_BEHAVIOUR = 'UNSPORTING_BEHAVIOUR',
  PERSISTENT_OFFENSES = 'PERSISTENT_OFFENSES',
  FAILING_RESPECT_REQUIRED_DISTANCE = 'FAILING_RESPECT_REQUIRED_DISTANCE',
  ENTER_OR_LEAVE_FIELD_WITHOUT_REFEREE_PERMISSION = 'ENTER_OR_LEAVE_FIELD_WITHOUT_REFEREE_PERMISSION',
  EXCESSIVE_GOAL_CELEBRATION = 'EXCESSIVE_GOAL_CELEBRATION',
  DISSENT_BY_ACTION = 'DISSENT_BY_ACTION',
  DELAYING_PLAY_RESTART = 'DELAYING_PLAY_RESTART',
}

export enum DelayRestartReasons {
  EXCESSIVELY_DELAYING_RESTART = 'EXCESSIVELY_DELAYING_RESTART',
  DELAYING_LEAVING_FIELD_ON_SUBSTITUTION = 'DELAYING_LEAVING_FIELD_ON_SUBSTITUTION',
  DECEIVING_THROW_IN = 'DECEIVING_THROW_IN',
  FREE_KICK_FROM_WRONG_POSITION = 'FREE_KICK_FROM_WRONG_POSITION',
  KICKING_OR_CARRYING_BALL_AWAY = 'KICKING_OR_CARRYING_BALL_AWAY',
}

export enum ExcesiveCelebrationReason {
  SHIRT_REMOVING_OR_COVERING_HEAD_WITH_SHIRT = 'SHIRT_REMOVING_OR_COVERING_HEAD_WITH_SHIRT',
  COVERING_HEAD_OR_FACE_WITH_MASK = 'COVERING_HEAD_OR_FACE_WITH_MASK',
  PROVOCATIVE_ACTING = 'PROVOCATIVE_ACTING',
  CLIMBING_ONTO_PERIMETER_FENCE = 'CLIMBING_ONTO_PERIMETER_FENCE',
}

export enum UnsportingeBehaviorReason {
  VERBALLY_DISTRACTION = 'VERBALLY_DISTRACTION',
  LACK_OF_RESPECT_FOR_GAME = 'LACK_OF_RESPECT_FOR_GAME',
  NOT_APPROPRIATE_BALL_TRANSMISSION = 'NOT_APPROPRIATE_BALL_TRANSMISSION',
  PLAYING_WITH_BALL_AFTER_PERMISSION_TO_LEAVE = 'PLAYING_WITH_BALL_AFTER_PERMISSION_TO_LEAVE',
  UNAUTHORIZED_MARKS_ON_FIELD = 'UNAUTHORIZED_MARKS_ON_FIELD',
  BALL_HANDLING_IN_ATTEMPT_TO_SCORE_OR_PREVENT_GOAL = 'BALL_HANDLING_IN_ATTEMPT_TO_SCORE_OR_PREVENT_GOAL',
  ATTEMPT_OF_REFEREE_DECEIVING = 'ATTEMPT_OF_REFEREE_DECEIVING',
  CHANGING_PLACE_WITH_GOALKEEPER = 'CHANGING_PLACE_WITH_GOALKEEPER',
  COMMITMENT_DIRECT_FREE_KICK_OFFENCE = 'COMMITMENT_DIRECT_FREE_KICK_OFFENCE',
  BALL_HANDLING_TO_INTERFERE_ATTACK = 'BALL_HANDLING_TO_INTERFERE_ATTACK',
  FOUL_COMMITMENT_OF_ATTACK = 'FOUL_COMMITMENT_OF_ATTACK',
  DENYING_OPPONENT_SCORE_BY_OFFENSE = 'DENYING_OPPONENT_SCORE_BY_OFFENSE',
}

export enum SubstitutionType {
  ON = 'ON',
  OFF = 'OFF',
}

export enum PeriodType {
  REGULAR = 'REGULAR',
  EXTENDED = 'EXTENDED',
}

export enum MatchStatus {
  BYE = 'BYE',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
  POSTPONED = 'POSTPONED',
  CANCELLED = 'CANCELLED',
  ABANDONED = 'ABANDONED',
  DRAFT = 'DRAFT',
}

export enum MatchesBulkActions {
  COMPLETE = 'COMPLETE',
  SCHEDULE = 'SCHEDULED',
  CANCEL = 'CANCELLED',
  POSTPONE = 'POSTPONED',
  ABANDON = 'ABANDONED',
  DELETE = 'DELETE',
}

export enum RankingTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum MatchPlayerOrigin {
  HOME = 'HOME',
  AWAY = 'AWAY',
}

export enum OfficialRole {
  HEAD_COUCH = 'HDCH',
  ASSISTANT_COUCH = 'ASCH',
  TEAM_MANAGER = 'TMGR',
  ASSISTANT_MANAGER = 'AMGR',
}

export enum JERSEY_PATTERNS {
  MONOCHROME = 'MONOCHROME',
  VERTICAL_STRIPES = 'VERTICAL_STRIPES',
  HORIZONTAL_STRIPES = 'HORIZONTAL_STRIPES',
  SINGLE_VERTICAL_STRIPE = 'SINGLE_VERTICAL_STRIPE',
  SINGLE_HORIZONTAL_STRIPE = 'SINGLE_HORIZONTAL_STRIPE',
  CHEQUERED = 'CHEQUERED',
  OBLIQUE_STRIPE_RIGHT_TO_LEFT = 'OBLIQUE_STRIPE_RIGHT_TO_LEFT',
  OBLIQUE_STRIPE_LEFT_TO_RIGHT = 'OBLIQUE_STRIPE_LEFT_TO_RIGHT',
  VERTICALLY_DIVIDED = 'VERTICALLY_DIVIDED',
  HORIZONTALLY_DIVIDED = 'HORIZONTALLY_DIVIDED',
  VERTICAL_NARROW_STRIPES = 'VERTICAL_NARROW_STRIPES',
  HORIZONTAL_NARROW_STRIPES = 'HORIZONTAL_NARROW_STRIPES',
}

export enum SHORTS_PATTERNS {
  MONOCHROME = 'MONOCHROME',
  SINGLE_VERTICAL_STRIPE = 'SINGLE_VERTICAL_STRIPE',
  VERTICALLY_DIVIDED = 'VERTICALLY_DIVIDED',
}

export enum SOCKS_PATTERNS {
  MONOCHROME = 'MONOCHROME',
  SINGLE_HORIZONTAL_STRIPE = 'SINGLE_HORIZONTAL_STRIPE',
  HORIZONTALLY_DIVIDED = 'HORIZONTALLY_DIVIDED',
}

export enum PlayerKitEntities {
  HOME_PLAYER_KIT = 'homePlayerKit',
  AWAY_PLAYER_KIT = 'awayPlayerKit',
  RESERVED_PLAYER_KIT = 'reservedPlayerKit',
}

export enum LogoSize {
  EXTRASMALL = 'extrasmall',
  SMALL = 'small',
  LARGE = 'large',
}

export enum CompetitionManagersRoles {
  REFEREE = 'Referee',
}

export enum RoleTypes {
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
  COMPETITION_MANAGER = 'COMPETITION_MANAGER',
  REFEREE = 'REFEREE',
  MA_SYSTEM_ADMIN = 'MA_SYSTEM_ADMIN',
}

export enum DISCIPLINE_OFFENDER {
  OFFICIAL = 'TEAM_OFFICIAL',
  PLAYER = 'PLAYER',
}

export enum DISCIPLINE_CARDS {
  YELLOW = 'YELLOW_CARD',
  RED = 'RED_CARD',
}

export enum SanctionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export enum OffenderType {
  PLAYER = 'PLAYER',
  TEAM_OFFICIAL = 'TEAM_OFFICIAL',
  MATCH_OFFICIAL = 'MATCH_OFFICIAL',
  TEAM = 'TEAM',
}

export enum SanctionType {
  SUSPENSION_FOR_NUMBER_OF_MATCHES = 'SUSPENSION_FOR_NUMBER_OF_MATCHES',
  SUSPENSION_FOR_NUMBER_OF_DAYS = 'SUSPENSION_FOR_NUMBER_OF_DAYS',
  FINANCIAL_PENALTY = 'FINANCIAL_PENALTY',
  POINTS_DEDUCTION = 'POINTS_DEDUCTION',
  DISQUALIFICATION_PENALTY = 'DISQUALIFICATION_PENALTY',
}

export enum MatchOfficialRoleType {
  REFEREE = 'REFEREE',
  SECOND_REFEREE = 'SECOND_REFEREE',
  ASSISTANT_REFEREE_1ST = 'ASSISTANT_REFEREE_1ST',
  ASSISTANT_REFEREE_2ND = 'ASSISTANT_REFEREE_2ND',
  FOURTH_OFFICIAL = 'FOURTH_OFFICIAL',
  ADDITIONAL_ASSISTANT_REFEREE_1ST = 'ADDITIONAL_ASSISTANT_REFEREE_1ST',
  ADDITIONAL_ASSISTANT_REFEREE_2ND = 'ADDITIONAL_ASSISTANT_REFEREE_2ND',
  RESERVE_ASSISTANT_REFEREE = 'RESERVE_ASSISTANT_REFEREE',
  DELEGATE = 'DELEGATE',
  REFEREE_OBSERVER = 'REFEREE_OBSERVER',
  VIDEO_ASSISTANT_REFEREE = 'VIDEO_ASSISTANT_REFEREE',
  ASSISTANT_VIDEO_ASSISTANT_REFEREE_1ST = 'ASSISTANT_VIDEO_ASSISTANT_REFEREE_1ST',
  ASSISTANT_VIDEO_ASSISTANT_REFEREE_2ND = 'ASSISTANT_VIDEO_ASSISTANT_REFEREE_2ND',
  MATCH_COMMISSIONER = 'MATCH_COMMISSIONER',
  CLUB_ADMIN = 'CLUB_ADMIN',
  SCORER = 'SCORER',
  ASSISTANT_SCORER = 'ASSISTANT_SCORER',
  LINE_JUDGE = 'LINE_JUDGE',
}

export enum UserRoleRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PlayerListRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum UserRoleType {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_COMPETITION_MANAGER = 'ROLE_COMPETITION_MANAGER',
  ROLE_REFEREE = 'ROLE_REFEREE',
  ROLE_MA_SYSTEM_ADMIN = 'ROLE_MA_SYSTEM_ADMIN',
  ROLE_MATCH_COMMISSIONER = 'ROLE_MATCH_COMMISSIONER',
  ROLE_CLUB_ADMIN = 'ROLE_CLUB_ADMIN',
  ROLE_REGIONAL_COMPETITION_MANAGER = 'ROLE_REGIONAL_COMPETITION_MANAGER',
}

export const defaultUserRoleSet = [
  UserRoleType.ROLE_REFEREE,
  UserRoleType.ROLE_COMPETITION_MANAGER,
  UserRoleType.ROLE_SUPER_ADMIN,
  UserRoleType.ROLE_MATCH_COMMISSIONER,
];

export const moGroupRoleSet = [
  UserRoleType.ROLE_SUPER_ADMIN,
  UserRoleType.ROLE_MA_SYSTEM_ADMIN,
];

export const managerUserRoleSet = [
  UserRoleType.ROLE_COMPETITION_MANAGER,
  UserRoleType.ROLE_SUPER_ADMIN,
];

export const setupUserRoleSet = [
  UserRoleType.ROLE_SUPER_ADMIN,
  UserRoleType.ROLE_MA_SYSTEM_ADMIN,
];

export const adminUserRoleSet = [
  UserRoleType.ROLE_MA_SYSTEM_ADMIN,
  UserRoleType.ROLE_SUPER_ADMIN,
];

export const superUserRoleSet = [UserRoleType.ROLE_SUPER_ADMIN];

export enum RegistrationTypes {
  PLAYER = 'player',
  MATCH_OFFICIAL = 'matchOfficial',
  TEAM_OFFICIAL = 'teamOfficial',
}

export enum RegistrationNature {
  NEW = 'NEW',
  TRANSFER = 'TRANSFER',
  RENEWAL = 'RENEWAL',
  DOMESTIC_LOAN = 'DOMESTIC_LOAN',
  AMENDMENT = 'AMENDMENT',
  ACCESS = 'ACCESS',
  INTERNATIONAL_LOAN = 'INTERNATIONAL_LOAN',
  INT_TRANSFER_OUT = 'INT_TRANSFER_OUT',
  INT_TRANSFER_RETURN = 'INT_TRANSFER_RETURN',
  API_DATA_ACCESS_REQ = 'API_DATA_ACCESS_REQ',
  PLAYER_CONTRACT = 'PLAYER_CONTRACT',
}

export enum ConflictType {
  SCHEDULE = 'SCHEDULE',
  REFEREE_CATEGORY = 'REFEREE_CATEGORY',
  VENUE = 'VENUE',
  APPOINTMENT = 'APPOINTMENT',
}

export enum CalendarConflictType {
  VENUE = 'VENUE',
  MATCH = 'MATCH',
  REFEREE = 'REFEREE',
  MA_OFFICIAL = 'MA_OFFICIAL',
}

export enum MemberType {
  REFEREE = 'REFEREE',
  MA_OFFICIAL = 'MA_OFFICIAL',
}

export enum CalendarEventStatusType {
  DEFAULT = 'DEFAULT',
  MODERATE = 'MODERATE',
  MAJOR = 'MAJOR',
  CRITICAL = 'CRITICAL',
  COMPLETED = 'COMPLETED',
}

export enum MatchSheetErrorType {
  TOTAL_PLAYERS = 'TOTAL_PLAYERS',
  TEAM_OFFICIALS = 'TEAM_OFFICIALS',
}

export enum PlayerListErrorType {
  TOTAL_PLAYERS = 'TOTAL_PLAYERS',
  AGE_VALIDATION = 'AGE_VALIDATION',
}

export enum DocumentReportType {
  REPORT = 'REPORT',
  PHOTO = 'PHOTO',
  OTHER = 'OTHER',
}

export enum TerrainType {
  SNOW = 'SNOW',
  SAND = 'SAND',
  PARQUET = 'PARQUET',
  TARAFLEX = 'TARAFLEX',
  OTHER = 'OTHER',
}

export enum CourtSurface {
  HARDWOOD = 'HARDWOOD',
  MULTI_PURPOSE = 'MULTI_PURPOSE',
  SYNTHETIC = 'SYNTHETIC',
  ASPHALT = 'ASPHALT',
  OTHER = 'OTHER',
}

export enum LanguageDirections {
  RTL = 'rtl',
  LTR = 'ltr',
}

export enum MatchSets {
  SET_1_0 = 'SET_1_0',
  SET_0_1 = 'SET_0_1',
  SET_2_0 = 'SET_2_0',
  SET_2_1 = 'SET_2_1',
  SET_1_2 = 'SET_1_2',
  SET_0_2 = 'SET_0_2',
  SET_3_0 = 'SET_3_0',
  SET_3_1 = 'SET_3_1',
  SET_3_2 = 'SET_3_2',
  SET_2_3 = 'SET_2_3',
  SET_1_3 = 'SET_1_3',
  SET_0_3 = 'SET_0_3',
}

export const KitTypes = new Array<KitType>('home', 'away', 'reserved');

export const KitPlayerTypes = new Array<KitPlayerType>('regular', 'special');

export enum APP_SPORT {
  BASKETBALL = 'basketball',
  VOLLEYBALL = 'volleyball',
}
