import i18next from 'i18next';

import { APP_SPORT } from '@core/types';

export enum APP_TITLES {
  POPULAR_NAME = 'POPULAR_NAME',
  POPULAR_NAME_TOOLTIP = 'POPULAR_NAME_TOOLTIP',
}

export const getTitle = (title: APP_TITLES): string => {
  const titlesMap = {
    [APP_TITLES.POPULAR_NAME]: {
      [APP_SPORT.BASKETBALL]: i18next.t('Nickname'),
      [APP_SPORT.VOLLEYBALL]: i18next.t('Popular name'),
    },
    [APP_TITLES.POPULAR_NAME_TOOLTIP]: {
      [APP_SPORT.BASKETBALL]: i18next.t('Nickname of the person'),
      [APP_SPORT.VOLLEYBALL]: i18next.t('Popular name of the person'),
    },
  };

  return titlesMap[title]?.[import.meta.env.VITE_SPORT] || title;
};
