import { createSelector } from '@reduxjs/toolkit';

import { State } from '@core/store';

const getIds = (state: State, ids: Array<number>) => ids;
const getCompetitors = (state: State) => state.api.competitorsList;

export const getCompetitorsById = createSelector(
  getIds,
  getCompetitors,
  (ids, competitors) => ids.map((id: number) => competitors[id]),
);
