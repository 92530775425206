import React from 'react';
import { Box, Theme, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  agreed: boolean;
  onChange: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  agreeBlock: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  agreeLabel: {
    display: 'block',
    position: 'relative',
    margin: 0,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2.5, 8, 2.5, 8),
    borderRadius: theme.spacing(0.25),
  },
  agreeCheckbox: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
  },
}));

const TermsAndConditionsAgree = ({ agreed, onChange }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.agreeBlock}>
      <FormControlLabel
        className={classes.agreeLabel}
        control={
          <Checkbox
            className={classes.agreeCheckbox}
            checked={agreed}
            onChange={onChange}
            name="acceptTerms"
          />
        }
        label="T&C Agreement Placeholder"
      />
    </Box>
  );
};

export default TermsAndConditionsAgree;
