import { all, take, put, select, call, Effect } from 'redux-saga/effects';

import { ManageFilter } from '@core/helpers';
import { FilterModel } from '@core/types';

import { getFiltersList } from './selectors';
import actions from './actions';

function* getManageFilters(filter: FilterModel): Generator<Effect, any, any> {
  const filterList = yield select(getFiltersList);

  return new ManageFilter(filter, filterList);
}

export function* addFilterFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.addFilter);
    const manageFilters = yield call(getManageFilters, payload);

    yield put(actions.setFilters(manageFilters.addFilter()));
  }
}

export function* removeFilterFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.removeFilter);
    const manageFilters = yield call(getManageFilters, payload);

    yield put(actions.setFilters(manageFilters.removeFilter()));
  }
}

export function* replaceFilterFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.replaceFilter);
    const manageFilters = yield call(getManageFilters, payload);

    yield put(actions.setFilters(manageFilters.replaceFilter()));
  }
}

export default function* sagas() {
  yield all([addFilterFlow(), removeFilterFlow(), replaceFilterFlow()]);
}
