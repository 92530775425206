import { Sports, Sport } from '@core/types';

export const getTeamColorSetsHaveSpecialPlayer = (
  sportOrSports: null | undefined | Sport | Array<Sport>,
): boolean => {
  const predicate = (sport: Sport): boolean =>
    [Sports.VOLLEYBALL].includes(sport.type);

  return sportOrSports
    ? Array.isArray(sportOrSports)
      ? sportOrSports.some(predicate)
      : predicate(sportOrSports)
    : null;
};
