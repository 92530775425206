import { MatchSets } from '@volleyball/types';

export enum STANDING_SETTINGS_FIELD_NAMES {
  matchSetWin1 = 'matchSetWin1',
  matchSetWin2 = 'matchSetWin2',
  matchSetLoss1 = 'matchSetLoss1',
  matchSetLoss2 = 'matchSetLoss2',
  matchSetSettings = 'matchSetSettings',
  standingTableHeaders = 'standingTableHeaders',
  standingTableRanks = 'standingTableRanks',
}

export const dependantSetsOfWin1 = [
  MatchSets.SET_1_0,
  MatchSets.SET_2_0,
  MatchSets.SET_3_0,
  MatchSets.SET_3_1,
];

export const dependantSetsOfWin2 = [MatchSets.SET_2_1, MatchSets.SET_3_2];

export const dependantSetsOfLoss1 = [
  MatchSets.SET_0_1,
  MatchSets.SET_1_2,
  MatchSets.SET_2_3,
];

export const dependantSetsOfLoss2 = [
  MatchSets.SET_0_2,
  MatchSets.SET_1_3,
  MatchSets.SET_0_3,
];
