import i18next from 'i18next';
import { UserRoleType } from '@core/types';

export const getUserRoleTypeValues = () => ({
  [UserRoleType.ROLE_SUPER_ADMIN]: i18next.t('Super admin'),
  [UserRoleType.ROLE_USER]: i18next.t('User'),
  [UserRoleType.ROLE_COMPETITION_MANAGER]: i18next.t('Competition Manager'),
  [UserRoleType.ROLE_REFEREE]: i18next.t('Referee'),
  [UserRoleType.ROLE_MA_SYSTEM_ADMIN]: i18next.t('MA system admin'),
  [UserRoleType.ROLE_MATCH_COMMISSIONER]: i18next.t('Match Commissioner'),
  [UserRoleType.ROLE_CLUB_ADMIN]: i18next.t('Club admin'),
  [UserRoleType.ROLE_REGIONAL_COMPETITION_MANAGER]: i18next.t(
    'Regional Competition Manager',
  ),
});
