import { assoc, pipe, identity } from 'ramda';

import {
  JERSEY_PATTERNS,
  PlayerKitsModel,
  SHORTS_PATTERNS,
  SOCKS_PATTERNS,
  PlayerKitColors,
} from '@core/types';
import { isValue } from '@core/helpers';
import toCamelCase from '@core/helpers/formatting/to-camel-case';

interface AddSecondaryColor {
  selectedPattern: JERSEY_PATTERNS | SHORTS_PATTERNS | SOCKS_PATTERNS;
  secondaryColor: string;
  itemPostFix: 'Jersey' | 'Shorts' | 'Socks';
}

function addSecondaryColorForItem(props: AddSecondaryColor) {
  const { selectedPattern, secondaryColor, itemPostFix } = props;
  const formattedColorPropName = `${toCamelCase(
    selectedPattern,
  )}${itemPostFix}`;
  const addSecondaryColor = assoc(formattedColorPropName, secondaryColor);
  const isMonochrome = selectedPattern === 'MONOCHROME';

  return isMonochrome ? identity : addSecondaryColor;
}

function addPrimaryColors(playerKits: Omit<PlayerKitsModel, 'id'>) {
  return pipe(
    // @ts-expect-error
    assoc('primaryJersey', playerKits.primaryJerseyColorCode),
    // @ts-expect-error
    assoc('primarySocks', playerKits.primarySocksColorCode),
    // @ts-expect-error
    assoc('primaryShorts', playerKits.primaryShortsColorCode),
  );
}

function addSecondaryJerseyColor(props: {
  selectedPattern: JERSEY_PATTERNS;
  secondaryColor: string;
}) {
  return addSecondaryColorForItem({
    ...props,
    itemPostFix: 'Jersey',
  });
}

function addSecondarySocksColor(props: {
  selectedPattern: SOCKS_PATTERNS;
  secondaryColor: string;
}) {
  return addSecondaryColorForItem({
    ...props,
    itemPostFix: 'Socks',
  });
}

function addSecondaryShortsColor(props: {
  selectedPattern: SHORTS_PATTERNS;
  secondaryColor: string;
}) {
  return addSecondaryColorForItem({
    ...props,
    itemPostFix: 'Shorts',
  });
}

export function getDefaultPlayerKitColors() {
  return [
    '#FFFFFF',
    '#E60000',
    '#8A002B',
    '#C8024E',
    '#66419B',
    '#0C3770',
    '#1565C0',
    '#74D0F1',
    '#00725F',
    '#4CAF50',
    '#FFEB3B',
    '#FFCE00',
    '#FF9800',
    '#FF5722',
    '#1F120D',
  ];
}

export function generatePlayerKitColors(
  playerKits: Omit<PlayerKitsModel, 'id'>,
): PlayerKitColors | any {
  if (!isValue(playerKits)) {
    return {};
  }

  const {
    // @ts-expect-error
    jerseyPattern,
    // @ts-expect-error
    secondaryJerseyColorCode,
    // @ts-expect-error
    shortsPattern,
    // @ts-expect-error
    secondaryShortsColorCode,
    // @ts-expect-error
    socksPattern,
    // @ts-expect-error
    secondarySocksColorCode,
  } = playerKits;

  return pipe(
    addSecondaryJerseyColor({
      selectedPattern: jerseyPattern,
      secondaryColor: secondaryJerseyColorCode,
    }),
    addSecondaryShortsColor({
      selectedPattern: shortsPattern,
      secondaryColor: secondaryShortsColorCode,
    }),
    addSecondarySocksColor({
      selectedPattern: socksPattern,
      secondaryColor: secondarySocksColorCode,
    }),
    addPrimaryColors(playerKits),
  )({});
}
