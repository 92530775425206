import { CalendarConflictType, DashboardMatchModel } from '@core/types';

export enum ConflictSeverityType {
  NONE = 0,
  MODERATE = 1,
  MAJOR = 2,
  CRITICAL = 3,
}

export interface BaseConflict {
  alertLevel: ConflictSeverityType;
  conflictingMatches: Array<number>;
  matchId: number;
  type?: CalendarConflictType;
}

export interface VenueConflict extends BaseConflict {
  venueId: number;
}

export interface TeamConflict extends BaseConflict {}

export interface MatchOfficialAppointmentConflict extends BaseConflict {
  matchOfficialId?: number;
}

export type Conflict =
  | VenueConflict
  | TeamConflict
  | MatchOfficialAppointmentConflict;

export interface ConflictResponse {
  /** @deprecated not available at runtime */
  id: number;
  conflicts: Array<Conflict>;
}

export interface MatchFixture extends DashboardMatchModel {
  conflicts: null | Array<Conflict>;
  dateLocalized?: string;
  timeLocalized?: string;
}
