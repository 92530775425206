import { call, Effect, put } from 'redux-saga/effects';

import * as competitorsApi from '@core/api/competitors';
import { getCompetition } from '@core/pages/competitions/store/api/sagas';
import {
  CreateCompetitorProps,
  DeleteCompetitorProps,
  UpdateCompetitorProps,
} from '@core/types';

import { actions as competitorsActions } from '.';

interface GetCompetitorsProps {
  competitionId: number;
  page?: number;
  limit?: number;
  query?: string;
}
export function* getCompetitorsByCompetition({
  competitionId,
  ...queryParams
}: GetCompetitorsProps): Generator<Effect, any, any> {
  yield put(competitorsActions.getCompetitorsByCompetitionRequest());
  const response = (yield call(competitorsApi.getCompetitorsByCompetition, {
    competitionId,
    queryParams,
  })) as InferApi<typeof competitorsApi.getCompetitorsByCompetition>;

  if (!response.error) {
    yield put(competitorsActions.getCompetitorsByCompetitionSuccess());
    yield put(
      competitorsActions.setCompetitors(
        response.response._embedded.competitors,
      ),
    );
  } else {
    yield put(competitorsActions.getCompetitorsByCompetitionFailure());
  }

  return response;
}

interface GetCompetitorByTeamIdProps {
  competitionId: number;
  teamId: number;
}

export function* getCompetitorByTeamId({
  competitionId,
  teamId,
}: GetCompetitorByTeamIdProps) {
  yield put(competitorsActions.getCompetitorByTeamRequest());
  const response = (yield call(
    competitorsApi.getCompetitorByTeam,
    competitionId,
    teamId,
  )) as InferApi<typeof competitorsApi.getCompetitorByTeam>;

  if (!response.error) {
    yield put(competitorsActions.getCompetitorByTeamSuccess());
    yield put(competitorsActions.setCompetitor(response.response));
  } else {
    yield put(competitorsActions.getCompetitorByTeamFailure());
  }

  return response;
}

export function* getCompetitorPlaceholder(
  props: GetCompetitorByTeamIdProps,
): Generator<Effect, any, any> {
  yield put(competitorsActions.getCompetitorPlaceholderRequest());
  const response = yield call(competitorsApi.getCompetitorPlaceholder, props);

  if (!response.error) {
    yield put(competitorsActions.getCompetitorPlaceholderSuccess());
  } else {
    yield put(competitorsActions.getCompetitorPlaceholderFailure());
  }

  return response;
}

export function* createCompetitor(
  props: CreateCompetitorProps,
): Generator<Effect, any, any> {
  yield put(competitorsActions.createCompetitorRequest());
  const response = yield call(competitorsApi.createCompetitor, props);

  if (!response.error) {
    yield put(competitorsActions.createCompetitorSuccess());
    yield call(getCompetition, props.competitionId);
  } else {
    yield put(competitorsActions.createCompetitorFailure());
  }

  return response;
}

export function* deleteCompetitor(props: DeleteCompetitorProps) {
  yield put(competitorsActions.deleteCompetitorRequest());
  const response = (yield call(
    competitorsApi.deleteCompetitor,
    props,
  )) as InferApi<typeof competitorsApi.deleteCompetitor>;

  if (!response.error) {
    yield put(competitorsActions.deleteCompetitorSuccess());
  } else {
    yield put(competitorsActions.deleteCompetitorFailure());
  }

  return response;
}

export function* updateCompetitor(props: UpdateCompetitorProps) {
  yield put(competitorsActions.updateCompetitorRequest());
  const response = (yield call(
    competitorsApi.updateCompetitor,
    props,
  )) as InferApi<typeof competitorsApi.updateCompetitor>;

  if (!response.error) {
    yield put(competitorsActions.updateCompetitorSuccess());
    yield call(getCompetitorByTeamId, {
      competitionId: props.competitionId,
      teamId: props.teamId,
    });
  } else {
    yield put(competitorsActions.updateCompetitorFailure());
  }

  return response;
}
