import { createAction } from 'redux-actions';

import * as COMPETITION_LEVELS_ACTION_TYPES from './constants';

export const setBeingModified = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.SET_LEVEL_BEING_MODIFIED,
);
export const setNewBeingCreated = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.SET_NEW_LEVEL_BEING_CREATED,
);
export const setErrors = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.SET_ERRORS,
);
export const setCompetitionLevels = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.SET_COMPETITION_LEVELS,
);

export const getCompetitionLevels = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.GET_COMPETITION_LEVELS,
);
export const getCompetitionLevelsRequest = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.GET_COMPETITION_LEVELS_REQUEST,
);
export const getCompetitionLevelsSuccess = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.GET_COMPETITION_LEVELS_SUCCESS,
);
export const getCompetitionLevelsFailure = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.GET_COMPETITION_LEVELS_FAILURE,
);

export const createCompetitionLevel = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.CREATE_COMPETITION_LEVEL,
);
export const createCompetitionLevelRequest = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.CREATE_COMPETITION_LEVEL_REQUEST,
);
export const createCompetitionLevelSuccess = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.CREATE_COMPETITION_LEVEL_SUCCESS,
);
export const createCompetitionLevelFailure = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.CREATE_COMPETITION_LEVEL_FAILURE,
);

export const updateCompetitionLevel = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.UPDATE_COMPETITION_LEVEL,
);
export const updateCompetitionLevelRequest = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.UPDATE_COMPETITION_LEVEL_REQUEST,
);
export const updateCompetitionLevelSuccess = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.UPDATE_COMPETITION_LEVEL_SUCCESS,
);
export const updateCompetitionLevelFailure = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.UPDATE_COMPETITION_LEVEL_FAILURE,
);

export const deleteCompetitionLevel = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.DELETE_COMPETITION_LEVEL,
);
export const deleteCompetitionLevelRequest = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.DELETE_COMPETITION_LEVEL_REQUEST,
);
export const deleteCompetitionLevelSuccess = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.DELETE_COMPETITION_LEVEL_SUCCESS,
);
export const deleteCompetitionLevelFailure = createAction(
  COMPETITION_LEVELS_ACTION_TYPES.DELETE_COMPETITION_LEVEL_FAILURE,
);
