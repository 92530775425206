import React, { useEffect, useRef } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';

import {
  ColorsField,
  FullScreenSpinner,
  TextField,
  Toggle,
} from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  ClubModel,
  KitSetCreateData,
  KitSetPatchData,
  KitTypes,
  KitSet,
} from '@core/types';
import { SUBMIT_BUTTON } from '@core/constants/test-ids';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import * as clubProfileSelectors from '@core/pages/club-profile/store/selectors';
import { getTeamColorSetsHaveSpecialPlayer } from '@core/factories/kit-set/get-team-color-sets-have-special-player';
import { getKitSet } from '@core/store/modules/tabs/edit-kit-set/selectors';
import { actions as editKitSetActions } from '@core/store/modules/tabs/edit-kit-set';
import { actions as createKitSetActions } from '@core/store/modules/tabs/create-kit-set';
import { State } from '@core/store';

import {
  CREATE_TEAM_KIT_PAGE,
  KITSET_FIELDS,
  KitSetFormValues,
  defaultPlayerKitColors,
  getColorsFieldName,
  getInitialValues,
  getIsReservedKitEnabled,
  getIsSpecialPlayerEnabled,
  getKitTitle,
  getPayload,
  getSpecialPlayerKitFieldLabel,
  getSpecialPlayerKitToggleLabel,
  getValidationSchema,
  getIsAwayKitEnabled,
} from './helpers';

import {
  SPECIAL_PLAYER_KIT_TOGGLE,
  SECONDARY_KIT_TOGGLE,
  TERTIARY_KIT_TOGGLE,
  TEAM_COLORS,
  LIBERO_COLORS,
} from './tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 1000,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  form: {
    width: '100%',
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
  dividerContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    flex: 1,
  },
}));

export interface OwnProps {
  clubId: number;
  competitionId: number | null;
  kitSetId?: number;
  tabId: string;
  teamColorSetsHaveSpecialPlayer: boolean;
}

interface DispatchProps {
  actions: {
    createKitSet: typeof createKitSetActions;
    editKitSet: typeof editKitSetActions;
    tab: typeof tabActions;
  };
}

interface StateProps {
  club: ClubModel;
  kitSet: KitSet;
  isLoading: boolean;
  isSubmittingLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const CreateKitSet = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    tabId,
    actions,
    clubId,
    competitionId,
    kitSet,
    kitSetId,
    isLoading,
    isSubmittingLoading,
    ...rest
  } = props;
  const isEdit = !!kitSetId;
  const isSubmittingOrLoading = isLoading || isSubmittingLoading;

  // prefer live binding to a club on a club profile page when possible since sports can be changed within club profile on a parallel tab
  // else use static prop initialized on tab opening which should be fine if club properties cannot be changed on a parallel tab
  const teamColorSetsHaveSpecialPlayer = rest.club
    ? getTeamColorSetsHaveSpecialPlayer(props.club?.sports)
    : rest.teamColorSetsHaveSpecialPlayer;

  const [isSpecialPlayerEnabled, setIsSpecialPlayerEnabled] =
    React.useState(false);
  const toggleSpecialPlayer = React.useCallback(
    () => setIsSpecialPlayerEnabled((current) => !current),
    [],
  );

  const [isAwayKitEnabled, setIsAwayKitEnabled] = React.useState(false);
  const toggleIsAwayKit = React.useCallback(
    () => setIsAwayKitEnabled((current) => !current),
    [],
  );

  const [isReservedKitEnabled, setIsReservedKitEnabled] = React.useState(false);
  const toggleReservedKit = React.useCallback(
    () => setIsReservedKitEnabled((current) => !current),
    [],
  );

  useEffect(() => {
    isEdit && actions.editKitSet.getKitSet({ kitSetId });

    return () => {
      isEdit
        ? actions.editKitSet.resetKitSetState({ kitSetId })
        : actions.createKitSet.resetState();
    };
  }, [actions.createKitSet, actions.editKitSet, isEdit, kitSetId]);

  useEffect(() => {
    if (isEdit && kitSet) {
      setIsSpecialPlayerEnabled(
        teamColorSetsHaveSpecialPlayer && getIsSpecialPlayerEnabled(kitSet),
      );
      setIsAwayKitEnabled(getIsAwayKitEnabled(kitSet));
      setIsReservedKitEnabled(getIsReservedKitEnabled(kitSet));
    }
  }, [isEdit, kitSet, teamColorSetsHaveSpecialPlayer]);

  const handleSubmit: FormikConfig<KitSetFormValues>['onSubmit'] = (
    values,
    formikHelpers,
  ) => {
    const postOrPatchPayload = getPayload(values, {
      isAwayKitEnabled,
      isReservedKitEnabled,
      isSpecialPlayerEnabled,
    });

    isEdit
      ? actions.editKitSet.updateKitSet({
          data: postOrPatchPayload as KitSetPatchData,
          kitSetId,
          clubId,
          competitionId,
          tabId,
        })
      : actions.createKitSet.createKitSet({
          clubId,
          data: postOrPatchPayload as KitSetCreateData,
          competitionId,
          tabId,
        });
  };

  const formRef = useRef<FormikProps<KitSetFormValues>>();

  const submit = () => formRef.current?.handleSubmit();

  const cancel = () => actions.tab.removeTab({ tabId });

  return (
    <>
      <Paper>
        <Box p={3} mb={2} data-qa={CREATE_TEAM_KIT_PAGE}>
          <Box mb={2}>
            <Typography variant="h6">
              {isEdit ? t('Edit color set') : t('Create color set')}{' '}
            </Typography>
          </Box>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={getInitialValues(isEdit, kitSet)}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t, {
              isAwayKitEnabled,
              isReservedKitEnabled,
              isSpecialPlayerEnabled,
            })}
          >
            {({ values }) =>
              !values || isLoading ? (
                <FullScreenSpinner />
              ) : (
                <Form className={classes.form} noValidate>
                  <Grid container direction="column">
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Box mt={3}>
                          <Divider orientation="horizontal" />
                        </Box>
                      </Grid>
                      <Grid container item xs={12} justifyContent="flex-end">
                        <Box mt={3} mb={5}>
                          <Typography variant="caption" color="textSecondary">
                            {`* ${t('Indicates a required field')}`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} md={2}>
                          <Typography
                            variant="caption"
                            className={classes.uppercase}
                          >
                            {t('Color set properties')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={8}
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item xs={12} md={6}>
                            <Box>
                              <TextField
                                name={'title'}
                                data-qa={KITSET_FIELDS.title}
                                required
                                label={t('Color set name')}
                                withTooltipMargin={false}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box>
                              {teamColorSetsHaveSpecialPlayer && (
                                <Toggle
                                  checked={isSpecialPlayerEnabled}
                                  onChange={toggleSpecialPlayer}
                                  label={getSpecialPlayerKitToggleLabel(t)}
                                  data-qa={SPECIAL_PLAYER_KIT_TOGGLE}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Box className={classes.dividerContainer}>
                          <Divider orientation="horizontal" />
                        </Box>
                      </Grid>
                      {KitTypes.map((kitType, index, array) => {
                        const isAwayKitType = kitType === 'away';
                        const isReservedKitType = kitType === 'reserved';

                        const isRegularPlayerKitDisabled = isAwayKitType
                          ? !isAwayKitEnabled
                          : isReservedKitType
                            ? !isReservedKitEnabled
                            : false;
                        const isSpecialPlayerKitDisabled =
                          isRegularPlayerKitDisabled || !isSpecialPlayerEnabled;

                        return (
                          <React.Fragment key={index}>
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={12} md={2}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Typography
                                      variant="caption"
                                      className={classes.uppercase}
                                    >
                                      {getKitTitle(t, kitType)}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {isAwayKitType && (
                                      <Toggle
                                        checked={isAwayKitEnabled}
                                        onChange={toggleIsAwayKit}
                                        label={''}
                                        data-qa={SECONDARY_KIT_TOGGLE}
                                      />
                                    )}
                                    {isReservedKitType && (
                                      <Toggle
                                        checked={isReservedKitEnabled}
                                        onChange={toggleReservedKit}
                                        label={''}
                                        data-qa={TERTIARY_KIT_TOGGLE}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} md={8} spacing={2}>
                                <Grid item xs={12} md={6} data-qa={TEAM_COLORS}>
                                  <Box>
                                    <ColorsField
                                      colors={defaultPlayerKitColors}
                                      disabled={isRegularPlayerKitDisabled}
                                      label={t('Team colors')}
                                      name={getColorsFieldName(
                                        kitType,
                                        'regular',
                                      )}
                                    />
                                  </Box>
                                </Grid>
                                {teamColorSetsHaveSpecialPlayer && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    data-qa={LIBERO_COLORS}
                                  >
                                    <Box>
                                      <ColorsField
                                        colors={defaultPlayerKitColors}
                                        disabled={isSpecialPlayerKitDisabled}
                                        label={getSpecialPlayerKitFieldLabel(t)}
                                        name={getColorsFieldName(
                                          kitType,
                                          'special',
                                        )}
                                      />
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                            {index !== array.length - 1 && (
                              <Grid container item xs={12}>
                                <Box className={classes.dividerContainer}>
                                  <Divider orientation="horizontal" />
                                </Box>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Form>
              )
            }
          </Formik>
        </Box>
      </Paper>
      <Grid container justifyContent="flex-end">
        <Box mt={1} display="flex">
          {tabId && (
            <Grid item>
              <Box mr={1}>
                <Button
                  disabled={isSubmittingOrLoading}
                  onClick={cancel}
                  variant="outlined"
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item>
            <ButtonWithProgress
              data-qa={SUBMIT_BUTTON}
              isLoading={isSubmittingOrLoading}
              onClick={submit}
              type="submit"
              variant="contained"
            >
              {isEdit ? t('Save') : t('Create')}
            </ButtonWithProgress>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    editKitSetActions.getKitSetRequest({
      kitSetId: ownProps.kitSetId,
    }),
  ]);

const isSubmittingLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    createKitSetActions.createKitSetRequest({ clubId: ownProps.clubId }),
    editKitSetActions.updateKitSetRequest({ kitSetId: ownProps.kitSetId }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    createKitSet: bindActionCreators(createKitSetActions, dispatch),
    editKitSet: bindActionCreators(editKitSetActions, dispatch),
    tab: bindActionCreators(tabActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  club: clubProfileSelectors.getClub(state, { clubId: ownProps.clubId }),
  kitSet: getKitSet(state, { kitSetId: ownProps.kitSetId }),
  isLoading: isLoadingSelector(ownProps)(state),
  isSubmittingLoading: isSubmittingLoadingSelector(ownProps)(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateKitSet);
