import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g transform="translate(3 2)">
      <rect width="14" height="2" x="2" y="4" opacity=".3" />
      <path d="M2,8 L16,8 L16,10 L18,10 L18,4 C18,2.9 17.1,2 16,2 L15,2 L15,0 L13,0 L13,2 L5,2 L5,0 L3,0 L3,2 L2,2 C0.89,2 0.01,2.9 0.01,4 L0,18 C0,19.1 0.89,20 2,20 L9,20 L9,18 L2,18 L2,8 Z M2,4 L16,4 L16,6 L2,6 L2,4 Z M19.84,14.28 L19.13,14.99 L17.01,12.87 L17.72,12.16 C18.11,11.77 18.74,11.77 19.13,12.16 L19.84,12.87 C20.23,13.26 20.23,13.89 19.84,14.28 Z M16.3,13.58 L18.42,15.7 L13.12,21 L11,21 L11,18.88 L16.3,13.58 Z" />
    </g>
  </SvgIcon>
);
