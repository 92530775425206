import { RegistrationTypes } from '@core/types';

export const getRegistrationTypes = (
  t: (key: string) => string,
  registrationType: RegistrationTypes,
) => {
  switch (registrationType) {
    case RegistrationTypes.PLAYER:
      return t('Player');
    case RegistrationTypes.MATCH_OFFICIAL:
      return t('Match Official');
    case RegistrationTypes.TEAM_OFFICIAL:
      return t('Team Official');
  }
};
