import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextField } from '@core/components';
import { getMatchFormFieldNames } from './helpers';

type Props = {
  responsive?: boolean;
};

export const MatchDataFormFieldsMatchAttendance = ({ responsive }: Props) => {
  const { t } = useTranslation();
  const formFieldNames = getMatchFormFieldNames();

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={12} md={responsive && 6}>
        <TextField
          fullWidth
          name={formFieldNames.attendance}
          data-qa={formFieldNames.attendance}
          label={t('Attendance')}
        />
      </Grid>
    </Grid>
  );
};
