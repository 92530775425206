import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HighlightOffTwoTone as HighlightOffTwoToneIcon } from '@mui/icons-material';
import { FieldArray, useFormikContext } from 'formik';

import { ErrorMessage } from '@core/components';
import ColorInputField from '@core/components/color-input-field';

const useStyles = makeStyles((theme) => ({
  colorInputFieldContainer: {
    position: 'relative',
  },
  removeColorIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    border: 'none',
    borderRadius: '50%',
    padding: '0px',
    fontSize: 0,
  },
  removeColorIcon: {
    border: 'none',
    borderRadius: '50%',
  },
}));

interface OwnProps {
  colors: Array<string>;
  disabled: boolean;
  label: string;
  maxNumberOfColors?: number;
  minNumberOfColors?: number;
  name: string;
}

const ColorsField = (props: OwnProps) => {
  const {
    colors,
    disabled,
    label,
    maxNumberOfColors = 2,
    minNumberOfColors = 1,
    name,
  } = props;

  const formikContext = useFormikContext();

  const classes = useStyles();

  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid container item spacing={2}>
        <FieldArray name={name}>
          {(fieldArrayRenderProps) => {
            const { form, name: _, ...arrayHelpers } = fieldArrayRenderProps;

            const values = form.getFieldMeta(name).value as Array<string>;

            const getFieldName = (index: number) => `${name}[${index}]`;

            return (
              <>
                {values.map((value, index) => {
                  const backgroundColor = theme.palette.getContrastText(value);
                  const color = theme.palette.getContrastText(backgroundColor);
                  const white = theme.palette.common.white;

                  return (
                    <Grid item key={index}>
                      <div className={classes.colorInputFieldContainer}>
                        <ColorInputField
                          colors={colors}
                          disabled={disabled}
                          label=""
                          name={getFieldName(index)}
                        />
                        {values.length !== minNumberOfColors && !disabled && (
                          <div
                            className={classes.removeColorIconContainer}
                            style={{ background: white }}
                          >
                            <HighlightOffTwoToneIcon
                              className={classes.removeColorIcon}
                              onClick={() => arrayHelpers.remove(index)}
                              style={{ backgroundColor, color }}
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  );
                })}
                {values.length !== maxNumberOfColors && (
                  <Grid item>
                    <div className={classes.colorInputFieldContainer}>
                      <ColorInputField
                        colors={colors}
                        disabled={disabled}
                        label="+"
                        name={getFieldName(values.length)}
                      />
                    </div>
                  </Grid>
                )}
              </>
            );
          }}
        </FieldArray>
      </Grid>
      {formikContext.getFieldMeta(name).error && (
        <Grid item>
          <ErrorMessage name={name} absolutePosition={false} />
        </Grid>
      )}
    </Grid>
  );
};

export default ColorsField;
