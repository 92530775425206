import { Action, createActions } from 'redux-actions';
import { ModalTypes } from '@core/types';

export const NAMESPACE = 'globalModal';

export const actions = createActions(
  'CLEAR_MODAL_ERRORS',
  'CLOSE_MODAL',
  'OPEN_MODAL',
  'SET_MODAL_ERRORS',
  'SET_MODAL_STATE',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  // TODO: JB: interface GlobalModalProps<ModalParams = any> should probably use modalActions: typeof actions
  clearModalErrors: NoopAction;
  closeModal: NoopAction;
  openModal: <T extends object = any>(
    ...args: [{ type: ModalTypes; params?: T }]
  ) => Action<{ type: ModalTypes; params?: T }>;
  // resetState: NoopAction;
  setModalErrors: IdentityActionFunction<any>;
  setModalState: <T extends object = any>(
    ...args: [{ isOpen: boolean; type: ModalTypes; params?: T }]
  ) => Action<{ isOpen: boolean; type: ModalTypes; params?: T }>;
  // setModalType: IdentityActionFunction<{modalType: ModalTypes}>;
  // setParams: IdentityActionFunction<any>;
};
