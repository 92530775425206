import dayjs from 'dayjs';

export const LANGUAGE_KEY = 'language';
const ACCESS_TOKEN_KEY = 'authorized';
const SESSION_EXPIRES = 'sessionExpires';
const SESSION_DURATION = 'sessionDuration';
const AUTH_PROVIDER = 'authProvider';

export const getAuthProvider = (): 'auth0' =>
  (localStorage.getItem(AUTH_PROVIDER) as any) ?? 'auth0';
export const setAuthProvider = (authProvider: 'auth0') =>
  localStorage.setItem(AUTH_PROVIDER, authProvider);
export const clearAuthProvider = () => localStorage.removeItem(AUTH_PROVIDER);

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const setAccessToken = () =>
  localStorage.setItem(ACCESS_TOKEN_KEY, dayjs().toISOString());
export const clearAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY);

export const setLanguage = (language: string) =>
  localStorage.setItem(LANGUAGE_KEY, language);
export const getLanguage = () => localStorage.getItem(LANGUAGE_KEY);

export const setSessionExpirationTime = (timeToExpiration: string) =>
  localStorage.setItem(SESSION_EXPIRES, timeToExpiration);
export const getSessionExpirationTime = () =>
  localStorage.getItem(SESSION_EXPIRES);
export const clearSessionExpirationTime = () =>
  localStorage.removeItem(SESSION_EXPIRES);

export const setSessionDuration = (sessionDuration: number) =>
  localStorage.setItem(SESSION_DURATION, sessionDuration.toString());
export const getSessionDuration = () => localStorage.getItem(SESSION_DURATION);
export const clearSessionDuration = () =>
  localStorage.removeItem(SESSION_DURATION);
