import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { createFilterId } from '@core/helpers';
import { FilterModel, FilterTabProps } from '@core/types';

type OwnProps = {
  label: string;
  filterProps: Partial<FilterModel>;
  filterLabel: string;
};

type Props = OwnProps & FilterTabProps;

const useStyles = makeStyles({
  root: {
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
    },
  },
});

const FilterTextField = (props: Props) => {
  const { handleFilterReplace, filterProps, label, filters, filterLabel } =
    props;
  const classes = useStyles();
  const filterId = createFilterId(filterProps);
  const fieldValue = filters?.[filterId]?.filterValues?.[0]?.value;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const filterValue = event.target.value;
    const filter = {
      ...filterProps,
      filterValues: {
        value: filterValue,
        label: `${filterLabel} ${filterValue}`,
      },
    } as FilterModel;

    handleFilterReplace(filter);
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      onChange={handleChange}
      classes={{ root: classes.root }}
      fullWidth
      type="number"
      value={fieldValue || ''}
      inputProps={{
        min: 1,
        step: 1,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default FilterTextField;
