import { call, Effect, put } from 'redux-saga/effects';

import * as disciplinaryInfringementsApi from '@core/api/disciplinary-infringements';

import { actions } from '.';

export function* getDisciplinaryInfringements(
  competitionId: number,
  page?: any,
): Generator<Effect, any, any> {
  yield put(actions.getDisciplinaryInfringementsRequest());
  const response = yield call(
    disciplinaryInfringementsApi.getDisciplinaryInfringements,
    competitionId,
    page,
  );

  if (!response.error) {
    yield put(actions.getDisciplinaryInfringementsSuccess());
    yield put(
      actions.setDisciplinaryInfringementsList(
        response.response?._embedded?.penaltyCardLog,
      ),
    );
  } else {
    yield put(actions.getDisciplinaryInfringementsFailure());
  }

  return response;
}
