import { forwardRef, type HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Box, Checkbox, Collapse, Tooltip } from '@mui/material';
import { UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { ConditionalWrapper } from '../../conditional-wrapper';
import Label from './label';
import { getNodeRelations } from '../helpers';
import type { TreeNode } from '../types';

interface Props
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<HTMLAttributes<HTMLLIElement>, 'onFocus'> {
  selectedItems: TreeNode['id'][];
}

const Item = forwardRef<HTMLLIElement, Props>(
  ({ id, itemId, label, disabled, children, selectedItems, ...rest }, ref) => {
    const {
      getRootProps,
      getContentProps,
      getIconContainerProps,
      getCheckboxProps,
      getLabelProps,
      getGroupTransitionProps,
      status,
      publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const { visible, ...checkboxProps } = getCheckboxProps();
    const { disabledTooltip } = publicAPI.getItem(itemId);
    const items = publicAPI.getItemTree() as TreeNode[];
    const relations = getNodeRelations(itemId, items);
    const hasSelectedChildren = relations.children.some((child) => {
      if (child.children?.length) {
        return child.children.some((grandchild) =>
          selectedItems.includes(grandchild.id),
        );
      }

      return selectedItems.includes(child.id);
    });
    const isIndeterminate = hasSelectedChildren && !status.selected;

    return (
      <TreeItem2Provider itemId={itemId}>
        <TreeItem2Root {...getRootProps(rest)}>
          <TreeItem2Content
            {...getContentProps({
              className: clsx('content', {
                'Mui-expanded': status.expanded,
                'Mui-selected': status.selected,
                'Mui-focused': status.focused,
                'Mui-disabled': status.disabled,
              }),
            })}
          >
            <ConditionalWrapper
              condition={checkboxProps.disabled && disabledTooltip}
              wrapper={(children) => (
                <Tooltip title={disabledTooltip} placement="top">
                  <Box display="flex" alignItems="center" gap={1}>
                    {children}
                  </Box>
                </Tooltip>
              )}
            >
              <>
                <Checkbox {...checkboxProps} indeterminate={isIndeterminate} />
                <Label {...getLabelProps()} />
              </>
            </ConditionalWrapper>
            <TreeItem2IconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
          </TreeItem2Content>
          {children && (
            <Collapse
              {...getGroupTransitionProps({
                sx: {
                  paddingLeft: `var(--TreeView-itemChildrenIndentation)`,
                },
              })}
            />
          )}
        </TreeItem2Root>
      </TreeItem2Provider>
    );
  },
);

export default Item;
