import { createAccessValidator } from '@core/helpers';
import {
  CurrentUserLinks,
  CurrentUserResponse,
  UserRole,
  UserRoleType,
} from '@core/types';

import paths from '../paths';

export const userHasRequiredUserRole = (
  currentUser: CurrentUserResponse,
  requiredRoles: Array<UserRoleType>,
) => {
  if (!Object.keys(currentUser).length) {
    return true;
  }

  if (!requiredRoles || requiredRoles.length === 0) return true;

  return currentUser.userRoles
    .map((userRole) => requiredRoles.includes(userRole.role))
    .includes(true);
};

export const shouldAllowAccess = ({
  accessLinks,
  currentUser,
  requiredAccess,
  roleAccessArray,
}: {
  accessLinks: CurrentUserLinks;
  currentUser: CurrentUserResponse;
  requiredAccess: string | Array<string>;
  roleAccessArray: Array<UserRoleType>;
}) => {
  if (
    roleAccessArray?.length &&
    !userHasRequiredUserRole(currentUser, roleAccessArray)
  ) {
    return false;
  }

  if (requiredAccess && !accessLinks) {
    return false;
  }

  if (requiredAccess && accessLinks) {
    const validateAccess = createAccessValidator(accessLinks);

    if (
      !validateAccess({
        requiredAccess: Array.isArray(requiredAccess)
          ? requiredAccess
          : [requiredAccess],
      })
    ) {
      return false;
    }
  }

  return true;
};

export const getRedirectPath = ({
  currentUser,
  customRedirectPath,
  accessLinks,
}: {
  currentUser: CurrentUserResponse;
  customRedirectPath: string;
  accessLinks: CurrentUserLinks;
}) => {
  if (customRedirectPath) {
    return customRedirectPath;
  }

  if (accessLinks?.dashboard) {
    return paths.home;
  }

  const userRoles: Array<string> =
    currentUser?.userRoles?.map(({ role }: UserRole) => role) ?? [];

  if (userRoles.includes(UserRoleType.ROLE_CLUB_ADMIN)) {
    return userRoles.filter(
      (role: string) => role === UserRoleType.ROLE_CLUB_ADMIN,
    ).length > 1
      ? paths.clubs
      : paths.club;
  }

  if (userRoles.includes(UserRoleType.ROLE_MA_SYSTEM_ADMIN)) {
    return paths.users;
  }

  // Use profile page as fallback
  return paths.userProfile;
};
