import restService from '@core/api/rest-service';
import { FilesResponse } from '@core/types';

export const getMatchReport = (href: string, i18n: any) => {
  const language = i18n.language;

  return restService.get<FilesResponse>(href, {
    responseType: 'blob',
    headers: {
      'Accept-Language': language,
    },
  });
};
