import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Box, Alert } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uuid } from '@core/helpers';

import { FullScreenSpinner } from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { languageDirection, createURL } from '@core/helpers';
import {
  COMPETITION_PROFILE_ADD_TEAM_BUTTON,
  COMPETITION_PROFILE_COMPETITORS_TAB,
} from '@core/pages/competition-profile/tests/test-ids';
import teamCreationActions from '@core/pages/create-team/store/actions';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getCompetitors,
  getCompetitionDetailsById,
} from '@core/store/modules/tabs/competition-profile/selectors';
import {
  CompetitionResponse,
  Competitor,
  Paginated,
  LanguageDirections,
} from '@core/types';
import paths from '@core/routes/paths';
import { State } from '@core/store';
import { actions as competitorActions } from '@core/store/modules/tabs/competitor-addition';
import { actions as editKitSetActions } from '@core/store/modules/tabs/edit-kit-set';

import CompetitorsTable from './competitors-table';
import CompetitorSummaryBlock from './competitor-summary-block';

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
    tabs: typeof tabsActions;
    competitor: typeof competitorActions;
    teamCreation: typeof teamCreationActions;
    editKitSet: typeof editKitSetActions;
  };
}

interface OwnProps {
  id: number;
}

interface StateProps {
  competitors: Paginated<Competitor>;
  competitionDetails: CompetitionResponse;
  isLoading: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

export const CompetitorsTab = (props: Props) => {
  const { actions, id, competitors, competitionDetails, isLoading } = props;
  const { t } = useTranslation();

  const canAddTeam = !!competitionDetails?._links?.createCompetitor;

  useEffect(() => {
    actions.competitionProfile.getCompetitors({ competitionId: id });
  }, []);

  const selectedCompetitors = competitors?.total || 0;
  const availableCompetitors = competitionDetails?.participatingTeams || 0;

  const teamCount =
    languageDirection === LanguageDirections.RTL
      ? `${availableCompetitors}/${selectedCompetitors}`
      : `${selectedCompetitors}/${availableCompetitors}`;

  const handleAddTeam = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.competitorAssignment),
      props: {
        competitionId: id,
      },
      id: uuid(),
    });

  const isTeamAddingDisabled = () =>
    selectedCompetitors === availableCompetitors;

  const renderAddTeamButton = () =>
    canAddTeam && (
      <Button
        data-qa={COMPETITION_PROFILE_ADD_TEAM_BUTTON}
        color="primary"
        variant="contained"
        onClick={handleAddTeam}
        disabled={isTeamAddingDisabled()}
      >
        {t('Add team')}
      </Button>
    );

  return (
    <Grid data-qa={COMPETITION_PROFILE_COMPETITORS_TAB}>
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <>
          {competitors && competitors.list.length === 0 && (
            <Box mb={3}>
              <Alert severity="warning">
                {t('Teams are missing. Please add teams to the competition.')}
              </Alert>
            </Box>
          )}
          <Grid>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <CompetitorSummaryBlock teamsCount={teamCount} />
              {renderAddTeamButton()}
            </Box>
            <Box mt={3}>
              <CompetitorsTable
                competitionId={id}
                isPlayerListStatus={
                  competitionDetails?.competitionSettings
                    ?.playerListApprovalRequired
                }
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
    competitor: bindActionCreators(competitorActions, dispatch),
    teamCreation: bindActionCreators(teamCreationActions, dispatch),
    editKitSet: bindActionCreators(editKitSetActions, dispatch),
  },
});

const isLoadingSelector = createLoadingSelector([
  competitionProfileActions.getCompetitorsRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitors: getCompetitors(state, ownProps.id),
  competitionDetails: getCompetitionDetailsById(ownProps.id)(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorsTab);
