import { UserRole, UserRoleType } from '@core/types';
import { getUserRoleName } from '@core/helpers';

export const getUserRolesDetailed = (
  t: (key: string) => string,
  userRoles: Array<UserRole>,
) => {
  if (!userRoles) {
    return [];
  }

  return userRoles.map(({ role, club }) => {
    let roleTranslated = getUserRoleName(t, role);

    if (club && role === UserRoleType.ROLE_CLUB_ADMIN) {
      roleTranslated += ` (${club.shortTitle || club.title})`;
    }

    return roleTranslated;
  });
};
