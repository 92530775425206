export const NAMESPACE = 'refereeCategories';

export const SET_CATEGORY_BEING_MODIFIED = `${NAMESPACE}/SET_CATEGORY_BEING_MODIFIED`;
export const SET_NEW_CATEGORY_BEING_CREATED = `${NAMESPACE}/SET_NEW_CATEGORY_BEING_CREATED`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;

export const GET_REFEREE_CATEGORIES = `${NAMESPACE}/GET_REFEREE_CATEGORIES`;
export const SET_REFEREE_CATEGORIES = `${NAMESPACE}/SET_REFEREE_CATEGORIES`;
export const GET_REFEREE_CATEGORIES_REQUEST = `${NAMESPACE}/GET_REFEREE_CATEGORIES_REQUEST`;
export const GET_REFEREE_CATEGORIES_SUCCESS = `${NAMESPACE}/GET_REFEREE_CATEGORIES_SUCCESS`;
export const GET_REFEREE_CATEGORIES_FAILURE = `${NAMESPACE}/GET_REFEREE_CATEGORIES_FAILURE`;

export const CREATE_REFEREE_CATEGORY = `${NAMESPACE}/CREATE_REFEREE_CATEGORY`;
export const CREATE_REFEREE_CATEGORY_REQUEST = `${NAMESPACE}/CREATE_REFEREE_CATEGORY_REQUEST`;
export const CREATE_REFEREE_CATEGORY_SUCCESS = `${NAMESPACE}/CREATE_REFEREE_CATEGORY_SUCCESS`;
export const CREATE_REFEREE_CATEGORY_FAILURE = `${NAMESPACE}/CREATE_REFEREE_CATEGORY_FAILURE`;

export const UPDATE_REFEREE_CATEGORY = `${NAMESPACE}/UPDATE_REFEREE_CATEGORY`;
export const UPDATE_REFEREE_CATEGORY_REQUEST = `${NAMESPACE}/UPDATE_REFEREE_CATEGORY_REQUEST`;
export const UPDATE_REFEREE_CATEGORY_SUCCESS = `${NAMESPACE}/UPDATE_REFEREE_CATEGORY_SUCCESS`;
export const UPDATE_REFEREE_CATEGORY_FAILURE = `${NAMESPACE}/UPDATE_REFEREE_CATEGORY_FAILURE`;

export const DELETE_REFEREE_CATEGORY = `${NAMESPACE}/DELETE_REFEREE_CATEGORY`;
export const DELETE_REFEREE_CATEGORY_REQUEST = `${NAMESPACE}/DELETE_REFEREE_CATEGORY_REQUEST`;
export const DELETE_REFEREE_CATEGORY_SUCCESS = `${NAMESPACE}/DELETE_REFEREE_CATEGORY_SUCCESS`;
export const DELETE_REFEREE_CATEGORY_FAILURE = `${NAMESPACE}/DELETE_REFEREE_CATEGORY_FAILURE`;
