import { createActions } from 'redux-actions';

import { NAMESPACE } from './constants';

export const actions = createActions(
  'UPDATE_GROUP',
  'UPDATE_GROUP_REQUEST',
  'UPDATE_GROUP_SUCCESS',
  'UPDATE_GROUP_FAILURE',

  'UPDATE_GROUP_SETTINGS',
  'UPDATE_GROUP_SETTINGS_REQUEST',
  'UPDATE_GROUP_SETTINGS_SUCCESS',
  'UPDATE_GROUP_SETTINGS_FAILURE',
  { prefix: NAMESPACE },
);
