import restService from '@core/api/rest-service';
import { MatchOfficialAllocationsResponse } from '@core/types';

export const getMatchOfficialAllocations = (matchId: number) =>
  restService.get<MatchOfficialAllocationsResponse>(
    `/v1/matches/${matchId}/matchOfficialAllocations`,
  );

export const appointMatchOfficial = (matchId: number, data: any) =>
  restService.post(
    `/v1/matches/${matchId}/matchOfficialAllocations/appoint`,
    data,
  );

export const removeMatchOfficial = (matchOfficialAppointmentId: number) =>
  restService.delete(
    `/v1/matchOfficialAllocations/${matchOfficialAppointmentId}`,
  );
