import { logDev } from '@core/store/helpers';
import { injectModules } from './inject-modules';

export async function lazyLoadStore(
  modulePath: string,
  moduleImport: Promise<any>,
  requestedBy: string = null,
): Promise<void> {
  const module = await moduleImport;

  const dependencies = module?.dependencies;

  if (dependencies) {
    for await (const key of Object.keys(dependencies)) {
      const dependencyImport = dependencies[key];
      await lazyLoadStore(key, dependencyImport, modulePath);
    }
  }

  await injectModules(module, modulePath);
  !!requestedBy &&
    logDev(`${requestedBy}`, `loaded dependency: ${modulePath}`, '#00a18d');
  !requestedBy && logDev(`${modulePath}`, `pre-loaded`, '#00a18d');
}
