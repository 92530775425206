import { createAction } from 'redux-actions';

const NAMESPACE = 'competitionContacts';

const getCompetitionContactsRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_REQUEST`,
);
const getCompetitionContactsSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_SUCCESS`,
);
const getCompetitionContactsFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_FAILURE`,
);

const createCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_REQUEST`,
);
const createCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_SUCCESS`,
);
const createCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_FAILURE`,
);

const updateCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_REQUEST`,
);
const updateCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_SUCCESS`,
);
const updateCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_FAILURE`,
);

const deleteCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_REQUEST`,
);
const deleteCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_SUCCESS`,
);
const deleteCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_FAILURE`,
);

export default {
  getCompetitionContactsRequest,
  getCompetitionContactsSuccess,
  getCompetitionContactsFailure,

  createCompetitionContactRequest,
  createCompetitionContactSuccess,
  createCompetitionContactFailure,

  updateCompetitionContactRequest,
  updateCompetitionContactSuccess,
  updateCompetitionContactFailure,

  deleteCompetitionContactRequest,
  deleteCompetitionContactSuccess,
  deleteCompetitionContactFailure,
};
