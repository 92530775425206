export const AUTOCOMPLETE = 'AUTOCOMPLETE';
export const AUTOCOMPLETE_OPTION = 'AUTOCOMPLETE_OPTION';
export const AUTOCOMPLETE_MENU = 'AUTOCOMPLETE_MENU';
export const AUTOCOMPLETE_CONTROL = 'AUTOCOMPLETE_CONTROL';
export const AUTOCOMPLETE_CHIP = 'AUTOCOMPLETE_CHIP';
export const AUTOCOMPLETE_SINGLE_VALUE = 'AUTOCOMPLETE_SINGLE_VALUE';
export const AUTOCOMPLETE_SPINNER = 'AUTOCOMPLETE_SPINNER';
export const AUTOCOMPLETE_LABEL = 'AUTOCOMPLETE_LABEL';
export const AUTOCOMPLETE_NO_OPTIONS_MESSAGE =
  'AUTOCOMPLETE_NO_OPTIONS_MESSAGE';
export const AUTOCOMPLETE_LOADING_MESSAGE = 'AUTOCOMPLETE_LOADING_MESSAGE';
