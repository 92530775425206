import React from 'react';
import { MenuItem, Theme, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OptionProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

import * as testIds from '../tests/test-ids';

type Props = OptionProps<AutocompleteOption, boolean>;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    backgroundColor: (props) =>
      props.isFocused
        ? alpha(theme.palette.primary.light, 0.15)
        : theme.palette.common.white,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.15),
    },
  },
}));

const Option = (props: Props) => {
  const classes = useStyles(props);

  return (
    <MenuItem
      data-qa={testIds.AUTOCOMPLETE_OPTION}
      ref={props.innerRef as any}
      className={classes.root}
      selected={props.isSelected}
      component="div"
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

export default Option;
