import React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  buttons: Array<React.FC>;
  dataQa?: string;
  stopPropagation?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  actionButton: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
  },
}));

const ActionsButtons = ({ stopPropagation, dataQa, buttons = [] }: Props) => {
  const classes = useStyles();

  const disableBubbling = (e: React.MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <Grid data-qa={dataQa} className={classes.root} onClick={disableBubbling}>
      {buttons.map((ActionButton, index) => (
        <Grid className={classes.actionButton} key={index}>
          <ActionButton />
        </Grid>
      ))}
    </Grid>
  );
};

export default ActionsButtons;
