import { useSelector } from 'react-redux';

import { FeatureToggle } from '@core/types/api';
import { getFeatureToggles } from '@core/pages/feature-toggle/store/selectors';
import { State } from '@core/store';

const useCheckToggles = (required: Array<string> | string) => {
  const featureToggles =
    useSelector((state: State) => getFeatureToggles(state)) || [];

  const isEnabled = (toggle: string) =>
    featureToggles.some(
      ({ name, featureState }: FeatureToggle) =>
        name === toggle && featureState,
    );

  return Array.isArray(required)
    ? required.every((requiredFeatureToggle) =>
        isEnabled(requiredFeatureToggle),
      )
    : isEnabled(required);
};

export default useCheckToggles;
