import { TeamModel } from '@core/types';
import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';

const getTeamRequest = createAction<void>(`${NAMESPACE}/GET_TEAM_REQUEST`);
const getTeamSuccess = createAction<void>(`${NAMESPACE}/GET_TEAM_SUCCESS`);
const getTeamFailure = createAction<void>(`${NAMESPACE}/GET_TEAM_FAILURE`);

const editTeamRequest = createAction<void>(`${NAMESPACE}/EDIT_TEAM_REQUEST`);
const editTeamSuccess = createAction<void>(`${NAMESPACE}/EDIT_TEAM_SUCCESS`);
const editTeamFailure = createAction<void>(`${NAMESPACE}/EDIT_TEAM_FAILURE`);

const deleteTeamRequest = createAction<void>(`${NAMESPACE}/GET_TEAM_REQUEST`);
const deleteTeamSuccess = createAction<void>(`${NAMESPACE}/GET_TEAM_SUCCESS`);
const deleteTeamFailure = createAction<void>(`${NAMESPACE}/GET_TEAM_FAILURE`);

const getTeamsRequest = createAction<void>(`${NAMESPACE}/GET_TEAMS_REQUEST`);
const getTeamsSuccess = createAction<void>(`${NAMESPACE}/GET_TEAMS_SUCCESS`);
const getTeamsFailure = createAction<void>(`${NAMESPACE}/GET_TEAMS_FAILURE`);

const getTeamsBySportRequest = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_SPORT_REQUEST`,
);
const getTeamsBySportSuccess = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_SPORT_SUCCESS`,
);
const getTeamsBySportFailure = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_SPORT_FAILURE`,
);

const getTeamsForCompetitionRequest = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_FOR_COMPETITION_REQUEST`,
);
const getTeamsForCompetitionSuccess = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_FOR_COMPETITION_SUCCESS`,
);
const getTeamsForCompetitionFailure = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_FOR_COMPETITION_FAILURE`,
);

const createTeamRequest = createAction<void>(
  `${NAMESPACE}/CREATE_TEAM_REQUEST`,
);
const createTeamSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_TEAM_SUCCESS`,
);
const createTeamFailure = createAction<void>(
  `${NAMESPACE}/CREATE_TEAM_FAILURE`,
);

const getTeamsByClubRequest = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_CLUB_REQUEST`,
);
const getTeamsByClubSuccess = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_CLUB_SUCCESS`,
);
const getTeamsByClubFailure = createAction<void>(
  `${NAMESPACE}/GET_TEAMS_BY_CLUB_FAILURE`,
);

const setTeams = createAction<Array<TeamModel>>(`${NAMESPACE}/SET_TEAMS`);

export default {
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFailure,

  deleteTeamRequest,
  deleteTeamSuccess,
  deleteTeamFailure,

  getTeamRequest,
  getTeamSuccess,
  getTeamFailure,

  editTeamRequest,
  editTeamSuccess,
  editTeamFailure,

  createTeamRequest,
  createTeamSuccess,
  createTeamFailure,

  getTeamsBySportRequest,
  getTeamsBySportSuccess,
  getTeamsBySportFailure,

  getTeamsForCompetitionRequest,
  getTeamsForCompetitionSuccess,
  getTeamsForCompetitionFailure,

  getTeamsByClubRequest,
  getTeamsByClubSuccess,
  getTeamsByClubFailure,

  setTeams,
};
