import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterCheckboxList, FullScreenSpinner, SearchField } from '@core';
import {
  areGroupCompetitorsLoading,
  getGroupCompetitors,
} from '@core/store/modules/tabs/team-grid/selectors';
import { TEAMS_FILTER, TEAMS_FILTER_ID } from './constants';
import { getUniqueCompetitors } from './get-unique-competitors';
import type { FilterTabProps, Group } from '@core/types';
import type { State } from '@core/store';

type Props = FilterTabProps<{
  groupId: Group['id'];
}>;

const GroupTeamsFilterTab = ({ groupId, filters, ...rest }: Props) => {
  const [query, setQuery] = useState<string>('');

  const isLoading = useSelector(areGroupCompetitorsLoading);
  const groupCompetitors = useSelector((state: State) =>
    getGroupCompetitors(state, groupId),
  );
  const uniqueCompetitors = getUniqueCompetitors(groupCompetitors);

  const filterValues = filters[TEAMS_FILTER_ID]?.filterValues || [];
  const filteredCompetitors = uniqueCompetitors.filter(({ title }) =>
    title.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
  );
  const competitorsIds = filteredCompetitors.map(({ id }) => id);

  const formatFilterLabel = (id: string) => {
    const filterId = Number(id);
    const competitor = uniqueCompetitors.find(
      (competitor) => competitor.id === filterId,
    );

    return competitor.title;
  };

  const handleSearch = ({ query: searchQuery }: { query: string }) => {
    setQuery(searchQuery);
  };

  return (
    <>
      <SearchField debounceMs={0} query={query} onSearch={handleSearch} />

      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <FilterCheckboxList
          shifted
          list={competitorsIds}
          filterProps={TEAMS_FILTER}
          filterValues={filterValues}
          formatFilterLabel={formatFilterLabel}
          formatLabel={formatFilterLabel}
          {...rest}
        />
      )}
    </>
  );
};

export default GroupTeamsFilterTab;
