import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import { formatPaginatedStoreWithIds } from '@core/helpers';
import { Paginated } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [competitionId: string]: {
    [sanctionType: string]: {
      currentCompetitor: number;
      competitors: Paginated<number>;
      players: Paginated<number>;
      matches: Paginated<number>;
      teamOfficials: Paginated<number>;
      matchOfficials: Paginated<number>;
    };
  };
}

export const defaultState: DefaultState = {};

type Payload = {
  competitionId: number;
  sanctionType: string;
  data: any;
  competitorId?: number;
};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCompetitors.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'competitors'],
        formatPaginatedStoreWithIds('competitors')(payload.data),
        state,
      ),
    [actions.setCurrentCompetitor.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'currentCompetitorId'],
        payload.data,
        state,
      ),
    [actions.setPlayers.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'players'],
        formatPaginatedStoreWithIds('competitorPlayers')(payload.data),
        state,
      ),
    [actions.setTeamOfficials.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'teamOfficials'],
        formatPaginatedStoreWithIds('competitorTeamOfficials')(payload.data),
        state,
      ),
    [actions.setMatchOfficials.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'matchOfficials'],
        formatPaginatedStoreWithIds('matchOfficials')(payload.data),
        state,
      ),
    [actions.setMatches.toString()]: (state, { payload }) =>
      assocPath(
        [payload.competitionId, payload.sanctionType, 'matches'],
        formatPaginatedStoreWithIds('matches')(payload.data),
        state,
      ),
    [actions.resetState.toString()]: (state, { payload }) =>
      dissocPath([payload.competitionId, payload.sanctionType], state),
  },
  defaultState,
);

export default reducer;
