import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { MATCH_SHEET_SUBMIT_BUTTON } from '@core/pages/matches/tests/test-ids';
import {
  AddedMatchPlayerList,
  MatchPlayerOrigin,
  MatchModel,
  ModalTypes,
  Sports,
} from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import {
  getAddedMatchLineupPlayers,
  getAddedMatchTeamOfficials,
} from '@core/store/modules/tabs/match-sheet-management/selectors';
import {
  isStartingLineupDataValid,
  isCaptainSelected,
  isLiberoSelected,
  getMatchSheetValidationErrors,
} from '@core/store/modules/tabs/match-sheet-management/helpers';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { State } from '@core/store';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    tab: typeof tabActions;
    matchSheetManagement: typeof matchSheetManagementActions;
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  tabId: string;
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  competitorId: number;
  matchDetails: MatchModel;
}

interface StateProps {
  isSubmitting: boolean;
  addedLineup: AddedMatchPlayerList;
  addedTeamOfficials: any;
}

type Props = DispatchProps & OwnProps & StateProps;

const FormActionsBlock = (props: Props) => {
  const { t } = useTranslation();
  const {
    tabId,
    actions,
    matchId,
    matchPlayerOrigin,
    isSubmitting,
    addedLineup,
    addedTeamOfficials,
    competitorId,
    matchDetails,
  } = props;

  const handleCancel = () => {
    actions.tab.removeTab({ tabId });
  };

  const handleInvalidLineupData = () => {
    actions.matchSheetManagement.setAddedLineupPlayerDataErrors({
      payload: {
        isLibero:
          matchDetails?.sport === Sports.BEACH_VOLLEYBALL
            ? true
            : isLiberoSelected(addedLineup || []),
        isCaptain: isCaptainSelected(addedLineup || []),
      },
      matchId,
      matchPlayerOrigin,
    });
  };

  const submitMatchSheet = () => {
    actions.matchSheetManagement.submitMatchSheet({
      matchId,
      matchPlayerOrigin,
      tabId,
      competitorId,
    });
  };

  const handleSubmit = () => {
    const matchSheetValidationErrors = getMatchSheetValidationErrors(
      matchDetails,
      addedLineup,
      addedTeamOfficials,
    );

    if (!isStartingLineupDataValid(addedLineup, matchDetails?.sport)) {
      handleInvalidLineupData();

      return;
    }
    if (matchSheetValidationErrors.length <= 0) {
      submitMatchSheet();

      return;
    }
    actions.globalModal.openModal({
      type: ModalTypes.MATCH_SHEET_VALIDATION_MODAL,
      params: {
        matchDetails,
        submitCallback: submitMatchSheet,
        addedLineup,
        addedTeamOfficials,
      },
    });
  };

  return (
    <Box mt={3} mb={3}>
      <Box display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button color="primary" variant="outlined" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Box>
        <ButtonWithProgress
          isLoading={isSubmitting}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          data-qa={MATCH_SHEET_SUBMIT_BUTTON}
        >
          {t('submit')}
        </ButtonWithProgress>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tab: bindActionCreators(tabActions, dispatch),
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

const isLoadingSelector = createLoadingSelector([
  matchSheetManagementActions.submitMatchSheetRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;
  const matchPlayerOrigin = ownProps?.matchPlayerOrigin;

  return {
    isSubmitting: isLoadingSelector(state),
    addedLineup: getAddedMatchLineupPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    addedTeamOfficials: getAddedMatchTeamOfficials(state, {
      matchId,
      matchPlayerOrigin,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormActionsBlock);
