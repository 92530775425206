import React from 'react';
import { Grid, Chip, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { Filters, FilterModel, FilterTabsList } from '@core/types';
import { useCustomerFormat } from '@core/pages/user-profile/hooks/use-customer-format';
import {
  ManageFilter,
  formatDate,
  validateStringAgainstDayjsFormat,
} from '@core/helpers';

type OwnProps = {
  tabsList: FilterTabsList;
  onSave: (filters: Filters) => void;
  initialFilters: Filters;
};

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
  },
  chip: {
    padding: theme.spacing(0.5),
  },
}));

const FilterChips = (props: Props) => {
  const { initialFilters, onSave } = props;
  const classes = useStyles();
  const filters = Object.values(initialFilters);
  const { t } = useTranslation();
  const { dateFormat } = useCustomerFormat();

  function handleDelete(filter: FilterModel) {
    const manageFilter = new ManageFilter(filter, initialFilters);

    return () => {
      onSave(manageFilter.removeFilter());
    };
  }

  function handleClearAll() {
    onSave({});
  }

  return (
    filters.length > 0 && (
      <Grid className={classes.root}>
        {filters.map((filter) => {
          const { filterValues = [], fieldName, filteringOperation } = filter;

          return filterValues.map((filterValue) => {
            const valueKey = `${fieldName}${filteringOperation}${filterValue.value}`;
            const displayValue = validateStringAgainstDayjsFormat(
              filterValue.value,
              'YYYY-MM-DD',
            )
              ? formatDate(dateFormat, filterValue.value)
              : filterValue.value;
            const label = filterValue.label || displayValue;

            return (
              <Grid className={classes.chip} key={valueKey}>
                <Chip
                  label={label}
                  onDelete={handleDelete({
                    ...filter,
                    filterValues: filterValue,
                  } as FilterModel)}
                />
              </Grid>
            );
          });
        })}
        <Button onClick={handleClearAll}>{t('Clear all')}</Button>
      </Grid>
    )
  );
};

export default FilterChips;
