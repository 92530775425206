import { createActions } from 'redux-actions';

export const NAMESPACE = 'user';

export const actions = createActions(
  'GET_USERS_REQUEST',
  'GET_USERS_SUCCESS',
  'GET_USERS_FAILURE',
  'GET_CURRENT_USER_REQUEST',
  'GET_CURRENT_USER_SUCCESS',
  'GET_CURRENT_USER_FAILURE',
  'LINK_WITH_PERSON_REQUEST',
  'LINK_WITH_PERSON_SUCCESS',
  'LINK_WITH_PERSON_FAILURE',
  'UNLINK_PERSON_REQUEST',
  'UNLINK_PERSON_SUCCESS',
  'UNLINK_PERSON_FAILURE',
  'DELETE_USER_ROLE_REQUEST',
  'DELETE_USER_ROLE_SUCCESS',
  'DELETE_USER_ROLE_FAILURE',
  { prefix: NAMESPACE },
) as unknown as {
  getUsersRequest: RequestLifecycleAction;
  getUsersSuccess: RequestLifecycleAction;
  getUsersFailure: RequestLifecycleAction;
  getCurrentUserRequest: RequestLifecycleAction;
  getCurrentUserSuccess: RequestLifecycleAction;
  getCurrentUserFailure: RequestLifecycleAction;
  linkWithPersonRequest: RequestLifecycleAction;
  linkWithPersonSuccess: RequestLifecycleAction;
  linkWithPersonFailure: RequestLifecycleAction;
  unlinkPersonRequest: RequestLifecycleAction;
  unlinkPersonSuccess: RequestLifecycleAction;
  unlinkPersonFailure: RequestLifecycleAction;
  deleteUserRoleRequest: RequestLifecycleAction;
  deleteUserRoleSuccess: RequestLifecycleAction;
  deleteUserRoleFailure: RequestLifecycleAction;
};
