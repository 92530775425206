import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FilterTabProps, Paginated, ClubModel } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import clubsActions from '@core/store/modules/modals/clubs-filter-tab/actions';
import { getClubs } from '@core/store/modules/modals/clubs-filter-tab/selectors';
import { SearchableFilterList } from '@core/components';

import { CLUBS_FILTER_ID, CLUBS_FILTER } from './constants';

type DispatchProps = {
  actions: {
    clubs: typeof clubsActions;
  };
};

type StateProps = {
  isLoading: boolean;
  clubs: Paginated<ClubModel>;
};

type OwnProps = FilterTabProps & StateProps;

type Props = DispatchProps & OwnProps & StateProps;

const ClubsFilterTab = (props: Props) => {
  const { actions, isLoading, clubs, ...rest } = props;

  function findClubTitle(id: string) {
    return clubs?.list.find((club) => club.id === Number(id)).title;
  }

  return (
    <SearchableFilterList
      isLoading={isLoading}
      filterId={CLUBS_FILTER_ID}
      paginatedList={clubs}
      filterProps={CLUBS_FILTER}
      getList={actions.clubs.getClubs}
      resetList={actions.clubs.resetState}
      searchByQuery={actions.clubs.searchClubsByQuery}
      formatFilterLabel={findClubTitle}
      {...rest}
    />
  );
};

const loadingSelector = createLoadingSelector([
  clubsActions.getClubsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
  clubs: getClubs(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    clubs: bindActionCreators(clubsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubsFilterTab);
