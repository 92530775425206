import { pathOr, isEmpty } from 'ramda';

import {
  Paginated,
  CompetitionResponse,
  Sorting,
  CompetitionLinks,
} from '@core/types';
import { State } from '@core/store';
import { NAMESPACE as competitionsNamespace } from '@core/pages/competitions/store/api';
import { getCompetitionsById } from '@core/pages/competitions/store/api/selectors';

import { NAMESPACE } from './constants';

export const getCompetitionBeingModified = pathOr(null, [
  'pages',
  NAMESPACE,
  'competitionBeingModified',
]);
export const getCompetitionsErrors = pathOr(null, [
  'pages',
  NAMESPACE,
  'errors',
]);

export const getCompetitions = (
  state: State,
): Paginated<CompetitionResponse> => {
  // TODO: investigate. need better typing
  const competitionsPage = { ...state.pages.competitions.competitions };

  return competitionsPage && !isEmpty(competitionsPage)
    ? {
        ...competitionsPage,
        list: getCompetitionsById(state, competitionsPage?.list),
      }
    : null;
};

export const getRolloverCompetitions = (
  state: State,
): Array<CompetitionResponse> =>
  state?.pages?.competitions?.rolloverCompetitions;

export const getCompetitionsHalLinks = (state: State): CompetitionLinks =>
  pathOr(null, ['pages', NAMESPACE, 'halLinks'], state);

export const getDisciplinaryRules = (competitionId: number, state: State) =>
  pathOr(
    [],
    [
      'tabs',
      'competitionCreationTab',
      'byCompetitionId',
      competitionId,
      'rules',
    ],
    state,
  );

export const getCompetitionById = (
  state: State,
  { competitionId }: { competitionId: number },
) => pathOr(null, ['api', competitionsNamespace, competitionId], state);

export const getSorting = (state: State): Sorting =>
  pathOr(null, ['pages', NAMESPACE, 'sorting'], state);
