import { call, Effect, put } from 'redux-saga/effects';

import { PlayerAttributes } from '@core/types';
import * as playersApi from '@core/api/players';

import { actions } from '.';

export function* getClubPlayers(
  queryParams?: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getClubPlayersBySportRequest());
  const response = (yield call(
    playersApi.getClubPlayersBySport,
    queryParams,
  )) as InferApi<typeof playersApi.getClubPlayersBySport>;

  if (!response.error) {
    yield put(actions.getClubPlayersBySportSuccess());
  } else {
    yield put(actions.getClubPlayersBySportFailure());
  }

  return response;
}

export function* createClubPlayer(
  clubId: number,
  sportId: Array<number>,
  personId: number,
): Generator<Effect, any, any> {
  yield put(actions.createClubPlayerRequest());
  const response = (yield call(
    playersApi.createClubPlayer,
    clubId,
    sportId,
    personId,
  )) as InferApi<typeof playersApi.createClubPlayer>;

  if (!response.error) {
    yield put(actions.createClubPlayerSuccess());
  } else {
    yield put(actions.createClubPlayerFailure());
  }

  return response;
}

export function* updatePlayerAttributes(
  personId: number,
  data: PlayerAttributes,
): Generator<Effect, any, any> {
  yield put(actions.updatePlayerAttributesRequest());
  const response = (yield call(
    playersApi.updatePlayerAttributes,
    personId,
    data,
  )) as InferApi<typeof playersApi.updatePlayerAttributes>;

  if (!response.error) {
    yield put(actions.updatePlayerAttributesSuccess());
  } else {
    yield put(actions.updatePlayerAttributesFailure());
  }

  return response;
}

export function* getPlayerAttributes(
  personId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPlayerAttributesRequest());
  const response = (yield call(
    playersApi.getPlayerAttributes,
    personId,
  )) as InferApi<typeof playersApi.getPlayerAttributes>;

  if (!response.error) {
    yield put(actions.getPlayerAttributesSuccess());
  } else {
    yield put(actions.getPlayerAttributesFailure());
  }

  return response;
}

export function* getPlayerListByPerson(
  personId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPlayerListByPersonRequest());
  const response = (yield call(
    playersApi.getPlayerListByPerson,
    personId,
  )) as InferApi<typeof playersApi.getPlayerListByPerson>;

  if (!response.error) {
    yield put(actions.getPlayerListByPersonSuccess());
  } else {
    yield put(actions.getPlayerListByPersonFailure());
  }

  return response;
}

export function* deletePlayer(playerId: number): Generator<Effect, any, any> {
  yield put(actions.deletePlayerRequest());
  const response = (yield call(playersApi.deletePlayer, playerId)) as InferApi<
    typeof playersApi.deletePlayer
  >;

  if (!response.error) {
    yield put(actions.deletePlayerSuccess());
  } else {
    yield put(actions.deletePlayerFailure());
  }

  return response;
}
