import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SingleValueProps } from 'react-select';

import { AutocompleteOption, MatchOfficial } from '@core/types';

type Props = SingleValueProps<any>;

const RefereeValue = (props: Props) => {
  const data = props.data as AutocompleteOption<MatchOfficial>;

  return (
    <Grid>
      <Grid>
        <Typography variant="body2">
          {data.props.localFirstName &&
            `${data.props.localFirstName} ${data.props.localFamilyName}`}
        </Typography>
      </Grid>
      {data.props.firstName && (
        <Grid>
          <Typography variant="body2" color="textSecondary">
            {`${data.props.firstName} ${data.props.familyName}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RefereeValue;
