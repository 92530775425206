import restService from '@core/api/rest-service';
import { MatchesListResponse, MatchModel, MatchStatus } from '@core/types';

export const getGroupsAppointmentsList = (
  groupId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<MatchesListResponse>(`/v1/groups/${groupId}/matches`, {
    params: queryParams,
  });

export const getAppointmentsList = (queryParams: any = { page: 1 }) =>
  restService.get<MatchesListResponse>('/v1/matches/appointments', {
    params: queryParams,
  });

export const getAppointmentsListForDashboard = (
  queryParams: any = { page: 1 },
) =>
  restService.get<MatchesListResponse>('/v1/matches/_dashboard', {
    params: queryParams,
  });

// TODO: Can't implement fetching all matches in date range, because limit doesn't work
export const getAppointmentsInDateRange = (queryParams: any = { page: 1 }) =>
  restService.get<MatchesListResponse>('/v1/matches', { params: queryParams });

export const getAppointmentById = (matchId: number) =>
  restService.get<MatchModel>(`/v1/matches/${matchId}`);

export const updateAppointment = (url: string, data: any) =>
  restService.patch<unknown>(url, data);

export const changeAppointmentStatus = (matchId: number, status: MatchStatus) =>
  restService.post<void>(`/v1/matches/${matchId}/changeStatus`, { status });

export const getAppointmentsByCompetition = (
  competitionId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<MatchesListResponse>(
    `/v1/competitions/${competitionId}/matches`,
    { params: queryParams },
  );
