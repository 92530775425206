import React from 'react';
import { Typography, Grid, GridSpacing, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  children: any;
  title?: string;
  spacing?: GridSpacing;
}

interface StyleProps {
  pb?: number;
  mb?: number;
}

type Props = OwnProps & StyleProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      paddingBottom: ({ pb }: StyleProps) =>
        pb ? theme.spacing(pb) : theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: ({ mb }: StyleProps) =>
        mb ? theme.spacing(mb) : theme.spacing(3),
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

// TODO: JB: move to packages/core/src/components/content-block/content-block.tsx
export function CenteredContentBlock({
  children,
  title,
  spacing,
  pb,
  mb,
}: Props) {
  const classes = useStyles({ pb, mb });

  const mdSide = 2;

  // TODO: JB: if spacing is added to root grid, then current children should be wrapped in grid container item and border/divider applied to this new child
  return (
    <Grid container item xs={12} spacing={0} className={classes.root}>
      <Grid item xs={12} md={mdSide}>
        {title && (
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
      </Grid>
      <Grid container item xs={12} md={12 - 2 * mdSide} spacing={spacing}>
        {children}
      </Grid>
    </Grid>
  );
}
