import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Grid, Button } from '@mui/material';
import { EditTwoTone } from '@mui/icons-material';

import {
  TabTitle,
  CustomTable,
  PlayerKitPreviewNew as PlayerKitPreview,
  makePlayerKitPreviewProps,
  TreeColumn,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { MANAGE_BUTTON } from '@core/constants/test-ids';
import {
  KitSet,
  KitType,
  KitTypes,
  Sport,
  Competitor,
  ModalTypes,
  TeamModel,
} from '@core/types';
import { getTeamColorSetsHaveSpecialPlayer } from '@core/factories/kit-set/get-team-color-sets-have-special-player';
import { actions as competitorActions } from '@core/store/modules/tabs/competitor-profile';
import {
  getCompetitorKitSet,
  getCompetitorByTeamId,
} from '@core/store/modules/tabs/competitor-profile/selectors';
import { State } from '@core/store';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';

import { OwnProps as ManageTeamKitsModalProps } from './manage-team-kits-modal';
import { Alert } from '@ui-components';

interface DispatchProps {
  actions: {
    competitor: typeof competitorActions;
    globalModal: typeof globalModalActions;
    tabs: typeof tabsActions;
  };
}

interface StateProps {
  clubId: number;
  isLoading: boolean;
  kitSet: KitSet;
  kitSetId: number;
  sport: Sport;
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
}

interface OwnProps {
  competitionId: number;
  competitorId: number;
  teamId: number;
}

type Props = DispatchProps & OwnProps & StateProps;

const TeamKitsTable = (props: Props) => {
  const {
    actions,
    clubId,
    competitionId,
    competitorId,
    isLoading,
    kitSet,
    kitSetId,
    sport,
    teamId,
    competitorDetails,
  } = props;
  const { t } = useTranslation();

  const canManageTeamKit = !!competitorDetails?.competitor?._links?.patch;
  const teamColorSetsHaveSpecialPlayer =
    getTeamColorSetsHaveSpecialPlayer(sport);

  useEffect(() => {
    if (competitorId && kitSetId) {
      actions.competitor.getCompetitorKitSet({ kitSetId, competitorId });
    }
  }, [actions.competitor, competitorId, kitSetId]);

  const columns = useMemo(() => {
    const getKitTitle = (kitType: KitType) => {
      switch (kitType) {
        case 'home':
          return t('Primary');
        case 'away':
          return t('Secondary');
        case 'reserved':
          return t('Tertiary');
      }
    };

    return new Array<TreeColumn<KitSet>>(
      {
        title: t('Color Set'),
        field: 'title',
        rowSpan: teamColorSetsHaveSpecialPlayer ? 2 : undefined,
        width: teamColorSetsHaveSpecialPlayer ? '20%' : '25%',
      },
      ...KitTypes.map<TreeColumn<KitSet>>((kitType) =>
        teamColorSetsHaveSpecialPlayer
          ? {
              title: getKitTitle(kitType),
              columns: [
                {
                  title: t('Team'),
                  render: (rowData) => (
                    <PlayerKitPreview
                      {...makePlayerKitPreviewProps(
                        rowData,
                        kitType,
                        'regular',
                      )}
                    />
                  ),
                },
                {
                  title: t('Libero'),
                  render: (rowData) => (
                    <PlayerKitPreview
                      {...makePlayerKitPreviewProps(
                        rowData,
                        kitType,
                        'special',
                      )}
                    />
                  ),
                  width: '20%',
                },
              ],
              colSpan: 2,
            }
          : {
              title: getKitTitle(kitType),
              render: (rowData) => (
                <PlayerKitPreview
                  {...makePlayerKitPreviewProps(rowData, kitType, 'regular')}
                />
              ),
              width: '25%',
            },
      ),
    );
  }, [teamColorSetsHaveSpecialPlayer, t]);

  const data = kitSet ? [kitSet] : [];

  const handleManage = () => {
    actions.globalModal.openModal<ManageTeamKitsModalProps>({
      type: ModalTypes.MANAGE_TEAM_KITS_MODAL,
      params: {
        clubId,
        competitionId,
        competitorId,
        kitSetId,
        teamId,
        sport,
      },
    });
  };

  const tabActions = canManageTeamKit && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleManage}
      startIcon={<EditTwoTone />}
      data-qa={MANAGE_BUTTON}
    >
      {t('Manage')}
    </Button>
  );

  return (
    <Grid>
      <TabTitle actions={tabActions} />
      {kitSetId ? (
        <CustomTable
          columns={columns}
          data={data}
          hasSubColumns
          isLoading={isLoading}
          noPaper
        />
      ) : (
        <Alert title={t('No team kits. Please manage.')} />
      )}
    </Grid>
  );
};

const isLoadingSelector = (ownProps: OwnProps & { kitSetId: number }) =>
  createLoadingSelector([
    competitorActions.getCompetitorKitSetRequest({
      kitSetId: ownProps.kitSetId,
      competitorId: ownProps.competitorId,
    }),
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const competitorDetails = getCompetitorByTeamId(
    state,
    ownProps?.competitorId,
  );
  const { clubId, teamColorSetId: kitSetId } =
    competitorDetails?.competitor ?? {};
  const { sport } = competitorDetails.team ?? {};

  return {
    clubId,
    isLoading: isLoadingSelector({ ...ownProps, kitSetId })(state),
    kitSet: getCompetitorKitSet(state, {
      competitorId: ownProps?.competitorId,
    }),
    competitorDetails: getCompetitorByTeamId(state, ownProps?.competitorId),
    kitSetId,
    sport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitor: bindActionCreators(competitorActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamKitsTable);
