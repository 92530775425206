import { handleActions } from 'redux-actions';
import {
  update,
  insert,
  assoc,
  equals,
  findIndex,
  propEq,
  reject,
  find,
} from 'ramda';

import { NavigationTabProps } from '@core/types';

import {
  OPEN_TAB,
  OPEN_ROOT_TAB,
  REMOVE_TAB,
  SET_TABS,
  UPDATE_ACTIVE_TAB_INDEX,
  CLEAR_ALL_TABS,
  SET_TOUCHED,
  SET_TOUCHED_BY_TAB_ID,
  UPDATE_TAB_TITLE,
  SET_ALL_TABS,
} from './constants';

export interface StateProps {
  allTabs: Array<NavigationTabProps>;
  activeIndex: number;
}

type Payload = any;

const initialState: StateProps = {
  allTabs: [],
  activeIndex: 0,
};

export const reducer = handleActions<StateProps, Payload>(
  {
    [OPEN_TAB]: (state, { payload }) => {
      const tabIndex = state.allTabs.findIndex(
        (tab) =>
          (tab.name === payload.name && equals(tab.props, payload.props)) ||
          tab.id === payload.id,
      );
      const isTabOpen = tabIndex >= 0;

      return {
        ...state,
        allTabs: isTabOpen
          ? state.allTabs
          : insert(state.activeIndex + 1, payload, state.allTabs),
        activeIndex: isTabOpen ? tabIndex : state.activeIndex + 1,
      };
    },
    [OPEN_ROOT_TAB]: (state, { payload }) => {
      return {
        ...state,
        allTabs: [payload],
        activeIndex: 0,
      };
    },
    [UPDATE_ACTIVE_TAB_INDEX]: (state, { payload }) =>
      assoc('activeIndex', payload, state),
    [CLEAR_ALL_TABS]: () => ({
      ...initialState,
    }),
    [SET_TOUCHED]: (state, { payload }) => ({
      ...state,
      allTabs: update(
        payload.index,
        { ...state.allTabs[payload.index], isTouched: payload.isTouched },
        state.allTabs,
      ),
    }),
    [SET_TABS]: (state, { payload }) => assoc('allTabs', payload, state),
    [SET_TOUCHED_BY_TAB_ID]: (state, { payload }) => {
      // TODO: ADD TEST COVERAGE
      const currentTabIndex = state.allTabs.findIndex(
        (tab) => tab.id === payload.tabId,
      );

      if (currentTabIndex > -1) {
        const currentTab = {
          ...state.allTabs[currentTabIndex],
          isTouched: payload.isTouched,
        };
        const updatedTabs = [...state.allTabs];
        updatedTabs[currentTabIndex] = currentTab;

        return {
          ...state,
          allTabs: updatedTabs,
        };
      }

      return state;
    },
    [UPDATE_TAB_TITLE]: (state, { payload }) => {
      const allTabs = state.allTabs;
      const tabIndex = findIndex(({ id }) => id === payload.tabId, allTabs);

      return {
        ...state,
        allTabs: update(
          tabIndex,
          { ...allTabs[tabIndex], title: payload.title },
          allTabs,
        ),
      };
    },

    [SET_ALL_TABS]: (state, { payload }) => {
      const tabsWithCallbacks = payload.allTabs.map(
        (tab: NavigationTabProps) => {
          const originalTab = state.allTabs.find(
            (stateTab) => stateTab.id === tab.id,
          );

          // Transfer callbacks from previous state tabs to current (because they've been deserialized and lost their callbacks)
          return {
            ...tab,
            ...(tab?.props && originalTab?.props?.callbacks
              ? {
                  props: {
                    ...tab.props,
                    callbacks: originalTab?.props?.callbacks,
                  },
                }
              : {}),
          };
        },
      );

      return {
        ...state,
        allTabs: tabsWithCallbacks,
        activeIndex: payload.activeIndex,
      };
    },

    [REMOVE_TAB]: (state, { payload }) => {
      const tabs = state.allTabs;
      const tabId = payload?.tabId?.tabId ?? payload?.tabId;
      const removedTabIndex = tabs.findIndex((tab) => tab.id === tabId);
      const filteredTabs = tabs.filter((tab) => tab.id !== tabId);
      let activeTabIndex = state.activeIndex;

      if (activeTabIndex >= removedTabIndex) {
        activeTabIndex -= 1;
      }

      return {
        ...state,
        allTabs: filteredTabs,
        activeIndex: activeTabIndex,
      };
    },
  },
  initialState,
);
