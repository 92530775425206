import React from 'react';
import { Typography, Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  title: string;
  subtitle?: string;
  label?: string;
  additional?: JSX.Element;
  actions?: JSX.Element;
  avatar?: JSX.Element;
  strongTitle?: boolean;
  increasedSize?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    lineHeight: '19px',
    fontSize: ({ increasedSize }: Props) => (increasedSize ? 20 : 16),
    fontWeight: ({ strongTitle }: Props) => (strongTitle ? 700 : 400),
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  titleSection: {
    flexShrink: 1,
  },
  actionsSection: {
    flexShrink: 2,
    maxWidth: '45vw',
  },
  additionalSection: {
    flexShrink: 2,
    maxWidth: '45vw',
  },
}));

const ExpansionPanelTitle = (props: Props) => {
  const { label, title, subtitle, additional, actions, avatar } = props;
  const classes = useStyles(props);

  return (
    <Grid className={classes.root}>
      {avatar && <Box mr={2}>{avatar}</Box>}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={classes.displayFlex}
      >
        <Grid item className={classes.titleSection}>
          {label && (
            <Typography color="textSecondary" variant="caption">
              {label}
            </Typography>
          )}
          <Typography variant="subtitle1" className={classes.title} noWrap>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              className={classes.subtitle}
              color="textSecondary"
              variant="caption"
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        {!!actions && (
          <Grid
            item
            className={classes.actionsSection}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            {actions}
          </Grid>
        )}
        {!!additional && (
          <Box
            component={Grid}
            item
            className={classes.additionalSection}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            {additional}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ExpansionPanelTitle;
