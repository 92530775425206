import { useEffect } from 'react';
import { times } from 'ramda';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ReportProblemOutlined } from '@mui/icons-material';
import { Typography, Grid, Box, Tooltip, Theme, alpha } from '@mui/material';
import {
  FullScreenSpinner,
  TextField,
  TimePickerField,
} from '@core/components';
import { isLoading } from '@core/store/modules/tabs/match-profile/selectors';
import { getTimeDiff } from '@core/helpers';
import { CompetitionSettings } from '@volleyball/types';
import {
  addPrefix,
  DURATION_PERIOD_TYPES,
  DURATION_MAIN_FIELDS,
  ATTENDANCE_MAIN_FIELDS,
  NOT_MUTABLE_FIELDS,
  AttendanceFormValues,
  FormValues,
  getPeriodName,
} from './constants';

type Props = {
  competitionSettings: CompetitionSettings;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  attendanceRow: {
    padding: theme.spacing(0),
  },
  attendanceGroupTitle: {},
  attendanceGroupTitleText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  attendanceRowTitle: {
    marginTop: theme.spacing(2),
  },
  attendanceRowTitleText: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(3),
  },
  attendanceRowLabel: {},
  fieldImmutable: {
    position: 'relative',
    '&  input': {
      color: theme.palette.grey['600'],
      backgroundColor: `${alpha(theme.palette.primary.main, 0.05)} !important`,
      borderRadius: theme.spacing(0.25),
    },
  },
  warningContainer: {
    position: 'absolute',
    height: theme.spacing(3),
    width: theme.spacing(3),
    top: theme.spacing(3),
    right: theme.spacing(2),
  },
  warningIcon: {
    fill: theme.palette.warning.light,
  },
  error: {
    display: 'block',
    color: theme.palette.error.main,
  },
}));

const DurationListFormTemplate = ({ competitionSettings }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isListLoading = useSelector(isLoading);
  const { values, setFieldValue } = useFormikContext<
    FormValues & AttendanceFormValues
  >();

  useEffect(() => {
    const updateStoppageTime = (
      type: DURATION_PERIOD_TYPES,
      index: number,
      periodLengthDefault: number,
    ) => {
      const startTime =
        values[
          `${type}_${index + 1}_${
            DURATION_MAIN_FIELDS.startTime
          }` as keyof (FormValues & AttendanceFormValues)
        ];
      const endTime =
        values[
          `${type}_${index + 1}_${
            DURATION_MAIN_FIELDS.endTime
          }` as keyof (FormValues & AttendanceFormValues)
        ];
      let totalTime =
        getTimeDiff(startTime.toString(), endTime.toString()) ?? 0;
      if (totalTime < 0) {
        totalTime = 0;
      }

      const fullTime =
        totalTime < periodLengthDefault ? totalTime : periodLengthDefault;
      const stoppageTime =
        totalTime && totalTime > periodLengthDefault
          ? totalTime - periodLengthDefault
          : 0;

      setFieldValue(
        `${type}_${index + 1}_${NOT_MUTABLE_FIELDS.duration}`,
        fullTime,
        true,
      );
      setFieldValue(
        `${type}_${index + 1}_${NOT_MUTABLE_FIELDS.stoppageTime}`,
        stoppageTime,
        false,
      );
    };

    times(
      (index) =>
        updateStoppageTime(
          DURATION_PERIOD_TYPES.REGULAR,
          index,
          competitionSettings.periodDuration,
        ),
      competitionSettings.numberOfPeriods,
    );
    times(
      (index) =>
        updateStoppageTime(
          DURATION_PERIOD_TYPES.EXTRATIME,
          index,
          competitionSettings.periodDurationInExtraTime,
        ),
      competitionSettings.numberOfPeriodsInExtraTime,
    );
  }, [values]);

  const halfInputGroupDynamic = (
    label: string,
    prefix: string,
    id: string,
    duration: number = null,
  ) => {
    const STOPPAGE_TO_REGULAR_RATIO_ALLOWED = 2;
    const keyMain = addPrefix(prefix, id, NOT_MUTABLE_FIELDS.duration);
    const keyStoppage = addPrefix(prefix, id, NOT_MUTABLE_FIELDS.stoppageTime);
    const durationMain = parseInt(values[keyMain], 10);
    const durationStoppage = parseInt(values[keyStoppage], 10);
    const isWarning =
      !!durationMain &&
      !!durationStoppage &&
      durationMain / durationStoppage < STOPPAGE_TO_REGULAR_RATIO_ALLOWED;

    return (
      <Grid
        key={`${prefix}_${id}`}
        container
        direction="column"
        className={classes.rowPadding}
      >
        <Grid item xs={12}>
          <Typography variant="overline">
            {label.toUpperCase()}
            {!!duration && ` (${duration}')`}
          </Typography>
        </Grid>
        <Grid container item xs={12} direction="row" spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TimePickerField
              forceError={true}
              name={addPrefix(prefix, id, DURATION_MAIN_FIELDS.startTime)}
              label={t('Start Time')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TimePickerField
              forceError={true}
              name={addPrefix(prefix, id, DURATION_MAIN_FIELDS.endTime)}
              label={t('End Time')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.fieldImmutable}>
            <TextField
              name={addPrefix(prefix, id, NOT_MUTABLE_FIELDS.duration)}
              label={t('Normal Time (min)')}
              forceError={true}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.fieldImmutable}>
            <TextField
              name={addPrefix(prefix, id, NOT_MUTABLE_FIELDS.stoppageTime)}
              label={t('Stoppage Time (min)')}
              forceError={true}
              disabled
            />
            {!!isWarning && (
              <Box className={classes.warningContainer}>
                <Tooltip
                  title={t(
                    `You entered unusually long period of time. If that's on purpose, please ignore this warning.`,
                  )}
                >
                  <ReportProblemOutlined className={classes.warningIcon} />
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const shootoutGroupDynamic = (label: string, prefix: string, id: string) => (
    <Grid container direction="column" className={classes.rowPadding}>
      <Grid item xs={12}>
        <Typography variant="overline">{label.toUpperCase()}</Typography>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TimePickerField
            forceError={true}
            name={addPrefix(prefix, id, DURATION_MAIN_FIELDS.startTime)}
            label={t('Start Time')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TimePickerField
            forceError={true}
            name={addPrefix(prefix, id, DURATION_MAIN_FIELDS.endTime)}
            label={t('End Time')}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const fieldGridDynamic = () => (
    <>
      {times(
        (index) =>
          halfInputGroupDynamic(
            t(
              getPeriodName(
                DURATION_PERIOD_TYPES.REGULAR,
                index,
                competitionSettings.numberOfPeriods,
              ),
            ),
            DURATION_PERIOD_TYPES.REGULAR,
            (index + 1).toString(),
            competitionSettings.periodDuration,
          ),
        competitionSettings.numberOfPeriods,
      )}
      {times(
        (index) =>
          halfInputGroupDynamic(
            t(
              getPeriodName(
                DURATION_PERIOD_TYPES.EXTRATIME,
                index,
                competitionSettings.numberOfPeriodsInExtraTime,
              ),
            ),
            DURATION_PERIOD_TYPES.EXTRATIME,
            (index + 1).toString(),
            competitionSettings.periodDurationInExtraTime,
          ),
        competitionSettings.numberOfPeriodsInExtraTime,
      )}

      {!!competitionSettings.penaltyShootOut && (
        <Grid
          container
          direction="column"
          className={classes.attendanceGroupTitle}
        >
          {shootoutGroupDynamic(
            t(getPeriodName(DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT)),
            DURATION_PERIOD_TYPES.PENALTY_SHOOT_OUT,
            '1',
          )}
        </Grid>
      )}
    </>
  );

  return isListLoading ? (
    <FullScreenSpinner />
  ) : (
    <Grid>
      <Grid item xs={12}>
        <Box
          className={classes.root}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          {fieldGridDynamic()}
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          {fieldGridDynamic()}
        </Box>
        <Box className={classes.attendanceRow}>
          <Box className={classes.attendanceRowTitle}>
            <Typography
              variant="subtitle1"
              className={classes.attendanceRowTitleText}
            >
              {t('Attendance')}
            </Typography>
          </Box>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name={ATTENDANCE_MAIN_FIELDS.attendance}
              label={t('Number of spectators')}
              type="number"
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DurationListFormTemplate;
