import { pathOr, pick, pipe } from 'ramda';

import { NAMESPACE } from '.';

export const getMatchOfficialsAllocations = (id: number) =>
  pathOr(null, [NAMESPACE, 'byMatchId', id]);

export const getMatchPlayersAndTeamOfficials = (id: number) =>
  pathOr(null, ['tabs', 'matchProfileTab', 'byMatchId', id]);

export const getMatchTeamOfficials = (id: number) =>
  pipe(
    pathOr({}, ['tabs', 'matchProfileTab', 'byMatchId', id]),
    pick(['homeTeamOfficials', 'awayTeamOfficials']),
  );
