import { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Toolbar,
  AppBar as MuiAppBar,
  Box,
  Typography,
  Divider,
  Theme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { CustomerResponse, CurrentUserResponse } from '@core/types/api';
import {
  getCurrentCustomer,
  getCurrentUser,
  getCustomerLogo,
} from '@core/pages/user-profile/store/selectors';
import userActions from '@core/pages/user-profile/store/actions';
import { State } from '@core/store';
import * as filesAction from '@core/store/modules/files';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';
import { UserMenu } from './components/user-menu';
import { MenuIconContainer as MenuIcon } from './components/menu-icon';
import { APP_BAR, APP_BAR_LOGO } from './tests/test-ids';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
    maxHeight: theme.mixins.toolbar.minHeight,
    borderWidth: '0px 0px 1px 0px',
    border: `${theme.palette.grey[200]} solid`,
    '@global': {
      button: {
        transition: theme.transitions.create('backgroundColor', {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      },
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  logo: {
    fontSize: '5rem',
    cursor: 'pointer',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 900,
    marginLeft: theme.spacing(1),
  },
  productName: {
    display: 'none',
    fontSize: '0.75rem',
    [theme.breakpoints.up('md')]: {
      textTransform: 'uppercase',
      display: 'block',
    },
  },
  divider: {
    margin: theme.spacing(0, 2),
    height: theme.spacing(4),
    backgroundColor: 'rgb(255, 255, 255, 0.3)',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuBlock: {
    height: theme.mixins.toolbar.minHeight,
    borderWidth: '0px 0px 0px 1px',
    border: `solid rgb(255, 255, 255, 0.3)`,
    '&:last-child': {
      borderWidth: '0px 1px 0px 1px',
    },
  },
  image: {
    height: '27px',
    width: '27px',
    cursor: 'pointer',
    marginLeft: 8,
  },
}));

interface StateProps {
  currentCustomer: CustomerResponse;
  currentUser: CurrentUserResponse;
  customerLogo: string | null;
}

interface DispatchProps {
  actions: {
    user: typeof userActions;
    files: typeof filesAction;
  };
}

type Props = StateProps & DispatchProps;

export const AppBar = (props: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentUser: user,
    currentCustomer: customer,
    customerLogo,
  } = props;

  const haveCustomer = !!customer && Object.keys(customer).length !== 0;
  const haveLogo = !!props.customerLogo;
  const haveLogoLink = !!customer?.logoFileLink;

  useEffect(() => {
    !haveCustomer && props.actions?.user?.getCurrentCustomer();
  }, [props.actions.user, haveCustomer]);

  useEffect(() => {
    if (!haveLogo && haveLogoLink) {
      // TODO: parsing URL is a temporary solution. Should request backend for proper data format.
      const [, , , , objectId, , fileId] = customer.logoFileLink.split('/');

      props.actions.user.getCustomerLogo({
        objectType: 'customers',
        objectId: parseInt(objectId, 10),
        fileType: 'logo',
        fileId: parseInt(fileId, 10),
      });
    }
  });

  const handleLogoClick = () => {
    navigate(createURL(paths.home));
  };

  return (
    <MuiAppBar
      data-qa={APP_BAR}
      elevation={0}
      position="relative"
      className={classes.root}
    >
      <Toolbar>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <MenuIcon />
        </Box>
        {customerLogo && (
          <img
            className={classes.image}
            data-qa={APP_BAR_LOGO}
            src={customerLogo}
            alt={t('logo')}
            onClick={handleLogoClick}
          />
        )}
        <Divider
          className={classes.divider}
          variant="middle"
          orientation="vertical"
        />
        <Typography className={classes.productName} variant="body2">
          {t(customer?.name)}
        </Typography>
        <Grid className={classes.grow} />
        <Grid className={classes.menuBlock} sx={{ display: 'flex' }}>
          <UserMenu />
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
  currentUser: getCurrentUser(state),
  customerLogo: getCustomerLogo(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    user: bindActionCreators(userActions, dispatch),
    files: bindActionCreators(filesAction, dispatch),
  },
});

export const AppBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBar);
