import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';
import {
  GetClubKitSets,
  CreateCompetitor,
  ClearCompetitorState,
  SetSelectedTeam,
  SetKitSets,
  SetTeams,
  GetVenues,
  GetTeam,
  SetVenueList,
  CopyPreviousPlayerList,
} from './types';

const getClubKitSets = createAction<GetClubKitSets>(
  `${NAMESPACE}/GET_CLUB_KIT_SETS`,
);
const getClubKitSetsRequest = createAction<void>(
  `${NAMESPACE}/GET_CLUB_KIT_SETS_REQUEST`,
);
const getClubKitSetsSuccess = createAction<void>(
  `${NAMESPACE}/GET_CLUB_KIT_SETS_SUCCESS`,
);
const getClubKitSetsFailure = createAction<void>(
  `${NAMESPACE}/GET_CLUB_KIT_SETS_FAILURE`,
);

const createCompetitor = createAction<CreateCompetitor>(
  `${NAMESPACE}/CREATE_COMPETITOR`,
);
const createCompetitorRequest = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITOR_REQUEST`,
);
const createCompetitorSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITOR_SUCCESS`,
);
const createCompetitorFailure = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITOR_FAILURE`,
);

const clearCompetitorState = createAction<ClearCompetitorState>(
  `${NAMESPACE}/CLEAR_COMPETITOR_STATE`,
);

const getTeams = createAction<GetTeam>(`${NAMESPACE}/GET_TEAMS`);
const getTeamsRequest = createAction<void>(`${NAMESPACE}/GET_TEAMS_REQUEST`);
const getTeamsSuccess = createAction<void>(`${NAMESPACE}/GET_TEAMS_SUCCESS`);
const getTeamsFailure = createAction<void>(`${NAMESPACE}/GET_TEAMS_FAILURE`);

const resetCompetitorState = createAction<{ competitionId: number }>(
  `${NAMESPACE}/RESET_COMPETITOR_STATE`,
);

const setSelectedTeam = createAction<SetSelectedTeam>(
  `${NAMESPACE}/SET_SELECTED_TEAM`,
);

const setKitSets = createAction<SetKitSets>(`${NAMESPACE}/SET_KIT_SETS`);

const setTeams = createAction<SetTeams>(`${NAMESPACE}/SET_TEAMS`);

const searchDefaultVenues = createAction<GetVenues>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE`,
);
const searchDefaultVenuesRequest = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_REQUEST`,
);
const searchDefaultVenuesSuccess = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_SUCCESS`,
);
const searchDefaultVenuesFailure = createAction<void>(
  `${NAMESPACE}/SEARCH_DEFAULT_VENUE_FAILURE`,
);
const setDefaultVenuesList = createAction<SetVenueList>(
  `${NAMESPACE}/SET_DEFAULT_VENUES_LIST`,
);
const resetSearchDefaultVenues = createAction(
  `${NAMESPACE}/RESET_SEARCH_DEFAULT_VENUE`,
);
const getDefaultVenues = createAction<GetVenues>(
  `${NAMESPACE}/GET_DEFAULT_VENUES`,
);

const searchAlternativeVenues = createAction<GetVenues>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE`,
);
const searchAlternativeVenuesRequest = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_REQUEST`,
);
const searchAlternativeVenuesSuccess = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_SUCCESS`,
);
const searchAlternativeVenuesFailure = createAction<void>(
  `${NAMESPACE}/SEARCH_ALTERNATIVE_VENUE_FAILURE`,
);
const setAlternativeVenuesList = createAction<SetVenueList>(
  `${NAMESPACE}/SET_ALTERNATIVE_VENUES_LIST`,
);
const resetSearchAlternativeVenues = createAction(
  `${NAMESPACE}/RESET_SEARCH_ALTERNATIVE_VENUE`,
);
const getAlternativeVenues = createAction<GetVenues>(
  `${NAMESPACE}/GET_ALTERNATIVE_VENUE`,
);

const copyPreviousPlayerList = createAction<CopyPreviousPlayerList>(
  `${NAMESPACE}/COPY_PREVIOUS_PLAYER_LIST`,
);
const copyPreviousPlayerListRequest = createAction<void>(
  `${NAMESPACE}/COPY_PREVIOUS_PLAYER_LIST_REQUEST`,
);
const copyPreviousPlayerListSuccess = createAction<void>(
  `${NAMESPACE}/COPY_PREVIOUS_PLAYER_LIST_SUCCESS`,
);
const copyPreviousPlayerListFailure = createAction<void>(
  `${NAMESPACE}/COPY_PREVIOUS_PLAYER_LIST_FAILURE`,
);

export default {
  getClubKitSets,
  getClubKitSetsRequest,
  getClubKitSetsSuccess,
  getClubKitSetsFailure,

  createCompetitor,
  createCompetitorRequest,
  createCompetitorSuccess,
  createCompetitorFailure,

  getTeams,
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFailure,

  searchDefaultVenues,
  searchDefaultVenuesRequest,
  searchDefaultVenuesSuccess,
  searchDefaultVenuesFailure,
  setDefaultVenuesList,
  resetSearchDefaultVenues,
  getDefaultVenues,

  searchAlternativeVenues,
  searchAlternativeVenuesRequest,
  searchAlternativeVenuesSuccess,
  searchAlternativeVenuesFailure,
  setAlternativeVenuesList,
  resetSearchAlternativeVenues,
  getAlternativeVenues,

  clearCompetitorState,
  resetCompetitorState,
  setSelectedTeam,
  setKitSets,
  setTeams,

  copyPreviousPlayerList,
  copyPreviousPlayerListRequest,
  copyPreviousPlayerListSuccess,
  copyPreviousPlayerListFailure,
};
