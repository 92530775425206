import { call, put } from 'redux-saga/effects';

import * as countriesApi from '@core/api/countries';

import { actions } from '.';

export function* getCountries(params?: { query?: string; page?: number }) {
  yield put(actions.getCountriesRequest());
  const response = (yield call(countriesApi.getCountries, params)) as InferApi<
    typeof countriesApi.getCountries
  >;

  if (!response.error) {
    yield put(actions.getCountriesSuccess());
    yield put(actions.setCountries(response.response?._embedded?.countries));
  } else {
    yield put(actions.getCountriesFailure());
  }

  return response;
}
