import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { uuid } from '@core/helpers';
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  Alert,
  Theme,
} from '@mui/material';
import { ReportProblemOutlined, CheckCircleOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { ColumnWithSubValue, DottedText, CustomTable } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import {
  createURL,
  truncateString,
  formatDate,
  formatTime,
  getVenue,
} from '@core/helpers';
import { Column } from '@core/components/material-table';
import { CustomerResponse, MatchModel } from '@core/types';
import paths from '@core/routes/paths';
import { State } from '@core/store';

import * as testIds from '../../tests/test-ids';

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
  };
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  matchOfficialConflicts: Array<MatchModel>;
  isLoading: boolean;
}

type Props = DispatchProps & StateProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipField: {
    color: theme.palette.primary.light,
    width: 'fit-content',
  },
}));

const MatchOfficialValidation = (props: Props) => {
  const { actions, isLoading, matchOfficialConflicts, currentCustomer } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleMatchClick = (match: MatchModel) => {
    const tabTitle = `${truncateString(
      match?.homeCompetitorTitle,
      20,
    )} | ${truncateString(match?.awayCompetitorTitle, 20)}`;
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.matchProfile),
      props: {
        competitionId: match.competitionId,
        stageId: null,
        matchId: match.id,
      } as TabbedProps<any>,
      title: tabTitle,
      id: uuid(),
    });
  };

  const columns: Array<Column<MatchModel>> = [
    {
      title: t('No.'),
      field: 'number',
      render: (rowData) => {
        const matchNo = String(rowData?.number) || '-';
        const competitionTitle = rowData?.competitionTitle || '-';
        const matchStage = rowData?.parentGroupTitle || '-';
        const season = rowData?.seasonTitle || '-';
        const round = rowData?.groupTitle || '-';
        const matchDay = String(rowData?.matchDayNumber) || '-';
        const title = [
          [t('Match No.'), matchNo],
          competitionTitle,
          season,
          matchStage,
          [t('RND'), round],
          [t('MD'), matchDay],
        ];

        return (
          <Tooltip
            title={<DottedText textEntries={title} />}
            arrow
            className={classes.tooltipField}
          >
            <Button variant="text" onClick={() => handleMatchClick(rowData)}>
              {rowData.number}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: t('Time'),
      field: 'time',
      render: (rowData) => {
        const dateTime = `${rowData.date} ${rowData.time}`;

        return rowData.date && rowData.time ? (
          <ColumnWithSubValue
            value={formatTime(currentCustomer.timeFormat, dateTime)}
            subValue={formatDate(currentCustomer?.dateFormat, dateTime)}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Venue'),
      field: 'venue',
      render: (rowData) => (
        <ColumnWithSubValue
          value={getVenue(rowData.venue)?.title}
          subValue={getVenue(rowData.venue)?.internationalTitle}
        />
      ),
    },
    {
      title: t('City'),
      field: 'city',
      render: (rowData) =>
        rowData?._embedded?.homeCompetitor?.defaultVenue?.city?.name,
    },
  ];

  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <Box width="100%" pt={3}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.label}
            >
              {t('Persons availability for this match')}
            </Typography>
            <Box width="100%" pt={1}>
              <Alert icon={<CircularProgress size={20} />} severity="info">
                {t('Checking...')}
              </Alert>
            </Box>
          </Box>
        </Grid>
      )}
      {Array.isArray(matchOfficialConflicts) && (
        <Grid item xs={12}>
          <Box width="100%" pt={3}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.label}
            >
              {t('Persons availability for this match')}
            </Typography>
            {matchOfficialConflicts.length <= 0 ? (
              <Box
                width="100%"
                pt={1}
                data-qa={testIds.MATCH_OFFICIAL_VALIDATION_SUCCESS}
              >
                <Alert icon={<CheckCircleOutline />} severity="success">
                  {t('Person is available.')}
                </Alert>
              </Box>
            ) : (
              <>
                <Box
                  width="100%"
                  pt={1}
                  data-qa={testIds.MATCH_OFFICIAL_VALIDATION_FAILURE}
                >
                  <Alert icon={<ReportProblemOutlined />} severity="warning">
                    {t('Person has another match(es) this day.')}
                  </Alert>
                </Box>
                <Box width="100%" pt={2}>
                  <CustomTable
                    noPaper
                    columns={columns}
                    data={matchOfficialConflicts}
                    borderedTable
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchOfficialValidation);
