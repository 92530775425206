import { call, put } from 'redux-saga/effects';

import { KitSetCreateData, KitSetPatchData } from '@core/types';
import * as kitSetApi from '@core/api/kit-set';

import { actions } from '.';

export function* createKitSet({
  clubId,
  data,
}: {
  clubId: number;
  data: KitSetCreateData;
}) {
  yield put(actions.createKitSetsRequest());
  const response = (yield call(
    kitSetApi.createKitSet,
    clubId,
    data,
  )) as InferApi<typeof kitSetApi.createKitSet>;

  if (!response.error) {
    yield put(actions.createKitSetsSuccess());
  } else {
    yield put(actions.createKitSetsFailure());
  }

  return response;
}

export function* getKitSetList(clubId: number) {
  yield put(actions.getKitSetListRequest());
  const response = (yield call(kitSetApi.getKitSetList, clubId)) as InferApi<
    typeof kitSetApi.getKitSetList
  >;

  if (!response.error) {
    yield put(actions.getKitSetListSuccess());
  } else {
    yield put(actions.getKitSetListFailure());
  }

  return response;
}

export function* getKitSet({ kitSetId }: { kitSetId: number }) {
  yield put(actions.getKitSetRequest());
  const response = (yield call(kitSetApi.getKitSet, { kitSetId })) as InferApi<
    typeof kitSetApi.getKitSet
  >;

  if (!response.error) {
    yield put(actions.getKitSetSuccess());
  } else {
    yield put(actions.getKitSetFailure());
  }

  return response;
}

export function* updateKitSet({
  kitSetId,
  data,
}: {
  kitSetId: number;
  data: KitSetPatchData;
}) {
  yield put(actions.updateKitSetRequest());
  const response = (yield call(kitSetApi.updateKitSet, {
    kitSetId,
    data,
  })) as InferApi<typeof kitSetApi.updateKitSet>;

  if (!response.error) {
    yield put(actions.updateKitSetSuccess());
  } else {
    yield put(actions.updateKitSetFailure());
  }

  return response;
}

export function* deleteKitSet({ kitSetId }: { kitSetId: number }) {
  yield put(actions.deleteKitSetRequest());
  const response = (yield call(kitSetApi.deleteKitSet, {
    kitSetId,
  })) as InferApi<typeof kitSetApi.deleteKitSet>;

  if (!response.error) {
    yield put(actions.deleteKitSetSuccess());
  } else {
    yield put(actions.deleteKitSetFailure());
  }

  return response;
}
