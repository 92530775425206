import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterTabProps, Genders, PersonGenders } from '@core/types';
import { getGenders } from '@core/helpers';
import { FilterCheckboxList } from '@core/components';
import { GENDER_FILTER, GENDER_FILTER_ID } from './constants';

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

type Props = FilterTabProps<{
  shifted?: boolean;
  includeMixed?: boolean;
}>;

const GenderFilter = ({ filters, includeMixed, ...rest }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const filterValues = filters[GENDER_FILTER_ID]?.filterValues || [];
  const genders = Object.values(includeMixed ? Genders : PersonGenders);

  const formatFilterLabel = (val: string) => getGenders(t, val as Genders);

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Gender')}
      </Typography>
      <FilterCheckboxList
        list={genders}
        filterProps={GENDER_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default GenderFilter;
