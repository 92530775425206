import React from 'react';
import { Typography, Grid, GridSpacing, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  children: any;
  title?: string;
  spacing?: GridSpacing;
}

interface StyleProps {
  pb?: number;
  mb?: number;
}

type Props = OwnProps & StyleProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      paddingBottom: ({ pb }: StyleProps) =>
        pb ? theme.spacing(pb) : theme.spacing(1),
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginBottom: ({ mb }: StyleProps) =>
        mb ? theme.spacing(mb) : theme.spacing(3),
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ContentBlock = ({ children, title, spacing, pb, mb, ...rest }: Props) => {
  const classes = useStyles({ pb, mb });

  return (
    <Grid className={classes.root} {...rest}>
      <Grid container>
        <Grid item xs={12} md={3}>
          {title && (
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={12} md={9} spacing={spacing}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentBlock;
