import React from 'react';
import { Box, IconButton } from '@mui/material';
import { VisibilityTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { uuid } from '@core/helpers';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { CalendarEdit } from '@core/icons';
import { createURL, truncateString } from '@core/helpers';
import {
  MATCH_TABLE_VIEW_BUTTON,
  MATCH_TABLE_EDIT_BUTTON,
} from '@core/pages/matches/tests/test-ids';
import { MatchStatus, MatchModel, ModalTypes, Group } from '@core/types';
import paths from '@core/routes/paths';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import { actions as fixtureMatchEditActions } from '@core/store/modules/modals/fixture-match-edit';

import { areCompetitorsPresent } from '../helpers';

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
    matchSheetManagement: typeof matchSheetManagementActions;
    fixtureMatchEdit: typeof fixtureMatchEditActions;
    globalModal: typeof globalModalActions;
  };
}

interface OwnProps {
  homeCompetitorId: number;
  awayCompetitorId: number;
  match: MatchModel;
  stage?: Group;
  competitionId: number;
}

type Props = OwnProps & DispatchProps;

const ActionsButtons = (props: Props) => {
  const { t } = useTranslation();
  const { match, actions, competitionId, stage } = props;

  const canViewMatch = !!match?._links?.self;
  const canEditMatch = !!match?._links?.patch;

  const awayCompetitorTitle = match?.awayCompetitorTitle;
  const homeCompetitorTitle = match?.homeCompetitorTitle;
  const tabTitle = `${truncateString(homeCompetitorTitle, 20)} |
    ${truncateString(awayCompetitorTitle, 20)}`;

  const handleView = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.matchProfile),
      props: {
        matchId: match?.id,
        stageId: stage?.id,
        competitionId,
        callbacks: {
          onRemoveCallback: () => {
            actions.matchSheetManagement.resetMatchSheetHistory({
              matchId: match.id,
            });
          },
        },
      } as TabbedProps<any>,
      title: tabTitle,
      id: uuid(),
    });
  };

  const handleEdit = () => {
    actions.globalModal.openModal({
      type: ModalTypes.MATCH_CREATE_MODAL,
      params: {
        competitionId,
        stage,
        matchId: match?.id,
        isEditMode: true,
      },
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {canViewMatch && (
        <IconButton
          title={t('View')}
          color="primary"
          onClick={handleView}
          disabled={!areCompetitorsPresent(match)}
          data-qa={MATCH_TABLE_VIEW_BUTTON}
        >
          <VisibilityTwoTone />
        </IconButton>
      )}
      {canEditMatch && (
        <IconButton
          title={t('Edit')}
          color="primary"
          disabled={match.status === MatchStatus.COMPLETE}
          onClick={handleEdit}
          data-qa={MATCH_TABLE_EDIT_BUTTON}
        >
          <CalendarEdit />
        </IconButton>
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
    fixtureMatchEdit: bindActionCreators(fixtureMatchEditActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ActionsButtons);
