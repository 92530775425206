import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { CANCEL_BUTTON } from '@core/constants/test-ids';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { GlobalModalProps } from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { actions as competitorActions } from '@core/store/modules/tabs/competitor-addition';
import { State } from '@core/store';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    tab: typeof tabActions;
    globalModal: typeof globalModalActions;
    competitor: typeof competitorActions;
  };
}

interface StateProps {
  isCopying: boolean;
}

type Props = GlobalModalProps & StateProps & DispatchProps;

const CompetitorPreviousPlayerListModal = (props: Props) => {
  const { isModalOpen, actions, modalParams, isCopying } = props;
  const players = modalParams?.players ?? [];
  const teamOfficials = modalParams?.teamOfficials ?? [];
  const tabId = modalParams?.tabId;
  const competitionId = modalParams?.competitionId;
  const teamId = modalParams?.teamId;
  const { t } = useTranslation();

  const handleModalClose = () => {
    actions.globalModal.closeModal();
    actions.tab.removeTab({ tabId });
  };

  const handleCopyPreviousClick = () => {
    actions.competitor.copyPreviousPlayerList({
      competitionId,
      teamId,
      tabId,
      players,
      teamOfficials,
    });
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('Copy previous player list?')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body2">
            {t(
              'Do you want to copy the player list for this team from the previous competition?',
            )}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button data-qa={CANCEL_BUTTON} onClick={handleModalClose}>
              {t(`Don't copy`)}
            </Button>
          </Box>
          <ButtonWithProgress
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleCopyPreviousClick}
            isLoading={isCopying}
          >
            {t('Copy')}
          </ButtonWithProgress>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const isCopyingSelector = createLoadingSelector([
  competitorActions.copyPreviousPlayerListRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isCopying: isCopyingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tab: bindActionCreators(tabActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
    competitor: bindActionCreators(competitorActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitorPreviousPlayerListModal);
