import { pathOr, all, propEq } from 'ramda';

import { ExpansionPanelGroupId } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from './constants';

export const getExpansionPanelGroups = pathOr(null, [
  'ui',
  NAMESPACE,
  'allGroupsStatesById',
]);

export const getExpansionPanelGroupById = (
  groupId: ExpansionPanelGroupId,
  state: State,
) => pathOr(null, ['ui', NAMESPACE, 'allGroupsStatesById', groupId], state);

export const areAllPanelsCompletedByGroupId = (
  groupId: ExpansionPanelGroupId,
  state: State,
) => {
  const group = pathOr(
    null,
    ['ui', NAMESPACE, 'allGroupsStatesById', groupId],
    state,
  );
  if (!group) {
    return false;
  }

  return all(propEq('isCompleted', true), group);
};
