import { useTranslation } from 'react-i18next';
import { IconButton, Box, Tooltip } from '@mui/material';
import {
  DeleteTwoTone as DeleteIcon,
  VisibilityTwoTone,
} from '@mui/icons-material';
import { uuid } from '@core/helpers';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { useCoreDispatch, useConfirmationDialog } from '@core/hooks';
import { VIEW_BUTTON, REMOVE_BUTTON } from '@core/constants/test-ids';
import { CUSTOM_TABLE_ACTIONS } from '@core/components/custom-table/tests/test-ids';
import { createURL } from '@core/helpers';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import useCheckToggles from '@core/helpers/feature-toggle/check-toggles';
import paths from '@core/routes/paths';
import type { Competitor } from '@core/types';
import { ConditionalWrapper } from '@ui-components';

type Props = {
  competitionId: number;
  competitor: Competitor;
};

export const CompetitorActionColumn = ({
  competitor,
  competitionId,
}: Props) => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const isDeleteTeamToggleEnabled = useCheckToggles(
    'MSCM2_1649_DELETE_TEAMS_FROM_COMPETITION',
  );
  const canViewCompetitor = !!competitor?._links?.self;
  const canDeleteCompetitor = !!competitor?._links?.delete;

  const handleViewClick =
    ({ id, title, teamId }: Competitor) =>
    () =>
      dispatch(
        tabsActions.openTab({
          title,
          isClosable: true,
          name: createURL(paths.competitorProfile),
          props: { id, competitionId, teamId, competitorId: id },
          id: uuid(),
        }),
      );

  const handleCompetitorDelete =
    ({ teamId }: Competitor) =>
    async () => {
      const confirmed = await getConfirmation({
        title: t('Delete'),
        message: t('Are you sure you want to delete this team?'),
        confirmText: t('Delete'),
        cancelText: t('Cancel'),
      });

      if (confirmed) {
        dispatch(
          competitionProfileActions.deleteCompetitor({ competitionId, teamId }),
        );
      }
    };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      data-qa={CUSTOM_TABLE_ACTIONS}
    >
      {canViewCompetitor && (
        <IconButton
          color="primary"
          title={t('View')}
          data-qa={VIEW_BUTTON}
          onClick={handleViewClick(competitor)}
        >
          <VisibilityTwoTone />
        </IconButton>
      )}
      <ConditionalWrapper
        condition={isDeleteTeamToggleEnabled && !canDeleteCompetitor}
        wrapper={(children) => (
          <Tooltip
            title={t(
              'The Team in Competition has matches with statuses, which do not allow deletion',
            )}
          >
            <Box>{children}</Box>
          </Tooltip>
        )}
      >
        <IconButton
          color="primary"
          title={t('Delete')}
          data-qa={REMOVE_BUTTON}
          onClick={handleCompetitorDelete(competitor)}
          disabled={isDeleteTeamToggleEnabled && !canDeleteCompetitor}
        >
          <DeleteIcon />
        </IconButton>
      </ConditionalWrapper>
    </Box>
  );
};

export default CompetitorActionColumn;
