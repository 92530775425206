import { TeamModel } from '@core/types';
import { formatOption } from '@core/helpers';

import { COMPETITOR_FIELDS, CompetitorFormValues } from '../constants';

export const getInitialValues = (
  selectedTeam: TeamModel,
): CompetitorFormValues => ({
  [COMPETITOR_FIELDS.teamColorSetId]: null,
  [COMPETITOR_FIELDS.title]: selectedTeam?.title || '',
  [COMPETITOR_FIELDS.internationalTitle]:
    selectedTeam?.internationalTitle || '',
  [COMPETITOR_FIELDS.defaultVenueOption]: formatOption(
    null,
    selectedTeam?.club?.defaultVenue,
    'title',
  ),
  [COMPETITOR_FIELDS.alternativeVenueOption]: formatOption(
    null,
    selectedTeam?.club?.alternativeVenue,
    'title',
  ),
});

export default getInitialValues;
