import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, AutocompleteField } from '@core/components';
import { formatOptions } from '@core/helpers';
import { actions as sanctionActions } from '@volleyball/store/modules/tabs/impose-sanction';
import { getMatchOfficials } from '@volleyball/store/modules/tabs/impose-sanction/selectors';
import { State } from '@volleyball/store';
import { SanctionType } from '@volleyball/types';
import { OFFENDER_TYPE_FIELDS } from '../constants';
import PersonOption from './person-option';
import PersonSingleValue from './person-single-value';
import type { FormMode } from '../constants';

type Props = {
  sportId: number;
  competitionId: number;
  mode: FormMode;
  sanctionType: SanctionType;
};

const OffenderTypeMatchOfficial = (props: Props) => {
  const { sportId, competitionId, mode, sanctionType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matchOfficials = useSelector((state: State) =>
    getMatchOfficials(state, competitionId, sanctionType),
  );

  useEffect(() => {
    dispatch({
      type: sanctionActions.getMatchOfficials.toString(),
      payload: { competitionId, sportId, sanctionType },
    });
  }, []);

  function handleCompetitorInputChange(query: string) {
    dispatch({
      type: sanctionActions.getMatchOfficials.toString(),
      payload: { competitionId, sportId, sanctionType, query },
    });
  }

  return (
    <Grid container spacing={2}>
      {mode === 'edit' ? (
        <Grid item md={12}>
          <TextField
            name={OFFENDER_TYPE_FIELDS.offender}
            label={t('Match official')}
            disabled
          />
        </Grid>
      ) : (
        <Grid item md={12}>
          {/* TODO: JB: probably ok, but check if AutocompleteField works with initial non empty value */}
          <AutocompleteField
            name={OFFENDER_TYPE_FIELDS.competitor}
            options={formatOptions(t, matchOfficials?.list, 'id')}
            textFieldProps={{
              label: t('Select match official'),
              required: true,
            }}
            onInputChange={handleCompetitorInputChange}
            customComponents={{
              Option: PersonOption,
              SingleValue: PersonSingleValue,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OffenderTypeMatchOfficial;
