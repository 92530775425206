import React from 'react';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { FullScreenSpinner } from '@core/components';

import * as testIds from './tests/test-ids';

const useStyles = makeStyles(() => ({
  table: {
    '& td:last-child': {
      textAlign: 'right',
    },
    '& th:last-child': {
      textAlign: 'right',
    },
  },
}));

interface Column {
  name: string;
  label: string;
  entityFieldAttributes?: Array<any>;
}

interface OwnProps {
  renderActions?: (item: any) => any;
  onRowClick?: (item: any) => void;
  rows: Array<any>;
  isLoading: boolean;
  columns: Array<Column>;
}

type Props = OwnProps;

const Table = (props: Props) => {
  const { rows, isLoading, columns, renderActions, onRowClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const tranformedColumns = renderActions
    ? [...columns, { name: 'actions', label: 'Actions' }]
    : columns;

  const handleRowClick = (item: any) => () => {
    onRowClick && onRowClick(item);
  };

  function getValue(val: any) {
    return val && typeof val === 'object' ? val.name : val;
  }

  const renderRowActions = (item: any) => (
    <TableCell key="actions">{renderActions(item)}</TableCell>
  );

  const renderCell = (item: any, name: string) => (
    <TableCell key={name}>{getValue(item[name])}</TableCell>
  );

  const renderRows = () =>
    rows &&
    tranformedColumns &&
    rows.map((item: any) => (
      <TableRow
        data-qa={testIds.STATIC_TABLE_ROW}
        key={item.id}
        onClick={handleRowClick(item)}
        hover={!!onRowClick}
      >
        {tranformedColumns.map(({ name }: Column) =>
          name === 'actions' ? renderRowActions(item) : renderCell(item, name),
        )}
      </TableRow>
    ));

  return (
    <Paper data-qa={testIds.STATIC_TABLE}>
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <MuiTable className={classes.table}>
          <TableHead>
            <TableRow>
              {tranformedColumns &&
                tranformedColumns.map(({ label }) => (
                  <TableCell data-qa={testIds.STATIC_TABLE_COLUMNS} key={label}>
                    {t(label)}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </MuiTable>
      )}
    </Paper>
  );
};

export default Table;
