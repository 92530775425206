import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { State } from '@core/store';
import { getUserRoleRequestsApiRoot } from '@core/store/modules/api/user-role-request/selectors';
import {
  CurrentUserResponse,
  CustomerResponse,
  UserRoleRequest,
} from '@core/types';

import { DefaultState } from './reducer';
import { NAMESPACE } from './constants';

const getNamespaceRoot = (state: State) =>
  pathOr<DefaultState>(null, ['pages', NAMESPACE], state);

export const getCurrentUser = createSelector(getNamespaceRoot, (root) => {
  return pathOr<CurrentUserResponse>(null, ['currentUser'], root);
});

export const getCurrentCustomer = createSelector(getNamespaceRoot, (root) => {
  return pathOr<CustomerResponse>(null, ['currentCustomer'], root);
});

export const getCustomerLogo = createSelector(getNamespaceRoot, (root) => {
  return pathOr(null, ['customerLogo'], root);
});

export const getUserRoleRequests = createSelector(
  getNamespaceRoot,
  getUserRoleRequestsApiRoot,
  (root, requests): Array<UserRoleRequest> => {
    const requestIds = root?.userRoleRequests?.list;

    return requests && requestIds
      ? requestIds.map((id: number) => requests[id])
      : [];
  },
);

export const getAccessLinks = createSelector(
  getCurrentUser,
  (user) => user?._links,
);
