export enum MATCH_SANCTION_FIELDS {
  competitionTitle = 'competitionTitle',
  competitionSeason = 'competitionSeason',
  commment = 'comment',
  matchId = 'matchId',
  numberOfMatches = 'numberOfMatches',
}

export enum FINANCIAL_PENALTY_FIELDS {
  competitionTitle = 'competitionTitle',
  competitionSeason = 'competitionSeason',
  amount = 'amount',
  currency = 'currency',
  commment = 'comment',
  matchId = 'matchId',
}

export enum OFFENDER_TYPE_FIELDS {
  offenderType = 'offenderType',
  competitor = 'competitor',
  player = 'player',
  teamOfficialCompetitor = 'teamOfficialCompetitor',
  teamOfficial = 'teamOfficial',
  matchOfficial = 'matchOfficial',
  competitionTeam = 'competitionTeam',
  offender = 'offender',
}

export type FormMode = 'edit' | 'new';
