import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { NumberField, Tooltip } from '@core/components';
import useStyles from '../styles';
import { FIELD_NAMES } from '../constants';
import { FormValues } from '../helpers/initialValues';

type Props = {
  editMode: boolean;
};

const GoldenRulesBlock = ({ editMode }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const handleChange: RadioGroupProps['onChange'] = (_event, value) => {
    switch (value) {
      case FIELD_NAMES.goldenSetRule:
        setFieldValue(value, true);
        setFieldValue(FIELD_NAMES.goldenMatchRule, false);
        setFieldValue(FIELD_NAMES.pointsToWinInGoldenMatchSet, 15);
        break;
      case FIELD_NAMES.goldenMatchRule:
        setFieldValue(value, true);
        setFieldValue(FIELD_NAMES.goldenSetRule, false);
        setFieldValue(FIELD_NAMES.pointsToWinInGoldenSet, 15);
        break;
      default:
        setFieldValue(FIELD_NAMES.goldenSetRule, false);
        setFieldValue(FIELD_NAMES.goldenMatchRule, false);
        setFieldValue(FIELD_NAMES.pointsToWinInGoldenSet, 15);
        setFieldValue(FIELD_NAMES.pointsToWinInGoldenMatchSet, 15);
    }
  };

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Golden rules')}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <RadioGroup onChange={handleChange}>
              <Paper sx={{ mb: 1 }}>
                <Accordion
                  variant="outlined"
                  expanded={values[FIELD_NAMES.goldenSetRule]}
                  disabled={editMode && values.goldenMatchRule}
                >
                  <AccordionSummary className={classes.summary}>
                    <Box flex="1" display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={values.goldenSetRule}
                          />
                        }
                        label={t('Apply golden set rule')}
                        style={{ width: '100%' }}
                        value={FIELD_NAMES.goldenSetRule}
                      />
                      <Tooltip
                        title={t(
                          'Enable playing a decisive Golden Set in a match when required. Please specify points to win.',
                        )}
                      />
                    </Box>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails className={classes.details}>
                    <Grid container item spacing={2}>
                      <Grid item xs={12} md={6}>
                        <NumberField
                          name={FIELD_NAMES.pointsToWinInGoldenSet}
                          data-qa={FIELD_NAMES.pointsToWinInGoldenSet}
                          label={t('Points to win golden set')}
                          min={1}
                          disabled={editMode && values.goldenMatchRule}
                          required
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>
              <Paper sx={{ mb: 1 }}>
                <Accordion
                  variant="outlined"
                  expanded={values[FIELD_NAMES.goldenMatchRule]}
                  disabled={editMode}
                >
                  <AccordionSummary className={classes.summary}>
                    <Box flex="1" display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={values.goldenMatchRule}
                          />
                        }
                        label={t('Apply golden match rule')}
                        style={{ width: '100%' }}
                        value={FIELD_NAMES.goldenMatchRule}
                      />
                      <Tooltip
                        title={t(
                          'Automatically generate a decisive Golden Match for each series. If the series winner is already determined from previous matches, the Golden Match will be canceled. Please specify points to win.',
                        )}
                      />
                    </Box>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails className={classes.details}>
                    <Grid container item spacing={2}>
                      <Grid item xs={12} md={6}>
                        <NumberField
                          name={FIELD_NAMES.pointsToWinInGoldenMatchSet}
                          data-qa={FIELD_NAMES.pointsToWinInGoldenMatchSet}
                          label={t('Points to win golden match')}
                          min={1}
                          disabled={editMode}
                          required
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>
              <Paper sx={{ mb: 1 }}>
                <Accordion
                  variant="outlined"
                  disabled={editMode && values.goldenMatchRule}
                >
                  <AccordionSummary className={classes.summary}>
                    <Box flex="1" display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={
                              !values.goldenMatchRule && !values.goldenSetRule
                            }
                          />
                        }
                        label={t('None')}
                        style={{ width: '100%' }}
                        checked={
                          !values[FIELD_NAMES.goldenSetRule] &&
                          !values[FIELD_NAMES.goldenMatchRule]
                        }
                        value=""
                      />
                      <Tooltip
                        title={t(
                          "Don't apply Golden Set or Golden Match rules",
                        )}
                      />
                    </Box>
                  </AccordionSummary>
                </Accordion>
                <Divider />
              </Paper>
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Box mb={2} mt={1} flex={1}>
          <Divider orientation="horizontal" />
        </Box>
      </Grid>
    </>
  );
};

export default GoldenRulesBlock;
