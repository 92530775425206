import * as yup from 'yup';
import { TFunction } from 'i18next';
import { validateStringAgainstDayjsFormat } from '@core/helpers';
import { DoubleEliminationType, EliminationType, StageType } from '@core/types';
import { FIELD_NAMES } from '../constants';

export const getNumberOfTeamsMinMax = (options: {
  stageType: StageType;
  knockoutElimination: EliminationType;
  knockoutDoubleElimination: DoubleEliminationType;
  numberOfPoolsValue: number;
  variant: 'stage' | 'pool';
}): { min: number; max: number } => {
  const {
    stageType,
    knockoutElimination,
    knockoutDoubleElimination,
    numberOfPoolsValue,
    variant,
  } = options;
  const numberOfTeamsRange = {
    roundRobinInPool: { min: 2, max: 128 },
    roundRobinWithoutPools: { min: 2, max: 128 },
    knockoutSingleElimination: { min: 2, max: 128 },
    knockoutDoubleEliminationClassic: { min: 4, max: 128 },
    knockoutDoubleEliminationCrossOver: { min: 8, max: 128 },
  };

  switch (true) {
    case stageType === StageType.ROUND_ROBIN &&
      numberOfPoolsValue &&
      variant === 'pool': // round robin with pools; pool number of teams
      return numberOfTeamsRange.roundRobinInPool;
    case stageType === StageType.ROUND_ROBIN &&
      numberOfPoolsValue &&
      variant === 'stage': // round robin with pools; stage number of teams
      return undefined;
    case stageType === StageType.ROUND_ROBIN &&
      !numberOfPoolsValue &&
      variant === 'stage': // round robin without pools; stage number of teams
      return numberOfTeamsRange.roundRobinWithoutPools;
    case stageType === StageType.KNOCK_OUT && variant === 'pool': // knock out; pool number of teams
      return undefined;
    case stageType === StageType.KNOCK_OUT && knockoutElimination === 'SINGLE': // knockout single elimination; stage number of teams
      return numberOfTeamsRange.knockoutSingleElimination;
    case stageType === StageType.KNOCK_OUT &&
      knockoutElimination === 'DOUBLE' &&
      knockoutDoubleElimination === 'CLASSIC': // knockout double elimination static finals; stage number of teams
      return numberOfTeamsRange.knockoutDoubleEliminationClassic;
    case stageType === StageType.KNOCK_OUT &&
      knockoutElimination === 'DOUBLE' &&
      knockoutDoubleElimination === 'CROSSOVER': // knockout double elimination cross-over finals; stage number of teams
      return numberOfTeamsRange.knockoutDoubleEliminationCrossOver;
    default:
      throw new Error(
        `not implemented: ${JSON.stringify({
          stageType,
          knockoutElimination,
          knockoutDoubleElimination,
          numberOfPoolsValue,
          variant,
        })}`,
      );
  }
};

function stageNumberOfTeamsSchemaBuilder(t: TFunction) {
  return yup
    .number()
    .notRequired()
    .when(
      [
        FIELD_NAMES.stageType,
        FIELD_NAMES.knockOutElimination,
        FIELD_NAMES.knockoutDoubleElimination,
        FIELD_NAMES.numberOfPools,
      ],
      (
        [
          stageType,
          knockoutElimination,
          knockoutDoubleElimination,
          numberOfPoolsValue,
        ],
        schema,
      ) => {
        const { min, max } =
          getNumberOfTeamsMinMax({
            stageType,
            knockoutElimination,
            knockoutDoubleElimination,
            numberOfPoolsValue,
            variant: 'stage',
          }) ?? {};
        if (typeof min === 'number' && typeof max === 'number') {
          return schema
            .min(min, t('Minimum {{value}} teams', { value: min }))
            .max(max, t('Maximum {{value}} teams', { value: max }))
            .required(t('Required'));
        } else {
          return schema;
        }
      },
    );
}

function roundRobinGroupsSchemaBuilder(t: TFunction) {
  return yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().notRequired(),
        numberOfTeams: yup.number().notRequired(),
      }),
    )
    .when(
      [FIELD_NAMES.stageType, FIELD_NAMES.numberOfPools],
      ([stageType, numberOfPoolsValue], schema) => {
        if (stageType === StageType.ROUND_ROBIN && numberOfPoolsValue) {
          const { min, max } = getNumberOfTeamsMinMax({
            stageType,
            knockoutElimination: undefined,
            knockoutDoubleElimination: undefined,
            numberOfPoolsValue,
            variant: 'pool',
          });

          return schema.of(
            yup.object().shape({
              title: yup.string().required(t('Required')),
              numberOfTeams: yup
                .number()
                .min(min, t('Minimum {{value}} teams', { value: min }))
                .max(max, t('Maximum {{value}} teams', { value: max }))
                .required(t('Required')),
            }),
          );
        } else {
          return schema;
        }
      },
    );
}

function knockoutGroupsSchemaBuilder(
  t: TFunction,
  options: {
    knockoutElimination?: EliminationType;
    knockoutDoubleElimination?: DoubleEliminationType;
  },
) {
  const fallbackSchema = yup.array().of(
    yup.object().shape({
      title: yup.string().notRequired(),
      bestOf: yup.number().notRequired(),
    }),
  );

  return fallbackSchema.when(
    [
      FIELD_NAMES.stageType,
      FIELD_NAMES.knockOutElimination,
      FIELD_NAMES.knockoutDoubleElimination,
    ],
    ([stageType, knockoutElimination, knockoutDoubleElimination], schema) => {
      const shouldValidate =
        stageType === StageType.KNOCK_OUT &&
        (options.knockoutElimination
          ? knockoutElimination === options.knockoutElimination
          : true) &&
        (options.knockoutDoubleElimination
          ? knockoutDoubleElimination === options.knockoutDoubleElimination
          : true);

      if (shouldValidate) {
        return schema.of(
          yup.object().shape({
            title: yup.string().required(t('Required')),
            bestOf: yup
              .number()
              .min(1, t('Minimum {{value}}', { value: 1 }))
              .required(t('Required')),
          }),
        );
      } else {
        return schema;
      }
    },
  );
}

function dateSchemaBuilder(t: TFunction, allowNull = false) {
  return yup
    .string()
    .when([], {
      is: () => !allowNull,
      then: (schema) => schema.required(t('Required')),
    })
    .nullable()
    .test('is-valid-date-format', t('Invalid date'), (value) =>
      validateStringAgainstDayjsFormat(value, 'YYYY-MM-DD', allowNull),
    );
}

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    // fields that do map one-to-one with group fields
    [FIELD_NAMES.title]: yup.string().required(t('Required')),
    [FIELD_NAMES.numberOfTeams]: stageNumberOfTeamsSchemaBuilder(t),
    [FIELD_NAMES.numberOfRounds]: yup
      .number()
      .min(1, t('Minimum {{value}} round', { value: 1 }))
      .required(t('Required')),
    [FIELD_NAMES.startDate]: dateSchemaBuilder(t),
    [FIELD_NAMES.endDate]: dateSchemaBuilder(t, true),
    // fields that do not map one-to-one with group fields
    [FIELD_NAMES.roundRobinGroups]: roundRobinGroupsSchemaBuilder(t),
    [FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups]:
      knockoutGroupsSchemaBuilder(t, {
        knockoutElimination: EliminationType.DOUBLE,
        knockoutDoubleElimination: DoubleEliminationType.CROSSOVER,
      }),
    [FIELD_NAMES.knockOutDoubleEliminationLosersGroups]:
      knockoutGroupsSchemaBuilder(t, {
        knockoutElimination: EliminationType.DOUBLE,
      }),
    [FIELD_NAMES.knockOutDoubleEliminationWinnersGroups]:
      knockoutGroupsSchemaBuilder(t, {
        knockoutElimination: EliminationType.DOUBLE,
      }),
    [FIELD_NAMES.knockoutSingleEliminationGroups]: knockoutGroupsSchemaBuilder(
      t,
      {
        knockoutElimination: EliminationType.SINGLE,
      },
    ),
  });
