import { FilterModel, Filters, FiltersItem, FilterValue } from '@core/types';
import { isValue } from '@core/helpers';

import { createFilterId } from './create-filter-id';

export class ManageFilter {
  filter: FilterModel;
  filterList: Filters;
  filterId: string;
  currentFilter: undefined | FiltersItem;
  isMultipleValues: boolean;
  newValue: FilterValue | Array<FilterValue>;

  public constructor(filter: FilterModel, filterList: Filters) {
    this.filter = filter;
    this.filterList = filterList;
    this.filterId = createFilterId(filter);
    this.currentFilter = filterList[this.filterId];
    this.newValue = filter.filterValues;
    this.isMultipleValues = Object.prototype.hasOwnProperty.call(
      this.newValue,
      'length',
    );
  }

  private addNewFilter() {
    const filterValues = this.isMultipleValues
      ? [...(this.newValue as Array<FilterValue>)]
      : [this.newValue];

    return {
      ...this.filterList,
      [this.filterId]: { ...this.filter, filterValues },
    };
  }

  private hasFilterValue(filterValue: string) {
    const currentFilterValue = this.currentFilter.filterValues;
    const valueIndex = currentFilterValue.findIndex(
      ({ value }) => value === filterValue,
    );

    return valueIndex !== -1;
  }

  private updateFilter() {
    const updatedFilterValue = [...this.currentFilter.filterValues];

    if (this.isMultipleValues) {
      const addValues = this.newValue as Array<FilterValue>;

      addValues.forEach(
        (addValue) =>
          !this.hasFilterValue(addValue.value) &&
          updatedFilterValue.push(addValue),
      );
    } else {
      const addValue = this.newValue as FilterValue;

      !this.hasFilterValue(addValue.value) && updatedFilterValue.push(addValue);
    }

    return {
      ...this.filterList,
      [this.filterId]: {
        ...this.currentFilter,
        filterValues: updatedFilterValue,
      },
    };
  }

  private removeProperty() {
    const updatedList = { ...this.filterList };

    delete updatedList[this.filterId];

    return updatedList;
  }

  public addFilter() {
    return !this.currentFilter ? this.addNewFilter() : this.updateFilter();
  }

  public removeFilter() {
    if (!this.currentFilter) return this.filterList;

    let updatedFilterValue = [...this.currentFilter.filterValues];

    if (this.isMultipleValues) {
      const removeValues = this.newValue as Array<FilterValue>;

      removeValues.forEach((removeVal) => {
        updatedFilterValue = updatedFilterValue.filter(
          (filterValue) => filterValue.value !== removeVal.value,
        );
      });
    } else {
      const removeValue = (this.newValue as FilterValue).value;

      updatedFilterValue = updatedFilterValue.filter(
        (filterValue) => filterValue.value !== removeValue,
      );
    }

    if (updatedFilterValue.length === 0) {
      return this.removeProperty();
    }

    return {
      ...this.filterList,
      [this.filterId]: {
        ...this.currentFilter,
        filterValues: updatedFilterValue,
      },
    };
  }

  public replaceFilter() {
    return isValue((this.newValue as FilterValue).value)
      ? this.addNewFilter()
      : this.removeProperty();
  }
}
