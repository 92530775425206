import React from 'react';
import Camera from '@mui/icons-material/CameraAlt';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ModalTypes } from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { actions as picturesActions } from '@core/store/modules/api/pictures';
import { FileParams } from '@core/components';

import SmallAvatar from './small-avatar';

type DispatchProps = {
  actions: {
    globalModal: typeof globalModalActions;
    pictures: typeof picturesActions;
  };
};

type OwnProps = {
  fileParams: FileParams;
  refreshParams?: any;
};

export type ChangeActionProps = DispatchProps & OwnProps;

const ChangeAction = (props: ChangeActionProps) => {
  const { actions, fileParams, refreshParams } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    actions.globalModal.openModal({
      type: ModalTypes.PICTURE_UPLOAD_MODAL,
      params: {
        fileParams,
        refreshParams,
      },
    });
    handleClose(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleRemove = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    // TODO: temporarily disabled
    actions.pictures.deletePicture({
      fileParams,
      refreshParams,
    });
  };

  return (
    <>
      <SmallAvatar onClick={fileParams.fileId ? handleClick : handleChange}>
        <Camera fontSize="small" color="inherit" />
      </SmallAvatar>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleChange}>{t('Change')}</MenuItem>
        <MenuItem onClick={handleRemove}>{t('Remove')}</MenuItem>
      </Menu>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
    pictures: bindActionCreators(picturesActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ChangeAction);
