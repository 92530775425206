import React from 'react';
import { MenuItem } from '@mui/material';
import { pathOr } from 'ramda';
import { OptionProps } from 'react-select';

import PersonNameColumn from '@core/components/person-name-column';
import { AutocompleteOption } from '@core/types';

type Props = OptionProps<AutocompleteOption, boolean>;

const PersonOption = (props: Props) => {
  const person =
    pathOr('', ['data', 'props', 'player'], props) ||
    pathOr('', ['data', 'props', 'teamOfficial'], props) ||
    pathOr('', ['data', 'props'], props);

  return (
    <MenuItem
      ref={props.innerRef as any}
      selected={props.isSelected}
      component="div"
      {...props.innerProps}
    >
      <PersonNameColumn person={person} />
    </MenuItem>
  );
};

export default PersonOption;
