import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 36,
    height: 36,
    border: 0,
    borderRadius: '50%',
    filter:
      'drop-shadow(0px 0.3px 0.5px rgba(28, 28, 30, 0.1)) drop-shadow(0px 2px 4px rgba(28, 28, 30, 0.2))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    fontSize: '0.875rem',
  },
}));

type Props = {
  number: string;
};

const JerseyNumber = ({ number }: Props) => {
  const styles = useStyles();
  const displayedNumber = number ? `#${number}` : '-';

  return <div className={styles.root}>{displayedNumber}</div>;
};

export default JerseyNumber;
