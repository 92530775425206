import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import {
  PlayerPositions,
  CompetitorTeamOfficialList,
  CompetitorPlayerList,
  TeamOfficialRoles,
  KitSet,
} from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [competitorId: string]: {
    playerPositions: PlayerPositions;
    teamOfficials: CompetitorTeamOfficialList;
    teamOfficialsRoles: TeamOfficialRoles;
    playerList: CompetitorPlayerList;
    kitSet: KitSet;
  };
}

export const defaultState: DefaultState = {};

type Payload = any;
interface Meta {
  competitorId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setPlayerPositions.toString()]: (state, { meta, payload }) =>
      assocPath([meta.competitorId, 'playerPositions'], payload, state),
    [actions.setTeamOfficials.toString()]: (state, { meta, payload }) =>
      assocPath([meta.competitorId, 'teamOfficials'], payload, state),
    [actions.setTeamOfficialsRoles.toString()]: (state, { meta, payload }) =>
      assocPath([meta.competitorId, 'teamOfficialsRoles'], payload, state),
    [actions.setCompetitorPlayerList.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitorId, 'playerList'], payload, state),
    [actions.resetCompetitorProfile.toString()]: (state, { payload }) =>
      dissoc(payload, state),
    [actions.setKitSet.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitorId, 'kitSet'], payload, state),
    [actions.setCompetitorByTeam.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitorId, 'competitorsByTeamId'], payload, state),
  },
  defaultState,
);

export default reducer;
