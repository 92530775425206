import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { ContentBlock, NamedValueColumn } from '@core/components';
import { Competitor, TeamModel } from '@core/types';

interface OwnProps {
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
}

type Props = OwnProps;

const TeamNameBlock = (props: Props) => {
  const { competitorDetails } = props;
  const { t } = useTranslation();

  return (
    <ContentBlock title={t('Team name in competition')}>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('Team name in competition')}
          value={competitorDetails?.competitor?.title || ''}
        />
      </Grid>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('International team name in competition')}
          value={competitorDetails?.competitor?.internationalTitle || ''}
        />
      </Grid>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('Team code')}
          value={competitorDetails?.competitor?.teamCode || ''}
        />
      </Grid>
    </ContentBlock>
  );
};

export default TeamNameBlock;
