import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import { formatPaginatedStoreWithIds } from '@core/helpers';
import { Paginated, MatchModel } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  byGroupId: {
    [id: number]: Paginated<MatchModel['id']>;
  };
  byId: {
    [id: number]: MatchModel['id'];
  };
  matches?: Paginated<MatchModel['id']>;
}

export const defaultState: DefaultState = {
  byGroupId: {},
  byId: {},
  matches: null,
};

interface Meta {
  id: number;
}

type Payload = MatchModel;

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setGroupMatches.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byGroupId', meta.id],
        formatPaginatedStoreWithIds('matches')(payload),
        state,
      ),

    [actions.setMatches.toString()]: (state, { payload }) =>
      assocPath(
        ['matches'],
        formatPaginatedStoreWithIds('matches')(payload),
        state,
      ),

    [actions.setMatchById.toString()]: (state, { payload, meta }) =>
      assocPath(['byId', meta.id], payload.id, state),

    [actions.clearMatchesByGroup.toString()]: (state, { meta }) =>
      dissocPath(['byGroupId', meta.id], state),
  },
  defaultState,
);

export default reducer;
