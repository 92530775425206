import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { actions } from './actions';
import {
  MatchOfficial,
  Player,
  TeamOfficial,
  PlayerAttributes,
} from '../../../../types';

export interface DefaultState {
  [personId: string]: {
    playerAttributes: PlayerAttributes;
    matchOfficials: Array<MatchOfficial>;
    teamOfficials: Array<TeamOfficial>;
    players: Array<Player>;
  };
}

export const defaultState: DefaultState = {};

type Payload = any;

export interface Meta {
  personId: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setPlayerAttributes.toString()]: (state, { payload, meta }) =>
      assocPath([meta.personId, 'playerAttributes'], payload, state),
    [actions.setMatchOfficialListByPerson.toString()]: (
      state,
      { payload, meta },
    ) => assocPath([meta.personId, 'matchOfficials'], payload, state),
    [actions.setTeamOfficialListByPerson.toString()]: (
      state,
      { payload, meta },
    ) => assocPath([meta.personId, 'teamOfficials'], payload, state),
    [actions.setPlayerListByPerson.toString()]: (state, { payload, meta }) =>
      assocPath([meta.personId, 'players'], payload, state),
  },
  defaultState,
);

export default reducer;
