import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Paper, Typography, Divider, Grid, Box, Button } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { isNil } from 'ramda';
import {
  ArrowBack,
  ArrowForward,
  FullScreenSpinner,
  HeaderStepper,
  HeaderStepperProps,
  SummaryBlock,
} from '@core/components';
import { State } from '@core/store';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { CustomerResponse, Group, MatchModel } from '@core/types';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import { getMatchGroupByGroupId } from '@core/store/modules/tabs/match-profile/selectors';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { actions as matchSetsActions } from '@core/store/modules/match-set';
import { getMatchSetsByMatchId } from '@core/store/modules/match-set/selectors';
import { MatchSet } from '@volleyball/types';
import { MatchDataCreateMatchDetails } from './match-data-create-match-details';
import {
  MatchDataCreateMatchSets,
  FormValues as MatchDataEntryResultsFormValues,
  initializeMatchSetsFieldArray,
} from './match-data-create-match-sets';
import { getSummaryBlockValues } from './helpers';
import * as testIds from './tests/test-ids';
import type {
  MatchDataCreateProps,
  FormValues as MatchDataEntryDetailsFormValues,
} from './shared/types';
import { ButtonWithProgress } from '@ui-components';

interface FormikState<T> {
  errors?: FormikErrors<T>;
  isValid?: boolean;
  touched?: FormikTouched<T>;
  values?: T;
}

type StepFormsValues =
  | MatchDataEntryResultsFormValues
  | MatchDataEntryDetailsFormValues;

function getFormikState<T>(formRef: React.MutableRefObject<FormikProps<any>>) {
  const { errors, isValid, touched, status, values } = formRef.current;

  return { errors, isValid, touched, status, values } as FormikState<T>;
}

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
    matchSets: typeof matchSetsActions;
    tabs: typeof tabsActions;
  };
}

interface StateProps {
  currentCustomer: CustomerResponse;
  group: Group;
  isLoading: boolean;
  isSubmitting: boolean;
  match: MatchModel;
  matchSets: Array<MatchSet>;
}

type Props = MatchDataCreateProps & DispatchProps & StateProps;

// TODO: JB: the case is not handled when create/update access rights have been lost since the tab has been opened
const MatchDataCreate: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    competitionId,
    actions,
    group,
    groupId,
    isLoading,
    isSubmitting,
    match,
    matchSets,
    matchId,
    stageId,
    tabId,
  } = props;

  const isLoadingOrInitializing = isLoading || !match || !matchSets || !group;

  useEffect(() => {
    if (isNil(match)) {
      actions.matchProfile.getMatch({ matchId });
    }
  }, [actions.matchProfile, match, matchId]);

  useEffect(() => {
    if (isNil(matchSets)) {
      actions.matchSets.getMatchSets({ matchId });
    }
  }, [actions.matchSets, matchId, matchSets]);

  useEffect(() => {
    if (isNil(group)) {
      actions.matchProfile.getMatchGroup({ matchId, groupId });
    }
  }, [actions.matchProfile, group, groupId, matchId]);

  const closeTab = () => actions.tabs.removeTab({ tabId });

  const formRef = useRef<FormikProps<any>>();

  const [matchSetsStepState, setMatchSetsStepState] =
    useState<FormikState<MatchDataEntryResultsFormValues>>(null);

  useEffect(() => {
    if (group && matchSets && !matchSetsStepState) {
      setMatchSetsStepState({
        values: initializeMatchSetsFieldArray(matchSets, group, match),
      });
    }
  }, [group, match, matchSets, matchSetsStepState]);

  const [detailsStepState, setDetailsStepState] =
    useState<FormikState<MatchDataEntryDetailsFormValues>>(null);

  useEffect(() => {
    if (match && !detailsStepState) {
      setDetailsStepState({ values: match });
    }
  }, [match, detailsStepState]);

  const [activeStep, setActiveStep] = useState(0);
  const steps: HeaderStepperProps['steps'] = [
    { title: t('Results') },
    { title: t('Details') },
  ];

  const preserveFormState = (step: -1 | 0 | 1) => {
    switch (activeStep) {
      case 0: {
        const formState =
          getFormikState<MatchDataEntryResultsFormValues>(formRef);
        setMatchSetsStepState(formState);
        setActiveStep((current) => current + step);
        break;
      }
      case 1: {
        const formState =
          getFormikState<MatchDataEntryDetailsFormValues>(formRef);
        setDetailsStepState(formState);
        setActiveStep((current) => current + step);
        break;
      }
    }
  };

  const submitForm = (values: StepFormsValues) => {
    if (activeStep !== steps.length - 1) {
      preserveFormState(1);
    } else {
      const lastStepFormState =
        getFormikState<MatchDataEntryDetailsFormValues>(formRef);

      if (lastStepFormState.isValid && matchSetsStepState.isValid) {
        preserveFormState(0);

        const matchSetsPayload = matchSetsStepState.values.map((set) => {
          const entries = Object.entries(set).map(([key, value]) => {
            if (key === 'goldenSet') {
              return [key, value];
            }

            if (key === 'duration' && !value) {
              return [key, null];
            }

            return [key, Number(value)];
          });

          return Object.fromEntries(entries);
        });

        actions.matchProfile.createMatchData({
          matchId,
          match,
          matchSetsPayload,
          matchPayload: lastStepFormState.values,
          onSuccessEffectParameters: {
            competitionId,
            stageId,
            matchId,
            modal: false,
            tabId,
          },
        });
      }
    }
  };

  const handleCancel = closeTab;

  const handlePrevious = () => preserveFormState(-1);

  const handleNext = () => formRef.current?.submitForm();

  const handleSubmit = () => formRef.current?.submitForm();

  return (
    <Fragment>
      <Paper data-qa={testIds.MATCH_DATA_ENTRY_FORM}>
        <Box p={3} mb={2}>
          <Box mb={2}>
            <Typography variant="h6">{t('Enter match data')}</Typography>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={12} md={8}>
              <SummaryBlock values={getSummaryBlockValues(props, t)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <HeaderStepper steps={steps} activeStep={activeStep} />
            </Grid>
          </Grid>
          <Box mt={3} ml={-3} mr={-3} mb={3}>
            <Divider orientation="horizontal" />
          </Box>
          {isLoadingOrInitializing && <FullScreenSpinner />}
          {!isLoadingOrInitializing &&
            matchSetsStepState?.values &&
            activeStep === 0 && (
              <MatchDataCreateMatchSets
                ref={formRef as any}
                initialValues={matchSetsStepState.values}
                initialErrors={matchSetsStepState.errors}
                initialTouched={matchSetsStepState.touched}
                onSubmit={submitForm}
                match={match}
                group={group}
              />
            )}
          {!isLoadingOrInitializing &&
            detailsStepState?.values &&
            activeStep === 1 && (
              <MatchDataCreateMatchDetails
                ref={formRef}
                initialValues={detailsStepState.values}
                initialErrors={detailsStepState.errors}
                initialTouched={detailsStepState.touched}
                onSubmit={submitForm}
              />
            )}
        </Box>
      </Paper>
      <Grid container justifyContent="flex-end">
        <Box mt={1} display="flex">
          <Box mr={1}>
            <Button
              variant="outlined"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
          </Box>
          {activeStep !== 0 && (
            <Box mr={1}>
              <Button
                variant="outlined"
                disabled={isSubmitting}
                startIcon={<ArrowBack />}
                onClick={handlePrevious}
              >
                {t('Previous')}
              </Button>
            </Box>
          )}
          {activeStep !== steps.length - 1 && (
            <Box mr={1}>
              <Button
                data-qa={testIds.MATCH_DATA_ENTRY_NEXT_BUTTON}
                disabled={isSubmitting}
                endIcon={<ArrowForward />}
                onClick={handleNext}
                variant="contained"
              >
                {t('Next')}
              </Button>
            </Box>
          )}
          {activeStep === steps.length - 1 && (
            <Box>
              <ButtonWithProgress
                variant="contained"
                disabled={isLoadingOrInitializing || isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                {t('Submit')}
              </ButtonWithProgress>
            </Box>
          )}
        </Box>
      </Grid>
    </Fragment>
  );
};

const isLoadingSelector = (groupId: number, matchId: number) =>
  createLoadingSelector([
    {
      actionName: matchProfileActions.getMatchGroupRequest.toString(),
      id: groupId,
    },
    { actionName: matchProfileActions.getMatchRequest.toString(), id: matchId },
    {
      actionName: matchSetsActions.getMatchSetsRequest.toString(),
      id: matchId,
    },
  ]);

const isSubmittingSelector = (matchId: number) =>
  createLoadingSelector([
    {
      actionName: matchProfileActions.createMatchDataRequest.toString(),
      id: matchId,
    },
  ]);

const mapStateToProps = (
  state: State,
  ownProps: MatchDataCreateProps,
): StateProps => {
  const match: MatchModel = getMatchById(state, ownProps.matchId);

  return {
    currentCustomer: getCurrentCustomer(state),
    group: getMatchGroupByGroupId(state, match?.groupId),
    isLoading: isLoadingSelector(match?.groupId, ownProps.matchId)(state),
    isSubmitting: isSubmittingSelector(ownProps.matchId)(state),
    match,
    matchSets: getMatchSetsByMatchId(state, ownProps.matchId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
    matchSets: bindActionCreators(matchSetsActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchDataCreate);
