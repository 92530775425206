import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';

import { KitSet, KitSetPatchData } from '@core/types';

export const NAMESPACE = 'editKitSet';

export const actions = createActions(
  {
    SET_KIT_SET: [prop('payload'), pick(['kitSetId'])],
  },

  'GET_KIT_SET',
  'GET_KIT_SET_REQUEST',
  'GET_KIT_SET_SUCCESS',
  'GET_KIT_SET_FAILURE',

  'UPDATE_KIT_SET',
  'UPDATE_KIT_SET_REQUEST',
  'UPDATE_KIT_SET_SUCCESS',
  'UPDATE_KIT_SET_FAILURE',

  'RESET_KIT_SET_STATE',

  { prefix: NAMESPACE },
) as unknown as {
  getKitSet: IdentityActionFunction<{ kitSetId: number }>;
  getKitSetRequest: RequestLifecycleAction<{ kitSetId: number }>;
  getKitSetSuccess: RequestLifecycleAction<{ kitSetId: number }>;
  getKitSetFailure: RequestLifecycleAction<{ kitSetId: number }>;
  setKitSet: IdentityActionMetaFunction<
    { kitSetId: number; payload: KitSet },
    { payload: KitSet },
    { kitSetId: number }
  >;

  updateKitSet: IdentityActionFunction<{
    kitSetId: number;
    data: KitSetPatchData;
    clubId: number;
    competitionId: number | null;
    tabId: string;
  }>;
  updateKitSetRequest: RequestLifecycleAction<{ kitSetId: number }>;
  updateKitSetSuccess: RequestLifecycleAction<{ kitSetId: number }>;
  updateKitSetFailure: RequestLifecycleAction<{ kitSetId: number }>;

  resetKitSetState: IdentityActionFunction<{ kitSetId: number }>;
};
