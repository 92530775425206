import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ConflictSeverityType } from '@core/types';
import { FixtureCalendarStyleProps } from './style-prop';

export default makeStyles((theme: Theme) => ({
  root: {
    position: 'static',
  },
  event: ({
    selected,
    conflictLevel,
    isCompleted,
  }: FixtureCalendarStyleProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '12.5%',
    cursor: 'pointer',

    backgroundColor: selected
      ? theme.palette.primary.dark
      : theme.palette.primary.main,
    boxShadow: selected
      ? '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
      : '0px 3px 1px -2px rgba(0,0,0,0), 0px 2px 2px 0px rgba(0,0,0,0), 0px 1px 5px 0px rgba(0,0,0,0)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
    },

    ...(ConflictSeverityType.NONE < conflictLevel
      ? {
          backgroundColor: selected
            ? theme.palette.warning.main
            : theme.palette.warning.light,
          '&:hover': {
            backgroundColor: theme.palette.warning.main,
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
          },
        }
      : {}),

    ...(isCompleted
      ? {
          backgroundColor: selected ? '#b4b4b4' : '#e0e0e0',
          '&:hover': {
            backgroundColor: '#b4b4b4',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
          },
        }
      : {}),
  }),
}));
