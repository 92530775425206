export const NAMESPACE = 'match';

export const GET_DISCIPLINARY_RULES_REQUEST = `${NAMESPACE}/GET_DISCIPLINARY_RULES_REQUEST`;
export const GET_DISCIPLINARY_RULES_SUCCESS = `${NAMESPACE}/GET_DISCIPLINARY_RULES_SUCCESS`;
export const GET_DISCIPLINARY_RULES_FAILURE = `${NAMESPACE}/GET_DISCIPLINARY_RULES_FAILURE`;

export const GET_DISCIPLINARY_RULE_REQUEST = `${NAMESPACE}/GET_DISCIPLINARY_RULE_REQUEST`;
export const GET_DISCIPLINARY_RULE_SUCCESS = `${NAMESPACE}/GET_DISCIPLINARY_RULE_SUCCESS`;
export const GET_DISCIPLINARY_RULE_FAILURE = `${NAMESPACE}/GET_DISCIPLINARY_RULE_FAILURE`;

export const CREATE_DISCIPLINARY_RULE_REQUEST = `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_REQUEST`;
export const CREATE_DISCIPLINARY_RULE_SUCCESS = `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_SUCCESS`;
export const CREATE_DISCIPLINARY_RULE_FAILURE = `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_FAILURE`;

export const UPDATE_DISCIPLINARY_RULE_REQUEST = `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_REQUEST`;
export const UPDATE_DISCIPLINARY_RULE_SUCCESS = `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_SUCCESS`;
export const UPDATE_DISCIPLINARY_RULE_FAILURE = `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_FAILURE`;

export const DELETE_DISCIPLINARY_RULE_REQUEST = `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_REQUEST`;
export const DELETE_DISCIPLINARY_RULE_SUCCESS = `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_SUCCESS`;
export const DELETE_DISCIPLINARY_RULE_FAILURE = `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_FAILURE`;
