import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import {
  Filters,
  MatchOfficialRoles,
  Paginated,
  Person,
  TeamOfficialRoles,
} from '@core/types';
import { formatPaginatedStoreWithIds } from '@core/helpers';

import { actions } from './actions';

export interface DefaultState {
  persons: Paginated<Person['id']>;
  links: any;
  query: string;
  filters: Filters;
  personsLazyLoading: Paginated<unknown>;
  teamOfficialsRoles: TeamOfficialRoles;
  matchOfficialRoles: MatchOfficialRoles;
}

export const defaultState: DefaultState = {
  persons: null,
  links: null,
  query: '',
  filters: {},
  personsLazyLoading: null,
  teamOfficialsRoles: null,
  matchOfficialRoles: null,
};

// TODO: JB: maybe use formatPaginatedStore and appendToPaginatedListFactory instead
function appendToClubsList(state: DefaultState, payload: any) {
  const currentList = state?.personsLazyLoading?.list;

  return currentList
    ? {
        list: [...state.personsLazyLoading.list, ...payload._embedded.persons],
        page: payload?.page,
        pages: payload?.pages,
        total: payload?.total,
      }
    : {
        list: [...payload._embedded.persons],
        page: payload?.page,
        pages: payload?.pages,
        total: payload?.total,
      };
}

const reducer = handleActions<DefaultState, any>(
  {
    [actions.setPersons.toString()]: (state, { payload }) => {
      const paginatedStore = formatPaginatedStoreWithIds('persons')(payload);

      return assoc('persons', paginatedStore, state) as unknown as DefaultState;
    },

    [actions.setPersonsLazyLoading.toString()]: (state, { payload }) =>
      assoc(
        'personsLazyLoading',
        appendToClubsList(state, payload),
        state,
      ) as unknown as DefaultState,

    [actions.setPersonsLinks.toString()]: (state, { payload }) =>
      assoc('links', payload, state),

    [actions.setPersonsQuery.toString()]: (state, { payload }) =>
      assoc('query', payload, state),

    [actions.setFilterParams.toString()]: (state, { payload }) =>
      assoc('filters', payload, state),

    [actions.setTeamOfficialsRoles.toString()]: (state, { payload }) =>
      assoc('teamOfficialsRoles', payload, state),

    [actions.setMatchOfficialsRoles.toString()]: (state, { payload }) =>
      assoc('matchOfficialRoles', payload, state),

    [actions.resetPersons.toString()]: () => defaultState,

    [actions.resetPersonsLazyLoading.toString()]: (state) => ({
      ...state,
      personsLazyLoading: {} as Paginated<unknown>,
    }),
  },
  defaultState,
);

export default reducer;
