import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { sortByKey } from '@core/helpers';
import matchOfficialsReducer from '@core/store/modules/match-officials/reducer';
import { NAMESPACE as matchOfficials } from '@core/store/modules/match-officials';
import competitionOrganisersReducer from '@core/store/modules/competition-organisers/reducer';
import { NAMESPACE as competitionOrganisers } from '@core/store/modules/competition-organisers/constants';
import competitionManagersReducer from '@core/store/modules/competition-managers/reducer';
import { NAMESPACE as competitionManagers } from '@core/store/modules/competition-managers/constants';
import matchesReducer from '@core/store/modules/matches/reducer';
import { NAMESPACE as matches } from '@core/store/modules/matches';
import matchesOfficialAllocationsReducer from '@core/store/modules/match-official-allocations/reducer';
import { NAMESPACE as matchOfficialAllocations } from '@core/store/modules/match-official-allocations';
import matchesAllocationReducer from '@core/store/modules/matches-allocation/reducer';
import { NAMESPACE as matchesAllocation } from '@core/store/modules/matches-allocation';
import * as rootActions from '@volleyball/store/root-reducer/actions';
import availabilitiesReducer from '../modules/availability/reducer';
import { NAMESPACE as availabilities } from '../modules/availability/constants';
import competitionTypesReducer from '../modules/competition-types/reducer';
import { NAMESPACE as competitionTypes } from '../modules/competition-types/constants';
import sportsReducer from '../modules/sports/reducer';
import { NAMESPACE as sports } from '../modules/sports/constants';
import groupStandingSettingsReducer from '../modules/group-standing-settings/reducer';
import { NAMESPACE as groupStandingSettings } from '../modules/group-standing-settings/constants';
import groupFixtureSettingsReducer from '../modules/group-fixture-settings/reducer';
import { NAMESPACE as groupFixtureSettings } from '../modules/group-fixture-settings/constants';
import { NAMESPACE as groupStandings } from '../modules/group-standings/constants';
import groupStandingsReducer from '../modules/group-standings/reducer';
import { NAMESPACE as files } from '../modules/files/constants';
import filesReducer from '../modules/files/reducer';
import TabsReducer from '../modules/tabs/reducer';
import SharedReducer from '../modules/shared/reducer';
import { NAMESPACE as competitionMatchOfficialRole } from '../modules/competition-match-official-roles';
import competitionMatchOfficialRoleReducer from '../modules/competition-match-official-roles/reducer';
import { staticPages } from '../modules/pages/reducer';
import { staticApiReducers } from '../modules/api/reducer';
import { staticModals } from '../modules/modals/reducer';
import { staticUiReducers } from '../modules/ui/reducer';
import { api } from '@core/services';
import { default as featureToggle } from '@core/pages/feature-toggle/store';
import type { RouterState } from 'redux-first-history';

const staticReducers = sortByKey({
  tabs: TabsReducer,
  shared: SharedReducer,
  [featureToggle.NAMESPACE]: featureToggle.reducers,
  [files]: filesReducer,
  [sports]: sportsReducer,
  [availabilities]: availabilitiesReducer,
  [competitionManagers]: competitionManagersReducer,
  [competitionMatchOfficialRole]: competitionMatchOfficialRoleReducer,
  [competitionOrganisers]: competitionOrganisersReducer,
  [competitionTypes]: competitionTypesReducer,
  [groupFixtureSettings]: groupFixtureSettingsReducer,
  [groupStandings]: groupStandingsReducer,
  [groupStandingSettings]: groupStandingSettingsReducer,
  [matches]: matchesReducer,
  [matchesAllocation]: matchesAllocationReducer,
  [matchOfficialAllocations]: matchesOfficialAllocationsReducer,
  [matchOfficials]: matchOfficialsReducer,
});

const createAppReducer = (
  routerReducer: Reducer<RouterState>,
  injectedReducers: any = {},
) => {
  const sortedReducers = Object.keys(injectedReducers).reduce(
    (reducers, key) => {
      if (key.includes('pages/')) {
        return {
          ...reducers,
          pages: {
            ...reducers.pages,
            [key.substr(6)]: injectedReducers[key],
          },
        };
      }
      if (key.includes('api/')) {
        return {
          ...reducers,
          api: {
            ...reducers.api,
            [key.substr(4)]: injectedReducers[key],
          },
        };
      }
      if (key.includes('modals/')) {
        return {
          ...reducers,
          modals: {
            ...reducers.modals,
            [key.substr(7)]: injectedReducers[key],
          },
        };
      }
      if (key.includes('ui/')) {
        return {
          ...reducers,
          ui: {
            ...reducers.ui,
            [key.substr(3)]: injectedReducers[key],
          },
        };
      }

      return {
        ...reducers,
        root: {
          ...reducers.root,
          [key]: injectedReducers[key],
        },
      };
    },
    {
      root: {},
      api: {},
      modals: {},
      pages: {},
      ui: {},
    },
  );

  const combinedReducers = {
    ...staticReducers,
    api: combineReducers(
      sortByKey({
        ...staticApiReducers,
        ...sortedReducers.api,
      }),
    ),
    pages: combineReducers(
      sortByKey({
        ...staticPages,
        ...sortedReducers.pages,
      }),
    ),
    modals: combineReducers(
      sortByKey({
        ...staticModals,
        ...sortedReducers.modals,
      }),
    ),
    ui: combineReducers(
      sortByKey({
        ...staticUiReducers,
        ...sortedReducers.ui,
      }),
    ),
  };

  return combineReducers({
    router: routerReducer,
    ...combinedReducers,
    ...sortedReducers.root,
    [api.reducerPath]: api.reducer,
  });
};

type AppReducer = typeof createAppReducer;

const createRootReducer =
  (routerReducer: Reducer<RouterState>, injectedReducers: unknown = {}) =>
  (
    state: Parameters<ReturnType<AppReducer>>[0],
    action: Parameters<ReturnType<AppReducer>>[1],
  ) => {
    if (action?.type === rootActions.reset.toString()) {
      state = undefined;
    }

    return createAppReducer(routerReducer, injectedReducers)(state, action);
  };

export default createRootReducer;
