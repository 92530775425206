import React from 'react';
import { Grid, IconButton, CircularProgress } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import * as testIds from './tests/test-ids';

interface OwnProps {
  isLoading?: boolean;
}

type Props = OwnProps & IconButtonProps;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  progressWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const IconButtonWithProgress = ({ isLoading, ...buttonProps }: Props) => {
  const classes = useStyles();

  const renderSpinner = () => (
    <Grid className={classes.progressWrapper}>
      <CircularProgress
        data-qa={testIds.ICON_BUTTON_WITH_PROGRESS_SPINNER}
        size={40}
      />
    </Grid>
  );

  return (
    <Grid data-qa={testIds.ICON_BUTTON_WITH_PROGRESS} className={classes.root}>
      <IconButton disabled={isLoading} {...buttonProps} />
      {isLoading && renderSpinner()}
    </Grid>
  );
};

export default IconButtonWithProgress;
