import { createAction } from 'redux-actions';

import * as SEASON_ACTION_TYPES from './constants';

export const setBeingModified = createAction(
  SEASON_ACTION_TYPES.SET_SEASON_BEING_MODIFIED,
);
export const setNewBeingCreated = createAction(
  SEASON_ACTION_TYPES.SET_NEW_SEASON_BEING_CREATED,
);
export const setErrors = createAction(SEASON_ACTION_TYPES.SET_ERRORS);
export const setCompetitionSeasons = createAction(
  SEASON_ACTION_TYPES.SET_COMPETITION_SEASONS,
);
export const setCompetitionSeasonQuery = createAction(
  SEASON_ACTION_TYPES.SET_COMPETITION_SEASON_QUERY,
);
export const searchCompetitionSeasonByQuery = createAction(
  SEASON_ACTION_TYPES.SEARCH_COMPETITION_SEASON_BY_QUERY,
);
export const resetCompetitionSeasons = createAction(
  SEASON_ACTION_TYPES.RESET_COMPETITION_SEASONS,
);

export const getCompetitionSeasons = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASONS,
);
export const getCompetitionSeasonsRequest = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASONS_REQUEST,
);
export const getCompetitionSeasonsSuccess = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASONS_SUCCESS,
);
export const getCompetitionSeasonsFailure = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASONS_FAILURE,
);

export const getCompetitionSeason = createAction<number>(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASON,
);
export const getCompetitionSeasonRequest = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASON_REQUEST,
);
export const getCompetitionSeasonSuccess = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASON_SUCCESS,
);
export const getCompetitionSeasonFailure = createAction(
  SEASON_ACTION_TYPES.GET_COMPETITION_SEASON_FAILURE,
);

export const createCompetitionSeason = createAction(
  SEASON_ACTION_TYPES.CREATE_COMPETITION_SEASON,
);
export const createCompetitionSeasonRequest = createAction(
  SEASON_ACTION_TYPES.CREATE_COMPETITION_SEASON_REQUEST,
);
export const createCompetitionSeasonSuccess = createAction(
  SEASON_ACTION_TYPES.CREATE_COMPETITION_SEASON_SUCCESS,
);
export const createCompetitionSeasonFailure = createAction(
  SEASON_ACTION_TYPES.CREATE_COMPETITION_SEASON_FAILURE,
);

export const updateCompetitionSeason = createAction(
  SEASON_ACTION_TYPES.UPDATE_COMPETITION_SEASON,
);
export const updateCompetitionSeasonRequest = createAction(
  SEASON_ACTION_TYPES.UPDATE_COMPETITION_SEASON_REQUEST,
);
export const updateCompetitionSeasonSuccess = createAction(
  SEASON_ACTION_TYPES.UPDATE_COMPETITION_SEASON_SUCCESS,
);
export const updateCompetitionSeasonFailure = createAction(
  SEASON_ACTION_TYPES.UPDATE_COMPETITION_SEASON_FAILURE,
);

export const deleteCompetitionSeason = createAction(
  SEASON_ACTION_TYPES.DELETE_COMPETITION_SEASON,
);
export const deleteCompetitionSeasonRequest = createAction(
  SEASON_ACTION_TYPES.DELETE_COMPETITION_SEASON_REQUEST,
);
export const deleteCompetitionSeasonSuccess = createAction(
  SEASON_ACTION_TYPES.DELETE_COMPETITION_SEASON_SUCCESS,
);
export const deleteCompetitionSeasonFailure = createAction(
  SEASON_ACTION_TYPES.DELETE_COMPETITION_SEASON_FAILURE,
);
