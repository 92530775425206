import { all } from 'redux-saga/effects';

import expansionPanelGroupsSagas from '@core/store/modules/ui/expansion-panel-groups/sagas';
import globalModal from './global-modal/sagas';
import filterSagas from './filter/sagas';
import usersSagas from './users/sagas';

export default function* saga() {
  yield all([
    expansionPanelGroupsSagas(),
    globalModal(),
    filterSagas(),
    usersSagas(),
  ]);
}
