import { handleActions } from 'redux-actions';
import { assoc, __ } from 'ramda';

import { formatPaginatedStoreWithIds } from '@core/helpers';
import { MatchModel, GroupCompetitor } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  venues: any;
  matchBeingModified: MatchModel;
  groupCompetitors: Array<GroupCompetitor>;
}

export const defaultState: DefaultState = {
  venues: null,
  matchBeingModified: null,
  groupCompetitors: null,
};

function appendToVenueList(state: DefaultState, payload: any) {
  const currentList = state?.venues?.list;
  const addToList = assoc('venues', __, state);

  if (!currentList || payload?.page === 1) {
    return addToList(payload);
  }

  return addToList({
    ...payload,
    list: [...currentList, ...payload.list],
  });
}

const reducer = handleActions<DefaultState, any>(
  {
    [actions.resetState.toString()]: () => defaultState,

    [actions.setVenueOptions.toString()]: (state, { payload }) =>
      appendToVenueList(state, formatPaginatedStoreWithIds('venues')(payload)),

    [actions.venuesReset.toString()]: (state) => assoc('venues', [], state),

    [actions.setGroupCompetitors.toString()]: (state, { payload }) =>
      assoc('groupCompetitors', payload, state),
  },
  defaultState,
);

export default reducer;
