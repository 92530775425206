import { useState } from 'react';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { State } from '@core/store';
import { convertCustomerDateFormatToDayjsFormat } from '@core/helpers';
import { DEFAULT_DATE_FORMAT, DATE_FORMAT } from '@core/constants/common';
import DatePickerStaticMode from './components/date-picker-static-mode';
import {
  FILTER_DATE_PICKER_INPUT,
  FILTER_DATE_PICKER_INPUT_FIELD,
  FILTER_DATE_PICKER_STATIC,
} from './tests/test-ids';

type OwnProps = {
  label: string;
  onChange: (date: string) => void;
  value?: string;
  enableKeyboardInput?: boolean;
  error?: string;
  forceFormat?: string;
};

interface StateProps {
  customerDateFormat?: string;
}

type Props = StateProps & OwnProps;

export const FilterDatePicker = (props: Props) => {
  const { label, onChange, value, error, customerDateFormat, forceFormat } =
    props;
  const [touched, setTouched] = useState(false);
  const [showStaticMode, setStaticMode] = useState(false);
  const [staticValue, setStaticDate] = useState<Dayjs>(null);
  const dateFormatServer =
    customerDateFormat || forceFormat || DEFAULT_DATE_FORMAT;
  const { format: dateFormat } =
    convertCustomerDateFormatToDayjsFormat(dateFormatServer);
  const parsedError = touched ? error : null;

  let parsedValue = null;
  if (value && typeof value === 'string') {
    parsedValue = dayjs(value, 'YYYY-MM-DD', true);
  } else if (value) {
    parsedValue = dayjs(value);
  }

  function staticDateChange(date: Dayjs) {
    if (date) {
      setStaticDate(date);
    }
  }

  function openStatic() {
    setStaticMode(true);
    value && setStaticDate(dayjs(value, dateFormat, true));
  }

  function handleStaticDateSave() {
    setStaticMode(false);

    if (staticValue) {
      onChange?.(
        staticValue.isValid()
          ? staticValue.format(DATE_FORMAT)
          : dayjs(null).format(DATE_FORMAT),
      );
    }
  }

  function handleStaticCancel() {
    setStaticMode(false);
  }

  function handleChange(date: Dayjs) {
    if (onChange) {
      const parsed = dayjs(date, dateFormat, true);

      if (date !== null) {
        setTouched(true);
      }

      if (parsed.isValid()) {
        onChange(parsed.format(DATE_FORMAT));
      } else {
        onChange(null);
      }
    }
  }

  return (
    <>
      {showStaticMode ? (
        <Grid data-qa={FILTER_DATE_PICKER_STATIC}>
          <DatePickerStaticMode
            handleCancel={handleStaticCancel}
            handleDateSave={handleStaticDateSave}
            handleChange={staticDateChange}
            value={dayjs(staticValue, dateFormat, true)}
          />
        </Grid>
      ) : (
        <Grid data-qa={FILTER_DATE_PICKER_INPUT}>
          <DatePicker
            enableAccessibleFieldDOMStructure
            slotProps={{
              textField(textFieldProps) {
                return {
                  ...textFieldProps,
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                  placeholder: dateFormat.toLocaleLowerCase(),
                  helperText: parsedError,
                  error: Boolean(parsedError),
                  inputProps: {
                    ...textFieldProps.inputProps,
                    'data-qa': FILTER_DATE_PICKER_INPUT_FIELD,
                  },
                };
              },
            }}
            onChange={handleChange}
            label={label}
            value={parsedValue}
            onOpen={openStatic}
            format={dateFormat}
          />
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  customerDateFormat: getCurrentCustomer(state).dateFormat,
});

export default connect(mapStateToProps)(FilterDatePicker);
