import { call, put, Effect, cancelled } from 'redux-saga/effects';

import * as teamsApi from '@core/api/teams';
import { QueryParamsByCompetition } from '@core/types';
import { TeamFormValues } from '@core/pages/create-team/constants/fields';

import { actions } from '.';

export function* getTeam(teamId: number): Generator<Effect, any, any> {
  yield put(actions.getTeamRequest());
  const response = yield call(teamsApi.getTeam, teamId);

  if (!response.error) {
    yield put(actions.getTeamSuccess());
  } else {
    yield put(actions.getTeamFailure());
  }

  return response;
}

export function* getTeams(
  queryParams?: QueryParamsByCompetition,
): Generator<Effect, any, any> {
  try {
    yield put(actions.getTeamsRequest());
    const response = (yield call(teamsApi.getTeams, queryParams)) as InferApi<
      typeof teamsApi.getTeams
    >;

    if (!response.error) {
      yield put(actions.getTeamsSuccess());
      yield put(actions.setTeams(response.response._embedded.teams));
    } else {
      yield put(actions.getTeamsFailure());
    }

    return response;
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.getTeamsSuccess());
    }
  }
}

export function* getTeamsForCompetition(
  queryParams: QueryParamsByCompetition,
): Generator<Effect, any, any> {
  yield put(actions.getTeamsForCompetitionRequest());
  const response = yield call(teamsApi.getTeamsForCompetition, queryParams);

  if (!response.error) {
    yield put(actions.getTeamsForCompetitionSuccess());
  } else {
    yield put(actions.getTeamsForCompetitionFailure());
  }

  return response;
}

export function* getTeamsBySport(
  sportId: number,
  searchParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getTeamsBySportRequest());
  const response = yield call(teamsApi.getTeamsBySport, sportId, searchParams);

  if (!response.error) {
    yield put(actions.getTeamsBySportSuccess());
  } else {
    yield put(actions.getTeamsBySportFailure());
  }

  return response;
}

export function* editTeam(
  teamId: number,
  teamData: TeamFormValues,
): Generator<Effect, any, any> {
  yield put(actions.editTeamRequest());
  const response = yield call(teamsApi.patchTeam, teamId, teamData);

  if (!response.error) {
    yield put(actions.editTeamSuccess());
  } else {
    yield put(actions.editTeamFailure());
  }

  return response;
}

export function* createTeam(
  clubId: number,
  teamData: TeamFormValues,
): Generator<Effect, any, any> {
  yield put(actions.createTeamRequest());
  const response = yield call(teamsApi.createTeam, clubId, teamData);

  if (!response.error) {
    yield put(actions.createTeamSuccess());
  } else {
    yield put(actions.createTeamFailure());
  }

  return response;
}

export function* getTeamsByClubId(clubId: number): Generator<Effect, any, any> {
  yield put(actions.getTeamsByClubRequest());
  const response = yield call(teamsApi.getTeamsByClub, clubId, { limit: 50 });

  if (!response.error) {
    yield put(actions.getTeamsByClubSuccess());
  } else {
    yield put(actions.getTeamsByClubFailure());
  }

  return response;
}

export function* deleteTeam(link: string): Generator<Effect, any, any> {
  yield put(actions.deleteTeamRequest());
  const response = yield call(teamsApi.deleteTeam, link);

  if (!response.error) {
    yield put(actions.deleteTeamSuccess());
  } else {
    yield put(actions.deleteTeamFailure());
  }

  return response;
}
