import { createAction } from 'redux-actions';

import * as COMPETITION_TYPES_ACTION_TYPES from './constants';

export const setCompetitionTypes = createAction(
  COMPETITION_TYPES_ACTION_TYPES.SET_COMPETITION_TYPES,
);

export const getCompetitionTypes = createAction(
  COMPETITION_TYPES_ACTION_TYPES.GET_COMPETITION_TYPES,
);
export const getCompetitionTypesRequest = createAction(
  COMPETITION_TYPES_ACTION_TYPES.GET_COMPETITION_TYPES_REQUEST,
);
export const getCompetitionTypesSuccess = createAction(
  COMPETITION_TYPES_ACTION_TYPES.GET_COMPETITION_TYPES_SUCCESS,
);
export const getCompetitionTypesFailure = createAction(
  COMPETITION_TYPES_ACTION_TYPES.GET_COMPETITION_TYPES_FAILURE,
);
