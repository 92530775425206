import { call, Effect, put } from 'redux-saga/effects';

import * as matchTeamOfficialsAPI from '@core/api/match-team-officials';

import { actions } from '.';

export function* getMatchTeamOfficials(
  matchId: number,
  competitorId: number,
): Generator<Effect, any, any> {
  yield put(actions.getMatchTeamOfficialsRequest());
  const response = yield call(
    matchTeamOfficialsAPI.getMatchTeamOfficials,
    matchId,
    competitorId,
  );

  if (!response.error) {
    yield put(actions.getMatchTeamOfficialsSuccess());
  } else {
    yield put(actions.getMatchTeamOfficialsFailure());
  }

  return response;
}

export function* changeMatchTeamOfficials(
  matchId: number,
  competitorId: number,
  data: any,
): Generator<Effect, any, any> {
  yield put(actions.changeMatchTeamOfficialsRequest());
  const response = yield call(
    matchTeamOfficialsAPI.changeMatchTeamOfficials,
    matchId,
    competitorId,
    data,
  );

  if (!response.error) {
    yield put(actions.changeMatchTeamOfficialsSuccess());
  } else {
    yield put(actions.changeMatchTeamOfficialsFailure());
  }

  return response;
}

export function* getPreviousMatchTeamOfficials(
  matchId: number,
  competitorId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPreviousMatchTeamOfficialsRequest());
  const response = yield call(
    matchTeamOfficialsAPI.getPreviousMatchTeamOfficials,
    matchId,
    competitorId,
  );

  if (!response.error) {
    yield put(actions.getPreviousMatchTeamOfficialsSuccess());
  } else {
    yield put(actions.getPreviousMatchTeamOfficialsFailure());
  }

  return response;
}
