import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ColumnWithSubValue, TableWithAssign } from '@core/components';
import { TeamOfficial, TeamOfficialList } from '@core/types';

interface OwnProps {
  addedCompetitorTeamOfficials: TeamOfficialList;
  updateTeamOfficialsList: (teamOfficials: TeamOfficialList) => void;
  isLoading?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const CompetitorTeamOfficialsTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { updateTeamOfficialsList, addedCompetitorTeamOfficials, isLoading } =
    props;

  const handleRemoveFromList = (id: number) => {
    const updatedTeamOfficials = addedCompetitorTeamOfficials?.filter(
      (official: TeamOfficial) => official.id !== id,
    );
    updateTeamOfficialsList(updatedTeamOfficials);
  };

  const columnsAdded = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: TeamOfficial) => (
        <ColumnWithSubValue
          value={
            rowData.localFirstName &&
            `${rowData.localFirstName} ${rowData.localFamilyName}`
          }
          subValue={
            rowData.firstName && `${rowData.firstName} ${rowData.familyName}`
          }
        />
      ),
    },
    {
      name: 'role',
      label: t('Role'),
      render: (rowData: any) => t(rowData?.teamOfficialRole?.title),
    },
  ];

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Added team officials')} (${
            addedCompetitorTeamOfficials?.length || 0
          })`}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            noStickyHeader
            maxHeight={496}
            isLoading={isLoading}
            columns={columnsAdded}
            data={addedCompetitorTeamOfficials}
            handleRemove={handleRemoveFromList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default CompetitorTeamOfficialsTable;
