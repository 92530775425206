import { createFilterId } from '@core/helpers';
import { FilteringOperations } from '@core/types';

export const COMPETITION_LEVEL_FILTER = {
  fieldName: 'competitionLevel',
  filteringOperation: FilteringOperations.equal,
};

export const COMPETITION_LEVEL_FILTER_ID = createFilterId(
  COMPETITION_LEVEL_FILTER,
);
