export enum FIELD_NAMES {
  venueOption = 'venueOption',
  subVenueOption = 'subVenueOption',
  homeCompetitorId = 'homeCompetitorId',
  awayCompetitorId = 'awayCompetitorId',
  number = 'number',
  groupOption = 'groupOption',
  matchDayNumber = 'matchDayNumber',
  date = 'date',
  time = 'time',
}
