import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNil } from 'ramda';

import { PlayerPositions } from '@core/types';

interface OwnProps {
  handleClose: () => void;
  handleSubmit: (selectedPosition: number) => void;
  selectedTeamOfficials: Array<number>;
  availableRoles: PlayerPositions;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SetRoleModal = (props: Props) => {
  const { selectedTeamOfficials, handleClose, handleSubmit, availableRoles } =
    props;
  const [selectedRole, setSelectedRole] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setSelectedRole(null);
  }, [selectedTeamOfficials]);

  return selectedTeamOfficials ? (
    <Dialog onClose={handleClose} open={!!selectedTeamOfficials} maxWidth="lg">
      <DialogTitle>{`${t('Set role for')} ${selectedTeamOfficials?.length} ${t(
        'team officials',
      )}`}</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          {availableRoles?.map((position) => {
            return (
              <Chip
                className={classes.chip}
                key={position.id}
                color={selectedRole === position.id ? 'primary' : 'default'}
                label={`${position.title} (${position.shortName})`}
                onClick={() => setSelectedRole(position.id)}
              />
            );
          })}
          <Chip
            className={classes.chip}
            key={0}
            label={t('Not specified')}
            color={selectedRole === 0 ? 'primary' : 'default'}
            onClick={() => setSelectedRole(0)}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={() => handleSubmit(selectedRole)}
            disabled={isNil(selectedRole)}
          >
            {t('Set')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

export default SetRoleModal;
