import React from 'react';
import {
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import FileUploadButton from '@core/components/file-upload-button';
import FullScreenSpinner from '@core/components/full-screen-spinner';

type Props = {
  title: string;
  handleFileSelect: (file: File) => void;
  isLoading?: boolean;
  setSelectedFiles: (file: Array<File>) => void;
  selectedFiles: Array<File>;
  handleClose: () => void;
  accept?: string;
  cancelLabel?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  infoContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    background: `#eeeeff`,
  },
  infoIcon: {
    color: theme.palette.primary.main,
  },
  infoTypography: {},
  infoList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  infoItem: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  infoText: {
    margin: 0,
    fontWeight: 'bold',
    color: `rgb(13, 60, 97)`,
  },
}));

const ContentDialog = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    title,
    isLoading,
    setSelectedFiles,
    selectedFiles,
    handleClose,
    accept,
    cancelLabel = t('Cancel'),
  } = props;

  const infoTexts = [
    `${t('Supported image types')}: ${t('PNG, JPG, JPEG')}`,
    `${t('Maximum image resolution')}: 5000x5000 ${t('px')}`,
    `${t('Maximum file upload size')}: 25${t('MB')}`,
  ];

  const uploadButtonComponent = (btnProps: any) => (
    <Button
      color="primary"
      variant="contained"
      disabled={isLoading}
      {...btnProps}
    >
      {t('Select image')}
    </Button>
  );

  const infoTextComponent = (text: string) => (
    <ListItem key={text} className={classes.infoItem}>
      <ListItemText
        primaryTypographyProps={{ className: classes.infoText }}
        className={classes.infoText}
        primary={text}
      />
    </ListItem>
  );

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <DialogContent>
          <Box
            display="flex"
            alignContent="flex-start"
            alignItems="flex-start"
            className={classes.infoContainer}
          >
            <Box>
              <InfoOutlinedIcon className={classes.infoIcon} />
            </Box>
            <Box className={classes.infoTypography}>
              <List className={classes.infoList}>
                {infoTexts.map((text) => infoTextComponent(text))}
              </List>
            </Box>
          </Box>
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleClose} disabled={isLoading}>
              {cancelLabel}
            </Button>
          </Box>
          <FileUploadButton
            buttonComponent={uploadButtonComponent}
            files={selectedFiles}
            setSelectedFile={setSelectedFiles}
            accept={accept}
            withSelectedFileNaming={false}
          />
        </Box>
      </DialogActions>
    </>
  );
};

export default ContentDialog;
