import { createAction } from 'redux-actions';

import * as COMPETITION_MANAGERS_ACTION_TYPES from './constants';

export const setCompetitionManagers = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.SET_COMPETITION_MANAGERS,
);

export const getCompetitionManagers = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_COMPETITION_MANAGERS,
);
export const getCompetitionManagersRequest = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_COMPETITION_MANAGERS_REQUEST,
);
export const getCompetitionManagersSuccess = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_COMPETITION_MANAGERS_SUCCESS,
);
export const getCompetitionManagersFailure = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_COMPETITION_MANAGERS_FAILURE,
);

export const setCurrentCompetitionManager = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.SET_CURRENT_COMPETITION_MANAGER,
);

export const getCurrentCompetitionManager = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_CURRENT_COMPETITION_MANAGER,
);
export const getCurrentCompetitionManagerRequest = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_CURRENT_COMPETITION_MANAGER_REQUEST,
);
export const getCurrentCompetitionManagerSuccess = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_CURRENT_COMPETITION_MANAGER_SUCCESS,
);
export const getCurrentCompetitionManagerFailure = createAction(
  COMPETITION_MANAGERS_ACTION_TYPES.GET_CURRENT_COMPETITION_MANAGER_FAILURE,
);
