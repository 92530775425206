import React from 'react';
import { Typography, Box, alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export interface AlertStatusProps {
  title: string;
  severity?: number;
}

const getColorBySeverity = (theme: Theme, severity?: number) => {
  switch (severity) {
    case 2:
      return theme.palette.warning.dark;
    case 3:
      return theme.palette.error.main;
    default:
      return alpha(theme.palette.warning.light, 0.15);
  }
};

const useStyles = makeStyles<Theme, AlertStatusProps>((theme: Theme) => {
  return {
    root: {
      backgroundColor: ({ severity }) => getColorBySeverity(theme, severity),
      width: 'max-content',
    },
    title: {
      color: ({ severity }) =>
        theme.palette.getContrastText(getColorBySeverity(theme, severity)),
      lineHeight: '16px',
    },
    icon: {
      display: 'flex',
      width: 15,
      height: 15,
      color: ({ severity }) =>
        theme.palette.getContrastText(getColorBySeverity(theme, severity)),
      marginRight: 4,
    },
  };
});

const AlertStatus = ({ title, severity }: AlertStatusProps) => {
  const classes = useStyles({ title, severity });

  return (
    <Box
      display="flex"
      borderRadius={5}
      marginTop="4px"
      padding="4px"
      className={classes.root}
    >
      {severity ? (
        <ReportProblemIcon className={classes.icon} />
      ) : (
        <ReportProblemOutlinedIcon className={classes.icon} />
      )}
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default AlertStatus;
