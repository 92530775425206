import { createAction } from 'redux-actions';

import * as actionTypes from './constants';

const getDisciplinaryRulesRequest = createAction(
  actionTypes.GET_DISCIPLINARY_RULES_REQUEST,
);
const getDisciplinaryRulesSuccess = createAction<void>(
  actionTypes.GET_DISCIPLINARY_RULES_SUCCESS,
);
const getDisciplinaryRulesFailure = createAction<void>(
  actionTypes.GET_DISCIPLINARY_RULES_FAILURE,
);

const getDisciplinaryRuleRequest = createAction(
  actionTypes.GET_DISCIPLINARY_RULE_REQUEST,
);
const getDisciplinaryRuleSuccess = createAction<void>(
  actionTypes.GET_DISCIPLINARY_RULE_SUCCESS,
);
const getDisciplinaryRuleFailure = createAction<void>(
  actionTypes.GET_DISCIPLINARY_RULE_FAILURE,
);

const createDisciplinaryRuleRequest = createAction(
  actionTypes.CREATE_DISCIPLINARY_RULE_REQUEST,
);
const createDisciplinaryRuleSuccess = createAction<void>(
  actionTypes.CREATE_DISCIPLINARY_RULE_SUCCESS,
);
const createDisciplinaryRuleFailure = createAction<void>(
  actionTypes.CREATE_DISCIPLINARY_RULE_FAILURE,
);

const updateDisciplinaryRuleRequest = createAction(
  actionTypes.UPDATE_DISCIPLINARY_RULE_REQUEST,
);
const updateDisciplinaryRuleSuccess = createAction<void>(
  actionTypes.UPDATE_DISCIPLINARY_RULE_SUCCESS,
);
const updateDisciplinaryRuleFailure = createAction<void>(
  actionTypes.UPDATE_DISCIPLINARY_RULE_FAILURE,
);

const deleteDisciplinaryRuleRequest = createAction(
  actionTypes.DELETE_DISCIPLINARY_RULE_REQUEST,
);
const deleteDisciplinaryRuleSuccess = createAction<void>(
  actionTypes.DELETE_DISCIPLINARY_RULE_SUCCESS,
);
const deleteDisciplinaryRuleFailure = createAction<void>(
  actionTypes.DELETE_DISCIPLINARY_RULE_FAILURE,
);

export default {
  getDisciplinaryRulesRequest,
  getDisciplinaryRulesSuccess,
  getDisciplinaryRulesFailure,

  getDisciplinaryRuleRequest,
  getDisciplinaryRuleSuccess,
  getDisciplinaryRuleFailure,

  createDisciplinaryRuleRequest,
  createDisciplinaryRuleSuccess,
  createDisciplinaryRuleFailure,

  updateDisciplinaryRuleRequest,
  updateDisciplinaryRuleSuccess,
  updateDisciplinaryRuleFailure,

  deleteDisciplinaryRuleRequest,
  deleteDisciplinaryRuleSuccess,
  deleteDisciplinaryRuleFailure,
};
