import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import {
  ContentBlock,
  ValueWithSubvalue,
  NamedValueColumn,
} from '@core/components';
import { VenueModel } from '@core/types';

interface OwnProps {
  alternativeVenue: VenueModel;
  defaultVenue: VenueModel;
}

type Props = OwnProps;

const TeamVenuesBlock = (props: Props) => {
  const { alternativeVenue, defaultVenue } = props;
  const { t } = useTranslation();

  const valueWithInternationalName = (
    name: string,
    internationalName: string,
  ) => <ValueWithSubvalue value={name} subvalue={internationalName} />;

  return (
    <ContentBlock title={t('Team venues')}>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('Default venue')}
          value={valueWithInternationalName(
            defaultVenue?.title || '-',
            defaultVenue?.internationalTitle,
          )}
        />
      </Grid>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('Alternate venue')}
          value={valueWithInternationalName(
            alternativeVenue?.title || '-',
            alternativeVenue?.internationalTitle,
          )}
        />
      </Grid>
    </ContentBlock>
  );
};

export default TeamVenuesBlock;
