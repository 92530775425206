import AssignedManagers from '@core/business-components/assigned-managers';
import ContactsList from '@core/business-components/contacts';
import type { CompetitionResponse } from '@core/types';

type Props = {
  competition: CompetitionResponse;
};

const ContactsAndManagers = ({ competition }: Props) => (
  <>
    <ContactsList competition={competition} />
    <AssignedManagers competition={competition} />
  </>
);

export default ContactsAndManagers;
