import {
  take,
  call,
  put,
  all,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';
import download from 'downloadjs';
import { path } from 'ramda';

import { getTeamOfficialRole } from '@core/store/modules/api/team-official-role/sagas';
import { handleApiErrors, replaceEmptyToNull } from '@core/helpers';
import { getCompetitorPlayerReport } from '@core/api/competitor-players';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { getCompetitorByTeamId } from '@core/store/modules/api/competitors/sagas';
import { getKitSet } from '@core/store/modules/api/kit-set/sagas';
import {
  updateCompetitorPlayer,
  getCompetitorPlayers,
} from '@core/store/modules/api/competitor-players/sagas';
import {
  getCompetitorTeamOfficials,
  updateCompetitorTeamOfficial,
} from '@core/store/modules/api/competitor-team-officials/sagas';
import { getTeam } from '@core/store/modules/api/team/sagas';
import { getPlayerPositionBySport } from '@core/store/modules/api/player-positions/sagas';

import { actions } from '.';

export function* getPlayerPositionsFlow() {
  yield takeLeadingByPredicate(actions.getPlayerPositions, function* (action) {
    const {
      payload: { sportId, competitorId },
    } = action;

    yield put(actions.getPlayerPositionsRequest({ sportId, competitorId }));
    const { response, error } = yield call(getPlayerPositionBySport, sportId);

    if (!error) {
      yield put(actions.getPlayerPositionsSuccess({ sportId, competitorId }));
      yield put(
        actions.setPlayerPositions({
          payload: path(['_embedded', 'playerPositions'], response),
          competitorId,
        }),
      );
    } else {
      yield put(actions.getPlayerPositionsFailure({ sportId, competitorId }));
      yield call(handleApiErrors, error);
    }
  });
}

export function* updateCompetitorPlayerFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { data, teamId, competitionId, playerId, competitorId },
    } = yield take(actions.updateCompetitorPlayer);

    yield put(actions.updateCompetitorPlayerRequest());
    const { error } = (yield call(updateCompetitorPlayer, {
      data: replaceEmptyToNull(data),
      teamId,
      competitionId,
      competitorId,
      playerId,
    })) as SagaReturnType<typeof updateCompetitorPlayer>;

    if (!error) {
      yield put(actions.updateCompetitorPlayerSuccess());
      yield put(
        actions.getCompetitorPlayerList({
          competitionId,
          teamId,
          competitorId,
        }),
      );
    } else {
      yield put(actions.updateCompetitorPlayerFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getTeamOfficialListFlow() {
  yield takeLeadingByPredicate(actions.getTeamOfficials, function* (action) {
    const {
      payload: { competitionId, teamId, competitorId },
    } = action;

    yield put(
      actions.getTeamOfficialsRequest({ competitionId, teamId, competitorId }),
    );
    const { error, response } = yield call(
      getCompetitorTeamOfficials,
      competitionId,
      teamId,
    );

    if (!error) {
      yield put(
        actions.getTeamOfficialsSuccess({
          competitionId,
          teamId,
          competitorId,
        }),
      );
      yield put(
        actions.setTeamOfficials({
          payload: path(['_embedded', 'competitorTeamOfficials'], response),
          competitorId,
        }),
      );
    } else {
      yield put(
        actions.getTeamOfficialsFailure({
          competitionId,
          teamId,
          competitorId,
        }),
      );
      yield call(handleApiErrors, error);
    }
  });
}

export function* getTeamOfficialsRolesFlow() {
  yield takeLeadingByPredicate(
    actions.getTeamOfficialsRoles,
    function* (action) {
      const { payload } = action;
      const { competitorId } = payload;

      yield put(actions.getTeamOfficialsRolesRequest({ competitorId }));
      const { error, response } = yield call(getTeamOfficialRole);

      if (!error) {
        const teamOfficialRoles = path<any>(
          ['_embedded', 'teamOfficialRoles'],
          response,
        );

        yield put(actions.getTeamOfficialsRolesSuccess({ competitorId }));
        yield put(
          actions.setTeamOfficialsRoles({
            payload: teamOfficialRoles,
            competitorId,
          }),
        );
      } else {
        yield put(actions.getTeamOfficialsRolesFailure({ competitorId }));
        yield call(handleApiErrors, error);
      }
    },
  );
}

export function* getCompetitorPlayerListFlow() {
  yield takeLeadingByPredicate(
    actions.getCompetitorPlayerList,
    function* (action): Generator<Effect, any, any> {
      const {
        payload: { competitionId, competitorId, teamId },
      } = action;

      yield put(
        actions.getCompetitorPlayerListRequest({
          competitionId,
          competitorId,
          teamId,
        }),
      );
      const { error, response } = (yield call(
        getCompetitorPlayers,
        competitionId,
        teamId,
      )) as SagaReturnType<typeof getCompetitorPlayers>;

      if (!error) {
        yield put(
          actions.getCompetitorPlayerListSuccess({
            competitionId,
            competitorId,
            teamId,
          }),
        );
        yield put(
          actions.setCompetitorPlayerList({
            competitorId,
            payload: path(['_embedded', 'competitorPlayers'], response),
          }),
        );
      } else {
        yield put(
          actions.getCompetitorPlayerListFailure({
            competitionId,
            competitorId,
            teamId,
          }),
        );
        yield call(handleApiErrors, error);
      }
    },
  );
}

export function* updateCompetitorTeamOfficialFlow() {
  while (true) {
    const { payload } = yield take(actions.updateTeamOfficial);
    const { competitionId, teamId, officialId, data, competitorId } = payload;

    yield put(actions.updateTeamOfficialRequest());

    const { error } = yield call(updateCompetitorTeamOfficial, {
      competitionId,
      teamId,
      officialId,
      data,
    });

    if (!error) {
      yield put(actions.updateTeamOfficialSuccess());
      yield put(
        actions.getTeamOfficials({
          competitionId,
          teamId,
          competitorId,
        }),
      );
    } else {
      yield put(actions.updateTeamOfficialFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getCompetitorKitSetFlow() {
  yield takeLeadingByPredicate(actions.getCompetitorKitSet, function* (action) {
    const { payload } = action;
    const { kitSetId, competitorId } = payload;

    yield put(actions.getCompetitorKitSetRequest({ kitSetId, competitorId }));

    const { error, response } = (yield call(getKitSet, {
      kitSetId,
    })) as SagaReturnType<typeof getKitSet>;

    if (!error) {
      yield put(actions.getCompetitorKitSetSuccess({ kitSetId, competitorId }));
      yield put(actions.setKitSet({ payload: response, competitorId }));
    } else {
      yield put(actions.getCompetitorKitSetFailure({ kitSetId, competitorId }));
      yield call(handleApiErrors, error);
    }
  });
}

export function* getCompetitorByTeamIdFlow(): any {
  yield takeLeadingByPredicate(actions.getCompetitorByTeam, function* (action) {
    const {
      payload: { teamId, competitionId, competitorId },
    } = action;

    yield put(
      actions.getCompetitorByTeamRequest({
        teamId,
        competitionId,
        competitorId,
      }),
    );

    const [competitor, team] = yield all([
      call(getCompetitorByTeamId, { teamId, competitionId }),
      call(getTeam, teamId),
    ]);

    if (!competitor.error && !team.error) {
      yield put(
        actions.setCompetitorByTeam({
          payload: { competitor: competitor.response.id, team: team.response },
          competitorId,
        }),
      );

      yield put(
        actions.getCompetitorByTeamSuccess({
          teamId,
          competitionId,
          competitorId,
        }),
      );
    } else {
      yield put(
        actions.getCompetitorByTeamFailure({
          teamId,
          competitionId,
          competitorId,
        }),
      );

      if (competitor.error) {
        yield call(handleApiErrors, competitor.error);
      }

      if (team.error) {
        yield call(handleApiErrors, team.error);
      }
    }
  });
}

export function* getCompetitorPlayerReportFlow() {
  while (true) {
    const { payload } = yield take(actions.getCompetitorPlayerReport);

    yield put(actions.getCompetitorPlayerReportRequest());
    const { competitionId, teamId } = payload;

    const { error, response } = yield call(
      getCompetitorPlayerReport,
      competitionId,
      teamId,
    );

    if (!error) {
      yield call(
        download,
        response,
        `competitor_list.csv`, // was is ze name?
        response.type,
      );
      yield put(actions.getCompetitorPlayerReportSuccess());
    } else {
      yield put(actions.getCompetitorPlayerReportFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    getPlayerPositionsFlow(),
    updateCompetitorPlayerFlow(),
    getTeamOfficialListFlow(),
    getTeamOfficialsRolesFlow(),
    getCompetitorPlayerListFlow(),
    updateCompetitorTeamOfficialFlow(),
    getCompetitorKitSetFlow(),
    getCompetitorByTeamIdFlow(),
    getCompetitorPlayerReportFlow(),
  ]);
}
