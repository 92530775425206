import React from 'react';
import { uuid } from '@core/helpers';
import deepEqual from 'fast-deep-equal/react';
import { FastField, FieldProps, FieldInputProps } from 'formik';
import { none, isNil } from 'ramda';
import {
  Box,
  Typography,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ErrorMessage, Tooltip } from '@core/components';

interface StyleProps {
  width?: string;
  tooltipText?: string;
  withTooltipMargin?: boolean;
}

interface OwnProps {
  tooltipText?: string;
  valueText?: string;
  width?: string;
  withTooltipMargin?: boolean;
  smallInput?: boolean;
  absoluteErrorPosition?: boolean;
  forceError?: boolean;
  summaryLabel?: string;
  maxCounterValue?: number;
}

export type TextFieldProps = Omit<
  MuiTextFieldProps,
  keyof Omit<FieldInputProps<any>, 'onChange' | 'name'> | 'variant'
> &
  OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: ({ width }: StyleProps) => width || '100%',
    backgroundColor: theme.palette.common.white,
    marginRight: ({ tooltipText, withTooltipMargin }: StyleProps) => {
      const tooltipIconWith = 34;
      const marginWithoutTooltipText = withTooltipMargin ? tooltipIconWith : 0;

      return tooltipText ? theme.spacing(1) : marginWithoutTooltipText;
    },
  },
  smallInput: {
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
  },
  fieldPadding: {
    padding: 0,
  },
}));

const TextField = (props: TextFieldProps) => {
  const {
    name,
    onChange,
    width,
    tooltipText,
    withTooltipMargin,
    smallInput,
    absoluteErrorPosition,
    forceError,
    maxCounterValue,
    ...restTextFieldProps
  } = props;
  const classes = useStyles({ width, tooltipText, withTooltipMargin });

  // force FastField to re-render on NumberField props change otherwise it will not re-render (even if it's render prop reference is different)
  const key = uuid();

  return (
    <FastField key={key} name={name}>
      {({ field, meta: { error, touched } }: FieldProps) => {
        const shouldShowFieldError = forceError
          ? none(isNil, [error])
          : none(isNil, [error, touched || null]);

        const getValue = () => (!isNil(field.value) ? field.value : '');

        function handleOnChange(event: React.ChangeEvent<any>) {
          onChange && onChange(event);
          field.onChange(event);
        }

        return (
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <MuiTextField
                {...restTextFieldProps}
                name={name}
                value={getValue()}
                error={shouldShowFieldError}
                onChange={handleOnChange}
                onBlur={field.onBlur}
                variant="outlined"
                className={clsx(classes.root, {
                  [classes.smallInput]: smallInput,
                })}
              />
              {tooltipText && <Tooltip title={tooltipText} />}
            </Box>
            <Box display="flex">
              {maxCounterValue && (
                <Box ml={1}>
                  <Typography
                    color={shouldShowFieldError ? 'error' : 'inherit'}
                    sx={{ mt: 0.375, fontSize: '0.75rem', lineHeight: 1.66 }}
                  >
                    {`${field?.value?.length}/${maxCounterValue}`}
                  </Typography>
                </Box>
              )}
              <Box ml={absoluteErrorPosition ? 0 : 1}>
                <ErrorMessage
                  name={name}
                  absolutePosition={absoluteErrorPosition}
                />
              </Box>
            </Box>
          </Box>
        );
      }}
    </FastField>
  );
};

export default React.memo(TextField, deepEqual);
