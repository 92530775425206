import { all, take, put, call } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as featureToggleApi from '@core/api/feature-toggle';

import actions from './actions';

export function* getFeatureToggles() {
  while (true) {
    yield take(actions.getFeatureToggles);

    yield put(actions.getFeatureTogglesRequest());

    const { error, response } = yield call(featureToggleApi.getFeatureToggles);

    if (!error) {
      yield put(actions.getFeatureTogglesSuccess());
      yield put(
        actions.setFeatureToggles({
          payload: response._embedded.featureToggles,
        }),
      );
    } else {
      yield put(actions.getFeatureTogglesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* changeFeatureToggleStatus() {
  while (true) {
    const {
      payload: { name, status },
    } = yield take(actions.changeFeatureToggleStatus);

    yield put(actions.changeFeatureToggleStatusRequest());
    const { error } = yield call(
      featureToggleApi.changeFeatureToggleStatus,
      name,
      !status,
    );

    if (!error) {
      yield put(actions.getFeatureToggles());
      yield put(actions.changeFeatureToggleStatusSuccess());
    } else {
      yield put(actions.changeFeatureToggleStatusFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([getFeatureToggles(), changeFeatureToggleStatus()]);
}
