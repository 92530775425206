import React from 'react';
import { Grid } from '@mui/material';

import { TeamModel } from '@core/types';

import GeneralInformationForm from './general-information-form';
import * as testIds from '../../tests/tests-ids';

interface OwnProps {
  competitionId: number;
  selectedTeam: TeamModel;
}

type Props = OwnProps;

const GeneralInformationPanel = (props: Props) => {
  const { competitionId, selectedTeam } = props;

  return (
    <Grid data-qa={testIds.COMPETITOR_ASSIGNMENT_GENERAL_INFORMATION_PANEL}>
      <GeneralInformationForm
        selectedTeam={selectedTeam}
        competitionId={competitionId}
      />
    </Grid>
  );
};

export default GeneralInformationPanel;
