import { LanguageDirections } from '@core/types';
import { Column } from '@core/components/material-table';

const applyActionsCellStyles =
  (isSmallScreen: boolean, languageDirection: LanguageDirections) =>
  (column: Column<any>): Column<any> => ({
    ...column,
    ...(column?.field === 'actions'
      ? {
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: isSmallScreen ? 4 : 12,
            paddingLeft: languageDirection === LanguageDirections.RTL && 12,
            textAlign:
              languageDirection === LanguageDirections.RTL ? 'left' : 'right',
            ...column?.cellStyle,
          },
        }
      : {}),
  });

export default applyActionsCellStyles;
