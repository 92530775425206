import type { ReactNode } from 'react';
import {
  Theme,
  Box,
  IconButton,
  Drawer as MuiDrawer,
  type DrawerProps as MuiDrawerProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    '&': {
      '@media (min-width: 960px)': { width: 600 },
      '@media (min-width: 1060px)': { width: 700 },
      '@media (min-width: 1160px)': { width: 900 },
    },
  },
  closeIconWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

type Props = {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  withCloseIcon?: boolean;
  disableBackdropClick?: boolean;
  onClose?: () => void;
};

const Drawer = ({
  children,
  isOpen,
  className,
  withCloseIcon,
  disableBackdropClick,
  onClose,
}: Props) => {
  const classes = useStyles();

  const handleClose: MuiDrawerProps['onClose'] = (_event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }

    onClose?.();
  };

  return (
    <MuiDrawer open={isOpen} onClose={handleClose} anchor="right">
      <Box className={clsx(classes.drawer, className)} data-qa="drawer">
        {withCloseIcon && (
          <Box className={classes.closeIconWrapper}>
            <IconButton onClick={onClose} data-qa="drawer-close-button">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
