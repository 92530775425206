import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import teamCreationActions from '@core/pages/create-team/store/actions';
import { actions as competitorActions } from '@core/store/modules/tabs/competitor-addition';
import { actions as editKitSetActions } from '@core/store/modules/tabs/edit-kit-set';
import { State } from '@core/store';
import { TeamModel } from '@core/types';

import * as testIds from '../tests/tests-ids';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  competitionId: number;
  tabId: string;
  selectedTeam: TeamModel;
}

interface StateProps {
  isSubmitting: boolean;
}

interface DispatchProps {
  actions: {
    tab: typeof tabActions;
    competitor: typeof competitorActions;
    teamCreation: typeof teamCreationActions;
    editKitSet: typeof editKitSetActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

export const CompetitorAssignmentActions = (props: Props) => {
  const { tabId, actions, isSubmitting, competitionId, selectedTeam } = props;
  const { t } = useTranslation();

  const handleCancel = () => {
    actions.competitor.clearCompetitorState({ competitionId });
    actions.teamCreation.clearState();
    actions.editKitSet.resetKitSetState({ kitSetId: null });
    actions.tab.removeTab({ tabId });
  };

  const isSubmitDisabled = !selectedTeam?.club?.title;

  return (
    <Box mt={3} pb={2} display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          color="primary"
          disabled={isSubmitting}
        >
          {t('Cancel')}
        </Button>
      </Box>
      <ButtonWithProgress
        type="submit"
        variant="contained"
        color="primary"
        data-qa={testIds.COMPETITOR_ASSIGNMENT_SAVE_COMPETITOR_BUTTON}
        isLoading={isSubmitting}
        disabled={isSubmitDisabled || isSubmitting}
      >
        {t('Save')}
      </ButtonWithProgress>
    </Box>
  );
};

const isSubmittingSelector = createLoadingSelector([
  competitorActions.createCompetitorRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isSubmitting: isSubmittingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tab: bindActionCreators(tabActions, dispatch),
    competitor: bindActionCreators(competitorActions, dispatch),
    teamCreation: bindActionCreators(teamCreationActions, dispatch),
    editKitSet: bindActionCreators(editKitSetActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitorAssignmentActions);
