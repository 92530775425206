import React, { useMemo } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

import { Column } from '@core/components/material-table';

import LoadingIndicator from './loading-indicator';

export interface TreeColumn<T extends object> extends Column<T> {
  columns?: Array<TreeColumn<T>>;
  colSpan?: number;
  rowSpan?: number;
}

const getRowsOfColumnsGroupedByDepth = (columns: Array<TreeColumn<any>>) => {
  const traverse = (
    currentLevel: Array<TreeColumn<any>>,
    currentLevels: Array<Array<TreeColumn<any>>>,
  ): Array<Array<TreeColumn<any>>> => {
    const nextLevel = currentLevel.flatMap((column) => column.columns ?? []);

    return nextLevel.length > 0
      ? traverse(nextLevel, [...currentLevels, nextLevel])
      : currentLevels;
  };

  return traverse(columns, [columns]);
};

const getLeafColumns = (
  columns: Array<TreeColumn<any>>,
  prev: Array<TreeColumn<any>> = [],
): Array<TreeColumn<any>> => {
  return columns.reduce((_prev, curr) => {
    return curr.columns
      ? getLeafColumns(curr.columns, _prev)
      : (_prev.push(curr), _prev);
  }, prev);
};

export function Header(props: any) {
  const { columns, isLoading } = props;

  const headerColumns = useMemo(
    () => getRowsOfColumnsGroupedByDepth(columns),
    [columns],
  );
  const dataColumns = useMemo(() => getLeafColumns(columns), [columns]);

  const theme = useTheme();

  return (
    <>
      <TableHead>
        {headerColumns.map((row, rowIndex) => (
          <TableRow
            key={rowIndex}
            sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}
          >
            {row.map((column, columnIndex) => (
              <TableCell
                key={columnIndex}
                colSpan={column.colSpan}
                rowSpan={column.rowSpan}
                sx={{ padding: theme.spacing(1) }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <LoadingIndicator colSpan={dataColumns.length} isLoading={isLoading} />
    </>
  );
}

export function Body(props: any) {
  const { columns, localization, onRowClick, renderData } = props;

  const dataColumns = useMemo(() => getLeafColumns(columns), [columns]);

  const data = renderData as Array<any>;

  return (
    <TableBody>
      {data?.length > 0 ? (
        data.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            onClick={
              onRowClick ? (event) => onRowClick(event, rowData) : undefined
            }
            style={{ cursor: onRowClick ? 'pointer' : undefined }}
          >
            {dataColumns.map((column, columnIndex) => (
              <TableCell
                key={columnIndex}
                style={column.width ? { width: column.width } : null}
              >
                {column.render
                  ? column.render(rowData, 'row')
                  : rowData[column.field]}
              </TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={dataColumns.length} align="center">
            {localization.emptyDataSourceMessage}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}
