import { createActions } from 'redux-actions';

export const NAMESPACE = 'teamOfficials';

export const actions = createActions(
  'GET_CLUB_TEAM_OFFICIALS_BY_SPORT_REQUEST',
  'GET_CLUB_TEAM_OFFICIALS_BY_SPORT_SUCCESS',
  'GET_CLUB_TEAM_OFFICIALS_BY_SPORT_FAILURE',

  'CREATE_TEAM_OFFICIAL_REQUEST',
  'CREATE_TEAM_OFFICIAL_SUCCESS',
  'CREATE_TEAM_OFFICIAL_FAILURE',

  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_REQUEST',
  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_SUCCESS',
  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_FAILURE',

  'DELETE_TEAM_OFFICIAL_REQUEST',
  'DELETE_TEAM_OFFICIAL_SUCCESS',
  'DELETE_TEAM_OFFICIAL_FAILURE',

  { prefix: NAMESPACE },
) as unknown as {
  getClubTeamOfficialsBySportRequest: RequestLifecycleAction;
  getClubTeamOfficialsBySportSuccess: RequestLifecycleAction;
  getClubTeamOfficialsBySportFailure: RequestLifecycleAction;
  createTeamOfficialRequest: RequestLifecycleAction;
  createTeamOfficialSuccess: RequestLifecycleAction;
  createTeamOfficialFailure: RequestLifecycleAction;
  getTeamOfficialListByPersonRequest: RequestLifecycleAction;
  getTeamOfficialListByPersonSuccess: RequestLifecycleAction;
  getTeamOfficialListByPersonFailure: RequestLifecycleAction;
  deleteTeamOfficialRequest: RequestLifecycleAction;
  deleteTeamOfficialSuccess: RequestLifecycleAction;
  deleteTeamOfficialFailure: RequestLifecycleAction;
};
