import { Grid, Box, Typography } from '@mui/material';
import React from 'react';

import { Competitor } from '@core/types';

type Props = {
  competitor: Competitor;
};

const TeamCardSummary = ({ competitor }: Props) => (
  <Grid container>
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle2">{competitor.title}</Typography>
    </Grid>
    <Box
      component={Grid}
      item
      xs={12}
      md={6}
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      <Typography variant="subtitle2">
        {competitor.defaultVenue?.title}
      </Typography>
    </Box>
    <Grid item xs={12} md={6}>
      <Typography variant="caption" color="textSecondary">
        {competitor.internationalTitle}
      </Typography>
    </Grid>
    <Box
      component={Grid}
      item
      xs={12}
      md={6}
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      <Typography variant="caption" color="textSecondary">
        {competitor.defaultVenue?.internationalTitle}
      </Typography>
    </Box>
  </Grid>
);

export default TeamCardSummary;
