import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  AddedLineupPlayerErrors,
  MatchPlayerOrigin,
  Sports,
} from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';

import AddedLineupTable from './added-lineup-table';
import AvailablePlayersTable from './available-players-table';

interface OwnProps {
  matchId: number;
  competitionId: number;
  teamId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  sport: keyof typeof Sports;
  tabId: string;
}

interface StateProps {
  lineupDataErrors: AddedLineupPlayerErrors;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

const StartingLineupTab = (props: Props) => {
  const {
    matchId,
    competitionId,
    teamId,
    matchPlayerOrigin,
    sport,
    tabId,
    actions,
  } = props;

  useEffect(() => {
    teamId &&
      competitionId &&
      actions.matchSheetManagement.searchCompetitorPlayers({
        matchId,
        teamId,
        competitionId,
        matchPlayerOrigin,
      });
  }, [teamId, competitionId]);

  return (
    <Grid container spacing={3}>
      <AvailablePlayersTable
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        tabId={tabId}
        teamId={teamId}
        competitionId={competitionId}
      />
      <AddedLineupTable
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        sport={sport}
        tabId={tabId}
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(null, mapDispatchToProps)(StartingLineupTab);
