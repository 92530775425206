import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Button } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DOWNLOAD_PDF_BUTTON } from '../__tests__/test-ids';
import { useGetBracketsQuery } from '@core/services/groups';
import { CompetitionResponse, Group } from '@core/types';
import { calculateMaxRound } from './utils';

type Props = {
  stage: Group;
  competition: CompetitionResponse;
  bracketsRef: React.RefObject<HTMLDivElement>;
};

const KnockoutBracketsDownload = ({
  stage,
  competition,
  bracketsRef,
}: Props) => {
  const { t } = useTranslation();

  const { data } = useGetBracketsQuery(stage.id);

  const handleExportPDF = () => {
    html2canvas(bracketsRef.current, {
      scale: 3,
      useCORS: true,
    }).then((canvas) => {
      const maxRound = calculateMaxRound(data.items);
      const margin = 10;
      const img = canvas.toDataURL('image/jpeg', 2);
      const doc = new jsPDF(maxRound > 5 ? 'p' : 'l', 'mm', 'a4');
      const maxWidth = doc.internal.pageSize.getWidth() - 2 * margin;
      const maxHeight = doc.internal.pageSize.getHeight() - 2 * margin;
      const imgProps = doc.getImageProperties(img);

      let imgWidth = maxWidth;
      let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      if (imgHeight > maxHeight) {
        imgHeight = maxHeight;
        imgWidth = (imgProps.width * imgHeight) / imgProps.height;
      }

      doc.addImage(img, 'JPEG', margin, margin, imgWidth, imgHeight);
      doc.save(`${competition.title}_${stage.title}.pdf`);
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={handleExportPDF}
      startIcon={<PictureAsPdf />}
      data-qa={DOWNLOAD_PDF_BUTTON}
    >
      {t('Download')}
    </Button>
  );
};

export default KnockoutBracketsDownload;
