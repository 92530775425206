import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SearchableFilterList } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { Paginated, FilterTabProps, CompetitionResponse } from '@core/types';
import { actions as competitionsFilterTabActions } from '@core/store/modules/modals/competitions-filter-tab';
import { getCompetitions } from '@core/store/modules/modals/competitions-filter-tab/selectors';
import { State } from '@core/store';
import {
  MATCH_COMPETITIONS_FILTER,
  MATCH_COMPETITIONS_FILTER_ID,
} from './constants';

type DispatchProps = {
  actions: {
    competitionsFilterTab: typeof competitionsFilterTabActions;
  };
};

type StateProps = {
  competitions: Paginated<CompetitionResponse>;
  isLoading: boolean;
};

type OwnProps = FilterTabProps;

type Props = DispatchProps & StateProps & OwnProps;

const MatchCompetitionsFilterTab = (props: Props) => {
  const { actions, isLoading, competitions, ...rest } = props;

  const findCompetitionTitle = (id: string) =>
    competitions?.list.find((competition) => competition.id === Number(id))
      .title;

  return (
    <SearchableFilterList
      isLoading={isLoading}
      filterId={MATCH_COMPETITIONS_FILTER_ID}
      paginatedList={competitions}
      filterProps={MATCH_COMPETITIONS_FILTER}
      getList={actions.competitionsFilterTab.getCompetitions}
      resetList={actions.competitionsFilterTab.resetState}
      searchByQuery={actions.competitionsFilterTab.searchCompetitionsByQuery}
      formatFilterLabel={findCompetitionTitle}
      {...rest}
    />
  );
};

const isLoadingSelector = createLoadingSelector([
  competitionsFilterTabActions.getCompetitionsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  competitions: getCompetitions(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionsFilterTab: bindActionCreators(
      competitionsFilterTabActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchCompetitionsFilterTab);
