import { createActions } from 'redux-actions';
import { CompetitionResponse } from '@core/types';

export const NAMESPACE = 'competitionsList';

export const actions = createActions(
  'GET_COMPETITIONS',
  'GET_COMPETITIONS_REQUEST',
  'GET_COMPETITIONS_SUCCESS',
  'GET_COMPETITIONS_FAILURE',
  'SET_COMPETITIONS',

  'GET_ROLLOVER_COMPETITIONS',
  'GET_ROLLOVER_COMPETITIONS_REQUEST',
  'GET_ROLLOVER_COMPETITIONS_SUCCESS',
  'GET_ROLLOVER_COMPETITIONS_FAILURE',

  'GET_COMPETITION',
  'GET_COMPETITION_REQUEST',
  'GET_COMPETITION_SUCCESS',
  'GET_COMPETITION_FAILURE',
  'SET_COMPETITION',

  'CREATE_COMPETITION_REQUEST',
  'CREATE_COMPETITION_SUCCESS',
  'CREATE_COMPETITION_FAILURE',

  'CREATE_ROLLOVER_COMPETITION_REQUEST',
  'CREATE_ROLLOVER_COMPETITION_SUCCESS',
  'CREATE_ROLLOVER_COMPETITION_FAILURE',

  'UPDATE_COMPETITION_REQUEST',
  'UPDATE_COMPETITION_SUCCESS',
  'UPDATE_COMPETITION_FAILURE',
  {
    namespace: NAMESPACE,
  },
) as unknown as {
  getCompetitions: IdentityActionFunction<unknown>;
  getCompetitionsRequest: RequestLifecycleAction;
  getCompetitionsSuccess: RequestLifecycleAction;
  getCompetitionsFailure: RequestLifecycleAction;
  setCompetitions: IdentityActionFunction<Array<CompetitionResponse>>;

  getRolloverCompetitions: IdentityActionFunction<unknown>;
  getRolloverCompetitionsRequest: RequestLifecycleAction;
  getRolloverCompetitionsSuccess: RequestLifecycleAction;
  getRolloverCompetitionsFailure: RequestLifecycleAction;

  getCompetition: IdentityActionFunction<unknown>;
  getCompetitionRequest: RequestLifecycleAction;
  getCompetitionSuccess: RequestLifecycleAction;
  getCompetitionFailure: RequestLifecycleAction;
  setCompetition: IdentityActionFunction<CompetitionResponse>;

  createCompetitionRequest: RequestLifecycleAction;
  createCompetitionSuccess: RequestLifecycleAction;
  createCompetitionFailure: RequestLifecycleAction;

  createRolloverCompetitionRequest: RequestLifecycleAction;
  createRolloverCompetitionSuccess: RequestLifecycleAction;
  createRolloverCompetitionFailure: RequestLifecycleAction;

  updateCompetitionRequest: RequestLifecycleAction;
  updateCompetitionSuccess: RequestLifecycleAction;
  updateCompetitionFailure: RequestLifecycleAction;
};
