import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  FormControl,
  Box,
  Grid,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, FieldProps } from 'formik';
import { none, isNil } from 'ramda';
import clsx from 'clsx';

import { ErrorMessage, Tooltip } from '@core/components';

interface OwnProps {
  label: string;
  hasBackground?: boolean;
  tooltipText?: string;
}

type Props = OwnProps & CheckboxProps;

const useStyles = makeStyles((theme: Theme) => ({
  withBackground: {
    backgroundColor: theme.palette.grey[200],
    padding: '0 15px',
    width: '100%',
    borderRadius: 4,
  },
}));

const CheckboxField = (props: Props) => {
  const {
    name,
    label,
    checked,
    disabled,
    onChange,
    hasBackground,
    tooltipText,
    'data-qa': dataQa,
  } = props;
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const shouldShowFieldError = none(isNil, [
          meta.error,
          meta.touched || null,
        ]);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          form.setFieldValue(name, event.target.checked, true);
          onChange && onChange(event, true);
        };

        return (
          <Grid
            container
            justifyContent="space-between"
            className={clsx({ [classes.withBackground]: hasBackground })}
          >
            <Grid item>
              <FormControl component="fieldset" error={shouldShowFieldError}>
                <FormControlLabel
                  checked={checked}
                  disabled={disabled}
                  label={label}
                  name={name}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={handleChange}
                      value={field.value}
                      name={name}
                      data-qa={dataQa}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <ErrorMessage name={name} />
            </Grid>
            {tooltipText && (
              <Grid item>
                <Box display="flex" alignItems="center" height="100%">
                  <Tooltip title={tooltipText} />
                </Box>
              </Grid>
            )}
          </Grid>
        );
      }}
    </Field>
  );
};

export default CheckboxField;
