import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FilterCheckboxList } from '@core/components';
import { getMatchStatus, createFilterId } from '@core/helpers';
import { FilterTabProps, MatchStatus } from '@core/types';

import { MATCH_STATUS_FILTER } from '../constants';

type Props = FilterTabProps;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

const StatusFilter = (props: Props) => {
  const { t } = useTranslation();
  const { filters, ...rest } = props;
  const classes = useStyles();
  const filterId = createFilterId(MATCH_STATUS_FILTER);
  const filterValues = filters[filterId]?.filterValues || [];

  function formatFilterLabel(val: string) {
    return getMatchStatus(t, val as MatchStatus);
  }

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Status')}
      </Typography>
      <FilterCheckboxList
        list={Object.values(MatchStatus)}
        filterProps={MATCH_STATUS_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        shifted
        {...rest}
      />
    </>
  );
};

export default StatusFilter;
