import { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { equals, omit } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FullScreenSpinner } from '@core/components';
import modalStyles from '@core/components/global-modal/styles';
import { useConfirmationDialog } from '@core/hooks';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  VenueModel,
  Paginated,
  GlobalModalProps,
  MatchStatus,
  Group,
  GroupCompetitor,
  MatchModel,
} from '@core/types';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import { getMatch } from '@core/store/modules/tabs/match-profile/selectors';
import { State } from '@core/store';
import { actions as fixtureMatchEditActions } from '@core/store/modules/modals/fixture-match-edit';
import {
  getGroupCompetitors,
  getVenuesOptions,
} from '@core/store/modules/modals/fixture-match-edit/selectors';
import MatchCreateForm from './match-create-form';
import * as testIds from './tests/test-ids';
import { FIELD_NAMES } from './constants';
import { getInitialValues, type FormValues } from './helpers/initialValues';
import { getValidationSchema } from './helpers/validationSchema';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
    fixtureMatchEdit: typeof fixtureMatchEditActions;
  };
}

interface StateProps {
  groupCompetitors: Array<GroupCompetitor>;
  isDeleting: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  match: MatchModel;
  venues: Paginated<VenueModel>;
}

export interface OwnProps {
  competitionId: number;
  isEditMode?: boolean;
  matchId?: number;
  stage?: Group;
  tabId?: string;
}

type Props = GlobalModalProps<OwnProps> & DispatchProps & StateProps;

const FixtureMatchModal = (props: Props) => {
  const {
    actions,
    groupCompetitors,
    isDeleting,
    isLoading,
    isModalOpen,
    isSubmitting,
    match,
    modalActions,
    modalParams,
    venues,
  } = props;
  const classes = modalStyles();
  const { getConfirmation } = useConfirmationDialog();
  const { t } = useTranslation();
  const { competitionId, matchId, stage, isEditMode } = modalParams;

  const canDeleteMatch = !!match?._links?.delete;

  useEffect(() => {
    if (matchId) {
      actions.matchProfile.getMatch({ matchId });
    }

    if (stage?.id) {
      actions.fixtureMatchEdit.getGroupCompetitors({ groupId: stage?.id });
    }

    actions.fixtureMatchEdit.getVenues({
      query: '',
      page: 1,
    });

    return () => {
      actions.fixtureMatchEdit.resetState();
    };
  }, [matchId, stage?.id]);

  const handleModalClose = () => {
    modalActions.closeModal();
    actions.fixtureMatchEdit.resetState();
  };

  const handleSubmit = (values: FormValues) => {
    const noChanges = equals(getInitialValues(match, groupCompetitors), values);

    if (noChanges) {
      return handleModalClose();
    }

    const data = {
      ...omit(
        [
          FIELD_NAMES.venueOption,
          FIELD_NAMES.subVenueOption,
          FIELD_NAMES.groupOption,
        ],
        values,
      ),
      venueId:
        values[FIELD_NAMES.subVenueOption] ||
        values[FIELD_NAMES.venueOption]?.value ||
        null,
    };

    if (isEditMode) {
      actions.fixtureMatchEdit.updateFixtureMatch({
        competitionId,
        stageId: stage?.id,
        matchId,
        data,
      });
    } else {
      actions.fixtureMatchEdit.createFixtureMatch({
        competitionId,
        stageId: stage?.id,
        groupId: values[FIELD_NAMES.groupOption] || stage?.id,
        data: {
          ...getValidationSchema(t, stage?.childGroups?.length > 0).cast(data),
          status: MatchStatus.DRAFT,
        },
      });
    }
  };

  const handleMatchDelete = async () => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this match?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      actions.fixtureMatchEdit.deleteFixtureMatch({
        competitionId,
        stageId: stage?.id,
        matchId,
        tabId: modalParams?.tabId,
      });
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
    >
      <Formik
        initialValues={getInitialValues(match, groupCompetitors)}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={getValidationSchema(
          t,
          stage?.childGroups?.length > 0,
        )}
      >
        <Form noValidate>
          <DialogTitle className={classes.title}>
            {isEditMode ? t('Edit match') : t('Create match')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box pb={2}>
              {isLoading ? (
                <FullScreenSpinner />
              ) : (
                <MatchCreateForm
                  isEditMode={isEditMode}
                  venues={venues}
                  competitors={groupCompetitors}
                  stage={stage}
                />
              )}
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions className={classes.actions}>
            <Box
              display="flex"
              justifyContent={isEditMode ? 'space-between' : 'flex-end'}
              width="100%"
            >
              {isEditMode && (
                <ButtonWithProgress
                  onClick={handleMatchDelete}
                  color="primary"
                  data-qa={testIds.MATCH_DELETE_BUTTON}
                  isLoading={isDeleting}
                  disabled={isSubmitting || isDeleting || !canDeleteMatch}
                >
                  {t('Delete')}
                </ButtonWithProgress>
              )}
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleModalClose}
                    data-qa={testIds.MATCH_CANCEL_BUTTON}
                    disabled={isSubmitting || isDeleting}
                  >
                    {t('Cancel')}
                  </Button>
                </Box>
                <ButtonWithProgress
                  type="submit"
                  data-qa={testIds.MATCH_SUBMIT_BUTTON}
                  color="primary"
                  variant="contained"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || isDeleting}
                >
                  {isEditMode ? t('Save') : t('Create')}
                </ButtonWithProgress>
              </Box>
            </Box>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

const submittingSelector = createLoadingSelector([
  fixtureMatchEditActions.createFixtureMatchRequest.toString(),
  fixtureMatchEditActions.updateFixtureMatchRequest.toString(),
]);
const deletingSelector = createLoadingSelector([
  fixtureMatchEditActions.deleteFixtureMatchRequest.toString(),
]);
const loadingSelector = createLoadingSelector([
  fixtureMatchEditActions.getGroupCompetitorsRequest.toString(),
  //fixtureMatchEditActions.getVenuesRequest.toString(),
  matchProfileActions.getMatchRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
    fixtureMatchEdit: bindActionCreators(fixtureMatchEditActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  { modalParams }: GlobalModalProps<OwnProps>,
): StateProps => ({
  isSubmitting: submittingSelector(state),
  isDeleting: deletingSelector(state),
  isLoading: loadingSelector(state),
  match: getMatch(state, { matchId: modalParams?.matchId }),
  groupCompetitors: getGroupCompetitors(state),
  venues: getVenuesOptions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixtureMatchModal);
