import addPrefix from '@core/helpers/object/add-prefix';
import { CUSTOMER_CODE_REGEX } from '@core/constants/regex';

export const CUSTOMER_CODE = `:customer(\\${CUSTOMER_CODE_REGEX})`;

export const baseAccessLinks = {
  home: 'dashboard',
  clubs: 'clubs',
  club: 'club',
  representativeClubs: 'representativeClubs',
  nationalClubs: 'nationalClubs',
  persons: 'persons',
  competitions: 'competitions',
  venues: 'venues',
  appointments: 'appointments',
  matches: 'matches',
  ageGroups: 'ageGroups',
  seasons: 'seasons',
  competitionLevels: 'competitionLevels',
  refereeCategories: 'refereeCategories',
  refereeGroups: 'refereeGroups',
  users: 'users',
  featureToggle: 'featureToggles',
  clubHome: 'clubAdminDashboard',
  clubAdminCompetitions: 'clubAdminCompetitions',
  clubAdminMatches: 'clubAdminMatches',
};

export const basePaths = {
  notFound: '/404', // fallback
  root: '',
  authenticated: '/authenticated',
  loggedOut: '/logged-out',
  ...addPrefix(`/${CUSTOMER_CODE}`, {
    customerRoot: '',
    login: '/login',
    register: '/register',
    registerVerify: '/register/verify',
    registerSuccess: '/register/success/:email(.+)?',
    landing: '/landing',
    home: '/home',
    supportCenter: '/support-center',
    termsAndConditions: '/terms-and-conditions',
    cookiePolicy: '/cookie-policy',
    privacyStatement: '/privacy-statement',
    competitions: '/competitions',
    ageGroups: '/age-groups',
    refereeCategories: '/referee-categories',
    refereeGroups: '/referee-groups',
    competitionLevels: '/competition-levels',
    seasons: '/seasons',
    persons: '/persons',
    personProfile: '/persons/:id',
    venues: '/venues',
    venueProfile: '/venues/:id',
    createVenue: '/venues/:id/create',
    appointments: '/appointments',
    clubs: '/clubs',
    representatives: '/representatives',
    nationalTeams: '/nationalTeams',
    createClub: '/clubs/:id/create',
    createRepresentative: '/representative/:id/create',
    club: '/club',
    clubProfile: '/clubs/:id',
    representativeProfile: '/representative/:id',
    createKitSet: '/clubs/:id/kit-sets/create',
    matches: '/matches',
    matchProfile: '/matches/:id',
    matchResults: '/matches/:id/results',
    matchDataCreate: '/matches/:id/create',
    matchDurationEdit: '/matches/:id/duration/edit',
    matchNotesEdit: '/matches/:id/notes/edit',
    matchCommissionerNotesEdit: '/matches/:id/commissionerNotes/edit',
    manageMatchSheet: '/matches/:id/match-sheet/edit',
    matchPenaltyShootoutsEdit: '/matches/:id/penalty-shootouts/edit',
    competitionCreation: '/competitions/create',
    competitionProfile: '/competitions/:id',
    competitionGeneralInformationEdit: '/competitions/:id/edit',
    createStage: '/competitions/:id/stages/create',
    createTeam: '/competitions/:id/teams/create',
    competitorAssignment: '/competitions/:id/competitors/add',
    competitorProfile: '/competitions/:competitionId/competitors/:competitorId',
    competitorGeneralInformationEdit:
      '/competitions/:competitionId/competitors/:competitorId/edit',
    disciplineSettings: '/competitions/:id/discipline-settings',
    competitionSettingsEdit: '/competitions/:id/settings/edit',
    stageStandingsSettings:
      '/competitions/:competitionId/stages/:groupId/standings/edit',
    stageGeneralInformationEdit:
      '/competitions/:competitionId/stages/:groupId/generalInformation/edit',
    stageSettingsEdit:
      '/competitions/:competitionId/stages/:groupId/settings/edit',
    stageTeamGridEdit:
      '/competitions/:competitionId/stages/:groupId/team-grid/edit',
    competitorTeamKitSet:
      '/competitions/:competitionId/stages/:groupId/competitors/:competitorId/kit-sets/:kitSetId/edit',
    playerListManagement:
      '/competitions/:competitionId/stages/:groupId/competitors/:competitorId/players/edit',
    playerListEdit:
      '/competitions/:competitionId/stages/:groupId/competitors/:competitorId/players/bulkEdit',
    imposeSanction: '/competitions/:id/sanction/create',
    userProfile: '/user',
    users: '/users',
    featureToggle: '/feature-toggle',
    registerPerson: '/register-person',
    assignments: '/competitions/assignments',
  }),
} as const;

export type Path = $ValuesLike<typeof basePaths>;

export default basePaths;
