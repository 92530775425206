import restService from '@core/api/rest-service';
import {
  Competition,
  CompetitionResponse,
  CompetitionsResponse,
  CompetitionStatus,
  CreateRolloverCompetitionPayload,
} from '@core/types';

export const createCompetition = (data: Partial<Competition>) =>
  restService.post<CompetitionResponse>('/v1/competitions', data);

export const updateCompetition = (id: number, data: Partial<Competition>) =>
  restService.patch(`/v1/competitions/${id}`, data);

export const getCompetition = (id: number) =>
  restService.get<CompetitionResponse>(`/v1/competitions/${id}`);

export const getCompetitions = (queryParams: URLSearchParams) =>
  restService.get<CompetitionsResponse>('/v1/competitions', {
    params: queryParams,
  });

export const submitCompetition = (id: number) =>
  restService.post(`/v1/competitions/${id}/submit`);

export const createRolloverCompetition = (
  competitionId: number,
  data: CreateRolloverCompetitionPayload,
) => restService.post(`/v1/competitions/${competitionId}/rollover`, data);

export const deleteCompetition = (id: number) =>
  restService.delete(`/v1/competitions/${id}`);

export const changeCompetitionStatus = (
  competitionId: number,
  status: CompetitionStatus,
) =>
  restService.post<void>(`/v1/competitions/${competitionId}/changeStatus`, {
    status,
  });
