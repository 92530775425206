import { SanctionType } from '@core/types';

export const getSanctionType = (
  t: (key: string) => string,
  sanctionType: any,
) => {
  switch (sanctionType) {
    case SanctionType.DISQUALIFICATION_PENALTY:
      return t('Disqualification penalty');
    case SanctionType.FINANCIAL_PENALTY:
      return t('Financial penalty');
    case SanctionType.POINTS_DEDUCTION:
      return t('Points deduction');
    case SanctionType.SUSPENSION_FOR_NUMBER_OF_DAYS:
      return t('Period suspension');
    case SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES:
      return t('Match suspension');
    default:
      return '';
  }
};
