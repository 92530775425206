import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import type { State } from '@core/store';

const getIds = (_state: State, ids: number[]) => ids;

export const getCompetitionSeasonsList = (state: State) =>
  pathOr(null, ['api', 'seasonsList'], state);

export const getCompetitionSeasonsById = createSelector(
  getIds,
  getCompetitionSeasonsList,
  (ids, seasons) => ids.map((id) => seasons[id]),
);
