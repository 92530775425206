import { TFunction } from 'i18next';
import { EventInput } from '@fullcalendar/core';
import { Theme } from '@mui/material';

import {
  CalendarConflictType,
  ConflictSeverityType,
  MatchFixture,
  Conflict,
} from '@core/types';

export const getConflictLevel = (conflicts: Array<Conflict>) =>
  conflicts
    ? Math.max(...conflicts.map((conflict) => conflict.alertLevel))
    : ConflictSeverityType.NONE;

export const getConflictColor = (
  conflictLevel: ConflictSeverityType,
  theme: Theme,
): string => {
  switch (conflictLevel) {
    case ConflictSeverityType.CRITICAL:
    case ConflictSeverityType.MAJOR:
    case ConflictSeverityType.MODERATE:
      return theme.palette.warning.light;
    default:
      return theme.palette.primary.main;
  }
};
export const getConflictIconColor = (
  conflictLevel: ConflictSeverityType,
  theme: Theme,
): string => {
  switch (conflictLevel) {
    case ConflictSeverityType.CRITICAL:
    case ConflictSeverityType.MAJOR:
    case ConflictSeverityType.MODERATE:
      return theme.palette.warning.main;
    default:
      return theme.palette.primary.main;
  }
};

export const createCalendarEvent = (match: MatchFixture): EventInput => {
  return {
    id: String(match.id),
    status: 'DEFAULT',
    start: `${match.date} ${match.time}`,
    end: `${match.date} ${match.time}`,
    match,
    allDay: false,
    classNames: [
      `match-event`,
      `match-event-alert-${getConflictLevel(match.conflicts)}`,
    ],
    display: 'block',
  };
};

export const getConflictMessage = (
  t: TFunction,
  type?: CalendarConflictType,
) => {
  switch (type) {
    case CalendarConflictType.MATCH:
      return t('Team conflict');
    case CalendarConflictType.VENUE:
      return t('Venue conflict');
    case CalendarConflictType.REFEREE:
    case CalendarConflictType.MA_OFFICIAL:
      return t('Appointment conflict');
    default:
      return t(type);
  }
};
