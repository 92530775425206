import { handleActions } from 'redux-actions';

import { SnackbarState } from '@core/types';

import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './constants';

const initialState: SnackbarState = {
  snackbars: [],
};

type Payload = any;

export default handleActions<SnackbarState, Payload>(
  {
    [ENQUEUE_SNACKBAR]: (state, action) => ({
      ...state,
      snackbars: [...state.snackbars, { ...action.payload }],
    }),
    [REMOVE_SNACKBAR]: (state, action) => ({
      ...state,
      snackbars: state.snackbars.filter(
        (snackbar) => snackbar.key !== action.payload,
      ),
    }),
  },
  initialState,
);
