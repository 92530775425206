import { createFilterId } from '@core/helpers';

export const SPORT_FILTER = {
  fieldName: 'sport',
};

export const STATUS_FILTER = {
  fieldName: 'status',
};

export const SPORT_FILTER_ID = createFilterId(SPORT_FILTER);
export const STATUS_FILTER_ID = createFilterId(STATUS_FILTER);
