import {
  all,
  call,
  cancel,
  cancelled,
  debounce,
  Effect,
  put,
  race,
  SagaReturnType,
  take,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { formatPaginatedStore, handleApiErrors } from '@core/helpers';
import { getTeams } from '@core/store/modules/api/team/sagas';

import actions from './actions';

export function* searchTeamsFlow(
  action: InferAction<
    typeof actions.getTeams | typeof actions.searchTeamsByQuery
  >,
): Generator<Effect, any, any> {
  try {
    const { payload } = action;

    yield put(actions.getTeamsRequest());
    const { error, response } = (yield call(
      getTeams,
      payload || {},
    )) as SagaReturnType<typeof getTeams>;

    if (!error) {
      yield put(actions.getTeamsSuccess());
      // TODO: JB: maybe use formatPaginatedStoreWithIds
      // if (false) {
      //   yield put(
      //     actions.setTeams(formatPaginatedStoreWithIds('teams')(response))
      //   );
      // }
      yield put(
        actions.setTeams(formatPaginatedStore('teams')(response) as any),
      );
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getTeamsFailure());
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.getTeamsSuccess());
    }
  }
}

export function* getTeamsFlow() {
  while (true) {
    const action = (yield take(actions.getTeams)) as InferAction<
      typeof actions.getTeams
    >;
    yield race([call(searchTeamsFlow, action), take(actions.resetState)]);
  }
}

export function* watchSearchTeamsByQuery() {
  while (true) {
    const task = (yield debounce(
      500,
      actions.searchTeamsByQuery.toString(),
      searchTeamsFlow,
    )) as Task;
    yield take(actions.resetState);
    yield cancel(task);
  }
}

export default function* saga() {
  yield all([getTeamsFlow(), watchSearchTeamsByQuery()]);
}
