import { PlayerListRequestStatus, StatusColors } from '@core/types';

export const getPlayerListRequestStatusColor = (
  status: PlayerListRequestStatus,
) => {
  switch (status) {
    case PlayerListRequestStatus.PENDING:
      return StatusColors.YELLOW;
    case PlayerListRequestStatus.APPROVED:
      return StatusColors.GREEN;
    case PlayerListRequestStatus.REJECTED:
      return StatusColors.RED;
    default:
      return StatusColors.LIGHT_GREY;
  }
};
