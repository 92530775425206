import { Group } from '@core/types';

import { FIELD_NAMES } from '../constants';

export interface FormValues {
  [FIELD_NAMES.title]: string;
  [FIELD_NAMES.numberOfTeams]: number;
}

export const getInitialValues = (group: Group): FormValues => ({
  [FIELD_NAMES.title]: group?.title || '',
  [FIELD_NAMES.numberOfTeams]: group?.numberOfTeams || 2,
});
