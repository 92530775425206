import { createAction } from 'redux-actions';
import { Group } from '@core/types';
import { GroupStandingSettings } from '@volleyball/types';

import * as ACTION_TYPES from './constants';

export const getGroupStandingSettings = createAction<Group['id']>(
  ACTION_TYPES.GET_GROUP_STANDING_SETTINGS,
);
export const getGroupStandingSettingsRequest = createAction(
  ACTION_TYPES.GET_GROUP_STANDING_SETTINGS_REQUEST,
);
export const getGroupStandingSettingsFailure = createAction(
  ACTION_TYPES.GET_GROUP_STANDING_SETTINGS_FAILURE,
);
export const getGroupStandingSettingsSuccess = createAction(
  ACTION_TYPES.GET_GROUP_STANDING_SETTINGS_SUCCESS,
);

export const addGroupStandingSettings = createAction<{
  groupId: Group['id'];
  settings: GroupStandingSettings;
}>(ACTION_TYPES.ADD_GROUP_STANDING_SETTINGS);

export const setGroupStandingSettings = createAction<{
  tabId: string;
  groupId: Group['id'];
  settings: GroupStandingSettings;
}>(ACTION_TYPES.SET_GROUP_STANDING_SETTINGS);
export const setGroupStandingSettingsRequest = createAction(
  ACTION_TYPES.SET_GROUP_STANDING_SETTINGS_REQUEST,
);
export const setGroupStandingSettingsFailure = createAction(
  ACTION_TYPES.SET_GROUP_STANDING_SETTINGS_FAILURE,
);
export const setGroupStandingSettingsSuccess = createAction(
  ACTION_TYPES.SET_GROUP_STANDING_SETTINGS_SUCCESS,
);

export const resetGroupStandingSettings = createAction(
  ACTION_TYPES.RESET_GROUP_STANDING_SETTINGS,
);

export const clearGroupStandingSettings = createAction<Group['id']>(
  ACTION_TYPES.CLEAR_GROUP_STANDING_SETTINGS,
);
