import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  separation: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
}));
