import { baseAccessLinks, basePaths } from '@core/routes';

export const accessLinks = {
  ...baseAccessLinks,
  // Extend access links here if needed
};

export const paths = {
  ...basePaths,
  // Extend paths here if needed
} as const;

export default paths;
