import { createAction } from 'redux-actions';

import { Filters, FilterModel } from '@core/types';

import { NAMESPACE } from './constants';

const setFilters = createAction<Filters>(`${NAMESPACE}/SET_FILTERS`);
const addFilter = createAction<FilterModel>(`${NAMESPACE}/ADD_FILTER`);
const removeFilter = createAction<FilterModel>(`${NAMESPACE}/REMOVE_FILTER`);
const clearFilters = createAction<void>(`${NAMESPACE}/CLEAR_FILTERS`);
const replaceFilter = createAction<FilterModel>(`${NAMESPACE}/REPLACE_FILTER`);

export default {
  setFilters,
  addFilter,
  removeFilter,
  clearFilters,
  replaceFilter,
};
