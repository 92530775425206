import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FilterTabProps, Paginated, VenueModel } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as venuesActions } from '@core/store/modules/modals/venues-filter-tab';
import { getVenues } from '@core/store/modules/modals/venues-filter-tab/selectors';
import { SearchableFilterList } from '@core/components';

import { VENUES_FILTER_ID, VENUES_FILTER } from './constants';

type DispatchProps = {
  actions: {
    venues: typeof venuesActions;
  };
};

type StateProps = {
  isLoading: boolean;
  venues: Paginated<VenueModel>;
};

type OwnProps = FilterTabProps & StateProps;

type Props = DispatchProps & OwnProps & StateProps;

const VenueFilterTab = (props: Props) => {
  const { actions, isLoading, venues, ...rest } = props;

  function findVenueTitle(id: string) {
    return venues?.list.find((venue) => venue.id === Number(id)).title;
  }

  return (
    <SearchableFilterList
      isLoading={isLoading}
      filterId={VENUES_FILTER_ID}
      paginatedList={venues}
      filterProps={VENUES_FILTER}
      getList={actions.venues.getVenues}
      resetList={actions.venues.resetState}
      searchByQuery={actions.venues.searchVenuesByQuery}
      formatFilterLabel={findVenueTitle}
      {...rest}
    />
  );
};

const loadingSelector = createLoadingSelector([
  venuesActions.getVenuesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
  venues: getVenues(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    venues: bindActionCreators(venuesActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueFilterTab);
