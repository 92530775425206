import React, { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FullScreenSpinner } from '@core/components';

const useStyles = makeStyles<Theme, any>((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    position: 'relative',
  },
  summary: {
    padding: ({ hasHeader }: any) => (hasHeader ? 0 : theme.spacing(3)),
  },
  content: {
    display: ({ hasHeader }: any) => (hasHeader ? 'block' : 'flex'),
  },
}));

export interface ExpansionPanelSummaryProps {
  expanded: boolean;
  isLoading?: boolean;
}

interface OwnProps {
  children: React.FunctionComponentElement<any>;
  headerComponent?: any;
  summaryComponent: (
    props: ExpansionPanelSummaryProps,
  ) => React.FunctionComponentElement<any>;
  testId?: string;
  onChange?: (event: object, expanded: boolean) => void;
  isLoading?: boolean;
  alwaysExpanded?: boolean;
}

type Props = AccordionProps & OwnProps;

const ExpansionPanelComponent = (props: Props) => {
  const {
    headerComponent,
    summaryComponent,
    children,
    testId,
    onChange,
    isLoading,
    alwaysExpanded,
  } = props;
  const classes = useStyles({ hasHeader: !!headerComponent });
  const [isExpanded, toggleExpansion] = useState(false);

  const handleChange = (event: object, expanded: boolean) => {
    onChange && onChange(event, expanded);
    toggleExpansion(expanded);
  };

  return (
    <Grid data-qa={testId} className={classes.root}>
      <Accordion onChange={handleChange} expanded={alwaysExpanded}>
        <AccordionSummary
          classes={{ root: classes.summary, content: classes.content }}
        >
          {headerComponent}
          {summaryComponent({ expanded: isExpanded, isLoading })}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? <FullScreenSpinner /> : children}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ExpansionPanelComponent;
