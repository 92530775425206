import { FormikErrors } from 'formik';
import { APIErrorsModel } from '@core/types';

export const getInitialErrors = (errors: APIErrorsModel) => {
  const formikErrors: FormikErrors<any> = {};

  if (!errors) {
    return formikErrors;
  }
  errors.forEach((error) => {
    const field = error.path.replace(/[[\]]/g, '');
    formikErrors[field] = error.message;
  });

  return formikErrors;
};
