import Select, {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  MenuPosition,
  SelectComponentsConfig,
  OnChangeValue,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import { TextFieldProps } from '@mui/material/TextField';
import { identity } from 'ramda';
import {
  AutocompleteOption,
  AutocompleteOptions,
  AutoCompleteOutputOption,
} from '@core/types';
import { COMPONENTS, MenuWithInfiniteScroll } from './components';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option = { label: string; value: string },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option> = GroupBase<Option>,
  > {
    'data-qa'?: string;
    filterOptions?: unknown;
    hasMore?: boolean;
    loadNext?: (inputValue: string) => void;
    textFieldProps?: TextFieldProps;
    tooltipText?: string;
    width?: string;
    withTooltipMargin?: boolean;
  }
}

export interface AutocompleteProps {
  // react-select props
  customComponents?: SelectComponentsConfig<AutocompleteOption, boolean, any>;
  defaultInputValue?: string;
  defaultValue?: any;
  filterOption?: (
    option: AutoCompleteOutputOption,
    searchText: string,
  ) => boolean;
  inputValue?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  menuPosition?: MenuPosition;
  name: string;
  onBlur?: any;
  onChange?: (
    value: OnChangeValue<AutocompleteOption, boolean>,
    action: ActionMeta<AutocompleteOption>,
  ) => void;
  onFocus?: any;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onMenuOpen?: () => void;
  options: AutocompleteOptions;
  value?: OnChangeValue<AutocompleteOption, boolean>;

  // custom props passed to react-select
  'data-qa'?: string;
  filterOptions?: unknown;
  pagination?: {
    hasMore: boolean;
    loadNext: (inputValue: string) => void;
  };
  textFieldProps?: TextFieldProps;
  tooltipText?: string;
  width?: string;
  withTooltipMargin?: boolean;

  // custom props not passed to react-select
  noOptionsMessage?: string | null;
}

const customStyles = {
  menuPortal: (provided: any) => ({
    ...provided,
    maxHeight: 270,
    // TODO: must find a solution that doesnt require circular reference to mscm-core
    zIndex: 999,
    // zIndex: theme.zIndex.tooltip,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const Autocomplete = (props: AutocompleteProps) => {
  const { t } = useTranslation();
  const {
    // react-select props
    customComponents,
    defaultValue,
    defaultInputValue,
    filterOption,
    inputValue,
    isClearable = true,
    isDisabled,
    isLoading,
    isMulti,
    menuPosition,
    name,
    onBlur,
    onChange,
    onFocus,
    onInputChange,
    onMenuOpen,
    options,
    value,

    // custom props passed to react-select
    'data-qa': dataQa,
    pagination,
    textFieldProps,
    tooltipText,
    width,
    withTooltipMargin,

    // custom props not passed to react-select
    noOptionsMessage,
  } = props;

  const getNoOptionsMessage = () =>
    noOptionsMessage == null ? null : noOptionsMessage || t('No options');
  const getLoadingMessage = () => t('Loading');
  const components = {
    ...COMPONENTS,
    ...customComponents,
  } as SelectComponentsConfig<AutocompleteOption, boolean, any>;

  if (pagination) {
    components.Menu = MenuWithInfiniteScroll;
  }

  // if (menuPosition === 'fixed') {
  //   if (import.meta.env.MODE !== 'production') {
  //     if (!hasWarned) {
  //       hasWarned = true;
  //       console.warn(
  //         'fixed" menuPosition might require extra layout management',
  //       );
  //     }
  //   }
  // }

  return (
    <Select
      // react-select props
      captureMenuScroll={!pagination}
      components={components}
      defaultInputValue={defaultInputValue}
      defaultValue={defaultValue}
      filterOption={filterOption}
      inputValue={inputValue}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      loadingMessage={getLoadingMessage}
      menuPosition={menuPosition || 'absolute'}
      name={name}
      noOptionsMessage={getNoOptionsMessage}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      options={options}
      placeholder=""
      styles={customStyles}
      value={value}
      // custom props passed to react-select
      data-qa={dataQa}
      filterOptions={identity}
      textFieldProps={textFieldProps}
      tooltipText={tooltipText}
      width={width}
      withTooltipMargin={withTooltipMargin}
      {...pagination}
    />
  );
};

export default Autocomplete;
