import { createContext, useContext, useState, type ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import {
  CONFIRMATION_DIALOG,
  CONFIRMATION_TEXT,
  CONFIRMATION_BUTTON_CANCEL,
  CONFIRMATION_BUTTON_OK,
} from '@core/constants/test-ids';

type DialogProps = Partial<{
  open: boolean;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onDismiss: () => void;
  actionCallback: (action: boolean) => void;
  openDialog: (action: any) => void;
}>;

type ProviderProps = {
  children: ReactNode;
};

const initialProps: DialogProps = {};

const ConfirmationDialog = ({
  open,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onDismiss,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={onDismiss} data-qa={CONFIRMATION_DIALOG}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box mb={2} data-qa={CONFIRMATION_TEXT}>
          <Typography variant="subtitle1">{message}</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onDismiss} data-qa={CONFIRMATION_BUTTON_CANCEL}>
          {cancelText}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirm}
          data-qa={CONFIRMATION_BUTTON_OK}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationDialogContext = createContext(initialProps);

export const ConfirmationDialogProvider = ({ children }: ProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<DialogProps>(initialProps);

  const openDialog = ({
    title,
    message,
    confirmText,
    cancelText,
    actionCallback,
  }: DialogProps) => {
    setDialogOpen(true);
    setDialogConfig({
      title,
      message,
      confirmText,
      cancelText,
      actionCallback,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setTimeout(() => setDialogConfig({}), 500);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmText={dialogConfig?.confirmText}
        cancelText={dialogConfig?.cancelText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationDialog;
