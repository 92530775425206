import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import { PlayerList, TeamOfficialList, Paginated } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  byClubId: {
    [clubId: string]: {
      clubPlayers: {
        players: PlayerList;
        list: Omit<Paginated<any>, 'list'>;
      };
      clubTeamOfficials: {
        officials: TeamOfficialList;
        list: Omit<Paginated<any>, 'list'>;
      };
    };
  };
  byCompetitorId: {
    [competitorId: string]: {
      addedCompetitorPlayers: PlayerList;
      competitorPlayers: PlayerList;
      addedCompetitorTeamOfficials: TeamOfficialList;
      competitorTeamOfficials: TeamOfficialList;
      previousCompetitorPlayerListTotal: number;
    };
  };
}

export const defaultState: DefaultState = {
  byClubId: {},
  byCompetitorId: {},
};

type Payload = any;

interface Meta {
  clubId?: number;
  competitorId?: number;
}

const setClubPlayers = (payload: Payload, state: any, clubId: number) => {
  const currentList = state?.byClubId[clubId]?.clubPlayers?.players;
  if (currentList)
    return { ...payload, players: [...currentList, ...payload.players] };

  return payload;
};

const setTeamOfficials = (payload: Payload, state: any, clubId: number) => {
  const currentList = state?.byClubId[clubId]?.clubTeamOfficials?.officials;
  if (currentList)
    return { ...payload, officials: [...currentList, ...payload.officials] };

  return payload;
};

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setClubPlayers.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byClubId', meta.clubId, 'clubPlayers'],
        setClubPlayers(payload, state, meta.clubId),
        state,
      ),
    [actions.setAddedCompetitorPlayers.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byCompetitorId', meta.competitorId, 'addedCompetitorPlayers'],
        payload,
        state,
      ),
    [actions.setCompetitorPlayers.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byCompetitorId', meta.competitorId, 'competitorPlayers'],
        payload,
        state,
      ),
    [actions.resetClubPlayers.toString()]: (state, { meta }) =>
      assocPath(
        ['byClubId', meta.clubId, 'clubPlayers'],
        setClubPlayers({ players: [] }, null, meta.clubId),
        state,
      ),
    [actions.setCompetitorTeamOfficials.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byCompetitorId', meta.competitorId, 'competitorTeamOfficials'],
        payload,
        state,
      ),
    [actions.setAddedCompetitorTeamOfficials.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byCompetitorId', meta.competitorId, 'addedCompetitorTeamOfficials'],
        payload,
        state,
      ),
    [actions.setClubTeamOfficials.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byClubId', meta.clubId, 'clubTeamOfficials'],
        setTeamOfficials(payload, state, meta.clubId),
        state,
      ),
    [actions.resetClubTeamOfficials.toString()]: (state, { meta }) =>
      assocPath(
        ['byClubId', meta.clubId, 'clubTeamOfficials'],
        setTeamOfficials({ officials: [] }, null, meta.clubId),
        state,
      ),
    [actions.setPreviousCompetitorPlayerListTotal.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        [
          'byCompetitorId',
          meta.competitorId,
          'previousCompetitorPlayerListTotal',
        ],
        payload,
        state,
      ),
    [actions.resetPreviousCompetitorPlayerListTotal.toString()]: (
      state,
      { meta },
    ) =>
      dissocPath(
        [
          'byCompetitorId',
          meta.competitorId,
          'previousCompetitorPlayerListTotal',
        ],
        state,
      ),
  },
  defaultState,
);

export default reducer;
