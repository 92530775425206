import restService from '@core/api/rest-service';
import {
  UploadFixturesProps,
  FixturesResponse,
  CreateGroupFixtureRequest,
  CreateGroupFixtureRequestData,
  GenerateGroupFixtureSettings,
} from '@core/types';

export const uploadGroupFixtures = ({
  groupId,
  file,
  importByTeamNames,
}: UploadFixturesProps) => {
  const formData = new FormData();
  formData.append('file', file);
  // @ts-ignore
  formData.append('importByTeamNames', importByTeamNames);

  return restService.post(`/v1/groups/${groupId}/importFixtures`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

interface GroupFixturesProps {
  groupId: number;
  queryParams: {
    page?: number;
  };
}

const defaultQueryParams = {
  page: 1,
};

export const getGroupFixtures = ({
  groupId,
  queryParams = defaultQueryParams,
}: GroupFixturesProps) =>
  restService.get<FixturesResponse>(`/v1/groups/${groupId}/groupFixtures`, {
    params: queryParams,
  });

export const exportGroupFixtures = (groupId: number) =>
  restService.post(`/v1/groups/${groupId}/exportFixtures`);

export const createGroupFixture = ({
  groupId,
  data,
}: CreateGroupFixtureRequest) =>
  restService.post(`/v1/groups/${groupId}/groupFixtures`, data);

export const deleteGroupFixture = (link: string) => restService.delete(link);

export const updateGroupFixture = (
  link: string,
  data: CreateGroupFixtureRequestData,
) => restService.patch(link, data);

export const regenerateGroupFixtures = (
  link: string,
  data: GenerateGroupFixtureSettings,
) => restService.post(link, data);
