import { call, put, Effect } from 'redux-saga/effects';

import * as teamOfficialsApi from '@core/api/team-officials';

import { actions } from '.';

export function* getClubTeamOfficials(
  queryParams?: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getClubTeamOfficialsBySportRequest());
  const response = (yield call(
    teamOfficialsApi.getClubTeamOfficials,
    queryParams,
  )) as InferApi<typeof teamOfficialsApi.getClubTeamOfficials>;

  if (!response.error) {
    yield put(actions.getClubTeamOfficialsBySportSuccess());
  } else {
    yield put(actions.getClubTeamOfficialsBySportFailure());
  }

  return response;
}

export function* createTeamOfficial(
  clubId: number,
  personId: number,
  teamOfficialRoleId: Array<number>,
  sportId: number,
): Generator<Effect, any, any> {
  yield put(actions.createTeamOfficialRequest());
  const response = (yield call(
    teamOfficialsApi.createTeamOfficial,
    clubId,
    personId,
    teamOfficialRoleId,
    sportId,
  )) as InferApi<typeof teamOfficialsApi.createTeamOfficial>;

  if (!response.error) {
    yield put(actions.createTeamOfficialSuccess());
  } else {
    yield put(actions.createTeamOfficialFailure());
  }

  return response;
}

export function* getTeamOfficialListByPerson(
  personId: number,
): Generator<Effect, any, any> {
  yield put(actions.getTeamOfficialListByPersonRequest());
  const response = (yield call(
    teamOfficialsApi.getTeamOfficialListByPerson,
    personId,
  )) as InferApi<typeof teamOfficialsApi.getTeamOfficialListByPerson>;

  if (!response.error) {
    yield put(actions.getTeamOfficialListByPersonSuccess());
  } else {
    yield put(actions.getTeamOfficialListByPersonFailure());
  }

  return response;
}

export function* deleteTeamOfficial(
  teamOfficialId: number,
): Generator<Effect, any, any> {
  yield put(actions.deleteTeamOfficialRequest());
  const response = (yield call(
    teamOfficialsApi.deleteTeamOfficial,
    teamOfficialId,
  )) as InferApi<typeof teamOfficialsApi.deleteTeamOfficial>;

  if (!response.error) {
    yield put(actions.deleteTeamOfficialSuccess());
  } else {
    yield put(actions.deleteTeamOfficialFailure());
  }

  return response;
}
