import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';

export interface BadgeProps {
  text: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    marginLeft: theme.spacing(1),
    minWidth: theme.spacing(2.5),
    width: 'auto',
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(1.25),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: '0.75rem',
    padding: `0 ${theme.spacing(1)}`,
    lineHeight: 1.75,
  },
}));

const Badge = (props: BadgeProps) => {
  const { text } = props;
  const classes = useStyles(props);

  return <Grid className={classes.badge}>{text}</Grid>;
};

export default Badge;
