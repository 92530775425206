import restService from '@core/api/rest-service';
import { MatchDurationListResponse, MatchDurationList } from '@core/types';

export const getMatchDurationList = (matchId: number) =>
  restService.get<MatchDurationListResponse>(
    `/v1/matches/${matchId}/matchDurationList`,
  );

export const postRewriteMatchDurationList = (
  matchId: number,
  data: MatchDurationList,
) =>
  restService.post(`/v1/matches/${matchId}/rewriteMatchDurationList`, {
    matchDuration: data,
  });
