import {
  call,
  all,
  take,
  put,
  debounce,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';

import { getVenues } from '@core/pages/venues/store/api/sagas';
import { handleApiErrors } from '@core/helpers';
import { getGroupCompetitors } from '@core/api/groups';
import {
  createMatch,
  patchMatch,
  deleteMatch,
} from '@core/store/modules/api/match/sagas';
import { getMatchMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { GroupCompetitor } from '@core/types';

import { actions } from '.';

const formatGroupCompetitors = (response: any) => {
  // check for childCompetitors key existence
  const childCompetitors =
    response?._embedded?.groupCompetitors?.childCompetitors;

  if (childCompetitors) {
    const competitors: Array<GroupCompetitor> = [];

    Object.keys(childCompetitors).forEach((groupId) => {
      competitors.push(...childCompetitors[groupId]);
    });

    return competitors;
  }

  return response?._embedded?.groupCompetitors;
};

function* getGroupCompetitorsFlow() {
  while (true) {
    const {
      payload: { groupId },
    } = yield take(actions.getGroupCompetitors);

    yield put(actions.getGroupCompetitorsRequest());
    const { response, error } = yield call(getGroupCompetitors, groupId);

    if (!error) {
      yield put(actions.setGroupCompetitors(formatGroupCompetitors(response)));
      yield put(actions.getGroupCompetitorsSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.getGroupCompetitorsFailure());
    }
  }
}

export function* searchVenuesFlow({
  payload,
}: InferAction<typeof actions.getVenues>) {
  yield put(actions.getVenuesRequest());
  const { query, page } = payload;

  const { error, response } = yield call(getVenues, { query, page });

  if (!error) {
    yield put(actions.getVenuesSuccess());
    yield put(actions.setVenueOptions(response));
  } else {
    yield put(actions.getVenuesFailure());
  }
}

export function* getVenuesFlow() {
  while (true) {
    const action = (yield take(actions.getVenues)) as InferAction<
      typeof actions.getVenues
    >;

    yield call(searchVenuesFlow, action);
  }
}

export function* watchSearchVenues() {
  yield debounce(500, actions.searchVenues, searchVenuesFlow);
}

export function* createFixtureMatchFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.createFixtureMatch)) as InferAction<
      typeof actions.createFixtureMatch
    >;
    const { competitionId, stageId, groupId, data } = payload;

    yield put(actions.createFixtureMatchRequest());
    const { error } = (yield call(
      createMatch,
      groupId,
      data,
    )) as SagaReturnType<typeof createMatch>;

    if (!error) {
      yield put(globalModalActions.closeModal());
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        { competitionId, stageId, matchId: null, modal: true, tabId: null },
        'create',
      );
      yield put(actions.createFixtureMatchSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.createFixtureMatchFailure());
    }
  }
}

export function* updateFixtureMatchFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.updateFixtureMatch)) as InferAction<
      typeof actions.updateFixtureMatch
    >;
    const { competitionId, stageId, matchId, data } = payload;

    yield put(actions.updateFixtureMatchRequest());
    const { error } = (yield call(patchMatch, matchId, data)) as SagaReturnType<
      typeof patchMatch
    >;

    if (!error) {
      yield put(globalModalActions.closeModal());
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        { competitionId, stageId, matchId, modal: true, tabId: null },
        'update',
      );
      yield put(actions.updateFixtureMatchSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.updateFixtureMatchFailure());
    }
  }
}

export function* deleteFixtureMatchFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.deleteFixtureMatch)) as InferAction<
      typeof actions.deleteFixtureMatch
    >;
    const { competitionId, stageId, matchId, tabId } = payload;

    yield put(actions.deleteFixtureMatchRequest());
    const { error } = (yield call(deleteMatch, matchId)) as SagaReturnType<
      typeof deleteMatch
    >;

    if (!error) {
      yield put(globalModalActions.closeModal());
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        { competitionId, stageId, matchId, modal: true, tabId },
        'delete',
      );
      yield put(actions.deleteFixtureMatchSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.deleteFixtureMatchFailure());
    }
  }
}

export default function* saga() {
  yield all([
    watchSearchVenues(),
    getVenuesFlow(),
    getGroupCompetitorsFlow(),
    createFixtureMatchFlow(),
    updateFixtureMatchFlow(),
    deleteFixtureMatchFlow(),
  ]);
}
