export const COMPETITOR_ASSIGNMENT_SELECT_TEAM_INPUT =
  'COMPETITOR_ASSIGNMENT_SELECT_TEAM_INPUT';
export const COMPETITOR_ASSIGNMENT_CREATE_TEAM_BUTTON =
  'COMPETITOR_ASSIGNMENT_CREATE_TEAM_BUTTON';
export const COMPETITOR_ASSIGNMENT_SELECT_TEAM_OPTION =
  'COMPETITOR_ASSIGNMENT_SELECT_TEAM_OPTION';
export const COMPETITOR_ASSIGNMENT_GENERAL_INFORMATION_PANEL =
  'COMPETITOR_ASSIGNMENT_GENERAL_INFORMATION_PANEL';
export const COMPETITOR_ASSIGNMENT_SAVE_COMPETITOR_BUTTON =
  'COMPETITOR_ASSIGNMENT_SAVE_COMPETITOR_BUTTON';
