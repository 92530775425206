import dayjs from 'dayjs';

import { DATE_FORMAT } from '@core/constants/common';
import {
  Group,
  StageType,
  KNOCKOUT_ROUNDS,
  BracketsType,
  EliminationType,
  DoubleEliminationType,
} from '@core/types';

import { FIELD_NAMES } from '../constants';

export const FIELDS_TO_OMIT_SYNTHETIC = [
  // synthetic fields
  // fields that do not map one-to-one with group fields
  FIELD_NAMES.numberOfPools,
  FIELD_NAMES.roundRobinGroups,
  FIELD_NAMES.knockOutElimination,
  FIELD_NAMES.knockoutDoubleElimination,
  FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups,
  FIELD_NAMES.knockOutDoubleEliminationLosersGroups,
  FIELD_NAMES.knockOutDoubleEliminationWinnersGroups,
  FIELD_NAMES.knockOutExtraGroups,
  FIELD_NAMES.knockoutSingleEliminationGroups,
];

export const FIELDS_TO_OMIT_IMMUTABLE = [
  // immutable fields once created
  FIELD_NAMES.stageType,
  FIELD_NAMES.childGroups,
  FIELD_NAMES.numberOfTeams,
  FIELD_NAMES.numberOfRounds,
  FIELD_NAMES.eliminationType,
];

export interface FormValues {
  // fields that do map one-to-one with group fields
  [FIELD_NAMES.title]: string;
  [FIELD_NAMES.internationalName]: string;
  [FIELD_NAMES.description]: string;
  [FIELD_NAMES.numberOfTeams]: number;
  [FIELD_NAMES.stageType]: StageType;
  [FIELD_NAMES.childGroups]: Array<any>;
  [FIELD_NAMES.numberOfRounds]: number;
  [FIELD_NAMES.startDate]: string;
  [FIELD_NAMES.endDate]: string;
  [FIELD_NAMES.knockoutRound]: KNOCKOUT_ROUNDS;
  // fields that do not map one-to-one with group fields
  [FIELD_NAMES.numberOfPools]: number;
  [FIELD_NAMES.roundRobinGroups]: Array<any>;
  [FIELD_NAMES.knockOutElimination]: EliminationType;
  [FIELD_NAMES.knockoutDoubleElimination]: DoubleEliminationType;
  [FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups]: Array<any>;
  [FIELD_NAMES.knockOutDoubleEliminationLosersGroups]: Array<any>;
  [FIELD_NAMES.knockOutDoubleEliminationWinnersGroups]: Array<any>;
  [FIELD_NAMES.knockOutExtraGroups]: Array<any>;
  [FIELD_NAMES.knockoutSingleEliminationGroups]: Array<any>;
  [FIELD_NAMES.thirdPlaceMatch]?: boolean;
}

export const getGroupedGroups = (group: Group) => {
  interface Options {
    bracketsType?: BracketsType;
    eliminationType?: EliminationType;
    extra?: boolean;
    stageType: StageType;
  }

  const getGroups = (options: Options) => {
    const { bracketsType, eliminationType, stageType, extra = false } = options;

    return (
      group?.childGroups?.filter(
        (childGroup) =>
          childGroup.stageType === stageType &&
          (eliminationType
            ? childGroup.eliminationType === eliminationType
            : true) &&
          (bracketsType ? childGroup.bracketsType === bracketsType : true) &&
          childGroup.extraRound === extra,
      ) ?? []
    );
  };

  const getKnockoutGroups = (options: Omit<Options, 'stageType'>) =>
    getGroups({ stageType: StageType.KNOCK_OUT, ...options });

  const getRoundRobinGroups = () =>
    getGroups({ stageType: StageType.ROUND_ROBIN });

  const roundRobinGroups = getRoundRobinGroups();
  const knockoutDoubleEliminationCrossOverGroups = getKnockoutGroups({
    eliminationType: EliminationType.DOUBLE,
    bracketsType: BracketsType.CROSSOVER,
  });
  const knockoutDoubleEliminationLosersGroups = getKnockoutGroups({
    eliminationType: EliminationType.DOUBLE,
    bracketsType: BracketsType.LOSERS,
  });
  const knockoutDoubleEliminationWinnerssGroups = getKnockoutGroups({
    eliminationType: EliminationType.DOUBLE,
    bracketsType: BracketsType.WINNERS,
  });
  const knockoutExtraGroups = getKnockoutGroups({ extra: true });
  const knockoutSingleEliminationGroups = getKnockoutGroups({
    eliminationType: EliminationType.SINGLE,
  });

  return {
    roundRobinGroups,
    knockoutDoubleEliminationCrossOverGroups,
    knockoutDoubleEliminationLosersGroups,
    knockoutDoubleEliminationWinnerssGroups,
    knockoutExtraGroups,
    knockoutSingleEliminationGroups,
  };
};

export const getInitialValues = (group: Group): FormValues => {
  const {
    roundRobinGroups,
    knockoutDoubleEliminationCrossOverGroups,
    knockoutDoubleEliminationLosersGroups,
    knockoutDoubleEliminationWinnerssGroups,
    knockoutExtraGroups,
    knockoutSingleEliminationGroups,
  } = getGroupedGroups(group);

  const addIndex = (value: Group, index: number) => ({
    ...value,
    // Add indexes for formik array field/material-table
    index,
  });

  return {
    // fields that do map one-to-one with group fields
    [FIELD_NAMES.title]: group?.title || '',
    [FIELD_NAMES.internationalName]: group?.internationalName || null,
    [FIELD_NAMES.description]: group?.description || null,
    [FIELD_NAMES.numberOfTeams]: group?.numberOfTeams || 2,
    [FIELD_NAMES.stageType]: group?.stageType || StageType.ROUND_ROBIN,
    [FIELD_NAMES.childGroups]: group?.childGroups || [],
    [FIELD_NAMES.numberOfRounds]: group?.numberOfRounds || 1,
    [FIELD_NAMES.startDate]: group?.startDate || dayjs().format(DATE_FORMAT),
    [FIELD_NAMES.endDate]: group?.endDate || null,
    [FIELD_NAMES.knockoutRound]: group?.knockoutRound || null,
    // fields that do not map one-to-one with group fields
    [FIELD_NAMES.numberOfPools]: roundRobinGroups.length,
    [FIELD_NAMES.roundRobinGroups]: roundRobinGroups.map(addIndex),
    [FIELD_NAMES.knockOutElimination]:
      group?.eliminationType || EliminationType.SINGLE,
    [FIELD_NAMES.knockoutDoubleElimination]:
      knockoutDoubleEliminationCrossOverGroups.length
        ? DoubleEliminationType.CROSSOVER
        : DoubleEliminationType.CLASSIC,
    [FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups]:
      knockoutDoubleEliminationCrossOverGroups.map(addIndex),
    [FIELD_NAMES.knockOutDoubleEliminationLosersGroups]:
      knockoutDoubleEliminationLosersGroups.map(addIndex),
    [FIELD_NAMES.knockOutDoubleEliminationWinnersGroups]:
      knockoutDoubleEliminationWinnerssGroups.map(addIndex),
    [FIELD_NAMES.knockOutExtraGroups]: knockoutExtraGroups.map(addIndex),
    [FIELD_NAMES.knockoutSingleEliminationGroups]:
      knockoutSingleEliminationGroups.map(addIndex),
  };
};
