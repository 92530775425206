import { CompetitionsResponse } from '@core/types';
import restService from './rest-service';

export const getUsers = (queryParams?: URLSearchParams) =>
  restService.get(`/v1/users`, { params: queryParams });

export const getAssignedCompetitions = (
  personId: number,
  queryParams?: { page?: number },
) =>
  restService.get<CompetitionsResponse>(
    `v1/regionalCompetitionManagers/${personId}/assignments`,
    {
      params: { ...queryParams },
    },
  );

export const getCompetitionAssignedPersons = (
  competitionId: number,
  queryParams?: { page?: number },
) =>
  restService.get<CompetitionsResponse>(
    `v1/competitions/${competitionId}/assignments`,
    {
      params: { ...queryParams },
    },
  );

export const getAllCompetitions = (queryParams?: URLSearchParams) =>
  restService.get<CompetitionsResponse>('/v1/competitions', {
    params: queryParams,
  });

export const unassignRegionalCompetitionManager = (
  competitionId: number,
  regionalCompetitionManagerId: number,
) =>
  restService.post(
    `/v1/competitions/${competitionId}/regionalCompetitionManagers/unassign`,
    {
      regionalCompetitionManagerId,
    },
  );

export const assignRegionalCompetitionManager = (
  competitionId: number,
  regionalCompetitionManagerId: number,
) =>
  restService.post(
    `/v1/competitions/${competitionId}/regionalCompetitionManagers/assign`,
    {
      regionalCompetitionManagerId,
    },
  );
