export const DEFAULT_LANGUAGE = 'en';
export const AVAILABLE_LANGUAGES = ['en', 'es', 'fr', 'de'];

export const mapLocaleToTermsLang = (lang: string) => {
  switch (lang) {
    case 'en':
    case 'en-EN':
    case 'en-US':
      return 'en';
    case 'fr':
    case 'fr-FR':
      return 'fr';
    case 'es':
    case 'es-ES':
      return 'es';
    case 'de':
    case 'de-DE':
      return 'de';
    default:
      return DEFAULT_LANGUAGE;
  }
};

export const mapTermsLangToLocale = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'en-US';
    case 'fr':
      return 'fr-FR';
    case 'es':
      return 'es-ES';
    case 'de':
      return 'en-US';
    default:
      return DEFAULT_LANGUAGE;
  }
};
