import { ChangeEvent } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterModel, FilterValue } from '@core/types';
import FullScreenSpinner from '../full-screen-spinner';

type Props = {
  handleFilterAdd: (filter: FilterModel) => void;
  handleFilterRemove: (filter: FilterModel) => void;
  filterValues: Array<FilterValue>;
  list: Array<any>;
  filterProps: Partial<FilterModel>;
  formatFilterLabel: (val: string) => string;
  formatLabel?: (val: any) => string;
  shifted?: boolean;
  isLoading?: boolean;
};

const useStyles = makeStyles({
  checkbox: {
    marginLeft: 9,
  },
});

const FilterCheckboxList = ({
  list,
  shifted,
  handleFilterAdd,
  handleFilterRemove,
  filterValues,
  filterProps,
  formatFilterLabel,
  formatLabel,
  isLoading,
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const filter = {
      ...filterProps,
      filterValues: {
        value,
        label: formatFilterLabel(value),
      },
    } as FilterModel;

    event.target.checked ? handleFilterAdd(filter) : handleFilterRemove(filter);
  };

  const isChecked = (checkboxValue: string) =>
    filterValues
      ?.map((filterValue) => filterValue.value)
      .includes(checkboxValue);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return (
    <>
      {list.map((item) => (
        <FormGroup key={item.id || item}>
          <FormControlLabel
            control={
              <Checkbox
                className={shifted ? classes.checkbox : ''}
                onChange={handleChange}
                value={item.id || item}
                checked={isChecked(String(item.id || item))}
              />
            }
            label={formatLabel ? formatLabel(item) : item.title}
          />
        </FormGroup>
      ))}
    </>
  );
};

export default FilterCheckboxList;
