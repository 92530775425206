import { createActions } from 'redux-actions';

export const NAMESPACE = 'clubPlayers';

export const actions = createActions(
  'GET_CLUB_PLAYERS_BY_SPORT_REQUEST',
  'GET_CLUB_PLAYERS_BY_SPORT_SUCCESS',
  'GET_CLUB_PLAYERS_BY_SPORT_FAILURE',

  'CREATE_CLUB_PLAYER_REQUEST',
  'CREATE_CLUB_PLAYER_SUCCESS',
  'CREATE_CLUB_PLAYER_FAILURE',

  'UPDATE_PLAYER_ATTRIBUTES_REQUEST',
  'UPDATE_PLAYER_ATTRIBUTES_SUCCESS',
  'UPDATE_PLAYER_ATTRIBUTES_FAILURE',

  'GET_PLAYER_ATTRIBUTES_REQUEST',
  'GET_PLAYER_ATTRIBUTES_SUCCESS',
  'GET_PLAYER_ATTRIBUTES_FAILURE',

  'GET_PLAYER_LIST_BY_PERSON_REQUEST',
  'GET_PLAYER_LIST_BY_PERSON_SUCCESS',
  'GET_PLAYER_LIST_BY_PERSON_FAILURE',

  'DELETE_PLAYER_ATTRIBUTES_REQUEST',
  'DELETE_PLAYER_ATTRIBUTES_SUCCESS',
  'DELETE_PLAYER_ATTRIBUTES_FAILURE',

  'DELETE_PLAYER_REQUEST',
  'DELETE_PLAYER_SUCCESS',
  'DELETE_PLAYER_FAILURE',

  { prefix: NAMESPACE },
);
