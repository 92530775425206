import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Radio,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import { ColumnWithSubValue, CustomTable } from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { formatDate, formatTime, getVenue } from '@core/helpers';
import { Column } from '@core/components/material-table';
import { CustomerResponse, GlobalModalProps, MatchModel } from '@core/types';
import { State } from '@volleyball/store';
import { actions as sanctionActions } from '@volleyball/store/modules/tabs/impose-sanction';
import {
  getMatches,
  getCurrentCompetitor,
} from '@volleyball/store/modules/tabs/impose-sanction/selectors';
import { SanctionType } from '@volleyball/types';
import { ButtonWithProgress } from '@ui-components';

interface StateProps {
  currentCustomer: CustomerResponse;
}

type OwnProps = GlobalModalProps<{
  competitionId: number;
  setMatch: (match: MatchModel) => void;
  sanctionType: SanctionType;
}>;

interface DispatchProps {
  actions?: {
    sanctions: typeof sanctionActions;
  };
}

type Props = StateProps & DispatchProps & OwnProps;

const isMatchesLoading = createLoadingSelector([
  sanctionActions.getMatchesRequest.toString(),
]);

const SelectMatchModal = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [filter, setFilter] = useState(null);
  const { isModalOpen, modalActions, modalParams, currentCustomer } = props;

  const competitionId = modalParams?.competitionId;
  const sanctionType = modalParams?.sanctionType;
  const isLoading = useSelector((state: State) => isMatchesLoading(state));
  const matches = useSelector((state: State) =>
    getMatches(state, competitionId, sanctionType),
  );
  const currentCompetitor = useSelector((state: State) =>
    getCurrentCompetitor(state, competitionId, sanctionType),
  );

  useEffect(() => {
    const defaultFilter = [
      ['filter[matchGroup.competition]', competitionId.toString()],
      ['filter[status]', 'COMPLETE'],
    ];
    currentCompetitor &&
      defaultFilter.push(['filter[competitor]', currentCompetitor]);
    setFilter(defaultFilter);
    dispatch({
      type: sanctionActions.getMatches.toString(),
      payload: {
        competitionId,
        sanctionType,
        queryParams: new URLSearchParams([
          ['page', '1'],
          ['query', ''],
          ...defaultFilter,
        ]),
      },
    });
  }, []);

  function handleClose() {
    dispatch({
      type: sanctionActions.getMatches.toString(),
      payload: {
        competitionId,
        sanctionType,
        queryParams: new URLSearchParams([
          ['page', '1'],
          ['query', ''],
          ...filter,
        ]),
      },
    });
    modalActions.closeModal();
  }

  function handleAddMatch() {
    selectedMatch && modalParams.setMatch(selectedMatch);
    modalActions.closeModal();
  }

  function handleRowSelect(match: MatchModel) {
    return () => setSelectedMatch(match);
  }

  const columns: Array<Column<MatchModel>> = [
    {
      title: '',
      render: (rowData) => (
        <Radio
          checked={selectedMatch?.id === rowData.id}
          onChange={handleRowSelect(rowData)}
        />
      ),
    },
    {
      title: t('Date & Time'),
      render: (rowData) => {
        const dateTime = `${rowData.date} ${rowData.time}`;

        return rowData.date && rowData.time ? (
          <ColumnWithSubValue
            value={formatDate(currentCustomer.dateFormat, dateTime)}
            subValue={formatTime(currentCustomer.timeFormat, dateTime)}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Home team'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={rowData.homeCompetitorTitle}
          subValue={rowData.homeCompetitorInternationalTitle}
        />
      ),
    },
    {
      title: t('Away team'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={rowData.awayCompetitorTitle}
          subValue={rowData.awayCompetitorInternationalTitle}
        />
      ),
    },
    {
      title: t('Venue'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={getVenue(rowData.venue)?.title}
          subValue={getVenue(rowData.venue)?.internationalTitle}
        />
      ),
    },
  ];

  function loadMatches({ page }: { page: number }) {
    dispatch({
      type: sanctionActions.getMatches.toString(),
      payload: { competitionId, sanctionType, queryParams: { page } },
    });
  }

  function getPaginationValues() {
    return {
      reloadData: loadMatches,
      page: matches?.page || 1,
      pages: matches?.pages,
      limit: matches?.limit || 10,
      total: matches?.total || 0,
    };
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t('Add related match')}</DialogTitle>
      <DialogContent style={{ paddingBottom: 24 }}>
        <CustomTable
          columns={columns}
          data={matches?.list || []}
          pagination={matches && getPaginationValues()}
          isLoading={isLoading}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleClose}>{t('Cancel')}</Button>
          </Box>
          <ButtonWithProgress
            color="primary"
            variant="contained"
            onClick={handleAddMatch}
          >
            {t('Add')}
          </ButtonWithProgress>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps, null)(SelectMatchModal);
