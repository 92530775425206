import dayjs from 'dayjs';

const FAKE_DAY = '2000-01-01';

export const getTimeDiff = (timeStart: string, timeEnd: string) => {
  if (!timeStart || !timeEnd) {
    return null;
  }

  const dateStart = dayjs(`${FAKE_DAY} ${timeStart}`);
  const dateEnd = dayjs(`${FAKE_DAY} ${timeEnd}`);

  return dateEnd.diff(dateStart, 'minute');
};
