import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { GroupStandingsColumnLabels } from '@volleyball/types';

import * as actions from './actions';

export interface DefaultState {
  byGroupId: {
    [groupId: string]: {
      heading: Array<{ name: string; label: GroupStandingsColumnLabels }>;
      data: Array<any>;
    };
  };
}

export const defaultState: DefaultState = { byGroupId: {} };

export const initializedState = { byGroupId: {} };

const reducer = handleActions<any>(
  {
    // [actions.initializeGroupStandingsStore.toString()]: (state, { meta }) => assocPath([meta.groupId], initializedState, state),
    [actions.setGroupStandings.toString()]: (state, { payload }) =>
      assocPath([payload.groupId], payload, state),
    [actions.setGroupStandingsHeader.toString()]: (state, { payload }) =>
      assocPath([payload.groupId, 'header'], payload, state),
  },
  defaultState,
);

export default reducer;
