export const NAMESPACE = 'competitions';

export const SET_COMPETITION_BEING_MODIFIED = `${NAMESPACE}/SET_COMPETITION_BEING_MODIFIED`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;

export const UPDATE_COMPETITION = `${NAMESPACE}/UPDATE_COMPETITION`;
export const UPDATE_DRAFT_COMPETITION = `${NAMESPACE}/UPDATE_DRAFT_COMPETITION`;
export const UPDATE_COMPETITION_REQUEST = `${NAMESPACE}/UPDATE_COMPETITION_REQUEST`;
export const UPDATE_COMPETITION_SUCCESS = `${NAMESPACE}/UPDATE_COMPETITION_SUCCESS`;
export const UPDATE_COMPETITION_FAILURE = `${NAMESPACE}/UPDATE_COMPETITION_FAILURE`;

export const CREATE_COMPETITION = `${NAMESPACE}/CREATE_COMPETITION`;
export const CREATE_COMPETITION_REQUEST = `${NAMESPACE}/CREATE_COMPETITION_REQUEST`;
export const CREATE_COMPETITION_SUCCESS = `${NAMESPACE}/CREATE_COMPETITION_SUCCESS`;
export const CREATE_COMPETITION_FAILURE = `${NAMESPACE}/CREATE_COMPETITION_FAILURE`;

export const CREATE_ROLLOVER_COMPETITION = `${NAMESPACE}/CREATE_ROLLOVER_COMPETITION`;
export const CREATE_ROLLOVER_COMPETITION_REQUEST = `${NAMESPACE}/CREATE_ROLLOVER_COMPETITION_REQUEST`;
export const CREATE_ROLLOVER_COMPETITION_SUCCESS = `${NAMESPACE}/CREATE_ROLLOVER_COMPETITION_SUCCESS`;
export const CREATE_ROLLOVER_COMPETITION_FAILURE = `${NAMESPACE}/CREATE_ROLLOVER_COMPETITION_FAILURE`;

export const DELETE_COMPETITION = `${NAMESPACE}/DELETE_COMPETITION`;
export const DELETE_COMPETITION_REQUEST = `${NAMESPACE}/DELETE_COMPETITION_REQUEST`;
export const DELETE_COMPETITION_SUCCESS = `${NAMESPACE}/DELETE_COMPETITION_SUCCESS`;
export const DELETE_COMPETITION_FAILURE = `${NAMESPACE}/DELETE_COMPETITION_FAILURE`;

export const GET_COMPETITION = `${NAMESPACE}/GET_COMPETITION`;
export const GET_COMPETITION_REQUEST = `${NAMESPACE}/GET_COMPETITION_REQUEST`;
export const GET_COMPETITION_SUCCESS = `${NAMESPACE}/GET_COMPETITION_SUCCESS`;
export const GET_COMPETITION_FAILURE = `${NAMESPACE}/GET_COMPETITION_FAILURE`;

export const GET_COMPETITIONS = `${NAMESPACE}/GET_COMPETITIONS`;
export const GET_COMPETITIONS_REQUEST = `${NAMESPACE}/GET_COMPETITIONS_REQUEST`;
export const GET_COMPETITIONS_SUCCESS = `${NAMESPACE}/GET_COMPETITIONS_SUCCESS`;
export const GET_COMPETITIONS_FAILURE = `${NAMESPACE}/GET_COMPETITIONS_FAILURE`;

export const GET_ROLLOVER_COMPETITIONS = `${NAMESPACE}/GET_ROLLOVER_COMPETITIONS`;
export const GET_ROLLOVER_COMPETITIONS_REQUEST = `${NAMESPACE}/GET_ROLLOVER_COMPETITIONS_REQUEST`;
export const GET_ROLLOVER_COMPETITIONS_SUCCESS = `${NAMESPACE}/GET_ROLLOVER_COMPETITIONS_SUCCESS`;
export const GET_ROLLOVER_COMPETITIONS_FAILURE = `${NAMESPACE}/GET_ROLLOVER_COMPETITIONS_FAILURE`;

export const CHANGE_COMPETITION_STATUS = `${NAMESPACE}/CHANGE_COMPETITION_STATUS`;
export const CHANGE_COMPETITION_STATUS_REQUEST = `${NAMESPACE}/CHANGE_COMPETITION_STATUS_REQUEST`;
export const CHANGE_COMPETITION_STATUS_SUCCESS = `${NAMESPACE}/CHANGE_COMPETITION_STATUS_SUCCESS`;
export const CHANGE_COMPETITION_STATUS_FAILURE = `${NAMESPACE}/CHANGE_COMPETITION_STATUS_FAILURE`;

export const SET_COMPETITIONS = `${NAMESPACE}/SET_COMPETITIONS`;
export const SET_ROLLOVER_COMPETITIONS = `${NAMESPACE}/SET_ROLLOVER_COMPETITIONS`;
export const SET_COMPETITIONS_HAL_LINKS = `${NAMESPACE}/SET_COMPETITIONS_HAL_LINKS`;

export const GET_DRAFT_COMPETITION = `${NAMESPACE}/GET_DRAFT_COMPETITION`;

export const SUBMIT_COMPETITION = `${NAMESPACE}/SUBMIT_COMPETITION`;
export const SET_SORTING_ORDER = `${NAMESPACE}/SET_SORTING_ORDER`;
