import restService from '@core/api/rest-service';
import { MatchSheetHistoryResponse } from '@core/types';

export const getMatchSheetHistory = (
  matchId: number,
  competitorId: number,
  queryParams: any = { page: 1 },
) =>
  restService.get<MatchSheetHistoryResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchSheetHistory`,
    {
      params: queryParams,
    },
  );
