import React from 'react';
import {
  Box,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  PopperProps,
  Typography,
  Link,
  Button,
} from '@mui/material';
import {
  SportsVolleyball,
  RoomOutlined,
  ScheduleOutlined,
  ArrowForward,
} from '@mui/icons-material';
import { uniqBy, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { uuid } from '@core/helpers';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modifier } from '@popperjs/core';
import { ArrowModifier } from '@popperjs/core/lib/modifiers/arrow';
import { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';

import { AlertStatus, DottedText } from '@core/components';
import { useCurrentUser } from '@core/hooks';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import {
  createURL,
  truncateString,
  getMatchStatus,
  getVenue,
  getSubVenue,
} from '@core/helpers';
import {
  FIXTURE_CALENDAR_MATCH_ACTION_BUTTON,
  FIXTURE_CALENDAR_MATCH_LINK,
  FIXTURE_CALENDAR_MATCH_POPUP,
} from '@core/pages/dashboard/tests/test-ids';
import {
  areCompetitorsPresent,
  getCompetitorTitleFactory,
} from '@core/components/match-table';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import paths from '@core/routes/paths';
import { Conflict, MatchFixture } from '@core/types';

import { popupStyles, popperArrowClassName } from '../styles';
import { getConflictMessage } from '../helpers';

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

interface OwnProps {
  open: boolean;
  data: MatchFixture;
  placement: PopperProps['placement'];
  anchorEl: PopperProps['anchorEl'];
  handleClose: (event: React.MouseEvent<Document> | any) => void;
}

interface InfoEntryProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  onClick?: (event?: any) => void;
}

type Props = OwnProps & DispatchProps;

const FixtureCalendarPopup = (props: Props) => {
  const {
    actions,
    anchorEl,
    handleClose,
    data: match,
    open,
    placement,
  } = props;
  const { t } = useTranslation();
  const {
    dateLocalized: date,
    timeLocalized: time,
    venue,
    number: matchNumber,
    status: matchStatus,
    conflicts,
  } = match;

  const isMatchAvailable = areCompetitorsPresent(match);

  const classes = popupStyles({ isMatchAvailable });

  const competitionTitle = match.competitionTitle ?? '';
  const season = match.seasonTitle ?? '-';
  const groupTitle = match.groupTitle ?? '-';
  const matchDay = match.matchDayNumber ?? '-';
  const matchTitle = `${competitionTitle} • ${season} • ${groupTitle} • ${t(
    'MD',
  )} ${matchDay}`;

  const getCompetitorTitle = getCompetitorTitleFactory(t);
  const homeCompetitorTitle = getCompetitorTitle(match, 'home', true);
  const awayCompetitorTitle = getCompetitorTitle(match, 'away', true);

  const { accessLinks } = useCurrentUser();

  const InfoEntry = ({ icon, title, onClick }: InfoEntryProps) =>
    onClick ? (
      <Link
        data-match-link-id={match.id}
        data-qa={FIXTURE_CALENDAR_MATCH_LINK}
        className={clsx(classes.infoEntry, classes.infoLink)}
        variant="caption"
        onClick={onClick}
      >
        {icon}
        {title}
      </Link>
    ) : (
      <Typography className={classes.infoEntry} variant="caption">
        {icon}
        {title}
      </Typography>
    );

  const renderWarnings = (input: Array<Conflict>) => {
    if (!input?.length) {
      return null;
    }

    const warnings = input?.map((conflict, key) => ({
      message: getConflictMessage(t, conflict.type),
      level: conflict.alertLevel,
      key,
    }));
    const alerts = uniqBy(prop('message'), warnings).map(
      ({ message, level, key }) => (
        <AlertStatus key={key} title={message} severity={level} />
      ),
    );

    return <Box mb={2}>{alerts}</Box>;
  };

  const handleMatchClick = (event: any) => {
    handleClose(event);
    const tabTitle = `${truncateString(
      homeCompetitorTitle,
      20,
    )} | ${truncateString(awayCompetitorTitle, 20)}`;
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.matchProfile),
      props: {
        competitionId: match.competitionId,
        stageId: undefined,
        matchId: match.id,
        callbacks: {
          onRemoveCallback: () => {
            actions.matchSheetManagement.resetMatchSheetHistory({
              matchId: match.id,
            });
          },
        },
      } as TabbedProps<any>,
      title: tabTitle,
      id: uuid(),
    });
  };

  const handleCompetitionClick = (event: any) => {
    handleClose(event);
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.competitionProfile),
      title: competitionTitle,
      props: { id: match.competitionId } as TabbedProps<any>,
      id: uuid(),
    });
  };

  const [arrowRef, setArrowRef] = React.useState(null);
  const modifiers = React.useMemo<Array<Modifier<any, any>>>(
    () => [
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowRef,
          padding: 0,
        },
      } as ArrowModifier,
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: false,
        },
      } as FlipModifier,
      {
        name: 'offset',
        enabled: false,
        options: {
          offset: [0, 0],
        },
      } as OffsetModifier,
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          mainAxis: true,
          altAxis: false,
          padding: 0,
          boundary: 'clippingParents',
          altBoundary: true,
          rootBoundary: 'viewport',
          tether: false,
          tetherOffset: 0,
        },
      } as PreventOverflowModifier,
    ],
    [arrowRef],
  );

  const venueObj = getVenue(venue);
  const venueEntries = [
    venueObj?.title || venueObj?.internationalTitle || t('Venue unknown'),
  ];

  const subVenue = getSubVenue(venue);
  subVenue?.title && venueEntries.push(subVenue.title);

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={modifiers}
    >
      <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleClose}>
        <Paper
          elevation={4}
          data-match-popup-id={match.id}
          data-qa={FIXTURE_CALENDAR_MATCH_POPUP}
        >
          <div ref={setArrowRef} className={popperArrowClassName} />
          <Box padding={2}>
            <Typography variant="subtitle1" className={classes.title}>
              {homeCompetitorTitle} – {awayCompetitorTitle}
            </Typography>
            <Box mb={1.5}>
              <Typography
                className={classes.subtitle}
                color="textSecondary"
                variant="caption"
              >
                {matchTitle}
              </Typography>
            </Box>
            {renderWarnings(conflicts)}
            <InfoEntry
              icon={<ScheduleOutlined className={classes.infoIcon} />}
              title={`${date} ${time}`}
            />
            <InfoEntry
              icon={<RoomOutlined className={classes.infoIcon} />}
              title={<DottedText textEntries={venueEntries} />}
            />{' '}
            <InfoEntry
              icon={<SportsVolleyball className={classes.infoIcon} />}
              title={`${t('Match no.')} ${matchNumber} • ${getMatchStatus(
                t,
                matchStatus,
              )}`}
              onClick={isMatchAvailable && handleMatchClick}
            />
          </Box>
          {accessLinks.competitions && (
            <>
              <Divider />
              <Box padding={1.5} display="flex" justifyContent="flex-end">
                <Button
                  data-match-action-id={match.id}
                  data-qa={FIXTURE_CALENDAR_MATCH_ACTION_BUTTON}
                  color="primary"
                  onClick={handleCompetitionClick}
                  endIcon={<ArrowForward />}
                >
                  {t('View competition')}
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(null, mapDispatchToProps)(FixtureCalendarPopup);
