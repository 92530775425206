import React from 'react';
import { Grid, Typography, Box, Paper, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';

import { TextField, FullScreenSpinner } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { COMPETITOR_GENERAL_INFO_FIELDS, Competitor } from '@core/types';
import { actions as competitorGeneralInformationEditActions } from '@core/store/modules/tabs/competitor-general-information-edit';
import { State } from '@core/store';
import { getCompetitor } from '@core/store/modules/tabs/competitor-general-information-edit/selectors';

import FormActionButtons from './form-action-buttons';
import DefaultVenueSearch from './default-venue-search';
import AlternativeVenueSearch from './alternate-venue-search';

interface StateProps {
  isLoading: boolean;
  isSubmitting: boolean;
  competitor: Competitor;
}

interface OwnProps {
  tabId: string;
  competitorId: number;
}

type Props = StateProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
}));

const GeneralInformationForm = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading, isSubmitting, tabId, competitorId, competitor } = props;

  const renderForm = () => {
    return (
      <Box paddingX={3} paddingY={1}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Box mb={3}>
              <Typography variant="caption" color="textSecondary">
                {`* ${t('Indicates a required field')}`}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={2}>
              <Typography variant="caption" className={classes.uppercase}>
                {t('Team title')}
              </Typography>
            </Grid>
            <Grid container item xs={12} md={8} spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextField
                    name={COMPETITOR_GENERAL_INFO_FIELDS.teamTitle}
                    data-qa={COMPETITOR_GENERAL_INFO_FIELDS.teamTitle}
                    required
                    label={t('Team title')}
                    withTooltipMargin={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextField
                    name={COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle}
                    data-qa={
                      COMPETITOR_GENERAL_INFO_FIELDS.internationalTeamTitle
                    }
                    label={t('International team title')}
                    withTooltipMargin={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Box mb={2} mt={1} flex={1}>
              <Divider orientation="horizontal" />
            </Box>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={2}>
              <Typography variant="caption" className={classes.uppercase}>
                {t('Team venues')}
              </Typography>
            </Grid>
            <Grid container item xs={12} md={8} spacing={2}>
              <Grid item xs={12} md={6}>
                {competitor && (
                  <DefaultVenueSearch competitorId={competitorId} />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {competitor && (
                  <AlternativeVenueSearch competitorId={competitorId} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Form>
      <Paper>
        <Box paddingX={3} paddingY={3}>
          <Box mb={2}>
            <Typography variant="h6">
              {t('Edit general information')}
            </Typography>
          </Box>
        </Box>
        <Grid container item xs={12}>
          <Box mb={2} flex={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Grid>
        <Box pb={3}>{isLoading ? <FullScreenSpinner /> : renderForm()}</Box>
      </Paper>
      <FormActionButtons isSubmitting={isSubmitting} tabId={tabId} />
    </Form>
  );
};

const isLoadingSelector = createLoadingSelector([
  competitorGeneralInformationEditActions.getCompetitorRequest.toString(),
]);

const isSubmittingSelector = createLoadingSelector([
  competitorGeneralInformationEditActions.updateCompetitorRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isLoading: isLoadingSelector(state),
  isSubmitting: isSubmittingSelector(state),
  competitor: getCompetitor(state, { competitorId: ownProps?.competitorId }),
});

export default connect(mapStateToProps)(GeneralInformationForm);
