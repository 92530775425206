import { createActions } from 'redux-actions';

export const NAMESPACE = 'teamOfficialRole';

export const actions = createActions(
  'GET_TEAM_OFFICIAL_ROLE_REQUEST',
  'GET_TEAM_OFFICIAL_ROLE_SUCCESS',
  'GET_TEAM_OFFICIAL_ROLE_FAILURE',
  { prefix: NAMESPACE },
);
