import { object, string, number } from 'yup';
import { TFunction } from 'i18next';

import { validateStringAgainstDayjsFormat } from '@core/helpers';

import { FIELD_NAMES } from '../constants';

const getValidationSchema = (t: TFunction) =>
  object().shape({
    [FIELD_NAMES.title]: string().required(t('Required')),
    [FIELD_NAMES.startDate]: string()
      .required(t('Required'))
      .test('is-valid-date-format', t('Invalid date'), (value) =>
        validateStringAgainstDayjsFormat(value, 'YYYY-MM-DD'),
      ),
    [FIELD_NAMES.endDate]: string()
      .nullable()
      .test('is-valid-date-format', t('Invalid date'), (value) =>
        validateStringAgainstDayjsFormat(value, 'YYYY-MM-DD', true),
      ),
    [FIELD_NAMES.competitionSeasonId]: number().required(t('Required')),
    [FIELD_NAMES.sportId]: number().required(t('Required')),
    [FIELD_NAMES.gender]: string().required(t('Required')),
    [FIELD_NAMES.ageGroupId]: number().required(t('Required')),
    [FIELD_NAMES.competitionLevelId]: number().required(t('Required')),
    [FIELD_NAMES.participatingTeams]: number()
      .required(t('Required'))
      .min(2, t('Minimum number of teams is {{value}}', { value: 2 })),
    [FIELD_NAMES.clubType]: string().required(t('Required')),
    [FIELD_NAMES.geography]: string().required(t('Required')),
  });

export default getValidationSchema;
