import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Alert,
  DialogActions,
  Button,
  Box,
  Typography,
  Theme,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReportProblemOutlined } from '@mui/icons-material';
import { object, string, number } from 'yup';
import { Form, Formik } from 'formik';

import { TextField, ConnectedText } from '@core/components';
import { getMatchStatus } from '@core/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { GlobalModalProps, MatchStatus, Group, MatchModel } from '@core/types';
import { actions as matchesActions } from '@core/store/modules/matches';
import { State } from '@core/store';
import * as testIds from './tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

const useStyles = makeStyles((theme: Theme) => ({
  dialogText: {
    margin: `${theme.spacing(2)}px 0`,
    marginTop: 0,
  },
  dialogConfirm: {
    marginBottom: theme.spacing(1),
  },
  dialogConfirmText: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subtitle: {
    textTransform: 'none',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    lineHeight: '19px',
  },
  infoBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  logoWrapper: {
    marginRight: 12,
  },
}));

interface DispatchProps {
  actions: {
    matches: typeof matchesActions;
  };
}

interface StateProps {
  isLoading: boolean;
}

enum CHANGE_STATUS_FORM_FIELDS {
  changeStatusReason = 'changeStatusReason',
  homeCompetitorScore = 'homeCompetitorScore',
  awayCompetitorScore = 'awayCompetitorScore',
}

interface FormValues {
  [CHANGE_STATUS_FORM_FIELDS.changeStatusReason]: string;
  [CHANGE_STATUS_FORM_FIELDS.homeCompetitorScore]: number | null;
  [CHANGE_STATUS_FORM_FIELDS.awayCompetitorScore]: number | null;
}

export interface OwnProps {
  stageId: number;
  stage: Group;
  matchId: number;
  match: MatchModel;
  status: MatchStatus;
}

type Props = GlobalModalProps<OwnProps> & DispatchProps & StateProps;

const ChangeStageStatusModal = (props: Props) => {
  const { isModalOpen, modalActions, actions, modalParams, isLoading } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isConfirmed, setConfirmed] = useState(false);

  const matchId = modalParams?.matchId;
  const fromStatus = modalParams?.match?.status;
  const toStatus = modalParams?.status;

  const handleChangeStatus = () => {
    actions.matches.changeMatchStatus({
      matchId,
      status: toStatus,
      reasonDescription: undefined,
      competitionId: modalParams?.match?.competitionId,
      onSuccessEffectParameters: {
        competitionId: modalParams?.match?.competitionId,
        stageId: modalParams.stageId ?? modalParams.stage.id,
        matchId,
        modal: true,
        tabId: null,
      },
    });
  };

  const handleClose = () => modalActions.closeModal();

  const handleScheduledStatusChange = (values: FormValues) => {
    actions.matches.changeMatchStatus({
      matchId,
      status: toStatus,
      reasonDescription: values[CHANGE_STATUS_FORM_FIELDS.changeStatusReason],
      competitionId: modalParams?.match?.competitionId,
      onSuccessEffectParameters: {
        competitionId: modalParams?.match?.competitionId,
        stageId: modalParams.stageId ?? modalParams.stage.id,
        matchId,
        modal: true,
        tabId: null,
      },
    });
  };

  const getValidationSchema = () =>
    object().shape({
      [CHANGE_STATUS_FORM_FIELDS.changeStatusReason]: string().required(
        t('Required'),
      ),
      [CHANGE_STATUS_FORM_FIELDS.homeCompetitorScore]: number().nullable(),
      [CHANGE_STATUS_FORM_FIELDS.awayCompetitorScore]: number().nullable(),
    });

  const getInitialValues = (): FormValues => ({
    [CHANGE_STATUS_FORM_FIELDS.changeStatusReason]: '',
    [CHANGE_STATUS_FORM_FIELDS.homeCompetitorScore]: null,
    [CHANGE_STATUS_FORM_FIELDS.awayCompetitorScore]: null,
  });

  const renderStatusChangeWarning = () => (
    <Alert
      className={classes.dialogText}
      icon={<ReportProblemOutlined />}
      severity="warning"
    >
      <Typography display="inline" variant="body2">
        {t('This action locks all match data and can’t be undone.')}
      </Typography>{' '}
      <Typography
        display="inline"
        variant="body2"
        style={{ fontWeight: 'bold' }}
      >
        {t(
          'Please ensure the score and match details have been entered before continuing.',
        )}
      </Typography>
    </Alert>
  );

  const renderCompleteStatus = () => (
    <>
      <DialogTitle>{t('Complete match')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">
            {t('Do you want to complete this match?')}
          </Typography>
        </Box>
        {renderStatusChangeWarning()}
        <Box m={1} className={classes.dialogConfirm}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isConfirmed}
                onChange={() => setConfirmed(!isConfirmed)}
                name="confirm"
                color="primary"
                disableRipple
              />
            }
            label={
              <Typography className={classes.dialogConfirmText} variant="body2">
                {t(
                  `I understand all consequences and want to complete the match`,
                )}
              </Typography>
            }
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button
              variant="outlined"
              onClick={handleClose}
              data-qa={testIds.CHANGE_MATCH_STATUS_MODAL_CANCEL_BUTTON}
            >
              {t('Cancel')}
            </Button>
          </Box>
          <ButtonWithProgress
            color="primary"
            variant="contained"
            onClick={handleChangeStatus}
            isLoading={isLoading}
            disabled={!isConfirmed}
          >
            {t('Complete')}
          </ButtonWithProgress>
        </Box>
      </DialogActions>
    </>
  );

  const renderChangeStatus = () => (
    <>
      <DialogTitle>{t('Change match status')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1" component="span">
            <ConnectedText
              textEntries={[
                t('Do you want to change match status to'),
                ' ',
                { value: `“${getMatchStatus(t, toStatus)}”`, boldText: true },
                '?',
              ]}
            />
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button
              variant="outlined"
              onClick={handleClose}
              data-qa={testIds.CHANGE_MATCH_STATUS_MODAL_CANCEL_BUTTON}
            >
              {t('Cancel')}
            </Button>
          </Box>
          <ButtonWithProgress
            color="primary"
            variant="contained"
            onClick={handleChangeStatus}
            isLoading={isLoading}
            data-qa={testIds.CHANGE_MATCH_STATUS_MODAL_CONFIRM_BUTTON}
          >
            {t('Confirm')}
          </ButtonWithProgress>
        </Box>
      </DialogActions>
    </>
  );

  const renderScheduledStatus = () => (
    <Formik
      onSubmit={handleScheduledStatusChange}
      validationSchema={getValidationSchema()}
      initialValues={getInitialValues()}
    >
      <Form noValidate>
        <DialogTitle>{t('Change match status')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="span">
            <ConnectedText
              textEntries={[
                t('Do you want to change match status to'),
                ' ',
                {
                  value: `“${getMatchStatus(t, MatchStatus.SCHEDULED)}”`,
                  boldText: true,
                },
                '?',
              ]}
            />
          </Typography>
          <Box mt={2} mb={2}>
            <TextField
              name={CHANGE_STATUS_FORM_FIELDS.changeStatusReason}
              data-qa={CHANGE_STATUS_FORM_FIELDS.changeStatusReason}
              required
              label={t('Reason')}
              multiline
              fullWidth
              type="text"
              rows={4}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box display="flex" padding={1}>
            <Box mr={1}>
              <Button
                variant="outlined"
                onClick={handleClose}
                data-qa={testIds.CHANGE_MATCH_STATUS_MODAL_CANCEL_BUTTON}
              >
                {t('Cancel')}
              </Button>
            </Box>
            <ButtonWithProgress
              color="primary"
              variant="contained"
              type="submit"
              isLoading={isLoading}
              data-qa={testIds.CHANGE_MATCH_STATUS_MODAL_CHANGE_BUTTON}
            >
              {t('Change')}
            </ButtonWithProgress>
          </Box>
        </DialogActions>
      </Form>
    </Formik>
  );

  const getDialogContent = () => {
    if (
      fromStatus === MatchStatus.COMPLETE &&
      toStatus === MatchStatus.SCHEDULED
    ) {
      return renderScheduledStatus();
    } else if (toStatus === MatchStatus.COMPLETE) {
      return renderCompleteStatus();
    }

    return renderChangeStatus();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="sm"
      fullWidth
    >
      {getDialogContent()}
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  matchesActions.changeMatchStatusRequest(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matches: bindActionCreators(matchesActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeStageStatusModal);
