import React from 'react';
import Badge from '@mui/material/Badge';

import { LogoSize } from '@core/types';
import { Logo, LogoProps } from '@core/components';
import ChangeAction from './components/change-action';

interface OwnProps {
  disabled?: boolean;
}

export type LogoChangerProps = Omit<LogoProps, 'size'> &
  Omit<React.ComponentProps<typeof ChangeAction>, 'hasLogo'> &
  OwnProps;

const LogoChanger = (props: LogoChangerProps) => {
  const { fileParams, refreshParams, disabled, ...logoProps } = props;

  if (disabled) return <Logo size={LogoSize.LARGE} {...logoProps} />;

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <ChangeAction fileParams={fileParams} refreshParams={refreshParams} />
      }
    >
      <Logo size={LogoSize.LARGE} {...logoProps} />
    </Badge>
  );
};

export default LogoChanger;
