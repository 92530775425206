import { pathOr, pipe, values, path } from 'ramda';

import { Filters } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getMatchOfficialsList = pathOr(null, [
  NAMESPACE,
  'allMatchOfficials',
]);

export const getCalendarMatchOfficialsList = pathOr(null, [
  NAMESPACE,
  'calendarMatchOfficials',
]);

export const getMatchOfficialGroups = pathOr(null, [
  NAMESPACE,
  'currentOfficialGroups',
]);

export const getMatchOfficialsMeta = pathOr(null, [NAMESPACE, 'meta']);

export const getMatchOfficialConflicts = pathOr(null, [
  NAMESPACE,
  'matchOfficialConflicts',
]);

export const getMatchOfficialsListFiltered = (fcproletype: string) =>
  pipe(
    pathOr(null, ['tabs', NAMESPACE, fcproletype, 'allMatchOfficials']),
    values,
  );

export const getFilters = (state: State): Filters =>
  path([NAMESPACE, 'filters'], state);
