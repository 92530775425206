import { call, Effect, put } from 'redux-saga/effects';

import * as groupFixtureSettingsAPI from '@core/api/group-fixture-settings';
import { GroupFixtureSettings } from '@volleyball/types';

import * as actions from './actions';

export function* getGroupFixtureSettings(
  groupId: number,
): Generator<Effect, any, any> {
  yield put(actions.getGroupFixtureSettingsRequest());
  const response = yield call(
    groupFixtureSettingsAPI.getGroupFixtureSettings,
    groupId,
  );

  if (!response.error) {
    yield put(actions.getGroupFixtureSettingsSuccess());
  } else {
    yield put(actions.getGroupFixtureSettingsFailure());
  }

  return response;
}

export function* updateGroupFixtureSettings(
  groupId: number,
  data: GroupFixtureSettings,
): Generator<Effect, any, any> {
  yield put(actions.updateGroupFixtureSettingsRequest());
  const response = yield call(
    groupFixtureSettingsAPI.putGroupFixtureSettings,
    groupId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateGroupFixtureSettingsSuccess());
  } else {
    yield put(actions.updateGroupFixtureSettingsFailure());
  }

  return response;
}

export function* createGroupFixtureSettings(
  groupId: number,
  data: GroupFixtureSettings,
): Generator<Effect, any, any> {
  yield put(actions.createGroupFixtureSettingsRequest());
  const response = yield call(
    groupFixtureSettingsAPI.postGroupFixtureSettings,
    groupId,
    data,
  );

  if (!response.error) {
    yield put(actions.createGroupFixtureSettingsSuccess());
  } else {
    yield put(actions.createGroupFixtureSettingsFailure());
  }

  return response;
}
