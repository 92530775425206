import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  removeSnackbar,
  selectSnackbars,
} from '@core/store/modules/ui/snackbar';
import { useSnackbar, type SnackbarKey } from 'notistack';
import { SNACKBAR } from './tests/test-ids';
import { useCoreDispatch } from '@core/hooks';

const Snackbars = (): null => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbars = useSelector(selectSnackbars);

  const dismissAction = useCallback(
    (key: SnackbarKey) => {
      const dismissSnackbar = () => {
        closeSnackbar(key);
      };

      return (
        <Button color="inherit" onClick={dismissSnackbar}>
          {t('Dismiss')}
        </Button>
      );
    },
    [t, closeSnackbar],
  );

  useEffect(() => {
    snackbars.forEach(({ key, message, options }) => {
      if (!key) {
        return;
      }

      enqueueSnackbar(t(message), {
        key,
        autoHideDuration: 7000,
        ...options,
        action: dismissAction,
      });

      dispatch(removeSnackbar(key));
    });
  });

  return null;
};

export const SnackbarWithState = memo(Snackbars);
