import restService from '@core/api/rest-service';
import { CompetitionMatchOfficialRolesResponse } from '@core/types';

export const getCompetitionMatchOfficialRoles = (competitionId: number) =>
  restService.get<CompetitionMatchOfficialRolesResponse>(
    `/v1/competitions/${competitionId}/competitionMatchOfficialRoles`,
  );

export const createCompetitionMatchOfficialRoles = (
  competitionId: number,
  competitionMatchOfficialRole: {
    matchOfficialRoleId: number;
    refereeCategoryId: number;
  },
) =>
  restService.post<CompetitionMatchOfficialRolesResponse>(
    `/v1/competitions/${competitionId}/competitionMatchOfficialRoles`,
    competitionMatchOfficialRole,
  );

export const updateCompetitionMatchOfficialRoles = (
  competitionId: number,
  competitionMatchOfficialRoleId: number,
  competitionMatchOfficialRole: {
    matchOfficialRoleId: number;
    refereeCategoryId: number;
  },
) =>
  restService.patch<CompetitionMatchOfficialRolesResponse>(
    `/v1/competitions/${competitionId}/competitionMatchOfficialRoles/${competitionMatchOfficialRoleId}`,
    competitionMatchOfficialRole,
  );

export const deleteCompetitionMatchOfficialRoles = (
  competitionId: number,
  competitionMatchOfficialRoleId: number,
) =>
  restService.delete<CompetitionMatchOfficialRolesResponse>(
    `/v1/competitions/${competitionId}/competitionMatchOfficialRoles/${competitionMatchOfficialRoleId}`,
  );
