import React, { Fragment } from 'react';
import { Typography, Divider, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { ColumnWithSubValue, TextField } from '@core/components';
import { Club } from '@core/icons';
import { MatchModel } from '@core/types';

interface Props {
  formFieldNames: {
    homeScoreFieldName: string;
    awayScoreFieldName: string;
    durationFieldName: string;
  };
  match: MatchModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const MatchDataFormFieldsMatchSets: React.FC<Props> = (props) => {
  const { formFieldNames, match } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  // TODO: JB: maybe use ContentBlock because of content block like structure
  return (
    <Grid container item spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.title}
        >
          {t('Score')}
        </Typography>
      </Grid>
      {[true, false].map((isHomeCompetitor, index) => {
        return (
          <Fragment key={index}>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={6}>
                <ColumnWithSubValue
                  value={
                    isHomeCompetitor
                      ? match.homeCompetitorTitle
                      : match.awayCompetitorTitle
                  }
                  subValue={
                    isHomeCompetitor
                      ? match.homeCompetitorInternationalTitle
                      : match.awayCompetitorInternationalTitle
                  }
                  logo={{
                    url: isHomeCompetitor
                      ? match._embedded.homeCompetitor.logoFileLink
                      : match._embedded.awayCompetitor.logoFileLink,
                    defaultIcon: <Club />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={
                    isHomeCompetitor
                      ? formFieldNames.homeScoreFieldName
                      : formFieldNames.awayScoreFieldName
                  }
                  data-qa={
                    isHomeCompetitor
                      ? formFieldNames.homeScoreFieldName
                      : formFieldNames.awayScoreFieldName
                  }
                  label={t('Points')}
                  inputMode="numeric"
                  required
                />
              </Grid>
            </Grid>
          </Fragment>
        );
      })}
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.title}
          >
            {t('Duration')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name={formFieldNames.durationFieldName}
            data-qa={formFieldNames.durationFieldName}
            label={t('Minutes')}
            inputMode="numeric"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
