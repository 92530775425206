import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TodayIcon from '@mui/icons-material/Today';

import {
  FIXTURE_CALENDAR_LIST_EMPTY,
  FIXTURE_CALENDAR_LIST,
} from '@core/pages/dashboard/tests/test-ids';
import { MatchFixture } from '@core/types';

import { sidebarStyles } from '../styles';
import FixtureCalendarListItem from './fixture-calendar-list-item';

interface OwnProps {
  title: string;
  matches: Array<MatchFixture>;
  isOnBottom: boolean;
}

type Props = OwnProps;

const FixtureCalendarList = (props: Props) => {
  const { t } = useTranslation();
  const classes = sidebarStyles();
  const { isOnBottom, title, matches } = props;

  return (
    <Grid data-qa={FIXTURE_CALENDAR_LIST} className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item className={classes.title}>
          {!!title && <TodayIcon className={classes.titleIcon} />}
          <Typography
            className={classes.titleText}
            variant="caption"
            display="inline"
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          className={clsx({
            [classes.listWrapper]: true,
            [classes.listWrapperEmpty]: !matches?.length,
          })}
        >
          {matches?.length ? (
            <Box className={classes.list}>
              {matches.map((match, index) => (
                <FixtureCalendarListItem
                  isOnBottom={isOnBottom}
                  match={match}
                  key={index}
                />
              ))}
            </Box>
          ) : (
            <Typography
              data-qa={FIXTURE_CALENDAR_LIST_EMPTY}
              variant="body2"
              display="inline"
              textAlign="center"
            >
              {t('No matches to display')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FixtureCalendarList;
