import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, AvatarProps, Theme } from '@mui/material';
import grey from '@mui/material/colors/grey';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    border: `2px solid #fff`,
    backgroundColor: theme.palette.common.white,
    color: grey[600],
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: grey[800],
    },
  },
}));

type Props = AvatarProps;

const SmallAvatar = (props: Props) => {
  const classes = useStyles();

  return <Avatar classes={{ root: classes.root }} {...props} />;
};

export default SmallAvatar;
