import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import { GroupFixtureSettings, APIErrorsModel } from '@volleyball/types';

import { actions } from '.';

interface Payload {
  groupId: number;
  data?: any;
}

interface DefaultState {
  [groupId: number]: {
    groupFixtureSettings: GroupFixtureSettings;
    errors: APIErrorsModel;
  };
}

export const defaultState: DefaultState = {};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setGroupFixturesSettings.toString()]: (state, { payload }) =>
      assocPath([payload.groupId, 'groupFixtureSettings'], payload.data, state),
    [actions.resetGroupFixtureSettings.toString()]: (state, { payload }) =>
      dissoc(payload.groupId, state),
    [actions.setApiErrors.toString()]: (state, { payload }) =>
      assocPath([payload.groupId, 'errors'], payload.data, state),
  },
  defaultState,
);

export default reducer;
