import { MatchStatus } from '@core/types';

export const getMatchStatus = (
  t: (key: string) => string,
  status: keyof typeof MatchStatus,
) => {
  switch (status) {
    case MatchStatus.ABANDONED:
      return t('Abandoned');
    case MatchStatus.BYE:
      return t('Bye');
    case MatchStatus.CANCELLED:
      return t('Cancelled');
    case MatchStatus.COMPLETE:
      return t('Completed');
    case MatchStatus.POSTPONED:
      return t('Postponed');
    case MatchStatus.SCHEDULED:
      return t('Scheduled');
    case MatchStatus.DRAFT:
      return t('Draft');
    default:
      return status;
  }
};
