import restService from '@core/api/rest-service';
import { MatchTeamOfficialsResponse } from '@core/types';

export const getMatchTeamOfficials = (matchId: number, competitorId: number) =>
  restService.get<MatchTeamOfficialsResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchTeamOfficials`,
  );

export const changeMatchTeamOfficials = (
  matchId: number,
  competitorId: number,
  data: any,
) =>
  restService.put(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchTeamOfficials`,
    data,
  );

export const getPreviousMatchTeamOfficials = (
  matchId: number,
  competitorId: number,
) =>
  restService.get<MatchTeamOfficialsResponse>(
    `/v1/matches/${matchId}/competitors/${competitorId}/matchTeamOfficials/activeOfPreviousMatch`,
  );
