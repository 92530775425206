import { handleActions } from 'redux-actions';

import { Paginated } from '@core/types';
import { appendToPaginatedListFactory } from '@core/helpers';

import actions from './actions';

export type DefaultState = {
  clubs: Paginated<number>;
};

type Payload = any;

export const defaultState: DefaultState = {
  clubs: null,
};

const appendToClubsList = appendToPaginatedListFactory<DefaultState>('clubs');

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setClubs.toString()]: (state, { payload }) =>
      appendToClubsList(state, payload),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
