import dayjs, { Dayjs } from 'dayjs';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  TimePicker,
  TimePickerProps,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { omit } from 'ramda';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { convertCustomerTimeFormatToDayjsFormat } from '@core/helpers';
import { Tooltip } from '@core/components';
import { DEFAULT_TIME_FORMAT } from '@core/constants/common';
import { TIME_PICKER_FIELD, TIME_PICKER_FIELD_INPUT } from './tests/test-ids';
import { useCoreSelector } from '@core/hooks';

interface StyleProps {
  width?: string;
  tooltipText?: string;
  withTooltipMargin?: boolean;
}

interface OwnProps extends Partial<TimePickerProps<Dayjs>> {
  label: string;
  name: string;
  disablePast?: boolean;
  clearable?: boolean;
  className?: string;
  tooltipText?: string;
  width?: string;
  withTooltipMargin?: boolean;
  required?: boolean;
  forceError?: boolean;
  forceFormat?: string;
  placeholder?: string | null;
  'data-qa'?: string;
}

type Props = StyleProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: ({ width }: StyleProps) => width || '100%',
    marginRight: ({ tooltipText, withTooltipMargin }: StyleProps) => {
      const tooltipIconWith = 34;
      const marginWithoutTooltipText = withTooltipMargin ? tooltipIconWith : 0;

      return tooltipText ? theme.spacing(1) : marginWithoutTooltipText;
    },
    backgroundColor: theme.palette.common.white,
  },
}));

const DUMMY_DATE = '1995-12-04';

const TimePickerField = (timePickerProps: Props) => {
  const {
    name,
    width,
    tooltipText,
    withTooltipMargin,
    forceError,
    forceFormat,
    placeholder,
    label,
    required,
    'data-qa': dataQa,
    ...restTimePickerProps
  } = timePickerProps;
  const { t } = useTranslation();
  const classes = useStyles({ width, tooltipText, withTooltipMargin });

  const currentCustomer = useCoreSelector(getCurrentCustomer);

  const timeFormat =
    currentCustomer?.timeFormat || forceFormat || DEFAULT_TIME_FORMAT;
  const { format: TIME_FORMAT, ampm } =
    convertCustomerTimeFormatToDayjsFormat(timeFormat) || {};
  const labelToDisplay = required ? `${label} *` : label;

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;

        const handleChange = (dayjsDate: Dayjs) => {
          if (!dayjsDate) {
            setFieldValue(name, dayjsDate?.format?.('HH:mm') ?? null, true);

            return;
          }

          const parsed = dayjs(dayjsDate, `YYYY-MM-DD ${TIME_FORMAT}`, true);
          const parsedValue = parsed.isValid()
            ? parsed.format('HH:mm')
            : dayjs(null).format('HH:mm');

          setFieldValue(name, parsedValue, true);
        };

        const isFieldTouched = meta.touched;
        const currentError = isFieldTouched && meta.error;

        let value = null;
        if (field.value && typeof field.value === 'string') {
          value = dayjs(
            `${DUMMY_DATE} ${field.value}`,
            `YYYY-MM-DD HH:mm`,
            true,
          );
        } else if (field.value) {
          value = dayjs(field.value);
        }

        const restProps = omit(['dispatch'], restTimePickerProps);

        return (
          <Box display="flex" alignItems="center" data-qa={TIME_PICKER_FIELD}>
            <TimePicker
              {...restProps}
              slotProps={{
                textField(textFieldProps) {
                  return {
                    ...textFieldProps,
                    name,
                    className: classes.field,
                    label: labelToDisplay,
                    variant: 'outlined',
                    placeholder:
                      placeholder || TIME_FORMAT?.toLowerCase() || '',
                    helperText: currentError,
                    error: Boolean(currentError),
                    onBlur: field.onBlur,
                    inputProps: {
                      ...textFieldProps.inputProps,
                      'data-qa': dataQa ?? TIME_PICKER_FIELD_INPUT,
                    },
                  };
                },
                toolbar: {
                  hidden: false,
                },
              }}
              localeText={{
                cancelButtonLabel: t('Cancel'),
                okButtonLabel: t('OK'),
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              format={TIME_FORMAT}
              ampm={ampm}
              views={['hours', 'minutes']}
              onChange={handleChange}
              value={value}
            />
            {tooltipText && <Tooltip title={tooltipText} />}
          </Box>
        );
      }}
    </Field>
  );
};

export default TimePickerField;
