import { Group } from '@core/types';
import { useMemo } from 'react';
import { useBracketStyle } from './knockout-brackets-style';
import { calculateMaxRound } from './utils';
import { KnockoutStandingsBrackets } from '@core/types/api/knockout-standing-brackets';
import { Typography } from '@mui/material';
import { KNOCKOUT_BRACKETS_ROUNDS } from '../__tests__/test-ids';

const thirdPlaceMatchTitle = '3rd Place Match';

type Props = {
  rounds: Array<Group>;
  data: KnockoutStandingsBrackets;
};

const KnockoutBracketsRounds = ({ rounds, data }: Props) => {
  const classes = useBracketStyle();
  const maxRound = useMemo(() => calculateMaxRound(data.items), [data.items]);

  const bracketRounds = useMemo(
    () =>
      rounds.filter(
        (round) => round.title !== thirdPlaceMatchTitle && !round.extraRound,
      ),
    [rounds],
  );

  const gridArea = useMemo(
    () =>
      bracketRounds.reduce((acc, round, index) => {
        const currentBox = `Box${round.id}`;

        switch (index) {
          case 0:
            return bracketRounds.length > 1
              ? `${acc} ${currentBox} ${currentBox} ${currentBox} ${currentBox} gap`
              : `${acc} ${currentBox} ${currentBox} ${currentBox} end`;
          case bracketRounds.length - 1:
            return `${acc} ${currentBox} ${currentBox} end`;
          default:
            return `${acc} ${currentBox} ${currentBox}`;
        }
      }, '"') + '"',
    [bracketRounds],
  );

  const gridColumns = useMemo(() => {
    const numberOfColumns = maxRound === 1 ? 4 : 2 * (maxRound - 1) + 6;
    return `repeat(${numberOfColumns}, 1fr)`;
  }, [maxRound]);

  return (
    <div
      className={classes.roundsGridContainer}
      style={{
        gridTemplateAreas: gridArea,
        gridTemplateColumns: gridColumns,
      }}
    >
      {bracketRounds.map((round) => (
        <div
          key={round.id}
          className={classes.roundsGridItem}
          style={{ gridArea: `Box${round.id}` }}
          data-qa={KNOCKOUT_BRACKETS_ROUNDS}
        >
          <Typography variant="h5">{round.title}</Typography>
        </div>
      ))}
    </div>
  );
};

export default KnockoutBracketsRounds;
