import {
  put,
  call,
  all,
  take,
  debounce,
  SagaReturnType,
  select,
  Effect,
} from 'redux-saga/effects';

import {
  formatPaginatedStoreWithIds,
  getURLSearchParams,
  handleApiErrors,
} from '@core/helpers';
import { getAppointments } from '@core/store/modules/api/appointment/sagas';

import * as appointmentsSelectors from './selectors';
import { actions } from '.';

export function* searchAppointmentsFlow(
  action: InferAction<
    typeof actions.getAppointments | typeof actions.searchByQuery
  >,
): Generator<Effect, any, any> {
  const {
    payload: { page },
  } = action;

  const urlSearchParams = getURLSearchParams(
    {
      paginated: (yield select(
        appointmentsSelectors.getAppointments,
      )) as InferSelector<typeof appointmentsSelectors.getAppointments>,
      filters: (yield select(
        appointmentsSelectors.getFilters,
      )) as InferSelector<typeof appointmentsSelectors.getFilters>,
      searchQuery: (yield select(
        appointmentsSelectors.getSearchQuery,
      )) as InferSelector<typeof appointmentsSelectors.getSearchQuery>,
      sorting: (yield select(
        appointmentsSelectors.getSorting,
      )) as InferSelector<typeof appointmentsSelectors.getSorting>,
    },
    { page, limit: undefined },
    { shouldAddTimeToSorting: true },
  );

  yield put(actions.getAppointmentsRequest());
  const { error, response } = (yield call(
    getAppointments,
    urlSearchParams,
  )) as SagaReturnType<typeof getAppointments>;

  if (!error) {
    // TODO: appointments
    yield put(
      actions.setAppointments(
        formatPaginatedStoreWithIds('matches')(response) as any,
      ),
    );
    yield put(actions.getAppointmentsSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(actions.getAppointmentsFailure());
  }
}

export function* getAppointmentsFlow() {
  while (true) {
    const action = (yield take(
      actions.getAppointments.toString(),
    )) as InferAction<typeof actions.getAppointments>;

    yield call(searchAppointmentsFlow, action);
  }
}

export function* watchSearchAppointmentsByQuery() {
  yield debounce(500, actions.searchByQuery.toString(), searchAppointmentsFlow);
}

export default function* saga() {
  yield all([getAppointmentsFlow(), watchSearchAppointmentsByQuery()]);
}
