import createPalette from '@mui/material/styles/createPalette';

const palette = createPalette({
  primary: {
    light: '#685fb9',
    main: '#4337a8',
    dark: '#2e2675',
  },
  secondary: {
    light: '#43485c',
    main: '#151b34',
    dark: '#0e1224',
  },
  success: {
    main: '#98C7B3',
    dark: '#8fc7af',
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
  },
  error: {
    light: '#f76b6a',
    main: '#c20000',
    dark: '#8d0000',
  },
  common: {
    white: '#fff',
  },
  background: {
    default: '#f7f9fc',
  },
  grey: {
    100: 'rgb(0, 0, 0, 0.03)',
  },
});

export default palette;
