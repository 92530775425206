export const NAMESPACE = 'seasons';

export const SET_SEASON_BEING_MODIFIED = `${NAMESPACE}/SET_SEASON_BEING_MODIFIED`;
export const SET_NEW_SEASON_BEING_CREATED = `${NAMESPACE}/SET_NEW_SEASON_BEING_CREATED`;
export const SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;
export const SET_COMPETITION_SEASON_QUERY = `${NAMESPACE}/SET_COMPETITION_SEASON_QUERY`;
export const SEARCH_COMPETITION_SEASON_BY_QUERY = `${NAMESPACE}/SEARCH_COMPETITION_SEASON_BY_QUERY`;
export const RESET_COMPETITION_SEASONS = `${NAMESPACE}/RESET_COMPETITION_SEASONS`;

export const GET_COMPETITION_SEASONS = `${NAMESPACE}/GET_COMPETITION_SEASONS`;
export const SET_COMPETITION_SEASONS = `${NAMESPACE}/SET_COMPETITION_SEASONS`;
export const GET_COMPETITION_SEASONS_REQUEST = `${NAMESPACE}/GET_COMPETITION_SEASONS_REQUEST`;
export const GET_COMPETITION_SEASONS_SUCCESS = `${NAMESPACE}/GET_COMPETITION_SEASONS_SUCCESS`;
export const GET_COMPETITION_SEASONS_FAILURE = `${NAMESPACE}/GET_COMPETITION_SEASONS_FAILURE`;

export const GET_COMPETITION_SEASON = `${NAMESPACE}/GET_COMPETITION_SEASON`;
export const SET_COMPETITION_SEASON = `${NAMESPACE}/SET_COMPETITION_SEASON`;
export const GET_COMPETITION_SEASON_REQUEST = `${NAMESPACE}/GET_COMPETITION_SEASON_REQUEST`;
export const GET_COMPETITION_SEASON_SUCCESS = `${NAMESPACE}/GET_COMPETITION_SEASON_SUCCESS`;
export const GET_COMPETITION_SEASON_FAILURE = `${NAMESPACE}/GET_COMPETITION_SEASON_FAILURE`;

export const CREATE_COMPETITION_SEASON = `${NAMESPACE}/CREATE_COMPETITION_SEASON`;
export const CREATE_COMPETITION_SEASON_REQUEST = `${NAMESPACE}/CREATE_COMPETITION_SEASON_REQUEST`;
export const CREATE_COMPETITION_SEASON_SUCCESS = `${NAMESPACE}/CREATE_COMPETITION_SEASON_SUCCESS`;
export const CREATE_COMPETITION_SEASON_FAILURE = `${NAMESPACE}/CREATE_COMPETITION_SEASON_FAILURE`;

export const UPDATE_COMPETITION_SEASON = `${NAMESPACE}/UPDATE_COMPETITION_SEASON`;
export const UPDATE_COMPETITION_SEASON_REQUEST = `${NAMESPACE}/UPDATE_COMPETITION_SEASON_REQUEST`;
export const UPDATE_COMPETITION_SEASON_SUCCESS = `${NAMESPACE}/UPDATE_COMPETITION_SEASON_SUCCESS`;
export const UPDATE_COMPETITION_SEASON_FAILURE = `${NAMESPACE}/UPDATE_COMPETITION_SEASON_FAILURE`;

export const DELETE_COMPETITION_SEASON = `${NAMESPACE}/DELETE_COMPETITION_SEASON`;
export const DELETE_COMPETITION_SEASON_REQUEST = `${NAMESPACE}/DELETE_COMPETITION_SEASON_REQUEST`;
export const DELETE_COMPETITION_SEASON_SUCCESS = `${NAMESPACE}/DELETE_COMPETITION_SEASON_SUCCESS`;
export const DELETE_COMPETITION_SEASON_FAILURE = `${NAMESPACE}/DELETE_COMPETITION_SEASON_FAILURE`;
