import React from 'react';
import { useSelector } from 'react-redux';

import { NavigationTabProps } from '@core/types';
import {
  getActiveTabIndex,
  getTabsList,
} from '@core/store/modules/ui/tabs/selectors';

const TabContext =
  React.createContext<Pick<NavigationTabProps, 'id' | 'name'>>(null);

interface Props {
  tab: NavigationTabProps;
  children: React.ReactNode;
}

export const TabProvider = (props: Props) => {
  return (
    <TabContext.Provider value={props.tab}>
      {props.children}
    </TabContext.Provider>
  );
};

export const useTabContext = () => {
  return React.useContext(TabContext);
};

export const useTabReactivated = (callback: () => void) => {
  const { id, name } = useTabContext();

  const tabsList = useSelector(getTabsList);
  const activeTabIndex = useSelector(getActiveTabIndex);
  const activeTab = tabsList[activeTabIndex];
  const previousActiveTab = React.useRef<typeof activeTab>();

  if (previousActiveTab.current) {
    const matchingTabs = tabsList.filter(
      (tabItem) => tabItem.name === name && tabItem.id === id,
    );

    if (matchingTabs.length === 1) {
      const { id: activeTabId, name: activeTabName } = activeTab;
      const { id: previousActiveTabId, name: previousActiveTabName } =
        previousActiveTab.current;
      const { id: matchingTabId, name: matchingTabName } = matchingTabs[0];

      if (
        activeTabId === matchingTabId &&
        activeTabName === matchingTabName &&
        (previousActiveTabId !== activeTabId ||
          previousActiveTabName !== activeTabName)
      ) {
        callback();
      }
    } else {
      if (import.meta.env.MODE === 'development') {
        console.warn('multiple tabs found with same id and name');

        return;
      }
    }
  }

  previousActiveTab.current = activeTab;
};
