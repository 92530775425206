import { call, put, select } from 'redux-saga/effects';
import { XOR } from 'ts-xor';

import { createURL } from '@core/helpers';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import * as tabsSelectors from '@core/store/modules/ui/tabs/selectors';
import { NavigationTabProps } from '@core/types';
import * as competitorAdditionSelectors from '@core/store/modules/tabs/competitor-addition/selectors';
import * as clubProfileSelectors from '@core/pages/club-profile/store/selectors';
import { actions as competitorAdditionActions } from '@core/store/modules/tabs/competitor-addition';
import { default as clubProfileActions } from '@core/pages/club-profile/store/actions';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import paths from '@core/routes/paths';

export type OnSuccessEffectParameters = XOR<
  {
    competitionId: number;
    clubId: number;
    kitSetId: number;
    modal: boolean;
    tabId: string;
  },
  {
    competitionId: number;
    clubId: number;
    kitSetIds: Array<number>;
    modal: boolean;
    tabId: string;
  }
>;

export interface OnSuccessEffectParametersPayload {
  onSuccessEffectParameters: OnSuccessEffectParameters;
}

type IsTabOpenedByUrl = (url: string) => boolean;

function* isTabOpenedByUrlFactory() {
  const tabs = (yield select(tabsSelectors.getTabsList)) as InferSelector<
    typeof tabsSelectors.getTabsList
  >;

  const isTabOpenedByUrl: IsTabOpenedByUrl = (url: string) =>
    tabs.some((tab) => tab.name === url);

  return isTabOpenedByUrl;
}

type GetOpenedTabsByTabIdOrMatchingKitSetId = (parameters: {
  kitSetIds: Array<number>;
  tabId: string;
}) => Array<NavigationTabProps>;

function* getOpenedTabsByTabIdOrMatchingKitSetIdFactory() {
  const tabs = (yield select(tabsSelectors.getTabsList)) as InferSelector<
    typeof tabsSelectors.getTabsList
  >;

  const getOpenedTabsByTabIdOrMatchingKitSetId: GetOpenedTabsByTabIdOrMatchingKitSetId =
    ({ tabId, kitSetIds }) => {
      const KitSetRelatedTabNames = [createURL(paths.createKitSet)];
      const filteredTabs = tabs.filter(
        (tab) =>
          tab.id === tabId ||
          (KitSetRelatedTabNames.includes(tab.name) &&
            kitSetIds.includes(tab.props.kitSetId)),
      );

      return filteredTabs;
    };

  return getOpenedTabsByTabIdOrMatchingKitSetId;
}

function* refreshKitSetsListInAnyTab(
  parameters: { competitionId: number; clubId: number },
  isTabOpenedByUrl: IsTabOpenedByUrl,
) {
  const { competitionId, clubId } = parameters;

  const clubProfileKitSetsExist = !!((yield select(
    clubProfileSelectors.getKitSetList,
    { clubId },
  )) as InferSelector<typeof clubProfileSelectors.getKitSetList>);

  const competitorAssignmentKitSetsExist = !!((yield select(
    competitorAdditionSelectors.getKitSets,
    {
      competitionId,
    },
  )) as InferSelector<typeof competitorAdditionSelectors.getKitSets>);

  if (
    isTabOpenedByUrl(createURL(paths.clubProfile)) &&
    clubProfileKitSetsExist
  ) {
    yield put(clubProfileActions.getKitSetList(clubId));
  }

  if (
    isTabOpenedByUrl(createURL(paths.competitorAssignment)) &&
    competitorAssignmentKitSetsExist
  ) {
    yield put(
      competitorAdditionActions.getClubKitSets({ competitionId, clubId }),
    );
  }
}

function* refreshTabsRelatedToKitSet(
  parameters: { kitSetIds: Array<number> },
  isTabOpenedByUrl: IsTabOpenedByUrl,
) {
  /*  */
}

function* closeTabByTabIdOrTabsRelatedToKitSets(
  parameters: XOR<{ kitSetIds: Array<number> }, { tabId: string }>,
) {
  const { kitSetIds = [], tabId } = parameters;

  if (!kitSetIds.length && !tabId) {
    return;
  }

  // redux state better be queried each time function is called since redux state can be changed
  // by any previous call to function in which action to remove tab was dispatched
  const getOpenedTabsByTabIdOrMatchingMatchId =
    yield* getOpenedTabsByTabIdOrMatchingKitSetIdFactory();

  const tabsToClose = getOpenedTabsByTabIdOrMatchingMatchId({
    kitSetIds,
    tabId,
  });

  for (const tab of tabsToClose) {
    yield put(tabsActions.removeTab(tab.id));
  }
}

export function* getKitSetMutatedSuccessfullyCallbacks(
  parameters: OnSuccessEffectParameters,
  operation: 'create' | 'update' | 'delete',
) {
  if (!parameters) {
    return;
  }
  const { competitionId, clubId, modal, tabId, ...kitSetIdOrKitSetIds } =
    parameters;
  const kitSetIds = kitSetIdOrKitSetIds.kitSetId
    ? [kitSetIdOrKitSetIds.kitSetId]
    : kitSetIdOrKitSetIds.kitSetIds ?? [];
  const isTabOpenedByUrl = yield* isTabOpenedByUrlFactory();

  switch (operation) {
    case 'create': {
      yield call(
        refreshKitSetsListInAnyTab,
        { competitionId, clubId },
        isTabOpenedByUrl,
      );
      // when create is done in a separate tab
      tabId &&
        ((yield call(closeTabByTabIdOrTabsRelatedToKitSets, {
          tabId,
        })) as void);
      break;
    }
    case 'update': {
      yield call(
        refreshKitSetsListInAnyTab,
        { competitionId, clubId },
        isTabOpenedByUrl,
      );
      yield call(refreshTabsRelatedToKitSet, { kitSetIds }, isTabOpenedByUrl);
      // when update is done in a separate tab, e.g. match data create
      tabId &&
        ((yield call(closeTabByTabIdOrTabsRelatedToKitSets, {
          tabId,
        })) as void);
      break;
    }
    case 'delete': {
      yield call(
        refreshKitSetsListInAnyTab,
        { competitionId, clubId },
        isTabOpenedByUrl,
      );
      yield call(closeTabByTabIdOrTabsRelatedToKitSets, { tabId });
      yield call(closeTabByTabIdOrTabsRelatedToKitSets, { kitSetIds });
      break;
    }
  }

  if (modal) {
    yield put(globalModalActions.closeModal());
  }
}
