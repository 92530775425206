import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth0GetAccessTokenSilently } from '@core/helpers/auth';
import { getAuthProvider } from '@core/helpers/storage';
import { getCustomerCode } from '@core/helpers/url';
import * as authActions from '@core/pages/login/store/actions';

const API_VERSION = 1;

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/v${API_VERSION}/`,
    prepareHeaders: async (headers) => {
      const customerCode = getCustomerCode();
      const sport = import.meta.env.VITE_SPORT;

      if (sport === 'volleyball') {
        const authProvider = getAuthProvider();

        if (authProvider === 'auth0') {
          const getAccessTokenSilently = getAuth0GetAccessTokenSilently();

          if (getAccessTokenSilently) {
            try {
              const { id_token } = await getAccessTokenSilently({
                detailedResponse: true,
              });

              headers.set('authorization', `Bearer ${id_token}`);
            } catch {
              globalThis.store.dispatch(authActions.logout());
            }
          }
        }
      }

      headers.set('X-CUSTOMER-CODE', customerCode);

      return headers;
    },
    credentials: 'include',
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Brackets',
    'Competition',
    'DisciplinaryCardReset',
    'DisciplinaryRule',
    'DisciplinarySanction',
    'DisciplinaryRuleset',
  ],
});
