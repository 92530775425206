import { all, put, take, call } from 'redux-saga/effects';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { updateCompetition } from '@core/pages/competitions/store/api/sagas';

import { actions } from '.';

export function* updateCompetitionGeneralInformationFlow() {
  while (true) {
    const { payload } = yield take(
      actions.updateCompetitionGeneralInformation.toString(),
    );
    const { error } = yield call(updateCompetition, payload.id, payload.data);

    if (!error) {
      yield put(tabActions.removeTab({ tabId: payload.tabId }));
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([updateCompetitionGeneralInformationFlow()]);
}
