import restService from '@core/api/rest-service';
import {
  CompetitorResponse,
  CompetitorsResponse,
  CompetitorPlaceholder,
  CreateCompetitorProps,
  DeleteCompetitorProps,
  UpdateCompetitorProps,
} from '@core/types';

interface GetCompetitorPlaceholderProps {
  competitionId: number;
  teamId: number;
}

interface GetCompetitorsProps {
  competitionId: number;
  queryParams: {
    page?: number;
  };
}

const defaultQueryParams = {
  page: 1,
};

export const getCompetitorsByCompetition = ({
  competitionId,
  queryParams = defaultQueryParams,
}: GetCompetitorsProps) =>
  restService.get<CompetitorsResponse>(
    `/v1/competitions/${competitionId}/competitors`,
    { params: queryParams },
  );

export const getCompetitorByTeam = (competitionId: number, teamId: number) =>
  restService.get<CompetitorResponse>(
    `/v1/competitions/${competitionId}/competitors/${teamId}`,
  );

export const getCompetitorPlaceholder = ({
  competitionId,
  teamId,
}: GetCompetitorPlaceholderProps) =>
  restService.get<CompetitorPlaceholder>(
    `v1/placeholder/competitions/${competitionId}/competitors/${teamId}`,
  );

export const createCompetitor = ({
  competitionId,
  teamId,
  data,
}: CreateCompetitorProps) =>
  restService.post(
    `/v1/competitions/${competitionId}/competitors/${teamId}`,
    data,
  );

export const deleteCompetitor = ({
  competitionId,
  teamId,
}: DeleteCompetitorProps) =>
  restService.delete(`/v1/competitions/${competitionId}/competitors/${teamId}`);

export const updateCompetitor = ({
  competitionId,
  teamId,
  data,
}: UpdateCompetitorProps) =>
  restService.patch(
    `/v1/competitions/${competitionId}/competitors/${teamId}`,
    data,
  );

export const getCompetitors = (queryParams?: {
  page?: number;
  query?: string;
}) =>
  restService.get<CompetitorsResponse>(`/v1/competitors`, {
    params: { ...queryParams, limit: 20 },
  });
