import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Logo } from '@core/components';
import { Club } from '@core/icons';
import { LogoSize } from '@core/types';

interface OwnProps {
  competitorTitle: string;
  competitorInternationalTitle: string;
  competitorLogoFileLink: string;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    textTransform: 'none',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    lineHeight: '19px',
  },
  infoBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  logoWrapper: {
    marginRight: 12,
  },
}));

const CompetitorSummary = (props: Props) => {
  const {
    competitorTitle,
    competitorInternationalTitle,
    competitorLogoFileLink,
  } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" p={1}>
      <Box className={classes.logoWrapper}>
        <Logo size={LogoSize.SMALL} src={competitorLogoFileLink}>
          <Club />
        </Logo>
      </Box>
      <Box className={classes.infoBox}>
        <Typography variant="subtitle2" className={classes.title}>
          {competitorTitle}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          className={classes.subtitle}
        >
          {competitorInternationalTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompetitorSummary;
