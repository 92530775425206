import React from 'react';
import { connect } from 'react-redux';

import {
  AddedMatchTeamOfficialList,
  MatchCompetitorsResponse,
  MatchPlayerOrigin,
  MatchModel,
  MatchPlayersList,
} from '@core/types';
import * as matchProfileSelectors from '@core/store/modules/tabs/match-profile/selectors';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { State } from '@core/store';

import MatchLineupTemplate, { MatchProps } from './match-lineup-template';

interface StateProps {
  awayPlayers: MatchPlayersList;
  awayTeamOfficials: AddedMatchTeamOfficialList;
  match: MatchModel;
  matchCompetitors: MatchCompetitorsResponse;
}

interface OwnProps {
  matchId: number;
  canManage: boolean;
}

type Props = OwnProps & StateProps;

const matchPlayerOrigin = MatchPlayerOrigin.AWAY;

const AwayCompetitorTab = (props: Props) => {
  const {
    canManage: canManageMatch,
    match,
    matchCompetitors,
    matchId,
    awayPlayers,
    awayTeamOfficials,
  } = props;

  const competitorId = match?.awayCompetitorId;
  const clubId = match?._embedded?.awayCompetitor?.clubId;
  const competitorTitle = match?.awayCompetitorTitle || '';
  const competitorInternationalTitle =
    match?.awayCompetitorInternationalTitle || '';
  const competitorLogoFileLink =
    match?._embedded?.awayCompetitor?.logoFileLink ||
    match?._embedded?.awayCompetitor?.clubLogoFileLink;
  const canEditMatch = !!match?._links?.patch;

  const matchProps: MatchProps = {
    competitorTitle,
    competitorInternationalTitle,
    competitorLogoFileLink,
    competitorId,
    matchId,
    clubId,
    matchPlayerOrigin,
    canEditMatch,
    canManageMatch,
  };

  const awayMatchCompetitor = matchCompetitors?._embedded.awayMatchCompetitor;

  return (
    <MatchLineupTemplate
      matchCompetitor={awayMatchCompetitor}
      matchProps={matchProps}
      playersList={awayPlayers}
      teamOfficialsList={awayTeamOfficials}
    />
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = ownProps?.matchId;

  return {
    awayPlayers: matchProfileSelectors.getMatchPlayers(state, {
      matchId,
      matchPlayerOrigin,
    }),
    awayTeamOfficials:
      matchProfileSelectors.getAwayTeamOfficials(matchId)(state),
    match: getMatchById(state, matchId),
    matchCompetitors: matchProfileSelectors.getMatchCompetitorsByMatchId(
      state,
      { matchId },
    ),
  };
};

export default connect(mapStateToProps, null)(AwayCompetitorTab);
