import React from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { TextField, CustomTable, NumberField } from '@core/components';
import { Column } from '@core/components/material-table';
import { Group, StageType } from '@core/types';

import { FIELD_NAMES } from '../../../constants';
import { getNumberOfTeamsMinMax } from '../../../helpers/validationSchema';

interface OwnProps {
  groups: Array<Group & { index: number }>;
}

type Props = OwnProps;

const PoolsTable = (props: Props) => {
  const { groups } = props;
  const { t } = useTranslation();

  const columns = new Array<Column<Group & { index: number }>>(
    {
      title: `${t('Pool')} *`,
      field: 'title',
      render: (rowData) => (
        <TextField
          name={`${FIELD_NAMES.roundRobinGroups}.${rowData.index}.title`}
          type="text"
          width="220px"
          smallInput
          required
        />
      ),
    },
    {
      title: `${t('Teams')} *`,
      field: 'numberOfTeams',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <Grid container justifyContent="center">
          <NumberField
            name={`${FIELD_NAMES.roundRobinGroups}.${rowData.index}.numberOfTeams`}
            {...getNumberOfTeamsMinMax({
              knockoutDoubleElimination: undefined,
              knockoutElimination: undefined,
              numberOfPoolsValue: 2,
              stageType: StageType.ROUND_ROBIN,
              variant: 'pool',
            })}
            smallInput
            required
          />
        </Grid>
      ),
    },
  );

  return (
    <FieldArray name={FIELD_NAMES.roundRobinGroups}>
      {() => <CustomTable columns={columns} data={groups || []} noPaper />}
    </FieldArray>
  );
};

export default PoolsTable;
