import { put, call, all, SagaReturnType } from 'redux-saga/effects';

import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { handleApiErrors } from '@core/helpers';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { updateCompetitor } from '@core/store/modules/api/competitors/sagas';
import { getKitSetList } from '@core/store/modules/api/kit-set/sagas';
import { actions as competitorProfile } from '@core/store/modules/tabs/competitor-profile';

import { actions } from '.';

export function* getKitSetsFlow() {
  yield takeLeadingByPredicate(actions.getKitSets, function* (action) {
    const { payload } = action;
    const { clubId, competitorId } = payload;

    yield put(actions.getKitSetsRequest({ clubId, competitorId }));

    const { error, response } = (yield call(
      getKitSetList,
      clubId,
    )) as SagaReturnType<typeof getKitSetList>;

    if (!error) {
      yield put(actions.getKitSetsSuccess({ clubId, competitorId }));
      yield put(
        actions.setKitSets({
          competitorId,
          data: response?._embedded?.kitSets,
        }),
      );
    } else {
      yield put(actions.getKitSetsFailure({ clubId, competitorId }));
      yield call(handleApiErrors, error);
    }
  });
}

export function* updateCompetitorKitSetFlow() {
  yield takeLeadingByPredicate(
    actions.updateCompetitorKitSet,
    function* (action) {
      const { payload } = action;
      const { competitionId, data, teamId, competitorId } = payload;

      yield put(
        actions.updateCompetitorKitSetRequest({ competitionId, teamId }),
      );

      const { error } = (yield call(updateCompetitor, {
        competitionId,
        data,
        teamId,
      })) as SagaReturnType<typeof updateCompetitor>;

      if (!error) {
        yield put(
          actions.updateCompetitorKitSetSuccess({ competitionId, teamId }),
        );
        yield put(
          competitorProfile.getCompetitorByTeam({
            teamId,
            competitionId,
            competitorId,
          }),
        );
        yield put(globalModalActions.closeModal());
      } else {
        yield put(
          actions.updateCompetitorKitSetFailure({ competitionId, teamId }),
        );
        yield call(handleApiErrors, error);
      }
    },
  );
}

export default function* saga() {
  yield all([getKitSetsFlow(), updateCompetitorKitSetFlow()]);
}
