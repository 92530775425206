import restService from '@core/api/rest-service';
import { ConflictResponse } from '@core/types';

export const getVenueConflicts = (queryParams?: URLSearchParams) =>
  restService.get<ConflictResponse>('/v1/matches/venueConflicts', {
    params: queryParams,
  });

export const getTeamConflicts = (queryParams?: URLSearchParams) =>
  restService.get<ConflictResponse>('/v1/matches/teamConflicts', {
    params: queryParams,
  });

export const getOfficialConflicts = (queryParams?: URLSearchParams) =>
  restService.get<ConflictResponse>(
    '/v1/matches/matchOfficialAppointmentConflicts',
    { params: queryParams },
  );
