import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FixtureCalendarStyleProps } from './style-prop';
import { getConflictColor, getConflictIconColor } from '../helpers';

export default makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  item: ({ selected }: FixtureCalendarStyleProps) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: theme.spacing(1.5, 2, 1.5, 3.5),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',

    backgroundColor: selected && lighten(theme.palette.primary.main, 0.93),

    '&:hover, &:active': {
      backgroundColor: lighten(theme.palette.primary.main, 0.93),
    },

    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  }),
  wrapper: {},
  indicator: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    width: theme.spacing(0.5),
    borderRadius: 1,
    backgroundColor: ({ conflictLevel }: FixtureCalendarStyleProps): string =>
      getConflictColor(conflictLevel, theme),
  },
  warning: {
    display: ({ conflictLevel }: FixtureCalendarStyleProps) =>
      conflictLevel ? 'block' : 'none',
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(2),
  },
  warningIcon: {
    fontSize: theme.spacing(2.5),
    fill: ({ conflictLevel }: FixtureCalendarStyleProps): string =>
      getConflictIconColor(conflictLevel, theme),
  },
  time: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
    lineHeight: `${theme.spacing(1.5)}`,
    marginBottom: theme.spacing(1.5),
  },
  team: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    lineHeight: `${theme.spacing(2.5)}`,
    marginTop: theme.spacing(0.5),
  },
  teamLogo: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  teamLogoPlaceholder: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    fill: theme.palette.grey[500],
  },
  teamName: {
    fontSize: `calc(${theme.typography.body2.fontSize} - 1px)`,
    height: theme.spacing(2),
    lineHeight: `${theme.spacing(1.9)}`,
    paddingTop: theme.spacing(0.1),
  },
}));
