import { take, all, put, call, debounce } from 'redux-saga/effects';

import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { getMatchOfficials } from '@core/store/modules/api/match-officials/sagas';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { getCompetitorsByCompetition } from '@core/store/modules/api/competitors/sagas';
import {
  createDisciplinarySanction,
  updateDisciplinarySanction,
} from '@core/store/modules/api/disciplinary-sanctions/sagas';
import { getMatches } from '@core/store/modules/api/match/sagas';
import { getCompetitorPlayers } from '@core/store/modules/api/competitor-players/sagas';
import { getCompetitorTeamOfficials } from '@core/store/modules/api/competitor-team-officials/sagas';
import { getTeams } from '@volleyball/store/modules/api/team/sagas';

import { actions } from '.';

export function* getCompetitorsFlow(action: any) {
  const { payload } = action;
  const { competitionId, sanctionType, ...query } = payload;

  yield put(actions.getCompetitorsRequest());

  const { error, response } = yield call(getCompetitorsByCompetition, {
    competitionId,
    ...query,
  });

  if (!error) {
    yield put(actions.getCompetitorsSuccess());
    yield put(
      actions.setCompetitors({ competitionId, sanctionType, data: response }),
    );
  } else {
    yield put(actions.getCompetitorsFailure());
    yield call(handleApiErrors, error);
  }
}

export function* watchSearchCompetitionCompetitors() {
  yield debounce(500, actions.getCompetitors, getCompetitorsFlow);
}

export function* getCompetitorPlayersFlow(action: any) {
  const { payload } = action;
  const { competitionId, teamId, competitorId, sanctionType, ...query } =
    payload;

  yield put(actions.getPlayersRequest());

  const { error, response } = (yield call(
    getCompetitorPlayers,
    competitionId,
    teamId,
    query,
  )) as InferApi<typeof getCompetitorPlayers>;

  if (!error) {
    yield put(actions.getPlayersSuccess());
    yield put(
      actions.setPlayers({ competitionId, sanctionType, data: response }),
    );
  } else {
    yield put(actions.getPlayersFailure());
    yield call(handleApiErrors, error);
  }
}

function* watchSearchCompetitorPlayers() {
  yield debounce(500, actions.getPlayers, getCompetitorPlayersFlow);
}

function* getCompetitorTeamOfficialsFlow(action: any) {
  const { payload } = action;
  const { competitionId, teamId, competitorId, sanctionType, ...query } =
    payload;

  yield put(actions.getTeamOfficialsRequest());

  const { error, response } = yield call(
    getCompetitorTeamOfficials,
    competitionId,
    teamId,
    query,
  );

  if (!error) {
    yield put(actions.getTeamOfficialsSuccess());
    yield put(
      actions.setTeamOfficials({ competitionId, sanctionType, data: response }),
    );
  } else {
    yield put(actions.getTeamOfficialsFailure());
    yield call(handleApiErrors, error);
  }
}

function* watchSearchCompetitorTeamOfficials() {
  yield debounce(500, actions.getTeamOfficials, getCompetitorTeamOfficialsFlow);
}

function* getMatchesFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatches);

    const { competitionId, sanctionType, queryParams } = payload;

    yield put(actions.getMatchesRequest());
    const { error, response } = yield call(getMatches, queryParams);

    if (!error) {
      yield put(actions.getMatchesSuccess());
      yield put(
        actions.setMatches({ competitionId, sanctionType, data: response }),
      );
    } else {
      yield put(actions.getMatchesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* createSanctionFlow() {
  while (true) {
    const { payload } = yield take(actions.createSanction);
    const { competitionId, data, tabId } = payload;

    yield put(actions.createSanctionRequest());
    const { error } = yield call(createDisciplinarySanction, {
      competitionId,
      data,
    });

    if (!error) {
      yield put(actions.createSanctionSuccess());
      yield put(tabsActions.removeTab({ tabId }));
      yield put(
        competitionProfileActions.getDisciplinarySanctions({ competitionId }),
      );
    } else {
      yield put(actions.createSanctionFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* updateSanctionFlow() {
  while (true) {
    const { payload } = yield take(actions.updateSanction);
    const { competitionId, sanctionId, data, tabId } = payload;

    yield put(actions.updateSanctionRequest());
    const { error } = yield call(updateDisciplinarySanction, {
      competitionId,
      sanctionId,
      data,
    });

    if (!error) {
      yield put(actions.updateSanctionSuccess());
      yield put(tabsActions.removeTab({ tabId }));
    } else {
      yield put(actions.updateSanctionFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* getMatchOfficialsFlow(
  action: InferAction<typeof actions.getMatchOfficials>,
) {
  const { payload } = action;
  const { competitionId, sanctionType, queryParams } = payload;

  yield put(actions.getMatchOfficialsRequest());

  const { error, response } = yield call(getMatchOfficials, queryParams);

  if (!error) {
    yield put(actions.getMatchOfficialsSuccess());
    yield put(
      actions.setMatchOfficials({
        competitionId,
        sanctionType,
        data: response,
      }),
    );
  } else {
    yield put(actions.getMatchOfficialsFailure());
    yield call(handleApiErrors, error);
  }
}

function* watchSearchMatchOfficials() {
  yield debounce(500, actions.getMatchOfficials, getMatchOfficialsFlow);
}

function* getTeamsFlow(action: any) {
  const { payload } = action;
  const { competitionId, sanctionType, ...query } = payload;

  yield put(actions.getTeamsRequest());

  const { error, response } = yield call(getTeams, query);

  if (!error) {
    yield put(actions.getTeamsSuccess());
    yield put(
      actions.setTeams({ competitionId, sanctionType, data: response }),
    );
  } else {
    yield put(actions.getTeamsFailure());
    yield call(handleApiErrors, error);
  }
}

function* watchSearchTeams() {
  yield debounce(500, actions.getTeams, getTeamsFlow);
}

export default function* saga() {
  yield all([
    watchSearchCompetitionCompetitors(),
    watchSearchCompetitorPlayers(),
    getMatchesFlow(),
    createSanctionFlow(),
    watchSearchCompetitorTeamOfficials(),
    watchSearchMatchOfficials(),
    watchSearchTeams(),
    updateSanctionFlow(),
  ]);
}
