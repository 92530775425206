import { TFunction } from 'i18next';

import { StandingsRanks, StandingsTableHeaderTypes } from '@volleyball/types';

export const headersArray = (t: TFunction) => [
  {
    id: StandingsTableHeaderTypes.POSITION,
    title: t('Rank'),
  },
  {
    id: StandingsTableHeaderTypes.POINTS,
    title: t('Points (Won • Lost)'),
  },
  {
    id: StandingsTableHeaderTypes.MATCHES,
    title: t('Matches (Played • Won • Lost)'),
  },
  {
    id: StandingsTableHeaderTypes.SETS,
    title: t('Sets (Won • Lost)'),
  },
  {
    id: StandingsTableHeaderTypes.MATCH_POINTS,
    title: t('Ranking Points'),
  },
  {
    id: StandingsTableHeaderTypes.RESULT_BREAKDOWN,
    title: t('Results Breakdown'),
  },
  {
    id: StandingsTableHeaderTypes.SET_RATIO,
    title: t('Set Ratio'),
  },
  {
    id: StandingsTableHeaderTypes.PERCENTAGE,
    title: t('Point Ratio'),
  },
];

export const rankingsArray = (t: TFunction) => [
  {
    id: StandingsRanks.POINTS,
    title: t('Ranking Points'),
  },
  {
    id: StandingsRanks.GAMES_WON,
    title: t('Matches Won'),
  },
  {
    id: StandingsRanks.SET_RATIO,
    title: t('Set Ratio'),
  },
  {
    id: StandingsRanks.POINTS_RATIO,
    title: t('Point Ratio'),
  },
];
