import { useTranslation } from 'react-i18next';
import { useBracketStyle } from './knockout-brackets-style';
import { type KnockoutBracket } from '@core/types/api/knockout-standing-brackets';
import KnockoutBracketTeam from './knockout-bracket-team';
import { KNOCKOUT_BRACKET } from '../__tests__/test-ids';

export interface BracketProps {
  data: KnockoutBracket;
  marginTop?: number;
}

const KnockoutBracket = ({ data }: BracketProps) => {
  const classes = useBracketStyle();
  const { t } = useTranslation();

  if (!data?.team1 || !data?.team2) {
    return <>{t('No Data available')}</>;
  }

  const homeTeamName =
    data.team1.title || data.team1Placeholder || t('No Data');
  const awayTeamName =
    data.team2.title || data.team2Placeholder || t('No Data');
  const isWinnerHome = data.isComplete && data.team1.isWinner;
  const isWinnerAway = data.isComplete && data.team2.isWinner;

  return (
    <div
      className={classes.wrapperField}
      data-qa={`${KNOCKOUT_BRACKET}_${data.id}`}
    >
      <main className={classes.bracketWrapper}>
        <KnockoutBracketTeam
          team={data.team1}
          teamName={homeTeamName}
          matches={data.matches}
          isWinnerHome={isWinnerHome}
          isWinnerAway={isWinnerAway}
        />

        <KnockoutBracketTeam
          team={data.team2}
          teamName={awayTeamName}
          matches={data.matches}
          isWinnerHome={isWinnerAway}
          isWinnerAway={isWinnerHome}
        />
      </main>
    </div>
  );
};

export default KnockoutBracket;
