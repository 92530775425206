import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { prop, propOr } from 'ramda';

import { ExpansionPanelTitle, FullScreenSpinner } from '@core/components';
import { getCompetitorLogoUrl } from '@core/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { MatchPlayerOrigin, MatchModel } from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import { getMatchDetails } from '@core/store/modules/tabs/match-sheet-management/selectors';
import CompetitorSummary from '@core/components/competitor-summary';
import { State } from '@core/store';

import FormActionsBlock from './components/form-actions-block';
import MatchSheetSummaryBlock from './components/match-sheet-summary-block';
import MatchSheetManagementTabs from './components/match-sheet-management-tabs';
import MatchSheetValidations from './components/match-sheet-validations';
import MatchSheetPlayersValidations from './components/match-sheet-players-validations';

interface OwnProps {
  id: number;
  tabId: string;
  matchPlayerOrigin: MatchPlayerOrigin;
  matchId: number;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}
interface StateProps {
  isLoading: boolean;
  matchDetails: MatchModel;
}

type Props = OwnProps & DispatchProps & StateProps;

const MatchSheetManagement = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    matchPlayerOrigin,
    matchId,
    isLoading,
    matchDetails,
    tabId,
  } = props;
  const isAwayCompetitor = matchPlayerOrigin === MatchPlayerOrigin.AWAY;
  const competitorId = prop(
    isAwayCompetitor ? 'awayCompetitorId' : 'homeCompetitorId',
    matchDetails,
  );
  const competitorInternationalTitle = propOr(
    '',
    isAwayCompetitor
      ? 'awayCompetitorInternationalTitle'
      : 'homeCompetitorInternationalTitle',
    matchDetails,
  ) as string;

  const competitorTitle = propOr(
    '',
    isAwayCompetitor ? 'awayCompetitorTitle' : 'homeCompetitorTitle',
    matchDetails,
  ) as string;
  const competitorLogoFileLink = isAwayCompetitor
    ? getCompetitorLogoUrl(matchDetails, 'awayCompetitor')
    : getCompetitorLogoUrl(matchDetails, 'homeCompetitor');

  useEffect(() => {
    actions.matchSheetManagement.getMatch({ matchId, matchPlayerOrigin });

    return () => {
      actions.matchSheetManagement.resetMatchSheetManagement({
        matchId,
        matchPlayerOrigin,
      });
      actions.matchSheetManagement.resetPreviousMatchPlayersTotal({
        matchId,
        matchPlayerOrigin,
      });
    };
  }, []);

  useEffect(() => {
    competitorId &&
      actions.matchSheetManagement.getMatchPlayers({
        matchId,
        matchPlayerOrigin,
        competitorId,
      });
    competitorId &&
      actions.matchSheetManagement.getMatchTeamOfficials({
        matchId,
        competitorId,
        matchPlayerOrigin,
      });
  }, [competitorId]);

  return (
    <Grid>
      <Accordion expanded>
        <AccordionSummary className="without-cursor">
          <ExpansionPanelTitle
            strongTitle
            increasedSize
            title={t('Manage match lineup')}
          />
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <FullScreenSpinner />
          ) : (
            <Box width="100%">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid container>
                  <Grid item xs={12} sm={7}>
                    <MatchSheetSummaryBlock matchDetails={matchDetails} />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <MatchSheetValidations matchDetails={matchDetails} />
              </Box>
              <MatchSheetPlayersValidations
                matchId={matchId}
                matchPlayerOrigin={matchPlayerOrigin}
              />
              <Box mt={3}>
                <CompetitorSummary
                  competitorTitle={competitorTitle}
                  competitorInternationalTitle={competitorInternationalTitle}
                  competitorLogoFileLink={competitorLogoFileLink}
                />
              </Box>
              <Box mt={3}>
                <MatchSheetManagementTabs
                  matchId={matchId}
                  matchPlayerOrigin={matchPlayerOrigin}
                  tabId={tabId}
                />
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <FormActionsBlock
        tabId={tabId}
        matchId={matchId}
        matchPlayerOrigin={matchPlayerOrigin}
        competitorId={competitorId}
        matchDetails={matchDetails}
      />
    </Grid>
  );
};

const isLoadingSelector = createLoadingSelector([
  matchSheetManagementActions.getMatchPlayersRequest.toString(),
  matchSheetManagementActions.getMatchRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = prop('matchId', ownProps);
  const matchPlayerOrigin = prop('matchPlayerOrigin', ownProps);

  return {
    isLoading: isLoadingSelector(state),
    matchDetails: getMatchDetails(state, { matchId, matchPlayerOrigin }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchSheetManagement);
