import { Formik, Form, FormikErrors, FormikHelpers } from 'formik';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { object, string, number } from 'yup';

import { TextField } from '@core/components';
import {
  MAX_FIELD_LENGTH,
  MAX_DESCRIPTION_FIELD_LENGTH,
  MIN_NUMBER_FIELD_VALUE,
} from '@core/constants';
import { CreateReferreeCategoryData } from '@volleyball/types';

import * as testIds from '../tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  initialFormValues?: CreateReferreeCategoryData;
  onSubmit: (
    values: CreateReferreeCategoryData,
    formikHelpers: FormikHelpers<CreateReferreeCategoryData>,
  ) => void;
  isSubmitting: boolean;
  errors: FormikErrors<any>;
}

type Props = OwnProps;

const useStyles = makeStyles(() => ({
  // root is used to remove vertical scrollbar
  // https://github.com/mui-org/material-ui/issues/7466
  root: {
    width: '100%',
    margin: 0,
  },
  inputContainer: {
    width: '280px',
  },
}));

const RefereeCategoryForm = (props: Props) => {
  const { onSubmit, isSubmitting, initialFormValues, errors } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const validationSchema = object().shape({
    title: string()
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      )
      .required(t('Required')),
    rank: number()
      .min(
        MIN_NUMBER_FIELD_VALUE,
        t('Value should be equal to or greater than {{value}}', {
          value: MIN_NUMBER_FIELD_VALUE,
        }),
      )
      .required(t('Required')),
    description: string().max(
      MAX_DESCRIPTION_FIELD_LENGTH,
      t('Maximum {{value}} characters allowed', {
        value: MAX_DESCRIPTION_FIELD_LENGTH,
      }),
    ),
  });

  const getInitialValues = () => {
    const initialValues = {
      title: '',
      rank: 1,
      description: '',
    };

    return { ...initialValues, ...initialFormValues };
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      initialErrors={errors}
    >
      {() => (
        <Form data-qa={testIds.REFEREE_CATEGORIES_FORM}>
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            className={classes.root}
          >
            <Grid item>
              <TextField
                fullWidth
                disabled={isSubmitting}
                name="title"
                label={t('Title')}
                className={classes.inputContainer}
                inputProps={{
                  'data-qa': testIds.REFEREE_CATEGORIES_TITLE_INPUT,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                disabled={isSubmitting}
                name="rank"
                type="number"
                label={t('Rank')}
                className={classes.inputContainer}
                inputProps={{
                  min: 0,
                  step: 1,
                  'data-qa': testIds.REFEREE_CATEGORIES_RANK_INPUT,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                disabled={isSubmitting}
                name="description"
                label={t('Description')}
                className={classes.inputContainer}
                inputProps={{
                  'data-qa': testIds.REFEREE_CATEGORIES_DESCRIPTION_INPUT,
                }}
              />
            </Grid>
            <Grid item>
              <ButtonWithProgress
                isLoading={isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
                data-qa={testIds.REFEREE_CATEGORIES_SUBMIT_BUTTON}
              >
                {t('Submit')}
              </ButtonWithProgress>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RefereeCategoryForm;
