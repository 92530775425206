import { createActions } from 'redux-actions';

import { NAMESPACE } from './constants';

const actions = createActions(
  'SEARCH_CLUBS',
  'SEARCH_CLUBS_REQUEST',
  'SEARCH_CLUBS_SUCCESS',
  'SEARCH_CLUBS_FAILURE',

  'SET_CLUBS',
  'GET_TEAMS_BY_CLUB',
  'GET_TEAMS_BY_CLUB_REQUEST',
  'GET_TEAMS_BY_CLUB_SUCCESS',
  'GET_TEAMS_BY_CLUB_FAILURE',

  'SET_TEAMS_BY_CLUB',

  'SET_RECENTLY_CREATED_TEAM',
  'CLEAR_RECENTLY_CREATED_TEAM',

  'CREATE_TEAM',
  'CREATE_TEAM_REQUEST',
  'CREATE_TEAM_SUCCESS',
  'CREATE_TEAM_FAILURE',

  'EDIT_TEAM',
  'EDIT_TEAM_REQUEST',
  'EDIT_TEAM_SUCCESS',
  'EDIT_TEAM_FAILURE',

  'SET_ERRORS',

  'CLEAR_STATE',
  { prefix: NAMESPACE },
);

export default actions;
