import { createActions } from 'redux-actions';
import { pick, prop } from 'ramda';

import {
  MatchOfficial,
  Player,
  PlayerAttributes,
  TeamOfficial,
} from '@core/types';
import { NAMESPACE } from './constants';

export const actions = createActions(
  {
    SET_PLAYER_ATTRIBUTES: [prop('payload'), pick(['personId'])],
    SET_MATCH_OFFICIAL_LIST_BY_PERSON: [prop('payload'), pick(['personId'])],
    SET_TEAM_OFFICIAL_LIST_BY_PERSON: [prop('payload'), pick(['personId'])],
    SET_PLAYER_LIST_BY_PERSON: [prop('payload'), pick(['personId'])],
  },
  'CREATE_PERSON',
  'CREATE_PERSON_REQUEST',
  'CREATE_PERSON_SUCCESS',
  'CREATE_PERSON_FAILURE',

  'DELETE_PERSON',
  'DELETE_PERSON_REQUEST',
  'DELETE_PERSON_SUCCESS',
  'DELETE_PERSON_FAILURE',

  'UPDATE_PERSON',
  'UPDATE_PERSON_REQUEST',
  'UPDATE_PERSON_SUCCESS',
  'UPDATE_PERSON_FAILURE',

  'UPDATE_PERSON_POPULAR_NAME',
  'UPDATE_PERSON_POPULAR_NAME_REQUEST',
  'UPDATE_PERSON_POPULAR_NAME_SUCCESS',
  'UPDATE_PERSON_POPULAR_NAME_FAILURE',

  'CREATE_CLUB_PLAYER',
  'CREATE_CLUB_PLAYER_REQUEST',
  'CREATE_CLUB_PLAYER_SUCCESS',
  'CREATE_CLUB_PLAYER_FAILURE',

  'DELETE_PLAYER',
  'DELETE_PLAYER_REQUEST',
  'DELETE_PLAYER_SUCCESS',
  'DELETE_PLAYER_FAILURE',

  'UPDATE_PLAYER_ATTRIBUTES',
  'UPDATE_PLAYER_ATTRIBUTES_REQUEST',
  'UPDATE_PLAYER_ATTRIBUTES_SUCCESS',
  'UPDATE_PLAYER_ATTRIBUTES_FAILURE',

  'GET_PLAYER_ATTRIBUTES',
  'GET_PLAYER_ATTRIBUTES_REQUEST',
  'GET_PLAYER_ATTRIBUTES_SUCCESS',
  'GET_PLAYER_ATTRIBUTES_FAILURE',

  'CREATE_MATCH_OFFICIAL',
  'CREATE_MATCH_OFFICIAL_REQUEST',
  'CREATE_MATCH_OFFICIAL_SUCCESS',
  'CREATE_MATCH_OFFICIAL_FAILURE',

  'DELETE_MATCH_OFFICIAL',
  'DELETE_MATCH_OFFICIAL_REQUEST',
  'DELETE_MATCH_OFFICIAL_SUCCESS',
  'DELETE_MATCH_OFFICIAL_FAILURE',

  'CREATE_TEAM_OFFICIAL',
  'CREATE_TEAM_OFFICIAL_REQUEST',
  'CREATE_TEAM_OFFICIAL_SUCCESS',
  'CREATE_TEAM_OFFICIAL_FAILURE',

  'DELETE_TEAM_OFFICIAL',
  'DELETE_TEAM_OFFICIAL_REQUEST',
  'DELETE_TEAM_OFFICIAL_SUCCESS',
  'DELETE_TEAM_OFFICIAL_FAILURE',

  'GET_PLAYER_LIST_BY_PERSON',
  'GET_PLAYER_LIST_BY_PERSON_REQUEST',
  'GET_PLAYER_LIST_BY_PERSON_SUCCESS',
  'GET_PLAYER_LIST_BY_PERSON_FAILURE',

  'GET_MATCH_OFFICIAL_LIST_BY_PERSON',
  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_REQUEST',
  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_SUCCESS',
  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_FAILURE',

  'GET_TEAM_OFFICIAL_LIST_BY_PERSON',
  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_REQUEST',
  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_SUCCESS',
  'GET_TEAM_OFFICIAL_LIST_BY_PERSON_FAILURE',

  {
    prefix: NAMESPACE,
  },
) as unknown as {
  createPerson: IdentityActionFunction<{
    data: unknown;
    tabId: string;
    onSuccess: () => void;
  }>;
  createPersonRequest: RequestLifecycleAction;
  createPersonSuccess: RequestLifecycleAction;
  createPersonFailure: RequestLifecycleAction;

  deletePerson: IdentityActionFunction<{
    personId: number;
    onSuccess: () => void;
  }>;
  deletePersonRequest: RequestLifecycleAction;
  deletePersonSuccess: RequestLifecycleAction;
  deletePersonFailure: RequestLifecycleAction;

  updatePerson: IdentityActionFunction<{
    data: unknown;
    personId: number;
    tabId: string;
    onSuccess: () => void;
  }>;
  updatePersonRequest: RequestLifecycleAction;
  updatePersonSuccess: RequestLifecycleAction;
  updatePersonFailure: RequestLifecycleAction;

  /** @deprecated not dispatched anywhere */
  updatePersonPopularName: UnknownAction;
  updatePersonPopularNameRequest: RequestLifecycleAction;
  updatePersonPopularNameSuccess: RequestLifecycleAction;
  updatePersonPopularNameFailure: RequestLifecycleAction;

  createClubPlayer: IdentityActionFunction<{
    clubId: number;
    personId: number;
    sportId: Array<number>;
    onSuccess: () => void;
  }>;
  createClubPlayerRequest: RequestLifecycleAction;
  createClubPlayerSuccess: RequestLifecycleAction;
  createClubPlayerFailure: RequestLifecycleAction;

  deletePlayer: IdentityActionFunction<{
    playerId: number;
    personId: number;
    onSuccess: () => void;
  }>;
  deletePlayerRequest: RequestLifecycleAction;
  deletePlayerSuccess: RequestLifecycleAction;
  deletePlayerFailure: RequestLifecycleAction;

  updatePlayerAttributes: IdentityActionFunction<{
    data: PlayerAttributes;
    personId: number;
  }>;
  updatePlayerAttributesRequest: RequestLifecycleAction;
  updatePlayerAttributesSuccess: RequestLifecycleAction;
  updatePlayerAttributesFailure: RequestLifecycleAction;

  getPlayerAttributes: IdentityActionFunction<{ personId: number }>;
  getPlayerAttributesRequest: RequestLifecycleAction;
  getPlayerAttributesSuccess: RequestLifecycleAction;
  getPlayerAttributesFailure: RequestLifecycleAction;
  setPlayerAttributes: IdentityActionMetaFunction<
    { payload: PlayerAttributes; personId: number },
    { payload: PlayerAttributes },
    { personId: number }
  >;

  createMatchOfficial: IdentityActionFunction<{
    personId: number;
    sportId: number;
    roles: Array<{ id: number }>;
    onSuccess: () => void;
  }>;
  createMatchOfficialRequest: RequestLifecycleAction;
  createMatchOfficialSuccess: RequestLifecycleAction;
  createMatchOfficialFailure: RequestLifecycleAction;

  deleteMatchOfficial: IdentityActionFunction<{
    matchOfficialId: number;
    personId: number;
    onSuccess: () => void;
  }>;
  deleteMatchOfficialRequest: RequestLifecycleAction;
  deleteMatchOfficialSuccess: RequestLifecycleAction;
  deleteMatchOfficialFailure: RequestLifecycleAction;

  createTeamOfficial: IdentityActionFunction<{
    clubId: number;
    personId: number;
    teamOfficialRoleId: Array<number>;
    sportId: number;
    onSuccess: () => void;
  }>;
  createTeamOfficialRequest: RequestLifecycleAction;
  createTeamOfficialSuccess: RequestLifecycleAction;
  createTeamOfficialFailure: RequestLifecycleAction;

  deleteTeamOfficial: IdentityActionFunction<{
    teamOfficialId: number;
    personId: number;
    onSuccess: () => void;
  }>;
  deleteTeamOfficialRequest: RequestLifecycleAction;
  deleteTeamOfficialSuccess: RequestLifecycleAction;
  deleteTeamOfficialFailure: RequestLifecycleAction;

  getPlayerListByPerson: IdentityActionFunction<{ personId: number }>;
  getPlayerListByPersonRequest: RequestLifecycleAction;
  getPlayerListByPersonSuccess: RequestLifecycleAction;
  getPlayerListByPersonFailure: RequestLifecycleAction;
  setPlayerListByPerson: IdentityActionMetaFunction<
    { payload: Array<Player>; personId: number },
    { payload: Array<Player> },
    { personId: number }
  >;

  getMatchOfficialListByPerson: IdentityActionFunction<{ personId: number }>;
  getMatchOfficialListByPersonRequest: RequestLifecycleAction;
  getMatchOfficialListByPersonSuccess: RequestLifecycleAction;
  getMatchOfficialListByPersonFailure: RequestLifecycleAction;
  setMatchOfficialListByPerson: IdentityActionMetaFunction<
    { payload: Array<MatchOfficial>; personId: number },
    { payload: Array<MatchOfficial> },
    { personId: number }
  >;

  getTeamOfficialListByPerson: IdentityActionFunction<{ personId: number }>;
  getTeamOfficialListByPersonRequest: RequestLifecycleAction;
  getTeamOfficialListByPersonSuccess: RequestLifecycleAction;
  getTeamOfficialListByPersonFailure: RequestLifecycleAction;
  setTeamOfficialListByPerson: IdentityActionMetaFunction<
    { payload: Array<TeamOfficial>; personId: number },
    { payload: Array<TeamOfficial> },
    { personId: number }
  >;
};
