import React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryBlockValues } from '@core/components';

interface OwnProps {
  teamsCount: string;
}

type Props = OwnProps;

export const CompetitorSummaryBlock = (props: Props) => {
  const { teamsCount } = props;
  const { t } = useTranslation();

  const getSummaryBlock = (): SummaryBlockValues => [
    {
      value: teamsCount,
      label: t('Teams'),
    },
  ];

  return <SummaryBlock values={getSummaryBlock()} />;
};

export default CompetitorSummaryBlock;
