import { createActions } from 'redux-actions';

export const NAMESPACE = 'venuesList';

export const actions = createActions(
  'CREATE_VENUE_REQUEST',
  'CREATE_VENUE_SUCCESS',
  'CREATE_VENUE_FAILURE',

  'PATCH_VENUE_REQUEST',
  'PATCH_VENUE_SUCCESS',
  'PATCH_VENUE_FAILURE',

  'DELETE_VENUE_REQUEST',
  'DELETE_VENUE_SUCCESS',
  'DELETE_VENUE_FAILURE',

  'GET_VENUE_REQUEST',
  'GET_VENUE_SUCCESS',
  'GET_VENUE_FAILURE',

  'GET_VENUES_REQUEST',
  'GET_VENUES_SUCCESS',
  'GET_VENUES_FAILURE',

  'GET_VENUES_BY_CLUB_REQUEST',
  'GET_VENUES_BY_CLUB_SUCCESS',
  'GET_VENUES_BY_CLUB_FAILURE',

  'SET_VENUE_LIST',
  'SET_VENUE',

  'RESET_VENUE_LIST',

  { prefix: NAMESPACE },
);
