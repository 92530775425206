import React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { AddedMatchTeamOfficialList, MatchPlayersList } from '@core/types';
import { filterStartingLineup } from '@core/factories/match';

export interface OwnProps {
  playersList: MatchPlayersList;
  isPlayersLoading: boolean;
  isTeamOfficialsLoading: boolean;
  teamOfficialsList: AddedMatchTeamOfficialList;
  playerKitSelect: React.ReactNode;
}

export type Props = OwnProps;

const MatchSummaryBlock = (props: Props) => {
  const {
    playersList,
    isPlayersLoading,
    teamOfficialsList,
    isTeamOfficialsLoading,
    playerKitSelect,
  } = props;
  const { t } = useTranslation();
  const startingLineup = filterStartingLineup(playersList);

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Starting lineup'),
      value: startingLineup && startingLineup.length,
    },
    {
      label: t('Team officials'),
      value: teamOfficialsList && teamOfficialsList.length,
    },
    {
      label: 'notLabelButChildKey1',
      node: playerKitSelect,
    },
  ];

  return (
    <SummaryBlock
      isLoading={isPlayersLoading || isTeamOfficialsLoading}
      values={getInfoBlocks()}
    />
  );
};

export default MatchSummaryBlock;
