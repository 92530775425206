import { useState, ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextField, RadioGroup, ContentBlock } from '@core/components';
import {
  CompetitionResponse,
  OffenderType,
  SanctionType,
} from '@volleyball/types';
import { MATCH_SANCTION_FIELDS, OFFENDER_TYPE_FIELDS } from '../constants';
import OffenderTypePlayer from './offender-type-player';
import OffenderTypeTeamOfficial from './offender-type-team-official';
import RelatedMatchBlock from './related-match-block';
import RequiredFieldsMessage from './required-fields-message';
import type { FormMode } from '../constants';
import type { MatchModel } from '@core/types';

type OwnProps = {
  competition: CompetitionResponse;
  match: MatchModel;
  setMatch: (match: MatchModel) => void;
  offenderType: OffenderType;
  mode: FormMode;
};

type Props = OwnProps;

const MatchSuspensionForm = (props: Props) => {
  const { competition, match, setMatch, offenderType, mode } = props;
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(offenderType);

  function handleTypeChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedValue(event.target.value as OffenderType);
  }

  return (
    <Grid>
      <RequiredFieldsMessage />
      <ContentBlock spacing={3} title={t('Competition')} mb={5} pb={5}>
        <Grid item xs={12} md={5}>
          <TextField
            name={MATCH_SANCTION_FIELDS.competitionTitle}
            label={t('Competition')}
            disabled
            required
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            name={MATCH_SANCTION_FIELDS.competitionSeason}
            label={t('Season')}
            required
            disabled
          />
        </Grid>
      </ContentBlock>
      <ContentBlock spacing={3} title={t('Offender')} mb={5} pb={5}>
        <Grid item xs={12} md={10}>
          <RadioGroup
            selectedValue={selectedValue}
            labelBackground="inherit"
            name={OFFENDER_TYPE_FIELDS.offenderType}
            onChange={handleTypeChange}
            disabled={mode === 'edit'}
            blocks={[
              {
                label: t('Player'),
                value: OffenderType.PLAYER,
                content: (
                  <OffenderTypePlayer
                    competitionId={competition?.id}
                    mode={mode}
                    sanctionType={SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES}
                  />
                ),
              },
              {
                label: t('Team official'),
                value: OffenderType.TEAM_OFFICIAL,
                content: (
                  <OffenderTypeTeamOfficial
                    competitionId={competition?.id}
                    mode={mode}
                    sanctionType={SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </ContentBlock>
      <ContentBlock spacing={3} title={t('Suspension')} mb={5} pb={5}>
        <Grid item xs={12} md={5}>
          <TextField
            name={MATCH_SANCTION_FIELDS.numberOfMatches}
            label={t('Number of matches')}
            type="number"
            required
            inputProps={{
              min: 0,
              step: 1,
            }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            name={MATCH_SANCTION_FIELDS.commment}
            label={t('Comment')}
            multiline
            minRows={6}
          />
        </Grid>
      </ContentBlock>
      <RelatedMatchBlock
        competitionId={competition?.id}
        match={match}
        setMatch={setMatch}
        sanctionType={SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES}
      />
    </Grid>
  );
};

export default MatchSuspensionForm;
