import React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as testIds from '../tests/test-ids';

const useStyles = makeStyles((theme: Theme) => ({
  panel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      overflowX: 'hidden',
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles();
  const { children, value, index } = props;
  const isHidden = value !== index;

  return (
    <Grid
      className={classes.panel}
      data-qa={testIds.TAB_PANEL}
      role="tabpanel"
      hidden={isHidden}
    >
      {children}
    </Grid>
  );
};
