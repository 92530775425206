import React from 'react';
import { Grid, Divider, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Filters, FilterTabsList } from '@core/types';
import { State } from '@core/store';
import { actions as filterActions } from '@core/store/modules/ui/filter';
import { getFiltersList } from '@core/store/modules/ui/filter/selectors';

import FilterActions from './filter-actions';
import FilterTabs from './filter-tabs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  actions: {
    [theme.breakpoints.only('sm')]: {
      position: 'relative',
      bottom: 'calc(100vh - 100%)',
      background: theme.palette.common.white,
    },
  },
}));

type OwnProps = {
  tabsList: FilterTabsList;
  onSave: (filters: Filters) => void;
  initialFilters?: Filters;
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
};

type DispatchProps = {
  actions: {
    filter: typeof filterActions;
  };
};

type StateProps = {
  filters: Filters;
};

type Props = OwnProps & DispatchProps & StateProps;

const FilterContent = (props: Props) => {
  const { tabsList, actions, onSave, handleClose, filters } = props;
  const classes = useStyles();

  const onClose = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    actions.filter.clearFilters();
  };

  return (
    <Grid className={classes.root}>
      <FilterTabs tabsList={tabsList} />
      <Box className={classes.actions}>
        <Divider />
        <FilterActions onClose={onClose} onSave={onSave} filters={filters} />
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  filters: getFiltersList(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    filter: bindActionCreators(filterActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
