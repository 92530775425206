import { useState } from 'react';
import axios from 'axios';
import { Grid, Theme, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    position: 'absolute',
    bottom: ' 1px',
    display: 'flex',
    height: '35px',
    alignItems: 'center',
  },
  item: {
    cursor: 'pointer',
    padding: '5px',
    margin: '3px',
    backgroundColor: alpha(theme.palette.grey[300], 0.8),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '60px',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '12px',
  },
  icon: {
    cursor: 'pointer',
    color: theme.palette.primary.light,
  },
}));

const LoginBar = () => {
  const classes = useStyles();
  const [rolesVisible, setRolesVisible] = useState(false);

  const loginAs = (role: string) => {
    axios({
      method: 'get',
      url: `/development-helpers/auth?key=${role}`,
      baseURL: import.meta.env.VITE_API_URL,
      withCredentials: true,
    });
    localStorage.setItem('authorized', '2022-11-30T12:00:46.070Z');
    localStorage.setItem('sessionDuration', '1800000');
    localStorage.setItem('authProvider', 'cognito');
    localStorage.setItem(
      'sessionExpires',
      dayjs().add(864000000, 'ms').toISOString(),
    );

    setRolesVisible(false);
    setTimeout(() => {
      if (globalThis.location.href.includes('login')) {
        globalThis.location.href = globalThis.location.href.replace(
          'login',
          'home',
        );

        return;
      }

      globalThis.location.reload();
    }, 1000);
  };

  const loginAsSuperAdmin = () => loginAs('super.admin');
  const loginAsCompetitionManager = () => loginAs('competition.manager');
  const loginAsReferee = () => loginAs('referee');
  const loginAsMatchCommissioner = () => loginAs('match.commissioner');
  const loginAsUser = () => loginAs('user');
  const loginAsClubAdmin = () => loginAs('club.admin');
  const hideRoles = () => setRolesVisible(false);
  const showRoles = () => setRolesVisible(true);

  if (!['development'].includes(import.meta.env.MODE)) {
    return <></>;
  }

  return (
    <Grid className={classes.bar}>
      {rolesVisible ? (
        <>
          <ChevronLeft className={classes.icon} onClick={hideRoles} />
          <Grid className={classes.item} onClick={loginAsSuperAdmin}>
            Super admin
          </Grid>
          <Grid className={classes.item} onClick={loginAsCompetitionManager}>
            Competition manager
          </Grid>
          <Grid className={classes.item} onClick={loginAsReferee}>
            Referee
          </Grid>
          <Grid className={classes.item} onClick={loginAsMatchCommissioner}>
            Match commissioner
          </Grid>
          <Grid className={classes.item} onClick={loginAsUser}>
            User
          </Grid>
          <Grid className={classes.item} onClick={loginAsClubAdmin}>
            Club admin
          </Grid>
        </>
      ) : (
        <ChevronRight className={classes.icon} onClick={showRoles} />
      )}
    </Grid>
  );
};

export default LoginBar;
