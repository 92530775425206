import restService from '@core/api/rest-service';
import { FeatureToggleResponse } from '@core/types';

export const getFeatureToggles = () =>
  restService.get<FeatureToggleResponse>('/v1/featureToggles');

export const changeFeatureToggleStatus = (name: string, status: boolean) =>
  restService.post(
    `/v1/featureToggles/${name}/${status ? 'ENABLE' : 'DISABLE'}`,
  );
