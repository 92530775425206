import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import StageMatchOfficialsBlock from '@core/components/stage-match-officials-block';
import { Group, Competition, Sports, StageType } from '@core/types';
import StageMatchDetailsBlock from './components/stage-match-details-block';
import { getInitialValues } from './helpers/initialValues';
import { getValidationSchema } from './helpers/validationSchema';
import GoldenRulesBlock from './components/golden-rules-block';

export type Props = {
  initialValues?: Group;
  editMode?: boolean;
  competition: Competition;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
};

const StageSettingsTemplate = forwardRef<FormikProps<any>, Props>(
  ({ initialValues, editMode, competition, onSubmit }, ref) => {
    const { t } = useTranslation();

    const handleSubmit: Props['onSubmit'] = (values, formikHelpers) => {
      onSubmit(values, formikHelpers);
    };

    const renderForm = (formikProps: FormikProps<any>) => {
      const { dirty } = formikProps;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTabStateSetter(dirty);

      return (
        <Form noValidate>
          <Grid container spacing={2}>
            <StageMatchDetailsBlock />
            {initialValues?.stageType === StageType.KNOCK_OUT && (
              <GoldenRulesBlock editMode={editMode} />
            )}
            <StageMatchOfficialsBlock
              initialValues={initialValues}
              competition={competition}
            />
          </Grid>
        </Form>
      );
    };

    return (
      <>
        <Grid container item xs={12} justifyContent="flex-end">
          <Box mb={3}>
            <Typography variant="caption" color="textSecondary">
              {`* ${t('Indicates a required field')}`}
            </Typography>
          </Box>
        </Grid>
        <Formik
          innerRef={ref}
          initialValues={getInitialValues(
            initialValues?.settings,
            competition?.sportTitle as keyof typeof Sports,
          )}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(t)}
          validateOnChange={false}
        >
          {renderForm}
        </Formik>
      </>
    );
  },
);

export default StageSettingsTemplate;
