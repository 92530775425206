import { propOr } from 'ramda';
import { Avatar, Link } from '@mui/material';
import { Member } from '@core/icons';
import { joinStringValues } from '@core/helpers';
import { ColumnWithSubValue } from '@core/components';

type Alignment = 'right' | 'left';

interface OwnProps {
  person: any;
  withLogo?: boolean;
  alignment?: Alignment;
  linkAction?: any;
  isCaptain?: boolean;
  isLibero?: boolean;
  isDisabled?: boolean;
  showInitials?: boolean;
  startText?: string;
}

type Props = OwnProps;

const PersonNameColumn = (props: Props) => {
  const {
    person,
    withLogo,
    alignment = 'left',
    linkAction,
    isCaptain,
    isLibero,
    isDisabled,
    showInitials,
    startText,
  } = props;
  const firstName = propOr('', 'firstName', person);
  const familyName = propOr('', 'familyName', person);
  const name = [firstName, familyName];
  const localFirstName = propOr('', 'localFirstName', person);
  const localFamilyName = propOr('', 'localFamilyName', person);
  const localName = [localFirstName, localFamilyName];

  return withLogo ? (
    <ColumnWithSubValue
      alignment={alignment}
      value={
        linkAction ? (
          <Link onClick={linkAction} style={{ cursor: 'pointer' }}>
            {joinStringValues(localName) as string}
          </Link>
        ) : (
          (joinStringValues(localName) as string)
        )
      }
      subValue={joinStringValues(name) as string}
      logo={{
        url: person.logoFileLink,
        defaultIcon: showInitials ? (
          <Avatar>
            {(localFirstName as string).charAt(0)}
            {(localFamilyName as string).charAt(0)}
          </Avatar>
        ) : (
          <Member />
        ),
      }}
      startText={startText}
      isCaptain={isCaptain}
      isLibero={isLibero}
      isDisabled={isDisabled}
    />
  ) : (
    <ColumnWithSubValue
      startText={startText}
      alignment={alignment}
      value={joinStringValues(localName) as string}
      subValue={joinStringValues(name) as string}
      isDisabled={isDisabled}
    />
  );
};

export default PersonNameColumn;
