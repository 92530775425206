import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  ul: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const PasswordExplanation = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle2">
        {t('Password should contain')}:
      </Typography>
      <ul className={classes.ul}>
        <li>{t('At least 8 characters')}</li>
        <li>{t('An upper and lower case letters')}</li>
        <li>{t('A number and a special character')}</li>
      </ul>
    </>
  );
};

export default PasswordExplanation;
