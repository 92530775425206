import { createTheme, ThemeOptions, Theme } from '@mui/material';
import { enUS } from '@mui/material/locale';
import { deepObjectCopy, deepObjectMerge } from '../helpers';
import baseTheme from './base';

const createThemeObject = (partialTheme: ThemeOptions): ThemeOptions =>
  deepObjectMerge({}, deepObjectCopy(baseTheme), partialTheme);

export const defaultTheme = createTheme(baseTheme);

// Use this function in products to overwrite base theme
export const createProductTheme = (partialTheme: ThemeOptions): Theme =>
  createTheme(createThemeObject(partialTheme), enUS);
