export const USER_PROFILE_PAGE = 'USER_PROFILE_PAGE';
export const USER_PROFILE_ACCOUNT_INFO_BLOCK =
  'USER_PROFILE_ACCOUNT_INFO_BLOCK';
export const USER_PROFILE_PERSONAL_INFO_BLOCK =
  'USER_PROFILE_PERSONAL_INFO_BLOCK';
export const USER_PROFILE_USER_ROLES_BLOCK = 'USER_PROFILE_USER_ROLES_BLOCK';
export const USER_PROFILE_USER_ROLE_REQUEST_BLOCK =
  'USER_PROFILE_USER_ROLE_REQUEST_BLOCK';
export const USER_PROFILE_REQUEST_ROLE_BUTTON =
  'USER_PROFILE_REQUEST_ROLE_BUTTON';
export const USER_ROLE_REQUEST_MODAL_REQUEST_BUTTON =
  'USER_ROLE_REQUEST_MODAL_REQUEST_BUTTON';
export const USER_ROLE_REQUEST_MODAL_VIEW_REQUEST_BUTTON =
  'USER_ROLE_REQUEST_MODAL_VIEW_REQUEST_BUTTON';
export const USER_PROFILE_GENERAL_TAB = 'USER_PROFILE_GENERAL_TAB';
export const USER_PROFILE_ROLES_TAB = 'USER_PROFILE_ROLES_TAB';
