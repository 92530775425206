import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AlertStatus, PersonNameColumn, CustomTable } from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { getGenders, formatDate, formatNationalities } from '@core/helpers';
import { Column } from '@core/components/material-table';
import {
  CustomerResponse,
  CompetitorPlayerList,
  CompetitorPlayer,
  Competition,
} from '@core/types';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { State } from '@core/store';
import { getTitle, APP_TITLES } from '@core/helpers/app-sports/title-getters';

interface OwnProps {
  players: CompetitorPlayerList;
  sportPlayerHasPosition: boolean;
  competitorId: number;
  competitionId: number;
  teamId: number;
}
interface StateProps {
  competition: Competition;
  currentCustomer: CustomerResponse;
}

type Props = OwnProps & StateProps;

const PlayersTable = (props: Props) => {
  const { players, sportPlayerHasPosition, competition, currentCustomer } =
    props;
  const { t } = useTranslation();

  const getColumns = (): Array<Column<CompetitorPlayer>> => {
    return new Array<Column<CompetitorPlayer>>(
      {
        title: t('Name'),
        render: (rowData) => (
          <PersonNameColumn person={rowData.player} withLogo />
        ),
      },
      {
        title: getTitle(APP_TITLES.POPULAR_NAME),
        render: (rowData) => rowData?.player?.popularName ?? '-',
      },
      {
        title: t('Gender'),
        render: (rowData) => getGenders(t, rowData?.player?.gender) ?? '-',
      },
      {
        title: t('Date of Birth'),
        render: (rowData) => {
          const dateOfBirth = formatDate(
            currentCustomer?.dateFormat,
            rowData?.player?.dateOfBirth ?? '-',
          );
          const earliestDateOfBirth =
            competition?.competitionSettings?.latestPlayerDateOfBirth;

          return (
            <>
              {dateOfBirth}
              {earliestDateOfBirth && earliestDateOfBirth >= dateOfBirth && (
                <AlertStatus title={t('Overage')} />
              )}
            </>
          );
        },
      },
      {
        title: t('Nationality'),
        render: (rowData) =>
          formatNationalities(rowData?.player?.nationalCitizenships || []),
      },
      sportPlayerHasPosition && {
        title: t('Pos.'),
        render: (rowData) => rowData?.playerPosition?.shortName ?? '-',
      },
      {
        title: t('Jersey'),
        render: (rowData) => rowData?.jerseyNumber ?? '-',
      },
    ).filter(Boolean);
  };

  return (
    <CustomTable
      isForm
      columns={getColumns()}
      titleRow={t('Players')}
      data={players || []}
      noPaper
      localization={{
        body: {
          emptyDataSourceMessage: t('No players. Please manage.'),
        },
      }}
      options={{
        showTitle: true,
        actionsColumnIndex: -1,
      }}
    />
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    competition: getCompetitionDetailsById(ownProps.competitionId)(state),
    currentCustomer: getCurrentCustomer(state),
  };
};

export default connect(mapStateToProps, null)(PlayersTable);
