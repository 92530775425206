import { createAction } from 'redux-actions';

import { ClubModel } from '@core/types';

import { NAMESPACE } from './constants';
import {
  SetKitSetList,
  SetClub,
  DeleteKitSet,
  SetTeams,
  SetMembers,
  GetMembers,
  DeleteClubTeam,
  DeleteClub,
} from './types';

export interface PatchClubPayload {
  clubId: number;
  tabId: string;
  data: ClubModel;
}

const getClub = createAction<number>(`${NAMESPACE}/GET_CLUB`);
const getClubRequest = createAction<void>(`${NAMESPACE}/GET_CLUB_REQUEST`);
const getClubSuccess = createAction<void>(`${NAMESPACE}/GET_CLUB_SUCCESS`);
const getClubFailure = createAction<void>(`${NAMESPACE}/GET_CLUB_FAILURE`);
const setClub = createAction<SetClub>(`${NAMESPACE}/SET_CLUB`);

const patchClub = createAction<PatchClubPayload>(`${NAMESPACE}/PATCH_CLUB`);
const patchClubRequest = createAction<void>(`${NAMESPACE}/PATCH_CLUB_REQUEST`);
const patchClubSuccess = createAction<void>(`${NAMESPACE}/PATCH_CLUB_SUCCESS`);
const patchClubFailure = createAction<void>(`${NAMESPACE}/PATCH_CLUB_FAILURE`);

const deleteClub = createAction<DeleteClub>(`${NAMESPACE}/DELETE_CLUB`);
const deleteClubRequest = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_REQUEST`,
);
const deleteClubSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_SUCCESS`,
);
const deleteClubFailure = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_FAILURE`,
);

const deleteClubTeam = createAction<DeleteClubTeam>(
  `${NAMESPACE}/DELETE_CLUB_TEAM`,
);
const deleteClubTeamRequest = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_TEAM_REQUEST`,
);
const deleteClubTeamSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_TEAM_SUCCESS`,
);
const deleteClubTeamFailure = createAction<void>(
  `${NAMESPACE}/DELETE_CLUB_TEAM_FAILURE`,
);

const getKitSetList = createAction<number>(`${NAMESPACE}/GET_KIT_SET_LIST`);
const getKitSetListRequest = createAction<{ clubId: number }>(
  `${NAMESPACE}/GET_KIT_SET_LIST_REQUEST`,
);
const getKitSetListSuccess = createAction<{ clubId: number }>(
  `${NAMESPACE}/GET_KIT_SET_LIST_SUCCESS`,
);
const getKitSetListFailure = createAction<{ clubId: number }>(
  `${NAMESPACE}/GET_KIT_SET_LIST_FAILURE`,
);
const setKitSetList = createAction<SetKitSetList>(
  `${NAMESPACE}/SET_KIT_SET_LIST`,
);

const deleteKitSet = createAction<DeleteKitSet>(`${NAMESPACE}/DELETE_KIT_SET`);
const deleteKitSetRequest = createAction<{ kitSetId: number }>(
  `${NAMESPACE}/DELETE_KIT_SET_REQUEST`,
);
const deleteKitSetSuccess = createAction<{ kitSetId: number }>(
  `${NAMESPACE}/DELETE_KIT_SET_SUCCESS`,
);
const deleteKitSetFailure = createAction<{ kitSetId: number }>(
  `${NAMESPACE}/DELETE_KIT_SET_FAILURE`,
);

const getTeams = createAction<number>(`${NAMESPACE}/GET_TEAMS`);
const getTeamsRequest = createAction<void>(`${NAMESPACE}/GET_TEAMS_REQUEST`);
const getTeamsSuccess = createAction<void>(`${NAMESPACE}/GET_TEAMS_SUCCESS`);
const getTeamsFailure = createAction<void>(`${NAMESPACE}/GET_TEAMS_FAILURE`);
const setTeams = createAction<SetTeams>(`${NAMESPACE}/SET_TEAMS`);

const getMembers = createAction<GetMembers>(`${NAMESPACE}/GET_PERSONS`);
// TODO: JB: request life cycle events should really be { clubId: number } (so too deeper actions)
const getMembersRequest = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_REQUEST`,
);
const getMembersSuccess = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_SUCCESS`,
);
const getMembersFailure = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_FAILURE`,
);
const setMembers = createAction<SetMembers>(`${NAMESPACE}/SET_MEMBERS`);
const searchMembersByQuery = createAction<GetMembers>(
  `${NAMESPACE}/SEARCH_MEMBERS_BY_QUERY`,
);
const setMembersQuery = createAction<{ clubId: number; data: string }>(
  `${NAMESPACE}/SET_MEMBERS_QUERY`,
);
const setFilterParams = createAction<any>(`${NAMESPACE}/SET_FILTER_PARAMS`);

const resetClubState = createAction<{ clubId: number }>(
  `${NAMESPACE}/RESET_CLUB_STATE`,
);

export default {
  getClub,
  getClubRequest,
  getClubSuccess,
  getClubFailure,
  setClub,

  patchClub,
  patchClubRequest,
  patchClubSuccess,
  patchClubFailure,

  deleteClub,
  deleteClubRequest,
  deleteClubSuccess,
  deleteClubFailure,

  deleteClubTeam,
  deleteClubTeamRequest,
  deleteClubTeamSuccess,
  deleteClubTeamFailure,

  getKitSetList,
  getKitSetListRequest,
  getKitSetListSuccess,
  getKitSetListFailure,
  setKitSetList,

  deleteKitSet,
  deleteKitSetRequest,
  deleteKitSetSuccess,
  deleteKitSetFailure,

  getTeams,
  getTeamsRequest,
  getTeamsFailure,
  getTeamsSuccess,
  setTeams,

  getMembers,
  getMembersRequest,
  getMembersFailure,
  getMembersSuccess,
  setMembers,
  searchMembersByQuery,
  setMembersQuery,
  setFilterParams,

  resetClubState,
};
