import { TFunction } from 'i18next';
import { getSet } from '@core/helpers';
import {
  StandingsTableHeaderTypes,
  StandingsTableColumn,
  StandingsHeader,
  SetBreakdown,
} from '@volleyball/types';

const getResultBreakdownColumns = (breakdownColumns: SetBreakdown) =>
  breakdownColumns.map((column) => ({
    title: getSet(column.value),
    key: column.value,
  }));

export const getMainHeaderColumn = (
  t: TFunction,
  tableHeader: StandingsHeader | { label: 'actions' },
): StandingsTableColumn | null => {
  switch (tableHeader.label) {
    case StandingsTableHeaderTypes.MATCH_POINTS:
      return {
        title: '',
        childColumns: [
          {
            title: t('PTS'),
            key: 'points',
          },
        ],
      };
    case StandingsTableHeaderTypes.MATCHES:
      return {
        title: t('Matches'),
        colSpan: 3,
        childColumns: [
          {
            title: t('MP'),
            key: 'gamesPlayed',
          },
          {
            title: t('MW'),
            key: 'gamesWon',
          },
          {
            title: t('ML'),
            key: 'gamesLost',
          },
        ],
      };
    case StandingsTableHeaderTypes.SETS:
      return {
        title: t('Sets'),
        colSpan: 2,
        childColumns: [
          {
            title: t('SW'),
            key: 'setsWon',
          },
          {
            title: t('SL'),
            key: 'setsLost',
          },
        ],
      };
    case StandingsTableHeaderTypes.POINTS:
      return {
        title: t('Points'),
        colSpan: 2,
        childColumns: [
          {
            title: t('W'),
            key: 'scoredPoints',
          },
          {
            title: t('L'),
            key: 'missedPoints',
          },
        ],
      };
    case StandingsTableHeaderTypes.RESULT_BREAKDOWN:
      return {
        title: t('Results Breakdown'),
        colSpan: tableHeader?.setBreakdown?.length,
        childColumns:
          tableHeader.setBreakdown &&
          getResultBreakdownColumns(tableHeader.setBreakdown),
      };
    case StandingsTableHeaderTypes.SET_RATIO:
      return {
        title: '',
        childColumns: [
          {
            title: t('SR'),
            key: 'setsRatio',
          },
        ],
      };
    case StandingsTableHeaderTypes.PERCENTAGE:
      return {
        title: '',
        childColumns: [
          {
            title: t('PR'),
            key: 'percentage',
          },
        ],
      };
    case 'actions':
      return {
        title: '',
        childColumns: [
          {
            title: '',
            key: 'actions',
            align: 'right',
            width: 50,
          },
        ],
      };
    default:
      return null;
  }
};

export const getAlwaysVisibleHeaderColumns = (
  t: TFunction,
): StandingsTableColumn => ({
  title: '',
  colSpan: 2,
  childColumns: [
    {
      title: t('Rank'),
      key: 'position',
      align: 'left',
      width: 45,
    },
    {
      title: t('Team'),
      key: 'competitor',
      align: 'left',
      noBorder: true,
    },
  ],
});
