import { all } from 'redux-saga/effects';

import matchOfficialsSaga from '@core/store/modules/match-officials/sagas';
import matchSaga from '@core/store/modules/api/match/sagas';
import competitionGeneralInformationEditSaga from '@core/store/modules/tabs/competition-general-information-edit/sagas';
import competitionOrganisersSaga from '@core/store/modules/competition-organisers/sagas';
import competitionManagersSaga from '@core/store/modules/competition-managers/sagas';
import matchesSaga from '@core/store/modules/matches/sagas';
import matchOfficialAllocationsSaga from '@core/store/modules/match-official-allocations/sagas';
import matchesAllocationSaga from '@core/store/modules/matches-allocation/sagas';
import teamGridSaga from '@core/store/modules/tabs/team-grid/sagas';
import matchSetSaga from '@core/store/modules/match-set/sagas';
import fixturesSaga from '@core/store/modules/api/fixtures/sagas';

import tabsSagas from '../modules/tabs/sagas';
import pagesSagas from '../modules/pages/sagas';
import uiSaga from '../modules/ui/sagas';
import i18nSaga from '../modules/i18n/sagas';
import availabilities from '../modules/availability/sagas';
import competitionTypesSaga from '../modules/competition-types/sagas';
import sportsSaga from '../modules/sports/sagas';
import groupStandingSettingsSaga from '../modules/group-standing-settings/sagas';
import groupFixtureSettingsSaga from '../modules/group-fixture-settings/sagas';
import groupFixturesUploadSaga from '../modules/group-fixtures-upload/sagas';
import groupStandingsSaga from '../modules/group-standings/sagas';
import filesSaga from '../modules/files/sagas';
import modalsSaga from '../modules/modals/sagas';
import matchReportsSaga from '../modules/match-reports/sagas';
import competitionMatchOfficialRoleSaga from '../modules/competition-match-official-roles/sagas';
import picturesSaga from '../modules/api/pictures/sagas';
import userRoleSaga from '../modules/api/user-role/sagas';
import citySaga from '../modules/api/city/sagas';
import sharedSaga from '../modules/shared/sagas';

export default function* rootSaga() {
  yield all([
    i18nSaga(),
    matchReportsSaga(),
    availabilities(),
    competitionTypesSaga(),
    competitionOrganisersSaga(),
    competitionManagersSaga(),
    sportsSaga(),
    uiSaga(),
    groupStandingSettingsSaga(),
    groupFixtureSettingsSaga(),
    matchesSaga(),
    matchOfficialAllocationsSaga(),
    matchOfficialsSaga(),
    groupFixturesUploadSaga(),
    matchesAllocationSaga(),
    groupStandingsSaga(),
    filesSaga(),
    modalsSaga(),
    tabsSagas(),
    competitionMatchOfficialRoleSaga(),
    competitionGeneralInformationEditSaga(),
    pagesSagas(),
    picturesSaga(),
    teamGridSaga(),
    userRoleSaga(),
    fixturesSaga(),
    matchSaga(),
    citySaga(),
    matchSetSaga(),
    sharedSaga(),
  ]);
}
