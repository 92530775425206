import React from 'react';

import CompetitionTitleBlock from './components/competition-title-block';
import CompetitionDetailsBlock, {
  OwnProps as CompetitionDetailsBlockProps,
} from './components/competition-details-block';
import CompetitionDatesBlock from './components/competition-dates-block';

type Props = CompetitionDetailsBlockProps;

const GeneralInformationFormTemplate = (props: Props) => {
  const {
    ageGroups,
    seasons,
    competitionLevels,
    sports,
    sportSelectionDisabled,
  } = props;

  return (
    <>
      <CompetitionTitleBlock />
      <CompetitionDetailsBlock
        ageGroups={ageGroups}
        seasons={seasons}
        sports={sports}
        competitionLevels={competitionLevels}
        sportSelectionDisabled={sportSelectionDisabled}
      />
      <CompetitionDatesBlock />
    </>
  );
};

export default GeneralInformationFormTemplate;
