import dayjs from 'dayjs';
import { isNil } from 'ramda';

import { DATE_FORMAT } from '@core/constants/common';
import {
  VenueModel,
  SelectOption,
  GroupCompetitor,
  MatchModel,
} from '@core/types';

import { FIELD_NAMES } from '../constants';

function formatMatchVenueOption(venue: VenueModel) {
  if (!venue) return null;

  if (venue.parentVenue) {
    return {
      label: venue.parentVenue.title,
      value: venue.parentVenue.id,
    };
  }

  return {
    label: venue.title,
    value: venue.id,
  };
}

function formatMatchSubVenueOption(venue: VenueModel) {
  if (!venue || !venue.parentVenue) return null;

  return venue.id;
}

export interface FormValues {
  [FIELD_NAMES.venueOption]: SelectOption;
  [FIELD_NAMES.subVenueOption]: number;
  [FIELD_NAMES.homeCompetitorId]: number;
  [FIELD_NAMES.awayCompetitorId]: number;
  [FIELD_NAMES.number]: number;
  [FIELD_NAMES.groupOption]: number;
  [FIELD_NAMES.matchDayNumber]: number;
  [FIELD_NAMES.date]: string;
  [FIELD_NAMES.time]: string;
}

const mapCompetitorIdToGroupCompetitorId = (
  groupCompetitors: Array<GroupCompetitor>,
  competitorId: number,
) =>
  !isNil(competitorId)
    ? groupCompetitors?.find(
        (groupCompetitor) => groupCompetitor.competitor?.id === competitorId,
      )?.id
    : null;

export const getInitialValues = (
  match: MatchModel,
  groupCompetitors: Array<GroupCompetitor>,
): FormValues => ({
  [FIELD_NAMES.venueOption]: formatMatchVenueOption(match?.venue),
  [FIELD_NAMES.subVenueOption]: formatMatchSubVenueOption(match?.venue),
  [FIELD_NAMES.awayCompetitorId]: mapCompetitorIdToGroupCompetitorId(
    groupCompetitors,
    match?.awayCompetitorId,
  ),
  [FIELD_NAMES.homeCompetitorId]: mapCompetitorIdToGroupCompetitorId(
    groupCompetitors,
    match?.homeCompetitorId,
  ),
  [FIELD_NAMES.number]: match?.number || 1,
  [FIELD_NAMES.groupOption]: match?.groupId || null,
  [FIELD_NAMES.matchDayNumber]: match?.matchDayNumber || 1,
  [FIELD_NAMES.date]: match?.date || dayjs().format(DATE_FORMAT),
  [FIELD_NAMES.time]: match?.time || null,
});
