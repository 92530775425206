import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: theme.spacing(5),
  },
}));

const RequiredFieldsMessage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <Typography variant="caption">{`* ${t('Indicates a required field')}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default RequiredFieldsMessage;
