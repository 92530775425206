import React from 'react';
import { Typography } from '@mui/material';
import type { NoticeProps } from 'react-select/dist/declarations/src/components/Menu';
import type { AutocompleteOption } from '@core/types';
import * as testIds from '../tests/test-ids';

type Props = NoticeProps<AutocompleteOption, boolean>;

const LoadingMessage = ({ children }: Props) => (
  <Typography align="center" data-qa={testIds.AUTOCOMPLETE_LOADING_MESSAGE}>
    {children}
  </Typography>
);

export default LoadingMessage;
