import { TFunction } from 'i18next';
import { ifElse, map, always } from 'ramda';

import { isValue } from '@core/helpers';

export function formatPersons(t: TFunction, persons: Array<any>, type: string) {
  return ifElse(
    isValue,
    map((value: any) => ({
      label: t(value[type]['id']),
      value: value[type].id,
      props: value,
    })),
    always([]),
  )(persons);
}
