import { Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from '@mui/material';
import { NavigationsTabsMap, NavigationTabProps } from '@core/types';
import { State } from '@core/store';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { StateProps } from '@core/store/modules/ui/tabs/reducers';
import {
  getTabsList,
  getActiveTabIndex,
} from '@core/store/modules/ui/tabs/selectors';
import { getTabData } from './helpers';
import { TabPanel } from './components/tab-panel';
import { TabProvider } from './navigation-tab-context';
import * as testIds from './tests/test-ids';

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

interface OwnProps {
  children?: any;
  tabMapping: NavigationsTabsMap;
}

type Props = DispatchProps & StateProps & OwnProps;

export const TabsPanel = ({
  allTabs,
  activeIndex,
  children,
  tabMapping,
}: Props) => {
  const renderTabsContent = () => {
    return allTabs.map((tab: NavigationTabProps, index: number) => {
      let contentToRender = null;

      if (index === 0) {
        contentToRender = children;
      } else {
        const tabData = getTabData(tab, tabMapping);
        if (tabData) {
          const TabContent: any = tabData.component;
          contentToRender = <TabContent tabId={tab.id} {...tab.props} />;
        }
      }

      return (
        <Suspense key={tab.id} fallback={<div />}>
          <TabProvider tab={tab}>
            <TabPanel value={activeIndex} index={index} key={tab.id}>
              {contentToRender}
            </TabPanel>
          </TabProvider>
        </Suspense>
      );
    });
  };

  return (
    <Grid data-qa={testIds.TABS_CONTENT_CONTAINER}>{renderTabsContent()}</Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  allTabs: getTabsList(state),
  activeIndex: getActiveTabIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsPanel);
