import { useTranslation } from 'react-i18next';
import { StyledEngineProvider } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';

import {
  FullScreenSpinner,
  LoginBar,
  SnackbarProviderWithStyle,
  Snackbars,
  SentryErrorBoundary,
  GlobalModal,
} from '@core';
import { ConfirmationDialogProvider } from '@core/hooks';
import modalsMapping from '@volleyball/constants/modals-map';
import theme from '@volleyball/themes/default';
import Routes from '@volleyball/routes';
import { history } from '@volleyball/store';
import SessionExpirationWarningModal from '@volleyball/business-components/session-expiration-warning-modal';
import { HistoryRouter } from 'redux-first-history/rr6';

const generateClassName = createGenerateClassName({
  disableGlobal: false,
});

const AppContent = () => {
  const { ready: translationsReady } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          {translationsReady ? (
            <ConfirmationDialogProvider>
              <HistoryRouter history={history}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <SnackbarProviderWithStyle>
                    <DndProvider backend={HTML5Backend}>
                      <CssBaseline />
                      <SentryErrorBoundary>
                        <Routes />
                      </SentryErrorBoundary>
                      <Snackbars />
                      <GlobalModal modalsMapping={modalsMapping} />
                      <SessionExpirationWarningModal />
                      <LoginBar />
                    </DndProvider>
                  </SnackbarProviderWithStyle>
                </LocalizationProvider>
              </HistoryRouter>
            </ConfirmationDialogProvider>
          ) : (
            <FullScreenSpinner absolute />
          )}
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppContent;
