import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { Competitor, TeamModel } from '@core/types';
import { NAMESPACE as competitorsNamespace } from '@core/store/modules/api/competitors';
import { State } from '@core/store';

import { DefaultState } from './reducer';
import { NAMESPACE } from '.';

const getNamespaceRoot = (state: State): DefaultState =>
  pathOr(null, ['tabs', NAMESPACE], state);
const getCompetitorNamespaceRoot = (state: State): any =>
  pathOr(null, ['api', competitorsNamespace], state);

export const getPlayerPositions = createSelector(
  getNamespaceRoot,
  (_: State, competitorId: number) => competitorId,
  (root: any, competitorId) =>
    pathOr<DefaultState[number]['playerPositions']>(
      null,
      [competitorId, 'playerPositions'],
      root,
    ),
);

export const getCompetitorPlayersList = createSelector(
  getNamespaceRoot,
  (_: State, competitorId: number) => competitorId,
  (root: any, competitorId) =>
    pathOr<DefaultState[number]['playerList']>(
      null,
      [competitorId, 'playerList'],
      root,
    ),
);

export const getCompetitorTeamOfficials = createSelector(
  getNamespaceRoot,
  (_: State, competitorId: number) => competitorId,
  // (root: any, competitorId) => pathOr<DefaultState[number]['teamOfficials']>(null, [competitorId, 'teamOfficials'], root),
  (root: any, competitorId) =>
    pathOr(null, [competitorId, 'teamOfficials'], root),
);

export const getCompetitorTeamOfficialsRoles = (competitorId: number) =>
  pathOr<DefaultState[number]['teamOfficialsRoles']>(null, [
    'tabs',
    NAMESPACE,
    competitorId,
    'teamOfficialsRoles',
  ]);

export const getCompetitorKitSet = (
  state: State,
  { competitorId }: { competitorId: number },
) =>
  pathOr<DefaultState[number]['kitSet']>(
    null,
    ['tabs', NAMESPACE, competitorId, 'kitSet'],
    state,
  );

export const getCompetitorByTeamId = createSelector(
  getNamespaceRoot,
  getCompetitorNamespaceRoot,
  (_: State, competitorId: number) => competitorId,
  (
    root,
    competitorRoot,
    competitorId,
  ): {
    team: TeamModel;
    competitor: Competitor;
  } => {
    const competitorAndTeam = {
      ...pathOr(null, [competitorId, 'competitorsByTeamId'], root),
    };
    const competitor = pathOr(null, [competitorId], competitorRoot);

    return { ...competitorAndTeam, competitor };
  },
);
