import type { TFunction } from 'i18next';
import { object, string } from 'yup';
import {
  MAX_FIELD_LENGTH,
  PHONE_MAX_LENGTH,
  PHONE_NUMBER_REGEX,
} from '@core/constants';

const getValidationSchema = (t: TFunction) =>
  object().shape({
    firstName: string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    familyName: string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    title: string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    email: string()
      .email('Enter a valid email address')
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      )
      .nullable(),
    phone: string()
      .max(
        PHONE_MAX_LENGTH,
        t('Maximum {{value}} characters allowed', { value: PHONE_MAX_LENGTH }),
      )
      .matches(PHONE_NUMBER_REGEX, t('Phone number is not valid'))
      .nullable(),
  });

export default getValidationSchema;
