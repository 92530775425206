import { ComponentType } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  body: ComponentType<any> | JSX.Element;
}

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const FullScreenLayout = ({ body }: Props) => {
  const classes = useStyles();

  // @ts-ignore
  return <Grid className={classes.root}>{body}</Grid>;
};
