import { TFunction } from 'i18next';

import {
  KNOCKOUT_ROUNDS,
  Group,
  DoubleEliminationType,
  EliminationType,
  BracketsType,
} from '@core/types';

export enum FIELD_NAMES {
  // do map one-to-one to Group
  title = 'title',
  internationalName = 'internationalName',
  description = 'description',
  startDate = 'startDate',
  endDate = 'endDate',
  childGroups = 'childGroups',
  stageType = 'stageType',
  numberOfTeams = 'numberOfTeams',
  bestOf = 'bestOf',
  numberOfPools = 'numberOfPools',
  numberOfRounds = 'numberOfRounds',
  eliminationType = 'eliminationType',
  extraRound = 'extraRound',
  knockoutRound = 'knockoutRound',
  // do not map one-to-one to Group
  roundRobinGroups = 'roundRobinGroups',
  knockOutElimination = 'knockOutElimination',
  knockoutDoubleElimination = 'knockOutDoubleElimination',
  knockOutDoubleEliminationCrossOverGroups = 'knockOutDoubleEliminationCrossOverFinalsGroups',
  knockOutDoubleEliminationLosersGroups = 'knockOutDoubleEliminationLosersGroups',
  knockOutDoubleEliminationWinnersGroups = 'knockOutDoubleEliminationWinnersGroups',
  knockOutExtraGroups = 'knockOutExtraGroups',
  knockoutSingleEliminationGroups = 'knockoutSingleEliminationGroups',
  thirdPlaceMatch = 'thirdPlaceMatch',
}

const POWERS_OF_TWO = [1, 2, 4, 8, 16, 32, 64].reverse();

type RoundGroupingKeys =
  | 'ROUND_OF_2'
  | 'ROUND_OF_4'
  | 'ROUND_OF_8'
  | 'ROUND_OF_16'
  | 'ROUND_OF_32'
  | 'ROUND_OF_64'
  | 'ROUND_OF_128';

export type Round = Partial<Omit<Group, 'id'>> & {
  id?: string;
  index?: number;
};

const knockOutSingleEliminationRoundTypes = (
  t: TFunction,
): Record<RoundGroupingKeys, Round> => ({
  ROUND_OF_2: {
    title: t('Final'),
    description: t('Top 2'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
    id: KNOCKOUT_ROUNDS.ROUND_OF_2,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_4: {
    title: t('Semi-finals'),
    description: t('Top 4'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_4,
    id: KNOCKOUT_ROUNDS.ROUND_OF_4,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_8: {
    title: t('Quarter-finals'),
    description: t('Top 8'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_8,
    id: KNOCKOUT_ROUNDS.ROUND_OF_8,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_16: {
    title: t('Round of 16'),
    description: t('Top 16'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_16,
    id: KNOCKOUT_ROUNDS.ROUND_OF_16,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_32: {
    title: t('Round of 32'),
    description: t('Top 32'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_32,
    id: KNOCKOUT_ROUNDS.ROUND_OF_32,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_64: {
    title: t('Round of 64'),
    description: t('Top 64'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_64,
    id: KNOCKOUT_ROUNDS.ROUND_OF_64,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
  ROUND_OF_128: {
    title: t('Round of 128'),
    description: t('Top 128'),
    knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_128,
    id: KNOCKOUT_ROUNDS.ROUND_OF_128,
    bestOf: 1,
    eliminationType: EliminationType.SINGLE,
  },
});

export const getKnockoutSingleEliminationGroups = (
  t: TFunction,
  numberOfTeams: number,
) => {
  const knockOutRounds = knockOutSingleEliminationRoundTypes(t);
  const groups: Array<Round> = [];

  for (const powerOfTwo of POWERS_OF_TWO) {
    if (numberOfTeams >= powerOfTwo + 1) {
      groups.push({
        ...{
          1: knockOutRounds.ROUND_OF_2,
          2: knockOutRounds.ROUND_OF_4,
          4: knockOutRounds.ROUND_OF_8,
          8: knockOutRounds.ROUND_OF_16,
          16: knockOutRounds.ROUND_OF_32,
          32: knockOutRounds.ROUND_OF_64,
          64: knockOutRounds.ROUND_OF_128,
        }[powerOfTwo],
        index: groups.length,
        orderNumber: groups.length + 1,
      });
    }
  }

  return groups;
};

export const knockOutDoubleEliminationLosersBracketRoundTypes = (
  t: TFunction,
): Record<RoundGroupingKeys, Array<Round>> => ({
  ROUND_OF_2: [],
  ROUND_OF_4: [
    {
      title: t('Major Finals'),
      description: t('Top 3'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_2}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Finals'),
      description: t('Top 4'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_2}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
  ROUND_OF_8: [
    {
      title: t('Major Semi-finals'),
      description: t('Top 6'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_4,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_4}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Semi-finals'),
      description: t('Top 8'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_4,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_4}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
  ROUND_OF_16: [
    {
      title: t('Major Round of 16'),
      description: t('Top 12'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_8,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_8}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Round of 16'),
      description: t('Top 16'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_8,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_8}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
  ROUND_OF_32: [
    {
      title: t('Major Round of 32'),
      description: t('Top 24'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_16,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_16}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Round of 32'),
      description: t('Top 32'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_16,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_16}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
  ROUND_OF_64: [
    {
      title: t('Major Round of 64'),
      description: t('Top 48'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_32,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_32}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Round of 64'),
      description: t('Top 64'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_32,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_32}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
  ROUND_OF_128: [
    {
      title: t('Major Round of 128'),
      description: t('Top 96'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_64,
      id: `major-${KNOCKOUT_ROUNDS.ROUND_OF_64}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
    {
      title: t('Minor Round of 128'),
      description: t('Top 128'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_64,
      id: `minor-${KNOCKOUT_ROUNDS.ROUND_OF_64}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.LOSERS,
    },
  ],
});

export const getKnockoutDoubleEliminationLoserGroups = (
  t: TFunction,
  numberOfTeams: number,
  doubleElimination: DoubleEliminationType,
) => {
  const knockOutRounds = knockOutDoubleEliminationLosersBracketRoundTypes(t);
  const groups: Array<Round> = [];

  for (const boundary of POWERS_OF_TWO) {
    if (numberOfTeams >= boundary + 1) {
      const isDoubleEliminationClassic =
        doubleElimination !== DoubleEliminationType.CROSSOVER;

      const losersMajorMinorRoundTuple = {
        1: isDoubleEliminationClassic ? knockOutRounds.ROUND_OF_2 : [],
        2: isDoubleEliminationClassic ? knockOutRounds.ROUND_OF_4 : [],
        4: knockOutRounds.ROUND_OF_8,
        8: knockOutRounds.ROUND_OF_16,
        16: knockOutRounds.ROUND_OF_32,
        32: knockOutRounds.ROUND_OF_64,
        64: knockOutRounds.ROUND_OF_128,
      }[boundary].reverse();

      for (const majorOrMinorRound of losersMajorMinorRoundTuple) {
        groups.push({
          ...majorOrMinorRound,
          index: groups.length,
          orderNumber: groups.length + 1,
        });
      }
    }
  }

  return groups;
};

const knockOutDoubleEliminationWinnersBracketRoundTypes = (
  t: TFunction,
): Record<RoundGroupingKeys, Array<Round>> => ({
  ROUND_OF_2: [],
  ROUND_OF_4: [
    {
      title: t('Grand Final (if game)'),
      description: t('Top 2'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
      id: `grand-final-if-game-${KNOCKOUT_ROUNDS.ROUND_OF_2}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
    {
      title: t('Grand Final'),
      description: t('Top 2'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
      id: `grand-final-${KNOCKOUT_ROUNDS.ROUND_OF_2}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
    {
      title: t('Main Final'),
      description: t('Top 3'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_2,
      id: `main-final-${KNOCKOUT_ROUNDS.ROUND_OF_2}`,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
    {
      title: t('Main Semi-finals'),
      description: t('Top 6'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_4,
      id: KNOCKOUT_ROUNDS.ROUND_OF_4,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
  ROUND_OF_8: [
    {
      title: t('Main Quarter-finals'),
      description: t('Top 12'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_8,
      id: KNOCKOUT_ROUNDS.ROUND_OF_8,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
  ROUND_OF_16: [
    {
      title: t('Main Round of 16'),
      description: t('Top 24'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_16,
      id: KNOCKOUT_ROUNDS.ROUND_OF_16,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
  ROUND_OF_32: [
    {
      title: t('Main Round of 32'),
      description: t('Top 48'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_32,
      id: KNOCKOUT_ROUNDS.ROUND_OF_32,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
  ROUND_OF_64: [
    {
      title: t('Main Round of 64'),
      description: t('Top 96'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_64,
      id: KNOCKOUT_ROUNDS.ROUND_OF_64,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
  ROUND_OF_128: [
    {
      title: t('Main Round of 128'),
      description: t('Top 128'),
      knockoutRound: KNOCKOUT_ROUNDS.ROUND_OF_128,
      id: KNOCKOUT_ROUNDS.ROUND_OF_128,
      bestOf: 1,
      eliminationType: EliminationType.DOUBLE,
      bracketsType: BracketsType.WINNERS,
    },
  ],
});

export const getKnockoutDoubleEliminationWinnerGroups = (
  t: TFunction,
  numberOfTeams: number,
  doubleElimination: DoubleEliminationType,
) => {
  const knockOutRounds = knockOutDoubleEliminationWinnersBracketRoundTypes(t);
  const groups: Array<Round> = [];

  let isDescriptionToBeChanged = true;

  const getWinnersBracketFirstRoundDescription = (
    knockoutRount: KNOCKOUT_ROUNDS,
  ) => {
    switch (knockoutRount) {
      case KNOCKOUT_ROUNDS.ROUND_OF_2:
        return t('Top 2');
      case KNOCKOUT_ROUNDS.ROUND_OF_4:
        return t('Top 4');
      case KNOCKOUT_ROUNDS.ROUND_OF_8:
        return t('Top 8');
      case KNOCKOUT_ROUNDS.ROUND_OF_16:
        return t('Top 16');
      case KNOCKOUT_ROUNDS.ROUND_OF_32:
        return t('Top 32');
      case KNOCKOUT_ROUNDS.ROUND_OF_64:
        return t('Top 64');
      case KNOCKOUT_ROUNDS.ROUND_OF_128:
        return t('Top 128');
    }
  };

  for (const boundary of POWERS_OF_TWO) {
    if (numberOfTeams >= boundary + 1) {
      const isDoubleEliminationClassic =
        doubleElimination !== DoubleEliminationType.CROSSOVER;

      const losersMajorMinorRoundTuple = {
        1: isDoubleEliminationClassic ? knockOutRounds.ROUND_OF_2 : [],
        2: isDoubleEliminationClassic
          ? knockOutRounds.ROUND_OF_4
          : knockOutRounds.ROUND_OF_4.slice(-1),
        4: knockOutRounds.ROUND_OF_8,
        8: knockOutRounds.ROUND_OF_16,
        16: knockOutRounds.ROUND_OF_32,
        32: knockOutRounds.ROUND_OF_64,
        64: knockOutRounds.ROUND_OF_128,
      }[boundary].reverse();

      if (isDescriptionToBeChanged && losersMajorMinorRoundTuple.length > 0) {
        isDescriptionToBeChanged = false;
        losersMajorMinorRoundTuple[0].description =
          getWinnersBracketFirstRoundDescription(
            losersMajorMinorRoundTuple[0].knockoutRound,
          );
      }

      for (const majorOrMinorRound of losersMajorMinorRoundTuple) {
        groups.push({
          ...majorOrMinorRound,
          index: groups.length,
          orderNumber: groups.length + 1,
        });
      }
    }
  }

  return groups;
};

export const getKnockoutDoubleEliminationCrossoverGroups = (t: TFunction) => {
  const knockOutRounds = knockOutSingleEliminationRoundTypes(t);
  const groups: Array<Round> = [];

  groups.push({
    ...knockOutRounds.ROUND_OF_4,
    eliminationType: EliminationType.DOUBLE,
    bracketsType: BracketsType.CROSSOVER,
    index: groups.length,
    orderNumber: groups.length + 1,
  });

  groups.push({
    ...knockOutRounds.ROUND_OF_2,
    eliminationType: EliminationType.DOUBLE,
    bracketsType: BracketsType.CROSSOVER,
    index: groups.length,
    orderNumber: groups.length + 1,
  });

  return groups;
};
