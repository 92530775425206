import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Button, IconButton } from '@mui/material';
import { AddCircleOutlineOutlined, DeleteTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  ContentBlock,
  ColumnWithSubValue,
  CustomTable,
} from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDate, formatTime, getVenue } from '@core/helpers';
import { Column } from '@core/components/material-table';
import { CustomerResponse, MatchModel } from '@core/types';
import { State } from '@volleyball/store';
import { ModalTypes, SanctionType } from '@volleyball/types';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';

interface StateProps {
  currentCustomer: CustomerResponse;
}

type OwnProps = {
  setMatch: (match: MatchModel) => void;
  match: MatchModel;
  competitionId: number;
  sanctionType: SanctionType;
};

type Props = StateProps & OwnProps;

const RelatedMatchBlock = (props: Props) => {
  const { setMatch, match, competitionId, sanctionType, currentCustomer } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleAddMatchClick() {
    dispatch({
      type: globalModalActions.openModal.toString(),
      payload: {
        type: ModalTypes.SELECT_MATCH_MODAL,
        params: { competitionId, setMatch, sanctionType },
      },
    });
  }

  function removeMatch() {
    return setMatch(null);
  }

  const columns: Array<Column<MatchModel>> = [
    {
      title: t('Date & Time'),
      render: (rowData) => {
        const dateTime = `${rowData.date} ${rowData.time}`;

        return rowData.date && rowData.time ? (
          <ColumnWithSubValue
            value={formatDate(currentCustomer?.dateFormat, dateTime)}
            subValue={formatTime(currentCustomer.timeFormat, dateTime)}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Home team'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={rowData.homeCompetitorTitle}
          subValue={rowData.homeCompetitorInternationalTitle}
        />
      ),
    },
    {
      title: t('Away team'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={rowData.awayCompetitorTitle}
          subValue={rowData.awayCompetitorInternationalTitle}
        />
      ),
    },
    {
      title: t('Venue'),
      render: (rowData) => (
        <ColumnWithSubValue
          value={getVenue(rowData.venue)?.title}
          subValue={getVenue(rowData.venue)?.internationalTitle}
        />
      ),
    },
    {
      cellStyle: {
        textAlign: 'right',
      },
      render: () => (
        <IconButton color="primary" onClick={removeMatch}>
          <DeleteTwoTone />
        </IconButton>
      ),
    },
  ];

  return (
    <ContentBlock spacing={3} title={t('Related match')}>
      <Grid item xs={12} md={8} />
      <Grid item md={2}>
        <Button
          variant="outlined"
          onClick={handleAddMatchClick}
          startIcon={<AddCircleOutlineOutlined />}
          fullWidth
          disabled={!!match}
        >
          {t('Add match')}
        </Button>
      </Grid>
      <Grid item md={10}>
        <CustomTable columns={columns} data={match ? [match] : []} />
      </Grid>
    </ContentBlock>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(RelatedMatchBlock);
