import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';
import { Tab, Tabs, Grid, Divider, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavigationsTabsMap, NavigationTabProps } from '@core/types';
import { State } from '@core/store';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { StateProps } from '@core/store/modules/ui/tabs/reducers';
import {
  getTabsList,
  getActiveTabIndex,
} from '@core/store/modules/ui/tabs/selectors';
import { getTabData } from './helpers';
import { TabLabel } from './components/tab-label';
import * as testIds from './tests/test-ids';

const useStyles = makeStyles((theme: Theme) => {
  const defaultSidePadding = 1;
  const mdSidePadding = 3;

  return {
    root: {
      padding: theme.spacing(0, defaultSidePadding),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, mdSidePadding),
      },
    },
    tabsBox: {
      width: `calc(100vw - ${theme.spacing(defaultSidePadding * 2)})`,
      [theme.breakpoints.up('md')]: {
        width: `calc(100vw - ${theme.spacing(mdSidePadding * 2)})`,
      },
      [theme.breakpoints.up('lg')]: {
        width: `calc(100vw - ${
          theme.mixins.sideMenu.minWidth
        }px - ${theme.spacing(mdSidePadding * 2)})`,
      },
    },
    container: {
      color: theme.palette.common.black,
    },
    paper: {
      position: 'relative',
      zIndex: theme.zIndex.mobileStepper,
    },
    tab: {
      padding: `0 ${theme.spacing(2)}`,
    },
  };
});

export interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

interface OwnProps {
  tabMapping: NavigationsTabsMap;
}

type Props = DispatchProps & StateProps & OwnProps;

export const TabsBar = ({
  allTabs,
  actions,
  activeIndex,
  tabMapping,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTabChange = (
    _event: React.ChangeEvent<Record<string, any>>,
    tabIndex: number,
  ) => {
    actions.tabs.updateActiveTabIndex(tabIndex);
  };

  const handleCloseClick = ({
    tabId,
    isTouched,
    onRemoveCallback,
  }: {
    index: number;
    tabId: string;
    isTouched: boolean;
    onRemoveCallback: any;
  }) => {
    const promptMessage = t(
      'You have unsaved information in the tab. Are you sure you want to close it?',
    );

    if (isTouched) {
      window.confirm(promptMessage) && actions.tabs.removeTab({ tabId });
    } else {
      actions.tabs.removeTab({ tabId });
      onRemoveCallback && onRemoveCallback();
    }
  };

  const renderTabs = () => {
    return allTabs.map((tab: NavigationTabProps, index: number) => {
      const tabData = getTabData(tab, tabMapping);
      const tabLabel = tabData ? tabData.title : 'tab';

      return (
        <Tab
          key={tab.id}
          component="div"
          className={classes.tab}
          label={
            <TabLabel
              isClosable={tab.isClosable}
              title={tabLabel}
              index={index}
              tabId={tab.id}
              onRemoveCallback={tab.props?.callbacks?.onRemoveCallback}
              onRemove={handleCloseClick}
              isSelected={index === activeIndex}
              isTouched={tab.isTouched}
            />
          }
        />
      );
    });
  };

  return (
    <Grid data-qa={testIds.TABS} className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item>
          <Grid className={classes.tabsBox}>
            <Tabs
              value={activeIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {renderTabs()}
            </Tabs>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  allTabs: getTabsList(state),
  activeIndex: getActiveTabIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsBar);
