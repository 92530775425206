import { createAction } from 'redux-actions';

import * as AVAILABILITY_ACTION_TYPES from './constants';

export const setAvailabilities = createAction(
  AVAILABILITY_ACTION_TYPES.SET_AVAILABILITIES,
);
export const setErrors = createAction(AVAILABILITY_ACTION_TYPES.SET_ERRORS);
export const clearErrors = createAction(AVAILABILITY_ACTION_TYPES.CLEAR_ERRORS);

export const getAvailabilities = createAction(
  AVAILABILITY_ACTION_TYPES.GET_AVAILABILITIES,
);
export const getAvailabilitiesRequest = createAction(
  AVAILABILITY_ACTION_TYPES.GET_AVAILABILITIES_REQUEST,
);
export const getAvailabilitiesSuccess = createAction(
  AVAILABILITY_ACTION_TYPES.GET_AVAILABILITIES_SUCCESS,
);
export const getAvailabilitiesFailure = createAction(
  AVAILABILITY_ACTION_TYPES.GET_AVAILABILITIES_FAILURE,
);

export const createAvailability = createAction(
  AVAILABILITY_ACTION_TYPES.CREATE_AVAILABILITY,
);
export const createAvailabilityRequest = createAction(
  AVAILABILITY_ACTION_TYPES.CREATE_AVAILABILITY_REQUEST,
);
export const createAvailabilitySuccess = createAction(
  AVAILABILITY_ACTION_TYPES.CREATE_AVAILABILITY_SUCCESS,
);
export const createAvailabilityFailure = createAction(
  AVAILABILITY_ACTION_TYPES.CREATE_AVAILABILITY_FAILURE,
);

export const deleteAvailability = createAction(
  AVAILABILITY_ACTION_TYPES.DELETE_AVAILABILITY,
);
export const deleteAvailabilityRequest = createAction(
  AVAILABILITY_ACTION_TYPES.DELETE_AVAILABILITY_REQUEST,
);
export const deleteAvailabilitySuccess = createAction(
  AVAILABILITY_ACTION_TYPES.DELETE_AVAILABILITY_SUCCESS,
);
export const deleteAvailabilityFailure = createAction(
  AVAILABILITY_ACTION_TYPES.DELETE_AVAILABILITY_FAILURE,
);
