import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Theme } from '@mui/material';

import { StatusColors } from '@core/types';

interface OwnProps {
  title: string;
  statusColor?: StatusColors;
}

type Props = OwnProps;

const getStatusColors = (statusColor: StatusColors, theme: Theme) => {
  switch (statusColor) {
    case StatusColors.LIGHT_GREY:
      return {
        background: theme.palette.grey[100],
        textColor: theme.palette.text.secondary,
      };
    case StatusColors.DARK_GREY:
      return {
        background: theme.palette.grey[300],
        textColor: theme.palette.text.secondary,
      };
    case StatusColors.BLUE:
      return {
        textColor: `rgb(13, 60, 97)`,
        background: `rgb(232, 244, 253)`,
      };
    case StatusColors.RED:
      return {
        textColor: `rgb(97,26,21)`,
        background: `rgb(253,236,234)`,
      };
    case StatusColors.YELLOW:
      return {
        textColor: `rgb(102,60,0)`,
        background: `rgb(255,244,229)`,
      };
    case StatusColors.GREEN:
      return {
        textColor: `rgb(30, 70, 32)`,
        background: `rgb(237, 247, 237)`,
      };

    default:
      return {
        background: theme.palette.grey[300],
        textColor: theme.palette.text.secondary,
      };
  }
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    backgroundColor: ({ statusColor }) =>
      getStatusColors(statusColor, theme).background,
    color: ({ statusColor }) => getStatusColors(statusColor, theme).textColor,
    padding: theme.spacing(0.25, 0.75, 0.25, 0.75),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 12,
    textTransform: 'uppercase',
  },
}));

const Status = (props: Props) => {
  const { title, statusColor } = props;
  const classes = useStyles({ statusColor, title });

  return (
    <Grid className={classes.root}>
      <Typography className={classes.title} variant="caption">
        {title}
      </Typography>
    </Grid>
  );
};

export default Status;
