import { PlayerListRequestStatus } from '@core/types';

export const getPlayerListRequestStatus = (
  t: (key: string) => string,
  roleStatus: PlayerListRequestStatus,
) => {
  switch (roleStatus) {
    case PlayerListRequestStatus.APPROVED:
      return t('Approved');
    case PlayerListRequestStatus.REJECTED:
      return t('Rejected');
    case PlayerListRequestStatus.PENDING:
      return t('Pending');
    default:
      return 'Draft';
  }
};
