import { forwardRef, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  getIn,
  setIn,
} from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { GridItems, Tooltip } from '@core/components';
import { Group, GroupSettings, MatchModel } from '@core/types';
import { MIN_NUMBER_FIELD_VALUE } from '@core/constants';
import { MatchSet, PostMatchSetPayload } from '@volleyball/types';
import { MatchDataFormFieldsMatchSets } from './match-data-form-fields-match-sets';
import {
  getMatchSetFormFieldNames as getFormFieldNames,
  getMinRequiredNumberOfRegularMatchSets,
  getIsRequiredRegularSet,
  getIsOptionalRegularSet,
} from './helpers';
import { CenteredContentBlock } from './shared/centered-content-block';
import type { TFunction } from 'i18next';

const useStyles = makeStyles<Theme>((theme) => ({
  accordionDetails: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

type FormShape = PostMatchSetPayload;

export type FormValues = Array<FormShape>;

export const getSingleMatchSetValidationSchema = (t: TFunction) =>
  yup
    .object({
      awayCompetitorScore: yup
        .number()
        .required(t('Required'))
        .min(
          MIN_NUMBER_FIELD_VALUE,
          t('Score should be equal to or greater than {{value}}', {
            value: MIN_NUMBER_FIELD_VALUE,
          }),
        )
        .max(999, t('Score should not exceed {{value}}', { value: 999 })),
      homeCompetitorScore: yup
        .number()
        .required(t('Required'))
        .min(
          MIN_NUMBER_FIELD_VALUE,
          t('Score should be equal to or greater than {{value}}', {
            value: MIN_NUMBER_FIELD_VALUE,
          }),
        )
        .max(999, t('Score should not exceed {{value}}', { value: 999 })),
      duration: yup
        .number()
        .min(
          1,
          t('Duration should be equal to or greater than {{value}}', {
            value: 1,
          }),
        )
        .max(
          999,
          t('Duration should not exceed {{value}} minutes', { value: 999 }),
        )
        .nullable(),
      number: yup.number().optional().nullable(),
      goldenSet: yup.mixed().oneOf([true, false]).optional().nullable(),
    })
    .defined();

// TODO: JB: validate match sets as a whole: required sets have to be filled,
// optional set have to be filled if next set if filled, set score validation.
const getMultipleMatchSetValidationSchema = (t: TFunction) =>
  yup.array().of(getSingleMatchSetValidationSchema(t));

function getPointsToWinInSet(
  values: PostMatchSetPayload,
  groupSettings: GroupSettings,
  match: MatchModel,
) {
  const {
    numberOfSets,
    pointsToWinInRegularSet,
    pointsToWinInTiebreakerSet,
    pointsToWinInGoldenSet,
    pointsToWinInGoldenMatchSet,
    goldenMatchRule,
  } = groupSettings;
  switch (true) {
    case goldenMatchRule && match.goldenMatch:
      return pointsToWinInGoldenMatchSet;
    case values.goldenSet:
      return pointsToWinInGoldenSet;
    case numberOfSets === 1:
      return pointsToWinInRegularSet;
    case values.number < numberOfSets:
      return pointsToWinInRegularSet;
    case values.number === numberOfSets:
      return pointsToWinInTiebreakerSet;
    default:
      return 0;
  }
}

function isSetScoreValid(
  {
    awayCompetitorScore,
    homeCompetitorScore,
  }: { awayCompetitorScore: number; homeCompetitorScore: number },
  pointsToWinInSet: number,
) {
  const pointsDifferenceToWinInAnySet = 2;
  const maxPoints = Math.max(awayCompetitorScore, homeCompetitorScore);
  const pointsDifference = Math.abs(awayCompetitorScore - homeCompetitorScore);

  return (
    (maxPoints === pointsToWinInSet &&
      pointsDifference >= pointsDifferenceToWinInAnySet) ||
    (maxPoints > pointsToWinInSet &&
      pointsDifference === pointsDifferenceToWinInAnySet)
  );
}

function validateSetScore(
  values: FormShape,
  t: TFunction,
  group: Group,
  match: MatchModel,
) {
  let errors: FormikErrors<FormShape>;
  const formFieldNames = getFormFieldNames();

  const durationRaw = getIn(values, formFieldNames.durationFieldName);
  const duration = Number(durationRaw);

  const homeCompetitorScore = Number(
    getIn(values, formFieldNames.homeScoreFieldName),
  );
  const awayCompetitorScore = Number(
    getIn(values, formFieldNames.awayScoreFieldName),
  );
  const isHomeScoreValid =
    Number.isInteger(homeCompetitorScore) &&
    homeCompetitorScore >= 0 &&
    homeCompetitorScore < 1000;
  const isAwayScoreValid =
    Number.isInteger(awayCompetitorScore) &&
    awayCompetitorScore >= 0 &&
    awayCompetitorScore < 1000;

  if (isHomeScoreValid && isAwayScoreValid) {
    const pointsToWinInSet = getPointsToWinInSet(values, group.settings, match);

    if (
      !isSetScoreValid(
        { awayCompetitorScore, homeCompetitorScore },
        pointsToWinInSet,
      )
    ) {
      errors = {};
      errors = setIn(
        errors,
        formFieldNames.awayScoreFieldName,
        t('Score is invalid'),
      );
      errors = setIn(
        errors,
        formFieldNames.homeScoreFieldName,
        t('Score is invalid'),
      );
    }
  }

  if (!isHomeScoreValid) {
    errors = errors ?? {};
    errors = setIn(
      errors,
      formFieldNames.homeScoreFieldName,
      t('Score is invalid'),
    );
  }

  if (!isAwayScoreValid) {
    errors = errors ?? {};
    errors = setIn(
      errors,
      formFieldNames.awayScoreFieldName,
      t('Score is invalid'),
    );
  }

  if (durationRaw && (!Number.isInteger(duration) || duration < 0)) {
    errors = errors ?? {};
    errors = setIn(
      errors,
      formFieldNames.durationFieldName,
      t('Invalid duration'),
    );
  }

  return errors;
}

export const getValidate =
  (t: TFunction, group: Group, match: MatchModel) =>
  (values: any): FormikErrors<any> => {
    let errors: FormikErrors<any>;

    if (Array.isArray(values)) {
      for (let index = 0; index < values.length; index++) {
        const error = validateSetScore(values[index], t, group, match);

        if (error) {
          errors = setIn(errors ?? [], index.toString(), error);
        }
      }
    } else {
      errors = validateSetScore(values, t, group, match);
    }

    return errors;
  };

interface Props<T extends FormValues = FormValues> {
  group: Group;
  initialErrors: FormikErrors<T>;
  initialTouched: FormikTouched<T>;
  initialValues: T;
  match: MatchModel;
  onSubmit: (values: T) => void;
}

const NUMBER_OF_GOLDEN_MATCH_SETS = 1;

export const MatchDataCreateMatchSets = forwardRef<
  FormikProps<FormValues>,
  Props
>((props, ref) => {
  const {
    group,
    initialErrors,
    initialTouched,
    initialValues,
    match,
    onSubmit,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const numberOfMatchSets = group.settings.numberOfSets;
  const goldenSetRuleEnabled = group.settings.goldenSetRule;

  const numberOfAccordions = useMemo(() => {
    if (match.goldenMatch) {
      return NUMBER_OF_GOLDEN_MATCH_SETS;
    }
    if (goldenSetRuleEnabled) {
      return numberOfMatchSets + 1;
    }
    return numberOfMatchSets;
  }, [goldenSetRuleEnabled, match.goldenMatch, numberOfMatchSets]);

  return (
    <Formik<FormValues>
      innerRef={ref}
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      onSubmit={onSubmit}
      validate={getValidate(t, group, match)}
      validationSchema={getMultipleMatchSetValidationSchema(t)}
    >
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <>
            <Grid container spacing={0} justifyContent="flex-end" mb={2}>
              <Grid item xs="auto">
                <Typography variant="caption" color="textSecondary">
                  {`* ${t('Indicates a required field')}`}
                </Typography>
              </Grid>
            </Grid>
            <CenteredContentBlock spacing={2}>
              <GridItems xs={12}>
                <Typography variant="caption" color="textSecondary">
                  {`* ${t('Played sets')} `}{' '}
                  <Tooltip title={t('Played sets')} />
                </Typography>
                {Array(numberOfAccordions)
                  .fill(null)
                  .map((_, index, array) => {
                    const isOptionalGoldenSet =
                      goldenSetRuleEnabled && index === array.length - 1;
                    const matchRegularSetNumber =
                      !isOptionalGoldenSet && index + 1;
                    const isRequiredRegularSet =
                      matchRegularSetNumber &&
                      getIsRequiredRegularSet(matchRegularSetNumber, group);
                    const isOptionalRegularSet =
                      matchRegularSetNumber &&
                      getIsOptionalRegularSet(matchRegularSetNumber, group);

                    const isAccordionExpanded =
                      isRequiredRegularSet ||
                      (isOptionalRegularSet
                        ? formikProps.values.some(
                            (value) => value.number === matchRegularSetNumber,
                          )
                        : formikProps.values.some(isGoldenSet));

                    const handleOptionalMatchSetToggle: (options: {
                      isOptionalGoldenSet?: boolean;
                      isOptionalRegularSet?: boolean;
                    }) => React.ChangeEventHandler<HTMLInputElement> =
                      (options) => (event) => {
                        const { value: toggledMatchSetNumber, checked } =
                          event.target;

                        const toggleOff = () =>
                          formikProps.values.filter(
                            options.isOptionalGoldenSet
                              ? removeSet('golden')
                              : removeSet('regular', +toggledMatchSetNumber),
                          );

                        const toggleOn = () => [
                          ...formikProps.values,
                          ...(options.isOptionalGoldenSet
                            ? [createEmptySet('golden')]
                            : createOptionalRegularMatchSets(
                                formikProps,
                                +toggledMatchSetNumber,
                              )),
                        ];

                        formikProps.setValues(
                          checked ? toggleOn() : toggleOff(),
                        );
                      };

                    const matchRegularSetNumberLabel = `${t(
                      'Set #',
                    )}${matchRegularSetNumber}`;

                    return (
                      <Accordion key={index} expanded={isAccordionExpanded}>
                        <AccordionSummary
                          className="without-cursor"
                          tabIndex={-1}
                        >
                          {isRequiredRegularSet && matchRegularSetNumberLabel}
                          {isOptionalRegularSet && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAccordionExpanded}
                                  color="primary"
                                  value={matchRegularSetNumber}
                                  onChange={(event) =>
                                    handleOptionalMatchSetToggle({
                                      isOptionalRegularSet,
                                    })(event)
                                  }
                                />
                              }
                              label={matchRegularSetNumberLabel}
                            />
                          )}
                          {isOptionalGoldenSet && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAccordionExpanded}
                                  color="primary"
                                  onChange={(event) =>
                                    handleOptionalMatchSetToggle({
                                      isOptionalGoldenSet,
                                    })(event)
                                  }
                                />
                              }
                              label={`${t('Golden set')}`}
                            />
                          )}
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{ root: classes.accordionDetails }}
                        >
                          {isAccordionExpanded && (
                            <MatchDataFormFieldsMatchSets
                              formFieldNames={getFormFieldNames(
                                isOptionalGoldenSet
                                  ? formikProps.values.findIndex(isGoldenSet)
                                  : index,
                              )}
                              match={match}
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </GridItems>
            </CenteredContentBlock>
          </>
        );
      }}
    </Formik>
  );
});

function isGoldenSet(
  value: PostMatchSetPayload,
  index: number,
  array: Array<PostMatchSetPayload>,
): boolean {
  return value.goldenSet === true;
}

function createEmptySet(type: 'golden'): FormShape;
function createEmptySet(type: 'regular', setNumber: number): FormShape;
function createEmptySet(
  type: 'regular' | 'golden',
  setNumber?: number,
): FormShape {
  return type === 'golden'
    ? {
        goldenSet: true,
        awayCompetitorScore: null,
        homeCompetitorScore: null,
        duration: null,
      }
    : {
        awayCompetitorScore: null,
        homeCompetitorScore: null,
        duration: null,
        number: setNumber ?? null,
      };
}

function createEmptyRequiredRegularMatchSets(
  minRequiredNumberOfRegularMatchSets: number,
  numberOfExistingRegularMatchSets: number,
): FormValues {
  return Array(
    minRequiredNumberOfRegularMatchSets - numberOfExistingRegularMatchSets,
  )
    .fill(null)
    .map((_, index) =>
      createEmptySet('regular', index + 1 + numberOfExistingRegularMatchSets),
    );
}

function createOptionalRegularMatchSets(
  formikProps: FormikProps<FormValues>,
  toggledMatchSetNumber: number,
): FormValues {
  const lastRegularOrOptionalMatchSetNumber = formikProps.values.reduce(
    (prev, curr) => Math.max(prev, curr.number ?? 0),
    1,
  );

  return Array(toggledMatchSetNumber - lastRegularOrOptionalMatchSetNumber)
    .fill(null)
    .map((__, offset) => {
      return createEmptySet(
        'regular',
        lastRegularOrOptionalMatchSetNumber + offset + 1,
      );
    });
}

function removeSet(
  type: 'golden',
): (value: FormShape, index: number, array: FormValues) => boolean;
function removeSet(
  type: 'regular',
  setNumber: number,
): (value: FormShape, index: number, array: FormValues) => boolean;
function removeSet(
  type: 'regular' | 'golden',
  setNumber?: number,
): (value: FormShape, index: number, array: FormValues) => boolean {
  return (value: FormShape, index: number, array: FormValues) => {
    if (type === 'golden') {
      return !isGoldenSet(value, index, array);
    }
    if (type === 'regular') {
      return isGoldenSet(value, index, array) || value.number < setNumber;
    }

    return true;
  };
}

export function initializeMatchSetsFieldArray(
  matchSets: Array<MatchSet>,
  group: Group,
  match: MatchModel,
): Array<FormShape> {
  const minRequiredNumberOfRegularMatchSets = match.goldenMatch
    ? NUMBER_OF_GOLDEN_MATCH_SETS
    : getMinRequiredNumberOfRegularMatchSets(group);
  const numberOfExistingRegularMatchSets = matchSets.filter(
    ({ goldenSet }) => !goldenSet,
  ).length;
  const emptyRequiredRegularMatchSets =
    minRequiredNumberOfRegularMatchSets > numberOfExistingRegularMatchSets
      ? createEmptyRequiredRegularMatchSets(
          minRequiredNumberOfRegularMatchSets,
          numberOfExistingRegularMatchSets,
        )
      : [];

  return [
    ...matchSets.filter(({ goldenSet }) => !goldenSet),
    ...emptyRequiredRegularMatchSets,
    ...matchSets.filter(({ goldenSet }) => goldenSet),
  ].map(
    ({
      awayCompetitorScore,
      duration,
      goldenSet,
      homeCompetitorScore,
      number,
    }) => {
      return goldenSet
        ? {
            goldenSet,
            awayCompetitorScore,
            homeCompetitorScore,
            duration,
          }
        : {
            awayCompetitorScore,
            homeCompetitorScore,
            duration,
            number,
          };
    },
  );
}
