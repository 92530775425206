import { createActions } from 'redux-actions';

export const NAMESPACE = 'userRole';

export const actions = createActions(
  'GET_USER_ROLES_REQUEST',
  'GET_USER_ROLES_SUCCESS',
  'GET_USER_ROLES_FAILURE',
  'GET_USER_ROLES',
  'SET_USER_ROLES',
  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getUserRolesRequest: RequestLifecycleAction;
  getUserRolesSuccess: RequestLifecycleAction;
  getUserRolesFailure: RequestLifecycleAction;
  getUserRoles: UnknownAction;
  setUserRoles: UnknownAction;
};
