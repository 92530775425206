import { createSelector } from '@reduxjs/toolkit';
import { Action } from 'redux-actions';
import deepEqual from 'fast-deep-equal';

import { State } from '@core/store';
import { DefaultState } from './reducer';

/* prefer stringable action creator without arguments for actions without payload or action for actions with payload argument types */
/** @deprecated */
type ActionType = string;

type StringableActionCreator0 = {
  (): Action<never>;
  toString(): string;
};

type StringableActionCreator1<T = any> = {
  (arg: any): Action<T>;
  toString(): string;
};

function isStringableActionCreator(
  pattern: any,
): pattern is StringableActionCreator0 | StringableActionCreator1 {
  return (
    typeof pattern === 'function' &&
    Object.prototype.hasOwnProperty.call(pattern, 'toString')
  );
}

function isReduxAction(pattern: any): pattern is Action<any> {
  return typeof pattern === 'object' && typeof pattern.type === 'string';
}

/* prefer stringable action creator without arguments for actions without payload or action for actions with payload argument types */
/** @deprecated */
type ActionObject = {
  /** @deprecated */
  id: number | string;
  /** @deprecated */
  actionName: ActionType | StringableActionCreator1;
};

type Pattern =
  | ActionType
  | StringableActionCreator0
  | ActionObject
  | Action<any>;

type CreateLoadingSelectorType = (
  patterns: Array<Pattern>,
) => (state: State) => boolean;

const getLoadingStates = (state: any): DefaultState => {
  return state.loading;
};

function parsePattern(pattern: Pattern): {
  actionType: string;
  payloadOrValue:
    | true
    | { id: string | number }
    | Record<'id', string | number>;
} {
  if (typeof pattern === 'string' || isStringableActionCreator(pattern)) {
    return {
      actionType: pattern.toString(),
      payloadOrValue: true,
    };
  }

  if (isReduxAction(pattern)) {
    const { type, payload } = pattern;

    return { actionType: type.toString(), payloadOrValue: payload };
  }

  return {
    actionType: pattern.actionName.toString(),
    payloadOrValue: { id: pattern.id },
  };
}

export const createLoadingSelector: CreateLoadingSelectorType = (patterns) =>
  createSelector(getLoadingStates, (loadingStates): boolean => {
    const filters = patterns.map((pattern) => {
      const { actionType, payloadOrValue } = parsePattern(pattern);

      const matches = /(.*)_(REQUEST)/.exec(actionType);
      if (!matches) {
        throw new Error(
          'Invalid action type. Pass action types with following signature: *_REQUEST',
        );
      }
      const requestLifecycleActionRoot = matches[1];

      return { requestLifecycleActionRoot, payloadOrValue };
    });

    return filters.some((filter): boolean => {
      // handle case of uninitialized loading redux store state when loading reducer is lazily injected
      if (!loadingStates) {
        return true;
      }

      return loadingStates[filter.requestLifecycleActionRoot]?.some(
        (value: any) => deepEqual(filter.payloadOrValue, value),
      );
    });
  });
