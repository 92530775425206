import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterCheckboxList } from '@core/components';
import { getClubType } from '@core/helpers';
import { ClubTypes, type FilterTabProps } from '@core/types';
import {
  COMPETITION_TYPE_FILTER,
  COMPETITION_TYPE_FILTER_ID,
} from '../constants';

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

const competitionClubTypes = [ClubTypes.CLUB, ClubTypes.NATIONAL_CLUB];

const CompetitionTypeFilter = ({ filters, ...rest }: FilterTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const filterValues = filters[COMPETITION_TYPE_FILTER_ID]?.filterValues || [];

  const formatFilterLabel = (type: string) =>
    getClubType(t, type as keyof typeof ClubTypes);

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Type')}
      </Typography>
      <FilterCheckboxList
        shifted
        list={competitionClubTypes}
        filterProps={COMPETITION_TYPE_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default CompetitionTypeFilter;
