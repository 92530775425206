import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCoreDispatch } from '@core/hooks';
import { setTouched } from '@core/store/modules/ui/tabs/actions';
import { getActiveTabIndex } from '@core/store/modules/ui/tabs/selectors';

const useTabStateSetter = (isDirty: boolean) => {
  const dispatch = useCoreDispatch();
  const activeIndex = useSelector(getActiveTabIndex);

  useEffect(() => {
    if (isDirty) {
      dispatch(setTouched(isDirty, activeIndex));
    }
  }, [isDirty, activeIndex, dispatch]);
};

export default useTabStateSetter;
