import { combineReducers } from 'redux';

import { sortByKey } from '@core/helpers';
import { NAMESPACE as competitionCreation } from '@core/store/modules/shared/competition-creation';
import competitionCreationReducer from '@core/store/modules/shared/competition-creation/reducer';

export default combineReducers(
  sortByKey({
    [competitionCreation]: competitionCreationReducer,
  }),
);
