import restService from '@core/api/rest-service';
import {
  KitSetCreateData,
  KitSetListResponse,
  KitSet,
  KitSetPatchData,
} from '@core/types';

export const createKitSet = (clubId: number, data: KitSetCreateData) =>
  restService.post<KitSet>(`/v1/clubs/${clubId}/teamColorSets`, data);

export const getKitSetList = (clubId: number) =>
  restService.get<KitSetListResponse>(`/v1/clubs/${clubId}/teamColorSets`);

export const getKitSet = ({ kitSetId }: { kitSetId: number }) =>
  restService.get<KitSet>(`/v1/teamColorSets/${kitSetId}`);

export const updateKitSet = ({
  kitSetId,
  data,
}: {
  kitSetId: number;
  data: KitSetPatchData;
}) => restService.patch<void>(`/v1/teamColorSets/${kitSetId}`, data);

export const deleteKitSet = ({ kitSetId }: { kitSetId: number }) =>
  restService.delete<void>(`/v1/teamColorSets/${kitSetId}`);
