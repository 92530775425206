import { createFilterId } from '@core/helpers';
import { FilteringOperations, type FilterModel } from '@core/types';

export const COMPETITION_SEASON_FILTER: Partial<FilterModel> = {
  fieldName: 'competitionSeason',
  filteringOperation: FilteringOperations.equal,
};

export const COMPETITION_SEASON_FILTER_ID = createFilterId(
  COMPETITION_SEASON_FILTER,
);
