import { all, put, take } from 'redux-saga/effects';

import { actions } from '.';

export function* openModalFlow() {
  while (true) {
    const {
      payload: { type, params },
    } = (yield take(actions.openModal)) as InferAction<
      typeof actions.openModal
    >;

    yield put(actions.setModalState({ isOpen: true, type, params }));
  }
}

export default function* saga() {
  yield all([openModalFlow()]);
}
