import React from 'react';
import { Field, getIn, FieldProps } from 'formik';
import { MTableEditField } from '@core/components/material-table';

export const FormikMTInput = (props: any) => {
  return (
    <Field name={props.columnDef.field}>
      {(fieldProps: FieldProps) => {
        const { field, form } = fieldProps;
        const { name } = field;
        const { errors, setFieldValue } = form;
        const showError = !!getIn(errors, name);
        const dataQa = props.columnDef['data-qa'];
        const handleOnChange = (newValue: any) => {
          setFieldValue(name, newValue, true);
        };

        return (
          <MTableEditField
            {...props}
            {...field}
            data-qa={dataQa || props.columnDef.field}
            error={showError}
            helperText={showError && errors[name]}
            onChange={handleOnChange}
          />
        );
      }}
    </Field>
  );
};
