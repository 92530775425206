import { call, Effect, put } from 'redux-saga/effects';

import * as matchPlayersAPI from '@core/api/match-player';
import { ChangeListRequestData } from '@core/types';

import { actions } from '.';

export function* getMatchPlayers(
  matchId: number,
  competitorId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPlayersRequest());

  const response = yield call(
    matchPlayersAPI.getMatchPlayers,
    matchId,
    competitorId,
  );

  if (!response.error) {
    yield put(actions.getPlayersSuccess());
  } else {
    yield put(actions.getPlayersFailure());
  }

  return response;
}

export function* changeMatchPlayersList(
  matchId: number,
  competitorId: number,
  data: ChangeListRequestData,
): Generator<Effect, any, any> {
  yield put(actions.changePlayerListRequest());
  const response = yield call(
    matchPlayersAPI.changeMatchPlayersList,
    matchId,
    competitorId,
    data,
  );

  if (!response.error) {
    yield put(actions.changePlayerListSuccess());
  } else {
    yield put(actions.changePlayerListFailure());
  }

  return response;
}

export function* getPreviousMatchPlayers(
  matchId: number,
  competitorId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPreviousPlayersRequest());

  const response = yield call(
    matchPlayersAPI.getPreviousMatchPlayers,
    matchId,
    competitorId,
  );

  if (!response.error) {
    yield put(actions.getPreviousPlayersSuccess());
  } else {
    yield put(actions.getPreviousPlayersFailure());
  }

  return response;
}
