import { createAction } from 'redux-actions';

import * as AGE_GROUPS_ACTION_TYPES from './constants';

export const setAgeGroups = createAction(
  AGE_GROUPS_ACTION_TYPES.SET_AGE_GROUPS,
);
export const setErrors = createAction(AGE_GROUPS_ACTION_TYPES.SET_ERRORS);
export const setGroupBeingModified = createAction(
  AGE_GROUPS_ACTION_TYPES.SET_GROUP_BEING_MODIFIED,
);
export const setNewGroupBeingCreated = createAction(
  AGE_GROUPS_ACTION_TYPES.SET_NEW_GROUP_BEING_CREATED,
);

export const getAgeGroups = createAction(
  AGE_GROUPS_ACTION_TYPES.GET_AGE_GROUPS,
);
export const getAgeGroupsRequest = createAction(
  AGE_GROUPS_ACTION_TYPES.GET_AGE_GROUPS_REQUEST,
);
export const getAgeGroupsSuccess = createAction(
  AGE_GROUPS_ACTION_TYPES.GET_AGE_GROUPS_SUCCESS,
);
export const getAgeGroupsFailure = createAction(
  AGE_GROUPS_ACTION_TYPES.GET_AGE_GROUPS_FAILURE,
);

export const createAgeGroup = createAction(
  AGE_GROUPS_ACTION_TYPES.CREATE_AGE_GROUP,
);
export const createAgeGroupRequest = createAction(
  AGE_GROUPS_ACTION_TYPES.CREATE_AGE_GROUP_REQUEST,
);
export const createAgeGroupSuccess = createAction(
  AGE_GROUPS_ACTION_TYPES.CREATE_AGE_GROUP_SUCCESS,
);
export const createAgeGroupFailure = createAction(
  AGE_GROUPS_ACTION_TYPES.CREATE_AGE_GROUP_FAILURE,
);

export const updateAgeGroup = createAction(
  AGE_GROUPS_ACTION_TYPES.UPDATE_AGE_GROUP,
);
export const updateAgeGroupRequest = createAction(
  AGE_GROUPS_ACTION_TYPES.UPDATE_AGE_GROUP_REQUEST,
);
export const updateAgeGroupSuccess = createAction(
  AGE_GROUPS_ACTION_TYPES.UPDATE_AGE_GROUP_SUCCESS,
);
export const updateAgeGroupFailure = createAction(
  AGE_GROUPS_ACTION_TYPES.UPDATE_AGE_GROUP_FAILURE,
);

export const deleteAgeGroup = createAction(
  AGE_GROUPS_ACTION_TYPES.DELETE_AGE_GROUP,
);
export const deleteAgeGroupRequest = createAction(
  AGE_GROUPS_ACTION_TYPES.DELETE_AGE_GROUP_REQUEST,
);
export const deleteAgeGroupSuccess = createAction(
  AGE_GROUPS_ACTION_TYPES.DELETE_AGE_GROUP_SUCCESS,
);
export const deleteAgeGroupFailure = createAction(
  AGE_GROUPS_ACTION_TYPES.DELETE_AGE_GROUP_FAILURE,
);
