export const NAMESPACE = 'groupStandings';

export const INITIALIZE_GROUP_STANDINGS_STORE = `${NAMESPACE}/INITIALIZE_GROUP_STANDINGS_STORE`;
export const SET_GROUP_STANDINGS = `${NAMESPACE}/SET_GROUP_STANDINGS`;
export const SET_GROUP_STANDINGS_HEADER = `${NAMESPACE}/SET_GROUP_STANDINGS_HEADER`;

export const UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT = `${NAMESPACE}/UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT`;
export const UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_REQUEST = `${NAMESPACE}/UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_REQUEST`;
export const UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_SUCCESS = `${NAMESPACE}/UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_SUCCESS`;
export const UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_FAILURE = `${NAMESPACE}/UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_FAILURE`;

export const GET_GROUP_STANDINGS = `${NAMESPACE}/GET_GROUP_STANDINGS`;
export const GET_GROUP_STANDINGS_REQUEST = `${NAMESPACE}/GET_GROUP_STANDINGS_REQUEST`;
export const GET_GROUP_STANDINGS_SUCCESS = `${NAMESPACE}/GET_GROUP_STANDINGS_SUCCESS`;
export const GET_GROUP_STANDINGS_FAILURE = `${NAMESPACE}/GET_GROUP_STANDINGS_FAILURE`;
