import { createAction } from 'redux-actions';

import { VenueModel, Paginated } from '@core/types';

import { NAMESPACE } from './constants';

type QueryParams = {
  page?: number;
  query?: string;
};

const setVenues = createAction<Paginated<VenueModel>>(`${NAMESPACE}/SET_VENUE`);
const getVenues = createAction<QueryParams | void>(`${NAMESPACE}/GET_VENUE`);
const getVenuesRequest = createAction<void>(`${NAMESPACE}/GET_VENUE_REQUEST`);
const getVenuesSuccess = createAction<void>(`${NAMESPACE}/GET_VENUE_SUCCESS`);
const getVenuesFailure = createAction<void>(`${NAMESPACE}/GET_VENUE_FAILURE`);
const searchVenuesByQuery = createAction<QueryParams>(
  `${NAMESPACE}/SEARCH_VENUES_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setVenues,
  getVenuesRequest,
  getVenuesSuccess,
  getVenuesFailure,
  getVenues,
  resetState,
  searchVenuesByQuery,
};
