import { createActions } from 'redux-actions';

import {
  MatchModel,
  MatchOfficialsList,
  MatchOfficialsResponse,
} from '@core/types';

export const NAMESPACE = 'matchOfficials';

export const actions = createActions(
  'SET_MATCH_OFFICIAL_GROUPS',
  'GET_MATCH_OFFICIAL_GROUPS',
  'GET_MATCH_OFFICIAL_GROUPS_REQUEST',
  'GET_MATCH_OFFICIAL_GROUPS_SUCCESS',
  'GET_MATCH_OFFICIAL_GROUPS_FAILURE',

  'GET_MATCH_OFFICIALS',
  'GET_MATCH_OFFICIALS_REQUEST',
  'GET_MATCH_OFFICIALS_SUCCESS',
  'GET_MATCH_OFFICIALS_FAILURE',
  'SET_MATCH_OFFICIALS',
  'SET_MATCH_OFFICIALS_META',
  'SET_MATCH_OFFICIALS_HEADER',

  'VALIDATE_MATCH_OFFICIAL',
  'VALIDATE_MATCH_OFFICIAL_REQUEST',
  'VALIDATE_MATCH_OFFICIAL_SUCCESS',
  'VALIDATE_MATCH_OFFICIAL_FAILURE',
  'SET_MATCH_OFFICIAL_CONFLICTS',
  'RESET_MATCH_OFFICIAL_CONFLICTS',

  'GET_CALENDAR_MATCH_OFFICIALS',
  'GET_CALENDAR_MATCH_OFFICIALS_REQUEST',
  'GET_CALENDAR_MATCH_OFFICIALS_SUCCESS',
  'GET_CALENDAR_MATCH_OFFICIALS_FAILURE',
  'SET_CALENDAR_MATCH_OFFICIALS',
  'SET_CALENDAR_MATCH_OFFICIALS_PAGINATION',
  'SET_FILTER_PARAMS',
  'RESET_MATCH_OFFICIALS',
  { prefix: NAMESPACE },
) as unknown as {
  setMatchOfficialGroups: UnknownAction;
  getMatchOfficialGroups: UnknownAction;
  getMatchOfficialGroupsRequest: RequestLifecycleAction;
  getMatchOfficialGroupsSuccess: RequestLifecycleAction;
  getMatchOfficialGroupsFailure: RequestLifecycleAction;

  getMatchOfficials: RequestLifecycleAction<{ queryParams: URLSearchParams }>;
  getMatchOfficialsRequest: RequestLifecycleAction;
  getMatchOfficialsSuccess: RequestLifecycleAction;
  getMatchOfficialsFailure: RequestLifecycleAction;
  setMatchOfficials: IdentityActionFunction<MatchOfficialsList>;
  setMatchOfficialsMeta: IdentityActionFunction<
    Omit<MatchOfficialsResponse, '_embedded' | '_links'>
  >;
  setMatchOfficialsHeader: never;

  validateMatchOfficial: IdentityActionFunction<{
    id: number;
    dateFilter: URLSearchParams;
  }>;
  validateMatchOfficialRequest: RequestLifecycleAction;
  validateMatchOfficialSuccess: RequestLifecycleAction;
  validateMatchOfficialFailure: RequestLifecycleAction;
  setMatchOfficialConflicts: IdentityActionFunction<Array<MatchModel>>;
  resetMatchOfficialConflicts: NoopAction;

  getCalendarMatchOfficials: UnknownAction;
  getCalendarMatchOfficialsRequest: RequestLifecycleAction;
  getCalendarMatchOfficialsSuccess: RequestLifecycleAction;
  getCalendarMatchOfficialsFailure: RequestLifecycleAction;
  setCalendarMatchOfficials: UnknownAction;
  setCalendarMatchOfficialsPagination: UnknownAction;
  setFilterParams: UnknownAction;
  resetMatchOfficials: UnknownAction;
};
