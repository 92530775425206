import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';
import {
  CompetitorPlayerList,
  CompetitorTeamOfficialList,
  KitSet,
  PlayerPositions,
  TeamOfficialRoles,
} from '@core/types';

export const NAMESPACE = 'competitorProfileTab';

type CompetitionCompetitorCompositeId = {
  competitionId: number;
  competitorId: number;
  teamId: number;
};

export const actions = createActions(
  {
    SET_PLAYER_POSITIONS: [prop('payload'), pick(['competitorId'])],
    SET_TEAM_OFFICIALS: [prop('payload'), pick(['competitorId'])],
    SET_TEAM_OFFICIALS_ROLES: [prop('payload'), pick(['competitorId'])],
    SET_COMPETITOR_PLAYER_LIST: [prop('payload'), pick(['competitorId'])],
    SET_KIT_SET: [prop('payload'), pick(['competitorId'])],
    SET_COMPETITOR_BY_TEAM: [prop('payload'), pick(['competitorId'])],
    SET_COMPETITOR_BY_TEAM_DEFAULT_VENUE: [
      prop('payload'),
      pick(['competitorId']),
    ],
    SET_COMPETITOR_BY_TEAM_ALTERNATE_VENUE: [
      prop('payload'),
      pick(['competitorId']),
    ],
  },
  'GET_PLAYER_POSITIONS',
  'GET_PLAYER_POSITIONS_REQUEST',
  'GET_PLAYER_POSITIONS_SUCCESS',
  'GET_PLAYER_POSITIONS_FAILURE',

  'UPDATE_COMPETITOR_PLAYER',
  'UPDATE_COMPETITOR_PLAYER_REQUEST',
  'UPDATE_COMPETITOR_PLAYER_SUCCESS',
  'UPDATE_COMPETITOR_PLAYER_FAILURE',

  'RESET_COMPETITOR_PROFILE',

  'GET_TEAM_OFFICIALS',
  'GET_TEAM_OFFICIALS_REQUEST',
  'GET_TEAM_OFFICIALS_SUCCESS',
  'GET_TEAM_OFFICIALS_FAILURE',

  'GET_TEAM_OFFICIALS_ROLES',
  'GET_TEAM_OFFICIALS_ROLES_REQUEST',
  'GET_TEAM_OFFICIALS_ROLES_SUCCESS',
  'GET_TEAM_OFFICIALS_ROLES_FAILURE',

  'GET_COMPETITOR_PLAYER_LIST',
  'GET_COMPETITOR_PLAYER_LIST_REQUEST',
  'GET_COMPETITOR_PLAYER_LIST_SUCCESS',
  'GET_COMPETITOR_PLAYER_LIST_FAILURE',

  'UPDATE_TEAM_OFFICIAL',
  'UPDATE_TEAM_OFFICIAL_REQUEST',
  'UPDATE_TEAM_OFFICIAL_SUCCESS',
  'UPDATE_TEAM_OFFICIAL_FAILURE',

  'GET_COMPETITOR_KIT_SET',
  'GET_COMPETITOR_KIT_SET_REQUEST',
  'GET_COMPETITOR_KIT_SET_SUCCESS',
  'GET_COMPETITOR_KIT_SET_FAILURE',

  'GET_COMPETITOR_BY_TEAM',
  'GET_COMPETITOR_BY_TEAM_REQUEST',
  'GET_COMPETITOR_BY_TEAM_SUCCESS',
  'GET_COMPETITOR_BY_TEAM_FAILURE',

  'GET_COMPETITOR_PLAYER_REPORT',
  'GET_COMPETITOR_PLAYER_REPORT_REQUEST',
  'GET_COMPETITOR_PLAYER_REPORT_SUCCESS',
  'GET_COMPETITOR_PLAYER_REPORT_FAILURE',

  { prefix: NAMESPACE },
) as unknown as {
  setCompetitorByTeamDefaultVenue: UnknownAction;
  setCompetitorByTeamAlternateVenue: UnknownAction;

  getPlayerPositions: IdentityActionFunction<{
    competitorId: number;
    sportId: number;
  }>;
  getPlayerPositionsRequest: RequestLifecycleAction<{
    competitorId: number;
    sportId: number;
  }>;
  getPlayerPositionsSuccess: RequestLifecycleAction<{
    competitorId: number;
    sportId: number;
  }>;
  getPlayerPositionsFailure: RequestLifecycleAction<{
    competitorId: number;
    sportId: number;
  }>;
  setPlayerPositions: IdentityActionMetaFunction<
    { payload: PlayerPositions; competitorId: number },
    { payload: PlayerPositions },
    { competitorId: number }
  >;

  updateCompetitorPlayer: UnknownAction;
  updateCompetitorPlayerRequest: RequestLifecycleAction;
  updateCompetitorPlayerSuccess: RequestLifecycleAction;
  updateCompetitorPlayerFailure: RequestLifecycleAction;

  resetCompetitorProfile: RequestLifecycleAction<number>;

  getTeamOfficials: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getTeamOfficialsRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getTeamOfficialsSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getTeamOfficialsFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setTeamOfficials: IdentityActionMetaFunction<
    { payload: CompetitorTeamOfficialList; competitorId: number },
    { payload: CompetitorTeamOfficialList },
    { competitorId: number }
  >;

  getTeamOfficialsRoles: IdentityActionFunction<{ competitorId: number }>;
  getTeamOfficialsRolesRequest: RequestLifecycleAction<{
    competitorId: number;
  }>;
  getTeamOfficialsRolesSuccess: RequestLifecycleAction<{
    competitorId: number;
  }>;
  getTeamOfficialsRolesFailure: RequestLifecycleAction<{
    competitorId: number;
  }>;
  setTeamOfficialsRoles: IdentityActionMetaFunction<
    { payload: TeamOfficialRoles; competitorId: number },
    { payload: TeamOfficialRoles },
    { competitorId: number }
  >;

  getCompetitorPlayerList: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayerListRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayerListSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorPlayerListFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setCompetitorPlayerList: IdentityActionMetaFunction<
    { payload: CompetitorPlayerList; competitorId: number },
    { payload: CompetitorPlayerList },
    { competitorId: number }
  >;

  updateTeamOfficial: UnknownAction;
  updateTeamOfficialRequest: RequestLifecycleAction;
  updateTeamOfficialSuccess: RequestLifecycleAction;
  updateTeamOfficialFailure: RequestLifecycleAction;

  getCompetitorKitSet: IdentityActionFunction<{
    kitSetId: number;
    competitorId: number;
  }>;
  getCompetitorKitSetRequest: RequestLifecycleAction<{
    kitSetId: number;
    competitorId: number;
  }>;
  getCompetitorKitSetSuccess: RequestLifecycleAction<{
    kitSetId: number;
    competitorId: number;
  }>;
  getCompetitorKitSetFailure: RequestLifecycleAction<{
    kitSetId: number;
    competitorId: number;
  }>;
  setKitSet: IdentityActionMetaFunction<
    { payload: KitSet; competitorId: number },
    { payload: KitSet },
    { competitorId: number }
  >;

  getCompetitorByTeam: IdentityActionFunction<CompetitionCompetitorCompositeId>;
  getCompetitorByTeamRequest: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorByTeamSuccess: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  getCompetitorByTeamFailure: RequestLifecycleAction<CompetitionCompetitorCompositeId>;
  setCompetitorByTeam: IdentityActionMetaFunction<
    { payload: { competitor: number; team: unknown }; competitorId: number },
    { payload: { competitor: number; team: unknown } },
    { competitorId: number }
  >;

  getCompetitorPlayerReport: UnknownAction;
  getCompetitorPlayerReportRequest: RequestLifecycleAction;
  getCompetitorPlayerReportSuccess: RequestLifecycleAction;
  getCompetitorPlayerReportFailure: RequestLifecycleAction;
};
