import { forwardRef } from 'react';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { MIN_NUMBER_FIELD_VALUE } from '@core/constants';
import { MatchDataFormFieldsMatchTime } from './match-data-form-fields-match-time';
import { MatchDataFormFieldsMatchAttendance } from './match-data-form-fields-match-attendance';
import { CenteredContentBlock } from './shared/centered-content-block';
import type { FormValues } from './shared/types';

export const getValidationSchema: (
  t: TFunction,
) => yup.ObjectSchema<FormValues> = (t) =>
  yup
    .object({
      actualStartTime: yup.string().required(t('Required')),
      actualEndTime: yup.string().required(t('Required')),
      attendance: yup
        .number()
        .min(
          MIN_NUMBER_FIELD_VALUE,
          t('Value should be equal to or greater than {{value}}', {
            value: MIN_NUMBER_FIELD_VALUE,
          }),
        )
        .optional()
        .nullable(),
    })
    .defined();

interface Props<T extends FormValues = FormValues> {
  initialErrors: FormikErrors<T>;
  initialTouched: FormikTouched<T>;
  initialValues: T;
  onSubmit: (values: T) => void;
}

export const MatchDataCreateMatchDetails = forwardRef<
  FormikProps<FormValues>,
  Props
>((props, ref) => {
  const { initialErrors, initialTouched, initialValues, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <Formik<FormValues>
      innerRef={ref}
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(t)}
    >
      {() => {
        return (
          <>
            <Grid container spacing={0} justifyContent="flex-end" mb={2}>
              <Grid item xs="auto">
                <Typography variant="caption" color="textSecondary">
                  {`* ${t('Indicates a required field')}`}
                </Typography>
              </Grid>
            </Grid>

            <CenteredContentBlock
              title={t('Duration')}
              mb={3}
              pb={3}
              spacing={3}
            >
              <MatchDataFormFieldsMatchTime responsive />
            </CenteredContentBlock>
            <CenteredContentBlock
              title={t('Other details')}
              mb={3}
              pb={3}
              spacing={3}
            >
              <MatchDataFormFieldsMatchAttendance responsive />
            </CenteredContentBlock>
          </>
        );
      }}
    </Formik>
  );
});
