import { StateProps as TabsProps } from '../reducers';

export const decodeTabs = (input: string): TabsProps => {
  if (!input) {
    return null;
  }

  try {
    const tabsInput = input.substr(1);
    const tabsStr = decodeURIComponent(atob(tabsInput));

    const tabsDecoded = JSON.parse(tabsStr);
    const tabsNormalized = {
      activeIndex: tabsDecoded.a,
      allTabs: tabsDecoded.t.map(
        (tab: { t: string; p: object; i: string; n: string }) => ({
          id: tab.i,
          name: tab.n,
          props: tab.p,
          title: tab.t,
          isClosable: true,
        }),
      ),
    };

    return tabsNormalized;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const encodeTabs = (tabs: TabsProps) => {
  try {
    const tabsShortened = {
      a: tabs.activeIndex,
      t: tabs.allTabs.map(({ id, name, props, title }) => ({
        i: id,
        n: name,
        p: props,
        t: title,
      })),
    };
    const tabsStr: string = JSON.stringify(tabsShortened);

    return btoa(encodeURIComponent(tabsStr));
  } catch (error) {
    console.log(error);

    return null;
  }
};
