import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import {
  GroupStandingSettingsTieRules,
  GroupStandingPointCalculation,
  StandingTableHeaders,
  StandingTableRanks,
} from '@volleyball/types';

import * as ACTION_TYPES from './constants';

export interface DefaultState {
  [groupId: number]: {
    tieRulesByGroupId: GroupStandingSettingsTieRules;
    pointCalculationsByGroupId: GroupStandingPointCalculation;
    tableHeadersByGroupId: StandingTableHeaders;
    tableRankingByGroupId: StandingTableRanks;
  };
}

export const defaultState: DefaultState = {};

const reducer = handleActions<any>(
  {
    [ACTION_TYPES.ADD_GROUP_STANDING_SETTINGS]: (state, { payload }) =>
      assocPath([payload.groupId], payload.settings, state),

    [ACTION_TYPES.CLEAR_GROUP_STANDING_SETTINGS]: (state, { payload }) =>
      dissocPath([payload], state),

    [ACTION_TYPES.RESET_GROUP_STANDING_SETTINGS]: () => defaultState,
  },
  defaultState,
);

export default reducer;
