import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SingleValueProps } from 'react-select';
import { pathOr } from 'ramda';

type Props = SingleValueProps<any>;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const TeamSingleValue = (props: Props) => {
  const classes = useStyles();
  const title = pathOr('', ['data', 'props', 'title'], props);

  return (
    <Grid className={classes.root} {...(props.innerProps as any)}>
      <Grid>
        <Typography variant="body2">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default TeamSingleValue;
