import { Navigate } from 'react-router-dom';
import { useAuth0ReturnTo } from '@core/helpers/auth';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';

const LoggedOut = () => {
  const returnTo = useAuth0ReturnTo();

  return <Navigate to={returnTo || createURL(paths.root)} />;
};

export default LoggedOut;
