import { map, evolve, curryN, pipe, filter } from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  CurrentUserLinks,
  FeatureToggles,
  MenuList,
  MenuItemProps,
} from '@core/types';
import { getAccessLinks } from '@core/pages/user-profile/store/selectors';
import { getFeatureToggles } from '@core/pages/feature-toggle/store/selectors';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as userActions } from '@core/store/modules/api/user';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createAccessValidator } from '@core/helpers';
import { FullScreenSpinner, Menu, MenuWithDrawer } from '@core/components';

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

interface OwnProps {
  mainMenuMapping: MenuList;
  isMobile?: boolean;
}

interface StateProps {
  featureToggles: FeatureToggles;
  accessLinks: CurrentUserLinks;
  isLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const MainMenu = (props: Props) => {
  const {
    mainMenuMapping,
    isMobile = false,
    actions,
    accessLinks,
    isLoading,
  } = props;
  const { t } = useTranslation();

  if (isLoading && !isMobile) {
    return <FullScreenSpinner />;
  }

  const applyTranslations: (mainMenuListItem: MenuItemProps) => MenuItemProps =
    evolve<any>({
      title: curryN(1, t),
      group: curryN(1, t),
    }) as any;

  const addOnClickReturn = (menuItem: MenuItemProps): MenuItemProps => ({
    ...menuItem,
    onSelectedClick: () => {
      actions.tabs.updateActiveTabIndex(0);
    },
  });

  const isMenuItemAllowed = createAccessValidator(accessLinks);

  const menuTree = pipe(
    map<MenuItemProps, MenuItemProps>(applyTranslations),
    map(addOnClickReturn),
    filter(isMenuItemAllowed),
  )(mainMenuMapping);

  return isMobile ? (
    <MenuWithDrawer menuTree={menuTree} />
  ) : (
    <Menu menuTree={menuTree} />
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

const isLoadingSelector = createLoadingSelector([
  userActions.getCurrentUserRequest.toString(),
  // featureToggleActions.getFeatureTogglesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  featureToggles: getFeatureToggles(state),
  accessLinks: getAccessLinks(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
