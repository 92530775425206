import React, { useEffect, useRef } from 'react';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { FilterModel, FilterValue, Paginated } from '@core/types';
import { FilterCheckboxList } from '@core/components';

const useLoadMoreIfNotOverflowing = ({
  handleLoadNext,
  hasMore,
  list,
}: {
  handleLoadNext: () => void;
  hasMore: boolean;
  list: Array<any> | undefined;
}) => {
  const scrollableTargetRef = useRef<HTMLElement>();

  const handleLoadNextRef = useRef(handleLoadNext);
  handleLoadNextRef.current = handleLoadNext;

  useEffect(() => {
    const isScrollable = (element: HTMLElement) =>
      element.scrollHeight > element.clientHeight;

    if (
      scrollableTargetRef.current &&
      !isScrollable(scrollableTargetRef.current) &&
      list?.length &&
      hasMore
    ) {
      handleLoadNextRef.current();
    }

    // TODO: JB: to be tested
    const handleWindowResize = () => {
      if (
        scrollableTargetRef.current &&
        !isScrollable(scrollableTargetRef.current) &&
        list?.length &&
        hasMore
      ) {
        handleLoadNextRef.current();
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [hasMore, list?.length]);

  return { scrollableTargetRef };
};

type OwnProps = {
  handleFilterAdd: (filter: FilterModel) => void;
  handleFilterRemove: (filter: FilterModel) => void;
  filterValues: Array<FilterValue>;
  searchQuery: string;
  loadNext: (queryParams: { page: number; query: string }) => void;
  filterProps: Partial<FilterModel>;
  formatFilterLabel: (id: string) => string;
  paginatedList: Paginated<any>;
  formatLabel?: (item: any) => string;
};

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  scrollable: {
    maxHeight: 350,
    overflowY: 'auto',
    // TODO: JB: verify that this is ok or do something better
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100vh - 196px)`,
    },
  },
}));

const Checkboxes = (props: Props) => {
  const { paginatedList, searchQuery, loadNext, formatFilterLabel, ...rest } =
    props;
  const { list, pages, page } = paginatedList || {};
  const { t } = useTranslation();
  const classes = useStyles();
  const hasMore = pages > page;

  function renderLoader() {
    return (
      <Box display="flex" justifyContent="center">
        <Typography>{`${t('Loading')}...`}</Typography>
      </Box>
    );
  }

  function handleLoadNext() {
    loadNext({ page: page + 1, query: searchQuery });
  }

  // if items are not overflowing scrollable target (no scrollbar), but more items exist,
  // load more items until scrollbar appears (so that user can scroll to the bottom to load more)
  // or no more items exist (if there doens't exist enough items for scrollbar to appear)
  const { scrollableTargetRef } = useLoadMoreIfNotOverflowing({
    hasMore,
    list,
    handleLoadNext,
  });

  return list ? (
    <Box
      component={Grid}
      id="scrollableTarget"
      className={classes.scrollable}
      sx={{ display: { xs: 'block', sm: 'flex' } }}
      ref={scrollableTargetRef}
    >
      <InfiniteScroll
        hasMore={hasMore}
        next={handleLoadNext}
        loader={renderLoader()}
        dataLength={list?.length}
        scrollableTarget="scrollableTarget"
      >
        <FilterCheckboxList
          shifted
          list={list || []}
          formatFilterLabel={formatFilterLabel}
          {...rest}
        />
      </InfiniteScroll>
    </Box>
  ) : null;
};

export default Checkboxes;
