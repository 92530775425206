const sortAlphabetically = (a: string, b: string): number =>
  a > b ? 1 : a < b ? -1 : 0;

export const sortByKey = <T extends Record<any, any> = Record<any, any>>(
  reducersMapObject: T,
): T => {
  const sortedEntries = Object.entries(reducersMapObject).sort(
    ([key1], [key2]) => sortAlphabetically(key1, key2),
  );

  return Object.fromEntries(sortedEntries) as T;
};
