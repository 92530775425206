import restService from '@core/api/rest-service';
import {
  DisciplinaryRulesResponse,
  DisciplinaryRuleResponse,
  DisciplinaryRule,
} from '@core/types';

type DisciplinaryRuleParams = Partial<DisciplinaryRule>;

export const getDisciplinaryRules = (competitionId: number) =>
  restService.get<DisciplinaryRulesResponse>(
    `/v1/competitions/${competitionId}/disciplinaryRule`,
  );

export const getDisciplinaryRule = (competitionId: number, ruleId: number) =>
  restService.get<DisciplinaryRuleResponse>(
    `/v1/competitions/${competitionId}/disciplinaryRule/${ruleId}`,
  );

export const createDisciplinaryRule = (
  competitionId: number,
  data: DisciplinaryRuleParams,
) =>
  restService.post(`/v1/competitions/${competitionId}/disciplinaryRule`, data);

export const updateDisciplinaryRule = (
  competitionId: number,
  ruleId: number,
  data: DisciplinaryRuleParams,
) =>
  restService.patch(
    `/v1/competitions/${competitionId}/disciplinaryRule/${ruleId}`,
    data,
  );

export const deleteDisciplinaryRule = (competitionId: number, ruleId: number) =>
  restService.delete(
    `/v1/competitions/${competitionId}/disciplinaryRule/${ruleId}`,
  );
