import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
} from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import { ChipsSelect, NumberField, Toggle, Tooltip } from '@core/components';
import { StageType, DoubleEliminationType, EliminationType } from '@core/types';
import { useRef } from '@core/helpers';

import RoundsTable from './components/rounds-table';
import {
  FIELD_NAMES,
  getKnockoutDoubleEliminationCrossoverGroups,
  getKnockoutDoubleEliminationLoserGroups,
  getKnockoutDoubleEliminationWinnerGroups,
  getKnockoutSingleEliminationGroups,
} from '../../constants';
import useSharedStyles from '../../styles';
import { FormValues } from '../../helpers/initialValues';
import { getNumberOfTeamsMinMax } from '../../helpers/validationSchema';
import * as testIds from '../../tests/test-ids';

const useStyles = makeStyles(() => {
  const scrollBar: CSSProperties = {
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: 8,
    },
    '& ::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .3)',
    },
  };

  return {
    horizontalScrollContainer1: scrollBar,
    horizontalScrollContainer2: {
      display: 'flex',
      overflowX: 'auto',
      outline: '0px solid red',
      ...scrollBar,
    },
    sideBySideTables: {
      '& > *:first-child, & > *:last-child,': {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'calc(50% - 1.5px)',
        padding: '0 24px',
      },
      '& > *:first-child': {
        marginLeft: '-24px',
      },
      '& > *:last-child': {
        marginRight: '-24px',
      },
    },
  };
});

const KnockOutAccordion = () => {
  const { t } = useTranslation();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const numberOfTeams = values?.[FIELD_NAMES.numberOfTeams];
  const elimination = values?.[FIELD_NAMES.knockOutElimination];
  const doubleElimination = values?.[FIELD_NAMES.knockoutDoubleElimination];
  const thirdPlaceMatch = values?.[FIELD_NAMES.thirdPlaceMatch];
  const knockoutExtraGroups = values?.[FIELD_NAMES.knockOutExtraGroups];
  const [isShowThirdPlaceMatch, setIsShowThirdPlaceMatch] = useState<boolean>(
    numberOfTeams > 3,
  );

  const setFieldValueRef = useRef(setFieldValue);
  const knockOutDoubleEliminationCrossOverFinalsGroupsRef = useRef(
    values?.[FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups],
  );
  const knockOutDoubleEliminationLosersGroupsRef = useRef(
    values?.[FIELD_NAMES.knockOutDoubleEliminationLosersGroups],
  );
  const knockOutDoubleEliminationWinnersGroupsRef = useRef(
    values?.[FIELD_NAMES.knockOutDoubleEliminationWinnersGroups],
  );
  const knockOutExtraGroupsRef = useRef(
    values[FIELD_NAMES.knockOutExtraGroups],
  );
  const knockOutSingleEliminationGroupsRef = useRef(
    values?.[FIELD_NAMES.knockoutSingleEliminationGroups],
  );

  const numberOfTeamsRange = getNumberOfTeamsMinMax({
    knockoutDoubleElimination: doubleElimination,
    knockoutElimination: elimination,
    numberOfPoolsValue: undefined,
    stageType: StageType.KNOCK_OUT,
    variant: 'stage',
  });

  useEffect(() => {
    if (numberOfTeams && elimination === EliminationType.SINGLE) {
      if (numberOfTeamsRange.min > numberOfTeams) {
        setFieldValueRef.current(
          FIELD_NAMES.knockoutSingleEliminationGroups,
          [],
        );
      }

      const groups = getKnockoutSingleEliminationGroups(t, numberOfTeams);

      if (groups.length !== knockOutSingleEliminationGroupsRef.current.length) {
        setFieldValueRef.current(
          FIELD_NAMES.knockoutSingleEliminationGroups,
          groups,
        );

        return;
      }
    }

    if (numberOfTeams && elimination === EliminationType.DOUBLE) {
      if (numberOfTeamsRange.min > numberOfTeams) {
        setFieldValueRef.current(
          FIELD_NAMES.knockOutDoubleEliminationLosersGroups,
          [],
        );
        setFieldValueRef.current(
          FIELD_NAMES.knockOutDoubleEliminationWinnersGroups,
          [],
        );

        return;
      }

      {
        const groups = getKnockoutDoubleEliminationLoserGroups(
          t,
          numberOfTeams,
          doubleElimination,
        );

        if (
          groups.length !==
          knockOutDoubleEliminationLosersGroupsRef.current.length
        ) {
          setFieldValueRef.current(
            FIELD_NAMES.knockOutDoubleEliminationLosersGroups,
            groups,
          );
        }
      }

      {
        const groups = getKnockoutDoubleEliminationWinnerGroups(
          t,
          numberOfTeams,
          doubleElimination,
        );

        if (
          groups.length !==
          knockOutDoubleEliminationWinnersGroupsRef.current.length
        ) {
          setFieldValueRef.current(
            FIELD_NAMES.knockOutDoubleEliminationWinnersGroups,
            groups,
          );
        }
      }
    }
  }, [
    doubleElimination,
    elimination,
    numberOfTeams,
    numberOfTeamsRange.min,
    t,
  ]);

  useEffect(() => {
    if (
      doubleElimination === DoubleEliminationType.CROSSOVER &&
      knockOutDoubleEliminationCrossOverFinalsGroupsRef.current.length === 0
    ) {
      const groups = getKnockoutDoubleEliminationCrossoverGroups(t);

      setFieldValueRef.current(
        FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups,
        groups,
      );
    }
  }, [doubleElimination, t]);

  const handleAddGroup = useCallback(() => {
    const groups = [...knockOutExtraGroupsRef.current];
    const numberOfExtraRounds = groups.length;

    groups.push({
      title: `${t('Extra round')} ${numberOfExtraRounds + 1}`,
      index: numberOfExtraRounds,
      extraRound: true,
      bestOf: 1,
      id: numberOfExtraRounds,
    });

    setFieldValueRef.current(FIELD_NAMES.knockOutExtraGroups, groups);
  }, [t]);

  const handleGroupDelete = useCallback(() => {
    const groups = [...knockOutExtraGroupsRef.current];

    groups.splice(groups.length - 1, 1);

    setFieldValueRef.current(FIELD_NAMES.knockOutExtraGroups, groups);
  }, []);

  const toggleDoubleEliminationCrossover = useCallback(() => {
    setFieldValueRef.current(
      FIELD_NAMES.knockoutDoubleElimination,
      doubleElimination === DoubleEliminationType.CROSSOVER
        ? DoubleEliminationType.CLASSIC
        : DoubleEliminationType.CROSSOVER,
      true,
    );
  }, [doubleElimination]);

  const toggle3rdPlaceMatch = useCallback(() => {
    setFieldValueRef.current(
      FIELD_NAMES.thirdPlaceMatch,
      !thirdPlaceMatch,
      true,
    );
  }, [thirdPlaceMatch]);

  useEffect(() => {
    if (numberOfTeams > 3) {
      setIsShowThirdPlaceMatch(true);
    } else {
      setIsShowThirdPlaceMatch(false);
    }
  }, [numberOfTeams, setIsShowThirdPlaceMatch]);

  useEffect(() => {
    setFieldValueRef.current(FIELD_NAMES.thirdPlaceMatch, false, true);
  }, [isShowThirdPlaceMatch, setFieldValueRef.current]);

  return (
    <Box mb={1} width={'100%'}>
      <Paper>
        <Accordion expanded={values.stageType === StageType.KNOCK_OUT}>
          <AccordionSummary classes={{ root: sharedClasses.accordionSummary }}>
            <FormControlLabel
              control={<Radio color="primary" />}
              label={t('Knock out')}
              style={{ width: '100%' }}
              value={StageType.KNOCK_OUT}
            />
          </AccordionSummary>
          {values.stageType === StageType.KNOCK_OUT && (
            <AccordionDetails
              classes={{ root: sharedClasses.accordionDetails }}
            >
              <Grid container item spacing={2}>
                <Grid container item xs={12} alignItems="end">
                  <Grid item xs>
                    <ChipsSelect
                      fieldName={FIELD_NAMES.knockOutElimination}
                      options={[
                        {
                          label: t('Single-elimination'),
                          value: EliminationType.SINGLE,
                        },
                        {
                          label: t('Double-elimination'),
                          value: EliminationType.DOUBLE,
                        },
                      ]}
                      required
                      title={t('Elimination type')}
                    />
                  </Grid>
                  {isShowThirdPlaceMatch &&
                    elimination === EliminationType.SINGLE && (
                      <Grid item>
                        <Toggle
                          checked={thirdPlaceMatch}
                          label={
                            <>
                              {t('3rd Place Match')}
                              <Tooltip
                                title={t(
                                  `A 3rd place match will be generated following the existing knock out stage rules.`,
                                )}
                              />
                            </>
                          }
                          onChange={toggle3rdPlaceMatch}
                        />
                      </Grid>
                    )}
                  {elimination === EliminationType.DOUBLE && (
                    <Grid item>
                      <Toggle
                        checked={
                          doubleElimination === DoubleEliminationType.CROSSOVER
                        }
                        label={
                          <>
                            {t('Crossover finals')}
                            <Tooltip
                              title={t(
                                `The last 2 remaining teams in the winner's bracket do not play each other.
                                Instead, they each play one of the 2 remaining teams in the loser's bracket.`,
                              )}
                            />
                          </>
                        }
                        onChange={toggleDoubleEliminationCrossover}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberField
                    name={FIELD_NAMES.numberOfTeams}
                    data-qa={FIELD_NAMES.numberOfTeams}
                    label={t('Number of teams')}
                    required
                    {...numberOfTeamsRange}
                  />
                </Grid>
                {elimination === EliminationType.SINGLE && (
                  <Grid item xs={12}>
                    <Box mx={1}>
                      <RoundsTable
                        name={FIELD_NAMES.knockoutSingleEliminationGroups}
                      />
                    </Box>
                  </Grid>
                )}
                {elimination === EliminationType.DOUBLE && (
                  <Grid
                    item
                    xs={12}
                    className={classes.horizontalScrollContainer1}
                  >
                    <Box
                      mx={-2}
                      className={clsx(
                        classes.horizontalScrollContainer2,
                        classes.sideBySideTables,
                      )}
                    >
                      <div>
                        <RoundsTable
                          name={
                            FIELD_NAMES.knockOutDoubleEliminationWinnersGroups
                          }
                        />
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div>
                        <RoundsTable
                          name={
                            FIELD_NAMES.knockOutDoubleEliminationLosersGroups
                          }
                        />
                      </div>
                    </Box>
                  </Grid>
                )}
                {elimination === EliminationType.DOUBLE &&
                  doubleElimination === DoubleEliminationType.CROSSOVER && (
                    <Grid item xs={12}>
                      <Box mx={1}>
                        <RoundsTable
                          name={
                            FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Box mx={1}>
                    <RoundsTable
                      name={FIELD_NAMES.knockOutExtraGroups}
                      onDelete={handleGroupDelete}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    disabled={knockoutExtraGroups.length >= 10}
                    onClick={handleAddGroup}
                    startIcon={<AddCircleOutlineOutlined />}
                    data-qa={testIds.STAGE_ADD_EXTRA_ROUND_BUTTON}
                  >
                    {t('Add round')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      </Paper>
    </Box>
  );
};

export default KnockOutAccordion;
