import { object, string, number, AnySchema } from 'yup';
import { TFunction } from 'i18next';
import { propEq } from 'ramda';
import { DisciplinaryRules } from '@volleyball/types';
import { FIELD_NAMES } from '../constants';

const getValidationSchema = (t: TFunction, data: DisciplinaryRules) => {
  const cardCountTaken = (cardType: string, offenderType: string) => {
    const checkOffender = propEq(FIELD_NAMES.offenderType, offenderType);
    const checkCardType = propEq(FIELD_NAMES.cardType, cardType);

    const filtered = data.filter(
      (entry) => checkOffender(entry) && checkCardType(entry),
    );

    return filtered.map((entry) => entry[FIELD_NAMES.cards]);
  };

  const positiveNumberRequired = number()
    .min(
      1,
      t('Value should be equal to or greater than {{value}}', { value: 1 }),
    )
    .integer()
    .required(t('Required'));
  const requiredString = string().required(t('Required'));

  return object().shape({
    [FIELD_NAMES.cards]: positiveNumberRequired.when(
      [FIELD_NAMES.cardType, FIELD_NAMES.offenderType],
      {
        is: (
          cardType: string,
          offenderType: string,
          schema: AnySchema,
          currentEntry: any,
        ) => {
          const cardCountDuplicates: Array<number> = cardCountTaken(
            cardType,
            offenderType,
          );
          if (
            !cardCountDuplicates.length ||
            !cardCountDuplicates.includes(parseInt(currentEntry?.value, 10))
          ) {
            return schema;
          }
          const regex = new RegExp('[^' + cardCountDuplicates.join('') + ']');

          return !!requiredString.matches(regex, {
            message: t('Duplicate rule'),
            excludeEmptyString: true,
          });
        },
        then: (schema) => schema.required(t('Duplicate rule')),
      },
    ),
    [FIELD_NAMES.cardType]: requiredString.matches(/[^(PLEASE_SELECT)]/, {
      message: t('Please Select'),
    }),
    [FIELD_NAMES.matchesSuspended]: positiveNumberRequired,
    [FIELD_NAMES.offenderType]: requiredString.matches(/[^(PLEASE_SELECT)]/, {
      message: t('Please Select'),
    }),
  });
};

export default getValidationSchema;
