import { createAction } from 'redux-actions';

import { Paginated, Filters, Sorting, MatchModel } from '@core/types';

import { NAMESPACE } from './constants';

const getMatches = createAction<{ page?: number }>(`${NAMESPACE}/GET_MATCHES`);
const getMatchesRequest = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_REQUEST`,
);
const getMatchesSuccess = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_SUCCESS`,
);
const getMatchesFailure = createAction<void>(
  `${NAMESPACE}/GET_MATCHES_FAILURE`,
);
const setMatches = createAction<Paginated<MatchModel['id']>>(
  `${NAMESPACE}/SET_MATCHES`,
);
const setSearchQuery = createAction<string>(`${NAMESPACE}/SET_SEARCH_QUERY`);
const resetMatches = createAction<void>(`${NAMESPACE}/RESET_MATCHES`);
const searchByQuery = createAction<{ page?: number }>(
  `${NAMESPACE}/SEARCH_BY_QUERY`,
);
const setFilterParams = createAction<Filters>(`${NAMESPACE}/SET_FILTER_PARAMS`);
const setSorting = createAction<Sorting>(`${NAMESPACE}/SET_SORTING_ORDER`);
const changeMatchAttendance = createAction(
  `${NAMESPACE}/CHANGE_MATCH_ATTENDANCE`,
);

export const actions = {
  getMatches,
  getMatchesRequest,
  getMatchesSuccess,
  getMatchesFailure,
  setMatches,
  setSearchQuery,
  resetMatches,
  searchByQuery,
  setFilterParams,
  setSorting,
  changeMatchAttendance,
};
