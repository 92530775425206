import React, { useEffect } from 'react';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import { FullScreenSpinner } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { withLayout } from '@core/components/layout';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import {
  getMatchGroupByGroupId,
  getMatch,
} from '@core/store/modules/tabs/match-profile/selectors';
import { actions as matchSetsActions } from '@core/store/modules/match-set';
import { getMatchSetsByMatchId } from '@core/store/modules/match-set/selectors';
import { Group, LayoutProps, MatchModel } from '@core/types';
import { MatchSet } from '@volleyball/types';
import { State } from '@volleyball/store';

import MatchProfile from './components/match-profile-template';

export interface OwnProps {
  competitionId: number;
  id?: number;
  matchDays?: Array<number>;
  matchId: number;
  tabId?: string;
  stageId?: number;
}

interface DispatchProps {
  actions: {
    matchProfile: typeof matchProfileActions;
    matchSetsActions: typeof matchSetsActions;
  };
}

interface StateProps {
  group: Group;
  isLoading: boolean;
  match: MatchModel;
  matchSets: Array<MatchSet>;
}

type Props = OwnProps & StateProps & DispatchProps;

const MatchProfilePage = (props: Props) => {
  const {
    actions,
    competitionId,
    group,
    id: routeId,
    matchId,
    match,
    isLoading,
    stageId,
    tabId,
    matchDays,
    matchSets,
  } = props;
  const getMatchId = () => matchId || routeId;

  useEffect(() => {
    if (getMatchId()) {
      actions.matchProfile.getMatch({ matchId: getMatchId() });
    }

    return () => {
      actions.matchProfile.resetMatchProfile(getMatchId());
    };
  }, [actions.matchProfile, matchId, routeId]);

  useEffect(() => {
    if (match?.groupId && isNil(group)) {
      actions.matchProfile.getMatchGroup({ matchId, groupId: match?.groupId });
    }
  }, [actions.matchProfile, group, match?.groupId, matchId]);

  useEffect(() => {
    if (!matchSets) {
      actions.matchSetsActions.getMatchSets({ matchId: getMatchId() });
    }
  }, [actions.matchSetsActions, matchSets]);

  return isLoading || isNil(group) || isNil(match) || isNil(matchSets) ? (
    <FullScreenSpinner />
  ) : (
    <MatchProfile
      competitionId={competitionId}
      group={group}
      match={match}
      matchDays={matchDays}
      stageId={stageId}
      tabId={tabId}
      matchSets={matchSets}
    />
  );
};

const isLoadingSelector = (groupId: number, matchId: number) =>
  createLoadingSelector([
    { actionName: matchProfileActions.getMatchRequest.toString(), id: groupId },
    {
      actionName: matchSetsActions.getMatchSetsRequest.toString(),
      id: matchId,
    },
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchProfile: bindActionCreators(matchProfileActions, dispatch),
    matchSetsActions: bindActionCreators(matchSetsActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  { matchId: currentMatchId, id: routeId }: OwnProps,
): StateProps => {
  const matchId = currentMatchId || routeId;
  const match: MatchModel = getMatch(state, { matchId });

  return {
    group: getMatchGroupByGroupId(state, match?.groupId),
    matchSets: getMatchSetsByMatchId(state, matchId),
    isLoading: isLoadingSelector(match?.groupId, matchId)(state),
    match,
  };
};

export const MatchProfileWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchProfilePage);

export default compose<React.FunctionComponent<LayoutProps>>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(MatchProfilePage);
