import { FilteringOperations } from '@core/types';
import { createFilterId } from '@core/helpers';

export const MEMBER_BIRTH_DATE_FROM_FILTER = {
  fieldName: 'dateOfBirth',
  filteringOperation: FilteringOperations.greaterThanOrEqual,
};

export const MEMBER_BIRTH_DATE_TO_FILTER = {
  fieldName: 'dateOfBirth',
  filteringOperation: FilteringOperations.lessThanOrEqual,
};

export const MEMBER_TYPE_FILTER = {
  fieldName: 'type',
};

export const MEMBER_BIRTH_DATE_FROM_FILTER_ID = createFilterId(
  MEMBER_BIRTH_DATE_FROM_FILTER,
);

export const MEMBER_BIRTH_DATE_TO_FILTER_ID = createFilterId(
  MEMBER_BIRTH_DATE_TO_FILTER,
);

export const MEMBER_TYPE_FILTER_ID = createFilterId(MEMBER_TYPE_FILTER);
