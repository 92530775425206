import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { PaginationProps } from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { CustomerResponse, Genders, PlayerList } from '@core/types';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';
import {
  getClubPlayers,
  getAddedCompetitorPlayers,
  getClubPlayersPagination,
} from '@core/store/modules/tabs/player-list-management/selectors';
import { State } from '@core/store';

import ClubPlayersTable, {
  getSearchClubPlayersQueryParams,
} from './club-players-table';
import CompetitorPlayersTable from './competitor-players-table';

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
  };
}

interface StateProps {
  clubPlayers: PlayerList;
  clubPlayersPagination: PaginationProps;
  addedCompetitorPlayers: PlayerList;
  areClubPlayersLoading: boolean;
  currentCustomer: CustomerResponse;
}
interface OwnProps {
  clubId: number;
  sportId: number;
  competitorId: number;
  areCompetitorPlayersLoading: boolean;
  minPlayers: number;
  maxPlayers: number;
  earliestDateOfBirth: string;
  gender?: keyof typeof Genders;
}

type Props = OwnProps & DispatchProps & StateProps;

const PlayersTab = (props: Props) => {
  const {
    actions,
    addedCompetitorPlayers,
    clubId,
    sportId,
    competitorId,
    clubPlayers,
    clubPlayersPagination,
    areCompetitorPlayersLoading,
    areClubPlayersLoading,
    earliestDateOfBirth,
    gender,
  } = props;

  useEffect(() => {
    actions.playerListManagement.searchClubPlayers({
      clubId,
      queryParams: getSearchClubPlayersQueryParams({ clubId, sportId, gender }),
    });

    return () => {
      actions.playerListManagement.resetClubPlayers({ clubId });
    };
  }, []);

  const handleAddedCompetitorPlayersChange = (players: PlayerList) => {
    actions.playerListManagement.setAddedCompetitorPlayers({
      payload: players,
      competitorId,
    });
  };

  const onPageChange = (page: number) => {
    actions.playerListManagement.searchClubPlayers({
      clubId,
      queryParams: getSearchClubPlayersQueryParams({
        clubId,
        sportId,
        gender,
        page,
      }),
    });
  };

  return (
    <Grid container spacing={3}>
      <ClubPlayersTable
        isLoading={areClubPlayersLoading || areCompetitorPlayersLoading}
        clubPlayers={clubPlayers}
        clubPlayersPagination={clubPlayersPagination}
        onPageChange={onPageChange}
        addedCompetitorPlayers={addedCompetitorPlayers}
        updatePlayersList={handleAddedCompetitorPlayersChange}
        earliestDateOfBirth={earliestDateOfBirth}
        clubId={clubId}
        sportId={sportId}
        gender={gender}
      />
      <CompetitorPlayersTable
        isLoading={areCompetitorPlayersLoading}
        addedCompetitorPlayers={addedCompetitorPlayers}
        updatePlayersList={handleAddedCompetitorPlayersChange}
        earliestDateOfBirth={earliestDateOfBirth}
      />
    </Grid>
  );
};

const clubPlayersLoadingSelector = createLoadingSelector([
  playerListManagementActions.searchClubPlayersRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
  clubPlayers: getClubPlayers(ownProps.clubId)(state),
  clubPlayersPagination: getClubPlayersPagination(ownProps.clubId)(state),
  addedCompetitorPlayers: getAddedCompetitorPlayers(ownProps.competitorId)(
    state,
  ),
  areClubPlayersLoading: clubPlayersLoadingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayersTab);
