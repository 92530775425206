import { call, put, Effect } from 'redux-saga/effects';

import * as matchOfficialsApi from '@core/api/match-officials';

import { actions } from '.';

export function* getMatchOfficials(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getMatchOfficialsRequest());
  const response = (yield call(
    matchOfficialsApi.getMatchOfficials,
    queryParams,
  )) as InferApi<typeof matchOfficialsApi.getMatchOfficials>;

  if (!response.error) {
    yield put(actions.getMatchOfficialsSuccess());
    yield put(
      actions.setMatchOfficials(response.response._embedded.matchOfficials),
    );
  } else {
    yield put(actions.getMatchOfficialsFailure());
  }

  return response;
}

export function* createMatchOfficial(
  sportId: number,
  personId: number,
  roleId: Array<number>,
): Generator<Effect, any, any> {
  yield put(actions.createMatchOfficialRequest());
  const response = (yield call(
    matchOfficialsApi.createMatchOfficial,
    sportId,
    personId,
    roleId,
  )) as InferApi<typeof matchOfficialsApi.createMatchOfficial>;

  if (!response.error) {
    yield put(actions.createMatchOfficialSuccess());
  } else {
    yield put(actions.createMatchOfficialFailure());
  }

  return response;
}

export function* deleteMatchOfficial(
  matchOfficialId: number,
): Generator<Effect, any, any> {
  yield put(actions.deleteMatchOfficialRequest());
  const response = (yield call(
    matchOfficialsApi.deleteMatchOfficial,
    matchOfficialId,
  )) as InferApi<typeof matchOfficialsApi.deleteMatchOfficial>;

  if (!response.error) {
    yield put(actions.deleteMatchOfficialSuccess());
  } else {
    yield put(actions.deleteMatchOfficialFailure());
  }

  return response;
}

export function* getMatchOfficialListByPerson(
  personId: number,
): Generator<Effect, any, any> {
  yield put(actions.getMatchOfficialListByPersonRequest());
  const response = (yield call(
    matchOfficialsApi.getMatchOfficialListByPerson,
    personId,
  )) as InferApi<typeof matchOfficialsApi.getMatchOfficialListByPerson>;

  if (!response.error) {
    yield put(actions.getMatchOfficialListByPersonSuccess());
  } else {
    yield put(actions.getMatchOfficialListByPersonFailure());
  }

  return response;
}
