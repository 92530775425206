import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FlowIndicator } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getAllocations } from '@core/store/modules/matches-allocation/selectors';
import { actions as matchesAllocationActions } from '@core/store/modules/matches-allocation';
import { State } from '@core/store';

interface OwnProps {
  matchId: number;
  groupId: number;
}

interface StateProps {
  allocations: ReturnType<ReturnType<typeof getAllocations>>;
  isLoading: boolean;
}

type Props = OwnProps & StateProps;

const AllocationsMatchOfficialsStatus = (props: Props) => {
  const { t } = useTranslation();
  const { allocations, isLoading, matchId } = props;

  const { hasConflicts, isFullyAllocated } =
    allocations?.find((allocation) => allocation.matchId === matchId) ??
    ({} as InferArray<typeof allocations>);

  return isLoading ? (
    <CircularProgress size={24} />
  ) : (
    <Box display="flex" alignItems="center">
      {hasConflicts && <FlowIndicator size="small" />}
      {isFullyAllocated ? t('Appointed') : t('Not appointed')}
    </Box>
  );
};

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    matchesAllocationActions.refreshMatchesAllocationRequest({
      stageId: ownProps.groupId,
    }),
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  allocations: getAllocations(ownProps.groupId)(state),
  isLoading: isLoadingSelector(ownProps)(state),
});

export default connect(mapStateToProps)(AllocationsMatchOfficialsStatus);
