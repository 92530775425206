import { createAction } from 'redux-actions';

import * as SPORTS_ACTION_TYPES from './constants';

export const getSportsRequest = createAction(
  SPORTS_ACTION_TYPES.GET_SPORTS_REQUEST,
);
export const getSportsSuccess = createAction(
  SPORTS_ACTION_TYPES.GET_SPORTS_SUCCESS,
);
export const getSportsFailure = createAction(
  SPORTS_ACTION_TYPES.GET_SPORTS_FAILURE,
);

export const setSports = createAction(SPORTS_ACTION_TYPES.SET_SPORTS);
