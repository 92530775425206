import { converge, merge, pick, pipe, path, assoc, __, map } from 'ramda';

export const formatPaginatedStore = (entity: string) =>
  // @ts-ignore
  converge(merge, [
    pick(['page', 'limit', 'pages', 'total']),
    pipe(path(['_embedded', entity]), assoc('list', __, {})),
  ]);

export const formatPaginatedStoreWithIds = (entity: string) =>
  // @ts-ignore
  converge(merge, [
    pick(['page', 'limit', 'pages', 'total']),
    pipe(
      // @ts-ignore
      path(['_embedded', entity]),
      map((item: any) => item.id),
      assoc('list', __, {}),
    ),
  ]);
