import { handleActions } from 'redux-actions';
import { assocPath, dissoc } from 'ramda';

import {
  CompetitorResponse,
  MatchCompetitorsResponse,
  MatchPlayerOrigin,
  MatchPlayersList,
  MatchTeamOfficialsList,
  KitSet,
  MatchDurationList,
  PenaltyShootouts,
  MatchModel,
} from '@core/types';

import { actions } from '.';

export interface DefaultState {
  byMatchId: {
    [matchId: string]: {
      match: MatchModel;
      group?: number;
      [MatchPlayerOrigin.AWAY]?: {
        competitionCompetitor: CompetitorResponse;
        players: MatchPlayersList;
        kitSet: KitSet;
      };
      [MatchPlayerOrigin.HOME]?: {
        competitionCompetitor: CompetitorResponse;
        players: MatchPlayersList;
        kitSet: KitSet;
      };
      awayTeamOfficials?: MatchTeamOfficialsList;
      homeTeamOfficials?: MatchTeamOfficialsList;
      penaltyShootouts?: PenaltyShootouts;
      matchDurationList?: MatchDurationList;
      matchCompetitors?: MatchCompetitorsResponse;
    };
  };
}

export const defaultState: DefaultState = {
  byMatchId: {},
};

type Payload = any;

interface Meta {
  matchId?: number;
  matchPlayerOrigin?: MatchPlayerOrigin;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  // @ts-ignore
  {
    [actions.setMatch.toString()]: (state, { payload, meta }) =>
      assocPath(['byMatchId', meta.matchId, 'match'], payload, state),
    [actions.setMatchGroup.toString()]: (state, { payload, meta }) =>
      assocPath(['byMatchId', meta.matchId, 'group'], payload, state),
    [actions.setMatchPlayers.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, meta.matchPlayerOrigin, 'players'],
        payload,
        state,
      ),
    [actions.setMatchNotesDescriptions.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchNotesDescriptions'],
        payload,
        state,
      ),
    [actions.setMatchNotesTitles.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchNotesTitles'],
        payload,
        state,
      ),
    [actions.setMatchCommissionerNoteDescriptions.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchCommissionerNoteDescriptions'],
        payload,
        state,
      ),
    [actions.setMatchCommissionerNoteTitles.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchCommissionerNoteTitles'],
        payload,
        state,
      ),
    [actions.setAwayTeamOfficials.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'awayTeamOfficials'],
        payload,
        state,
      ),
    [actions.setHomeTeamOfficials.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'homeTeamOfficials'],
        payload,
        state,
      ),
    [actions.resetMatchProfile.toString()]: (state, { payload }) =>
      dissoc(payload, state),
    [actions.setKitSet.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, meta.matchPlayerOrigin, 'kitSet'],
        payload,
        state,
      ),
    [actions.setPenaltyShootouts.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'penaltyShootouts'],
        payload,
        state,
      ),
    [actions.setMatchDurationList.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchDurationList'],
        payload,
        state,
      ),
    [actions.setMatchScores.toString()]: (state, { payload, meta }) =>
      assocPath(['byMatchId', meta.matchId, 'matchScores'], payload, state),
    [actions.setMatchCompetitors.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byMatchId', meta.matchId, 'matchCompetitors'],
        payload,
        state,
      ),
    [actions.setCompetitionCompetitor.toString()]: (state, { payload, meta }) =>
      assocPath(
        [
          'byMatchId',
          meta.matchId,
          meta.matchPlayerOrigin,
          'competitionCompetitor',
        ],
        payload,
        state,
      ),
  },
  defaultState,
);

export default reducer;
