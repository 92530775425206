export const NAMESPACE = 'groupStandingsSettings';

export const GET_GROUP_STANDING_SETTINGS = `${NAMESPACE}/GET_GROUP_STANDING_SETTINGS`;
export const GET_GROUP_STANDING_SETTINGS_REQUEST = `${NAMESPACE}/GET_GROUP_STANDING_SETTINGS_REQUEST`;
export const GET_GROUP_STANDING_SETTINGS_FAILURE = `${NAMESPACE}/GET_GROUP_STANDING_SETTINGS_FAILURE`;
export const GET_GROUP_STANDING_SETTINGS_SUCCESS = `${NAMESPACE}/GET_GROUP_STANDING_SETTINGS_SUCCESS`;

export const ADD_GROUP_STANDING_SETTINGS = `${NAMESPACE}/ADD_GROUP_STANDING_SETTINGS`;

export const SET_GROUP_STANDING_SETTINGS = `${NAMESPACE}/SET_GROUP_STANDING_SETTINGS`;
export const SET_GROUP_STANDING_SETTINGS_REQUEST = `${NAMESPACE}/SET_GROUP_STANDING_SETTINGS_REQUEST`;
export const SET_GROUP_STANDING_SETTINGS_FAILURE = `${NAMESPACE}/SET_GROUP_STANDING_SETTINGS_FAILURE`;
export const SET_GROUP_STANDING_SETTINGS_SUCCESS = `${NAMESPACE}/SET_GROUP_STANDING_SETTINGS_SUCCESS`;

export const CLEAR_GROUP_STANDING_SETTINGS = `${NAMESPACE}/CLEAR_GROUP_STANDING_SETTINGS`;

export const RESET_GROUP_STANDING_SETTINGS = `${NAMESPACE}/RESET_GROUP_STANDING_SETTINGS`;
