import React from 'react';
import { Grid, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';

export interface NoDataAlertProps {
  text: string;
}

const NoDataAlert = ({ text }: NoDataAlertProps) => {
  return (
    <Grid container alignItems="center" justifyContent="center" flexWrap="wrap">
      <AccessTime color="action" />
      <Typography
        color="textSecondary"
        variant="body2"
        sx={{ ml: 1, lineHeight: 1 }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default NoDataAlert;
