import {
  assoc,
  ifElse,
  prop,
  pipe,
  evolve,
  reject,
  isNil,
  is,
  identity,
  merge,
  dissoc,
} from 'ramda';

import { KitSet, KitSetFormData } from '@core/types';

const setReservedPlayerKitIsEnabledFlag = ifElse(
  prop('reservedPlayerKit') as any,
  assoc('reservedPlayerKitIsEnabled', true),
  assoc('reservedPlayerKitIsEnabled', false),
);

const cleanNilValues = ifElse(is(Object), reject(isNil), identity);

const mergeIfInitial = (defaultValue: any) =>
  ifElse(isNil, () => defaultValue, merge(defaultValue));

export const populateWithDefault = (
  kitSet: KitSet,
  defaultValues: KitSetFormData,
) =>
  pipe(
    setReservedPlayerKitIsEnabledFlag as any,
    evolve({
      homePlayerKit: cleanNilValues,
      awayPlayerKit: cleanNilValues,
      reservedPlayerKit: cleanNilValues,
    }),
    evolve({
      homePlayerKit: mergeIfInitial(defaultValues.homePlayerKit),
      awayPlayerKit: mergeIfInitial(defaultValues.awayPlayerKit),
      reservedPlayerKit: mergeIfInitial(defaultValues.reservedPlayerKit),
    }),
    evolve({
      homePlayerKit: dissoc('id'),
      awayPlayerKit: dissoc('id'),
      reservedPlayerKit: dissoc('id'),
    }),
  )(kitSet);
