import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, RadioGroup, Box, Divider } from '@mui/material';

import { FIELD_NAMES } from '../constants';
import useStyles from '../styles';
import { FormValues } from '../helpers/initialValues';
import RoundRobinAccordion from './round-robin-accordion';
import KnockOutAccordion from './knock-out-accordion';

const StageTypeFormatBlock = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const handleStageTypeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue(FIELD_NAMES.stageType, event?.target?.value);

  return (
    <>
      <Grid container item xs={12}>
        <Box mb={2} mt={1} flex={1}>
          <Divider orientation="horizontal" />
        </Box>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Teams & Format')}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              {t('Stage type *')}
            </Typography>
            <RadioGroup
              name={FIELD_NAMES.stageType}
              value={values.stageType}
              onChange={handleStageTypeChange}
            >
              <RoundRobinAccordion />
              <KnockOutAccordion />
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StageTypeFormatBlock;
