import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Grid, Box, Paper, useMediaQuery, Theme } from '@mui/material';
import { HomeOutlined as HomeOutlinedIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';
import background from '@core/assets/img/background2.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'safe center',
    minHeight: '100vh',
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  background: {
    minHeight: '100vh',
    width: '100%',
    background: `url('${background}') center/cover`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 712,
    width: 448,
    padding: theme.spacing(4, 8),
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      width: '100%',
      padding: theme.spacing(4, 8),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgb(0, 0, 0, 0.75)',
  },
}));

type Props = {
  children: React.ReactNode;
};

const AuthenticationLayout = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Grid className={clsx(classes.root, classes.background)}>
      <Grid
        className={classes.content}
        component={isSmallScreen ? 'div' : Paper}
      >
        <Box mb={3} className={classes.header}>
          <Link to={createURL(paths.login)}>
            <HomeOutlinedIcon
              color="primary"
              style={{ verticalAlign: 'middle' }}
            />
          </Link>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
