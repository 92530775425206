import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';
import { TeamModel } from '@core/types';

import { actions } from '.';

export type DefaultState = {
  [teamId: number]: TeamModel;
};

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setTeams.toString()]: (state, { payload }) => ({
      ...state,
      ...convertArrayToObject(payload),
    }),
  },
  defaultState,
);

export default reducer;
