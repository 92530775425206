import {
  all,
  call,
  cancel,
  cancelled,
  debounce,
  Effect,
  put,
  race,
  SagaReturnType,
  take,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { getVenues } from '@core/pages/venues/store/api/sagas';
import { formatPaginatedStoreWithIds, handleApiErrors } from '@core/helpers';

import actions from './actions';

export function* searchVenueFlow(
  action: InferAction<
    typeof actions.getVenues | typeof actions.searchVenuesByQuery
  >,
): Generator<Effect, any, any> {
  try {
    const { payload } = action;

    yield put(actions.getVenuesRequest());
    const { error, response } = (yield call(
      getVenues,
      payload || {},
    )) as SagaReturnType<typeof getVenues>;

    if (!error) {
      yield put(actions.getVenuesSuccess());
      yield put(
        actions.setVenues(
          formatPaginatedStoreWithIds('venues')(response) as any,
        ),
      );
    } else {
      yield put(actions.getVenuesFailure());
      yield call(handleApiErrors, error);
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(actions.getVenuesSuccess());
    }
  }
}

export function* getVenuesFlow() {
  while (true) {
    const action = (yield take(actions.getVenues)) as InferAction<
      typeof actions.getVenues
    >;
    yield race([call(searchVenueFlow, action), take(actions.resetState)]);
  }
}

export function* watchSearchVenuesByQuery() {
  while (true) {
    const task = (yield debounce(
      500,
      actions.searchVenuesByQuery.toString(),
      searchVenueFlow,
    )) as Task;
    yield take(actions.resetState);
    yield cancel(task);
  }
}

export default function* saga() {
  yield all([watchSearchVenuesByQuery(), getVenuesFlow()]);
}
