import { path } from 'ramda';

import { KitSet } from '@core/types';

import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getKitSet = (
  state: State,
  { kitSetId }: { kitSetId: number },
): KitSet => path(['tabs', NAMESPACE, kitSetId, 'kitSet'], state);
