export interface MatchOfficialSettings {
  id: number;
  roleId: number;
  groupId?: number;
}

export enum FIELD_NAMES {
  numberOfSets = 'numberOfSets',
  pointsToWinInRegularSet = 'pointsToWinInRegularSet',
  pointsToWinInTiebreakerSet = 'pointsToWinInTiebreakerSet',
  goldenSetRule = 'goldenSetRule',
  goldenMatchRule = 'goldenMatchRule',
  pointsToWinInGoldenSet = 'pointsToWinInGoldenSet',
  pointsToWinInGoldenMatchSet = 'pointsToWinInGoldenMatchSet',
  matchOfficialsSettings = 'matchOfficialsSettings',
}
