import { uuid } from '@core/helpers';
import { createAction } from 'redux-actions';
import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './constants';
import type { Snackbar } from '@core/types';
import type { SnackbarKey } from 'notistack';

export const enqueueSnackbar = createAction(
  ENQUEUE_SNACKBAR,
  (snackbar: Snackbar) => ({
    key: uuid(),
    ...snackbar,
  }),
);

export const removeSnackbar = createAction(
  REMOVE_SNACKBAR,
  (key: SnackbarKey) => key,
);
