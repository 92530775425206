import { createActions } from 'redux-actions';
import { pick } from 'ramda';
import {
  MatchStatus,
  MatchesListResponse,
  MatchModel,
  PatchMatchPayload,
  MatchesBulkActions,
} from '@core/types';
import { OnSuccessEffectParametersPayload } from '@core/store/helpers/get-match-mutated-successfully-callbacks';

export const NAMESPACE = 'match';

export const actions = createActions(
  {
    SET_GROUP_MATCHES: [
      (payload: MatchesListResponse) => payload,
      (_: MatchesListResponse, groupId: number) => ({ id: groupId }),
    ],
    SET_MATCH_BY_ID: [
      (payload: MatchModel) => payload,
      (_: MatchModel, matchId: number) => ({ id: matchId }),
    ],
    CLEAR_MATCHES_BY_GROUP: [undefined, pick(['id'])],
  },
  'GET_MATCHES_BY_GROUP',
  'GET_MATCHES_BY_GROUP_REQUEST',
  'GET_MATCHES_BY_GROUP_SUCCESS',
  'GET_MATCHES_BY_GROUP_FAILURE',

  'GET_MATCHES',
  'GET_MATCHES_REQUEST',
  'GET_MATCHES_SUCCESS',
  'GET_MATCHES_FAILURE',
  'SET_MATCHES',

  'REFRESH_MATCH',
  'REFRESH_MATCH_REQUEST',
  'REFRESH_MATCH_SUCCESS',
  'REFRESH_MATCH_FAILURE',

  'GET_MATCH',
  'GET_MATCH_REQUEST',
  'GET_MATCH_SUCCESS',
  'GET_MATCH_FAILURE',

  'CHANGE_MATCH_STATUS_IN_BULK',
  'CHANGE_MATCH_STATUS_IN_BULK_REQUEST',
  'CHANGE_MATCH_STATUS_IN_BULK_SUCCESS',
  'CHANGE_MATCH_STATUS_IN_BULK_FAILURE',

  'BULK_DELETE',
  'BULK_DELETE_REQUEST',
  'BULK_DELETE_SUCCESS',
  'BULK_DELETE_FAILURE',

  'CHANGE_MATCH_STATUS',
  'CHANGE_MATCH_STATUS_REQUEST',
  'CHANGE_MATCH_STATUS_SUCCESS',
  'CHANGE_MATCH_STATUS_FAILURE',

  'CHANGE_MATCH_ATTENDANCE',
  'CHANGE_MATCH_ATTENDANCE_REQUEST',
  'CHANGE_MATCH_ATTENDANCE_SUCCESS',
  'CHANGE_MATCH_ATTENDANCE_FAILURE',

  'CHANGE_MATCH_PROPERTIES',
  'CHANGE_MATCH_PROPERTIES_REQUEST',
  'CHANGE_MATCH_PROPERTIES_SUCCESS',
  'CHANGE_MATCH_PROPERTIES_FAILURE',

  {
    prefix: NAMESPACE,
  },
) as unknown as {
  clearMatchesByGroup: UnknownAction;

  /** @deprecated not dispached anywhere */
  getMatchesByGroup: IdentityActionFunction<{
    id: number;
    query: URLSearchParams;
  }>;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesByGroupRequest: RequestLifecycleAction<{ id: number }>;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesByGroupSuccess: RequestLifecycleAction<{ id: number }>;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesByGroupFailure: RequestLifecycleAction<{ id: number }>;
  /** @deprecated action initializing sage is not dispatched anywhere */
  setGroupMatches: IdentityActionMetaFunction1<
    [payload: MatchesListResponse, groupId: number],
    MatchesListResponse,
    { id: number }
  >;

  /** @deprecated not dispached anywhere */
  getMatches: IdentityActionFunction<URLSearchParams>;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesRequest: RequestLifecycleAction;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesSuccess: RequestLifecycleAction;
  /** @deprecated action initializing sage is not dispatched anywhere */
  getMatchesFailure: RequestLifecycleAction;
  /** @deprecated action initializing sage is not dispatched anywhere */
  setMatches: IdentityActionFunction<MatchesListResponse>;

  /** @deprecated action is not listened for anywhere */
  refreshMatch: UnknownAction;
  /** @deprecated not used anywhere */
  refreshMatchRequest: RequestLifecycleAction;
  /** @deprecated not used anywhere */
  refreshMatchSuccess: RequestLifecycleAction;
  /** @deprecated not used anywhere */
  refreshMatchFailure: RequestLifecycleAction;

  /** @deprecated probably use one from match-profile since it has hal links */
  getMatch: IdentityActionFunction<number>;
  /** @deprecated probably use one from match-profile since it has hal links */
  getMatchRequest: RequestLifecycleAction<{ id: number }>;
  /** @deprecated probably use one from match-profile since it has hal links */
  getMatchSuccess: RequestLifecycleAction<{ id: number }>;
  /** @deprecated probably use one from match-profile since it has hal links */
  getMatchFailure: RequestLifecycleAction<{ id: number }>;
  /** @deprecated probably use one from match-profile since it has hal links */
  setMatchById: IdentityActionMetaFunction1<
    [payload: MatchModel, matchId: number],
    MatchModel,
    { id: number }
  >;

  changeMatchStatusInBulk: IdentityActionFunction<
    {
      competitionId: number;
      stageId: number;
      matchIds: Array<number>;
      onSuccessCallback: () => void;
      status: MatchesBulkActions;
    } & OnSuccessEffectParametersPayload
  >;
  changeMatchStatusInBulkRequest: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;
  changeMatchStatusInBulkSuccess: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;
  changeMatchStatusInBulkFailure: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;

  bulkDelete: IdentityActionFunction<
    {
      competitionId: number;
      stageId: number;
      matchIds: Array<number>;
      onSuccessCallback: () => void;
    } & OnSuccessEffectParametersPayload
  >;
  bulkDeleteRequest: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;
  bulkDeleteSuccess: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;
  bulkDeleteFailure: RequestLifecycleAction<{
    competitionId: number;
    stageId: number;
  }>;

  changeMatchStatus: IdentityActionFunction<
    {
      matchId: number;
      status: MatchStatus;
      reasonDescription: string;
      competitionId: number;
    } & OnSuccessEffectParametersPayload
  >;
  changeMatchStatusRequest: RequestLifecycleAction;
  changeMatchStatusSuccess: RequestLifecycleAction;
  changeMatchStatusFailure: RequestLifecycleAction;

  /** @deprecated action dispached in unreachable code; probably will unintentionally uninitialize some properties on match */
  changeMatchAttendance: UnknownAction;
  /** @deprecated action initializing sage is dispached in unreachable code; probably will unintentionally uninitialize some properties on match */
  changeMatchAttendanceRequest: RequestLifecycleAction;
  /** @deprecated action initializing sage is dispached in unreachable code; probably will unintentionally uninitialize some properties on match */
  changeMatchAttendanceSuccess: RequestLifecycleAction;
  /** @deprecated action initializing sage is dispached in unreachable code; probably will unintentionally uninitialize some properties on match */
  changeMatchAttendanceFailure: RequestLifecycleAction;

  changeMatchProperties: IdentityActionFunction<
    {
      match: MatchModel;
      data: PatchMatchPayload;
    } & OnSuccessEffectParametersPayload
  >;
  changeMatchPropertiesRequest: RequestLifecycleAction<{ id: number }>;
  changeMatchPropertiesSuccess: RequestLifecycleAction<{ id: number }>;
  changeMatchPropertiesFailure: RequestLifecycleAction<{ id: number }>;
};
