import { handleActions } from 'redux-actions';
import { formatPaginatedStore } from '@core/helpers';
import { actions } from '.';
import type {
  AssignmentsUsersOriginType,
  CompetitionResponse,
  Paginated,
  UsersResponse,
} from '@core/types';
import { AssignmentsUsersOrigin } from '@core/constants';

type Users = Omit<UsersResponse, 'header'>;

export interface DefaultState {
  assignmentsPageUsers: Users;
  competitionContactsUsers: Users;
  competitionsById: Record<string, CompetitionResponse[]>;
  personsById: any;
  allCompetitions: CompetitionResponse[];
  allCompetitionsPagination: Omit<Paginated<never>, 'list'>;
  assignedCompetitionsPagination: Omit<Paginated<never>, 'list'>;
}

export const defaultState: DefaultState = {
  assignmentsPageUsers: {
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    _embedded: {
      users: [],
    },
    _links: {
      first: {
        href: '',
      },
      last: {
        href: '',
      },
      self: {
        href: '',
      },
    },
  },
  competitionContactsUsers: {
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    _embedded: {
      users: [],
    },
    _links: {
      first: {
        href: '',
      },
      last: {
        href: '',
      },
      self: {
        href: '',
      },
    },
  },
  competitionsById: {},
  personsById: {},
  allCompetitions: null,
  allCompetitionsPagination: null,
  assignedCompetitionsPagination: null,
};

const reducer = handleActions<
  DefaultState,
  any,
  Record<'origin', AssignmentsUsersOriginType>
>(
  {
    [actions.setUsers.toString()]: (state, { payload, meta }) => ({
      ...state,
      [meta.origin === AssignmentsUsersOrigin.ASSIGNMENTS
        ? 'assignmentsPageUsers'
        : 'competitionContactsUsers']: payload,
    }),
    [actions.setAllCompetitionsPagination.toString()]: (
      state,
      { payload },
    ) => ({
      ...state,
      allCompetitionsPagination: payload,
    }),
    [actions.setAllCompetitions.toString()]: (state, { payload }) => ({
      ...state,
      allCompetitions: payload,
    }),
    [actions.setAssignedCompetitionsPagination.toString()]: (
      state,
      { payload },
    ) => ({
      ...state,
      assignedCompetitionsPagination: payload,
    }),
    [actions.setCompetitionsById.toString()]: (state, { payload }) => ({
      ...state,
      competitionsById: {
        ...state.competitionsById,
        [payload.id]: payload.competitions,
      },
    }),
    [actions.setCompetitionAssignedPersons.toString()]: (
      state,
      { payload },
    ) => ({
      ...state,
      personsById: {
        ...state.personsById,
        [payload.id]: formatPaginatedStore('regionalCompetitionManagers')(
          payload.persons,
        ),
      },
    }),
    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
