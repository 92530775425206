import { createActions } from 'redux-actions';

export const NAMESPACE = 'matchTeamOfficials';

export const actions = createActions(
  'GET_MATCH_TEAM_OFFICIALS_REQUEST',
  'GET_MATCH_TEAM_OFFICIALS_SUCCESS',
  'GET_MATCH_TEAM_OFFICIALS_FAILURE',

  'GET_PREVIOUS_MATCH_TEAM_OFFICIALS_REQUEST',
  'GET_PREVIOUS_MATCH_TEAM_OFFICIALS_SUCCESS',
  'GET_PREVIOUS_MATCH_TEAM_OFFICIALS_FAILURE',

  'CHANGE_MATCH_TEAM_OFFICIALS_REQUEST',
  'CHANGE_MATCH_TEAM_OFFICIALS_SUCCESS',
  'CHANGE_MATCH_TEAM_OFFICIALS_FAILURE',
  { prefix: NAMESPACE },
) as unknown as {
  getMatchTeamOfficialsRequest: RequestLifecycleAction;
  getMatchTeamOfficialsSuccess: RequestLifecycleAction;
  getMatchTeamOfficialsFailure: RequestLifecycleAction;
  getPreviousMatchTeamOfficialsRequest: RequestLifecycleAction;
  getPreviousMatchTeamOfficialsSuccess: RequestLifecycleAction;
  getPreviousMatchTeamOfficialsFailure: RequestLifecycleAction;
  changeMatchTeamOfficialsRequest: RequestLifecycleAction;
  changeMatchTeamOfficialsSuccess: RequestLifecycleAction;
  changeMatchTeamOfficialsFailure: RequestLifecycleAction;
};
