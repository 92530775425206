export const FIXTURE_CALENDAR_ROOT = 'FIXTURE_CALENDAR_ROOT';
export const FIXTURE_CALENDAR = 'FIXTURE_CALENDAR';
export const FIXTURE_CALENDAR_DAY = 'FIXTURE_CALENDAR_DAY';
export const FIXTURE_CALENDAR_MATCH = 'FIXTURE_CALENDAR_MATCH';
export const FIXTURE_CALENDAR_MATCH_POPUP = 'FIXTURE_CALENDAR_MATCH_POPUP';
export const FIXTURE_CALENDAR_MATCH_LINK = 'FIXTURE_CALENDAR_MATCH_LINK';
export const FIXTURE_CALENDAR_MATCH_ACTION_BUTTON =
  'FIXTURE_CALENDAR_MATCH_ACTION_BUTTON';
export const FIXTURE_CALENDAR_LIST = 'FIXTURE_CALENDAR_LIST';
export const FIXTURE_CALENDAR_LIST_EMPTY = 'FIXTURE_CALENDAR_LIST_EMPTY';
export const FIXTURE_CALENDAR_LIST_ITEM_WRAPPER =
  'FIXTURE_CALENDAR_LIST_ITEM_WRAPPER';
export const FIXTURE_CALENDAR_LIST_ITEM = 'FIXTURE_CALENDAR_LIST_ITEM';
