import { ActionMeta, createActions } from 'redux-actions';
import { prop, pick } from 'ramda';
import {
  ChildGroupCompetitors,
  GroupCompetitor,
  GroupCompetitorsPayload,
} from '@core/types';

export const NAMESPACE = 'teamGrid';

export default createActions(
  {
    SET_COMPETITORS_BY_GROUP_ID: [prop('payload'), pick(['groupId'])],
    SET_COMPETITORS_BY_COMPETITION_ID: [
      prop('payload'),
      pick(['competitionId']),
    ],
  },
  'GET_COMPETITORS_BY_GROUP_ID',
  'GET_COMPETITORS_BY_GROUP_ID_REQUEST',
  'GET_COMPETITORS_BY_GROUP_ID_SUCCESS',
  'GET_COMPETITORS_BY_GROUP_ID_FAILURE',

  'GET_COMPETITORS_BY_COMPETITION_ID',
  'GET_COMPETITORS_BY_COMPETITION_ID_REQUEST',
  'GET_COMPETITORS_BY_COMPETITION_ID_SUCCESS',
  'GET_COMPETITORS_BY_COMPETITION_ID_FAILURE',

  'UPDATE_GROUP_COMPETITORS',
  'UPDATE_GROUP_COMPETITORS_REQUEST',
  'UPDATE_GROUP_COMPETITORS_SUCCESS',
  'UPDATE_GROUP_COMPETITORS_FAILURE',
  { prefix: NAMESPACE },
) as unknown as {
  getCompetitorsByGroupId: IdentityActionFunction<{ groupId: number }>;
  getCompetitorsByGroupIdRequest: RequestLifecycleAction<{ id: number }>;
  getCompetitorsByGroupIdSuccess: RequestLifecycleAction<{ id: number }>;
  getCompetitorsByGroupIdFailure: RequestLifecycleAction<{ id: number }>;
  setCompetitorsByGroupId: (payload: {
    payload: Array<GroupCompetitor> | ChildGroupCompetitors;
    groupId: number;
  }) => ActionMeta<
    { payload: Array<GroupCompetitor> | ChildGroupCompetitors },
    { groupId: number }
  >;

  getCompetitorsByCompetitionId: IdentityActionFunction<{
    competitionId: number;
    limit: number;
    query?: string;
  }>;
  getCompetitorsByCompetitionIdRequest: RequestLifecycleAction;
  getCompetitorsByCompetitionIdSuccess: RequestLifecycleAction;
  getCompetitorsByCompetitionIdFailure: RequestLifecycleAction;
  setCompetitorsByCompetitionId: (payload: {
    payload: Array<GroupCompetitor['id']>;
    competitionId: number;
  }) => ActionMeta<
    { payload: Array<GroupCompetitor['id']> },
    { competitionId: number }
  >;

  updateGroupCompetitors: IdentityActionFunction<{
    groupId: number;
    groupCompetitors: GroupCompetitorsPayload;
    tabId: string;
    competitionId: number;
  }>;
  updateGroupCompetitorsRequest: RequestLifecycleAction;
  updateGroupCompetitorsSuccess: RequestLifecycleAction;
  updateGroupCompetitorsFailure: RequestLifecycleAction;
};
