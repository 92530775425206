import { handleActions } from 'redux-actions';
import { assocPath } from 'ramda';

import { AvailabilitiesList } from '@volleyball/types';

import { SET_AVAILABILITIES, SET_ERRORS, CLEAR_ERRORS } from './constants';

export interface DefaultState {
  [groupId: string]: {
    availabilities: AvailabilitiesList;
  };
}

type Payload = {
  availabilities: AvailabilitiesList;
  groupId?: number;
  errors?: any;
};

const initialState: any = {
  errors: null,
};

export const defaultState: any = {};

const reducer = handleActions<DefaultState, Payload>(
  {
    [SET_AVAILABILITIES]: (state, { payload }) =>
      assocPath(
        ['byGroupId', payload?.groupId, 'availabilities'],
        payload?.availabilities,
        state,
      ),
    [SET_ERRORS]: (state, { payload }) =>
      assocPath(
        ['byGroupId', payload?.groupId, 'errors'],
        payload?.errors,
        state,
      ),
    [CLEAR_ERRORS]: (state, { payload }) =>
      assocPath(
        ['byGroupId', payload?.groupId, 'errors'],
        initialState.errors,
        state,
      ),
  },
  defaultState,
);

export default reducer;
