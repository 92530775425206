// @ts-nocheck
import { memo, ComponentType } from 'react';
import { Typography, Grid, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 'auto',
  },
  footer: {
    padding: theme.spacing(2),
  },
  logo: {
    padding: theme.spacing(2),
  },
  text: {
    color: theme.palette.grey[600],
    padding: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  copyright: string;
  links: JSX.Element | ComponentType<any>;
  icon: JSX.Element | ComponentType<any>;
}

const LayoutTemplate = ({ copyright, links, icon }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.footer}>
        <Divider className={classes.divider} />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.text} variant="body2">
              {copyright}
            </Typography>
          </Grid>
          <Grid item>{links}</Grid>
          <Grid item className={classes.logo}>
            {icon}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Layout = memo(LayoutTemplate);
