import { createAction } from 'redux-actions';

import { Paginated, QueryParamsByCompetition, TeamModel } from '@core/types';

import { NAMESPACE } from './constants';

const setTeams = createAction<Paginated<TeamModel>>(`${NAMESPACE}/SET_TEAMS`);
const getTeams = createAction<QueryParamsByCompetition | void>(
  `${NAMESPACE}/GET_TEAMS`,
);
const getTeamsRequest = createAction<void>(`${NAMESPACE}/GET_TEAMS_REQUEST`);
const getTeamsSuccess = createAction<void>(`${NAMESPACE}/GET_TEAMS_SUCCESS`);
const getTeamsFailure = createAction<void>(`${NAMESPACE}/GET_TEAMS_FAILURE`);
const searchTeamsByQuery = createAction<QueryParamsByCompetition>(
  `${NAMESPACE}/SEARCH_TEAMS_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setTeams,
  getTeams,
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsFailure,
  resetState,
  searchTeamsByQuery,
};
