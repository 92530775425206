import { createAction } from 'redux-actions';

import { GroupStandingsPointAjustmentRequest } from '@volleyball/types';

import * as ACTION_TYPES from './constants';

export const setGroupStandings = createAction<{
  groupId: number;
  payload: any;
}>(ACTION_TYPES.SET_GROUP_STANDINGS);

export const setGroupStandingsHeader = createAction<{
  groupId: number;
  payload: any;
}>(ACTION_TYPES.SET_GROUP_STANDINGS_HEADER);

export const updateGroupStandingsCompetitorPointAdjustment = createAction<{
  stageId: number;
  groupId: number;
  competitorId: number;
  data: GroupStandingsPointAjustmentRequest;
}>(ACTION_TYPES.UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT);
export const updateGroupStandingsCompetitorPointAdjustmentRequest =
  createAction(
    ACTION_TYPES.UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_REQUEST,
  );
export const updateGroupStandingsCompetitorPointAdjustmentSuccess =
  createAction(
    ACTION_TYPES.UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_SUCCESS,
  );
export const updateGroupStandingsCompetitorPointAdjustmentFailure =
  createAction(
    ACTION_TYPES.UPDATE_GROUP_STANDINGS_COMPETITOR_POINT_ADJUSTMENT_FAILURE,
  );

export const getGroupStandings = createAction(ACTION_TYPES.GET_GROUP_STANDINGS);
export const getGroupStandingsRequest = createAction(
  ACTION_TYPES.GET_GROUP_STANDINGS_REQUEST,
);
export const getGroupStandingsSuccess = createAction(
  ACTION_TYPES.GET_GROUP_STANDINGS_SUCCESS,
);
export const getGroupStandingsFailure = createAction(
  ACTION_TYPES.GET_GROUP_STANDINGS_FAILURE,
);
