import { take, all, call, put, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as availabilityAPI from '@core/api/availability';
import { CreateAvailabilityData, AvailabilityObject } from '@volleyball/types';

import * as ACTION_TYPES from './constants';
import * as availabilityActions from './actions';

export function* getAvailabilities(
  object: AvailabilityObject,
  id: number,
): Generator<Effect, any, any> {
  yield put(availabilityActions.getAvailabilitiesRequest());
  const response = yield call(availabilityAPI.getAvailabilities, object, id);

  if (!response.error) {
    yield put(availabilityActions.getAvailabilitiesSuccess());
  } else {
    yield put(availabilityActions.getAvailabilitiesFailure());
  }

  return response;
}

export function* getAvailabilitiesFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.GET_AVAILABILITIES);
    const { error, response } = yield call(
      getAvailabilities,
      payload.object,
      payload.id,
    );

    if (!error) {
      const availabilities = response._embedded.availabilities;

      yield put(
        availabilityActions.setAvailabilities({
          groupId: payload.id,
          availabilities,
        }),
      );
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export function* createAvailability(
  object: AvailabilityObject,
  id: number,
  availabilityData: CreateAvailabilityData,
): Generator<Effect, any, any> {
  yield put(availabilityActions.createAvailabilityRequest());
  const response = yield call(
    availabilityAPI.createAvailability,
    object,
    id,
    availabilityData,
  );

  if (!response.error) {
    yield put(availabilityActions.createAvailabilitySuccess());
  } else {
    yield put(availabilityActions.createAvailabilityFailure());
  }

  return response;
}

export function* createAvailabilityFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.CREATE_AVAILABILITY);

    yield put(availabilityActions.clearErrors({ groupId: payload.id }));
    const { error } = yield call(
      createAvailability,
      payload.object,
      payload.id,
      payload.data,
    );

    if (!error) {
      yield put(
        availabilityActions.getAvailabilities({
          object: payload.object,
          id: payload.id,
        }),
      );
    } else {
      yield put(
        availabilityActions.setErrors({
          errors: error.errors,
          groupId: payload.id,
        }),
      );
    }
  }
}

export function* deleteAvailability(
  object: AvailabilityObject,
  id: number,
): Generator<Effect, any, any> {
  yield put(availabilityActions.deleteAvailabilityRequest());
  const response = yield call(availabilityAPI.deleteAvailability, object, id);

  if (!response.error) {
    yield put(availabilityActions.deleteAvailabilitySuccess());
  } else {
    yield put(availabilityActions.deleteAvailabilityFailure());
  }

  return response;
}

export function* deleteAvailabilityFlow() {
  while (true) {
    const { payload } = yield take(ACTION_TYPES.DELETE_AVAILABILITY);
    const { error } = yield call(
      deleteAvailability,
      payload.object,
      payload.availabilityId,
    );

    if (!error) {
      yield put(
        availabilityActions.getAvailabilities({
          object: payload.object,
          id: payload.objectId,
        }),
      );
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    getAvailabilitiesFlow(),
    createAvailabilityFlow(),
    deleteAvailabilityFlow(),
  ]);
}
