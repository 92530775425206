import React from 'react';
import { Box, Divider, Typography, Grid, Button } from '@mui/material';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { NavigateBefore } from '@mui/icons-material';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { SUBMIT_BUTTON } from '@core/constants/test-ids';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  isSubmitting: boolean;
  onPreviousClick?: () => void;
  children?: any;
  title?: string;
  isValid?: boolean;
  tabId?: string;
}

type DispatchProps = {
  actions: {
    tab: typeof tabActions;
  };
};

type Props = OwnProps & DispatchProps;

export const FormWithSubmit = ({
  children,
  title,
  isSubmitting,
  tabId,
  actions,
  onPreviousClick,
}: Props) => {
  const { t } = useTranslation();

  function handleCancel() {
    actions.tab.removeTab({ tabId });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Grid container direction="column">
          <Form noValidate>
            <Grid item xs={12}>
              {title && (
                <Box mb={5}>
                  <Typography variant="h6">{title}</Typography>
                </Box>
              )}
              <Box mb={5}>
                <Grid container spacing={2}>
                  {children}
                </Grid>
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                {tabId && (
                  <Grid item>
                    <Button onClick={handleCancel}>{t('Cancel')}</Button>
                  </Grid>
                )}
                <Grid container item xs justifyContent="flex-end" spacing={2}>
                  {onPreviousClick && (
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={onPreviousClick}
                        startIcon={<NavigateBefore />}
                      >
                        {t('Previous')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <ButtonWithProgress
                      isLoading={isSubmitting}
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      data-qa={SUBMIT_BUTTON}
                    >
                      {t('Submit')}
                    </ButtonWithProgress>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tab: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(FormWithSubmit);
