import { useEffect, useState } from 'react';
import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps, Form } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { propEq, find } from 'ramda';
import dayjs from 'dayjs';

import {
  SummaryBlock,
  SummaryBlockValues,
  AutocompleteField,
} from '@core/components';
import { formatOptions, getVenue } from '@core/helpers';
import { DATE_FORMAT } from '@core/constants/common';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as membersActions } from '@core/pages/persons/store/actions';
import { actions as matchOfficialsActions } from '@core/store/modules/match-officials';
import {
  getMatchOfficialsList,
  getMatchOfficialConflicts,
} from '@core/store/modules/match-officials/selectors';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionActions } from '@core/store/modules/tabs/competition-profile';
import { actions as matchesAllocationActions } from '@core/store/modules/matches-allocation';
import { actions as matchOfficialAllocationsActions } from '@core/store/modules/match-official-allocations';
import {
  Competition,
  MatchOfficialsList,
  StageType,
  MatchModel,
  MatchOfficialAllocationModel,
  GlobalModalProps,
} from '@core/types';
import { State } from '@core/store';

import MatchOfficialValidation from './match-official-validation';
import RefereeValue from './referee-value';
import RefereesResults from './referees-results';
import { ALLOCATION_MODAL_ALL_GROUPS_CHECKBOX } from '../../tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    competition: typeof competitionActions;
    matchOfficials: typeof matchOfficialsActions;
    matchOfficialAllocations: typeof matchOfficialAllocationsActions;
    members: typeof membersActions;
  };
}

interface StateProps {
  matchOfficials: MatchOfficialsList;
  matchOfficialConflicts: Array<MatchModel>;
  competition: Competition;
  isSubmitLoading: boolean;
  isOfficialsLoading: boolean;
  isValidationLoading: boolean;
}

export interface OwnProps {
  match: MatchModel;
  roleId: number;
  stageId: number;
  stageType: StageType;
  selectedOfficial: MatchOfficialAllocationModel;
}

type Props = StateProps & DispatchProps & GlobalModalProps<OwnProps>;

const MatchOfficialAllocationModal = (props: Props) => {
  const {
    competition,
    matchOfficials,
    matchOfficialConflicts,
    actions,
    isSubmitLoading,
    isOfficialsLoading,
    isValidationLoading,
    modalActions,
    isModalOpen,
    modalParams,
  } = props;
  const { match, roleId, stageId, stageType } = modalParams ?? {};

  const { t } = useTranslation();
  const [selectedReferee, setSelectedReferee] = useState(null);
  const [showAllGroups, setShowAllGroups] = useState(false);

  const matchOfficialRole = modalParams?.selectedOfficial?.matchOfficialRole;
  const matchOfficialGroupId =
    modalParams?.selectedOfficial?.matchOfficialGroup?.id;

  const matchOfficialGroupFilter = [
    'filter[matchOfficialGroup]',
    String(matchOfficialGroupId),
  ];

  const defaultFilter: Array<Array<string>> = [
    ['filter[roleType]', matchOfficialRole?.roleType],
    ['filter[sport][]', String(competition?.sportId)],
  ];

  if (!showAllGroups && matchOfficialGroupId) {
    defaultFilter.push(matchOfficialGroupFilter);
  }

  useEffect(() => {
    if (matchOfficialRole.roleType) {
      actions.members.getMatchOfficialsRoles();
    }
  }, [matchOfficialRole.roleType]);

  useEffect(() => {
    !competition &&
      actions.competition.getCompetitionDetails(match?.competitionId);
  }, []);

  useEffect(() => {
    actions.matchOfficials.getMatchOfficials({
      queryParams: new URLSearchParams([...defaultFilter]),
    });
  }, [showAllGroups]);

  const handleDialogClose = () => {
    if (isSubmitLoading) {
      return;
    }
    modalActions.closeModal();
    actions.matchOfficials.resetMatchOfficialConflicts();
  };

  const onInputChange = (query: string) => {
    actions.matchOfficials.resetMatchOfficialConflicts();
    actions.matchOfficials.getMatchOfficials({
      queryParams: new URLSearchParams([['query', query], ...defaultFilter]),
    });
  };

  const onRefereeSelect = (refereeId: any) => {
    actions.matchOfficials.resetMatchOfficialConflicts();
    if (refereeId) {
      const internalFilter = [
        [
          'filter[date]',
          dayjs(`${match.date} ${match.time}`).format(DATE_FORMAT),
        ],
      ];
      const dateFilter = new URLSearchParams([...internalFilter]);
      actions.matchOfficials.validateMatchOfficial({
        id: refereeId,
        dateFilter,
      });
    }
    setSelectedReferee(find(propEq('id', refereeId as number), matchOfficials));
  };

  const handleSubmit = () => {
    actions.matchOfficials.resetMatchOfficialConflicts();
    actions.matchOfficialAllocations.appointMatchOfficial({
      matchId: match.id,
      stageId,
      data: {
        matchOfficialRoleId: roleId,
        matchOfficialId: selectedReferee.id,
      },
    });
  };

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Match official'),
      value: t(matchOfficialRole?.title),
    },
  ];

  const getMatchInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: match?.competitionTitle,
      fullWidth: true,
    },
    {
      label: t('Season'),
      value: match?.seasonTitle,
      halfWidth: true,
    },
    {
      label: t('Stage'),
      value: match?.parentGroupTitle,
      halfWidth: true,
    },
    {
      label: t('Match No.'),
      value: match?.number,
    },
    {
      label: stageType === StageType.ROUND_ROBIN ? t('Pool') : t('Round'),
      value: match?.groupTitle,
    },
    {
      label: t('Matchday'),
      value: match?.matchDayNumber,
    },
    {
      label: t('Date, time'),
      value: `${match?.date} ${match?.time}`,
      dateField: true,
      fullWidth: true,
    },
    {
      label: t('Venue'),
      value: getVenue(match?.venue)?.title,
      fullWidth: true,
    },
  ];

  const renderForm = (_: FormikProps<any>) => (
    <Form>
      <DialogTitle>{t('Appoint match official')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item container md={8} alignContent="flex-start">
            <Grid item xs={12}>
              <SummaryBlock values={getInfoBlocks()} />
            </Grid>
            <Grid item xs={12}>
              <Box width="100%" pt={3}>
                <AutocompleteField
                  name="officials"
                  textFieldProps={{ label: t('Select match official') }}
                  options={formatOptions(null, matchOfficials, 'firstName')}
                  withTooltipMargin={false}
                  onInputChange={onInputChange}
                  onChange={onRefereeSelect}
                  isLoading={isOfficialsLoading}
                  customComponents={{
                    Option: RefereesResults,
                    SingleValue: RefereeValue,
                  }}
                  menuPosition="fixed"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAllGroups}
                      onChange={() => setShowAllGroups(!showAllGroups)}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                        'data-qa': ALLOCATION_MODAL_ALL_GROUPS_CHECKBOX,
                      }}
                    />
                  }
                  label="Search in all groups"
                />
              </Box>
            </Grid>
            <MatchOfficialValidation
              matchOfficialConflicts={matchOfficialConflicts}
              isLoading={isValidationLoading}
            />
          </Grid>
          <Grid item container md={4}>
            <Grid>
              <SummaryBlock values={getMatchInfoBlocks()} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Box padding={2}>
        <DialogActions>
          <Box display="flex">
            <Box mr={1}>
              <Button
                onClick={handleDialogClose}
                variant="outlined"
                disabled={isSubmitLoading}
              >
                {t('Cancel')}
              </Button>
            </Box>
            <ButtonWithProgress
              type="submit"
              variant="contained"
              isLoading={isSubmitLoading}
              disabled={!selectedReferee || isSubmitLoading}
            >
              {t('Appoint')}
            </ButtonWithProgress>
          </Box>
        </DialogActions>
      </Box>
    </Form>
  );

  return (
    <Dialog
      onClose={handleDialogClose}
      open={isModalOpen}
      maxWidth="md"
      fullWidth
    >
      <Formik enableReinitialize initialValues={{}} onSubmit={handleSubmit}>
        {renderForm}
      </Formik>
    </Dialog>
  );
};

const isSubmitLoadingSelector = (ownProps: GlobalModalProps<OwnProps>) =>
  createLoadingSelector([
    matchOfficialAllocationsActions.appointMatchOfficialRequest({
      matchId: ownProps.modalParams.match.id,
    }),
    matchesAllocationActions.refreshMatchesAllocationRequest({
      stageId: ownProps.modalParams.stageId,
    }),
  ]);
const isLoadingOfficialsSelector = createLoadingSelector([
  competitionActions.getCompetitionDetailsRequest.toString(),
  matchOfficialsActions.getMatchOfficialsRequest.toString(),
]);
const isLoadingValidationSelector = createLoadingSelector([
  matchOfficialsActions.validateMatchOfficialRequest(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competition: bindActionCreators(competitionActions, dispatch),
    matchOfficials: bindActionCreators(matchOfficialsActions, dispatch),
    matchOfficialAllocations: bindActionCreators(
      matchOfficialAllocationsActions,
      dispatch,
    ),
    members: bindActionCreators(membersActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  ownProps: GlobalModalProps<OwnProps>,
): StateProps => {
  const modalParams = ownProps?.modalParams;
  const competitionId: number = modalParams?.match?.competitionId;

  return {
    matchOfficials: getMatchOfficialsList(state),
    matchOfficialConflicts: getMatchOfficialConflicts(state),
    competition: getCompetitionDetailsById(competitionId)(state),
    isSubmitLoading: isSubmitLoadingSelector(ownProps)(state),
    isOfficialsLoading: isLoadingOfficialsSelector(state),
    isValidationLoading: isLoadingValidationSelector(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchOfficialAllocationModal);
