import React, { useEffect } from 'react';
import { Box, Button, Paper, Divider, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { withLayout } from '@core/components/layout';
import { ExpansionPanelGroupId, LayoutProps } from '@core/types';
import * as competitionsActions from '@core/pages/competitions/store/actions';
import { getCompetitionBeingModified } from '@core/pages/competitions/store/selectors';
import competitionCreationActions from '@core/store/modules/shared/competition-creation';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { Competition } from '@volleyball/types';
import DisciplineSettingsTable from '@volleyball/business-components/discipline-settings-table';
import { State } from '@volleyball/store';
import { ActionsButtons } from '@ui-components';

interface StateProps {
  competitionBeingModified?: Competition;
}

interface DispatchProps {
  actions: {
    competitions: typeof competitionsActions;
    competitionCreation: typeof competitionCreationActions;
    tabActions: typeof tabActions;
  };
}

interface OwnProps {
  expansionPanelGroupId: ExpansionPanelGroupId;
  expansionPanelIndex: number;
  competitionId?: number;
  tabId?: string;
}

type Props = OwnProps & DispatchProps & StateProps;

const DisciplineSettingsTab = (props: Props) => {
  const { t } = useTranslation();
  const { actions, competitionId, tabId, competitionBeingModified } = props;

  useEffect(() => {
    if (!competitionBeingModified && !!competitionId) {
      actions.competitions.getDraftCompetition(competitionId);
    }
  }, []);

  const getSummaryBlockValues = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: competitionBeingModified?.title,
    },
    {
      label: t('Season'),
      value: competitionBeingModified?.competitionSeasonTitle,
    },
  ];

  const actionButtons: React.ComponentProps<typeof ActionsButtons>['buttons'] =
    [
      () => (
        <Button variant="outlined" color="primary" onClick={handleClose}>
          {t('Cancel')}
        </Button>
      ),
      () => (
        <Button variant="contained" color="primary" onClick={handleClose}>
          {t('Save')}
        </Button>
      ),
    ];

  const handleClose = () => {
    if (tabId) {
      actions.competitionCreation.getDisciplinaryRules({ competitionId });
      actions.tabActions.removeTab({ tabId });
    }
  };

  return (
    <>
      <Paper>
        <Box paddingX={3} paddingY={3}>
          <Box mb={2}>
            <Typography variant="h6">
              {t('Edit discipline settings')}
            </Typography>
          </Box>
          <SummaryBlock values={getSummaryBlockValues()} />
        </Box>

        <Grid container item xs={12}>
          <Box mb={2} flex={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Grid>
        <Box p={3} pt={0} pb={0}>
          <DisciplineSettingsTable
            competitionId={
              competitionId || pathOr(null, ['id'], competitionBeingModified)
            }
          />
        </Box>
      </Paper>
      <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
        <ActionsButtons buttons={actionButtons} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitionBeingModified:
    getCompetitionDetailsById(ownProps.competitionId)(state) ||
    getCompetitionBeingModified(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabActions: bindActionCreators(tabActions, dispatch),
    competitions: bindActionCreators(competitionsActions, dispatch),
    competitionCreation: bindActionCreators(
      competitionCreationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisciplineSettingsTab);

export const DisciplineSettingsWithLayout = compose<
  React.FunctionComponent<LayoutProps>
>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(DisciplineSettingsTab);
