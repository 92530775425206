import {
  put,
  call,
  all,
  take,
  takeEvery,
  Effect,
  SagaReturnType,
} from 'redux-saga/effects';
import i18next from 'i18next';
import { handleApiErrors } from '@core/helpers';
import { enqueueSnackbar } from '@core/store/modules/ui/snackbar/actions';
import {
  changeMatchStatus,
  changeMatchStatusInBulk,
  bulkDelete,
  getGroupsMatches,
  getMatch,
  getMatches,
  patchMatch,
} from '@core/store/modules/api/match/sagas';
import { getCompetition } from '@core/pages/competitions/store/api/sagas';
import { handleBulkApiErrors } from '@core/store/helpers/handle-bulk-api-errors';
import { getMatchMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import { PatchMatchPayload } from '@core/types';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { api } from '@core/services';
import { actions } from '.';

/** @deprecated action listened for is not dispached anywhere */
export function* getGroupsMatchesFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.getMatchesByGroup)) as InferAction<
      typeof actions.getMatchesByGroup
    >;

    yield put(actions.getMatchesByGroupRequest({ id: payload.id }));

    const { error, response } = (yield call(
      getGroupsMatches,
      payload.id,
      payload.query,
    )) as SagaReturnType<typeof getGroupsMatches>;

    if (!error) {
      yield put(actions.getMatchesByGroupSuccess({ id: payload.id }));
      yield put(actions.setGroupMatches(response, payload.id));
    } else {
      yield put(actions.getMatchesByGroupFailure({ id: payload.id }));
      yield call(handleApiErrors, error);
    }
  }
}

/** @deprecated action listened for is not dispached anywhere */
export function* getMatchesFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = (yield take(actions.getMatches)) as InferAction<
      typeof actions.getMatches
    >;

    yield put(actions.getMatchesRequest());

    const { error, response } = (yield call(
      getMatches,
      payload,
    )) as SagaReturnType<typeof getMatches>;

    if (!error) {
      yield put(actions.getMatchesSuccess());
      yield put(actions.setMatches(response));
    } else {
      yield put(actions.getMatchesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getMatchFlow({
  payload,
}: InferAction<typeof actions.getMatch>): Generator<Effect, any, any> {
  yield put(actions.getMatchRequest({ id: payload }));
  const { error, response } = (yield call(getMatch, payload)) as SagaReturnType<
    typeof getMatch
  >;

  if (!error) {
    yield put(actions.getMatchSuccess({ id: payload }));
    yield put(actions.setMatchById(response, payload));
  } else {
    yield put(actions.getMatchFailure({ id: payload }));
    yield call(handleApiErrors, error);
  }
}

export function* getMatchFlowWatcher() {
  yield takeEvery(actions.getMatch, getMatchFlow);
}

export function* changeMatchStatusInBulkFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: {
        competitionId,
        stageId,
        matchIds,
        status,
        onSuccessCallback,
        onSuccessEffectParameters,
      },
    } = (yield take(actions.changeMatchStatusInBulk)) as InferAction<
      typeof actions.changeMatchStatusInBulk
    >;

    yield put(
      actions.changeMatchStatusInBulkRequest({ competitionId, stageId }),
    );
    const response = (yield call(changeMatchStatusInBulk, {
      matchIds,
      status,
    })) as SagaReturnType<typeof changeMatchStatusInBulk>;

    if (!response.response && !response.error) {
      yield call(getCompetition, competitionId);
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield call(onSuccessCallback);
      yield put(
        actions.changeMatchStatusInBulkSuccess({ competitionId, stageId }),
      );
      yield put(
        enqueueSnackbar({
          message: i18next.t('All match statuses changed successfully'),
          options: {
            variant: 'success',
          },
        }),
      );
      yield put(api.util.invalidateTags(['Brackets']));
      yield put(competitionProfileActions.getStageDetails({ competitionId }));
    } else if (response.response) {
      const errors = response.response.errors;

      yield put(
        enqueueSnackbar({
          message: i18next.t('{{x}} out of {{y}} match status change failed', {
            replace: { x: errors.length, y: matchIds.length },
          }),
          options: { variant: 'warning' },
        }),
      );
      yield call(handleBulkApiErrors, errors);

      yield put(
        actions.changeMatchStatusInBulkFailure({ competitionId, stageId }),
      );
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield put(api.util.invalidateTags(['Brackets']));
      yield put(competitionProfileActions.getStageDetails({ competitionId }));
    } else if (response.error) {
      yield put(
        actions.changeMatchStatusInBulkFailure({ competitionId, stageId }),
      );
      yield put(
        enqueueSnackbar({
          message: i18next.t('All match statuses change failed'),
          options: { variant: 'warning' },
        }),
      );
      yield call(handleBulkApiErrors, response.error.errors as any);
    }
  }
}

export function* bulkDeleteFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: {
        competitionId,
        stageId,
        matchIds,
        onSuccessCallback,
        onSuccessEffectParameters,
      },
    } = (yield take(actions.bulkDelete)) as InferAction<
      typeof actions.bulkDelete
    >;

    yield put(actions.bulkDeleteRequest({ competitionId, stageId }));
    const response = (yield call(bulkDelete, { matchIds })) as SagaReturnType<
      typeof bulkDelete
    >;

    if (!response.response && !response.error) {
      yield call(getCompetition, competitionId);
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield call(onSuccessCallback);
      yield put(actions.bulkDeleteSuccess({ competitionId, stageId }));
      yield put(
        enqueueSnackbar({
          message: i18next.t('All selected matches have been deleted'),
          options: {
            variant: 'success',
          },
        }),
      );
      yield put(api.util.invalidateTags(['Brackets']));
    } else if (response.response) {
      const errors = response.response.errors;

      yield put(
        enqueueSnackbar({
          message: i18next.t(
            '{{x}} out of {{y}} match status deletion failed',
            {
              replace: { x: errors.length, y: matchIds.length },
            },
          ),
          options: { variant: 'warning' },
        }),
      );
      yield call(handleBulkApiErrors, errors);

      yield put(actions.bulkDeleteFailure({ competitionId, stageId }));
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield put(api.util.invalidateTags(['Brackets']));
    } else if (response.error) {
      yield put(actions.bulkDeleteFailure({ competitionId, stageId }));
      yield put(
        enqueueSnackbar({
          message: i18next.t('Matches deletion failed'),
          options: { variant: 'warning' },
        }),
      );
      yield call(handleBulkApiErrors, response.error.errors as any);
    }
  }
}

export function* changeMatchStatusFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { onSuccessEffectParameters, competitionId, ...payload },
    } = (yield take(actions.changeMatchStatus)) as InferAction<
      typeof actions.changeMatchStatus
    >;

    yield put(actions.changeMatchStatusRequest());
    const response = (yield call(changeMatchStatus, payload)) as SagaReturnType<
      typeof changeMatchStatus
    >;

    if (!response.error) {
      yield put(actions.changeMatchStatusSuccess());
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      // TODO: JB: why would match competitors change when updating status?
      yield put(matchProfileActions.getMatchCompetitors(payload.matchId));
      yield put(api.util.invalidateTags(['Brackets']));
      yield put(
        competitionProfileActions.getStageDetails({
          competitionId,
        }),
      );
    } else {
      yield put(actions.changeMatchStatusFailure());
      yield call(handleApiErrors, response.error);
    }
  }
}

/** @deprecated action initializing sage is dispached in unreachable code */
export function* changeMatchAttendanceFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.changeMatchAttendance);

    yield put(actions.changeMatchAttendanceRequest());
    const response = yield call(patchMatch, payload.matchId, payload.data);

    if (!response.error) {
      yield put(actions.changeMatchAttendanceSuccess());
      yield put(actions.getMatch(payload.matchId));
    } else {
      yield put(actions.changeMatchAttendanceFailure());
    }
  }
}

export function* changeMatchPropertiesWorker({
  payload,
}: InferAction<typeof actions.changeMatchProperties>): Generator<
  Effect,
  any,
  any
> {
  const { data, match, onSuccessEffectParameters } = payload;

  const matchId = match.id;
  const dataNew: PatchMatchPayload = {
    actualEndTime: data.actualEndTime ?? match.actualEndTime,
    actualStartTime: data.actualStartTime ?? match.actualStartTime,
    attendance: data.attendance ?? match.attendance,
    date: data.date ?? match.date,
    matchDayNumber: data.matchDayNumber ?? match.matchDayNumber,
    number: data.number ?? match.number,
    time: data.time ?? match.time,
    venueId: data.venueId ?? match.venue?.id,
  };

  yield put(actions.changeMatchPropertiesRequest({ id: matchId }));
  const response = (yield call(patchMatch, matchId, dataNew)) as SagaReturnType<
    typeof patchMatch
  >;

  if (!response.error) {
    yield put(actions.changeMatchPropertiesSuccess({ id: matchId }));
    yield call(
      getMatchMutatedSuccessfullyCallbacks,
      onSuccessEffectParameters,
      'update',
    );
  } else {
    yield put(actions.changeMatchPropertiesFailure({ id: matchId }));
  }

  return response;
}

export function* changeMatchPropertiesWatcher() {
  yield takeEvery(actions.changeMatchProperties, changeMatchPropertiesWorker);
}

export default function* saga() {
  yield all([
    getGroupsMatchesFlow(),
    getMatchesFlow(),
    getMatchFlowWatcher(),
    changeMatchStatusInBulkFlow(),
    bulkDeleteFlow(),
    changeMatchStatusFlow(),
    changeMatchAttendanceFlow(),
    changeMatchPropertiesWatcher(),
  ]);
}
