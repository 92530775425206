import { length } from 'ramda';

import { PlayerListErrorType } from '@core/types';

export const getPlayerListValidationErrors = (
  maxPlayers: number,
  minPlayers: number,
  earliestPlayerDateOfBirth: string,
  addedCompetitorPlayers: any,
) => {
  const validationErros = [];
  const addedPlayersCount = length(addedCompetitorPlayers);
  if (
    minPlayers &&
    maxPlayers &&
    (addedPlayersCount < minPlayers || addedPlayersCount > maxPlayers)
  )
    validationErros.push(PlayerListErrorType.TOTAL_PLAYERS);

  const overagedPlayers = addedCompetitorPlayers?.filter(
    (competitor: any) =>
      earliestPlayerDateOfBirth &&
      earliestPlayerDateOfBirth >=
        (competitor?.player?.dateOfBirth || competitor?.dateOfBirth),
  );
  if (overagedPlayers?.length > 0) {
    validationErros.push(PlayerListErrorType.AGE_VALIDATION);
  }

  return validationErros;
};
