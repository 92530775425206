import React from 'react';
import { Grid, Button, Box, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Filters } from '@core/types';
import { FILTERS_APPLY_BUTTON } from './tests/testIds';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type OwnProps = {
  onClose: (event: React.MouseEvent<EventTarget>) => void;
  onSave: (filters: Filters) => void;
  filters: Filters;
};

type Props = OwnProps;

const FilterActions = (props: Props) => {
  const { onClose, onSave, filters } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  function handleFiltersSave(event: React.MouseEvent<EventTarget>) {
    onSave(filters);
    onClose(event);
  }

  function handleClearAll(event: React.MouseEvent<EventTarget>) {
    onSave({});
    onClose(event);
  }

  return (
    <Grid className={classes.root}>
      <Button onClick={handleClearAll}>{t('Clear all')}</Button>
      <Box display="flex">
        <Box mr={1}>
          <Button variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={handleFiltersSave}
          data-qa={FILTERS_APPLY_BUTTON}
        >
          {t('Apply')}
        </Button>
      </Box>
    </Grid>
  );
};

export default FilterActions;
