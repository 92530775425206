import { createActions } from 'redux-actions';

export const NAMESPACE = 'currentCustomer';

export const actions = createActions(
  'GET_CUSTOMER_REQUEST',
  'GET_CUSTOMER_SUCCESS',
  'GET_CUSTOMER_FAILURE',

  'SET_CUSTOMER',

  { prefix: NAMESPACE },
);
