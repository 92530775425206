import { take, all, call, put } from 'redux-saga/effects';
import { enqueueSnackbar } from '@core/store/modules/ui/snackbar/actions';
import * as assignmentsApi from '@core/api/assignments';
import { actions } from '.';
import type { AssignmentsUsersOriginType } from '@core/types';

type GetUsersParams = {
  payload: {
    queryParams: URLSearchParams;
    origin: AssignmentsUsersOriginType;
  };
};

export function* getUsers() {
  while (true) {
    const {
      payload: { queryParams, origin },
    }: GetUsersParams = yield take(actions.getUsers);

    yield put(actions.getUsersRequest());

    const { error, response } = yield call(
      assignmentsApi.getUsers,
      queryParams,
    );

    if (!error) {
      yield put(actions.getUsersSuccess());
      yield put(actions.setUsers({ origin, usersResponse: response }));
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.getUsersFailure());
    }
  }
}

export function* getAssignedCompetitions() {
  while (true) {
    const {
      payload: { queryParams, personId },
    } = yield take(actions.getAssignedCompetitions);

    yield put(actions.getAssignedCompetitionsRequest());

    const { error, response } = yield call(
      assignmentsApi.getAssignedCompetitions,
      personId,
      queryParams,
    );

    if (!error) {
      const { page, pages, limit, total } = response;
      yield put(actions.getAssignedCompetitionsSuccess());
      yield put(
        actions.setAssignedCompetitionsPagination({
          page,
          pages,
          limit,
          total,
        }),
      );

      yield put(
        actions.setCompetitionsById({
          id: personId,
          competitions:
            response?._embedded?.regionalCompetitionManagerAssignments,
        }),
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.getAssignedCompetitionsFailure());
    }
  }
}

export function* getCompetitionAssignedPersons() {
  while (true) {
    const {
      payload: { queryParams, competitionId },
    } = yield take(actions.getCompetitionAssignedPersons);

    yield put(actions.getCompetitionAssignedPersonsRequest());

    const { error, response } = yield call(
      assignmentsApi.getCompetitionAssignedPersons,
      competitionId,
      queryParams,
    );

    if (!error) {
      yield put(actions.getCompetitionAssignedPersonsSuccess());
      yield put(
        actions.setCompetitionAssignedPersons({
          id: competitionId,
          persons: response,
        }),
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.getCompetitionAssignedPersonsFailure());
    }
  }
}

export function* getAllCompetitions() {
  while (true) {
    const {
      payload: { queryParams },
    } = yield take(actions.getAllCompetitions);

    yield put(actions.getAllCompetitionsRequest());

    const { error, response } = yield call(
      assignmentsApi.getAllCompetitions,
      queryParams,
    );

    if (!error) {
      const { page, pages, limit, total } = response;
      yield put(actions.getAllCompetitionsSuccess());
      yield put(
        actions.setAllCompetitionsPagination({ page, pages, limit, total }),
      );
      yield put(actions.setAllCompetitions(response?._embedded?.competitions));
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.getAllCompetitionsFailure());
    }
  }
}

export function* assignRegionalCompetitionManagerFlow() {
  while (true) {
    const {
      payload: { personId, competitionId, reloadCallback },
    } = yield take(actions.assignRegionalCompetitionManager);

    yield put(actions.assignRegionalCompetitionManagerRequest());

    const { error } = yield call(
      assignmentsApi.assignRegionalCompetitionManager,
      competitionId,
      personId,
    );

    if (!error) {
      reloadCallback?.();

      yield put(actions.assignRegionalCompetitionManagerSuccess());
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.assignRegionalCompetitionManagerFailure());
    }
  }
}

export function* unassignRegionalCompetitionManagerFlow() {
  while (true) {
    const {
      payload: { personId, competitionId, reloadCallback },
    } = yield take(actions.unassignRegionalCompetitionManager);

    yield put(actions.unassignRegionalCompetitionManagerRequest());

    const { error } = yield call(
      assignmentsApi.unassignRegionalCompetitionManager,
      competitionId,
      personId,
    );

    if (!error) {
      reloadCallback?.();

      yield put(actions.unassignRegionalCompetitionManagerSuccess());
    } else {
      yield put(
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        }),
      );
      yield put(actions.unassignRegionalCompetitionManagerFailure());
    }
  }
}

export default function* saga() {
  yield all([
    getUsers(),
    getAssignedCompetitions(),
    getAllCompetitions(),
    assignRegionalCompetitionManagerFlow(),
    unassignRegionalCompetitionManagerFlow(),
    getCompetitionAssignedPersons(),
  ]);
}
