import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Button, Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { PageFormActionBlock } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
  };
}

interface OwnProps {
  tabId: string;
  disabled: boolean;
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.only('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  fullWidth: {
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      margin: theme.spacing(2),
    },
  },
}));

const DurationListFormActions = ({ actions, tabId, disabled }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCancel = () => actions.tabs.removeTab({ tabId });

  return (
    <PageFormActionBlock>
      <Box
        component={Grid}
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        className={classes.root}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <Grid item>
          <Button onClick={handleCancel} variant="outlined" color="primary">
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            {t('Submit')}
          </Button>
        </Grid>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Button
          className={classes.fullWidth}
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled}
        >
          {t('Save & next')}
        </Button>
      </Box>
    </PageFormActionBlock>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(undefined, mapDispatchToProps)(DurationListFormActions);
