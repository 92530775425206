import React from 'react';
import { prop } from 'ramda';
import { Select, MenuItem, Typography, SelectChangeEvent } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { EditComponentProps } from '@core/components/material-table';

interface OwnProps {
  options: Array<{
    id: number | string;
    title: string;
    shortName?: string;
  }>;
  initialValue: unknown;
  renderValue: (value: unknown) => number | string;
  withShowName?: boolean;
}

type Props = EditComponentProps<any> & OwnProps;

const FormikMTSelect = (props: Props) => {
  const { options, renderValue, columnDef, initialValue, withShowName } = props;

  const fieldName = prop('field', columnDef);
  const dataQa = prop('field', columnDef);

  return (
    <Field name={fieldName}>
      {(fieldProps: FieldProps) => {
        const { form, field } = fieldProps;
        const { setFieldValue } = form;
        const handlePositionSelect = (event: SelectChangeEvent) =>
          setFieldValue(fieldName, event.target.value, true);
        const getInputValue = () => field.value || initialValue || '';

        return (
          <Select
            name={fieldName}
            data-qa={dataQa}
            value={getInputValue()}
            onChange={handlePositionSelect}
            renderValue={renderValue || field.value}
          >
            {options &&
              options.map(({ id, title, shortName }) => (
                <MenuItem key={id} value={id}>
                  <Typography variant="body2">
                    {withShowName ? `${title} (${shortName})` : title}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        );
      }}
    </Field>
  );
};

export default FormikMTSelect;
