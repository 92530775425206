import React from 'react';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ErrorMessage as FormikError } from 'formik';
import clsx from 'clsx';

import * as testIds from './tests/test-ids';

interface Props {
  name: string;
  absolutePosition?: boolean;
}

const useStyles = makeStyles({
  helperText: {
    background: 'inherit',
  },
  absolutePosition: {
    position: 'absolute',
    bottom: '-5px',
  },
});

const ErrorMessage = (props: Props) => {
  const { name, absolutePosition } = props;
  const classes = useStyles();

  return (
    <FormikError name={name}>
      {(msg) => (
        <FormHelperText
          error
          data-qa={testIds.ERROR_MESSAGE}
          className={clsx(classes.helperText, {
            [classes.absolutePosition]: absolutePosition,
          })}
        >
          {msg}
        </FormHelperText>
      )}
    </FormikError>
  );
};

export default ErrorMessage;
