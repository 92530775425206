import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { assoc, isEmpty } from 'ramda';
import { Formik, Form, FormikValues, FormikProps } from 'formik';

import {
  SelectField,
  TextField,
  PageFormActionBlock,
  TableWithSelect,
  PersonNameColumn,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  getSports,
  getGenders,
  formatDate,
  formatNationalities,
} from '@core/helpers';
import * as snackbarActions from '@core/store/modules/ui/snackbar/actions';
import { useResetCompetitorProfile } from '@core/helpers/useResetCompetitorProfile';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';
import {
  getCompetitorPlayersList,
  getCompetitorTeamOfficials,
  getPlayerPositions,
  getCompetitorTeamOfficialsRoles,
} from '@core/store/modules/tabs/competitor-profile/selectors';
import CompetitorSummary from '@core/components/competitor-summary';
import { actions as playerListManagementActions } from '@core/store/modules/tabs/player-list-management';
import {
  Sports,
  CompetitorPlayer,
  CompetitorTeamOfficial,
  CompetitorPlayerList,
  PlayerPositions,
  TeamOfficialRoles,
  MatchTeamOfficial,
  PlayerListPlayer,
  CustomerResponse,
  Genders,
} from '@core/types';
import { State } from '@core/store';
import { getTitle, APP_TITLES } from '@core/helpers/app-sports/title-getters';

import SetPositionModal from './set-position-modal';
import SetRoleModal from './set-role-modal';
import * as testIds from './tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

interface StateProps {
  currentCustomer: CustomerResponse;
  competitorPlayers: CompetitorPlayerList;
  competitorTeamOfficials: Array<MatchTeamOfficial>;
  playerPositions: PlayerPositions;
  teamOfficialRoles: TeamOfficialRoles;
  isSubmitting: boolean;
}

interface DispatchProps {
  actions: {
    playerListManagement: typeof playerListManagementActions;
    tab: typeof tabActions;
    competitorProfile: typeof competitorProfileActions;
    snackbar: any;
  };
}

interface OwnProps {
  competition: {
    id: number;
    title: string;
    season: string;
    minPlayers: number;
    maxPlayers: number;
    earliestPlayerDateOfBirth: string;
    sport: Sports;
    gender: Genders;
    latestPlayerRegistrationDate: string;
  };
  competitor: {
    id: number;
    title: string;
    internationalTitle?: string;
    logoFileLink?: string;
    club?: string;
  };
  sportId: number;
  tabId: string;
  teamId: number;
}

type Props = OwnProps & StateProps & DispatchProps;

enum COMPETITOR_PLAYER_FIELDS {
  playerPositionId = 'playerPositionId',
  jerseyNumber = 'jerseyNumber',
}

enum COMPETITOR_OFFICIALS_FIELDS {
  teamOfficialRoleId = 'teamOfficialRoleId',
}

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    position: 'relative',
    width: '120px',
    padding: theme.spacing(1.5, 0),
  },
}));

const PlayerListEditPage = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    tabId,
    competitor,
    competition,
    currentCustomer,
    competitorPlayers,
    competitorTeamOfficials,
    playerPositions,
    teamOfficialRoles,
    teamId,
    isSubmitting,
    sportId,
  } = props;
  const sportPlayerHasPosition = !!playerPositions?.length;
  const classes = useStyles(props);
  const [hasErrors, setHasErrors] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState(null);
  const [selectedTeamOfficials, setSelectedTeamOfficials] = useState(null);

  const getInitialValues = () => {
    let constructedValues = {};
    if (competitorPlayers && competitorTeamOfficials) {
      competitorPlayers.forEach((competitorPlayer) => {
        constructedValues = assoc(
          `${COMPETITOR_PLAYER_FIELDS.jerseyNumber}-${competitorPlayer?.player?.id}`,
          competitorPlayer?.jerseyNumber,
          constructedValues,
        );
        constructedValues = assoc(
          `${COMPETITOR_PLAYER_FIELDS.playerPositionId}-${competitorPlayer?.player?.id}`,
          competitorPlayer?.playerPosition?.id,
          constructedValues,
        );
      });
      competitorTeamOfficials.forEach((teamOfficial) => {
        constructedValues = assoc(
          `${COMPETITOR_OFFICIALS_FIELDS.teamOfficialRoleId}-${teamOfficial.id}`,
          teamOfficial?.teamOfficialRole?.id,
          constructedValues,
        );
      });
    }

    return constructedValues;
  };

  const resetCompetitorProfileRef = useResetCompetitorProfile(competitor.id);

  useEffect(() => {
    if (competitor && competition && sportId) {
      !playerPositions &&
        actions.competitorProfile.getPlayerPositions({
          sportId,
          competitorId: competitor.id,
        });
      !teamOfficialRoles &&
        actions.competitorProfile.getTeamOfficialsRoles({
          competitorId: competitor.id,
        });
      !competitorTeamOfficials &&
        actions.competitorProfile.getTeamOfficials({
          competitionId: competition.id,
          teamId,
          competitorId: competitor.id,
        });
    }

    return () => resetCompetitorProfileRef.current();
  }, []);

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Team in competition'),
      value: competitor?.title,
    },
    {
      label: t('Team ID'),
      value: competitor?.id,
    },
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.season,
    },
    {
      label: t('Sport'),
      value: getSports(t, competition?.sport),
    },
    {
      label: t('Gender'),
      value: getGenders(t, competition?.gender),
    },
    {
      label: t('Club'),
      value: competitor?.club,
    },
  ];

  const handleCancel = () => actions.tab.removeTab({ tabId });

  const handleSubmit = (values: any) => {
    const players = competitorPlayers?.map<PlayerListPlayer>(
      (competitorPlayer: CompetitorPlayer) => {
        return {
          playerId: competitorPlayer?.player?.id,
          jerseyNumber:
            values[
              `${COMPETITOR_PLAYER_FIELDS.jerseyNumber}-${competitorPlayer?.player?.id}`
            ] || null,
          playerPositionId:
            values[
              `${COMPETITOR_PLAYER_FIELDS.playerPositionId}-${competitorPlayer?.player?.id}`
            ] || null,
        };
      },
    );

    const teamOfficials = competitorTeamOfficials?.map(
      (matchTeamOfficial: MatchTeamOfficial) => {
        return {
          teamOfficialId: matchTeamOfficial.teamOfficial.id,
          teamOfficialRoleId:
            values[
              `${COMPETITOR_OFFICIALS_FIELDS.teamOfficialRoleId}-${matchTeamOfficial?.id}`
            ] || null,
        };
      },
    );

    actions.playerListManagement.updatePlayersForCompetitor({
      competitionId: competition.id,
      teamId,
      competitorId: competitor.id,
      players,
      teamOfficials,
      tabId,
    });
  };

  const formatOptions = (
    values: Array<any>,
    labelKey = 'title',
    shortLabelKey = 'shortName',
  ) => {
    const options = values?.map((value: any) => ({
      label: `${t(value[labelKey])} (${value[shortLabelKey]})`,
      value: value.id,
      props: value,
    }));

    return options?.length > 0
      ? [...options, { value: 0, label: t('Not specified') }]
      : [];
  };

  const renderCustomInputValue = (val: any, options: Array<any>) => {
    const currentOption = options?.find((option: any) => option.id === val);
    if (!currentOption) return () => <Grid />;
    const { shortName } = currentOption;

    return () => <Grid>{shortName}</Grid>;
  };

  const playersColumns: React.ComponentProps<
    typeof TableWithSelect
  >['columns'] = [
    {
      name: 'name',
      label: t('Name'),
      render: (rowData: CompetitorPlayer) => (
        <PersonNameColumn person={rowData.player} withLogo />
      ),
    },
    {
      name: 'popularName',
      label: getTitle(APP_TITLES.POPULAR_NAME),
      render: (rowData: CompetitorPlayer) => rowData?.player?.popularName,
    },
    {
      name: 'gender',
      label: t('Gender'),
      render: (rowData: CompetitorPlayer) =>
        getGenders(t, rowData?.player?.gender) ?? '-',
    },
    {
      name: 'dateOfBirth',
      label: t('Date of Birth'),
      render: (rowData: CompetitorPlayer) => {
        const dateOfBirth = formatDate(
          currentCustomer?.dateFormat,
          rowData?.player?.dateOfBirth ?? '-',
        );

        return <>{dateOfBirth}</>;
      },
    },
    {
      name: 'nationality',
      label: t('Nationality'),
      render: (rowData: CompetitorPlayer) =>
        formatNationalities(rowData?.player?.nationalCitizenships || []),
    },
    sportPlayerHasPosition && {
      name: 'playerPosition',
      label: t('Pos.'),
      render: (rowData: CompetitorPlayer) => (
        <SelectField
          label=""
          name={`${COMPETITOR_PLAYER_FIELDS.playerPositionId}-${rowData.player.id}`}
          width={'90px'}
          smallField
          renderCustomInputValue={(value) =>
            renderCustomInputValue(value, playerPositions)
          }
          options={formatOptions(playerPositions)}
        />
      ),
    },
    {
      name: 'jerseyNumber',
      label: t('Jersey #'),
      render: (rowData: CompetitorPlayer) => (
        <Grid className={classes.inputWrapper}>
          <TextField
            name={`${COMPETITOR_PLAYER_FIELDS.jerseyNumber}-${rowData.player.id}`}
            absoluteErrorPosition
            smallInput
            data-qa={testIds.PLAYER_JERSEY_NUMBER_INPUT_FIELD}
            width={'70px'}
          />
        </Grid>
      ),
    },
  ].filter(Boolean);

  const teamOfficialsColumns: React.ComponentProps<
    typeof TableWithSelect
  >['columns'] = [
    {
      name: 'name',
      label: t('Name'),
      render: (rowData: CompetitorTeamOfficial) => (
        <PersonNameColumn person={rowData.teamOfficial} withLogo />
      ),
    },
    {
      name: 'popularName',
      label: getTitle(APP_TITLES.POPULAR_NAME),
      render: (rowData: CompetitorTeamOfficial) =>
        rowData?.teamOfficial.popularName ?? '-',
    },
    {
      name: 'gender',
      label: t('Gender'),
      render: (rowData: CompetitorTeamOfficial) =>
        getGenders(t, rowData?.teamOfficial.gender) ?? '-',
    },
    {
      name: 'teamOfficialRole',
      label: t('Role'),
      render: (rowData: CompetitorTeamOfficial) => (
        <SelectField
          label=""
          name={`${COMPETITOR_OFFICIALS_FIELDS.teamOfficialRoleId}-${rowData.id}`}
          width={'110px'}
          smallField
          renderCustomInputValue={(value) =>
            renderCustomInputValue(value, teamOfficialRoles)
          }
          options={formatOptions(teamOfficialRoles)}
        />
      ),
    },
  ];

  const handleValidation = (values: any) => {
    interface Errors {
      [key: string]: string;
    }
    const errors: Errors = {};

    const duplicates: Array<any> = [];
    const tempObj: any = {};

    Object.entries(values).forEach((player: any) => {
      const [key, value] = player;
      const fieldName = key.split('-')[0];

      if (
        value?.toString()?.length > 2 &&
        fieldName === COMPETITOR_PLAYER_FIELDS.jerseyNumber
      ) {
        errors[key] = `${t('Maximum length is 2')}`;
      }
      if (
        value &&
        isNaN(value) &&
        fieldName === COMPETITOR_PLAYER_FIELDS.jerseyNumber
      ) {
        errors[key] = `${t('Must be a number')}`;
      }
      if (fieldName === COMPETITOR_PLAYER_FIELDS.jerseyNumber && value) {
        if (tempObj[value]) {
          duplicates.push(key);
          duplicates.push(tempObj[value]);
        } else {
          tempObj[value] = key;
        }
      }
    });

    duplicates.forEach((field) => {
      errors[field] = `${t('Must be unique value')}`;
    });

    if (errors) setHasErrors(!isEmpty(errors));

    return errors;
  };

  const handleSetPositionSubmit = (
    selectedPosition: number,
    values: FormikValues,
    setValues: any,
  ) => {
    let originalValues = values;
    selectedPlayers.forEach((selectedPlayerId: number) => {
      const selectedCompetitor = competitorPlayers.find(
        (competitorPlayer: CompetitorPlayer) =>
          competitorPlayer.id === selectedPlayerId,
      );
      originalValues = assoc(
        `${COMPETITOR_PLAYER_FIELDS.playerPositionId}-${selectedCompetitor?.player?.id}`,
        selectedPosition === 0 ? null : selectedPosition,
        originalValues,
      );
    });
    setValues(originalValues);
    setSelectedPlayers(null);
  };

  const handleSetRoleSubmit = (
    selectedRole: number,
    values: FormikValues,
    setValues: any,
  ) => {
    let originalValues = values;
    selectedTeamOfficials.forEach((selectedTeamOfficialId: number) => {
      const selectedTeamOfficial = competitorTeamOfficials.find(
        (competitorTeamOfficial: MatchTeamOfficial) =>
          competitorTeamOfficial.id === selectedTeamOfficialId,
      );
      originalValues = assoc(
        `${COMPETITOR_OFFICIALS_FIELDS.teamOfficialRoleId}-${selectedTeamOfficial?.id}`,
        selectedRole === 0 ? null : selectedRole,
        originalValues,
      );
    });
    setValues(originalValues);
    setSelectedTeamOfficials(null);
  };

  const renderForm = (formikProps: FormikProps<any>) => {
    const { setValues, values } = formikProps;

    return (
      <Form>
        <Paper>
          <Box py={3} px={3}>
            <Box mb={3}>
              <Typography variant="h6">{t('Edit player list')}</Typography>
            </Box>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid container>
                <Grid item xs={12}>
                  <SummaryBlock values={getInfoBlocks()} />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <CompetitorSummary
                competitorTitle={competitor?.title}
                competitorInternationalTitle={competitor?.internationalTitle}
                competitorLogoFileLink={competitor?.logoFileLink}
              />
            </Box>
            <>
              <Box mt={3}>
                {competitorPlayers && playerPositions && (
                  <TableWithSelect
                    title={t('Players')}
                    noPaper
                    titleRow
                    columns={playersColumns}
                    data={competitorPlayers}
                    maxHeight={3000}
                    emptyMessage={t('No players. Please manage')}
                    bulkAction={{
                      label: t('Set position'),
                      onClick: (selectedIds: Array<number>) => {
                        setSelectedPlayers(selectedIds);
                      },
                    }}
                  />
                )}
                {competitorTeamOfficials && (
                  <TableWithSelect
                    title={t('Team officials')}
                    connectedTable
                    titleRow
                    noPaper
                    columns={teamOfficialsColumns}
                    data={competitorTeamOfficials}
                    maxHeight={3000}
                    emptyMessage={t('No team officials. Please manage')}
                    bulkAction={{
                      label: t('Set role'),
                      onClick: (selectedIds: Array<number>) => {
                        setSelectedTeamOfficials(selectedIds);
                      },
                    }}
                  />
                )}
              </Box>
            </>
          </Box>
        </Paper>
        <SetPositionModal
          selectedPlayers={selectedPlayers}
          handleClose={() => setSelectedPlayers(null)}
          handleSubmit={(selectedPosition) =>
            handleSetPositionSubmit(selectedPosition, values, setValues)
          }
          availablePositions={playerPositions}
        />
        <SetRoleModal
          selectedTeamOfficials={selectedTeamOfficials}
          handleClose={() => setSelectedTeamOfficials(null)}
          handleSubmit={(selectedRole) =>
            handleSetRoleSubmit(selectedRole, values, setValues)
          }
          availableRoles={teamOfficialRoles}
        />
        <PageFormActionBlock>
          <Box display="flex" justifyContent="flex-end">
            <Box mr={1}>
              <Button onClick={handleCancel} variant="outlined" color="primary">
                {t('Cancel')}
              </Button>
            </Box>
            <ButtonWithProgress
              isLoading={isSubmitting}
              variant="contained"
              type="submit"
              disabled={hasErrors || isSubmitting}
              color="primary"
            >
              {t('Save')}
            </ButtonWithProgress>
          </Box>
        </PageFormActionBlock>
      </Form>
    );
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={handleValidation}
    >
      {renderForm}
    </Formik>
  );
};

const submittingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    playerListManagementActions.addPlayersAndTeamOfficialsToCompetitorRequest({
      competitionId: ownProps.competition?.id,
      competitorId: ownProps.competitor?.id,
      teamId: ownProps.teamId,
    }),
  ]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    playerListManagement: bindActionCreators(
      playerListManagementActions,
      dispatch,
    ),
    tab: bindActionCreators(tabActions, dispatch),
    competitorProfile: bindActionCreators(competitorProfileActions, dispatch),
    snackbar: bindActionCreators(snackbarActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitorPlayers: getCompetitorPlayersList(state, ownProps?.competitor?.id),
  playerPositions: getPlayerPositions(state, ownProps?.competitor?.id),
  teamOfficialRoles: getCompetitorTeamOfficialsRoles(ownProps?.competitor?.id)(
    state,
  ),
  competitorTeamOfficials: getCompetitorTeamOfficials(
    state,
    ownProps?.competitor?.id,
  ),
  isSubmitting: submittingSelector(ownProps)(state),
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerListEditPage);
