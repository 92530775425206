import React, { forwardRef } from 'react';
import { Grid, TextField, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ControlProps } from 'react-select';

import { AutocompleteOption } from '@core/types';
import { Tooltip } from '@core/components';

import { getControlInputWrapperCssDeclaration } from './helpers';
import * as testIds from '../tests/test-ids';

type Props = ControlProps<AutocompleteOption, boolean>;

const inputComponent = forwardRef<any, any>((props, ref) => (
  <Grid ref={ref} {...props} />
));

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'flex',
    minHeight: theme.spacing(7),
    padding: 0,
    paddingLeft: theme.spacing(1.75),
    height: 'auto',
  },
  inputWrapper: getControlInputWrapperCssDeclaration(theme),
}));

const Control = (props: Props) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { textFieldProps, tooltipText, width, withTooltipMargin },
    hasValue,
    isFocused,
  } = props;
  const dataQa = props.selectProps['data-qa'];
  const classes = useStyles({ tooltipText, width, withTooltipMargin });

  const shouldShrinkLabel = () => isFocused || hasValue;

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Grid className={classes.inputWrapper}>
        <TextField
          data-qa={dataQa || testIds.AUTOCOMPLETE_CONTROL}
          fullWidth
          InputProps={{
            inputComponent,
            inputProps: {
              className: classes.input,
              ref: innerRef,
              children,
              ...(innerProps as any),
            },
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: shouldShrinkLabel(),
            'data-qa': testIds.AUTOCOMPLETE_LABEL,
          }}
          {...textFieldProps}
        />
      </Grid>
      {tooltipText && <Tooltip title={tooltipText} />}
    </Box>
  );
};

export default Control;
