import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { FullScreenSpinner } from '@core/components';
import { GlobalModalProps, ModalTypes } from '@core/types';
import { actions as modalActions } from '@core/store/modules/ui/global-modal';
import {
  getModalErrors,
  getModalParams,
  getModalType,
  isModalOpen,
} from '@core/store/modules/ui/global-modal/selectors';
import { State } from '@core/store';
import modalStyles from './styles';

interface OwnProps {
  modalsMapping: any;
}

interface DispatchProps {
  actions: {
    globalModal: typeof modalActions;
  };
}

interface StateProps {
  modalType: ModalTypes;
  isModalOpen: boolean;
  modalErrors: any;
  modalParams: any;
}

type Props = OwnProps & StateProps & DispatchProps;

const GlobalModal = (props: Props) => {
  const { modalsMapping, modalType, actions, ...otherProps } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const classes = modalStyles();

  const modalProps: GlobalModalProps = {
    fullScreen: isMobile,
    modalActions: { ...actions.globalModal } as any,
    modalType: modalType as any,
    ...otherProps,
  };

  const CurrentModal = modalsMapping[modalType];

  return (
    <>
      {CurrentModal && (
        <Grid className={classes.modalRoot}>
          <React.Suspense fallback={<FullScreenSpinner />}>
            <CurrentModal {...modalProps} />
          </React.Suspense>
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(modalActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  isModalOpen: isModalOpen(state),
  modalErrors: getModalErrors(state),
  modalParams: getModalParams(state),
  modalType: getModalType(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);
