import {
  all,
  put,
  take,
  takeEvery,
  call,
  throttle,
  select,
  Effect,
  SagaReturnType,
} from 'redux-saga/effects';
import { pathOr } from 'ramda';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { getMatchSheetHistory } from '@core/api/match-sheet-history';
import { getMatch } from '@core/store/modules/api/match/sagas';
import {
  AddedMatchPlayerList,
  AddedMatchTeamOfficialList,
  MatchPlayerOrigin,
  MatchPlayersList,
} from '@core/types';
import { getCompetitorPlayers } from '@core/store/modules/api/competitor-players/sagas';
import { getCompetitorTeamOfficials } from '@core/store/modules/api/competitor-team-officials/sagas';
import { actions as matchProfileActions } from '@core/store/modules/tabs/match-profile';
import {
  getMatchPlayers,
  changeMatchPlayersList,
  getPreviousMatchPlayers,
} from '@core/store/modules/api/match-player/sagas';
import {
  getMatchTeamOfficials,
  changeMatchTeamOfficials,
  getPreviousMatchTeamOfficials,
} from '@core/store/modules/api/match-team-officials/sagas';

import {
  formatAddedMatchPlayers,
  formatAvailablePlayerList,
  findCaptainId,
  findLiberoIds,
  formatCompetitorPlayersChangeList,
  checkAreChangesInMatchPlayers,
  updateLineupPlayerRole,
  formatAddedMatchTeamOfficials,
  formatAvailableTeamOfficials,
  checkIfDiffsById,
} from './helpers';
import * as selectors from './selectors';

import { actions } from '.';

interface HandlePlayerListChangeProps {
  matchPlayerOrigin: MatchPlayerOrigin;
  playersLists: [AddedMatchPlayerList, AddedMatchPlayerList, MatchPlayersList];
  competitorId: number;
  matchId: number;
  tabId: string;
}

export function* getMatchPlayersFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatchPlayers);
    const { matchId, matchPlayerOrigin, competitorId } = payload;

    yield put(actions.getMatchPlayersRequest());

    const { error, response } = yield call(
      getMatchPlayers,
      matchId,
      competitorId,
    );

    if (!error) {
      yield put(actions.getMatchPlayersSuccess());

      const matchPlayers = pathOr([], ['_embedded', 'matchPlayers'], response);

      yield put(
        actions.setMatchPlayers({
          matchId,
          matchPlayerOrigin,
          payload: matchPlayers,
        }),
      );

      yield put(
        actions.setLineupAddedPlayers({
          matchPlayerOrigin,
          matchId,
          payload: formatAddedMatchPlayers(matchPlayers),
        }),
      );
    } else {
      yield put(actions.getMatchPlayersFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getPreviousMatchPlayersFlow() {
  while (true) {
    const { payload } = yield take(actions.getPreviousMatchPlayers);
    const { matchId, matchPlayerOrigin, competitorId } = payload;

    yield put(actions.getPreviousMatchPlayersRequest());

    const { error: playersError, response: playersResponse } = yield call(
      getPreviousMatchPlayers,
      matchId,
      competitorId,
    );

    if (!playersError) {
      const { error: officialsError, response: officialsResponse } = yield call(
        getPreviousMatchTeamOfficials,
        matchId,
        competitorId,
      );

      yield put(actions.getPreviousMatchPlayersSuccess());

      const matchPlayers = pathOr(
        [],
        ['_embedded', 'matchPlayers'],
        playersResponse,
      );
      let matchParticipantsTotal = matchPlayers.length;

      if (!officialsError) {
        const matchTeamOfficials = pathOr(
          [],
          ['_embedded', 'matchTeamOfficials'],
          officialsResponse,
        );

        matchParticipantsTotal += matchTeamOfficials.length;

        if (matchTeamOfficials.length > 0) {
          yield put(
            actions.setAddedTeamOfficials({
              matchId,
              matchPlayerOrigin,
              payload: formatAddedMatchTeamOfficials(matchTeamOfficials),
            }),
          );
        }
      }

      if (matchPlayers.length > 0) {
        yield put(
          actions.setLineupAddedPlayers({
            matchPlayerOrigin,
            matchId,
            payload: formatAddedMatchPlayers(matchPlayers),
          }),
        );
      }

      yield put(
        actions.setPreviousMatchPlayersTotal({
          matchPlayerOrigin,
          matchId,
          payload: matchParticipantsTotal,
        }),
      );
    } else {
      yield put(actions.getPreviousMatchPlayersFailure());
      yield call(handleApiErrors, playersError);
    }
  }
}

export function* searchAvailableCompetitorPlayersFlow(
  action: any,
): Generator<Effect, any, any> {
  const { payload } = action;
  const { matchId, matchPlayerOrigin, competitionId, teamId, params } = payload;

  yield put(actions.searchCompetitorPlayersRequest());

  const { error, response } = (yield call(
    getCompetitorPlayers,
    competitionId,
    teamId,
    params,
  )) as SagaReturnType<typeof getCompetitorPlayers>;

  if (!error) {
    const setCompetitorPlayersAction = actions.setLineupCompetitorPlayers;

    const competitorPlayers = formatAvailablePlayerList(response);

    yield put(
      setCompetitorPlayersAction({
        matchId,
        matchPlayerOrigin,
        payload: competitorPlayers,
      }),
    );

    yield put(actions.searchCompetitorPlayersSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(actions.searchCompetitorPlayersFailure());
  }
}

export function* watchSearchCompetitorPlayersFlow() {
  yield throttle(
    500,
    actions.searchCompetitorPlayers,
    searchAvailableCompetitorPlayersFlow,
  );
}

export function* changeLineupRoleFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.changeLineupAddedPlayersRole);
    const { matchId, playerId, matchPlayerOrigin, role, tabId } = payload;

    const addedMatchLineupPlayers = yield select(
      selectors.getAddedMatchLineupPlayers,
      {
        matchId,
        matchPlayerOrigin,
      },
    );

    const updatedLineupPlayers = updateLineupPlayerRole({
      role,
      playerId,
      addedMatchLineupPlayers,
    });

    yield put(
      actions.setLineupAddedPlayers({
        payload: updatedLineupPlayers,
        matchId,
        matchPlayerOrigin,
        tabId,
      }),
    );

    yield put(
      actions.setAddedLineupPlayerDataErrors({
        payload: null,
        matchId,
        matchPlayerOrigin,
      }),
    );
  }
}

export function* getPlayersLists({
  matchId,
  matchPlayerOrigin,
}: {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
}): Generator<Effect, any, any> {
  const addedMatchLineupPlayers = yield select(
    selectors.getAddedMatchLineupPlayers,
    {
      matchId,
      matchPlayerOrigin,
    },
  );

  const currentMatchPlayers = yield select(selectors.getMatchPlayers, {
    matchId,
    matchPlayerOrigin,
  });

  return [addedMatchLineupPlayers, currentMatchPlayers];
}

export function* handlePlayersListChanges(props: HandlePlayerListChangeProps) {
  const { matchPlayerOrigin, playersLists, matchId, competitorId } = props;
  const [addedMatchLineupPlayers] = playersLists;

  const formattedData = {
    captainPlayerId: findCaptainId(addedMatchLineupPlayers),
    liberoPlayerIds: findLiberoIds(addedMatchLineupPlayers),
    playerIds: formatCompetitorPlayersChangeList(addedMatchLineupPlayers),
  };

  yield put(actions.submitMatchSheetRequest());

  const { error } = yield call(
    changeMatchPlayersList,
    matchId,
    competitorId,
    formattedData,
  );

  if (!error) {
    yield put(actions.submitMatchSheetSuccess());

    yield put(
      matchProfileActions.getMatchPlayers({
        competitorId,
        matchId,
        matchPlayerOrigin,
      }),
    );
  } else {
    yield put(actions.submitMatchSheetFailure());
    yield call(handleApiErrors, error);
  }
}

function getIds(addedTeamOfficials: AddedMatchTeamOfficialList) {
  return addedTeamOfficials.map((official) => official.id);
}

function* handleTeamOfficialsChange(props: any) {
  const { matchId, competitorId, addedTeamOfficials, matchPlayerOrigin } =
    props;
  const { error } = yield call(
    changeMatchTeamOfficials,
    matchId,
    competitorId,
    {
      teamOfficialIds: getIds(addedTeamOfficials),
    },
  );

  if (!error) {
    yield put(
      matchProfileActions.getMatchTeamOfficials({
        competitorId,
        matchId,
        matchPlayerOrigin,
      }),
    );
  } else {
    yield call(handleApiErrors, error);
  }
}

export function* getTeamOfficialsLists({
  matchId,
  matchPlayerOrigin,
}: {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
}): Generator<Effect, any, any> {
  const addedTeamOfficials = yield select(
    selectors.getAddedMatchTeamOfficials,
    {
      matchId,
      matchPlayerOrigin,
    },
  );
  const matchTeamOfficials = yield select(selectors.getMatchTeamOfficials, {
    matchId,
    matchPlayerOrigin,
  });

  return [matchTeamOfficials, addedTeamOfficials];
}

export function* submitMatchSheetFlow(): any {
  while (true) {
    const { payload } = yield take(actions.submitMatchSheet);
    const { matchId, matchPlayerOrigin, tabId, competitorId } = payload;

    const playersLists = yield call(getPlayersLists, {
      matchId,
      matchPlayerOrigin,
    });

    const areChangesInPlayers = checkAreChangesInMatchPlayers(playersLists);
    const [matchTeamOfficials, addedTeamOfficials] = yield call(
      getTeamOfficialsLists,
      {
        matchId,
        matchPlayerOrigin,
      },
    );
    const areChangesInTeamOfficials = checkIfDiffsById(
      matchTeamOfficials || [],
      addedTeamOfficials || [],
    );

    if (areChangesInTeamOfficials) {
      yield call(handleTeamOfficialsChange, {
        matchPlayerOrigin,
        addedTeamOfficials,
        matchId,
        competitorId,
      });
    }

    if (areChangesInPlayers) {
      yield call(handlePlayersListChanges, {
        matchPlayerOrigin,
        playersLists,
        matchId,
        tabId,
        competitorId,
      });
    }

    yield put(tabActions.removeTab({ tabId }));
  }
}

export function* setTabDirtyLineupChangeFlow() {
  while (true) {
    const {
      meta: { tabId },
    } = yield take(actions.setLineupAddedPlayers);

    yield put(tabActions.setTouchedByTabId(true, tabId));
  }
}

export function* setTabDirtySubstituteChangeFlow() {
  while (true) {
    const {
      meta: { tabId },
    } = yield take(actions.setSubstituteAddedPlayers);

    yield put(tabActions.setTouchedByTabId(true, tabId));
  }
}

export function* getMatchTeamOfficialsFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatchTeamOfficials);
    const { matchId, competitorId, matchPlayerOrigin } = payload;

    yield put(actions.getMatchTeamOfficialsRequest());
    const { error, response } = yield call(
      getMatchTeamOfficials,
      matchId,
      competitorId,
    );

    if (!error) {
      yield put(actions.getMatchTeamOfficialsSuccess());
      const matchTeamOfficials = pathOr(
        [],
        ['_embedded', 'matchTeamOfficials'],
        response,
      );

      yield put(
        actions.setTeamOfficials({
          matchId,
          matchPlayerOrigin,
          payload: formatAddedMatchTeamOfficials(matchTeamOfficials),
        }),
      );
      yield put(
        actions.setAddedTeamOfficials({
          matchId,
          matchPlayerOrigin,
          payload: formatAddedMatchTeamOfficials(matchTeamOfficials),
        }),
      );
    } else {
      yield put(actions.getMatchTeamOfficialsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* searchAvailableCompetitorTeamOfficialsFlow(action: any) {
  const { payload } = action;
  const { matchId, matchPlayerOrigin, competitionId, teamId, params } = payload;

  yield put(actions.searchCompetitorTeamOfficialsRequest());
  const { error, response } = yield call(
    getCompetitorTeamOfficials,
    competitionId,
    teamId,
    params,
  );

  if (!error) {
    yield put(
      actions.setCompetitorTeamOfficials({
        matchId,
        matchPlayerOrigin,
        payload: formatAvailableTeamOfficials(
          response._embedded.competitorTeamOfficials,
        ),
      }),
    );

    yield put(actions.searchCompetitorTeamOfficialsSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(actions.searchCompetitorTeamOfficialsFailure());
  }
}

export function* watchSearchCompetitorTeamOfficialsFlow() {
  yield throttle(
    500,
    actions.searchCompetitorTeamOfficials,
    searchAvailableCompetitorTeamOfficialsFlow,
  );
}

export function* getMatchFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatch);
    const { matchId, matchPlayerOrigin } = payload;

    yield put(actions.getMatchRequest());

    const { error, response } = yield call(getMatch, matchId);

    if (!error) {
      yield put(actions.getMatchSuccess());
      yield put(
        actions.setMatch({ payload: response, matchPlayerOrigin, matchId }),
      );
    } else {
      yield put(actions.getMatchFailure());
    }
  }
}

export function* getMatchSheetHistoryFlowWatcher() {
  yield takeEvery(actions.getMatchSheetHistory, getMatchSheetHistoryFlow);
}

export function* getMatchSheetHistoryFlow(action) {
  const { matchId, competitorId } = action.payload;

  yield put(actions.getMatchSheetHistoryRequest());

  const { error, response } = yield call(
    getMatchSheetHistory,
    matchId,
    competitorId,
  );

  if (!error) {
    yield put(actions.getMatchSheetHistorySuccess());
    yield put(
      actions.setMatchSheetHistory({
        payload: response._embedded.matchSheetHistory,
        matchId,
        competitorId,
      }),
    );
  } else {
    yield put(actions.getMatchSheetHistoryFailure());
  }
}

export default function* saga() {
  yield all([
    getMatchPlayersFlow(),
    watchSearchCompetitorPlayersFlow(),
    changeLineupRoleFlow(),
    submitMatchSheetFlow(),
    setTabDirtyLineupChangeFlow(),
    setTabDirtySubstituteChangeFlow(),
    getMatchTeamOfficialsFlow(),
    watchSearchCompetitorTeamOfficialsFlow(),
    getMatchFlow(),
    getPreviousMatchPlayersFlow(),
    getMatchSheetHistoryFlowWatcher(),
  ]);
}
