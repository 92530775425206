import React from 'react';
import { Grid, Paper, Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuProps } from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AutocompleteOption } from '@core/types';

import { getMenuRootCssDeclaration } from './helpers';
import * as testIds from '../tests/test-ids';

type Props = MenuProps<AutocompleteOption, boolean>;

const useStyles = makeStyles((theme: Theme) => ({
  root: getMenuRootCssDeclaration(theme),
  scrollable: {
    maxHeight: 300,
    overflowY: 'auto',
  },
}));

const MenuWithInfiniteScroll = (props: Props) => {
  const {
    selectProps: { menuPosition, tooltipText, width, withTooltipMargin },
  } = props;
  const classes = useStyles({
    menuPosition,
    tooltipText,
    width,
    withTooltipMargin,
  });
  const hasMore = !!props?.selectProps?.hasMore;
  const dataLength = props?.options.length;
  const currentQuery = props?.selectProps?.inputValue;

  const loader = (
    <Box display="flex" justifyContent="center" mb={1}>
      <CircularProgress size={20} />
    </Box>
  );

  function next() {
    props?.selectProps?.loadNext(currentQuery);
  }

  return (
    <Paper
      className={classes.root}
      {...(props.innerProps as any)}
      data-qa={testIds.AUTOCOMPLETE_MENU}
    >
      <Grid id="scrollableDiv" className={classes.scrollable}>
        <InfiniteScroll
          hasMore={hasMore}
          next={next}
          loader={loader}
          dataLength={dataLength}
          scrollableTarget="scrollableDiv"
        >
          {props.children}
        </InfiniteScroll>
      </Grid>
    </Paper>
  );
};

export default MenuWithInfiniteScroll;
