import { replace, pipe, tail, toUpper, toLower } from 'ramda';

// (some-string) => someString
// (some_string) => someString
// (SOME_STRING) => someString

export default pipe(
  toLower,
  replace(/[-_]([a-z])/g, pipe(tail as (val: string) => string, toUpper)),
);
