import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { DatePickerField, TextField } from '@core/components';

import { FIELD_NAMES } from '../constants';
import { FormValues } from '../helpers/initialValues';
import useStyles from '../styles';

const StageTitleDescriptionBlock = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormikContext<FormValues>();

  let maxDate = null;
  let minDate = null;

  if (values[FIELD_NAMES.endDate]) {
    maxDate = dayjs(values[FIELD_NAMES.endDate]);
  }

  if (values[FIELD_NAMES.startDate]) {
    minDate = dayjs(values[FIELD_NAMES.startDate]);
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography variant="caption" className={classes.uppercase}>
          {t('Titles & Description')}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={8} spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            data-qa={FIELD_NAMES.title}
            name={FIELD_NAMES.title}
            label={t('Stage title')}
            withTooltipMargin={false}
            required
            tooltipText={t(
              'Name of the stage in the local language. This title will be displayed across the application and reports',
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            data-qa={FIELD_NAMES.internationalName}
            name={FIELD_NAMES.internationalName}
            label={t('International stage title')}
            withTooltipMargin={false}
            tooltipText={t(
              'The international version of Stage Title (in Latin characters)',
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            data-qa={FIELD_NAMES.description}
            withTooltipMargin
            rows={3}
            multiline
            name={FIELD_NAMES.description}
            label={t('Description')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            withTooltipMargin
            name={FIELD_NAMES.startDate}
            data-qa={FIELD_NAMES.startDate}
            label={t('Start date')}
            maxDate={maxDate}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            withTooltipMargin
            name={FIELD_NAMES.endDate}
            data-qa={FIELD_NAMES.endDate}
            label={t('End date')}
            minDate={minDate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StageTitleDescriptionBlock;
