import { createAction } from 'redux-actions';

import { NAMESPACE } from './constants';

const regenerateGroupFixturesRequest = createAction<void>(
  `${NAMESPACE}/REGENERATE_GROUP_FIXTURES_REQUEST`,
);
const regenerateGroupFixturesSuccess = createAction<void>(
  `${NAMESPACE}/REGENERATE_GROUP_FIXTURES_SUCCESS`,
);
const regenerateGroupFixturesFailure = createAction<void>(
  `${NAMESPACE}/REGENERATE_GROUP_FIXTURES_FAILURE`,
);

export default {
  regenerateGroupFixturesRequest,
  regenerateGroupFixturesSuccess,
  regenerateGroupFixturesFailure,
};
