import { all, put, take, select, call, Effect } from 'redux-saga/effects';
import { assocPath, path } from 'ramda';

import { PanelState } from '@core/types';

import * as ACTION_TYPES from './constants';
import * as expansionPanelsActions from './actions';
import { getExpansionPanelGroups } from './selectors';

export function* activatePanel(
  groupId: number,
  panelIndex: number,
): Generator<Effect, any, any> {
  const expansionPanelGroups = yield select(getExpansionPanelGroups);
  const nextPanelState = path<PanelState>(
    [groupId, panelIndex],
    expansionPanelGroups,
  );

  if (nextPanelState) {
    const updatedNextPanelState = {
      ...nextPanelState,
      isExpanded: true,
      isDisabled: false,
    };

    const updatedExpansionPanelGroups = assocPath(
      [groupId, panelIndex],
      updatedNextPanelState,
      expansionPanelGroups,
    );

    yield put(
      expansionPanelsActions.setExpansionPanelGroups(
        updatedExpansionPanelGroups,
      ),
    );
  }
}

export function* activateNextPanel() {
  while (true) {
    const {
      payload: { panelIndex, groupId },
    } = yield take(ACTION_TYPES.ACTIVATE_NEXT_PANEL);

    yield call(activatePanel, groupId, panelIndex + 1);
  }
}

export function* activatePreviousPanel() {
  while (true) {
    const {
      payload: { panelIndex, groupId },
    } = yield take(ACTION_TYPES.ACTIVATE_PREVIOUS_PANEL);

    yield call(activatePanel, groupId, panelIndex - 1);
    yield put(
      expansionPanelsActions.updatePanelState({
        panelIndex,
        groupId,
        panelState: { isExpanded: false },
      }),
    );
  }
}

export function* updatePanelState(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { panelIndex, panelState, groupId },
    } = yield take(ACTION_TYPES.UPDATE_PANEL_STATE);
    const expansionPanelGroups = yield select(getExpansionPanelGroups);
    const updatedPanelState = {
      ...expansionPanelGroups[groupId][panelIndex],
      ...panelState,
    };

    const updatedExpansionPanelGroups = assocPath(
      [groupId, panelIndex],
      updatedPanelState,
      expansionPanelGroups,
    );

    yield put(
      expansionPanelsActions.setExpansionPanelGroups(
        updatedExpansionPanelGroups,
      ),
    );
  }
}

export function* setPanelError(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { panelIndex, groupId },
    } = yield take(ACTION_TYPES.SET_PANEL_ERROR);
    const expansionPanelGroups = yield select(getExpansionPanelGroups);
    const updatedPanelState = {
      ...expansionPanelGroups[groupId][panelIndex],
      isCompleted: false,
      hasError: true,
    };

    const updatedExpansionPanelGroups = assocPath(
      [groupId, panelIndex],
      updatedPanelState,
      expansionPanelGroups,
    );

    yield put(
      expansionPanelsActions.setExpansionPanelGroups(
        updatedExpansionPanelGroups,
      ),
    );
  }
}

export function* setPanelCompleted(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { panelIndex, groupId },
    } = yield take(ACTION_TYPES.SET_PANEL_COMPLETED);
    const expansionPanelGroups = yield select(getExpansionPanelGroups);
    const updatedPanelState = {
      ...expansionPanelGroups[groupId][panelIndex],
      isCompleted: true,
      hasError: false,
      isExpanded: false,
    };

    const updatedExpansionPanelGroups = assocPath(
      [groupId, panelIndex],
      updatedPanelState,
      expansionPanelGroups,
    );

    yield put(
      expansionPanelsActions.setExpansionPanelGroups(
        updatedExpansionPanelGroups,
      ),
    );
  }
}

export default function* saga() {
  yield all([
    updatePanelState(),
    activateNextPanel(),
    activatePreviousPanel(),
    setPanelCompleted(),
    setPanelError(),
  ]);
}
