import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from '@mui/material';
import { Club } from '@core/icons';
import {
  ColumnWithSubValue,
  AlertStatus,
  CustomTable,
  Status,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import {
  getPlayerListRequestStatus,
  getPlayerListRequestStatusColor,
} from '@core/helpers';
import { COMPETITION_PROFILE_COMPETITORS_TABLE } from '@core/pages/competition-profile/tests/test-ids';
import { getCompetitors } from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { Competitor, Paginated } from '@core/types';
import { State } from '@core/store';

import CompetitorActionColumn from './competitor-actions-column';

interface OwnProps {
  competitionId: number;
  isPlayerListStatus?: boolean;
}

interface StateProps {
  competitors: Paginated<Competitor>;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
    competitionProfile: typeof competitionProfileActions;
  };
}

type Props = OwnProps & DispatchProps & StateProps;

export const CompetitorsTable = (props: Props) => {
  const { competitors, actions, competitionId, isPlayerListStatus } = props;
  const { t } = useTranslation();

  const columns = [
    {
      title: t('Team name in competition'),
      field: 'title',
      render: (rowData: Competitor) => (
        <ColumnWithSubValue
          value={rowData.title}
          subValue={rowData.internationalTitle}
          logo={{
            url: rowData.logoFileLink || rowData.clubLogoFileLink,
            defaultIcon: <Club />,
          }}
        />
      ),
    },
    {
      title: t('Team ID'),
      field: 'teamId',
    },
    {
      title: t('Team code'),
      field: 'teamCode',
    },
    {
      title: t('Default venue'),
      render: (rowData: Competitor) => (
        <ColumnWithSubValue
          value={rowData?.defaultVenue?.title}
          subValue={rowData?.defaultVenue?.internationalTitle}
        />
      ),
    },
    {
      title: t('Team members'),
      render: (rowData: Competitor) =>
        rowData.competitorPlayersCount === 0 ? (
          <AlertStatus title={t('Missing')} />
        ) : (
          rowData.competitorPlayersCount
        ),
    },
  ];

  if (isPlayerListStatus) {
    columns.push({
      title: t('Player list status'),
      field: 'latestPlayerListRequestStatus',
      render: (rowData: any) => (
        <Status
          title={getPlayerListRequestStatus(
            t,
            rowData.latestPlayerListRequestStatus,
          )}
          statusColor={getPlayerListRequestStatusColor(
            rowData.latestPlayerListRequestStatus,
          )}
        />
      ),
    });
  }

  columns.push({
    title: '',
    field: 'actions',
    render: (rowData: Competitor) => (
      <CompetitorActionColumn
        competitor={rowData}
        competitionId={competitionId}
      />
    ),
  });

  const handleDataReload = ({ page }: { page: number }) =>
    actions.competitionProfile.getCompetitors({ competitionId, page });

  const getPaginationValues = () => ({
    reloadData: handleDataReload,
    page: competitors?.page || 1,
    pages: competitors?.pages,
    limit: competitors?.limit || 10,
    total: competitors?.total || 0,
  });

  return (
    competitors && (
      <Grid data-qa={COMPETITION_PROFILE_COMPETITORS_TABLE}>
        <CustomTable
          noPaper
          columns={columns}
          data={competitors?.list || []}
          pagination={getPaginationValues()}
        />
      </Grid>
    )
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitors: getCompetitors(state, ownProps.competitionId),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorsTable);
