import { take, call, put, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as sportsAPI from '@core/api/sports';

import * as ACTION_TYPES from './constants';
import * as sportsActions from './actions';

export function* getSports(): Generator<Effect, any, any> {
  yield put(sportsActions.getSportsRequest());
  const response = yield call(sportsAPI.getSports);

  if (!response.error) {
    yield put(sportsActions.getSportsSuccess());
  } else {
    yield put(sportsActions.getSportsFailure());
  }

  return response;
}

export function* getSportsFlow() {
  while (true) {
    yield take(ACTION_TYPES.GET_SPORTS);
    const { error, response } = yield call(getSports);

    if (!error) {
      const sports = response._embedded.sports;

      yield put(sportsActions.setSports(sports));
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield getSportsFlow();
}
