import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { DatePickerField } from '@core/components';

import { FIELD_NAMES } from '../constants';
import { FormValues } from '../helpers/defaultValues';
import useStyles from './styles';

const CompetitionDatesBlock = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormikContext<FormValues>();

  let maxDate = null;
  let minDate = null;

  if (values[FIELD_NAMES.endDate]) {
    maxDate = dayjs(values[FIELD_NAMES.endDate]);
  }

  if (values[FIELD_NAMES.startDate]) {
    minDate = dayjs(values[FIELD_NAMES.startDate]);
  }

  return (
    <Grid container item xs={12} className={classes.separation} spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography variant="caption" className={classes.uppercase}>
          {t('Dates')}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={8} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <DatePickerField
              name={FIELD_NAMES.startDate}
              data-qa={FIELD_NAMES.startDate}
              withTooltipMargin
              label={t('Start date')}
              maxDate={maxDate}
              required
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <DatePickerField
              name={FIELD_NAMES.endDate}
              data-qa={FIELD_NAMES.endDate}
              withTooltipMargin
              label={t('End date')}
              minDate={minDate}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompetitionDatesBlock;
