import React, { forwardRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { AddCircleTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { CustomTable, Tooltip } from '@core/components';
import { Column, EditComponentProps } from '@core/components/material-table';
import { actions as membersActions } from '@core/pages/persons/store';
import { getMatchOfficialsRoles } from '@core/pages/persons/store/selectors';
import { actions as matchOfficialGroupActions } from '@core/pages/referee-groups/store/actions';
import { actions as matchOfficialRolesActions } from '@core/pages/persons/store/actions';
import { getMatchOfficialRoleType } from '@core/helpers';
import { ADD_BUTTON } from '@core/constants/test-ids';
import { getRefereeGroupList } from '@core/pages/referee-groups/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  Competition,
  Group,
  MatchOfficialRole,
  MatchOfficialRoles,
  MatchOfficialRoleType,
  MatchOfficialGroup,
  MatchOfficialGroupsList,
} from '@core/types';
import { State } from '@core/store';

import useStyles from './styles';
import { FIELD_NAMES, MatchOfficialSettings } from './constants';
import { FormValues } from './helpers/initialValues';
import {
  STAGE_SETTINGS_GROUP_SELECT,
  STAGE_SETTINGS_ROLE_SELECT,
} from './tests/test-ids';

interface OwnProps {
  initialValues: Group;
  competition: Competition;
}

interface DispatchProps {
  actions: {
    members: typeof membersActions;
    matchOfficialGroups: typeof matchOfficialGroupActions;
  };
}

interface StateProps {
  matchOfficialRoles: MatchOfficialRoles;
  matchOfficialGroups: MatchOfficialGroupsList;
  isLoading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

const StageMatchOfficialsBlock = (props: Props) => {
  const {
    actions,
    competition,
    matchOfficialRoles,
    matchOfficialGroups,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const classes = useStyles();

  const getRefereeRoles = () =>
    matchOfficialRoles?.filter((matchOfficialRole) =>
      [
        MatchOfficialRoleType.REFEREE,
        MatchOfficialRoleType.SECOND_REFEREE,
      ].includes(matchOfficialRole.roleType),
    );

  const referees = getRefereeRoles()?.map((referee) => referee.id);
  const allowMatchOfficialAdd =
    values[FIELD_NAMES.matchOfficialsSettings]?.length !==
    matchOfficialRoles?.length;
  const allowMatchOfficialChange =
    values[FIELD_NAMES.matchOfficialsSettings]?.length > 1;

  useEffect(() => {
    actions.members.getMatchOfficialsRoles();
  }, []);

  useEffect(() => {
    competition &&
      actions.matchOfficialGroups.getMatchOfficialGroups({
        query:
          '?' +
          new URLSearchParams([
            ['filter[roleType]', MatchOfficialRoleType.REFEREE],
            ['filter[sport]', String(competition?.sportId)],
          ]),
      });
  }, [competition?.sportId]);

  useEffect(() => {
    // set 1st referee and 2nd referee as selected match officials
    if (
      matchOfficialRoles &&
      values[FIELD_NAMES.matchOfficialsSettings]?.length === 0
    ) {
      const refereeRoles = getRefereeRoles();

      if (refereeRoles) {
        const matchOfficialsSettings = refereeRoles?.map((referee) => ({
          id: referee.id,
          roleId: referee.id,
          groupId: null,
        }));

        setFieldValue(
          FIELD_NAMES.matchOfficialsSettings,
          matchOfficialsSettings,
        );
      }
    }
  }, [matchOfficialRoles]);

  const getRoleTitle = (roleId: number) => {
    const role = matchOfficialRoles?.find(
      (matchOfficialRole) => matchOfficialRole.id === roleId,
    );

    return role ? getMatchOfficialRoleType(t, role) : '';
  };

  const getGroupTitle = (groupId: number) => {
    const group = matchOfficialGroups?.find(
      (matchOfficialGroup) => matchOfficialGroup.id === groupId,
    );

    return group?.title || '';
  };

  const renderRoleMenuItem = (role: MatchOfficialRole) => (
    <MenuItem key={role.id} value={role.id}>
      {getMatchOfficialRoleType(t, role)}
    </MenuItem>
  );

  const renderGroupMenuItem = (group: MatchOfficialGroup) => (
    <MenuItem key={group.id} value={group.id}>
      {group.title}
    </MenuItem>
  );

  const empty_group = {
    id: -1,
    rank: 0,
    roleType: MatchOfficialRoleType.REFEREE,
    title: t('Not specified'),
  };

  const columns: Array<Column<MatchOfficialSettings>> = [
    {
      title: t('Match official'),
      field: 'roleId',
      render: (rowData) => getRoleTitle(rowData.roleId),
      editComponent: (editProps: EditComponentProps<MatchOfficialSettings>) => {
        const handleOnChange = (event: SelectChangeEvent) =>
          editProps.onChange(event.target.value);

        const selectedMatchOfficialRoles = values[
          FIELD_NAMES.matchOfficialsSettings
        ].map((role) => role.roleId);
        const filteredMatchOfficials = matchOfficialRoles?.filter(
          ({ id }) =>
            !selectedMatchOfficialRoles.includes(id) || id === editProps.value,
        );

        return (
          <Select
            variant="standard"
            value={editProps.value || ''}
            name={editProps.columnDef.field}
            // 1st and 2nd referees are required and should be disabled
            disabled={referees?.includes(editProps.value)}
            onChange={handleOnChange}
            style={{
              fontSize: 13,
            }}
            data-qa={STAGE_SETTINGS_ROLE_SELECT}
          >
            {filteredMatchOfficials?.map(renderRoleMenuItem)}
          </Select>
        );
      },
    },
    {
      title: (
        <>
          {t('Group')}{' '}
          <Tooltip
            title={t(
              'Specify minimum Group requirement for Match Officials, so only Match Officials from the specified Group or higher in rank can participate in this Stage.',
            )}
          />
        </>
      ),
      field: 'groupId',
      render: (rowData) => getGroupTitle(rowData.groupId),
      editComponent: (editProps: EditComponentProps<MatchOfficialSettings>) => {
        const groupList = matchOfficialGroups;

        if (
          groupList?.findIndex((group) => group.id === empty_group.id) === -1
        ) {
          groupList?.unshift(empty_group);
        }

        const isGroupAccessible =
          groupList &&
          groupList?.findIndex((element) => element.id === editProps.value);

        const handleOnChange = (event: SelectChangeEvent) =>
          editProps.onChange(event.target.value);

        const getValue = () =>
          isGroupAccessible === -1
            ? empty_group.id
            : editProps.value || empty_group.id;

        return (
          <Select
            variant="standard"
            value={getValue()}
            name={editProps.columnDef.field}
            onChange={handleOnChange}
            style={{
              fontSize: 13,
            }}
            data-qa={STAGE_SETTINGS_GROUP_SELECT}
          >
            {groupList && groupList?.map(renderGroupMenuItem)}
          </Select>
        );
      },
    },
  ];

  const handleAddMatchOfficial = async (newData: MatchOfficialSettings) =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        // copy old value without mutation
        const matchOfficialsSettings = [
          ...values[FIELD_NAMES.matchOfficialsSettings],
        ];

        matchOfficialsSettings.push({
          ...newData,
          id: newData.roleId,
        });

        setFieldValue(
          FIELD_NAMES.matchOfficialsSettings,
          matchOfficialsSettings,
        );
        resolve();
      }, 100);
    });

  const handleRowUpdate = async (
    newData: MatchOfficialSettings,
    oldData: any,
  ) =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        // copy old value without mutation
        const matchOfficialsSettings = [
          ...values[FIELD_NAMES.matchOfficialsSettings],
        ];

        const index = oldData.tableData.index;
        matchOfficialsSettings[index] = {
          ...newData,
          id: newData.roleId,
        };

        setFieldValue(
          FIELD_NAMES.matchOfficialsSettings,
          matchOfficialsSettings,
        );
        resolve();
      }, 100);
    });

  const handleRowDelete = async (rowData: MatchOfficialSettings) =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        // copy old value without mutation
        const matchOfficialsSettings = [
          ...values[FIELD_NAMES.matchOfficialsSettings],
        ];

        const index = matchOfficialsSettings.findIndex(
          (matchOfficialSettings) =>
            matchOfficialSettings.roleId === rowData.roleId,
        );
        matchOfficialsSettings.splice(index, 1);

        setFieldValue(
          FIELD_NAMES.matchOfficialsSettings,
          matchOfficialsSettings,
        );
        resolve();
      }, 100);
    });

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} md={2}>
        <Typography variant="caption" className={classes.uppercase}>
          {t('Match officials')}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={8} spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            isLoading={isLoading}
            columns={columns}
            data={values?.[FIELD_NAMES.matchOfficialsSettings] || []}
            localization={{
              body: {
                emptyDataSourceMessage: t(
                  'No match officials allocated. Please add.',
                ),
                editRow: {
                  deleteText: t(
                    'Are you sure you want to remove this official?',
                  ),
                },
                addTooltip: t(
                  'Specify Match Official roles and Categories that are required for this Stage. To fill the roles go to Stage profile/Allocation section. Specified Categories will validate Match Official eligibility.',
                ),
              },
            }}
            editable={{
              // 1st and 2nd referees are required and should not be deleted
              isDeletable: (rowData: MatchOfficialSettings) =>
                !referees?.includes(rowData.roleId),
              onRowAdd: allowMatchOfficialAdd ? handleAddMatchOfficial : null,
              onRowUpdate: handleRowUpdate,
              onRowDelete: allowMatchOfficialChange ? handleRowDelete : null,
            }}
            icons={{
              Add: forwardRef((iconProps, ref: React.Ref<HTMLDivElement>) => (
                <Button
                  {...iconProps}
                  ref={ref}
                  component="div"
                  variant="outlined"
                  color="primary"
                  startIcon={<AddCircleTwoTone />}
                  data-qa={ADD_BUTTON}
                >
                  {t('Add match official')}
                </Button>
              )),
            }}
            options={{ actionsColumnIndex: 3, toolbar: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const isLoadingSelector = createLoadingSelector([
  matchOfficialRolesActions.getMatchOfficialsRolesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  matchOfficialRoles: getMatchOfficialsRoles(state),
  matchOfficialGroups: getRefereeGroupList(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    members: bindActionCreators(membersActions, dispatch),
    matchOfficialGroups: bindActionCreators(
      matchOfficialGroupActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageMatchOfficialsBlock);
