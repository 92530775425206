import {
  all,
  call,
  cancel,
  cancelled,
  debounce,
  Effect,
  put,
  race,
  SagaReturnType,
  take,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { getSeasons } from '@core/pages/seasons/store/api/sagas';
import { formatPaginatedStoreWithIds, handleApiErrors } from '@core/helpers';
import actions from './actions';

export function* searchSeasonsFlow(
  action: InferAction<
    typeof actions.getSeasons | typeof actions.searchSeasonsByQuery
  >,
): Generator<Effect, void, any> {
  try {
    const { payload } = action;

    yield put(actions.getSeasonsRequest());
    const { error, response }: SagaReturnType<typeof getSeasons> = yield call(
      getSeasons,
      payload || {},
    );

    if (!error) {
      yield put(actions.getSeasonsSuccess());
      yield put(
        actions.setSeasons(
          formatPaginatedStoreWithIds('seasons')(response) as any,
        ),
      );
    } else {
      yield put(actions.getSeasonsFailure());
      yield call(handleApiErrors, error);
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.getSeasonsSuccess());
    }
  }
}

export function* getSeasonsFlow() {
  while (true) {
    const action: InferAction<typeof actions.getSeasons> = yield take(
      actions.getSeasons,
    );
    yield race([call(searchSeasonsFlow, action), take(actions.resetState)]);
  }
}

export function* watchSearchSeasonsByQuery() {
  while (true) {
    const task: Task = yield debounce(
      500,
      actions.searchSeasonsByQuery.toString(),
      searchSeasonsFlow,
    );
    yield take(actions.resetState);
    yield cancel(task);
  }
}

export default function* saga() {
  yield all([watchSearchSeasonsByQuery(), getSeasonsFlow()]);
}
