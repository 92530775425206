import { WeekDayLong } from '@core/types';

export const getWeekdayLong = (t: (key: string) => string, weekDay: string) => {
  switch (weekDay) {
    case WeekDayLong.MONDAY:
      return t('Monday');
    case WeekDayLong.TUESDAY:
      return t('Tuesday');
    case WeekDayLong.WEDNESDAY:
      return t('Wednesday');
    case WeekDayLong.THURSDAY:
      return t('Thursday');
    case WeekDayLong.FRIDAY:
      return t('Friday');
    case WeekDayLong.SATURDAY:
      return t('Saturday');
    case WeekDayLong.SUNDAY:
      return t('Sunday');
    default:
      return t(weekDay);
  }
};
