import { object, number, string } from 'yup';
import { TFunction } from 'i18next';

import { FIELD_NAMES } from '../constants';

export const getValidationSchema = (t: TFunction) =>
  object().shape({
    [FIELD_NAMES.title]: string().required(t('Required')),
    [FIELD_NAMES.numberOfTeams]: number()
      .min(2, t('Minimum {{value}} teams', { value: 2 }))
      .required(t('Required')),
  });
