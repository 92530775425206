import React from 'react';
import { Grid } from '@mui/material';

import { usePopperToggle } from '@core/hooks';
import { FIXTURE_CALENDAR_MATCH } from '@core/pages/dashboard/tests/test-ids';
import { MatchStatus, MatchFixture } from '@core/types';

import { eventStyles } from '../styles';
import FixtureCalendarPopup from './fixture-calendar-popup';
import { getConflictLevel } from '../helpers';

interface OwnProps {
  match: MatchFixture;
  className: string;
}

type Props = OwnProps;

const FixtureCalendarEvent = ({ match }: Props) => {
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();
  const conflictLevel = getConflictLevel(match.conflicts);
  const isCompleted = match?.status === MatchStatus.COMPLETE;
  const classes = eventStyles({ conflictLevel, selected: isOpen, isCompleted });

  const showPopup = handleToggle;
  const closePopup = handleClose;

  return (
    <Grid className={classes.root}>
      <Grid
        className={classes.event}
        data-qa={FIXTURE_CALENDAR_MATCH}
        id={`calendar-event-${match.id}`}
        data-match-id={match.id}
        ref={anchorRef}
        onClick={showPopup}
      />
      {anchorRef?.current && (
        <FixtureCalendarPopup
          data={match}
          open={isOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          handleClose={closePopup}
        />
      )}
    </Grid>
  );
};

export default FixtureCalendarEvent;
