import restService from '@core/api/rest-service';
import {
  Player,
  PlayerAttributes,
  PlayersByPersonResponse,
  PlayersResponse,
} from '@core/types';

export const getClubPlayersBySport = (queryParams: any = { page: 1 }) =>
  restService.get<PlayersResponse>(`/v1/players`, {
    params: queryParams,
  });

export const createClubPlayer = (
  clubId: number,
  sportId: Array<number>,
  personId: number,
) =>
  restService.post<Player>(`/v1/clubs/${clubId}/players`, {
    sportId,
    personId,
  });

export const updatePlayerAttributes = (
  personId: number,
  data: PlayerAttributes,
) => restService.patch<void>(`/v1/persons/${personId}/player/attributes`, data);

export const getPlayerAttributes = (personId: number) =>
  restService.get<PlayerAttributes>(
    `/v1/persons/${personId}/player/attributes`,
  );

export const getPlayerListByPerson = (personId: number) =>
  restService.get<PlayersByPersonResponse>(`/v1/persons/${personId}/players`);

export const deletePlayer = (playerId: number) =>
  restService.delete<void>(`/v1/players/${playerId}`);
