import {
  pipe,
  identity,
  pathEq,
  ifElse,
  assocPath,
  dissoc,
  prop,
  assoc,
} from 'ramda';

import { PlayerKitEntities } from '@core/types';

const checkJersey = (entity: PlayerKitEntities) =>
  ifElse(
    pathEq([entity, 'jerseyPattern'], 'MONOCHROME'),
    assocPath([entity, 'secondaryJerseyColorCode'], null),
    identity,
  );

const checkSocks = (entity: PlayerKitEntities) =>
  ifElse(
    pathEq([entity, 'socksPattern'], 'MONOCHROME'),
    assocPath([entity, 'secondarySocksColorCode'], null),
    identity,
  );

const checkShorts = (entity: PlayerKitEntities) =>
  ifElse(
    pathEq([entity, 'shortsPattern'], 'MONOCHROME'),
    assocPath([entity, 'secondaryShortsColorCode'], null),
    identity,
  );

const checkItems = (entity: PlayerKitEntities) =>
  pipe(checkJersey(entity), checkSocks(entity), checkShorts(entity));

const cleanUnusedColors = pipe(
  checkItems(PlayerKitEntities.AWAY_PLAYER_KIT),
  checkItems(PlayerKitEntities.HOME_PLAYER_KIT),
  checkItems(PlayerKitEntities.RESERVED_PLAYER_KIT),
);

const cleanDisabledReservedPlayerKit = ifElse(
  prop('reservedPlayerKitIsEnabled') as any,
  identity,
  assoc('reservedPlayerKit', null),
);

export const formatSubmitKitSetData = pipe(
  cleanUnusedColors,
  cleanDisabledReservedPlayerKit,
  dissoc('reservedPlayerKitIsEnabled'),
);

export const formatUpdateKitSetData = pipe(
  formatSubmitKitSetData,
  dissoc('id'),
);
