import React from 'react';
import { Grid, Alert, AlertTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { length } from 'ramda';
import { connect } from 'react-redux';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDate } from '@core/helpers';
import {
  CompetitorPlayerList,
  PlayerListErrorType,
  PlayerList,
  CustomerResponse,
} from '@core/types';
import { getPlayerListValidationErrors } from '@core/store/modules/tabs/player-list-management/helpers';
import { State } from '@core/store';

export interface OwnProps {
  maxPlayers: number;
  minPlayers: number;
  earliestPlayerDateOfBirth: string;
  addedCompetitorPlayers: CompetitorPlayerList | PlayerList;
}

interface StateProps {
  currentCustomer: CustomerResponse;
}

type Props = OwnProps & StateProps;

const PlayerListAlert = ({
  maxPlayers,
  minPlayers,
  earliestPlayerDateOfBirth,
  addedCompetitorPlayers,
  currentCustomer,
}: Props) => {
  const playerListValidationErrors = getPlayerListValidationErrors(
    maxPlayers,
    minPlayers,
    earliestPlayerDateOfBirth,
    addedCompetitorPlayers,
  );
  const { t } = useTranslation();
  if (length(playerListValidationErrors) <= 0) return <></>;

  return (
    <Box mb={2}>
      <Alert severity="warning">
        <AlertTitle>
          <strong>{t('Player list is not complying with requirements')}</strong>
        </AlertTitle>
        {playerListValidationErrors.includes(
          PlayerListErrorType.TOTAL_PLAYERS,
        ) && (
          <Grid>{`• ${t(
            'Allowed number of Players in Player list',
          )}: ${minPlayers} min - ${maxPlayers} max`}</Grid>
        )}
        {playerListValidationErrors.includes(
          PlayerListErrorType.AGE_VALIDATION,
        ) && (
          <Grid>{`• ${t('Age restrictions')}: ${t('born after')} ${formatDate(
            currentCustomer?.dateFormat,
            earliestPlayerDateOfBirth,
          )}`}</Grid>
        )}
      </Alert>
    </Box>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(PlayerListAlert);
