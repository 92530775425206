import { path, isEmpty } from 'ramda';

import { getVenuesById } from '@core/pages/venues/store/api/selectors';
import { VenueModel, Paginated, Competitor, APIErrorsModel } from '@core/types';
import { NAMESPACE as competitorsNamespace } from '@core/store/modules/api/competitors';
import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getCompetitor = (
  state: State,
  { competitorId }: { competitorId: number },
): Competitor => path(['api', competitorsNamespace, competitorId], state);

export const getSubmitErrors = (
  state: State,
  { competitorId }: { competitorId: number },
): APIErrorsModel => path(['tabs', NAMESPACE, competitorId, 'errors'], state);

export const getDefaultVenueOptions = (
  state: State,
  { competitorId }: { competitorId: number },
): Paginated<VenueModel> => {
  const venuesPerTab: Paginated<number> = {
    ...path(['tabs', NAMESPACE, competitorId, 'defaultVenuesList'], state),
  };

  return venuesPerTab && !isEmpty(venuesPerTab)
    ? { ...venuesPerTab, list: getVenuesById(state, venuesPerTab.list) }
    : null;
};

export const getAlternativeVenueOptions = (
  state: State,
  { competitorId }: { competitorId: number },
): Paginated<VenueModel> => {
  const venuesPerTab: Paginated<number> = {
    ...path(['tabs', NAMESPACE, competitorId, 'alternativeVenuesList'], state),
  };

  return venuesPerTab && !isEmpty(venuesPerTab)
    ? { ...venuesPerTab, list: getVenuesById(state, venuesPerTab.list) }
    : null;
};
