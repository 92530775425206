import React from 'react';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

import { LanguageDirections } from '@core/types';
import { languageDirection } from '@core/helpers';

const ArrowForward = () => {
  if (languageDirection === LanguageDirections.RTL) return <ArrowBackIcon />;

  return <ArrowForwardIcon />;
};

export default ArrowForward;
