import { createAction } from 'redux-actions';

import { KitSetCreateData } from '@core/types';

import { NAMESPACE } from './constants';

const createKitSet = createAction<{
  clubId: number;
  data: KitSetCreateData;
  competitionId: number | null;
  tabId: string;
}>(`${NAMESPACE}/CREATE_KIT_SET`);
const createKitSetRequest = createAction<{ clubId: number }>(
  `${NAMESPACE}/CREATE_KIT_SET_REQUEST`,
);
const createKitSetSuccess = createAction<{ clubId: number }>(
  `${NAMESPACE}/CREATE_KIT_SET_SUCCESS`,
);
const createKitSetFailure = createAction<{ clubId: number }>(
  `${NAMESPACE}/CREATE_KIT_SET_FAILURE`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export const actions = {
  createKitSet,
  createKitSetRequest,
  createKitSetSuccess,
  createKitSetFailure,
  resetState,
};
