import { pathOr } from 'ramda';

import { Paginated, MatchAllocation } from '@core/types';

import { getMatchesStatus } from './helpers';
import { NAMESPACE } from '.';

export const getMatchesAllocation = (id: number) =>
  pathOr<Paginated<MatchAllocation>>(null, [
    NAMESPACE,
    'byGroupId',
    id,
    'paginated',
  ]);

export const getAllocations = (id: number) =>
  pathOr<ReturnType<typeof getMatchesStatus>>(null, [
    NAMESPACE,
    'byGroupId',
    id,
    'allocations',
  ]);
