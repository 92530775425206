import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as clubProfileSelectors from '@core/pages/club-profile/store/selectors';
import * as personsSelectors from '@core/pages/persons/store/selectors';
import { default as clubProfileActions } from '@core/pages/club-profile/store/actions';
import { actions as personsPageActions } from '@core/pages/persons/store/actions';
import { State } from '@core/store';
import * as tabsSelectors from '@core/store/modules/ui/tabs/selectors';
import { createURL } from '@core/helpers';
import paths from '@core/routes/paths';
import { useCoreDispatch } from './store';

export function usePersonMutatedHelpers(options: { clubId: number }) {
  const { clubId } = options;

  const tabs = useSelector(tabsSelectors.getTabsList);

  const isTabOpened = useCallback(
    (url: string) => tabs.some((tab) => tab.name === url),
    [tabs],
  );

  const clubProfilePersonsExist = !!useSelector((state: State) =>
    clubProfileSelectors.getMembersPaginated(state, { clubId }),
  );

  const personsExist = !!useSelector((state) =>
    personsSelectors.getPersonsPaginated(state),
  );

  const dispatch = useCoreDispatch();

  const refreshPersonsListInAnyTab = useCallback(() => {
    isTabOpened(createURL(paths.clubProfile)) &&
      clubProfilePersonsExist &&
      dispatch(clubProfileActions.getMembers({ clubId }));

    isTabOpened(createURL(paths.persons)) &&
      personsExist &&
      dispatch(personsPageActions.getPersons({}));
  }, [clubId, clubProfilePersonsExist, dispatch, isTabOpened, personsExist]);

  return { refreshPersonsListInAnyTab };
}
