// src: http://www.localeplanet.com/icu/index.html

interface LocaleToLanguageNameMap {
  [locale: string]: {
    formalName: string; // English name of Language
    nativeName: string;
    code: string; // ISO 639-1 Code
    hidden?: boolean;
  };
}

const localeToLanguageNameMap: LocaleToLanguageNameMap = {
  'en-US': {
    formalName: 'English',
    nativeName: 'English',
    code: 'en',
  },
  'es-ES': {
    formalName: 'Spanish',
    nativeName: 'español',
    code: 'es',
  },
  'fr-FR': {
    formalName: 'French',
    nativeName: 'français',
    code: 'fr',
  },
  'ru-RU': {
    formalName: 'Russian',
    nativeName: 'русский',
    code: 'ru',
  },
  'pt-MZ': {
    formalName: 'Portuguese',
    nativeName: 'português',
    code: 'pt',
  },
};

export default localeToLanguageNameMap;
