import { FastField, FastFieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, IconButton } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import { TextField, CustomTable, NumberField } from '@core/components';
import { Column } from '@core/components/material-table';
import { FIELD_NAMES, Round } from '../../../constants';
import * as testIds from '../../../tests/test-ids';
import type { FormValues } from '../../../helpers/initialValues';
import type { TFunction } from 'i18next';

function getRoundColumnTitleByFieldName(
  fieldName: OwnProps['name'],
  t: TFunction,
): string {
  switch (fieldName) {
    case FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups:
      return `${t('Finals Round')} *`;
    case FIELD_NAMES.knockOutDoubleEliminationLosersGroups:
      return `${t('Losers Round')} *`;
    case FIELD_NAMES.knockOutDoubleEliminationWinnersGroups:
      return `${t('Winners Round')} *`;
    case FIELD_NAMES.knockOutExtraGroups:
    case FIELD_NAMES.knockoutSingleEliminationGroups:
      return `${t('Round')} *`;
  }
}

interface OwnProps {
  name:
    | FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups
    | FIELD_NAMES.knockOutDoubleEliminationLosersGroups
    | FIELD_NAMES.knockOutDoubleEliminationWinnersGroups
    | FIELD_NAMES.knockOutExtraGroups
    | FIELD_NAMES.knockoutSingleEliminationGroups;
  onDelete?: () => void;
}

type Props = OwnProps;

const RoundsTable = (props: Props) => {
  const { name, onDelete } = props;

  const { values } = useFormikContext<FormValues>();
  const groups = values[name];

  const { t } = useTranslation();

  const isNarrow =
    name === FIELD_NAMES.knockOutDoubleEliminationLosersGroups ||
    name === FIELD_NAMES.knockOutDoubleEliminationWinnersGroups;

  const columns = new Array<Column<Round>>(
    {
      title: getRoundColumnTitleByFieldName(name, t),
      render: (rowData) => {
        return (
          <Grid container alignItems="center">
            <TextField
              name={`${name}.${rowData.index}.title`}
              type="text"
              width={isNarrow ? '160px' : '220px'}
              smallInput
              required
            />
            {rowData.description && (
              <Typography variant="caption" color="textSecondary" ml={1.25}>
                {rowData.description}
              </Typography>
            )}
          </Grid>
        );
      },
    },
    {
      title: `${t('Best of matches')} *`,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => {
        return (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <NumberField
                name={`${name}.${rowData.index}.bestOf`}
                min={1}
                smallInput
                required
                width={isNarrow ? '50px' : undefined}
              />
            </Grid>
            {onDelete &&
              name === FIELD_NAMES.knockOutExtraGroups &&
              rowData.index === groups.length - 1 && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={onDelete}
                    title={t('Delete')}
                    sx={{ mr: '-70px' }}
                    data-qa={testIds.STAGE_REMOVE_EXTRA_ROUND_BUTTON}
                  >
                    <DeleteTwoTone />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        );
      },
    },
  );

  return (
    <FastField name={name}>
      {(fastFieldProps: FastFieldProps) => {
        return (
          <CustomTable
            columns={columns}
            data={fastFieldProps.field.value || []}
            localization={{
              body: {
                emptyDataSourceMessage:
                  name === FIELD_NAMES.knockOutExtraGroups
                    ? t('No extra rounds to display yet. Add round.')
                    : t('No rounds to display yet. Set number of teams.'),
              },
            }}
            noPaper
            topBorder={
              name === FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups ||
              name === FIELD_NAMES.knockOutExtraGroups
            }
          />
        );
      }}
    </FastField>
  );
};

export default RoundsTable;
