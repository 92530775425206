import React, { Children } from 'react';
import { Grid, GridProps } from '@mui/material';

export type GridItemsProps = Omit<GridProps, 'item' | 'container'>;

const GridItems = (props: GridItemsProps) => {
  const { children, ...rest } = props;

  return (
    <>
      {Children.map(children, (child, index) => (
        <Grid item key={index} {...rest}>
          {child}
        </Grid>
      ))}
    </>
  );
};

export default GridItems;
