import { useSelector } from 'react-redux';
import { CurrentUserLinks } from '@core/types/api';
import {
  getCurrentUser,
  getAccessLinks,
} from '@core/pages/user-profile/store/selectors';
import { State } from '@core/store';

export function useCurrentUser() {
  const user =
    useSelector(getCurrentUser) || ({} as ReturnType<typeof getCurrentUser>);
  const hasUser = !!Object.keys(user).length;
  const accessLinks: CurrentUserLinks = useSelector((state: State) =>
    getAccessLinks(state),
  );

  return {
    user,
    hasUser,
    accessLinks,
  };
}
