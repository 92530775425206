import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip';
import { Tooltip, IconButton } from '@mui/material';
import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Props = Omit<TooltipProps, 'children'>;

const TooltipComponent = ({ title, ...tooltipProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip enterTouchDelay={0} title={t(title as string)} {...tooltipProps}>
      <IconButton size="small">
        <HelpIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipComponent;
