import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { SearchField, ColumnWithSubValue, GridItems } from '@core/components';
import modalStyles from '@core/components/global-modal/styles';
import { Club } from '@core/icons';
import { GlobalModalProps, Competitor } from '@core/types';
import teamGridActions from '@core/store/modules/tabs/team-grid';
import { getCompetitionCompetitors } from '@core/store/modules/tabs/team-grid/selectors';
import { State } from '@core/store';

import { Slot, Slots } from '../types';
import { TEAM_GRID_MODAL_POPULATE_BUTTON } from '../tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

type DispatchProps = {
  actions: {
    teamGrid: typeof teamGridActions;
  };
};

type StateProps = {
  competitors: Array<Competitor>;
};

export interface OwnProps {
  applyAction: (competitors: Array<Competitor>) => void;
  competitionId: number;
  competitorsLimit: number;
  groupTitle: string;
  slots: Slots;
}

type SearchHandler = (params: { query: string; page: number }) => void;

type Props = GlobalModalProps<OwnProps> & DispatchProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    height: 350,
    overflow: 'auto',
  },
  selectAll: {
    margin: 0,
  },
  selectAllWarning: {
    color: theme.palette.error.main,
  },
  modalContent: {
    overflowY: 'unset',
  },
}));

const getCompetitorsFromSlots = (slots: Slots) =>
  slots.map((slot) => slot.competitor).filter(Boolean) || [];

const findSlotByCompetitorId = (
  slots: Slots,
  competitorId: Competitor['id'],
): Slot => slots.find((slot) => slot.competitor?.id === competitorId);

const TeamGridMultiteamSelectModal = (props: Props) => {
  const {
    actions,
    competitors,
    isModalOpen,
    modalActions,
    modalParams: {
      applyAction,
      competitionId,
      competitorsLimit,
      groupTitle,
      slots,
    },
  } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCompetitors, setSelectedCompetitors] = useState<
    Array<Competitor>
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const multiteamSelectClasses = useStyles();
  const modalClasses = modalStyles();
  const { t } = useTranslation();

  useEffect(() => {
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
    });
  }, [actions.teamGrid, competitionId]);

  useEffect(() => {
    const checkedCompetitors = getCompetitorsFromSlots(slots);
    setSelectAll(checkedCompetitors.length === competitorsLimit);
    setSelectedCompetitors(checkedCompetitors);
  }, [competitorsLimit, slots]);

  const toggleSelectAllCompetitors = () => {
    const newSelectAll = !selectAll;
    const isCompetitorCheckable = (competitor: Competitor) =>
      findSlotByCompetitorId(slots, competitor.id)?.editable ?? true;
    const checkableCompetitors = competitors.filter((competitor) =>
      isCompetitorCheckable(competitor),
    );
    const uncheckableCompetitors = competitors.filter(
      (competitor) => !isCompetitorCheckable(competitor),
    );

    const checkedCompetitors: Array<Competitor> = [
      ...(newSelectAll && selectedCompetitors
        ? checkableCompetitors.slice(
            0,
            competitorsLimit - uncheckableCompetitors.length,
          )
        : []),
      ...uncheckableCompetitors,
    ];

    setSelectAll(newSelectAll);
    setSelectedCompetitors(checkedCompetitors);
  };

  const reset = (reason?: string) => {
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
    });
    setSearchQuery('');
    if (reason !== 'accept') {
      const checkedCompetitors = getCompetitorsFromSlots(slots);
      setSelectAll(checkedCompetitors.length === competitorsLimit);
      setSelectedCompetitors(checkedCompetitors);
    }
  };

  const handleClose = (event: unknown, reason?: string) => {
    modalActions.closeModal();
    reset(reason);
  };

  const handleSearch: SearchHandler = ({ query }) => {
    setSearchQuery(query);
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
      query,
    });
  };

  const isCompetitorSelected = (competitor: Competitor) =>
    selectedCompetitors.some(
      (selectedCompetitor) => selectedCompetitor.id === competitor.id,
    );

  const selectCompetitor = (competitor: Competitor) => () => {
    isCompetitorSelected(competitor)
      ? setSelectedCompetitors(
          selectedCompetitors.filter(
            (selectedCompetitor) => selectedCompetitor.id !== competitor.id,
          ),
        )
      : setSelectedCompetitors([...selectedCompetitors, competitor]);
  };

  const handleApply = () => {
    applyAction(selectedCompetitors);
    handleClose(undefined, 'accept');
  };

  const isExtraSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Dialog open={isModalOpen} maxWidth="md" fullWidth>
      <DialogTitle>{t('Populate slots')}</DialogTitle>
      <DialogContent className={modalClasses.content}>
        <Box pb={2}>
          <Grid
            container
            alignItems="flex-start"
            spacing={isExtraSmall ? 3 : 4.5}
          >
            <Grid item xs={12} flex={1}>
              <Typography variant="body2">
                {`${t('Select teams which play in ')} `}
                <Typography component="span" style={{ fontWeight: 'bold' }}>
                  {groupTitle}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <SearchField query={searchQuery} onSearch={handleSearch} />
            </Grid>
          </Grid>
          <Box m={1} mt={3} mb={2} ml={0} mr={0}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectAll ||
                      selectedCompetitors.length === competitorsLimit
                    }
                    disabled={
                      !competitors || !slots.some((slot) => slot.editable)
                    }
                    onChange={toggleSelectAllCompetitors}
                    name="selectAll"
                    indeterminate={
                      selectedCompetitors.length < competitorsLimit
                    }
                    disableRipple
                  />
                }
                label={
                  <>
                    <Typography variant="body2">{`${t('Select all')} (${
                      selectedCompetitors.length
                    } ${t('selected')})`}</Typography>
                    <FormHelperText
                      className={clsx(
                        multiteamSelectClasses.selectAll,
                        selectedCompetitors.length > competitorsLimit &&
                          multiteamSelectClasses.selectAllWarning,
                      )}
                    >
                      Max. {competitorsLimit} teams in this pool
                    </FormHelperText>
                  </>
                }
              />
            </FormControl>
          </Box>
          <Divider />
          <Box m={1} mt={1} mr={0} mb={1} ml={0}>
            <Grid
              container
              className={multiteamSelectClasses.list}
              alignContent="flex-start"
              spacing={1}
            >
              {competitors?.map((competitor: Competitor) => (
                <Grid item xs={12} sm={6} md={4} key={competitor.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCompetitorSelected(competitor)}
                        disabled={
                          !(
                            findSlotByCompetitorId(slots, competitor.id)
                              ?.editable ?? true
                          )
                        }
                        onChange={selectCompetitor(competitor)}
                        name={competitor.title}
                        disableRipple
                      />
                    }
                    label={
                      <ColumnWithSubValue
                        value={competitor.title}
                        valueTypographyProps={{ variant: 'subtitle2' }}
                        subValue={competitor.internationalTitle}
                        logo={{
                          url:
                            competitor.logoFileLink ||
                            competitor.clubLogoFileLink,
                          defaultIcon: <Club />,
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className={modalClasses.actions}>
        <Grid container spacing={1} justifyContent="flex-end">
          <GridItems>
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <ButtonWithProgress
              variant="contained"
              onClick={handleApply}
              data-qa={TEAM_GRID_MODAL_POPULATE_BUTTON}
            >
              {t('Populate')}
            </ButtonWithProgress>
          </GridItems>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    teamGrid: bindActionCreators(teamGridActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  ownProps: GlobalModalProps<OwnProps>,
): StateProps => ({
  competitors: getCompetitionCompetitors(
    state,
    ownProps.modalParams.competitionId,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamGridMultiteamSelectModal);
