import { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ExpansionPanelTitle } from '@core/components';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as competitorKitSetActions } from '@core/store/modules/tabs/competitor-kit-set';
import { getKitSets } from '@core/store/modules/tabs/competitor-kit-set/selectors';
import { KitSetList } from '@volleyball/types';
import { State } from '@volleyball/store';
import KitSetSelectionForm from '@volleyball/business-components/kit-set-selection-form';

interface DispatchProps {
  actions: {
    competitorKitSet: typeof competitorKitSetActions;
  };
}

interface StateProps {
  kitSets: KitSetList;
  isSubmitting: boolean;
}

interface OwnProps {
  competitorId: number;
  clubId: number;
  competitionId: number;
  teamId: number;
  tabId: string;
  kitSetId?: number;
}

type Props = DispatchProps & OwnProps & StateProps;

const CompetitorKitSet = (props: Props) => {
  const { t } = useTranslation();
  const {
    actions,
    clubId,
    competitorId,
    kitSets,
    competitionId,
    teamId,
    tabId,
    kitSetId,
    isSubmitting,
  } = props;

  useEffect(() => {
    actions.competitorKitSet.getKitSets({ clubId, competitorId });

    return () => {
      actions.competitorKitSet.resetCompetitor({ competitorId });
    };
  }, []);

  function handleInputChange() {
    actions.competitorKitSet.getKitSets({
      clubId,
      competitorId,
    });
  }

  function renderForm(formikProps: FormikProps<any>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTabStateSetter(formikProps.dirty);

    return (
      <KitSetSelectionForm
        kitSets={kitSets}
        isSubmitting={isSubmitting}
        onInputChange={handleInputChange}
        tabId={tabId}
      />
    );
  }

  function handleSubmit(/*values: { kitSet: number }*/) {
    actions.competitorKitSet.updateCompetitorKitSet({
      competitionId,
      teamId,
      competitorId,
      // tabId,
      data: {
        //kitSetId: values?.kitSet,
      },
    });
  }

  return (
    <Accordion expanded>
      <AccordionSummary className="without-cursor">
        <ExpansionPanelTitle title={t('Team kits')} />
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          initialValues={{ kitSet: kitSetId }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorKitSet: bindActionCreators(competitorKitSetActions, dispatch),
  },
});

const isSubmittingSelector = createLoadingSelector([
  competitorKitSetActions.updateCompetitorKitSetRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  kitSets: getKitSets(state, { competitorId: ownProps.competitorId }),
  isSubmitting: isSubmittingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorKitSet);
