import { call, put, all, take, Effect } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as userRoleApi from '@core/api/user-role';

import { actions } from '.';

export function* getUserRoles(): Generator<Effect, any, any> {
  while (true) {
    yield take(actions.getUserRoles);
    yield put(actions.getUserRolesRequest());
    const response = yield call(userRoleApi.getUserRoles);

    if (!response.error) {
      yield put(actions.setUserRoles(response.response.userRoles));
      yield put(actions.getUserRolesSuccess());
    } else {
      yield put(actions.getUserRolesFailure());
      yield call(handleApiErrors, response.error);
    }
  }
}

export default function* saga() {
  yield all([getUserRoles()]);
}
