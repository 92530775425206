import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Alert, AlertTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddedLineupPlayerErrors, MatchPlayerOrigin } from '@core/types';
import { getLineupPlayersDataErrors } from '@core/store/modules/tabs/match-sheet-management/selectors';
import { State } from '@core/store';

type Props = {
  matchPlayerOrigin: MatchPlayerOrigin;
  matchId: number;
};

const MatchSheetValidations = ({ matchId, matchPlayerOrigin }: Props) => {
  const { t } = useTranslation();
  const errorRef = useRef<HTMLDivElement>(null);
  const lineupDataErrors: AddedLineupPlayerErrors = useSelector(
    (state: State) =>
      getLineupPlayersDataErrors(state, { matchId, matchPlayerOrigin }),
  );

  useEffect(() => {
    if (lineupDataErrors) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [lineupDataErrors]);

  if (!lineupDataErrors) return null;

  return (
    <Box mb={2}>
      <Alert severity="error" ref={errorRef}>
        <AlertTitle>
          <strong>{t('Mandatory players are missing!')}</strong>
        </AlertTitle>
        {!lineupDataErrors?.isCaptain && (
          <Grid>{`• ${t('Please mark which player is a captain (C).')}`}</Grid>
        )}
        {!lineupDataErrors?.isLibero && (
          <Grid>{`• ${t('Please mark at least 1 libero player.')}`}</Grid>
        )}
      </Alert>
    </Box>
  );
};

export default MatchSheetValidations;
