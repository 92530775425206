import React from 'react';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import { PersonNameColumn, CustomTable } from '@core/components';
import { getGenders } from '@core/helpers';
import {
  AddedMatchTeamOfficial,
  AddedMatchTeamOfficialList,
} from '@core/types';
import { getTitle, APP_TITLES } from '@core/helpers/app-sports/title-getters';

interface OwnProps {
  officials: AddedMatchTeamOfficialList;
}

type Props = OwnProps;

const TeamOfficialsTable = (props: Props) => {
  const { t } = useTranslation();
  const { officials } = props;

  const columns = [
    {
      title: t('Role'),
      field: 'role',
      render: (rowData: AddedMatchTeamOfficial) =>
        t(pathOr(null, ['teamOfficialRole', 'title'], rowData)) ||
        t(
          pathOr(
            '-',
            ['competitorTeamOfficial', 'teamOfficialRole', 'title'],
            rowData,
          ),
        ),
    },
    {
      title: t('Name'),
      field: 'name',
      render: (rowData: AddedMatchTeamOfficial) => (
        <PersonNameColumn
          person={pathOr(
            null,
            ['competitorTeamOfficial', 'teamOfficial'],
            rowData,
          )}
        />
      ),
    },
    {
      title: getTitle(APP_TITLES.POPULAR_NAME),
      field: 'competitorTeamOfficial.teamOfficial.popularName',
    },
    {
      title: t('Gender'),
      field: 'competitorTeamOfficial.teamOfficial.gender',
      render: (rowData: any) =>
        getGenders(t, rowData.competitorTeamOfficial.teamOfficial.gender),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      titleRow={t('Team officials')}
      connectedTable
      mobileTable
      data={officials || []}
      noPaper
    />
  );
};

export default TeamOfficialsTable;
