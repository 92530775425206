import { put, call, Effect } from 'redux-saga/effects';

import * as appointmentAPI from '@core/api/appointment';
import { MatchStatus } from '@core/types';

import { actions } from '.';

export function* getAppointments(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(actions.getAppointmentsRequest());
  const response = (yield call(
    appointmentAPI.getAppointmentsList,
    queryParams,
  )) as InferApi<typeof appointmentAPI.getAppointmentsList>;

  if (!response.error) {
    yield put(actions.getAppointmentsSuccess());
    // TODO: appointments: after switching to appointments' own endpoint, this should be changed to response.response?._embedded?.appointments
    yield put(actions.setAppointments(response.response?._embedded?.matches));
  } else {
    yield put(actions.getAppointmentsFailure());
  }

  return response;
}

export function* getGroupsAppointments(
  groupId: number,
  queryParams: { page?: number; query?: string } = {},
): Generator<Effect, any, any> {
  yield put(actions.getAppointmentsByGroupRequest({ id: groupId }));
  const response = (yield call(
    appointmentAPI.getGroupsAppointmentsList,
    groupId,
    queryParams,
  )) as InferApi<typeof appointmentAPI.getGroupsAppointmentsList>;

  if (!response.error) {
    yield put(actions.getAppointmentsByGroupSuccess({ id: groupId }));
    yield put(actions.setAppointments(response.response?._embedded?.matches));
  } else {
    yield put(actions.getAppointmentsByGroupFailure({ id: groupId }));
  }

  return response;
}

export function* getAppointmentsByCompetition(
  competitionId: number,
  queryParams: { page?: number; query?: string } = {},
): Generator<Effect, any, any> {
  yield put(actions.getAppointmentsByCompetitionRequest({ id: competitionId }));

  const response = (yield call(
    appointmentAPI.getAppointmentsByCompetition,
    competitionId,
    queryParams,
  )) as InferApi<typeof appointmentAPI.getAppointmentsByCompetition>;

  if (!response.error) {
    yield put(
      actions.getAppointmentsByCompetitionSuccess({ id: competitionId }),
    );
    yield put(actions.setAppointments(response.response?._embedded?.matches));
  } else {
    yield put(
      actions.getAppointmentsByCompetitionFailure({ id: competitionId }),
    );
  }

  return response;
}

export function* getAppointment(
  appointmentId: number,
): Generator<Effect, any, any> {
  yield put(actions.getAppointmentRequest());
  const response = (yield call(
    appointmentAPI.getAppointmentById,
    appointmentId,
  )) as InferApi<typeof appointmentAPI.getAppointmentById>;

  if (!response.error) {
    yield put(actions.getAppointmentSuccess());
    yield put(actions.setAppointment(response.response));
  } else {
    yield put(actions.getAppointmentFailure());
  }

  return response;
}

export function* updateAppointment(
  url: string,
  data: any,
): Generator<Effect, any, any> {
  yield put(actions.updateAppointmentRequest());
  const response = (yield call(
    appointmentAPI.updateAppointment,
    url,
    data,
  )) as InferApi<typeof appointmentAPI.updateAppointment>;

  if (!response.error) {
    yield put(actions.updateAppointmentSuccess());
  } else {
    yield put(actions.updateAppointmentFailure());
  }

  return response;
}

export function* changeAppointmentStatus({
  appointmentId,
  status,
}: {
  appointmentId: number;
  status: MatchStatus;
}): Generator<Effect, any, any> {
  yield put(actions.changeAppointmentStatusRequest());
  const response = (yield call(
    appointmentAPI.changeAppointmentStatus,
    appointmentId,
    status,
  )) as InferApi<typeof appointmentAPI.changeAppointmentStatus>;

  if (!response.error) {
    yield put(actions.changeAppointmentStatusSuccess());
  } else {
    yield put(actions.changeAppointmentStatusFailure());
  }

  return response;
}
