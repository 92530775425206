import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import { Group } from '@core/types';
import StageTitleDescriptionBlock from './components/stage-title-description-block';
import StageTypeFormatBlock from './components/stage-type-format-block';
import { getInitialValues } from './helpers/initialValues';
import { getValidationSchema } from './helpers/validationSchema';

interface OwnProps {
  initialValues?: Group;
  isEditMode?: boolean;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
}

export type Props = OwnProps;

const StageGeneralInformationTemplate = forwardRef<FormikProps<any>, Props>(
  ({ initialValues, isEditMode, onSubmit }, ref) => {
    const { t } = useTranslation();

    const handleSubmit: Props['onSubmit'] = (values, formikHelpers) => {
      onSubmit(values, formikHelpers);
    };

    const renderForm = (formikProps: FormikProps<any>) => {
      const { dirty } = formikProps;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTabStateSetter(dirty);

      return (
        <Form noValidate>
          <Grid container spacing={2}>
            <StageTitleDescriptionBlock />
            {!isEditMode && <StageTypeFormatBlock />}
          </Grid>
        </Form>
      );
    };

    return (
      <>
        <Grid container item xs={12} justifyContent="flex-end">
          <Box mb={3}>
            <Typography variant="caption" color="textSecondary">
              {`* ${t('Indicates a required field')}`}
            </Typography>
          </Box>
        </Grid>
        <Formik
          enableReinitialize
          validateOnChange
          validationSchema={getValidationSchema(t)}
          initialValues={getInitialValues(initialValues)}
          onSubmit={handleSubmit}
          innerRef={ref}
        >
          {renderForm}
        </Formik>
      </>
    );
  },
);

export default StageGeneralInformationTemplate;
