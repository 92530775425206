import React from 'react';
import {
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Filters, FilterTabsList } from '@core/types';
import { actions as filterActions } from '@core/store/modules/ui/filter';
import { usePopperToggle } from '@core/hooks';
import FilterContent from './components/filter-content';
import MobileFilterContent from './components/mobile-filter-content';
import { FILTERS_BUTTON } from './components/tests/testIds';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    height: 556,
    width: 414,
  },
  touched: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  noTitle: {
    minWidth: 'auto',
    '@global': {
      span: {
        margin: 0,
      },
    },
  },
}));

type OwnProps = {
  tabsList: FilterTabsList;
  onSave: (filters: Filters) => void;
  initialFilters?: Filters;
  withTitle?: boolean;
};

type DispatchProps = {
  actions: {
    filter: typeof filterActions;
  };
};

type Props = OwnProps & DispatchProps;

const Filter = ({
  tabsList,
  actions,
  onSave,
  initialFilters,
  withTitle = false,
}: Props) => {
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const renderFilterContent = (
    <FilterContent
      onSave={onSave}
      tabsList={tabsList}
      handleClose={handleClose}
    />
  );

  const onClose = (event: any) => {
    handleClose(event);
    actions.filter.clearFilters();
  };

  const setInitialFilterValues = () =>
    !isOpen && initialFilters && actions.filter.setFilters(initialFilters);

  const handleFilterToggle = (event: React.MouseEvent<EventTarget>) => {
    setInitialFilterValues();
    handleToggle(event);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        className={clsx({
          [classes.touched]: isOpen,
          [classes.noTitle]: !withTitle,
        })}
        onClick={handleFilterToggle}
        variant="outlined"
        startIcon={<FilterListIcon />}
        data-qa={FILTERS_BUTTON}
      >
        {withTitle && t('Filters')}
      </Button>
      {!isSmallScreen && (
        <Popper
          className={classes.popper}
          open={isOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={onClose}>
            <Paper className={classes.paper}>{renderFilterContent}</Paper>
          </ClickAwayListener>
        </Popper>
      )}
      {isSmallScreen && (
        <MobileFilterContent
          isOpen={isOpen}
          onSave={onSave}
          tabsList={tabsList}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    filter: bindActionCreators(filterActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Filter);
