// @ts-nocheck
import { Component } from 'react';
import { Formik, Form } from 'formik';
import { Paper } from '@mui/material';

interface OwnProps {
  validationSchema?: any;
  initialValues: object;
  noPaper?: boolean;
}

export interface ContainerProps {
  children: [Component];
  style: object;
}

type Props = OwnProps & ContainerProps;

export const FormikMTContainer = (props: Props) => {
  const handleSubmit = () => {
    // stub function, submition is happening on the row level
  };

  return (
    <Formik
      validationSchema={props.validationSchema}
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
    >
      <Form>
        {props.noPaper && props.children}
        {!props.noPaper && <Paper>{props.children}</Paper>}
      </Form>
    </Formik>
  );
};
