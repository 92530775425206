import { createAction } from 'redux-actions';
import {
  MatchOfficialRoles,
  PersonsResponse,
  TeamOfficialRoles,
} from '@core/types';

import { NAMESPACE } from './constants';

const getPersons = createAction<{ page?: number; limit?: number }>(
  `${NAMESPACE}/GET_PERSONS`,
);
const getPersonsRequest = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_REQUEST`,
);
const getPersonsSuccess = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_SUCCESS`,
);
const getPersonsFailure = createAction<void>(
  `${NAMESPACE}/GET_PERSONS_FAILURE`,
);
const setPersons = createAction<PersonsResponse>(`${NAMESPACE}/SET_PERSONS`);

const getTeamOfficialsRoles = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_ROLES`,
);
const getTeamOfficialsRolesRequest = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_ROLES_REQUEST`,
);
const getTeamOfficialsRolesSuccess = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_ROLES_SUCCESS`,
);
const getTeamOfficialsRolesFailure = createAction<void>(
  `${NAMESPACE}/GET_TEAM_OFFICIALS_ROLES_FAILURE`,
);
const setTeamOfficialsRoles = createAction<TeamOfficialRoles>(
  `${NAMESPACE}/SET_TEAM_OFFICIALS_ROLES`,
);

const getMatchOfficialsRoles = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_ROLES`,
);
const getMatchOfficialsRolesRequest = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_ROLES_REQUEST`,
);
const getMatchOfficialsRolesSuccess = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_ROLES_SUCCESS`,
);
const getMatchOfficialsRolesFailure = createAction<void>(
  `${NAMESPACE}/GET_MATCH_OFFICIALS_ROLES_FAILURE`,
);
const setMatchOfficialsRoles = createAction<MatchOfficialRoles>(
  `${NAMESPACE}/SET_MATCH_OFFICIALS_ROLES`,
);

const searchMembersByQuery = createAction<{ page?: number; limit?: number }>(
  `${NAMESPACE}/SEARCH_PERSONS_BY_QUERY`,
);
const searchMembersByQueryLazyLoading = createAction<unknown>(
  `${NAMESPACE}/SEARCH_PERSONS_BY_QUERY_LAZY_LOADING`,
);

const setPersonsLazyLoading = createAction<PersonsResponse>(
  `${NAMESPACE}/SET_PERSONS_LAZY_LOADING`,
);
const setPersonsLinks = createAction<PersonsResponse['_links']>(
  `${NAMESPACE}/SET_LINKS`,
);

const setFilterParams = createAction<any>(`${NAMESPACE}/SET_FILTER_PARAMS`);
const setPersonsQuery = createAction<string>(`${NAMESPACE}/SET_PERSONS_QUERY`);

const resetPersons = createAction<void>(`${NAMESPACE}/RESET_PERSONS`);
const resetPersonsLazyLoading = createAction<void>(
  `${NAMESPACE}/RESET_PERSONS_LAZY_LOADING`,
);

export const actions = {
  getPersons,
  getPersonsRequest,
  getPersonsSuccess,
  getPersonsFailure,
  setPersons,

  getTeamOfficialsRoles,
  getTeamOfficialsRolesRequest,
  getTeamOfficialsRolesSuccess,
  getTeamOfficialsRolesFailure,
  setTeamOfficialsRoles,

  getMatchOfficialsRoles,
  getMatchOfficialsRolesRequest,
  getMatchOfficialsRolesSuccess,
  getMatchOfficialsRolesFailure,
  setMatchOfficialsRoles,

  searchMembersByQuery,
  /** @depracated TODO: JB: review saga if feature is to be used */
  searchMembersByQueryLazyLoading,

  setPersonsLinks,
  setPersonsLazyLoading,

  setFilterParams,
  setPersonsQuery,

  resetPersons,
  resetPersonsLazyLoading,
};
