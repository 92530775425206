import { TableSortLabelProps } from '@mui/material';
import type { Sorting } from '@core/types';

export const getTableSortLabelProps =
  (sorting: Sorting, onSortChanged: (newSorting: Sorting) => void) =>
  (key: string): TableSortLabelProps => {
    const active = sorting?.sortingOrderBy === key;

    const direction =
      sorting?.sortingOrderBy === key ? sorting?.sortingDirection : undefined;

    const onClick = () => {
      let newSorting: Sorting;

      if (sorting) {
        if (sorting.sortingOrderBy === key) {
          newSorting =
            sorting.sortingDirection === 'asc'
              ? { sortingOrderBy: key, sortingDirection: 'desc' }
              : undefined;
        } else {
          newSorting = { sortingOrderBy: key, sortingDirection: 'asc' };
        }
      } else {
        newSorting = { sortingOrderBy: key, sortingDirection: 'asc' };
      }

      onSortChanged(newSorting);
    };

    return {
      active,
      direction,
      onClick,
    };
  };
