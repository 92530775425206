export const NAMESPACE = 'competitionManagers';

export const SET_COMPETITION_MANAGERS = `${NAMESPACE}/SET_COMPETITION_MANAGERS`;

export const GET_COMPETITION_MANAGERS = `${NAMESPACE}/GET_COMPETITION_MANAGERS`;
export const GET_COMPETITION_MANAGERS_REQUEST = `${NAMESPACE}/GET_COMPETITION_MANAGERS_REQUEST`;
export const GET_COMPETITION_MANAGERS_SUCCESS = `${NAMESPACE}/GET_COMPETITION_MANAGERS_SUCCESS`;
export const GET_COMPETITION_MANAGERS_FAILURE = `${NAMESPACE}/GET_COMPETITION_MANAGERS_FAILURE`;

export const SET_CURRENT_COMPETITION_MANAGER = `${NAMESPACE}/SET_CURRENT_COMPETITION_MANAGER`;

export const GET_CURRENT_COMPETITION_MANAGER = `${NAMESPACE}/GET_CURRENT_COMPETITION_MANAGER`;
export const GET_CURRENT_COMPETITION_MANAGER_REQUEST = `${NAMESPACE}/GET_CURRENT_COMPETITION_MANAGER_REQUEST`;
export const GET_CURRENT_COMPETITION_MANAGER_SUCCESS = `${NAMESPACE}/GET_CURRENT_COMPETITION_MANAGER_SUCCESS`;
export const GET_CURRENT_COMPETITION_MANAGER_FAILURE = `${NAMESPACE}/GET_CURRENT_COMPETITION_MANAGER_FAILURE`;
