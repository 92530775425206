import { put } from 'redux-saga/effects';
import { Error } from '@core/types';
import { enqueueSnackbar } from '@core/store/modules/ui/snackbar/actions';
import i18nInstance from '@core/i18n';

export function* handleApiErrors(error: Error) {
  if (!error) {
    return;
  }

  const errorMessage = error.errors?.length
    ? error.errors[0].message
    : error.message;

  if (errorMessage) {
    yield put(
      enqueueSnackbar({
        message: i18nInstance?.t(errorMessage),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}
