import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { prop } from 'ramda';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import { object, string } from 'yup';

import { TextField, FileUploadButton, ChipsSelect } from '@core/components';
import { formatOptions } from '@core/helpers';
import * as testIds from '@core/components/error-message/tests/test-ids';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { File, GlobalModalProps, DocumentReportType } from '@core/types';
import { State } from '@volleyball/store';
import * as filesActions from '@volleyball/store/modules/files';
import * as FILES_ACTION_TYPES from '@volleyball/store/modules/files/constants';
import { getFilesList } from '@volleyball/store/modules/files/selectors';
import { Alert, ButtonWithProgress } from '@ui-components';

enum FIELD_NAMES {
  DOCUMENT_NAME = 'documentName',
  DOCUMENT_TYPE = 'documentType',
}

interface FormValues {
  [FIELD_NAMES.DOCUMENT_NAME]: string;
  [FIELD_NAMES.DOCUMENT_TYPE]: number;
}

const getInitialValues = (): FormValues => ({
  [FIELD_NAMES.DOCUMENT_NAME]: '',
  [FIELD_NAMES.DOCUMENT_TYPE]: null,
});

interface DispatchProps {
  actions: {
    files: typeof filesActions;
  };
}

interface StateProps {
  isLoading: boolean;
  documents?: any;
}

export type Props = GlobalModalProps & DispatchProps & StateProps;

const DocumentUploadModal = (props: Props) => {
  const {
    isModalOpen,
    modalActions,
    actions,
    modalParams,
    isLoading,
    documents,
  } = props;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const objectType = prop('objectType', modalParams);
  const id = prop('id', modalParams);
  const accept =
    '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.odt,.ots,.otp,.pdf,.jpg,.png,.bmp';

  const alreadyHaveThisFile =
    documents &&
    selectedFiles[0] &&
    documents.find((file: File) => file.realName === selectedFiles[0].name);

  const fileTooBig =
    documents &&
    selectedFiles[0] &&
    selectedFiles.find((file: File) => file.size > 25000000);

  const handleUpload = (values: FormValues) => {
    actions.files.uploadFile({
      objectType,
      objectId: id,
      fileType: 'doc',
      documentType: values[FIELD_NAMES.DOCUMENT_TYPE],
      documentName: values[FIELD_NAMES.DOCUMENT_NAME],
      file: selectedFiles[0],
    });
    modalActions.closeModal();
  };

  const uploadButtonComponent = (btnProps: any) => (
    <Button
      color="primary"
      variant="outlined"
      disabled={isLoading}
      {...btnProps}
    >
      {t('Select file')}
    </Button>
  );

  function handleClose() {
    modalActions.closeModal();
  }

  const formRef = useRef<FormikProps<any>>();
  const submitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const documentType = [
    {
      title: 'Report',
      id: DocumentReportType.REPORT,
    },
    {
      title: 'Photo',
      id: DocumentReportType.PHOTO,
    },
    {
      title: 'Other',
      id: DocumentReportType.OTHER,
    },
  ];

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('Upload Document')}</DialogTitle>
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues()}
        validationSchema={object().shape({
          [FIELD_NAMES.DOCUMENT_NAME]: string().nullable(),
          [FIELD_NAMES.DOCUMENT_TYPE]: string().nullable(),
        })}
        onSubmit={(values) => {
          setIsSubmitting(true);
          handleUpload(values);
          handleClose();
        }}
        enableReinitialize
      >
        <Form noValidate>
          <DialogContent>
            {fileTooBig && (
              <Box mb={2}>
                <Alert title={t('File size exceeds 25MB.')} error />
              </Box>
            )}
            <Box mb={3}>
              <TextField
                name={FIELD_NAMES.DOCUMENT_NAME}
                data-qa={FIELD_NAMES.DOCUMENT_NAME}
                withTooltipMargin={false}
                label={t('Document name')}
                tooltipText={t('Specify document name')}
              />
            </Box>
            <Box mb={2}>
              <Box mr={4}>
                <ChipsSelect
                  fieldName={FIELD_NAMES.DOCUMENT_TYPE}
                  data-qa={FIELD_NAMES.DOCUMENT_TYPE}
                  title={t('Document type')}
                  options={formatOptions(t, documentType)}
                />
              </Box>
            </Box>
            <Box mb={2}>
              <Box display="flex" mb={1}>
                <Typography variant="body1" color="textSecondary">
                  {t('Attached file *')}
                </Typography>
                {alreadyHaveThisFile && (
                  <Box ml={1}>
                    <FormHelperText error data-qa={testIds.ERROR_MESSAGE}>
                      {t(
                        'Document with selected file already exists. Please select another file.',
                      )}
                    </FormHelperText>
                  </Box>
                )}
              </Box>
              <FileUploadButton
                files={selectedFiles}
                setSelectedFile={setSelectedFiles}
                buttonComponent={uploadButtonComponent}
                accept={accept}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Box display="flex" padding={1}>
              <Box mr={1}>
                <Button onClick={handleClose}>{t('Cancel')}</Button>
              </Box>
              <ButtonWithProgress
                color="primary"
                variant="contained"
                onClick={submitForm}
                isLoading={isLoading || isSubmitting}
                disabled={
                  selectedFiles.length === 0 ||
                  !!fileTooBig ||
                  alreadyHaveThisFile
                }
              >
                {t('Upload')}
              </ButtonWithProgress>
            </Box>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  FILES_ACTION_TYPES.UPLOAD_FILE_REQUEST,
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    files: bindActionCreators(filesActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  props: GlobalModalProps,
): StateProps => ({
  isLoading: loadingSelector(state),
  documents: getFilesList(props.modalParams.id)(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentUploadModal);
