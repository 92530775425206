import { handleActions } from 'redux-actions';
import { reduce, assocPath, dissocPath } from 'ramda';

import {
  formatPaginatedStore,
  formatPaginatedStoreWithIds,
} from '@core/helpers';
import {
  Paginated,
  Filters,
  Sorting,
  CompetitionResponse,
  Group,
  MatchModel,
} from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [competitionId: number]: {
    competitionDetails: CompetitionResponse;
    stages: {
      [stageId: number]: Group;
    };
    matchesByGroup: {
      [stageId: number]: {
        matches: Paginated<MatchModel['id']>;
        searchQuery: string;
        filters: Filters;
        sorting: Sorting;
      };
    };
  };
}

export const defaultState: DefaultState = {};

type Payload = any;

interface Meta {
  competitionId: number;
  teamId?: number;
  groupId?: number;
}

export const initializedCompetitionState = {
  competitionDetails: {},
  stages: {},
  matchesByGroup: {},
};

export const arrayToObject = reduce(
  (acc, value: any) => ({ ...acc, [value.id]: value }),
  {},
);

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.initializeCompetitionStore.toString()]: (state, { meta }) =>
      assocPath([meta.competitionId], initializedCompetitionState, state),

    [actions.setCompetitionDetails.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'competitionDetails'], payload.id, state),

    [actions.setStageDetails.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'stages'],
        arrayToObject(payload || []),
        state,
      ),

    [actions.setCompetitors.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'competitors'],
        formatPaginatedStoreWithIds('competitors')(payload),
        state,
      ),

    [actions.setGroup.toString()]: (state, { payload, meta }) =>
      assocPath([meta.competitionId, 'stages', meta.groupId], payload, state),

    [actions.setDisciplinarySanctions.toString()]: (
      state,
      { payload, meta },
    ) => {
      return assocPath(
        [meta.competitionId, 'disciplinarySanctions'],
        formatPaginatedStoreWithIds('disciplinarySanctions')(payload),
        state,
      );
    },

    [actions.setDisciplinaryInfringements.toString()]: (
      state,
      { payload, meta },
    ) => {
      return assocPath(
        [meta.competitionId, 'disciplinaryInfringements'],
        formatPaginatedStoreWithIds('penaltyCardLog')(payload),
        state,
      );
    },

    [actions.setGroupMatches.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'matchesByGroup', meta.groupId, 'matches'],
        formatPaginatedStoreWithIds('matches')(payload),
        state,
      ),

    [actions.clearMatchesByGroup.toString()]: (state, { payload }) =>
      dissocPath(
        [payload.competitionId, 'matchesByGroup', payload.groupId],
        state,
      ),

    [actions.setMatchSearchQuery.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'matchesByGroup', meta.groupId, 'searchQuery'],
        payload,
        state,
      ),

    [actions.setFilterParams.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'matchesByGroup', meta.groupId, 'filters'],
        payload,
        state,
      ),

    [actions.setSorting.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'matchesByGroup', meta.groupId, 'sorting'],
        payload,
        state,
      ),

    [actions.setGroupFixtures.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'fixturesByGroup', meta.groupId, 'fixtures'],
        formatPaginatedStore('groupFixtures')(payload),
        state,
      ),

    [actions.clearFixturesByGroup.toString()]: (state, { payload }) =>
      dissocPath(
        [payload.competitionId, 'fixturesByGroup', payload.groupId],
        state,
      ),

    [actions.setFixturesSearchQuery.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'fixturesByGroup', meta.groupId, 'searchQuery'],
        payload,
        state,
      ),
    [actions.setSanctionsSearchQuery.toString()]: (state, { payload, meta }) =>
      assocPath(
        [meta.competitionId, 'sanctions', 'searchQuery'],
        payload,
        state,
      ),
    [actions.setInfringementsSearchQuery.toString()]: (
      state,
      { payload, meta },
    ) =>
      assocPath(
        [meta.competitionId, 'infringements', 'searchQuery'],
        payload,
        state,
      ),
  },
  defaultState,
);

export default reducer;
