import restService from '@core/api/rest-service';

export const createCompetitionContact = (competitionId: number, data: any) =>
  restService.post(
    `/v1/competitions/${competitionId}/competitionContacts`,
    data,
  );

export const getCompetitionContactList = (competitionId: number) =>
  restService.get<any>(`/v1/competitions/${competitionId}/competitionContacts`);

export const updateCompetitionContact = (contactId: number, data: any) =>
  restService.patch<any>(`/v1/competitionContacts/${contactId}`, data);

export const deleteCompetitionContact = (contactId: number) =>
  restService.delete<any>(`/v1/competitionContacts/${contactId}`);
