import { Paginated, PaginationLimits } from '@core/types';
import { PaginationProps } from './custom-table';

export function getPaginationValuesFactory(
  reloadData: (pagination: { page: number }) => void,
  pagination: Omit<Paginated<any>, 'list'>,
  initialPagination?: {
    page?: number;
    limit?: PaginationLimits;
    total?: number;
  },
) {
  const { page = 1, limit = 10, total = 0 } = initialPagination ?? {};

  return (): PaginationProps => ({
    reloadData,
    page: pagination?.page || page,
    pages: pagination?.pages,
    limit: pagination?.limit || limit,
    total: pagination?.total || total,
  });
}
