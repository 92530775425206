import { Theme } from '@mui/material';
import { CreateCSSProperties } from '@mui/styles';
import { MenuPosition } from 'react-select';

interface StyleProps {
  menuPosition: MenuPosition;
  tooltipText?: string;
  width?: string;
  withTooltipMargin?: boolean;
}

const tooltipIconWidth = '30px';
const withTooltipMarginWidth = '34px';
const tooltipTextMargin = (theme: Theme) => theme.spacing(1);

type ControlInputWrapperCssProperties = Pick<
  Required<StyleProps>,
  'tooltipText' | 'width' | 'withTooltipMargin'
>;
export function getControlInputWrapperCssDeclaration(
  theme: Theme,
): CreateCSSProperties<ControlInputWrapperCssProperties> {
  return {
    width: ({ width }) => width || '100%',
    marginRight: ({ tooltipText, withTooltipMargin }) => {
      return tooltipText
        ? tooltipTextMargin(theme)
        : withTooltipMargin
          ? withTooltipMarginWidth
          : 0;
    },
  };
}

type MenuRootCssProperties = Pick<
  Required<StyleProps>,
  'menuPosition' | 'tooltipText' | 'width' | 'withTooltipMargin'
>;

export function getMenuRootCssDeclaration(
  theme: Theme,
): CreateCSSProperties<MenuRootCssProperties> {
  return {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
    width: ({ menuPosition, tooltipText, width, withTooltipMargin }) => {
      const _width = width || '100%';

      if (menuPosition === 'absolute') {
        return tooltipText
          ? `calc(${_width} - ${tooltipTextMargin(theme)} - ${tooltipIconWidth}) `
          : withTooltipMargin
            ? `calc(${_width} - ${withTooltipMarginWidth})`
            : _width;
      }
      if (menuPosition === 'fixed') {
        return withTooltipMargin
          ? `calc(${_width} - ${withTooltipMarginWidth})`
          : _width;
      }

      return _width;
    },
  };
}
