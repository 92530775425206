import { handleActions } from 'redux-actions';
import { appendToPaginatedListFactory } from '@core/helpers';
import { Paginated, Season } from '@core/types';
import actions from './actions';

export type DefaultState = {
  seasons: Paginated<Season['id']>;
};

type Payload = DefaultState['seasons'];

export const defaultState: DefaultState = {
  seasons: null,
};

const appendToSeasonsList =
  appendToPaginatedListFactory<DefaultState>('seasons');

const reducer = handleActions<DefaultState, Payload | void>(
  {
    [actions.setSeasons.toString()]: (state, { payload }) =>
      appendToSeasonsList(state, payload as DefaultState['seasons']),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
