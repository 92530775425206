import { GeographyTypes } from '@core/types';

export const getGeography = (
  t: (key: string) => string,
  geography: keyof typeof GeographyTypes,
) => {
  switch (geography) {
    case GeographyTypes.DOMESTIC:
      return t('Domestic');
    case GeographyTypes.INTERNATIONAL:
      return t('International');
    default:
      return geography;
  }
};
