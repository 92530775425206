import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { prop } from 'ramda';

import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { GlobalModalProps } from '@core/types';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { State } from '@core/store';
import MatchSheetAlert from '@core/components/match-sheet-alert/match-sheet-alert';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
  };
}

interface StateProps {
  isLoading: boolean;
}

type Props = GlobalModalProps & DispatchProps & StateProps;

const MatchSheetValidationsModal = (props: Props) => {
  const { isModalOpen, modalActions, isLoading, modalParams } = props;
  const matchDetails = prop('matchDetails', modalParams);
  const addedLineup = prop('addedLineup', modalParams);
  const addedTeamOfficials = prop('addedTeamOfficials', modalParams);

  const { t } = useTranslation();

  const handleClose = () => {
    modalActions.closeModal();
  };

  const handleSubmit = () => {
    modalParams.submitCallback();
    modalActions.closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('Submit match sheet')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="subtitle1">
            {t('Do you want to submit the match sheet?')}
          </Typography>
        </Box>
        <MatchSheetAlert
          matchDetails={matchDetails}
          addedLineup={addedLineup}
          addedTeamOfficials={addedTeamOfficials}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleClose}>{t('Cancel')}</Button>
          </Box>
          <ButtonWithProgress
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            {t('Submit')}
          </ButtonWithProgress>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  competitionProfileActions.getGroupRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchSheetValidationsModal);
