export const ADD_BUTTON = 'ADD_BUTTON';
export const NEXT_BUTTON = 'NEXT_BUTTON';
export const EDIT_BUTTON = 'EDIT_BUTTON';
export const REMOVE_BUTTON = 'REMOVE_BUTTON';
export const DELETE_BUTTON = 'DELETE_BUTTON';
export const VIEW_BUTTON = 'VIEW_BUTTON';
export const MANAGE_BUTTON = 'MANAGE_BUTTON';
export const CREATE_BUTTON = 'CREATE_BUTTON';
export const CANCEL_BUTTON = 'CANCEL_BUTTON';
export const CLOSE_BUTTON = 'CLOSE_BUTTON';
export const SUBMIT_BUTTON = 'SUBMIT_BUTTON';
export const CONFIRM_BUTTON = 'CONFIRM_BUTTON';
export const DIALOG_SUBMIT_BUTTON = 'DIALOG_SUBMIT_BUTTON';

export const FULL_SCREEN_SPINNER = 'FULL_SCREEN_SPINNER';
