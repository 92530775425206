import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { STAGE_PROFILE_DETAILS } from '@core/pages/competition-profile/tests/test-ids';
import { Group } from '@core/types';
import { CompetitionResponse } from '@volleyball/types';

interface OwnProps {
  stage: Group;
  competition: CompetitionResponse;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  detailsBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
}));

const StageProfileSummary = ({ stage, competition }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.competitionSeasonTitle,
    },
    {
      label: t('Stage'),
      value: stage?.title,
    },
  ];

  return (
    <Grid className={classes.detailsBlock} data-qa={STAGE_PROFILE_DETAILS}>
      <SummaryBlock values={getInfoBlocks()} />
    </Grid>
  );
};

export default StageProfileSummary;
