import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { actions } from '.';

export interface DefaultState {
  canSubmitMatchEvents: false;
}

const defaultState: DefaultState = {
  canSubmitMatchEvents: false,
};

const reducer = handleActions<DefaultState, any>(
  {
    [actions.setCanSubmitMatchEvents.toString()]: (state, { payload }) =>
      assoc('canSubmitMatchEvents', payload, state),
  },
  defaultState,
);

export default reducer;
