import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { find, identity, path, pathOr } from 'ramda';
import { getAuthProvider, getCustomerCode } from '@core/helpers';
import { getAuth0GetAccessTokenSilently } from '@core/helpers/auth';
import * as authActions from '@core/pages/login/store/actions';
import { ErrorResponse } from '@core/types';

export const handleAuthorization = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const authProvider = getAuthProvider();

  if (authProvider === 'auth0') {
    const getAccessTokenSilently = getAuth0GetAccessTokenSilently();

    if (getAccessTokenSilently) {
      try {
        const { id_token } = await getAccessTokenSilently({
          detailedResponse: true,
        });

        config.headers.set('authorization', `Bearer ${id_token}`);
      } catch {
        globalThis.store.dispatch(authActions.logout());
      }
    }

    // updateSessionExpiration();
  }

  return config;
};

export const mapAPIResponse = (response: AxiosResponse): any => ({
  response: response.data,
});

const findFirstAvailable = find((val: any) => identity(val));
const isUnauthorizedError = (statusCode?: number) => statusCode === 401;

export const handleApiReject = (reject: AxiosError): ErrorResponse => {
  const { config, response, message } = reject;

  const errorMessage = findFirstAvailable([
    // https://auth0.com/docs/api/authentication#standard-error-responses
    ...(config?.baseURL === 'https://' + import.meta.env.VITE_AUTH0_DOMAIN
      ? [
          path(['data', 'error_description'], response),
          path(['data', 'error'], response),
        ]
      : []),
    path(['data', 'message'], response),
    path(['data', 'statusText'], response),
    message,
    'Unknown Error',
  ]);

  const errorCode = findFirstAvailable([
    path(['data', 'status'], response),
    path(['status'], response),
  ]);

  if (isUnauthorizedError(path(['response', 'status'], reject))) {
    globalThis.store.dispatch(authActions.logout());
  }

  const errors = pathOr(null, ['response', 'data', 'errors'], reject);

  return {
    error: {
      message: errorMessage,
      code: errorCode,
      errors,
    },
  };
};

export const addCustomerCode = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  config.headers.set('X-CUSTOMER-CODE', getCustomerCode());

  return config;
};
