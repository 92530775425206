import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { actions } from '.';

export interface DefaultState {
  isModalOpen: boolean;
}

const defaultState: DefaultState = {
  isModalOpen: false,
};

const reducer = handleActions<DefaultState>(
  {
    [actions.openTeamsListModal.toString()]: (state) =>
      assoc('isModalOpen', true, state),
    [actions.closeTeamsListModal.toString()]: (state) =>
      assoc('isModalOpen', false, state),
  },
  defaultState,
);

export default reducer;
