import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '@core/store';
import * as uiActions from '@core/store/modules/ui/menu/actions';

import { MenuDispatchProps, UIState } from '../types';

const mapDispatchToProps = (dispatch: Dispatch): MenuDispatchProps => ({
  actions: {
    ui: bindActionCreators(uiActions, dispatch),
  },
});
const mapStateToProps = (state: State): UIState => {
  return { isMenuOpen: state?.ui?.menu?.isMenuOpen };
};

export const withMenuDrawerState = connect(mapStateToProps, mapDispatchToProps);
