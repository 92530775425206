import { createAction } from 'redux-actions';

import { Paginated } from '@core/types';

import { NAMESPACE } from './constants';

type QueryParams = {
  page?: number;
  query?: string;
  sort?: string;
  public?: boolean;
};

const setClubs = createAction<Paginated<number>>(`${NAMESPACE}/SET_CLUBS`);
const getClubs = createAction<QueryParams | void>(`${NAMESPACE}/GET_CLUBS`);
const getClubsRequest = createAction<void>(`${NAMESPACE}/GET_CLUBS_REQUEST`);
const getClubsSuccess = createAction<void>(`${NAMESPACE}/GET_CLUBS_SUCCESS`);
const getClubsFailure = createAction<void>(`${NAMESPACE}/GET_CLUBS_FAILURE`);
const searchClubsByQuery = createAction<QueryParams>(
  `${NAMESPACE}/SEARCH_CLUBS_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setClubs,
  getClubsRequest,
  getClubsSuccess,
  getClubsFailure,
  getClubs,
  resetState,
  searchClubsByQuery,
};
