import { useTranslation } from 'react-i18next';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Box, Divider, Theme } from '@mui/material';
import { Dayjs } from 'dayjs';
import {
  FILTER_DATE_CANCEL_STATIC_BUTTON,
  FILTER_DATE_SUBMIT_STATIC_BUTTON,
} from '../tests/test-ids';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'absolute',
    height: 507,
    width: 414,
    left: 0,
    top: 49,
    background: theme.palette.common.white,
    zIndex: theme.zIndex.modal + 1,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      height: `calc(100% - 49px)`,
      width: '100%',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  datePickerBox: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
  },
  datePickerRoot: {},
  actionsBox: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type Props = {
  handleChange: (date: Dayjs) => void;
  handleDateSave: () => void;
  handleCancel: () => void;
  value: Dayjs;
};

const DatePickerStaticMode = ({
  handleChange,
  handleDateSave,
  handleCancel,
  value,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.contentBox}>
        <Grid className={classes.datePickerBox}>
          <StaticDatePicker
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
            className={classes.datePickerRoot}
            onChange={handleChange}
            value={value}
            orientation="portrait"
          />
        </Grid>
        <Divider />
        <Grid className={classes.actionsBox}>
          <Box mr={1}>
            <Button
              data-qa={FILTER_DATE_CANCEL_STATIC_BUTTON}
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
          </Box>
          <Button
            onClick={handleDateSave}
            color="primary"
            variant="contained"
            data-qa={FILTER_DATE_SUBMIT_STATIC_BUTTON}
          >
            {t('OK')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DatePickerStaticMode;
