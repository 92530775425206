import React, { useEffect, FunctionComponent, useState } from 'react';
import { Dialog, DialogContent, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikErrors } from 'formik';
import { equals, pick, map, prop } from 'ramda';
import {
  AddCircleOutlineOutlined,
  DeleteTwoTone,
  EditTwoTone,
} from '@mui/icons-material';

import { APIErrorsModel } from '@core/types';
import { mapApiErrorsToFormErrors } from '@core/helpers';
import { IconButtonWithProgress } from '@core/components';
import { useConfirmationDialog } from '@core/hooks';

import * as testIds from './tests/test-ids';
import PageLayout from './components/page-layout';

interface Column {
  name: string;
  label: string;
}

interface FormComponentProps {
  errors: FormikErrors<any>;
  initialFormValues: any;
  isSubmitting: boolean;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
}

interface TableComponentProps {
  isLoading: boolean;
  rows: any;
  renderActions: (item: any) => any;
}

interface StateProps {
  isFormSubmitting: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  beingModified: any;
  isNewBeingCreated: boolean;
  errors: APIErrorsModel;
  rows: Array<any>;
  formComponent: FunctionComponent<FormComponentProps>;
  tableComponent: FunctionComponent<TableComponentProps>;
  title: string;
  actions: {
    setErrors: (error: any) => void;
    setBeingModified: (item: any) => void;
    getList: () => void;
    setNewBeingCreated: (flag: boolean) => void;
    deleteItem: (itemId: number) => void;
    updateItem: (item: any) => void;
    createItem: (item: any) => void;
  };
  columns: Array<Column>;
  buttonLabel?: string;
}

type Props = StateProps;

const TableList = (props: Props) => {
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();

  const {
    actions,
    isFormSubmitting,
    isDeleting,
    isLoading,
    beingModified,
    isNewBeingCreated,
    errors,
    rows,
    formComponent: FormComponent,
    tableComponent: TableComponent,
    title,
    columns,
    buttonLabel,
  } = props;

  const isModalOpen = !!beingModified || isNewBeingCreated;

  useEffect(() => {
    actions.getList();
  }, []);

  const resetFormState = () => {
    actions.setBeingModified(null);
    actions.setNewBeingCreated(false);
    actions.setErrors(null);
  };

  const [itemBeingDeletedId, setItemBeingDeletedId] = useState(null);

  const handleDialogClose = () => !isFormSubmitting && resetFormState();

  const handleCreateClick = () => actions.setNewBeingCreated(true);

  const handleEditClick = (item: any) => () =>
    !isDeleting && actions.setBeingModified(item);

  const handleDeleteClick = (itemId: number) => async () => {
    if (isDeleting) {
      return;
    }

    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this item?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      setItemBeingDeletedId(itemId);
      actions.deleteItem(itemId);
    }
  };

  const getInitialFormValues = () =>
    beingModified ? pick(map(prop('name'), columns), beingModified) : null;

  const getInitialFormErrors = () =>
    errors ? mapApiErrorsToFormErrors(errors) : null;

  const handleFormUpdate = (values: any) => {
    const wasGroupModified = !equals(getInitialFormValues(), values);
    if (wasGroupModified) {
      actions.updateItem({
        id: beingModified.id,
        data: values,
      });
    } else {
      actions.setBeingModified(null);
    }
  };

  const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    isNewBeingCreated ? actions.createItem(values) : handleFormUpdate(values);
    formikHelpers.setSubmitting(false);
  };

  const isCreateButtonDisabled = () =>
    isLoading || isFormSubmitting || isDeleting;

  const isDeletingById = (itemId: number) =>
    isDeleting && itemId === itemBeingDeletedId;

  const renderTableActions = (item: any) => (
    <>
      <IconButton
        color="primary"
        onClick={handleEditClick(item)}
        data-qa="STATIC_TABLE_EDIT_BUTTON"
      >
        <EditTwoTone />
      </IconButton>
      <IconButtonWithProgress
        color="primary"
        isLoading={isDeletingById(item.id)}
        onClick={handleDeleteClick(item.id)}
        data-qa="STATIC_TABLE_DELETE_BUTTON"
      >
        <DeleteTwoTone />
      </IconButtonWithProgress>
    </>
  );

  return (
    <PageLayout
      title={title}
      table={
        <TableComponent
          isLoading={isLoading}
          rows={rows}
          renderActions={renderTableActions}
        />
      }
      dialog={
        <Dialog onClose={handleDialogClose} open={isModalOpen}>
          <DialogContent>
            <FormComponent
              errors={getInitialFormErrors()}
              initialFormValues={getInitialFormValues()}
              isSubmitting={isFormSubmitting}
              onSubmit={handleFormSubmit}
            />
          </DialogContent>
        </Dialog>
      }
      button={
        <Button
          fullWidth
          data-qa={testIds.TABLE_WITH_CRUD_CREATE_BUTTON}
          color="primary"
          variant="contained"
          disabled={isCreateButtonDisabled()}
          onClick={handleCreateClick}
          startIcon={<AddCircleOutlineOutlined />}
        >
          {buttonLabel ?? t('Create')}
        </Button>
      }
    />
  );
};

export default TableList;
