import { isEmpty } from 'ramda';

import { getCompetitionsById } from '@core/pages/competitions/store/api/selectors';
import { CompetitionResponse, Paginated } from '@core/types';

import { State } from '@core/store';

import { NAMESPACE } from './constants';

const getCompetititonsFilterPagination = (
  state: State,
): Paginated<CompetitionResponse['id']> =>
  // @ts-ignore
  state.modals?.[NAMESPACE]?.competitions;

export const getCompetitions = (
  state: State,
): Paginated<CompetitionResponse> => {
  const competititonsFilterPagination = getCompetititonsFilterPagination(state);

  return competititonsFilterPagination &&
    !isEmpty(competititonsFilterPagination)
    ? {
        ...competititonsFilterPagination,
        list: getCompetitionsById(state, competititonsFilterPagination?.list),
      }
    : null;
};
