import { MouseEvent, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { BlockPicker, BlockPickerProps } from 'react-color';
import { Field, FieldProps } from 'formik';
import {
  Box,
  Button,
  ClickAwayListener,
  ClickAwayListenerProps,
  Grid,
  Popper,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePopperToggle } from '@core/hooks';
import * as testIds from './tests/test-ids';

interface StyleProps {
  backgroundColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  // #E5E5EA
  const defaultBackgroundColor = theme.palette.grey[300];

  return {
    button: {
      color: ({ backgroundColor }) =>
        backgroundColor &&
        theme.palette.getContrastText(
          backgroundColor || defaultBackgroundColor,
        ),
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || defaultBackgroundColor,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1,
      minHeight: 36,
      minWidth: 36,
      border: 0,
      borderRadius: '50%',
      boxShadow: theme.shadows[1],
      '&:hover': {
        backgroundColor: ({ backgroundColor }) =>
          backgroundColor || defaultBackgroundColor,
        boxShadow: theme.shadows[3],
      },
    },
    buttonDisabled: {
      opacity: 0.7,
      '&:hover': {
        boxShadow: theme.shadows[1],
      },
    },
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    blockPicker: {
      boxShadow: theme.shadows[1],
      borderRadius: '6px 6px 0 0',
    },
    applyButtonWrapper: {
      backgroundColor: theme.palette.common.white,
    },
    applyButton: {
      borderRadius: 0,
    },
  };
});

type Props = {
  label?: string;
  name: string;
  disabled?: boolean;
  colors?: Array<string>;
  emptyColor?: string;
};

const ColorInputField = ({
  name,
  label,
  disabled,
  colors,
  emptyColor,
}: Props) => {
  const { t } = useTranslation();
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();
  const [shouldShowApply, setShouldShowApply] = useState<boolean>(false);
  const [customColor, setCustomColor] = useState<string>(null);

  return (
    <Grid data-qa={testIds.COLOR_INPUT_FIELD}>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const classes = useStyles({
            backgroundColor: field.value || emptyColor,
          });

          const handleClickAway: ClickAwayListenerProps['onClickAway'] = (
            event,
          ) => {
            handleClose(event as any);
            setShouldShowApply(false);
            setCustomColor(null);
          };

          const handleApply: MouseEventHandler<HTMLButtonElement> = (event) => {
            form.setFieldValue(name, customColor, true);
            handleClose(event);
            setShouldShowApply(false);
            setCustomColor(null);
          };

          const handleChangeComplete: BlockPickerProps['onChangeComplete'] = (
            { hex },
            event,
          ) => {
            if (event.type === 'change') {
              setCustomColor(hex);
              setShouldShowApply(true);
            }

            if (event.type === 'click') {
              form.setFieldValue(name, hex, true);
              setCustomColor(null);
              handleClose(event as any);
            }
          };

          const handleColorButtonClick = (event: MouseEvent<EventTarget>) =>
            disabled ? null : handleToggle(event);

          return (
            <>
              <Button
                ref={anchorRef}
                disabled={disabled}
                onClick={handleColorButtonClick}
                className={clsx(classes.button, {
                  [classes.buttonDisabled]: disabled,
                })}
              >
                {label}
              </Button>
              <Popper
                className={classes.popper}
                anchorEl={anchorRef.current}
                open={isOpen}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box className={classes.blockPicker}>
                    <Box>
                      <BlockPicker
                        color={customColor || field.value || emptyColor}
                        onChange={handleChangeComplete}
                        triangle="hide"
                        colors={colors}
                      />
                    </Box>
                    {shouldShowApply && (
                      <Box className={classes.applyButtonWrapper}>
                        <Button
                          fullWidth
                          variant="outlined"
                          size="small"
                          onClick={handleApply}
                          className={classes.applyButton}
                        >
                          {t('Apply')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </ClickAwayListener>
              </Popper>
            </>
          );
        }}
      </Field>
    </Grid>
  );
};

export default ColorInputField;
