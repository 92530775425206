import { handleActions } from 'redux-actions';
import { assocPath, dissocPath } from 'ramda';

import { DisciplinaryRules, DisciplinaryRule } from '@core/types';

import actions from '.';

export interface DefaultState {
  byCompetitionId: {
    [competitionId: string]: {
      rules?: DisciplinaryRules;
      byRuleId?: {
        [ruleId: number]: DisciplinaryRule;
      };
      contacts: any;
    };
  };
}

export const defaultState: DefaultState = {
  byCompetitionId: {},
};

type Payload = any;

interface Meta {
  competitionId?: number;
  ruleId?: number;
}

const reducer = handleActions<DefaultState, Payload, Meta>(
  {
    [actions.setDisciplinaryRules.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byCompetitionId', meta.competitionId, 'rules'],
        payload,
        state,
      ),

    [actions.setDisciplinaryRule.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byCompetitionId', meta.competitionId, 'byRuleId', meta.ruleId],
        payload,
        state,
      ),

    [actions.resetCompetitionCreation.toString()]: (state, { payload }) =>
      dissocPath(['byCompetitionId', payload], state),

    [actions.setCompetitionContacts.toString()]: (state, { payload, meta }) =>
      assocPath(
        ['byCompetitionId', meta.competitionId, 'contacts'],
        payload,
        state,
      ),
  },
  defaultState,
);

export default reducer;
