import { TabProps } from '@core/types';

export enum FilteringOperations {
  // Binary
  greaterThan = 'gt',
  greaterThanOrEqual = 'gte',
  lessThan = 'lt',
  lessThanOrEqual = 'lte',
  equal = 'eq',
  notEqual = 'neq',
  like = 'like',
  notLike = 'notLike',
  in = 'in',
  // Unary
  isNull = 'isNull',
  isNotNull = 'isNotNull',
}

export interface FiltersQueryObject {
  [key: string]: string; // eg.: filter[id][]: eq:31
}

export type FilterValue = {
  value: string;
  label?: string;
};

export interface FilterModel {
  fieldName: string;
  filteringOperation: FilteringOperations;
  filterValues: FilterValue | Array<FilterValue>;
}

export interface FiltersItem extends Partial<FilterModel> {
  filterValues: Array<FilterValue>;
}

export type Filters = {
  [id: string]: FiltersItem;
};

export type FilterParams = Array<[string, string]>;

type BaseFilterTabProps = {
  handleFilterAdd: (filter: FilterModel) => void;
  handleFilterRemove: (filter: FilterModel) => void;
  handleFilterReplace: (filter: FilterModel) => void;
  filters: Filters;
};

export type FilterTabProps<T extends Record<string, unknown> = undefined> =
  T extends undefined ? BaseFilterTabProps : T & BaseFilterTabProps;

export interface FilterTab extends TabProps {
  filterIds: Array<string>;
  initialQueryParams?: {
    'filter[candidatesForCompetition]'?: number;
  };
}

export type FilterTabsList = Array<FilterTab>;
