import { MatchSets } from '@core/types';

export const getSet = (set: keyof typeof MatchSets) => {
  switch (set) {
    case MatchSets.SET_0_1:
      return '0-1';
    case MatchSets.SET_0_2:
      return '0-2';
    case MatchSets.SET_0_3:
      return '0-3';
    case MatchSets.SET_1_0:
      return '1-0';
    case MatchSets.SET_1_2:
      return '1-2';
    case MatchSets.SET_1_3:
      return '1-3';
    case MatchSets.SET_2_0:
      return '2-0';
    case MatchSets.SET_2_1:
      return '2-1';
    case MatchSets.SET_2_3:
      return '2-3';
    case MatchSets.SET_3_0:
      return '3-0';
    case MatchSets.SET_3_1:
      return '3-1';
    case MatchSets.SET_3_2:
      return '3-2';
    default:
      return set;
  }
};
