import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = {
  innerProps: any;
};

const DropdownIndicator = ({ innerProps }: Props) => (
  <ArrowDropDownIcon {...innerProps} />
);

export default DropdownIndicator;
