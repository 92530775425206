import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Tooltip } from '@core/components';

interface OwnProps {
  title: ReactNode;
  value: JSX.Element | string | number;
  tooltipText: string;
  hideValue: boolean;
  error?: string;
  isLoading?: boolean;
  unrestrictedLabel?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const ExpansionPanelTitleComponent = (props: Props) => {
  const { title, value, error, tooltipText, hideValue, unrestrictedLabel } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box flex="1" display="flex" alignItems="center">
      <Box flex="1" display="flex" alignItems="flex-end">
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={3}>
        {unrestrictedLabel && !value && !error && !hideValue ? (
          <Typography variant="body2" color="textSecondary">
            {t('Unrestricted')}
          </Typography>
        ) : (
          !hideValue && (
            <Box
              className={clsx({
                [classes.error]: !!error,
                [classes.summary]: !error,
              })}
              display="flex"
              flexDirection="row"
              textAlign="end"
              sx={{ typography: 'body2' }}
            >
              {error || value}
            </Box>
          )
        )}
      </Box>
      {tooltipText && <Tooltip title={tooltipText} />}
    </Box>
  );
};

export default ExpansionPanelTitleComponent;
