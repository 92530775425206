import React, { useState } from 'react';
import {
  Grid,
  Tab,
  Tabs,
  Box,
  Divider,
  Tooltip,
  TabsProps as MuiTabsProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Lock } from '@mui/icons-material';
import clsx from 'clsx';

import { TabsProps, TabProps } from '@core/types';
import { FlowIndicator, Badge } from '@core/components';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    width: '100%',
  },
  divider: {
    position: 'relative',
    top: '-1px',
    marginRight: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
  },
  tabWithTooltip: {
    // export const createValue = <T extends string = string>(value: `${T} !important`) => value as T;
    // TODO: JB: pointerEvents: createValue('auto !important'),
    pointerEvents: 'auto !important' as 'auto',
  },
}));

export interface TabsComponentProps
  extends Partial<Pick<MuiTabsProps, 'variant' | 'scrollButtons'>> {
  children: (currentTab: string) => JSX.Element;
  initialTab?: string;
  tabs: TabsProps;
  fullWidth?: boolean;
  testId?: string;
  styles?: {
    divider?: string;
    root?: string;
    tab?: string;
    selectedTab?: string;
  };
  tabChangePermissionCheck?: (currentTab: string) => boolean;
  onChange?: (tab: string) => void;
}

const TabsComponent = (props: TabsComponentProps) => {
  const {
    children,
    tabs,
    initialTab,
    testId,
    fullWidth,
    styles = {},
    tabChangePermissionCheck,
    onChange,
    variant,
    scrollButtons,
  } = props;
  const [currentTab, setCurrentTab] = useState(initialTab || tabs[0].value);
  const classes = useStyles();
  const filteredTabs = tabs.filter((tab) => !tab.hidden);

  function handleTabChange(_: React.ChangeEvent<any>, selectedTab: string) {
    if (
      tabChangePermissionCheck ? tabChangePermissionCheck(currentTab) : true
    ) {
      setCurrentTab(selectedTab);
      onChange?.(selectedTab);
    }
  }

  function renderTab({
    label,
    value,
    disabled,
    testId: tabTestId,
    tooltipText,
    badge,
    withWarning,
    locked,
  }: TabProps) {
    const className = clsx(styles.tab, {
      [styles.selectedTab]: currentTab === value,
      [classes.tabWithTooltip]: !!tooltipText,
    });

    const defaultTabProps = {
      className,
      key: value,
      label: (
        <Box display="flex" alignItems="center">
          {withWarning && <FlowIndicator size="small" />}
          {locked && (
            <Box mr={1} display="flex" alignItems="center">
              <Lock color="disabled" fontSize="small" />
            </Box>
          )}
          <span>{label}</span>
          {badge ? <Badge text={badge} /> : null}
        </Box>
      ),
      value,
      disabled: disabled || locked,
      'data-qa': tabTestId,
    };

    const withTooltipTextOverrides = {
      label: (
        <Tooltip title={tooltipText ?? ''}>{defaultTabProps.label}</Tooltip>
      ),
    };

    return (
      <Tab
        {...defaultTabProps}
        {...(!!tooltipText && withTooltipTextOverrides)}
      />
    );
  }

  return (
    <Box width="100%">
      <Tabs
        className={clsx(classes.tabs, styles.root)}
        variant={variant}
        scrollButtons={scrollButtons}
        value={currentTab}
        onChange={handleTabChange}
        data-qa={testId}
        indicatorColor="primary"
      >
        {filteredTabs.map((tab) => renderTab(tab))}
      </Tabs>
      <Grid className={clsx({ [classes.divider]: fullWidth }, styles.divider)}>
        <Divider variant="fullWidth" />
      </Grid>
      <Box>{children(currentTab)}</Box>
    </Box>
  );
};

TabsComponent.defaultProps = {
  variant: 'scrollable',
  scrollButtons: 'auto',
};

export default TabsComponent;
