import { pathOr } from 'ramda';

export interface CompetitorsData {
  _embedded: {
    homeCompetitor?: {
      logoFileLink: string;
      clubLogoFileLink: string;
    };
    awayCompetitor?: {
      logoFileLink: string;
      clubLogoFileLink: string;
    };
  };
}

export const getCompetitorLogoUrl = (
  data: CompetitorsData,
  competitorType: string,
) => {
  const competitorLogoUrl = pathOr(
    null,
    ['_embedded', competitorType, 'logoFileLink'],
    data,
  );
  if (competitorLogoUrl) {
    return competitorLogoUrl;
  }

  return pathOr(null, ['_embedded', competitorType, 'clubLogoFileLink'], data);
};
