import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckCircleOutline } from '@mui/icons-material';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { pathOr } from 'ramda';

import {
  CurrentUserResponse,
  GlobalModalProps,
  UserRoleType,
} from '@core/types';
import userProfileActions from '@core/pages/user-profile/store/actions';
import { getCurrentUser } from '@core/pages/user-profile/store/selectors';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { actions as userRoleActions } from '@core/store/modules/api/user-role';
import { getUserRoles } from '@core/store/modules/api/user-role/selectors';
import { getUserRoleType } from '@core/helpers';
import { RadioField, FullScreenSpinner, ClubsSearch } from '@core/components';
import { UserRolesList } from '@core/types/api/users';

import * as testIds from '../../tests/test-ids';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    userProfile: typeof userProfileActions;
    globalModal: typeof globalModalActions;
    userRole: typeof userRoleActions;
  };
}

interface StateProps {
  currentUser: CurrentUserResponse;
  isLoading: boolean;
  roles: UserRolesList;
}

export type Props = DispatchProps & GlobalModalProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  check: {
    color: theme.palette.success.main,
    height: '60px',
    width: '60px',
  },
  radioBox: {
    width: '100%',
  },
  title: {
    padding: theme.spacing(1),
  },
}));

const UserRoleRequestModal = (props: Props) => {
  const { t } = useTranslation();
  const { isModalOpen = false, actions, currentUser, isLoading, roles } = props;
  const classes = useStyles();
  const [showSuccessScreen, setSuccessScreen] = useState<boolean>(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedClubLabel, setSelectedClubLabel] = useState('');

  useEffect(() => {
    actions.userRole.getUserRoles();
  }, []);

  const requestAnotherRole = () => setSuccessScreen(false);

  const handleDialogClose = () => {
    setSuccessScreen(false);
    actions.globalModal.closeModal();
  };

  const requestNewUserRole = (role: { role: UserRoleType }) => {
    setSelectedClubLabel(selectedClub?.label);
    actions.userProfile.requestNewUserRole({
      ...role,
      setSuccessScreen,
      userId: currentUser.id,
      clubId: selectedClub?.value,
    });
  };

  const handleSelectChange = (club: any) => setSelectedClub(club);

  const getAdditionalContent = (role: string) => {
    if (role === UserRoleType.ROLE_CLUB_ADMIN) {
      return <ClubsSearch handleSelectChange={handleSelectChange} />;
    }

    return null;
  };

  const getRoleOptions = (translate: TFunction) =>
    roles.map(({ title, role }) => ({
      label: translate(title),
      value: role,
      additionalContentIfChecked: getAdditionalContent(role),
    }));

  const isButtonDisabled = (formikProps: any) =>
    !formikProps.values.role ||
    (formikProps.values.role === UserRoleType.ROLE_CLUB_ADMIN && !selectedClub);

  return (
    <Dialog
      onClose={handleDialogClose}
      open={isModalOpen}
      maxWidth="sm"
      fullWidth
    >
      <Formik initialValues={{ role: null }} onSubmit={requestNewUserRole}>
        {(formikProps) => {
          const handleSubmitClick = () => formikProps.handleSubmit();
          const requestForText = t('Your request for');
          const requestSentText = t(
            'role was sent and will be reviewed by responsible person.',
          );
          const getRoleText = () => {
            const role = pathOr(null, ['values', 'role'], formikProps);
            const roleText = getUserRoleType(t, role);

            return role === UserRoleType.ROLE_CLUB_ADMIN
              ? `${roleText} ${t('at')} ${selectedClubLabel}`
              : roleText;
          };
          const getDialogText = () => (
            <Grid>
              {requestForText} <strong>{getRoleText()} </strong>
              {requestSentText}
            </Grid>
          );

          return (
            <>
              {showSuccessScreen ? (
                <>
                  <Box mt={8} mx={'auto'}>
                    <CheckCircleOutline className={classes.check} />
                  </Box>
                  <Box mx={'auto'}>
                    <DialogTitle className={classes.title}>
                      {t('Role requested')}
                    </DialogTitle>
                  </Box>
                  <Box mx={'auto'} pb={5} pr={3} pl={3} justifyContent="center">
                    <Typography variant="subtitle1" align="center">
                      {getDialogText()}
                    </Typography>
                  </Box>
                  <Divider />
                  <DialogActions>
                    <Box display="flex" padding={1}>
                      <Box mr={1}>
                        <ButtonWithProgress
                          onClick={requestAnotherRole}
                          variant="outlined"
                        >
                          {t('Request another role')}
                        </ButtonWithProgress>
                      </Box>
                      <Box mr={1}>
                        <ButtonWithProgress
                          onClick={handleDialogClose}
                          disabled={!formikProps.values.role}
                          variant="contained"
                          data-qa={
                            testIds.USER_ROLE_REQUEST_MODAL_VIEW_REQUEST_BUTTON
                          }
                        >
                          {t('View requests')}
                        </ButtonWithProgress>
                      </Box>
                    </Box>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>{t('Request user role')}</DialogTitle>
                  <Divider />
                  <DialogContent>
                    <Box mb={3} mt={2}>
                      <Typography variant="body2">
                        {t('Select a user role you want to request.')}
                      </Typography>
                    </Box>
                    <Grid container>
                      {isLoading ? (
                        <FullScreenSpinner />
                      ) : (
                        <Box pr={3} className={classes.radioBox}>
                          <RadioField
                            fullWidth
                            required
                            name={'role'}
                            options={getRoleOptions(t)}
                          />
                        </Box>
                      )}
                    </Grid>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Box display="flex" padding={1}>
                      <Box mr={1}>
                        <ButtonWithProgress
                          onClick={handleDialogClose}
                          variant="outlined"
                        >
                          {t('Cancel')}
                        </ButtonWithProgress>
                      </Box>
                      <Box mr={1}>
                        <ButtonWithProgress
                          onClick={handleSubmitClick}
                          disabled={isButtonDisabled(formikProps)}
                          variant="contained"
                          data-qa={
                            testIds.USER_ROLE_REQUEST_MODAL_REQUEST_BUTTON
                          }
                        >
                          {t('Request')}
                        </ButtonWithProgress>
                      </Box>
                    </Box>
                  </DialogActions>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const isLoadingSelector = createLoadingSelector([
  userRoleActions.getUserRolesRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  currentUser: getCurrentUser(state),
  isLoading: isLoadingSelector(state),
  roles: getUserRoles(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    userProfile: bindActionCreators(userProfileActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
    userRole: bindActionCreators(userRoleActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRoleRequestModal);
