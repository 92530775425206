//  from: https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
//  Not appropriate for complex objects with circular references or functions

export const isObject = (item: any): boolean => {
  return !!item && typeof item === 'object' && !Array.isArray(item);
};

export const deepObjectMerge = (target: any, ...sources: Array<any>): any => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepObjectMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepObjectMerge(target, ...sources);
};
