import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';
import { MatchSet } from '@core/types';

import { actions } from '.';

type DefaultState = {
  [matchId: number]: Array<MatchSet>;
};

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setMatchSets.toString()]: (state, { payload }) => ({
      ...state,
      ...{ [payload.matchId]: convertArrayToObject(payload.matchSets) },
    }),
  },
  defaultState,
);

export default reducer;
