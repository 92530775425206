import { Formik, Form } from 'formik';
import {
  Grid,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { TextField } from '@core/components';
import {
  CONTACT_TITLE_INPUT,
  CONTACT_FIRSTNAME_INPUT,
  CONTACT_FAMILY_INPUT,
  CONTACT_PHONE_INPUT,
  CONTACT_EMAIL_INPUT,
  CONTACT_CANCEL_BUTTON,
  CONTACT_SUBMIT_BUTTON,
} from '@core/pages/competition-profile/tests/test-ids';
import { CreateContactData } from '@core/types';

import getInitialValues from './helpers/initialValues';
import getValidationSchema from './helpers/validationSchema';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  isOpen?: boolean;
  initialFormValues?: CreateContactData;
  isSubmitting: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '100%',
    maxWidth: '360px !important',
  },
}));

const ContactsInformationModal = ({
  handleClose,
  handleSubmit,
  isOpen,
  isSubmitting,
  initialFormValues,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isEdit = !!initialFormValues;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={getInitialValues(initialFormValues)}
        validationSchema={getValidationSchema(t)}
      >
        {() => (
          <Form noValidate>
            <DialogTitle>
              {isEdit ? t('Edit contact') : t('Add contact')}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
              >
                <Grid item className={classes.inputContainer}>
                  <TextField
                    type="text"
                    name="title"
                    label={t('Title')}
                    fullWidth
                    required
                    disabled={isSubmitting}
                    inputProps={{
                      'data-qa': CONTACT_TITLE_INPUT,
                    }}
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <TextField
                    type="text"
                    name="firstName"
                    label={t('First name')}
                    fullWidth
                    required
                    disabled={isSubmitting}
                    inputProps={{
                      'data-qa': CONTACT_FIRSTNAME_INPUT,
                    }}
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <TextField
                    type="text"
                    name="familyName"
                    label={t('Last name')}
                    fullWidth
                    required
                    disabled={isSubmitting}
                    inputProps={{
                      'data-qa': CONTACT_FAMILY_INPUT,
                    }}
                  />
                </Grid>
                <Grid item className={classes.inputContainer}>
                  <TextField
                    type="text"
                    name="phone"
                    label={t('Phone number')}
                    fullWidth
                    disabled={isSubmitting}
                    inputProps={{
                      'data-qa': CONTACT_PHONE_INPUT,
                    }}
                  />
                </Grid>
                <Grid item mb={2} className={classes.inputContainer}>
                  <TextField
                    type="text"
                    name="email"
                    label={t('Email address')}
                    fullWidth
                    disabled={isSubmitting}
                    inputProps={{
                      'data-qa': CONTACT_EMAIL_INPUT,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Box display="flex" padding={1}>
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    data-qa={CONTACT_CANCEL_BUTTON}
                    onClick={handleClose}
                  >
                    {t('Cancel')}
                  </Button>
                </Box>
                <ButtonWithProgress
                  isLoading={isSubmitting}
                  color="primary"
                  variant="contained"
                  type="submit"
                  data-qa={CONTACT_SUBMIT_BUTTON}
                >
                  {isEdit ? t('Save') : t('Add')}
                </ButtonWithProgress>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ContactsInformationModal;
