import { makeStyles } from '@mui/styles';

import { PlayerKitColors } from '@core/types';

interface StyleProps {
  playerKitColors: PlayerKitColors;
}

export const useStyles = makeStyles(() => ({
  root: {
    enableBackground: 'new 0 0 185.1 398.7',
  },
  st0: {
    opacity: 0.08,
    fill: '#231F20',
    enableBackground: 'new',
  },
  st1: {
    fill: '#DBDBDB',
  },
  st3: {
    fill: '#3A3A3A',
  },
  st4: {
    fill: '#FFFFFF',
  },
  st5: {
    fill: 'none',
  },
  st6: {
    opacity: 0.08,
    enableBackground: 'new',
  },
  st7: {
    opacity: 0.15,
    enableBackground: 'new',
  },
  primaryJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.primaryJersey,
  }),
  verticalStripesJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.verticalStripesJersey || 'none',
  }),
  horizontalStripesJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.horizontalStripesJersey || 'none',
  }),
  singleVerticalStripeJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.singleVerticalStripeJersey || 'none',
  }),
  singleHorizontalStripeJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.singleHorizontalStripeJersey || 'none',
  }),
  verticalNarrowStripesJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.verticalNarrowStripesJersey || 'none',
  }),
  horizontalNarrowStripesJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.horizontalNarrowStripesJersey || 'none',
  }),
  chequeredJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.chequeredJersey || 'none',
  }),
  obliqueStripeLeftToRightJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.obliqueStripeLeftToRightJersey || 'none',
  }),
  obliqueStripeRightToLeftJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.obliqueStripeRightToLeftJersey || 'none',
  }),
  verticallyDividedJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.verticallyDividedJersey || 'none',
  }),
  horizontallyDividedJersey: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.horizontallyDividedJersey || 'none',
  }),
  primarySocks: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.primarySocks,
  }),
  singleHorizontalStripeSocks: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.singleHorizontalStripeSocks || 'none',
  }),
  horizontallyDividedSocks: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.horizontallyDividedSocks || 'none',
  }),
  primaryShorts: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.primaryShorts,
  }),
  singleVerticalStripeShorts: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.singleVerticalStripeShorts || 'none',
  }),
  verticallyDividedShorts: ({ playerKitColors }: StyleProps) => ({
    fill: playerKitColors.verticallyDividedShorts || 'none',
  }),
}));
