import { handleActions } from 'redux-actions';

import { appendToPaginatedListFactory } from '@core/helpers';
import { VenueModel, Paginated } from '@core/types';

import actions from './actions';

export type DefaultState = {
  venues: Paginated<VenueModel['id']>;
};

type Payload = any;

export const defaultState: DefaultState = {
  venues: null,
};

const appendToVenuesList = appendToPaginatedListFactory<DefaultState>('venues');

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setVenues.toString()]: (state, { payload }) =>
      appendToVenuesList(state, payload),

    [actions.resetState.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
