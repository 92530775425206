import React, { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';

import { FullScreenSpinner } from '@core/components';

export interface ValueSummaryProps {
  expanded: boolean;
  isLoading?: boolean;
}

interface OwnProps {
  children: any;
  summaryComponent: (props: ValueSummaryProps) => any;
  summaryComponentProps: any;
  testId?: string;
  onChange?: (event: React.ChangeEvent<any>, expanded: boolean) => void;
  showIcon?: boolean;
  isLoading?: boolean;
}

type Props = AccordionProps & OwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    position: 'relative',
  },
  summary: {
    padding: theme.spacing(0, 2),
    minHeight: `${theme.spacing(6)} !important`,
  },
  details: {
    padding: theme.spacing(2),
  },
  expandIcon: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const ExpansionField = (props: Props) => {
  const {
    summaryComponent,
    children,
    testId,
    onChange,
    showIcon = true,
    isLoading,
    summaryComponentProps,
  } = props;
  const classes = useStyles();
  const [isExpanded, toggleExpansion] = useState(false);

  const handleChange = (event: React.ChangeEvent<any>, expanded: boolean) => {
    onChange && onChange(event, expanded);
    toggleExpansion(expanded);
  };

  const expansionIcon = showIcon && <ExpandMore />;

  return (
    <Grid data-qa={testId} className={classes.root}>
      <Accordion elevation={0} variant="outlined" onChange={handleChange}>
        <AccordionSummary
          expandIcon={expansionIcon}
          className={classes.summary}
          classes={{ expandIconWrapper: classes.expandIcon }}
        >
          {summaryComponent({
            expanded: isExpanded,
            isLoading,
            ...summaryComponentProps,
            hideValue:
              summaryComponentProps?.hideValueIfExpanded && isExpanded
                ? summaryComponentProps?.hideValueIfExpanded
                : false,
          })}
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          {isLoading ? <FullScreenSpinner /> : children}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ExpansionField;
