import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { GlobalModalProps } from '@core/types';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
  };
}

type Props = DispatchProps & GlobalModalProps;

const SessionExpiredModal = (props: Props) => {
  const { t } = useTranslation();
  const { isModalOpen = false, actions } = props;

  function handleDialogClose() {
    actions.globalModal.closeModal();
  }

  return (
    <Dialog
      onClose={handleDialogClose}
      open={isModalOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('Session expired')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {t('Your session has expired. Please sign in again.')}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <ButtonWithProgress onClick={handleDialogClose} variant="contained">
              {t('Sign in again')}
            </ButtonWithProgress>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SessionExpiredModal);
