import { object, string, number } from 'yup';
import { TFunction } from 'i18next';

import { MAX_FIELD_LENGTH } from '@core/constants';

import { COMPETITOR_FIELDS } from '../constants';

export const getValidationSchema = (t: TFunction) =>
  object().shape({
    [COMPETITOR_FIELDS.title]: string()
      .required(t('Required'))
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      ),
    [COMPETITOR_FIELDS.internationalTitle]: string()
      .max(
        MAX_FIELD_LENGTH,
        t('Maximum {{value}} characters allowed', { value: MAX_FIELD_LENGTH }),
      )
      .nullable(),
    [COMPETITOR_FIELDS.teamColorSetId]: number().required(t('Required')),
  });

export default getValidationSchema;
