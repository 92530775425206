import { ClubTypes } from '@core/types';

export const getClubType = (
  t: (key: string) => string,
  clubType: keyof typeof ClubTypes,
) => {
  switch (clubType) {
    case ClubTypes.CLUB:
      return t('Club');
    case ClubTypes.NATIONAL_CLUB:
      return t('National');
    default:
      return clubType;
  }
};
