import { all, put, take, call } from 'redux-saga/effects';
import download from 'downloadjs';

import { handleApiErrors } from '@core/helpers';
import {
  getFileList,
  getFile,
  deleteFile,
  uploadFile,
  overwriteFile,
} from '@core/api/files';

import * as actions from '.';

export function* getFileListFlow() {
  while (true) {
    const { payload } = yield take(actions.getFiles);

    yield put(actions.getFilesRequest());
    const { error, response } = yield call(
      getFileList,
      payload.objectType,
      payload.objectId,
      payload.fileType,
    );

    if (!error) {
      yield put(
        actions.setFileList({
          objectId: payload.objectId,
          payload: { ...response._embedded },
        }),
      );
      yield put(actions.getFilesSuccess());
    } else {
      yield put(
        actions.setFileList({ objectId: payload.objectId, payload: error }),
      );
      yield put(actions.getFilesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getFileFlow() {
  while (true) {
    const { payload } = yield take(actions.getFile);

    yield put(actions.getFileRequest());
    const { error, response } = yield call(
      getFile,
      payload.objectType,
      payload.objectId,
      payload.fileType,
      payload.fileId,
    );

    if (!error) {
      yield call(download, response, payload.fileName, payload.mimeType);
      yield put(actions.getFileSuccess());
    } else {
      yield put(actions.getFileFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* deleteFileFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteFile);

    yield put(actions.getFilesRequest());
    const { error } = yield call(
      deleteFile,
      payload.objectType,
      payload.objectId,
      payload.fileType,
      payload.fileId,
    );

    if (!error) {
      yield put(actions.deleteFileSuccess());
      yield put(
        actions.getFiles({
          objectType: payload.objectType,
          objectId: payload.objectId,
          fileType: payload.fileType,
        }),
      );
    } else {
      yield put(actions.deleteFileFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* uploadFileFlow() {
  while (true) {
    const { payload } = yield take(actions.uploadFile);

    yield put(actions.uploadFileRequest());
    const { error } = yield call(
      uploadFile,
      payload.objectType,
      payload.objectId,
      payload.fileType,
      payload?.file,
      payload?.documentName,
      payload?.documentType,
    );

    if (!error) {
      yield put(actions.uploadFileSuccess());
      yield put(
        actions.getFiles({
          objectType: payload.objectType,
          objectId: payload.objectId,
          fileType: payload.fileType,
        }),
      );
    } else {
      yield put(actions.uploadFileFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* overwriteFileFlow() {
  while (true) {
    const { payload } = yield take(actions.overwriteFile);

    yield put(actions.overwriteFileRequest());
    const { error } = yield call(
      overwriteFile,
      payload.objectType,
      payload.objectId,
      payload.fileType,
      payload.file,
      payload.fileId,
      payload?.documentName,
      payload?.documentType,
    );

    if (!error) {
      yield put(actions.overwriteFileSuccess());
      yield put(
        actions.getFiles({
          objectType: payload.objectType,
          objectId: payload.objectId,
          fileType: payload.fileType,
        }),
      );
    } else {
      yield put(actions.overwriteFileFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    getFileListFlow(),
    getFileFlow(),
    deleteFileFlow(),
    uploadFileFlow(),
    overwriteFileFlow(),
  ]);
}
