import { reject, ifElse, is, always, propEq } from 'ramda';

export const filterStartingLineup = ifElse(
  is(Object),
  reject(propEq('isSubstitute', true)),
  always(null),
);

export const filterSubstitutePlayers = ifElse(
  is(Object),
  reject(propEq('isSubstitute', false)),
  always(null),
);
