import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { VenueModel } from '@core/types';
import { State } from '@core/store';

const getIds = (state: State, ids: Array<number>) => ids;
export const getVenuesList = (state: State): { [id: number]: VenueModel } =>
  pathOr(null, ['api', 'venuesList'], state);

export const getVenuesById = createSelector(
  getIds,
  getVenuesList,
  (ids, venues): Array<VenueModel> => ids.map((id: number) => venues[id]),
);
