import { handleActions } from 'redux-actions';

import actions from './actions';

type DefaultState = {
  sports: Array<number>;
};

type Payload = any;

export const defaultState: DefaultState = {
  sports: null,
};

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setSports.toString()]: (state, { payload }) => ({
      ...state,
      sports: payload,
    }),
  },
  defaultState,
);

export default reducer;
