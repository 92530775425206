import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ContainerProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

import * as testIds from '../tests/test-ids';

type Props = ContainerProps<AutocompleteOption, boolean>;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    boxSizing: 'border-box',
  },
}));

const ValueContainer = ({ children, innerProps }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      data-qa={testIds.AUTOCOMPLETE}
      className={classes.root}
      {...(innerProps as any)}
    >
      {children}
    </Grid>
  );
};

export default ValueContainer;
