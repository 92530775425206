import { Typography, Grid, alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Info, Warning, Error, SvgIconComponent } from '@mui/icons-material';

enum AlertType {
  INFO,
  WARNING,
  ERROR,
}

export interface AlertProps {
  title: string;
  boldedTitle?: string;
  noBg?: boolean;
  padding?: number;
  warning?: boolean;
  error?: boolean;
  children?: any;
}

const getColor = (type: AlertType, theme: Theme) =>
  ({
    [AlertType.INFO]: theme.palette.primary.main,
    [AlertType.WARNING]: theme.palette.warning.main,
    [AlertType.ERROR]: theme.palette.error.main,
  })[type];

const determineType = (warning?: boolean, error?: boolean) => {
  if (warning) {
    return AlertType.WARNING;
  } else if (error) {
    return AlertType.ERROR;
  }

  return AlertType.INFO;
};

const useStyles = makeStyles<Theme, AlertProps>((theme: Theme) => ({
  root: {
    backgroundColor: ({ noBg, warning, error }) =>
      noBg
        ? 'inherit'
        : alpha(getColor(determineType(warning, error), theme), 0.15),
    borderRadius: 5,
    padding: ({ padding }) => padding || theme.spacing(2),
  },
  icon: {
    display: 'flex',
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: ({ warning, error }) =>
      getColor(determineType(warning, error), theme),
  },
}));

const Alert = (props: AlertProps) => {
  const { title, boldedTitle, warning, error, children } = props;
  const type = determineType(warning, error);
  const classes = useStyles(props);
  const Icon: SvgIconComponent = {
    [AlertType.INFO]: Info,
    [AlertType.WARNING]: Warning,
    [AlertType.ERROR]: Error,
  }[type];

  return (
    <Grid className={classes.root}>
      <Grid
        container
        wrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Icon className={classes.icon} data-qa={`icon-${type}`} />
        </Grid>
        <Grid item>
          <Typography variant="body2" display="inline">
            {title}
          </Typography>
          <Typography
            variant="body2"
            display="inline"
            style={{ fontWeight: 'bold' }}
          >
            {' '}
            {boldedTitle}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};

export default Alert;
