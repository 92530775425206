import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import {
  // getSessionExpirationTime,
  // getAccessToken,
  getAuthProvider,
} from '@core/helpers';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import * as authActions from '@core/pages/login/store/actions';
import { actions as usersActions } from '@volleyball/store/modules/ui/users';
import { State } from '@volleyball/store';

dayjs.extend(duration);

// const ALERT_BEFORE = dayjs.duration({ minutes: 5 }).asMilliseconds();
// const SESSION_ENDING_CHECK_INTERVAL = dayjs
//   .duration({ minutes: 1 })
//   .asMilliseconds();
const COUNTDOWN_TILL_LOGOUT = dayjs.duration({ minutes: 1 }).asSeconds();

interface DispatchProps {
  actions: {
    auth: typeof authActions;
    users: typeof usersActions;
  };
}

interface StateProps {
  isLoading: boolean;
}

type Props = DispatchProps & StateProps;

const SessionExpirationWarningModal = ({ actions }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(COUNTDOWN_TILL_LOGOUT);
  // const [isTimerStarted, setIsTimerStarted] = useState(false);

  const authProvider = getAuthProvider();

  // const shouldOpenModal = (timeTillSessionEnd: number) =>
  //   timeTillSessionEnd <= ALERT_BEFORE && !isModalOpen;

  function resetModal() {
    setIsModalOpen(false);
    // setIsTimerStarted(false);
  }

  // useEffect(() => {
  //   return null;
  //   if (authProvider === 'auth0') return null;
  //   const expirationTimer = setInterval(() => {
  //     if (!getAccessToken() || isTimerStarted) return;

  //     const timeTillSessionEnd = dayjs(getSessionExpirationTime()).diff(
  //       dayjs()
  //     );

  //     if (shouldOpenModal(timeTillSessionEnd)) {
  //       setTimeLeft(COUNTDOWN_TILL_LOGOUT);
  //       setIsModalOpen(true);
  //     }
  //   }, SESSION_ENDING_CHECK_INTERVAL);

  //   return () => clearInterval(expirationTimer);
  // }, [authProvider, isLoading, isTimerStarted]);

  useEffect(() => {
    if (authProvider !== 'auth0' && isModalOpen) {
      // setIsTimerStarted(true);

      if (timeLeft === 0) {
        actions.auth.logoutSessionExpired();
        resetModal();
      }
      const countdownInterval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [authProvider, isModalOpen, timeLeft, actions.auth]);

  function handleLogoutClick() {
    actions.auth.logout();
    resetModal();
  }

  function handleStaySignedInClick() {
    actions.users.refreshUserSession();
    resetModal();
  }

  return (
    <Dialog onClose={resetModal} open={isModalOpen} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Your session is about to expire')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {`${t('You have been inactive for a while')}. ${t(
            'For your security, we will log you out in {{timeLeft}} seconds',
            {
              timeLeft,
            },
          )}. ${t('Do you want to stay signed in?')}`}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Box mr={1}>
            <Button onClick={handleLogoutClick} variant="outlined">
              {t('Logout now')}
            </Button>
          </Box>
          <Button onClick={handleStaySignedInClick} variant="contained">
            {t('Stay signed in')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const refreshSessionLoadingSelector = createLoadingSelector([
  usersActions.refreshUserSessionRequest.toString(),
  authActions.logoutSessionExpiredRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch),
    users: bindActionCreators(usersActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  isLoading: refreshSessionLoadingSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionExpirationWarningModal);
