import { call, Effect, put, takeLatest } from 'redux-saga/effects';

import * as cityApi from '@core/api/city';

import { actions } from '.';

export function* getCity(cityId: number): Generator<Effect, any, any> {
  yield put(actions.getCityRequest());
  const response = yield call(cityApi.getCity, cityId);

  if (!response.error) {
    yield put(actions.getCitySuccess());
    yield put(actions.setCity(response.response));
  } else {
    yield put(actions.getCityFailure());
  }

  return response;
}

export function* getCityListFlow(params?: any): Generator<Effect, any, any> {
  const { payload } = params;

  yield put(actions.getCityListRequest());
  const response = yield call(cityApi.getCityList, payload);

  if (!response.error) {
    yield put(actions.getCityListSuccess());
    yield put(actions.setCityList(response.response));
    yield put(
      actions.setCityListMeta({
        links: response?.response?._links,
        limit: response?.response.limit,
        page: response?.response.page,
        pages: response?.response.pages,
        total: response?.response.total,
      }),
    );
  } else {
    yield put(actions.getCityListFailure());
  }

  return response;
}

export default function* saga() {
  yield takeLatest(actions.getCityList, getCityListFlow);
}
