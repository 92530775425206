import { ThemeOptions } from '@mui/material';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const baseTheme: ThemeOptions = {
  palette,
  typography,
  mixins: {
    toolbar: {
      minHeight: 64,
    },
    sideMenu: {
      minWidth: 210,
    },
  },
  components: overrides,
};

export default baseTheme;
