import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uuid } from '@core/helpers';
import {
  AddCircleOutlineOutlined,
  DeleteTwoTone,
  EditOutlined,
  EditTwoTone,
} from '@mui/icons-material';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { useConfirmationDialog } from '@core/hooks';
import {
  ContentBlock,
  CustomTable,
  TabTitle,
  NamedValueColumn,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { getStageSubHeader, createURL, formatDate } from '@core/helpers';
import {
  STAGE_PROFILE_EDIT_DETAILS_BUTTON,
  STAGE_PROFILE_EDIT_POOL_BUTTON,
  STAGE_PROFILE_DELETE_POOL_BUTTON,
  STAGE_PROFILE_INFO_BLOCK,
  STAGE_PROFILE_TEAMS_BLOCK,
  STAGE_PROFILE_CREATE_GROUP_BUTTON,
} from '@core/pages/competition-profile/tests/test-ids';
import { Column } from '@core/components/material-table';
import { getGroupedGroups } from '@core/components/stage-general-information-template';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { State } from '@core/store';
import { Group, ModalTypes, StageType, CustomerResponse } from '@core/types';
import paths from '@core/routes/paths';

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  stage: Group;
  competitionId: number;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
    competitionProfile: typeof competitionProfileActions;
    globalModal: typeof globalModalActions;
  };
}

type Props = StateProps & OwnProps & DispatchProps;

const StageGeneralInformationTab = ({
  stage,
  competitionId,
  actions,
  currentCustomer,
}: Props) => {
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const canEditStage = !!stage?._links?.patch;

  const handleEditStage = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.stageGeneralInformationEdit),
      props: {
        stageId: stage?.id,
        competitionId,
      },
      id: uuid(),
    });
  };

  const handleCreateGroup = () => {
    actions.globalModal.openModal({
      type: ModalTypes.GROUP_CREATE_MODAL,
      params: {
        competitionId,
        stage,
        groupBeingModified: null,
      },
    });
  };

  const handleEditGroup = (group: Group) => () => {
    actions.globalModal.openModal({
      type: ModalTypes.GROUP_CREATE_MODAL,
      params: {
        competitionId,
        groupBeingModified: group,
        isEditMode: true,
      },
    });
  };

  const handleDeleteGroup = (groupId: number) => async () => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this pool?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      actions.competitionProfile.deleteGroup({
        competitionId,
        stageId: stage.id,
        groupId,
      });
    }
  };

  const tabActions = canEditStage && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleEditStage}
      data-qa={STAGE_PROFILE_EDIT_DETAILS_BUTTON}
      startIcon={<EditOutlined />}
    >
      {t('Edit')}
    </Button>
  );

  const columns = new Array<Column<Group>>(
    {
      title: stage.stageType === StageType.KNOCK_OUT ? t('Round') : t('Pool'),
      field: 'title',
      headerStyle: {
        width: '50%',
      },
    },
    {
      title:
        stage.stageType === StageType.KNOCK_OUT
          ? t('Best of matches')
          : t('Teams'),
      field:
        stage.stageType === StageType.KNOCK_OUT ? 'bestOf' : 'numberOfTeams',
      headerStyle: {
        width: '50%',
      },
    },
  );

  if (stage.stageType === StageType.ROUND_ROBIN) {
    columns.push({
      field: 'actions',
      render: (rowData) => {
        const canEditChildGroup = !!rowData?._links?.patch;
        const canDeleteChildGroup = !!rowData?._links?.delete;

        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {canEditChildGroup && (
              <IconButton
                color="primary"
                onClick={handleEditGroup(rowData)}
                title={t('Edit')}
                data-qa={STAGE_PROFILE_EDIT_POOL_BUTTON}
              >
                <EditTwoTone />
              </IconButton>
            )}
            {canDeleteChildGroup && (
              <IconButton
                color="primary"
                onClick={handleDeleteGroup(rowData.id)}
                title={t('Delete')}
                data-qa={STAGE_PROFILE_DELETE_POOL_BUTTON}
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </Box>
        );
      },
    });
  }

  const {
    knockoutDoubleEliminationCrossOverGroups,
    knockoutDoubleEliminationLosersGroups,
    knockoutDoubleEliminationWinnerssGroups,
    knockoutSingleEliminationGroups,
    roundRobinGroups,
    knockoutExtraGroups,
  } = getGroupedGroups(stage);

  const groupedGroupsSortedTuple = [
    roundRobinGroups,
    knockoutSingleEliminationGroups,
    knockoutDoubleEliminationWinnerssGroups,
    knockoutDoubleEliminationLosersGroups,
    knockoutDoubleEliminationCrossOverGroups,
    knockoutExtraGroups,
  ].filter((group) => group.length > 0);

  return (
    stage && (
      <>
        <TabTitle
          actions={tabActions}
          rootStyles={{ marginTop: tabActions ? 24 : 0 }}
        />
        <ContentBlock
          title={t('Titles & Description')}
          data-qa={STAGE_PROFILE_INFO_BLOCK}
        >
          <Grid xs={12} sm={4} item>
            <NamedValueColumn label={t('Stage title')} value={stage.title} />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('International stage title')}
              value={stage.internationalName}
            />
          </Grid>
          <Grid xs={12} sm={12} item>
            <NamedValueColumn
              label={t('Description')}
              value={stage.description}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Start date')}
              value={formatDate(currentCustomer?.dateFormat, stage.startDate)}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('End date')}
              value={formatDate(currentCustomer?.dateFormat, stage.endDate)}
            />
          </Grid>
        </ContentBlock>
        <ContentBlock title={t('Format')} data-qa={STAGE_PROFILE_TEAMS_BLOCK}>
          <Grid xs={12} sm={4} item>
            <NamedValueColumn
              label={t('Stage type')}
              value={getStageSubHeader(t, stage.stageType)}
            />
          </Grid>
          {stage.stageType === StageType.ROUND_ROBIN && (
            <Grid xs={12} sm={4} item>
              <NamedValueColumn
                label={t('Times teams play each other')}
                value={stage.numberOfRounds}
              />
            </Grid>
          )}
          {stage.stageType === StageType.KNOCK_OUT && (
            <Grid xs={12} sm={4} item>
              <NamedValueColumn
                label={t('Number of teams')}
                value={stage.numberOfTeams}
              />
            </Grid>
          )}
          {groupedGroupsSortedTuple.map((group, index) => (
            <Grid key={index} item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} mb={3}>
                  <CustomTable
                    noPaper
                    columns={columns}
                    data={group || []}
                    topBorder
                  />
                </Grid>
                {stage.stageType === StageType.ROUND_ROBIN && (
                  <Grid item xs={12} textAlign="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCreateGroup}
                      startIcon={<AddCircleOutlineOutlined />}
                      data-qa={STAGE_PROFILE_CREATE_GROUP_BUTTON}
                    >
                      {t('Create Pool')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </ContentBlock>
      </>
    )
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageGeneralInformationTab);
