import { handleActions } from 'redux-actions';
import { assocPath, assoc } from 'ramda';

import { Paginated, Filters, Sorting, MatchModel } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  matches: Paginated<MatchModel['id']>;
  searchQuery: string;
  filters: Filters;
  sorting: Sorting;
}

export const defaultState: DefaultState = {
  matches: null,
  searchQuery: '',
  filters: {},
  sorting: {
    sortingOrderBy: 'date',
    sortingDirection: 'desc',
  },
};

const reducer = handleActions<DefaultState, any>(
  {
    [actions.setMatches.toString()]: (state, { payload }) =>
      assocPath(['matches'], payload, state),

    [actions.setSearchQuery.toString()]: (state, { payload }) =>
      assoc('searchQuery', payload, state),

    [actions.setFilterParams.toString()]: (state, { payload }) =>
      assoc('filters', payload, state),

    [actions.setSorting.toString()]: (state, { payload }) =>
      assoc('sorting', payload, state),

    [actions.resetMatches.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
