import { object, number } from 'yup';
import { TFunction } from 'i18next';
import { FIELD_NAMES } from '../constants';

export const getValidationSchema = (t: TFunction) =>
  object().shape({
    [FIELD_NAMES.numberOfSets]: number()
      .min(1, t('Minimum {{value}} set', { value: 1 }))
      .required(t('Required')),
    [FIELD_NAMES.pointsToWinInRegularSet]: number()
      .min(1, t('Minimum {{value}} point', { value: 1 }))
      .required(t('Required')),
    [FIELD_NAMES.pointsToWinInTiebreakerSet]: number()
      .min(1, t('Minimum {{value}} point', { value: 1 }))
      .required(t('Required')),
    [FIELD_NAMES.pointsToWinInGoldenSet]: number().when(
      FIELD_NAMES.goldenSetRule,
      ([formValue], schema) =>
        formValue === true
          ? schema
              .required(t('Required'))
              .min(1, t('Minimum {{value}} point', { value: 1 }))
          : schema,
    ),
    [FIELD_NAMES.pointsToWinInGoldenMatchSet]: number().when(
      FIELD_NAMES.goldenMatchRule,
      ([formValue], schema) =>
        formValue === true
          ? schema
              .required(t('Required'))
              .min(1, t('Minimum {{value}} point', { value: 1 }))
          : schema,
    ),
  });
