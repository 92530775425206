export const MATCH_TABLE_VIEW_BUTTON = 'MATCH_TABLE_VIEW_BUTTON';
export const MATCH_TABLE_EDIT_BUTTON = 'MATCH_TABLE_EDIT_BUTTON';
export const CAPTAIN_RADIO_BUTTON = 'CAPTAIN_RADIO_BUTTON';
export const LIBERO_CHECKBOX = 'LIBERO_CHECKBOX';
export const MATCH_SHEET_TABS = 'MATCH_SHEET_TABS';
export const MATCH_TABS = 'MATCH_TABS';
export const MATCH_LINEUP_TAB = 'MATCH_LINEUP_TAB';
export const MATCH_DATA_TAB = 'MATCH_DATA_TAB';
export const MATCH_DOCUMENTS_TAB = 'MATCH_DOCUMENTS_TAB';
export const MATCH_OFFICIALS_TAB = 'MATCH_OFFICIALS_TAB';
export const MATCH_PROFILE_TEAMS_TABS = 'MATCH_PROFILE_TEAMS_TABS';
export const MATCH_SHEET_SUBMIT_BUTTON = 'MATCH_SHEET_SUBMIT_BUTTON';

export const CREATE_MATCH_EVENT_BUTTON = 'CREATE_MATCH_EVENT_BUTTON';
export const CREATE_EVENT_GOAL = 'CREATE_EVENT_GOAL';
export const CREATE_EVENT_SUBSTITUTION = 'CREATE_EVENT_SUBSTITUTION';
export const CREATE_EVENT_CARD = 'CREATE_EVENT_CARD';
export const CREATE_EVENT_PENALTY = 'CREATE_EVENT_PENALTY';
export const MATCH_EVENT_CARD = 'MATCH_EVENT_CARD';
