import { State } from '@core/store';

export const getCompetitorTeamOfficialsById = (
  state: State,
  ids: Array<number>,
) => {
  const competitorTeamOfficialsById = state.api.competitorTeamOfficialsList;

  return ids.map((id: number) => competitorTeamOfficialsById[id]);
};
