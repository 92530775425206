import React from 'react';
import { Grid, Box, Divider, Avatar, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiberManualRecord, ArrowUpward } from '@mui/icons-material';

import { MatchSheetHistoryModel } from '@core/types';

interface OwnProps {
  matchSheetHistory: Array<MatchSheetHistoryModel>;
}

type Props = OwnProps;

interface IndicatorProps {
  addDivider?: boolean;
  customTopMargin?: number;
  icon?: React.ReactNode;
  current?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  inactive: {
    backgroundColor: theme.palette.grey[400],
  },
  timelineConnector: {
    width: 2,
    color: theme.palette.grey[400],
  },
}));

const Indicator = (props: IndicatorProps) => {
  const { addDivider, customTopMargin, icon, current } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      item
      direction="column"
      wrap="nowrap"
      justifyContent="center"
    >
      {addDivider && (
        <Grid container item justifyContent="center">
          <Box display="flex" flex="1">
            <Divider
              flexItem
              className={classes.timelineConnector}
              variant="middle"
              orientation="vertical"
              style={{ height: icon ? 26 : 32 }}
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          mb={1}
          mt={customTopMargin ? customTopMargin : 1}
        >
          {icon ? (
            <Avatar
              style={{ height: 32, width: 32 }}
              className={current ? classes.active : ''}
            >
              {icon}
            </Avatar>
          ) : (
            <FiberManualRecord
              fontSize="small"
              color={current ? 'primary' : 'disabled'}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
const MatchSheetHistoryIndicators = (props: Props) => {
  const { matchSheetHistory } = props;

  return (
    matchSheetHistory && (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: '100%', width: 60, paddingRight: 14, paddingLeft: 14 }}
      >
        {matchSheetHistory.map((event, index) => {
          return (
            <Indicator
              key={index}
              current={!index}
              icon={
                event.action.toString() === 'SUBMIT' &&
                !!index && <ArrowUpward fontSize="small" />
              }
              customTopMargin={!index && 2}
              addDivider={!!index}
            />
          );
        })}
      </Grid>
    )
  );
};

export default MatchSheetHistoryIndicators;
