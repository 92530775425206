import type { TFunction } from 'i18next';
import { object, number, string } from 'yup';
import { validateStringAgainstDayjsFormat } from '@core/helpers';
import { FIELD_NAMES } from '../constants';

export const getValidationSchema = (
  t: TFunction,
  stageGroupsExist?: boolean,
) => {
  let validationRules = {
    [FIELD_NAMES.groupOption]: number().notRequired().nullable(),
    [FIELD_NAMES.matchDayNumber]: number()
      .min(1, t('Match day must be {{value}} or higher', { value: 1 }))
      .required(t('Required')),
    [FIELD_NAMES.number]: string().trim().required(t('Required')),
    [FIELD_NAMES.date]: string()
      .required(t('Required'))
      .test('is-valid-date-format', t('Invalid date'), (value) =>
        validateStringAgainstDayjsFormat(value, 'YYYY-MM-DD'),
      ),
    [FIELD_NAMES.time]: string()
      .required(t('Required'))
      .test('is-valid-time-format', t('Invalid time'), (value) =>
        validateStringAgainstDayjsFormat(value, 'HH:mm'),
      ),
    [FIELD_NAMES.awayCompetitorId]: number().when(
      FIELD_NAMES.homeCompetitorId,
      ([homeCompetitorId], schema) =>
        schema
          .test({
            test: (awayCompetitorId: number) => {
              return homeCompetitorId
                ? awayCompetitorId !== homeCompetitorId
                : true;
            },
            message: t('Away team should not be the same as home team'),
          })
          .nullable(),
    ),
  };

  if (stageGroupsExist) {
    validationRules = {
      ...validationRules,
      [FIELD_NAMES.groupOption]: number().required(t('Required')),
    };
  }

  return object().shape(validationRules);
};
