import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Card,
  Tooltip,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDateTime } from '@core/helpers';
import { CustomerResponse, MatchSheetHistoryModel } from '@core/types';
import { getMatchSheetHistory } from '@core/store/modules/tabs/match-sheet-management/selectors';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import { State } from '@core/store';

import MatchSheetHistoryIndicators from '../match-sheet-history-indicators';
import { ACTION_TYPES } from './constants';

interface OwnProps {
  matchId: number;
  competitorId: number;
}

interface StateProps {
  matchSheetHistory: Array<MatchSheetHistoryModel>;
  currentCustomer: CustomerResponse;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  bolderText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const MatchSheetHistory = (props: Props) => {
  const { matchId, competitorId, matchSheetHistory, actions, currentCustomer } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    actions.matchSheetManagement.getMatchSheetHistory({
      matchId,
      competitorId,
    });
  }, []);

  const reversedMatchSheetHistory =
    matchSheetHistory && matchSheetHistory.slice().reverse();
  const getRoles = (roles: Array<string>) => {
    return roles.length === 1 ? (
      `(${roles[0]})`
    ) : (
      <Tooltip
        title={
          <>
            {roles.map((role, index) => (
              <Grid key={index}>{role}</Grid>
            ))}
          </>
        }
        placement="bottom"
      >
        <span>({t('Multiple roles')})</span>
      </Tooltip>
    );
  };

  return reversedMatchSheetHistory ? (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>
        <MatchSheetHistoryIndicators
          matchSheetHistory={reversedMatchSheetHistory}
        />
      </Grid>
      <Grid container item direction="column">
        {reversedMatchSheetHistory.map((event, index) => {
          return (
            <Box key={index} flex={1} mb={2}>
              <Card style={{ flex: 1 }}>
                <Box
                  display="flex"
                  m={2}
                  flexWrap="wrap"
                  style={{ wordBreak: 'break-all' }}
                >
                  <Typography variant="body2" className={classes.bolderText}>
                    {event.user.firstName} {event.user.lastName}{' '}
                    {getRoles(event.user.roles)}
                  </Typography>
                  <Box mx={1}>
                    <Typography variant="body2">
                      {t(ACTION_TYPES[event.action])}
                    </Typography>
                  </Box>
                  <Typography variant="body2" className={classes.bolderText}>
                    {t('Match sheet')}
                  </Typography>
                  <Box ml={2}>
                    <Typography variant="body2" color="textSecondary">
                      {formatDateTime(
                        currentCustomer?.dateTimeFormat,
                        event.createdAt,
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (
  state: State,
  { matchId, competitorId }: OwnProps,
): StateProps => ({
  matchSheetHistory: getMatchSheetHistory(state, { matchId, competitorId }),
  currentCustomer: getCurrentCustomer(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchSheetHistory);
