import useCheckToggles from '@core/helpers/feature-toggle/check-toggles';
import { AddedMatchPlayer, MatchPlayer } from '@core/types';

/**
 * This is a helper hook that extracts the jersey number based on a feature flag.
 * Will be removed after the feature flag is not needed.
 */
export const useMatchForJerseyNumbers = () => {
  const featureToggle = useCheckToggles(
    'COMP_5192_FRONT_END_USE_MATCH_FOR_JERSEY_NUMBERS',
  );

  return (match: MatchPlayer | AddedMatchPlayer) =>
    featureToggle ? match?.jerseyNumber : match.competitorPlayer.jerseyNumber;
};
