import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';
import { Typography, Theme } from '@mui/material';
import { FilterTabProps, Sports } from '@core/types';
import * as sportsActions from '@core/store/modules/sports/actions';
import { getSports } from '@core/helpers';
import { FilterCheckboxList } from '@core/components';
import { useSports } from '@core/hooks';
import { SPORT_FILTER_ID, SPORT_FILTER } from '../constants';

interface DispatchProps {
  actions: {
    sports: typeof sportsActions;
  };
}

type Props = FilterTabProps & DispatchProps;

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkbox: {
    padding: 8,
  },
}));

const SportFilter = (props: Props) => {
  const { t } = useTranslation();
  const { filters, actions, ...rest } = props;
  const classes = useStyles();
  const { sports } = useSports();

  const filterValues = filters[SPORT_FILTER_ID]?.filterValues || [];

  useEffect(() => {
    actions.sports.getSports();
  }, []);

  function formatFilterLabel(val: string) {
    const sportType = sports?.length
      ? sports.find((sport) => +sport.id === +val).type
      : '';

    return getSports(t, sportType as Sports);
  }

  const sportList = sports?.length
    ? sports.map((sport: { id: number; type: Sports }) => sport.id)
    : [];

  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Sport')}
      </Typography>
      <FilterCheckboxList
        shifted
        list={sportList}
        filterProps={SPORT_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    sports: bindActionCreators(sportsActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SportFilter);
