import { take, call, fork } from 'redux-saga/effects';
import { ActionPattern, ActionMatchingPattern } from '@redux-saga/types';
import deepEqual from 'fast-deep-equal';

export function takeLeadingByPredicate<P extends ActionPattern>(
  pattern: P,
  worker: (action: ActionMatchingPattern<P>) => any,
) {
  return fork(function* () {
    const cache: Array<any> = [];

    while (true) {
      const action = (yield take(pattern)) as any;
      const cacheKey = action;
      const getCacheIndex = () =>
        cache.findIndex((value) => deepEqual(value, cacheKey));

      // N.B. index can change inbetween
      if (getCacheIndex() === -1) {
        yield fork(function* () {
          cache.push(cacheKey);
          yield call(worker, action);
          cache.splice(getCacheIndex(), 1);
        });
      }
    }
  });
}
