import { useSelector } from 'react-redux';
import { SearchableFilterList } from '@core/components';
import { FilterTabProps } from '@core/types';
import { actions as teamsActions } from '@core/store/modules/modals/teams-filter-tab';
import {
  areTeamsLoading,
  getTeams,
} from '@core/store/modules/modals/teams-filter-tab/selectors';
import { TEAMS_FILTER_ID, TEAMS_FILTER } from './constants';
import { useCoreDispatch } from '@core/hooks';

type Props = FilterTabProps<{
  initialQueryParams?: {
    'filter[candidatesForCompetition]'?: number;
  };
}>;

const GlobalTeamsFilterTab = (props: Props) => {
  const dispatch = useCoreDispatch();

  const isLoading = useSelector(areTeamsLoading);
  const teams = useSelector(getTeams);

  const findCompetitorTitle = (id: string) =>
    teams?.list.find((team) => team.id === Number(id)).title;

  const fetchTeams = () => dispatch(teamsActions.getTeams());

  const resetState = () => dispatch(teamsActions.resetState());

  const searchTeamsByQuery = (queryParams: Record<string, unknown>) =>
    dispatch(teamsActions.searchTeamsByQuery(queryParams));

  return (
    <SearchableFilterList
      isLoading={isLoading}
      paginatedList={teams}
      filterId={TEAMS_FILTER_ID}
      filterProps={TEAMS_FILTER}
      getList={fetchTeams}
      resetList={resetState}
      searchByQuery={searchTeamsByQuery}
      formatFilterLabel={findCompetitorTitle}
      {...props}
    />
  );
};

export default GlobalTeamsFilterTab;
