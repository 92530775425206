import React, { useCallback, useMemo, useRef } from 'react';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { GridItems } from '@core/components';
import { GlobalModalProps, Group, MatchModel } from '@core/types';
import { OnSuccessEffectParameters } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import { actions as matchesActions } from '@core/store/modules/matches';
import { actions as matchSetsActions } from '@core/store/modules/match-set';
import useModalStyles from '@core/components/global-modal/styles';
import { MatchSet } from '@volleyball/types';
import {
  getMatchSetFormFieldNames,
  getNextAvailableMatchSetNumber,
} from './helpers';
import { MatchDataFormFieldsMatchSets } from './match-data-form-fields-match-sets';
import { MatchDataFormFieldsMatchTime } from './match-data-form-fields-match-time';
import { MatchDataFormFieldsMatchAttendance } from './match-data-form-fields-match-attendance';
import {
  getSingleMatchSetValidationSchema,
  getValidate,
} from './match-data-create-match-sets';
import { getValidationSchema as getMatchDetailsValidationSchema } from './match-data-create-match-details';
import type { TFunction } from 'i18next';
import { ButtonWithProgress } from '@ui-components';

type Base = {
  competitionId: number;
  stageId: number | undefined;
  match: MatchModel;
};

type MatchSetAdd = {
  kind: 'matchSetAdd';
  matchSets: Array<MatchSet>;
  goldenSet: boolean;
  group: Group;
} & Base;

type MatchSetEdit = {
  kind: 'matchSetEdit';
  matchSet: MatchSet;
  group: Group;
} & Base;

type MatchTimeEdit = { kind: 'matchTime' } & Base;

type MatchOtherEdit = { kind: 'other' } & Base;

type Renderer<T extends OwnProps> = (
  modalParams: T,
  formRef: React.MutableRefObject<FormikProps<any>>,
  actions: {
    matches: typeof matchesActions;
    matchSets: typeof matchSetsActions;
  },
  onSuccessEffectParameters: OnSuccessEffectParameters,
  t: TFunction,
) => {
  maxWidth: Breakpoint;
  title: React.ReactNode;
  form: React.ReactNode;
};

const addMatchSetRenderer: Renderer<MatchSetAdd> = (
  modalParams,
  formRef,
  actions,
  onSuccessEffectParameters,
  t,
) => {
  const { match, matchSets, group, goldenSet } = modalParams;

  const nextAvailableMatchSetNumber =
    !goldenSet && getNextAvailableMatchSetNumber(matchSets);
  const maxWidth = 'sm';

  const title = goldenSet
    ? t('Add golden set')
    : `${t('Add set #')} ${nextAvailableMatchSetNumber}`;

  const formFieldNames = getMatchSetFormFieldNames();

  const submitForm = (values: FormikProps<any>['values']) => {
    if (values[formFieldNames.durationFieldName] === '') {
      values[formFieldNames.durationFieldName] = null;
    }
    const onSuccess = () => {
      actions.matchSets.getMatchSets({ matchId: modalParams.match.id });
    };

    actions.matchSets.createMatchSet({
      matchId: match.id,
      matchSet: values,
      onSuccess,
      onSuccessEffectParameters,
    });
  };

  const initialValuesWithoutNumber = Object.fromEntries(
    Object.entries(formFieldNames).map(([, value]) => [value, null]),
  );
  const initialValuesWithNumber = {
    ...initialValuesWithoutNumber,
    number: nextAvailableMatchSetNumber,
  };
  const initialValues = goldenSet
    ? { ...initialValuesWithoutNumber, goldenSet: true }
    : initialValuesWithNumber;

  const form = (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={submitForm}
      validate={getValidate(t, group, match)}
      validationSchema={getSingleMatchSetValidationSchema(t)}
    >
      <MatchDataFormFieldsMatchSets
        formFieldNames={formFieldNames}
        match={match}
      />
    </Formik>
  );

  return { maxWidth, title, form };
};

const editMatchSetRenderer: Renderer<MatchSetEdit> = (
  modalParams,
  formRef,
  actions,
  onSuccessEffectParameters,
  t,
) => {
  const { match, matchSet, group } = modalParams;

  const maxWidth = 'sm';

  const title = matchSet.goldenSet
    ? t('Edit golden set')
    : `${t('Edit set #')} ${matchSet.number}`;

  const formFieldNames = getMatchSetFormFieldNames();

  const submitForm = (values: FormikProps<any>['values']) => {
    if (
      values[formFieldNames.durationFieldName] === '' ||
      values[formFieldNames.durationFieldName] === null
    ) {
      values[formFieldNames.durationFieldName] = null;
    } else {
      values[formFieldNames.durationFieldName] = Number(
        values[formFieldNames.durationFieldName],
      );
    }

    const inputValues = {
      [formFieldNames.awayScoreFieldName]: Number(
        values[formFieldNames.awayScoreFieldName],
      ),
      [formFieldNames.homeScoreFieldName]: Number(
        values[formFieldNames.homeScoreFieldName],
      ),
    };

    const onSuccess = () => {
      actions.matchSets.getMatchSets({ matchId: modalParams.match.id });
    };

    actions.matchSets.updateSet({
      setId: matchSet.id,
      set: { ...values, ...inputValues },
      onSuccess,
      onSuccessEffectParameters,
    });
  };

  const form = (
    <Formik
      innerRef={formRef}
      initialValues={matchSet}
      onSubmit={submitForm}
      validate={getValidate(t, group, match)}
      validationSchema={getSingleMatchSetValidationSchema(t)}
    >
      <MatchDataFormFieldsMatchSets
        formFieldNames={getMatchSetFormFieldNames()}
        match={match}
      />
    </Formik>
  );

  return { maxWidth, title, submitForm, form };
};

const editMatchTimeRenderer: Renderer<MatchTimeEdit> = (
  modalParams,
  formRef,
  actions,
  onSuccessEffectParameters,
  t,
) => {
  const { match } = modalParams;

  const maxWidth = 'xs';

  const title = `${t('Edit match time')}`;

  const submitForm = (values: FormikProps<any>['values']) => {
    actions.matches.changeMatchProperties({
      match,
      data: values,
      onSuccessEffectParameters,
    });
  };

  const form = (
    <Formik
      innerRef={formRef}
      initialValues={match}
      onSubmit={submitForm}
      validationSchema={getMatchDetailsValidationSchema(t)}
    >
      <MatchDataFormFieldsMatchTime />
    </Formik>
  );

  return { maxWidth, title, form };
};

const editOtherMatchDetailsRenderer: Renderer<MatchOtherEdit> = (
  modalParams,
  formRef,
  actions,
  onSuccessEffectParameters,
  t,
) => {
  const { match } = modalParams;

  const maxWidth = 'xs';

  const title = `${t('Edit other details')}`;

  const submitForm = (values: FormikProps<any>['values']) => {
    actions.matches.changeMatchProperties({
      match,
      data: values,
      onSuccessEffectParameters,
    });
  };

  const form = (
    <Formik
      innerRef={formRef}
      initialValues={match}
      onSubmit={submitForm}
      validationSchema={getMatchDetailsValidationSchema(t)}
    >
      <MatchDataFormFieldsMatchAttendance />
    </Formik>
  );

  return { maxWidth, title, form };
};

interface DispatchProps {
  actions: {
    matchSets: typeof matchSetsActions;
    matches: typeof matchesActions;
  };
}

export type OwnProps =
  | MatchSetAdd
  | MatchSetEdit
  | MatchTimeEdit
  | MatchOtherEdit;

type Props = GlobalModalProps<OwnProps> & DispatchProps;

const MatchDataEditModal: React.FC<Props> = (props) => {
  const { actions, isModalOpen, modalActions, modalParams } = props;

  const modalClasses = useModalStyles();
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<any>>();

  const handleClose = useCallback(
    () => modalActions.closeModal(),
    [modalActions],
  );

  const handleSubmit = () => formRef.current?.submitForm();

  const { maxWidth, title, form } = useMemo(() => {
    const { competitionId, stageId, match } = modalParams;
    const onSuccessEffectParameters: OnSuccessEffectParameters = {
      competitionId,
      stageId,
      matchId: match.id,
      modal: true,
      tabId: null,
    };

    switch (modalParams.kind) {
      case 'matchSetAdd': {
        return addMatchSetRenderer(
          modalParams,
          formRef,
          actions,
          onSuccessEffectParameters,
          t,
        );
      }
      case 'matchSetEdit': {
        return editMatchSetRenderer(
          modalParams,
          formRef,
          actions,
          onSuccessEffectParameters,
          t,
        );
      }
      case 'matchTime': {
        return editMatchTimeRenderer(
          modalParams,
          formRef,
          actions,
          onSuccessEffectParameters,
          t,
        );
      }
      case 'other': {
        return editOtherMatchDetailsRenderer(
          modalParams,
          formRef,
          actions,
          onSuccessEffectParameters,
          t,
        );
      }
    }
  }, [actions, modalParams, handleClose, t]);

  return (
    <Dialog open={isModalOpen} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={modalClasses.content}>{form}</DialogContent>
      <Divider />
      <DialogActions className={modalClasses.actions}>
        <Grid container spacing={1} justifyContent="flex-end">
          <GridItems>
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <ButtonWithProgress variant="contained" onClick={handleSubmit}>
              {t('Save')}
            </ButtonWithProgress>
          </GridItems>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSets: bindActionCreators(matchSetsActions, dispatch),
    matches: bindActionCreators(matchesActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MatchDataEditModal);
