import type {
  Action,
  ThunkAction,
  Reducer,
  PreloadedStateShapeFromReducersMapObject,
} from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './root-reducer';
import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext } from 'redux-first-history';
import rootSaga, { createSagaInjector, InjectSaga } from './root-saga';
import * as Sentry from '@sentry/react';
import * as authActions from '@core/pages/login/store/actions';
import { createBrowserHistory } from 'history';
import { api } from '@core/services';
import type { State as LibState } from '@core/store';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    if (import.meta.env.MODE === 'development') {
      console.error(error);
    }

    Sentry.captureException(error);
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === authActions.register.toString()) {
      return {
        ...action,
        payload: {
          ...action.payload,
          password: null,
        },
      };
    }

    return action;
  },
});

export const makeStore = (
  preloadedState?: PreloadedStateShapeFromReducersMapObject<
    ReturnType<typeof rootReducer>
  >,
) => {
  const store = configureStore({
    preloadedState,
    reducer: rootReducer(routerReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(api.middleware)
        .concat(sagaMiddleware)
        .concat(routerMiddleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);

  type InjectReducer = (path: string, payload: Reducer) => Promise<unknown>;

  const castedInstance = store as typeof store & {
    injectedReducers: Record<string, unknown>;
    injectReducer: InjectReducer;
    injectSaga: InjectSaga;
  };

  castedInstance.injectedReducers = {};

  castedInstance.injectReducer = (key, reducer) =>
    new Promise((resolve) => {
      castedInstance.injectedReducers[key] = reducer;
      castedInstance.replaceReducer(
        rootReducer(routerReducer, castedInstance.injectedReducers),
      );

      resolve(castedInstance);
    });

  castedInstance.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

  globalThis.store = store;

  return castedInstance;
};

export const store = makeStore();
// @ts-ignore
export const history = createReduxHistory(store);

// Infer the type of `store
export type State = ReturnType<typeof store.getState> & LibState;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  State,
  unknown,
  Action
>;
