import {
  all,
  call,
  cancelled,
  debounce,
  Effect,
  put,
  race,
  SagaReturnType,
  take,
} from 'redux-saga/effects';
import { omit } from 'ramda';

import { handleApiErrors } from '@core/helpers';
import * as apiMatchOfficials from '@core/api/match-officials';

import { actions as matchOfficialsActions } from '.';

function* getMatchOfficialGroupFlow(action: any) {
  yield put(matchOfficialsActions.getMatchOfficialGroupsRequest());
  const { payload } = action;

  const { response, error } = yield call(
    apiMatchOfficials.getMatchOfficialGroup,
    payload.officialId,
  );

  if (!error) {
    yield put(
      matchOfficialsActions.setMatchOfficialGroups(response._embedded.items),
    );
    yield put(matchOfficialsActions.getMatchOfficialGroupsSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(matchOfficialsActions.getMatchOfficialGroupsFailure());
  }
}

export function* getMatchOfficials(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(matchOfficialsActions.getMatchOfficialsRequest());
  const response = (yield call(
    apiMatchOfficials.getMatchOfficials,
    queryParams,
  )) as InferApi<typeof apiMatchOfficials.getMatchOfficials>;

  if (!response.error) {
    yield put(matchOfficialsActions.getMatchOfficialsSuccess());
  } else {
    yield put(matchOfficialsActions.getMatchOfficialsFailure());
  }

  return response;
}

export function* getCalendarMatchOfficials(
  queryParams: URLSearchParams,
): Generator<Effect, any, any> {
  yield put(matchOfficialsActions.getCalendarMatchOfficialsRequest());
  const response = yield call(apiMatchOfficials.getMatchOfficials, queryParams);

  if (!response.error) {
    yield put(matchOfficialsActions.getCalendarMatchOfficialsSuccess());
  } else {
    yield put(matchOfficialsActions.getCalendarMatchOfficialsFailure());
  }

  return response;
}

export function* validateMatchOfficial(
  id: number,
  dateFilter: URLSearchParams,
): Generator<Effect, any, any> {
  try {
    yield put(matchOfficialsActions.validateMatchOfficialRequest());
    const response = (yield call(
      apiMatchOfficials.getMatchOfficialConflicts,
      id,
      dateFilter,
    )) as InferApi<typeof apiMatchOfficials.getMatchOfficialConflicts>;

    if (!response.error) {
      yield put(matchOfficialsActions.validateMatchOfficialSuccess());
    } else {
      yield put(matchOfficialsActions.validateMatchOfficialFailure());
    }

    return response;
  } finally {
    if ((yield cancelled()) as boolean) {
      yield put(matchOfficialsActions.validateMatchOfficialSuccess());
    }
  }
}

function* validateMatchOfficialFlow() {
  while (true) {
    const action = (yield take(
      matchOfficialsActions.validateMatchOfficial,
    )) as InferAction<typeof matchOfficialsActions.validateMatchOfficial>;
    yield race([
      call(function* ({ payload }: typeof action) {
        const { response, error } = (yield call(
          validateMatchOfficial,
          payload.id,
          payload.dateFilter,
        )) as SagaReturnType<typeof validateMatchOfficial>;

        if (!error) {
          yield put(
            matchOfficialsActions.setMatchOfficialConflicts(
              response._embedded.matches,
            ),
          );
        } else {
          yield call(handleApiErrors, error);
        }
      }, action),
      take(matchOfficialsActions.resetMatchOfficialConflicts),
    ]);
  }
}

function* getMatchOfficialsFlow(
  action: InferAction<typeof matchOfficialsActions.getMatchOfficials>,
): Generator<Effect, any, any> {
  const { payload } = action;

  const { response, error } = (yield call(
    getMatchOfficials,
    payload.queryParams,
  )) as SagaReturnType<typeof getMatchOfficials>;

  if (!error) {
    yield put(
      matchOfficialsActions.setMatchOfficials(
        response._embedded.matchOfficials,
      ),
    );
    yield put(
      matchOfficialsActions.setMatchOfficialsMeta(
        omit(['_embedded', '_links'], response),
      ),
    );
  } else {
    yield call(handleApiErrors, error);
  }
}

function* getCalendarMatchOfficialsFlow(action: any) {
  const { payload = '' } = action;

  const { response, error } = yield call(
    getCalendarMatchOfficials,
    payload.query,
  );

  if (!error) {
    yield put(matchOfficialsActions.setCalendarMatchOfficials(response));
  } else {
    yield call(handleApiErrors, error);
  }
}

export function* watchGetCalendarMatchOfficialsFlow() {
  yield debounce(
    500,
    matchOfficialsActions.getCalendarMatchOfficials,
    getCalendarMatchOfficialsFlow,
  );
}

export function* watchGetMatchOfficialsFlow() {
  yield debounce(
    500,
    matchOfficialsActions.getMatchOfficials,
    getMatchOfficialsFlow,
  );
}

export function* watchGetMatchOfficialGroupFlow() {
  yield debounce(
    500,
    matchOfficialsActions.getMatchOfficialGroups,
    getMatchOfficialGroupFlow,
  );
}

export default function* saga() {
  yield all([
    watchGetMatchOfficialsFlow(),
    validateMatchOfficialFlow(),
    watchGetMatchOfficialGroupFlow(),
    watchGetCalendarMatchOfficialsFlow(),
  ]);
}
