import { createAction } from 'redux-actions';

import * as COMPETITION_ORGANISERS_ACTION_TYPES from './constants';

export const setCompetitionOrganisers = createAction(
  COMPETITION_ORGANISERS_ACTION_TYPES.SET_COMPETITION_ORGANISERS,
);

export const getCompetitionOrganisers = createAction(
  COMPETITION_ORGANISERS_ACTION_TYPES.GET_COMPETITION_ORGANISERS,
);
export const getCompetitionOrganisersRequest = createAction(
  COMPETITION_ORGANISERS_ACTION_TYPES.GET_COMPETITION_ORGANISERS_REQUEST,
);
export const getCompetitionOrganisersSuccess = createAction(
  COMPETITION_ORGANISERS_ACTION_TYPES.GET_COMPETITION_ORGANISERS_SUCCESS,
);
export const getCompetitionOrganisersFailure = createAction(
  COMPETITION_ORGANISERS_ACTION_TYPES.GET_COMPETITION_ORGANISERS_FAILURE,
);
