import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Badge, TableSortLabel, useTheme } from '@mui/material';
import {
  ButtonWithDropdown,
  ButtonWithDropdownProps,
  ColumnWithScore,
  ColumnWithSubValue,
  CustomTable,
  getPaginationValuesFactory,
  Status,
} from '@core/components';
import { CUSTOM_TABLE_ACTIONS } from '@core/components/custom-table/tests/test-ids';
import { Club, Competition } from '@core/icons';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import {
  formatDate,
  formatTime,
  getCompetitorLogoUrl,
  getMatchStatus,
  getStatusColor,
  getTableSortLabelProps,
  getVenue,
  getWeekdayShort,
} from '@core/helpers';
import { useCoreDispatch, useConfirmationDialog } from '@core/hooks';
import { Column } from '@core/components/material-table';
import {
  EliminationType,
  Filters,
  FilterTabsList,
  Group,
  MatchesBulkActions,
  MatchLinks,
  MatchModel,
  Paginated,
  Sorting,
  StageType,
} from '@core/types';
import type { State } from '@core/store';
import { actions as matchesActions } from '@core/store/modules/matches';
import ActionsButtons from './components/actions-buttons';
import { getCompetitorTitleFactory } from './helpers';
import {
  DATE_FIELD_HEADER,
  STAGE_PROFILE_MATCHES_BULK_ACTIONS_BUTTON,
} from './tests/test-ids';
import GoldenMatchLabel from '../golden-match-label';

interface ActionsData {
  data: Array<MatchModel>;
}

interface Props {
  competitionId?: number;
  handleSearch?: (params: { query: string; page: number }) => void;
  handleSorting?: (newSorting: Sorting) => void;
  initialFilters?: Filters;
  isGlobal?: boolean;
  isLoading?: boolean;
  matches: Paginated<MatchModel['id']>;
  matchesList?: Array<MatchModel>;
  onFilterSave?: (filters: Filters) => void;
  onReload?: (pagination: { page: number }) => void;
  query?: string;
  sorting?: Sorting;
  stage?: Group;
  tableFilter?: {
    tabsList: FilterTabsList;
    onSave: (filters: Filters) => void;
    initialFilters: Filters;
  };
}

const isBulkActionInProgressSelector = ({
  isGlobal,
  competitionId,
  stage,
}: Pick<Props, 'isGlobal' | 'competitionId' | 'stage'>): ((
  state: State,
) => boolean) =>
  !isGlobal
    ? createLoadingSelector([
        matchesActions.changeMatchStatusInBulkRequest({
          competitionId,
          stageId: stage.id,
        }),
        matchesActions.bulkDeleteRequest({ competitionId, stageId: stage.id }),
      ])
    : () => false;

const MatchesTable = ({
  competitionId,
  isGlobal,
  isLoading,
  matches,
  query,
  stage,
  tableFilter,
  sorting,
  handleSorting,
  handleSearch,
  onReload,
  matchesList = [],
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useCoreDispatch();
  const theme = useTheme();
  const tableRef = useRef();
  const { getConfirmation } = useConfirmationDialog();

  const currentCustomer = useSelector(getCurrentCustomer);
  const isBulkActionInProgress = useSelector(
    isBulkActionInProgressSelector({ isGlobal, competitionId, stage }),
  );

  const canDelete = matchesList.some((match) => match._links?.delete);
  const canComplete = matchesList.some(
    (match) => match._links?.changeStatus?.COMPLETE,
  );
  const canSchedule = matchesList.some(
    (match) => match._links?.changeStatus?.SCHEDULED,
  );
  const canPostpone = matchesList.some(
    (match) => match._links?.changeStatus?.POSTPONED,
  );
  const canCancel = matchesList.some(
    (match) => match._links?.changeStatus?.CANCELLED,
  );
  const canAbandon = matchesList.some(
    (match) => match._links?.changeStatus?.ABANDONED,
  );

  const stageId = stage?.id;
  const isLoadingOrBulkActionInProgress = isLoading || isBulkActionInProgress;
  const shouldShowSelectAndToolbar =
    !isGlobal &&
    (canDelete ||
      canSchedule ||
      canPostpone ||
      canCancel ||
      canComplete ||
      canAbandon);

  const getCompetitorTitle = getCompetitorTitleFactory(t);

  const getMatchDayColumnName = (stageType?: keyof typeof StageType) => {
    switch (stageType) {
      case StageType.ROUND_ROBIN:
        return t('MD');
      case StageType.KNOCK_OUT:
        return t('Leg');
      default:
        return `${t('MD')}/${t('Leg')}`;
    }
  };

  const getRoundColumnName = (stageType?: keyof typeof StageType) => {
    switch (stageType) {
      case StageType.ROUND_ROBIN:
        return t('Pool');
      case StageType.KNOCK_OUT:
        return t('Round');
      default:
        return `${t('Pool')}/${t('Round')}`;
    }
  };

  const getTableSortProps = getTableSortLabelProps(sorting, handleSorting);

  const [bulkActionMatchIds, setBulkActionMatchIds] = useState<Array<number>>(
    [],
  );

  useEffect(() => {
    if (matchesList) {
      // sync bulk action match ids with matches list in case some match has been deleted
      setBulkActionMatchIds((current) =>
        current.length
          ? current.filter((id) => matchesList.find((match) => match.id === id))
          : current,
      );
    }
  }, [matchesList]);

  const columns: Array<Column<MatchModel>> = [
    {
      title: (
        <TableSortLabel {...getTableSortProps('number')}>
          {t('No.')}
        </TableSortLabel>
      ),
      field: 'number',
      width: 30,
    },
    {
      title: (
        <TableSortLabel {...getTableSortProps('matchDayNumber')}>
          {getMatchDayColumnName(stage?.stageType)}
        </TableSortLabel>
      ),
      field: 'matchDayNumber',
      width: 30,
    },
    isGlobal ||
    stage?.stageType === StageType.KNOCK_OUT ||
    (stage?.stageType === StageType.ROUND_ROBIN &&
      stage?.childGroups.length > 0)
      ? {
          title: (
            <TableSortLabel {...getTableSortProps('group')}>
              {getRoundColumnName(stage?.stageType)}
            </TableSortLabel>
          ),
          field: 'groupTitle',
        }
      : null,
    stage?.stageType === StageType.KNOCK_OUT &&
    stage?.eliminationType === EliminationType.SINGLE
      ? {
          title: (
            <TableSortLabel {...getTableSortProps('bracket')}>
              {t('Series No.')}
            </TableSortLabel>
          ),
          field: 'bracketNumber',
        }
      : null,
    {
      title: (
        <TableSortLabel
          {...getTableSortProps('date')}
          data-qa={DATE_FIELD_HEADER}
        >
          {t('Date, time')}
        </TableSortLabel>
      ),
      field: 'date',
      headerStyle: {
        textAlign: 'right',
      },
      render: ({ date, time }) => {
        const dateTime = `${date} ${time}`;

        return date && time ? (
          <ColumnWithSubValue
            alignment="right"
            value={formatDate(currentCustomer?.dateFormat, dateTime)}
            subValue={`${getWeekdayShort(
              t,
              dayjs(dateTime).format('ddd'),
            )} • ${formatTime(currentCustomer.timeFormat, dateTime)}`}
            valueTypographyProps={{ noWrap: true }}
            valueSecondaryProps={{ noWrap: true }}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: t('Home'),
      field: 'homeCompetitorTitle',
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
      render: (rowData) => (
        <ColumnWithSubValue
          value={getCompetitorTitle(rowData)}
          subValue={rowData.homeCompetitorInternationalTitle}
          alignment="right"
          logo={{
            url: getCompetitorLogoUrl(rowData, 'homeCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
    },
    {
      field: 'homeCompetitorScore',
      cellStyle: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      render: ({ homeCompetitorScore, awayCompetitorScore }) => (
        <ColumnWithScore
          scoreHome={homeCompetitorScore}
          scoreAway={awayCompetitorScore}
        />
      ),
    },
    {
      title: t('Away'),
      field: 'awayCompetitorTitle',
      render: (rowData) => (
        <ColumnWithSubValue
          value={getCompetitorTitle(rowData, 'away')}
          subValue={rowData.awayCompetitorInternationalTitle}
          logo={{
            url: getCompetitorLogoUrl(rowData, 'awayCompetitor'),
            defaultIcon: <Club />,
          }}
        />
      ),
    },
    {
      title: (
        <TableSortLabel {...getTableSortProps('venue')}>
          {t('Venue')}
        </TableSortLabel>
      ),
      field: 'venue',
      render: ({ venue }) => (
        <ColumnWithSubValue
          value={getVenue(venue)?.title}
          subValue={getVenue(venue)?.internationalTitle}
        />
      ),
    },
    {
      title: (
        <TableSortLabel {...getTableSortProps('venue')}>
          {t('Sub-venue')}
        </TableSortLabel>
      ),
      field: 'venue',
      render: ({ venue }) => {
        const subVenue = getVenue(venue, { subVenue: true });

        if (!subVenue?.parentVenue) {
          return null;
        }

        return (
          <ColumnWithSubValue
            value={subVenue?.title}
            subValue={subVenue?.internationalTitle}
          />
        );
      },
    },
    {
      title: (
        <TableSortLabel {...getTableSortProps('status')}>
          {t('Status')}
        </TableSortLabel>
      ),
      field: 'status',
      render: ({ status, goldenMatch }) => (
        <Badge badgeContent={<GoldenMatchLabel />} invisible={!goldenMatch}>
          <Status
            title={getMatchStatus(t, status)}
            statusColor={getStatusColor(status)}
          />
        </Badge>
      ),
    },
    {
      field: 'actions',
      render: (rowData) => (
        <ActionsButtons
          data-qa={CUSTOM_TABLE_ACTIONS}
          homeCompetitorId={rowData.homeCompetitorId}
          awayCompetitorId={rowData.awayCompetitorId}
          competitionId={competitionId}
          stage={stage}
          match={rowData}
        />
      ),
    },
  ];

  if (isGlobal) {
    columns.unshift({
      title: (
        <TableSortLabel {...getTableSortProps('competition')}>
          {t('Competition')}
        </TableSortLabel>
      ),
      field: 'competitionTitle',
      render: (rowData) => (
        <ColumnWithSubValue
          value={rowData.competitionTitle}
          logo={{
            url: rowData.competitionLogoFileLink,
            defaultIcon: <Competition />,
          }}
        />
      ),
    });
  }

  const getPaginationValues = useMemo(
    () =>
      getPaginationValuesFactory(
        (pagination) => {
          setBulkActionMatchIds([]);
          onReload(pagination);
        },
        {
          page: matches?.page,
          pages: matches?.pages,
          limit: matches?.limit,
          total: matches?.total,
        },
      ),
    [
      matches?.limit,
      matches?.page,
      matches?.pages,
      matches?.total,
      onReload,
      setBulkActionMatchIds,
    ],
  );

  const tableSearch = handleSearch && {
    query,
    onSearch: handleSearch,
  };

  const clearSelection = () => {
    if (tableRef.current) {
      // @ts-ignore
      tableRef.current.onAllSelected(false);
    }
  };

  const onSelectionChange = (rows: Array<MatchModel>) => {
    const matchIds = rows.map((match) => match.id);

    setBulkActionMatchIds(matchIds);
  };

  const bulkActionChangeMatchStatus = (status: MatchesBulkActions) =>
    dispatch(
      matchesActions.changeMatchStatusInBulk({
        competitionId,
        stageId,
        matchIds: bulkActionMatchIds,
        status,
        onSuccessEffectParameters: {
          competitionId,
          stageId,
          matchIds: bulkActionMatchIds,
          modal: false,
          tabId: null,
        },
        onSuccessCallback: clearSelection,
      }),
    );

  const bulkActionDeleteMatches = async () => {
    const confirmed = await getConfirmation({
      title: t('Confirm Deletion'),
      message: t('Are you sure you want to delete the selected matches?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      dispatch(
        matchesActions.bulkDelete({
          competitionId,
          stageId,
          matchIds: bulkActionMatchIds,
          onSuccessEffectParameters: {
            competitionId,
            stageId,
            matchIds: bulkActionMatchIds,
            modal: false,
            tabId: null,
          },
          onSuccessCallback: clearSelection,
        }),
      );
    }
  };

  const renderTableActions = (selectedMatches: Array<MatchModel>) => {
    const hasHalLink = (status: Omit<MatchesBulkActions, 'DELETE'>) =>
      Array.isArray(selectedMatches)
        ? selectedMatches.every(
            (match) =>
              match._links?.changeStatus?.[
                status as keyof MatchLinks['changeStatus']
              ],
          )
        : false;
    const canDeleteSelected = Array.isArray(selectedMatches)
      ? selectedMatches.every((match) => match._links?.delete)
      : false;
    const canScheduleSelected = hasHalLink(MatchesBulkActions.SCHEDULE);
    const canPostponeSelected = hasHalLink(MatchesBulkActions.POSTPONE);
    const canCancelSelected = hasHalLink(MatchesBulkActions.CANCEL);
    const canAbandonSelected = hasHalLink(MatchesBulkActions.ABANDON);
    const canCompleteSelected = hasHalLink(MatchesBulkActions.COMPLETE);

    const options: ButtonWithDropdownProps<MatchesBulkActions>['options'] = [
      {
        text: t('Complete matches'),
        value: MatchesBulkActions.COMPLETE,
        disabled: !canCompleteSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
      {
        text: t('Schedule matches'),
        value: MatchesBulkActions.SCHEDULE,
        disabled: !canScheduleSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
      {
        text: t('Cancel matches'),
        value: MatchesBulkActions.CANCEL,
        disabled: !canCancelSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
      {
        text: t('Postpone matches'),
        value: MatchesBulkActions.POSTPONE,
        disabled: !canPostponeSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
      {
        text: t('Abandon matches'),
        value: MatchesBulkActions.ABANDON,
        disabled: !canAbandonSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
      {
        text: t('Delete matches'),
        value: MatchesBulkActions.DELETE,
        disabled: !canDeleteSelected,
        tooltipTextDisabled: t(
          'Some of the selected matches have status that prevents this action. Please remove them from the selection and try again',
        ),
      },
    ];

    const onSelect: ButtonWithDropdownProps<MatchesBulkActions>['onSelect'] = (
      action,
    ) => {
      if (action === MatchesBulkActions.DELETE) {
        bulkActionDeleteMatches();
      } else {
        bulkActionChangeMatchStatus(action);
      }
    };

    return (
      <ButtonWithDropdown
        data-qa={STAGE_PROFILE_MATCHES_BULK_ACTIONS_BUTTON}
        onSelect={onSelect}
        options={options}
        variant="text"
        sx={{ color: theme.palette.common.white }}
      >
        {t('Actions')}
      </ButtonWithDropdown>
    );
  };

  return (
    <CustomTable
      noPaper
      tableRef={tableRef}
      tableFilter={
        tableFilter
          ? {
              ...tableFilter,
              onSave: (filters) => {
                setBulkActionMatchIds([]);
                tableFilter.onSave(filters);
              },
            }
          : null
      }
      columns={columns}
      data={matchesList}
      options={{
        toolbar: shouldShowSelectAndToolbar,
        selection: shouldShowSelectAndToolbar,
      }}
      components={{
        Action: ({ data: selectedMatches }: ActionsData) =>
          renderTableActions(selectedMatches),
      }}
      actions={
        shouldShowSelectAndToolbar
          ? [
              {
                icon: null,
                onClick: () => ({}),
              },
            ]
          : []
      }
      onSelectionChange={onSelectionChange}
      pagination={getPaginationValues()}
      tableSearch={tableSearch}
      isLoading={isLoadingOrBulkActionInProgress}
    />
  );
};

export default MatchesTable;
