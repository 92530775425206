import { createActions } from 'redux-actions';

export const NAMESPACE = 'kitSet';

export const actions = createActions(
  'CREATE_KIT_SETS_REQUEST',
  'CREATE_KIT_SETS_SUCCESS',
  'CREATE_KIT_SETS_FAILURE',

  'GET_KIT_SET_LIST_REQUEST',
  'GET_KIT_SET_LIST_SUCCESS',
  'GET_KIT_SET_LIST_FAILURE',

  'GET_KIT_SET_REQUEST',
  'GET_KIT_SET_SUCCESS',
  'GET_KIT_SET_FAILURE',

  'UPDATE_KIT_SET_REQUEST',
  'UPDATE_KIT_SET_SUCCESS',
  'UPDATE_KIT_SET_FAILURE',

  'DELETE_KIT_SET_REQUEST',
  'DELETE_KIT_SET_SUCCESS',
  'DELETE_KIT_SET_FAILURE',

  { prefix: NAMESPACE },
);
