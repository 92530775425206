import { handleActions } from 'redux-actions';
import { assocPath, dissoc, __ } from 'ramda';

import { VenueModel, Paginated, ApiErrors, Competitor } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  [competitorId: number]: {
    competitor: Competitor;
    errors?: ApiErrors;
    defaultVenuesList?: Paginated<VenueModel>;
    alternativeVenuesList?: Paginated<VenueModel>;
  };
}

interface Payload {
  data?: any;
  competitorId: number;
}

export const defaultState = {};

function appendToVenueList(
  state: DefaultState,
  payload: Payload,
  venueType: 'defaultVenuesList' | 'alternativeVenuesList',
) {
  const competitorId = payload.competitorId;
  const currentList = state?.[competitorId]?.[venueType]?.list;
  const addToList = assocPath([competitorId, venueType], __, state);

  if (!currentList) {
    return addToList(payload.data);
  }

  return addToList({
    ...payload.data,
    list: [...currentList, ...payload.data.list],
  });
}

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCompetitor.toString()]: (state, { payload }) =>
      assocPath([payload.competitorId, 'competitor'], payload.data, state),

    [actions.resetCompetitor.toString()]: (state, { payload }) =>
      dissoc(payload.competitorId, state),

    [actions.setSubmitErrors.toString()]: (state, { payload }) =>
      assocPath([payload.competitorId, 'errors'], payload.data, state),

    [actions.setDefaultVenuesList.toString()]: (state, { payload }) =>
      appendToVenueList(state, payload, 'defaultVenuesList'),

    [actions.resetSearchDefaultVenues.toString()]: (state, { payload }) =>
      assocPath([payload.competitorId, 'defaultVenuesList'], {}, state),

    [actions.setAlternativeVenuesList.toString()]: (state, { payload }) =>
      appendToVenueList(state, payload, 'alternativeVenuesList'),

    [actions.resetSearchAlternativeVenues.toString()]: (state, { payload }) =>
      assocPath([payload.competitorId, 'alternativeVenuesList'], {}, state),
  },
  defaultState,
);

export default reducer;
