import React from 'react';
import { Alert, Box, Button, Divider, Grid } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { uuid } from '@core/helpers';
import { GridItems, NoDataAlert } from '@core/components';
import { createURL } from '@core/helpers';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { Group, MatchModel } from '@core/types';
import { MatchSet } from '@volleyball/types';
import paths from '@volleyball/routes/paths';
import MatchDataViewMatchAttendanceTable from './match-data-view-match-attendance-table';
import MatchDataViewMatchSetsTable from './match-data-view-match-sets-table';
import MatchDataViewMatchTimeTable from './match-data-view-match-time-table';
import { getRegularMatchSets, getGoldenMatchSets } from './helpers';
import { CenteredContentBlock } from './shared/centered-content-block';
import * as testIds from './tests/test-ids';
import type { MatchDataCreateProps } from './shared/types';

interface DispatchProps {
  actions: {
    tabsActions: typeof tabsActions;
  };
}

interface OwnProps {
  competitionId: number;
  group: Group;
  match: MatchModel;
  matchSets: Array<MatchSet>;
  stageId: number | undefined;
}

export type Props = DispatchProps & OwnProps;

export const MatchData: React.FC<Props> = (props) => {
  const { actions, competitionId, match, matchSets, group, stageId } = props;
  const { t } = useTranslation();

  const isEditDisabled =
    !match._links.updateMatchSets || !match._links.createMatchSet;

  const handleCreate = () => {
    actions.tabsActions.openTab({
      isClosable: true,
      name: createURL(paths.matchDataCreate),
      props: {
        competitionId,
        groupId: match.groupId,
        matchId: match.id,
        stageId,
      } as TabbedProps<MatchDataCreateProps>,
      id: uuid(),
    });
  };

  const regularMatchSets = getRegularMatchSets(matchSets);
  const goldenMatchSets = getGoldenMatchSets(matchSets);

  return (
    <Box pt={3} pb={3}>
      {matchSets.length === 0 ? (
        <Grid container spacing={3}>
          <GridItems xs={12}>
            <NoDataAlert
              text={t('No match data to display yet. Enter match data.')}
            />
            {isEditDisabled && (
              <Box padding={0} display="flex" justifyContent="center">
                <Alert severity="warning">
                  {t('Change match status to enter data.')}
                </Alert>
              </Box>
            )}
            <Divider />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                data-qa={testIds.ENTER_MATCH_DATA_BUTTON}
                disabled={isEditDisabled}
                onClick={handleCreate}
                startIcon={<EditOutlined />}
                variant="contained"
              >
                {t('Enter match data')}
              </Button>
            </Grid>
          </GridItems>
        </Grid>
      ) : (
        <>
          <CenteredContentBlock title={t('Results')} mb={3} pb={3} spacing={3}>
            <MatchDataViewMatchSetsTable
              competitionId={competitionId}
              isEditable={!isEditDisabled}
              isGoldenSetTable={false}
              match={match}
              matchSets={regularMatchSets}
              group={group}
              stageId={stageId}
            />
            {group.settings.goldenSetRule && (
              <MatchDataViewMatchSetsTable
                competitionId={competitionId}
                isEditable={!isEditDisabled}
                isGoldenSetTable={true}
                match={match}
                matchSets={goldenMatchSets}
                group={group}
                stageId={stageId}
              />
            )}
          </CenteredContentBlock>
          <CenteredContentBlock title={t('Details')} mb={3} pb={3} spacing={3}>
            <MatchDataViewMatchTimeTable
              competitionId={competitionId}
              match={match}
              stageId={stageId}
            />
            <MatchDataViewMatchAttendanceTable
              competitionId={competitionId}
              match={match}
              stageId={stageId}
            />
          </CenteredContentBlock>
        </>
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabsActions: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MatchData);
