import {
  all,
  put,
  take,
  takeEvery,
  call,
  Effect,
  SagaReturnType,
} from 'redux-saga/effects';

import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import {
  getMatchCommissionerNoteDescriptions,
  getMatchCommissionerNoteTitles,
  postMatchCommissionerNotesDescription,
} from '@core/api/match-commissioner-notes';
import * as getMatchCompetitorsAPI from '@core/api/match-competitors';
import {
  getMatchNotesDescriptions,
  getMatchNotesTitles,
  postMatchNotesDescription,
} from '@core/api/match-notes';
import {
  getMatchDurationList,
  postRewriteMatchDurationList,
} from '@core/api/match-duration-list';
import * as getMatchScoresAPI from '@core/api/match-scores';
import { MatchPlayerOrigin } from '@core/types';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { getKitSet } from '@core/store/modules/api/kit-set/sagas';
import { getMatch } from '@core/store/modules/api/match/sagas';
import { getGroup } from '@core/store/modules/api/groups/sagas';
import { getCompetitorByTeamId } from '@core/store/modules/api/competitors/sagas';
import { getMatchMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import { actions as matchActions } from '@core/store/modules/matches';
import * as matchSagas from '@core/store/modules/matches/sagas';
import { getMatchPlayers } from '@core/store/modules/api/match-player/sagas';
import { getMatchTeamOfficials } from '@core/store/modules/api/match-team-officials/sagas';
import * as matchSetSagas from '@core/store/modules/match-set/sagas';
import { actions as matchSetActions } from '@core/store/modules/match-set';
import { api } from '@core/services';
import { actions } from '.';

export function* getDurationListFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatchDurationList);

    yield put(actions.getMatchDurationListRequest());
    const { error, response } = yield call(
      getMatchDurationList,
      payload.matchId,
    );

    if (!error) {
      yield put(actions.getMatchDurationListSuccess());
      yield put(
        actions.setMatchDurationList({
          payload: response,
          matchId: payload.matchId,
        }),
      );
    } else {
      yield put(actions.getMatchDurationListFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* updateDurationListFlow() {
  while (true) {
    const { payload } = yield take(actions.updateMatchDurationList);

    yield put(actions.updateMatchDurationListRequest());
    const { error, response } = yield call(
      postRewriteMatchDurationList,
      payload.matchId,
      payload.data,
    );

    if (!error) {
      yield put(actions.updateMatchDurationListSuccess());
      yield put(
        actions.setMatchDurationList({
          payload: response,
          matchId: payload.matchId,
        }),
      );
      yield put(tabsActions.removeTab({ tabId: payload.tabId }));
    } else {
      yield put(actions.updateMatchDurationListFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getMatchNotesDescriptionsFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatchNotesDescriptions);

    yield put(actions.getMatchNotesDescriptionsRequest());
    const { error, response } = yield call(
      getMatchNotesDescriptions,
      payload.matchId,
    );

    if (!error) {
      yield put(actions.getMatchNotesDescriptionsSuccess());
      yield put(
        actions.setMatchNotesDescriptions({
          payload: response,
          matchId: payload.matchId,
        }),
      );
    } else {
      yield put(actions.getMatchNotesDescriptionsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getMatchNotesTitlesFlow() {
  while (true) {
    const {
      payload: { matchId },
    } = yield take(actions.getMatchNotesTitles);

    yield put(actions.getMatchNotesTitlesRequest());
    const { error, response } = yield call(getMatchNotesTitles, matchId);

    if (!error) {
      yield put(actions.getMatchNotesTitlesSuccess());
      yield put(
        actions.setMatchNotesTitles({
          payload: response._embedded.matchNotesTitles,
          matchId,
        }),
      );
    } else {
      yield put(actions.getMatchNotesTitlesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* getPlayers({ payload }: any) {
  const { competitorId, matchId, matchPlayerOrigin } = payload;

  yield put(actions.getMatchPlayersRequest());
  const { error, response } = yield call(
    getMatchPlayers,
    matchId,
    competitorId,
  );

  if (!error) {
    yield put(actions.getMatchPlayersSuccess());
    yield put(
      actions.setMatchPlayers({
        matchPlayerOrigin,
        matchId,
        payload: response._embedded.matchPlayers,
      }),
    );
  } else {
    yield put(actions.getMatchPlayersFailure());
    yield call(handleApiErrors, error);
  }
}

function* getMatchPlayersFlow() {
  yield takeEvery(actions.getMatchPlayers, getPlayers);
}

function* getTeamOfficials({ payload }: any): Generator<Effect, any, any> {
  const { matchId, competitorId, matchPlayerOrigin } = payload;

  yield put(actions.getMatchTeamOfficialsRequest());
  const { error, response } = yield call(
    getMatchTeamOfficials,
    matchId,
    competitorId,
  );
  const isHomeTeamOfficials = matchPlayerOrigin === MatchPlayerOrigin.HOME;

  if (!error) {
    isHomeTeamOfficials
      ? yield put(
          actions.setHomeTeamOfficials({
            matchId,
            payload: response._embedded.matchTeamOfficials,
          }),
        )
      : yield put(
          actions.setAwayTeamOfficials({
            matchId,
            payload: response._embedded.matchTeamOfficials,
          }),
        );
    yield put(actions.getMatchTeamOfficialsSuccess());
  } else {
    yield call(handleApiErrors, error);
    yield put(actions.getMatchTeamOfficialsFailure());
  }
}

function* getTeamOfficialsFlow() {
  yield takeEvery(actions.getMatchTeamOfficials, getTeamOfficials);
}

export function* getKitSetFlow({
  payload,
}: InferAction<typeof actions.getKitSet>) {
  const { kitSetId, matchId, matchPlayerOrigin } = payload;

  yield put(actions.getKitSetRequest({ kitSetId }));

  const { error, response } = (yield call(getKitSet, {
    kitSetId,
  })) as SagaReturnType<typeof getKitSet>;

  if (!error) {
    yield put(actions.getKitSetSuccess({ kitSetId }));
    yield put(
      actions.setKitSet({
        payload: response,
        matchId,
        matchPlayerOrigin,
      }),
    );
  } else {
    yield put(actions.getKitSetFailure({ kitSetId }));
    yield call(handleApiErrors, error);
  }
}

function* getMatchScores({ payload }: any) {
  yield put(actions.getMatchScoresRequest());
  const { error, response } = yield call(
    getMatchScoresAPI.getMatchScores,
    payload,
  );

  if (!error) {
    yield put(actions.getMatchScoresSuccess());
    yield put(
      actions.setMatchScores({
        matchId: payload,
        payload: response,
      }),
    );
  } else {
    yield put(actions.getMatchScoresFailure());
    yield call(handleApiErrors, error);
  }
}

function* getMatchCompetitors({
  payload,
}: InferAction<typeof actions.getMatchCompetitors>) {
  const matchId = payload;

  yield put(actions.getMatchCompetitorsRequest({ matchId }));
  const { error, response } = (yield call(
    getMatchCompetitorsAPI.getMatchCompetitors,
    matchId,
  )) as InferApi<typeof getMatchCompetitorsAPI.getMatchCompetitors>;

  if (!error) {
    yield put(actions.getMatchCompetitorsSuccess({ matchId }));
    yield put(
      actions.setMatchCompetitors({
        matchId,
        payload: response,
      }),
    );
  } else {
    yield put(actions.getMatchCompetitorsFailure({ matchId }));
    yield call(handleApiErrors, error);
  }
}

function* updateMatchCompetitor({
  payload,
}: InferAction<typeof actions.updateMatchCompetitor>) {
  const { matchId, competitorId, teamColorId } = payload;

  yield put(actions.updateMatchCompetitorRequest({ matchId }));
  const { error } = (yield call(
    getMatchCompetitorsAPI.patchMatchCompetitor,
    matchId,
    competitorId,
    {
      teamColorId,
    },
  )) as InferApi<typeof getMatchCompetitorsAPI.patchMatchCompetitor>;

  if (!error) {
    yield put(actions.updateMatchCompetitorSuccess({ matchId }));
    yield put(actions.getMatch({ matchId }));
    yield put(actions.getMatchCompetitors(matchId));
  } else {
    yield put(actions.updateMatchCompetitorFailure({ matchId }));
    yield call(handleApiErrors, error);
  }
}

export function* getMatchCommissionerNotesDescriptionsFlow() {
  while (true) {
    const { payload } = yield take(
      actions.getMatchCommissionerNoteDescriptions,
    );

    yield put(actions.getMatchCommissionerNoteDescriptionsRequest());

    const { error, response } = yield call(
      getMatchCommissionerNoteDescriptions,
      payload.matchId,
    );

    if (!error) {
      yield put(actions.getMatchCommissionerNoteDescriptionsSuccess());
      yield put(
        actions.setMatchCommissionerNoteDescriptions({
          payload: response,
          matchId: payload.matchId,
        }),
      );
    } else {
      yield put(actions.getMatchCommissionerNoteDescriptionsFailure());
    }
  }
}

export function* getMatchCommissionerNotesTitlesFlow() {
  while (true) {
    const {
      payload: { matchId },
    } = yield take(actions.getMatchCommissionerNoteTitles);

    yield put(actions.getMatchCommissionerNoteTitlesRequest());
    const { error, response } = yield call(
      getMatchCommissionerNoteTitles,
      matchId,
    );

    if (!error) {
      yield put(actions.getMatchCommissionerNoteTitlesSuccess());
      yield put(
        actions.setMatchCommissionerNoteTitles({
          payload: response._embedded.matchCommissionerNoteTitle,
          matchId,
        }),
      );
    } else {
      yield put(actions.getMatchCommissionerNoteTitlesFailure());
    }
  }
}

export function* updateMatchCommissionerNotesDescriptionsFlow() {
  while (true) {
    const {
      payload: { matchId, matchCommissionerNoteDescriptions, tabId },
    } = yield take(actions.updateMatchCommissionerNoteDescriptions);

    yield put(actions.updateMatchCommissionerNoteDescriptionsRequest());

    const { error } = yield call(
      postMatchCommissionerNotesDescription,
      matchId,
      matchCommissionerNoteDescriptions,
    );

    if (!error) {
      yield put(actions.updateMatchCommissionerNoteDescriptionsSuccess());
      yield put(actions.getMatchCommissionerNoteDescriptions({ matchId }));
      yield put(tabsActions.removeTab({ tabId }));
    } else {
      yield put(actions.updateMatchCommissionerNoteDescriptionsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* updateMatchNotesDescriptionsFlow() {
  while (true) {
    const {
      payload: { matchId, matchNotesDescriptions, tabId },
    } = yield take(actions.updateMatchNotesDescriptions);

    yield put(actions.updateMatchNotesDescriptionsRequest());
    const { error } = yield call(
      postMatchNotesDescription,
      matchId,
      matchNotesDescriptions,
    );

    if (!error) {
      yield put(actions.updateMatchNotesDescriptionsSuccess());
      yield put(actions.getMatchNotesDescriptions({ matchId }));
      yield put(tabsActions.removeTab({ tabId }));
    } else {
      yield put(actions.updateMatchNotesDescriptionsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* getMatchScoresFlow() {
  yield takeLeadingByPredicate(actions.getMatchScores, getMatchScores);
}

function* getMatchCompetitorsFlow() {
  yield takeLeadingByPredicate(
    actions.getMatchCompetitors,
    getMatchCompetitors,
  );
}

function* updateMatchCompetitorFlow() {
  yield takeLeadingByPredicate(
    actions.updateMatchCompetitor,
    updateMatchCompetitor,
  );
}

function* getCompetitionCompetitorWatcher() {
  yield takeLeadingByPredicate(
    actions.getCompetitionCompetitor,
    function* (action) {
      const { competitionId, teamId, matchId, matchPlayerOrigin } =
        action.payload;

      yield put(actions.getCompetitionCompetitorRequest());
      const { response, error } = (yield call(getCompetitorByTeamId, {
        competitionId,
        teamId,
      })) as SagaReturnType<typeof getCompetitorByTeamId>;

      if (!error) {
        yield put(actions.getCompetitionCompetitorSuccess());
        yield put(
          actions.setCompetitionCompetitor({
            payload: response,
            matchId,
            matchPlayerOrigin,
          }),
        );
      } else {
        yield put(actions.getCompetitionCompetitorFailure());
        yield call(handleApiErrors, error);
      }
    },
  );
}

export function* getKitSetFlowWatcher() {
  yield takeLeadingByPredicate(actions.getKitSet, getKitSetFlow);
}

function* getMatchWorker({
  payload: { matchId },
}: InferAction<typeof actions.getMatch>): Generator<Effect, any, any> {
  yield put(actions.getMatchRequest({ id: matchId }));
  const { error, response } = (yield call(getMatch, matchId)) as SagaReturnType<
    typeof getMatch
  >;

  if (!error) {
    yield put(actions.getMatchSuccess({ id: matchId }));
    yield put(actions.setMatch({ match: response, matchId }));
  } else {
    yield put(actions.getMatchFailure({ id: matchId }));
    yield call(handleApiErrors, error);
  }
}

function* getMatchWatcher() {
  yield takeEvery(actions.getMatch, getMatchWorker);
}

function* getMatchGroupWorker({
  payload,
}: InferAction<typeof actions.getMatchGroup>): Generator<Effect, any, any> {
  const { groupId, matchId } = payload;

  yield put(actions.getMatchGroupRequest({ id: groupId }));
  const { error } = (yield call(getGroup, { groupId })) as SagaReturnType<
    typeof getGroup
  >;

  if (!error) {
    yield put(actions.getMatchGroupSuccess({ id: groupId }));
    yield put(actions.setMatchGroup({ groupId, matchId }));
  } else {
    yield put(actions.getMatchGroupFailure({ id: groupId }));
    yield call(handleApiErrors, error);
  }
}

function* getMatchGroupWatcher() {
  yield takeEvery(actions.getMatchGroup, getMatchGroupWorker);
}

function* createMatchDataWorker({
  payload,
}: InferAction<typeof actions.createMatchData>) {
  const {
    matchId,
    match,
    matchPayload,
    matchSetsPayload,
    onSuccessEffectParameters,
  } = payload;

  yield put(actions.createMatchDataRequest({ id: matchId }));

  const createMatchSetsAction = matchSetActions.createMatchSets({
    onSuccessEffectParameters: null,
    matchId,
    matchSets: matchSetsPayload,
    *onSuccess() {
      yield put(matchSetActions.getMatchSets({ matchId }));
    },
  });
  const changeMatchPropertiesAction = matchActions.changeMatchProperties({
    onSuccessEffectParameters: null,
    match,
    data: matchPayload,
  });

  const responses = (yield all([
    call(matchSetSagas.createMatchSetsWorker, createMatchSetsAction),
    call(matchSagas.changeMatchPropertiesWorker, changeMatchPropertiesAction),
  ])) as [
    SagaReturnType<typeof matchSetSagas.createMatchSetsWorker>,
    SagaReturnType<typeof matchSagas.changeMatchPropertiesWorker>,
  ];

  const flattenResponses = responses.flat<any>();

  if (flattenResponses.every((response) => !response.error)) {
    yield put(actions.createMatchDataSuccess({ id: matchId }));
    yield call(
      getMatchMutatedSuccessfullyCallbacks,
      onSuccessEffectParameters,
      'update',
    );
    yield put(api.util.invalidateTags(['Brackets']));
  } else {
    if (flattenResponses.some((response) => !response.error)) {
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        { ...onSuccessEffectParameters, tabId: null },
        'update',
      );
    }
    yield put(actions.createMatchDataFailure({ id: matchId }));
  }
}

function* createMatchDataWatcher() {
  yield takeEvery(actions.createMatchData, createMatchDataWorker);
}

export default function* saga() {
  yield all([
    getMatchPlayersFlow(),
    getTeamOfficialsFlow(),
    getKitSetFlowWatcher(),
    getDurationListFlow(),
    getMatchScoresFlow(),
    getMatchCompetitorsFlow(),
    updateMatchCompetitorFlow(),
    getCompetitionCompetitorWatcher(),
    getMatchNotesDescriptionsFlow(),
    getMatchNotesTitlesFlow(),
    getMatchCommissionerNotesTitlesFlow(),
    getMatchCommissionerNotesDescriptionsFlow(),
    updateMatchCommissionerNotesDescriptionsFlow(),
    updateMatchNotesDescriptionsFlow(),
    updateDurationListFlow(),
    getMatchWatcher(),
    getMatchGroupWatcher(),
    createMatchDataWatcher(),
  ]);
}
