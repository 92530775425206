import React, { useEffect, useState } from 'react';

export function useLazyReinitializableState<S>(
  initialState: () => S,
  deps: React.DependencyList,
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [...(deps ?? [])]);

  return [state, setState];
}
