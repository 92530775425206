import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  AlertStatus,
  ColumnWithSubValue,
  TableWithAssign,
} from '@core/components';
import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { formatDate } from '@core/helpers';
import { State } from '@core/store';
import { Player, PlayerList, CustomerResponse } from '@core/types';

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  addedCompetitorPlayers: PlayerList;
  updatePlayersList: (players: PlayerList) => void;
  earliestDateOfBirth: string;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

type Props = StateProps & OwnProps;

const CompetitorPlayersTable = (props: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const {
    updatePlayersList,
    addedCompetitorPlayers,
    earliestDateOfBirth,
    currentCustomer,
    isLoading,
  } = props;

  const handleRemoveFromList = (id: number) => {
    const updatedPlayers = addedCompetitorPlayers?.filter(
      (player: Player) => player.id !== id,
    );
    updatePlayersList(updatedPlayers);
  };

  const columnsAdded = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: Player) => (
        <ColumnWithSubValue
          subValue={
            rowData.firstName && `${rowData.firstName} ${rowData.familyName}`
          }
          value={
            rowData.localFirstName &&
            `${rowData.localFirstName} ${rowData.localFamilyName}`
          }
        />
      ),
    },
    {
      name: 'dateOfBirth',
      label: 'Date of birth',
      render: (rowData: Player) => (
        <>
          {formatDate(currentCustomer?.dateFormat, rowData.dateOfBirth)}
          {earliestDateOfBirth &&
            earliestDateOfBirth >= rowData.dateOfBirth && (
              <AlertStatus title={t('Overage')} />
            )}
        </>
      ),
    },
  ];

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Added players')} (${addedCompetitorPlayers?.length || 0})`}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            isLoading={isLoading}
            columns={columnsAdded}
            data={addedCompetitorPlayers}
            maxHeight={496}
            noStickyHeader
            handleRemove={handleRemoveFromList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(mapStateToProps)(CompetitorPlayersTable);
