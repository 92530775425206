import type { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';
import ErrorMessage from './components/error-message';

const SentryErrorBoundary = ({ children }: PropsWithChildren) => (
  <Sentry.ErrorBoundary fallback={() => <ErrorMessage />}>
    {children}
  </Sentry.ErrorBoundary>
);

export default SentryErrorBoundary;
