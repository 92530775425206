import { createActions } from 'redux-actions';
import { NAMESPACE } from './constants';

export const actions = createActions(
  'CREATE_COMPETITION_SEASON_REQUEST',
  'CREATE_COMPETITION_SEASON_SUCCESS',
  'CREATE_COMPETITION_SEASON_FAILURE',

  'PATCH_COMPETITION_SEASON_REQUEST',
  'PATCH_COMPETITION_SEASON_SUCCESS',
  'PATCH_COMPETITION_SEASON_FAILURE',

  'DELETE_COMPETITION_SEASON_REQUEST',
  'DELETE_COMPETITION_SEASON_SUCCESS',
  'DELETE_COMPETITION_SEASON_FAILURE',

  'GET_COMPETITION_SEASON_REQUEST',
  'GET_COMPETITION_SEASON_SUCCESS',
  'GET_COMPETITION_SEASON_FAILURE',

  'GET_COMPETITION_SEASONS_REQUEST',
  'GET_COMPETITION_SEASONS_SUCCESS',
  'GET_COMPETITION_SEASONS_FAILURE',

  'SET_COMPETITION_SEASON_LIST',
  'SET_COMPETITION_SEASON',

  'RESET_COMPETITION_SEASON_LIST',

  { prefix: NAMESPACE },
);
