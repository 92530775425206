import { call, Effect, put } from 'redux-saga/effects';

import * as playerPositionsAPI from '@core/api/player-positions';

import { actions } from '.';

export function* getPlayerPositionBySport(
  sportId: number,
): Generator<Effect, any, any> {
  yield put(actions.getPlayerPositionsBySportRequest());
  const response = yield call(
    playerPositionsAPI.getPlayerPositionBySport,
    sportId,
  );

  if (!response.error) {
    yield put(actions.getPlayerPositionsBySportSuccess());
  } else {
    yield put(actions.getPlayerPositionsBySportFailure());
  }

  return response;
}
