import { pathOr, groupBy } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { State } from '@core/store';
import { DashboardMatchModel, Conflict } from '@core/types';

import { NAMESPACE } from '.';

export const getMatches = (state: State): Array<DashboardMatchModel> =>
  state?.api?.[NAMESPACE]?.matches;
export const getConflicts = (state: State): Array<Conflict> =>
  state?.api?.[NAMESPACE]?.conflicts;

export const getMatchesWithConflicts = createSelector(
  [getMatches, getConflicts],
  (matches, conflicts) => {
    if (!matches) {
      return [];
    }
    const conflictsGroupedByMatchId = conflicts
      ? groupBy((conflict) => String(conflict.matchId), conflicts)
      : null;

    return (
      matches?.map<DashboardMatchModel & { conflicts: Array<Conflict> }>(
        (match: DashboardMatchModel) => ({
          ...match,
          conflicts: pathOr(null, [match.id], conflictsGroupedByMatchId),
        }),
      ) ?? []
    );
  },
);
