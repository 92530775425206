import type { ReactElement } from 'react';

type Props = {
  condition: boolean;
  wrapper: (children: ReactElement) => JSX.Element;
  children: ReactElement;
};

const ConditionalWrapper = ({ condition, wrapper, children }: Props) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
