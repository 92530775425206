import { createActions } from 'redux-actions';

import { NAMESPACE } from './constants';

export default createActions(
  'GET_FEATURE_TOGGLES',
  'GET_FEATURE_TOGGLES_REQUEST',
  'GET_FEATURE_TOGGLES_SUCCESS',
  'GET_FEATURE_TOGGLES_FAILURE',
  'SET_FEATURE_TOGGLES',
  'CHANGE_FEATURE_TOGGLE_STATUS',
  'CHANGE_FEATURE_TOGGLE_STATUS_REQUEST',
  'CHANGE_FEATURE_TOGGLE_STATUS_SUCCESS',
  'CHANGE_FEATURE_TOGGLE_STATUS_FAILURE',
  { prefix: NAMESPACE },
);
