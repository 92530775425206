import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { SummaryBlock, SummaryBlockValues } from '@core/components';
import { CustomerResponse, GlobalModalProps, MatchModel } from '@core/types';
import { actions as matchActions } from '@core/store/modules/api/match';
import { getMatchById } from '@core/store/modules/matches/selectors';
import { actions as matchSheetActions } from '@core/store/modules/tabs/match-sheet-management';
import { State } from '@volleyball/store';
import { StandingItem } from '@volleyball/types';

import StandingsHistory from './standings-history';

interface DispatchProps {
  actions: {
    match: typeof matchActions;
  };
}

interface StateProps {
  isLoading: boolean;
  match: MatchModel;
  currentCustomer: CustomerResponse;
}

interface ModalParams {
  matchId: number;
  competitionTitle: string;
  seasonTitle: string;
  stageTitle: string;
  standings: Array<StandingItem>;
}

type Props = GlobalModalProps<ModalParams> & DispatchProps & StateProps;

const StandingsHistoryModal = (props: Props) => {
  const { isModalOpen, modalActions, modalParams } = props;

  const { t } = useTranslation();

  const getSummaryBlocks = (): SummaryBlockValues => {
    return [
      {
        label: t('Competition'),
        value: modalParams?.competitionTitle,
      },
      {
        label: t('Season'),
        value: modalParams?.seasonTitle,
      },
      {
        label: t('Stage'),
        value: modalParams?.stageTitle,
      },
    ];
  };

  const handleClose = () => {
    modalActions.closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: modalActions.resetState }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t('Standings adjustment history')}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <SummaryBlock values={getSummaryBlocks()} />
        </Box>
        <StandingsHistory
          matchId={modalParams.matchId}
          standings={modalParams?.standings}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" padding={1}>
          <Button variant={'contained'} color={'primary'} onClick={handleClose}>
            {t('Close')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  matchSheetActions.getMatchSheetHistoryRequest.toString(),
]);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    match: bindActionCreators(matchActions, dispatch),
  },
});

const mapStateToProps = (
  state: State,
  { modalParams }: GlobalModalProps,
): StateProps => ({
  isLoading: loadingSelector(state),
  match: getMatchById(state, modalParams.matchId),
  currentCustomer: getCurrentCustomer(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StandingsHistoryModal);
