import { call } from 'redux-saga/effects';
import i18next from 'i18next';

import { handleApiErrors } from '@core/helpers';
import { Error } from '@core/types';

export function* handleBulkApiErrors(
  bulkErrors: Array<Error & { matchId: number }>,
) {
  const matchIdsGroupedByErrorMessage = bulkErrors.reduce<
    Record<string, Array<number>>
  >(
    (prev, curr) => ({
      ...prev,
      [curr.message]: [
        ...(prev[curr.message] ? prev[curr.message] : []),
        curr.matchId,
      ],
    }),
    {},
  );

  const errors: Array<Error> = Object.entries(
    matchIdsGroupedByErrorMessage,
  ).map(([message, matchIds]) => ({
    message: `Matches ${matchIds.join(', ')}\n${i18next.t(message)}`,
    errors: null,
  }));

  for (const error of errors) {
    yield call(handleApiErrors, error);
  }
}
