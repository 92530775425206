import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';
import type { Groups } from '@core/types';
import { actions } from '.';

export interface DefaultState {
  allGroups: Groups;
  currentStep: number;
}

export const defaultState: DefaultState = {
  allGroups: null,
  currentStep: 0,
};

const reducer = handleActions<DefaultState, any>(
  {
    [actions.setGroups.toString()]: (state, { payload }) =>
      assoc('allGroups', payload, state),
    [actions.setActiveStep.toString()]: (state, { payload }) =>
      assoc('currentStep', payload, state),
  },
  defaultState,
);

export default reducer;
