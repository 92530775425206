import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  ChipsSelect,
  ExpansionPanelComponent,
  NumberField,
} from '@core/components';
import useStyles from '../styles';
import { FIELD_NAMES } from '../constants';
import { FormValues } from '../helpers/initialValues';

const StageMatchDetailsBlock = () => {
  const { values } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const classes = useStyles();

  const numberOfSets = [
    {
      value: 1,
      label: `1 ${t('set')}`,
    },
    {
      value: 3,
      label: `3 ${t('sets')}`,
    },
    {
      value: 5,
      label: `5 ${t('sets')}`,
    },
  ];

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" className={classes.uppercase}>
            {t('Match Details')}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <Box mb={1}>
              <Paper>
                <ExpansionPanelComponent
                  title={t('Number of sets')}
                  tooltip={t('Select number of sets')}
                  value={`${t('best of')} ${values.numberOfSets}`}
                >
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <ChipsSelect
                        fieldName={FIELD_NAMES.numberOfSets}
                        data-qa={FIELD_NAMES.numberOfSets}
                        required
                        options={numberOfSets}
                        title={t('Best of')}
                      />
                    </Grid>
                  </Grid>
                </ExpansionPanelComponent>
              </Paper>
            </Box>
            <Box mb={1}>
              <Paper>
                <ExpansionPanelComponent
                  title={t('Points to win')}
                  tooltip={t('Enter points to win')}
                  value={`${values.pointsToWinInRegularSet} ${t('pts (R)')} • ${
                    values.pointsToWinInTiebreakerSet
                  } ${t('pts (T)')}`}
                >
                  <Grid container item spacing={2}>
                    <Grid item xs={12} md={6}>
                      <NumberField
                        name={FIELD_NAMES.pointsToWinInRegularSet}
                        data-qa={FIELD_NAMES.pointsToWinInRegularSet}
                        label={t('Regular set')}
                        min={1}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <NumberField
                        name={FIELD_NAMES.pointsToWinInTiebreakerSet}
                        data-qa={FIELD_NAMES.pointsToWinInTiebreakerSet}
                        label={t('Tie-breaker')}
                        min={1}
                        required
                      />
                    </Grid>
                  </Grid>
                </ExpansionPanelComponent>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Box mb={2} mt={1} flex={1}>
          <Divider orientation="horizontal" />
        </Box>
      </Grid>
    </>
  );
};

export default StageMatchDetailsBlock;
