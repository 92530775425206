import { MonthShort } from '@core/types';

export const getMonthShort = (t: (key: string) => string, month: string) => {
  switch (month) {
    case MonthShort.JAN:
      return t('Jan');
    case MonthShort.FEB:
      return t('Feb');
    case MonthShort.MAR:
      return t('Mar');
    case MonthShort.APR:
      return t('Apr');
    case MonthShort.MAY:
      return t('May');
    case MonthShort.JUN:
      return t('Jun');
    case MonthShort.JUL:
      return t('Jul');
    case MonthShort.AUG:
      return t('Aug');
    case MonthShort.SEP:
      return t('Sep');
    case MonthShort.OCT:
      return t('Oct');
    case MonthShort.NOV:
      return t('Nov');
    case MonthShort.DEC:
      return t('Dec');
    default:
      return t(month);
  }
};
