import { all, take, call, put } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as disciplinaryRulesActions from '@core/store/modules/api/disciplinary-rule/sagas';
import {
  createCompetitionContact,
  getCompetitionContacts,
  updateCompetitionContact,
  deleteCompetitionContact,
} from '@core/store/modules/api/competition-contacts/sagas';

import actions from '.';

export function* getDisciplinaryRulesFlow() {
  while (true) {
    const { payload } = yield take(actions.getDisciplinaryRules);
    const { competitionId } = payload;

    yield put(actions.getDisciplinaryRulesRequest());
    const { error, response } = yield call(
      disciplinaryRulesActions.getDisciplinaryRules,
      payload,
    );

    if (!error) {
      yield put(actions.getDisciplinaryRulesSuccess());
      yield put(
        actions.setDisciplinaryRules(response._embedded.disciplinaryRules, {
          competitionId,
        }),
      );
    } else {
      yield put(actions.getDisciplinaryRulesFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* getDisciplinaryRuleFlow() {
  while (true) {
    const { payload } = yield take(actions.getDisciplinaryRule);
    const { competitionId, ruleId } = payload;

    yield put(actions.getDisciplinaryRuleRequest());
    const { error, response } = yield call(
      disciplinaryRulesActions.getDisciplinaryRule,
      payload,
    );

    if (!error) {
      yield put(actions.getDisciplinaryRuleSuccess());
      yield put(
        actions.setDisciplinaryRule(response._embedded.disciplinaryRules, {
          ruleId,
          competitionId,
        }),
      );
    } else {
      yield put(actions.getDisciplinaryRuleFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* createDisciplinaryRuleFlow() {
  while (true) {
    const { payload } = yield take(actions.createDisciplinaryRule);

    yield put(actions.createDisciplinaryRuleRequest());
    const { error } = yield call(
      disciplinaryRulesActions.createDisciplinaryRule,
      payload,
    );

    if (!error) {
      yield put(actions.createDisciplinaryRuleSuccess());
      yield put(actions.getDisciplinaryRules(payload));
    } else {
      yield put(actions.createDisciplinaryRuleFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* updateDisciplinaryRuleFlow() {
  while (true) {
    const { payload } = yield take(actions.updateDisciplinaryRule);

    yield put(actions.updateDisciplinaryRuleRequest());
    const { error } = yield call(
      disciplinaryRulesActions.updateDisciplinaryRule,
      payload,
    );

    if (!error) {
      yield put(actions.updateDisciplinaryRuleSuccess());
      yield put(actions.getDisciplinaryRules(payload));
    } else {
      yield put(actions.updateDisciplinaryRuleFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export function* deleteDisciplinaryRuleFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteDisciplinaryRule);

    yield put(actions.deleteDisciplinaryRuleRequest());
    const { error } = yield call(
      disciplinaryRulesActions.deleteDisciplinaryRule,
      payload,
    );

    if (!error) {
      yield put(actions.deleteDisciplinaryRuleSuccess());
      yield put(actions.getDisciplinaryRules(payload));
    } else {
      yield put(actions.deleteDisciplinaryRuleFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* createCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.createCompetitionContact);

    yield put(actions.createCompetitionContactRequest());
    const { error } = yield call(
      createCompetitionContact,
      payload.competitionId,
      payload.data,
    );

    if (!error) {
      yield put(actions.createCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.createCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* updateCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.updateCompetitionContact);

    yield put(actions.updateCompetitionContactRequest());
    const { error } = yield call(
      updateCompetitionContact,
      payload.competitionId,
      payload.contactId,
      payload.data,
    );

    if (!error) {
      yield put(actions.updateCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.updateCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* deleteCompetitionContactFlow() {
  while (true) {
    const { payload } = yield take(actions.deleteCompetitionContact);

    yield put(actions.deleteCompetitionContactRequest());
    const { error } = yield call(
      deleteCompetitionContact,
      payload.competitionId,
      payload.contactId,
    );

    if (!error) {
      yield put(actions.deleteCompetitionContactSuccess());
      yield put(actions.getCompetitionContacts(payload.competitionId));
    } else {
      yield put(actions.deleteCompetitionContactFailure());
      yield call(handleApiErrors, error);
    }
  }
}

function* getCompetitionContactsFlow() {
  while (true) {
    const { payload } = yield take(actions.getCompetitionContacts);

    yield put(actions.getCompetitionContactsRequest());
    const { error, response } = yield call(getCompetitionContacts, payload);

    if (!error) {
      yield put(actions.getCompetitionContactsSuccess());
      yield put(
        actions.setCompetitionContacts({
          payload: response._embedded.competitionContacts,
          competitionId: payload,
        }),
      );
    } else {
      yield put(actions.getCompetitionContactsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    getDisciplinaryRulesFlow(),
    getDisciplinaryRuleFlow(),
    createDisciplinaryRuleFlow(),
    updateDisciplinaryRuleFlow(),
    deleteDisciplinaryRuleFlow(),
    createCompetitionContactFlow(),
    getCompetitionContactsFlow(),
    updateCompetitionContactFlow(),
    deleteCompetitionContactFlow(),
  ]);
}
