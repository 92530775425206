import { createAction } from 'redux-actions';

import { Paginated } from '@core/types';

import { NAMESPACE } from './constants';

type QueryParams = {
  page?: number;
  query?: string;
};

const setCountries = createAction<Paginated<number>>(
  `${NAMESPACE}/SET_COUNTRIES`,
);
const getCountries = createAction<QueryParams | void>(
  `${NAMESPACE}/GET_COUNTRIES`,
);
const getCountriesRequest = createAction<void>(
  `${NAMESPACE}/GET_COUNTRIES_REQUEST`,
);
const getCountriesSuccess = createAction<void>(
  `${NAMESPACE}/GET_COUNTRIES_SUCCESS`,
);
const getCountriesFailure = createAction<void>(
  `${NAMESPACE}/GET_COUNTRIES_FAILURE`,
);
const searchCountriesByQuery = createAction<QueryParams>(
  `${NAMESPACE}/SEARCH_COUNTRIES_BY_QUERY`,
);
const resetState = createAction<void>(`${NAMESPACE}/RESET_STATE`);

export default {
  setCountries,
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,
  getCountries,
  resetState,
  searchCountriesByQuery,
};
