import { ascend, descend } from 'ramda';
import { getMatchOfficialRoleType } from '@core/helpers';
import { MatchOfficialRole, MatchOfficialRoleType } from '@core/types';
import type { TFunction } from 'i18next';

export const isRequiredMatchOfficialRole = (role: MatchOfficialRole): boolean =>
  [
    MatchOfficialRoleType.REFEREE,
    MatchOfficialRoleType.SECOND_REFEREE,
  ].includes(role.roleType);

export function getSortedByMatchOfficialRoles<
  T extends Array<MatchOfficialRole>,
>(t: TFunction, matchOfficialRoles: T, propertyAccessor?: never): T;
export function getSortedByMatchOfficialRoles<
  T extends Array<any>,
  U = InferArray<T>,
>(
  t: TFunction,
  anyArray: T,
  propertyAccessor: (value: U) => MatchOfficialRole,
): T;
export function getSortedByMatchOfficialRoles<T = any, U = InferArray<T>>(
  t: TFunction,
  matchOfficialRolesOrAnyArray: Array<MatchOfficialRole> | Array<T>,
  propertyAccessor?: (value: U) => MatchOfficialRole,
): Array<T> {
  return matchOfficialRolesOrAnyArray
    .map((matchOfficialRolesOrSomeElement: any) => {
      const matchOfficialRole: MatchOfficialRole = propertyAccessor
        ? propertyAccessor(matchOfficialRolesOrSomeElement)
        : matchOfficialRolesOrSomeElement;

      return {
        disabled: isRequiredMatchOfficialRole(matchOfficialRole),
        title: getMatchOfficialRoleType(t, matchOfficialRole),
        value: matchOfficialRolesOrSomeElement,
      };
    })
    .sort(ascend((value) => value.title))
    .sort(descend((value) => value.disabled))
    .map(({ value }) => value);
}
