import { pathOr, path } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { Filters, Person } from '@core/types';
import { State } from '@core/store';
import { NAMESPACE as personsNamespace } from '@core/store/modules/api/persons/actions';

import { DefaultState } from './reducer';
import { NAMESPACE } from './constants';

export const getPersonsListIds = pathOr<DefaultState['persons']['list']>(null, [
  'pages',
  NAMESPACE,
  'persons',
  'list',
]);
export const getPersonsPaginated = pathOr<DefaultState['persons']>(null, [
  'pages',
  NAMESPACE,
  'persons',
]);
export const getPersonsLinks = pathOr<DefaultState['links']>(null, [
  'pages',
  NAMESPACE,
  'links',
]);
export const getPersonsLazyLoading = pathOr<DefaultState['personsLazyLoading']>(
  null,
  ['pages', NAMESPACE, 'personsLazyLoading'],
);
export const getTeamOfficialsRoles = pathOr<DefaultState['teamOfficialsRoles']>(
  null,
  ['pages', NAMESPACE, 'teamOfficialsRoles'],
);
export const getMatchOfficialsRoles = pathOr<
  DefaultState['matchOfficialRoles']
>(null, ['pages', NAMESPACE, 'matchOfficialRoles']);
export const getSearchQuery = (state: State): string =>
  path(['pages', NAMESPACE, 'query'], state);

const getPersonsPersons = (state: State): { [id: number]: Person } => {
  return pathOr(null, ['api', personsNamespace], state);
};
const getPersonsIds = (state: State): DefaultState['persons']['list'] =>
  pathOr(null, ['pages', NAMESPACE, 'persons', 'list'], state);

export const getPersonsList = createSelector(
  getPersonsPersons,
  getPersonsIds,
  (membersPersons, membersIds) =>
    membersIds ? membersIds.map((id: number) => membersPersons[id]) : [],
);

export const getFilters = (state: State): Filters =>
  path(['pages', NAMESPACE, 'filters'], state);
