import { Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isValue } from '@core/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    height: theme.spacing(4.5),
    flex: `0 0 ${theme.spacing(8.5)}`,
    minWidth: `${theme.spacing(8.5)}`,
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(4.5),
    lineHeight: `${theme.spacing(4.5)}`,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  score: {
    flex: `0 0 ${theme.spacing(3)}`,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(0.5),
  },
  divider: {
    flex: `0 0 ${theme.spacing(2.5)}`,
  },
}));

interface Props {
  scoreHome: number;
  scoreAway: number;
}

const ColumnWithScore = ({ scoreHome, scoreAway }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography
        className={clsx(classes.item, classes.score)}
        align={'center'}
        variant="body2"
      >
        {isValue(scoreHome) ? scoreHome : '—'}
      </Typography>
      <Typography
        className={clsx(classes.item, classes.divider)}
        variant="caption"
        color="textSecondary"
      >
        {':'}
      </Typography>
      <Typography
        className={clsx(classes.item, classes.score)}
        align={'center'}
        variant="body2"
      >
        {isValue(scoreAway) ? scoreAway : '—'}
      </Typography>
    </Box>
  );
};

export default ColumnWithScore;
