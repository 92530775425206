import { useState } from 'react';
import { Grid, Button, IconButton, Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  AddCircleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
} from '@mui/icons-material';
import { CustomTable, ContentBlock } from '@core/components';
import { EDIT_BUTTON, DELETE_BUTTON } from '@core/constants/test-ids';
import { useCoreDispatch, useConfirmationDialog } from '@core/hooks';
import ContactInformationModal from '@core/pages/competition-profile/components/modals/contact-information-modal';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { areContactsLoading } from '@core/store/modules/tabs/competition-contacts-edit/selectors';
import { actions as competitionContactsEditActions } from '@core/store/modules/tabs/competition-contacts-edit';
import type { Column } from '@core/components/material-table';
import type { CompetitionResponse, Contact } from '@core/types';

type Props = {
  competition: CompetitionResponse;
};

const ContactsList = ({ competition }: Props) => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const { getConfirmation } = useConfirmationDialog();
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [contactToEdit, setContactToEdit] = useState<Contact>(null);
  const existingCompetition = useSelector(
    getCompetitionDetailsById(competition?.id),
  );
  const currentCompetition = existingCompetition ?? competition;
  const isLoading = useSelector(areContactsLoading);
  const canEditCompetition = !!currentCompetition?._links?.patch;

  const columns: Column<Contact>[] = [
    {
      title: t('Title'),
      field: 'title',
    },
    {
      title: t('Contact person'),
      render: (contact) => `${contact.firstName} ${contact.familyName}`,
    },
    {
      title: t('Phone'),
      field: 'phone',
    },
    {
      title: t('Email'),
      field: 'email',
    },
    canEditCompetition && {
      field: 'actions',
      render: (contact) => (
        <>
          <IconButton
            color="primary"
            onClick={handleEditClick(contact)}
            data-qa={EDIT_BUTTON}
          >
            <EditTwoTone />
          </IconButton>
          <IconButton
            color="primary"
            onClick={handleDeleteClick(contact)}
            data-qa={DELETE_BUTTON}
          >
            <DeleteTwoTone />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClose = () => setIsContactModalOpen(false);

  const handleSubmit = (contact: Partial<Contact>) => {
    if (contactToEdit?.id) {
      dispatch(
        competitionContactsEditActions.updateCompetitionContact({
          competitionId: currentCompetition.id,
          contactId: contactToEdit.id,
          data: {
            title: contact.title,
            firstName: contact.firstName,
            familyName: contact.familyName,
            email: contact.email,
            phone: contact.phone,
          },
        }),
      );
    } else {
      dispatch(
        competitionContactsEditActions.createCompetitionContact({
          competitionId: currentCompetition.id,
          data: contact,
        }),
      );
    }

    setIsContactModalOpen(false);
  };

  const handleAdd = () => {
    setContactToEdit(null);
    setIsContactModalOpen(true);
  };

  const handleEditClick = (contact: Contact) => () => {
    setContactToEdit(contact);
    setIsContactModalOpen(true);
  };

  const handleDeleteClick = (contact: Contact) => async () => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this contact?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      dispatch(
        competitionContactsEditActions.deleteCompetitionContact({
          competitionId: currentCompetition.id,
          contactId: contact.id,
        }),
      );
    }
  };

  return (
    <ContentBlock title={t('Contacts')}>
      <Grid item xs={12}>
        <Paper variant="elevation">
          {canEditCompetition && (
            <Box display="flex" justifyContent="flex-end" p={1.5}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAdd}
                startIcon={<AddCircleTwoTone />}
                data-qa="add-contact-button"
              >
                {t('Add contact')}
              </Button>
            </Box>
          )}

          <CustomTable
            disableBodyElevation
            isLoading={isLoading}
            options={{
              search: false,
              toolbar: false,
              showFirstLastPageButtons: false,
            }}
            data={currentCompetition.contacts}
            columns={columns}
          />
        </Paper>
        <ContactInformationModal
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          initialFormValues={contactToEdit}
          isOpen={isContactModalOpen}
          isSubmitting={isLoading}
        />
      </Grid>
    </ContentBlock>
  );
};

export default ContactsList;
