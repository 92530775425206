import restService from '@core/api/rest-service';
import { Season, SeasonsListResponse } from '@core/types';

interface GetSeasonsProps {
  queryParams?: {
    query?: string;
    page?: number;
  };
}

export const getSeasons = ({ queryParams }: GetSeasonsProps = {}) =>
  restService.get<SeasonsListResponse>('/v1/seasons', { params: queryParams });

export const getSeason = (id: number) => restService.get(`/v1/seasons/${id}`);

export const deleteSeason = (id: number) =>
  restService.delete(`/v1/seasons/${id}`);

export const createSeason = (data: Season) =>
  restService.post(`/v1/seasons`, data);

export const patchSeason = (id: number, data: Season) =>
  restService.patch(`/v1/seasons/${id}`, data);
