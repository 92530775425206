import { Box, Typography, Avatar, Grid, Tooltip, Theme } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Competition } from '@core/icons';
import { LogoSize } from '@core/types';
import { UserRole } from '@core/types/api';
import userActions from '@core/pages/user-profile/store/actions';
import * as competitionManagerActions from '@core/store/modules/competition-managers/actions';
import { getUserRolesDetailed } from '@core/helpers';
import { Logo } from '@core/components';
import * as testIds from '@core/components/app-bar/tests/test-ids';
import { useCurrentUser } from '@core/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  avatar: {
    width: 40,
    height: 40,
    background: 'transparent',
    borderColor: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  detailsContainer: {
    paddingRight: '4px !important',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  details: {
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '@global': {
      '>*:not(:first-child)': {
        paddingTop: theme.spacing(0.5),
      },
    },
  },
  name: {
    fontSize: 14,
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
  role: {
    lineHeight: 1,
    color: 'rgb(255, 255, 255, 0.7)',
  },
  multipleRoleText: {},
  paddingRight: {
    paddingRight: '4px !important',
  },
  iconArrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface OwnProps {
  isOpen: boolean;
}

interface DispatchProps {
  actions: {
    competitionManager: typeof competitionManagerActions;
    user: typeof userActions;
  };
}

type Props = OwnProps & DispatchProps;

const UserProfile = (props: Props) => {
  const { isOpen, actions } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, hasUser } = useCurrentUser();

  const hasRoles = !!user?.userRoles?.length;

  useEffect(() => {
    if (!hasUser) {
      actions.user.getCurrentUser();
    }
  });

  const renderUserRole = (roles: Array<UserRole>) => {
    if (!roles || !roles.length) {
      return null;
    }

    const rolesDetailed = getUserRolesDetailed(t, roles);

    return rolesDetailed.length === 1 ? (
      rolesDetailed[0]
    ) : (
      <Tooltip
        title={
          <>
            {rolesDetailed.map((role, index) => (
              <Grid key={index}>{role}</Grid>
            ))}
          </>
        }
        placement="bottom"
      >
        <span className={classes.multipleRoleText}>{t('Multiple roles')}</span>
      </Tooltip>
    );
  };

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      spacing={2}
      justifyContent="space-between"
    >
      <Grid item className={classes.paddingRight}>
        {user && user.profilePictureFileLink ? (
          <Logo
            data-qa={testIds.USER_MENU_ICON}
            size={LogoSize.SMALL}
            src={user.profilePictureFileLink}
          >
            <Competition />
          </Logo>
        ) : (
          <Avatar data-qa={testIds.USER_MENU_ICON} className={classes.avatar}>
            {user.firstName?.charAt(0)}
            {user.lastName?.charAt(0)}
          </Avatar>
        )}
      </Grid>
      <Grid item className={classes.detailsContainer}>
        <Box
          component={Grid}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.details}
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          {hasUser && hasRoles && (
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.role}>
                {renderUserRole(user.userRoles)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.name}>
              {hasUser && user.firstName + ' ' + user.lastName}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item className={classes.iconArrow}>
        {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionManager: bindActionCreators(competitionManagerActions, dispatch),
    user: bindActionCreators(userActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(UserProfile);
