import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  SelectField,
  TextField,
  DatePickerField,
  TimePickerField,
  VenueSearchField,
  ModalFieldsBlock,
} from '@core/components';
import {
  VenueModel,
  Paginated,
  GroupCompetitor,
  Group,
  StageType,
} from '@core/types';
import { actions as fixtureMatchEditActions } from '@core/store/modules/modals/fixture-match-edit';
import * as testIds from './tests/test-ids';
import { useCoreDispatch } from '@core/hooks';
import { FIELD_NAMES } from './constants';
import type { FormValues } from './helpers/initialValues';

type Props = {
  isEditMode?: boolean;
  competitors: Array<GroupCompetitor>;
  venues: Paginated<VenueModel>;
  stage: Group;
};

const MatchCreateForm = ({ isEditMode, competitors, venues, stage }: Props) => {
  const dispatch = useCoreDispatch();
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<FormValues>();
  const [subVenues, setSubVenues] = useState(null);

  useEffect(() => {
    const availableSubVenuesFromValues =
      // @ts-ignore
      values[FIELD_NAMES.venueOption]?.props?.subVenues;
    const availableSubVenuesFromVenues = venues?.list?.find(
      (venue) => venue.id === values[FIELD_NAMES.venueOption]?.value,
    )?.subVenues;

    setSubVenues(availableSubVenuesFromVenues ?? availableSubVenuesFromValues);
  }, [venues, values[FIELD_NAMES.venueOption]]);

  const onVenueChange = () => {
    setValues(
      {
        ...values,
        [FIELD_NAMES.subVenueOption]: null,
      },
      false,
    );
  };

  const handleSearchVenue = (query: string) =>
    dispatch(fixtureMatchEditActions.searchVenues({ query, page: 1 }));

  const handleLoadNextVenues = ({
    query,
    page,
  }: {
    query: string;
    page: number;
  }) =>
    dispatch(
      fixtureMatchEditActions.getVenues({
        query,
        page,
      }),
    );

  const formatGroupCompetitorsOptions = (
    groupCompetitors: Array<GroupCompetitor>,
  ) => {
    // Filter competitors by groupId if childGroups exist
    return groupCompetitors
      ?.filter((groupCompetitor) =>
        stage?.childGroups?.length > 0
          ? groupCompetitor.groupId === values[FIELD_NAMES.groupOption]
          : true,
      )
      .map((groupCompetitor) => ({
        label: groupCompetitor.competitor
          ? `${t('Team')} ${groupCompetitor.slotNumber} - ${
              groupCompetitor.competitor.title
            }`
          : `${t('Team')} ${groupCompetitor.slotNumber}`,
        value: groupCompetitor.id,
      }));
  };

  const formatGroupsOptions = (groups: Array<Group>) =>
    groups?.map((group: Group) => ({
      label: group.title,
      value: group.id,
    }));

  const formatSubVenueOptions = () =>
    subVenues?.map((subVenue: VenueModel) => ({
      label: subVenue.title,
      value: subVenue.id,
    }));

  return (
    <>
      <ModalFieldsBlock title={t('Details')}>
        {stage?.childGroups?.length > 0 && (
          <Grid item xs={12}>
            <SelectField
              name={FIELD_NAMES.groupOption}
              data-qa={testIds.MATCH_GROUP_SELECT}
              label={
                stage?.stageType === StageType.ROUND_ROBIN
                  ? t('Pool')
                  : t('Round')
              }
              options={formatGroupsOptions(stage?.childGroups)}
              disabled={isEditMode}
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            name={FIELD_NAMES.matchDayNumber}
            data-qa={testIds.MATCH_DAY_INPUT}
            label={
              stage?.stageType === StageType.ROUND_ROBIN
                ? t('Matchday')
                : t('Leg')
            }
            type="number"
            disabled={isEditMode}
            inputProps={{
              min: 1,
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name={FIELD_NAMES.number}
            data-qa={testIds.MATCH_NUMBER_INPUT}
            label={t('Match No.')}
            type={'text'}
            disabled={isEditMode}
            required
          />
        </Grid>
      </ModalFieldsBlock>
      <ModalFieldsBlock title={t('Date & Time')}>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={FIELD_NAMES.date}
            data-qa={testIds.MATCH_DATE_INPUT}
            label={t('Date')}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TimePickerField
            name={FIELD_NAMES.time}
            data-qa={testIds.MATCH_TIME_INPUT}
            label={t('Time')}
            required
          />
        </Grid>
      </ModalFieldsBlock>
      <ModalFieldsBlock title={t('Teams')}>
        <Grid item xs={12}>
          <SelectField
            name={FIELD_NAMES.homeCompetitorId}
            data-qa={testIds.MATCH_HOME_COMPETITOR_SELECT}
            label={t('Home team')}
            options={formatGroupCompetitorsOptions(competitors)}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={FIELD_NAMES.awayCompetitorId}
            data-qa={testIds.MATCH_AWAY_COMPETITOR_SELECT}
            label={t('Away team')}
            options={formatGroupCompetitorsOptions(competitors)}
          />
        </Grid>
      </ModalFieldsBlock>
      <ModalFieldsBlock title={t('Location')}>
        <Grid item xs={12}>
          <VenueSearchField
            name={FIELD_NAMES.venueOption}
            label={t('Venue')}
            placeholder={t('Start typing')}
            options={venues}
            onInputChange={handleSearchVenue}
            onLoadNext={handleLoadNextVenues}
            onChange={onVenueChange}
            menuPosition="fixed"
          />
        </Grid>
        {subVenues?.length > 0 && (
          <Grid item xs={12}>
            <SelectField
              name={FIELD_NAMES.subVenueOption}
              data-qa={testIds.MATCH_SUB_VENUE_SELECT}
              label={t('Sub-venue')}
              options={formatSubVenueOptions()}
            />
          </Grid>
        )}
      </ModalFieldsBlock>
    </>
  );
};

export default MatchCreateForm;
