import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Paper,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DottedText } from '@core/components';
import { getVenue } from '@core/helpers';
import MatchProfileActions from '@core/pages/match-profile/components/match-profile-actions';

import { Group, MatchModel } from '@core/types';
import { MatchSet } from '@volleyball/types';
import MatchDataHeader from '@volleyball/business-components/match-data-header';

import MatchTabs from './match-tabs';

interface OwnProps {
  competitionId: number;
  group: Group;
  match: MatchModel;
  matchSets: Array<MatchSet>;
  matchDays: Array<number>;
  tabId: string;
  stageId: number | undefined;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.75, 3),
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'column',
    },
  },
  subtitle: {
    fontSize: 12,
  },
  expansionPanel: {
    margin: '0 !important',
  },
  accordionContent: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      display: 'block',
    },
  },
  matchInformation: {
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    '& > *:first-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

const MatchProfile = (props: Props) => {
  const { competitionId, match, group, stageId, tabId, matchSets } = props;
  const classes = useStyles();
  const venue = getVenue(match?.venue);

  const subtitle = [];
  match?.date && match?.time && subtitle.push(`${match.date}, ${match.time}`);
  (venue?.title || venue?.internationalTitle) &&
    subtitle.push(venue.title || venue.internationalTitle);

  return (
    <Paper>
      <Grid container className={classes.root}>
        <MatchProfileActions
          competitionId={competitionId}
          stageId={stageId}
          group={group}
          match={match}
          tabId={tabId}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Accordion
        elevation={0}
        expanded={true}
        className={classes.expansionPanel}
      >
        <AccordionSummary
          className="without-cursor"
          classes={{ content: classes.accordionContent }}
        >
          {/* TODO: JB: on small screens MatchDataHeader will move on top of these dotted texts */}
          <Box
            position="absolute"
            top={15}
            left={0}
            className={classes.matchInformation}
          >
            <DottedText
              color="text.secondary"
              textEntries={[match.competitionTitle, match.seasonTitle]}
            />
            <DottedText
              color="text.secondary"
              textEntries={[match.parentGroupTitle, match.groupTitle]}
            />
          </Box>
          <Grid container justifyContent="center">
            <MatchDataHeader match={match} matchSets={matchSets} noPaper />
          </Grid>
        </AccordionSummary>
      </Accordion>
      <Box pr={3} pl={3}>
        <Grid container>
          <Grid item xs={12}>
            <MatchTabs
              competitionId={competitionId}
              group={group}
              match={match}
              matchId={match.id}
              stageId={stageId}
              matchSets={matchSets}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default MatchProfile;
