import { pathOr } from 'ramda';

import { State } from '@core/store';

import { NAMESPACE } from '.';

export const getCompetitorPlayers = (competitorId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byCompetitorId',
    competitorId,
    'competitorPlayers',
  ]);
export const getClubPlayers = (clubId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byClubId',
    clubId,
    'clubPlayers',
    'players',
  ]);
export const getClubPlayersPagination = (clubId: number) =>
  pathOr(null, ['tabs', NAMESPACE, 'byClubId', clubId, 'clubPlayers', 'list']);
export const getAddedCompetitorPlayers = (competitorId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byCompetitorId',
    competitorId,
    'addedCompetitorPlayers',
  ]);
export const getCompetitorTeamOfficials = (competitorId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byCompetitorId',
    competitorId,
    'competitorTeamOfficials',
  ]);
export const getAddedCompetitorTeamOfficials = (competitorId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byCompetitorId',
    competitorId,
    'addedCompetitorTeamOfficials',
  ]);
export const getClubTeamOfficials = (clubId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byClubId',
    clubId,
    'clubTeamOfficials',
    'officials',
  ]);
export const getClubTeamOfficialsPagination = (clubId: number) =>
  pathOr(null, [
    'tabs',
    NAMESPACE,
    'byClubId',
    clubId,
    'clubTeamOfficials',
    'list',
  ]);
export const getPreviousCompetitorPlayerListTotal = (
  state: State,
  { competitorId }: any,
) =>
  pathOr(
    null,
    [
      'tabs',
      NAMESPACE,
      'byCompetitorId',
      competitorId,
      'previousCompetitorPlayerListTotal',
    ],
    state,
  );
