import restService from '@core/api/rest-service';
import {
  CompetitorPlayerResponse,
  FilesResponse,
  PlayerListPlayer,
  UpdateCompetitorPlayerRequest,
} from '@core/types';

export const getCompetitorPlayers = (
  competitionId: number,
  teamId: number,
  queryParams?: { query: string },
) =>
  restService.get<CompetitorPlayerResponse>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/players`,
    {
      params: queryParams,
    },
  );

export const setCompetitorPlayers = (
  competitionId: number,
  teamId: number,
  data: Array<number>,
) =>
  restService.put<void>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/players`,
    { playerIds: data },
  );

export const patchCompetitorPlayers = (
  competitionId: number,
  teamId: number,
  competitorPlayers: Array<PlayerListPlayer>,
) =>
  restService.patch<void>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/players`,
    { competitorPlayers },
  );

export const updateCompetitorPlayer = ({
  data,
  teamId,
  competitionId,
  playerId,
}: UpdateCompetitorPlayerRequest) =>
  restService.patch(
    `/v1/competitions/${competitionId}/competitors/${teamId}/players/${playerId}`,
    data,
  );

export const getPreviousCompetitorPlayers = (
  competitionId: number,
  teamId: number,
) =>
  restService.get<CompetitorPlayerResponse>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/players/activeOfPreviousCompetitor`,
  );

export const getCompetitorPlayerReport = (
  competitionId: number,
  teamId: number,
) => {
  const href = `/v1/competitions/${competitionId}/competitors/${teamId}/exportList`;

  return restService.post<FilesResponse>(href, {});
};
