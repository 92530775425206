import { FALLBACK_LANGUAGE } from '@core/i18n/constants';

export default {
  name: 'language-detector',

  lookup() {
    switch (navigator.language) {
      case 'es':
      case 'es-ES':
        return 'es-ES';
      case 'fr':
      case 'fr-FR':
        return 'fr-FR';
      case 'ru':
      case 'ru-RU':
        return 'ru-RU';
      default:
        return FALLBACK_LANGUAGE;
    }
  },
};
