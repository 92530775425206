import { isEmpty } from 'ramda';

import { getVenuesById } from '@core/pages/venues/store/api/selectors';
import { State } from '@core/store';

import { NAMESPACE } from './constants';
import { DefaultState } from './reducer';

export const getVenues = (state: State) => {
  const venues: DefaultState['venues'] = { ...state.modals[NAMESPACE].venues };

  return venues && !isEmpty(venues)
    ? { ...venues, list: getVenuesById(state, venues.list) }
    : null;
};
