import { createAction } from 'redux-actions';

import { FileParams } from '@core/components';

export const NAMESPACE = 'pictures';

const uploadPicture = createAction<{
  fileParams: FileParams;
  file: File;
  refreshParams: any;
  documentName?: string;
  documentType?: string;
}>(`${NAMESPACE}/UPLOAD_PICTURE`);

const uploadPictureRequest = createAction<void>(
  `${NAMESPACE}/UPLOAD_PICTURE_REQUEST`,
);

const uploadPictureSuccess = createAction<void>(
  `${NAMESPACE}/UPLOAD_PICTURE_SUCCESS`,
);

const uploadPictureFailure = createAction<void>(
  `${NAMESPACE}/UPLOAD_PICTURE_FAILURE`,
);

const deletePicture = createAction<{
  fileParams: FileParams;
  refreshParams: any;
}>(`${NAMESPACE}/DELETE_PICTURE`);

const deletePictureRequest = createAction<void>(
  `${NAMESPACE}/DELETE_PICTURE_REQUEST`,
);

const deletePictureSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_PICTURE_SUCCESS`,
);

const deletePictureFailure = createAction<void>(
  `${NAMESPACE}/DELETE_PICTURE_FAILURE`,
);

export default {
  uploadPicture,
  uploadPictureRequest,
  uploadPictureSuccess,
  uploadPictureFailure,
  deletePicture,
  deletePictureRequest,
  deletePictureSuccess,
  deletePictureFailure,
};
