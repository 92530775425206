import React from 'react';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prop, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { TableWithAssign, PersonNameColumn } from '@core/components';
import { makeStyles } from '@mui/styles';

import {
  AddedMatchTeamOfficialList,
  AddedMatchTeamOfficial,
  MatchPlayerOrigin,
} from '@core/types';
import { getAddedMatchTeamOfficials } from '@core/store/modules/tabs/match-sheet-management/selectors';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';
import { State } from '@core/store';

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

interface OwnProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  tabId: string;
}

interface StateProps {
  addedTeamOfficials: AddedMatchTeamOfficialList;
}

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  tableTitle: {
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const AddedTeamOfficialsTable = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { tabId, addedTeamOfficials, actions, matchId, matchPlayerOrigin } =
    props;

  const columns = [
    {
      name: 'firstName',
      label: t('Name'),
      render: (rowData: AddedMatchTeamOfficial) => (
        <PersonNameColumn person={rowData.teamOfficial} />
      ),
    },
    {
      name: 'role',
      label: t('Role'),
      render: (rowData: AddedMatchTeamOfficial) =>
        t(pathOr('-', ['teamOfficialRole', 'title'], rowData)),
    },
  ];

  const handleRemoveFromList = (id: number) => {
    const updatedTeamOfficials = addedTeamOfficials?.filter(
      (teamOfficial: AddedMatchTeamOfficial) => teamOfficial.id !== id,
    );

    actions.matchSheetManagement.setAddedTeamOfficials({
      matchId,
      matchPlayerOrigin,
      payload: updatedTeamOfficials,
      tabId,
    });
  };

  const renderTableTitle = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="subtitle1"
          id="tableTitle"
          className={classes.tableTitle}
        >
          {`${t('Added team officials')} (${addedTeamOfficials?.length || 0})`}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} md={6}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box>
          <TableWithAssign
            title={renderTableTitle()}
            columns={columns}
            data={addedTeamOfficials}
            handleRemove={handleRemoveFromList}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const matchId = prop('matchId', ownProps);
  const matchPlayerOrigin = prop('matchPlayerOrigin', ownProps);

  return {
    addedTeamOfficials: getAddedMatchTeamOfficials(state, {
      matchId,
      matchPlayerOrigin,
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddedTeamOfficialsTable);
