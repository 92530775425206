import React, { useState, useEffect } from 'react';
import { Dialog, DialogProps } from '@mui/material';

import { GlobalModalProps } from '@core/types';

import ErrorDialogContent from './components/error-dialog-content';
import ContentDialog from './components/content-dialog';

type OwnProps = Omit<
  React.ComponentProps<typeof ContentDialog>,
  'handleClose' | 'selectedFiles' | 'setSelectedFiles'
> & {
  dialogProps: Omit<DialogProps, 'open' | 'onClose' | 'onExited' | 'fullWidth'>;
  additionalCheck?: React.ReactNode;
};

type Props = GlobalModalProps & OwnProps;

const FileUploadModal = (props: Props) => {
  const {
    isModalOpen,
    modalErrors,
    modalActions,
    dialogProps,
    ...contentProps
  } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    !isModalOpen && setSelectedFiles([]);
  }, [isModalOpen]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={modalActions.closeModal}
      TransitionProps={{ onExited: modalActions.resetState }}
      fullWidth
      {...dialogProps}
    >
      {modalErrors ? (
        <ErrorDialogContent
          selectedFiles={selectedFiles}
          handleClose={modalActions.closeModal}
          setSelectedFiles={setSelectedFiles}
          clearErrors={modalActions.clearModalErrors}
          errors={modalErrors}
        />
      ) : (
        <ContentDialog
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          handleClose={modalActions.closeModal}
          {...contentProps}
        />
      )}
    </Dialog>
  );
};

FileUploadModal.defaultProps = {
  dialogProps: {
    maxWidth: 'md',
  },
};

export default FileUploadModal;
