import React from 'react';
import { Chip, Theme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import { MultiValueProps } from 'react-select';

import { AutocompleteOption } from '@core/types';

import * as testIds from '../tests/test-ids';

type Props = MultiValueProps<AutocompleteOption>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

const MultiValue = (props: Props) => {
  const classes = useStyles();

  return (
    <Chip
      data-qa={testIds.AUTOCOMPLETE_CHIP}
      tabIndex={-1}
      label={props.children}
      className={classes.root}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...(props.removeProps as any)} />}
    />
  );
};

export default MultiValue;
