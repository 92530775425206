import { pathOr, isEmpty } from 'ramda';

import { ClubModel, Paginated } from '@core/types';
import { getClubsById } from '@core/pages/clubs/store/api/selectors';
import { State } from '@core/store';

import { DefaultState } from './reducer';
import { NAMESPACE } from '.';

export const getClubs = (state: State): Paginated<ClubModel> => {
  const clubs: DefaultState['clubs'] = {
    ...pathOr(null, ['modals', NAMESPACE, 'clubs'], state),
  };

  return clubs && !isEmpty(clubs)
    ? { ...clubs, list: getClubsById(state, clubs.list) }
    : null;
};
