import { createActions } from 'redux-actions';

export const NAMESPACE = 'competitorTeamOfficialsList';

export const actions = createActions(
  'GET_COMPETITOR_TEAM_OFFICIALS_REQUEST',
  'GET_COMPETITOR_TEAM_OFFICIALS_SUCCESS',
  'GET_COMPETITOR_TEAM_OFFICIALS_FAILURE',

  'ADD_TEAM_OFFICIALS_TO_COMPETITOR_REQUEST',
  'ADD_TEAM_OFFICIALS_TO_COMPETITOR_SUCCESS',
  'ADD_TEAM_OFFICIALS_TO_COMPETITOR_FAILURE',

  'UPDATE_TEAM_OFFICIALS_FOR_COMPETITOR_REQUEST',
  'UPDATE_TEAM_OFFICIALS_FOR_COMPETITOR_SUCCESS',
  'UPDATE_TEAM_OFFICIALS_FOR_COMPETITOR_FAILURE',

  'UPDATE_COMPETITOR_TEAM_OFFICIAL_REQUEST',
  'UPDATE_COMPETITOR_TEAM_OFFICIAL_SUCCESS',
  'UPDATE_COMPETITOR_TEAM_OFFICIAL_FAILURE',

  'GET_PREVIOUS_COMPETITOR_TEAM_OFFICIALS_REQUEST',
  'GET_PREVIOUS_COMPETITOR_TEAM_OFFICIALS_SUCCESS',
  'GET_PREVIOUS_COMPETITOR_TEAM_OFFICIALS_FAILURE',

  'SET_COMPETITOR_TEAM_OFFICIALS',
  { prefix: NAMESPACE },
) as unknown as {
  getCompetitorTeamOfficialsRequest: RequestLifecycleAction;
  getCompetitorTeamOfficialsSuccess: RequestLifecycleAction;
  getCompetitorTeamOfficialsFailure: RequestLifecycleAction;
  addTeamOfficialsToCompetitorRequest: RequestLifecycleAction;
  addTeamOfficialsToCompetitorSuccess: RequestLifecycleAction;
  addTeamOfficialsToCompetitorFailure: RequestLifecycleAction;
  updateTeamOfficialsForCompetitorRequest: RequestLifecycleAction;
  updateTeamOfficialsForCompetitorSuccess: RequestLifecycleAction;
  updateTeamOfficialsForCompetitorFailure: RequestLifecycleAction;
  updateCompetitorTeamOfficialRequest: RequestLifecycleAction;
  updateCompetitorTeamOfficialSuccess: RequestLifecycleAction;
  updateCompetitorTeamOfficialFailure: RequestLifecycleAction;
  getPreviousCompetitorTeamOfficialsRequest: RequestLifecycleAction;
  getPreviousCompetitorTeamOfficialsSuccess: RequestLifecycleAction;
  getPreviousCompetitorTeamOfficialsFailure: RequestLifecycleAction;
  setCompetitorTeamOfficials: UnknownAction;
};
