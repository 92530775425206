import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { GlobalModalProps } from '@core/types';
import {
  getTerms,
  getTermsPreferredLanguage,
} from '@core/pages/terms-and-conditions/store/selectors';
import termsActions from '@core/pages/terms-and-conditions/store/actions';
import { State } from '@core/store';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import * as i18nActions from '@core/store/modules/i18n/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { FullScreenSpinner } from '@core/components';
import {
  mapTermsLangToLocale,
  AVAILABLE_LANGUAGES,
} from '@core/components/terms-and-conditions/helpers';
import {
  TermsAndConditionsAgree,
  TermsAndConditionsBody,
  TermsAndConditionsControls,
} from '@core/components/terms-and-conditions';
import { AgreementsModel, TermsResponse } from '@core/types/api/terms';

import termsAndConditionsModalStyles from './terms-and-conditions-modal.styles';
import { ButtonWithProgress } from '@ui-components';

const useStyles = termsAndConditionsModalStyles;

interface DispatchProps {
  actions: {
    globalModal: typeof globalModalActions;
    terms: typeof termsActions;
    i18n: typeof i18nActions;
  };
}

interface StateProps {
  terms: TermsResponse;
  preferredLanguage: keyof AgreementsModel;
  isLoading: boolean;
}

interface OwnProps {
  onTermsAccepted: () => void;
  onTermsDeclined: () => void | null;
  isAuthenticated: boolean;
}
type Props = DispatchProps & GlobalModalProps<OwnProps> & StateProps;

const TermsAndConditionsModal = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const [savedLocale, saveLocale] = useState(i18n.language);
  const {
    isLoading,
    isModalOpen = false,
    actions,
    modalParams,
    terms,
    preferredLanguage,
  } = props;
  const {
    onTermsAccepted,
    onTermsDeclined,
    isAuthenticated = false,
  } = modalParams;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  useEffect(() => {
    actions.terms.getTerms();
    saveLocale(i18n.language);

    return () => {
      actions.i18n.changeLanguage(savedLocale);
    };
  }, []);

  useEffect(() => {
    !preferredLanguage && actions.terms.setPreferredLanguage(i18n.language);
  }, [preferredLanguage]);

  const handleCancel = () => {
    actions.globalModal.closeModal();
    onTermsDeclined?.();
  };

  const handleAccept = () => {
    actions.globalModal.closeModal();
    onTermsAccepted();
  };

  const handleCheck = () => {
    setAgreed(!agreed);
  };

  const handleLanguageChange = (language: string) => {
    actions.i18n.changeLanguage(mapTermsLangToLocale(language));
    actions.terms.setPreferredLanguage(language);
  };

  // const handleDownload = () => {
  //   // TODO
  //   // actions.terms.getDocument({ href: terms.link, language: preferredLanguage });
  // };

  return (
    <Dialog
      className={classes.root}
      fullScreen={isMobile}
      disableEscapeKeyDown={!!onTermsDeclined}
      open={isModalOpen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.title}>
        {t('Service description and terms of use')}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box className={classes.loading}>
            <FullScreenSpinner />
          </Box>
        ) : (
          <Box className={classes.bodyContainer}>
            <TermsAndConditionsBody
              terms={terms}
              lang={preferredLanguage}
              hasChanged={isAuthenticated}
            />
          </Box>
        )}
        <TermsAndConditionsAgree agreed={agreed} onChange={handleCheck} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box className={classes.actions} display="flex" padding={1}>
          <Box className={classes.actionControls}>
            <TermsAndConditionsControls
              language={preferredLanguage}
              onChange={handleLanguageChange}
              // TODO: uncomment when backend is ready
              // onDownload={handleDownload}
              languagesAvailable={AVAILABLE_LANGUAGES}
              isLoading={isLoading}
            />
          </Box>
          <Box className={classes.actionButtons} mr={1}>
            <ButtonWithProgress
              className={classes.buttonCancel}
              onClick={handleCancel}
              variant="outlined"
            >
              {isAuthenticated ? t('Logout') : t('Cancel')}
            </ButtonWithProgress>
            <ButtonWithProgress
              disabled={!agreed}
              onClick={handleAccept}
              variant="contained"
            >
              {t('Agree')}
            </ButtonWithProgress>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const loadingSelector = createLoadingSelector([
  termsActions.getTermsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
  terms: getTerms(state),
  preferredLanguage: getTermsPreferredLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    globalModal: bindActionCreators(globalModalActions, dispatch),
    terms: bindActionCreators(termsActions, dispatch),
    i18n: bindActionCreators(i18nActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsModal);

export type TermsAndConditionsModalProps = OwnProps;
