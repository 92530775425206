import { useMemo } from 'react';
import { KnockoutStandingsBrackets } from '@core/types/api/knockout-standing-brackets';
import { useBracketStyle } from './knockout-brackets-style';
import KnockoutBracket from './knockout-bracket';
import KnockoutBracketLine from './knockout-bracket-line';
import {
  calculateMaxRound,
  getTemplateAreas,
  getTemplateColumns,
} from './utils';
import { KNOCKOUT_BRACKET } from '../__tests__/test-ids';

export interface KnockoutBracketsProps {
  data: KnockoutStandingsBrackets;
}

const KnockoutBrackets = ({ data }: KnockoutBracketsProps) => {
  const maxRound = useMemo(() => calculateMaxRound(data.items), [data.items]);
  const classes = useBracketStyle();

  return (
    <div
      className={classes.gridContainer}
      style={{
        gridTemplateAreas: getTemplateAreas(maxRound),
        gridTemplateColumns: getTemplateColumns(maxRound),
      }}
    >
      {data.items.map((bracket, index) => (
        <div
          key={bracket.id}
          className={classes.gridItem}
          style={{ gridArea: 'Box' + index }}
          data-qa={KNOCKOUT_BRACKET}
        >
          <KnockoutBracket data={bracket} />
          <KnockoutBracketLine
            data={bracket}
            currentIndex={index}
            isMaxRound={bracket.round === maxRound}
            numberOfItems={data.items.length}
          />
        </div>
      ))}
    </div>
  );
};

export default KnockoutBrackets;
