import { path, pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import { Paginated, Filters, Sorting, MatchModel } from '@core/types';
import { State } from '@core/store';

import { NAMESPACE } from '.';

const getMatchesUnsorted = (state: State) =>
  pathOr(null, ['api', 'matchesList'], state);
const getMatchesIds = (state: State) =>
  pathOr(null, ['pages', NAMESPACE, 'matches', 'list'], state);

export const getMatchesList = createSelector(
  getMatchesUnsorted,
  getMatchesIds,
  (matchesUnsorted, matchesIds): Array<MatchModel> =>
    matchesIds ? matchesIds.map((id: number) => matchesUnsorted[id]) : [],
);

export const getMatches = (state: State): Paginated<MatchModel['id']> =>
  path(['pages', NAMESPACE, 'matches'], state);

export const getSearchQuery = (state: State): string =>
  path(['pages', NAMESPACE, 'searchQuery'], state);

export const getFilters = (state: State): Filters =>
  path(['pages', NAMESPACE, 'filters'], state);

export const getSorting = (state: State): Sorting =>
  path(['pages', NAMESPACE, 'sorting'], state);
