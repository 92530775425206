import React from 'react';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

import { LanguageDirections } from '@core/types';
import { languageDirection } from '@core/helpers';

const ArrowBack = () => {
  if (languageDirection === LanguageDirections.RTL) return <ArrowForwardIcon />;

  return <ArrowBackIcon />;
};

export default ArrowBack;
