import { Button, CircularProgress, Grid } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { BUTTON_WITH_PROGRESS, BUTTON_WITH_PROGRESS_SPINNER } from './test-ids';

interface OwnProps {
  isLoading?: boolean;
  containerClass?: object | string | null;
}

export type ButtonWithProgressProps = OwnProps & ButtonProps;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  fullWidth: {
    width: '100%',
  },
  progressWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const ButtonWithProgress = ({
  isLoading,
  fullWidth,
  containerClass,
  ...buttonProps
}: ButtonWithProgressProps) => {
  const classes = useStyles();

  const renderSpinner = () => (
    <Grid className={classes.progressWrapper}>
      <CircularProgress data-qa={BUTTON_WITH_PROGRESS_SPINNER} size={24} />
    </Grid>
  );

  return (
    <Grid
      data-qa={BUTTON_WITH_PROGRESS}
      className={clsx(classes.root, containerClass, {
        [classes.fullWidth]: fullWidth,
      })}
    >
      <Button disabled={isLoading} fullWidth {...buttonProps} />
      {isLoading && renderSpinner()}
    </Grid>
  );
};

export default ButtonWithProgress;
