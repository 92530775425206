import { CompetitionStatus } from '@core/types';

export const getCompetitionStatus = (
  t: (key: string) => string,
  competitionStatus: keyof typeof CompetitionStatus,
) => {
  switch (competitionStatus) {
    case CompetitionStatus.DRAFT:
      return t('Draft');
    case CompetitionStatus.SCHEDULED:
      return t('Scheduled');
    case CompetitionStatus.COMPLETE:
      return t('Completed');
    default:
      return competitionStatus;
  }
};
