import { object, number, string } from 'yup';
import { TFunction } from 'i18next';
import { SanctionType, OffenderType } from '@volleyball/types';
import {
  MATCH_SANCTION_FIELDS,
  OFFENDER_TYPE_FIELDS,
  FINANCIAL_PENALTY_FIELDS,
  type FormMode,
} from '../constants';

export const getMatchSuspensionValidationSchema = (
  t: TFunction,
  formMode: FormMode,
) =>
  formMode === 'new'
    ? object().shape({
        [OFFENDER_TYPE_FIELDS.competitor]: object()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.PLAYER,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.player]: number()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.PLAYER,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: object()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.TEAM_OFFICIAL,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.teamOfficial]: number()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.TEAM_OFFICIAL,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [MATCH_SANCTION_FIELDS.numberOfMatches]: number().required(
          t('Required'),
        ),
      })
    : object().shape({
        [MATCH_SANCTION_FIELDS.numberOfMatches]: number().required(
          t('Required'),
        ),
      });

const getFinancialPenaltyValidationSchema = (
  t: TFunction,
  formMode: FormMode,
) =>
  formMode === 'new'
    ? object().shape({
        [OFFENDER_TYPE_FIELDS.competitor]: object()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.PLAYER,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.player]: number()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.PLAYER,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.teamOfficialCompetitor]: object()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.TEAM_OFFICIAL,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [OFFENDER_TYPE_FIELDS.teamOfficial]: number()
          .when([OFFENDER_TYPE_FIELDS.offenderType], {
            is: OffenderType.TEAM_OFFICIAL,
            then: (schema) => schema.required(t('Required')),
          })
          .nullable(),
        [FINANCIAL_PENALTY_FIELDS.amount]: number().required(t('Required')),
        [FINANCIAL_PENALTY_FIELDS.currency]: string().required(t('Required')),
      })
    : object().shape({
        [FINANCIAL_PENALTY_FIELDS.amount]: number().required(t('Required')),
        [FINANCIAL_PENALTY_FIELDS.currency]: string().required(t('Required')),
      });

export const getValidationSchema = (
  formMode: FormMode,
  sanctionType: SanctionType,
  t: TFunction,
) => {
  if (sanctionType === SanctionType.SUSPENSION_FOR_NUMBER_OF_MATCHES) {
    return getMatchSuspensionValidationSchema(t, formMode);
  }
  if (sanctionType === SanctionType.FINANCIAL_PENALTY) {
    return getFinancialPenaltyValidationSchema(t, formMode);
  }

  return undefined;
};
