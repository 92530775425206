import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(0, 3, 3, 3),
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      flex: '1 1 100%',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
  },

  // A PLACEHOLDER FOR VIEW-RELATED STYLES
  view: {},

  calendarLoading: {
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.appBar,
  },
  tableLoading: {
    position: 'absolute',
    top: `calc(${theme.spacing(13)} + 1px)`,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.grey[100],
  },

  // SIDEBAR WITH MATCH LIST
  sidebar: {
    display: 'flex',
    flex: '1 1 100%',
    flexFlow: 'row nowrap',
    justifyContent: 'stretch',
    alignItems: 'stretch',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      flex: '1 1 auto',
      minHeight: theme.spacing(12),
    },
  },

  // MAIN CALENDAR COMPONENT
  calendar: {
    position: 'relative',
    display: 'flex',
    flex: '0 0 100%',

    '&': {
      '@media (min-width: 960px)': { maxWidth: 600 },
      '@media (min-width: 1060px)': { maxWidth: 700 },
      '@media (min-width: 1160px)': { maxWidth: 800 },

      ...Array.from(Array(20).keys()).reduce((acc, index) => {
        const breakpoint = 1280 + index * 50;
        const width = 660 + index * 50;

        return {
          ...acc,
          [`@media (min-width: ${breakpoint}px)`]: { maxWidth: `${width}px` },
        };
      }, {}),
    },

    '@global': {
      '.fc-scrollgrid-sync-table': {
        border: 'none',
        borderSpacing: 0,
        borderCollapse: 'separate',
      },
      '.fc-scrollgrid-sync-table > tbody > tr, .fc-col-header > thead > tr': {
        display: 'flex !important',
        flexFlow: 'row nowrap',
        justifyContent: 'stretch',
        alignItems: 'stretch',
      },
      '.fc-scrollgrid-sync-table td, .fc-col-header th': {
        display: 'flex',
        flex: '1 1 calc(100% / 7)',
        justifyContent: 'center',
        border: `0px solid ${theme.palette.grey[300]}`,
        borderRightWidth: 1,
        borderBottomWidth: 1,

        '&:last-of-type': {
          borderRightColor: 'transparent',
        },
      },
      '.fc-scrollgrid-sync-table td': {
        minHeight: theme.spacing(10),
      },
      '.fc-scrollgrid-sync-table tr:last-of-type td': {
        borderBottomWidth: 1,
        borderBottomColor: 'transparent',
      },
      '.fc-daygrid-day-frame': {
        width: '100%',
      },
      '.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard': {
        display: 'flex',
        flex: '1 1 100%',
      },
      '.fc .fc-toolbar.fc-header-toolbar': {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: '1rem',

        '& .fc-toolbar-title': {
          fontSize: '1rem',
          '&::first-letter': {
            textTransform: 'capitalize',
          },
        },

        '& .fc-button.fc-button-primary': {
          padding: 0,
          background: 'none',
          border: 'none',
          outline: 'none !important',
          boxShadow: 'none !important',
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: 'inherit',
          cursor: 'text',
        },

        '& .fc-toolbar-chunk': {
          '&:nth-child(1)': {
            marginRight: theme.spacing(2),
          },

          '&:nth-child(2)': {
            marginRight: theme.spacing(0.5),

            '& .fc-button': {
              height: 48,
              width: 48,
              borderRadius: 24,
              cursor: 'pointer',
              color: theme.palette.grey['600'],

              '&:hover': {
                backgroundColor: theme.palette.grey['100'],
              },

              '& .fc-icon': {
                fontSize: theme.typography.h4.fontSize,
              },
            },
          },
        },
      },

      '.fc-scrollgrid-section.fc-scrollgrid-section-header': {
        backgroundColor: theme.palette.grey[100],
      },
      '.fc-col-header': {
        borderCollapse: 'separate',
      },
      '.fc-col-header-cell': {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderTopWidth: 0,
        borderLeftWidth: 0,

        '&:last-of-type': {
          borderRightWidth: 0,
        },
      },
      '.fc-col-header-cell .fc-scrollgrid-sync-inner': {
        height: theme.spacing(5),
        lineHeight: `${theme.spacing(3)}`,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
      },
    },
  },

  // DAY CELL
  day: {
    backgroundColor: `${theme.palette.common.white} !important`,
    cursor: 'pointer',

    '&:hover, &:active': {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.93)} !important`,
    },

    // ACTIVE (SELECTED) DAY FRAME
    '& .fc-day-active': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: -1,
      border: `2px solid ${theme.palette.primary.main}`,
    },

    // DAY NUMBERS
    '& .fc-day-number': {
      lineHeight: `${theme.spacing(2.5)}`,
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      borderRadius: theme.spacing(1.25),
      fontSize: theme.typography.caption.fontSize,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '&.fc-day-today': {
      backgroundColor: 'none',
      '& .fc-day-number': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '& .fc-daygrid-day-top': {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: theme.spacing(4),
      opacity: '1 !important',
    },
    '& .fc-daygrid-day-number': {
      position: 'static',
      padding: 0,
    },

    // EVENTS GRID
    '& .fc-daygrid-day-frame .fc-daygrid-day-events': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(16px, 1fr))',
      gridGap: theme.spacing(0.25),
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      margin: ` 0 !important`,

      '&:before': {
        display: 'none',
      },

      '& .fc-daygrid-event-harness': {
        display: 'grid',
        placeItems: 'center',

        '&:before': {
          content: '""',
          display: 'block',
          paddingBottom: '100%',
          gridArea: ' 1 / 1 / 2 / 2',
        },
      },

      '& .fc-daygrid-event-harness-abs': {
        position: 'relative !important',
        visibility: 'visible !important',

        top: '0 !important',
        left: '0 !important',
        right: '0 !important',
        bottom: '0 !important',
      },

      // EVENT BLOCK
      '& .fc-event-main': {
        position: 'static',
      },

      '& .fc-daygrid-event': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 0,
      },
      '& .match-event': {
        border: 'none',
        margin: 0,
        paddingBottom: '100%',
        borderRadius: '12.5%',
        backgroundColor: 'transparent',
      },
    },
  },

  dayLabel: {
    display: 'flex',
  },

  // EVENTS
  event: {
    display: 'flex',
    flex: ' 1 1 100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  eventCompleted: {
    display: 'flex',
    flex: ' 1 1 100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#e0e0e0',

    '&:hover': {
      backgroundColor: '#b4b4b4',
    },
  },
}));
