import { createActions } from 'redux-actions';
import { UserRoleRequest } from '@core/types';

export const NAMESPACE = 'userRoleRequestsList';

export const actions = createActions(
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_REQUEST',
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_SUCCESS',
  'GET_USER_ROLE_REQUESTS_BY_USER_ID_FAILURE',

  'REQUEST_NEW_USER_ROLE_REQUEST',
  'REQUEST_NEW_USER_ROLE_SUCCESS',
  'REQUEST_NEW_USER_ROLE_FAILURE',

  'GET_USER_ROLE_REQUESTS_REQUEST',
  'GET_USER_ROLE_REQUESTS_SUCCESS',
  'GET_USER_ROLE_REQUESTS_FAILURE',

  'APPROVE_USER_ROLE',
  'APPROVE_USER_ROLE_REQUEST',
  'APPROVE_USER_ROLE_SUCCESS',
  'APPROVE_USER_ROLE_FAILURE',

  'REJECT_USER_ROLE',
  'REJECT_USER_ROLE_REQUEST',
  'REJECT_USER_ROLE_SUCCESS',
  'REJECT_USER_ROLE_FAILURE',

  'SET_USER_ROLE_REQUESTS',
  'SET_USER_ROLE_REQUEST',

  {
    prefix: NAMESPACE,
  },
) as unknown as {
  getUserRoleRequestsByUserIdRequest: RequestLifecycleAction;
  getUserRoleRequestsByUserIdSuccess: RequestLifecycleAction;
  getUserRoleRequestsByUserIdFailure: RequestLifecycleAction;

  requestNewUserRoleRequest: RequestLifecycleAction;
  requestNewUserRoleSuccess: RequestLifecycleAction;
  requestNewUserRoleFailure: RequestLifecycleAction;

  getUserRoleRequestsRequest: RequestLifecycleAction;
  getUserRoleRequestsSuccess: RequestLifecycleAction;
  getUserRoleRequestsFailure: RequestLifecycleAction;

  approveUserRole: RequestLifecycleAction;
  approveUserRoleRequest: RequestLifecycleAction;
  approveUserRoleSuccess: RequestLifecycleAction;
  approveUserRoleFailure: RequestLifecycleAction;

  rejectUserRole: RequestLifecycleAction;
  rejectUserRoleRequest: RequestLifecycleAction;
  rejectUserRoleSuccess: RequestLifecycleAction;
  rejectUserRoleFailure: RequestLifecycleAction;

  setUserRoleRequests: IdentityActionFunction<Array<UserRoleRequest>>;
  setUserRoleRequest: IdentityActionFunction<UserRoleRequest>;
};
