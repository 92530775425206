import { pathOr } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import { Group } from '@core/types';
import { State } from '@volleyball/store';
import { NAMESPACE } from './constants';

export const getNamespace = (state: State) => pathOr(null, [NAMESPACE], state);

const getId = (state: State, { groupId }: { groupId: Group['id'] }) => groupId;

export const getGroupStandingSettingsByGroupId = createSelector(
  getNamespace,
  getId,
  (groupStandingsSettings, groupId) =>
    pathOr(null, [groupId], groupStandingsSettings),
);
