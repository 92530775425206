import { formatFilterParams, formatSorting } from '@core/helpers';
import type { Filters, Paginated, Sorting, SortingOptions } from '@core/types';

export const getURLSearchParams = (
  queryState: {
    paginated?: Omit<Paginated<any>, 'list'>;
    filters?: Filters;
    searchQuery?: string;
    sorting?: Sorting;
  },
  queryOverrides: { page: number; limit: number },
  options?: SortingOptions,
): URLSearchParams => {
  const { paginated, filters, searchQuery, sorting } = queryState;
  const { page, limit } = queryOverrides;

  return new URLSearchParams(
    [
      ['page', (page ?? paginated?.page ?? 1).toString()],
      (limit ?? paginated?.limit) && [
        'limit',
        (limit ?? paginated.limit).toString(),
      ],
      searchQuery && ['query', searchQuery],
      sorting && formatSorting(sorting, options),
      ...(filters ? formatFilterParams(filters) : []),
    ].filter(Boolean),
  );
};
