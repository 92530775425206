export enum FIELD_NAMES {
  title = 'title',
  internationalTitle = 'internationalTitle',
  abbreviation = 'abbreviation',
  startDate = 'startDate',
  endDate = 'endDate',
  competitionSeasonId = 'competitionSeasonId',
  sportId = 'sportId',
  gender = 'gender',
  ageGroupId = 'ageGroupId',
  competitionLevelId = 'competitionLevelId',
  participatingTeams = 'participatingTeams',
  clubType = 'clubType',
  geography = 'geography',
}
