import { Country } from '@core/types';
import { State } from '@core/store';

export const getCountriesById = (
  state: State,
  ids: Array<number>,
): Array<Country> => {
  const countries = state.api.countriesList;

  return ids.map((id: number) => countries[id]);
};
