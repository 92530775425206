import type { Sorting, SortingOptions } from '@core/types';

const defaultOptions: SortingOptions = {
  shouldAddTimeToSorting: false,
  dateFieldName: 'date',
  timeFieldName: 'time',
};

export function formatSorting(
  sortingOrder: Sorting,
  options: SortingOptions,
): [string, string] {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  if (
    mergedOptions.shouldAddTimeToSorting &&
    sortingOrder.sortingOrderBy === mergedOptions.dateFieldName
  ) {
    return [
      'sort',
      `${sortingOrder.sortingOrderBy}:${sortingOrder.sortingDirection},${mergedOptions.timeFieldName}:${sortingOrder.sortingDirection}`,
    ];
  }

  return [
    'sort',
    sortingOrder
      ? `${sortingOrder.sortingOrderBy}:${sortingOrder.sortingDirection}`
      : '',
  ];
}
