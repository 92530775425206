import {
  take,
  call,
  put,
  all,
  debounce,
  takeEvery,
  SagaReturnType,
  Effect,
} from 'redux-saga/effects';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { handleApiErrors } from '@core/helpers';
import { getGroupCompetitors, updateGroupCompetitors } from '@core/api/groups';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { getCompetitorsByCompetition } from '@core/store/modules/api/competitors/sagas';
import { Competitor } from '@core/types';
import { api } from '@core/services';

import actions from '.';

function getListOfIds(list: Array<Competitor>) {
  return list.map((item) => item.id);
}

export function* getGroupCompetitorsFlow({
  payload: { groupId },
}: InferAction<typeof actions.getCompetitorsByGroupId>): Generator<
  Effect,
  any,
  any
> {
  yield put(actions.getCompetitorsByGroupIdRequest({ id: groupId }));

  const { response, error } = (yield call(
    getGroupCompetitors,
    groupId,
  )) as InferApi<typeof getGroupCompetitors>;

  if (!error) {
    yield put(actions.getCompetitorsByGroupIdSuccess({ id: groupId }));
    yield put(
      actions.setCompetitorsByGroupId({
        payload: response._embedded.groupCompetitors,
        groupId,
      }),
    );
  } else {
    yield put(actions.getCompetitorsByGroupIdFailure({ id: groupId }));
    yield call(handleApiErrors, error);
  }
}

export function* watchGetGroupCompetitors() {
  yield takeEvery(actions.getCompetitorsByGroupId, getGroupCompetitorsFlow);
}

export function* getCompetitionCompetitors(
  action: InferAction<typeof actions.getCompetitorsByCompetitionId>,
): Generator<Effect, any, any> {
  const {
    payload: { competitionId, ...payload },
  } = action;
  yield put(actions.getCompetitorsByCompetitionIdRequest());

  const { response, error } = (yield call(getCompetitorsByCompetition, {
    competitionId,
    ...payload,
  })) as SagaReturnType<typeof getCompetitorsByCompetition>;

  if (!error) {
    yield put(actions.getCompetitorsByCompetitionIdSuccess());
    yield put(
      actions.setCompetitorsByCompetitionId({
        payload: getListOfIds(response._embedded.competitors),
        competitionId,
      }),
    );
  } else {
    yield put(actions.getCompetitorsByCompetitionIdFailure());
    yield call(handleApiErrors, error);
  }
}

export function* watchSearchCompetitionCompetitors() {
  yield debounce(
    500,
    actions.getCompetitorsByCompetitionId,
    getCompetitionCompetitors,
  );
}

export function* updateGroupCompetitorsFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { groupId, groupCompetitors, tabId, competitionId },
    } = (yield take(actions.updateGroupCompetitors)) as InferAction<
      typeof actions.updateGroupCompetitors
    >;

    yield put(actions.updateGroupCompetitorsRequest());
    const { error } = (yield call(
      updateGroupCompetitors,
      groupId,
      groupCompetitors,
    )) as InferApi<typeof updateGroupCompetitors>;

    if (!error) {
      yield put(actions.updateGroupCompetitorsSuccess());
      yield put(actions.getCompetitorsByGroupId({ groupId }));
      yield put(
        competitionProfileActions.getMatchesByGroup({
          groupId,
          competitionId,
        }),
      );
      yield put(tabsActions.removeTab({ tabId }));
      yield put(api.util.invalidateTags(['Brackets']));
    } else {
      yield put(actions.updateGroupCompetitorsFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([
    watchGetGroupCompetitors(),
    updateGroupCompetitorsFlow(),
    watchSearchCompetitionCompetitors(),
  ]);
}
