import React from 'react';
import { Grid, Typography, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { generatePlayerKitColors } from '@core/factories/kit-set';
import PlayerKitPreview from '@core/components/player-kit-preview';
import { PlayerKitsModel } from '@volleyball/types';

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    background: ({ backgroundColor }) =>
      backgroundColor ? backgroundColor : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: ({ vp = 2, hp = 2, padding }) =>
      padding ? padding : `${theme.spacing(vp)} ${theme.spacing(hp)}`,
    minWidth: ({ minWidth }) => (minWidth ? minWidth : 132),
  },
}));

interface OwnProps {
  label: string;
  playerKitSet: PlayerKitsModel;
  height?: number;
  minWidth?: number;
  disableLabel?: boolean;
  backgroundColor?: string;
  vp?: number;
  hp?: number;
  padding?: string;
}

type Props = OwnProps;

const PlayerKit = (props: Props) => {
  const {
    label,
    playerKitSet,
    height = 170,
    disableLabel = false,
    minWidth,
  } = props;
  const playerKitColors = playerKitSet && generatePlayerKitColors(playerKitSet);
  const classes = useStyles(props);

  return (
    <Grid className={classes.root}>
      {!disableLabel && (
        <Box mb={3}>
          <Typography variant="body2">{label}</Typography>
        </Box>
      )}
      <PlayerKitPreview
        playerKitColors={playerKitColors}
        height={height}
        width={minWidth && minWidth}
      />
    </Grid>
  );
};

export default PlayerKit;
