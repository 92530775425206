import { all, call, put, SagaReturnType } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import { takeLeadingByPredicate } from '@core/store/helpers';
import { getKitSet, updateKitSet } from '@core/store/modules/api/kit-set/sagas';

import { getKitSetMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-kit-set-mutated-successfully-callbacks';

import { actions } from '.';

export function* getKitSetFlow() {
  yield takeLeadingByPredicate(actions.getKitSet, function* (action) {
    const { payload } = action;
    const { kitSetId } = payload;

    yield put(actions.getKitSetRequest({ kitSetId }));

    const { error, response } = (yield call(getKitSet, {
      kitSetId,
    })) as SagaReturnType<typeof getKitSet>;

    if (!error) {
      yield put(actions.setKitSet({ payload: response, kitSetId }));
      yield put(actions.getKitSetSuccess({ kitSetId }));
    } else {
      yield put(actions.getKitSetFailure({ kitSetId }));
      yield call(handleApiErrors, error);
    }
  });
}

export function* updateKitSetFlow() {
  yield takeLeadingByPredicate(actions.updateKitSet, function* (action) {
    const { payload } = action;
    const { data, kitSetId, clubId, competitionId, tabId } = payload;

    yield put(actions.updateKitSetRequest({ kitSetId }));

    const { error } = (yield call(updateKitSet, {
      kitSetId,
      data,
    })) as SagaReturnType<typeof updateKitSet>;

    if (!error) {
      yield put(actions.updateKitSetSuccess({ kitSetId }));
      yield call(
        getKitSetMutatedSuccessfullyCallbacks,
        { clubId, competitionId, kitSetId, modal: null, tabId },
        'update',
      );
    } else {
      yield put(actions.updateKitSetFailure({ kitSetId }));
      yield call(handleApiErrors, error);
    }
  });
}

export default function* saga() {
  yield all([getKitSetFlow(), updateKitSetFlow()]);
}
