import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Box, Grid, IconButton, Typography, Theme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { getCurrentCustomer } from '@core/pages/user-profile/store/selectors';
import {
  MoreActionsButton,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import { useConfirmationDialog } from '@core/hooks';
import { bindActionCreators, Dispatch } from 'redux';
import { getStageSubHeader, formatDate } from '@core/helpers';
import {
  STAGE_PROFILE_DETAILS,
  STAGE_PROFILE_ACTIONS,
  STAGE_PROFILE_DELETE,
  STAGE_PROFILE_SUMMARY_TOGGLE,
} from '@core/pages/competition-profile/tests/test-ids';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { CustomerResponse, Group, StageType } from '@core/types';
import { State } from '@core/store';

interface StateProps {
  currentCustomer: CustomerResponse;
}

interface OwnProps {
  competitionId: number;
  stage: Group;
  expanded?: boolean;
}

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
  };
}

type Props = StateProps & OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    width: 'auto',
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
  },
  icon: {
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
    }),
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
}));

const StageProfilePanelSummary = ({
  actions,
  stage,
  expanded,
  competitionId,
  currentCustomer,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getConfirmation } = useConfirmationDialog();

  const canDeleteStage = !!stage?._links?.delete;

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: t('Delete'),
      message: t('Are you sure you want to delete this stage?'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
    });

    if (confirmed) {
      actions.competitionProfile.deleteGroup({
        competitionId,
        groupId: stage?.id,
      });
    }
  };

  const getInfoBlocks = (): SummaryBlockValues => [
    {
      label: t('Start date'),
      value: stage.startDate,
      dateField: true,
    },
    {
      label: t('End date'),
      value: stage.endDate,
      dateField: true,
    },
    {
      label: t('Teams'),
      value: stage.numberOfTeams,
    },
    {
      label: t('Stage type'),
      value: getStageSubHeader(t, stage.stageType),
    },
  ];

  const expandIconClasses = clsx(classes.icon, {
    [classes.iconExpanded]: expanded,
  });

  const moreActions = [
    {
      title: t('Delete stage'),
      action: handleDelete,
      disabled: !canDeleteStage,
      disabledTooltip: t(
        'The Stage contains matches with statuses, which do not allow deletion.',
      ),
      testId: STAGE_PROFILE_DELETE,
    },
  ];

  return (
    <Grid container data-qa={STAGE_PROFILE_DETAILS}>
      <Box mb={2}>
        <Typography style={{ fontWeight: 700 }} variant="subtitle1">
          {stage.title}
        </Typography>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={8}>
          {!expanded && (
            <Grid container direction="column" className={classes.summary}>
              <Grid item>
                <Typography variant="caption">
                  {formatDate(currentCustomer?.dateFormat, stage.startDate)} —{' '}
                  {formatDate(currentCustomer?.dateFormat, stage.endDate)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  {stage.numberOfTeams} {t('Teams').toLocaleLowerCase()}
                </Typography>
                {' • '}
                <Typography variant="caption">{`${getStageSubHeader(
                  t,
                  stage.stageType,
                )}${
                  stage.stageType === StageType.ROUND_ROBIN &&
                  stage?.childGroups.length
                    ? ' (' + stage.childGroups.length + ' ' + t('pools') + ')'
                    : ''
                }`}</Typography>
              </Grid>
            </Grid>
          )}
          {expanded && <SummaryBlock values={getInfoBlocks()} />}
        </Grid>
        <Grid item md={4} textAlign="right">
          <MoreActionsButton
            actions={moreActions}
            testId={STAGE_PROFILE_ACTIONS}
          />
          <IconButton
            classes={{ root: expandIconClasses }}
            data-qa={STAGE_PROFILE_SUMMARY_TOGGLE}
          >
            <ExpandMore />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const mapStateToProps = (state: State): StateProps => ({
  currentCustomer: getCurrentCustomer(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StageProfilePanelSummary);
