import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { FullScreenSpinner, CustomTable } from '@core/components';
import { RefereeCategoriesList } from '@volleyball/types';

import * as testIds from '../tests/test-ids';

interface OwnProps {
  rows: RefereeCategoriesList;
  isLoading: boolean;
  renderActions: (item: any) => any;
}

type Props = OwnProps;

const RefereeCategoriesTable = (props: Props) => {
  const { t } = useTranslation();
  const { isLoading, rows, renderActions } = props;
  const columns = [
    {
      title: t('Title'),
      field: 'title',
    },
    {
      title: t('Rank'),
      field: 'rank',
    },
    {
      title: t('Description'),
      field: 'description',
    },
    {
      field: 'actions',
      render: (rowData: any) => renderActions(rowData),
    },
  ];

  return isLoading ? (
    <FullScreenSpinner />
  ) : (
    rows && (
      <Grid data-qa={testIds.REFEREE_CATEGORIES_TABLE}>
        <CustomTable noPaper columns={columns} data={rows} />
      </Grid>
    )
  );
};

export default RefereeCategoriesTable;
