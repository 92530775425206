import { createAction } from 'redux-actions';
import { prop, pick } from 'ramda';

import {
  Person,
  PersonRegistrations,
  PlayerStatistic,
  PlayerStatistics,
} from '@core/types';

import { NAMESPACE } from './constants';

export interface setPerson {
  memberId: number;
  payload: Person;
}

export interface setPersonRegistrations {
  memberId: number;
  payload: PersonRegistrations;
}

type SetPlayerStatistics = {
  payload: PlayerStatistics;
  memberId: number;
};

type SetPlayerStatisticsTotal = {
  payload: PlayerStatistic;
  memberId: number;
};

const getPerson = createAction<number>(`${NAMESPACE}/GET_PERSON`);
const getPersonRequest = createAction<void>(`${NAMESPACE}/GET_PERSON_REQUEST`);
const getPersonSuccess = createAction<void>(`${NAMESPACE}/GET_PERSON_SUCCESS`);
const getPersonFailure = createAction<void>(`${NAMESPACE}/GET_PERSON_FAILURE`);
const setPerson = createAction(
  `${NAMESPACE}/SET_PERSON`,
  (data: setPerson): Person => prop('payload', data),
  (data: setPerson): { memberId: number } => pick(['memberId'], data),
);
const getPersonRegistrations = createAction<number>(
  `${NAMESPACE}/GET_PERSON_REGISTRATIONS`,
);
const getPersonRegistrationsRequest = createAction<void>(
  `${NAMESPACE}/GET_PERSON_REGISTRATIONS_REQUEST`,
);
const getPersonRegistrationsSuccess = createAction<void>(
  `${NAMESPACE}/GET_PERSON_REGISTRATIONS_SUCCESS`,
);
const getPersonRegistrationsFailure = createAction<void>(
  `${NAMESPACE}/GET_PERSON_REGISTRATIONS_FAILURE`,
);
const setPersonRegistrations = createAction(
  `${NAMESPACE}/SET_PERSON_REGISTRATIONS`,
  (data: setPersonRegistrations): PersonRegistrations => prop('payload', data),
  (data: setPersonRegistrations): { memberId: number } =>
    pick(['memberId'], data),
);

const getPlayerStatistics = createAction<{ memberId: number; sportId: number }>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS`,
);
const getPlayerStatisticsRequest = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_REQUEST`,
);
const getPlayerStatisticsSuccess = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_SUCCESS`,
);
const getPlayerStatisticsFailure = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_FAILURE`,
);
const setPlayerStatistics = createAction(
  `${NAMESPACE}/SET_PLAYER_STATISTICS`,
  (data: SetPlayerStatistics): PlayerStatistics => prop('payload', data),
  (data: SetPlayerStatistics): { memberId: number } => pick(['memberId'], data),
);

const getPlayerStatisticsTotal = createAction<{
  memberId: number;
  sportId: number;
}>(`${NAMESPACE}/GET_PLAYER_STATISTICS_TOTAL`);
const getPlayerStatisticsTotalRequest = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_TOTAL_REQUEST`,
);
const getPlayerStatisticsTotalSuccess = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_TOTAL_SUCCESS`,
);
const getPlayerStatisticsTotalFailure = createAction<void>(
  `${NAMESPACE}/GET_PLAYER_STATISTICS_TOTAL_FAILURE`,
);
const setPlayerStatisticsTotal = createAction(
  `${NAMESPACE}/SET_PLAYER_STATISTICS_TOTAL`,
  (data: SetPlayerStatisticsTotal): PlayerStatistic => prop('payload', data),
  (data: SetPlayerStatisticsTotal): { memberId: number } =>
    pick(['memberId'], data),
);

export default {
  getPerson,
  getPersonRequest,
  getPersonSuccess,
  getPersonFailure,
  setPerson,
  getPersonRegistrations,
  getPersonRegistrationsRequest,
  getPersonRegistrationsSuccess,
  getPersonRegistrationsFailure,
  setPersonRegistrations,
  getPlayerStatistics,
  getPlayerStatisticsRequest,
  getPlayerStatisticsSuccess,
  getPlayerStatisticsFailure,
  setPlayerStatistics,
  getPlayerStatisticsTotal,
  getPlayerStatisticsTotalRequest,
  getPlayerStatisticsTotalSuccess,
  getPlayerStatisticsTotalFailure,
  setPlayerStatisticsTotal,
};
