import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import { isEmpty } from 'ramda';
import { actions as pictureActions } from '@core/store/modules/api/pictures';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import type { GlobalModalProps, ObjectType } from '@core/types';
import ContentDialog from './components/content-dialog';
import ErrorDialogContent from './components/error-dialog-content';
import { useCoreDispatch } from '@core/hooks';

export type FileParams = {
  fileId?: number;
  objectId: number;
  objectType: ObjectType;
};

type Props = GlobalModalProps<{
  fileParams: FileParams;
  refreshParams: any;
}>;

const uploadingLoadingSelector = createLoadingSelector([
  pictureActions.uploadPictureRequest.toString(),
]);

const PictureUploadModal = ({
  modalParams,
  isModalOpen,
  modalActions,
  modalErrors,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useCoreDispatch();
  const isUploading: boolean = useSelector(uploadingLoadingSelector);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const selectedFile: Array<File> = useMemo(
    () => selectedFiles,
    [selectedFiles],
  );

  const selectedFileIsTooBig =
    !!selectedFiles[0] &&
    !!selectedFiles.find((file: File) => file.size > 25000000);
  const selectedFileExtention = selectedFiles[0]?.name?.split('.').pop();
  const acceptableExtentions = ['png', 'jpeg', 'jpg'];
  const incorrectFileFormat =
    !!selectedFiles[0] && !acceptableExtentions.includes(selectedFileExtention);

  useEffect(() => {
    !isModalOpen && setSelectedFiles([]);
  }, [isModalOpen]);

  useEffect(() => {
    if (!isEmpty(selectedFile) && !selectedFileIsTooBig && !incorrectFileFormat)
      handleFileSelect(selectedFile[0]);
  }, [selectedFile]);

  const handleFileSelect = (file: File) => {
    const { fileParams, refreshParams } = modalParams;

    dispatch(
      pictureActions.uploadPicture({
        file,
        fileParams,
        refreshParams,
      }),
    );
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={modalActions.closeModal}
      TransitionProps={{ onExited: modalActions.resetState }}
      fullWidth
      maxWidth="sm"
    >
      {modalErrors || selectedFileIsTooBig || incorrectFileFormat ? (
        <ErrorDialogContent
          selectedFiles={selectedFiles}
          handleClose={modalActions.closeModal}
          setSelectedFiles={setSelectedFiles}
          clearErrors={modalActions.clearModalErrors}
          errors={modalErrors}
          selectedFileIsTooBig={selectedFileIsTooBig}
          incorrectFileFormat={incorrectFileFormat}
        />
      ) : (
        <ContentDialog
          title={t('Upload picture')}
          handleFileSelect={handleFileSelect}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          handleClose={modalActions.closeModal}
          isLoading={isUploading}
          accept="image/png, image/jpeg, image/jpg"
          {...rest}
        />
      )}
    </Dialog>
  );
};

export default PictureUploadModal;
