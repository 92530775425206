import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';

import { actions } from '.';

type DefaultState = any;

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setCountries.toString()]: (state, { payload }) => ({
      ...state,
      ...convertArrayToObject(payload),
    }),
  },
  defaultState,
);

export default reducer;
