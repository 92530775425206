import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import {
  ContentBlock,
  ValueWithSubvalue,
  NamedValueColumn,
} from '@core/components';
import { Competitor, TeamModel } from '@core/types';

interface OwnProps {
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
}

type Props = OwnProps;

const TeamDetailsBlock = (props: Props) => {
  const { competitorDetails } = props;
  const { t } = useTranslation();

  const valueWithInternationalName = (
    name: string,
    internationalName: string,
  ) => <ValueWithSubvalue value={name} subvalue={internationalName} />;

  return (
    <ContentBlock title={t('Team details')}>
      <Grid xs={12} sm={4} item>
        <NamedValueColumn
          label={t('Club')}
          value={valueWithInternationalName(
            competitorDetails?.team?.club?.title || '-',
            competitorDetails?.team?.club?.internationalTitle || '-',
          )}
        />
      </Grid>
    </ContentBlock>
  );
};

export default TeamDetailsBlock;
