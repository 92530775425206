import React, { useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { compose, map, omit } from 'ramda';

import {
  ArrowBack,
  ArrowForward,
  FullScreenSpinner,
  HeaderStepper,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { SUBMIT_BUTTON } from '@core/constants/test-ids';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import * as competitionActions from '@core/pages/competitions/store/actions';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import StageGeneralInformationTemplate, {
  FIELD_NAMES,
  FIELDS_TO_OMIT_SYNTHETIC,
  FormValues as StageGeneralInformationValues,
} from '@core/components/stage-general-information-template';
import {
  Competition,
  DoubleEliminationType,
  EliminationType,
  Group,
  StageType,
} from '@core/types';
import { actions as groupsActions } from '@core/store/modules/pages/create-stage';
import { getActiveStep } from '@core/store/modules/pages/create-stage/selectors';

import StageSettingsTemplate, {
  FIELD_NAMES as SETTINGS_FIELD_NAMES,
  FormValues as StageSettingsValues,
} from '@volleyball/business-components/stage-settings-template';

import { CREATE_STAGE_STEP_LABELS } from './constants';
import { ButtonWithProgress } from '@ui-components';

interface DispatchProps {
  actions: {
    competitions: typeof competitionActions;
    groups: typeof groupsActions;
    tabs: typeof tabsActions;
  };
}

interface StateProps {
  activeStep?: number;
  competition: Competition;
  isSubmitting: boolean;
  isLoading: boolean;
}

interface OwnProps {
  competitionId: number;
  tabId: string;
}

type Props = DispatchProps & StateProps & OwnProps;

const CreateStage = ({
  actions,
  activeStep,
  isSubmitting,
  isLoading,
  competition,
  tabId,
}: Props) => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  const [stageOneData, setStageOneData] = useState({});

  useEffect(
    () => () => {
      actions.groups.setActiveStep(0);
    },
    [],
  );

  const steps = [
    {
      title: CREATE_STAGE_STEP_LABELS.GENERAL,
    },
    {
      title: CREATE_STAGE_STEP_LABELS.SETTINGS,
    },
  ];

  const handleCancel = () => actions.tabs.removeTab({ tabId });

  const handlePrevious = () => actions.groups.setActiveStep(0);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const omitKeys = compose(map, omit);

  const submitForm = (
    values: StageGeneralInformationValues & StageSettingsValues,
  ) => {
    if (activeStep === 0) {
      let numberOfTeams = values?.numberOfTeams;

      const thirdPlaceMatch = values[FIELD_NAMES.thirdPlaceMatch];
      delete values[FIELD_NAMES.thirdPlaceMatch];

      // get childGroups based on stage type
      if (values[FIELD_NAMES.stageType] === StageType.ROUND_ROBIN) {
        values[FIELD_NAMES.childGroups] = values[FIELD_NAMES.roundRobinGroups];
      } else if (
        values[FIELD_NAMES.stageType] === StageType.KNOCK_OUT &&
        values[FIELD_NAMES.knockOutElimination] === EliminationType.SINGLE
      ) {
        values[FIELD_NAMES.childGroups] = [
          ...values[FIELD_NAMES.knockOutExtraGroups],
          ...values[FIELD_NAMES.knockoutSingleEliminationGroups],
        ];
        values[FIELD_NAMES.thirdPlaceMatch] = thirdPlaceMatch;
      } else if (
        values[FIELD_NAMES.stageType] === StageType.KNOCK_OUT &&
        values[FIELD_NAMES.knockOutElimination] === EliminationType.DOUBLE &&
        values[FIELD_NAMES.knockoutDoubleElimination] ===
          DoubleEliminationType.CLASSIC
      ) {
        values[FIELD_NAMES.childGroups] = [
          ...values[FIELD_NAMES.knockOutDoubleEliminationLosersGroups],
          ...values[FIELD_NAMES.knockOutDoubleEliminationWinnersGroups],
          ...values[FIELD_NAMES.knockOutExtraGroups],
        ];
      } else if (
        values[FIELD_NAMES.stageType] === StageType.KNOCK_OUT &&
        values[FIELD_NAMES.knockOutElimination] === EliminationType.DOUBLE &&
        values[FIELD_NAMES.knockoutDoubleElimination] ===
          DoubleEliminationType.CROSSOVER
      ) {
        values[FIELD_NAMES.childGroups] = [
          ...values[FIELD_NAMES.knockOutDoubleEliminationCrossOverGroups],
          ...values[FIELD_NAMES.knockOutDoubleEliminationLosersGroups],
          ...values[FIELD_NAMES.knockOutDoubleEliminationWinnersGroups],
          ...values[FIELD_NAMES.knockOutExtraGroups],
        ];
      }

      // set number of teams if number of pools is not 0
      if (
        values[FIELD_NAMES.stageType] === StageType.ROUND_ROBIN &&
        values[FIELD_NAMES.numberOfPools]
      ) {
        numberOfTeams = values[FIELD_NAMES.childGroups]?.reduce(
          (number: number, group: Group) => number + group.numberOfTeams,
          0,
        );
      }

      values = {
        ...omit(FIELDS_TO_OMIT_SYNTHETIC, values),
        numberOfTeams,
        eliminationType:
          values[FIELD_NAMES.stageType] === StageType.KNOCK_OUT
            ? values[FIELD_NAMES.knockOutElimination]
            : undefined,
        // @ts-ignore
        childGroups: omitKeys(
          ['id', 'index', 'tableData', 'description'].filter(Boolean),
        )(values.childGroups),
      };

      setStageOneData(values);

      actions.groups.setActiveStep(1);
    } else if (activeStep === 1) {
      actions.groups.createGroup({
        tabId,
        competitionId: competition.id,
        data: {
          ...omit(Object.values(SETTINGS_FIELD_NAMES), stageOneData),
          settings: {
            numberOfSets: values[SETTINGS_FIELD_NAMES.numberOfSets],
            goldenSetRule: values[SETTINGS_FIELD_NAMES.goldenSetRule],
            goldenMatchRule: values[SETTINGS_FIELD_NAMES.goldenMatchRule],
            pointsToWinInGoldenMatchSet:
              values[SETTINGS_FIELD_NAMES.pointsToWinInGoldenMatchSet],
            pointsToWinInGoldenSet:
              values[SETTINGS_FIELD_NAMES.pointsToWinInGoldenSet],
            pointsToWinInRegularSet:
              values[SETTINGS_FIELD_NAMES.pointsToWinInRegularSet],
            pointsToWinInTiebreakerSet:
              values[SETTINGS_FIELD_NAMES.pointsToWinInTiebreakerSet],
            // @ts-ignore
            matchOfficialsSettings: {
              ...values[SETTINGS_FIELD_NAMES.matchOfficialsSettings].map(
                (matchOfficialSettings) => ({
                  roleId: matchOfficialSettings.roleId,
                  groupId:
                    matchOfficialSettings.groupId > 0
                      ? matchOfficialSettings.groupId
                      : null,
                }),
              ),
            },
          },
        },
      });
    }
  };

  const initialGeneralValues = {
    ...(competition?.startDate && { startDate: competition.startDate }),
    ...stageOneData,
  } as Group;

  const summaryBlockValues: SummaryBlockValues = [
    {
      label: t('Competition'),
      value: competition?.title,
    },
    {
      label: t('Season'),
      value: competition?.competitionSeasonTitle,
    },
  ];

  return isLoading ? (
    <FullScreenSpinner />
  ) : (
    <>
      <Paper>
        <Box p={3} mb={2}>
          <Box mb={2}>
            <Typography variant="h6">{t('Create stage')}</Typography>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={12} md={8}>
              <SummaryBlock values={summaryBlockValues} />
            </Grid>
            <Grid item xs={12} md={4}>
              <HeaderStepper steps={steps} activeStep={activeStep} />
            </Grid>
          </Grid>
          <Box mt={3} ml={-3} mr={-3} mb={3}>
            <Divider orientation="horizontal" />
          </Box>
          {activeStep === 0 && (
            <StageGeneralInformationTemplate
              ref={formRef}
              initialValues={initialGeneralValues}
              onSubmit={submitForm}
            />
          )}
          {activeStep === 1 && (
            <StageSettingsTemplate
              ref={formRef}
              initialValues={initialGeneralValues}
              onSubmit={submitForm}
              competition={competition}
            />
          )}
        </Box>
      </Paper>
      <Grid container justifyContent="flex-end">
        <Box mt={1} display="flex">
          <Box mr={1}>
            <Button
              variant="outlined"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
          </Box>
          {activeStep === 1 && (
            <>
              <Box mr={1}>
                <Button
                  variant="outlined"
                  disabled={isSubmitting}
                  startIcon={<ArrowBack />}
                  onClick={handlePrevious}
                >
                  {t('Previous')}
                </Button>
              </Box>
              <ButtonWithProgress
                variant="contained"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
                data-qa={SUBMIT_BUTTON}
              >
                {t('Create')}
              </ButtonWithProgress>
            </>
          )}
          {activeStep === 0 && (
            <ButtonWithProgress
              variant="contained"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={handleSubmit}
              endIcon={<ArrowForward />}
            >
              {t('Next')}
            </ButtonWithProgress>
          )}
        </Box>
      </Grid>
    </>
  );
};
const isSubmittingSelector = createLoadingSelector([
  groupsActions.updateGroupRequest.toString(),
  groupsActions.createGroupRequest.toString(),
  groupsActions.updateGroupSettingsRequest.toString(),
]);

const getLoadingSelector = createLoadingSelector([
  competitionProfileActions.getCompetitionDetailsRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  activeStep: getActiveStep(state),
  competition: getCompetitionDetailsById(ownProps?.competitionId)(state),
  isSubmitting: isSubmittingSelector(state),
  isLoading: getLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitions: bindActionCreators(competitionActions, dispatch),
    groups: bindActionCreators(groupsActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStage);
