import React from 'react';
import { Grid, Badge, Grow, Tooltip, Box, Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { uuid } from '@core/helpers';
import { GroupAddOutlined, Lock, PriorityHigh } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createURL } from '@core/helpers';
import { MANAGE_BUTTON } from '@core/constants/test-ids';
import { MatchPlayerOrigin } from '@core/types';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import paths from '@core/routes/paths';

interface OwnProps {
  competitorId: number;
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  canManage: boolean;
  tooltipText?: string;
  areNoPersons: boolean;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabActions;
    globalModal: typeof globalModalActions;
  };
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  badgeRoot: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  badgeColorError: {
    backgroundColor: theme.palette.warning.main,
    '& > svg': {
      fontSize: theme.spacing(2),
    },
  },
}));

const MatchLineupActionsBlock = (props: Props) => {
  const {
    actions,
    competitorId,
    matchId,
    matchPlayerOrigin,
    canManage,
    tooltipText,
    areNoPersons,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleManageLineupClick = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.manageMatchSheet),
      props: { matchPlayerOrigin, matchId },
      id: uuid(),
      title: `${t('Manage match lineup')} ${competitorId}`,
    });
  };

  const renderManageButton = () => (
    <Badge
      classes={{
        badge: classes.badgeRoot,
        colorError: classes.badgeColorError,
      }}
      badgeContent={<Grow in={true}>{<PriorityHigh />}</Grow>}
      color="error"
      invisible={!areNoPersons}
    >
      <Button
        disabled={!canManage}
        color="primary"
        variant="contained"
        onClick={handleManageLineupClick}
        startIcon={canManage ? <GroupAddOutlined /> : <Lock />}
        className={classes.button}
        data-qa={MANAGE_BUTTON}
      >
        {t('Manage')}
      </Button>
    </Badge>
  );

  return (
    <Box display={'flex'}>
      {tooltipText ? (
        <Tooltip title={tooltipText} placement="bottom" arrow>
          <Grid>{renderManageButton()}</Grid>
        </Tooltip>
      ) : (
        renderManageButton()
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabActions, dispatch),
    globalModal: bindActionCreators(globalModalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MatchLineupActionsBlock);
