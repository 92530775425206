import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uuid } from '@core/helpers';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  TabTitle,
  ContentBlock,
  CustomTable,
  NamedValueColumn,
} from '@core/components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { getMatchOfficialRoleType, createURL } from '@core/helpers';
import { STAGE_PROFILE_EDIT_DETAILS_BUTTON } from '@core/pages/competition-profile/tests/test-ids';
import { Column } from '@core/components/material-table';
import { Group, MatchOfficialSettings, StageType } from '@core/types';
import paths from '@volleyball/routes/paths';

interface OwnProps {
  stage: Group;
  competitionId: number;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

type Props = OwnProps & DispatchProps;

const SettingsTab = ({ stage, competitionId, actions }: Props) => {
  const { t } = useTranslation();
  const canEditStage = !!stage?._links?.patch;

  const handleEdit = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.stageSettingsEdit),
      props: {
        stageId: stage.id,
        competitionId,
      },
      id: uuid(),
    });

  const tabActions = canEditStage && (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleEdit}
      data-qa={STAGE_PROFILE_EDIT_DETAILS_BUTTON}
      startIcon={<EditOutlinedIcon />}
    >
      {t('Edit')}
    </Button>
  );

  const columns: Array<Column<MatchOfficialSettings>> = [
    {
      title: t('Match official'),
      render: (rowData) => getMatchOfficialRoleType(t, rowData?.role),
    },
    {
      title: t('Group'),
      render: (rowData) => rowData?.group?.title,
    },
  ];

  return (
    <>
      <TabTitle
        actions={tabActions}
        rootStyles={{ marginTop: tabActions ? 24 : 0 }}
      />
      <ContentBlock title={t('Match Details')}>
        <Grid xs={12} sm={4} item>
          <NamedValueColumn
            label={t('Number of sets')}
            value={stage.settings?.numberOfSets}
          />
        </Grid>
        <Grid xs={12} sm={4} item>
          <NamedValueColumn
            label={t('Points to win')}
            value={
              stage.settings
                ? `${stage.settings?.pointsToWinInRegularSet} ${t(
                    'pts (R)',
                  )} • ${stage.settings?.pointsToWinInTiebreakerSet} ${t(
                    'pts (T)',
                  )}`
                : null
            }
          />
        </Grid>
        {stage.stageType === StageType.KNOCK_OUT &&
          stage.settings?.goldenSetRule && (
            <Grid xs={12} item>
              <NamedValueColumn
                label={t('Points to win golden set')}
                value={stage.settings?.pointsToWinInGoldenSet}
              />
            </Grid>
          )}
        {stage.stageType === StageType.KNOCK_OUT &&
          stage.settings?.goldenMatchRule && (
            <Grid xs={12} item>
              <NamedValueColumn
                label={t('Points to win golden match')}
                value={stage.settings?.pointsToWinInGoldenMatchSet}
              />
            </Grid>
          )}
      </ContentBlock>
      <ContentBlock title={t('Match officials')}>
        <Grid xs={12} sm={10} item mb={2}>
          <CustomTable
            columns={columns}
            data={stage.settings?.matchOfficialsSettings}
          />
        </Grid>
      </ContentBlock>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(SettingsTab);
