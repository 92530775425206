import { isEmpty } from 'ramda';
import { getCompetitionSeasonsById } from '@core/pages/seasons/store/api/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { NAMESPACE } from './constants';
import actions from './actions';
import type { Paginated, Season } from '@core/types';
import type { State } from '@core/store';

export const getSeasons = (state: State): Paginated<Season> => {
  const seasons = {
    ...state.modals[NAMESPACE].seasons,
  };

  return !isEmpty(seasons)
    ? { ...seasons, list: getCompetitionSeasonsById(state, seasons.list) }
    : null;
};

export const areSeasonsLoading = createLoadingSelector([
  actions.getSeasonsRequest.toString(),
]);
