import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { VenueSearchField } from '@core/components';
import {
  VenueModel,
  Paginated,
  COMPETITOR_GENERAL_INFO_FIELDS,
} from '@core/types';
import { actions as competitorGeneralInformationEditActions } from '@core/store/modules/tabs/competitor-general-information-edit';
import { getDefaultVenueOptions } from '@core/store/modules/tabs/competitor-general-information-edit/selectors';
import { State } from '@core/store';

interface DispatchProps {
  actions: {
    competitorEdit: typeof competitorGeneralInformationEditActions;
  };
}

interface OwnProps {
  competitorId: number;
}

interface StateProps {
  options: Paginated<VenueModel>;
}

type Props = DispatchProps & OwnProps & StateProps;

const DefaultVenueSearch = (props: Props) => {
  const { t } = useTranslation();
  const { actions, competitorId, options } = props;

  useEffect(() => {
    actions.competitorEdit.getDefaultVenues({
      page: 1,
      competitorId,
    });
  }, [actions.competitorEdit, competitorId]);

  const handleInputChange = (query: string) => {
    actions.competitorEdit.searchDefaultVenues({
      page: 1,
      query,
      competitorId,
    });
  };

  const handleLoadNext = ({ query, page }: { query: string; page: number }) => {
    actions.competitorEdit.getDefaultVenues({
      page,
      query,
      competitorId,
    });
  };

  return (
    <VenueSearchField
      name={COMPETITOR_GENERAL_INFO_FIELDS.defaultVenueOption}
      label={t('Default team venue')}
      onInputChange={handleInputChange}
      options={options}
      onLoadNext={handleLoadNext}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorEdit: bindActionCreators(
      competitorGeneralInformationEditActions,
      dispatch,
    ),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  options: getDefaultVenueOptions(state, {
    competitorId: ownProps?.competitorId,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultVenueSearch);
