import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { SET_STEP, RESET_COMPETITION_CREATION } from './constants';

export interface CompetitionCreationState {
  currentStep: number;
}

function getInitialState(): CompetitionCreationState {
  return {
    currentStep: null,
  };
}

export default handleActions(
  {
    [SET_STEP]: (state, { payload }) => assoc('currentStep', payload, state),
    [RESET_COMPETITION_CREATION]: () => ({ currentStep: null }),
  },
  getInitialState(),
);
