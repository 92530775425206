import { handleAction, combineActions } from 'redux-actions';

import * as uiActions from './actions';

export interface State {
  isMenuOpen: boolean;
}

const defaultState: State = { isMenuOpen: false };

const reducer = handleAction(
  combineActions(uiActions.toggleMenu),
  {
    next: (state) => ({ ...state, isMenuOpen: !state.isMenuOpen }),
    throw: (state) => ({ ...state }),
  },
  defaultState,
);

export default reducer;
