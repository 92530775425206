import { createActions } from 'redux-actions';

export const NAMESPACE = 'matchOfficialsList';

export const actions = createActions(
  'GET_MATCH_OFFICIALS_REQUEST',
  'GET_MATCH_OFFICIALS_SUCCESS',
  'GET_MATCH_OFFICIALS_FAILURE',

  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_REQUEST',
  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_SUCCESS',
  'GET_MATCH_OFFICIAL_LIST_BY_PERSON_FAILURE',

  'CREATE_MATCH_OFFICIAL_REQUEST',
  'CREATE_MATCH_OFFICIAL_SUCCESS',
  'CREATE_MATCH_OFFICIAL_FAILURE',

  'DELETE_MATCH_OFFICIAL_REQUEST',
  'DELETE_MATCH_OFFICIAL_SUCCESS',
  'DELETE_MATCH_OFFICIAL_FAILURE',

  'SET_MATCH_OFFICIALS',
  { prefix: NAMESPACE },
) as unknown as {
  getMatchOfficialsRequest: RequestLifecycleAction;
  getMatchOfficialsSuccess: RequestLifecycleAction;
  getMatchOfficialsFailure: RequestLifecycleAction;
  getMatchOfficialListByPersonRequest: RequestLifecycleAction;
  getMatchOfficialListByPersonSuccess: RequestLifecycleAction;
  getMatchOfficialListByPersonFailure: RequestLifecycleAction;
  createMatchOfficialRequest: RequestLifecycleAction;
  createMatchOfficialSuccess: RequestLifecycleAction;
  createMatchOfficialFailure: RequestLifecycleAction;
  deleteMatchOfficialRequest: RequestLifecycleAction;
  deleteMatchOfficialSuccess: RequestLifecycleAction;
  deleteMatchOfficialFailure: RequestLifecycleAction;
  setMatchOfficials: UnknownAction;
};
