import React, { useEffect } from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { uuid } from '@core/helpers';

import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { AutocompleteField } from '@core/components';
import { createURL, formatOptions } from '@core/helpers';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { getTeamsSearchList } from '@core/store/modules/tabs/competitor-addition/selectors';
import { actions as competitorAdditionActions } from '@core/store/modules/tabs/competitor-addition';
import { CompetitionResponse, TeamListModel } from '@core/types';
import paths from '@core/routes/paths';
import { State } from '@core/store';

import TeamOption from './team-option';
import TeamValueContainer from './team-value-container';
import * as testIds from '../../tests/tests-ids';

interface StateProps {
  teams: TeamListModel;
  isTeamsLoading: boolean;
  competition: CompetitionResponse;
}

interface DispatchProps {
  actions: {
    competitorAddition: typeof competitorAdditionActions;
    tabs: typeof tabsActions;
  };
}

interface OwnProps {
  competitionId: number;
}

type Props = OwnProps & DispatchProps & StateProps;

const TeamPanel = (props: Props) => {
  const { actions, teams, competitionId, isTeamsLoading, competition } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (competitionId) {
      actions.competitorAddition.getTeams({
        queryParams: {
          query: '',
          'filter[candidatesForCompetition]': competitionId,
        },
        competitionId,
      });
    }
  }, [actions.competitorAddition, competitionId]);

  const onFocus = () => {
    if (!teams && !isTeamsLoading) {
      actions.competitorAddition.getTeams({
        queryParams: {
          query: '',
          'filter[candidatesForCompetition]': competitionId,
        },
        competitionId,
      });
    }
  };

  const onInputChange = (query: string) =>
    actions.competitorAddition.getTeams({
      queryParams: {
        query,
        'filter[candidatesForCompetition]': competitionId,
      },
      competitionId,
    });

  const onTeamSelect = (teamId: any) => {
    !teamId &&
      actions.competitorAddition.clearCompetitorState({ competitionId });
    const selectedTeamById = teams?.find((team) => team.id === teamId);

    actions.competitorAddition.setSelectedTeam({
      competitionId,
      data: selectedTeamById,
    });
  };

  const handleCreateTeam = () =>
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.createTeam),
      id: uuid(),
      props: { competition, lockSelects: true },
    });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        data-qa={testIds.COMPETITOR_ASSIGNMENT_SELECT_TEAM_INPUT}
      >
        <AutocompleteField
          name="team"
          textFieldProps={{ label: t('Select team') }}
          options={formatOptions(null, teams)}
          onInputChange={onInputChange}
          onChange={onTeamSelect}
          onFocus={onFocus}
          isLoading={isTeamsLoading}
          withTooltipMargin={false}
          customComponents={{
            Option: TeamOption,
            SingleValue: TeamValueContainer,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2} mb={3}>
          <Box mr={1}>
            <Typography variant="body2" color="textSecondary">
              {t(`Can't find a team? Probably it's not created yet.`)}
            </Typography>
          </Box>
          <Typography variant="body2">
            <Link
              data-qa={testIds.COMPETITOR_ASSIGNMENT_CREATE_TEAM_BUTTON}
              onClick={handleCreateTeam}
            >
              {t('Create team')}
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorAddition: bindActionCreators(competitorAdditionActions, dispatch),
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

const isTeamsLoadingSelector = createLoadingSelector([
  competitorAdditionActions.getTeamsRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  teams: getTeamsSearchList(state, { competitionId: ownProps.competitionId }),
  isTeamsLoading: isTeamsLoadingSelector(state),
  competition: getCompetitionDetailsById(ownProps.competitionId)(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamPanel);
