import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Grow,
  Box,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Button,
  Grid,
  CircularProgress,
  ButtonGroup,
  ButtonProps,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

export interface OptionProps<T> {
  text: string;
  disabled?: boolean;
  value: T;
}

interface OwnProps<T> {
  children: string;
  options: Array<OptionProps<T>>;
  selected?: T;
  isLoading?: boolean;
  maxWidthMobile?: boolean;
  disabled?: boolean;
  onSelect: (value: T) => void;
}

type Button = Omit<ButtonProps, 'onSelect' | 'children'>;
type Props<T> = Button & OwnProps<T>;

const useStyles = makeStyles<Theme, Props<any>>((theme: Theme) => ({
  paper: {
    zIndex: theme.zIndex.modal,
  },
  wrapper: {
    position: 'relative',
  },
  maxWidthMobile: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  buttonText: {
    textTransform: 'none',
  },
}));

type Value = string | number | any;

export default function ButtonWithDropdown<T extends Value>(props: Props<T>) {
  const {
    children,
    options,
    onSelect,
    selected,
    variant = 'contained',
    isLoading,
    disabled,
    maxWidthMobile,
    ...buttonProps
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles(props);

  const handleMenuItemClick =
    (val: T) => (_: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setOpen(false);
      onSelect && onSelect(val);
    };

  const handleToggle = (event: any) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid
        item
        xs={12}
        className={maxWidthMobile ? classes.maxWidthMobile : ''}
      >
        <Grid className={classes.wrapper}>
          <ButtonGroup
            className={maxWidthMobile ? classes.maxWidthMobile : ''}
            variant={variant}
            color="primary"
            ref={anchorRef}
          >
            <Button
              variant={variant}
              className={clsx(
                classes.buttonText,
                maxWidthMobile ? classes.maxWidthMobile : '',
              )}
              {...buttonProps}
              disabled={!options || isLoading || disabled}
              color="primary"
              onClick={handleToggle}
              endIcon={<ArrowDropDown />}
            >
              {children}
              {isLoading && (
                <Box ml={2} mb={-1}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Button>
          </ButtonGroup>
          <Popper
            className={classes.paper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options &&
                        options.map(
                          (option: OptionProps<T>) =>
                            option && (
                              <MenuItem
                                key={option.value.toString()}
                                disabled={option.disabled}
                                selected={option.value === selected}
                                onClick={
                                  option.value !== selected
                                    ? handleMenuItemClick(option.value)
                                    : null
                                }
                              >
                                {option.text}
                              </MenuItem>
                            ),
                        )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
}
