import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReportProblemOutlined } from '@mui/icons-material';

interface Props {
  size?: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  indicator: {
    display: 'flex',
    width: 24,
    height: 24,
    color: theme.palette.warning.main,
    marginRight: 12,
  },
  small: {
    display: 'flex',
    width: 20,
    height: 20,
    color: theme.palette.warning.main,
    marginRight: 8,
  },
}));

const FlowIndicator = ({ size = 'large' }: Props) => {
  const classes = useStyles();

  return (
    <ReportProblemOutlined
      className={size === 'large' ? classes.indicator : classes.small}
    />
  );
};

export default FlowIndicator;
