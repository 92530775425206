import { createAction } from 'redux-actions';
import { prop, pick } from 'ramda';

import { DisciplinaryRuleSubmitData } from '@core/types';

export const NAMESPACE = 'competitionCreationTab';

interface GetPayload {
  competitionId: number;
}

interface GetOnePayload extends GetPayload {
  ruleId: number;
}

interface CreatePayload {
  competitionId: number;
  data: DisciplinaryRuleSubmitData;
}

interface UpdatePayload extends CreatePayload {
  ruleId: number;
}

interface DeletePayload extends GetPayload {
  ruleId: number;
}

type SetPayload = Array<DisciplinaryRuleSubmitData>;

type SetOnePayload = DisciplinaryRuleSubmitData;

interface Meta {
  competitionId?: number;
  ruleId?: number;
}

const getDisciplinaryRules = createAction<GetPayload>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULES`,
);
const getDisciplinaryRulesRequest = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULES_REQUEST`,
);
const getDisciplinaryRulesSuccess = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULES_SUCCESS`,
);
const getDisciplinaryRulesFailure = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULES_FAILURE`,
);

const getDisciplinaryRule = createAction<GetOnePayload>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULE`,
);
const getDisciplinaryRuleRequest = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULE_REQUEST`,
);
const getDisciplinaryRuleSuccess = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULE_SUCCESS`,
);
const getDisciplinaryRuleFailure = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_RULE_FAILURE`,
);

const createDisciplinaryRule = createAction<CreatePayload>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_RULE`,
);
const createDisciplinaryRuleRequest = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_REQUEST`,
);
const createDisciplinaryRuleSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_SUCCESS`,
);
const createDisciplinaryRuleFailure = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_RULE_FAILURE`,
);

const updateDisciplinaryRule = createAction<UpdatePayload>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE`,
);
const updateDisciplinaryRuleRequest = createAction(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_REQUEST`,
);
const updateDisciplinaryRuleSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_SUCCESS`,
);
const updateDisciplinaryRuleFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_RULE_FAILURE`,
);

const deleteDisciplinaryRule = createAction<DeletePayload>(
  `${NAMESPACE}/DELETE_DISCIPLINARY_RULE`,
);
const deleteDisciplinaryRuleRequest = createAction(
  `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_REQUEST`,
);
const deleteDisciplinaryRuleSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_SUCCESS`,
);
const deleteDisciplinaryRuleFailure = createAction<void>(
  `${NAMESPACE}/DELETE_DISCIPLINARY_RULE_FAILURE`,
);

const setDisciplinaryRules = createAction<SetPayload, Meta>(
  `${NAMESPACE}/SET_DISCIPLINARY_RULES`,
  (payload) => payload,
  (_, meta) => meta,
);
const setDisciplinaryRule = createAction<SetOnePayload, Meta>(
  `${NAMESPACE}/SET_DISCIPLINARY_RULE`,
  (payload) => payload,
  (_, meta) => meta,
);

const resetCompetitionCreation = createAction<void>(
  `${NAMESPACE}/RESET_COMPETITION_CREATION`,
);

const getCompetitionContacts = createAction<any>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS`,
);
const getCompetitionContactsRequest = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_REQUEST`,
);
const getCompetitionContactsSuccess = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_SUCCESS`,
);
const getCompetitionContactsFailure = createAction<void>(
  `${NAMESPACE}/GET_COMPETITION_CONTACTS_FAILURE`,
);

const createCompetitionContact = createAction<any>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT`,
);
const createCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_REQUEST`,
);
const createCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_SUCCESS`,
);
const createCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/CREATE_COMPETITION_CONTACT_FAILURE`,
);

const updateCompetitionContact = createAction<any>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT`,
);
const updateCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_REQUEST`,
);
const updateCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_SUCCESS`,
);
const updateCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_COMPETITION_CONTACT_FAILURE`,
);

const deleteCompetitionContact = createAction<any>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT`,
);
const deleteCompetitionContactRequest = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_REQUEST`,
);
const deleteCompetitionContactSuccess = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_SUCCESS`,
);
const deleteCompetitionContactFailure = createAction<void>(
  `${NAMESPACE}/DELETE_COMPETITION_CONTACT_FAILURE`,
);

const saveCompetitionAsDraft = createAction<any>(
  `${NAMESPACE}/SAVE_COMPETITION_AS_DRAFT`,
);
const saveCompetitionAsDraftRequest = createAction<void>(
  `${NAMESPACE}/SAVE_COMPETITION_AS_DRAFT_REQUEST`,
);
const saveCompetitionAsDraftSuccess = createAction<void>(
  `${NAMESPACE}/SAVE_COMPETITION_AS_DRAFT_SUCCESS`,
);
const saveCompetitionAsDraftFailure = createAction<void>(
  `${NAMESPACE}/SAVE_COMPETITION_AS_DRAFT_FAILURE`,
);

type SetCompetitionContacts = {
  payload: any;
  competitionId: number;
};

const setCompetitionContacts = createAction(
  `${NAMESPACE}/SET_COMPETITION_CONTACTS`,
  (data: SetCompetitionContacts): any => prop('payload', data),
  (data: SetCompetitionContacts): { competitionId: number } =>
    pick(['competitionId'], data),
);

export default {
  saveCompetitionAsDraft,
  saveCompetitionAsDraftRequest,
  saveCompetitionAsDraftSuccess,
  saveCompetitionAsDraftFailure,

  getDisciplinaryRules,
  getDisciplinaryRulesRequest,
  getDisciplinaryRulesSuccess,
  getDisciplinaryRulesFailure,

  getDisciplinaryRule,
  getDisciplinaryRuleRequest,
  getDisciplinaryRuleSuccess,
  getDisciplinaryRuleFailure,

  createDisciplinaryRule,
  createDisciplinaryRuleRequest,
  createDisciplinaryRuleSuccess,
  createDisciplinaryRuleFailure,

  updateDisciplinaryRule,
  updateDisciplinaryRuleRequest,
  updateDisciplinaryRuleSuccess,
  updateDisciplinaryRuleFailure,

  deleteDisciplinaryRule,
  deleteDisciplinaryRuleRequest,
  deleteDisciplinaryRuleSuccess,
  deleteDisciplinaryRuleFailure,

  setDisciplinaryRules,
  setDisciplinaryRule,

  getCompetitionContacts,
  getCompetitionContactsRequest,
  getCompetitionContactsSuccess,
  getCompetitionContactsFailure,

  createCompetitionContact,
  createCompetitionContactRequest,
  createCompetitionContactSuccess,
  createCompetitionContactFailure,

  updateCompetitionContact,
  updateCompetitionContactRequest,
  updateCompetitionContactSuccess,
  updateCompetitionContactFailure,

  deleteCompetitionContact,
  deleteCompetitionContactRequest,
  deleteCompetitionContactSuccess,
  deleteCompetitionContactFailure,

  setCompetitionContacts,

  resetCompetitionCreation,
};
