import { useEffect } from 'react';
import { Formik, FormikProps, FormikConfig, Form } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { pick } from 'ramda';
import { Paper, Box, Grid, Typography, Divider, Button } from '@mui/material';

import { GET_AGE_GROUPS_REQUEST } from '@core/pages/age-groups/store/constants';
import { GET_COMPETITION_SEASONS_REQUEST } from '@core/pages/seasons/store/constants';
import { GET_COMPETITION_LEVELS_REQUEST } from '@core/pages/competition-levels/store/constants';
import { getCompetitionsLevelsList } from '@core/pages/competition-levels/store/selectors';
import { getAgeGroupList } from '@core/pages/age-groups/store/selectors';
import { getSeasons } from '@core/pages/seasons/store/selectors';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import * as seasonActions from '@core/pages/seasons/store/actions';
import * as ageGroupActions from '@core/pages/age-groups/store/actions';
import * as competitionLevelsActions from '@core/pages/competition-levels/store/actions';
import {
  FullScreenSpinner,
  SummaryBlock,
  SummaryBlockValues,
} from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { getInitialErrors } from '@core/helpers';
import useTabStateSetter from '@core/components/navigation-tabs/hooks/use-tab-state-setter';
import { DATE_FORMAT } from '@core/constants/common';
import {
  CREATE_COMPETITION_REQUEST,
  UPDATE_COMPETITION_REQUEST,
} from '@core/pages/competitions/store/constants';
import { getCompetitionsErrors } from '@core/pages/competitions/store/selectors';
import GeneralInformationFormTemplate, {
  FormValues,
  getDefaultValues,
  getValidationSchema,
  FIELD_NAMES,
} from '@core/components/general-information-form-template';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { getSportList } from '@core/store/modules/sports/selectors';
import * as sportsActions from '@core/store/modules/sports/actions';
import { GET_SPORTS_REQUEST } from '@core/store/modules/sports/constants';
import {
  APIErrorsModel,
  Competition,
  CompetitionLevelsList,
  AgeGroupsList,
  SeasonsList,
  SportsList,
} from '@core/types';
import { State } from '@core/store';
import { actions as competitionGeneralInformationEditActions } from '@core/store/modules/tabs/competition-general-information-edit';
import * as competitionCreationActions from '@core/store/modules/ui/competition-creation/actions';
import { ButtonWithProgress } from '@ui-components';

interface OwnProps {
  competitionId: number;
  tabId: string;
}

interface StateProps {
  isSubmitting: boolean;
  isLoading: boolean;
  competitionBeingModified: Competition;
  errors: APIErrorsModel;
  ageGroups: AgeGroupsList;
  competitionLevels: CompetitionLevelsList;
  seasons: SeasonsList;
  sports: SportsList;
}

interface DispatchProps {
  actions: {
    competitionCreation: typeof competitionCreationActions;
    competitionGeneralInformationEdit: typeof competitionGeneralInformationEditActions;
    season: typeof seasonActions;
    ageGroup: typeof ageGroupActions;
    competitionLevels: typeof competitionLevelsActions;
    sports: typeof sportsActions;
    tab: typeof tabActions;
  };
}

type Props = OwnProps & DispatchProps & StateProps;

const GeneralInformationPanel = ({
  actions,
  errors,
  isSubmitting,
  competitionBeingModified,
  ageGroups,
  sports,
  seasons,
  competitionLevels,
  isLoading,
  tabId,
}: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    actions.season.getCompetitionSeasons();
    actions.ageGroup.getAgeGroups();
    actions.competitionLevels.getCompetitionLevels();
    actions.sports.getSports();
  }, []);

  const closeTab = () => actions.tab.removeTab({ tabId });

  const getSummaryBlockValues = (): SummaryBlockValues => [
    {
      label: t('Competition'),
      value: competitionBeingModified?.title,
    },
    {
      label: t('Season'),
      value: competitionBeingModified?.competitionSeasonTitle,
    },
  ];

  const renderForm = (formikProps: FormikProps<any>) => {
    const { dirty } = formikProps;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTabStateSetter(dirty);

    return (
      <Form noValidate>
        <Paper>
          <Box paddingX={3} paddingY={3}>
            <Box mb={2}>
              <Typography variant="h6">
                {t('Edit general information')}
              </Typography>
            </Box>
            <SummaryBlock values={getSummaryBlockValues()} />
          </Box>
          <Grid container item xs={12}>
            <Box mb={2} flex={1}>
              <Divider orientation="horizontal" />
            </Box>
          </Grid>
          <Box pb={3}>
            <Box paddingX={3} paddingY={1}>
              <Grid container spacing={2}>
                <GeneralInformationFormTemplate
                  ageGroups={ageGroups}
                  sports={sports}
                  seasons={seasons}
                  competitionLevels={competitionLevels}
                  sportSelectionDisabled={
                    competitionBeingModified?.competitorsCount > 0
                  }
                />
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Grid container justifyContent="flex-end">
          <Box mt={3} display="flex">
            {tabId && (
              <Grid item>
                <Box mr={1}>
                  <Button variant="outlined" onClick={closeTab}>
                    {t('Cancel')}
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item>
              <ButtonWithProgress
                isLoading={isSubmitting}
                type="submit"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('Save')}
              </ButtonWithProgress>
            </Grid>
          </Box>
        </Grid>
      </Form>
    );
  };

  const handleSubmit: FormikConfig<FormValues>['onSubmit'] = (values) => {
    const startDate = dayjs(values[FIELD_NAMES.startDate]).format(DATE_FORMAT);
    const endDate = dayjs(values[FIELD_NAMES.endDate]).format(DATE_FORMAT);

    const competitionData = {
      ...values,
      [FIELD_NAMES.startDate]: startDate,
      [FIELD_NAMES.endDate]: !values[FIELD_NAMES.endDate] ? null : endDate,
    };

    const payload = {
      tabId,
      id: competitionBeingModified.id,
      data: competitionData,
    };

    actions.competitionGeneralInformationEdit.updateCompetitionGeneralInformation(
      payload,
    );
  };

  const getInitialValues = () => {
    if (competitionBeingModified) {
      const fieldNames = Object.keys(FIELD_NAMES);

      return pick(fieldNames, competitionBeingModified);
    }

    return getDefaultValues();
  };

  return isLoading ? (
    <FullScreenSpinner />
  ) : (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={getValidationSchema(t)}
      initialErrors={getInitialErrors(errors)}
    >
      {renderForm}
    </Formik>
  );
};

const isSubmittingSelector = createLoadingSelector([
  CREATE_COMPETITION_REQUEST,
  UPDATE_COMPETITION_REQUEST,
]);

const isLoadingSelector = createLoadingSelector([
  GET_AGE_GROUPS_REQUEST,
  GET_COMPETITION_LEVELS_REQUEST,
  GET_COMPETITION_SEASONS_REQUEST,
  GET_SPORTS_REQUEST,
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isSubmitting: isSubmittingSelector(state),
  isLoading: isLoadingSelector(state),
  errors: getCompetitionsErrors(state),
  competitionBeingModified: getCompetitionDetailsById(ownProps.competitionId)(
    state,
  ),
  ageGroups: getAgeGroupList(state),
  seasons: getSeasons(state),
  competitionLevels: getCompetitionsLevelsList(state),
  sports: getSportList(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionCreation: bindActionCreators(
      competitionCreationActions,
      dispatch,
    ),
    competitionGeneralInformationEdit: bindActionCreators(
      competitionGeneralInformationEditActions,
      dispatch,
    ),
    competitionLevels: bindActionCreators(competitionLevelsActions, dispatch),
    sports: bindActionCreators(sportsActions, dispatch),
    season: bindActionCreators(seasonActions, dispatch),
    ageGroup: bindActionCreators(ageGroupActions, dispatch),
    tab: bindActionCreators(tabActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInformationPanel);
