import { handleActions } from 'redux-actions';

import { convertArrayToObject } from '@core/helpers';

import actions from './actions';

type DefaultState = any;

export const defaultState: DefaultState = null;

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  {
    [actions.setPersons.toString()]: (state, { payload }) => ({
      ...state,
      ...convertArrayToObject(payload),
    }),
    [actions.setPerson.toString()]: (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  },
  defaultState,
);

export default reducer;
