import { all, put, take, call } from 'redux-saga/effects';
import download from 'downloadjs';

import { handleApiErrors } from '@core/helpers';
import { getMatchReport } from '@core/api/match-reports';

import { actions } from '.';

type MatchReportQuery = {
  reportType: string;
  reportOutputType: string;
};

export function* getMatchReportFlow() {
  while (true) {
    const { payload } = yield take(actions.getMatchReport);

    yield put(actions.getMatchReportRequest());
    const { match, href, t, i18n } = payload;

    const parsedQuery: MatchReportQuery = href
      .split('?')[1]
      .split('&')
      .reduce((p: { [x: string]: any }, c: string) => {
        const components = c.split('=');
        p[components[0]] = components[1];

        return p;
      }, new Map<string, string>());

    const { error, response } = yield call(getMatchReport, href, i18n);

    if (!error) {
      const reportName = t ? t(parsedQuery.reportType) : parsedQuery.reportType;

      yield call(
        download,
        response,
        `${reportName}_${match.number}_${match.homeCompetitorTitle}-${match.awayCompetitorTitle}`,
        response.type,
      );
      yield put(actions.getMatchReportSuccess());
    } else {
      yield put(actions.getMatchReportFailure());
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([getMatchReportFlow()]);
}
