import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { uuid } from '@core/helpers';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import { ActionsButtons } from '@ui-components';
import * as tabsActions from '@core/store/modules/ui/tabs/actions';
import { createURL } from '@core/helpers';
import { COMPETITION_PROFILE_CREATE_STAGE } from '@core/pages/competition-profile/tests/test-ids';
import paths from '@core/routes/paths';

interface OwnProps {
  isLoading?: boolean;
  competitionId: number;
}

interface DispatchProps {
  actions: {
    tabs: typeof tabsActions;
  };
}

type Props = DispatchProps & OwnProps;

const CompetitionProfileActions = (props: Props) => {
  const { t } = useTranslation();
  const { isLoading, actions, competitionId } = props;

  const handleAddStage = () => {
    const tabId = uuid();
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.createStage),
      props: { competitionId, tabId },
      id: tabId,
    });
  };

  const actionButtons: React.ComponentProps<typeof ActionsButtons>['buttons'] =
    [
      () => (
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          data-qa={COMPETITION_PROFILE_CREATE_STAGE}
          onClick={handleAddStage}
          startIcon={<AddCircleOutlineOutlined />}
        >
          {t('Create stage')}
        </Button>
      ),
    ];

  return <ActionsButtons buttons={actionButtons} />;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    tabs: bindActionCreators(tabsActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(CompetitionProfileActions);
