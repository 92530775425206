import React from 'react';
import { Box } from '@mui/material';

import { TeamModel } from '@core/types';

import TeamPanel from './teams-panel';
import GeneralInformationPanel from './general-information-panel';
import KitSetPanel from './kit-set-panel';

interface OwnProps {
  competitionId: number;
  selectedTeam: TeamModel;
}

type Props = OwnProps;

const CompetitorAssignmentExpansionPanelGroup = (props: Props) => {
  const { competitionId, selectedTeam } = props;

  const panelProps = {
    competitionId,
  };

  return (
    <Box px={3}>
      <TeamPanel {...panelProps} />
      {selectedTeam?.club?.title && (
        <>
          <GeneralInformationPanel
            {...panelProps}
            selectedTeam={selectedTeam}
          />
          <KitSetPanel {...panelProps} />
        </>
      )}
    </Box>
  );
};

export default CompetitorAssignmentExpansionPanelGroup;
