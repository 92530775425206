import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Paper,
  TablePagination,
  TableCellProps,
  TableContainer,
  Box,
  CircularProgress,
  IconButton,
  Theme,
  Toolbar,
} from '@mui/material';
import { AddCircleTwoTone, RemoveCircleTwoTone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FullScreenSpinner } from '@core/components';
import { TABLE_WITH_ASSIGN, ADD_BUTTON, REMOVE_BUTTON } from './tests/test-ids';

interface Column {
  name: string;
  label?: React.ReactNode;
  render: (rowData: any) => any;
  align?: TableCellProps['align'];
}

interface OwnProps {
  data: any;
  columns: Array<Column>;
  handleAdd?: (id: number) => void;
  handleRemove?: (id: number) => void;
  title: string | React.ReactElement;
  isLoading?: boolean;
  infiniteScroll?: any;
  noStickyHeader?: boolean;
  emptyMessage?: string;
  maxHeight?: number;
  titleRow?: boolean;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    '@global': {
      'tr > *:first-child': {
        paddingLeft: ({ titleRow }) =>
          titleRow ? theme.spacing(1.5) : theme.spacing(0.5),
      },
    },
  },
  tableContainer: {
    maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : '753px'),
  },
  tableToolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  scrollable: {
    maxHeight: ({ maxHeight }) => maxHeight,
    overflowY: 'auto',
  },
}));

const TableWithAssign = (props: Props) => {
  const { t } = useTranslation();
  const ROWS_PER_PAGE = 100;
  const {
    data,
    columns,
    handleAdd,
    handleRemove,
    title,
    isLoading,
    infiniteScroll,
    noStickyHeader,
    emptyMessage,
  } = props;
  const [page, setPage] = React.useState(0);
  const [paginatedData, setData] = React.useState(
    data?.length ? data.slice(0, ROWS_PER_PAGE) : [],
  );
  const classes = useStyles(props);

  useEffect(() => {
    setPaginatedData(page);
  }, [data]);

  const setPaginatedData = (newPage: number) => {
    const startIndex = newPage * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    const newData = data?.slice(startIndex, endIndex);

    setData(() => newData);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPaginatedData(newPage);
    setPage(() => newPage);
  };

  const handleLoadNext = () => {
    infiniteScroll.loadNext(infiniteScroll?.pagination?.page + 1);
  };

  const renderTable = () => {
    if (data?.length === 0 && !emptyMessage) {
      return null;
    }

    const renderTableContainer = () => (
      <Table stickyHeader={!noStickyHeader} className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns &&
              columns.map((column: Column) => (
                <TableCell key={column.name} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData?.length <= 0 && emptyMessage ? (
            <TableRow>
              <TableCell align="center" colSpan={columns.length}>
                {emptyMessage}
              </TableCell>
            </TableRow>
          ) : (
            paginatedData?.map((rowData: any) => {
              return (
                <TableRow key={rowData.id}>
                  <TableCell padding="checkbox">
                    {handleAdd && !rowData.isAdded && (
                      <IconButton
                        color="primary"
                        onClick={() => handleAdd(rowData.id)}
                        title={t('Add')}
                        data-qa={ADD_BUTTON}
                      >
                        <AddCircleTwoTone />
                      </IconButton>
                    )}
                    {handleRemove && (
                      <IconButton
                        color="primary"
                        onClick={() => handleRemove(rowData.id)}
                        title={t('Remove')}
                        data-qa={REMOVE_BUTTON}
                      >
                        <RemoveCircleTwoTone />
                      </IconButton>
                    )}
                    <Grid />
                  </TableCell>
                  {columns &&
                    columns.map((column: Column) => (
                      <TableCell key={column.name} align={column.align}>
                        {column.render(rowData)}
                      </TableCell>
                    ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    );

    if (infiniteScroll) {
      return (
        <TableContainer>
          <Grid id="scrollableDiv" className={classes.scrollable}>
            <InfiniteScroll
              hasMore={
                infiniteScroll?.pagination?.page <
                infiniteScroll?.pagination?.pages
              }
              next={handleLoadNext}
              loader={
                <Box display="flex" flex="1" justifyContent="center" p={2}>
                  <CircularProgress size={20} />
                </Box>
              }
              dataLength={paginatedData?.length}
              scrollableTarget="scrollableDiv"
            >
              {renderTableContainer()}
            </InfiniteScroll>
          </Grid>
        </TableContainer>
      );
    }

    return (
      <TableContainer className={classes.tableContainer}>
        {renderTableContainer()}
      </TableContainer>
    );
  };

  return (
    <Grid data-qa={TABLE_WITH_ASSIGN}>
      <Paper>
        <>
          <Toolbar className={classes.tableToolbar}>{title}</Toolbar>
          {isLoading && !infiniteScroll?.pagination ? (
            <FullScreenSpinner />
          ) : (
            renderTable()
          )}
          {data?.length > ROWS_PER_PAGE && (
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={handleChangePage}
            />
          )}
        </>
      </Paper>
    </Grid>
  );
};

export default TableWithAssign;
