import { NAMESPACE } from './constants';
import { createCoreSelector } from '@core/store/helpers';
import type { State } from '@core/store';

const getRoot = (state: State) => state[NAMESPACE];

export const getFeatureToggles = createCoreSelector(
  getRoot,
  (root) => root?.featureToggles ?? [],
);
