import { all, call, Effect, put, throttle } from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import * as competitionOrganisersAPI from '@core/api/competition-organisers';

import * as ACTION_TYPES from './constants';
import * as competitionOrganisersActions from './actions';

export function* getCompetitionOrganisers(
  query: string,
): Generator<Effect, any, any> {
  yield put(competitionOrganisersActions.getCompetitionOrganisersRequest());
  const response = yield call(
    competitionOrganisersAPI.getCompetitionOrganisers,
    query,
  );

  if (!response.error) {
    yield put(competitionOrganisersActions.getCompetitionOrganisersSuccess());
  } else {
    yield put(competitionOrganisersActions.getCompetitionOrganisersFailure());
  }

  return response;
}

function* getCompetitionOrganisersFlow(
  action: ReturnType<
    typeof competitionOrganisersActions.getCompetitionOrganisers
  >,
) {
  const { payload = '' } = action;

  const { error, response } = yield call(getCompetitionOrganisers, payload);

  if (!error) {
    const { competitionOrganisers } = response._embedded;

    yield put(
      competitionOrganisersActions.setCompetitionOrganisers(
        competitionOrganisers,
      ),
    );
  } else {
    yield call(handleApiErrors, error);
  }
}

export function* watchGetCompetitionOrganisers() {
  yield throttle(
    500,
    ACTION_TYPES.GET_COMPETITION_ORGANISERS,
    getCompetitionOrganisersFlow,
  );
}

export default function* saga() {
  yield all([watchGetCompetitionOrganisers()]);
}
