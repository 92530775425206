import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  MenuItem,
  FormControlLabel,
  RadioGroup as MRadioGroup,
  Radio,
  FormControl,
  Paper,
  Theme,
} from '@mui/material';
import { uuid } from '@core/helpers';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import { OptionProps } from 'react-select';
import { map, pipe, values } from 'ramda';

import { Member } from '@core/icons';
import {
  User,
  GlobalModalProps,
  AutocompleteOption,
  OffenderType,
  TeamOfficialRole,
} from '@core/types';
import { RegisterPersonProps } from '@core/pages/register-person';
import { getUsers } from '@core/pages/users/store/selectors';
import {
  getPersonsLazyLoading,
  getTeamOfficialsRoles,
} from '@core/pages/persons/store/selectors';
import usersActions from '@core/pages/users/store/actions';
import { actions as personsActions } from '@core/pages/persons/store/actions';
import { actions as personManagementActions } from '@core/pages/persons/store/api/actions';
import { State } from '@core/store';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  createURL,
  formatNationalities,
  formatOptions,
  getGenders,
  getSports,
} from '@core/helpers';
import {
  AutocompleteField,
  ChipsSelect,
  ColumnWithSubValue,
  ErrorMessage,
  Tooltip,
} from '@core/components';
import paths from '@core/routes/paths';

import {
  FIELD_NAMES,
  FormValues,
  getInitialValues,
  getValidationSchema,
} from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minHeight: '48px',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface StateProps {
  isLoading: boolean;
  users: Array<User>;
  persons: any;
  teamOfficialRoles: Array<TeamOfficialRole>;
}

interface DispatchProps {
  actions: {
    users: typeof usersActions;
    persons: typeof personsActions;
    tabs: typeof tabActions;
    personManagement: typeof personManagementActions;
  };
}

interface ModalParams {
  club: any;
  onAddCallback: () => void;
  globalModalToggle: () => void;
}

type Props = GlobalModalProps<ModalParams> & StateProps & DispatchProps;

const AddPersonModal = ({
  actions,
  isModalOpen,
  isLoading,
  modalParams,
  modalActions,
  persons,
  teamOfficialRoles,
}: Props) => {
  const { club, globalModalToggle, onAddCallback } = modalParams;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    actions.persons.searchMembersByQueryLazyLoading(
      new URLSearchParams([
        ['page', '1'],
        ['limit', '10'],
      ]),
    );
    actions.persons.getTeamOfficialsRoles();
  }, []);

  const handleClose = () => modalActions.closeModal();

  const handleCreatePerson = () => {
    actions.tabs.openTab({
      isClosable: true,
      name: createURL(paths.registerPerson),
      title: t('Create person'),
      // @ts-expect-error
      props: {
        callbacks: {
          onRemoveCallback: globalModalToggle,
        },
        globalModalToggle,
        club,
        teamMemberOnly: true,
      } as TabbedProps<RegisterPersonProps>,
      id: uuid(),
    });
  };

  const getPersonsOptions = () => {
    if (!persons?.list) {
      return [];
    }

    return persons?.list.map((person: any) => ({
      label: `${person.localFirstName} ${person.localFamilyName}`,
      value: person.id,
      props: person,
    }));
  };

  const onInputChange = (query: string) => {
    actions.persons.searchMembersByQueryLazyLoading(
      new URLSearchParams([
        ['page', '1'],
        ['limit', '10'],
        ['query', query],
      ]),
    );
  };

  const hasMore = persons?.pages > persons?.page;

  const loadNext = (query: string) => {
    actions.persons.searchMembersByQueryLazyLoading(
      new URLSearchParams([
        ['page', persons?.page + 1],
        ['limit', '10'],
        ['query', query],
      ]),
    );
  };

  const submitForm = (formValues: FormValues) => {
    handleClose();
    if (formValues[FIELD_NAMES.PERSON_TYPE] === OffenderType.PLAYER) {
      actions.personManagement.createClubPlayer({
        clubId: club?.id,
        personId: formValues[FIELD_NAMES.PERSON],
        sportId: [formValues[FIELD_NAMES.PLAYER_SPORT]],
        onSuccess: () => ({}),
      });
    }
    if (formValues[FIELD_NAMES.PERSON_TYPE] === OffenderType.TEAM_OFFICIAL) {
      actions.personManagement.createTeamOfficial({
        clubId: club?.id,
        personId: formValues[FIELD_NAMES.PERSON],
        teamOfficialRoleId: [formValues[FIELD_NAMES.TEAM_OFFICIAL_ROLE]],
        sportId: club?.sports?.[0]?.id,
        onSuccess: () => ({}),
      });
    }
    onAddCallback();
  };

  const ResultItem = ({
    data,
    innerRef,
    isSelected,
    innerProps,
  }: OptionProps<AutocompleteOption, boolean>) => {
    const person = data.props;
    const subValues = [
      person.firstName,
      person.familyName,
      person.maId,
      person.dateOfBirth,
      getGenders(t, person.gender),
      formatNationalities(person.nationalCitizenships),
    ]
      .filter((value) => value)
      .join(' • ');

    return (
      <MenuItem
        ref={innerRef as any}
        selected={isSelected}
        component="div"
        {...innerProps}
        style={{ padding: 8 }}
      >
        <ColumnWithSubValue
          value={
            person.localFirstName &&
            `${person.localFirstName} ${person.localFamilyName}`
          }
          subValue={subValues}
          logo={{
            url: person.logoFileLink,
            defaultIcon: <Member />,
          }}
        />
      </MenuItem>
    );
  };

  const getSportsOptions = (sports: any) => {
    const options = pipe(
      // @ts-ignore
      values,
      map((val: any) => ({
        label: getSports(t, val?.type),
        value: val.id,
      })),
      // @ts-ignore
    )(sports);

    return options;
  };

  const renderForm = (_: FormikProps<any>) => {
    return (
      <Form>
        <DialogTitle>{t('Add person')}</DialogTitle>
        <DialogContent>
          <Box mb={1.5}>
            <Typography variant="body2" component="span">
              {`${t(`Select a person to`)} `}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              style={{ fontWeight: 'bold' }}
            >
              {club?.title}
            </Typography>
            <Typography variant="body2" component="span">
              {` ${t(`national team`)}`}
            </Typography>
          </Box>
          <Box mb={1.5}>
            {/* TODO: JB: must check if AutocompleteField works with initial non empty value */}
            <AutocompleteField
              name={FIELD_NAMES.PERSON}
              isLoading={isLoading}
              options={getPersonsOptions()}
              textFieldProps={{
                label: t('Select person'),
                required: true,
              }}
              onInputChange={onInputChange}
              pagination={{ hasMore, loadNext }}
              customComponents={{
                Option: ResultItem,
              }}
              menuPosition={'fixed'}
            />
          </Box>
          <Box mb={2} display="flex">
            <Box mr={1}>
              <Typography variant="body2" color="textSecondary">
                {t(
                  "Can't find a person? Probably the person is not created yet.",
                )}
              </Typography>
            </Box>
            <Typography variant="body2">
              <Link onClick={handleCreatePerson}>{t('Create person')}</Link>
            </Typography>
          </Box>
          <Box mb={2}>
            <Box mb={1} display="flex">
              <Typography variant="body1" color="textSecondary">
                {`${t('Add person as')} *`}
              </Typography>
              <Box ml={0.5}>
                <Tooltip title={t('Tooltip')} />
              </Box>
              <Box ml={0.5}>
                <ErrorMessage name={FIELD_NAMES.PERSON_TYPE} />
              </Box>
            </Box>

            <Field name={FIELD_NAMES.PERSON_TYPE}>
              {({ field, form }: FieldProps) => {
                const handleOnChange = (event: React.ChangeEvent<any>) => {
                  form.setFieldValue(
                    FIELD_NAMES.PERSON_TYPE,
                    event.target.defaultValue,
                    true,
                  );
                };

                return (
                  <FormControl component="fieldset" fullWidth>
                    <MRadioGroup
                      name={FIELD_NAMES.PERSON_TYPE}
                      value={field.value}
                      onChange={handleOnChange}
                    >
                      <Box mb={0.5}>
                        <Paper className={classes.paper}>
                          <Box paddingY={0.5} paddingX={1.5}>
                            <FormControlLabel
                              value={OffenderType.PLAYER}
                              control={<Radio />}
                              label={<>{t('Player')}</>}
                            />
                          </Box>
                          {field.value === OffenderType.PLAYER && (
                            <>
                              <Divider orientation="horizontal" />
                              <Box padding={1.5}>
                                <ChipsSelect
                                  fieldName={FIELD_NAMES.PLAYER_SPORT}
                                  options={getSportsOptions(club.sports)}
                                  title={t('Sport')}
                                  required
                                />
                              </Box>
                            </>
                          )}
                        </Paper>
                      </Box>
                      <Box>
                        <Paper className={classes.paper}>
                          <Box paddingY={0.5} paddingX={1.5}>
                            <FormControlLabel
                              value={OffenderType.TEAM_OFFICIAL}
                              control={<Radio />}
                              label={<>{t('Team official')}</>}
                            />
                          </Box>
                          {field.value === OffenderType.TEAM_OFFICIAL && (
                            <>
                              <Divider orientation="horizontal" />
                              <Box padding={1.5}>
                                <ChipsSelect
                                  fieldName={FIELD_NAMES.TEAM_OFFICIAL_ROLE}
                                  options={formatOptions(t, teamOfficialRoles)}
                                  title={t('Role')}
                                  required
                                />
                              </Box>
                            </>
                          )}
                        </Paper>
                      </Box>
                    </MRadioGroup>
                  </FormControl>
                );
              }}
            </Field>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box display="flex" padding={1}>
            <Box mr={1}>
              <Button variant="outlined" onClick={handleClose}>
                {t('Cancel')}
              </Button>
            </Box>
            <Button variant="contained" type="submit">
              {t('Add')}
            </Button>
          </Box>
        </DialogActions>
      </Form>
    );
  };

  return (
    <Dialog onClose={handleClose} open={isModalOpen} maxWidth="sm" fullWidth>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema(t)}
        onSubmit={submitForm}
      >
        {renderForm}
      </Formik>
    </Dialog>
  );
};

const isLoadingSelector = createLoadingSelector([
  personsActions.getPersonsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: isLoadingSelector(state),
  users: getUsers(state),
  persons: getPersonsLazyLoading(state),
  teamOfficialRoles: getTeamOfficialsRoles(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    users: bindActionCreators(usersActions, dispatch),
    persons: bindActionCreators(personsActions, dispatch),
    tabs: bindActionCreators(tabActions, dispatch),
    personManagement: bindActionCreators(personManagementActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonModal);
