import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterCheckboxList, SearchField } from '@core/components';
import { getChildGroupsByCompAndStageIds } from '@core/store/modules/tabs/competition-profile/selectors';
import { STAGE_ROUND_FILTER, STAGE_ROUND_FILTER_ID } from '../constants';
import type { FilterTabProps } from '@core/types';

type Props = FilterTabProps<{
  competitionId: number;
  stageId: number;
}>;

const RoundFilterTab = ({
  filters,
  competitionId,
  stageId,
  ...rest
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const group = useSelector(
    getChildGroupsByCompAndStageIds({ competitionId, stageId }),
  );

  const filterValues = filters[STAGE_ROUND_FILTER_ID]?.filterValues || [];
  const filteredGroups =
    group.filter(({ title }) =>
      title.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
    ) ?? [];

  const groupIds = filteredGroups.map(({ id }) => id);

  const formatFilterLabel = (id: string) => {
    const filterId = Number(id);
    const round = group.find((stage) => stage.id === filterId);

    return round.title;
  };

  const handleSearch = ({ query: searchQuery }: { query: string }) => {
    setQuery(searchQuery);
  };

  return (
    <>
      <SearchField debounceMs={0} query={query} onSearch={handleSearch} />
      <FilterCheckboxList
        shifted
        list={groupIds}
        filterProps={STAGE_ROUND_FILTER}
        filterValues={filterValues}
        formatFilterLabel={formatFilterLabel}
        formatLabel={formatFilterLabel}
        {...rest}
      />
    </>
  );
};

export default RoundFilterTab;
