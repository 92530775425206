import React, { useCallback } from 'react';
import { MoreVert } from '@mui/icons-material';
import {
  IconButton,
  IconButtonProps,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
} from '@mui/material';
import { usePopperToggle } from '@core/hooks';
import { ConditionalWrapper } from '@ui-components';

interface ActionProps {
  title: string;
  action: (event: React.MouseEvent<EventTarget>) => void;
  disabled?: boolean;
  disabledTooltip?: string;
  testId?: string;
}

type Props = {
  actions: Array<ActionProps>;
  size?: IconButtonProps['size'];
  testId?: string;
};

const MoreActionsButton = ({ actions, testId, size = 'medium' }: Props) => {
  const { isOpen, handleToggle, handleClose, anchorRef } = usePopperToggle();

  const stopPropagation = useCallback((event) => event.stopPropagation(), []);

  const handleActionClick =
    (action: (event: React.MouseEvent<EventTarget>) => void) =>
    (event: React.MouseEvent<HTMLLIElement>) =>
      action(event);

  return (
    <>
      <IconButton
        onClick={handleToggle}
        ref={anchorRef}
        size={size}
        data-qa={testId || null}
      >
        <MoreVert />
      </IconButton>
      <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-end">
        <Paper>
          <ClickAwayListener onClickAway={(event: any) => handleClose(event)}>
            <MenuList onClick={stopPropagation}>
              {actions?.map((action, index) => (
                <ConditionalWrapper
                  key={`${action.title}${action.testId ?? ''}`}
                  condition={action.disabled && !!action.disabledTooltip}
                  wrapper={(wrapperChildren) => (
                    <Tooltip
                      arrow
                      title={action.disabledTooltip}
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -20],
                            },
                          },
                        ],
                      }}
                    >
                      <Box display="flex">{wrapperChildren}</Box>
                    </Tooltip>
                  )}
                >
                  <MenuItem
                    key={index}
                    onClick={handleActionClick(action.action)}
                    data-qa={action.testId}
                    disabled={action.disabled}
                  >
                    {action.title}
                  </MenuItem>
                </ConditionalWrapper>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default MoreActionsButton;
