import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FullScreenSpinner } from '@core/components';
import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getCompetitionDetailsById } from '@core/store/modules/tabs/competition-profile/selectors';
import { useResetCompetitorProfile } from '@core/helpers/useResetCompetitorProfile';
import {
  getCompetitorByTeamId,
  getCompetitorPlayersList,
} from '@core/store/modules/tabs/competitor-profile/selectors';
import { actions as competitorProfileActions } from '@core/store/modules/tabs/competitor-profile';
import {
  Competitor,
  Competition,
  TeamModel,
  CompetitorPlayerList,
} from '@core/types';
import { State } from '@core/store';

import CompetitorProfileSummary from './components/competitor-profile-summary';
import CompetitorProfilePanelSummary from './components/competitor-profile-panel-summary';
import CompetitorProfileTabs from './components/competitor-profile-tabs';

interface DispatchProps {
  actions: {
    competitorProfile: typeof competitorProfileActions;
    tab: typeof tabActions;
  };
}

interface StateProps {
  isLoading: boolean;
  competitorDetails: {
    competitor: Competitor;
    team: TeamModel;
  };
  competitionDetails: Competition;
  competitorPlayers: CompetitorPlayerList;
}

interface OwnProps {
  teamId: number;
  competitionId: number;
  competitorId: number;
  tabId: string;
}

type Props = DispatchProps & OwnProps & StateProps;

const CompetitorProfilePage = (props: Props) => {
  const {
    actions,
    competitionId,
    teamId,
    isLoading,
    competitorDetails,
    competitionDetails,
    competitorId,
    tabId,
    competitorPlayers,
  } = props;

  const resetCompetitorProfileRef = useResetCompetitorProfile(competitorId);

  useEffect(() => {
    actions.competitorProfile.getCompetitorByTeam({
      competitionId,
      teamId,
      competitorId,
    });

    actions.competitorProfile.getCompetitorPlayerList({
      competitionId,
      teamId,
      competitorId,
    });

    return () => resetCompetitorProfileRef.current();
  }, []);

  const competitorTitle = competitorDetails?.competitor?.title;

  useEffect(() => {
    competitorTitle &&
      actions.tab.updateTabTitle({ title: competitorTitle, tabId });
  }, [competitorTitle]);

  return isLoading ? (
    <FullScreenSpinner />
  ) : (
    competitorDetails && competitionDetails && (
      <Accordion expanded>
        <CompetitorProfilePanelSummary
          competitionId={competitionId}
          competitorId={competitorId}
          teamId={teamId}
        />
        <AccordionDetails>
          <Box width="100%">
            <Box mb={3}>
              <CompetitorProfileSummary
                competitionId={competitionId}
                competitorId={competitorId}
              />
            </Box>
            <CompetitorProfileTabs
              competitionId={competitionId}
              competitorId={competitorId}
              teamId={teamId}
              playersCount={competitorPlayers?.length}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitorProfile: bindActionCreators(competitorProfileActions, dispatch),
    tab: bindActionCreators(tabActions, dispatch),
  },
});

const isLoadingSelector = (ownProps: OwnProps) =>
  createLoadingSelector([
    competitorProfileActions.getCompetitorByTeamRequest({
      competitionId: ownProps.competitionId,
      competitorId: ownProps.competitorId,
      teamId: ownProps.teamId,
    }),
  ]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  isLoading: isLoadingSelector(ownProps)(state),
  competitorDetails: getCompetitorByTeamId(state, ownProps?.competitorId),
  competitionDetails: getCompetitionDetailsById(ownProps.competitionId)(state),
  competitorPlayers: getCompetitorPlayersList(state, ownProps?.competitorId),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitorProfilePage);
