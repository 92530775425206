import React, { ChangeEvent, MouseEvent } from 'react';
import { Grid, TablePagination, Theme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { pathOr } from 'ramda';
import {
  PaginationLimits,
  FilterTabsList,
  Filters,
  LanguageDirections,
} from '@core/types';
import { isValue, languageDirection } from '@core/helpers';
import MaterialTable, {
  MaterialTableProps,
  Options,
  Icons,
} from '@core/components/material-table';
import { CUSTOM_TABLE } from './tests/test-ids';
import tableIcons from './constants';
import { FormikMTInput } from './formik/formik-mt-input';
import { FormikMTRow, RowProps } from './formik/formik-mt-row';
import TableToolbar from './components/table-toolbar';
import TableHeader from './components/table-header';
import applyActionsCellStyles from './apply-actions-cell-styles';
import TableTitle from './components/table-title';
import {
  Body as SubColumnsBody,
  Header as SubColumnsHeader,
} from './components/subcolumns-table';

export interface PaginationProps {
  reloadData: (query: { page: number }) => void;
  page: number;
  pages: number;
  limit: PaginationLimits;
  total: number;
}

interface OwnProps<T extends Record<string, any>> {
  isForm?: boolean;
  pagination?: PaginationProps;
  dialog?: React.ReactElement;
  noPaper?: boolean;
  noMargin?: boolean;
  icons?: Icons;
  rowActions?: {
    handleRowOpen?: (data: T) => void;
    handleRowClose?: () => void;
    handleRowClick?: (
      event?: React.MouseEvent,
      rowData?: T,
      toggleDetailPanel?: (panelIndex?: number) => void,
    ) => void;
  };
  tableSearch?: {
    query: string;
    onSearch: (values: { query: string; page: number }) => void;
  };
  tableFilter?: {
    tabsList: FilterTabsList;
    onSave: (filters: Filters) => void;
    initialFilters: Filters;
  };
  hasSubColumns?: boolean;
  isLoading?: boolean;
  withSummary?: boolean;
  borderedTable?: boolean;
  topBorder?: boolean;
  titleRow?: string;
  connectedTable?: boolean;
  mobileTable?: boolean;
  mobileColumnsToShow?: Array<number>;
  compactHeader?: boolean;
  disableBodyElevation?: boolean;
  fixedBodyHeight?: boolean;
  fixedFirstColumnWidth?: boolean;
  borderBottomTable?: boolean;
}

type Props<T extends object> = OwnProps<T> & MaterialTableProps<T>;

const noPaperContainer = ({ children }: any) => <>{children}</>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    '@global': {
      'tr > *:first-child': {
        paddingLeft: ({ fixedFirstColumnWidth }: Props<any>) =>
          fixedFirstColumnWidth ? theme.spacing(1.5) : theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: () =>
            languageDirection === LanguageDirections.RTL && theme.spacing(2),
        },
        paddingRight: () =>
          languageDirection === LanguageDirections.RTL && theme.spacing(3),
      },
      'tr > *:last-child': {
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          paddingRight: theme.spacing(2),
        },
      },
    },
    '& th': {
      padding: ({ compactHeader }: Props<any>) =>
        compactHeader ? '7.5px 12px' : '',
    },
    margin: ({ noPaper, noMargin }: Props<any>) =>
      noPaper ? (noMargin ? 0 : theme.spacing(0, -3)) : 0,
  },
  summary: {
    '@global': {
      'tr:last-child td': {
        borderBottom: 'none',
        fontWeight: ({ data }) =>
          data?.length
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightRegular,
        '&:empty::after': {
          content: 'none',
        },
      },
    },
  },
  borderedTable: {
    borderWidth: '1px 1px 0px 1px',
    border: `${theme.palette.grey[300]} solid`,
  },
  topBorder: {
    borderTop: `1px ${theme.palette.grey[300]} solid`,
  },
  borderBottomTable: {
    borderWidth: '1px 1px 1px 1px',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  title: {
    marginRight: 2,
    width: '50%',
    textAlign: 'right',
  },
  value: {
    width: '50%',
    marginLeft: 4,
    textAlign: 'left',
  },
  mobileTableEntry: {
    padding: theme.spacing(1),
  },
  disableBodyElevation: {
    '& > *:nth-child(2)': {
      boxShadow: 'none',
    },
  },
}));

const CustomTable = <T extends Record<string, any>>(props: Props<T>) => {
  const classes = useStyles(props);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const { t } = useTranslation();

  const {
    pagination,
    noPaper,
    noMargin,
    options,
    dialog,
    isForm,
    components,
    rowActions,
    title = '',
    icons = {},
    tableSearch,
    tableFilter,
    isLoading,
    withSummary,
    borderedTable,
    topBorder,
    columns,
    titleRow,
    connectedTable,
    mobileTable,
    localization,
    disableBodyElevation,
    fixedBodyHeight,
    mobileColumnsToShow,
    hasSubColumns,
    borderBottomTable,
    ...mtProps
  } = props;

  const { palette } = useTheme<Theme>();

  const defaultOptions: Options<any> = {
    actionsCellStyle: {
      color: palette.primary.main,
    },
    emptyRowsWhenPaging: false,
    detailPanelColumnAlignment: 'right',
    paging: false,
    search: false,
    sorting: false,
    draggable: false,
    toolbar: false,
    showFirstLastPageButtons: false,
    minBodyHeight: fixedBodyHeight ? 354 : '',
    rowStyle: {
      fontSize: '0.8125rem',
    },
  };

  const renderTHead = (headerProps: any) => (
    <TableHeader
      headerProps={headerProps}
      columns={columns}
      isLoading={isLoading}
    />
  );

  const renderTreeColumnTHead = (headerProps: any) => (
    <SubColumnsHeader
      headerProps={headerProps}
      columns={columns}
      isLoading={isLoading}
    />
  );

  const renderFormComponents = () => ({
    EditField: FormikMTInput,
    EditRow: (rowProps: RowProps) => (
      <FormikMTRow {...rowProps} {...rowActions} />
    ),
  });

  const componentOverrides = {
    Header: hasSubColumns ? renderTreeColumnTHead : renderTHead,
    ...(hasSubColumns ? { Body: SubColumnsBody } : {}),
    ...(noPaper ? { Container: noPaperContainer } : {}),
    ...(isForm ? renderFormComponents() : {}),
    ...components,
  };

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => {
    pagination && pagination.reloadData({ page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (_: ChangeEvent<HTMLInputElement>) => {
    // TODO: implement when BE adds possibility to change limit per page
  };

  const renderTable = () => {
    if (mobileTable && isSmallScreen) {
      const onTableRowClick = (event: any, rowData: any, togglePanel: any) => {
        togglePanel();
      };

      const renderDetailPanel = (rowData: any) => {
        const [, ...restColumns] = columns;

        return (
          <Grid className={classes.mobileTableEntry}>
            {restColumns.map((column, index: number) => {
              if (column.render) {
                const columnTitle = column.title;
                const columnRender = column.render;

                return (
                  <Grid className={classes.row} key={index}>
                    <Grid className={classes.title}>
                      {columnTitle && `${columnTitle}:`}
                    </Grid>
                    <Grid className={classes.value}>
                      {columnRender(rowData, 'row')}
                    </Grid>
                  </Grid>
                );
              }

              if (column.field) {
                const columnTitle = column.title;
                const columnField = column.field;
                const fieldsArray = (columnField as string).split('.');

                return (
                  <Grid className={classes.row} key={index}>
                    <Grid className={classes.title}>
                      {columnTitle && `${columnTitle}:`}
                    </Grid>
                    <Grid className={classes.value}>
                      {pathOr('-', fieldsArray, rowData)}
                    </Grid>
                  </Grid>
                );
              }

              return '';
            })}
          </Grid>
        );
      };

      return (
        <MaterialTable
          icons={{
            ...tableIcons,
            ...icons,
          }}
          options={{
            ...defaultOptions,
            ...options,
            header: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t('No records to display'),
              editTooltip: t('Edit'),
              deleteTooltip: t('Delete'),
              addTooltip: t('Add'),
              editRow: {
                saveTooltip: t('Save'),
                cancelTooltip: t('Cancel'),
              },
              ...localization?.body,
            },
            header: {
              actions: t('Actions'),
            },
          }}
          isLoading={options?.loadingType === 'overlay' && isLoading}
          components={componentOverrides}
          title={title}
          columns={mobileColumnsToShow
            .map((columnId) => columns[columnId])
            .map(applyActionsCellStyles(isSmallScreen, languageDirection))}
          onRowClick={onTableRowClick}
          detailPanel={renderDetailPanel}
          {...mtProps}
        />
      );
    }

    return (
      <MaterialTable
        icons={{
          ...tableIcons,
          ...icons,
        }}
        options={{
          ...defaultOptions,
          ...options,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t('No records to display'),
            editTooltip: t('Edit'),
            deleteTooltip: t('Delete'),
            addTooltip: t('Add'),
            editRow: {
              saveTooltip: t('Save'),
              cancelTooltip: t('Cancel'),
            },
            ...localization?.body,
          },
          header: {
            actions: t('Actions'),
          },
        }}
        isLoading={options?.loadingType === 'overlay' && isLoading}
        components={componentOverrides}
        title={title}
        columns={columns.map(
          applyActionsCellStyles(isSmallScreen, languageDirection),
        )}
        onRowClick={rowActions?.handleRowClick}
        {...mtProps}
      />
    );
  };

  return (
    <Grid
      data-qa={CUSTOM_TABLE}
      className={clsx(classes.root, {
        [classes.summary]: withSummary,
        [classes.borderedTable]: borderedTable,
        [classes.borderBottomTable]: borderBottomTable,
        [classes.topBorder]: topBorder,
        [classes.disableBodyElevation]: disableBodyElevation,
      })}
    >
      {titleRow && (
        <TableTitle connectedTable={connectedTable} title={titleRow} />
      )}
      <TableToolbar tableFilter={tableFilter} tableSearch={tableSearch} />
      {renderTable()}
      {isValue(pagination) && (
        <TablePagination
          rowsPerPageOptions={[pagination.limit]}
          component="div"
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t('of')} ${count}`
          }
        />
      )}
      {dialog}
    </Grid>
  );
};

export default CustomTable;
export type CustomTableProps<T extends object> = Props<T>;
