import { MatchModel, PreviousPairTeamResult } from '@core/types';
import type { TFunction } from 'i18next';

// TODO: JB: maybe move helpers elsewhere

export function areCompetitorsPresent({
  homeCompetitorId,
  awayCompetitorId,
}: Pick<MatchModel, 'awayCompetitorId' | 'homeCompetitorId'>): boolean {
  return !!(homeCompetitorId && awayCompetitorId);
}

export function getCompetitorTitleFactory(t: TFunction) {
  type CompetitorsTitles = Pick<
    MatchModel,
    | 'awayCompetitorTitle'
    | 'awayCompetitorSlotNumber'
    | 'homeCompetitorTitle'
    | 'homeCompetitorSlotNumber'
    | 'groupTitle'
    | 'pair'
    | 'homeCompetitorPlaceholder'
    | 'awayCompetitorPlaceholder'
  >;

  return (
    match: CompetitorsTitles,
    team = 'home',
    ignoreGroupTitle = false,
  ) => {
    const teamTitle =
      match[team === 'home' ? 'homeCompetitorTitle' : 'awayCompetitorTitle'];

    if (teamTitle) {
      return teamTitle;
    }

    const teamPlaceholder =
      match[
        team === 'home'
          ? 'homeCompetitorPlaceholder'
          : 'awayCompetitorPlaceholder'
      ];

    if (teamPlaceholder) {
      return teamPlaceholder;
    }

    if (
      match.pair &&
      match.pair.awayTeamPreviousPair &&
      match.pair.awayTeamPreviousPairTeamResult &&
      match.pair.homeTeamPreviousPair &&
      match.pair.homeTeamPreviousPairTeamResult
    ) {
      const getPairOfLabel = (
        previousPairTeamResult: PreviousPairTeamResult,
        pair: number,
      ) =>
        previousPairTeamResult === 'LOSER'
          ? t('Loser of pair {{pair}}', { replace: { pair } })
          : t('Winner of pair {{pair}}', { replace: { pair } });

      return team === 'home'
        ? getPairOfLabel(
            match.pair.homeTeamPreviousPairTeamResult,
            match.pair.homeTeamPreviousPair.pairNumber,
          )
        : getPairOfLabel(
            match.pair.awayTeamPreviousPairTeamResult,
            match.pair.awayTeamPreviousPair.pairNumber,
          );
    }

    const teamSlotNumber =
      match[
        team === 'home'
          ? 'homeCompetitorSlotNumber'
          : 'awayCompetitorSlotNumber'
      ];

    if (teamSlotNumber) {
      return ignoreGroupTitle
        ? `${t('Team')} ${teamSlotNumber}`
        : `${t('Team')} ${teamSlotNumber} - ${match.groupTitle}`;
    }

    return '';
  };
}
