import { TFunction } from 'i18next';

import PlayersTab from './players-tab';
import TeamOfficialsTab from './team-offcials-tab';

export enum CompetitionProfileTabs {
  PLAYERS_TAB = 'PLAYERS_TAB',
  TEAM_OFFICIALS_TAB = 'TEAM_OFFICIALS_TAB',
}

type Tabs = Array<{
  value: CompetitionProfileTabs;
  component: typeof PlayersTab | typeof TeamOfficialsTab;
  label: string;
}>;

export const getPlayerListTabs = (
  t: TFunction,
  teamsNumber = 0,
  officialsNumber = 0,
): Tabs => [
  {
    value: CompetitionProfileTabs.PLAYERS_TAB,
    component: PlayersTab,
    label: `${t('Players')} (${teamsNumber})`,
  },
  {
    value: CompetitionProfileTabs.TEAM_OFFICIALS_TAB,
    component: TeamOfficialsTab,
    label: `${t('Team officials')} (${officialsNumber})`,
  },
];
