import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Theme,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { MenuItemProps } from '@core/types';
import * as uiActions from '@core/store/modules/ui/menu/actions';

import { MenuDispatchProps } from '../types';
import * as testIds from '../tests/test-ids';

type Props = MenuItemProps & MenuDispatchProps;

export const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  listItemIconSelected: {
    color: theme.palette.common.white,
  },
  icon: {
    width: 20,
    height: 20,
  },
  listItemText: {
    marginTop: 3,
    marginBottom: 2,
  },
  listItemTextPrimary: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0,
  },
}));

const MenuItemTemplate = (props: Props) => {
  const { title, subTitle, icon: Icon, path, actions, onSelectedClick } = props;
  const location = useLocation();
  const isSelected = location.pathname === path;
  const listItemMarginLeft = !Icon ? 5 : 0;
  const classes = useStyles();

  const handleLinkClick = (event: MouseEvent) => {
    if (isSelected) {
      event.preventDefault();
      onSelectedClick && onSelectedClick();
    } else {
      actions.ui.toggleMenu();
    }
  };

  return (
    <ListItem
      button
      selected={isSelected}
      component={RouterLink}
      to={path}
      onClick={handleLinkClick}
      data-qa={testIds.MENU_ITEM}
      className={classes.listItem}
    >
      {Icon && (
        <ListItemIcon
          className={clsx(classes.listItemIcon, {
            [classes.listItemIconSelected]: isSelected,
          })}
        >
          <Icon data-qa={testIds.MENU_ICON} className={classes.icon} />
        </ListItemIcon>
      )}
      <Box ml={listItemMarginLeft}>
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary,
          }}
          data-qa={testIds.MENU_ITEM_TITLE}
          primary={title}
          secondary={subTitle}
        />
      </Box>
    </ListItem>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): MenuDispatchProps => ({
  actions: {
    ui: bindActionCreators(uiActions, dispatch),
  },
});

export const MenuItem = connect(null, mapDispatchToProps)(MenuItemTemplate);
