import LatoLightWoff2 from '../fonts/Lato-Light.woff2';
import LatoLightWoff from '../fonts/Lato-Light.woff';

const latoLatinLight = `
        @font-face {
          font-family: 'LatoWeb';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          text-rendering: optimizeLegibility;
          src: url(${LatoLightWoff2}) format('woff2'), url(${LatoLightWoff}) format('woff');
        }
      `;

export default latoLatinLight;
