import restService from '@core/api/rest-service';
import {
  CompetitorTeamOfficialsResponse,
  UpdateCompetitorTeamOfficialRequest,
} from '@core/types';

export const getCompetitorTeamOfficials = (
  competitionId: number,
  teamId: number,
  queryParams?: { query: string },
) =>
  restService.get<CompetitorTeamOfficialsResponse>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/teamOfficials`,
    {
      params: queryParams,
    },
  );

export const setCompetitorTeamOfficials = (
  competitionId: number,
  teamId: number,
  data: Array<number>,
) =>
  restService.put(
    `/v1/competitions/${competitionId}/competitors/${teamId}/teamOfficials`,
    {
      teamOfficialIds: data,
    },
  );

export const patchCompetitorTeamOfficials = (
  competitionId: number,
  teamId: number,
  competitorTeamOfficials: Array<{
    teamOfficialId: number;
    teamOfficialRoleId: number;
  }>,
) =>
  restService.patch(
    `/v1/competitions/${competitionId}/competitors/${teamId}/teamOfficials`,
    { competitorTeamOfficials },
  );

export const updateCompetitorTeamOfficials = ({
  competitionId,
  teamId,
  officialId,
  data,
}: UpdateCompetitorTeamOfficialRequest) =>
  restService.patch(
    `/v1/competitions/${competitionId}/competitors/${teamId}/teamOfficials/${officialId}`,
    data,
  );

export const getPreviousCompetitorTeamOfficials = (
  competitionId: number,
  teamId: number,
) =>
  restService.get<CompetitorTeamOfficialsResponse>(
    `/v1/competitions/${competitionId}/competitors/${teamId}/teamOfficials/activeOfPreviousCompetitor`,
  );
