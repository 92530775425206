export const getPoolTypes = (t: (key: string) => string) => {
  return [
    {
      value: 0,
      label: t('No pools'),
    },
    // We need sequence of numbers until 10, beginning from 2
    ...Array(9)
      .fill(0)
      .map((element, index) => {
        const value = index + 2;

        return {
          value,
          label: String(value),
        };
      }),
  ];
};
