import { TFunction } from 'i18next';
import { SummaryBlockValues } from '@core/components';
import { formatDate, formatTime, getVenue } from '@core/helpers';
import type { FormValues } from './shared/types';
import type { CustomerResponse, Group, MatchModel } from '@core/types';
import type { MatchSet } from '@volleyball/types';

type SummarBlockProps = {
  match: MatchModel;
  currentCustomer: CustomerResponse;
};
export const getSummaryBlockValues = (
  { match, currentCustomer }: SummarBlockProps,
  t: TFunction,
): SummaryBlockValues => [
  {
    label: t('Match'),
    value: match
      ? `${t('No.')} ${match.id} • ${match.homeCompetitorTitle} - ${
          match.awayCompetitorTitle
        }`
      : '',
  },
  {
    label: t('Date, time'),
    value: match
      ? [
          formatDate(currentCustomer?.dateFormat, match.date),
          formatTime(currentCustomer.timeFormat, match.time),
        ]
          .filter(Boolean)
          .join(', ')
      : '',
  },
  {
    label: t('Venue'),
    value: getVenue(match?.venue)?.title,
  },
];

export function getBestOfSetsNumber(numberOfSets: number) {
  return Math.ceil(numberOfSets / 2);
}

export function getGoldenMatchSets(
  matchSets: Array<MatchSet>,
): Array<MatchSet> {
  return matchSets?.filter((matchSet) => matchSet.goldenSet);
}

export function getRegularMatchSets(
  matchSets: Array<MatchSet>,
): Array<MatchSet> {
  return matchSets?.filter((matchSet) => !matchSet.goldenSet);
}

export function convertDurationToString(duration: number, t: TFunction) {
  const hours = Math.floor((duration / 60) % 24);
  const minutes = Math.floor(duration % 60);

  return [
    hours ? `${hours} ${t('h')}` : undefined,
    minutes ? `${minutes} ${t('min')}` : undefined,
  ]
    .filter(Boolean)
    .join(' ');
}

export function getMinRequiredNumberOfRegularMatchSets(group: Group) {
  return getBestOfSetsNumber(group.settings.numberOfSets);
}

export function getIsOptionalRegularSet(
  matchRegularSetNumber: number,
  group: Group,
) {
  const numberOfMatchSets = group.settings.numberOfSets;
  const minRequiredNumberOfRegularMatchSets =
    getMinRequiredNumberOfRegularMatchSets(group);

  return (
    minRequiredNumberOfRegularMatchSets < matchRegularSetNumber &&
    matchRegularSetNumber <= numberOfMatchSets
  );
}

export function getIsRequiredRegularSet(
  matchRegularSetNumber: number,
  group: Group,
) {
  const minRequiredNumberOfRegularMatchSets =
    getMinRequiredNumberOfRegularMatchSets(group);

  return (
    1 <= matchRegularSetNumber &&
    matchRegularSetNumber <= minRequiredNumberOfRegularMatchSets
  );
}

export function getNextAvailableMatchSetNumber(matchSet: Array<MatchSet>) {
  return matchSet.reduce((max, { number }) => Math.max(max, number), 0) + 1;
}

export function getMatchSetFormFieldNames(index?: number) {
  type Keys = keyof Pick<
    MatchSet,
    'awayCompetitorScore' | 'homeCompetitorScore' | 'duration'
  >;
  type FieldName = `[${number}].${Keys}` | `${Keys}`;

  if (index === undefined) {
    const homeScoreFieldName: FieldName = `homeCompetitorScore` as const;
    const awayScoreFieldName: FieldName = `awayCompetitorScore` as const;
    const durationFieldName: FieldName = `duration` as const;

    return { homeScoreFieldName, awayScoreFieldName, durationFieldName };
  } else {
    const homeScoreFieldName: FieldName =
      `[${index}].homeCompetitorScore` as const;
    const awayScoreFieldName: FieldName =
      `[${index}].awayCompetitorScore` as const;
    const durationFieldName: FieldName = `[${index}].duration` as const;

    return { homeScoreFieldName, awayScoreFieldName, durationFieldName };
  }
}

export function getMatchFormFieldNames() {
  type FieldName = keyof FormValues;
  const actualEndTime: FieldName = 'actualEndTime';
  const actualStartTime: FieldName = 'actualStartTime';
  const attendance: FieldName = 'attendance';

  return { actualEndTime, actualStartTime, attendance };
}
