import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { CompetitionTypesList } from '@volleyball/types';

import { SET_COMPETITION_TYPES } from './constants';

export interface DefaultState {
  allTypes: CompetitionTypesList;
}

export const defaultState: DefaultState = {
  allTypes: null,
};

const reducer = handleActions<DefaultState, any>(
  {
    [SET_COMPETITION_TYPES]: (state, { payload }) =>
      assoc('allTypes', payload, state),
  },
  defaultState,
);

export default reducer;
