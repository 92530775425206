import React from 'react';
import { MenuItem, Grid } from '@mui/material';
import { OptionProps } from 'react-select';

import { ColumnWithSubValue } from '@core/components';
import { AutocompleteOption, MatchOfficial } from '@core/types';

type Props = OptionProps<AutocompleteOption, boolean>;

const RefereesResults = (props: Props) => {
  const { innerRef, isSelected, innerProps } = props;
  const data = props.data as AutocompleteOption<MatchOfficial>;

  return (
    <MenuItem
      ref={innerRef as any}
      selected={isSelected}
      component="div"
      {...innerProps}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <ColumnWithSubValue
            value={
              data.props.localFirstName &&
              `${data.props.localFirstName} ${data.props.localFamilyName}`
            }
            subValue={`${data?.props?.firstName} ${data?.props?.familyName}`}
          />
        </Grid>
        {/*<Grid item>
          <Typography align="center" variant="caption" color="textSecondary">
            {data?.props?.matchOfficialGroups[0]?.title}
          </Typography>
        </Grid>*/}
      </Grid>
    </MenuItem>
  );
};

export default RefereesResults;
