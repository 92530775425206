import { Country } from '@core/types';

export const formatNationalities = (nationalCitizenships: Array<Country>) =>
  nationalCitizenships
    .map((nationalCitizenship: Country) => nationalCitizenship?.name)
    .join(', ');

export const formatNationalitiesISO = (nationalCitizenships: Array<Country>) =>
  nationalCitizenships
    .map((nationalCitizenship: Country) => nationalCitizenship?.iso3)
    .join(', ');
