import restService from '@core/api/rest-service';
import {
  MatchNotesDescriptionsModel,
  MatchNotesDescriptionSubmitData,
  MatchNotesTitlesModel,
} from '@core/types';

export const getMatchNotesDescriptions = (matchId: number) =>
  restService.get<MatchNotesDescriptionsModel>(
    `/v1/matches/${matchId}/matchNotesDescriptions`,
  );

export const getMatchNotesTitles = (matchId: number) =>
  restService.get<MatchNotesTitlesModel>(
    `/v1/matches/${matchId}/matchNotesTitles`,
  );

export const postMatchNotesDescription = (
  matchId: number,
  matchNotesDescriptions: MatchNotesDescriptionSubmitData,
) =>
  restService.post<MatchNotesDescriptionsModel>(
    `/v1/matches/${matchId}/rewriteMatchNotesDescriptions`,
    {
      matchNotesDescriptions,
    },
  );
