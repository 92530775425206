import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { Grid, Paper, Box, Alert } from '@mui/material';

import { FullScreenSpinner, Tabs, TabPanel } from '@core/components';
import {
  COMPETITION_PROFILE_PANEL,
  COMPETITION_PROFILE_TABS,
  COMPETITION_PROFILE_PAGE,
} from '@core/pages/competition-profile/tests/test-ids';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { withLayout } from '@core/components/layout';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import {
  getCompetitionDetailsById,
  getStagesByCompetitionId,
} from '@core/store/modules/tabs/competition-profile/selectors';
import ExpansionPanel, {
  ExpansionPanelSummaryProps,
} from '@core/pages/competition-profile/components/expansion-panel';
import CompetitionProfileHeader from '@core/pages/competition-profile/components/competition-profile-panel/competition-profile-panel-header';
import { CompetitionProfilePanelSummary } from '@core/pages/competition-profile/components/competition-profile-panel';
import CompetitionProfileActions from '@core/pages/competition-profile/components/competition-profile-panel/competition-profile-actions';
import { CompetitionResponse, Groups } from '@volleyball/types';
import { State } from '@volleyball/store';

import { getCompetitionProfileTabs } from './components/competition-profile-tabs-mapping';
import StageExpansionPanel from './components/stage-expansion-panel';
import { LayoutProps } from '@core/types';

interface DispatchProps {
  actions: {
    competitionProfile: typeof competitionProfileActions;
  };
}

export interface OwnProps {
  id: number;
  tabId: string;
}

interface StateProps {
  isLoading: boolean;
  competition: CompetitionResponse;
  stages: Groups;
  isCompetitionUpdating: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

export const CompetitionProfilePage = (props: Props) => {
  const {
    competition,
    actions,
    id: competitionId,
    isLoading,
    stages,
    isCompetitionUpdating,
    tabId,
  } = props;
  const { t } = useTranslation();

  const canCreateStage = !!competition?._links?.createGroup;

  useEffect(() => {
    actions.competitionProfile.getCompetitionDetails(competitionId);
    actions.competitionProfile.getStageDetails({ competitionId });
  }, []);

  const renderCompetitionProfilePanel = () => {
    const competitionProfileTabs = getCompetitionProfileTabs(
      t,
      competition.competitorsCount === 0,
    );
    const competitionProfileSummary = (
      summaryProps: ExpansionPanelSummaryProps,
    ) => (
      <CompetitionProfilePanelSummary
        {...summaryProps}
        competition={competition}
        tabId={tabId}
      />
    );

    return (
      <ExpansionPanel
        testId={COMPETITION_PROFILE_PANEL}
        headerComponent={<CompetitionProfileHeader competition={competition} />}
        summaryComponent={competitionProfileSummary}
        isLoading={isCompetitionUpdating}
      >
        <Tabs
          fullWidth
          tabs={competitionProfileTabs}
          testId={COMPETITION_PROFILE_TABS}
        >
          {(currentTab) => (
            <TabPanel
              currentTab={currentTab}
              id={competitionId}
              tabs={competitionProfileTabs}
            />
          )}
        </Tabs>
      </ExpansionPanel>
    );
  };

  const renderStageProfilePanel = () =>
    stages?.length > 0 ? (
      <Grid>
        {stages.map((stage, index) => (
          <StageExpansionPanel
            key={stage.id}
            stage={stage}
            stageIndex={index}
            competition={competition}
          />
        ))}
      </Grid>
    ) : (
      <Paper>
        <Box padding={3}>
          <Alert severity="warning">
            {t('Stages are missing. Please create a stage.')}
          </Alert>
        </Box>
      </Paper>
    );

  return (
    <Grid data-qa={COMPETITION_PROFILE_PAGE}>
      {!isLoading && !!competition && !isEmpty(competition) ? (
        <>
          {renderCompetitionProfilePanel()}
          {renderStageProfilePanel()}
          {canCreateStage && (
            <Box mt={3} display="flex" justifyContent="flex-end">
              <CompetitionProfileActions
                competitionId={competition.id}
                isLoading={isLoading}
              />
            </Box>
          )}
        </>
      ) : (
        <FullScreenSpinner />
      )}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    competitionProfile: bindActionCreators(competitionProfileActions, dispatch),
  },
});

const isCompetitionUpdatingSelector = createLoadingSelector([
  competitionProfileActions.updateCompetitionRequest.toString(),
]);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const isLoadingSelector = createLoadingSelector([
    competitionProfileActions.getCompetitionDetailsRequest.toString(),
    competitionProfileActions.deleteGroupRequest.toString(),
    {
      actionName: competitionProfileActions.getStageDetailsRequest.toString(),
      id: ownProps.id,
    },
  ]);

  return {
    isLoading: isLoadingSelector(state),
    isCompetitionUpdating: isCompetitionUpdatingSelector(state),
    competition: getCompetitionDetailsById(ownProps.id)(state),
    stages: getStagesByCompetitionId(state, ownProps.id),
  };
};

export const CompetitionProfile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetitionProfilePage);

export default compose<React.FunctionComponent<LayoutProps>>(
  withLayout,
  connect(mapStateToProps, mapDispatchToProps),
)(CompetitionProfilePage);
