import { createActions } from 'redux-actions';

export const NAMESPACE = 'cityList';

export const actions = createActions(
  'GET_CITY',
  'GET_CITY_REQUEST',
  'GET_CITY_SUCCESS',
  'GET_CITY_FAILURE',

  'GET_CITY_LIST',
  'GET_CITY_LIST_META',
  'GET_CITY_LIST_REQUEST',
  'GET_CITY_LIST_SUCCESS',
  'GET_CITY_LIST_FAILURE',

  'SET_CITY',
  'SET_CITY_LIST',
  'SET_CITY_LIST_META',

  'RESET_CITY_LIST',
  { prefix: NAMESPACE },
) as unknown as {
  getCity: RequestLifecycleAction;
  getCityRequest: RequestLifecycleAction;
  getCitySuccess: RequestLifecycleAction;
  getCityFailure: RequestLifecycleAction;
  getCityList: UnknownAction;
  getCityListRequest: RequestLifecycleAction;
  getCityListSuccess: RequestLifecycleAction;
  getCityListFailure: RequestLifecycleAction;
  setCity: UnknownAction;
  setCityList: UnknownAction;
  setCityListMeta: any;
  resetCityList: RequestLifecycleAction;
};
