import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormControlLabel,
  RadioGroup as MRadioGroup,
  Radio,
  FormControl,
  Theme,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { none, isNil } from 'ramda';
import { withField } from './withField';
import type { Props, Block } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2, 0, 2, 4),
  },
}));

const RadioGroup = (props: Props) => {
  const { blocks, name, onChange, selectedValue, labelBackground, disabled } =
    props;
  const classes = useStyles({ labelBackground });

  const getValue = () => {
    if (blocks.length === 1) {
      return blocks[0].value;
    }

    return selectedValue || '';
  };

  const isSelected = ({ value }: Block) => value === getValue();

  const renderBlockContent = (block: Block) =>
    isSelected(block) ? (
      <Grid className={classes.content}>{block.content}</Grid>
    ) : null;

  const renderBlock = () =>
    blocks.map((block: Block) => {
      const Tooltip = block.tooltip || React.Fragment;

      return (
        <Grid key={block.label}>
          <Tooltip>
            <FormControlLabel
              value={block.value}
              control={<Radio />}
              label={block.label}
              disabled={block.disabled}
            />
          </Tooltip>
          {renderBlockContent(block)}
        </Grid>
      );
    });

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { errors, touched } = form;
        const shouldShowFieldError = none(isNil, [errors[name], touched[name]]);

        function handleOnChange(event: React.ChangeEvent<any>) {
          onChange && onChange(event);
          field.onChange(event);
        }

        return (
          <FormControl
            component="fieldset"
            fullWidth
            error={shouldShowFieldError}
            disabled={disabled}
          >
            <MRadioGroup
              name={name}
              value={getValue()}
              onChange={handleOnChange}
            >
              {renderBlock()}
            </MRadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default RadioGroup;
export const RadioGroupField = withField(RadioGroup);
