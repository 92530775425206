import { PlayerKitsModel } from '@core/types';

export const getIsPlayerKitEnabled = (value: PlayerKitsModel): boolean => {
  const {
    primaryColor,
    secondaryColor,
    specialPlayerPrimaryColor,
    specialPlayerSecondaryColor,
  } = value;
  const hasAnyColor =
    primaryColor ||
    secondaryColor ||
    specialPlayerPrimaryColor ||
    specialPlayerSecondaryColor;

  return !!hasAnyColor;
};
