import { createAction } from 'redux-actions';

export const NAMESPACE = 'disciplinarySanctionsList';

const getDisciplinarySanctionsRequest = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_SANCTIONS_REQUEST`,
);
const getDisciplinarySanctionsSuccess = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_SANCTIONS_SUCCESS`,
);
const getDisciplinarySanctionsFailure = createAction<void>(
  `${NAMESPACE}/GET_DISCIPLINARY_SANCTIONS_FAILURE`,
);

const createDisciplinarySanctionRequest = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_SANCTION_REQUEST`,
);
const createDisciplinarySanctionSuccess = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_SANCTION_SUCCESS`,
);
const createDisciplinarySanctionFailure = createAction<void>(
  `${NAMESPACE}/CREATE_DISCIPLINARY_SANCTION_FAILURE`,
);

const updateDisciplinarySanctionStatusRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_STATUS_REQUEST`,
);
const updateDisciplinarySanctionStatusSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_STATUS_SUCCESS`,
);
const updateDisciplinarySanctionStatusFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_STATUS_FAILURE`,
);

const updateDisciplinarySanctionRequest = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_REQUEST`,
);
const updateDisciplinarySanctionSuccess = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_SUCCESS`,
);
const updateDisciplinarySanctionFailure = createAction<void>(
  `${NAMESPACE}/UPDATE_DISCIPLINARY_SANCTION_FAILURE`,
);

const setDisciplinarySanctionsList = createAction<any>(
  `${NAMESPACE}/SET_DISCIPLINARY_SANCTIONS_LIST`,
);

export default {
  getDisciplinarySanctionsRequest,
  getDisciplinarySanctionsSuccess,
  getDisciplinarySanctionsFailure,
  createDisciplinarySanctionRequest,
  createDisciplinarySanctionSuccess,
  createDisciplinarySanctionFailure,
  updateDisciplinarySanctionStatusRequest,
  updateDisciplinarySanctionStatusSuccess,
  updateDisciplinarySanctionStatusFailure,
  updateDisciplinarySanctionRequest,
  updateDisciplinarySanctionSuccess,
  updateDisciplinarySanctionFailure,
  setDisciplinarySanctionsList,
};
