import * as i18next from 'i18next';

import CompetitionGeneralInformationTab from '@core/pages/competition-profile/components/competition-profile-panel/competition-general-information-tab';
import ContactsTab from '@core/pages/competition-profile/components/competition-profile-panel/contacts-tab';
import CompetitorsTab from '@core/pages/competition-profile/components/competition-profile-panel/competitors-tab';
//import DocumentsTab from './documents-tab';
//import CompetitionSettingsTab from './competition-settings-tab';
//import DisciplineTab from './discipline-tab';

export enum CompetitionProfileTabs {
  GENERAL_INFO_TAB = 'GENERAL_INFO_TAB',
  CONTACTS_TAB = 'CONTACTS_TAB',
  TEAMS_TAB = 'TEAMS_TAB',
  /*SETTINGS_TAB = 'SETTINGS_TAB',
  DISCIPLINE_TAB = 'DISCIPLINE_TAB',
  DOCUMENTS_TAB = 'DOCUMENTS_TAB',*/
}

type Tabs = Array<{
  value: CompetitionProfileTabs;
  component: any;
  label: string;
  withWarning?: boolean;
  'data-qa': string;
}>;

export const getCompetitionProfileTabs = (
  t: i18next.TFunction,
  withWarning: boolean,
): Tabs => [
  {
    value: CompetitionProfileTabs.GENERAL_INFO_TAB,
    component: CompetitionGeneralInformationTab,
    label: t('General'),
    'data-qa': 'General',
  },
  {
    value: CompetitionProfileTabs.CONTACTS_TAB,
    component: ContactsTab,
    label: t('Contacts'),
    'data-qa': 'Contacts',
  },
  {
    value: CompetitionProfileTabs.TEAMS_TAB,
    component: CompetitorsTab,
    label: t('Teams'),
    withWarning,
    'data-qa': 'Teams',
  },
  /*{
    value: CompetitionProfileTabs.SETTINGS_TAB,
    component: CompetitionSettingsTab,
    label: t('Settings'),
    'data-qa': 'Settings',
  },
  {
    value: CompetitionProfileTabs.DISCIPLINE_TAB,
    component: DisciplineTab,
    label: t('Discipline'),
    'data-qa': 'Discipline',
  },
  {
    value: CompetitionProfileTabs.DOCUMENTS_TAB,
    component: DocumentsTab,
    label: t('Documents'),
    'data-qa': 'Documents',
  },*/
];
