import { pipe, assoc, dissoc } from 'ramda';

import { replaceEmptyToNull } from '@core/helpers';
import { UpdateCompetitorFormValues } from '@core/types';

export function updateCompetitorDataFactory(data: UpdateCompetitorFormValues) {
  const defaultVenueId = data?.defaultVenueOption?.value || null;
  const alternativeVenueId = data?.alternativeVenueOption?.value || null;

  return pipe(
    assoc('defaultVenueId', defaultVenueId),
    assoc('alternativeVenueId', alternativeVenueId),
    dissoc('defaultVenueOption'),
    dissoc('alternativeVenueOption'),
    replaceEmptyToNull,
  )(data);
}
