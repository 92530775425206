import {
  all,
  take,
  put,
  call,
  debounce,
  Effect,
  SagaReturnType,
} from 'redux-saga/effects';
import { pathOr } from 'ramda';

import * as tabActions from '@core/store/modules/ui/tabs/actions';
import { getVenues } from '@core/pages/venues/store/api/sagas';
import { formatPaginatedStoreWithIds, handleApiErrors } from '@core/helpers';
import { takeLeadingByPredicate } from '@core/store/helpers';
import teamCreationActions from '@core/pages/create-team/store/actions';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { createCompetitor } from '@core/store/modules/api/competitors/sagas';
import { actions as editKitSetActions } from '@core/store/modules/tabs/edit-kit-set';
import { getKitSetList } from '@core/store/modules/api/kit-set/sagas';
import { getTeamsForCompetition } from '@core/store/modules/api/team/sagas';
import { actions as globalModalActions } from '@core/store/modules/ui/global-modal';
import { addPlayersToCompetitor } from '@core/store/modules/api/competitor-players/sagas';
import { addTeamOfficialsToCompetitor } from '@core/store/modules/api/competitor-team-officials/sagas';

import { actions } from '.';

export function* clearCompetitorFlow() {
  while (true) {
    const {
      payload: { competitionId },
    } = yield take(actions.clearCompetitorState);

    yield put(actions.resetCompetitorState({ competitionId }));
  }
}

export function* createCompetitorFlow(): Generator<Effect, any, any> {
  while (true) {
    const { payload } = yield take(actions.createCompetitor);
    const { competitionId, teamId, tabId, data } = payload;

    yield put(actions.createCompetitorRequest());

    const { error } = (yield call(createCompetitor, {
      competitionId,
      teamId,
      data,
    })) as SagaReturnType<typeof createCompetitor>;

    if (!error) {
      yield put(actions.resetCompetitorState({ competitionId }));
      yield put(competitionProfileActions.getCompetitors({ competitionId }));
      yield put(teamCreationActions.clearState());
      yield put(editKitSetActions.resetKitSetState({ kitSetId: null }));
      yield put(tabActions.removeTab({ tabId }));
      yield put(actions.createCompetitorSuccess());
    } else {
      yield call(handleApiErrors, error);
      yield put(actions.createCompetitorFailure());
    }
  }
}

export function* copyPreviousPlayerListFlow() {
  while (true) {
    const { payload } = yield take(actions.copyPreviousPlayerList);
    const { competitionId, teamId, tabId, players, teamOfficials } = payload;

    yield put(actions.copyPreviousPlayerListRequest());

    const { error: playersError } =
      players?.length > 0
        ? yield call(addPlayersToCompetitor, competitionId, teamId, players)
        : { error: null };

    const { error: teamOfficialsError } =
      teamOfficials?.length > 0
        ? yield call(
            addTeamOfficialsToCompetitor,
            competitionId,
            teamId,
            teamOfficials,
          )
        : { error: null };

    if (!playersError && !teamOfficialsError) {
      yield put(actions.copyPreviousPlayerListSuccess());
      yield put(competitionProfileActions.getCompetitors({ competitionId }));
      yield put(globalModalActions.closeModal());
      yield put(tabActions.removeTab({ tabId }));
    } else {
      yield put(actions.copyPreviousPlayerListFailure());
      yield call(handleApiErrors, playersError);
      yield call(handleApiErrors, teamOfficialsError);
    }
  }
}

export function* getKitSetsFlow() {
  yield takeLeadingByPredicate(actions.getClubKitSets, function* (action) {
    const {
      payload: { clubId, competitionId },
    } = action;

    yield put(actions.getClubKitSetsRequest());
    const { error, response } = (yield call(
      getKitSetList,
      clubId,
    )) as SagaReturnType<typeof getKitSetList>;

    if (!error) {
      yield put(actions.getClubKitSetsSuccess());
      const kitSets = response._embedded.kitSets;

      yield put(actions.setKitSets({ data: kitSets, competitionId }));
    } else {
      yield put(actions.getClubKitSetsFailure());
    }
  });
}

export function* getTeamsFlow(action: any) {
  const { payload } = action;
  const { queryParams, competitionId } = payload;

  yield put(actions.getTeamsRequest());
  const { error, response } = yield call(getTeamsForCompetition, queryParams);

  if (!error) {
    yield put(actions.getTeamsSuccess());
    yield put(
      actions.setTeams({
        data: pathOr(null, ['_embedded', 'teams'], response),
        competitionId,
      }),
    );
  } else {
    yield put(actions.getTeamsFailure());
    yield call(handleApiErrors, error);
  }
}

export function* watchGetTeamsFlow() {
  yield debounce(500, actions.getTeams, getTeamsFlow);
}

export function* getDefaultVenues(action: any) {
  const { payload } = action;
  const { query, page, competitionId } = payload;

  yield put(actions.searchDefaultVenuesRequest());

  if (page === 1) {
    yield put(actions.resetSearchDefaultVenues({ competitionId }));
  }

  const { error, response } = yield call(getVenues, { query, page });

  if (!error) {
    yield put(actions.searchDefaultVenuesSuccess());

    yield put(
      actions.setDefaultVenuesList({
        competitionId,
        data: formatPaginatedStoreWithIds('venues')(response) as any,
      }),
    );
  } else {
    yield put(actions.searchDefaultVenuesFailure());
  }
}

export function* watchSearchDefaultVenues() {
  yield debounce(500, actions.searchDefaultVenues, getDefaultVenues);
}

export function* getDefaultVenuesFlow(): Generator<Effect, any, any> {
  while (true) {
    const action = yield take(actions.getDefaultVenues);

    yield call(getDefaultVenues, action);
  }
}

export function* getAlternativeVenues(action: any) {
  const { payload } = action;
  const { query, page, competitionId } = payload;

  yield put(actions.searchAlternativeVenuesRequest());

  if (page === 1) {
    yield put(actions.resetSearchAlternativeVenues({ competitionId }));
  }

  const { error, response } = yield call(getVenues, { query, page });

  if (!error) {
    yield put(actions.searchAlternativeVenuesSuccess());

    yield put(
      actions.setAlternativeVenuesList({
        competitionId,
        data: formatPaginatedStoreWithIds('venues')(response) as any,
      }),
    );
  } else {
    yield put(actions.searchAlternativeVenuesFailure());
  }
}

export function* getAlternativeVenuesFlow(): Generator<Effect, any, any> {
  while (true) {
    const action = yield take(actions.getAlternativeVenues);

    yield call(getAlternativeVenues, action);
  }
}

export function* watchSearchAlternativeVenues() {
  yield debounce(500, actions.searchAlternativeVenues, getAlternativeVenues);
}

export default function* saga() {
  yield all([
    clearCompetitorFlow(),
    createCompetitorFlow(),
    getKitSetsFlow(),
    watchGetTeamsFlow(),
    watchSearchDefaultVenues(),
    watchSearchAlternativeVenues(),
    getDefaultVenuesFlow(),
    getAlternativeVenuesFlow(),
    copyPreviousPlayerListFlow(),
  ]);
}
