import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { path } from 'ramda';

import { Tabs, TabPanel } from '@core/components';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import {
  STAGE_PROFILE_PANEL,
  STAGE_PROFILE_TABS,
} from '@core/pages/competition-profile/tests/test-ids';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import ExpansionPanel, {
  ExpansionPanelSummaryProps,
} from '@core/pages/competition-profile/components/expansion-panel';
import { StageProfilePanelSummary } from '@core/pages/competition-profile/components/stage-profile-panel';
import { CompetitionResponse } from '@volleyball/types';
import { State } from '@volleyball/store';
import { Group } from '@core/types';

import {
  getStageProfileTabs,
  StageProfileTabs,
} from './stage-profile-panel/stage-profile-tabs-mapping';

interface OwnProps {
  competition: CompetitionResponse;
  stage: Group;
  stageIndex: number;
}

interface StateProps {
  isStageUpdating: boolean;
}

type Props = OwnProps & StateProps;

const StageExpansionPanel = (props: Props) => {
  const { competition, isStageUpdating, stage } = props;
  const { t } = useTranslation();

  const competitionId = competition?.id;

  const stageProfilePanelSummary = ({
    expanded,
  }: ExpansionPanelSummaryProps) => (
    <StageProfilePanelSummary
      stage={stage}
      expanded={expanded}
      competitionId={competitionId}
    />
  );

  const stageProfileTabs = getStageProfileTabs(
    t,
    stage.stageType,
    stage._links,
    stage.status,
  );

  return (
    <ExpansionPanel
      summaryComponent={stageProfilePanelSummary}
      testId={STAGE_PROFILE_PANEL}
      isLoading={isStageUpdating}
    >
      <Box width="100%">
        <Tabs fullWidth tabs={stageProfileTabs} testId={STAGE_PROFILE_TABS}>
          {(currentTab) => (
            <TabPanel
              mt={currentTab === StageProfileTabs.GENERAL_INFO_TAB ? 0 : 3}
              currentTab={currentTab}
              id={competitionId}
              tabs={stageProfileTabs}
              props={{ stageId: stage.id, stage, competitionId, competition }}
            />
          )}
        </Tabs>
      </Box>
    </ExpansionPanel>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const isStageUpdatingSelector = createLoadingSelector([
    {
      actionName: competitionProfileActions.getGroupRequest.toString(),
      id: path(['stage', 'id'], ownProps),
    },
  ]);

  return {
    isStageUpdating: isStageUpdatingSelector(state),
  };
};

export default connect(mapStateToProps)(StageExpansionPanel);
