import { VenueModel } from '@core/types';

interface Options {
  subVenue: boolean;
}

export const getVenue = (venue: VenueModel, options?: Options) => {
  if (!venue) return null;

  if (venue.parentVenue && !options?.subVenue) {
    return venue.parentVenue;
  }

  return venue;
};
