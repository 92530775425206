import { all, take, call, put, Effect } from 'redux-saga/effects';
import download from 'downloadjs';

import { handleApiErrors } from '@core/helpers';
import * as uploadFixturesAPI from '@core/api/group-fixtures';
import { enqueueSnackbar } from '@core/store/modules/ui/snackbar/actions';
import { actions as competitionProfileActions } from '@core/store/modules/tabs/competition-profile';
import { UploadFixturesProps } from '@volleyball/types';
import { actions as globalModalActions } from '@volleyball/store/modules/ui/global-modal';

import { actions } from '.';

export function* uploadFixtures(
  uploadFixturesProps: UploadFixturesProps,
): Generator<Effect, any, any> {
  yield put(actions.uploadFixturesRequest());
  const response = yield call(
    uploadFixturesAPI.uploadGroupFixtures,
    uploadFixturesProps,
  );

  if (!response.error) {
    yield put(actions.uploadFixturesSuccess());
  } else {
    yield put(actions.uploadFixturesFailure());
  }

  return response;
}

export function* uploadFixturesFlow() {
  while (true) {
    const { payload } = yield take(actions.uploadFixtures);
    const { groupId, competitionId } = payload;

    const { error } = yield call(uploadFixtures, payload);

    if (!error) {
      yield put(
        competitionProfileActions.getFixturesByGroup({
          groupId,
          competitionId,
        }),
      );
      yield put(globalModalActions.closeModal());
      yield put(
        enqueueSnackbar({
          message: 'Success',
          options: {
            variant: 'success',
          },
        }),
      );
    } else {
      if (error.code !== 500) {
        yield put(globalModalActions.setModalErrors(error));
      } else {
        yield call(handleApiErrors, error);
      }
    }
  }
}

export function* exportFixtures(groupId: number): Generator<Effect, any, any> {
  yield put(actions.exportFixturesRequest());
  const response = yield call(uploadFixturesAPI.exportGroupFixtures, groupId);

  if (!response.error) {
    yield put(actions.exportFixturesSuccess());
  } else {
    yield put(actions.exportFixturesFailure());
  }

  return response;
}

export function* exportFixturesFlow() {
  while (true) {
    const { payload } = yield take(actions.exportFixtures);

    const { error, response } = yield call(exportFixtures, payload);

    if (!error) {
      const blob = new Blob([response], {
        type: 'text/csv;charset=utf-8',
      });

      yield call(download, blob, 'fixtures.csv', 'text/plain');
    } else {
      yield call(handleApiErrors, error);
    }
  }
}

export default function* saga() {
  yield all([uploadFixturesFlow(), exportFixturesFlow()]);
}
