import React from 'react';

import { Club } from '@core/icons';
import { LogoChanger } from '@core/components';
import { ObjectType, Competitor } from '@core/types';

type CompetitorAvatarProps = {
  competitor: Competitor;
  refreshParams: any;
};

const CompetitorAvatar = (props: CompetitorAvatarProps) => {
  if (!props?.competitor) {
    return null;
  }

  const {
    competitor: { id, logoFileLink, logoFileId, clubLogoFileLink, _links },
    refreshParams,
  } = props;

  const canEditCompetitor = !!_links?.patch;

  const src = logoFileLink || clubLogoFileLink;
  const fileParams = {
    objectId: id,
    objectType: ObjectType.Competitors,
    fileId: logoFileId,
  };

  return (
    <LogoChanger
      src={src}
      fileParams={fileParams}
      refreshParams={refreshParams}
      disabled={!canEditCompetitor}
    >
      <Club />
    </LogoChanger>
  );
};

export default CompetitorAvatar;
