import { Status } from '@core/types';

export const getStatus = (t: (key: string) => string, status: Status) => {
  switch (status) {
    case Status.ACTIVE:
      return t('Active');
    case Status.INACTIVE:
      return t('Inactive');
    default:
      return '';
  }
};
