import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
  Divider,
} from '@mui/material';
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles } from '@mui/styles';

import { Logo, SearchField } from '@core/components';
import { Club } from '@core/icons';
import { LogoSize, Competitor } from '@core/types';
import teamGridActions from '@core/store/modules/tabs/team-grid';
import { getCompetitionCompetitors } from '@core/store/modules/tabs/team-grid/selectors';
import { UpdateSlot } from '@core/pages/team-grids-edit/use-slots';
import { State } from '@core/store';

type DispatchProps = {
  actions: {
    teamGrid: typeof teamGridActions;
  };
};

type StateProps = {
  competitors: Array<Competitor>;
};

type OwnProps = {
  competitionId: number;
  popoverProps: PopoverProps;
  onClose: () => void;
  updateSlot: UpdateSlot;
  slotId: string;
  selectedCompetitorsIds: Array<number>;
};

type Props = DispatchProps & StateProps & OwnProps;

type SearchHandler = (params: { query: string; page: number }) => void;

const useStyles = makeStyles(() => ({
  list: { maxHeight: 350, overflow: 'auto', padding: 0 },
  listItem: {
    margin: 0,
    borderRadius: 0,
  },
}));

const TeamListPopover = (props: Props) => {
  const classes = useStyles();
  const {
    onClose,
    competitors,
    updateSlot,
    slotId,
    popoverProps,
    actions,
    competitionId,
    selectedCompetitorsIds,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const reset = () => {
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
    });
    setSearchQuery('');
  };

  useEffect(() => {
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
    });

    return reset;
  }, []);

  const handleSearch: SearchHandler = ({ query }) => {
    setSearchQuery(query);
    actions.teamGrid.getCompetitorsByCompetitionId({
      competitionId,
      limit: 1000,
      query,
    });
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const createClickHandler = (competitor: Competitor) => () => {
    updateSlot(slotId, competitor);
    handleClose();
  };

  return (
    <Popover
      {...popoverProps}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box p={2}>
        <SearchField query={searchQuery} onSearch={handleSearch} />
      </Box>
      <Divider />
      <List className={classes.list}>
        {competitors
          ?.filter(
            (competitor: Competitor) =>
              !selectedCompetitorsIds.includes(competitor.id),
          )
          .map((competitor: Competitor, index: number) => (
            <Fragment key={competitor.id}>
              {index !== 0 && <Divider />}
              <ListItem
                button
                onClick={createClickHandler(competitor)}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <Logo
                    size={LogoSize.SMALL}
                    src={competitor.logoFileLink || competitor.clubLogoFileLink}
                  >
                    <Club />
                  </Logo>
                </ListItemIcon>
                <ListItemText
                  primary={competitor.title}
                  secondary={competitor.internationalTitle}
                />
              </ListItem>
            </Fragment>
          ))}
      </List>
    </Popover>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    teamGrid: bindActionCreators(teamGridActions, dispatch),
  },
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  competitors: getCompetitionCompetitors(state, ownProps.competitionId),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamListPopover);
