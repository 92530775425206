import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    minHeight: 'max-content',
    padding: theme.spacing(3),
    display: 'flex',
  },
  message: {
    textTransform: 'uppercase',
  },
}));

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid
        spacing={3}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography paragraph variant="h1">
            {404}
          </Typography>
        </Grid>
        <Grid item>
          <Typography paragraph variant="h4" className={classes.message}>
            {t('Page not found')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
