import {
  put,
  call,
  take,
  all,
  SagaReturnType,
  takeEvery,
  select,
  Effect,
} from 'redux-saga/effects';

import { handleApiErrors } from '@core/helpers';
import { getMatchMutatedSuccessfullyCallbacks } from '@core/store/helpers/get-match-mutated-successfully-callbacks';
import {
  getMatchSets,
  createMatchSet,
  updateMatchSets,
  updateSet,
  deleteSet,
} from '@core/store/modules/api/match-set/sagas';
import { MatchSet } from '@core/types';

import { getMatchSetsByMatchId } from './selectors';
import { actions } from '.';

export function* getMatchSetsWorker({
  payload: { matchId },
}: InferAction<typeof actions.getMatchSets>): Generator<Effect, any, any> {
  yield put(actions.getMatchSetsRequest({ id: matchId }));
  const response = (yield call(getMatchSets, matchId)) as SagaReturnType<
    typeof getMatchSets
  >;

  if (!response.error) {
    yield put(actions.getMatchSetsSuccess({ id: matchId }));
  } else {
    yield put(actions.getMatchSetsFailure({ id: matchId }));
    yield call(handleApiErrors, response.error);
  }
}
export function* getMatchSetsWatcher() {
  yield takeEvery(actions.getMatchSets, getMatchSetsWorker);
}

export function* createMatchSetFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { matchId, matchSet, onSuccess, onSuccessEffectParameters },
    } = (yield take(actions.createMatchSet)) as InferAction<
      typeof actions.createMatchSet
    >;

    yield put(actions.createMatchSetRequest({ id: matchId }));
    const response = (yield call(
      createMatchSet,
      matchId,
      matchSet,
    )) as SagaReturnType<typeof createMatchSet>;

    if (!response.error) {
      onSuccess && ((yield call(onSuccess)) as void);
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield put(actions.createMatchSetSuccess({ id: matchId }));
      yield put(actions.getMatchSets({ matchId }));
    } else {
      yield put(actions.createMatchSetFailure({ id: matchId }));
      yield call(handleApiErrors, response.error);
    }
  }
}

export function* createMatchSetsWorker({
  payload: { matchId, matchSets, onSuccess, onSuccessEffectParameters },
}: InferAction<typeof actions.createMatchSets>): Generator<Effect, any, any> {
  yield put(actions.createMatchSetsRequest({ id: matchId }));

  const existingMatchSets = (yield select(
    getMatchSetsByMatchId,
    matchId,
  )) as Array<MatchSet>;

  const responses: Array<
    SagaReturnType<typeof createMatchSet | typeof updateSetWorker>
  > = [];
  let response: SagaReturnType<typeof createMatchSet | typeof updateSetWorker>;
  let hasSucceededAtleastOnce = false;
  for (const matchSet of matchSets) {
    const existingMatchSet = existingMatchSets.find(
      ({ number }) => number === matchSet.number,
    );

    if (existingMatchSet) {
      // TODO: JB: call lower level generator that updateSetWorker genarator uses under the hood instead of
      // reusing updateSetWorker as a generator and leave it to be used as a saga
      const updateSetAction = actions.updateSet({
        onSuccessEffectParameters,
        set: { ...existingMatchSet, ...matchSet },
        setId: existingMatchSet.id,
      });
      response = (yield call(
        updateSetWorker,
        updateSetAction,
      )) as SagaReturnType<typeof updateSetWorker>;
    } else {
      response = (yield call(
        createMatchSet,
        matchId,
        matchSet,
      )) as SagaReturnType<typeof createMatchSet>;
    }

    responses.push(response);

    if (response.error) {
      break;
    }
    hasSucceededAtleastOnce = true;
  }

  if (hasSucceededAtleastOnce) {
    onSuccess && ((yield call(onSuccess)) as void);
  }

  if (!response.error) {
    yield put(actions.createMatchSetsSuccess({ id: matchId }));
  } else {
    yield put(actions.createMatchSetsFailure({ id: matchId }));
    yield call(handleApiErrors, response.error);
  }

  return responses;
}

export function* updateMatchSetsFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { matchId, matchSets, onSuccess },
    } = (yield take(actions.updateMatchSets)) as InferAction<
      typeof actions.updateMatchSets
    >;

    yield put(actions.updateMatchSetsRequest({ id: matchId }));
    const response = (yield call(
      updateMatchSets,
      matchId,
      matchSets,
    )) as SagaReturnType<typeof updateMatchSets>;

    if (!response.error) {
      yield put(actions.updateMatchSetsSuccess({ id: matchId }));
      yield put(actions.getMatchSets({ matchId }));
      onSuccess && ((yield call(onSuccess)) as void);
    } else {
      yield put(actions.updateMatchSetsFailure({ id: matchId }));
      yield call(handleApiErrors, response.error);
    }
  }
}

export function* updateSetWorker({
  payload: { setId, set, onSuccess, onSuccessEffectParameters },
}: InferAction<typeof actions.updateSet>): Generator<Effect, any, any> {
  yield put(actions.updateSetRequest({ id: setId }));
  const response = (yield call(updateSet, setId, set)) as SagaReturnType<
    typeof updateSet
  >;

  if (!response.error) {
    onSuccess && ((yield call(onSuccess)) as void);
    yield call(
      getMatchMutatedSuccessfullyCallbacks,
      onSuccessEffectParameters,
      'update',
    );
    yield put(actions.updateSetSuccess({ id: setId }));
  } else {
    yield put(actions.updateSetFailure({ id: setId }));
    yield call(handleApiErrors, response.error);
  }

  return response;
}

export function* updateSetWatcher() {
  yield takeEvery(actions.updateSet, updateSetWorker);
}

export function* deleteSetFlow(): Generator<Effect, any, any> {
  while (true) {
    const {
      payload: { setId, onSuccess, onSuccessEffectParameters },
    } = (yield take(actions.deleteSet)) as InferAction<
      typeof actions.deleteSet
    >;

    yield put(actions.deleteSetRequest({ id: setId }));
    const response = (yield call(deleteSet, setId)) as SagaReturnType<
      typeof deleteSet
    >;

    if (!response.error) {
      onSuccess && ((yield call(onSuccess)) as void);
      yield call(
        getMatchMutatedSuccessfullyCallbacks,
        onSuccessEffectParameters,
        'update',
      );
      yield put(actions.deleteSetSuccess({ id: setId }));
    } else {
      yield put(actions.deleteSetFailure({ id: setId }));
      yield call(handleApiErrors, response.error);
    }
  }
}

export default function* saga() {
  yield all([
    createMatchSetFlow(),
    getMatchSetsWatcher(),
    updateMatchSetsFlow(),
    updateSetWatcher(),
    deleteSetFlow(),
  ]);
}
