import { pathOr, isEmpty } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import { getCompetitorsById } from '@core/store/modules/api/competitors/selectors';
import { State } from '@core/store';
import { DefaultState } from './reducer';
import actions, { NAMESPACE } from '.';

export const getGroupCompetitors = (
  state: State,
  groupId: number,
): DefaultState['byGroupId'][string]['competitors'] =>
  pathOr(null, ['tabs', NAMESPACE, 'byGroupId', groupId, 'competitors'], state);

// TODO: getState is an ant-pattern, but current state structure makes it impossible to use re-select efficiently without redesigning stage.
const getState = (state: State) => state;
const getCompetitors = (
  state: State,
  competitionId: number,
): DefaultState['byCompetitionId'][string]['competitors'] => [
  ...pathOr(
    [],
    ['tabs', NAMESPACE, 'byCompetitionId', competitionId, 'competitors'],
    state,
  ),
];

export const getCompetitionCompetitors = createSelector(
  getState,
  getCompetitors,
  (state, competitors) => {
    return competitors && !isEmpty(competitors)
      ? [...getCompetitorsById(state, competitors)]
      : null;
  },
);

export const areGroupCompetitorsLoading = createLoadingSelector([
  actions.getCompetitorsByGroupIdRequest.toString(),
]);
