import { call, Effect, put } from 'redux-saga/effects';

import * as disciplinarySanctionsApi from '@core/api/disciplinary-sanctions';
import { SanctionStatus, NewDisciplinarySanction } from '@core/types';

import { actions } from '.';

export type DisciplinaryCreation = {
  competitionId: number;
  data: NewDisciplinarySanction;
};

export function* getDisciplinarySanctions(
  competitionId: number,
  page?: any,
): Generator<Effect, any, any> {
  yield put(actions.getDisciplinarySanctionsRequest());
  const response = yield call(
    disciplinarySanctionsApi.getDisciplinarySanctions,
    competitionId,
    page,
  );

  if (!response.error) {
    yield put(actions.getDisciplinarySanctionsSuccess());
    yield put(
      actions.setDisciplinarySanctionsList(
        response.response?._embedded.disciplinarySanctions,
      ),
    );
  } else {
    yield put(actions.getDisciplinarySanctionsFailure());
  }

  return response;
}

export function* createDisciplinarySanction({
  competitionId,
  data,
}: DisciplinaryCreation): Generator<Effect, any, any> {
  yield put(actions.createDisciplinarySanctionRequest());
  const response = yield call(
    disciplinarySanctionsApi.createDisciplinarySanction,
    competitionId,
    data,
  );

  if (!response.error) {
    yield put(actions.createDisciplinarySanctionSuccess());
  } else {
    yield put(actions.createDisciplinarySanctionFailure());
  }

  return response;
}

type SanctionStatusUpdate = {
  competitionId: number;
  sanctionId: number;
  data: { status: SanctionStatus };
};

export function* updateDisciplinarySanctionStatus({
  competitionId,
  sanctionId,
  data,
}: SanctionStatusUpdate): Generator<Effect, any, any> {
  yield put(actions.updateDisciplinarySanctionStatusRequest());
  const response = yield call(
    disciplinarySanctionsApi.updateDisciplinarySanctionStatus,
    competitionId,
    sanctionId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateDisciplinarySanctionStatusSuccess());
    yield call(getDisciplinarySanctions, competitionId);
  } else {
    yield put(actions.updateDisciplinarySanctionStatusFailure());
  }

  return response;
}

type SanctionUpdate = {
  competitionId: number;
  sanctionId: number;
  data: any;
};

export function* updateDisciplinarySanction({
  competitionId,
  sanctionId,
  data,
}: SanctionUpdate): Generator<Effect, any, any> {
  yield put(actions.updateDisciplinarySanctionRequest());
  const response = yield call(
    disciplinarySanctionsApi.updateDisciplinarySanction,
    competitionId,
    sanctionId,
    data,
  );

  if (!response.error) {
    yield put(actions.updateDisciplinarySanctionSuccess());
    yield call(getDisciplinarySanctions, competitionId);
  } else {
    yield put(actions.updateDisciplinarySanctionFailure());
  }

  return response;
}
