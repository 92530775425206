import createTypography from '@mui/material/styles/createTypography';

import palette from '../palette';

const typography = createTypography(palette, {
  fontFamily: ['"LatoWeb"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
  h1: {
    fontSize: '3rem',
  },
  h2: {
    fontSize: '2rem',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.25rem',
  },
  h5: {
    fontSize: '1rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  caption: {
    fontSize: '0.75rem',
  },
  subtitle1: {
    fontSize: '1rem',
  },
});

export default typography;
