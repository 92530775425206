import {
  map,
  converge,
  merge,
  pick,
  pipe,
  pathOr,
  prop,
  symmetricDifferenceWith,
  eqBy,
  isEmpty,
  not,
  find,
  isNil,
  ifElse,
  length,
} from 'ramda';

import {
  MatchPlayersList,
  CompetitorPlayerResponse,
  CompetitorPlayerList,
  MatchTeamOfficialsList,
  CompetitorTeamOfficialList,
  MatchModel,
  MatchSheetErrorType,
  Sports,
  AddedMatchPlayerList,
  AddedMatchPlayer,
  AvailablePlayerList,
  AddedMatchTeamOfficialList,
} from '@core/types';

// @ts-ignore
export const formatAddedMatchPlayers: (
  arr1: MatchPlayersList,
) => AddedMatchPlayerList = map(
  // @ts-ignore
  converge(merge, [
    pick(['isCaptain', 'isLibero']),
    pipe(prop('competitorPlayer'), pick(['player', 'id', 'jerseyNumber'])),
  ]),
);

export const formatAddedMatchTeamOfficials = (list: MatchTeamOfficialsList) =>
  map(
    // @ts-ignore
    converge(merge, [
      pipe(prop('competitorTeamOfficial'), pick(['teamOfficial', 'id'])),
      ifElse(
        prop('teamOfficialRole') as any,
        pick(['teamOfficialRole']),
        pipe(prop('competitorTeamOfficial'), pick(['teamOfficialRole'])),
      ),
    ]),
    list,
  );

export const formatAvailableTeamOfficials = (
  list: CompetitorTeamOfficialList,
) => map(pick(['teamOfficialRole', 'teamOfficial', 'id']), list);

export const formatAvailablePlayerList: (
  response: CompetitorPlayerResponse,
) => AvailablePlayerList = pipe(
  pathOr([], ['_embedded', 'competitorPlayers']),
  map<CompetitorPlayerList, any>(pick(['id', 'player', 'jerseyNumber'])),
);

export const checkIfDiffsById: (arr1: Array<any>, arr2: Array<any>) => boolean =
  pipe(symmetricDifferenceWith(eqBy(prop('id') as any)), isEmpty, not);

export const findCaptainId: (
  addedMatchPlayers: AddedMatchPlayerList,
) => number = pipe(find<AddedMatchPlayer>(prop('isCaptain')), prop('id'));

export const isCaptainSelected = pipe(findCaptainId, isNil, not);

export const findLiberoIds = (addedMatchPlayers: AddedMatchPlayerList) =>
  addedMatchPlayers
    .filter((player) => player.isLibero)
    .map((player) => player.id);

export const isLiberoSelected = (addedMatchPlayers: AddedMatchPlayerList) =>
  findLiberoIds(addedMatchPlayers)?.length > 0;

export const formatCompetitorPlayersChangeList = (
  addedMatchLineupPlayers: AddedMatchPlayerList,
) => addedMatchLineupPlayers.map((player) => player.id);

function captainChangeCheck(
  addedMatchLineupPlayers: AddedMatchPlayerList,
  currentMatchPlayers: MatchPlayersList,
) {
  const currentCaptainId = findCaptainId(addedMatchLineupPlayers);
  const previousCaptainId = findCaptainId(
    formatAddedMatchPlayers(currentMatchPlayers),
  );

  return currentCaptainId !== previousCaptainId;
}

function liberoChangeCheck(
  addedMatchLineupPlayers: AddedMatchPlayerList,
  currentMatchPlayers: MatchPlayersList,
) {
  const currentLiberoPlayers = findLiberoIds(addedMatchLineupPlayers);
  const previousLiberoPlayers = findLiberoIds(
    formatAddedMatchPlayers(currentMatchPlayers),
  );

  if (currentLiberoPlayers?.length !== previousLiberoPlayers?.length) {
    return true;
  }

  return (
    !currentLiberoPlayers.every((currentLiberoPlayer) =>
      previousLiberoPlayers.includes(currentLiberoPlayer),
    ) ||
    !previousLiberoPlayers.every((previousLiberoPlayer) =>
      currentLiberoPlayers.includes(previousLiberoPlayer),
    )
  );
}

export function checkAreChangesInMatchPlayers(
  playersLists: [AddedMatchPlayerList, MatchPlayersList],
) {
  const [addedMatchLineupPlayers, currentMatchPlayers] = playersLists;

  const previousLineupPlayers = formatAddedMatchPlayers(currentMatchPlayers);
  const isDiffInLineup = checkIfDiffsById(
    addedMatchLineupPlayers,
    previousLineupPlayers,
  );

  const isCaptainChange = captainChangeCheck(
    addedMatchLineupPlayers,
    currentMatchPlayers,
  );
  const isLiberoChange = liberoChangeCheck(
    addedMatchLineupPlayers,
    currentMatchPlayers,
  );

  return isDiffInLineup || isCaptainChange || isLiberoChange;
}

export function isStartingLineupDataValid(
  addedLineup: AddedMatchPlayerList,
  sport: keyof typeof Sports,
) {
  if (sport === Sports.VOLLEYBALL) {
    return (
      !!addedLineup &&
      isCaptainSelected(addedLineup) &&
      isLiberoSelected(addedLineup)
    );
  }

  return !!addedLineup && isCaptainSelected(addedLineup);
}

export function updateLineupPlayerRole({
  role,
  playerId,
  addedMatchLineupPlayers,
}: {
  role: keyof AddedMatchPlayer;
  playerId: number;
  addedMatchLineupPlayers: AddedMatchPlayerList;
}) {
  if (role === 'isCaptain') {
    return addedMatchLineupPlayers.map((player) => ({
      ...player,
      [role]: player.id === Number(playerId),
    }));
  }

  return addedMatchLineupPlayers.map((player) =>
    player.id === Number(playerId)
      ? { ...player, [role]: !player[role] }
      : player,
  );
}

export const getMatchSheetValidationErrors = (
  matchDetails: MatchModel,
  addedLineup: AddedMatchPlayerList,
  addedTeamOfficials: AddedMatchTeamOfficialList,
) => {
  const validationErros = [];
  const competitionSettingsProperties = [
    '_embedded',
    'competition',
    'competitionSettings',
  ];
  const minimumPlayersInLineup = pathOr(
    null,
    [...competitionSettingsProperties, 'minimumPlayersInLineup'],
    matchDetails,
  );
  const maximumPlayersInLineup = pathOr(
    null,
    [...competitionSettingsProperties, 'maximumPlayersInLineup'],
    matchDetails,
  );
  const maximumTeamOfficialsInLineup = pathOr(
    null,
    [...competitionSettingsProperties, 'maximumTeamOfficialsInLineup'],
    matchDetails,
  );
  const addedPlayersCount = length(addedLineup);
  const addedTeamOfficialsCount = length(addedTeamOfficials);
  if (
    minimumPlayersInLineup &&
    maximumPlayersInLineup &&
    (addedPlayersCount < minimumPlayersInLineup ||
      addedPlayersCount > maximumPlayersInLineup)
  )
    validationErros.push(MatchSheetErrorType.TOTAL_PLAYERS);
  if (
    maximumTeamOfficialsInLineup &&
    addedTeamOfficialsCount > maximumTeamOfficialsInLineup
  )
    validationErros.push(MatchSheetErrorType.TEAM_OFFICIALS);

  return validationErros;
};
