import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Paginated, ClubModel } from '@core/types';
import { State } from '@core/store';
import { createLoadingSelector } from '@core/store/modules/ui/loading/selectors';
import clubsActions from '@core/store/modules/modals/clubs-filter-tab/actions';
import { getClubs } from '@core/store/modules/modals/clubs-filter-tab/selectors';
import { AutocompleteField } from '@core/components';

type DispatchProps = {
  actions: {
    clubs: typeof clubsActions;
  };
};

type StateProps = {
  isLoading: boolean;
  clubs: Paginated<ClubModel>;
};

interface OwnProps {
  handleSelectChange: (club: any) => void;
}

type Props = DispatchProps & OwnProps & StateProps;

const ClubSearch = (props: Props) => {
  const { t } = useTranslation();
  const { actions, isLoading, clubs, handleSelectChange } = props;

  useEffect(() => {
    actions.clubs.getClubs({ sort: 'title', public: true });

    return () => {
      handleSelectChange(undefined);
      actions.clubs.resetState();
    };
  }, []);

  const getClubOptions = () => {
    if (!clubs) {
      return [];
    }

    return clubs.list.map((club) => ({
      label: club.title,
      value: club.id,
    }));
  };

  const loadNext = (query: string) => {
    actions.clubs.getClubs({
      page: clubs.page + 1,
      query,
      sort: 'title',
      public: true,
    });
  };

  const onInputChange = (val: string) => {
    actions.clubs.getClubs({
      page: 1,
      query: val,
      sort: 'title',
      public: true,
    });
  };

  const onClubSelect = (club: any) => {
    handleSelectChange(club);
  };

  const hasMore = clubs?.pages > clubs?.page;

  return (
    <Box mb={1} mt={1}>
      <AutocompleteField
        name="club-admin"
        isLoading={isLoading}
        options={getClubOptions()}
        textFieldProps={{
          label: t('Select club'),
          required: true,
        }}
        onInputChange={onInputChange}
        onChange={onClubSelect}
        pagination={{ hasMore, loadNext }}
        useOptionAsValue
        menuPosition={'fixed'}
      />
    </Box>
  );
};

const loadingSelector = createLoadingSelector([
  clubsActions.getClubsRequest.toString(),
]);

const mapStateToProps = (state: State): StateProps => ({
  isLoading: loadingSelector(state),
  clubs: getClubs(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    clubs: bindActionCreators(clubsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubSearch);
