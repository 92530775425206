import { pathOr } from 'ramda';

import { MenuItemProps } from '@core/types/ui';

export const createAccessValidator =
  (accessLinks: Record<string, any>) =>
  (menuItem: Partial<MenuItemProps>): boolean => {
    return menuItem.requiredAccess
      ? menuItem.requiredAccess.some(
          (access) => !!pathOr(false, [access], accessLinks),
        )
      : true;
  };
