import { handleActions } from 'redux-actions';
import { assoc, dissoc } from 'ramda';

import { formatPaginatedStore } from '@core/helpers';
import { Filters, MatchModel, MatchOfficial } from '@core/types';

import { actions } from '.';

export interface DefaultState {
  allMatchOfficials: Array<{ [key: string]: MatchOfficial }>;
  calendarMatchOfficials: Array<{ [key: string]: MatchOfficial }>;
  matchOfficialConflicts: Array<MatchModel>;
  filters: Filters;
}

export const defaultState: DefaultState = {
  allMatchOfficials: null,
  calendarMatchOfficials: null,
  matchOfficialConflicts: null,
  filters: {},
};

type Payload = any;

const reducer = handleActions<DefaultState, Payload>(
  // @ts-ignore
  {
    [actions.setMatchOfficials.toString()]: (state, { payload }) =>
      assoc('allMatchOfficials', payload, state),
    [actions.setCalendarMatchOfficials.toString()]: (state, { payload }) =>
      assoc(
        'calendarMatchOfficials',
        formatPaginatedStore('matchOfficials')(payload),
        state,
      ),
    [actions.setMatchOfficialGroups.toString()]: (state, { payload }) =>
      assoc('currentOfficialGroups', payload, state),
    [actions.setMatchOfficialsMeta.toString()]: (state, { payload }) =>
      assoc('meta', payload, state),
    [actions.setMatchOfficialConflicts.toString()]: (state, { payload }) =>
      assoc('matchOfficialConflicts', payload, state),
    [actions.resetMatchOfficialConflicts.toString()]: (state) =>
      dissoc('matchOfficialConflicts', state),
    [actions.setFilterParams.toString()]: (state, { payload }) =>
      assoc('filters', payload, state),
    [actions.resetMatchOfficials.toString()]: () => defaultState,
  },
  defaultState,
);

export default reducer;
