import { createAction } from 'redux-actions';

import {
  PanelState,
  ExpansionPanelGroupStates,
  ExpansionPanelGroupId,
} from '@core/types';

import * as ACTION_TYPES from './constants';

export const setExpansionPanelGroups = createAction(
  ACTION_TYPES.SET_EXPANSION_PANEL_GROUPS,
);

export const updatePanelState = createAction<{
  panelIndex: number;
  groupId: ExpansionPanelGroupId;
  panelState: Partial<PanelState>;
}>(ACTION_TYPES.UPDATE_PANEL_STATE);

export const activatePreviousPanel = createAction<{
  panelIndex: number;
  groupId: ExpansionPanelGroupId;
}>(ACTION_TYPES.ACTIVATE_PREVIOUS_PANEL);

export const activateNextPanel = createAction<{
  panelIndex: number;
  groupId: ExpansionPanelGroupId;
}>(ACTION_TYPES.ACTIVATE_NEXT_PANEL);

export const setPanelError = createAction<{
  panelIndex: number;
  groupId: ExpansionPanelGroupId;
}>(ACTION_TYPES.SET_PANEL_ERROR);

export const setPanelCompleted = createAction<{
  panelIndex: number;
  groupId: ExpansionPanelGroupId;
}>(ACTION_TYPES.SET_PANEL_COMPLETED);

export const addExpansionPanelGroup = createAction<{
  groupId: ExpansionPanelGroupId;
  panelsStates: ExpansionPanelGroupStates;
}>(ACTION_TYPES.ADD_EXPANSION_PANEL_GROUP);

export const removeExpansionPanelGroup = createAction<ExpansionPanelGroupId>(
  ACTION_TYPES.REMOVE_EXPANSION_PANEL_GROUP,
);
