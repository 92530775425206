import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

import { CompetitionOrganisersList } from '@core/types';

import { SET_COMPETITION_ORGANISERS } from './constants';

export interface DefaultState {
  allOrganisers: CompetitionOrganisersList;
}

export const defaultState: DefaultState = {
  allOrganisers: null,
};

const reducer = handleActions<DefaultState, any>(
  {
    [SET_COMPETITION_ORGANISERS]: (state, { payload }) =>
      assoc('allOrganisers', payload, state),
  },
  defaultState,
);

export default reducer;
