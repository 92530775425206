import { pathOr, isEmpty } from 'ramda';

import { State } from '@core/store';
import { getCountriesById } from '@core/store/modules/api/countries/selectors';

import { DefaultState } from './reducer';
import { NAMESPACE } from '.';

export const getCountries = (state: State) => {
  const countries: DefaultState['countries'] = {
    ...pathOr(null, ['modals', NAMESPACE, 'countries'], state),
  };

  return countries && !isEmpty(countries)
    ? { ...countries, list: getCountriesById(state, countries.list) }
    : null;
};
