import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, InputAdornment, TextField, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { MatchPlayerOrigin } from '@core/types';
import { actions as matchSheetManagementActions } from '@core/store/modules/tabs/match-sheet-management';

interface OwnProps {
  matchId: number;
  matchPlayerOrigin: MatchPlayerOrigin;
  teamId: number;
  competitionId: number;
}

interface DispatchProps {
  actions: {
    matchSheetManagement: typeof matchSheetManagementActions;
  };
}

type Props = OwnProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 36,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
  },
  gridContainer: {
    paddingRight: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
}));

const AvailablePlayersSearchField = (props: Props) => {
  const { actions, matchId, matchPlayerOrigin, teamId, competitionId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  function handleSearchAvailablePlayers(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const searchValue = event.target.value;

    actions.matchSheetManagement.searchCompetitorPlayers({
      matchId,
      teamId,
      competitionId,
      matchPlayerOrigin,
      params: { query: searchValue },
    });
  }

  return (
    <Grid className={classes.gridContainer} item xs={12} sm={6}>
      <TextField
        classes={{
          root: classes.root,
        }}
        placeholder={t('Search')}
        id="searchForPlayer"
        onChange={handleSearchAvailablePlayers}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon classes={{ root: classes.searchIcon }} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    matchSheetManagement: bindActionCreators(
      matchSheetManagementActions,
      dispatch,
    ),
  },
});

export default connect(null, mapDispatchToProps)(AvailablePlayersSearchField);
